import React from 'react';
import {
  ListItem,
  ListItemStatus,
  ListItemLabelWrapper,
  ListItemLabel
} from 'components/list/list-item';
import { getLocalDateFormat } from 'utils/date';

export const SessionSporterListItem = ({
  active,
  session,
  percentage,
  onClick,
  noMaxHeight
}) => (
  <ListItem
    noMaxHeight={noMaxHeight}
    active={active}
    done={percentage === 100}
    inProgress={percentage !== 0 && percentage !== 100}
    onClick={onClick}
  >
    <ListItemStatus>{`${percentage}%`}</ListItemStatus>
    <ListItemLabelWrapper>
      <ListItemLabel label>{session.name}</ListItemLabel>
      <ListItemLabel info middleColumn>
        {session.testSets?.[0]?.title}
      </ListItemLabel>
      <ListItemLabel info>
        {getLocalDateFormat(session.startdate)}
      </ListItemLabel>
    </ListItemLabelWrapper>
  </ListItem>
);
