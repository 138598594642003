import gql from 'graphql-tag';
import { getGQLResult } from 'services/aws/utils/get';
import { appSyncClient } from './app-sync';

export const QUERY_GET_VIDEOS = gql`
  query getExercises($entityId: ID!) {
    getExercises(entityId: $entityId) {
      id
      parentId
      title
      note
      remarks
      cues
      protocol
      level
      media {
        id
        filename
        signedDownloadUrl
        linkId
        status
        meta
        type
      }
      tags {
        id
        label
      }
      editable
      templateCount
      access
      meta
      shares {
        id
      }
      annotations {
        id
        testData {
          id
          personId
          data
          date_updated
          date_created
          finished
          resultBenchmarkId
        }
      }
      person {
        id
        firstname
        lastname
      }
    }
  }
`;

export const QUERY_GET_VIDEOS_BY_PERSON = gql`
  query getExercisesByPersonId($entityId: ID!, $personId: ID) {
    getExercisesByPersonId(entityId: $entityId, personId: $personId) {
      id
      parentId
      title
      note
      remarks
      cues
      protocol
      level
      media {
        id
        filename
        signedDownloadUrl
        linkId
        status
        meta
        type
      }
      tags {
        id
        label
      }
      editable
      templateCount
      access
      meta
      shares {
        id
      }
      annotations {
        id
        testData {
          id
          personId
          data
          date_updated
          date_created
          finished
          resultBenchmarkId
        }
      }
      person {
        id
        firstname
        lastname
      }
    }
  }
`;

export const QUERY_GET_VIDEOS_SHARED_WITH = gql`
  query getExercisesSharedWith($entityId: ID, $personId: ID) {
    getExercisesSharedWith(entityId: $entityId, personId: $personId) {
      id
      parentId
      title
      note
      remarks
      cues
      protocol
      level
      media {
        id
        filename
        signedDownloadUrl
        linkId
        status
        meta
        type
      }
      tags {
        id
        label
      }
      templateCount
      access
      meta
      shares {
        id
        linkId
        sharedWith {
          id
          firstname
          lastname
        }
      }
      annotations {
        id
        testData {
          id
          personId
          data
          date_updated
          date_created
          finished
          resultBenchmarkId
        }
      }
      person {
        id
        firstname
        lastname
      }
    }
  }
`;

export const QUERY_GET_VIDEOS_BY_TAGS = gql`
  query getExercisesByTags($entityId: ID!, $tags: [ID]!) {
    getExercisesByTags(entityId: $entityId, tags: $tags) {
      id
      parentId
      title
      note
      remarks
      cues
      protocol
      level
      tags {
        id
        label
      }
      editable
    }
  }
`;

export const QUERY_GET_VIDEO = gql`
  query getExercise($id: ID!, $entityId: ID) {
    getExercise(exerciseId: $id, entityId: $entityId) {
      id
      parentId
      title
      cues
      protocol
      note
      remarks
      level
      entity {
        id
        name
        info
      }
      media {
        id
        filename
        signedDownloadUrl
        linkId
        status
        meta
        type
      }
      tags {
        id
        label
      }
      meta
      editable
      templateCount
      access
      date_created
      shares {
        id
        linkId
        sharedWith {
          id
          firstname
          lastname
        }
      }
      annotations {
        id
      }
      person {
        id
        firstname
        lastname
      }
    }
  }
`;

export const MUTATION_ADD_VIDEO = gql`
  mutation addExercise(
    $entityId: ID!
    $title: String!
    $note: String
    $remarks: String
    $cues: [String]
    $protocol: String
    $level: Int
    $thumbFileId: ID
    $videoFileId: ID
    $tagIds: [ID]
  ) {
    addExercise(
      input: {
        entityId: $entityId
        title: $title
        note: $note
        remarks: $remarks
        cues: $cues
        protocol: $protocol
        level: $level
        thumbFileId: $thumbFileId
        videoFileId: $videoFileId
        tagIds: $tagIds
      }
    ) {
      id
      parentId
    }
  }
`;

export const MUTATION_EDIT_VIDEO = gql`
  mutation editExercise(
    $id: ID!
    $entityId: ID!
    $title: String!
    $note: String
    $remarks: String
    $cues: [String]
    $protocol: String
    $level: Int
    $thumbFileId: ID
    $videoFileId: ID
    $tagIds: [ID]
    $meta: AWSJSON
  ) {
    editExercise(
      input: {
        id: $id
        entityId: $entityId
        title: $title
        note: $note
        remarks: $remarks
        cues: $cues
        protocol: $protocol
        level: $level
        thumbFileId: $thumbFileId
        videoFileId: $videoFileId
        tagIds: $tagIds
        meta: $meta
      }
    ) {
      id
      parentId
    }
  }
`;

export const MUTATION_COMPLETE_VIDEO = gql`
  mutation editExercise(
    $id: ID!
    $entityId: ID!
    $title: String!
    $meta: AWSJSON
  ) {
    editExercise(
      input: { id: $id, entityId: $entityId, title: $title, meta: $meta }
    ) {
      id
    }
  }
`;

export const MUTATION_ARCHIVE_VIDEO = gql`
  mutation archiveExercise($exerciseId: ID!) {
    archiveExercise(exerciseId: $exerciseId)
  }
`;

export const downloadExerciseProgram = async programId => {
  return await getGQLResult(appSyncClient, QUERY_DOWNLOAD_EXERCISE_PROGRAM, {
    programId
  });
};

export const QUERY_DOWNLOAD_EXERCISE_PROGRAM = gql`
  query downloadExerciseProgram($programId: ID!) {
    downloadExerciseProgram(programId: $programId)
  }
`;

export const QUERY_MOVE_FILE_TO_VIDEOANALYSIS = gql`
  mutation moveFileToVideoAnalysis($fileId: ID!) {
    moveFileToVideoAnalysis(fileId: $fileId)
  }
`;

export const QUERY_GET_VIDEOANALYSIS = gql`
  query getVideoAnalysis($key: String) {
    getVideoAnalysis(key: $key)
  }
`;
