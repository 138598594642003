import React, { useContext } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { StoreContext } from 'index';
import { useNotificationQueue } from 'components/notification';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import messages from 'messages.js';
import {
  MUTATION_ADD_CLIENT,
  MUTATION_UPDATE_CLIENT,
  S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID
} from 'services/aws/client-query';
import { cleanNullUndefined } from 'utils/object';
import {
  QUERY_GET_PERSONS_OF_ENTITY,
  QUERY_GET_USER_BY_ID
} from 'services/aws/user-query';
import { format } from 'date-fns';
import { parseDateString } from 'utils/date';

function SporterForm({
  entityId,
  person,
  children,
  extraClassNames,
  onCancel,
  onAdded,
  onComplete
}) {
  const {
    authStore: {
      user,
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const notification = useNotificationQueue();
  const intl = useIntl();

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required(
      intl.formatMessage(messages.firstnameRequired)
    ),
    lastname: Yup.string().required(
      intl.formatMessage(messages.lastnameRequired)
    ),
    gender: Yup.string().required(intl.formatMessage(messages.genderRequired)),
    birthdate: Yup.date()
      .transform(parseDateString)
      .typeError(intl.formatMessage(messages.birthdateRequired))
      .required(intl.formatMessage(messages.birthdateRequired)),
    athleteEmail: Yup.string().email(intl.formatMessage(messages.emailNotValid))
  });

  const [editPerson] = useMutation(MUTATION_UPDATE_CLIENT, {
    refetchQueries: [
      {
        query: QUERY_GET_USER_BY_ID,
        variables: { id: person.id },
        fetchPolicy: 'network-only'
      }
    ]
  });

  const [addPerson] = useMutation(MUTATION_ADD_CLIENT, {
    refetchQueries: [
      {
        query: S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID,
        variables: { id: entityId }
      },
      {
        query: QUERY_GET_PERSONS_OF_ENTITY,
        variables: { entityId: rootEntityId }
      }
    ]
  });

  const handleSubmit = async values => {
    const meta = JSON.stringify({
      email: values.athleteEmail
        ? values.athleteEmail.trim().toLowerCase()
        : undefined
    });

    const personData = cleanNullUndefined({
      firstname: values.firstname,
      lastname: values.lastname,
      gender: values.gender,
      birthdate: format(values.birthdate, 'yyyy-LL-dd'),
      uid: values.uid,
      meta,
      notes: values.notes,
      dominantHand: values.dominantHand || null
    });

    if (!values.id) {
      addPerson({
        variables: {
          entityId,
          topLevelEntityId: user.rootEntityId,
          person: { ...personData }
        }
      }).then(({ data: { addPersonToEntityReturnPerson } }) => {
        notification.add(addPersonToEntityReturnPerson.id, {
          message: intl.formatMessage(messages.personAdded)
        });
        setTimeout(() => {
          onAdded && onAdded(addPersonToEntityReturnPerson.id);
        }, 100);
      });
    } else {
      editPerson({
        variables: { ...personData, id: person.id, entityId }
      }).then(({ data: { editPerson } }) => {
        notification.add(editPerson.id, {
          message: intl.formatMessage(messages.personUpdated)
        });
        onComplete && onComplete(editPerson.id);
      });
    }
  };
  return (
    <Formik
      initialValues={person}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={handleSubmit}
      onReset={() => onCancel && onCancel()}
    >
      {props => {
        return (
          <Form
            className={classNames('c-card c-card--secondary', extraClassNames)}
            noValidate
          >
            {typeof children === 'function' ? children(props) : children}
          </Form>
        );
      }}
    </Formik>
  );
}

export default SporterForm;
