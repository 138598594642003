import React, { useEffect, useState, useContext, Fragment } from 'react';
import { generatePath } from 'react-router-dom';
import { StoreContext } from 'index';
import { FormattedMessage } from 'react-intl';
import { useSessionContext } from 'contexts/SessionContext';
import Panel from 'components/panel/Panel';
import Loader from 'components/loader/Loader';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import PanelHeader from 'components/panel/PanelHeader';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import messages from 'messages';
import {
  PanelTitleWrapper,
  PanelTitle,
  PanelSubtitle,
  PanelTitleButton,
  PanelTitleButtonsGroup
} from 'components/panel/panel-title';
import PanelBody from 'components/panel/PanelBody';
import { getLocalDateFormat } from 'utils/date';
import { TabList, TabPanel, Tab, Tabs, TabPanelBody } from 'components/tabs';
import ReportCard from 'containers/partials/cards/ReportCard';
import { ROUTE_USER_SESSIONS_ENTITY } from 'routes/RouteList';
import MenuWrapper from 'components/menu/MenuWrapper';
import DownloadSessionReportButton from 'containers/partials/buttons/DownloadSessionReportButton';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { Card, CardBody } from 'components/card';
import { TestDataProvider } from 'contexts/TestDataContext';
import SporterReportCard from 'containers/partials/cards/SporterReportCard';

const SessionReportPanel = ({ entityId }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { session, loading, error, options, setOptions } = useSessionContext();
  const [testData, setTestData] = useState(false);

  useEffect(() => {
    if (session.id) {
      setOptions({ ...options, hideEmpty: true });
      const testData = session.getTestData(user.id);
      setTestData(testData?.id ? testData : false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.id]);

  if (loading) {
    return (
      <Panel>
        <Loader />
      </Panel>
    );
  }

  return (
    <Fragment>
      <Panel>
        {error && <RetryPanel />}
        {session && (
          <div style={{ background: 'inherit' }}>
            <PanelHeader>
              <Breadcrumbs>
                <Breadcrumb
                  to={generatePath(ROUTE_USER_SESSIONS_ENTITY, {
                    entityId
                  })}
                >
                  <FormattedMessage {...messages.breadcrumbSessions} />
                </Breadcrumb>
                <Breadcrumb>
                  <FormattedMessage
                    {...messages.breadcrumbSessionReport}
                    values={{ session: session.name }}
                  />
                </Breadcrumb>
              </Breadcrumbs>
              <PanelTitleWrapper>
                <PanelTitle>
                  {session.name}
                  {testData && (
                    // Only show de button when there is a data
                    <PanelTitleButtonsGroup>
                      <MenuWrapper trigger={<PanelTitleButton />}>
                        <DownloadSessionReportButton
                          session={session}
                          testDataId={testData.id}
                          options={options}
                        />
                      </MenuWrapper>
                    </PanelTitleButtonsGroup>
                  )}
                </PanelTitle>
                <PanelSubtitle>
                  {getLocalDateFormat(session.startdate)}
                </PanelSubtitle>
              </PanelTitleWrapper>
            </PanelHeader>
            <PanelBody>
              <Tabs>
                <TabList>
                  <Tab>
                    <FormattedMessage {...messages.tabSessionSporterReport} />
                  </Tab>
                </TabList>
                <TabPanel>
                  {testData ? (
                    <TabPanelBody withScroller>
                      {session.isTestSetV2 ? (
                        <TestDataProvider
                          entityId={entityId}
                          session={session}
                          sporterId={user.id}
                        >
                          <ReportCard compressed />
                        </TestDataProvider>
                      ) : (
                        <SporterReportCard
                          entityId={entityId}
                          rootEntityId={entityId}
                          reportBenchmarkTab
                        />
                      )}
                    </TabPanelBody>
                  ) : (
                    <TabPanelBody withScroller>
                      <Card secondary>
                        <CardBody extraClassNames="u-margin-bottom-none u-padding-vertical-huge">
                          <Message emptyState icon="test">
                            <MessageText>
                              <FormattedMessage
                                {...messages.emptySessionForAthlete}
                              />
                            </MessageText>
                          </Message>
                        </CardBody>
                      </Card>
                    </TabPanelBody>
                  )}
                </TabPanel>
              </Tabs>
            </PanelBody>
          </div>
        )}
      </Panel>
    </Fragment>
  );
};

export default SessionReportPanel;
