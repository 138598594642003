import { createContext, useContext, useEffect, useReducer } from 'react';
import { useLazyQuery } from '@apollo/client';
import { S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID } from 'services/aws/client-query';
import { EntityTypes } from 'constants.js';
import { sortEntities, sortUsers } from 'utils/sort';
import Person from 'models/Person';
import Group from 'models/Group';

export const GroupsContext = createContext({});

const findStringInEntity = (entity, str) => {
  const trimmedString = str.toLowerCase().trim();
  const entityName = entity.name.toLowerCase().trim();
  if (entityName.indexOf(trimmedString) !== -1) return true;
};

const findStringInClient = (client, str) => {
  const trimmedString = str.toLowerCase().trim();
  const clientFirstname = client.firstname.toLowerCase().trim();
  if (clientFirstname.indexOf(trimmedString) !== -1) return true;
  const clientLastname = client.lastname.toLowerCase().trim();
  if (clientLastname.indexOf(trimmedString) !== -1) return true;
};

const filterEntitiesByYear = (entities, year) => {
  return entities.filter(entity => {
    if (!entity.meta) return false;
    const meta = JSON.parse(entity.meta);
    return meta?.year === year;
  });
};

export const getFilteredGroups = (entities, search) => {
  return !search
    ? entities
    : entities.filter(e => {
        if (findStringInEntity(e, search)) return true;

        const noFoundDeeper = search.split(' ').some(sw => {
          return !findStringInEntity(e, sw);
        });
        return !noFoundDeeper;
      });
};

export const getFilteredUsers = (users, search) => {
  return !search
    ? users
    : users.filter(c => {
        if (findStringInClient(c, search)) return true;

        const noFoundDeeper = search.split(' ').some(sw => {
          return !findStringInClient(c, sw);
        });
        return !noFoundDeeper;
      });
};

const initialState = {
  filter: {
    search: '',
    year: '',
    type: ''
  },
  group: new Group({}),
  hasGroups: false,
  groups: [],
  hasUsers: false,
  users: [],
  entities: []
};

// Actions
export const SET_GROUP = 'SET_GROUP';
export const SET_GROUPS = 'SET_GROUPS';
export const SET_USERS = 'SET_USERS';
export const SET_ENTITIES = 'SET_ENTITIES';

export const SET_FILTER = 'SET_FILTER';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_GROUP:
      return { ...state, group: action.payload };
    case SET_GROUPS:
      return {
        ...state,
        groups: [...action.payload]
      };
    case SET_USERS:
      return {
        ...state,
        users: [...action.payload]
      };
    case SET_ENTITIES:
      return { ...state, entities: [...action.payload] };
    case SET_FILTER:
      return { ...state, filter: { ...action.payload } };
    default:
      return state;
  }
};

const GroupsContextProvider = ({ entityId, rootEntityId, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [getGroupsAndClients, { loading, error, data, refetch }] = useLazyQuery(
    S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID,
    {
      variables: { id: entityId }
    }
  );

  /*useEffect(() => {
    const fetchData = async () => {
      await getGroupsAndClients();
    };
    fetchData().catch(err => console.error(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, entityId]);*/

  useEffect(() => {
    if (data?.getEntity) {
      const group = new Group(data.getEntity);
      const entities = [];
      if (group?.subEntities) {
        const groups = group.subEntities.filter(
          se => se.type !== EntityTypes.ORGANISATION
        );
        actions.setGroups(groups);
        entities.push(...groups);
        state.hasGroups = groups.length > 0;
      }
      if (group?.clients && state.filter.type !== EntityTypes.GROUP) {
        actions.setUsers(group.clients.map(user => new Person(user)));

        entities.push(...group.clients.map(user => new Person(user)));
        state.hasUsers = group.clients.length > 0;
      }

      actions.setEntities(entities);
      actions.setGroup(group);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (state.group.id && state.filter) {
      const entities = [];
      let filteredGroups = getFilteredGroups(
        sortEntities(state.group.subEntities),
        state.filter.search ?? ''
      );
      if (state.filter.year) {
        filteredGroups = filterEntitiesByYear(
          filteredGroups,
          state.filter.year
        );
      }
      entities.push(...filteredGroups.map(g => new Group(g)));
      dispatch({
        type: SET_GROUPS,
        payload: filteredGroups.map(g => new Group(g))
      });

      const filteredUsers =
        state.filter.type !== EntityTypes.GROUP && entityId !== rootEntityId
          ? getFilteredUsers(
              sortUsers(state.group.clients),
              state.filter.search ?? ''
            )
          : [];
      entities.push(...filteredUsers.map(user => new Person(user)));
      dispatch({
        type: SET_USERS,
        payload: filteredUsers.map(user => new Person(user))
      });
      actions.setEntities(entities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.group, state.filter]);

  const actions = {
    setGroup: group => {
      dispatch({ type: SET_GROUP, payload: new Group(group) });
    },
    setGroups: groups => {
      dispatch({
        type: SET_GROUPS,
        payload: groups.map(g => new Group(g))
      });
    },
    setUsers: users => {
      dispatch({
        type: SET_USERS,
        payload: users
      });
    },
    setEntities: entities => {
      dispatch({ type: SET_ENTITIES, payload: entities });
    },
    setFilter: filter => {
      dispatch({
        type: SET_FILTER,
        payload: { ...state.filter, ...filter }
      });
    },
    resetFilter: () => {
      dispatch({
        type: SET_FILTER,
        payload: { search: '', year: '', type: '' }
      });
    },
    getGroups: async groupId => {
      actions.setEntities([]);
      await getGroupsAndClients({
        variables: { id: groupId }
      });
    },
    refetchGroups: async groupId => {
      actions.setEntities([]);
      await getGroupsAndClients({
        variables: { id: groupId },
        fetchPolicy: 'cache-and-network'
      });
    }
  };

  return (
    <GroupsContext.Provider value={{ state, actions, loading, error, refetch }}>
      {children}
    </GroupsContext.Provider>
  );
};

function useGroupsContext() {
  const context = useContext(GroupsContext);
  if (context === undefined) {
    throw new Error(
      'The GroupsContext hook must be used within a GroupsContext.Provider'
    );
  }
  return context;
}

export { GroupsContextProvider, useGroupsContext };
