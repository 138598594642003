import { groupBy } from 'utils/array';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import Template from 'models/Template';

export default class Lesson extends Template {
  didactics = '';
  school = '';
  classroom = '';
  subject = '';
  lessonDate;

  constructor({
    id,
    entityId,
    title = '',
    personId = '',
    exerciseIds = [],
    exercises = [],
    editable,
    notes = [],
    didactics = '',
    school = '',
    classroom = '',
    subject = '',
    lessonDate = '',
    meta
  }) {
    super({
      id,
      entityId,
      title,
      personId,
      exerciseIds,
      exercises,
      editable
    });

    try {
      this.meta = JSON.parse(meta);
    } catch (er) {
      this.meta = {};
    }

    this.didactics = this.meta?.didactics ?? didactics;
    this.school = this.meta?.school ?? school;
    this.classroom = this.meta?.classroom ?? classroom;
    this.subject = this.meta?.subject ?? subject;
    this.lessonDate = this.meta?.lessonDate
      ? new Date(this.meta.lessonDate)
      : lessonDate
      ? new Date(lessonDate)
      : '';

    if (notes.length > 0) {
      const groupedNotes = groupBy(notes, ['linkId2']);
      Object.keys(groupedNotes).forEach(key => {
        groupedNotes[key] = sort(groupedNotes[key], {
          keys: [
            {
              key: 'date_created',
              desc: true,
              dataType: SORT_DATA_TYPES.NUMBER
            }
          ]
        });
      });
      this.notes = groupedNotes;
    }
  }

  static get modelName() {
    return 'Lesson';
  }
}
