import React, { useContext } from 'react';
import Select, { Option } from 'components/input/Select';
import { useIntl } from 'react-intl';
import messages from 'messages';
import { TestSetV2Type } from 'constants.js';
import { capitalize } from 'utils/string';
import { StoreContext } from 'index';

function TestSetTypeSelect({ onChange, value, label }) {
  const intl = useIntl();
  const {
    authStore: { user }
  } = useContext(StoreContext);

  const testSetTypes = Object.values(TestSetV2Type).filter(t => {
    if (!user.superadmin) {
      return [TestSetV2Type.TALENT, TestSetV2Type.REHAB_TEMPLATE].includes(t);
    }
    return t;
  });

  return (
    <Select
      id="type"
      label={label ?? intl.formatMessage(messages.labelSessionTestSetType)}
      name="testsettype"
      onChange={selectedValue => {
        onChange(selectedValue.value);
      }}
      value={value}
    >
      {testSetTypes.map(type => (
        <Option
          key={type}
          value={type}
          label={
            type === 'talent'
              ? intl.formatMessage(messages.talentLabel)
              : capitalize(type).replace('_', ' ')
          }
        />
      ))}
    </Select>
  );
}

export default TestSetTypeSelect;
