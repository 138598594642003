import { useContext, useRef, useState } from 'react';
import Panel from 'components/panel/Panel';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import {
  PanelSubtitle,
  PanelTitle,
  PanelTitleWrapper
} from 'components/panel/panel-title';
import { getLocalDateFormat, getToday } from 'utils/date';
import { PanelHeader } from 'components/panel';
import { StoreContext } from 'index';
import { useHistory } from 'react-router-dom';
import PanelBody from 'components/panel/PanelBody';
import Card from 'components/card/Card';
import { CardHeaderText, CardHeaderTitle } from 'components/card/card-header';
import CardHeader from 'components/card/card-header/CardHeader';
import CardBody from 'components/card/CardBody';
import Button from 'components/button/Button';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_USAGE } from 'services/aws/account-query';

import Loader from 'components/loader/Loader';
import * as Yup from 'yup';
import { FieldDatePicker } from 'components/input/FieldDatePicker';
import { Form, Formik } from 'formik';
import MessageText from 'components/message/MessageText';
import Icon from 'components/icon/Icon';
import Badge from 'components/badge/Badge';
import { isNumber } from 'utils/math';
import classNames from 'classnames';
import { DATE_LOCALE } from 'constants.js';
import { ROUTE_SETTINGS } from 'routes/RouteList';
import { format } from 'date-fns';

Usage.propTypes = {};

function Usage() {
  const {
    authStore: {
      user: { rootEntityId, baseLanguage }
    }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const intl = useIntl();
  const [numberOfAthletes, setNumberOfAthletes] = useState('-');

  const startDateRef = useRef();
  const endDateRef = useRef();

  const [getUsage, { loading }] = useLazyQuery(QUERY_GET_USAGE);

  const validationSchema = Yup.object().shape({
    startdate: Yup.date().required(
      intl.formatMessage(messages.startdateRequired)
    ),
    enddate: Yup.date().required(intl.formatMessage(messages.enddateRequired))
  });

  const countSportersHandler = async formData => {
    const { data } = await getUsage({
      variables: {
        startdate: format(formData.startdate, 'yyyy-LL-dd'),
        enddate: format(formData.enddate, 'yyyy-LL-dd'),
        entityId: rootEntityId
      }
    });

    if (data?.getUsage) {
      const usageData = JSON.parse(data.getUsage);
      setNumberOfAthletes(usageData.athletesUsed);
    }
  };

  return (
    <Panel>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb onClick={() => push(ROUTE_SETTINGS)}>
            <FormattedMessage {...messages.breadcrumbAccountUsage} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.accountUsageTitle} />
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage {...messages.accountUsageSubTitle} />
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        <Card secondary>
          <CardHeader secondary>
            <CardHeaderText>
              <CardHeaderTitle>
                <FormattedMessage {...messages.accountUsageSportersTitle} />
              </CardHeaderTitle>
            </CardHeaderText>
          </CardHeader>
          <CardBody secondary>
            {loading && <Loader />}
            <p>
              <FormattedMessage {...messages.accountUsageSportersInfo} />
            </p>
            <Formik
              initialValues={{
                startdate: '',
                enddate: ''
              }}
              validationSchema={validationSchema}
              onSubmit={countSportersHandler}
            >
              {({ errors, handleChange }) => (
                <Form noValidate>
                  <div className="o-layout o-layout--tiny">
                    <div
                      className={classNames(
                        'o-layout__item u-1-of-2-at-small',
                        {
                          'u-margin-bottom-medium': errors['startdate']
                        }
                      )}
                    >
                      <FieldDatePicker
                        innerRef={startDateRef}
                        id={'startdate'}
                        name="startdate"
                        type={'date'}
                        min="1930-01-01"
                        max={getToday()}
                        onChange={handleChange}
                        required
                        icon={
                          errors['startdate'] ? 'validation-error' : 'calendar'
                        }
                        errors={errors}
                      >
                        <FormattedMessage
                          {...messages.accountUsageSportersStartdateLabel}
                        />
                      </FieldDatePicker>
                    </div>
                    <div
                      className={classNames(
                        'o-layout__item u-1-of-2-at-small',
                        {
                          'u-margin-bottom-medium': errors['enddate']
                        }
                      )}
                    >
                      <FieldDatePicker
                        innerRef={endDateRef}
                        id="enddate"
                        name="enddate"
                        type={'date'}
                        min="1930-01-01"
                        max={getToday()}
                        onChange={handleChange}
                        required
                        icon={
                          errors['enddate'] ? 'validation-error' : 'calendar'
                        }
                        errors={errors}
                      >
                        <FormattedMessage
                          {...messages.accountUsageSportersEnddateLabel}
                        />
                      </FieldDatePicker>
                    </div>
                    <div className="o-layout__item o-flex o-flex--justify-start">
                      <Button type="submit" primary small>
                        <FormattedMessage
                          {...messages.accounUsageCountSporters}
                        />
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>

        {isNumber(numberOfAthletes) && (
          <Card
            secondary
            extraClassNames="c-card--remarks u-padding-vertical-medium"
          >
            <Icon id="sporters" />
            <div className="c-card__content u-margin-left-small">
              <CardBody secondary remarks>
                <MessageText extraClassNames="o-flex o-flex--wrap o-flex--align-center">
                  <FormattedMessage
                    {...messages.accountUsageAnswer}
                    values={{
                      startdate: getLocalDateFormat(
                        startDateRef?.current?.value,
                        'yyyy-LL-dd',
                        'dd MMMM yyyy',
                        { locale: DATE_LOCALE[baseLanguage] }
                      ),
                      enddate: getLocalDateFormat(
                        endDateRef?.current?.value,
                        'yyyy-LL-dd',
                        'dd MMMM yyyy',
                        { locale: DATE_LOCALE[baseLanguage] }
                      ),
                      activeUsers: numberOfAthletes,
                      b: chunks => (
                        <b style={{ display: 'inline-block', margin: '0 4px' }}>
                          {chunks}
                        </b>
                      ),
                      span: chunks => (
                        <Badge
                          large
                          extraClassNames="u-margin-horizontal-small u-bgcolor-primary u-color-neutral-xlight"
                        >
                          {chunks}
                        </Badge>
                      )
                    }}
                  />
                </MessageText>
              </CardBody>
            </div>
          </Card>
        )}
        {/* </Message> */}
      </PanelBody>
    </Panel>
  );
}

//
export default Usage;
