import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { useNotificationQueue } from 'components/notification';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import messages from 'messages.js';
import {
  MUTATION_ADD_TEMPLATE,
  MUTATION_EDIT_TEMPLATE,
  QUERY_GET_TEMPLATE,
  QUERY_GET_TEMPLATES
} from 'services/aws/templates-query';
import { cleanFalsy } from 'utils/object';
import { useExercisesContext } from 'contexts/ExercisesContext';
import { NoteTypes, TemplateType } from 'constants.js';
import {
  MUTATION_ADD_NOTE,
  MUTATION_EDIT_NOTE
} from 'services/aws/notes-query';
import { QUERY_GET_EXERCISE_NOTES } from 'services/aws/exercises-query';

const TrainingForm = ({
  entityId,
  training,
  children,
  extraClassNames,
  onComplete
}) => {
  const notification = useNotificationQueue();
  const intl = useIntl();

  const { exercisesState } = useExercisesContext();
  const [editTemplate] = useMutation(MUTATION_EDIT_TEMPLATE);
  const [addTemplate] = useMutation(MUTATION_ADD_TEMPLATE, {
    awaitRefetchQueries: true
  });
  const [addNote] = useMutation(MUTATION_ADD_NOTE);
  const [editNote] = useMutation(MUTATION_EDIT_NOTE);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage(messages.titleRequired)),
    note: Yup.string()
  });

  const onSubmitHandler = async training => {
    const trainingObj = cleanFalsy({
      entityId,
      title: training.title,
      exerciseIds: exercisesState.selectedExercises.map(e => e.id),
      tagIds: training.tags.map(tag => tag.id),
      duration: training.duration,
      type: training.type ?? TemplateType.TEMPLATE
    });

    if (!training.id) {
      await addTemplate({
        variables: {
          ...trainingObj
        },
        refetchQueries: [
          {
            query: QUERY_GET_TEMPLATES,
            variables: { entityId, type: training.type }
          }
        ]
      }).then(
        async ({ data }) => {
          await submitNote({
            ...training,
            trainingId: data.addExerciseProgramTemplate.id
          });
          if (onComplete) onComplete(data.addExerciseProgramTemplate.id);
        },
        () => {
          notification.add('edit_error', {
            message: intl.formatMessage(messages.messageAddTemplateSaveError),
            level: 'error'
          });
        }
      );
    } else {
      await submitNote(training);
      await editTemplate({
        variables: {
          id: training.id,
          ...trainingObj
        },
        refetchQueries: [
          {
            query: QUERY_GET_TEMPLATES,
            variables: { entityId, type: training.type }
          }
        ]
      }).then(
        ({ data }) => {
          onComplete && onComplete(data.editExerciseProgramTemplate.id);
        },
        () => {
          notification.add('edit_error', {
            message: intl.formatMessage(messages.messageAddTemplateSaveError),
            level: 'error'
          });
        }
      );
    }
  };

  const submitNote = training => {
    const refetchQueries = [
      {
        query: QUERY_GET_EXERCISE_NOTES,
        variables: { templateId: training.id }
      },
      {
        query: QUERY_GET_TEMPLATE,
        variables: { id: training.id, type: training.type }
      }
    ];

    if (training.trainingNote) {
      if (training.note === training.trainingNote) {
        return;
      }

      return editNote({
        variables: {
          id: training.trainingNote.id,
          linkId: training.id,
          note: training.note,
          noteType: NoteTypes.TEMPLATE
        },
        refetchQueries
      });
    } else {
      return addNote({
        variables: {
          linkId: training.id,
          note: training.note,
          noteType: NoteTypes.TEMPLATE
        },
        refetchQueries
      });
    }
  };

  return (
    <Formik
      initialValues={training}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmitHandler}
    >
      {props => {
        return (
          <Form
            className={classNames('c-card c-card--secondary', extraClassNames)}
            noValidate
          >
            {typeof children === 'function' ? children(props) : children}
          </Form>
        );
      }}
    </Formik>
  );
};

export default TrainingForm;
