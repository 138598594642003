import React, { useContext, useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import {
  Panel,
  PanelHeader,
  PanelSubtitle,
  PanelTitle,
  PanelTitleWrapper
} from 'components/panel';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import {
  ROUTE_EXERCISES_ENTITY,
  ROUTE_EXERCISES_ENTITY_EXERCISE
} from 'routes/RouteList';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { Tab, TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import { StoreContext } from 'index';
import Loader from 'components/loader/Loader';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import ExercisePanel from 'containers/pages/exercises/exercises/ExercisePanel';
import ExerciseLanguageButton from 'containers/pages/exercises/exercises/ExerciseLanguageButton';
import useExercise from 'hooks/queries/useExercise';
import useExerciseCopy from 'hooks/queries/useExerciseCopy';
import ExerciseCopyFormInput from 'containers/pages/exercises/exercises/ExerciseCopyFormInput';
import ExerciseCopyForm from 'containers/pages/exercises/exercises/ExerciseCopyForm';

function ExercisesLanguages({ entityId, exerciseId, lang }) {
  const {
    uiState: { locale }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const [activeLang, setActiveLang] = useState(lang ?? locale);

  const { exercise, loading, error, getExercise } = useExercise(
    entityId,
    exerciseId
  );
  const { exerciseCopy, setLanguage } = useExerciseCopy(
    entityId,
    exerciseId,
    exercise,
    lang
  );

  useEffect(() => {
    getExercise();
  }, []);

  useEffect(() => {
    setActiveLang(lang ?? locale);
  }, [lang, locale]);

  useEffect(() => {
    if (activeLang) {
      setLanguage(activeLang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLang]);

  if (loading)
    return (
      <Panel>
        <Loader />
      </Panel>
    );
  if (error) return <RetryPanel />;

  return (
    <Panel secondary extraClassNames="o-flex o-flex--column">
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb
            onClick={() =>
              push(generatePath(ROUTE_EXERCISES_ENTITY, { entityId }))
            }
          >
            <FormattedMessage {...messages.breadcrumbOverview} />
          </Breadcrumb>
          <Breadcrumb
            onClick={() =>
              push(generatePath(ROUTE_EXERCISES_ENTITY, { entityId }))
            }
          >
            <FormattedMessage {...messages.breadcrumbExercises} />
          </Breadcrumb>
          <Breadcrumb
            onClick={() =>
              push(
                generatePath(ROUTE_EXERCISES_ENTITY_EXERCISE, {
                  entityId,
                  exerciseId
                })
              )
            }
          >
            <FormattedMessage {...messages.breadcrumbExercise} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <span className="" title={exercise.title}>
              {exercise.title}
            </span>
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage {...messages.exercisesLabelLevelSubtitle} />{' '}
            {exercise.level}
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <Tabs fullWidth defaultIndex={0}>
        <TabList>
          <Tab key="exercise.global">
            <FormattedMessage {...messages.preventionTabGlobal} />
          </Tab>
        </TabList>
        <TabPanel key="exercise.global">
          <Panel>
            <ExercisePanel
              exercise={exercise}
              entityId={entityId}
              exerciseId={exerciseId}
              edit={true}
              readOnly={true}
            />
          </Panel>
          <Panel>
            <TabPanelBody>
              <ExerciseLanguageButton
                entityId={entityId}
                exerciseId={exerciseId}
                lang={activeLang}
                onLangSet={lang => setActiveLang(lang)}
              />

              <ExerciseCopyForm entityId={entityId} exerciseCopy={exerciseCopy}>
                {() => (
                  <ExerciseCopyFormInput
                    onCancel={() =>
                      push(
                        generatePath(ROUTE_EXERCISES_ENTITY_EXERCISE, {
                          entityId,
                          exerciseId
                        })
                      )
                    }
                    entityId={entityId}
                  />
                )}
              </ExerciseCopyForm>
            </TabPanelBody>
          </Panel>
        </TabPanel>
      </Tabs>
    </Panel>
  );
}

export default ExercisesLanguages;
