import React, { useContext } from 'react';
import {
  Redirect,
  Route,
  Switch,
  generatePath,
  withRouter
} from 'react-router-dom';
import {
  ROUTE_EXERCISES_ENTITY,
  ROUTE_EXERCISES_ENTITY_EXERCISE,
  ROUTE_EXERCISES_ENTITY_EXERCISE_EDIT,
  ROUTE_EXERCISES_INDEX
} from 'routes/RouteList';
import { ExercisesProvider } from 'contexts/ExercisesContext';
import Exercises from 'containers/pages/exercises/exercises/Exercises';
import Exercise from 'containers/pages/exercises/exercises/Exercise';
import { StoreContext } from 'index';
import { PreviewModalProvider } from 'contexts/PreviewModalContext';
import messages from 'messages';
import Panel from 'components/panel/Panel';
import { useIntl } from 'react-intl';
import { TemplatesProvider } from 'contexts/TemplatesContext';
import { TemplateType } from 'constants.js';

const GSVExercises = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();
  return (
    <>
      <TemplatesProvider
        entityId={user.rootEntityId}
        type={TemplateType.COLLECTION}
      >
        <PreviewModalProvider entityId={user.rootEntityId}>
          <Switch>
            <Route
              exact
              path={ROUTE_EXERCISES_ENTITY}
              render={props => (
                <ExercisesProvider
                  user={user}
                  entityId={props.match.params.entityId}
                >
                  <Exercises
                    entityId={props.match.params.entityId}
                    exerciseId={props.match.params.exerciseId}
                    active={ROUTE_EXERCISES_INDEX === props.match.path}
                    crumbs={[]}
                  />
                  <Panel
                    emptyState
                    emptyLabel={intl.formatMessage(
                      messages.exercisesEmptyPanel
                    )}
                  />
                </ExercisesProvider>
              )}
            />
            <Route
              exact
              path={[
                ROUTE_EXERCISES_ENTITY,
                ROUTE_EXERCISES_ENTITY_EXERCISE_EDIT
              ]}
              render={props => (
                <ExercisesProvider
                  user={user}
                  entityId={props.match.params.entityId}
                >
                  <Exercises
                    entityId={props.match.params.entityId}
                    exerciseId={props.match.params.exerciseId}
                    active={ROUTE_EXERCISES_ENTITY === props.match.path}
                    crumbs={[]}
                  />
                  <Exercise
                    exerciseId={props.match.params.exerciseId}
                    entityId={props.match.params.entityId}
                    active={ROUTE_EXERCISES_ENTITY === props.match.path}
                    edit={
                      ROUTE_EXERCISES_ENTITY_EXERCISE_EDIT === props.match.path
                    }
                    editable
                    parentPath={generatePath(
                      ROUTE_EXERCISES_ENTITY_EXERCISE_EDIT,
                      {
                        entityId: props.match.params.entityId,
                        exerciseId: props.match.params.exerciseId
                      }
                    )}
                    crumbs={[
                      {
                        path: generatePath(ROUTE_EXERCISES_ENTITY, {
                          entityId: props.match.params.entityId
                        }),
                        label: intl.formatMessage(messages.breadcrumbExercises)
                      },
                      {
                        path: generatePath(ROUTE_EXERCISES_ENTITY_EXERCISE, {
                          entityId: props.match.params.entityId,
                          exerciseId: props.match.params.exerciseId
                        }),
                        label: intl.formatMessage(messages.breadcrumbExercise)
                      }
                    ]}
                  />
                </ExercisesProvider>
              )}
            />
            {/*<Route
              exact
              path={ROUTE_EXERCISES_ENTITY_EXERCISE_ADD}
              render={props => (
                <ExercisesProvider
                  user={user}
                  entityId={props.match.params.entityId}
                >
                  <Exercises
                    entityId={props.match.params.entityId}
                    active={ROUTE_EXERCISES_ENTITY === props.match.path}
                    crumbs={[]}
                  />
                  <AddExercise
                    entityId={props.match.params.entityId}
                    active={
                      ROUTE_EXERCISES_ENTITY_EXERCISE_ADD === props.match.path
                    }
                  />
                </ExercisesProvider>
              )}
            />*/}
            <Route
              exact
              path={[
                ROUTE_EXERCISES_ENTITY_EXERCISE,
                ROUTE_EXERCISES_ENTITY_EXERCISE_EDIT
              ]}
              render={props => (
                <ExercisesProvider
                  user={user}
                  entityId={props.match.params.entityId}
                >
                  <Exercises
                    entityId={props.match.params.entityId}
                    exerciseId={props.match.params.exerciseId}
                    active={ROUTE_EXERCISES_ENTITY === props.match.path}
                    crumbs={[]}
                  />
                  <Exercise
                    exerciseId={props.match.params.exerciseId}
                    entityId={props.match.params.entityId}
                    active={
                      ROUTE_EXERCISES_ENTITY_EXERCISE === props.match.path
                    }
                    edit={
                      ROUTE_EXERCISES_ENTITY_EXERCISE_EDIT === props.match.path
                    }
                    editable
                    parentPath={generatePath(ROUTE_EXERCISES_ENTITY_EXERCISE, {
                      entityId: props.match.params.entityId,
                      exerciseId: props.match.params.exerciseId
                    })}
                    crumbs={[
                      {
                        path: generatePath(ROUTE_EXERCISES_ENTITY, {
                          entityId: props.match.params.entityId
                        }),
                        label: intl.formatMessage(messages.breadcrumbExercises)
                      },
                      {
                        path: generatePath(ROUTE_EXERCISES_ENTITY_EXERCISE, {
                          entityId: props.match.params.entityId,
                          exerciseId: props.match.params.exerciseId
                        }),
                        label: intl.formatMessage(messages.breadcrumbExercise)
                      }
                    ]}
                  />
                </ExercisesProvider>
              )}
            />
            <Redirect
              to={generatePath(ROUTE_EXERCISES_ENTITY, {
                entityId: user.rootEntityId
              })}
            />
          </Switch>
        </PreviewModalProvider>
      </TemplatesProvider>
    </>
  );
};

export default withRouter(GSVExercises);
