import React from 'react';
import { PanelHeader, PanelTitle } from 'components/panel';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import Panel from 'components/panel/Panel';
import PanelBody from 'components/panel/PanelBody';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import { CardBody } from 'components/card';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { exercises } from 'containers/pages/test/data';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper,
  ListItemStatus
} from 'components/list/list-item';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { ROUTE_TEST_ENTITY_EXERCISES_EXERCISE } from 'routes/RouteList';

function ExercisesList() {
  const { push } = useHistory();
  const { entityId, type } = useParams();

  return (
    <Panel id="panel">
      <PanelHeader>
        <PanelTitleWrapper>
          <PanelTitle>Available exercises</PanelTitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        <CardHeader secondary extraClassNames="u-margin-bottom-none">
          <CardHeaderText>
            <CardHeaderTitle>Exercise to Analyse</CardHeaderTitle>
          </CardHeaderText>
        </CardHeader>
        <CardBody secondary separatorBottom>
          <List>
            <ListBody>
              {exercises?.map(exercise => (
                <ListItem
                  key={exercise.id}
                  onClick={() => {
                    push(
                      generatePath(ROUTE_TEST_ENTITY_EXERCISES_EXERCISE, {
                        entityId,
                        exerciseId: exercise.id,
                        type
                      })
                    );
                  }}
                >
                  <ListItemStatus statusIcon="exercise"></ListItemStatus>
                  <ListItemLabelWrapper>
                    <ListItemLabel label>{exercise.name}</ListItemLabel>
                  </ListItemLabelWrapper>
                </ListItem>
              ))}
            </ListBody>
          </List>
        </CardBody>
      </PanelBody>
    </Panel>
  );
}

export default ExercisesList;
