import gql from 'graphql-tag';

export const QUERY_GET_SCANNERS_BY_ENTITY_ID = gql`
  query getMtsScanners($entityId: ID!) {
    getMtsScanners(entityId: $entityId) {
      id
      name
    }
  }
`;

export const QUERY_GET_SCANNER_BY_ID = gql`
  query getMtsScanner($scannerId: ID!, $entityId: ID!) {
    getMtsScanner(scannerId: $scannerId, entityId: $entityId) {
      id
      name
      type
      vendor
      coil
      location
      calibrations {
        id
        name
        date
        coil
        shim
        meta
      }
    }
  }
`;

export const QUERY_GET_CALIBRATION_BY_ID = gql`
  query getCalibrations($calibrationId: ID!, $entityId: ID!) {
    getMtsCalibration(calibrationId: $calibrationId, entityId: $entityId) {
      id
      name
      date
      shim
      coil
      meta
      scanner {
        id
        name
      }
    }
  }
`;

export const QUERY_GET_CALIBRATIONS_BY_SCANNER_ID = gql`
  query getCalibrations($scannerId: ID!, $entityId: ID!) {
    getMtsCalibrations(scannerId: $scannerId, entityId: $entityId) {
      id
      name
      date
      coil
      shim
      meta
    }
  }
`;

export const MUTATION_ADD_SCANNER = gql`
  mutation addMtsScanner(
    $entityId: ID!
    $name: String!
    $type: String!
    $vendor: String!
    $location: String
    $coil: String
  ) {
    addMtsScanner(
      input: {
        entityId: $entityId
        name: $name
        type: $type
        vendor: $vendor
        location: $location
        coil: $coil
      }
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_SCANNER = gql`
  mutation editMtsScanner(
    $id: ID!
    $name: String
    $type: String
    $vendor: String
    $coil: String
    $location: String
  ) {
    editMtsScanner(
      input: {
        id: $id
        name: $name
        type: $type
        vendor: $vendor
        location: $location
        coil: $coil
      }
    ) {
      id
    }
  }
`;

export const MUTATION_REMOVE_SCANNER = gql`
  mutation archiveMtsScanner($scannerId: ID!) {
    archiveMtsScanner(scannerId: $scannerId)
  }
`;

export const MUTATION_ADD_CALIBRATION_TO_SCANNER = gql`
  mutation addMtsCalibrationToScanner(
    $scannerId: ID!
    $entityId: ID!
    $name: String!
    $date: AWSDate!
    $shim: Float
    $coil: Float
    $meta: AWSJSON
  ) {
    addMtsCalibrationToScanner(
      input: {
        scannerId: $scannerId
        entityId: $entityId
        name: $name
        date: $date
        shim: $shim
        coil: $coil
        meta: $meta
      }
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_CALIBRATION = gql`
  mutation editMtsCalibration(
    $calibrationId: ID!
    $name: String
    $date: AWSDate
    $shim: Float
    $coil: Float
    $meta: AWSJSON
  ) {
    editMtsCalibration(
      input: {
        calibrationId: $calibrationId
        name: $name
        date: $date
        shim: $shim
        coil: $coil
        meta: $meta
      }
    ) {
      id
    }
  }
`;

export const MUTATION_REMOVE_CALIBRATION_FROM_SCANNER = gql`
  mutation archiveMtsCalibration($calibrationId: ID!) {
    archiveMtsCalibration(calibrationId: $calibrationId)
  }
`;
