import MessageText from 'components/message/MessageText';
import Message from 'components/message/Message';
import { ROUTE_VIDEOS_ENTITY_VIDEO } from 'routes/RouteList';
import ExercisesListVirtualized from 'containers/partials/lists/ExercisesListVirtualized';
import { Card, CardBody } from 'components/card';
import { generatePath, useHistory } from 'react-router-dom';
import Loader from 'components/loader/Loader';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { useVideosContext } from 'contexts/VideosContext';

const VideosSharedWithMe = ({ entityId, videoId }) => {
  const { push } = useHistory();
  const { videosState, loading } = useVideosContext();

  if (loading) return <Loader />;

  return (
    <>
      {videosState.sharedVideos.length > 0 ? (
        <ExercisesListVirtualized
          statusIcon="play"
          exercises={videosState.sharedVideos}
          activeId={videoId}
          enablePreviewModal={false}
          showSharesAndAnnotations={true}
          showOwner={true}
          onClick={id => {
            if (id !== videoId) {
              push(
                generatePath(ROUTE_VIDEOS_ENTITY_VIDEO, {
                  entityId,
                  videoId: id
                })
              );
            }
          }}
        />
      ) : (
        <Card empty secondary extraClassNames="u-margin-top-medium">
          <CardBody empty>
            <Message emptyState icon="video" iconFillColor="color-neutral-dark">
              <MessageText>
                <FormattedMessage {...messages.annotationEmptySharedWithMe} />
              </MessageText>
              <MessageText>
                <FormattedMessage
                  {...messages.annotationEmptySharedWithMeExtra}
                />
              </MessageText>
            </Message>
          </CardBody>
        </Card>
      )}
    </>
  );
};

VideosSharedWithMe.propTypes = {};

export default VideosSharedWithMe;
