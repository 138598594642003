import classNames from 'classnames';

const CardHeaderSubtitle = props => {
  const { children, extraClassNames } = props;

  return (
    <p className={classNames('c-card__header-subtitle', extraClassNames)}>
      {children}
    </p>
  );
};

export default CardHeaderSubtitle;
