import React from 'react';
// import TabBarList from '../tabBarList/TabBarList';

class CardMenu extends React.Component {
  render() {
    return (
      <div className="c-card__menu">
        {/* <TabBarList {...this.props}></TabBarList> */}
        {this.props.children}
      </div>
    );
  }
}

CardMenu.propTypes = {};

export default CardMenu;
