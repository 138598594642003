import React, { useContext } from 'react';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import { StoreContext } from 'index';
import ConfirmButton from 'containers/partials/buttons/ConfirmButton';
import {
  QUERY_GET_PERSONENTITIES_OF_PERSON,
  removePersonEntityMethod
} from 'services/aws/user-query';

const RemovePersonsFromGroupButton = ({
  ids,
  entityId,
  rootEntityId,
  children,
  onComplete,
  ...props
}) => {
  const { uiState } = useContext(StoreContext);
  const onConfirmRemovePerson = async ids => {
    if (Array.isArray(ids) && ids.length > 0) {
      uiState.increasePendingRequest();
      for (const id of ids) {
        await removePersonEntityMethod({ personId: id, entityId }, [
          {
            query: QUERY_GET_PERSONENTITIES_OF_PERSON,
            variables: { topLevelEntityId: rootEntityId, personId: id }
          }
        ]);
      }
      uiState.decreasePendingRequest();
      onComplete && onComplete();
    }
  };

  return (
    <ConfirmButton
      {...props}
      menu
      confirmTitle={messages.modalConfirmRemovePersonFromGroupTitle}
      confirmMessage={messages.modalConfirmRemovePersonFromGroupMessage}
      messageValues={{ count: ids.length }}
      onOk={() => onConfirmRemovePerson(ids)}
    >
      <Icon id="delete" strokeColor="color-neutral-dark" />
      {children}
    </ConfirmButton>
  );
};

export default RemovePersonsFromGroupButton;
