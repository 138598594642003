const DEFAULT_ROUND_DECIMALS = 2;

/**
 * Get rounded percentage
 * @param {Number} number - Value must be between 0 - 1
 * @returns {Number} percentage 0 - 100
 */
export const getRoundedPercentage = number => {
  return floor(number * 100, 0);
};

export const round = (num, decimals) => {
  decimals =
    typeof decimals !== 'undefined' ? decimals : DEFAULT_ROUND_DECIMALS;
  return Number(`${Math.round(`${num}e+${decimals}`)}e-${decimals}`);
};

export const floor = (num, decimals) => {
  decimals =
    typeof decimals !== 'undefined' ? decimals : DEFAULT_ROUND_DECIMALS;
  return Number(`${Math.floor(`${num}e+${decimals}`)}e-${decimals}`);
};

export const ceil = (num, decimals) => {
  decimals =
    typeof decimals !== 'undefined' ? decimals : DEFAULT_ROUND_DECIMALS;
  return Number(`${Math.ceil(`${num}e+${decimals}`)}e-${decimals}`);
};

export const isNumber = num => {
  return !isNaN(parseFloat(num)) && isFinite(num) && !Array.isArray(num);
};

export const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive
};

export const getRandomInt = (max = 100) => {
  return Math.floor(Math.random() * Math.round(max));
};
