import React, { Fragment, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import { Card, CardBody } from 'components/card';
import PanelBody from 'components/panel/PanelBody';
import Panel from 'components/panel/Panel';
import FieldInput from 'components/input/FieldInput';
import { useMutation } from '@apollo/client';
import {
  MUTATION_ADD_PERSONS_TO_PROGRAM,
  MUTATION_REMOVE_PERSONS_FROM_PROGRAM,
  QUERY_GET_PROGRAM
} from 'services/aws/programs-query';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import { FieldDatePicker } from 'components/input/FieldDatePicker';
import ProgramForm from 'containers/pages/exercises/programs/ProgramForm';
import SelectPersonsCard from 'containers/partials/cards/SelectPersonsCard';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import VirtualizedEntityList from 'containers/pages/sporters/VirtualizedEntityList';
import Person from 'models/Person';

const ProgramDetailPanel = ({ entityId, program, edit: initialEdit }) => {
  const [edit, setEdit] = useState(initialEdit);
  const intl = useIntl();
  const [programPersons, setProgramPersons] = useState([]);
  const [selectedPersons, setSelectedPersons] = useState([]);
  const [selectedProgramPersons, setSelectedProgramPersons] = useState([]);
  const [unSelectedPersons, setUnselectedPersons] = useState([]);

  const [assignPersons] = useMutation(MUTATION_ADD_PERSONS_TO_PROGRAM);
  const [removePersons] = useMutation(MUTATION_REMOVE_PERSONS_FROM_PROGRAM);

  useEffect(() => {
    if (program.personEntities.length > 0) {
      setProgramPersons(
        program.personEntities.map(pe => new Person(pe.person))
      );
      setSelectedProgramPersons(
        program.personEntities.map(pe => new Person(pe.person))
      );
    }
  }, [program]);

  const onSelectPersonsHandler = async persons => {
    setSelectedPersons([...persons]);
  };

  const onSelectProgramPersonsHandler = async (persons, person, checked) => {
    if (programPersons.find(p => p.id === person.id)) {
      if (checked) {
        setSelectedProgramPersons([...selectedProgramPersons, person]);
      } else {
        unSelectedPersons.push(person);
        setSelectedProgramPersons(
          selectedProgramPersons.filter(p => p.id !== person.id)
        );
      }
    } else {
      if (!checked) {
        setSelectedPersons(selectedPersons.filter(p => p.id !== person.id));
      }
    }
  };

  const onSavePersonsHandler = async persons => {
    if (program.id && persons.length > 0) {
      try {
        await assignPersons({
          variables: {
            exerciseProgramId: program.id,
            personIds: [
              ...programPersons.map(p => p.id),
              ...persons.map(person => person.id)
            ]
          },
          refetchQueries: [
            { query: QUERY_GET_PROGRAM, variables: { id: program.id } }
          ]
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    }

    if (program.id && unSelectedPersons.length > 0) {
      await removePersons({
        variables: {
          exerciseProgramId: program.id,
          personIds: unSelectedPersons.map(person => person.id)
        },
        refetchQueries: [
          { query: QUERY_GET_PROGRAM, variables: { id: program.id } }
        ]
      });
    }

    setSelectedPersons([]);
    setUnselectedPersons([]);
  };

  return (
    <Fragment>
      <Panel>
        <PanelBody>
          <Card secondary>
            <CardHeader secondary extraClassNames="u-margin-bottom-medium">
              <CardHeaderTitle>
                <FormattedMessage {...messages.programDetailSelectAthletes} />
              </CardHeaderTitle>
            </CardHeader>
            <CardBody secondary extraClassNames="u-padding-bottom-none">
              <SelectPersonsCard
                rootEntityId={entityId}
                onSelectPersons={persons => onSelectPersonsHandler(persons)}
                selectedPersons={selectedPersons}
                excludedPersons={programPersons}
                extraClassNamesForList="c-list--medium-negative-margin"
              />
            </CardBody>
          </Card>
        </PanelBody>
      </Panel>
      <Panel>
        <PanelBody>
          <Card secondary multiBody>
            <ProgramForm entityId={entityId} program={program}>
              {({ resetForm, submitForm, errors, touched, handleChange }) => (
                <CardHeader secondary extraClassNames="u-margin-bottom-none">
                  <CardHeaderText>
                    <CardHeaderTitle>
                      <FormattedMessage {...messages.programDetailTitle} />
                    </CardHeaderTitle>
                    <FormEditHeaderButtons
                      edit={edit}
                      setEdit={setEdit}
                      onCancel={resetForm}
                      onSubmit={submitForm}
                    />
                  </CardHeaderText>
                  <FieldInput
                    id="title"
                    name="title"
                    placeholder={intl.formatMessage(messages.programLabelName)}
                    readOnly={!edit}
                    errors={errors}
                    touched={touched}
                  >
                    <FormattedMessage {...messages.programLabelName} />
                  </FieldInput>
                  <div className="o-layout o-layout--small">
                    <div className="o-layout__item u-1-of-2">
                      <FieldDatePicker
                        id="startdate"
                        name="startdate"
                        icon="calendar"
                        placeholder={intl.formatMessage(
                          messages.programLabelStartDate
                        )}
                        readOnly={!edit}
                        disabled={!edit}
                        errors={errors}
                        touched={touched}
                        onChange={handleChange}
                        required
                      >
                        <FormattedMessage {...messages.programLabelStartDate} />
                      </FieldDatePicker>
                    </div>
                    <div className="o-layout__item u-1-of-2">
                      <FieldInput
                        id="duration"
                        name="duration"
                        placeholder={intl.formatMessage(
                          messages.programPlaceholderWeeks
                        )}
                        readOnly={!edit}
                        errors={errors}
                        touched={touched}
                      >
                        <FormattedMessage {...messages.programLabelWeeks} />
                      </FieldInput>
                    </div>
                  </div>
                </CardHeader>
              )}
            </ProgramForm>
            <CardBody
              secondary
              separatorBottom
              extraClassNames="u-padding-top-small"
            />

            <CardHeader secondary>
              <CardHeaderText>
                <CardHeaderTitle>
                  <FormattedMessage {...messages.programDetailAthletesTitle} />
                </CardHeaderTitle>
              </CardHeaderText>
            </CardHeader>

            <CardBody
              secondary
              extraClassNames="u-padding-top-small u-padding-bottom-none"
            >
              <VirtualizedEntityList
                variableHeight
                reCalculateHeight={
                  selectedPersons.length > 0 ||
                  unSelectedPersons.length > 0 ||
                  edit
                }
                entityId={entityId}
                items={[...programPersons, ...selectedPersons]}
                selectedItems={[...selectedProgramPersons, ...selectedPersons]}
                checkableGroups
                checkable
                onSelect={onSelectProgramPersonsHandler}
                emptyMessage={messages.programDetailAthletesEmpty}
                extraClassNames="c-list--medium-negative-margin"
              />
            </CardBody>

            <FormEditFooterButtons
              edit={selectedPersons.length > 0 || unSelectedPersons.length > 0}
              setEdit={setEdit}
              onCancel={() => {
                setSelectedPersons([]);
                setUnselectedPersons([]);
              }}
              onSubmit={() => onSavePersonsHandler(selectedPersons)}
              extraClassNames={'c-card__footer-bottom-fixed'}
            />
          </Card>
        </PanelBody>
      </Panel>
    </Fragment>
  );
};

export default ProgramDetailPanel;
