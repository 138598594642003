import React from 'react';
import { inject, observer } from 'mobx-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Card, CardBody, CardFooter } from 'components/card';
import { Button, ButtonsGroup } from 'components/button';
import { ROUTE_FORGOT } from 'routes/RouteList';
import messages from 'messages';
import { isEmailValid } from 'utils/email';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import InputErrorMessage from 'components/input/InputErrorMessage';
import FieldInput from 'components/input/FieldInput';

class Login extends React.Component {
  state = {
    email: '',
    password: '',
    emailError: false,
    passwordError: false,
    errorMessage: ''
  };

  async handleSubmit(e) {
    e.preventDefault();
    const { email, password } = this.state;
    if (email.length === 0 || password.length === 0) {
      this.setState({
        emailError: email.length === 0,
        passwordError: password.length === 0
      });
      return;
    }

    const { authStore } = this.props;
    const result = await authStore.doLogin(email, password);
    if (result?.error) {
      this.setState({
        emailError: true,
        passwordError: true,
        errorMessage: result.error.message
      });
    }
  }

  onForgotClick = e => {
    e.preventDefault();
    const {
      routing: { push }
    } = this.props;
    push(ROUTE_FORGOT);
  };

  render() {
    const { brand } = this.props;
    const { email, password, emailError, passwordError, errorMessage } =
      this.state;
    const { formatMessage } = this.props.intl;

    const title = formatMessage(messages.loginTitle);
    const message = formatMessage(messages[`loginMessage${brand}`]);

    const emailIcon = emailError
      ? 'validation-error'
      : email && isEmailValid(email)
      ? 'validation-check'
      : ' ';

    return (
      <Card extraClassNames="c-card--primary c-card--login">
        <form>
          <CardHeader primary>
            <img
              className="c-logo-img"
              src={
                require(`assets/images/logo-${brand.toLowerCase()}.svg`).default
              }
              alt={`${brand} logo`}
            />
            <CardHeaderTitle>{title}</CardHeaderTitle>
            <CardHeaderSubtitle>{message}</CardHeaderSubtitle>
          </CardHeader>
          <CardBody primary>
            <FieldInput
              id="email-id"
              type="email"
              icon={emailIcon}
              onBlur={() =>
                this.setState({
                  emailError: !email || !isEmailValid(email)
                })
              }
              onChange={e =>
                this.setState({
                  email: e.target.value,
                  emailError: false,
                  passwordError: false,
                  errorMessage: ''
                })
              }
              tabIndex="1"
            >
              <FormattedMessage {...messages.loginUsername} />
            </FieldInput>
            <FieldInput
              id="password-id"
              type="password"
              icon={passwordError ? 'validation-error' : ' '}
              onChange={e =>
                this.setState({
                  password: e.target.value.trim(),
                  passwordError: false,
                  errorMessage: ''
                })
              }
              tabIndex="1"
            >
              <Button
                tabIndex="0"
                miniDefaultText
                onClick={e => this.onForgotClick(e)}
              >
                <FormattedMessage {...messages.loginForgotPasswordButton} />
              </Button>
              <FormattedMessage {...messages.loginPassword} />
            </FieldInput>
            {errorMessage && (
              <InputErrorMessage>
                {errorMessage === 'api-error' ? (
                  <FormattedMessage {...messages.apiError} />
                ) : (
                  errorMessage
                )}
              </InputErrorMessage>
            )}
          </CardBody>
          <CardFooter primary>
            <ButtonsGroup>
              <Button
                primary
                type="submit"
                disabled={!email || !password || !isEmailValid(email)}
                onClick={e => this.handleSubmit(e)}
                tabIndex="1"
              >
                <FormattedMessage {...messages.loginSubmitButton} />
              </Button>
            </ButtonsGroup>
          </CardFooter>
        </form>
      </Card>
    );
  }
}

export default injectIntl(inject('authStore', 'routing')(observer(Login)));
