import { useState, useEffect, Fragment } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from 'components/table';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
  getExpandedRowModel
} from '@tanstack/react-table';
import messages from 'messages.js';
import { useIntl } from 'react-intl';
import Checkbox from 'components/input/Checkbox';
import Input from 'components/input/Input';
import { athleteImportErrors } from 'constants.js';
import Icon from 'components/icon/Icon';

const columnHelper = createColumnHelper();

// Give our default column cell renderer editing superpowers!
const DefaultColumn = ({ getValue, row: { index }, column: { id }, table }) => {
  const initialValue = getValue();
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  // When the input is blurred, we'll call our table meta's updateData function
  const onBlur = () => {
    table.options.meta?.updateData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Input
      type={'text'}
      value={value}
      onChange={e => setValue(e.target.value)}
      onBlur={onBlur}
    />
  );
};

function ImportAthletesReviewTable({ athletes, onEdit, onSelect, selected }) {
  const intl = useIntl();
  const [expanded, setExpanded] = useState({});
  const [rowSelection, setRowSelection] = useState({});

  const columns = [
    {
      id: 'select',
      header: () => '',
      cell: ({ row }) => {
        const errors = row.original.problems.filter(p => p.type === 'error');
        return (
          <>
            {errors.length === 0 && (
              <Checkbox
                id={row.id}
                checked={row.getIsSelected()}
                secondary
                onChange={e => {
                  const isSelected = e.target.checked;
                  const athleteRow = athletes[row.id];

                  row.toggleSelected(isSelected);

                  const selection = athletes.reduce((acc, athlete) => {
                    if (athlete.row === athleteRow.row) {
                      if (isSelected) {
                        acc.push(athlete);
                      }
                    } else {
                      if (selected.find(s => s.row === athlete.row)) {
                        acc.push(athlete);
                      }
                    }
                    return acc;
                  }, []);

                  onSelect && onSelect(selection);
                }}
              />
            )}
          </>
        );
      }
    },
    columnHelper.accessor('row', {
      header: intl.formatMessage(messages.tableHeaderRow),
      cell: info => info.getValue(),
      footer: info => info.column.id
    }),
    columnHelper.accessor('uid', {
      header: intl.formatMessage(messages.tableHeaderUID),
      footer: info => info.column.id
    }),
    columnHelper.accessor('firstname', {
      header: intl.formatMessage(messages.tableHeaderFirstname),
      footer: info => info.column.id
    }),
    columnHelper.accessor('lastname', {
      header: intl.formatMessage(messages.tableHeaderLastname),
      footer: info => info.column.id
    }),
    columnHelper.accessor('birthdate', {
      header: intl.formatMessage(messages.tableHeaderBirthdate),
      footer: info => info.column.id
    }),
    columnHelper.accessor('gender', {
      header: intl.formatMessage(messages.tableHeaderSex),
      footer: info => info.column.id
    }),
    columnHelper.accessor('email', {
      header: intl.formatMessage(messages.tableHeaderEmail),
      footer: info => info.column.id
    }),
    columnHelper.accessor('group', {
      header: intl.formatMessage(messages.tableHeaderGroup),
      footer: info => info.column.id
    })
    // columnHelper.accessor('problems', {
    //   header: intl.formatMessage(messages.tableHeaderProblems),
    //   cell: ({ row, getValue }) => {
    //     const problems = getValue();
    //     return (
    //       <div>
    //         {/*{row.getCanExpand() ? (
    //           <button
    //             {...{
    //               onClick: row.getToggleExpandedHandler(),
    //               style: { cursor: 'pointer' }
    //             }}
    //           >
    //             {row.getIsExpanded() ? '👇' : '👉'}
    //           </button>
    //         ) : (
    //           '🔵'
    //         )}*/}
    //         {problems.map((p, i) => (
    //           <div key={i}>
    //             <Icon
    //               id="alert"
    //               strokeColor={
    //                 p.type === 'error' ? 'color-error' : 'color-notice'
    //               }
    //             />
    //           </div>
    //         ))}
    //       </div>
    //     );
    //   },
    //   footer: info => info.column.id
    // }),
  ];

  const athletesTable = useReactTable({
    data: athletes,
    columns,
    state: {
      rowSelection,
      expanded
    },
    getRowCanExpand: () => true,
    defaultColumn: { cell: DefaultColumn },
    enableRowSelection: true,
    enableExpanding: true,
    onRowSelectionChange: setRowSelection,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    meta: {
      updateData: (rowIndex, columnId, value) => {
        onEdit(
          athletes.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...athletes[rowIndex],
                [columnId]: value
              };
            }
            return row;
          })
        );
      }
    }
  });

  useEffect(() => {
    if (selected.length > 0) {
      setRowSelection(
        selected.reduce((acc, athlete) => {
          const index = athletes.findIndex(a => a.row === athlete.row);
          acc[index] = true;
          return acc;
        }, {})
      );
    }
  }, [selected, athletes]);

  useEffect(() => {
    if (athletes.length > 0) {
      setExpanded(
        athletes.reduce((acc, athlete, i) => {
          acc[i] = athlete.problems.length > 0;
          return acc;
        }, {})
      );
    }
  }, [athletes]);

  return (
    <>
      <Table valueTable fixed extraClassNames="c-table--import">
        <TableHeader>
          {athletesTable.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <TableHeaderCell key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </TableHeaderCell>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {athletesTable.getRowModel().rows.map(row => {
            return (
              <Fragment key={row.id}>
                <TableRow
                  extraClassNames={{
                    'c-table__row--selected': row.getIsSelected(),
                    'c-table__row--error': row.original.problems.some(
                      problem => problem.type === 'error'
                    ),
                    'c-table__row--warning': row.original.problems.some(
                      problem => problem.type === 'warning'
                    )
                  }}
                >
                  <TableCell colSpan={row.getVisibleCells().length}>
                    <Table fixed editable>
                      <TableBody>
                        <TableRow>
                          {row.getVisibleCells().map(cell => (
                            <TableCell key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                        {row.getIsExpanded() && (
                          <TableRow>
                            {/* 2nd row is a custom 1 cell row */}
                            <TableCell colSpan={row.getVisibleCells().length}>
                              {ProblemsRow({ row, intl })}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}

const ProblemsRow = ({ row, intl }) => {
  const problems = row.original.problems;
  return (
    <div>
      {problems.map((p, i) => (
        <div key={i}>
          <Icon
            id="alert"
            strokeColor={p.type === 'error' ? 'color-error' : 'color-notice'}
          />
          {athleteImportErrors?.[p.problem]?.message &&
            intl.formatMessage(athleteImportErrors[p.problem].message)}
        </div>
      ))}
    </div>
  );
};

export default ImportAthletesReviewTable;
