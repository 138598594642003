import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import File from 'models/File';
import { QUERY_GET_FILE } from 'services/aws/file-upload';

const useFile = fileId => {
  const [file, setFile] = useState();
  const [fetchFile, { loading, error, data, refetch }] = useLazyQuery(
    QUERY_GET_FILE,
    {
      variables: { fileId },
      skip: !fileId
    }
  );

  const getFile = fileId => {
    fetchFile({ variables: { fileId } });
  };

  useEffect(() => {
    if (data?.getFile) {
      const videoData = data.getFile;
      setFile(
        new File({
          ...videoData
        })
      );
    }
  }, [data, fileId]);

  return { file, loading, error, refetch, getFile };
};

export default useFile;
