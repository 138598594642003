import React, { useContext } from 'react';
import messages from 'messages';
import { FormattedMessage, useIntl } from 'react-intl';
import { pollIsValidUrl } from 'utils/url';
import { openWindowWithLoader } from 'utils/browser';
import { MODAL_TYPES } from 'models/ModalData';
import { StoreContext } from 'index';
import { downloadSkReportSession } from 'services/aws/session-query';
import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';

const DownloadSkReportButtons = ({ session, testData = null }) => {
  const {
    uiState,
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();

  const downloadSkSession = async (session, testDataId, bnw) => {
    const filenameQuery = await downloadSkReportSession(
      session.id,
      testDataId,
      bnw
    );

    if (filenameQuery) {
      return filenameQuery.data.generateSkReport;
    }
  };

  const downloadReport = async newWindow => {
    const openWindow = newWindow;

    try {
      uiState.increasePendingRequest();
      const url = await downloadSkSession(session, testData.id, false);
      if (url) {
        const response = await pollIsValidUrl(url, 25, 1000);
        if (response) {
          openWindow.location = url;
          openWindow.focus();
        }
      }
    } catch (error) {
      // @ts-ignore
      uiState.showModal({
        title: intl.formatMessage(messages.titleSessionHanReportDownloadFailed),
        message: intl.formatMessage(
          messages.messageSessionHanReportDownloadFailed
        ),
        dismissButton: false,
        type: MODAL_TYPES.WARNING
      });
    }

    uiState.decreasePendingRequest();
  };

  if (testData.finishedPercentage < 100) return null;
  if (!user.isHylyghtOrg && (user.isHanOrg || session.isHan)) return null;
  if (
    (user.isHylyghtOrg &&
      session?.testSets[0]?.customReport !== 'han_ido' &&
      session?.testSets[0]?.customReport !== 'sk_ido') ||
    (!user.isHylyghtOrg && !(session.isIDo || session.isILike))
  ) {
    return null;
  }

  return (
    <Button
      menu
      onClick={() => {
        const newWindow = openWindowWithLoader();
        downloadReport(newWindow);
      }}
    >
      <Icon id="download" />
      <FormattedMessage {...messages.menuItemSessionDownloadHanReport} />
    </Button>
  );
};

export default DownloadSkReportButtons;
