import React, { useContext, useEffect, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import {
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_EDIT
} from 'routes/RouteList';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import Badge from 'components/badge/Badge';
import ReportCard from 'containers/partials/cards/ReportCard';
import EditTestResultCard from 'containers/partials/cards/EditTestResultCard';
import SporterCard from 'containers/partials/cards/SporterCard';
import { ExercisesProvider } from 'contexts/ExercisesContext';
import { PreviewModalProvider } from 'contexts/PreviewModalContext';
import TrainingAdvice from 'containers/pages/prevention/TrainingAdvice';
import { useRouteMatch } from 'react-router-dom';
import { StoreContext } from 'index';
import useTabIndex from 'hooks/utils/useTabIndex';
import { useSessionContext } from 'contexts/SessionContext';
import { TestDataProvider } from 'contexts/TestDataContext';
import TabLink from 'components/tabs/TabLink';

const SporterTabs = ({ entityId, sessionId, sporterId, programId, edit }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { session } = useSessionContext();
  const [reportTabEnabled, setReportTabEnabled] = useState(false);

  const isSessionView = useRouteMatch({
    path: ROUTE_SESSIONS_ENTITY_SESSION_SPORTER,
    strict: true
  });
  const isReportView = useRouteMatch(
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT
  );
  const isAdviceView = useRouteMatch(
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE
  );

  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
    [
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_EDIT
    ],
    [
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT
    ],
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE
  ]);

  useEffect(() => {
    const personTestData = session.getTestDataByPersonId(sporterId)?.[0];
    if (personTestData && personTestData.finishedPercentage > 0) {
      setReportTabEnabled(true);
    }

    if (
      (!personTestData ||
        personTestData.finishedPercentage === 0 ||
        !personTestData.finishedPercentage) &&
      (isReportView || isSessionView.isExact)
    ) {
      push(
        generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS, {
          entityId,
          sporterId,
          sessionId
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, pathname]);

  return (
    <Tabs
      selectedIndex={tabIndex}
      onSelect={index => setTabIndex(index)}
      fullWidth={isAdviceView}
    >
      <TabList>
        <TabLink
          key="session.sporter.report"
          to={generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT, {
            sessionId,
            entityId,
            sporterId
          })}
          disabled={!reportTabEnabled}
        >
          <FormattedMessage {...messages.tabSessionSporterReport} />
        </TabLink>
        <TabLink
          key="session.sporter.data"
          to={generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS, {
            sessionId,
            entityId,
            sporterId
          })}
        >
          <FormattedMessage {...messages.tabSessionSporterTests} />
        </TabLink>
        <TabLink
          key="session.sporter.global"
          to={generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL, {
            sessionId,
            entityId,
            sporterId
          })}
        >
          <FormattedMessage {...messages.tabSessionGlobal} />
        </TabLink>
        {isAdviceView && programId && (
          <TabLink
            key="session.sporter.advice"
            to={generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE, {
              sessionId,
              entityId,
              sporterId,
              programId
            })}
          >
            <Badge>
              <FormattedMessage {...messages.badgeSessionSporterAdvice} />
            </Badge>
            <FormattedMessage {...messages.tabSessionSporterAdvice} />
          </TabLink>
        )}
      </TabList>

      {/* Tab > REPORT */}
      <TabPanel key="session.sporter.report">
        <TabPanelBody withScroller>
          {reportTabEnabled && (
            <TestDataProvider
              entityId={entityId}
              session={session}
              sporterId={sporterId}
            >
              <ReportCard compressed />
            </TestDataProvider>
          )}
        </TabPanelBody>
      </TabPanel>
      {/* Tab > DATA */}

      <TabPanel key="session.sporter.data">
        <TabPanelBody withScroller>
          <TestDataProvider
            entityId={entityId}
            session={session}
            sporterId={sporterId}
          >
            <EditTestResultCard
              entityId={entityId}
              sporterId={sporterId}
              edit={edit}
              reportEnabled={reportTabEnabled}
              reportPath={generatePath(
                ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
                {
                  sessionId,
                  entityId,
                  sporterId
                }
              )}
            />
          </TestDataProvider>
        </TabPanelBody>
      </TabPanel>
      {/* Tab > GLOBAL */}
      <TabPanel key="session.sporter.global">
        <TabPanelBody withScroller>
          <SporterCard
            entityId={entityId}
            parentPath={generatePath(
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
              {
                sessionId,
                entityId,
                sporterId
              }
            )}
          />
        </TabPanelBody>
      </TabPanel>
      {/* Tab > ADVICE */}
      {isAdviceView && (
        <TabPanel key="session.sporter.advice">
          <ExercisesProvider user={user} entityId={entityId}>
            <PreviewModalProvider entityId={entityId}>
              <TrainingAdvice
                entityId={entityId}
                sessionId={sessionId}
                sporterId={sporterId}
                // testDataId={testData?.id}
              />
            </PreviewModalProvider>
          </ExercisesProvider>
        </TabPanel>
      )}
    </Tabs>
  );
};

export default SporterTabs;
