import React, { createContext, useContext, useState } from 'react';

export const EditableContext = createContext({});

const EditableContextProvider = ({ children }) => {
  const [isEditing, setIsEditing] = useState(undefined);

  return (
    <EditableContext.Provider value={{ isEditing, setIsEditing }}>
      {children}
    </EditableContext.Provider>
  );
};

function useEditableContext() {
  const context = useContext(EditableContext);
  if (context === undefined) {
    throw new Error(
      'The EditableContext hook must be used within a EditableContext.Provider'
    );
  }
  return context;
}

export { EditableContextProvider, useEditableContext };
