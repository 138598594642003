import React, { useContext, useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import {
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_EDIT
} from 'routes/RouteList';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import Badge from 'components/badge/Badge';
import ReportCard from 'containers/partials/cards/ReportCard';
import EditTestResultCard from 'containers/partials/cards/EditTestResultCard';
import { useRouteMatch } from 'react-router-dom';
import { StoreContext } from 'index';
import useTabIndex from 'hooks/utils/useTabIndex';
import { useSessionContext } from 'contexts/SessionContext';
import { TestDataProvider } from 'contexts/TestDataContext';
import TabLink from 'components/tabs/TabLink';

const Session = ({ entityId, testDataId, hasTestData, edit }) => {
  const {
    routing: { push }
  } = useContext(StoreContext);
  const { sessionId, sporterId, programId } = useParams();
  const { session } = useSessionContext();
  const [reportTabEnabled, setReportTabEnabled] = useState(false);
  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT,
    [
      ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS,
      ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_EDIT
    ]
  ]);
  const isAdviceView = useRouteMatch(
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE
  );

  useEffect(() => {
    const personTestData = session.getTestDataByPersonId(sporterId)?.[0];
    setReportTabEnabled(
      personTestData && personTestData.finishedPercentage > 0
    );

    if (!personTestData || personTestData.finishedPercentage === 0) {
      push(
        generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS, {
          entityId,
          sporterId,
          sessionId
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, testDataId, hasTestData, sporterId]);

  return (
    <Tabs
      selectedIndex={tabIndex}
      onSelect={index => setTabIndex(index)}
      fullWidth={isAdviceView}
    >
      <TabList>
        <TabLink
          key="session.sporter.report"
          to={generatePath(
            ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT,
            {
              sessionId,
              entityId,
              sporterId
            }
          )}
          disabled={!reportTabEnabled}
        >
          <FormattedMessage {...messages.tabSessionSporterReport} />
        </TabLink>
        <TabLink
          key="session.sporter.data"
          to={generatePath(
            ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS,
            {
              sessionId,
              entityId,
              sporterId
            }
          )}
        >
          <FormattedMessage {...messages.tabSessionSporterTests} />
        </TabLink>

        {isAdviceView && (
          <TabLink
            key="session.sporter.advice"
            to={generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE, {
              sessionId,
              entityId,
              sporterId,
              programId
            })}
          >
            <Badge>
              <FormattedMessage {...messages.badgeSessionSporterAdvice} />
            </Badge>
            <FormattedMessage {...messages.tabSessionSporterAdvice} />
          </TabLink>
        )}
      </TabList>

      {/* Tab > REPORT */}
      <TabPanel key="session.sporter.report">
        <TabPanelBody withScroller>
          <TestDataProvider
            entityId={entityId}
            session={session}
            sporterId={sporterId}
          >
            <ReportCard
              testDataId={testDataId}
              entityId={entityId}
              rootEntityId={entityId}
              compressed
            />
          </TestDataProvider>
        </TabPanelBody>
      </TabPanel>

      {/* Tab > DATA */}
      <TabPanel key="session.sporter.data">
        <TabPanelBody withScroller>
          <TestDataProvider
            entityId={entityId}
            session={session}
            sporterId={sporterId}
          >
            <EditTestResultCard
              entityId={entityId}
              sporterId={sporterId}
              testSet={session.testSet}
              edit={edit}
              reportEnabled={reportTabEnabled}
              reportPath={generatePath(
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT,
                {
                  sessionId,
                  entityId,
                  sporterId
                }
              )}
            />
          </TestDataProvider>
        </TabPanelBody>
      </TabPanel>
    </Tabs>
  );
};

export default Session;
