import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_SETTINGS } from 'routes/RouteList';
import { FormattedMessage } from 'react-intl';
import Panel from 'components/panel/Panel';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelSubtitle from 'components/panel/panel-title/PanelSubtitle';
import PanelHeader from 'components/panel/PanelHeader';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import PanelBody from 'components/panel/PanelBody';
import Card from 'components/card/Card';
import { QUERY_GET_ORGANISATION_BY_ID } from 'services/aws/entity-query';
import messages from 'messages';
import OrganisationFormInput from 'containers/pages/settings/organisations/OrganisationFormInput';
import OrganisationSettings from 'containers/pages/settings/organisations/OrganisationSettings';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import OrganisationForm from 'containers/pages/settings/organisations/OrganisationForm';
import { StoreContext } from 'index';
import OrganisationEntity from 'models/Organisation';
import { useQuery } from '@apollo/client';
import useEdit from 'hooks/utils/useEdit';

const GlobalSettings = ({ ...props }) => {
  const { push } = useHistory();
  const {
    authStore: {
      user,
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const [edit, setEdit] = useEdit(props.edit, true);
  const [organisation, setOrganisation] = useState(
    new OrganisationEntity({ baseLanguage: user.baseLanguage })
  );

  const { data } = useQuery(QUERY_GET_ORGANISATION_BY_ID, {
    variables: { id: rootEntityId },
    skip: !rootEntityId
  });

  useEffect(() => {
    if (data?.getEntity) {
      setOrganisation(new OrganisationEntity(data.getEntity));
    }
  }, [data]);
  return (
    <Panel>
      <PanelHeader borderBottom>
        <Breadcrumbs>
          <Breadcrumb onClick={() => push(ROUTE_SETTINGS)}>
            <FormattedMessage {...messages.breadcrumbSettings} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>{organisation.name}</PanelTitle>
          <PanelSubtitle>
            <FormattedMessage {...messages.subtitleGlobalSettings} />
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        <OrganisationForm
          organisationId={rootEntityId}
          organisation={organisation}
          onComplete={() => setEdit(false)}
          allowFeatureEdit={edit && user.superadmin}
        >
          {({ submitForm, isValid, isSubmitting, resetForm }) => (
            <Card secondary multiBody>
              <OrganisationFormInput organisation={organisation} edit={edit} />
              <OrganisationSettings
                edit={edit && user.superadmin}
                organisation={organisation}
              />
              <FormEditFooterButtons
                edit={edit}
                setEdit={setEdit}
                onCancel={() => {
                  resetForm();
                }}
                onSubmit={submitForm}
                disabledSubmit={!isValid || isSubmitting}
                topMargin
                extraClassNames={'c-card__footer-bottom-fixed'}
              />
            </Card>
          )}
        </OrganisationForm>
      </PanelBody>
    </Panel>
  );
};

export default GlobalSettings;
