import { IntlMessageFormat } from 'intl-messageformat';
import messages from 'messages';

export const renderUnit = unit => {
  return unit.toLowerCase() === 'min'
    ? new IntlMessageFormat(messages.labelUnit.defaultMessage).format({
        count: '',
        unit: 'mins'
      })
    : unit !== '#' && unit.toLowerCase().indexOf('none') === -1
    ? ` ${unit}`
    : ``;
};
export const renderOption = (testItem, value) => {
  if (!testItem?.input_type_parameters?.options) return '';

  const option = testItem.input_type_parameters.options.find(
    option => option.value === value
  );
  return option ? option.label : '';
};
