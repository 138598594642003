import { TableHeaderCell, TableRow, TableHeader } from 'components/table';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import { SORT_TYPES } from 'utils/sort';
import { useResultsPanelContext } from 'contexts/ResultsPanelContext';
import FieldSelect from 'components/input/FieldSelect';
import messages from 'messages.js';
import { useIntl } from 'react-intl';

const ResultsTableHeader = () => {
  const intl = useIntl();
  const {
    testItems,
    usersArray,
    testCols,
    onChangeCol,
    onChangeAthleteCol,
    onSort,
    onSortAthletes,
    sortIndex,
    athletesSortDirection
  } = useResultsPanelContext();

  return (
    <TableHeader>
      <TableRow>
        <TableHeaderCell extraClassNames={'c-table__content'}>
          <FieldSelect
            name={`athleteFilter`}
            placeholder={intl.formatMessage(
              messages.resultsTableHeaderSelectAthletes
            )}
            onChange={(option, action) => {
              onChangeAthleteCol(option, action);
            }}
            label={intl.formatMessage(
              messages.resultsTableHeaderFilterByAthlete
            )}
            options={usersArray.current.map(u => ({
              value: u.id,
              label: u.label
            }))}
            isClearable
            isMulti
            styles={{
              container: { width: '100%' },
              option: { textAlign: 'left' }
            }}
            customMultiValue
          />
          <Button
            tiny
            inactive={true}
            onClick={() => {
              onSortAthletes();
            }}
            disabled={false}
          >
            <Icon
              id={
                athletesSortDirection
                  ? `sort-${athletesSortDirection ? 'down' : 'up'}`
                  : 'sort'
              }
            />
          </Button>
        </TableHeaderCell>
        {testCols.map((col, i) => {
          const isSortIndex = i === sortIndex;
          return (
            <TableHeaderCell key={i}>
              <FieldSelect
                name={`test-set-${i}`}
                value={
                  (testItems.length &&
                    testItems.find(item => item.id === col?.id)) ??
                  ''
                }
                onChange={option => {
                  onChangeCol(i, option?.value ?? null);
                }}
                placeholder={
                  !testItems.length
                    ? intl.formatMessage(messages.resultsTableHeaderNoTestItems)
                    : intl.formatMessage(
                        messages.resultsTableHeaderSelectTestItem
                      )
                }
                disabled={!testItems.length}
                options={testItems}
                isClearable
                styles={{
                  container: { width: '100%' },
                  option: { textAlign: 'left' }
                }}
              />
              <Button
                tiny
                inactive={!isSortIndex}
                onClick={() => {
                  const newDirection = !isSortIndex
                    ? SORT_TYPES.DOWN
                    : col.sortDirection === SORT_TYPES.DOWN
                    ? SORT_TYPES.UP
                    : SORT_TYPES.DOWN;
                  onSort(i, newDirection);
                }}
                disabled={!col.id}
              >
                <Icon id={isSortIndex ? `sort-${col.sortDirection}` : 'sort'} />
              </Button>
            </TableHeaderCell>
          );
        })}
      </TableRow>
    </TableHeader>
  );
};

export default ResultsTableHeader;
