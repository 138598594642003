import React from 'react';
import {
  ListItem,
  ListItemStatus,
  ListItemLabelWrapper,
  ListItemLabel
} from 'components/list/list-item';
import { getLocalDateFormat } from 'utils/date';

export const CalibrationListItem = ({
  calibration: { name, date },
  active,
  onClick
}) => (
  <ListItem onClick={onClick} active={active}>
    <ListItemStatus statusIcon="calibrate" />
    <ListItemLabelWrapper>
      <ListItemLabel label>{name}</ListItemLabel>
      <ListItemLabel info>{getLocalDateFormat(date)}</ListItemLabel>
    </ListItemLabelWrapper>
  </ListItem>
);
