import React, { useState } from 'react';
import { useNotificationQueue } from 'components/notification';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import {
  MUTATION_ADD_NOTE,
  MUTATION_EDIT_NOTE
} from 'services/aws/notes-query';
import { NoteTypes } from 'constants.js';
import { QUERY_GET_EXERCISE_NOTES } from 'services/aws/exercises-query';
import messages from 'messages';
import Textarea from 'components/input/Textarea';
import { ButtonsGroup } from 'components/button';
import Button from 'components/button/Button';
import { QUERY_GET_PROGRAM } from 'services/aws/programs-query';
import { QUERY_GET_TEMPLATE } from 'services/aws/templates-query';

const EditableExerciseNote = ({
  exercise,
  exerciseGroup,
  noteType,
  onEdit,
  ...props
}) => {
  const notification = useNotificationQueue();
  const intl = useIntl();
  const [note, setNote] = useState(props.note?.note);

  const [addNote] = useMutation(MUTATION_ADD_NOTE);
  const [editNote] = useMutation(MUTATION_EDIT_NOTE);

  const onSaveNoteHandler = exercise => {
    const refetchQueries = [
      {
        query: QUERY_GET_EXERCISE_NOTES,
        variables:
          noteType === NoteTypes.PROGRAM
            ? { programId: exerciseGroup.id }
            : { templateId: exerciseGroup.id }
      }
    ];
    if (noteType === NoteTypes.PROGRAM) {
      refetchQueries.push({
        query: QUERY_GET_PROGRAM,
        variables: { id: exerciseGroup.id }
      });
    } else {
      refetchQueries.push({
        query: QUERY_GET_TEMPLATE,
        variables: { id: exerciseGroup.id }
      });
    }

    if (props.note?.id) {
      const existingNote = { ...props.note };

      if (note === existingNote.note) {
        onEdit(false);
        return;
      }

      editNote({
        variables: {
          id: existingNote.id,
          linkId: exerciseGroup.id,
          linkId2: exercise.id,
          note,
          noteType: noteType || NoteTypes.PROGRAM
        },
        refetchQueries
      }).then(({ data }) => {
        notification.add(data.editNote.id, {
          message: intl.formatMessage(messages.noteSaved)
        });
        onEdit(false);
      });
    } else {
      addNote({
        variables: {
          linkId: exerciseGroup.id,
          linkId2: exercise.id,
          note: note,
          noteType: noteType || NoteTypes.PROGRAM
        },
        refetchQueries
      }).then(({ data }) => {
        notification.add(data.addNote.id, {
          message: intl.formatMessage(messages.noteSaved)
        });
        onEdit(false);
      });
    }
  };

  return (
    <>
      <Textarea
        id={exercise.id}
        rows="2"
        value={note}
        resizeVertical
        onChange={e => setNote(e.target.value)}
      />
      <ButtonsGroup>
        <Button tiny onClick={() => onEdit(false)}>
          <FormattedMessage {...messages.buttonCancel} />
        </Button>
        <Button tiny onClick={() => onSaveNoteHandler(exercise)}>
          <FormattedMessage {...messages.buttonSubmit} />
        </Button>
      </ButtonsGroup>
    </>
  );
};

export default EditableExerciseNote;
