import React from 'react';

class SelectableList extends React.Component {
  onChange = (e, id, data) => {
    if (this.props.disabled) return;

    let selectedItems = [...(this.props.selectedIds || [])];

    if (data) {
      if (!selectedItems.find(si => si.id === id)) {
        selectedItems.push(data);
      } else {
        selectedItems = selectedItems.filter(e => e.id !== id);
      }
    } else {
      if (!selectedItems.includes(id)) {
        selectedItems.push(id);
      } else {
        selectedItems = selectedItems.filter(e => e !== id);
      }
    }

    const { onChange } = this.props;
    if (onChange) onChange(id, selectedItems);
  };

  onClick = (e, id, childId) => {
    const { onClick, disabled } = this.props;
    if (disabled) return;
    if (onClick) onClick(id, childId);
  };

  render() {
    return (
      <React.Fragment>
        {renderChildren({
          children: this.props.children,
          selectedItems: this.props.selectedIds || [],
          onClick: this.onClick,
          onChange: this.onChange,
          alwaysVisible: this.props.alwaysVisible,
          disabled: this.props.disabled
        })}
      </React.Fragment>
    );
  }
}

export default SelectableList;

const renderChildren = ({
  children,
  selectedItems,
  onClick,
  onChange,
  alwaysVisible,
  disabled
}) => {
  return React.Children.map(children, child => {
    if (child) {
      let checked;
      if (child.props.data) {
        checked = selectedItems.find(si => si.id === child.props.id)
          ? true
          : false;
      } else {
        checked = selectedItems.includes(child.props.id);
      }

      return React.cloneElement(child, {
        onClick: e =>
          onClick(e, child.props.id, child.props.childId, child.props.data),
        onChange: e => onChange(e, child.props.id, child.props.data),
        /*
        TODO refactor props are overwritten
        checked: child.props.checked ? child.props.checked : checked,
        visible: child.props.visible
          ? child.props.visible
          : selectedItems.length > 0 || alwaysVisible,*/
        checked,
        visible: selectedItems.length > 0 || alwaysVisible,
        disabled
      });
    }
  });
};
