import { useState, useContext } from 'react';
import { observer } from 'mobx-react';
import { withRouter, generatePath, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import Panel from 'components/panel/Panel';
import PanelHeader from 'components/panel/PanelHeader';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import {
  ROUTE_SESSIONS,
  ROUTE_SESSIONS_ENTITY_SESSION
} from 'routes/RouteList';
import PanelBody from 'components/panel/PanelBody';
import { TestSetV2Type } from 'constants.js';
import {
  PanelSubtitle,
  PanelTitleWrapper,
  PanelTitle
} from 'components/panel/panel-title';
import { StoreContext } from 'index';
import SessionForm from 'containers/pages/prevention/SessionForm';
import Session from 'models/Session';
import SessionFormInput from 'containers/pages/sessions/SessionFormInput';
import { Card } from 'components/card';

const AddSession = ({ entityId, active }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);

  const { push } = useHistory();
  const [edit, setEdit] = useState(true);
  const [session] = useState(
    new Session({ type: TestSetV2Type.TALENT, language: user.getLanguage() })
  );

  // Disabled for now, looks like it's not needed
  /*useEffect(() => {
    if (user?.testSets) {
      setTestSetId(user.testSets[0].id);
    }
  }, [user]);*/

  const onCompleteHandler = res => {
    if (res.data.addTestSession && res.data.addTestSession.id) {
      push(
        generatePath(ROUTE_SESSIONS_ENTITY_SESSION, {
          entityId,
          sessionId: res.data.addTestSession.id
        })
      );
    }
  };

  return (
    <Panel active={active}>
      <PanelHeader borderBottom>
        <Breadcrumbs>
          <Breadcrumb onClick={() => push(ROUTE_SESSIONS)}>
            <FormattedMessage {...messages.breadcrumbSessions} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            {session.name || (
              <FormattedMessage {...messages.titleSessionsNewSession} />
            )}
          </PanelTitle>
          <PanelSubtitle />
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        <Card secondary>
          <SessionForm
            entityId={entityId}
            session={session}
            onComplete={res => onCompleteHandler(res)}
          >
            {() => (
              <SessionFormInput
                entityId={entityId}
                edit={edit}
                setEdit={setEdit}
              />
            )}
          </SessionForm>
        </Card>
      </PanelBody>
    </Panel>
  );
};

export default withRouter(observer(AddSession));
