import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './ProgressBar.scss';
import { ProgressBarValueTypes } from 'constants.js';

const ProgressBar = props => {
  const {
    value,
    children,
    extraClassNames,
    secondary,
    benchmarks,
    benchmarksWithFrame,
    unavailable,
    range,
    visibleValue,
    growthPrediction,
    valueAtEnd,
    showPercentage
  } = props;

  return (
    <div
      className={classNames('c-progress-bar', extraClassNames, {
        'c-progress-bar--secondary': secondary,
        'c-progress-bar--benchmark': benchmarks,
        'c-progress-bar--benchmark c-progress-bar--framed': benchmarksWithFrame,
        'c-progress-bar--unavailable': unavailable,
        'c-progress-bar--value-fixed': visibleValue,
        'c-progress-bar--growth': growthPrediction,
        'c-progress-bar--without-percent': !showPercentage
      })}
    >
      {(children || value === 0) && (
        <span
          className={classNames('c-progress-bar__label', {
            'c-progress-bar__label-hidden': !showPercentage
          })}
          style={valueAtEnd && { left: `${value}%` }}
        >
          {children}
        </span>
      )}

      {range ? (
        <div
          className={classNames('c-progress-bar__bar c-progress-bar--' + range)}
          style={{ width: `${value === 0 ? '.5' : value}%` }}
        />
      ) : (
        <div
          className={classNames('c-progress-bar__bar')}
          style={{ width: `${value === 0 ? '.5' : value}%` }}
        />
      )}
    </div>
  );
};

ProgressBar.propTypes = {
  range: PropTypes.oneOf([
    ProgressBarValueTypes.RED,
    ProgressBarValueTypes.ORANGE,
    ProgressBarValueTypes.YELLOW,
    ProgressBarValueTypes.LIME,
    ProgressBarValueTypes.GREEN,
    ProgressBarValueTypes.AVERAGE
  ])
};

export default ProgressBar;
