import { getBenchmarkResultLabel } from './benchmark';

export const getTotalBestScoreString = item => {
  let score = item.calculation
    ? getTotalBestScore(
        item.calculation.type,
        item.calculation.best_value,
        item.values
      )
    : null;
  if (score !== null) {
    return getBenchmarkResultLabel(score, item.unit);
  }
  return '';
};

export const getTotalBestScoreStringFromTestItem = testitem => {
  let score = testitem.calculation
    ? getTotalBestScore(
        testitem.calculation,
        testitem.best_value,
        testitem.values
      )
    : null;
  if (score !== null && testitem.input_type !== 'select_with_options') {
    return getBenchmarkResultLabel(score, testitem.unit);
  } else if (score !== null && testitem.input_type === 'select_with_options') {
    return score;
  }
  return '';
};

export const getTotalBestScore = (type, best_value, values) => {
  let score;
  if (
    values &&
    values.length &&
    !values.some(v => v === null || v === undefined)
  ) {
    switch (type) {
      case 'best_performance':
      case 'Best Performance':
        score = best_value === 1 ? Math.max(...values) : Math.min(...values);
        break;
      case 'sum':
      case 'Sum':
        score = values.reduce((partial_sum, a) => partial_sum + a, 0);
        break;
      default:
        score = values[0];
    }
    return score;
  }
  return null;
};
