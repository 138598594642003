import { FormattedMessage, useIntl } from 'react-intl';
import { getLocalDateFormat } from 'utils/date';
import CardBody from 'components/card/CardBody';
import Card from 'components/card/Card';
import CardFooter from 'components/card/CardFooter';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import ResultList from 'components/list/ResultList';
import messages from 'messages';
import { useSessionContext } from 'contexts/SessionContext';
import { useTestDataContext } from 'contexts/TestDataContext';

const SapReportCard = ({ sporter }) => {
  const intl = useIntl();
  const { session } = useSessionContext();
  const { testData } = useTestDataContext();

  return (
    <Card reports>
      <CardHeader secondary>
        <CardHeaderText>
          <CardHeaderTitle>{session.name}</CardHeaderTitle>
          <CardHeaderSubtitle>
            <FormattedMessage
              {...messages.subtitleSapSession}
              values={{
                person: `${sporter.firstname} ${sporter.lastname}`
              }}
            />
          </CardHeaderSubtitle>
          <CardHeaderSubtitle>
            {testData && getLocalDateFormat(testData.date_created)}
          </CardHeaderSubtitle>
        </CardHeaderText>
      </CardHeader>
      <CardBody secondary>
        {testData?.result?.tests && (
          <ResultList
            defaultVisibleItems={10}
            items={testData.result.tests}
            labelShowMore={intl.formatMessage(messages.cardButtonShowAll, {
              count: testData.result.tests.length
            })}
            labelShowLess={intl.formatMessage(messages.cardButtonShowLess)}
          />
        )}
      </CardBody>
      <CardFooter />
    </Card>
  );
};

export default SapReportCard;
