import {
  add,
  format,
  formatDistanceToNow,
  isFuture,
  isPast,
  parse
} from 'date-fns';
import differenceInDays from 'date-fns/differenceInDays';
import getUnixTime from 'date-fns/getUnixTime';
import messages from 'messages';
import { DATE_LOCALE } from 'constants.js';

export const parsePrograms = (programs, intl, locale) => {
  return programs.map(program => {
    const startDate = parse(program.startdate, 'yyyy-LL-dd', new Date());
    const endDate = add(startDate, { weeks: program.duration });
    const daysLeft = differenceInDays(endDate, new Date());
    let sortDate = getUnixTime(endDate);
    let order = 1;
    let status = '';
    let dateString = '';
    if (isFuture(startDate)) {
      order = 2;
      dateString = `${format(startDate, 'dd/LL/yyyy')} > ${format(
        endDate,
        'dd/LL/yyyy'
      )}`;
      sortDate = getUnixTime(startDate);
    }
    if (isPast(startDate) && isFuture(endDate)) {
      if (daysLeft <= 14) {
        order = 0;
        status = 'inProgress';
      }
      dateString = intl.formatMessage(
        messages.exercisesProgramStatusDateInProgress,
        {
          time: formatDistanceToNow(endDate, {
            locale: DATE_LOCALE[locale]
          })
        }
      );
    }
    if (isPast(endDate)) {
      order = 3;
      status = 'done';
      dateString = intl.formatMessage(messages.exercisesProgramStatusDateEnded);
    }

    return { ...program, status, order, dateString, daysLeft, sortDate };
  });
};
