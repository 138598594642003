import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ErrorMessage, useFormikContext } from 'formik';
import messages from 'messages';
import useTags from 'hooks/queries/useTags';
import { uid, useUIDSeed } from 'react-uid';
import Radiobutton from 'components/input/Radiobutton';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { CardBody } from 'components/card';
import { mmssToSeconds, secondsToMMSS } from 'utils/time';
import ToggletipPopper from 'components/tooltip/ToggletipPopper';
import { TogglerContextProvider } from 'contexts/TogglerContext';
import { FieldTextarea } from 'components/input/FieldTextarea';
import TagsSelect from 'components/input/TagsSelect';
import Loader from 'components/loader/Loader';
import InputErrorMessage from 'components/input/InputErrorMessage';
import FieldInput from 'components/input/FieldInput';
import { sanitize } from 'dompurify';

AnnotationInput.propTypes = {};

function AnnotationInput({ entityId, seekingTime, edit, setEdit }) {
  const intl = useIntl();
  const uidSeed = useUIDSeed();
  const {
    values: annotation,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    handleReset,
    isSubmitting,
    validateField
  } = useFormikContext();
  const [startTime, setStartTime] = useState(
    annotation?.starttime ? secondsToMMSS(annotation.starttime) : '00:00'
  );
  const { tags } = useTags({ entityId });
  const [selectedTag, setSelectedTag] = useState();

  useEffect(() => {
    if (seekingTime) {
      setFieldValue('starttime', Math.floor(seekingTime));
      setFieldValue('endtime', Math.floor(seekingTime));
      setStartTime(secondsToMMSS(Math.floor(seekingTime)));
    }
  }, [seekingTime]);

  useEffect(() => {
    if (tags.length > 0 && annotation.tags.length > 0) {
      setSelectedTag(tags.find(t => t.id === annotation.tags[0]));
    }
  }, [tags, annotation]);

  const onTagSelect = tag => {
    setSelectedTag(tag);
    setFieldValue('tags', [tag.id]);
  };

  const onTagCheck = (e, tag) => {
    const annotationTags = [...annotation.tags];
    annotationTags[1] = tag.id;
    setFieldValue('tags', annotationTags);
  };

  const onChangeStartTime = value => {
    validateField('starttime');
    const seconds = mmssToSeconds(value);
    setStartTime(value);
    setFieldValue('starttime', seconds);
    setFieldValue('endtime', seconds);
  };

  return (
    <>
      {isSubmitting && <Loader />}
      <CardHeader secondary>
        <div className="o-flex o-flex--justify-space-between u-margin-bottom-medium">
          <CardHeaderTitle>
            <FormattedMessage {...messages.annotationFormTitle} />
          </CardHeaderTitle>
          <FormEditHeaderButtons
            edit={edit}
            setEdit={setEdit}
            type={'button'}
            onSubmit={handleSubmit}
            onCancel={handleReset}
            disabledSubmit={isSubmitting}
          />
        </div>
      </CardHeader>
      <CardBody secondary>
        <div className="o-layout u-margin-bottom-none">
          <div className="o-layout__item">
            <div className="o-layout o-layout--small">
              <div className="o-layout__item u-1-of-2">
                <FieldInput
                  id="starttime"
                  name="starttime"
                  value={startTime}
                  icon="calendar"
                  errors={errors}
                  touched={touched}
                  readOnly={!edit}
                  disabled={!edit}
                  pattern={'[0-9]{2}:[0-9]{2}:[0-9]{2}:[0-9]{2}'}
                  title="Write a duration in the format hh:mm:ss:ms"
                  onChange={e => onChangeStartTime(e.target.value)}
                  useNative
                >
                  <FormattedMessage {...messages.labelAnnotationStartTime} />
                </FieldInput>
              </div>
              {/*
              // TODO: Zolang dit niet werkt, tonen we het niet
              <div className="o-layout__item u-1-of-2">
                <FieldInput
                  id="endtime"
                  value={startTime}
                  icon="calendar"
                  errors={errors}
                  touched={touched}
                  readOnly={!edit}
                  disabled={!edit}
                  pattern={'[0-9]{2}:[0-9]{2}:[0-9]{2}:[0-9]{2}'}
                  //   title="Write a duration in the format hh:mm:ss:ms"
                >
                  <FormattedMessage {...messages.labelAnnotationEndTime} /> *
                </FieldInput>
              </div> */}
            </div>

            <fieldset className="c-input-group o-flex--justify-start">
              <legend className="c-input__label">
                <FormattedMessage {...messages.labelAnnotationScore} />
              </legend>
              {[1, 2, 3, 4].map(score => (
                <Radiobutton
                  secondary
                  key={uid(score)}
                  id={score}
                  name={'score'}
                  checked={annotation?.score === score}
                  onChange={e => setFieldValue('score', score)}
                >
                  {score}
                </Radiobutton>
              ))}

              {errors?.score && typeof errors.score === 'string' && (
                <ErrorMessage
                  name={'score'}
                  render={msg => (
                    <div className="u-1-of-1 u-margin-bottom-small">
                      <InputErrorMessage>{msg}</InputErrorMessage>
                    </div>
                  )}
                />
              )}
            </fieldset>

            <TagsSelect
              label={intl.formatMessage(messages.labelAnnotationTagsDimension)}
              options={tags}
              //   isClearable
              value={tags.filter(t => t.id === annotation.tags[0])}
              onChange={(value, actions) => onTagSelect(value, actions)}
            />

            {selectedTag && (
              <fieldset className="c-input-group">
                <legend className="c-input__label">
                  <FormattedMessage {...messages.labelAnnotationTagsBehavior} />
                </legend>
                <TogglerContextProvider>
                  {selectedTag.children.map((tag, index) => (
                    <div
                      className="o-flex o-flex--align-center o-flex--justify-start u-1-of-1"
                      key={tag.id}
                    >
                      <Radiobutton
                        extraClassNames="u-margin-right-small"
                        secondary
                        id={uidSeed(tag.id)}
                        name={selectedTag.id}
                        checked={tag.id === annotation.tags[1]}
                        onChange={e => onTagCheck(e, tag)}
                      >
                        {tag.label.replace(/^\d+\.\s/, '')}
                      </Radiobutton>

                      {tag.description && (
                        <ToggletipPopper
                          extraClassNames="u-margin-left-auto"
                          id={`toggletip-${index}`}
                          icon="info"
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: sanitize(tag.description)
                            }}
                          ></span>
                        </ToggletipPopper>
                      )}
                    </div>
                  ))}
                </TogglerContextProvider>
              </fieldset>
            )}

            {errors?.tags && typeof errors.tags === 'string' && (
              <ErrorMessage name={'tags'} component={InputErrorMessage} />
            )}

            <FieldTextarea
              id="note"
              name="note"
              readOnly={!edit}
              errors={errors}
              touched={touched}
              resizeVertical={edit}
              characterLimit={1500}
            >
              <FormattedMessage {...messages.labelAnnotationText} />
            </FieldTextarea>
          </div>
        </div>
      </CardBody>
      <FormEditFooterButtons
        edit={edit}
        setEdit={setEdit}
        onSubmit={handleSubmit}
        onCancel={handleReset}
        paddingBottomMedium
        disabledSubmit={isSubmitting}
      />
    </>
  );
}

export default AnnotationInput;
