import { Fragment, useContext, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Dropzone from 'react-dropzone';
import {
  uploadFile,
  FILE_ACTION,
  getFileUrl,
  deleteFileMutation
} from 'services/aws/file-upload';
import { Button } from 'components/button';
import { navigateToUrl } from 'utils/browser';
import Icon from 'components/icon/Icon';
import classNames from 'classnames';
import { DROP_STATE } from 'constants.js';
import messages from 'messages';
import { BigPlayButton, Player } from 'video-react';
import guid from 'utils/guid';
import { StoreContext } from 'index';

export const FILE_UPLOAD_ACCEPT = Object.freeze({
  ALL: { label: '' },
  VIDEO: { value: 'video/*,video/mp4,video/mov', label: 'video' },
  IMAGE: { value: 'image/*', label: 'image' },
  DICOM: { value: 'application/dicom', label: 'dicom' },
  SDAT: { value: 'application/sdat', label: 'sdat' },
  PDF: { value: 'application/x-pdf,application/pdf', label: 'pdf' }
});

export const FILE_ICONS = Object.freeze({
  dcm: 'dicom.png',
  dicom: 'dicom.png'
});

const FileUpload = ({
  entityId,
  linkId,
  type,
  onFileUploaded,
  onFileDeleted,
  attachedFile,
  isPublic,
  accept,
  multi,
  label,
  url,
  formImage,
  formVideo,
  loading
}) => {
  const { uiState } = useContext(StoreContext);
  const intl = useIntl();
  const [acceptedState, setAcceptedState] = useState(false);
  const [isDeleting, setIsDeleting] = useState();
  const [isDisabled, setIsDisabled] = useState();

  useEffect(() => {
    setIsDisabled(
      acceptedState || (multi && !!attachedFile) || loading || isDeleting
    );
  }, [isDeleting, acceptedState, multi, attachedFile, isDeleting]);

  const onDownloadHandler = async () => {
    const response = await getFileUrl({ entityId, type });
    if (response && response.data && response.data.getFileDownloadLink)
      navigateToUrl(response.data.getFileDownloadLink);
  };

  const onDrop = async acceptedFiles => {
    if (type && acceptedFiles.length !== 0) {
      setAcceptedState(true);
      const uploadLinkId = linkId ?? guid();
      for (const acceptedFile of acceptedFiles) {
        const response = await uploadFile({
          entityId,
          file: acceptedFile,
          linkId: uploadLinkId,
          type,
          action: attachedFile ? FILE_ACTION.REPLACE : FILE_ACTION.ADD,
          isPublic: isPublic ? true : undefined,
          sharedWithAthlete: false
        });
        if (onFileUploaded) onFileUploaded(uploadLinkId, type, response);
      }
      setAcceptedState(false);
    }
  };

  const onDeleteHandler = async () => {
    uiState.showModal({
      title: intl.formatMessage(messages.modalConfirmRemoveFileTitle),
      message: intl.formatMessage(messages.modalConfirmRemoveFileMessage, {
        file: attachedFile.filename
      }),
      okLabel: intl.formatMessage(messages.modalConfirmRemoveFileAcceptButton),
      dismissLabel: intl.formatMessage(
        messages.modalConfirmRemoveFileDismissButton
      ),
      okHandler: async () => {
        setIsDeleting(true);
        await deleteFileMutation({ fileId: attachedFile.id, entityId });
        setIsDeleting(false);
        if (onFileDeleted) onFileDeleted(attachedFile.id);
      }
    });
  };

  return (
    <div
      className={classNames('c-file-upload', {
        'c-file-upload--uploaded': !!attachedFile && multi
      })}
      title={attachedFile ? attachedFile.filename : ''}
    >
      {!multi && (
        <label htmlFor={type} className={classNames('c-input__label', {})}>
          {label || type}
        </label>
      )}
      <Dropzone
        className={classNames('c-file-upload__dropzone', {
          'c-file-upload__list-item': !!(attachedFile && multi),
          'c-file-upload--disabled': isDisabled
        })}
        inputProps={{ id: type }}
        accept={accept.value}
        onDrop={onDrop}
        multiple={!!multi}
        disabled={isDisabled}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
          let dropState = DROP_STATE.DEFAULT;

          if (isDragActive) {
            dropState = DROP_STATE.DRAGGING;
          }
          if (isDragReject) {
            dropState = DROP_STATE.REJECT;
          }
          if (acceptedState) {
            dropState = DROP_STATE.UPLOADING;
          }

          if (dropState === DROP_STATE.DEFAULT && attachedFile && multi) {
            return (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="c-file-upload__uploaded-info">
                  <p className="c-file-upload__filename">
                    {attachedFile.filename}
                  </p>
                </div>
                <div className="c-file-upload__attached-actions">
                  <Button
                    type="button"
                    circular
                    primary
                    onClick={() => onDownloadHandler()}
                  >
                    <Icon id="download-white" />
                  </Button>
                  <Button
                    type="button"
                    circular
                    grey
                    onClick={() => onDeleteHandler()}
                  >
                    <Icon id="delete" strokeColor="color-neutral-dark" />
                  </Button>
                </div>
              </div>
            );
          }
          return attachedFile ? (
            <div
              className={classNames('c-file-upload--state', {
                'c-file-upload--rejected': dropState === DROP_STATE.REJECT,
                'c-file-upload--dragging': dropState === DROP_STATE.DRAGGING,
                'c-file-upload--uploading': dropState === DROP_STATE.UPLOADING,
                'c-file-upload--state-uploaded': attachedFile,
                'c-file-upload__logo-wrapper': formImage && !formVideo,
                'c-file-upload__image-wrapper': formImage && formVideo
              })}
            >
              {url && formImage ? (
                <img
                  alt={label || type}
                  className={classNames({
                    'c-file-upload__logo': formImage && !formVideo,
                    'c-file-upload__image': formImage && formVideo
                  })}
                  src={url}
                />
              ) : url && formVideo ? (
                <Player disabled>
                  <source src={url} />
                  <BigPlayButton position="center" disabled />
                </Player>
              ) : (
                <Fragment>
                  <Icon hugeIcon id="spreadsheet" />
                  <p className="c-file-upload__message">
                    {attachedFile.filename}
                  </p>
                </Fragment>
              )}
            </div>
          ) : (
            <div
              className={classNames('c-file-upload--state', {
                'c-file-upload--rejected': dropState === DROP_STATE.REJECT,
                'c-file-upload--dragging': dropState === DROP_STATE.DRAGGING,
                'c-file-upload--uploading': dropState === DROP_STATE.UPLOADING,
                'c-file-upload--state-uploaded': attachedFile,
                'c-file-upload__logo-wrapper-disabled': formImage && !formVideo,
                'c-file-upload__image-wrapper-disabled': formImage && formVideo
              })}
            >
              {dropState === DROP_STATE.UPLOADING && (
                <Icon id="retry" mediumIcon />
              )}
              {dropState !== DROP_STATE.UPLOADING && (
                <Fragment>
                  {multi ? (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Icon hugeIcon id="upload" />
                      <p className="c-file-upload__message">
                        <FormattedMessage
                          {...messages.fileUploadLabelMultiInfo}
                        />
                      </p>
                      <div className="c-file-upload__dropzone-button">
                        <Icon id="browse" />
                        <label className={classNames('c-input__label', {})}>
                          <FormattedMessage
                            {...messages.fileUploadLabelBrowse}
                          />
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Icon id="upload-gray" mediumIcon />
                      {!label && <span>{accept.label}</span>}
                    </div>
                  )}
                </Fragment>
              )}
            </div>
          );
        }}
      </Dropzone>
      {!multi && attachedFile && !formImage && (
        <div className="c-file-upload__attached-actions c-file-upload__attached-actions-relative">
          <Button
            type="button"
            circular
            primary
            onClick={() => onDownloadHandler()}
          >
            <Icon id="download-white" />
          </Button>
          <Button type="button" circular grey onClick={() => onDeleteHandler()}>
            <Icon id="delete" strokeColor="color-neutral-dark" />
          </Button>
        </div>
      )}

      {/* {formImage && (
          <div className="c-file-upload__attached-actions c-file-upload__attached-actions-relative">
            <Button circular grey onClick={() => this.onDeleteHandler()}>
              <Icon id="delete" strokeColor="color-neutral-dark" />
            </Button>
          </div>
        )}
         */}
    </div>
  );
};

export default FileUpload;

// const getColor = dropState => {
//   switch (dropState) {
//     case DROP_STATE.DEFAULT:
//       return 'grey';
//     case DROP_STATE.REJECT:
//       return 'red';
//     case DROP_STATE.DRAGGING:
//       return 'green';
//     case DROP_STATE.UPLOADING:
//       return 'yellow';
//     default:
//       return 'grey';
//   }
// };

// const getFileIcon = filename => {
//   const extension = filename.split('.').pop();
//   const key = Object.keys(FILE_ICONS).find(
//     fc => fc === extension.toLowerCase()
//   );
//   if (key) return FILE_ICONS[key];
//   return 'default.png';
// };
