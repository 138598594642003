import React from 'react';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import {
  MUTATION_ARCHIVE_TEMPLATE,
  QUERY_GET_TEMPLATES
} from 'services/aws/templates-query';

const RemoveVideosLessonButton = ({
  entityId,
  lessonId,
  children,
  onDeleted,
  ...props
}) => {
  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_ARCHIVE_TEMPLATE}
      confirmTitle={messages.modalConfirmRemoveTemplateTitle}
      confirmMessage={messages.modalConfirmRemoveTemplateMessage}
      variables={{
        templateId: lessonId
      }}
      refetchQueries={[{ query: QUERY_GET_TEMPLATES, variables: { entityId } }]}
      update={(cache, { data: { archiveExerciseProgramTemplate } }) => {
        if (archiveExerciseProgramTemplate) {
          onDeleted(archiveExerciseProgramTemplate.id);
        }
      }}
    >
      <Icon id="delete" strokeColor="color-neutral-dark" />
      {children}
    </ConfirmMutationButton>
  );
};

export default RemoveVideosLessonButton;
