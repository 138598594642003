import { CHART_COLORS, CHART_TYPE } from 'constants.js';
import { ProgressBlockValueTypes } from 'components/progress-bar/ProgressBlock';
import { IntlMessageFormat } from 'intl-messageformat';
import messages from 'messages';

export const parseGroupGrowthPrediction = growthData => {
  const colFilterItems = [
    {
      id: 0,
      label: new IntlMessageFormat(
        messages.growthPredictionHeightVelocity.defaultMessage
      ).format()
    },
    {
      id: 1,
      label: new IntlMessageFormat(
        messages.growthPredictionWeightVelocity.defaultMessage
      ).format()
    },
    {
      id: 3,
      label: new IntlMessageFormat(
        messages.growthPredictionAdultHeight.defaultMessage
      ).format()
    }
  ];
  const rows = createGrowthRows(growthData);
  return {
    groupEntity: {
      name: growthData[0].entity.name
    },
    dataSet: { colFilterItems, rows }
  };
};

export const getGrowthPredictionDataSet = (testCols, dataSet) => {
  return {
    rows: dataSet.rows,
    cols: testCols
  };
};

const createGrowthRows = data => {
  const rows = [];
  data.forEach(d => {
    const row = {
      id: d.id,
      label: `${d.firstname} ${d.lastname}`,
      firstname: d.firstname,
      lastname: d.lastname,
      data: JSON.parse(JSON.stringify(d))
    };
    rows.push(row);
  });

  return rows;
};

export const getGrowthPredictionResultLabel = (result, unit) =>
  `${unit && unit === '#' ? unit : ''}${Math.round(result)}${
    unit && unit !== '#' ? unit : ''
  }`;

export const chartData = () => '';
//export const getGrowthPredictionDataSet = () => '';
//export const parseGroupGrowthPrediction = () => '';
export const getGroupGrowthPredictionChartData = (row, colIndex) => {
  // colIndex0 -> growth
  // colIndex1 -> weight
  // colIndex2 -> geen tabelleke

  const data = [];
  let avg = 0;
  let min = 3000;
  let max = 0;
  let countGrowth = 0;

  let evolutions = row.data.heightEvolution;
  let unit = 'cm/';
  if (colIndex === 1) {
    evolutions = row.data.weightEvolution;
    unit = 'kg/';
  }

  for (let i = 0; i < evolutions.length; i++) {
    const evolution = evolutions[i];
    if (typeof evolution.growthYear !== 'undefined') {
      countGrowth++;
      data.push({
        x: evolution.testdate.split('T')[0],
        y: Math.round(evolution.growthYear),
        value: evolution.growthYear.toFixed(2),
        date: evolution.testdate.split('T')[0],
        unit,
        range: ProgressBlockValueTypes[`VALUE_${evolution.alertLevel}`]
      });
      avg += Math.round(evolution.growthYear);
      if (Math.round(evolution.growthYear) < min) {
        min = Math.round(evolution.growthYear);
      }
      if (Math.round(evolution.growthYear) > max) {
        max = Math.round(evolution.growthYear);
      }
    }
  }

  avg /= countGrowth;

  return {
    avg,
    data: [
      {
        color: CHART_COLORS.GREEN,
        data,
        id: 'first name last',
        type: CHART_TYPE.GROWTHTRACKER
      }
    ],
    min,
    max
  };

  /*return {
    avg: 65,
    data: [{
      color: CHART_COLORS.GREEN,
      data: [
        {
          x: 'sessie 1',
          y: 61,
          date: '2020-06-11',
          unit: '#',
          // percentile: 71,
          // zScore: 0.85
        },
        {
          x: 'sessie 2',
          y: 72,
          date: '2020-07-11',
          unit: '#',
          // percentile: 81,
          // zScore: 0.85
        }
      ],
      id: 'firstname lastname'
    }],
    min: 61,
    max: 72
  }*/
};
