import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { CardBody, CardFooter } from 'components/card';
import { Button, ButtonsGroup } from 'components/button';
import IssueForm from 'containers/pages/rehab/issue/IssueForm';
import IssueDetailFields from 'containers/pages/rehab/issue/IssueDetailFields';
import PersonIssue from 'models/PersonIssue';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';

function AddIssueModal({ entityId, onClose, sporterId }) {
  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal card autoHeight isOpen={true} onClose={onCloseHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage
            {...messages.rehabAddIssueTitle}
            values={{
              issueExists: false
            }}
          />
        </CardHeaderTitle>
      </CardHeader>
      <IssueForm
        entityId={entityId}
        sporterId={sporterId}
        personIssue={new PersonIssue({})}
        onComplete={() => onCloseHandler()}
      >
        {({ isSubmitting }) => (
          <>
            <CardBody modalWithOverflow>
              <IssueDetailFields edit={true} />
            </CardBody>
            <CardFooter modal extraClassNames="c-card__footer--modal-larger">
              <ButtonsGroup>
                <Button secondary onClick={() => onCloseHandler()}>
                  <FormattedMessage {...messages.cancelButton} />
                </Button>
                <Button type="submit" primary disabled={isSubmitting}>
                  <FormattedMessage {...messages.saveButton} />
                </Button>
              </ButtonsGroup>
            </CardFooter>
          </>
        )}
      </IssueForm>
    </Modal>
  );
}

export default AddIssueModal;
