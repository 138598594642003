import { Fragment, useContext } from 'react';
import { ListItemLabel } from 'components/list/list-item';
import { renderUnit, renderOption } from 'utils/results';
import { round } from 'utils/math';
import { Laterality, Lateralities } from 'constants.js';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import FieldRange from 'components/input/FieldRange';
import { useTestDataContext } from 'contexts/TestDataContext';
import { StoreContext } from 'index';
import ProgressBarLabelSide from 'components/progress-bar/ProgressBarLabelSide';

function TestItemResult({ testItem, sidesDisabled }) {
  const { formData } = useTestDataContext();

  return (
    <>
      <ListItemLabel
        label
        extraClassNames={classNames(
          'c-list__item-label-results c-list__item-label-results-variation',
          {
            'c-list__item-slider-wrapper': testItem.input_type === 'slider'
          }
        )}
      >
        {formData[testItem.id] && (
          <Fragment>
            {Object.keys(formData[testItem.id]).map((laterality, l) => {
              const values = formData[testItem.id];
              const hasTrialsLabel = values[laterality].length > 1;
              return values[laterality].map(
                (value, trial) =>
                  (parseInt(laterality) === Laterality.RIGHT ||
                    parseInt(laterality) === Laterality.UNILATERAL) && (
                    <div
                      className={classNames(
                        'c-list__item-small-editing-area c-list__item-small-edited-area o-flex--column',
                        {
                          'c-list__item-small-edited-area-select':
                            testItem.input_type === 'select_with_options'
                        }
                      )}
                      key={`${testItem.id}${l}${trial}`}
                    >
                      {parseInt(laterality) === Laterality.RIGHT ? (
                        Array.from(Array(2).keys()).map(laterality => (
                          <Value
                            key={`${testItem.id}${laterality}${trial}`}
                            testItem={testItem}
                            trialsLabel={hasTrialsLabel}
                            trial={trial}
                            laterality={laterality}
                            values={values}
                            disabled={
                              sidesDisabled && sidesDisabled[laterality]
                            }
                          />
                        ))
                      ) : parseInt(laterality) === Laterality.UNILATERAL ? (
                        // This is the comp when there are no lateralities
                        // 2 == no side
                        <Value
                          testItem={testItem}
                          trialsLabel={hasTrialsLabel}
                          trial={trial}
                          laterality={laterality}
                          values={values}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  )
              );
            })}
          </Fragment>
        )}
      </ListItemLabel>
      {/*) : (
        <ListItemLabel progress>
          <ProgressBar value={Number(round(testItem.result, 0))}>
            {round(testItem.result, 0)}
          </ProgressBar>
        </ListItemLabel>
      ) : (
        <ListItemLabel label>{testItem.value}</ListItemLabel>
      )}*/}
    </>
  );
}

export default TestItemResult;

const Value = ({ testItem, trialsLabel, trial, laterality, values }) => {
  const {
    uiState: { locale }
  } = useContext(StoreContext);
  const value = values?.[laterality]?.[trial];
  switch (testItem.input_type) {
    case 'select_with_options':
      return (
        <>
          {trialsLabel && (
            <span className="c-list__item-trial">
              <FormattedMessage
                {...messages.labelTrial}
                values={{
                  trial: trial + 1
                }}
              />
            </span>
          )}
          <div
            className={classNames(
              'c-list__item-result',
              value !== '' ? '' : 'c-list__item-result-empty'
            )}
          >
            {value !== null && (
              <>
                {Lateralities[laterality] &&
                  laterality.toString() !==
                    Laterality.UNILATERAL.toString() && (
                    <ProgressBarLabelSide>
                      {Lateralities[laterality]}
                    </ProgressBarLabelSide>
                  )}
                {value !== '' ? (
                  <>
                    <i>{renderOption(testItem, value)}</i>
                    {renderUnit(testItem.unit)}
                  </>
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        </>
      );
    case 'slider':
      return (
        <FieldRange
          id={`id-${testItem.id}-${Lateralities[laterality]}-${trial}`}
          name={`testValues.${laterality}.${trial}`}
          disabled
          value={value ? round(value, 0) : ''}
          min={testItem.minimum}
          max={testItem.maximum}
          minLabel={
            testItem.input_type_parameters?.minLabels?.[locale] ??
            testItem.input_type_parameters.minLabel
          }
          maxLabel={
            testItem.input_type_parameters?.maxLabels?.[locale] ??
            testItem.input_type_parameters.maxLabel
          }
        >
          {Lateralities[laterality] &&
            laterality.toString() !== Laterality.UNILATERAL.toString() && (
              <ProgressBarLabelSide>
                {Lateralities[laterality]}
              </ProgressBarLabelSide>
            )}
        </FieldRange>
      );
    case 'free_number':
    default:
      return (
        <>
          {trialsLabel && (
            <span className="c-list__item-trial">
              <FormattedMessage
                {...messages.labelTrial}
                values={{
                  trial: trial + 1
                }}
              />
            </span>
          )}
          <div
            className={classNames(
              'c-list__item-result',
              value !== '' ? '' : 'c-list__item-result-empty'
            )}
          >
            {value !== null && (
              <>
                {Lateralities[laterality] &&
                  laterality.toString() !==
                    Laterality.UNILATERAL.toString() && (
                    <ProgressBarLabelSide>
                      {Lateralities[laterality]}
                    </ProgressBarLabelSide>
                  )}
                {value}
                {value !== '' ? renderUnit(testItem.unit) : ''}
              </>
            )}
          </div>
        </>
      );
  }
};
