import classNames from 'classnames';

const ProgressBarLabelSide = props => {
  const { children, extraClassNames } = props;
  return (
    <span className={classNames('c-progress-bar__label-side', extraClassNames)}>
      {children}
    </span>
  );
};

export default ProgressBarLabelSide;
