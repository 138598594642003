import React from 'react';
import classNames from 'classnames';
import Icon from 'components/icon/Icon';

SingleMarker.propTypes = {};

function SingleMarker({ annotation, isActive, position, onClick }) {
  return (
    <>
      <button
        type={'button'}
        style={{
          left: `${position}%`
        }}
        onClick={() => {
          onClick(annotation);
        }}
        className={classNames(
          'annotation-marker',
          {
            'annotation-marker-active': isActive
          },
          annotation.iconClasses?.map(c => `annotation-marker--${c}`)
        )}
      >
        <Icon id="pointer" />
      </button>
    </>
  );
}

export default SingleMarker;
