export const isValueValidNumber = v => {
  return v !== undefined && !isNaN(v) && v !== '' && v !== null;
};

export const getvalidFileType = (file, accept) => {
  return new Promise(async (resolve, reject) => {
    if (!file) {
      reject('no file given');
      return;
    }

    const mimeType = (file.type || '').toLowerCase();
    const baseMimeType = mimeType.replace(/\/.*$/, '');

    const acceptedFile = accept.some(type => {
      const validType = type.trim().toLowerCase();
      if (validType.endsWith('/*')) {
        // This is something like an image/* mime type
        return baseMimeType === validType.replace(/\/.*$/, '');
      }
      return mimeType === validType;
    });

    if (acceptedFile) {
      resolve(baseMimeType);
    } else {
      reject('file type not valid');
    }
  });
};
