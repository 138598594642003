import classNames from 'classnames';
import { Field } from 'formik';

const FieldRadiobutton = props => {
  return (
    <div
      className={classNames(
        'c-input__group c-input__group--radiobutton',
        props.extraClassNames,
        {
          'c-input__group-radiobutton-org': props.organisation,
          'c-input__group-radiobutton-readonly':
            props.readOnly || props.disabled
        }
      )}
    >
      <label
        htmlFor={props.id}
        className={classNames('c-input__label c-input__label--radiobutton')}
      >
        <Field
          id={props.id}
          name={props.name}
          type="radio"
          className={classNames('c-input c-input--radiobutton')}
          disabled={props.readOnly || props.disabled}
          value={props.value}
        />

        {props.subLabel ? (
          <span className="c-input__label-wrapper">
            <span className="c-input__main-label">{props.children}</span>
            <span className="c-input__extra-label">{props.subLabel}</span>
          </span>
        ) : (
          props.children
        )}
      </label>
    </div>
  );
};

export default FieldRadiobutton;
