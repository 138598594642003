import React from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Mutation } from '@apollo/client/react/components';

import Modal from 'components/modal/Modal';
import Select, { Option } from 'components/input/Select';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import { ButtonsGroup, Button } from 'components/button';
import { CardBody, CardFooter } from 'components/card';
import Textarea from 'components/input/Textarea';

import messages from 'messages';
import { MUTATION_ADD_BENCHMARK } from 'services/aws/benchmark-query';
import {
  QUERY_GET_BENCHMARKS,
  MUTATION_EDIT_BENCHMARK,
  MUTATION_COPY_BENCHMARK
} from 'services/aws/benchmark-query';
import { ROUTE_SETTINGS_BENCHMARKS_BENCHMARK } from 'routes/RouteList';
import InputErrorMessage from 'components/input/InputErrorMessage';
import FieldInput from 'components/input/FieldInput';

export const BENCHMARK_UPDATE_TYPE = Object.freeze({
  ADD: 'Add',
  EDIT: 'Edit',
  COPY: 'Copy'
});

class BenchmarkModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title || '',
      note: props.note || '',
      benchmarkType: props.benchmarkType || 'age',
      errorMessage: null
    };
  }

  onCloseHandler = () => {
    const { onClose } = this.props;
    if (onClose) onClose();
  };

  onChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  onChangeBenchmarkType = e => {
    this.setState({
      benchmarkType: e.value
    });
  };

  onSubmit = async (e, mutation) => {
    e.preventDefault();

    const {
      intl,
      authStore: {
        user: { rootEntityId }
      },
      existingNames,
      id,
      originalId,
      type
    } = this.props;

    const { title, note, benchmarkType } = this.state;
    if (existingNames.find(n => n === title.toLowerCase())) {
      this.setState({
        errorMessage: intl.formatMessage(
          messages.modalBenchmarkErrorExistingName
        )
      });
      return;
    }
    this.setState({ errorMessage: null });

    await mutation({
      variables: {
        entityId: rootEntityId,
        id: BENCHMARK_UPDATE_TYPE.EDIT === type ? id : undefined,
        title,
        note: note || undefined,
        type: benchmarkType || 'age',
        originalId: BENCHMARK_UPDATE_TYPE.COPY === type ? originalId : undefined
      }
    });
  };

  getMutationByType = type => {
    const {
      routing,
      authStore: {
        user: { rootEntityId }
      }
    } = this.props;

    const mutationData = {};
    let key;
    switch (type) {
      case BENCHMARK_UPDATE_TYPE.ADD:
        mutationData.mutation = MUTATION_ADD_BENCHMARK;
        key = 'addBenchmark';
        break;
      case BENCHMARK_UPDATE_TYPE.EDIT:
        mutationData.mutation = MUTATION_EDIT_BENCHMARK;
        key = 'editBenchmark';
        break;
      case BENCHMARK_UPDATE_TYPE.COPY:
        mutationData.mutation = MUTATION_COPY_BENCHMARK;
        key = 'addBenchmark';
        break;
      default:
        mutationData.mutation = MUTATION_ADD_BENCHMARK;
        key = 'addBenchmark';
    }

    mutationData.update = (cache, { data }) => {
      if (data && data[key]) {
        this.onCloseHandler();
        routing.push(
          ROUTE_SETTINGS_BENCHMARKS_BENCHMARK.replace(
            ':benchmarkId',
            data[key].id
          )
        );
      }
    };

    mutationData.refetchQueries = () => [
      {
        query: QUERY_GET_BENCHMARKS,
        variables: { entityId: rootEntityId }
      }
    ];
    return mutationData;
  };

  render() {
    const { intl, type } = this.props;
    const { title, note, errorMessage, benchmarkType } = this.state;

    const titlePanel = intl.formatMessage(
      messages[`modal${type}BenchmarkTitle`]
    );
    const subtitle = intl.formatMessage(
      messages[`modal${type}BenchmarkSubtitle`]
    );
    const submitLabel =
      type === BENCHMARK_UPDATE_TYPE.EDIT
        ? intl.formatMessage(messages.modalBenchmarkButtonSave)
        : intl.formatMessage(messages.modalBenchmarkButtonCreate);

    const mutationData = this.getMutationByType(type);

    return (
      <Modal card autoHeight isOpen={true} onClose={this.onCloseHandler}>
        <Mutation
          {...mutationData}
          onError={() => {
            this.setState({
              errorMessage: intl.formatMessage(
                messages.modalBenchmarkErrorMessage
              )
            });
          }}
        >
          {(mutation, { loading }) => (
            <form>
              <CardHeader modal>
                <CardHeaderTitle>{titlePanel}</CardHeaderTitle>
                <CardHeaderSubtitle>{subtitle}</CardHeaderSubtitle>
              </CardHeader>
              <CardBody modal>
                <FieldInput
                  id="title"
                  value={title || ''}
                  readOnly={false}
                  disabled={loading}
                  onChange={this.onChange}
                  autoFocus={type === BENCHMARK_UPDATE_TYPE.ADD}
                >
                  <FormattedMessage {...messages.modalBenchmarkLabelName} />
                </FieldInput>
                <Select
                  id="benchmarkType"
                  value={benchmarkType}
                  onChange={this.onChangeBenchmarkType}
                  label={intl.formatMessage(messages.modalBenchmarkLabelType)}
                >
                  <Option
                    id="benchmarkType"
                    value="age"
                    label={intl.formatMessage(
                      messages.modalBenchmarkLabelTypeAge
                    )}
                  />
                  <Option
                    id="benchmarkType"
                    value="bioage"
                    label={intl.formatMessage(
                      messages.modalBenchmarkLabelTypeBioAge
                    )}
                  />
                </Select>
                <Textarea
                  id="note"
                  value={note || ''}
                  rows={'3'}
                  readOnly={false}
                  disabled={loading}
                  onChange={this.onChange}
                  resizeVertical
                >
                  <FormattedMessage {...messages.modalBenchmarkLabelNotes} />
                </Textarea>
                {errorMessage && (
                  <InputErrorMessage>{errorMessage}</InputErrorMessage>
                )}
              </CardBody>
              <CardFooter modal>
                <ButtonsGroup>
                  <Button secondary onClick={this.onCloseHandler} type="button">
                    <FormattedMessage {...messages.buttonCancel} />
                  </Button>
                  <Button
                    type="submit"
                    primary
                    onClick={e => this.onSubmit(e, mutation)}
                    disabled={loading || !title}
                  >
                    {submitLabel}
                  </Button>
                </ButtonsGroup>
              </CardFooter>
            </form>
          )}
        </Mutation>
      </Modal>
    );
  }
}

export default injectIntl(
  withRouter(inject('routing', 'authStore')(BenchmarkModal))
);
