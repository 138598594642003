import React from 'react';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages.js';
import { FormattedMessage } from 'react-intl';
import { QUERY_GET_ANNOTATIONS } from 'services/aws/annotations-query';
import { QUERY_GET_LESSONS } from 'services/aws/lessons-query';
import { MUTATION_ARCHIVE_NOTE } from 'services/aws/notes-query';

const RemoveNoteButton = ({
  entityId,
  noteId,
  linkId,
  children,
  onComplete,
  tiny,
  extraClassNames,
  ...props
}) => {
  return (
    <ConfirmMutationButton
      tiny={tiny}
      extraClassNames={extraClassNames}
      {...props}
      mutation={MUTATION_ARCHIVE_NOTE}
      confirmTitle={messages.modalConfirmRemoveAnnotationNoteTitle}
      confirmMessage={messages.modalConfirmRemoveAnnotationNoteMessage}
      variables={{
        noteId
      }}
      refetchQueries={[
        {
          query: QUERY_GET_ANNOTATIONS,
          variables: { linkId }
        },
        {
          query: QUERY_GET_LESSONS,
          variables: { entityId }
        }
      ]}
      update={(cache, { data: { archiveNote } }) => {
        if (archiveNote) {
          onComplete && onComplete(archiveNote);
        }
      }}
    >
      <Icon id="delete" strokeColor="color-neutral-dark" />
      {tiny ? (
        <div className="u-hidden-visually">
          <FormattedMessage
            {...messages.annotationsRemoveAnnotationNoteButton}
          />
        </div>
      ) : (
        <FormattedMessage {...messages.annotationsRemoveAnnotationNoteButton} />
      )}
    </ConfirmMutationButton>
  );
};

export default RemoveNoteButton;
