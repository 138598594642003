const awsmobile = {
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools: process.env.REACT_APP_AWS_USER_POOLS,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
};

export default awsmobile;
