import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_GET_PROGRAM } from 'services/aws/programs-query';
import Program from 'models/Program';
import { useRouteMatch } from 'react-router-dom';
import {
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_EXERCISE,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK,
  ROUTE_USER_ENTITY_PROGRAMS_PROGRAM,
  ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_EXERCISE,
  ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_FEEDBACK
} from 'routes/RouteList';

export const ProgramContext = createContext();

const ProgramProvider = ({ user, children }) => {
  const { params: { programId } = {} } =
    useRouteMatch([
      ROUTE_USER_ENTITY_PROGRAMS_PROGRAM,
      ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_EXERCISE,
      ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_FEEDBACK,
      ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT,
      ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM,
      ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK,
      ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_EXERCISE
    ]) ?? {};

  const [program, setProgram] = useState(new Program({}));
  const [exercises, setExercises] = useState([]);

  const { loading, error, data } = useQuery(QUERY_GET_PROGRAM, {
    variables: { id: programId },
    skip: !programId
  });

  useEffect(() => {
    if (programId && data?.getExerciseProgram) {
      const programData = new Program({ ...data.getExerciseProgram });
      setProgram(programData);
      setExercises([...programData.exercises]);
    }
  }, [programId, data]);

  return (
    <ProgramContext.Provider
      value={{
        program,
        exercises,
        loading,
        error
      }}
    >
      {children}
    </ProgramContext.Provider>
  );
};

function useProgramContext() {
  const context = useContext(ProgramContext);
  if (context === undefined) {
    throw new Error(
      'The ProgramContext hook must be used within a ProgramContext.Provider'
    );
  }
  return context;
}

export { ProgramProvider, useProgramContext };
