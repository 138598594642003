import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Query, Mutation } from '@apollo/client/react/components';
import {
  ROUTE_SCANNERS,
  ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION,
  ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS,
  ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION_RESULT,
  ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION_RESULT_EDIT
} from 'routes/RouteList';
import { TabList, Tab, TabPanel, Tabs } from 'components/tabs';
import Panel from 'components/panel/Panel';
import PanelHeader from 'components/panel/PanelHeader';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelSubtitle from 'components/panel/panel-title/PanelSubtitle';
import PanelBody from 'components/panel/PanelBody';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import {
  QUERY_GET_CALIBRATION_BY_ID,
  QUERY_GET_SCANNER_BY_ID,
  MUTATION_UPDATE_CALIBRATION
} from 'services/aws/scanner-query';
import CalibrationModel from 'models/Calibration';
import { getLocalDateFormat } from 'utils/date';
import RemoveCalibrationFromScannerButton from 'containers/partials/buttons/RemoveCalibrationFromScannerButton';
import { MenuWrapper } from 'components/menu';
import { PanelTitleButton } from 'components/panel/panel-title';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import MultiFileUploadCard from 'containers/partials/cards/MultiFileUploadCard';
import CalibrationResultForm from './CalibrationResultForm';
import messages from 'messages';
import { isValueValidNumber } from 'utils/form';
import Loader from 'components/loader/Loader';
import CalibrationForm from './CalibrationForm';

const getValue = value => (value !== undefined && value !== null ? value : '');

class Calibration extends React.Component {
  scanner;
  state = {
    name: '',
    date: '',
    coil: '',
    shim: '',
    carnosine: '',
    creatine: '',
    tma: '',
    feedback: '',
    voxelSizeX: '',
    voxelSizeY: '',
    voxelSizeZ: '',
    signalWater: ''
  };

  onSave = async mutation => {
    const { calibrationId } = this.props;
    const { name, date, shim, coil } = this.state;
    await mutation({
      variables: {
        calibrationId,
        name,
        date,
        shim,
        coil
      }
    });
  };
  onSaveMeta = async mutation => {
    const { calibrationId } = this.props;
    const {
      carnosine,
      creatine,
      tma,
      feedback,
      voxelSizeX,
      voxelSizeY,
      voxelSizeZ,
      signalWater
    } = this.state;
    const meta = JSON.stringify({
      carnosine,
      creatine,
      tma,
      feedback: feedback ? feedback : undefined,
      voxelSizeX,
      voxelSizeY,
      voxelSizeZ,
      signalWater
    });
    await mutation({
      variables: {
        calibrationId,
        meta
      }
    });
  };

  onCompleted = data => {
    if (data && data.getMtsCalibration) {
      const calibration = data.getMtsCalibration;
      let meta = {};
      try {
        meta = JSON.parse(calibration.meta) || {};
      } catch (er) {}
      this.setState({
        name: calibration.name,
        date: calibration.date,
        shim: getValue(calibration.shim),
        coil: getValue(calibration.coil),
        carnosine: getValue(meta.carnosine),
        creatine: getValue(meta.creatine),
        tma: getValue(meta.tma),
        feedback: meta.feedback || '',
        voxelSizeX: getValue(meta.voxelSizeX),
        voxelSizeY: getValue(meta.voxelSizeY),
        voxelSizeZ: getValue(meta.voxelSizeZ),
        signalWater: getValue(meta.signalWater)
      });
    }
  };

  onReset = () => {
    const errors = {};
    Object.keys(this.state).forEach(key => {
      if (key.search('Error') !== -1) {
        errors[key] = false;
      }
    });
    let meta = {};
    try {
      meta = JSON.parse(this.calibration.meta) || {};
    } catch (er) {}

    this.setState({
      name: this.calibration.name,
      date: this.calibration.date,
      shim: getValue(this.calibration.shim),
      coil: getValue(this.calibration.coil),
      carnosine: getValue(meta.carnosine),
      creatine: getValue(meta.creatine),
      tma: getValue(meta.tma),
      feedback: meta.feedback || '',
      voxelSizeX: getValue(meta.voxelSizeX),
      voxelSizeY: getValue(meta.voxelSizeY),
      voxelSizeZ: getValue(meta.voxelSizeZ),
      signalWater: getValue(meta.signalWater),
      ...errors
    });

    const {
      routing: { push },
      entityId,
      scannerId,
      calibrationId
    } = this.props;
    push(
      `${ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION.replace(
        ':entityId',
        entityId
      )
        .replace(':scannerId', scannerId)
        .replace(':calibrationId', calibrationId)}`
    );
  };

  onChange = (e, type) => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  onBlur = (e, type) => {
    if (type === 'number') {
      const value = Number(e.target.value.replace(',', '.'));
      this.setState({
        [e.target.id]: value,
        [`${e.target.id}Error`]: isValueValidNumber(value) ? false : true
      });
    } else {
      this.setState({
        [`${e.target.id}Error`]: e.target.value ? false : true
      });
    }
  };

  render() {
    const {
      intl,
      entityId,
      scannerId,
      calibrationId,
      edit,
      tabIndex = 0,
      routing: { push }
    } = this.props;

    return (
      <Panel>
        <Query
          query={QUERY_GET_CALIBRATION_BY_ID}
          fetchPolicy="cache-and-network"
          variables={{ entityId, calibrationId }}
          onCompleted={this.onCompleted}
        >
          {result => {
            const { loading, error, data } = result;
            if (loading) return <Loader />;
            if (error) return <RetryPanel />;
            this.calibration = new CalibrationModel(data.getMtsCalibration);
            let meta = null;
            try {
              meta = JSON.parse(this.calibration.meta);
            } catch (er) {}
            return (
              <Fragment>
                <PanelHeader>
                  <Breadcrumbs>
                    <Breadcrumb
                      onClick={() =>
                        push(ROUTE_SCANNERS.replace(':entityId', entityId))
                      }
                    >
                      <FormattedMessage {...messages.breadcrumbScanners} />
                    </Breadcrumb>
                    <Breadcrumb
                      onClick={() =>
                        push(
                          ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS.replace(
                            ':entityId',
                            entityId
                          ).replace(':scannerId', scannerId)
                        )
                      }
                    >
                      <FormattedMessage
                        {...messages.breadcrumbScannersCalibrations}
                        values={{ scanner: this.calibration.scanner.name }}
                      />
                    </Breadcrumb>
                  </Breadcrumbs>
                  <PanelTitleWrapper>
                    <PanelTitle>
                      {this.calibration.name}
                      <MenuWrapper trigger={<PanelTitleButton />}>
                        <RemoveCalibrationFromScannerButton
                          menu
                          entityId={entityId}
                          scannerId={scannerId}
                          calibrationId={calibrationId}
                          onComplete={() =>
                            push(
                              ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS.replace(
                                ':entityId',
                                entityId
                              ).replace(':scannerId', scannerId)
                            )
                          }
                        >
                          <FormattedMessage
                            {...messages.scannerMenuItemRemoveCalibration}
                          />
                        </RemoveCalibrationFromScannerButton>
                      </MenuWrapper>
                    </PanelTitle>
                    <PanelSubtitle>
                      {getLocalDateFormat(this.calibration.date)}
                    </PanelSubtitle>
                  </PanelTitleWrapper>
                </PanelHeader>
                <PanelBody>
                  <Tabs defaultIndex={tabIndex}>
                    <TabList>
                      <Tab
                        onClick={() => {
                          this.onReset();
                          push(
                            `${ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION.replace(
                              ':scannerId',
                              scannerId
                            )
                              .replace(':calibrationId', calibrationId)
                              .replace(':entityId', entityId)}`
                          );
                        }}
                      >
                        <FormattedMessage {...messages.scannerTabGlobal} />
                      </Tab>
                      <Tab
                        onClick={() => {
                          this.onReset();
                          let url =
                            ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION_RESULT;
                          if (!meta) {
                            url =
                              ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION_RESULT_EDIT;
                          }
                          push(
                            `${url
                              .replace(':scannerId', scannerId)
                              .replace(':calibrationId', calibrationId)
                              .replace(':entityId', entityId)}`
                          );
                        }}
                      >
                        <FormattedMessage {...messages.scannerTabResult} />
                      </Tab>
                    </TabList>
                    <TabPanel>
                      <Mutation
                        mutation={MUTATION_UPDATE_CALIBRATION}
                        refetchQueries={() => {
                          return [
                            {
                              query: QUERY_GET_SCANNER_BY_ID,
                              variables: { entityId, scannerId }
                            },
                            {
                              query: QUERY_GET_CALIBRATION_BY_ID,
                              variables: { entityId, calibrationId }
                            }
                          ];
                        }}
                        update={(cache, { data: { editMtsCalibration } }) => {
                          if (editMtsCalibration && editMtsCalibration.id) {
                            push(
                              `${ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION.replace(
                                ':entityId',
                                entityId
                              )
                                .replace(':scannerId', scannerId)
                                .replace(':calibrationId', calibrationId)}`
                            );
                          }
                        }}
                      >
                        {(editMtsCalibration, data) => {
                          return (
                            <CalibrationForm
                              scannerId={scannerId}
                              calibrationId={calibrationId}
                              entityId={entityId}
                              edit={edit}
                              onSave={() => this.onSave(editMtsCalibration)}
                              onReset={this.onReset}
                              onChange={this.onChange}
                              onBlur={this.onBlur}
                              loading={data.loading || loading}
                              {...this.state}
                            />
                          );
                        }}
                      </Mutation>
                      <MultiFileUploadCard
                        entityId={entityId}
                        linkId={calibrationId}
                      />
                    </TabPanel>
                    <TabPanel>
                      <Mutation
                        mutation={MUTATION_UPDATE_CALIBRATION}
                        refetchQueries={() => {
                          return [
                            {
                              query: QUERY_GET_SCANNER_BY_ID,
                              variables: { entityId, scannerId }
                            },
                            {
                              query: QUERY_GET_CALIBRATION_BY_ID,
                              variables: { entityId, calibrationId }
                            }
                          ];
                        }}
                        update={(cache, { data: { editMtsCalibration } }) => {
                          if (editMtsCalibration && editMtsCalibration.id) {
                            push(
                              `${ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION_RESULT.replace(
                                ':entityId',
                                entityId
                              )
                                .replace(':scannerId', scannerId)
                                .replace(':calibrationId', calibrationId)}`
                            );
                          }
                        }}
                      >
                        {(editMtsCalibration, data) => {
                          return (
                            <CalibrationResultForm
                              scannerId={scannerId}
                              calibrationId={calibrationId}
                              entityId={entityId}
                              edit={edit}
                              onSave={() => this.onSaveMeta(editMtsCalibration)}
                              onReset={this.onReset}
                              onChange={this.onChange}
                              onBlur={this.onBlur}
                              loading={data.loading || loading}
                              {...this.state}
                            />
                          );
                        }}
                      </Mutation>
                    </TabPanel>
                  </Tabs>
                </PanelBody>
              </Fragment>
            );
          }}
        </Query>
      </Panel>
    );
  }
}

export default withRouter(
  injectIntl(inject('routing', 'authStore', 'uiState')(Calibration))
);
