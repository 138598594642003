import React, { useContext } from 'react';
import PreviewButton from '../buttons/PreviewButton';
import { ButtonsGroup } from 'components/button';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper,
  ListItemStatus
} from 'components/list/list-item';
import Icon from 'components/icon/Icon';
import { StoreContext } from 'index';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { sanitize } from 'dompurify';

const VideoListItemSortable = ({
  id,
  uid,
  exercise,
  checkbox,
  active,
  clickable,
  draggable,
  onClick,
  onChange,
  visible,
  preview,
  onShowPreview,
  showNote,
  showTemplateCount,
  showSharesAndAnnotations,
  showMessage,
  checked,
  done,
  inProgress
}) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id, disabled: !draggable }); //

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };
  return (
    <ListItem
      id={id}
      ref={setNodeRef}
      active={active || isDragging}
      checkbox={!draggable && checkbox}
      onClick={onClick}
      clickable={clickable}
      style={style}
      done={done}
      inProgress={inProgress}
      draggable={draggable}
      isDragging={isDragging}
      outfaded={showMessage}
      {...attributes}
      {...listeners}
    >
      <ListItemStatus
        itemId={uid}
        checkbox={checkbox}
        visible={visible}
        checked={checked}
        onChange={onChange}
        statusIcon="play"
      />
      <ListItemLabelWrapper extraClassNames="u-padding-horizontal-medium u-padding-vertical-small">
        <ListItemLabel labelNotePreview>
          {/* NOTE TO REFACTOR: Here is not necessary an ellipsed span, because there is no preview */}
          <span>{exercise.title}</span>
        </ListItemLabel>

        {(exercise.note && showNote) ||
          preview ||
          (!user.isGSVOrg && (
            <div className="c-list__item-small-editing-area c-list__item-wrapping-note-preview">
              {exercise.note && showNote && <p>{exercise.note}</p>}

              {/* NOTE TO REFACTOR: This is not necessary apparently, we are not using a preview on
                This component is used only in Vitale, under lessons
               */}
              {preview && (
                <ButtonsGroup>
                  <PreviewButton
                    video={exercise.video?.signedDownloadUrl}
                    thumb={exercise.thumb?.signedDownloadUrl}
                    showPreview={onShowPreview}
                  />
                </ButtonsGroup>
              )}
            </div>
          ))}

        {showTemplateCount && (
          <ListItemLabel info>
            {exercise.templateCount ? (
              <>
                {exercise.templateCount}
                <Icon
                  extraClassNames="u-margin-left-tiny"
                  id="bookmark"
                  fillColor="color-primary"
                  strokeColor="color-primary"
                />
              </>
            ) : (
              <>
                0
                <Icon
                  extraClassNames="u-margin-left-tiny"
                  id="bookmark"
                  fillColor="transparent"
                  strokeColor="color-neutral-dark"
                />
              </>
            )}
          </ListItemLabel>
        )}

        {showSharesAndAnnotations && !showMessage && (
          <div className="c-list__item-double-icon-info">
            <ListItemLabel info>
              <span className="c-list__item-secondary-text-value">
                {exercise.shares ? exercise.shares.length : 0}
              </span>
              <span
                className="c-list__item-secondary-text-value-unit"
                dangerouslySetInnerHTML={{
                  __html: sanitize(
                    intl.formatMessage(messages.annotationSharedTimes),
                    {
                      ALLOWED_TAGS: ['b', 'i', 'br']
                    }
                  )
                }}
              ></span>
              <Icon extraClassNames="u-margin-left-tiny" id="profile" />
            </ListItemLabel>
            <ListItemLabel info>
              <span className="c-list__item-secondary-text-value">
                {exercise.annotations ? exercise.annotations.length : 0}
              </span>
              <span className="c-list__item-secondary-text-value-unit u-margin-left-tiny">
                <FormattedMessage
                  {...messages.annotationLength}
                  values={{ length: exercise.annotations?.length }}
                />
              </span>
              <Icon
                extraClassNames="u-margin-left-tiny"
                id="comment"
                fillColor="transparent"
                strokeColor="color-neutral-dark"
              />
            </ListItemLabel>
          </div>
        )}

        {showMessage && (
          <ListItemLabel info style={{ width: 'auto', marginRight: -8 }}>
            <FormattedMessage {...messages.videoUpload} />
          </ListItemLabel>
        )}
      </ListItemLabelWrapper>
    </ListItem>
  );
};

export default VideoListItemSortable;
