import { useContext, useEffect, useState } from 'react';
import { TabList, Tab, TabPanel, Tabs } from 'components/tabs';
import { FormattedMessage, useIntl } from 'react-intl';
import { Panel, PanelHeader, PanelSubtitle } from 'components/panel';
import messages from 'messages';
import {
  PanelTitleWrapper,
  PanelTitle,
  PanelTitleButton,
  PanelTitleButtonsGroup
} from 'components/panel/panel-title';
import PanelBody from 'components/panel/PanelBody';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import './Exercise.scss';
import { MenuWrapper } from 'components/menu';
import RemoveExerciseButton from 'containers/partials/buttons/RemoveExerciseButton';
import { StoreContext } from 'index';
import Loader from 'components/loader/Loader';
import ExercisePanel from './ExercisePanel';
import useExercise from 'hooks/queries/useExercise';
import { AbilityContext, Can } from 'Can';
import BreadcrumbPath from 'containers/partials/misc/BreadcrumbPath';
import AddExercisesToCollectionModal from 'containers/pages/exercises/collections/AddExercisesToCollectionModal';
import Tags from 'containers/partials/inputs/Tags';
import { useMutation } from '@apollo/client';
import {
  MUTATION_ADD_EXERCISE_TO_TEMPLATES,
  QUERY_GET_TEMPLATES
} from 'services/aws/templates-query';
import { useNotificationQueue } from 'components/notification';
import { generatePath, Link, useParams } from 'react-router-dom';
import { useAbility } from '@casl/react';
import RemoveExercisesFromTemplateButton from 'containers/partials/buttons/RemoveExercisesFromTemplateButton';
import {
  ROUTE_EXERCISES_ENTITY_EXERCISE,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES,
  ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE
} from 'routes/RouteList';
import Organisation from 'components/organisation/Organisation';
import useEdit from 'hooks/utils/useEdit';
import useTemplate from 'hooks/queries/useTemplate';
import ExerciseDetailsPanel from 'containers/pages/exercises/exercises/ExerciseDetailsPanel';
import ExerciseEditLanguagesButton from 'containers/pages/exercises/exercises/ExerciseEditLanguagesButton';
import TabPanelBody from 'components/tabs/TabPanelBody';

const Exercise = ({
  entityId,
  exerciseId,
  tabIndex,
  active,
  editable,
  inCollection = false,
  inTemplate = false,
  inTraining = false,
  crumbs,
  parentPath,
  ...props
}) => {
  const {
    authStore: { user },
    routing: { push }
  } = useContext(StoreContext);
  const { collectionId, templateId } = useParams();
  const ability = useAbility(AbilityContext);
  const notification = useNotificationQueue();
  const intl = useIntl();
  const [edit, setEdit] = useEdit(props.edit, true);
  const [addToCollectionModal, setAddToCollectionModal] = useState(null);
  const { exercise, loading, error, getExercise } = useExercise(
    entityId,
    exerciseId
  );
  const { template } = useTemplate(entityId, templateId ?? collectionId, user);

  const [addExercise] = useMutation(MUTATION_ADD_EXERCISE_TO_TEMPLATES);

  useEffect(() => {
    getExercise();
  }, []);

  const addToCollectionHandler = () => {
    setAddToCollectionModal(true);
  };

  const onAddExerciseToCollection = collections => {
    addExercise({
      variables: { exerciseId: exercise.id, programTemplateIds: collections },
      refetchQueries: [
        {
          query: QUERY_GET_TEMPLATES,
          variables: { entityId }
        }
      ]
    }).then(
      () => {
        notification.add('add_exercises', {
          message: intl.formatMessage(messages.messageExercisesAddedToTemplate)
        });
        setAddToCollectionModal(false);
      },
      err => {
        notification.add('edit_error', {
          message: intl.formatMessage(
            messages.messageExercisesAddedToTemplateError
          ),
          level: 'error'
        });
      }
    );
  };

  const onUpdated = () => {
    if (inCollection) {
      push(
        generatePath(ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES, {
          entityId,
          collectionId
        })
      );
    }

    if (inTemplate) {
      push(
        generatePath(ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE, {
          entityId,
          templateId
        })
      );
    }
  };

  if (loading)
    return (
      <Panel active={active}>
        <Loader />
      </Panel>
    );
  if (error) return <RetryPanel />;

  return (
    <Panel active={active}>
      <PanelHeader>
        {crumbs && <BreadcrumbPath crumbs={crumbs} />}
        <PanelTitleWrapper>
          <PanelTitle>
            <span title={exercise.title}>{exercise.title}</span>

            <PanelTitleButtonsGroup>
              {!inTraining && (
                <PanelTitleButton
                  primary
                  small
                  icon={'bookmark'}
                  iconFillColor={'transparent'}
                  iconStrokeColor={'color-neutral-x-light'}
                  onClick={() => addToCollectionHandler(exercise)}
                />
              )}

              {/*<PanelTitleButton
                primary
                small
                icon={'program'}
                iconFillColor={'transparent'}
                onClick={() => addToProgramHandler(exercise)}
              />*/}

              <MenuWrapper trigger={<PanelTitleButton />}>
                {ability.can('delete', exercise) && editable && (
                  <RemoveExerciseButton
                    entityId={entityId}
                    exerciseId={exerciseId}
                  />
                )}
                {template &&
                  ability.can('update', template) &&
                  inCollection &&
                  collectionId && (
                    <RemoveExercisesFromTemplateButton
                      entityId={entityId}
                      type="collection"
                      exerciseIds={[exerciseId]}
                      templateId={collectionId}
                      updated={() => onUpdated()}
                    />
                  )}

                {ability.can('update', template) &&
                  inTemplate &&
                  templateId && (
                    <RemoveExercisesFromTemplateButton
                      entityId={entityId}
                      type="template"
                      exerciseIds={[exerciseId]}
                      templateId={templateId}
                      updated={() => onUpdated()}
                    />
                  )}
              </MenuWrapper>
            </PanelTitleButtonsGroup>
          </PanelTitle>

          {!user.isGSVOrg && (
            <PanelSubtitle>
              <div className="u-margin-bottom-small">
                <FormattedMessage {...messages.exercisesLabelLevelSubtitle} />{' '}
                {exercise.level}
              </div>
            </PanelSubtitle>
          )}
          {exercise.tags && exercise.tags.length > 0 && (
            <Tags
              extraClassNames="u-margin-bottom-none"
              entityId={entityId}
              placeholder={``}
              tags={exercise.tags}
              isMulti
              isClearable={false}
              edit={false}
            />
          )}
          {user.isGSVOrg &&
            exercise?.entity?.id &&
            exercise.entity.id !== entityId && (
              <Organisation
                to={generatePath(ROUTE_EXERCISES_ENTITY_EXERCISE, {
                  entityId: exercise.entity.id,
                  exerciseId
                })}
                logo={exercise.entity.info && exercise.entity.info.logo}
                name={exercise.entity.name}
              />
            )}
          {/* <Organisation name="Eigen Oefening" /> */}
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        <Tabs defaultIndex={tabIndex}>
          <TabList>
            <Tab>
              <Link to={parentPath}>
                <FormattedMessage {...messages.exerciseTab} />
              </Link>
            </Tab>
            {inTraining && (
              <Tab>
                <Link to={`${parentPath}/details`}>
                  <FormattedMessage {...messages.exerciseDetailsTab} />
                </Link>
              </Tab>
            )}
          </TabList>
          <TabPanel>
            <TabPanelBody withScroller>
              {!user.isGSVOrg && (
                <div className="o-flex o-flex--column u-1-of-1 u-margin-top-medium">
                  <Can I={'translate'} an={exercise}>
                    <ExerciseEditLanguagesButton
                      entityId={entityId}
                      exerciseId={exerciseId}
                    />
                  </Can>
                </div>
              )}
              <ExercisePanel
                entityId={entityId}
                exercise={exercise}
                edit={edit}
                editable={editable && exercise.editable}
                setEdit={edit => setEdit(edit)}
              />
            </TabPanelBody>
          </TabPanel>
          {inTraining && (
            <TabPanel>
              <TabPanelBody withScroller>
                <ExerciseDetailsPanel entityId={entityId} exercise={exercise} />
              </TabPanelBody>
            </TabPanel>
          )}
        </Tabs>
      </PanelBody>
      {addToCollectionModal && (
        <AddExercisesToCollectionModal
          entityId={entityId}
          collectionType={user.isGSVOrg ? 'Collection' : 'Template'}
          onAdd={selected => onAddExerciseToCollection(selected)}
          onClose={() => {
            setAddToCollectionModal(false);
          }}
        />
      )}
      {/*{addToProgramModal && (
        <AddExercisesToCollectionModal
          entityId={entityId}
          onClose={() => {
            setAddToProgramModal(false);
          }}
        />
      )}*/}
    </Panel>
  );
};

export default Exercise;
