import { useState, useEffect } from 'react';
import Modal from 'components/modal/Modal';
import {
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderTitle
} from 'components/card/card-header';
import { FormattedMessage, useIntl } from 'react-intl';
import { CardBody, CardFooter } from 'components/card';
import CardBodyTools from 'components/card/CardBodyTools';
import { Button, ButtonsGroup } from 'components/button';
import messages from 'messages';
import Loader from 'components/loader/Loader';
import { useExercisesContext } from 'contexts/ExercisesContext';
import Icon from 'components/icon/Icon';
import TagsSelect from 'components/input/TagsSelect';
import Checkbox from 'components/input/Checkbox';
import Tags from 'containers/partials/inputs/Tags';
import useSelectable from 'hooks/utils/useSelectable';
import { useMutation, useQuery } from '@apollo/client';
import { QUERY_GET_TAGS } from 'services/aws/tags-query';
import { arrayToTree } from 'performant-array-to-tree';
import { sort } from 'utils/sort';
import { useUIDSeed } from 'react-uid';
import {
  MUTATION_EDIT_TEMPLATE,
  QUERY_GET_TEMPLATES
} from 'services/aws/templates-query';
import { useNotificationQueue } from 'components/notification';
import ExerciseViewCard from 'containers/pages/exercises/exercises/ExerciseViewCard';
import ExercisesListVirtualized from 'containers/partials/lists/ExercisesListVirtualized';
import classNames from 'classnames';
import FieldInput from 'components/input/FieldInput';

const AddExercisesModal = ({ entityId, template, onAdded, onClose }) => {
  const intl = useIntl();
  const uidSeed = useUIDSeed();
  const notification = useNotificationQueue();
  const {
    exercisesState,
    exercisesState: { filter: exerciseFilter },
    exerciseActions,
    loading
  } = useExercisesContext();
  const [filter, setFilter] = useState({ search: '', tags: [] });
  const [filtering, setFiltering] = useState(false);
  const [preview, setPreview] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const { selected, select, setSelected } = useSelectable();

  const [editTemplate] = useMutation(MUTATION_EDIT_TEMPLATE, {
    refetchQueries: [
      {
        query: QUERY_GET_TEMPLATES,
        variables: { entityId }
      }
    ]
  });

  const { data } = useQuery(QUERY_GET_TAGS, {
    variables: { entityId }
  });

  useEffect(() => {
    exerciseActions.getExercises();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (exerciseFilter?.tags) {
      setSelected(exerciseFilter.tags.map(t => t.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exerciseFilter]);

  useEffect(() => {
    if (data?.getTagsByEntity) {
      setTags(data.getTagsByEntity);
      setTagOptions(
        arrayToTree(
          sort(
            data.getTagsByEntity.map(({ id, label, parentId }) => ({
              id,
              label,
              value: label,
              parentId
            })),
            { keys: [{ key: 'order' }] }
          ),
          { dataField: null }
        )
      );
    }
  }, [data]);

  useEffect(() => {
    exerciseActions.setFilter({ ...filter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  const onAddHandler = async () => {
    await submitCollection([...template.exercises.map(e => e.id), ...selected]);
    if (onAdded) onAdded();
  };

  const submitCollection = async exerciseIds => {
    template.exerciseIds = [...exerciseIds];
    template.tagIds = !template?.tags ? [] : template.tags.map(tag => tag.id);

    await editTemplate({ variables: { ...template } }).then(({ data }) => {
      notification.add(data.editExerciseProgramTemplate.id, {
        message: intl.formatMessage(messages.messageEditTemplateSuccess)
      });
    });
  };

  const onSearch = e => {
    setFilter({ ...filter, search: e.target.value });
    exerciseActions.setFilter({ search: e.target.value });
  };

  const onTagSelect = (tags, actions) => {
    switch (actions.action) {
      case 'remove-value':
      case 'pop-value':
        select(actions.removedValue.id);
        break;
      case 'select-option':
        select(actions.option.id);
        break;
      default:
        break;
    }
  };

  const onTagCheck = (e, tag) => {
    select(tag);
  };

  const handleTagChange = tags => {
    exerciseActions.setFilter({ tags });
  };

  const onSubmitFilter = () => {
    setFiltering(false);
    exerciseActions.setFilter({
      tags: tags.filter(t => selected.includes(t.id))
    });
  };

  return (
    <Modal
      card
      isOpen
      onClose={onCloseHandler}
      extraClassNames="c-modal__content-bottom-auto"
    >
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.addExtraExercisesModalTitle} />
          {filtering && (
            <CardHeaderSubtitle>
              <FormattedMessage
                {...messages.collectionsAddExerciseFilterInModalMessage}
              />
            </CardHeaderSubtitle>
          )}
          {/* {preview && <CardHeaderSubtitle>{preview.title}</CardHeaderSubtitle>} */}
        </CardHeaderTitle>
      </CardHeader>

      {/* Bij filtering is de Body van de modal anders */}
      {filtering && (
        <CardBody modal bodyWithHeader>
          <div className="c-card-modal__body-head c-card-modal__body-head--small">
            <Button
              rounded
              light
              onClick={() => {
                setFiltering(false);
              }}
            >
              <Icon id="back-nav" mediumIcon />

              <FormattedMessage {...messages.buttonBack} />
            </Button>

            <ButtonsGroup extraClassNames="o-flex--justify-end">
              <Button
                secondary
                onClick={() => {
                  setFiltering(false);
                  setSelected([]);
                }}
              >
                <FormattedMessage {...messages.cancelButton} />
              </Button>

              <Button
                primary
                disabled={selected.length === 0}
                onClick={onSubmitFilter}
              >
                <FormattedMessage {...messages.labelApplyFilter} />
              </Button>
            </ButtonsGroup>
          </div>
          <div style={{ padding: '16px 16px 24px 24px' }}>
            {tagOptions.map(tag => {
              return (
                <div className="c-card__body-wrapper" key={tag.id}>
                  {tag.children.length > 12 ? (
                    <TagsSelect
                      label={tag.label}
                      options={tag.children}
                      value={tag.children
                        .filter(t => selected.includes(t.id))
                        .map(tag => tag && { ...tag, value: tag.label })}
                      isMulti
                      isClearable
                      onChange={(value, actions) => onTagSelect(value, actions)}
                    />
                  ) : (
                    <fieldset className="c-input-group">
                      <legend className="c-input__label">{tag.label}</legend>
                      {tag.children.map(tag => (
                        <Checkbox
                          secondary
                          key={tag.id}
                          id={uidSeed(tag.id)}
                          checked={selected.includes(tag.id)}
                          onChange={e => onTagCheck(e, tag.id)}
                        >
                          {tag.label}
                        </Checkbox>
                      ))}
                    </fieldset>
                  )}
                </div>
              );
            })}
          </div>
        </CardBody>
      )}

      {preview && (
        <CardBody modal bodyWithHeader>
          <div className="c-card-modal__body-head">
            <div className="c-card-modal__body-head-breadcrumb">
              <Button
                rounded
                light
                onClick={() => {
                  setPreview(false);
                }}
              >
                <Icon id="back-nav" mediumIcon />
                <FormattedMessage {...messages.buttonBack} />
              </Button>
              <Icon id="breadcrumb" fillColor="color-neutral-steel" />
              <span className="c-organisation">{preview.title}</span>
            </div>
          </div>
          <div className="c-card-modal__body-content c-card-modal__body-content-exercise">
            <ExerciseViewCard
              entityId={entityId}
              exercise={{ ...preview }}
              setEdit={false}
              editable={false}
            />
          </div>
        </CardBody>
      )}

      {!filtering && !preview && (
        // Als filtering is off, worden de (gefiltreerde) list items getoond
        <CardBody modal bodyWithHeader>
          {loading && <Loader />}
          <div
            className={classNames('c-card-modal__body-head', {
              'u-padding-bottom-none': exerciseFilter.tags.length > 0
            })}
          >
            <CardBodyTools extraClassNames="c-panel__tools-separated u-padding-none">
              <FieldInput
                id="title"
                rounded
                placeholder={intl.formatMessage(
                  messages.exercisesProgramFilterNameLabel
                )}
                onChange={onSearch}
                icon="search-grey"
                iconLeft
              />
              <Button rounded light onClick={() => setFiltering(true)}>
                <Icon id="filter" strokeColor="color-neutral-dark" />
                <FormattedMessage {...messages.filterButton} />
              </Button>
              {exerciseFilter.tags.length > 0 && (
                <Tags
                  extraClassNames="u-margin-left-none"
                  entityId={entityId}
                  label={intl.formatMessage(messages.exercisesLabelTags)}
                  tags={exerciseFilter.tags}
                  isMulti
                  isClearable={false}
                  dropdownDisabled={true}
                  onChange={handleTagChange}
                  placeholder={intl.formatMessage(
                    messages.exercisesProgramTagsLabel
                  )}
                  noOptionsMessage={intl.formatMessage(
                    messages.exercisesProgramTagsNoOption
                  )}
                  rounded
                />
              )}
            </CardBodyTools>
          </div>
          <div className="c-card-modal__body-content">
            <ExercisesListVirtualized
              onModal
              exercises={exercisesState.exercises.filter(
                e => !template.exercises.map(e => e.id).includes(e.id)
              )}
              checkable
              enablePreviewModal
              onPreview={exercise => {
                setPreview(exercise);
              }}
              onSelect={exerciseIds => setSelected(exerciseIds)}
            />
          </div>
        </CardBody>
      )}

      <CardFooter modal>
        {!filtering && !preview && (
          <ButtonsGroup>
            <Button secondary onClick={() => onCloseHandler()}>
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button
              disabled={selected.length === 0}
              primary
              onClick={() => onAddHandler()}
            >
              <FormattedMessage {...messages.addButton} />
            </Button>
          </ButtonsGroup>
        )}
      </CardFooter>
    </Modal>
  );
};

export default AddExercisesModal;
