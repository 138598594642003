import React, { Component } from 'react';
import { TableCell, TableRow } from 'components/table';
import ProgressBarGroup from 'components/progress-bar/ProgressBarGroup';
import ProgressBar from 'components/progress-bar/ProgressBar';
import { ProgressBarValueTypes } from 'constants.js';
import { getRandomInt } from 'utils/math';

export default class GrowthPredictionTableRowWaiting extends Component {
  render() {
    return (
      <TableRow>
        <TableCell>
          <ProgressBarGroup noData>
            <ProgressBar benchmarks unavailable value={getRandomInt(40) + 20} />
          </ProgressBarGroup>
        </TableCell>
        {Array(3)
          .fill()
          .map((v, i) => (
            <TableCell key={i}>
              <ProgressBarGroup noData>
                <ProgressBar
                  benchmarks
                  range={ProgressBarValueTypes.RED}
                  value={getRandomInt()}
                />
                <ProgressBar
                  benchmarks
                  range={ProgressBarValueTypes.AVERAGE}
                  value={50}
                />
              </ProgressBarGroup>
            </TableCell>
          ))}
      </TableRow>
    );
  }
}
