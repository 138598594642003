import React, { Fragment, Component } from 'react';
import classNames from 'classnames';

class AccordionButton extends Component {
  ref = React.createRef();

  render() {
    const props = this.props;
    return !props.customClickableElement ? (
      <Fragment>
        <input
          className={classNames('c-accordion__button', props.extraClassNames)}
          type={props.selfClosing ? 'checkbox' : 'radio'}
          name={props.name}
          id={props.id}
        />
        <label
          className={classNames('c-accordion__label', props.extraClassNames, {
            'c-accordion__label-empty': props.selectableButton,
            'c-accordion__label-selfclosing': props.selfClosing
          })}
          htmlFor={props.id}
        >
          {!props.selectableButton && !props.customClickableElement && (
            <span>{props.children}</span>
          )}
        </label>

        {props.selectableButton && (
          <button
            disabled={props.disabled}
            className={classNames(
              'c-button c-button--menu c-accordion__label',
              {
                'c-button--disabled': props.disabled
              }
            )}
            onClick={props.onClick}
          >
            {props.children}
          </button>
        )}
      </Fragment>
    ) : (
      <Fragment>
        {props.children}
        <input
          ref={this.ref}
          className={classNames(
            'c-accordion__button c-accordion__button-custom',
            props.extraClassNames
          )}
          type="checkbox"
          onChange={e => {
            props.onOpenClose &&
              props.onOpenClose(e.target.checked, () => {
                if (this.ref.current) {
                  this.ref.current.checked = e.target.checked;
                }
              });
          }}
          checked={props.isOpen ?? null}
          name={props.name}
          id={props.id}
        />
        <label
          className={classNames(
            'c-accordion__label c-accordion__label-custom',
            props.extraClassNames
          )}
          htmlFor={props.id}
        >
          {props.openLabel}
        </label>
      </Fragment>
    );
  }
}

export default AccordionButton;
