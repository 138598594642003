import React, { useState, useContext } from 'react';
import { withRouter, generatePath } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'components/modal/Modal';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import { CardBody, CardFooter } from 'components/card';
import messages from 'messages';
import Select, { Option } from 'components/input/Select';
import InjurySelect from '../inputs/InjurySelect';
import BenchmarksSelect from 'containers/partials/inputs/BenchmarksSelect';
import { TestSetV2Type } from 'constants.js';
import { StoreContext } from 'index';
import { FieldTextarea } from 'components/input/FieldTextarea';
import * as Yup from 'yup';
import FieldInput from 'components/input/FieldInput';
import { useNotificationQueue } from 'components/notification';
import { useTestSetContext } from 'contexts/TestSetContext';
import TestSet from 'models/TestSet';
import Button from 'components/button/Button';
import ButtonsGroup from 'components/button/ButtonsGroup';
import { ROUTE_SETTINGS_TESTSETS_TESTSET } from 'routes/RouteList';

export const TESTSET_UPDATE_TYPE = Object.freeze({
  ADD: 'Add',
  EDIT: 'Edit',
  COPY: 'Copy'
});

const TestSetModal = ({ type, testSet: initialTestSet, onClose }) => {
  const intl = useIntl();
  const {
    authStore,
    authStore: {
      user,
      user: { rootEntityId }
    },
    routing: { push }
  } = useContext(StoreContext);

  const { actions, loading } = useTestSetContext();

  const notification = useNotificationQueue();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage(messages.titleRequired))
  });

  const [testSet] = useState(
    initialTestSet ?? new TestSet({ type: TestSetV2Type.TALENT })
  );

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  const onSubmitHandler = async data => {
    if (
      (type === TESTSET_UPDATE_TYPE.ADD || type === TESTSET_UPDATE_TYPE.COPY) &&
      actions.findExistingTitle(data.title)
    ) {
      notification.add('testSetTitleError', {
        message: intl.formatMessage(messages.modalTestSetErrorExistingName),
        level: 'error'
      });
      return;
    }

    const testSetData = {
      entityId: rootEntityId,
      title: data.title,
      note: data.note || undefined,
      type: data.type,
      injuryId: data?.injury?.id || undefined,
      defaultBenchmarkId: data.defaultBenchmark?.id || undefined,
      testIds:
        TESTSET_UPDATE_TYPE.COPY === type
          ? data.tests.map(t => t.id)
          : undefined,
      originalId:
        TESTSET_UPDATE_TYPE.COPY === type ? testSet.originalId : undefined
    };

    let testSetId;

    switch (type) {
      case TESTSET_UPDATE_TYPE.EDIT:
        testSetId = await actions.editTestSet({ ...testSetData, id: data.id });
        break;
      case TESTSET_UPDATE_TYPE.ADD:
        testSetId = await actions.addTestSet(testSetData);
        break;
      case TESTSET_UPDATE_TYPE.COPY:
        testSetId = await actions.copyTestSet(testSetData);
        break;
    }

    if (type === TESTSET_UPDATE_TYPE.ADD || type === TESTSET_UPDATE_TYPE.COPY) {
      push(
        generatePath(ROUTE_SETTINGS_TESTSETS_TESTSET, {
          testSetId: testSetId,
          type: testSetData.type
        })
      );
    }

    if (onClose) onClose();

    await authStore.renewEntities();
  };

  return (
    <Modal card autoHeight isOpen={true} onClose={onCloseHandler}>
      <Formik
        initialValues={testSet}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmitHandler}
      >
        {({
          values: testSet,
          errors,
          isSubmitting,
          isValid,
          setFieldValue
        }) => (
          <Form>
            <CardHeader modal>
              <CardHeaderTitle>
                {intl.formatMessage(messages[`modal${type}TestSetTitle`])}
              </CardHeaderTitle>
              <CardHeaderSubtitle>
                {intl.formatMessage(messages[`modal${type}TestSetSubtitle`])}
              </CardHeaderSubtitle>
            </CardHeader>
            <CardBody modalWithOverflow>
              <FieldInput
                id="title"
                name="title"
                disabled={isSubmitting}
                errors={errors}
                autoFocus={type === TESTSET_UPDATE_TYPE.ADD}
              >
                <FormattedMessage {...messages.modalTestSetLabelName} />
              </FieldInput>
              {type === TESTSET_UPDATE_TYPE.ADD && (
                <Select
                  id="type"
                  name={'type'}
                  value={testSet.type || TestSetV2Type.TALENT}
                  label={intl.formatMessage(messages.labelTestSetType)}
                  onChange={select => {
                    setFieldValue('type', select.value);
                    if (
                      (select.value === TestSetV2Type.REHAB_TEMPLATE ||
                        select.value === TestSetV2Type.REHAB) &&
                      user.isHylyghtOrg
                    ) {
                      setFieldValue(
                        'defaultBenchmark',
                        '188a14db-d276-4cc4-ac20-b55bc252d266'
                      );
                    }
                  }}
                >
                  {/*{Object.values(TestSetV2Type).map(type => (
                    <Option key={type} value={type} label={type} />
                  ))}*/}
                  <Option
                    key={'talent'}
                    value={'talent'}
                    label={intl.formatMessage(messages.talentLabel)}
                  />
                  {user.isHylyghtOrg && (
                    <Option
                      key={'rehab_template'}
                      value={'rehab_template'}
                      label={'Rehab template'}
                    />
                  )}
                </Select>
              )}
              {user.isHylyghtOrg &&
                testSet.type !== TestSetV2Type.REHAB_TEMPLATE &&
                testSet.type !== TestSetV2Type.REHAB && (
                  <BenchmarksSelect
                    entityId={rootEntityId}
                    id={'defaultBenchmark'}
                    value={testSet.defaultBenchmark?.id}
                    onChange={selectedItem => {
                      setFieldValue('defaultBenchmark', selectedItem);
                    }}
                  />
                )}
              {(testSet.type === TestSetV2Type.REHAB_TEMPLATE ||
                testSet.type === TestSetV2Type.REHAB) && (
                <InjurySelect
                  entityId={rootEntityId}
                  id={'injury'}
                  name={'injury'}
                  personInjury={testSet.injury}
                  value={testSet.injuryId}
                  onChange={selectedItem => {
                    setFieldValue('injury', selectedItem);
                    setFieldValue('injuryId', selectedItem.id);
                  }}
                />
              )}
              <FieldTextarea
                id="note"
                name="note"
                rows={'3'}
                readOnly={false}
                disabled={isSubmitting}
                errors={errors}
                resizeVertical
              >
                <FormattedMessage {...messages.modalTestSetLabelNotes} />
              </FieldTextarea>
            </CardBody>
            <CardFooter modal>
              <ButtonsGroup>
                <Button secondary onClick={onCloseHandler} type="button">
                  <FormattedMessage {...messages.buttonCancel} />
                </Button>
                <Button
                  type="submit"
                  primary
                  disabled={loading || !isValid || isSubmitting}
                >
                  {type === TESTSET_UPDATE_TYPE.EDIT
                    ? intl.formatMessage(messages.modalTestSetButtonSave)
                    : intl.formatMessage(messages.modalTestSetButtonCreate)}
                </Button>
              </ButtonsGroup>
            </CardFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default withRouter(TestSetModal);
