import { cloneElement } from 'react';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper,
  ListItemStatus
} from 'components/list/list-item';
import { useUIDSeed } from 'react-uid';
import messages from 'messages';
import { useIntl } from 'react-intl';

const VirtualListItem = ({
  uid,
  virtualRow,
  titleLabel,
  titleAttribute,
  metaLabel,
  statusLabel,
  item,
  checkbox,
  active,
  clickable,
  onClick,
  onChange,
  meta,
  visible,

  outfaded,
  disabled, // not selectable

  icon,
  mediumIcon,
  checked = false,
  indeterminate,
  iconStrokeColor,
  supertitle
}) => {
  const uidSeed = useUIDSeed();
  const intl = useIntl();

  return (
    <ListItem
      active={active}
      checkbox={checkbox}
      onClick={onClick}
      clickable={clickable}
      disabled={disabled}
      outfaded={outfaded}
      group={icon === 'group'}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: 48,
        transform: `translateY(${virtualRow.start}px)`
      }}
    >
      {icon !== null && (
        <ListItemStatus
          itemId={uid}
          visible={checked || visible || indeterminate}
          checked={checked}
          disabled={disabled}
          indeterminate={indeterminate}
          onChange={onChange}
          mediumIcon={mediumIcon}
          statusIcon={icon}
          strokeColor={iconStrokeColor && iconStrokeColor}
        >
          {statusLabel && statusLabel}
        </ListItemStatus>
      )}
      <ListItemLabelWrapper>
        <ListItemLabel
          labelNotePreview
          supertitle={
            supertitle &&
            item.type === 'person_doc_application' &&
            item?.sharedWithAthlete
              ? intl.formatMessage(messages.videoAnnotationStatusShared)
              : ''
          }
        >
          <span
            className="c-list__item-title c-list__item-title-ellipsed"
            title={item[titleLabel]}
          >
            {item[titleLabel]}
          </span>
        </ListItemLabel>
        {meta &&
          cloneElement(meta, {
            ...meta.props,
            key: uidSeed(meta),
            item,
            metaLabel
          })}
      </ListItemLabelWrapper>
    </ListItem>
  );
};

export default VirtualListItem;
