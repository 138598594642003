import React, { useEffect, useState } from 'react';
import { QUERY_GET_PERSONS_OF_ENTITY } from 'services/aws/user-query';
import { useLazyQuery } from '@apollo/client';
import { sort } from 'utils/sort';
import Person from 'models/Person';

function useUserPool({ entityId }) {
  const [personPool, setPersonPool] = useState([]);
  const [getPool, { loading, error, data }] = useLazyQuery(
    QUERY_GET_PERSONS_OF_ENTITY,
    {
      variables: { entityId },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (data?.getPersonsOfEntity) {
      setPersonPool(
        sort(
          data.getPersonsOfEntity.map(person => new Person(person)),
          {
            keys: [{ key: 'firstname' }, { key: 'lastName' }]
          }
        )
      );
    }
  }, [data]);

  return { loading, error, personPool, getPool };
}

export default useUserPool;
