import axios from 'axios';
import gql from 'graphql-tag';
import { appSyncClient } from './app-sync';
import { gqlMutation } from './utils/mutate';
import { getGQLResult } from './utils/get';
import * as Sentry from '@sentry/react';

export const FILE_STATUS = Object.freeze({
  WAITING: 'waiting',
  FAILED: 'failed',
  AVAILABLE: 'available'
});

export const FILE_ACTION = Object.freeze({
  ADD: 'add',
  REPLACE: 'replace'
});

export const uploadFile = async ({
  entityId,
  file,
  linkId,
  type,
  action,
  onUploadProgress,
  isPublic,
  sharedWithAthlete
}) => {
  const addFile = await addFileMutation({
    entityId,
    linkId,
    type,
    filename: file.name,
    action,
    public: isPublic,
    sharedWithAthlete
  });
  await upload(addFile.signedUploadUrl, file, onUploadProgress);
  return addFile;
};

export const addMultipartFileMutation = async variables => {
  return new Promise(async resolve => {
    await gqlMutation(appSyncClient, {
      mutation: MUTATION_UPLOAD_MULTIPART_FILE,
      variables,
      update: (cache, { data: { addFile } }) => {
        if (addFile && addFile.id) {
          resolve(addFile);
        }
      }
    });
  });
};

export const addFileMutation = async variables => {
  return new Promise(async resolve => {
    await gqlMutation(appSyncClient, {
      mutation: MUTATION_UPLOAD_FILE,
      variables,
      update: (cache, { data: { addFile } }) => {
        if (addFile && addFile.id) {
          resolve(addFile);
        }
      }
    });
  });
};

export const finishMultipartFileMutation = async variables => {
  return new Promise(async resolve => {
    await gqlMutation(appSyncClient, {
      mutation: MUTATION_FINISH_MULTIPART_FILE,
      variables,
      update: (cache, { data: { finishMultipartFile } }) => {
        if (finishMultipartFile) {
          resolve(finishMultipartFile);
        }
      }
    });
  });
};

export const deleteFileMutation = async variables => {
  return new Promise(async resolve => {
    await gqlMutation(appSyncClient, {
      mutation: MUTATION_REMOVE_UPLOAD_FILE,
      variables,
      skip: !variables.fileId,
      update: (cache, { data: { archiveFile } }) => {
        if (archiveFile) {
          resolve();
        }
      }
    });
  });
};

export const upload = async (uploadURL, file, onUploadProgress) => {
  const uploadConfig = {
    headers: { 'content-type': file.type },
    onUploadProgress
  };
  await axios.put(uploadURL, file, uploadConfig);
};

export const getFileUrl = async variables => {
  const query = gql`
    query getFileDownloadLink($entityId: ID!, $key: String!) {
      getFileDownloadLink(key: $key, entityId: $entityId)
    }
  `;

  try {
    return await getGQLResult(appSyncClient, query, variables);
  } catch (er) {}
  return null;
};

export const getAllFiles = async variables => {
  const query = gql`
    query getAllFiles($entityId: ID!, $type: String!, $linkId: ID!) {
      getAllFiles(type: $type, entityId: $entityId, linkId: $linkId)
    }
  `;

  try {
    return await getGQLResult(appSyncClient, query, variables);
  } catch (er) {}
  return null;
};

export const importBenchmarkData = async ({
  entityId,
  benchmarkId,
  benchmarkTestId,
  file,
  onUploadProgress,
  onUploaded
}) => {
  try {
    const addFile = await uploadFile({
      entityId,
      linkId: benchmarkTestId,
      type: 'import',
      file,
      onUploadProgress
    });
    onUploaded();

    const fileId = addFile.id;

    await importBenchmarkDataItemMutation({
      benchmarkId: benchmarkId,
      testItemId: benchmarkTestId,
      fileId
    });

    return { fileId };
  } catch (er) {}
  return { error: true };
};

export const importSporters = async ({
  entityId,
  groupId,
  file,
  onUploadProgress,
  onUploaded
}) => {
  try {
    // mutation file & file upload
    const addFile = await uploadFile({
      entityId,
      linkId: groupId,
      type: 'import',
      file,
      onUploadProgress
    });
    onUploaded();

    const fileId = addFile.id;

    //for (var x = 0; x < 3; x++) {
    const rawData = await importSportersMutation({
      topLevelEntityId: entityId,
      entityId: groupId,
      fileId,
      test: true
    });

    const response = JSON.parse(rawData);
    if (response.error) {
      return { error: response.error };
    }
    return { athletes: [...response], fileId };
  } catch (er) {
    Sentry.captureException(er);
    return { error: er.message };
  }
};

export const importBenchmarkDataItemMutation = async variables => {
  return new Promise(async resolve => {
    await gqlMutation(appSyncClient, {
      mutation: MUTATION_IMPORT_BENCHMARK_ITEM,
      variables,
      update: (cache, { data }) => {
        if (data) {
          resolve(data);
        }
      }
    });
  });
};

export const importSportersMutation = async variables => {
  return new Promise(async resolve => {
    await gqlMutation(appSyncClient, {
      mutation: MUTATION_IMPORT_SPORTERS,
      variables,
      update: (cache, { data }) => {
        if (data) {
          resolve(data.importSporters2);
        }
      }
    });
  });
};

export const MUTATION_IMPORT_BENCHMARK_ITEM = gql`
  mutation importBenchmarkItem(
    $benchmarkId: ID!
    $testItemId: ID!
    $fileId: ID!
  ) {
    importBenchmarkItem(
      benchmarkId: $benchmarkId
      testItemId: $testItemId
      fileId: $fileId
    )
  }
`;

/*export const MUTATION_IMPORT_SPORTERS = gql`
  mutation importSporters(
    $fileId: ID!
    $entityId: ID!
    $topLevelEntityId: ID!
    $test: Boolean!
  ) {
    importSporters(
      fileId: $fileId
      entityId: $entityId
      topLevelEntityId: $topLevelEntityId
      test: $test
    )
  }
`;*/

export const MUTATION_IMPORT_SPORTERS = gql`
  mutation importSporters2(
    $fileId: ID
    $persons: AWSJSON
    $entityId: ID!
    $topLevelEntityId: ID!
    $test: Boolean!
  ) {
    importSporters2(
      fileId: $fileId
      persons: $persons
      entityId: $entityId
      topLevelEntityId: $topLevelEntityId
      test: $test
    )
  }
`;

export const MUTATION_UPLOAD_FILE = gql`
  mutation addFile(
    $entityId: ID!
    $linkId: ID!
    $filename: String!
    $type: String
    $action: FileAction
    $public: Boolean
    $sharedWithAthlete: Boolean
  ) {
    addFile(
      input: {
        entityId: $entityId
        linkId: $linkId
        type: $type
        action: $action
        filename: $filename
        public: $public
        sharedWithAthlete: $sharedWithAthlete
      }
    ) {
      id
      key
      signedUploadUrl
      url
    }
  }
`;

export const MUTATION_UPLOAD_MULTIPART_FILE = gql`
  mutation addFile(
    $entityId: ID!
    $linkId: ID!
    $filename: String!
    $type: String
    $action: FileAction
    $public: Boolean
    $multipart: Boolean
    $partCount: Int
  ) {
    addFile(
      input: {
        entityId: $entityId
        linkId: $linkId
        type: $type
        action: $action
        filename: $filename
        public: $public
        multipart: $multipart
        partCount: $partCount
      }
    ) {
      id
      key
      signedUploadUrls
      multipartUploadId
    }
  }
`;

export const MUTATION_FINISH_MULTIPART_FILE = gql`
  mutation finishMultipartFile(
    $key: String
    $uploadId: String
    $parts: AWSJSON
  ) {
    finishMultipartFile(
      input: { key: $key, uploadId: $uploadId, parts: $parts }
    )
  }
`;

export const QUERY_GET_UPLOADED_FILES_BY_LINK_ID = gql`
  query getFiles($entityId: ID!, $linkId: ID!) {
    getFiles(linkId: $linkId, entityId: $entityId) {
      id
      linkId
      filename
      key
      type
      status
      signedDownloadUrl
      meta
      date_created
      sharedWithAthlete
    }
  }
`;

export const QUERY_GET_FILE = gql`
  query getFile($fileId: ID!) {
    getFile(fileId: $fileId) {
      id
      linkId
      filename
      signedDownloadUrl
      key
      type
      meta
      status
      date_created
      sharedWithAthlete
    }
  }
`;

export const MUTATION_REMOVE_UPLOAD_FILE = gql`
  mutation archiveFile($fileId: ID!, $entityId: ID!) {
    archiveFile(fileId: $fileId, entityId: $entityId)
  }
`;

export const MUTATION_REMOVE_ALL_FILES = gql`
  mutation archiveAllFiles($linkId: ID!, $entityId: ID!, $type: String!) {
    archiveAllFiles(linkId: $linkId, entityId: $entityId, type: $type)
  }
`;

export const MUTATION_EDIT_FILE = gql`
  mutation editFile(
    $id: ID!
    $entityId: ID!
    $linkId: ID!
    $filename: String!
    $type: String
    $action: FileAction
    $public: Boolean
    $sharedWithAthlete: Boolean
  ) {
    editFile(
      input: {
        id: $id
        entityId: $entityId
        linkId: $linkId
        type: $type
        action: $action
        filename: $filename
        public: $public
        sharedWithAthlete: $sharedWithAthlete
      }
    ) {
      id
      entityId
      linkId
      url
      key
      type
      filename
      sharedWithAthlete
    }
  }
`;
