import { useHistory, useParams } from 'react-router-dom';
import { useTestSetContext } from 'contexts/TestSetContext';
import React, { useEffect, useState } from 'react';
import { getTestSetGroups } from 'utils/tests';
import List from 'components/list/List';
import ListHeader from 'components/list/ListHeader';
import ListColumnTitle from 'components/list/ListColumnTitle';
import ListBody from 'components/list/ListBody';
import TestSetListItem from 'containers/pages/settings/testsets/TestSetListItem';

const TestsList = () => {
  const { push } = useHistory();
  const { testSetId } = useParams();
  const { state } = useTestSetContext();
  const [groups, setGroups] = useState();

  useEffect(() => {
    if (state.testSets) {
      setGroups(getTestSetGroups(state.testSets));
    }
  }, [state.testSets]);

  return (
    <>
      {groups &&
        groups.map(group => (
          <List key={group.id}>
            {group.label && (
              <ListHeader oneTitle>
                <ListColumnTitle>{group.label}</ListColumnTitle>
              </ListHeader>
            )}
            <ListBody>
              {group.children.map(testSet => (
                <TestSetListItem
                  key={testSet.id}
                  testSet={testSet}
                  push={push}
                  active={testSetId === testSet.id}
                />
              ))}
            </ListBody>
          </List>
        ))}
    </>
  );
};

export default TestsList;
