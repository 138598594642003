import React, { useContext } from 'react';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody } from 'components/card';
import { StoreContext } from 'index';
import PreventionDetailExtras from 'containers/pages/prevention/PreventionDetailExtras';
import messages from 'messages';

function PreventionDetailExtraCard({ edit }) {
  const {
    authStore: { entityId }
  } = useContext(StoreContext);

  return (
    <Card secondaryCollapsible>
      <CardHeader secondary>
        <CardHeaderTitle>
          <FormattedMessage {...messages.titlePreventionDetailExtras} />
        </CardHeaderTitle>
      </CardHeader>
      <CardBody secondary>
        <PreventionDetailExtras entityId={entityId} edit={edit} />
      </CardBody>
    </Card>
  );
}

export default PreventionDetailExtraCard;
