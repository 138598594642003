import { useFormikContext } from 'formik';
import FieldInput from 'components/input/FieldInput';
import Select, { Option } from 'components/input/Select';
import { PreventionType, TestSetV2Type } from 'constants.js';
import SportSelect from 'containers/partials/inputs/SportSelect';
import { FieldDatePicker } from 'components/input/FieldDatePicker';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { FieldTextarea } from 'components/input/FieldTextarea';
import { useContext } from 'react';
import { StoreContext } from 'index';

function PreventionDetail({ entityId, edit }) {
  const intl = useIntl();
  const {
    uiState: { locale }
  } = useContext(StoreContext);
  const { values: session, errors, touched } = useFormikContext();
  return (
    <>
      <div className="o-layout">
        <div className="o-layout__item">
          <FieldInput
            id="name"
            name="name"
            errors={errors}
            touched={touched}
            readOnly={!edit}
          >
            <FormattedMessage {...messages.labelSessionName} /> *
          </FieldInput>
        </div>
      </div>
      <div className="o-layout o-layout--small">
        <div className="o-layout__item u-1-of-2">
          <FieldInput
            id="location"
            name="location"
            errors={errors}
            touched={touched}
            readOnly={!edit}
          >
            <FormattedMessage {...messages.labelSessionLocation} />
          </FieldInput>
        </div>
        <div className="o-layout__item u-1-of-2">
          <FieldDatePicker
            id="startdate"
            name="startdate"
            icon="calendar"
            placeholder={'dd/mm/yyyy'}
            errors={errors}
            touched={touched}
            readOnly={!edit}
            disabled={!edit}
            locale={locale}
            required
          >
            <FormattedMessage {...messages.labelSessionDate} /> *
          </FieldDatePicker>
        </div>
      </div>
      <div className="o-layout">
        <div className="o-layout__item">
          <FieldTextarea
            id="notes"
            name="notes"
            rows="1"
            errors={errors}
            touched={touched}
            readOnly={!edit}
            resizeVertical={edit}
          >
            <FormattedMessage {...messages.labelSessionNotes} />
          </FieldTextarea>
        </div>
      </div>
      {session.id && (
        <div className="o-layout o-layout--small">
          <div className="o-layout__item u-1-of-2">
            <Select
              id="preventionType"
              value={session.preventionType}
              label={intl.formatMessage(messages.preventionTypeSelect) + ' *'}
              placeholder={intl.formatMessage(messages.preventionTypeSelect)}
              readOnly
              errors={errors}
              touched={touched}
            >
              <Option
                value={PreventionType.BASIC}
                label={intl.formatMessage(messages.preventionBasicOption)}
              ></Option>
              <Option
                value={PreventionType.FULL}
                label={intl.formatMessage(messages.preventionFullOption)}
              ></Option>
            </Select>
          </div>
          <div className="o-layout__item u-1-of-2">
            <SportSelect
              entityId={entityId}
              type={TestSetV2Type.PREVENTION}
              preventionType={session.preventionType}
              readOnly
              value={session.sport?.id}
              errors={errors}
              touched={touched}
            />
          </div>
        </div>
      )}
      {/*<FieldInput
        disabled
        id="benchmark-id"
        name="benchmarkId"
        value={'Hylyght base'}
        readOnly={!edit}
      >
        Benchmarks *
      </FieldInput>*/}
    </>
  );
}

export default PreventionDetail;
