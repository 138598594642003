import React, { useContext } from 'react';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import { StoreContext } from 'index';

const BreadcrumbPath = ({ crumbs, isMine, inLesson, customLabel }) => {
  const {
    authStore: { user },
    routing: { push }
  } = useContext(StoreContext);

  if (!crumbs || crumbs.length === 0) {
    return <></>;
  }
  return (
    <Breadcrumbs>
      {/*{user.isVitaleOrg
        ? crumbs.map((crumb, i, array) => {
            let result;
            if (i === 0 && !inLesson) {
              result = isMine ? (
                <Breadcrumb to={crumb.path} key={i}>
                  My {crumb.label}
                </Breadcrumb>
              ) : (
                <Breadcrumb to={crumb.path} key={i}>
                  {crumb.label} shared with me
                </Breadcrumb>
              );
            } else if (i === array.length - 1 && inLesson && customLabel) {
              result = (
                <Breadcrumb to={crumb.path} key={i}>
                  {customLabel}
                </Breadcrumb>
              );
            } else {
              result = (
                <Breadcrumb to={crumb.path} key={i}>
                  {crumb.label}
                </Breadcrumb>
              );
            }

            return result;
          })
        : crumbs.map((crumb, i) => (
            <Breadcrumb to={crumb.path} key={i}>
              {crumb.label}
            </Breadcrumb>
          ))}*/}
      {crumbs.map((crumb, i) => (
        <Breadcrumb to={crumb.path} key={i}>
          {crumb.label}
        </Breadcrumb>
      ))}
    </Breadcrumbs>
  );
};

export default BreadcrumbPath;
