import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withRouter, generatePath } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { FinishedState } from 'enums';

import {
  ROUTE_SESSIONS,
  ROUTE_SESSIONS_ENTITY_SESSION,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE
} from 'routes/RouteList';
import { Panel, PanelHeader } from 'components/panel';
import {
  PanelTitleWrapper,
  PanelTitle,
  PanelSubtitle,
  PanelTitleButton,
  PanelTitleButtonsGroup
} from 'components/panel/panel-title';
import PanelBody from 'components/panel/PanelBody';
import messages from 'messages';
import { openNewWindow } from 'utils/browser';
import RemoveSporterFromSessionButton from 'containers/partials/buttons/RemoveSporterFromSessionButton';
import MenuWrapper from 'components/menu/MenuWrapper';
import { getTestSessionURL } from 'utils/url';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import MailReportButton from 'containers/partials/buttons/MailReportButton';
import { getAgeFromDobOnSession } from 'utils/date';
import { StoreContext } from 'index';
import { useSessionContext } from 'contexts/SessionContext';
import { usePersonContext } from 'contexts/PersonContext';
import DownloadSessionReportButton from 'containers/partials/buttons/DownloadSessionReportButton';
import BreadcrumbPath from 'containers/partials/misc/BreadcrumbPath';
import SporterTabsMTS from 'containers/pages/sessions/SporterTabsMTS';
import SporterTabsLegacy from 'containers/pages/sessions/SporterTabsLegacy';
import SporterTabs from './SporterTabs';
import DownloadSkReportButtons from 'containers/partials/buttons/DownloadSKReportButtons';

const SessionSporter = ({
  edit,
  entityId,
  sessionId,
  sporterId,
  programId,
  active
}) => {
  const intl = useIntl();
  const {
    authStore: { user },
    routing: { push },
    uiState: { locale }
  } = useContext(StoreContext);
  const { person } = usePersonContext();
  const { session, error: sessionError, options } = useSessionContext();
  const [testData, setTestData] = useState(false);

  useEffect(() => {
    if (session.id) {
      const testData = session.getTestData(sporterId);
      if (testData?.id) {
        setTestData(testData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  const isAdviceView = useRouteMatch(
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE
  );

  const openTest = (entityId, sessionId, packageType) => {
    const url = getTestSessionURL({
      entityId,
      sessionId,
      packageType,
      locale,
      sporterId
    });
    openNewWindow(url);
  };

  return (
    <Panel active={active} extraClassNames={isAdviceView ? 'u-1-of-1' : ''}>
      {person && (
        <>
          {sessionError && <RetryPanel />}
          {session && (
            <Fragment>
              <PanelHeader>
                <BreadcrumbPath
                  crumbs={[
                    {
                      path: ROUTE_SESSIONS,
                      label: intl.formatMessage(messages.breadcrumbSessions)
                    },
                    {
                      path: generatePath(ROUTE_SESSIONS_ENTITY_SESSION, {
                        sessionId,
                        entityId
                      }),
                      label: intl.formatMessage(messages.breadcrumbSession, {
                        session: session.name
                      })
                    },
                    {
                      path: generatePath(ROUTE_SESSIONS_ENTITY_SESSION, {
                        sessionId,
                        entityId
                      }),
                      label: intl.formatMessage(
                        messages.breadcrumbSessionSporter
                      )
                    }
                  ]}
                />
                <PanelTitleWrapper>
                  <PanelTitle>
                    {person.firstname} {person.lastname}
                    {!user.isAthlete() && (
                      <PanelTitleButtonsGroup>
                        {!testData &&
                          session.finished !== FinishedState.FINISHED &&
                          session.isMTS && (
                            <PanelTitleButton
                              primary
                              small
                              icon="test-white"
                              onClick={() =>
                                openTest(entityId, session.id, session.type)
                              }
                            />
                          )}

                        <MenuWrapper trigger={<PanelTitleButton />}>
                          {testData && (
                            <>
                              <DownloadSessionReportButton
                                session={session}
                                testDataId={testData.id}
                                options={options}
                              />
                              <DownloadSkReportButtons
                                session={session}
                                testData={testData}
                                benw={false}
                              />
                              <MailReportButton
                                menu
                                entityId={entityId}
                                session={session}
                                sporter={person}
                                testDataId={testData.id}
                                options={options}
                              >
                                <FormattedMessage
                                  {...messages.menuItemSessionMailReport}
                                />
                              </MailReportButton>
                            </>
                          )}

                          <RemoveSporterFromSessionButton
                            menu
                            rootEntityId={entityId}
                            sessionId={session.id}
                            ids={[sporterId]}
                            onComplete={() =>
                              push(
                                generatePath(
                                  ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS,
                                  { entityId, sessionId: session.id }
                                )
                              )
                            }
                          >
                            <FormattedMessage
                              {...messages.menuItemSessionRemoveSporter}
                            />
                          </RemoveSporterFromSessionButton>
                        </MenuWrapper>
                      </PanelTitleButtonsGroup>
                    )}
                  </PanelTitle>
                  <PanelSubtitle>
                    {session.startdate && person.birthdate && (
                      <FormattedMessage
                        {...messages.labelSessionSporterAgeAtTestDate}
                        values={{
                          age: getAgeFromDobOnSession(
                            person.birthdate,
                            session.startdate
                          )
                        }}
                      />
                    )}
                  </PanelSubtitle>
                </PanelTitleWrapper>
              </PanelHeader>
              <PanelBody fullWidth={isAdviceView}>
                {session?.id && (
                  <SporterTabsView
                    session={session}
                    entityId={entityId}
                    sessionId={sessionId}
                    sporterId={sporterId}
                    programId={programId}
                    testDataId={testData?.id}
                    edit={edit}
                  />
                )}
              </PanelBody>
            </Fragment>
          )}
        </>
      )}
    </Panel>
  );
};

const SporterTabsView = ({ session, ...props }) => {
  if (session.isTestSetV2) {
    return <SporterTabs {...props} />;
  } else if (session.isMTS) {
    return <SporterTabsMTS {...props} />;
  } else {
    if (session.isIDo) {
      return <SporterTabs {...props} />;
    } else {
      return <SporterTabsLegacy {...props} />;
    }
  }
};

export default withRouter(SessionSporter);
