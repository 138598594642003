import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { useNotificationQueue } from 'components/notification';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';

import messages from 'messages.js';
import {
  MUTATION_ADD_ANNOTATION,
  MUTATION_EDIT_ANNOTATION
} from 'services/aws/annotations-query';
import { FinishedState } from 'enums';
import {
  MUTATION_ADD_TEST_DATA,
  MUTATION_UPDATE_TEST_DATA
} from 'services/aws/session-query';

function AnnotationForm({
  annotation,
  personId,
  children,
  extraClassNames,
  onComplete,
  onCancel,
  refetch
}) {
  const notification = useNotificationQueue();
  const intl = useIntl();

  const validationSchema = Yup.object().shape({
    starttime: Yup.number()
      .required('Starttime is required')
      .typeError(intl.formatMessage(messages.timeValidation)),
    //endtime: Yup.number().required('Endtime is required'),
    score: Yup.number().required(intl.formatMessage(messages.scoreValidation)),
    note: Yup.string().required(
      intl.formatMessage(messages.annotationValidation)
    ),
    tags: Yup.array()
      .ensure()
      .length(
        2,
        intl.formatMessage(messages.dimensionsBehaviourValidationRequired)
      )
      .required(
        intl.formatMessage(messages.dimensionsBehaviourValidationRequired)
      )
  });

  const [editAnnotation] = useMutation(MUTATION_EDIT_ANNOTATION);
  const [addAnnotation] = useMutation(MUTATION_ADD_ANNOTATION);
  const [addTestData] = useMutation(MUTATION_ADD_TEST_DATA);
  const [updateTestData] = useMutation(MUTATION_UPDATE_TEST_DATA);

  const onSubmitHandler = async annotation => {
    const annotationObj = {
      linkId: annotation.linkId,
      fileId: annotation.fileId,
      testSetId: annotation.testSetId,
      starttime: annotation.starttime,
      endtime: annotation.endtime,
      type: annotation.type
    };

    const testDataObj = {
      score: annotation.score,
      note: annotation.note,
      tags: annotation.tags
    };

    if (!annotation.id) {
      await addAnnotation({
        variables: { ...annotationObj }
      }).then(async ({ data }) => {
        const annotationId = data.addAnnotation.id;
        // ADD TESTDATA
        await createTestData({
          personId,
          annotationId
        }).then(async ({ data }) => {
          // EDIT TESTDATA
          await editTestData(data.addTestData.id, testDataObj).then(() => {
            notification.add(annotationId, {
              message: intl.formatMessage(messages.messageAddAnnotationSuccess)
            });
            refetch && refetch();
            onComplete && onComplete({ id: annotationId });
          });
        });
      });
    } else {
      await editAnnotation({
        variables: { ...annotationObj, id: annotation.id }
      }).then(async () => {
        await editTestData(annotation.testData.id, testDataObj).then(() => {
          onComplete && onComplete(annotation.id);
        });
        notification.add(annotation.id, {
          message: intl.formatMessage(messages.messageEditAnnotationSuccess)
        });
        refetch && refetch();
        onComplete && onComplete(annotation);
      });
    }
  };

  const onCancelHandler = () => {
    onCancel && onCancel();
  };

  const createTestData = async ({ personId, annotationId }) => {
    return await addTestData({
      variables: {
        personId,
        linkId: annotationId,
        finished: FinishedState.STARTED
      }
    });
  };

  const editTestData = async (testDataId, values) => {
    const data = {
      'c107c331-673e-42a4-9332-17390f831290': {
        'c107c331-673e-42a4-9332-17390f831290': {
          2: [values.score]
        }
      },
      '5316e89d-b75b-4333-8e11-3fe618172346': {
        '5316e89d-b75b-4333-8e11-3fe618172346': {
          2: [values.tags]
        }
      },
      '73cae474-72ad-4f60-b49e-b210aee96592': {
        '73cae474-72ad-4f60-b49e-b210aee96592': {
          2: [values.note]
        }
      }
    };

    return await updateTestData({
      variables: {
        id: testDataId,
        data: JSON.stringify(data),
        finished: FinishedState.STARTED
      }
    });
  };

  return (
    <Formik
      initialValues={annotation}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
      onReset={onCancelHandler}
    >
      {props => {
        return (
          <Form
            className={classNames('c-form--annotations', extraClassNames)}
            noValidate
          >
            {typeof children === 'function' ? children(props) : children}
          </Form>
        );
      }}
    </Formik>
  );
}

export default AnnotationForm;
