import React, { useContext } from 'react';
import { withRouter, generatePath } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import {
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_EDIT
} from 'routes/RouteList';
import { TabList, Tab, TabPanel, Tabs, TabPanelBody } from 'components/tabs';
import messages from 'messages';
import MTSTestDataResultCard from 'containers/partials/cards/MTSTestDataResultCard';
import { StoreContext } from 'index';
import SporterTabsLegacy from 'containers/partials/cards/SporterReportCard';
import useTabIndex from 'hooks/utils/useTabIndex';

const SessionMTS = ({
  edit,
  entityId,
  sessionId,
  sporterId,
  testDataId,
  session
}) => {
  const {
    routing: { push }
  } = useContext(StoreContext);

  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT,
    [
      ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS,
      ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_EDIT
    ]
  ]);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
      <TabList>
        <Tab
          key="session.sporter.mtsscan"
          onClick={() =>
            push(
              `${ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION.replace(
                ':sessionId',
                sessionId
              )
                .replace(':entityId', entityId)
                .replace(':sporterId', sporterId)}`
            )
          }
        >
          <FormattedMessage {...messages.tabSessionSporterScan} />
        </Tab>

        <Tab
          key="session.sporter.mtsdata"
          onClick={() =>
            push(
              generatePath(
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS,
                {
                  sessionId,
                  entityId,
                  sporterId
                }
              )
            )
          }
        >
          <FormattedMessage {...messages.tabSessionSporterTests} />
        </Tab>
      </TabList>

      <TabPanel>
        <TabPanelBody withScroller>
          <SporterTabsLegacy
            testDataId={testDataId}
            entityId={entityId}
            rootEntityId={entityId}
            session={session}
          />
        </TabPanelBody>
      </TabPanel>
      {/* Tab > For MTS > Data Result */}
      <TabPanel key="session.sporter.mtsdata">
        <MTSTestDataResultCard
          edit={edit}
          entityId={entityId}
          rootEntityId={entityId}
          sporterId={sporterId}
          sessionId={sessionId}
          testDataId={testDataId}
          /*editUrl={generatePath(
            ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT_EDIT,
            {
              entityId,
              sporterId,
              sessionId
            }
          )}*/
          resetUrl={generatePath(
            ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT,
            {
              entityId,
              sporterId,
              sessionId
            }
          )}
        />
      </TabPanel>
    </Tabs>
  );
};

export default withRouter(SessionMTS);
