import Calibration from './Calibration';
export default class Scanner {
  id = '';
  name = '';
  type = '';
  vendor = '';
  coil = '';
  location = '';
  calibrations = [];
  constructor({
    id = '',
    name = '',
    type = '',
    vendor = '',
    location = '',
    coil = '',
    calibrations = []
  }) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.vendor = vendor;
    this.coil = coil;
    this.location = location;
    this.calibrations = calibrations
      ? calibrations.map(c => new Calibration(c))
      : [];
  }
}
