import React, { useState } from 'react';

const useSelectable = (initialState = []) => {
  const [selected, setAllSelected] = useState(initialState);

  const select = id => {
    let selectedIds;
    if (!selected.includes(id)) {
      selectedIds = [...selected, id];
    } else {
      selectedIds = [...selected.filter(s => s !== id)];
    }
    setAllSelected(selectedIds);
    return selectedIds;
  };

  const setSelected = ids => {
    setAllSelected(ids);
  };

  const addSelected = ids => {
    setSelected(Array.from(new Set([...selected, ...ids])));
  };

  const removeSelected = ids => {
    setSelected(selected.filter(id => !ids.includes(id)));
  };

  return { selected, select, setSelected, addSelected, removeSelected };
};

export default useSelectable;
