import { generatePath, useHistory, useParams } from 'react-router-dom';
import Panel from 'components/panel/Panel';
import PanelHeader from 'components/panel/PanelHeader';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import { FormattedMessage, useIntl } from 'react-intl';
import { ROUTE_SPORTERS_ENTITY } from 'routes/RouteList';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import messages from 'messages';
import PanelBody from 'components/panel/PanelBody';
import { TabList, Tabs, Tab, TabPanel } from 'components/tabs';
import Badge from 'components/badge/Badge';
import SporterCard from 'containers/partials/cards/SporterCard';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import Person from 'models/Person';
import TabPanelBody from 'components/tabs/TabPanelBody';

const AddSporter = ({ active }) => {
  const { entityId } = useParams();
  const intl = useIntl();
  const { push } = useHistory();
  const ability = useAbility(AbilityContext);

  const handleCancel = () => {
    push(generatePath(ROUTE_SPORTERS_ENTITY, { entityId }));
  };

  return (
    <Panel active={active}>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb
            onClick={() =>
              push(generatePath(ROUTE_SPORTERS_ENTITY, { entityId }))
            }
          >
            <FormattedMessage {...messages.breadcrumbSporters} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.titleSportersAddSporter} />
          </PanelTitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        <Tabs defaultIndex={ability.can('read', 'Sessions') ? 1 : 0}>
          <TabList>
            {ability.can('read', 'Sessions') && (
              <Tab disabled>
                <>
                  <Badge>0</Badge>
                  {intl.formatMessage(messages.tabSporterSession)}
                </>
              </Tab>
            )}
            <Tab>{intl.formatMessage(messages.tabSporterGlobal)}</Tab>
          </TabList>
          {ability.can('read', 'Sessions') && <TabPanel>'Empty'</TabPanel>}
          <TabPanel>
            <TabPanelBody withScroller>
              <SporterCard
                entityId={entityId}
                person={new Person({})}
                isAdd={true}
                onCancel={handleCancel}
              />
            </TabPanelBody>
          </TabPanel>
        </Tabs>
      </PanelBody>
    </Panel>
  );
};

export default AddSporter;
