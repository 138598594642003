import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION_RESULT_EDIT } from 'routes/RouteList';

import messages from 'messages';

import Icon from 'components/icon/Icon';
import Button from 'components/button/Button';

import { Card, CardFooter } from 'components/card';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderButtons
} from 'components/card/card-header';
import { isValueValidNumber } from 'utils/form';
import FieldInput from 'components/input/FieldInput';

class CalibrationResultForm extends React.Component {
  render() {
    const {
      scannerId,
      entityId,
      calibrationId,
      edit,
      onChange,
      onSave,
      onReset,
      loading,
      carnosine,
      creatine,
      tma,
      feedback,
      voxelSizeX,
      voxelSizeY,
      voxelSizeZ,
      signalWater,
      onBlur,
      routing: { push },
      ...props
    } = this.props;

    const isValidToSubmit = () =>
      isValueValidNumber(carnosine) &&
      isValueValidNumber(creatine) &&
      isValueValidNumber(tma) &&
      isValueValidNumber(voxelSizeX) &&
      isValueValidNumber(voxelSizeY) &&
      isValueValidNumber(voxelSizeZ) &&
      isValueValidNumber(signalWater);
    return (
      <Card secondary>
        <CardHeader secondary>
          <CardHeaderTitle>
            <FormattedMessage {...messages.scannerCalibrationTitleResult} />
          </CardHeaderTitle>
          <CardHeaderButtons>
            {edit ? (
              <Button
                type="submit"
                tiny
                disabled={loading || !isValidToSubmit()}
                onClick={e => {
                  e.preventDefault();
                  onSave();
                }}
              >
                <FormattedMessage {...messages.calibrationButtonSubmit} />
              </Button>
            ) : (
              <Button
                tiny
                onClick={e => {
                  e.preventDefault();
                  push(
                    `${ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION_RESULT_EDIT.replace(
                      ':entityId',
                      entityId
                    )
                      .replace(':calibrationId', calibrationId)
                      .replace(':scannerId', scannerId)}`
                  );
                }}
              >
                <Icon id="edit" />
              </Button>
            )}
          </CardHeaderButtons>
        </CardHeader>
        <FieldInput
          extraClassNames="c-input--border"
          id="carnosine"
          value={carnosine || ''}
          pattern="\d+(\.\d*)?"
          onChange={e => onChange(e, 'number')}
          readOnly={!edit}
          onBlur={e => onBlur(e, 'number')}
          icon={props['carnosineError'] ? 'validation-error' : ' '}
        >
          <FormattedMessage {...messages.calibrationCarnosineLabel} />*
        </FieldInput>

        <FieldInput
          extraClassNames="c-input--border"
          id="creatine"
          value={creatine}
          pattern="\d+(\.\d*)?"
          onChange={e => onChange(e, 'number')}
          readOnly={!edit}
          onBlur={e => onBlur(e, 'number')}
          icon={props['creatineError'] ? 'validation-error' : ' '}
        >
          <FormattedMessage {...messages.calibrationCreatineLabel} />*
        </FieldInput>
        <FieldInput
          extraClassNames="c-input--border"
          id="tma"
          value={tma}
          pattern="\d+(\.\d*)?"
          onChange={e => onChange(e, 'number')}
          onBlur={e => onBlur(e, 'number')}
          readOnly={!edit}
          icon={props['tmaError'] ? 'validation-error' : ' '}
        >
          <FormattedMessage {...messages.calibrationTmaLabel} />*
        </FieldInput>
        <div className="c-input__three-cols-group c-input__three-cols-group-label">
          <span className="c-input__label">
            <FormattedMessage {...messages.calibrationVoxelSizeLabel} />
          </span>
          <FieldInput
            extraClassNames="c-input--border"
            id="voxelSizeX"
            value={voxelSizeX}
            pattern="\d+(\.\d*)?"
            onChange={e => onChange(e, 'number')}
            readOnly={!edit}
            onBlur={e => onBlur(e, 'number')}
            icon={props['voxelSizeXError'] ? 'validation-error' : ' '}
          >
            <FormattedMessage {...messages.calibrationVoxelSizeXLabel} />*
          </FieldInput>
          <FieldInput
            extraClassNames="c-input--border"
            id="voxelSizeY"
            value={voxelSizeY}
            pattern="\d+(\.\d*)?"
            onChange={e => onChange(e, 'number')}
            readOnly={!edit}
            onBlur={e => onBlur(e, 'number')}
            icon={props['voxelSizeYError'] ? 'validation-error' : ' '}
          >
            <FormattedMessage {...messages.calibrationVoxelSizeYLabel} />*
          </FieldInput>
          <FieldInput
            extraClassNames="c-input--border"
            id="voxelSizeZ"
            value={voxelSizeZ}
            pattern="\d+(\.\d*)?"
            onChange={e => onChange(e, 'number')}
            readOnly={!edit}
            onBlur={e => onBlur(e, 'number')}
            icon={props['voxelSizeZError'] ? 'validation-error' : ' '}
          >
            <FormattedMessage {...messages.calibrationVoxelSizeZLabel} />*
          </FieldInput>
        </div>
        <FieldInput
          extraClassNames="c-input--border"
          id="signalWater"
          value={signalWater}
          pattern="\d+(\.\d*)?"
          onChange={e => onChange(e, 'number')}
          onBlur={e => onBlur(e, 'number')}
          readOnly={!edit}
          icon={props['signalWaterError'] ? 'validation-error' : ' '}
        >
          <FormattedMessage {...messages.calibrationSignalWaterLabel} />*
        </FieldInput>
        {(edit || feedback) && (
          <FieldInput
            extraClassNames="c-input--border"
            id="feedback"
            value={feedback}
            onChange={onChange}
            readOnly={!edit}
          >
            <FormattedMessage {...messages.calibrationFeedbackLabel} />
          </FieldInput>
        )}
        <CardFooter secondary>
          {edit && (
            <Button
              secondary
              disabled={loading}
              onClick={e => {
                e.preventDefault();
                onReset();
              }}
            >
              <FormattedMessage {...messages.scannerButtonCancel} />
            </Button>
          )}
          {edit && (
            <Button
              type="submit"
              primary
              onClick={e => {
                e.preventDefault();
                onSave();
              }}
              disabled={loading || !isValidToSubmit()}
            >
              <FormattedMessage {...messages.scannerButtonSubmit} />
            </Button>
          )}
        </CardFooter>
      </Card>
    );
  }
}

export default withRouter(injectIntl(inject('routing')(CalibrationResultForm)));
