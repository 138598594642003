import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import messages from 'messages';
import { useIntl } from 'react-intl';
import { QUERY_GET_BENCHMARKS } from 'services/aws/benchmark-query';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import FieldSelect from 'components/input/FieldSelect';

function BenchmarksFieldSelect({
  entityId,
  onChange,
  inline = false,
  style = {},
  readOnly,
  emptyOption = '',
  label,
  ...props
}) {
  const intl = useIntl();
  const [benchmarks, setBenchmarks] = useState([]);
  const [selectedBenchmark, setSelectedBenchmark] = useState();

  const { loading, error, data } = useQuery(QUERY_GET_BENCHMARKS, {
    variables: { entityId }
  });

  useEffect(() => {
    if (data?.getBenchmarks && data.getBenchmarks.length > 0) {
      let benchmarksData = data.getBenchmarks
        .filter(benchmark => !benchmark.draft)
        .map(benchmark => ({
          ...benchmark,
          key: benchmark.id,
          value: benchmark.id,
          label: benchmark.title
        }));

      benchmarksData = sort(benchmarksData, {
        keys: [
          {
            key: 'default',
            dataType: SORT_DATA_TYPES.BOOL,
            desc: true
          },
          { key: 'originalId' },
          {
            key: 'version',
            desc: true,
            dataType: SORT_DATA_TYPES.NUMBER
          }
        ]
      });

      if (emptyOption) {
        benchmarksData.unshift({
          id: 'default',
          key: 'default',
          value: 'default',
          label: emptyOption
        });
      }

      setBenchmarks([...benchmarksData]);
      setSelectedBenchmark(
        benchmarksData.find(benchmark => benchmark.id === props.value)
      );
    }
  }, [data, props.value]);

  const onSelectHandler = select => {
    onChange(benchmarks.find(benchmark => benchmark.id === select.value));
    setSelectedBenchmark(select);
  };

  if (error) return intl.formatMessage(messages.errorMessage, { message: '' });

  const getPlaceHolder = () => {
    if (loading) {
      return intl.formatMessage(messages.globalLoading);
    } else if (benchmarks.length === 0) {
      return intl.formatMessage(messages.noResultsOption);
    } else {
      return intl.formatMessage(messages.labelSessionBenchmark);
    }
  };

  return (
    <FieldSelect
      onChange={select => onSelectHandler(select)}
      value={selectedBenchmark ?? ''}
      readOnly={readOnly}
      placeholder={getPlaceHolder()}
      options={benchmarks}
      label={label}
    />
  );
}

export default BenchmarksFieldSelect;
