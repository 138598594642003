import React, { useEffect, useState } from 'react';
import Accordion from './Accordion';
import AccordionButton from './AccordionButton';
import AccordionContent from './AccordionContent';
import { Button } from '../button';
import Icon from '../icon/Icon';
import Radiobutton from 'components/input/Radiobutton';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper,
  ListItemStatus
} from '../list/list-item';

const AccordionLayer = ({
  org,
  org: { id, name, status, subEntities, location, type },
  crumbs,
  onOpenClose,
  checkboxListItems,
  onActiveTree,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTreeId, setActiveTreeId] = useState(null);
  const [closeHandler, setCloseHandler] = useState(null);

  useEffect(() => {
    setIsOpen(crumbs && crumbs[0].id === org.id);
  }, []);

  return (
    <Accordion
      extraClassNames={`${props.extraClassNames && props.extraClassNames} ${
        isOpen && !activeTreeId ? 'is-open' : ''
      } ${checkboxListItems ? 'c-accordion--list-items' : ''}`}
    >
      {() => {
        return (
          <div
            className={`c-accordion--custom-wrapper ${
              isOpen && !activeTreeId
                ? 'c-accordion--custom-wrapper-subleveled'
                : ''
            }`}
          >
            {isOpen && !activeTreeId && (
              <div className="c-accordion__back-button">
                <Button
                  onClick={() => {
                    closeHandler && closeHandler();
                    setIsOpen(false);
                    setCloseHandler(null);
                    onActiveTree && onActiveTree(null);
                  }}
                  rounded
                  ghost
                  extraClassNames="c-button--accordion-back"
                >
                  <Icon id="back-nav" />
                  {props.labelUp ? (
                    <React.Fragment>
                      {props.labelUp}{' '}
                      <span className="c-accordion__back-label-name">
                        {name}
                      </span>
                    </React.Fragment>
                  ) : (
                    `Back to ${name}`
                  )}
                </Button>
              </div>
            )}
            <AccordionButton
              id={id}
              customClickableElement
              extraClassNames={
                (!subEntities || subEntities.length <= 0) &&
                'c-accordion__button-no-child'
              }
              name={name}
              openLabel={
                subEntities &&
                subEntities.length > 0 &&
                `${subEntities.length} ${
                  props.labelArrow ? props.labelArrow : 'Orgs'
                }`
              }
              isOpen={crumbs && crumbs.find(o => o.id === org.id) ? true : null}
              onOpenClose={(isOpen, closeHandler) => {
                onOpenClose && onOpenClose(isOpen, closeHandler);
              }}
            >
              {checkboxListItems ? (
                <ListItem
                  checkbox
                  group={type === 'group'}
                  extraClassNames={`${
                    isOpen ? 'c-input__group-radiobutton-org-removed' : ''
                  }`}
                >
                  <ListItemStatus
                    statusIcon="group"
                    itemId={name + '-' + id}
                    visible
                    indeterminate={status.indeterminate}
                    defaultChecked={status.checked && !status.indeterminate}
                  />
                  <ListItemLabelWrapper>
                    <ListItemLabel label>{name}</ListItemLabel>
                    <ListItemLabel info>{location}</ListItemLabel>
                  </ListItemLabelWrapper>
                </ListItem>
              ) : (
                <Radiobutton
                  ref={props.defaultChecked === id ? props.selectedRef : null}
                  organisation
                  id={`radio_${id}`}
                  name="Organisation"
                  extraClassNames={`${
                    isOpen ? 'c-input__group-radiobutton-org-removed' : ''
                  }`}
                  defaultChecked={props.defaultChecked === id}
                  subLabel={location}
                  onChange={() => {
                    props.onChange({ id, name });
                  }}
                  disabled={isOpen}
                >
                  {name}
                </Radiobutton>
              )}
            </AccordionButton>
            <AccordionContent
              extraClassNames={`${
                isOpen ? 'content-open' : 'content-not-open'
              }`}
            >
              {subEntities &&
                subEntities.length > 0 &&
                subEntities.map(
                  org =>
                    (!activeTreeId || activeTreeId === org.id) && (
                      <AccordionLayer
                        {...props}
                        checkboxListItems={checkboxListItems}
                        key={org.id}
                        org={org}
                        crumbs={crumbs}
                        onOpenClose={(isOpen, closeHandler) => {
                          setIsOpen(isOpen);
                          setCloseHandler(closeHandler);
                          const sendId = isOpen ? id : null;
                          props.onActiveTree && props.onActiveTree(sendId);
                        }}
                        onActiveTree={id => {
                          setActiveTreeId(id);
                        }}
                      />
                    )
                )}
            </AccordionContent>
          </div>
        );
      }}
    </Accordion>
  );
};

export default AccordionLayer;
