import awsconfig from 'services/aws/config/aws-exports';
import * as Sentry from '@sentry/react';

import { Amplify, Auth } from 'aws-amplify';

export const connect = async () => {
  Amplify.configure(awsconfig);
};

export const loginWithEmailAndPassword = async (email, password) => {
  return await Auth.signIn(email, password);
};

export const getCurrentSession = async () => {
  try {
    const currentSession = await Auth.currentSession();
    const currentAccessToken = await currentSession.accessToken;
    const currentJwtToken = await currentAccessToken.jwtToken;
    return {
      authenticated: true,
      jwtToken: currentJwtToken
    };
  } catch (error) {
    Sentry.captureException(error);
    return { authenticated: false };
  }
};

export const completeNewPassword = async (cognitoUser, password) => {
  return await Auth.completeNewPassword(cognitoUser, password);
};

export const signOut = async () => {
  await Auth.signOut();
};

export const getCurrentAuthenticatedUser = async () => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (error) {
    throw error;
  }
};
