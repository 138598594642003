import React, { useContext, useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import Button from 'components/button/Button';

import {
  ROUTE_SETTINGS_USERS_ADD,
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_USERS_USER
} from 'routes/RouteList';
import {
  MUTATE_RESET_LOGIN,
  QUERY_GET_USER_BY_ID,
  QUERY_GET_USERS_BY_ENTITY_ID
} from 'services/aws/user-query';
import Entity from 'models/Entity';
import Panel from 'components/panel/Panel';
import PanelHeader from 'components/panel/PanelHeader';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelSubtitle from 'components/panel/panel-title/PanelSubtitle';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Icon from 'components/icon/Icon';
import PanelTools from 'components/panel/PanelTools';
import { sortUsers } from 'utils/sort';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import Loader from 'components/loader/Loader';
import { StoreContext } from 'index';
import { useQuery } from '@apollo/client';
import MessageText from 'components/message/MessageText';
import Message from 'components/message/Message';
import User from 'models/User';
import { useNotificationQueue } from 'components/notification';
import VirtualizedEntityList from 'containers/pages/sporters/VirtualizedEntityList';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';

const Users = ({ userId }) => {
  const ability = useAbility(AbilityContext);
  const { push } = useHistory();
  const {
    uiState,
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const intl = useIntl();
  const notification = useNotificationQueue();
  const [users, setUsers] = useState([]);

  const { loading, error, data, refetch } = useQuery(
    QUERY_GET_USERS_BY_ENTITY_ID,
    {
      variables: { id: rootEntityId }
    }
  );

  useEffect(() => {
    if (data?.getEntity) {
      const entity = new Entity(data.getEntity);
      setUsers(sortUsers(entity.users.map(user => new User(user))));
    }
  }, [data]);

  const onClickPersonStatusHandler = person => {
    uiState.showModal({
      title: intl.formatMessage(messages.modalConfirmResetPasswordTitle),
      message: intl.formatMessage(messages.modalConfirmResetPasswordMessage),
      okLabel: intl.formatMessage(
        messages.modalConfirmResetPasswordAcceptButton
      ),
      dismissLabel: intl.formatMessage(messages.modalConfirmRemoveCancelButton),
      mutationData: {
        mutation: MUTATE_RESET_LOGIN,
        variables: {
          personId: person.id
        },
        refetchQueries: [
          {
            query: QUERY_GET_USERS_BY_ENTITY_ID,
            variables: { id: rootEntityId }
          },
          {
            query: QUERY_GET_USER_BY_ID,
            variables: {
              id: person.id
            }
          }
        ],
        update: (cache, { data: { updatePersonEntityRoles } }) => {
          if (updatePersonEntityRoles) {
            refetch();
            notification.add(`emailReset_${person.id}`, {
              message: intl.formatMessage(messages.messageUserEmailReset)
            });
          }
        }
      }
    });
  };

  return (
    <Panel>
      <PanelHeader borderBottom extraClassNames="u-margin-bottom-none">
        <Breadcrumbs>
          <Breadcrumb onClick={() => push(ROUTE_SETTINGS)}>
            <FormattedMessage {...messages.breadcrumbSettings} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.titleUsers} />
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage {...messages.subtitleUsers} />
          </PanelSubtitle>
        </PanelTitleWrapper>
        <PanelTools extraClassNames="u-margin-bottom-none">
          {ability.can('create', 'Users') && (
            <Button
              rounded
              primary
              onClick={() => push(ROUTE_SETTINGS_USERS_ADD)}
            >
              <Icon id="add-white" />
              <FormattedMessage {...messages.buttonUsersAddUser} />
            </Button>
          )}
        </PanelTools>
      </PanelHeader>
      {loading && <Loader />}
      {error && <p>Error! {error.message}</p>}
      {!loading && (
        <>
          {users.length > 0 ? (
            <VirtualizedEntityList
              items={users}
              activeId={userId}
              extraClassNames="u-padding-top-medium"
              personStatus
              onClick={(person, type) => {
                push(
                  generatePath(ROUTE_SETTINGS_USERS_USER, {
                    userId: person.id
                  })
                );
              }}
              onClickStatus={person => onClickPersonStatusHandler(person)}
            />
          ) : (
            <Message emptyState icon="sporters">
              <MessageText>
                <FormattedMessage {...messages.noUsersAddedMessage} />
              </MessageText>
            </Message>
          )}
        </>
      )}
    </Panel>
  );
};

export default Users;
