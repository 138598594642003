import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { TableCell } from 'components/table';
import ProgressBlockValue from 'components/progress-bar/ProgressBlock';
import GrowthPredictionTableWarning from './GrowthPredictionTableWarning';

class GrowthPredictionTableCell extends Component {
  render() {
    const { data, inTooltip, onClick } = this.props;

    return inTooltip ? (
      <div className="c-table__content">
        {data.value && (
          <ProgressBlockValue range={data.range} noClick={data.noClick}>
            {data.value}
            {data.unit}
          </ProgressBlockValue>
        )}
        {data.value && data.alert && <GrowthPredictionTableWarning />}
      </div>
    ) : (
      <TableCell>
        <div className="c-table__content">
          {data.value && (
            <ProgressBlockValue
              range={data.range}
              noClick={data.noClick}
              onClick={onClick}
            >
              {data.value}
              {data.unit}
            </ProgressBlockValue>
          )}
          {data.value && data.alert && <GrowthPredictionTableWarning />}
        </div>
      </TableCell>
    );
  }
}

export default withRouter(
  injectIntl(
    inject('routing', 'authStore')(observer(GrowthPredictionTableCell))
  )
);
