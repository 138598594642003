import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { ROUTE_SETTINGS, ROUTE_SETTINGS_ORGANISATIONS } from 'routes/RouteList';
import { FormattedMessage } from 'react-intl';
import Panel from 'components/panel/Panel';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelSubtitle from 'components/panel/panel-title/PanelSubtitle';
import PanelHeader from 'components/panel/PanelHeader';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import PanelBody from 'components/panel/PanelBody';
import Card from 'components/card/Card';
import { MenuWrapper } from 'components/menu';
import { QUERY_GET_ORGANISATION_BY_ID } from 'services/aws/entity-query';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import { PanelTitleButton } from 'components/panel';
import RemoveOrganisationButton from 'containers/partials/buttons/RemoveOrganisationButton';
import messages from 'messages';
import Loader from 'components/loader/Loader';
import { useQuery } from '@apollo/client';
import { StoreContext } from 'index';
import OrganisationEntity from 'models/Organisation';
import OrganisationForm from 'containers/pages/settings/organisations/OrganisationForm';
import OrganisationFormInput from 'containers/pages/settings/organisations/OrganisationFormInput';
import OrganisationSettings from 'containers/pages/settings/organisations/OrganisationSettings';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import useEdit from 'hooks/utils/useEdit';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';

const Organisation = ({ organisationId = null, ...props }) => {
  const ability = useAbility(AbilityContext);
  const { push } = useHistory();
  const {
    authStore: {
      user,
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const [edit, setEdit] = useEdit(props.edit, true);
  const [organisation, setOrganisation] = useState(
    new OrganisationEntity({ baseLanguage: user.baseLanguage })
  );

  const { data, loading, error } = useQuery(QUERY_GET_ORGANISATION_BY_ID, {
    variables: { id: organisationId },
    skip: !organisationId
  });

  useEffect(() => {
    if (data?.getEntity) {
      setOrganisation(new OrganisationEntity(data.getEntity));
    }
  }, [data]);

  return (
    <Panel>
      {loading && <Loader />}
      {error && <RetryPanel />}

      <Fragment>
        <PanelHeader borderBottom>
          <Breadcrumbs>
            <Breadcrumb onClick={() => push(ROUTE_SETTINGS)}>
              <FormattedMessage {...messages.breadcrumbSettings} />
            </Breadcrumb>
            <Breadcrumb onClick={() => push(ROUTE_SETTINGS_ORGANISATIONS)}>
              <FormattedMessage {...messages.breadcrumbOrganisations} />
            </Breadcrumb>
          </Breadcrumbs>
          <PanelTitleWrapper>
            <PanelTitle>
              {organisation.name ? (
                `${organisation.name}`
              ) : (
                <FormattedMessage {...messages.organisationsAddTitle} />
              )}
              <MenuWrapper trigger={<PanelTitleButton />}>
                {ability.can('delete', 'Organisations') && (
                  <RemoveOrganisationButton
                    menu
                    parentId={rootEntityId}
                    organisationId={organisationId}
                  >
                    <FormattedMessage
                      {...messages.organisationsMenuItemRemoveOrganisation}
                    />
                  </RemoveOrganisationButton>
                )}
              </MenuWrapper>
            </PanelTitle>
            <PanelSubtitle>
              <FormattedMessage {...messages.organisationsAddSubTitle} />
            </PanelSubtitle>
          </PanelTitleWrapper>
        </PanelHeader>
        <PanelBody>
          <OrganisationForm
            organisationId={organisationId}
            organisation={organisation}
            onComplete={() => setEdit(false)}
            allowFeatureEdit={true}
          >
            {({ submitForm, isValid, isSubmitting, resetForm }) => (
              <Card secondary multiBody>
                <OrganisationFormInput
                  organisation={organisation}
                  edit={edit}
                />
                <OrganisationSettings edit={edit} />
                <FormEditFooterButtons
                  edit={edit}
                  setEdit={setEdit}
                  onCancel={() => {
                    resetForm();
                  }}
                  onSubmit={submitForm}
                  disabledSubmit={!isValid || isSubmitting}
                  topMargin
                  extraClassNames={'c-card__footer-bottom-fixed'}
                />
              </Card>
            )}
          </OrganisationForm>
        </PanelBody>
      </Fragment>
    </Panel>
  );
};

export default withRouter(Organisation);
