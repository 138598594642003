export const findStringInEntity = (entity, str) => {
  if (!entity) return false;

  const trimmedString = str.toLowerCase().trim();
  const entityName = entity.title.toLowerCase().trim();

  if (entityName.indexOf(trimmedString) !== -1) {
    return true;
  }
};

export const filterByString = (entities, search) => {
  if (!search) return entities;
  return entities.filter(e => findStringInEntity(e, search));
};

export const filterByTags = (entities, tags) => {
  if (!tags || tags.length === 0) return entities;
  return entities.filter(
    entity =>
      entity.tags &&
      tags.every(tag => entity.tags.some(et => et.label === tag.label))
  );
};

export const getFilteredEntities = (entities, search, tags, personId) => {
  const entitiesWithTags = filterByTags(entities, tags);
  const entitiesByFilter = filterByString(entitiesWithTags, search);

  if (!personId) return entitiesByFilter;
  return entitiesByFilter.filter(e => e.person?.id === personId);
};
