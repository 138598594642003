import classNames from 'classnames';

const PanelTools = props => {
  const { extraClassNames, tabTools, separated, children } = props;
  return (
    <div
      className={classNames('c-panel__tools', extraClassNames, {
        'c-panel__tools--on-tabs': tabTools,
        'c-panel__tools--separated': separated
      })}
    >
      {children}
    </div>
  );
};

export default PanelTools;
