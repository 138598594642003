import React, { Fragment, useState } from 'react';

import Panel from 'components/panel/Panel';
import PanelBody from 'components/panel/PanelBody';
import SelectExercisesCard from 'containers/partials/cards/SelectExercisesCard';
import SelectedExercisesCard from 'containers/partials/cards/SelectedExercisesCard';
import { NoteTypes } from 'constants.js';
import { usePreviewModalContext } from 'contexts/PreviewModalContext';
import ProgramForm from 'containers/pages/exercises/programs/ProgramForm';

const ProgramExercisePanel = ({
  entityId,
  program,
  edit: initialEdit,
  onUse
}) => {
  const [edit, setEdit] = useState(initialEdit);
  const { setModalData } = usePreviewModalContext();

  return (
    <Fragment>
      <Panel>
        <PanelBody>
          <SelectExercisesCard
            entityId={entityId}
            edit={edit}
            onEdit={edit => setEdit(edit)}
            onPreview={exercise =>
              setModalData({ type: 'exercise', data: exercise })
            }
          />
        </PanelBody>
      </Panel>
      <Panel>
        <PanelBody>
          <ProgramForm
            style={{ flex: 1 }}
            entityId={entityId}
            program={program}
            onComplete={() => setEdit(false)}
          >
            {() => (
              <SelectedExercisesCard
                entityId={entityId}
                edit={edit}
                onEdit={edit => setEdit(edit)}
                onPreview={exercise =>
                  setModalData({ type: 'exercise', data: exercise })
                }
                noteType={NoteTypes.PROGRAM}
                editableNotes={true}
                onUse={onUse}
              />
            )}
          </ProgramForm>
        </PanelBody>
      </Panel>
    </Fragment>
  );
};

export default ProgramExercisePanel;
