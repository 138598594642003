import classNames from 'classnames';

const CardHeaderText = props => {
  const { children, style, extraClassNames } = props;

  return (
    <div
      className={classNames('c-card__header-text-group', extraClassNames)}
      style={style}
    >
      {children}
    </div>
  );
};

export default CardHeaderText;
