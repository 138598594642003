import gql from 'graphql-tag';

export const MUTATION_ADD_SCREENING = gql`
  mutation addScreening($personInjuryId: ID!, $testSession: TestSessionInput!) {
    addScreening(personInjuryId: $personInjuryId, testSession: $testSession) {
      id
    }
  }
`;

export const MUTATION_EDIT_SCREENING = gql`
  mutation editScreening($personInjuryId: ID!, $testSession: TestSessionEdit!) {
    editScreening(personInjuryId: $personInjuryId, testSession: $testSession) {
      id
    }
  }
`;

export const MUTATION_ARCHIVE_SCREENING = gql`
  mutation archiveScreening($personInjuryId: ID!, $testSessionId: ID!) {
    archiveScreening(
      personInjuryId: $personInjuryId
      testSessionId: $testSessionId
    )
  }
`;
