import { Card, CardBody } from 'components/card';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import FormEditHeaderButtons from '../buttons/FormEditHeaderButtons';
import FormEditFooterButtons from '../buttons/FormEditFooterButtons';
import InjuryForm from 'containers/pages/rehab/injury/InjuryForm';
import InjuryDetailFields from 'containers/pages/rehab/injury/InjuryDetailFields';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import useEdit from 'hooks/utils/useEdit';
import InjuryDetailMoreFields from 'containers/pages/rehab/injury/InjuryDetailMoreFields';

function InjuriesDetailCard({
  entityId,
  personInjury,
  medical,
  sporterId,
  ...props
}) {
  const [edit, setEdit] = useEdit(props.edit, true);

  return (
    <Card secondary>
      <InjuryForm
        entityId={entityId}
        sporterId={sporterId}
        personInjury={personInjury}
        medical={medical}
      >
        {({ submitForm, resetForm }) => (
          <>
            <CardHeader secondary>
              <CardHeaderTitle>
                <FormattedMessage {...messages.titleInjuryDetails} />
              </CardHeaderTitle>
              <FormEditHeaderButtons
                edit={edit}
                setEdit={setEdit}
                onCancel={resetForm}
                onSubmit={submitForm}
                type={'button'}
              />
            </CardHeader>
            <CardBody secondary>
              <div className="o-layout o-layout--small">
                <InjuryDetailFields edit={edit} />
                <InjuryDetailMoreFields
                  edit={edit}
                  showMore={personInjury.hasMoreInfo}
                />
              </div>
            </CardBody>
            <FormEditFooterButtons
              edit={edit}
              setEdit={setEdit}
              onCancel={resetForm}
              onSubmit={submitForm}
              type={'button'}
            />
          </>
        )}
      </InjuryForm>
    </Card>
  );
}

export default InjuriesDetailCard;
