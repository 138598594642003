class BackController {
  path;
  back = push => {
    const urlVariables = this.path.split('/');
    //TODO @maarten waarom niet gewoon terugkeren in history stack
    if (urlVariables.length > 5) {
      const newUrlVariables = urlVariables.slice(0, 4);
      push(newUrlVariables.join('/'));
    } else if (urlVariables.length > 3) {
      const newUrlVariables = urlVariables.slice(0, 3);
      push(newUrlVariables.join('/'));
    } else if (urlVariables.includes('settings') && urlVariables.length > 2) {
      const newUrlVariables = urlVariables.slice(0, 2);
      push(newUrlVariables.join('/'));
    }
  };

  hasBack() {
    const urlVariables = this.path.split('/');
    if (
      urlVariables.length > 3 ||
      (urlVariables.includes('settings') && urlVariables.length > 2)
    ) {
      return true;
    }
    return false;
  }

  setPath(path) {
    this.path = path;
  }
}

export const backController = new BackController();
