import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  addMultipartFileMutation,
  deleteFileMutation,
  FILE_ACTION,
  finishMultipartFileMutation
} from 'services/aws/file-upload';
import './FileUpload.scss';
import { StatusBar, useUppy } from '@uppy/react';
import Uppy from '@uppy/core';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import DropTarget from '@uppy/drop-target';
import ThumbnailGenerator from '@uppy/thumbnail-generator';

import '@uppy/core/dist/style.css';
import '@uppy/drop-target/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import '@uppy/progress-bar/dist/style.css';
import '@uppy/file-input/dist/style.css';
import { getvalidFileType } from 'utils/form';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Icon from 'components/icon/Icon';
import FileInput from '@uppy/file-input';

const FileUppyWrapper = ({
  children,
  type,
  accept,
  linkId,
  entityId,
  maxFiles = null,
  numFiles = 0,
  onFilesUploaded,
  isPublic,
  sharedWithAthlete
}) => {
  const intl = useIntl();
  let signedUploadUrls = {};
  let files = [];

  const addFile = async (file, parts, linkId, type) => {
    try {
      const fileType = await getvalidFileType(file, accept);

      return await addMultipartFileMutation({
        entityId,
        filename: file.name,
        linkId,
        type: `${type}_${fileType}`,
        action: FILE_ACTION.ADD,
        isPublic: isPublic ? true : undefined,
        multipart: true,
        partCount: parts,
        sharedWithAthlete: sharedWithAthlete ?? false
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };
  const [uploading, setUploading] = useState(false);

  const deleteFile = async (file, entityId) => {
    await deleteFileMutation({
      entityId,
      fileId: file.id
    });
  };

  const uppy = useUppy(() => {
    return new Uppy({
      debug: false,
      autoProceed: true,
      restrictions: { allowedFileTypes: accept, maxNumberOfFiles: maxFiles }
    }).use(AwsS3Multipart, {
      limit: 5,
      retryDelays: [0, 1000, 3000, 5000],
      getChunkSize: file => {
        const MB = 1024 * 1024;
        return Math.max(5 * MB, Math.ceil(file.size / 500));
      },
      createMultipartUpload: async file => {
        const parts = Math.ceil(file.data.size / 5242880);
        const addedFile = await addFile(file, parts, linkId, type);
        files.push({ id: addedFile.id, linkId, uppyId: file.id });
        signedUploadUrls[addedFile.multipartUploadId] = [
          null,
          ...addedFile.signedUploadUrls
        ];
        return {
          uploadId: addedFile.multipartUploadId,
          key: addedFile.key
        };
      },
      //listParts: (file, { uploadId, key }) => {},
      prepareUploadParts: (file, partData) => {
        return new Promise(resolve => {
          resolve({ presignedUrls: signedUploadUrls[partData.uploadId] });
        });
      },
      completeMultipartUpload: async (file, { uploadId, key, parts }) => {
        await finishMultipartFileMutation({
          key,
          uploadId,
          parts: JSON.stringify(parts)
        });

        return new Promise(resolve => {
          resolve({
            location: ''
          });
        });
      },
      abortMultipartUpload: async (file, { uploadId, key, parts }) => {
        // TODO Mutation om multipart files te wissen
        const toDeleteFile = files.find(f => f.uppyId === file.id);
        if (toDeleteFile) await deleteFile(toDeleteFile, entityId);
      }
    });
  });

  useEffect(() => {
    uppy.use(DropTarget, {
      target: '.c-media__upload'
    });

    uppy.on('upload', data => {
      setUploading(true);
    });

    uppy.on('complete', result => {
      if (onFilesUploaded) onFilesUploaded([...files]);
      files = [];
      document.activeElement.blur();
      uppy.cancelAll();
    });

    // TODO: add on.cancel > remove thumbnail!

    uppy.use(FileInput, {
      target: '.c-file-upload__dropzone-button',
      pretty: false
    });

    uppy.use(ThumbnailGenerator, {
      id: 'ThumbnailGenerator',
      thumbnailWidth: 500,
      thumbnailType: 'image/jpeg',
      waitForThumbnailsBeforeUpload: true,
      locale: {
        strings: {
          generatingThumbnails: intl.formatMessage(
            messages.fileUploadGeneratingThumbnails
          )
        }
      }
    });

    if (maxFiles === 1) {
      uppy.on('thumbnail:generated', (file, preview) => {
        const imgParent = document.querySelector('.c-media__upload');
        const img = imgParent.querySelector('img') || false;

        if (!img) {
          const thisImg = document.createElement('img');
          const emptyMsgIcon = document.querySelector(
            '.c-media__empty-message'
          );
          thisImg.src = preview;

          emptyMsgIcon.style.display = 'none';
          imgParent.appendChild(thisImg);
        } else {
          img.src = preview;
        }
      });
    }
  }, [uppy]);

  return (
    <div className={classNames('c-media__upload')}>
      {children}
      <div className={classNames('c-media__upload-area')}>
        {numFiles <= 0 && (
          <>
            <Icon hugeIcon id="upload" />
            <p className="c-file-upload__message">
              <FormattedMessage {...messages.fileUploadLabelMultiInfo} />
            </p>
          </>
        )}
        <label
          className={classNames(
            'c-input__label c-file-upload__dropzone-button',
            {}
          )}
        >
          <Icon id="browse" />
          <FormattedMessage {...messages.fileUploadLabelBrowse} />
        </label>
        <StatusBar
          className={classNames('c-media__uppy-status-bar', {
            'u-hidden': !uploading
          })}
          uppy={uppy}
          hideUploadButton
          hideAfterFinish
          showProgressDetails={false}
          hidePauseResumeButton
        />
      </div>
    </div>
  );
  //   );
};

export default FileUppyWrapper;
