import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import './Graphics.scss';

class Graphic extends Component {
  render() {
    const { maturity, pah, position } = this.props;
    return (
      <div
        className={classNames('c-graphic', {
          'c-graphic--maturity': maturity,
          'c-graphic--pah': pah
        })}
      >
        {maturity && (
          <Fragment>
            <div className="c-graphic__area c-graphic__lt-area">
              <span className="c-graphic__area-label c-graphic__lt-area-label">
                - 1yr
              </span>
            </div>
            <div className="c-graphic__area c-graphic__middle-area">
              <span className="c-graphic__area-label c-graphic__middle-area-label">
                APHV
              </span>
            </div>
            <div className="c-graphic__area c-graphic__gt-area">
              <span className="c-graphic__area-label c-graphic__gt-area-label">
                + 1yr
              </span>
            </div>
            <div
              className="c-graphic__position"
              style={{ left: `calc(calc(100% / 6) * calc(${position} + 3))` }}
            />
          </Fragment>
        )}

        {pah && (
          <Fragment>
            <div className="c-graphic__area c-graphic__lt-area">
              <span className="c-graphic__area-label c-graphic__lt-area-label">
                86%
              </span>
            </div>
            <div className="c-graphic__area c-graphic__middle-lt-area">
              <span className="c-graphic__area-label c-graphic__middle-lt-area-label">
                89%
              </span>
            </div>
            <div className="c-graphic__area c-graphic__middle-gt-area">
              <span className="c-graphic__area-label c-graphic__middle-gt-area-label">
                95%
              </span>
            </div>
            <div className="c-graphic__area c-graphic__gt-area">
              <span className="c-graphic__area-label c-graphic__gt-area-label">
                100%
              </span>
            </div>
            <div
              className="c-graphic__position"
              style={{ left: `calc(calc(100% / 20) * calc(${position} - 80))` }}
            />
          </Fragment>
        )}
      </div>
    );
  }
}

export default Graphic;
