import React, { useContext } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import {
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_EXERCISE,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK
} from 'routes/RouteList';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import Panel from 'components/panel/Panel';
import { PanelBody, PanelHeader, PanelTitleButton } from 'components/panel';
import {
  PanelSubtitle,
  PanelTitle,
  PanelTitleWrapper
} from 'components/panel/panel-title';
import Loader from 'components/loader/Loader';
import { TabPanelBody, Tabs } from 'components/tabs';
import { Tab, TabList, TabPanel } from 'react-tabs';
import { StoreContext } from 'index';
import { getLocalDateFormat } from 'utils/date';
import ExerciseFeedbackPanel from 'containers/pages/user/programs/ExerciseFeedbackPanel';
import ExercisePanel from 'containers/pages/user/programs/ExercisePanel';
import useTabIndex from 'hooks/utils/useTabIndex';
import MenuWrapper from 'components/menu/MenuWrapper';
import DownloadExerciseProgramButton from 'containers/partials/buttons/DownloadExerciseProgramButton';
import { useProgramContext } from 'contexts/ProgramContext';

const SporterProgramExercises = ({ active }) => {
  const {
    routing: { push }
  } = useContext(StoreContext);

  const { entityId, sporterId, programId, exerciseId } = useParams();
  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM,
    ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK
  ]);
  const { program, exercises, loading } = useProgramContext();

  const handleExerciseClick = exerciseId => {
    push(
      generatePath(
        ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_EXERCISE,
        {
          entityId,
          sporterId,
          programId,
          exerciseId: exerciseId
        }
      )
    );
  };

  const exercisesTab = () => {
    push(
      generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM, {
        entityId,
        sporterId,
        programId
      })
    );
  };

  const feedbackTab = () => {
    push(
      generatePath(
        ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK,
        {
          entityId,
          sporterId,
          programId
        }
      )
    );
  };

  return (
    <Panel active={active}>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb
            to={generatePath(ROUTE_SPORTERS_ENTITY, {
              entityId
            })}
          >
            <FormattedMessage {...messages.breadcrumbSporters} />
          </Breadcrumb>
          <Breadcrumb
            to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS, {
              entityId,
              sporterId
            })}
          >
            <FormattedMessage {...messages.breadcrumbPrograms} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            {program.title}
            <MenuWrapper trigger={<PanelTitleButton />}>
              <DownloadExerciseProgramButton programId={program.id} />
            </MenuWrapper>
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage
              {...messages.exercisesProgramSubTitle}
              values={{
                startdate: getLocalDateFormat(program.startdate),
                duration: program.duration
              }}
            />
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        {loading && <Loader />}

        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <TabList>
            <Tab onClick={exercisesTab}>
              <FormattedMessage {...messages.tabExercises} />
            </Tab>
            <Tab onClick={feedbackTab}>
              <FormattedMessage {...messages.tabFeedback} />
            </Tab>
          </TabList>
          <TabPanel>
            <ExercisePanel
              exercises={exercises}
              exerciseId={exerciseId}
              onClick={handleExerciseClick}
              exerciseGroup={program}
            />
          </TabPanel>
          <TabPanel>
            {program?.id && (
              <TabPanelBody withScroller>
                <ExerciseFeedbackPanel program={program} personId={sporterId} />
              </TabPanelBody>
            )}
          </TabPanel>
        </Tabs>
      </PanelBody>
    </Panel>
  );
};

export default SporterProgramExercises;
