import React from 'react';
import classNames from 'classnames';

class CardFooter extends React.Component {
  render() {
    const { primary, secondary, extraClassNames, modal, style, ...props } =
      this.props;

    return (
      <div
        className={classNames('c-card__footer', extraClassNames, {
          'c-card__footer--primary': primary,
          'c-card__footer--secondary': secondary,
          'c-card__footer--primary c-card__footer--modal': modal
        })}
        style={style}
      >
        {props.children}
      </div>
    );
  }
}

export default CardFooter;
