import React from 'react';
import classNames from 'classnames';
import Icon from '../icon/Icon';
import { ErrorMessage, getIn, useFormikContext, useField } from 'formik';
import InputErrorMessage from './InputErrorMessage';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { DATE_LOCALE } from 'constants.js';

import 'react-datepicker/dist/react-datepicker.css';
import { isDate } from 'date-fns';

export const FieldDatePicker = ({ children, ...props }) => {
  const { setFieldValue, validateField, handleChange } = useFormikContext();
  const [field] = useField(props);

  return (
    <div
      className={classNames('c-input__group', {
        'c-input--read-only c-input--disabled': props.readOnly,
        'c-input--disabled': props.disabled,
        'c-input--icon-left': props.iconLeft,
        'c-input--inline': props.inline
      })}
    >
      {props.id && children && (
        <label htmlFor={props.id} className={classNames('c-input__label', {})}>
          {children}
        </label>
      )}
      <div className="c-input__wrapper c-input__wrapper-date-picker">
        <DatePicker
          {...field}
          {...props}
          selected={
            (field.value && isDate(field.value) && new Date(field.value)) ||
            null
          }
          locale={DATE_LOCALE[props.locale]}
          dateFormat="dd/MM/yyyy"
          showMonthDropdown
          showYearDropdown
          dateFormatCalendar=" "
          className={classNames('c-input', props.extraClassNames, {
            'c-input--large': props.large,
            'c-input--rounded': props.rounded,
            'c-input--url': props.url,
            'c-input--in-table': props.inTable,
            'has-error':
              props.errors?.[props.name] && props.touched?.[props.name]
          })}
          minDate={props.min}
          maxDate={props.max}
          required={props.required}
          autoFocus={props.autoFocus}
          placeholderText={props.placeholder}
          readOnly={props.readOnly}
          disabled={props.disabled || props.readOnly}
          onChange={val => {
            setFieldValue(field.name, val);
          }}
          onBlur={() => {
            validateField(props.name);
          }}
          onCalendarClose={() => {
            validateField(props.name);
          }}
          onChangeRaw={e => {
            handleChange(e);
          }}
        />
        {props.icon && !props.readOnly && props.type !== 'range' && (
          // props.type !== 'date' &&
          <Icon
            id={props.icon}
            fillColor={props.iconColor}
            extraClassNames={classNames('c-icon--input', {
              'c-icon--input-left': props.iconLeft
            })}
          />
        )}
      </div>

      {props.errors &&
        props.errors[props.name] &&
        typeof props.errors[props.name] === 'string' && (
          <ErrorMessage name={props.name} component={InputErrorMessage} />
        )}

      {/* TODO @fabian error message position in TestItemForm */}
      {props.errors &&
        typeof props.errors[props.name] !== 'string' &&
        getIn(props.errors, props.name) && (
          <InputErrorMessage>
            {getIn(props.errors, props.name)}
          </InputErrorMessage>
        )}
    </div>
  );
};
