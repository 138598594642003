import React, { useContext } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { generatePath, Route, Switch } from 'react-router-dom';
import { StoreContext } from 'index';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import { observer } from 'mobx-react';
import { PreviewModalProvider } from 'contexts/PreviewModalContext';
import {
  ROUTE_LESSONS_ENTITY,
  ROUTE_LESSONS_ENTITY_LESSON,
  ROUTE_LESSONS_ENTITY_LESSON_EDIT,
  ROUTE_LESSONS_ENTITY_LESSON_VIDEOS,
  ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO,
  ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_ANNOTATE,
  ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_ANNOTATE_ADD,
  ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_ANNOTATE_EDIT,
  ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_EDIT
} from 'routes/RouteList';
import Panel from 'components/panel/Panel';
import messages from 'messages';
import Lessons from 'containers/pages/lessons/Lessons';
import Lesson from 'containers/pages/lessons/Lesson';
import Video from 'containers/pages/videos/Video';
import AnnotateVideo from 'containers/pages/videos/AnnotateVideo';
import { LessonsProvider } from 'contexts/LessonsContext';
import { VideosProvider } from 'contexts/VideosContext';
import { EditableContextProvider } from 'contexts/EditableContext';

const LessonsIndex = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const ability = useAbility(AbilityContext);
  const intl = useIntl();
  return (
    <>
      <LessonsProvider entityId={user.rootEntityId}>
        <VideosProvider user={user} entityId={user.rootEntityId}>
          <PreviewModalProvider entityId={user.rootEntityId}>
            <Switch>
              <Route
                exact
                path={ROUTE_LESSONS_ENTITY}
                render={props => (
                  <>
                    <Lessons
                      entityId={user.rootEntityId}
                      active={ROUTE_LESSONS_ENTITY === props.match.path}
                    />
                    <Panel
                      emptyState
                      emptyLabel={intl.formatMessage(
                        messages.lessonsEmptyPanel
                      )}
                    />
                  </>
                )}
              />

              <Route
                exact
                path={[
                  ROUTE_LESSONS_ENTITY_LESSON,
                  ROUTE_LESSONS_ENTITY_LESSON_EDIT,
                  ROUTE_LESSONS_ENTITY_LESSON_VIDEOS,
                  ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO,
                  ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_EDIT
                ]}
                render={props => (
                  <>
                    <Lessons
                      entityId={props.match.params.entityId}
                      lessonId={props.match.params.lessonId}
                      active={ROUTE_LESSONS_ENTITY === props.match.path}
                    />
                    <Lesson
                      entityId={props.match.params.entityId}
                      active={ROUTE_LESSONS_ENTITY_LESSON === props.match.path}
                      lessonId={props.match.params.lessonId}
                      exerciseId={props.match.params.exerciseId}
                    />
                    <Route
                      exact
                      path={[
                        ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO,
                        ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_EDIT
                      ]}
                      render={props => (
                        <EditableContextProvider>
                          <Video
                            entityId={props.match.params.entityId}
                            active={
                              ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO ===
                              props.match.path
                            }
                            editable
                            edit={
                              ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_EDIT ===
                              props.match.path
                            }
                            inLesson
                            videoId={props.match.params.videoId}
                            crumbs={[
                              {
                                path: generatePath(ROUTE_LESSONS_ENTITY, {
                                  entityId: props.match.params.entityId
                                }),
                                label: intl.formatMessage(
                                  messages.breadcrumbLessons
                                )
                              },
                              {
                                path: generatePath(
                                  ROUTE_LESSONS_ENTITY_LESSON,
                                  {
                                    entityId: props.match.params.entityId,
                                    lessonId: props.match.params.lessonId
                                  }
                                ),
                                label: intl.formatMessage(
                                  messages.breadcrumbLesson
                                )
                              }
                            ]}
                            parentPath={generatePath(
                              ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO,
                              {
                                entityId: props.match.params.entityId,
                                lessonId: props.match.params.lessonId,
                                videoId: props.match.params.videoId
                              }
                            )}
                          />
                        </EditableContextProvider>
                      )}
                    />
                  </>
                )}
              />

              <Route
                exact
                path={[
                  ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_ANNOTATE,
                  ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_ANNOTATE_ADD,
                  ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_ANNOTATE_EDIT
                ]}
                render={props => (
                  <EditableContextProvider>
                    <AnnotateVideo
                      inLesson
                      entityId={props.match.params.entityId}
                      crumbs={[
                        {
                          path: generatePath(ROUTE_LESSONS_ENTITY, {
                            entityId: props.match.params.entityId
                          }),
                          label: intl.formatMessage(messages.breadcrumbLessons)
                        },
                        {
                          path: generatePath(
                            ROUTE_LESSONS_ENTITY_LESSON_VIDEOS,
                            {
                              entityId: props.match.params.entityId,
                              lessonId: props.match.params.lessonId
                            }
                          ),
                          label: intl.formatMessage(messages.breadcrumbLesson)
                        },
                        {
                          path: generatePath(
                            ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO,
                            {
                              entityId: props.match.params.entityId,
                              lessonId: props.match.params.lessonId,
                              videoId: props.match.params.videoId
                            }
                          ),
                          label: intl.formatMessage(messages.breadcrumbVideo)
                        }
                      ]}
                      parentPath={generatePath(
                        ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_ANNOTATE,
                        {
                          entityId: props.match.params.entityId,
                          lessonId: props.match.params.lessonId,
                          videoId: props.match.params.videoId
                        }
                      )}
                    />
                  </EditableContextProvider>
                )}
              />

              <Redirect
                to={generatePath(ROUTE_LESSONS_ENTITY, {
                  entityId: user.rootEntityId
                })}
              />
            </Switch>
          </PreviewModalProvider>
        </VideosProvider>
      </LessonsProvider>
    </>
  );
};
export default withRouter(observer(LessonsIndex));
