import { useContext, useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Card, CardBody, CardFooter } from 'components/card';
import Panel from 'components/panel/Panel';
import PanelHeader from 'components/panel/PanelHeader';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelTools from 'components/panel/PanelTools';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ROUTE_EXERCISES_TRAININGS_ENTITY,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE
} from 'routes/RouteList';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import messages from 'messages';
import PanelBody from 'components/panel/PanelBody';
import { StoreContext } from 'index';
import { Tab, TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import FieldInput from 'components/input/FieldInput';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import useTabIndex from 'hooks/utils/useTabIndex';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { Button, ButtonsGroup } from 'components/button';
import Icon from 'components/icon/Icon';
import AddExercisesModal from 'containers/pages/exercises/collections/AddExercisesModal';
import { useNotificationQueue } from 'components/notification';
import Loader from 'components/loader/Loader';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import { PanelSubtitle, PanelTitleButton } from 'components/panel/panel-title';
import { MenuWrapper } from 'components/menu';
import useSelectable from 'hooks/utils/useSelectable';
import RemoveExerciseTemplateButton from 'containers/partials/buttons/RemoveExerciseTemplateButton';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import ExercisesListSortable from 'containers/partials/lists/ExercisesListSortable';
import RemoveExercisesFromTemplateButton from 'containers/partials/buttons/RemoveExercisesFromTemplateButton';
import SortableList from 'components/dndkit/SortableList';
import { usePreviewModalContext } from 'contexts/PreviewModalContext';
import { useMutation } from '@apollo/client';
import {
  MUTATION_EDIT_TEMPLATE,
  QUERY_GET_TEMPLATES
} from 'services/aws/templates-query';
import { Link, useRouteMatch } from 'react-router-dom';
import { TemplateType } from 'constants.js';
import useTraining from 'hooks/queries/useTraining';
import TrainingForm from 'containers/pages/exercises/trainings/TrainingForm';
import { FieldTextarea } from 'components/input/FieldTextarea';
import { useExercisesContext } from 'contexts/ExercisesContext';
import DownloadExerciseProgramTemplateButton from 'containers/partials/buttons/DownloadExerciseProgramTemplateButton';
import ShowEmbedUrlModal from 'containers/pages/exercises/trainings/ShowEmbedUrlModal';

const Training = ({ entityId, active, trainingId, exerciseId }) => {
  const {
    authStore: { user },
    routing: { push }
  } = useContext(StoreContext);
  const ability = useAbility(AbilityContext);
  const intl = useIntl();
  const notification = useNotificationQueue();
  const { exerciseActions } = useExercisesContext();
  const isExercisesPage = useRouteMatch(
    ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES
  );
  const { setModalData } = usePreviewModalContext();
  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES,
    ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING
  ]);
  const [addExercisesModal, setAddExercisesModal] = useState(null);
  const [draggable, setDraggable] = useState(false);
  const { training, exercises, setFilter, loading, error } = useTraining(
    entityId,
    trainingId,
    user
  );
  const { selected, setSelected } = useSelectable([]);
  const [exercisesSortOrder, setExercisesSortOrder] = useState(null);

  const [editTemplate] = useMutation(MUTATION_EDIT_TEMPLATE);
  const [showEmbedUrl, setShowEmbedUrl] = useState(false);

  useEffect(() => {
    setSelected([]);
  }, [trainingId]);

  useEffect(() => {
    if (training) {
      exerciseActions.setSelectedExercises(training.exercises);
    }
  }, [training]);

  const onCompleteHandler = trainingId => {
    notification.add(trainingId, {
      message: intl.formatMessage(messages.messageEditTrainingSuccess)
    });
    setTimeout(() => {
      push(
        generatePath(ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING, {
          entityId,
          trainingId
        })
      );
    }, 100);
  };

  const onSearch = e => {
    setFilter({ search: e.target.value });
  };

  const onAddedHandler = () => {
    setAddExercisesModal(false);
    setSelected([]);
  };

  const onTrainingDeleted = trainingId => {
    notification.add(`removed_${trainingId}`, {
      message: intl.formatMessage(messages.messageRemovedtrainingsSuccess)
    });
    push(
      generatePath(ROUTE_EXERCISES_TRAININGS_ENTITY, {
        entityId
      })
    );
  };

  const submitExercisesSorting = async () => {
    const trainingData = {
      id: training.id,
      entityId: entityId,
      title: training.title,
      exerciseIds: [...exercisesSortOrder],
      type: TemplateType.TRAINING
    };

    await editTemplate({
      variables: { ...trainingData },
      refetchQueries: [
        {
          query: QUERY_GET_TEMPLATES,
          variables: { entityId, type: TemplateType.TRAINING }
        }
      ]
    }).then(res => {
      setDraggable(false);
      notification.add(res.data.editExerciseProgramTemplate.id, {
        message: intl.formatMessage(messages.trainingSaved)
      });
    });
  };

  const onCancelHandler = () => {
    setDraggable(false);
  };

  if (loading)
    return (
      <Panel active={active}>
        <Loader />
      </Panel>
    );
  if (error) return <RetryPanel />;

  return (
    <Panel active={active}>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb
            to={generatePath(ROUTE_EXERCISES_TRAININGS_ENTITY, { entityId })}
          >
            <FormattedMessage {...messages.breadcrumbTrainings} />
          </Breadcrumb>
          <Breadcrumb>{training.title}</Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            {`${training.title}`}
            <MenuWrapper trigger={<PanelTitleButton />}>
              <DownloadExerciseProgramTemplateButton
                templateId={training.id}
                collectionType={TemplateType.TRAINING}
              />
              {ability.can('embed', 'Trainings') && (
                <Button menu onClick={() => setShowEmbedUrl(true)}>
                  <Icon id="download" />
                  <FormattedMessage {...messages.openCopyEmbedModal} />
                </Button>
              )}

              {ability.can('update', training) && (
                <RemoveExercisesFromTemplateButton
                  disabledMessage={
                    draggable
                      ? intl.formatMessage(
                          messages.collectionRemoveExerciseDisabledDragging
                        )
                      : intl.formatMessage(
                          messages.collectionRemoveExerciseDisabledNotSelected
                        )
                  }
                  entityId={entityId}
                  type="training"
                  exerciseIds={selected}
                  templateId={trainingId}
                  updated={() => setSelected([])}
                  disabled={!selected || selected.length === 0}
                />
              )}
              {ability.can('delete', training) && (
                <RemoveExerciseTemplateButton
                  entityId={entityId}
                  templateId={trainingId}
                  collectionType={TemplateType.TRAINING}
                  menu
                  onDeleted={trainingId => onTrainingDeleted(trainingId)}
                >
                  <FormattedMessage {...messages.removeTrainingButton} />
                </RemoveExerciseTemplateButton>
              )}
              {ability.can('update', training) && (
                <Button
                  menu
                  onClick={() => setDraggable(true)}
                  disabled={(selected && selected.length > 0) || draggable}
                >
                  {draggable ? (
                    intl.formatMessage(
                      messages.collectionSortExercisesDisabledDragging
                    )
                  ) : (
                    <FormattedMessage {...messages.sortTrainingButton} />
                  )}
                  <Icon id="arrange" strokeColor="color-neutral-dark" />
                </Button>
              )}
            </MenuWrapper>
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage
              {...messages.exercisesLengthInIt}
              values={{ this: training.exercises.length }}
            />
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <TabList>
            <Tab>
              <Link
                to={generatePath(
                  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES,
                  {
                    entityId,
                    trainingId
                  }
                )}
              >
                <FormattedMessage {...messages.trainingsExercises} />
              </Link>
            </Tab>
            {ability.can('update', training) && (
              <Tab>
                <Link
                  to={generatePath(ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING, {
                    entityId,
                    trainingId
                  })}
                >
                  <FormattedMessage {...messages.trainingsDetails} />
                </Link>
              </Tab>
            )}
          </TabList>
          <TabPanel>
            <TabPanelBody withScroller>
              {isExercisesPage && (
                <PanelTools>
                  <FieldInput
                    rounded
                    icon="search-grey"
                    iconLeft
                    onChange={onSearch}
                  />

                  {ability.can('update', training) && (
                    <Button
                      rounded
                      primary
                      disabled={!trainingId}
                      onClick={() => setAddExercisesModal(training)}
                    >
                      <Icon id="add-white" />
                      <FormattedMessage
                        {...messages.exercisesAddExercisesButton}
                      />
                    </Button>
                  )}
                </PanelTools>
              )}
              {exercises.length > 0 ? (
                <>
                  <SortableList
                    items={exercises}
                    onSort={exercises => setExercisesSortOrder(exercises)}
                  >
                    {({ items }) => (
                      <ExercisesListSortable
                        exercises={items}
                        exerciseGroup={training}
                        selectedExercises={selected}
                        activeId={exerciseId}
                        draggable={draggable}
                        enablePreviewModal={false}
                        showTemplateCount={false}
                        showFrequency
                        showHasComment
                        checkable={ability.can('update', training)}
                        onPreview={exercise =>
                          setModalData({ type: 'exercise', data: exercise })
                        }
                        onClick={id => {
                          if (id !== exerciseId) {
                            push(
                              generatePath(
                                ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE,
                                {
                                  entityId,
                                  trainingId,
                                  exerciseId: id
                                }
                              )
                            );
                          }
                        }}
                        onSelect={exerciseIds => {
                          setSelected([...exerciseIds]);
                        }}
                      />
                    )}
                  </SortableList>
                  {draggable && (
                    <ButtonsGroup extraClassNames="u-margin-left-auto u-margin-top-medium">
                      <Button secondary onClick={() => onCancelHandler()}>
                        <FormattedMessage {...messages.cancelButton} />
                      </Button>
                      <Button
                        primary
                        disabled={loading} //|| processing
                        onClick={() => submitExercisesSorting()}
                      >
                        <FormattedMessage
                          {...messages.saveSortTemplateButton}
                        />
                      </Button>
                    </ButtonsGroup>
                  )}
                </>
              ) : (
                <Card empty extraClassNames="u-margin-auto">
                  <CardBody empty>
                    {ability.can('update', training) ? (
                      <Message emptyState={true} icon="browse">
                        {training.exercises.length ? (
                          <MessageText>
                            <FormattedMessage
                              {...messages.exercisesListEmptyResult}
                            />
                          </MessageText>
                        ) : (
                          <MessageText>
                            <FormattedMessage
                              {...messages.templateEmptyExercises}
                            />
                          </MessageText>
                        )}

                        <Button
                          primary
                          rounded
                          onClick={() => setAddExercisesModal(training)}
                        >
                          <Icon id="add-white" />
                          <FormattedMessage
                            {...messages.exercisesAddExercisesButton}
                          />
                        </Button>
                      </Message>
                    ) : (
                      <Message emptyState={true} icon="browse">
                        {training.exercises.length ? (
                          <MessageText>
                            <FormattedMessage
                              {...messages.exercisesListEmptyResult}
                            />
                          </MessageText>
                        ) : (
                          <MessageText>
                            <FormattedMessage
                              {...messages.templateEmptyExercisesAndCantAdd}
                            />
                          </MessageText>
                        )}
                      </Message>
                    )}
                  </CardBody>
                </Card>
              )}
              {ability.can('update', training) && addExercisesModal && (
                <AddExercisesModal
                  entityId={entityId}
                  template={training}
                  onClose={() => {
                    setAddExercisesModal(false);
                  }}
                  onAdded={() => onAddedHandler()}
                />
              )}
            </TabPanelBody>
          </TabPanel>
          {ability.can('update', training) && (
            <TabPanel>
              <TabPanelBody withScroller>
                <TrainingForm
                  entityId={entityId}
                  training={{ ...training }}
                  onComplete={onCompleteHandler}
                >
                  {({ errors, touched, isSubmitting, handleSubmit }) => (
                    <>
                      <CardBody secondary>
                        <FieldInput
                          id="title"
                          name="title"
                          placeholder={intl.formatMessage(
                            messages.trainingNamePlaceholder
                          )}
                          errors={errors}
                          touched={touched}
                          required
                        >
                          <FormattedMessage {...messages.trainingNameLabel} />
                        </FieldInput>
                        <FieldTextarea
                          id="note"
                          name="note"
                          errors={errors}
                          touched={touched}
                          resizeVertical
                        >
                          <FormattedMessage
                            {...messages.trainingsDetailsNoteLabel}
                          />
                        </FieldTextarea>
                      </CardBody>
                      <CardFooter secondary>
                        <FormEditFooterButtons
                          edit
                          disabledSubmit={isSubmitting}
                          onCancel={() =>
                            push(
                              generatePath(ROUTE_EXERCISES_TRAININGS_ENTITY, {
                                entityId
                              })
                            )
                          }
                          onSubmit={handleSubmit}
                        />
                      </CardFooter>
                    </>
                  )}
                </TrainingForm>
              </TabPanelBody>
            </TabPanel>
          )}
        </Tabs>
      </PanelBody>
      {showEmbedUrl && ability.can('embed', 'Trainings') && (
        <ShowEmbedUrlModal
          isOpen={showEmbedUrl}
          templateId={trainingId}
          onClose={() => setShowEmbedUrl(false)}
        />
      )}
    </Panel>
  );
};

export default observer(Training);
