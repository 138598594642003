import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { observer } from 'mobx-react';
import Panel from 'components/panel/Panel';
import messages from 'messages';
import { PanelBody, PanelHeader, PanelSubtitle } from 'components/panel';
import {
  PanelTitle,
  PanelTitleButton,
  PanelTitleWrapper
} from 'components/panel/panel-title';
import PanelTools from 'components/panel/PanelTools';
import { Button } from 'components/button';
import {
  ROUTE_EXERCISES_COLLECTIONS_ENTITY,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES
} from 'routes/RouteList';
import Icon from 'components/icon/Icon';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { MenuWrapper } from 'components/menu';
import { StoreContext } from 'index';
import Loader from 'components/loader/Loader';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import TemplatesListItem from 'containers/partials/list-items/TemplatesListItem';
import { useTemplatesContext } from 'contexts/TemplatesContext';
import BreadcrumbPath from 'containers/partials/misc/BreadcrumbPath';
import useSelectable from 'hooks/utils/useSelectable';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import { TabList, Tab, TabPanel, Tabs } from 'components/tabs';
import AddCollectionModal from 'containers/pages/exercises/collections/AddCollectionModal';
import FieldInput from 'components/input/FieldInput';
import TabPanelBody from 'components/tabs/TabPanelBody';

const Collections = ({ active, entityId, collectionId }) => {
  const {
    authStore: { user },
    routing: { push }
  } = useContext(StoreContext);
  const ability = useAbility(AbilityContext);

  const [addModalData, setAddModalData] = useState(null);
  const { state, actions, loading: templatesLoading } = useTemplatesContext();
  const { selected, select } = useSelectable([]);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    actions.getTemplates();
  }, []);

  const onSearch = e => {
    actions.setFilter({ search: e.target.value });
  };

  const onClickHandler = collection => {
    push(
      generatePath(ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES, {
        entityId,
        collectionId: collection.id
      })
    );
  };

  const onEditHandler = collection => {
    push(
      generatePath(ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION, {
        entityId,
        collectionId: collection.id
      })
    );
  };

  const onDeleteHandler = collectionId => {
    push(
      generatePath(ROUTE_EXERCISES_COLLECTIONS_ENTITY, {
        entityId,
        collectionId
      })
    );
  };

  return (
    <Panel active={active}>
      <PanelHeader>
        <BreadcrumbPath crumbs={[]} />
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.collectionsTitle} />
            <MenuWrapper trigger={<PanelTitleButton />} />
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage {...messages.collectionsSubTitle} />
          </PanelSubtitle>
        </PanelTitleWrapper>
        <PanelTools>
          <FieldInput rounded icon="search-grey" iconLeft onChange={onSearch} />

          {ability.can('create', 'Template') && (
            <Button rounded primary onClick={() => setAddModalData(true)}>
              <Icon id="add-white" />
              <FormattedMessage {...messages.exercisesAddCollectionButton} />
            </Button>
          )}
        </PanelTools>
      </PanelHeader>
      <PanelBody>
        {templatesLoading && <Loader />}
        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <TabList>
            <Tab key={`collections.my`}>
              {/*<Badge>
              {state.templates.filter(t => t.personId === user.id).length}
            </Badge>*/}
              <FormattedMessage {...messages.collectionsTabMyCollections} />
            </Tab>
            <Tab key={`collections.other`}>
              {/*<Badge>
              {state.templates.filter(t => t.personId !== user.id).length}
            </Badge>*/}
              <FormattedMessage {...messages.collectionsTabAllCollections} />
            </Tab>
          </TabList>
          <TabPanel>
            <TabPanelBody withScroller>
              {state.templates &&
              state.templates.length &&
              state.templates.filter(t => t.personId === user.id).length ? (
                <List>
                  {/* <ListHeader noTitleStatus>
                <ListColumnTitle
                // ascendent // in comment totdat het werkt:
                // TODO: rangschikking list by column title
                >
                  <FormattedMessage
                    {...messages.collectionsHeaderListCollections}
                  />
                </ListColumnTitle>
                <ListColumnTitle>
                  <FormattedMessage
                    {...messages.collectionsHeaderListExercisesQty}
                  />
                </ListColumnTitle>
              </ListHeader> */}
                  <ListBody>
                    {state.templates
                      .filter(t => t.personId === user.id)
                      .map(template => (
                        <TemplatesListItem
                          checkbox={false}
                          icon={'template'}
                          visible={selected.length > 0}
                          exercisesLength={template.exercises.length}
                          id={template.id}
                          key={template.id}
                          template={template}
                          entityId={entityId}
                          active={template.id === collectionId}
                          checked={selected.includes(template.id)}
                          onChange={() => select(template.id)}
                          onClick={() => {
                            return onClickHandler(template);
                          }}
                          onEdit={() => onEditHandler(template)}
                          onDeleted={() => onDeleteHandler(collectionId)}
                        />
                      ))}
                  </ListBody>
                </List>
              ) : (
                <EmptyCollectionList />
              )}
            </TabPanelBody>
          </TabPanel>
          <TabPanel>
            <TabPanelBody withScroller>
              {state.templates &&
              state.templates.length &&
              state.templates.filter(t => t.personId !== user.id).length ? (
                <List>
                  {/* <ListHeader noTitleStatus>
                <ListColumnTitle
                // ascendent // in comment totdat het werkt:
                // TODO: rangschikking list by column title
                >
                  <FormattedMessage
                    {...messages.collectionsHeaderListCollections}
                  />
                </ListColumnTitle>
                <ListColumnTitle>
                  <FormattedMessage
                    {...messages.collectionsHeaderListExercisesQty}
                  />
                </ListColumnTitle>
              </ListHeader> */}
                  <ListBody>
                    {state.templates
                      .filter(t => t.personId !== user.id)
                      .map(template => (
                        <TemplatesListItem
                          checkbox={false}
                          icon={'template'}
                          visible={selected.length > 0}
                          exercisesLength={template.exercises.length}
                          id={template.id}
                          key={template.id}
                          template={template}
                          entityId={entityId}
                          active={template.id === collectionId}
                          checked={selected.includes(template.id)}
                          onChange={() => select(template.id)}
                          onClick={() => {
                            return onClickHandler(template);
                          }}
                          onEdit={() => onEditHandler(template)}
                          onDeleted={() => onDeleteHandler(collectionId)}
                        />
                      ))}
                  </ListBody>
                </List>
              ) : (
                <EmptyCollectionList />
              )}
            </TabPanelBody>
          </TabPanel>
        </Tabs>
      </PanelBody>

      {addModalData && (
        <AddCollectionModal
          entityId={entityId}
          onClose={() => setAddModalData(false)}
        />
      )}
    </Panel>
  );
};

export default observer(Collections);

const EmptyCollectionList = () => (
  <Card centered>
    <CardBody empty>
      <Message emptyState={true} icon="browse">
        <MessageText>
          <FormattedMessage {...messages.templateListEmpty} />
        </MessageText>
      </Message>
    </CardBody>
  </Card>
);
