import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import { useMutation } from '@apollo/client';
import {
  MUTATION_ADD_ORGANISATION,
  MUTATION_CONVERT_GROUP_TO_ENTITY,
  MUTATION_UPDATE_ORGANISATION,
  QUERY_GET_ORGANISATIONS_BY_ID
} from 'services/aws/entity-query';
import { OTHER_VALUE } from 'constants.js';
import messages from 'messages';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { StoreContext } from 'index';
import { useNotificationQueue } from 'components/notification';

function OrganisationForm({
  organisationId,
  organisation,
  onComplete,
  allowFeatureEdit,
  children
}) {
  const intl = useIntl();
  const {
    authStore,
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);

  const notification = useNotificationQueue();

  const [updateOrganisation] = useMutation(MUTATION_UPDATE_ORGANISATION);
  const [addOrganisation] = useMutation(MUTATION_ADD_ORGANISATION);
  const [convertGroupToEntity] = useMutation(MUTATION_CONVERT_GROUP_TO_ENTITY);

  const onSubmitHandler = async organisation => {
    if (organisation.info.organisationType !== OTHER_VALUE) {
      delete organisation.info.otherOrganisationType;
    }
    if (organisation.info.contact.type !== OTHER_VALUE) {
      delete organisation.info.contact.otherType;
    }

    let orgData = {
      name: organisation.name,
      info: JSON.stringify(organisation.info),
      baseLanguage: organisation.baseLanguage
    };

    if (allowFeatureEdit) {
      orgData.featureFlags = JSON.stringify(organisation.featureFlags);
    }

    if (!organisationId) {
      await addOrganisation({
        variables: { ...orgData, parentId: rootEntityId }
      }).then(async ({ data }) => {
        await convertGroupToEntity({
          variables: { entityId: data.createEntity.id },
          refetchQueries: [
            {
              query: QUERY_GET_ORGANISATIONS_BY_ID,
              variables: { id: rootEntityId }
            }
          ]
        }).then(({ data }) => {
          notification.add(data.convertGroupToEntity.id, {
            message: intl.formatMessage(messages.notificationOrganisationAdded)
          });
          if (onComplete) onComplete(data.convertGroupToEntity.id);
        });
      });
    } else {
      await updateOrganisation({
        variables: {
          ...orgData,
          id: organisationId,
          parentId: organisation.parentId
        },
        refetchQueries: [
          {
            query: QUERY_GET_ORGANISATIONS_BY_ID,
            variables: { id: rootEntityId }
          }
        ]
      }).then(({ data }) => {
        notification.add(data.editEntity.id, {
          message: intl.formatMessage(messages.notificationOrganisationUpdated)
        });
        if (onComplete) onComplete(data.editEntity.id);
      });
    }

    await authStore.renewEntities();
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage(messages.organisationNameRequired)
    )
  });

  return (
    <Formik
      initialValues={organisation}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={onSubmitHandler}
    >
      {props => (
        <Form noValidate>
          {typeof children === 'function' ? children(props) : children}
        </Form>
      )}
    </Formik>
  );
}

export default OrganisationForm;
