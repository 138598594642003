import React, { createContext, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useSessionContext } from 'contexts/SessionContext';
import { FinishedState } from 'enums';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  MUTATION_START_TEST_SESSION,
  MUTATION_UPDATE_TEST_DATA,
  QUERY_GET_TESTDATA
} from 'services/aws/session-query';
import TestItemResult from 'models/TestItemResult';
import TestData from 'models/TestData';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import { groupTests } from 'utils/tests';
import { StoreContext } from 'index';
import { useNotificationQueue } from 'components/notification';
import { useIntl } from 'react-intl';
import messages from 'messages.js';

export const TestDataContext = createContext();

const TestDataProvider = ({ entityId, sporterId, children }) => {
  const {
    uiState: { locale }
  } = useContext(StoreContext);
  const intl = useIntl();
  const [testData, setTestData] = useState(undefined);
  const [testDataId, setTestDataId] = useState(undefined);
  const [groupedTests, setGroupedTests] = useState([]);
  const [formData, setFormData] = useState({});
  const { session, refetch, options } = useSessionContext();
  // const [touchedFields, setTouchedFields] = useState([]);
  const [formIsUpdated, setFormIsUpdated] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const notification = useNotificationQueue();

  const [
    getTestData,
    { data, loading: testDataLoading, refetch: refetchTestData }
  ] = useLazyQuery(QUERY_GET_TESTDATA);

  const [updateTestData] = useMutation(MUTATION_UPDATE_TEST_DATA);
  const [startTestSession] = useMutation(MUTATION_START_TEST_SESSION);

  useEffect(() => {
    // reset all context data
    setFormIsUpdated(false);
    setTestDataId(undefined);
    setTestData(undefined);
    setFormData({});
    setGroupedTests([]);
  }, [session.id]);

  useEffect(() => {
    const sporterTestData = session.getTestDataByPersonId(sporterId);
    if (session.id && sporterTestData) {
      fetchTestData(session.id, sporterTestData).catch(error => {
        Sentry.captureException(error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, session, sporterId, options.benchmark]);

  // If we don't have testData create one and refresh the session data
  useEffect(() => {
    const createNewTestData = async () => {
      const testData = await createTestData();
      if (testData?.data?.addTestData) {
        await refetch({ testSessionId: session.id });
        refetchTestData();
      }
    };
    if (
      testDataId === false &&
      session.id &&
      !session.isILike &&
      !session.isSAP
    ) {
      createNewTestData().catch(error => {
        Sentry.captureException(error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testDataId, session]);

  useEffect(() => {
    if (data?.getTestDataV2 === null) {
      setTestDataId(false);
      setTestData(null);
      setFormData({});
      setGroupedTests([]);
    }
    if (data?.getTestDataV2 && session.tests) {
      const testDataResult = new TestData(data.getTestDataV2);

      const tempdata = {};
      let hasData = false;
      const assignedTests = session.tests.map(test => {
        // noinspection EqualityComparisonWithCoercionJS
        const resultItems = sort(
          testDataResult.result
            // eslint-disable-next-line
            .filter(testItem => testItem.testId == test.id) // Unstrict check required
            .map(testItem => {
              const testItemResult = new TestItemResult({
                ...testItem,
                category: test.category,
                dominant_only: test.dominant_only
              });
              if (testItemResult?.values) {
                const itemData = testItemResult?.values ?? '';
                Object.keys(testItemResult.sides).forEach(
                  key =>
                    (itemData[key] =
                      itemData?.[key] && Array.isArray(itemData[key])
                        ? itemData[key].map(value => value ?? '')
                        : [''])
                );
                tempdata[testItemResult.id] = itemData;
              } else {
                tempdata[testItemResult.id] = { ...testItemResult.sides };
              }
              testItemResult.title =
                testItemResult?.copy?.[locale]?.title ?? testItemResult.title;

              if (testItemResult?.result) {
                // TODO nice to have: add a check if the result is a valid array
                if (testItemResult.isOption) {
                  Object.keys(testItemResult.sides).forEach(side => {
                    if (testItemResult.result[side][0]) {
                      testItemResult.result[side][0].label =
                        testItemResult.result[side]?.[0]?.labels?.[locale] ??
                        testItemResult.result[side]?.[0]?.label;
                    }
                  });
                }
              }
              if (!testItemResult.hidden) {
                //  && testItemResult.values
                hasData = true;
              }
              return testItemResult;
            }),
          {
            keys: [
              {
                key: 'order',
                dataType: SORT_DATA_TYPES.NUMBER
              },
              {
                key: 'title',
                dataType: SORT_DATA_TYPES.STRING
              }
            ]
          }
        );
        test.testItems = resultItems.length > 0 ? resultItems : test.testItems;
        return test;
      });
      setTestDataId(testDataResult.id);
      setTestData(testDataResult);
      if (Object.keys(formData).length === 0) {
        setFormData(tempdata);
      }
      setGroupedTests(
        hasData
          ? {
              ...groupTests(filterGrowthPredictionData(assignedTests))
            }
          : []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const createTestData = async () => {
    const testData = await startTestSession({
      variables: {
        personId: sporterId,
        testSessionId: session.id,
        finished: FinishedState.STARTED
      }
    });

    if (testData?.data?.addTestData) {
      refetch({ testSessionId: session.id });
    }
  };

  const fetchTestData = async (sessionId, sporterTestData) => {
    if (sessionId && sporterId && sporterTestData) {
      const variables = {
        entityId,
        personId: sporterId,
        testSessionId: session.id
      };
      if (options.benchmark && !(session.isRehab || session.isPrevention)) {
        variables.benchmarkId = options.benchmark;
      }
      await getTestData({
        variables,
        fetchPolicy: 'network-only'
      });
    }
  };

  const updateFormData = (testId, testItem, testDataId, data) => {
    setFormData({ ...data });
  };

  // const submitData = (testId, testItem, testDataId, data) => {
  const submitData = async testDataId => {
    let formValues = Object.assign({}, formData);

    Object.keys(formValues).forEach(key => {
      Object.keys(formValues[key]).forEach(laterality => {
        if (Array.isArray(formValues[key][laterality])) {
          formValues[key][laterality] = [
            ...formData[key][laterality].map(v => {
              return v !== '' && typeof v !== 'object' && !isNaN(Number(v))
                ? Number(v)
                : null;
            })
          ];
        } else {
          console.error(key, formValues[key]);
          formValues[key][laterality] = [''];
        }
      });

      const testItemValue = {
        [key]: {
          [key]: {
            ...formValues[key]
          }
        }
      };
      formValues = { ...formValues, ...testItemValue };
    });

    // Fallback for testset version 1
    /*const testIdKey = session.version === 1 ? testId : testItem.id;

    const resultData = {
      [testIdKey]: {
        [testItem.id]: {
          ...formValues
        }
      }
    };*/

    return await updateTestData({
      variables: {
        id: testDataId,
        data: JSON.stringify(formValues),
        finished: FinishedState.STARTED
      }
    })
      .then(result => {
        setFormIsDirty(false);
        setFormIsUpdated(true);
        notification.add(result.data.editTestData.id, {
          message: intl.formatMessage(messages.messageTestdataSaved)
        });
        return result;
      })
      .catch(e => {
        notification.add(`testDataError${testDataId}`, {
          message: intl.formatMessage(messages.messageTestdataSaveError),
          level: 'error'
        });
        Sentry.captureException(e);
        return e;
      });
  };

  /*const addTouched = id => {
    setFormIsDirty(true);
    // setTouchedFields(Array.from(new Set([...touchedFields, id])));
  };*/

  return (
    <TestDataContext.Provider
      value={{
        testDataId,
        testData,
        testDataLoading,
        formData,
        groupedTests,
        refetchTestData,
        updateFormData,
        submitData,
        createTestData,
        // touchedFields,
        formIsUpdated,
        formIsDirty,
        setFormIsDirty
      }}
    >
      {children}
    </TestDataContext.Provider>
  );
};

function useTestDataContext() {
  const context = useContext(TestDataContext);
  if (context === undefined) {
    throw new Error(
      'The TestDataContext hook must be used within a TestDataProvider.Provider'
    );
  }
  return context;
}

export { TestDataProvider, useTestDataContext };

const filterGrowthPredictionData = tests => {
  const growIds = [
    'face72bd-d260-44a3-b55c-3bae9e3d18dc',
    '496d355a-4667-4253-8701-6a26bc7a3f59',
    '7497420b-4a4f-4958-971e-f68aa41cbbe9',
    '05c16b3e-0f75-4f17-a837-0d6163b0fd22',
    '5737716d-7eb3-4724-85d2-7baa4652ae93',
    '105b2d29-1469-4d27-bdc0-9d9b6c44b915',
    '0c58cad6-8a6f-402f-b30e-b00e06871f14'
  ];
  //const excludeIds = []; //'32c6900c-8eea-4be4-a5f3-3ff558308afa'

  return tests.map(test => {
    if (test.testItems.find(t => growIds.indexOf(t.id) !== -1)) {
      test.category = 'B_growthprediction';
    }
    return test;
  });
  //.filter(test => excludeIds.indexOf(test.id) === -1);
};
