import React, { useContext } from 'react';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import { FormattedMessage } from 'react-intl';
import { StoreContext } from 'index';
import Button from 'components/button/Button';

const MailAllReportsModalButton = ({
  entityId,
  session,
  options,
  children,
  onClick,
  ...props
}) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  if (
    !user.isHylyghtOrg &&
    (session.isIDo ||
      session.isILike ||
      session.isIAm ||
      session.isDjas ||
      session.isHan ||
      user.isHanOrg ||
      session.isHanSisp ||
      session.isKlusce)
  )
    return <></>;
  return (
    <Button menu onClick={onClick} {...props}>
      <Icon id="send" />
      <FormattedMessage {...messages.menuItemSessionMailAllReports} />
    </Button>
  );
};

export default MailAllReportsModalButton;
