import React from 'react';
import AnnotationListItem from 'containers/pages/videos/AnnotationListItem';

AnnotationList.propTypes = {};

function AnnotationList({
  videoId,
  video,
  annotations,
  activeAnnotation,
  parentPath,
  onClickItem,
  ownershipVideo,
  videoSticky,
  overflowParent,
  editable,
  refetch
}) {
  return (
    <>
      {annotations.map(
        annotation =>
          annotation.person && (
            <AnnotationListItem
              video={video}
              ownershipVideo={ownershipVideo}
              key={annotation.id}
              videoId={videoId}
              annotation={annotation}
              activeAnnotation={activeAnnotation}
              parentPath={parentPath}
              onClick={onClickItem}
              editable={editable}
              refetch={refetch}
              videoSticky={videoSticky}
              overflowParent={overflowParent}
            />
          )
      )}
    </>
  );
}

export default AnnotationList;
