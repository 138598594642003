import { Fragment, useContext, useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import { CardBody, CardFooter } from 'components/card';
import { ButtonsGroup, Button } from 'components/button';
import { DROP_STATE, IMPORT_SPORTERS_EXAMPLE_URL } from 'constants.js';
import Icon from 'components/icon/Icon';
import ProgressBar from 'components/progress-bar/ProgressBar';
import messages from 'messages';
import {
  importSporters,
  MUTATION_IMPORT_SPORTERS
} from 'services/aws/file-upload';
import { StoreContext } from 'index';
import { useMutation } from '@apollo/client';
import ImportAthletesReviewTable from 'containers/pages/sporters/ImportAthletesReviewTable';

export const IMPORT_SPORTERS_STATE = Object.freeze({
  DEFAULT: 'default',
  UPLOADING: 'uploading',
  PROCESSING: 'processing',
  RESULT: 'result',
  FAILED: 'failed',
  FAILED_ERRORS: 'failed-errors',
  IMPORTING: 'importing'
});

const ImportSportersCard = ({
  rootEntityId,
  entityId,
  entityName,
  onClose,
  onStateChange
}) => {
  const {
    authStore: { user },
    uiState,
    uiState: { locale }
  } = useContext(StoreContext);
  const [importState, setImportState] = useState(IMPORT_SPORTERS_STATE.DEFAULT);
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [athletes, setAthletes] = useState([]);
  const [errors, setErrors] = useState(0);
  const [selectedAthletes, setSelectedAthletes] = useState([]);

  const [importProps, setImportProps] = useState({
    sportersCount: 0,
    extension: 'xslx',
    fileId: null
  });

  const [importSportersMutation, { loading }] = useMutation(
    MUTATION_IMPORT_SPORTERS
  );

  useEffect(() => {
    onStateChange(importState);
  }, [importState]);

  const onUploadingProgress = progress => {
    setUploadingProgress(() => (progress.loaded / progress.total) * 100);
  };

  const onReset = () => {
    setImportState(IMPORT_SPORTERS_STATE.DEFAULT);
    setUploadingProgress(0);
    setErrors(0);
    setAthletes([]);
    setSelectedAthletes([]);
    setImportProps({
      extension: '',
      fileId: null
    });
  };

  const setStates = athletes => {
    setAthletes([...athletes]);
    //setSelectedAthletes();

    let errors = 0;

    setSelectedAthletes(
      athletes
        .map(athlete => {
          if (athlete.problems.filter(p => p.type === 'error').length === 0) {
            return athlete;
          }
          errors++;
          return false;
        })
        .filter(Boolean)
    );

    setErrors(errors);
  };

  const onClickImportHandler = async () => {
    uiState.increasePendingRequest();
    await importSportersMutation({
      variables: {
        persons: JSON.stringify(selectedAthletes),
        entityId,
        topLevelEntityId: user.rootEntityId,
        test: false
      }
    })
      .then(({ data }) => {
        if (data?.importSporters2 !== undefined) {
          uiState.decreasePendingRequest();
          setImportState(IMPORT_SPORTERS_STATE.IMPORTING);
        }
      })
      .catch(error => {
        uiState.decreasePendingRequest();
        Sentry.captureException(error);
      });
  };

  const onClickCheckHandler = async () => {
    uiState.increasePendingRequest();
    await importSportersMutation({
      variables: {
        persons: JSON.stringify(athletes),
        entityId,
        topLevelEntityId: user.rootEntityId,
        test: true
      }
    })
      .then(({ data }) => {
        if (data && data.importSporters2 !== undefined) {
          uiState.decreasePendingRequest();
          const athletesResponse = JSON.parse(data.importSporters2);
          if (athletesResponse.error) {
            setImportState(IMPORT_SPORTERS_STATE.FAILED);
          } else {
            setStates(athletesResponse);
          }
        }
      })
      .catch(error => {
        uiState.decreasePendingRequest();
        Sentry.captureException(error);
      });
  };

  const onDrop = async acceptedFile => {
    if (acceptedFile.length === 1) {
      const file = acceptedFile[0];
      const extension = `.${file.name.split('.').pop()}`;
      setImportState(IMPORT_SPORTERS_STATE.UPLOADING);

      try {
        const { athletes, fileId, error } = await importSporters({
          entityId: rootEntityId,
          groupId: entityId,
          file,
          onUploadProgress: onUploadingProgress,
          onUploaded: () => setImportState(IMPORT_SPORTERS_STATE.PROCESSING)
        });

        const hasValidResponse = !error;

        if (error) {
          Sentry.captureException(error);
          setImportState(IMPORT_SPORTERS_STATE.FAILED);
        } else {
          setImportState(IMPORT_SPORTERS_STATE.RESULT);
          setImportProps({
            extension: extension,
            fileId: hasValidResponse ? fileId : null
          });
          setStates(athletes);
        }
      } catch (error) {
        Sentry.captureException(error);
        setImportState(IMPORT_SPORTERS_STATE.FAILED);
      }
    }
  };

  const onEditAthletes = athletes => {
    setAthletes(athletes);
  };

  const onSelectAthletes = athletes => {
    setSelectedAthletes(athletes);
  };

  const language = locale.substring(0, 2);
  return (
    <Fragment>
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.cardImportSportersTitle} />
        </CardHeaderTitle>

        <CardHeaderSubtitle>
          <FormattedMessage {...messages.cardImportSportersSubTitle} />
        </CardHeaderSubtitle>
      </CardHeader>
      <CardBody modal>
        {importState === IMPORT_SPORTERS_STATE.DEFAULT && (
          <Fragment>
            <Dropzone
              className="c-file-upload c-file-upload--no-padding"
              accept={{
                'text/csv': [],
                'application/vnd.ms-excel': [],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                  []
              }}
              onDrop={onDrop}
              multiple={false}
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject
              }) => {
                let dropState = DROP_STATE.DEFAULT;

                if (isDragActive) {
                  dropState = DROP_STATE.DRAGGING;
                }
                if (isDragReject) {
                  dropState = DROP_STATE.REJECT;
                }
                return (
                  <div
                    className={classNames(
                      'c-file-upload--state c-file-upload--import-sporters',
                      {
                        'c-file-upload--rejected':
                          dropState === DROP_STATE.REJECT,
                        'c-file-upload--dragging':
                          dropState === DROP_STATE.DRAGGING
                      }
                    )}
                    style={{ maxWidth: 'calc(100% - 32px)', margin: 'auto' }}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <Icon hugeIcon id="upload" />
                    <p className="c-file-upload__message">
                      <FormattedMessage
                        {...messages.cardImportSportersDragInfo}
                      />
                    </p>
                    <div className="c-file-upload__dropzone-button">
                      <Icon id="browse" />
                      <label className={classNames('c-input__label', {})}>
                        <FormattedMessage
                          {...messages.cardImportSportersBrowseButton}
                        />
                      </label>
                    </div>
                  </div>
                );
              }}
            </Dropzone>
            <p className="c-file-upload__message u-margin-horizontal-auto u-margin-top-large u-margin-bottom-small">
              <FormattedMessage {...messages.cardImportSportersFileInfo} />
              <a
                download
                rel="noreferrer"
                target="_blank"
                href={IMPORT_SPORTERS_EXAMPLE_URL.replace(
                  '{language}',
                  language
                )}
                className="c-file-upload__link"
              >
                <FormattedMessage
                  {...messages.cardImportSportersDownloadExampleButton}
                />
              </a>
            </p>
          </Fragment>
        )}
        {importState === IMPORT_SPORTERS_STATE.UPLOADING && (
          <div className="c-file-upload--wrapper-states">
            <Icon id="spreadsheet" xHugeIcon />
            <ProgressBar secondary value={Math.round(uploadingProgress / 2)} />
            <p className="c-file-upload__message">
              <FormattedMessage
                {...messages.cardImportSportersUploadingFile}
                values={{ extension: importProps.extension }}
              />
            </p>
          </div>
        )}
        {importState === IMPORT_SPORTERS_STATE.PROCESSING && (
          <div className="c-file-upload--wrapper-states">
            <Icon id="spreadsheet" xHugeIcon />
            <ProgressBar secondary value={75} />
            <p className="c-file-upload__message">
              <FormattedMessage
                {...messages.cardImportSportersIdentifyingSporters}
              />
            </p>
          </div>
        )}
        {importState === IMPORT_SPORTERS_STATE.FAILED && (
          <div className="c-file-upload--wrapper-states">
            <Icon id="alert" xHugeIcon />

            <p className="c-file-upload__message c-file-upload__message-failed">
              <FormattedMessage {...messages.cardImportSportersFailed} />
            </p>
          </div>
        )}
        {importState === IMPORT_SPORTERS_STATE.RESULT &&
          importState !== IMPORT_SPORTERS_STATE.FAILED && (
            <div className="c-file-upload--wrapper-states u-padding-top-medium">
              <p className="c-file-upload__message c-file-upload__message-success">
                <FormattedMessage
                  {...messages.cardImportSportersResultQuestion}
                  values={{
                    count: athletes.length,
                    errors: errors,
                    group: entityName
                  }}
                />
              </p>

              <div className="c-file-upload__content">
                {athletes.length > 0 && (
                  <ImportAthletesReviewTable
                    athletes={athletes}
                    selected={selectedAthletes}
                    onEdit={onEditAthletes}
                    onSelect={athletes => onSelectAthletes(athletes)}
                  />
                )}
              </div>
            </div>
          )}
        {importState === IMPORT_SPORTERS_STATE.IMPORTING && (
          <div className="c-file-upload--wrapper-states">
            <p className="c-file-upload__message c-file-upload__message-success">
              <FormattedMessage {...messages.cardImportSportersImporting} />
            </p>
          </div>
        )}
      </CardBody>
      <CardFooter modal>
        <ButtonsGroup fullwidth>
          {(importState === IMPORT_SPORTERS_STATE.RESULT ||
            importState === IMPORT_SPORTERS_STATE.UPLOADING) && (
            <Button secondary onClick={onClose}>
              <FormattedMessage {...messages.cardButtonCancel} />
            </Button>
          )}

          {(importState === IMPORT_SPORTERS_STATE.FAILED ||
            importState === IMPORT_SPORTERS_STATE.FAILED_ERRORS) && (
            <Button primary onClick={onReset}>
              <FormattedMessage {...messages.cardImportSportersRetryButton} />
            </Button>
          )}
          {importState === IMPORT_SPORTERS_STATE.RESULT && (
            <>
              <Button
                secondary
                disabled={loading}
                onClick={() => onClickCheckHandler()}
              >
                <FormattedMessage {...messages.cardImportSportersCheckButton} />
              </Button>
              <Button
                primary
                disabled={loading}
                onClick={() => onClickImportHandler()}
              >
                <FormattedMessage
                  {...messages.cardImportSportersAddButton}
                  values={{ count: selectedAthletes.length }}
                />
              </Button>
            </>
          )}
          {importState === IMPORT_SPORTERS_STATE.IMPORTING && (
            <Button primary onClick={() => onClose(true)}>
              <FormattedMessage {...messages.cardImportSportersCloseButton} />
            </Button>
          )}
        </ButtonsGroup>
      </CardFooter>
    </Fragment>
  );
};

export default ImportSportersCard;
