import { Fragment } from 'react';
import { Lateralities, Laterality } from 'constants.js';
import ProgressBar from 'components/progress-bar/ProgressBar';
import ProgressBarLabelBlock from 'components/progress-bar/ProgressBarLabelBlock';
import ProgressBarGroup from 'components/progress-bar/ProgressBarGroup';
import TableCell from 'components/table/TableCell';
import TableRow from 'components/table/TableRow';
import { getProgressBarColorByPercentile } from 'containers/pages/benchmarks/utils/benchmarkUtil';
import messages from 'messages';
import { useIntl } from 'react-intl';
import { uid } from 'react-uid';
import { isNumber } from 'utils/math';
import ProgressBarLabelSide from 'components/progress-bar/ProgressBarLabelSide';

const TestItemResultRow = ({
  testItem,
  options = { showScore: false, showPercentage: true },
  hasBenchmarkedValues,
  sidesDisabled,
  colSpan,
  noValues
}) => {
  const val = hasBenchmarkedValues ? testItem.benchmarked : testItem.values;

  return (
    <>
      {val && !noValues && (
        <TableRow>
          {Object.keys(val).map((side, index, lateralities) => {
            // Left/Right without one side
            return (
              <Fragment key={uid(`${testItem.id}_${side}`)}>
                {/* There is only 1 Side + Left is an empty side, if side is RIGHT === 1 */}
                {lateralities.length === 1 &&
                  Number(side) === Laterality.RIGHT && (
                    <TableCell
                      evenHalf
                      data-info={`side-${side} array-${lateralities.length}`}
                    />
                  )}

                {/* Filled size: */}
                <FilledTableCell
                  testItem={testItem}
                  side={side}
                  options={options}
                  colSpan={colSpan}
                  sidesDisabled={sidesDisabled}
                />

                {/* There is only 1 Side + Right is an empty side, if side is LEFT === 0 */}
                {lateralities.length === 1 &&
                  Number(side) === Laterality.LEFT && (
                    <TableCell
                      evenHalf
                      data-info={`side-${side} array-${lateralities.length}`}
                    />
                  )}
              </Fragment>
            );
          })}
        </TableRow>
      )}
    </>
  );
};

export default TestItemResultRow;

const FilledTableCell = ({
  testItem,
  side,
  options,
  colSpan,
  sidesDisabled
}) => {
  const intl = useIntl();

  // Percentile is sometimes between 0 en 1 or already a percentage
  const benchmarkedPercentage = testItem?.benchmarked?.[side]?.percentage;
  let percentile =
    isNumber(benchmarkedPercentage) && benchmarkedPercentage >= 0
      ? testItem.benchmarked[side].percentage
      : null;

  percentile =
    isNumber(percentile) && percentile >= 0 && percentile <= 1
      ? Math.round(percentile * 100)
      : percentile;

  let labelValue;

  if (testItem.isOption) {
    labelValue = testItem.result?.[side]?.[0]?.label ?? '';
  }
  if (!testItem.isOption && testItem?.result?.[side]?.[0]) {
    labelValue = intl.formatMessage(messages.labelUnit, {
      count: testItem.result[side][0],
      unit: testItem.unit
    });
  }

  return (
    <TableCell evenHalf colSpan={colSpan}>
      {(!sidesDisabled[side] || options.showBothSides) && (
        <>
          <ProgressBarGroup fullWidth>
            {/* Don't show the Side label when Unilateral */}
            {Number(side) !== Laterality.UNILATERAL &&
              Lateralities[side] &&
              labelValue && (
                <ProgressBarLabelBlock>
                  <ProgressBarLabelSide>
                    {Lateralities[side]}
                  </ProgressBarLabelSide>
                </ProgressBarLabelBlock>
              )}

            {((options.showScore && testItem.showScore) ||
              (testItem.showScore && !isNumber(percentile))) &&
              labelValue && <ProgressBarLabelBlock value={labelValue} />}

            {/* `testItem.showPercentage` Not working
                // @ TrainingAdviceCategoryCard
                // @ http://localhost:3000/sessions/7f747a4b-55e9-4641-ae25-c45de20fb32d/7f134424-6f16-4de3-8dc8-6c94387088cb/sporter/f30e5e6c-02e8-41c2-988e-646651669299/advice/72503efa-2a25-43b9-8fea-eced9caae16e
            */}

            {(testItem.showPercentage ||
              (!testItem.showPercentage && options.showPercentage)) &&
            isNumber(percentile) ? (
              <ProgressBar
                benchmarksWithFrame
                range={getProgressBarColorByPercentile(percentile)}
                value={`${percentile}`}
                showPercentage={options.showPercentage}
                // showScore={options.showScore}
                visibleValue
              >
                {`${percentile} %`}
              </ProgressBar>
            ) : (
              ''
            )}
          </ProgressBarGroup>
        </>
      )}
    </TableCell>
  );
};
