import React, { useContext } from 'react';
import { StoreContext } from 'index';
import { FormattedMessage, useIntl } from 'react-intl';
import CardBody from 'components/card/CardBody';
import Card from 'components/card/Card';
import messages from 'messages';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderText
} from 'components/card/card-header';
import Select, { Option } from 'components/input/Select';
import Cues from 'containers/partials/inputs/Cues';
import { Editor } from '@tinymce/tinymce-react';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import { useFormikContext } from 'formik';
import FieldInput from 'components/input/FieldInput';
import { FieldTextarea } from 'components/input/FieldTextarea';
import { Field } from 'formik';
import TagTree from 'containers/partials/inputs/TagTree';
import FileUppyWrapper from 'containers/partials/file-upload/FileUppyWrapper';
import { FILE_TYPES } from 'constants.js';
import ThumbList from 'containers/partials/media/ThumbList';
import useMediaList from 'hooks/utils/useMediaList';
import File from 'models/File';
import { sortByArray } from 'utils/sort';

const ExerciseFormInput = ({
  edit,
  readOnly,
  isAdd,
  setEdit,
  onCancel,
  entityId
}) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { values, errors, touched, submitForm, setFieldValue, resetForm } =
    useFormikContext();

  const { getMedia, addMedia, removeMedia } = useMediaList(values.media);

  const intl = useIntl();

  const onFilesUploaded = async files => {
    if (files.length === 0) return;

    if (files.length > 0) {
      const media = addMedia(
        files.map(f => new File({ ...f, status: 'waiting', type: '' }))
      );
      setFieldValue('meta', {
        order: media.map(m => m.id)
      });
    }
  };

  const onFileDeleted = async fileId => {
    const media = removeMedia(fileId);
    setFieldValue('meta', {
      order: media.map(m => m.id)
    });
  };

  const onSortHandler = ids => {
    setFieldValue('meta', {
      order: [...ids]
    });
  };

  // if (loading) <Loader />;

  return (
    <Card secondary multiBody>
      <CardHeader secondary>
        <CardHeaderText>
          <CardHeaderTitle>
            <FormattedMessage {...messages.cardTitleExercise} />
          </CardHeaderTitle>
        </CardHeaderText>
        {!readOnly && (values.editable || isAdd) && (
          <FormEditHeaderButtons
            edit={edit}
            setEdit={setEdit}
            onSubmit={submitForm}
            onCancel={() => {
              resetForm();
              if (onCancel) onCancel();
            }}
          />
        )}
      </CardHeader>
      <CardBody secondary separatorBottom>
        <div className="o-layout o-layout--small">
          {user.isGSVOrg ? (
            <div className="o-layout__item u-3-of-3">
              {/* EXERCISE TITLE */}
              <FieldInput id="title" name="title" readOnly={readOnly}>
                <FormattedMessage {...messages.exercisesLabelTitle} />*
              </FieldInput>
            </div>
          ) : (
            <>
              <div className="o-layout__item u-2-of-3">
                {/* EXERCISE TITLE */}
                <FieldInput id="title" name="title" readOnly={readOnly}>
                  <FormattedMessage {...messages.exercisesLabelTitle} />*
                </FieldInput>
              </div>
              <div className="o-layout__item u-1-of-3">
                {/* LEVEL */}
                {/* TODO level field is deprecated */}
                <Select
                  extraClassNames="u-growth-left"
                  label={`${intl.formatMessage(messages.exercisesLabelLevel)}*`}
                  name="level"
                  left
                  readOnly={!edit}
                  value={values.level}
                  onChange={option => {
                    setFieldValue('level', option.value);
                  }}
                >
                  {[1, 2, 3, 4, 5].map(lvl => (
                    <Option
                      key={`level_${lvl}`}
                      value={lvl}
                      label={`Level ${lvl}`}
                    />
                  ))}
                </Select>
              </div>
            </>
          )}
        </div>
      </CardBody>

      <CardHeader secondary>
        <CardHeaderText>
          <CardHeaderTitle>
            <FormattedMessage {...messages.cardExercisePracticalTitle} />
          </CardHeaderTitle>
        </CardHeaderText>
      </CardHeader>
      <CardBody secondary separatorBottom>
        {/* Notes / INTRO */}
        <FieldTextarea
          id="note"
          name="note"
          readOnly={!edit}
          errors={errors}
          touched={touched}
          resizeVertical
        >
          <FormattedMessage {...messages.exercisesLabelNotes} />
        </FieldTextarea>

        {/* Remarks */}
        <FieldTextarea
          id="remarks"
          name="remarks"
          readOnly={!edit}
          errors={errors}
          touched={touched}
          resizeVertical
        >
          <FormattedMessage {...messages.exercisesLabelRemarks} />
        </FieldTextarea>

        <Cues
          entityId={entityId}
          label={`${intl.formatMessage(messages.exercisesLabelCues)}`}
          cues={values.cues}
          readOnly={!edit}
          onChange={newCues => setFieldValue('cues', newCues)}
        />
      </CardBody>

      <CardHeader secondary extraClassNames="u-margin-bottom-none">
        <CardHeaderText>
          <CardHeaderTitle>
            <FormattedMessage {...messages.exercisesLabelFotoAndVideo} />
          </CardHeaderTitle>
        </CardHeaderText>
      </CardHeader>
      <CardBody
        secondary
        separatorBottom
        extraClassNames="u-padding-bottom-medium"
      >
        {/* MEDIA */}
        <div className={'c-file-upload--state c-file-upload--state-small'}>
          <div className="c-media">
            {!isAdd ? (
              <FileUppyWrapper
                entityId={entityId}
                linkId={values.id}
                type={'exercise'}
                accept={[...FILE_TYPES.VIDEO, ...FILE_TYPES.IMAGE]}
                onFilesUploaded={files => onFilesUploaded(files)}
                numFiles={getMedia().length}
                isPublic
              >
                {getMedia().length > 0 && (
                  <ThumbList
                    entityId={entityId}
                    media={
                      values.meta?.order
                        ? sortByArray([...getMedia()], values.meta.order)
                        : [...getMedia()]
                    }
                    onSort={onSortHandler}
                    onFileDeleted={file => onFileDeleted(file)}
                    editable={true}
                  />
                )}
              </FileUppyWrapper>
            ) : (
              <p>
                <FormattedMessage {...messages.exercisesWarningFotoAndVideo} />
              </p>
            )}
          </div>
        </div>
      </CardBody>

      <CardHeader secondary>
        <CardHeaderText>
          <CardHeaderTitle>
            <FormattedMessage {...messages.exercisesLabelProtocol} />
          </CardHeaderTitle>
        </CardHeaderText>
      </CardHeader>
      <CardBody
        secondary
        separatorBottom
        extraClassNames="u-padding-bottom-large"
      >
        {/* PROTOCOL */}
        <Field
          as={Editor}
          name="protocol"
          init={{
            branding: false,
            height: 300,
            menubar: false,
            // placeholder: intl.formatMessage(messages.exercisesLabelProtocol),
            plugins: [
              'advlist autolink lists link image',
              'charmap print preview anchor help',
              'searchreplace visualblocks code',
              'insertdatetime media table paste'
            ],
            toolbar:
              'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help'
          }}
          onEditorChange={(content, editor) => {
            setFieldValue('protocol', editor.getContent());
          }}
        />
      </CardBody>

      <CardHeader secondary>
        <CardHeaderText>
          <CardHeaderTitle>
            <FormattedMessage {...messages.exercisesLabelTags} />
          </CardHeaderTitle>
        </CardHeaderText>
      </CardHeader>
      <CardBody secondary>
        {/* TAGS */}
        <TagTree
          entityId={entityId}
          label={`${intl.formatMessage(messages.exercisesLabelTags)}`}
          tags={values.tags}
          isMulti
          isClearable={false}
          edit={edit}
          onChange={tags => {
            setFieldValue('tagIds', tags);
          }}
        />
      </CardBody>
      {!readOnly && (values.editable || isAdd) && (
        <FormEditFooterButtons
          edit={edit}
          setEdit={setEdit}
          onCancel={() => {
            resetForm();
            if (onCancel) onCancel();
          }}
          onSubmit={submitForm}
          topMargin
        />
      )}
    </Card>
  );
};

export default ExerciseFormInput;
