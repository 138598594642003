import classNames from 'classnames';

const InputWrapper = props => {
  return (
    <div
      className={classNames(
        'c-input__group-benchmarks',
        props.extraClassNames,
        {
          'c-input__group-range-date': props.dateRangeCol
        }
      )}
      style={props.style}
    >
      {props.label?.trim().length > 0 && !props.trialLabel && (
        <span className={classNames('c-input__group-label')}>
          {props.label}
        </span>
      )}
      {props.trialLabel && props.trialLabel}
      {props.tooltip && props.tooltip}
      {props.children}
    </div>
  );
};

export default InputWrapper;
