import React from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { ROUTE_EXERCISES_PROGRAMS_ENTITY } from 'routes/RouteList';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import {
  MUTATION_ARCHIVE_PROGRAM,
  QUERY_GET_PROGRAMS
} from 'services/aws/programs-query';

const RemoveProgramButton = ({
  entityId,
  programId,
  routing: { push },
  children,
  onComplete,
  ...props
}) => {
  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_ARCHIVE_PROGRAM}
      confirmTitle={messages.modalConfirmRemoveProgramTitle}
      confirmMessage={messages.modalConfirmRemoveProgramMessage}
      variables={{
        programId
      }}
      refetchQueries={[{ query: QUERY_GET_PROGRAMS, variables: { entityId } }]}
      update={(cache, { data: { archiveExerciseProgram } }) => {
        if (archiveExerciseProgram) {
          push(ROUTE_EXERCISES_PROGRAMS_ENTITY.replace(':entityId', entityId));
        }
      }}
    >
      <Icon id="delete" strokeColor="color-neutral-dark" />
      {children}
    </ConfirmMutationButton>
  );
};

export default inject('routing')(withRouter(RemoveProgramButton));
