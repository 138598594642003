import classNames from 'classnames';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import Select, { components } from 'react-select';
import Icon from '../icon/Icon';

const MultiValueRemove = props => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon id="close" />
    </components.MultiValueRemove>
  );
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon id="dropdown-open" extraClassNames={classNames('c-icon--input')} />
    </components.DropdownIndicator>
  );
};

const IndicatorSeparator = () => {
  return <></>;
};

const MultiOutsideTagsSelect = ({ dropdownDisabled = false, ...props }) => {
  const handleRemoveValue = e => {
    if (!props.onChange) return;
    const { name: buttonName } = e.currentTarget;
    const removedValue = props.value.find(val => val.value === buttonName);
    if (!removedValue) return;
    props.onChange(
      props.value.filter(val => val.value !== buttonName),
      { action: 'remove-value', removedValue }
    );
  };

  return (
    <>
      <Select
        value={props.value}
        options={props.options}
        isMulti={props.isMulti}
        isClearable={props.isClearable}
        onChange={props.onChange}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        isSearchable={!props.readOnly}
        isDisabled={props.disabled}
        isLoading={props.isLoading}
        noOptionsMessage={() => props.noOptionsMessage}
        className={classNames('p-select-container')}
        classNamePrefix={'p-select'}
        controlShouldRenderValue={!props.outsideMultiValues}
        components={{
          Menu: dropdownDisabled
            ? () => <></>
            : ({ children, ...props }) => (
                <components.Menu {...props}>{children}</components.Menu>
              ),
          IndicatorsContainer: dropdownDisabled
            ? () => <></>
            : ({ children, ...props }) => (
                <components.IndicatorsContainer {...props}>
                  {children}
                </components.IndicatorsContainer>
              ),
          MultiValueRemove,
          DropdownIndicator,
          IndicatorSeparator
        }}
      />
      {props.outsideMultiValues && (
        <div
          className="p-select-container"
          style={{ marginTop: 8, width: '100%' }}
        >
          {props.value &&
            props.value.map(val => {
              return (
                <div
                  className="p-select__multi-value"
                  key={val.id}
                  style={{
                    justifyContent: 'space-between',
                    overflow: 'hidden'
                  }}
                  title={val.label}
                >
                  <div className="p-select__multi-value__label">
                    {val.label}
                  </div>

                  <button
                    className="p-select__multi-value__remove"
                    name={val.value}
                    onClick={handleRemoveValue}
                    aria-label={val.label}
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      paddingLeft: 4,
                      borderRadius: 0,
                      paddingRight: 8,
                      border: 0,
                      background: 'inherit'
                    }}
                  >
                    <Icon id="close" />
                  </button>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};

MultiOutsideTagsSelect.propTypes = {
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  disabled: PropTypes.bool,
  creatable: PropTypes.bool
};

export default MultiOutsideTagsSelect;
