export default class TestData {
  id;
  personId;
  result;
  data;
  tests;
  sports;
  finished;
  finishedPercentage;
  date_updated;
  date_created;
  note;
  testSetVersion;
  testSet;
  constructor({
    id,
    personId,
    result = '[]',
    data = '',
    sports = [],
    date_updated,
    date_created,
    finished,
    finishedPercentage,
    notes,
    testSetVersion,
    testSet
  }) {
    this.id = id;
    this.personId = personId;
    try {
      this.result = result ? JSON.parse(result) : [];
    } catch (e) {
      this.result = result ? result : [];
    }
    try {
      this.data = data ? JSON.parse(data) : [];
    } catch (e) {
      this.data = data ? data : [];
    }
    this.tests = []; // this.result.tests;
    try {
      this.sports = sports ? JSON.parse(sports) : [];
    } catch (e) {
      this.sports = sports ? sports : [];
    }
    this.date_updated = date_updated;
    this.date_created = date_created;
    this.finished = finished;
    this.finishedPercentage = finishedPercentage;
    this.note = notes?.length > 0 ? notes[0] : null;
    this.testSetVersion = testSetVersion;
    this.isTestSetV2 = testSetVersion && testSetVersion === 2;
    this.testSet = testSet;
  }
}
