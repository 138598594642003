import classNames from 'classnames';
import './Accordion.scss';

const Accordion = props => {
  return (
    <div
      className={classNames('c-accordion', props.extraClassNames, {
        'c-accordion--visible-flow': props.visibleFlow,
        'c-accordion--cogwheel': props.cogwheel,
        'c-accordion--switch': props.switch,
        'c-accordion--small': props.small
      })}
      style={props.style}
    >
      {props.children(props.onClick)}
    </div>
  );
};

export default Accordion;
