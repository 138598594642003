import React from 'react';
import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import messages from 'messages';
import { FormattedMessage } from 'react-intl';
import ScreeningForm from 'containers/pages/rehab/screening/ScreeningForm';
import { CardBody, CardFooter } from 'components/card';
import { Button, ButtonsGroup } from 'components/button';
import { TestSetV2Type } from 'constants.js';
import Session from 'models/Session';
import ScreeningFormDetails from 'containers/pages/rehab/screening/ScreeningFormDetails';

function AddScreeningModal({
  entityId,
  screening = null,
  personInjury,
  onClose
}) {
  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal card autoHeight isOpen={true} onClose={onCloseHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          {screening ? (
            <FormattedMessage
              {...messages.rehabCopyScreeningTitle}
              values={{
                screening: screening.name
              }}
            />
          ) : (
            <FormattedMessage {...messages.rehabAddScreeningTitle} />
          )}
        </CardHeaderTitle>
      </CardHeader>
      <ScreeningForm
        entityId={entityId}
        personInjury={personInjury}
        onSubmitted={onCloseHandler}
        screening={new Session({ ...screening, type: TestSetV2Type.REHAB })}
      >
        {({ isSubmitting }) => (
          <>
            <CardBody modalWithOverflow extraClassNames="u-padding-bottom-huge">
              <ScreeningFormDetails
                entityId={entityId}
                personInjury={personInjury}
                edit
              />
            </CardBody>

            <CardFooter modal extraClassNames="c-card__footer--modal-larger">
              <ButtonsGroup>
                <Button
                  type="button"
                  secondary
                  onClick={() => onCloseHandler()}
                >
                  <FormattedMessage {...messages.cancelButton} />
                </Button>
                <Button type="submit" primary disabled={isSubmitting}>
                  <FormattedMessage {...messages.saveButton} />
                </Button>
              </ButtonsGroup>
            </CardFooter>
          </>
        )}
      </ScreeningForm>
    </Modal>
  );
}

export default AddScreeningModal;
