import { groupBy } from 'utils/array';
import { NoteTypes, TemplateType } from 'constants.js';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import Note from 'models/Note';

export default class Template {
  id;
  entityId;
  personId;
  title;
  exerciseIds;
  exercises;
  tags;
  duration;
  editable;
  notes = [];
  meta;
  type = TemplateType.TEMPLATE;
  constructor({
    id,
    entityId,
    title = '',
    personId = '',
    exerciseIds = [],
    exercises = [],
    tags = [],
    duration = 0,
    notes = [],
    editable,
    type
  }) {
    this.id = id;
    this.entityId = entityId;
    this.title = title;
    this.personId = personId;
    this.exerciseIds = exerciseIds;
    this.exercises = exercises;
    this.tags = tags;
    this.duration = duration;
    this.editable = editable;
    this.type = type;

    if (notes.length > 0) {
      const groupedNotes = groupBy(
        notes
          .filter(n => n.noteType === NoteTypes.TEMPLATE)
          .map(n => new Note({ ...n })),
        ['linkId2']
      );
      Object.keys(groupedNotes).forEach(key => {
        groupedNotes[key] = sort(groupedNotes[key], {
          keys: [
            {
              key: 'date_created',
              desc: true,
              dataType: SORT_DATA_TYPES.NUMBER
            }
          ]
        });
      });
      this.notes = groupedNotes;
    }
  }

  static get modelName() {
    return 'Template';
  }
}
