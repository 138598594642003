import { Card, CardBody } from 'components/card';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import FormEditHeaderButtons from '../buttons/FormEditHeaderButtons';
import FormEditFooterButtons from '../buttons/FormEditFooterButtons';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import IllnessForm from 'containers/pages/rehab/illness/IllnessForm';
import IllnessDetailFields from 'containers/pages/rehab/illness/IllnessDetailFields';
import useEdit from 'hooks/utils/useEdit';

function IllnessDetailCard({
  entityId,
  personIllness,
  medical,
  sporterId,
  ...props
}) {
  const [edit, setEdit] = useEdit(props.edit, true);

  return (
    <Card secondary>
      <IllnessForm
        entityId={entityId}
        sporterId={sporterId}
        personIllness={personIllness}
        medical={medical}
      >
        {({ submitForm, resetForm }) => (
          <>
            <CardHeader secondary>
              <CardHeaderTitle>
                <FormattedMessage {...messages.titleIllnessDetails} />
              </CardHeaderTitle>
              <FormEditHeaderButtons
                edit={edit}
                setEdit={setEdit}
                onCancel={resetForm}
                onSubmit={submitForm}
                type={'button'}
              />
            </CardHeader>
            <CardBody secondary>
              <IllnessDetailFields edit={edit} />
            </CardBody>
            <FormEditFooterButtons
              edit={edit}
              setEdit={setEdit}
              onCancel={resetForm}
              onSubmit={submitForm}
              type={'button'}
            />
          </>
        )}
      </IllnessForm>
    </Card>
  );
}

export default IllnessDetailCard;
