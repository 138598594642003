import FieldSelect from 'components/input/FieldSelect';
import useFiles from 'hooks/queries/useFiles';
import { useEffect } from 'react';

const tempLinkId = '99999';

function PythonVideoSelect({ id, entityId, name, label, readOnly, onChange }) {
  const { files, getFiles } = useFiles({ entityId, linkId: tempLinkId });

  useEffect(() => {
    getFiles();
  }, []);

  return (
    <FieldSelect
      id={id}
      name={name}
      onChange={select => onChange(select)}
      readOnly={readOnly}
      options={files
        .filter(f => f.type === 'video_analysis_video')
        .map(f => ({
          ...f,
          value: f.id,
          label: f.filename
        }))}
      label={label}
    />
  );
}

export default PythonVideoSelect;
