import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useSessionContext } from 'contexts/SessionContext';
import Checkbox from 'components/input/Checkbox';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import BenchmarksSelect from 'containers/partials/inputs/BenchmarksSelect';
import { StoreContext } from 'index';
import { Accordion, AccordionContent } from 'components/accordion';
import AccordionButtonHooks from 'components/accordion/AccordionButtonHooks';
import { Button } from 'components/button';
import { useUIDSeed } from 'react-uid';
import { reportOptions } from 'constants.js';
import classNames from 'classnames';
import TooltipPopper from 'components/tooltip/TooltipPopper';
import { CardHeaderTitle } from 'components/card/card-header';

const ReportOptions = ({
  onChangeBenchmark = null,
  inlineSelect = false,
  inModal = false,
  showCreateProgramButton = false,
  setModalData,
  ...props
}) => {
  const {
    authStore: {
      user,
      user: { entityId }
    }
  } = useContext(StoreContext);
  const [benchmarkId, setBenchmarkId] = useState();
  const [showBenchmarksAndOptions, setShowBenchmarksAndOptions] =
    useState(false);
  const { session, options, setOptions } = useSessionContext();
  const parentSelectRef = useRef();

  const [openAccordion, setOpenAccordion] = useState(
    props?.openAccordion ?? false
  );
  const [openSecondaryAccordion, setOpenSecondaryAccordion] = useState(
    props?.openSecondaryAccordion ?? false
  );

  useEffect(() => {
    if (options.benchmark) {
      setBenchmarkId(options.benchmark);
      setShowBenchmarksAndOptions(user.isHylyghtOrg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, options]);

  const onsetOption = option => {
    setOptions(prevOptions => ({ ...prevOptions, ...option }));

    onChangeBenchmark &&
      option?.benchmark &&
      onChangeBenchmark(option.benchmark);
  };

  return (
    showBenchmarksAndOptions && (
      <div
        className={classNames('c-accordion-options', {
          'c-accordion-options--in-modal': inModal,
          'u-margin-bottom-small': session.hasBenchmarkSelect
          //   'u-margin-top-medium': session.isPrevention || session.isRehab
        })}
        ref={parentSelectRef}
      >
        {session.hasBenchmarkSelect && (
          <div
            style={
              inModal || !(session.isPrevention || session.isRehab)
                ? { maxWidth: '100%' }
                : { maxWidth: 'calc(100% - 48px)', zIndex: 10 }
            }
          >
            <BenchmarksSelect
              inline={inlineSelect}
              entityId={entityId}
              value={benchmarkId}
              benchmarkId={options.benchmark}
              onChange={benchmark => onsetOption({ benchmark: benchmark.id })}
            />
          </div>
        )}

        {showCreateProgramButton && (
          <div style={{ maxWidth: 'calc(100% - 40px)', zIndex: 1 }}>
            <Button
              rounded
              accented
              iconRight
              extraClassNames="u-margin-left-auto"
              onClick={() => setModalData(true)}
            >
              <FormattedMessage {...messages.buttonCreateProgram} />
            </Button>
          </div>
        )}

        {(session.isPrevention || session.isRehab) && (
          <OptionsAccordion
            session={session}
            cogwheel={
              session.hasBenchmarkSelect ||
              session.isPrevention ||
              session.isRehab
            }
            small={session.isPrevention || session.isRehab}
            setOpenAccordion={setOpenAccordion}
            openAccordion={openAccordion}
            setOpenSecondaryAccordion={setOpenSecondaryAccordion}
            openSecondaryAccordion={openSecondaryAccordion}
            options={options}
            onsetOption={onsetOption}
          />
        )}
      </div>
    )
  );
};

export const OptionsAccordion = ({
  session,
  openAccordion,
  setOpenAccordion,
  openSecondaryAccordion,
  setOpenSecondaryAccordion,
  options,
  onsetOption,
  cogwheel,
  small
}) => {
  const uid = useUIDSeed();
  const simpleOptions = Object.keys(reportOptions).filter(
    option =>
      !reportOptions[option].type || reportOptions[option].type !== 'advanced'
  );
  const advancedOptions = Object.keys(reportOptions).filter(
    option =>
      reportOptions[option].type && reportOptions[option].type === 'advanced'
  );

  return (
    <Accordion cogwheel={cogwheel} small={small}>
      {() => (
        <>
          <AccordionButtonHooks
            id="trigger-options"
            name="accordion-options"
            checkbox
            isControlled
            checked={openAccordion}
            onChange={() => setOpenAccordion(!openAccordion)}
          >
            <FormattedMessage {...messages.reportViewOptions} />
          </AccordionButtonHooks>
          {openAccordion && (
            <AccordionContent extraClassNames="u-margin-top-small">
              {simpleOptions.map(opt => {
                const option = reportOptions[opt];

                return (
                  option.enabled.includes(session.type) &&
                  (option.type !== 'switch' ? (
                    <Fragment key={uid(option)}>
                      {option.title && (
                        <CardHeaderTitle
                          small
                          extraClassNames="u-margin-top-medium"
                        >
                          <FormattedMessage {...messages[option.title]} />
                        </CardHeaderTitle>
                      )}

                      <div
                        className={classNames(
                          'c-input-checkbox--with-paragraph',
                          {
                            'c-input-switch': option.type === 'switch'
                          }
                        )}
                      >
                        <Checkbox
                          id={uid(option)}
                          name={`option-${option.label}`}
                          secondary
                          // extraClassNames="u-margin-none"
                          checked={options[option.id]}
                          onChange={e =>
                            onsetOption({ [option.id]: e.target.checked })
                          }
                        >
                          <FormattedMessage {...messages[option.label]} />
                        </Checkbox>
                        <div className="c-input__description">
                          <FormattedMessage
                            {...messages[option.description]}
                            values={{
                              button: chunks => (
                                <TooltipPopper
                                  info
                                  // extraClassNames={extraClassNames}
                                  id={`popper_tooltip_${uid(option)}`}
                                  icon={'info'}
                                  // alertFill={person.status === PersonStatus.UNCONFIRMED}
                                  onMouseEnter={() => true}
                                  onFocus={() => true}
                                  onMouseLeave={() => false}
                                  onBlur={() => false}
                                  onClick={e => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                  }}
                                >
                                  {chunks}
                                </TooltipPopper>
                              )
                            }}
                          />
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment key={uid(option)}>
                      <Accordion switch>
                        {() => (
                          <>
                            <AccordionButtonHooks
                              id="trigger-options-advanced"
                              name="accordion-options-advanced"
                              checkbox
                              isControlled
                              checked={openSecondaryAccordion}
                              onChange={() =>
                                setOpenSecondaryAccordion(
                                  !openSecondaryAccordion
                                )
                              }
                            >
                              <FormattedMessage {...messages[option.label]} />
                            </AccordionButtonHooks>

                            <AccordionContent>
                              {openSecondaryAccordion &&
                                advancedOptions.map(opt => {
                                  const option = reportOptions[opt];

                                  return (
                                    option.enabled.includes(session.type) && (
                                      <Fragment key={uid(option)}>
                                        {option.title && (
                                          <CardHeaderTitle
                                            small
                                            extraClassNames="u-margin-top-medium"
                                          >
                                            <FormattedMessage
                                              {...messages[option.title]}
                                            />
                                          </CardHeaderTitle>
                                        )}
                                        <div
                                          className={classNames(
                                            'c-input-checkbox--with-paragraph',
                                            {
                                              'c-input-switch':
                                                option.type === 'switch'
                                            }
                                          )}
                                          key={uid(option)}
                                        >
                                          <Checkbox
                                            id={uid(option)}
                                            name={`option-${option.label}`}
                                            secondary
                                            checked={options[option.id]}
                                            onChange={e =>
                                              onsetOption({
                                                [option.id]: e.target.checked
                                              })
                                            }
                                          >
                                            <FormattedMessage
                                              {...messages[option.label]}
                                            />
                                          </Checkbox>
                                          <div className="c-input__description">
                                            <FormattedMessage
                                              {...messages[option.description]}
                                              values={{
                                                button: chunks => (
                                                  <TooltipPopper
                                                    info
                                                    // extraClassNames={extraClassNames}
                                                    id={`popper_tooltip_${uid(
                                                      option
                                                    )}`}
                                                    icon={'info'}
                                                    // alertFill={person.status === PersonStatus.UNCONFIRMED}
                                                    onMouseEnter={() => true}
                                                    onFocus={() => true}
                                                    onMouseLeave={() => false}
                                                    onBlur={() => false}
                                                    onClick={e => {
                                                      e.stopPropagation();
                                                      e.preventDefault();
                                                    }}
                                                  >
                                                    {chunks}
                                                  </TooltipPopper>
                                                )
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </Fragment>
                                    )
                                  );
                                })}
                            </AccordionContent>
                          </>
                        )}
                      </Accordion>
                    </Fragment>
                  ))
                );
              })}
            </AccordionContent>
          )}
        </>
      )}
    </Accordion>
  );
};

ReportOptions.propTypes = {};

export default ReportOptions;
