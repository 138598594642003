import React from 'react';
import './Breadcrumbs.scss';
import { Link } from 'react-router-dom';

const Breadcrumb = props => {
  return (
    <li className="c-breadcrumbs__item">
      {props.noLink && (
        <span className="c-breadcrumbs__link">{props.children}</span>
      )}

      {props.to && (
        <Link to={props.to} className="c-breadcrumbs__link">
          {props.children}
        </Link>
      )}

      {!props.to && !props.noLink && (
        <a
          onClick={e => {
            e.preventDefault();
            if (props.onClick) props.onClick();
          }}
          href="#!"
          className="c-breadcrumbs__link"
        >
          {props.children}
        </a>
      )}
    </li>
  );
};

export default Breadcrumb;
