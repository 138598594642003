import { Button } from 'components/button';
import { CardFooter } from 'components/card';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';

function FormEditFooterButtons({
  extraClassNames,
  edit,
  setEdit,
  disabledSubmit = false,
  onCancel,
  onSubmit,
  type = 'button',
  saveLabel = 'saveButton',
  cancelLabel = 'cancelButton',
  hasCancel = true,
  topMargin = null,
  paddingBottomMedium = null
}) {
  return (
    <>
      {edit && (
        <CardFooter
          secondary
          extraClassNames={[
            !topMargin && 'u-margin-top-none',
            paddingBottomMedium && 'u-padding-bottom-medium',
            extraClassNames
          ]}
        >
          {hasCancel && (
            <Button
              secondary
              onClick={() => {
                if (onCancel) onCancel();
                if (setEdit) setEdit(false);
              }}
            >
              <FormattedMessage {...messages[`${cancelLabel}`]} />
            </Button>
          )}
          <Button
            primary
            type={type}
            onClick={() => {
              if (onSubmit) return onSubmit();
            }}
            disabled={disabledSubmit}
          >
            <FormattedMessage {...messages[`${saveLabel}`]} />
          </Button>
        </CardFooter>
      )}
    </>
  );
}

export default FormEditFooterButtons;
