import SporterMedicalInjuries from 'containers/pages/rehab/SporterMedicalInjuries';
import SporterMedicalHistory from 'containers/pages/rehab/SporterMedicalHistory';
import SporterMedicalIllnesses from 'containers/pages/rehab/SporterMedicalIllnesses';
import SporterMedicalIssues from 'containers/pages/rehab/SporterMedicalIssues';
import { usePersonContext } from 'contexts/PersonContext';
import { useMedicalContext } from 'contexts/MedicalContext';
import Loader from 'components/loader/Loader';

function SporterMedicalCard() {
  const { loading } = usePersonContext();
  const { loading: medicalLoading } = useMedicalContext();

  if (loading || medicalLoading) return <Loader />;

  return (
    <>
      <SporterMedicalHistory />
      <SporterMedicalInjuries />
      <SporterMedicalIllnesses />
      <SporterMedicalIssues />
    </>
  );
}

export default SporterMedicalCard;
