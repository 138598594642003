import { useContext, useState } from 'react';
import { ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE } from 'routes/RouteList';
import { FormattedMessage, useIntl } from 'react-intl';
import { StoreContext } from 'index';
import AddProgramModal from 'containers/pages/exercises/programs/AddProgramModal';
import Program from 'models/Program';
import { usePersonContext } from 'contexts/PersonContext';
import { useSessionContext } from 'contexts/SessionContext';
import messages from 'messages';
import ReportRemarkInput from 'containers/partials/forms/ReportRemarkInput';
import ReportOptions from 'containers/partials/forms/ReportOptions';
import { uid } from 'react-uid';
import GrowthPredictionCard from 'containers/partials/cards/ReportCards/GrowthPredictionCard';
import PreventionCategoryCard from 'containers/partials/cards/ReportCards/PreventionCategoryCard';
import Loader from 'components/loader/Loader';
import SportsReportCard from 'containers/partials/cards/ReportCards/SportsReportCard';
import { useTestDataContext } from 'contexts/TestDataContext';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';

const ReportCard = ({ compressed, personInjury }) => {
  const intl = useIntl();
  const {
    authStore: { user, entityId }
  } = useContext(StoreContext);
  const { person } = usePersonContext();
  const { session, options, loading } = useSessionContext();
  const { testDataId, testData, groupedTests } = useTestDataContext();
  const [modalData, setModalData] = useState(null);

  if (loading || !testDataId) return <Loader />;

  if (groupedTests.length === 0) {
    return (
      <Card secondary>
        <CardBody extraClassNames="u-margin-bottom-none u-padding-vertical-huge">
          <Message emptyState icon="test">
            <MessageText>
              <FormattedMessage {...messages.emptySessionForAthlete} />
            </MessageText>
          </Message>
        </CardBody>
      </Card>
    );
  }

  return (
    <div
      className="u-margin-top-medium u-padding-bottom-medium"
      key={uid(testDataId)}
    >
      {user.isHylyghtOrg && !user.isAthlete() && (
        <ReportOptions
          key={uid('reportCardOptions')}
          setModalData={setModalData}
          showCreateProgramButton={session.isPrevention || session.isRehab}
        />
      )}
      {!user.isAthlete() && session.isTestSetV2 && (
        <ReportRemarkInput entityId={entityId} />
      )}
      {Object.keys(groupedTests).map((category, i) => {
        const tests = groupedTests[category];
        const hasTestItems = tests.some(t => t.testItems.length > 0);
        if (!hasTestItems) return <></>;
        if (session.isPrevention) {
          return (
            <PreventionCategoryCard
              key={uid(category, i)}
              category={category}
              tests={tests}
              compressedTable={compressed}
              isAthlete={user.isAthlete()}
              sides={personInjury ? personInjury.sides : []}
              options={options}
            />
          );
        } else {
          switch (category.toLowerCase()) {
            case 'b_growthprediction':
              return (
                <GrowthPredictionCard
                  key={uid(category, i)}
                  category={'growthprediction'}
                  tests={tests}
                />
              );
            default:
              return (
                <PreventionCategoryCard
                  key={uid(category, i)}
                  category={category}
                  tests={tests}
                  compressedTable={compressed}
                  isAthlete={user.isAthlete()}
                  sides={personInjury ? personInjury.sides : []}
                  options={options}
                />
              );
          }
        }
      })}
      {testData?.sports?.length > 0 && (
        <SportsReportCard sports={testData.sports} />
      )}
      {!user.isAthlete() && modalData && (
        <AddProgramModal
          entityId={entityId}
          destiny={{
            path: ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE,
            variables: {
              entityId,
              sessionId: session.id,
              sporterId: person.id
            }
          }}
          program={
            new Program({
              entityId,
              title: intl.formatMessage(messages.titleNewProgram, {
                person: `${person.firstname} ${person.lastname}`
              })
            })
          }
          persons={[person]}
          onClose={() => setModalData(null)}
        />
      )}
    </div>
  );
};

export default ReportCard;
