import { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
import {
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER,
  ROUTE_SPORTERS_ENTITY_SPORTER,
  ROUTE_SPORTERS_ENTITY_SPORTER_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL,
  ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL_EDIT
} from 'routes/RouteList';
import Person from '../models/Person';
import { QUERY_GET_PERSON_BY_ID } from 'services/aws/user-query';

export const PersonContext = createContext({});

const PersonContextProvider = ({ personId, children }) => {
  const { params: { entityId, sporterId } = {} } =
    useRouteMatch([
      ROUTE_SPORTERS_ENTITY_SPORTER,
      ROUTE_SPORTERS_ENTITY_SPORTER_EDIT,
      ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL,
      ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL_EDIT,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER
    ]) ?? {};

  const [person, setPerson] = useState(new Person({}));
  const {
    loading,
    error,
    data: personData
  } = useQuery(QUERY_GET_PERSON_BY_ID, {
    variables: { id: sporterId || personId },
    skip: (!sporterId && !personId) || sporterId === 'add'
  });

  useEffect(() => {
    if (personData?.getPerson) {
      setPerson(new Person(personData.getPerson));
    } else {
      setPerson(new Person({}));
    }
  }, [personData, sporterId]);

  return (
    <PersonContext.Provider value={{ entityId, person, loading, error }}>
      {children}
    </PersonContext.Provider>
  );
};

function usePersonContext() {
  const context = useContext(PersonContext);
  if (context === undefined) {
    throw new Error(
      'The PersonContext hook must be used within a PersonContext.Provider'
    );
  }
  return context;
}

export { PersonContextProvider, usePersonContext };
