import React from 'react';
import MTSCard from './MTSCard';
import { PackageTypes, PackageSubTypes } from 'constants.js';
import RetryPanel from '../error-boundary/RetryPanel';
import Loader from 'components/loader/Loader';
import { usePersonContext } from 'contexts/PersonContext';
import { useSessionContext } from 'contexts/SessionContext';
import IAmReportCard from 'containers/partials/cards/ReportCards/IAmReportCard';
import SapReportCard from 'containers/partials/cards/ReportCards/SapReportCard';
import ILikeReportCard from 'containers/partials/cards/ReportCards/ILikeReportCard';
import { TestDataProvider } from 'contexts/TestDataContext';

const LegacyReportCard = ({ type, subtype, reportBenchmarkTab, ...props }) => {
  switch (type) {
    case PackageTypes.ILIKE:
      return <ILikeReportCard {...props} />;
    case PackageTypes.MTS:
      return <MTSCard {...props} />;
    case PackageTypes.SAP:
      switch (subtype) {
        case PackageSubTypes.IAM:
        case PackageSubTypes.DJAS:
          return <IAmReportCard {...props} />;
        default:
          return <SapReportCard {...props} />;
      }
    default:
      return <ILikeReportCard {...props} />;
  }
};

const SporterReportCard = ({ entityId, rootEntityId, reportBenchmarkTab }) => {
  const {
    person,
    loading: personLoading,
    error: personError
  } = usePersonContext();
  const {
    session,
    tests,
    loading: sessionLoading,
    error: sessionError,
    options,
    testData
  } = useSessionContext();

  if (sessionLoading || personLoading) return <Loader />;
  if (sessionError || personError) return <RetryPanel />;

  return (
    <>
      {session.testData && (
        <TestDataProvider
          entityId={entityId}
          session={session}
          sporterId={person.id}
        >
          <LegacyReportCard
            testDataId={testData?.id}
            entityId={entityId}
            rootEntityId={rootEntityId}
            type={session.config.type}
            subtype={session.config.subtype}
            reportBenchmarkTab={reportBenchmarkTab}
            // result={dataResult}
            testData={session.testData}
            session={session}
            tests={tests}
            sporter={person}
            getDataEntityId={entityId}
            startdate={session.startdate}
            options={options}
            compressed
          />
        </TestDataProvider>
      )}
    </>
  );
};

export default SporterReportCard;
