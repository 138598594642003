import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Modal.scss';
import ReactModal from 'react-modal';
import Button from '../../components/button/Button';
import Icon from '../icon/Icon';

ReactModal.setAppElement('#root');

class Modal extends React.Component {
  state = {
    showModal: false
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
    setTimeout(() => {
      if (this.props.onClose) this.props.onClose();
    }, 600);
  };

  componentDidMount() {
    this.setState({ showModal: this.props.isOpen });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.isOpen !== this.state.showModal) {
      this.setState({ showModal: newProps.isOpen });
    }
  }

  render() {
    const {
      children,
      card,
      cardSmall,
      cardPanel,
      filter,
      extraClassNames,
      autoHeight,
      largeBody,
      alert,
      noCloseButton,
      benchmarksTableAlert,
      importSporters,
      bodyOverflowVisible,
      bodyOverflowScroll
    } = this.props;
    const { showModal } = this.state;
    return (
      <ReactModal
        closeTimeoutMS={600}
        isOpen={showModal}
        onRequestClose={this.handleCloseModal}
        className={classNames('c-modal__content', extraClassNames, {
          'c-card c-card--shadow': card,
          'c-card c-card--modal-small': cardSmall,
          'c-card c-card--panel': cardPanel,
          'c-card c-card--panel c-card--filter': filter,
          'c-card-modal__content-alert': alert,
          'c-card-modal__content-auto-height': autoHeight,
          'c-card-modal__content-body-max-height': largeBody,
          'c-card c-card-modal__import-sporters': importSporters,
          'c-card-modal__content-alert c-card-modal__content-benchmark-table-alert':
            benchmarksTableAlert,
          'ReactModal__Overlay--before-close': !showModal,
          'c-card-modal__overflow-visible': bodyOverflowVisible,
          'c-card-modal__overflow-scroll': bodyOverflowScroll
        })}
        overlayClassName={classNames('c-modal__overlay', {
          'c-modal__overlay-benchmark-table-alert': benchmarksTableAlert
        })}
      >
        {!noCloseButton && (
          <Button
            tiny
            extraClassNames="c-button--absolute-top-right"
            onClick={e => this.handleCloseModal(e)}
          >
            <Icon id="close" strokeColor="color-primary-dark" />
          </Button>
        )}
        {children}
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  emptyState: PropTypes.bool
};

export default Modal;
