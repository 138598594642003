import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_SPORTERS_ENTITY } from 'routes/RouteList';
import { MUTATION_ARCHIVE_ENTITY } from 'services/aws/entity-query';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import { S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID } from 'services/aws/client-query';
import { StoreContext } from 'index';

const RemoveGroupButton = ({
  group,
  entityId,
  children,
  parentId,
  ...props
}) => {
  const {
    authStore,
    authStore: { user },
    uiState
  } = useContext(StoreContext);
  const { push } = useHistory();

  if (!user.canRemoveGroups(entityId)) return null;

  if (group.type !== 'group') return null;

  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_ARCHIVE_ENTITY}
      variables={{ id: group.id }}
      confirmTitle={messages.modalConfirmRemoveGroupTitle}
      confirmMessage={messages.modalConfirmRemoveGroupMessage}
      refetchQueries={[
        {
          query: S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID,
          variables: { id: parentId }
        }
      ]}
      update={(cache, { data: { archiveEntity } }) => {
        if (archiveEntity) {
          uiState.decreasePendingRequest();
          authStore.renewRoles();
          push(ROUTE_SPORTERS_ENTITY.replace(':entityId', parentId));
        }
      }}
    >
      <Icon id="delete" strokeColor="color-neutral-dark" />
      {children}
    </ConfirmMutationButton>
  );
};

export default RemoveGroupButton;
