import {
  ListItem,
  ListItemStatus,
  ListItemLabelWrapper,
  ListItemLabel
} from 'components/list/list-item';
import { getNamePrefix } from 'utils/name-prefix';

export const SporterListItem = ({
  sporter: { id, firstname, lastname },
  active,
  percentage,
  session,
  onClick,
  checkbox,
  checked,
  onChange,
  visible
}) => (
  <ListItem
    active={active}
    done={percentage === 100}
    inProgress={percentage !== 0 && percentage !== 100}
    onClick={onClick}
    checkbox={checkbox}
  >
    <ListItemStatus
      itemId={id}
      checked={checked}
      onChange={onChange}
      visible={visible}
    >
      {!session ? getNamePrefix(firstname, lastname) : `${percentage}%`}
    </ListItemStatus>
    <ListItemLabelWrapper>
      <ListItemLabel label>
        {firstname} {lastname}
      </ListItemLabel>
    </ListItemLabelWrapper>
  </ListItem>
);
