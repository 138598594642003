import { Fragment, useContext } from 'react';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import { Card, CardBody } from 'components/card';
import { Table, TableBody, TableRow } from 'components/table';
import TableCell from 'components/table/TableCell';
import { checkLateralityIsHidden } from 'utils/tests';
import classNames from 'classnames';
import TestItemResultRow from 'containers/partials/cards/ReportCards/TestItemResultRow';
import { StoreContext } from 'index';
import { usePersonContext } from 'contexts/PersonContext';
import SportsTableRow from 'containers/partials/cards/ReportCards/SportsTableRow';
import { FormattedMessage } from 'react-intl';
import messages from 'messages.js';

const PreventionCategoryCard = ({
  category,
  tests,
  compressedTable,
  isAthlete,
  sides,
  options
}) => {
  const {
    uiState: { locale },
    authStore: { user }
  } = useContext(StoreContext);
  const { person } = usePersonContext();

  const categoryHasNotValues = tests
    .map(test =>
      test.testItems.filter(
        ti =>
          !ti.hidden &&
          ti.hasValues &&
          !(!ti.hasBenchmarkedValues && !ti.showScore)
      )
    )
    .flat();

  return (
    <Card
      secondary
      extraClassNames={
        categoryHasNotValues.length === 0 &&
        options.hideEmpty &&
        !user.isAthlete() // Admin sees no empty categories when hide emnpty is checked
          ? 'u-hidden-visually'
          : ''
      }
    >
      <CardHeader secondary extraClassNames="u-margin-bottom-small">
        <CardHeaderText>
          <CardHeaderTitle>{category}</CardHeaderTitle>
        </CardHeaderText>
      </CardHeader>

      {categoryHasNotValues.length === 0 && user.isAthlete() ? ( // Athlete sees empty categories with empty message
        <CardBody secondary empty>
          <p>
            <FormattedMessage {...messages.emptyCategoryLabel} />
          </p>
        </CardBody>
      ) : (
        <CardBody secondary>
          <div
            style={{
              maxWidth: '100%',
              overflow: 'auto'
            }}
          >
            {tests.map(test => {
              return (
                <Fragment key={test.id}>
                  {test.testItems.map(testItem => {
                    if (test.id === 'd061f19d-0c48-4355-9720-8b4bad9c730a') {
                      return (
                        <SportsTableRow key={test.id} testItem={testItem} />
                      );
                    }
                    const noScoresNoBenchmark =
                      !testItem.hasBenchmarkedValues && !testItem.showScore;

                    const testItemsWithoutBenchmarksHidden =
                      options.showUnBenchmarked
                        ? false
                        : testItem.hasBenchmarked &&
                          !testItem.hasBenchmarkedValues;

                    const testItemHasNotValues =
                      !testItem.hasValues ||
                      noScoresNoBenchmark ||
                      testItemsWithoutBenchmarksHidden;

                    if (!testItem.hidden) {
                      if (!isAthlete || (isAthlete && testItem.hasValues)) {
                        return (
                          <Table
                            key={testItem.id}
                            tableReport
                            extraClassNames={classNames(
                              'c-table--results-left-right',
                              {
                                'u-margin-bottom-none': compressedTable,
                                'u-margin-bottom-small ': !compressedTable,
                                'u-hidden-visually':
                                  options.hideEmpty && testItemHasNotValues
                              }
                            )}
                            compressedTable={compressedTable}
                          >
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  evenHalf
                                  colSpan={2}
                                  empty={testItemHasNotValues}
                                >
                                  {testItem.copy?.[locale]?.title ??
                                    testItem.title}
                                </TableCell>
                              </TableRow>

                              {testItemHasNotValues && (
                                <TableRow>
                                  <TableCell
                                    evenHalf
                                    colSpan={2}
                                    empty
                                  ></TableCell>
                                </TableRow>
                              )}

                              <TestItemResultRow
                                testItem={testItem}
                                options={options}
                                sidesDisabled={checkLateralityIsHidden(
                                  testItem,
                                  sides,
                                  person
                                )}
                                noValues={
                                  noScoresNoBenchmark || testItemHasNotValues
                                }
                                colSpan={
                                  Object.keys(testItem.sides)[0] === '2'
                                    ? 2
                                    : null
                                }
                              />
                            </TableBody>
                          </Table>
                        );
                      }
                    }

                    return null;
                  })}
                </Fragment>
              );
            })}
          </div>
        </CardBody>
      )}
    </Card>
  );
};

export default PreventionCategoryCard;
