import React from 'react';
import Card from 'components/card/Card';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import CardBody from 'components/card/CardBody';
import ResultList from 'components/list/ResultList';
import CardFooter from 'components/card/CardFooter';

const SportsReportCard = ({ sports }) => {
  const intl = useIntl();

  return (
    <Card reports>
      <CardHeader secondary>
        <CardHeaderText>
          <CardHeaderTitle>
            <FormattedMessage {...messages.cardLabelTitleSports} />
          </CardHeaderTitle>
        </CardHeaderText>
      </CardHeader>

      <CardBody secondary>
        {sports.length > 0 ? (
          <ResultList
            items={sports}
            defaultVisibleItems={10}
            labelShowLess={intl.formatMessage(messages.cardButtonShowLess)}
            labelShowMore={intl.formatMessage(messages.cardButtonShowAll, {
              count: sports.length
            })}
          />
        ) : (
          <FormattedMessage {...messages.cardLabelNotAllTestsCompleted} />
        )}
      </CardBody>

      <CardFooter />
    </Card>
  );
};

export default SportsReportCard;
