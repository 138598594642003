import { useState } from 'react';
import classNames from 'classnames';
import Icon from 'components/icon/Icon';
import { usePopper } from 'react-popper';
import { Portal } from 'components/Portal/Portal';

const TooltipPopper = props => {
  const [isOpen, setIsOpen] = useState(false);

  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();

  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8]
        }
      },
      {
        name: 'flip',
        options: {
          flipVariations: false,
          padding: { bottom: 24, top: 24 },
          fallbackPlacements: ['top-end']
        }
      }
    ]
  });

  const handleOnEvent = (e, event) => {
    if (event) {
      event();

      if (e._reactName === 'onMouseEnter' || e._reactName === 'onFocus') {
        setIsOpen(true);
      }

      if (e._reactName === 'onMouseLeave' || e._reactName === 'onBlur') {
        setIsOpen(false);
      }
    }
  };

  const {
    id,
    children,
    extraClassNames,
    icon,
    info,
    mediumIcon,
    video,
    videoMessage,
    label,
    stylesLabel,
    onClick,
    iconStrokeColor,
    alert,
    iconFillColor,
    alertFill,
    tabIndex,
    onMouseLeave,
    onBlur,
    onMouseEnter,
    onFocus
  } = props;

  return (
    <div
      className={classNames('c-tooltip c-tooltip--popper', extraClassNames, {
        'c-tooltip--alert': alert,
        'c-tooltip--info': info,
        'c-tooltip--video': video,
        'c-tooltip--label': label?.length > 0
      })}
    >
      <button
        className={classNames('c-button c-button--tiny c-tooltip__trigger', {
          'c-button--disabled': props.disabled || props.pseudoDisabled // ok
        })}
        type={'button'}
        aria-labelledby={id?.length && !video ? id : `tooltip-label-${id}`}
        onClick={onClick}
        ref={setReferenceElement}
        onMouseEnter={e => {
          handleOnEvent(e, onMouseEnter);
        }}
        onFocus={e => {
          handleOnEvent(e, onFocus);
        }}
        onMouseLeave={e => {
          handleOnEvent(e, onMouseLeave);
        }}
        onBlur={e => {
          handleOnEvent(e, onBlur);
        }}
        tabIndex={tabIndex}
      >
        {icon && (
          <Icon
            id={icon}
            mediumIcon={mediumIcon}
            strokeColor={
              iconStrokeColor ? iconStrokeColor : alert ? 'color-error' : false
            }
            fillColor={
              iconFillColor ? iconFillColor : alertFill ? 'color-error' : false
            }
          />
        )}
        {label && (
          <span id={`tooltip-label-${id}`} style={stylesLabel}>
            {label}
          </span>
        )}
      </button>
      {isOpen && (
        <Portal>
          <>
            {video && (
              <div
                className={classNames(
                  'c-tooltip__message c-tooltip-popper__message-video',
                  {
                    'c-tooltip-popper__message--hidden': !isOpen
                  }
                )}
                role="tooltip"
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                {children}
                {videoMessage && (
                  <section className="c-tooltip__info">{videoMessage}</section>
                )}
              </div>
            )}
            {!video && (
              <p
                id={id}
                role="tooltip"
                className={classNames(
                  'c-tooltip__message c-tooltip-popper__message',
                  {
                    'c-tooltip-popper__message--hidden': !isOpen
                  }
                )}
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                <span>{children}</span>
              </p>
            )}
          </>
        </Portal>
      )}
    </div>
  );
};

export default TooltipPopper;
