import React from 'react';
import { inject, observer } from 'mobx-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, ButtonsGroup } from 'components/button';

import messages from 'messages';
import { Card, CardBody, CardFooter } from 'components/card';
import {
  isPasswordValid,
  VALID_PASSWORD,
  ERROR_PASSWORD_NOT_EQUAL,
  ERROR_PASSWORD_EMPTY,
  ERROR_PASSWORD_TOO_SHORT,
  ERROR_PASSWORD_NO_ALPHABETICAL_CHAR,
  ERROR_PASSWORD_NO_NUMERICAL_CHAR
} from 'utils/password';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import InputErrorMessage from 'components/input/InputErrorMessage';
import FieldInput from 'components/input/FieldInput';

class Signup extends React.Component {
  state = {
    password1: '',
    password2: '',
    error1: ERROR_PASSWORD_EMPTY,
    error2: ERROR_PASSWORD_EMPTY,
    serverMessage: null
  };

  async handleSubmit(e) {
    e.preventDefault();
    const { password1, password2 } = this.state;
    if (
      isPasswordValid(password1) === VALID_PASSWORD &&
      password1 === password2
    ) {
      const { authStore } = this.props;
      const user = await authStore.doSignup(password1);
      if (!user || user.error) {
        this.setState({ serverMessage: messages.errorSomethingWentWrong });
      }
    } else {
      this.setState({
        error1:
          password1 !== password2
            ? ERROR_PASSWORD_NOT_EQUAL
            : isPasswordValid(password1),
        error2:
          password1 !== password2
            ? ERROR_PASSWORD_NOT_EQUAL
            : isPasswordValid(password2),
        serverMessage: null
      });
    }
  }

  getMessageForError(error) {
    switch (error) {
      case VALID_PASSWORD:
        return messages.validPassword;
      case ERROR_PASSWORD_EMPTY:
        return messages.errorPasswordEmpty;
      case ERROR_PASSWORD_TOO_SHORT:
        return messages.errorPasswordTooShort;
      case ERROR_PASSWORD_NOT_EQUAL:
        return messages.errorPasswordNotEqual;
      case ERROR_PASSWORD_NO_ALPHABETICAL_CHAR:
        return messages.errorPasswordNoAlphabeticalChar;
      case ERROR_PASSWORD_NO_NUMERICAL_CHAR:
        return messages.errorPasswordNoNumericalChar;
      default:
        return messages.errorSomethingWentWrong;
    }
  }

  render() {
    const {
      intl: { formatMessage },
      authStore: { tmpCognitoUser }
    } = this.props;

    const { password1, password2, error1, error2, serverMessage } = this.state;
    const { challengeParam: { userAttributes: { given_name } = {} } = {} } =
      tmpCognitoUser;
    const name = given_name || '';
    const title = formatMessage(messages.signupTitle, { name });
    const message = formatMessage(messages.signupMessage);

    const icon1 =
      error1 === VALID_PASSWORD
        ? 'validation-check'
        : ERROR_PASSWORD_EMPTY === error1
        ? ' '
        : 'validation-error';
    const icon2 =
      error2 === VALID_PASSWORD
        ? 'validation-check'
        : ERROR_PASSWORD_EMPTY === error2
        ? ' '
        : 'validation-error';

    return (
      <Card>
        <form>
          <CardHeader primary>
            <CardHeaderTitle>{title}</CardHeaderTitle>
            <CardHeaderSubtitle extraClassNames="u-margin-horizontal-auto">
              {message}
            </CardHeaderSubtitle>
          </CardHeader>
          <CardBody primary>
            <FieldInput
              id="password1-id"
              type="password"
              icon={icon1}
              onChange={e => {
                const validState =
                  isPasswordValid(password2) === VALID_PASSWORD &&
                  password2 === e.target.value;
                this.setState({
                  password1: e.target.value.trim(),
                  error1: validState ? VALID_PASSWORD : ERROR_PASSWORD_EMPTY
                });
              }}
              onBlur={() => {
                const validState =
                  isPasswordValid(password1) === VALID_PASSWORD &&
                  password2 === password1;
                if (!validState) {
                  this.setState({
                    error1:
                      isPasswordValid(password1) !== VALID_PASSWORD
                        ? isPasswordValid(password1)
                        : password2 && password1 && password2 !== password1
                        ? ERROR_PASSWORD_NOT_EQUAL
                        : VALID_PASSWORD
                  });
                } else {
                  this.setState({
                    error1: VALID_PASSWORD,
                    error2: VALID_PASSWORD
                  });
                }
              }}
            >
              <FormattedMessage {...messages.signupPasswordLabel} />
            </FieldInput>
            {error1 !== VALID_PASSWORD &&
              error1 !== ERROR_PASSWORD_EMPTY &&
              error1 !== ERROR_PASSWORD_NOT_EQUAL && (
                <InputErrorMessage>
                  <FormattedMessage {...this.getMessageForError(error1)} />
                </InputErrorMessage>
              )}
            <FieldInput
              id="password2-id"
              type="password"
              icon={icon2}
              onChange={e => {
                const validState =
                  isPasswordValid(password1) === VALID_PASSWORD &&
                  password1 === e.target.value;
                this.setState({
                  password2: e.target.value.trim(),
                  error2: validState ? VALID_PASSWORD : ERROR_PASSWORD_EMPTY
                });
              }}
              onBlur={() => {
                const validState =
                  isPasswordValid(password2) === VALID_PASSWORD &&
                  password2 === password1;
                if (!validState) {
                  this.setState({
                    error2:
                      isPasswordValid(password2) !== VALID_PASSWORD
                        ? isPasswordValid(password2)
                        : password2 && password1 && password2 !== password1
                        ? ERROR_PASSWORD_NOT_EQUAL
                        : VALID_PASSWORD
                  });
                } else {
                  this.setState({
                    error1: VALID_PASSWORD,
                    error2: VALID_PASSWORD
                  });
                }
              }}
            >
              <FormattedMessage {...messages.signupPasswordRepeatLabel} />
            </FieldInput>
            {error2 !== VALID_PASSWORD &&
              error2 !== ERROR_PASSWORD_EMPTY &&
              error2 !== ERROR_PASSWORD_NOT_EQUAL && (
                <InputErrorMessage>
                  <FormattedMessage {...this.getMessageForError(error2)} />
                </InputErrorMessage>
              )}
            {(error1 === ERROR_PASSWORD_NOT_EQUAL ||
              error2 === ERROR_PASSWORD_NOT_EQUAL) && (
              <FormattedMessage {...messages.errorPasswordNotEqual} />
            )}
            {serverMessage && <FormattedMessage {...serverMessage} />}
          </CardBody>
          <CardFooter primary>
            <ButtonsGroup>
              <Button
                primary
                type="submit"
                onClick={e => this.handleSubmit(e)}
                disabled={
                  isPasswordValid(password1) !== VALID_PASSWORD ||
                  isPasswordValid(password2) !== VALID_PASSWORD
                }
              >
                <FormattedMessage {...messages.signupSubmitButton} />
              </Button>
            </ButtonsGroup>
          </CardFooter>
        </form>
      </Card>
    );
  }
}

export default injectIntl(inject('authStore')(observer(Signup)));
