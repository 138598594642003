import React, { useContext } from 'react';
import { StoreContext } from 'index';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper,
  ListItemStatus
} from 'components/list/list-item';
import Icon from 'components/icon/Icon';

const LessonsListItem = ({
  id,
  checkbox,
  checked,
  onClick,
  onChange,
  active,
  videosCount,
  visible,
  iconLess,
  statusIcon,
  mediumIcon,
  lesson: { title }
}) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);

  return (
    <ListItem checkbox={checkbox} active={active} onClick={onClick}>
      {!iconLess && (
        <ListItemStatus
          itemId={id}
          checkbox={checkbox}
          visible={visible}
          checked={checked}
          onChange={onChange}
          statusIcon={statusIcon ? statusIcon : 'group'}
          mediumIcon={mediumIcon}
        />
      )}
      <ListItemLabelWrapper>
        <ListItemLabel label="true">{title}</ListItemLabel>

        <ListItemLabel info>
          <span className="u-margin-right-small">{videosCount}</span>
          <Icon id="video" />
        </ListItemLabel>
      </ListItemLabelWrapper>
    </ListItem>
  );
};

export default LessonsListItem;
