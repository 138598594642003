import VideoTooltip from 'components/tooltip/VideoTooltip';
import { Button } from 'components/button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';

function PreviewButton({ media, showPreview }) {
  const intl = useIntl();

  if (!media) return <></>;

  if (media.length === 0) {
    return (
      <Button
        tiny
        onClick={e => {
          e.preventDefault();
          showPreview();
        }}
      >
        <FormattedMessage {...messages.buttonView} />
      </Button>
    );
  }
  if (media.length > 0) {
    return (
      <VideoTooltip
        media={media}
        onClick={showPreview}
        videoMessage={intl.formatMessage(messages.exercisesVideoTooltipMessage)}
      />
    );
  }
}

export default PreviewButton;
