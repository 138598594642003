import { ProgressBarRange, ProgressBarValueTypes } from 'constants.js';

export const getProgressBarValueType = zScore => {
  const count = ProgressBarRange.length;
  const part = 5 / count;
  const start = -2.5;
  let valueIndex = 0;
  Array(count)
    .fill()
    .some((v, i) => {
      valueIndex++;
      return zScore < start + part * (i + 1);
    });
  return Object.values(ProgressBarValueTypes)[valueIndex];
};

export const getProgressBarColorByPercentile = percent => {
  const rangeIndex = ProgressBarRange.findIndex(
    (range, index) =>
      percent >= range &&
      (ProgressBarRange[index + 1]
        ? percent < ProgressBarRange[index + 1]
        : 100)
  );
  return Object.values(ProgressBarValueTypes)[rangeIndex];
};

export const getProgressBarColor = value => {
  const rangeIndex = ProgressBarRange.findIndex(
    (range, index) =>
      value * 100 >= range &&
      (ProgressBarRange[index + 1]
        ? value * 100 < ProgressBarRange[index + 1]
        : 100)
  );
  return Object.values(ProgressBarValueTypes)[rangeIndex];
};
