import React from 'react';
import classNames from 'classnames';

class PageTitle extends React.Component {
  render() {
    const { location, logo, ...props } = this.props;
    return (
      <div className={classNames('c-page__title', {})}>
        {logo && (
          <div className={classNames('c-page__title-logo')}>
            <img src={logo} alt="" />
          </div>
        )}
        {props.children}{' '}
        {location && <span className="c-page__title-location">{location}</span>}
      </div>
    );
  }
}
export default PageTitle;
