import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card } from 'components/card';
import messages from 'messages';
import {
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderTitle
} from 'components/card/card-header';
import Icon from 'components/icon/Icon';

const NoAccess = () => {
  return (
    <Card extraClassNames={'c-card--primary'}>
      <CardHeader primary>
        <Icon id="blocked" xHugeIcon />
        <CardHeaderTitle>
          <FormattedMessage {...messages.errorNoAccessTitle} />
        </CardHeaderTitle>
        <CardHeaderSubtitle extraClassNames="u-margin-horizontal-auto">
          <FormattedMessage {...messages.errorNoAccessMessage} />
        </CardHeaderSubtitle>
      </CardHeader>
    </Card>
  );
};

export default NoAccess;
