import { createContext, useEffect, useState, useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
import {
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL,
  ROUTE_USER_SESSIONS_ENTITY_MEDICAL
} from 'routes/RouteList';
import {
  MUTATION_ADD_MEDICAL,
  QUERY_GET_PERSON_MEDICALS
} from 'services/aws/injury-query';
import { usePersonContext } from './PersonContext';
import Medical from 'models/Medical';
import { sort, SORT_DATA_TYPES } from 'utils/sort';

export const MedicalContext = createContext({});

const MedicalContextProvider = ({
  rootEntityId,
  personId,
  isUser = false,
  children
}) => {
  const { person } = usePersonContext();
  const { params: { entityId, sporterId } = {} } =
    useRouteMatch({
      path: [
        ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL,
        ROUTE_USER_SESSIONS_ENTITY_MEDICAL
      ],
      strict: false
    }) ?? {};

  const [addMedical] = useMutation(MUTATION_ADD_MEDICAL);

  const [medical, setMedical] = useState({});
  const [screenings, setScreenings] = useState([]);

  const {
    loading,
    error,
    data: medicalData
  } = useQuery(QUERY_GET_PERSON_MEDICALS, {
    variables: { entityId: rootEntityId, personId: sporterId ?? personId },
    skip: !rootEntityId || (!sporterId && !personId)
  });

  useEffect(() => {
    if (
      medicalData?.getMedicalsByPerson &&
      person &&
      rootEntityId &&
      entityId
    ) {
      const medicals = medicalData.getMedicalsByPerson;
      if (medicals[0]) {
        const medical = new Medical({ ...medicals[0] });
        const screenings = medical.injuries.reduce((screenings, injury) => {
          return [
            ...screenings,
            ...injury.screenings.reduce((sessions, screening) => {
              return [...sessions, screening];
            }, [])
          ];
        }, []);
        setScreenings(
          sort(screenings, {
            keys: [
              {
                key: 'startdate',
                desc: true,
                dataType: SORT_DATA_TYPES.DATE_STRING
              }
            ]
          })
        );
        setMedical(medical);
      } else {
        if (!isUser) {
          addMedical({
            variables: {
              entityId: rootEntityId,
              personId: person.id
            }
          }).then(res => {
            if (res.data.addMedical?.id) {
              setMedical(new Medical({ ...res.data.addMedical }));
            }
          });
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicalData, person, rootEntityId, entityId]);

  return (
    <MedicalContext.Provider value={{ medical, screenings, loading, error }}>
      {children}
    </MedicalContext.Provider>
  );
};

function useMedicalContext() {
  const context = useContext(MedicalContext);
  if (context === undefined) {
    throw new Error(
      'The MedicalContext hook must be used within a MedicalContext.Provider'
    );
  }
  return context;
}

export { MedicalContextProvider, useMedicalContext };
