import React, { useEffect, useReducer } from 'react';
import { round } from 'utils/math';

const EMPTY = '00';

export const getEmptyRow = dataKeys => {
  return dataKeys.reduce((ac, k) => ({ ...ac, [k]: EMPTY }), {
    empty: true
  });
};

function reducer(state, action) {
  const { value, rowData, key, row, col, keyEvent } = action.payload;

  switch (action.type) {
    case 'setDataTable':
      return { ...state, dataTable: action.payload.dataTable };

    case 'setActiveRow':
      return { ...state, activeRow: row };

    case 'setActiveCol':
      return { ...state, activeCol: col };

    case 'onFocus':
      if (row !== state.activeRow || col !== state.activeCol) {
        return { ...state, activeRow: row, activeCol: col };
      }
      return { ...state };

    case 'onClick':
      return { ...state, activeRow: row, activeCol: col };

    case 'onBlur':
      if (rowData.empty && value !== EMPTY) {
        Object.keys(rowData).forEach(k => (rowData[k] = Number(rowData[k])));
        rowData.empty = undefined;
        state.dataTable.push(getEmptyRow(state.dataKeys));
      }

      if (rowData.empty && value === EMPTY) {
        return { ...state };
      }

      rowData[key] = isNaN(round(Number(value.replace(',', '.'))))
        ? 0
        : round(Number(value.replace(',', '.')));

      return { ...state };

    case 'onChange':
      rowData[key] = value;
      return { ...state };

    case 'onKeyPress':
      let newActiveRow = state.activeRow;
      let newActiveCol = state.activeCol;

      switch (keyEvent.code) {
        case 'Tab':
          newActiveCol++;
          break;
        case 'Enter':
          if (keyEvent.target.nodeName === 'INPUT') {
            //keyEvent.target.blur();
            newActiveRow++;
          }
          break;
        case 'ArrowLeft':
          newActiveCol--;
          break;
        case 'ArrowRight':
          newActiveCol++;
          break;
        case 'ArrowUp':
          newActiveRow--;
          break;
        case 'ArrowDown':
          newActiveRow++;
          break;
        default:
          return { ...state };
      }

      if (newActiveCol + 1 > state.dataKeys.length) {
        newActiveCol = 0;
        newActiveRow++;
      }

      if (
        newActiveRow === state.dataTable.length ||
        newActiveCol <= -1 ||
        newActiveRow <= -1
      ) {
        newActiveCol = -1;
        newActiveRow = -1;
      }
      if (
        newActiveRow !== -1 &&
        keyEvent.code !== 'Tab' &&
        !!keyEvent.code.indexOf('Arrow')
      ) {
        const input = document.getElementById(
          `input-${newActiveRow}-${newActiveCol}`
        );
        input && input.focus();
      }
      return { ...state, activeRow: newActiveRow, activeCol: newActiveCol };

    case 'onDelete':
      const newDataTable = state.dataTable.filter(d => d !== rowData);
      return { ...state, dataTable: [...newDataTable] };

    default:
      return { ...state };
  }
}

export function useDataTable({ dataKeys, dataTable }) {
  const initialState = {
    dataKeys,
    activeRow: -1,
    activeCol: -1,
    dataTable: [...dataTable, getEmptyRow(dataKeys)]
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    window.addEventListener('keypress', onKeyPress);
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('keypress', onKeyPress);
      window.removeEventListener('keyup', onKeyUp);
    };
  }, []);

  const onKeyUp = e => {
    switch (e.code) {
      case 'Escape':
        e.target.blur();
        return;
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'ArrowUp':
      case 'ArrowDown':
        // TODO why not on arrow
        if (e.target.nodeName !== 'INPUT') {
          dispatch({ type: 'onKeyPress', payload: { keyEvent: e } });
        }
        break;
      default:
        return;
    }
  };

  const onKeyPress = e => {
    dispatch({ type: 'onKeyPress', payload: { keyEvent: e } });
  };

  return [state, dispatch];
}
