import { observable, action, makeObservable } from 'mobx';
import {
  PersonEntityRoles,
  defaultConfig,
  EntityTypes,
  gsvConfig,
  OrganisationTypes,
  hylyghtConfig
} from 'constants.js';
import { defaultMTSMetaConfig } from 'default-vars';
import { sortByArrayValue } from 'utils/sort';
import {
  ROUTE_HOME,
  ROUTE_LESSONS,
  ROUTE_SPORTERS,
  ROUTE_VIDEOS
} from 'routes/RouteList';

export default class Person {
  id = null;
  uid = '';
  hmac = '';
  _activeEntity = null;
  parentId = null;
  lastname = '';
  firstname = '';
  birthdate = '';
  gender = '';
  dominantHand = '';
  email = '';
  athleteEmail = '';
  rootEntityId = null;
  rootEntityRoles = [];
  entities = [];
  entityId = null;
  entityRoles = [];
  entityTree = null;
  baseLanguage = 'en';
  notes = '';
  meta;
  account_meta;
  weight = '';
  length = '';
  vegetarianDate = '';
  betaAlanineDate = '';
  creatine = false;
  creatineDate = '';
  taurine = false;
  taurineDate = '';

  personReady = false;
  rolesReady = false;

  status;

  constructor(
    {
      id,
      uid = '',
      hmac = '',
      parentId,
      lastname = '',
      firstname = '',
      gender = '',
      dominantHand = '',
      email = '',
      athleteEmail = '',
      entities = [],
      birthdate = '',
      notes = '',
      baseLanguage,
      meta,
      status,
      account_meta,
      weight,
      length,
      vegetarianDate,
      betaAlanineDate,
      creatine = false,
      creatineDate,
      taurine = false,
      taurineDate
    },
    entityId
  ) {
    makeObservable(this, {
      id: observable,
      parentId: observable,
      lastname: observable,
      firstname: observable,
      gender: observable,
      dominantHand: observable,
      rootEntityId: observable,
      rootEntityRoles: observable,
      entities: observable,
      entityId: observable,
      rolesReady: observable,
      personReady: observable,
      updateEntities: action
    });
    this.id = id;
    this.uid = uid || '';
    this.hmac = hmac || '';
    this.parentId = parentId;
    this.lastname = lastname || '';
    this.firstname = firstname || '';
    this.birthdate = birthdate ? new Date(birthdate) : '';
    this.gender = gender || '';
    this.dominantHand = dominantHand || '';
    try {
      this.meta = meta ? JSON.parse(meta) : {};
    } catch (e) {
      this.meta = meta;
    }
    try {
      this.account_meta = account_meta ? JSON.parse(account_meta) : {};
    } catch (e) {
      this.account_meta = account_meta;
    }
    this.status = status;
    this.email = email?.trim() || ''; // email is the login email
    this.athleteEmail = this.meta?.email
      ? (this.meta?.email).toString().trim()
      : ''; // only to contact the athlete, no login
    this.notes = notes || '';
    this.baseLanguage = baseLanguage;
    this.entityId = entityId;
    if (entities && entities.length > 0) {
      this.entities = entities;
      this.rootEntityRoles = this.activeEntity.roles;
      this.rootEntityId = this.activeEntity.entityId;
    }
    this.weight = weight;
    this.length = length;
    this.vegetarianDate = vegetarianDate;
    this.betaAlanineDate = betaAlanineDate;
    this.creatine = creatine;
    this.creatineDate = creatineDate;
    this.taurine = taurine;
    this.taurineDate = taurineDate;

    this.personConfig = defaultConfig;
  }

  hasInValidLogin() {
    return this.email && this.account_meta.status === 'FORCE_CHANGE_PASSWORD';
  }
  hasEmailNoLogin() {
    return this.athleteEmail && !this.email;
  }

  hasRoleByEntityId(entityId, role) {
    let entity =
      entityId === this.rootEntityId
        ? this.getPersonEntityByEntityId(entityId)
        : this.entityRoles.find(er => er.id === entityId);
    // let entity = this.getPersonEntityByEntityId(entityId);
    if (entity) {
      if (entity.roles && entity.roles.length > 0) {
        if (entity.roles.find(r => r === role)) {
          return true;
        }
      }
      return false;
    }

    if (entityId !== this.rootEntityId) {
      return this.hasRoleByEntityId(this.rootEntityId, role);
    }

    return false;
  }

  getRelatedEntityNameByEntityId(entityId) {
    const personEntity = this.getPersonEntityByEntityId(entityId);
    if (personEntity && personEntity.entity && personEntity.entity.name) {
      return personEntity.entity.name;
    }
    return '';
  }

  getPersonEntityByEntityId(entityId) {
    return this.entities.find(e => e.entityId === entityId);
  }

  get testSets() {
    return this.activeEntity.entity.testSets;
  }

  get activeEntity() {
    if (this.entityId) {
      const entity = this.entities.find(
        entity => entity.entity.id === this.entityId
      );
      if (entity) {
        return entity;
      }
    }

    const foundEntity = this.entities.find(
      pe => pe.entity && pe.entity.type === EntityTypes.ORGANISATION
    );

    if (foundEntity) return foundEntity;
    return this.entities[0];
    // return this._activeEntity;
  }

  getPersonEntityIdByEntityId(entityId = this.rootEntityId) {
    const personEntity = this.getPersonEntityByEntityId(entityId);
    if (personEntity) {
      return personEntity.id || null;
    }
    return null;
  }

  setPersonReady(value) {
    this.personReady = value;
  }

  setConfig() {
    if (this?.activeEntity?.entity?.config) {
      const config = JSON.parse(this.activeEntity.entity.config);

      let routes = config.menu || defaultConfig.menu;
      routes = sortByArrayValue(
        routes.filter(r => r !== 'packages'),
        defaultConfig.menu
      ).map(route => {
        switch (route) {
          // replace legacy sporters with athletes
          case 'sporters':
            return '/athletes';
          // replace legacy benchmarks with results
          case 'benchmarks':
            return '/results';
          default:
            return !route.startsWith('/') ? `/${route}` : route;
        }
      });

      if (config.homepageUrl) {
        routes.unshift('/');
      }

      switch (config.type) {
        case OrganisationTypes.HYLYGHT:
          routes = hylyghtConfig.menu;
          if (
            this.rootEntityRoles.find(
              role => role === PersonEntityRoles.ROLE_ATHLETE
            )
          ) {
            routes = ['/sessions'];
            if (process.env.REACT_APP_REHAB_ENABLED === 'true') {
              routes.push('/programs');
            }
            routes.push('/documents');
          }
          if (config.homepageUrl) {
            routes.unshift(ROUTE_HOME);
          }
          if (process.env.REACT_APP_RESULTS_ENABLED === 'true') {
            routes.push('/results');
          }
          if (config.menu.includes('test')) {
            routes.push('/test');
          }
          break;
        case OrganisationTypes.GSV:
          routes = gsvConfig.menu;
          routes.push('/sporters');
          routes = routes.map(r => (r === '/programs' ? '/trainings' : r));
          if (config.homepageUrl) {
            routes.unshift(ROUTE_HOME);
          }
          break;
        case OrganisationTypes.VITALE:
          routes = [ROUTE_HOME, ROUTE_LESSONS, ROUTE_VIDEOS, ROUTE_SPORTERS];

          break;
        default:
          if (
            this.rootEntityRoles.find(
              role => role === PersonEntityRoles.ROLE_ATHLETE
            )
          ) {
            routes = ['/sessions'];
            if (process.env.REACT_APP_REHAB_ENABLED === 'true') {
              routes.push('/programs');
            }

            if (config.homepageUrl) {
              routes.unshift(ROUTE_HOME);
            }

            if (process.env.REACT_APP_RESULTS_ENABLED === 'true') {
              routes.push('/results');
            }
          }
          break;
      }

      this.personConfig = {
        menu: routes,
        logo: config.logo ?? defaultConfig.logo,
        name: config.name ?? defaultConfig.name,
        meta: config.meta ?? defaultMTSMetaConfig,
        type: config.type,
        benchmarksEnabled: config.benchmarksEnabled || false,
        testSetsEnabled: config.testSetsEnabled || false,
        reportTabEnabled: config.reportTabEnabled || false,
        testSetsInheritance: config.testSetsInheritance || false,
        homepageUrl: config.homepageUrl || ''
      };
    }
  }

  get config() {
    return this.personConfig;
  }

  get routes() {
    return this.config.menu;
  }

  get logo() {
    return this.config.logo;
  }

  get name() {
    return this.config.name;
  }

  get fullName() {
    return `${this.firstname} ${this.lastname}`;
  }
  get namePrefix() {
    if (!this.firstname || !this.lastname) {
      return '';
    }

    const name = `${this.firstname.substr(0, 1)}${this.lastname.substr(0, 1)}`;
    return name.toUpperCase();
  }

  get homepageUrl() {
    return this.config.homepageUrl;
  }

  setRoles(roles) {
    this.rolesReady = true;
    this.entityRoles = roles.list;
    this.entityTree = roles.tree;
  }

  async updateEntities(entities) {
    this.entities = entities;
  }

  static hasSubEntityInTree = (entity, subEntityId) => {
    const exist =
      entity &&
      entity.subEntities &&
      entity.subEntities.find(se => {
        if (se.id === subEntityId) {
          return true;
        }
        return Person.hasSubEntityInTree(se, subEntityId) || false;
      });
    return !!exist;
  };

  static get modelName() {
    return 'Person';
  }
}
