import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { ROUTE_SETTINGS_TESTSETS } from 'routes/RouteList';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import { MUTATION_ARCHIVE_TESTSET } from 'services/aws/testsets-query';
import messages from 'messages';
import { StoreContext } from 'index';

const RemoveTestSetButton = ({
  testSetId,
  children,
  entityId,
  onComplete,
  ...props
}) => {
  const {
    authStore,
    routing: { push }
  } = useContext(StoreContext);
  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_ARCHIVE_TESTSET}
      variables={{ testSetId }}
      confirmTitle={messages.modalConfirmRemoveTestSetTitle}
      confirmMessage={messages.modalConfirmRemoveTestSetMessage}
      update={async (cache, { data: { archiveTestSetV2 } }) => {
        if (archiveTestSetV2) {
          if (onComplete) onComplete();
          await authStore.renewEntities();
          push(ROUTE_SETTINGS_TESTSETS);
        }
      }}
    >
      <Icon id="delete" strokeColor="color-neutral-dark" />
      {children}
    </ConfirmMutationButton>
  );
};

export default withRouter(RemoveTestSetButton);
