import { useFormikContext } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { FieldDatePicker } from 'components/input/FieldDatePicker';
import { FieldTextarea } from 'components/input/FieldTextarea';
import FieldInput from 'components/input/FieldInput';

function IssueDetailFields({ edit }) {
  const intl = useIntl();
  const { touched, errors } = useFormikContext();

  return (
    <>
      <FieldInput
        id="title"
        name="title"
        placeholder={intl.formatMessage(messages.rehabIssueTitlePlaceholder)}
        errors={errors}
        touched={touched}
        readOnly={!edit}
        disabled={!edit}
        required
      >
        <FormattedMessage {...messages.rehabIssueTitleLabel} />
      </FieldInput>
      <div className="o-layout">
        <div className="o-layout__item u-1-of-2">
          <FieldDatePicker
            id="injuryDate"
            name="injuryDate"
            icon="calendar"
            errors={errors}
            touched={touched}
            readOnly={!edit}
            disabled={!edit}
            required
          >
            <FormattedMessage {...messages.rehabIssueDateLabel} />
          </FieldDatePicker>
        </div>
        <div className="o-layout__item u-1-of-2">
          <FieldDatePicker
            id="injuryEndDate"
            name="injuryEndDate"
            icon="calendar"
            errors={errors}
            touched={touched}
            readOnly={!edit}
            disabled={!edit}
            required
          >
            <FormattedMessage {...messages.rehabIssueEndDateLabel} />
          </FieldDatePicker>
        </div>
      </div>
      <FieldTextarea
        id="description"
        name="description"
        rows="3"
        errors={errors}
        touched={touched}
        readOnly={!edit}
        resizeVertical={edit}
      >
        <FormattedMessage {...messages.rehabIssueDescriptionLabel} />
      </FieldTextarea>

      <FieldTextarea
        id="extraInfo"
        name="extraInfo"
        rows="3"
        errors={errors}
        touched={touched}
        readOnly={!edit}
        resizeVertical={edit}
      >
        <FormattedMessage {...messages.rehabIssueExtraInfoLabel} />
      </FieldTextarea>
    </>
  );
}

export default IssueDetailFields;
