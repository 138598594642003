import {
  cloneElement,
  createContext,
  useContext,
  useRef,
  useState
} from 'react';

export const MenuContext = createContext({});

const MenuContextProvider = ({ children }) => {
  const menuItems = useRef([]);
  const [menuItemsState, setMenuItemsState] = useState();

  const addMenuItems = menuItem => {
    if (!menuItem) return;
    if (Array.isArray(menuItem)) {
      const menuItemsToAdd = [];
      menuItem.forEach(mItem => {
        menuItemsToAdd.push(cloneElement(mItem));
      });
      if (menuItemsToAdd.length > 0) {
        menuItems.current = [...menuItemsToAdd];
      }
    } else {
      const newMenuItems = menuItems.current.filter(
        item => item.key !== menuItem.key
      );
      newMenuItems.push(cloneElement(menuItem));
      menuItems.current = newMenuItems;
    }

    setMenuItemsState([...menuItems.current]);
  };

  const removeMenuItems = key => {
    if (!key) return;
    if (Array.isArray(key)) {
      // remove all items with key in array
      menuItems.current = menuItems.current.filter(
        item => !key.includes(item.key)
      );
    } else {
      menuItems.current = menuItems.current.filter(item => item.key !== key);
    }

    setMenuItemsState([...menuItems.current]);
  };

  const removeAllMenuItems = () => {
    menuItems.current = [];

    setMenuItemsState([...menuItems.current]);
  };

  return (
    <MenuContext.Provider
      value={{
        menuItems: menuItemsState,
        addMenuItems,
        removeMenuItems,
        removeAllMenuItems
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

function useMenuContext() {
  const context = useContext(MenuContext);
  if (context === undefined) {
    throw new Error(
      'The MenuContext hook must be used within a MenuContext.Provider'
    );
  }
  return context;
}

export { MenuContextProvider, useMenuContext };
