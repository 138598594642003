export const getNamePrefix = (firstname, lastname) => {
  if (!firstname || !lastname) {
    return '';
  }

  const name = `${firstname.substr(0, 1)}${lastname.substr(0, 1)}`;
  return name.toUpperCase();
};

export const getSessionPrefix = name => {
  if (!name) {
    return '';
  }

  const prefix = `${name.substr(0, 1)}`;
  return prefix.toUpperCase();
};

export const getTestNamePrefix = name => {
  if (!name) {
    return '';
  }
  const splitted = name.split(' ');
  const prefix = splitted
    .map(s => s.substr(0, 1))
    .join('')
    .substr(0, 3);
  return prefix.toUpperCase();
};
