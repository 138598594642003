import { Card, CardBody } from 'components/card';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { Table, TableBody, TableRow } from 'components/table';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import { uid } from 'react-uid';
import TableCell from 'components/table/TableCell';
import Graphic from 'components/graphics/Graphic';
import ProgressBarGroup from 'components/progress-bar/ProgressBarGroup';
import ProgressBarLabelBlock from 'components/progress-bar/ProgressBarLabelBlock';

const GrowthPredictionCard = ({ category, tests }) => {
  const maturityEst = [
    {
      name: '(K-R)',
      testItems: []
    },
    {
      name: '(M)',
      testItems: []
    }
  ];

  tests.map((test, i) => {
    // Biological Age (M) - set on first place
    const bioAgeM = test.testItems.find(
      testItem => testItem.id === 'c4141f44-42ad-4620-bbd4-7d4f257f8d1b'
    );
    if (bioAgeM) bioAgeM.order = 0;

    return sort(test.testItems, {
      keys: [
        {
          key: 'order',
          dataType: SORT_DATA_TYPES.NUMBER
        }
      ]
    }).map(testItem => {
      //   if (testItem.hidden || !testItem.result) return null;

      // Maturity Estimations (K-R)
      // Bio Age (K-R)
      if (testItem.id === '5737716d-7eb3-4724-85d2-7baa4652XXXX')
        maturityEst[0].testItems.push(testItem);

      // Adult Height Prediction (K-R)
      if (testItem.id === '5737716d-7eb3-4724-85d2-7baa4652ae93')
        maturityEst[0].testItems.push(testItem);

      // % Adult Height Prediction
      if (testItem.id === 'face72bd-d260-44a3-b55c-3bae9e3d18dc')
        maturityEst[0].testItems.push(testItem);

      // Development (K-R)
      if (testItem.id === '0c58cad6-8a6f-402f-b30e-b00e06871f14')
        maturityEst[0].testItems.push(testItem);

      // Development (K-R) Z-score
      if (testItem.id === '105b2d29-1469-4d27-bdc0-9d9b6c44b915')
        maturityEst[0].testItems.push(testItem);

      // Maturity Estimations (M)
      // Biological Age (M)
      if (testItem.id === 'c4141f44-42ad-4620-bbd4-7d4f257f8d1b')
        maturityEst[1].testItems.push(testItem);

      // APHV
      if (testItem.id === '496d355a-4667-4253-8701-6a26bc7a3f59')
        maturityEst[1].testItems.push(testItem);

      // Years from APHV (Maturity Offset)
      if (testItem.id === '7497420b-4a4f-4958-971e-f68aa41cbbe9')
        maturityEst[1].testItems.push(testItem);

      // Adult Height Prediction (M) v2
      if (testItem.id === 'e4e9be42-7890-4188-8876-9f0c638fdcac')
        maturityEst[1].testItems.push(testItem);

      // Development (M)
      if (testItem.id === '9a7ec084-cc6b-45fd-9140-8a30169c1de1')
        maturityEst[1].testItems.push(testItem);
    });
  });

  return maturityEst.map((group, index) => {
    return (
      <Card secondary key={index}>
        <CardHeader secondary extraClassNames="u-margin-bottom-small">
          <CardHeaderText>
            <CardHeaderTitle>
              <FormattedMessage
                {...messages.titleBenchmarkMaturityEstimations}
              />{' '}
              - {group.name}
            </CardHeaderTitle>
          </CardHeaderText>
        </CardHeader>
        <CardBody secondary>
          <div
            style={{
              maxWidth: '100%',
              overflow: 'auto'
            }}
          >
            {group.testItems.map((testItem, i) => {
              return (
                <Table
                  tableReport
                  compressedTable
                  extraClassNames={
                    'c-table--results-left-right u-margin-bottom-none '
                  }
                  key={uid(testItem, i)}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell evenHalf colSpan={2} empty={!testItem.result}>
                        {testItem.title}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        evenHalf
                        colSpan={2}
                        empty={!testItem.result}
                        style={{
                          flexDirection: [
                            'face72bd-d260-44a3-b55c-3bae9e3d18dc',
                            '7497420b-4a4f-4958-971e-f68aa41cbbe9'
                          ].some(el => el === testItem.id)
                            ? 'column'
                            : 'row'
                        }}
                      >
                        {testItem.result && (
                          <ProgressBarGroup fullWidth>
                            <ProgressBarLabelBlock
                              value={
                                testItem.id ===
                                '05c16b3e-0f75-4f17-a837-0d6163b0fd22' ? (
                                  testItem.result[2][0]
                                ) : (
                                  <FormattedMessage
                                    {...messages.labelUnit}
                                    values={{
                                      count: testItem.result[2][0],
                                      unit: testItem.unit
                                    }}
                                  />
                                )
                              }
                            ></ProgressBarLabelBlock>
                          </ProgressBarGroup>
                        )}

                        {testItem.id ===
                          '7497420b-4a4f-4958-971e-f68aa41cbbe9' &&
                          testItem.result && (
                            <Graphic
                              maturity
                              position={
                                testItem.result[2][0] > 3
                                  ? 3
                                  : testItem.result[2][0] < -3
                                  ? -3
                                  : testItem.result[2][0]
                              }
                            />
                          )}
                        {testItem.id ===
                          'face72bd-d260-44a3-b55c-3bae9e3d18dc' &&
                          testItem.result && (
                            <Graphic
                              pah
                              position={
                                testItem.result[2][0] > 100
                                  ? 100
                                  : testItem.result[2][0] < 80
                                  ? 80
                                  : testItem.result[2][0]
                              }
                            />
                          )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              );
            })}
          </div>
        </CardBody>
      </Card>
    );
  });
};

export default GrowthPredictionCard;
