import classNames from 'classnames';

const TableCell = props => {
  const {
    children,
    mainCell,
    onClick,
    extraClassNames,
    wide,
    evenHalf,
    graphicCell,
    empty,
    ...rest
  } = props;
  return (
    <td
      onClick={onClick}
      className={classNames('c-table-cell', extraClassNames, {
        'c-table-cell--main': mainCell,
        'c-table-cell--wide': wide,
        'c-table-cell--even-half': evenHalf,
        'c-table-cell--graphic': graphicCell,
        'c-table-cell--empty': empty
      })}
      {...rest}
    >
      {children}
    </td>
  );
};

export default TableCell;
