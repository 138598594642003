import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Video from 'models/Video';
import { QUERY_GET_VIDEO } from 'services/aws/videos-query';

const useVideo = (entityId, exerciseId) => {
  const [video, setVideo] = useState(new Video({}));
  const { loading, error, data, refetch } = useQuery(QUERY_GET_VIDEO, {
    variables: { id: exerciseId, entityId },
    skip: !exerciseId
  });

  useEffect(() => {
    if (data?.getExercise) {
      const videoData = data.getExercise;
      setVideo(
        new Video({
          ...videoData,
          thumbFileId: videoData?.thumb?.id,
          videoFileId: videoData?.video?.id,
          entityId
        })
      );
    } else {
      setVideo(new Video({}));
    }
  }, [data, exerciseId, entityId]);

  return { video, loading, error, refetch };
};

export default useVideo;
