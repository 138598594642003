import React, { useContext, useState } from 'react';
import TestItemPreviewModal from 'containers/partials/modals/TestItemPreviewModal';
import ExercisePreviewModal from 'containers/partials/modals/ExercisePreviewModal';

export const PreviewModalContext = React.createContext({});

const PreviewModal = ({ type, ...props }) => {
  switch (type) {
    case 'testItem':
      return <TestItemPreviewModal {...props} />;
    case 'exercise':
      return <ExercisePreviewModal {...props} />;
    default:
      throw new Error('No preview type provided');
  }
};

const PreviewModalProvider = ({ entityId, children }) => {
  const [modalData, setModalData] = useState(null);

  return (
    <PreviewModalContext.Provider value={{ setModalData }}>
      {modalData && (
        <PreviewModal
          entityId={entityId}
          type={modalData.type}
          data={modalData.data}
          onClose={() => setModalData(null)}
        />
      )}
      {children}
    </PreviewModalContext.Provider>
  );
};

function usePreviewModalContext() {
  const context = useContext(PreviewModalContext);
  if (context === undefined) {
    throw new Error(
      'The PreviewModalContext hook must be used within a PreviewModalContext.Provider'
    );
  }
  return context;
}

export { PreviewModalProvider, usePreviewModalContext };
