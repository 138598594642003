import { useContext, useEffect, useState } from 'react';
import Modal from 'components/modal/Modal';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import { CardBody, CardFooter } from 'components/card';
import { Button, ButtonsGroup } from 'components/button';
import FieldInput from 'components/input/FieldInput';
import Tags from 'containers/partials/inputs/Tags';
import messages from 'messages';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { StoreContext } from 'index';
import { ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EDIT } from 'routes/RouteList';
import { useNotificationQueue } from 'components/notification';
import Template from 'models/Template';
import TemplateForm from 'containers/pages/exercises/templates/TemplateForm';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper
} from 'components/list/list-item';
import { NoteTypes } from 'constants.js';
import { MUTATION_ADD_NOTE } from 'services/aws/notes-query';
import { generatePath } from 'react-router-dom';

const getNote = (exercise, notes) => {
  if (notes && notes.length > 0) {
    return notes[0].note;
  }
  if (exercise.note) {
    return exercise.note;
  }
  return '';
};

function AddTemplateModal({
  entityId,
  template,
  onClose,
  fromProgram,
  program
}) {
  const {
    routing: { push }
  } = useContext(StoreContext);
  const intl = useIntl();
  const [notes, setNotes] = useState(null);
  const [tags, setTags] = useState(template.tags);
  const notification = useNotificationQueue();
  const [addNote] = useMutation(MUTATION_ADD_NOTE);

  useEffect(() => {
    const exerciseNotes = [];
    template.exercises.forEach(exercise => {
      exerciseNotes.push({
        note: getNote(exercise, program.notes[exercise.id]),
        exerciseId: exercise.id
      });
    });
    setNotes(exerciseNotes);
  }, [program]);

  const onCompleteHandler = async templateId => {
    Promise.all([
      ...notes.map(note => {
        return addNote({
          variables: {
            linkId: templateId,
            linkId2: note.exerciseId,
            note: note.note,
            noteType: NoteTypes.TEMPLATE
          }
        });
      })
    ]).then(() => {
      notification.add(templateId, {
        message: intl.formatMessage(messages.templateSaved)
      });
      push(
        generatePath(ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EDIT, {
          entityId,
          templateId
        })
      );
      onClose && onClose();
    });
  };

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal card autoHeight isOpen={true} onClose={onCloseHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage
            {...messages.addTemplateTitle}
            values={{
              fromProgram
            }}
          />
        </CardHeaderTitle>
      </CardHeader>
      <TemplateForm
        entityId={entityId}
        template={template ?? new Template({})}
        onComplete={onCompleteHandler}
      >
        {({ isSubmitting, setFieldValue, errors, touched }) => (
          <>
            <CardBody modalWithOverflow extraClassNames="u-padding-bottom-huge">
              <FieldInput
                id="title"
                name="title"
                placeholder={intl.formatMessage(
                  messages.templateNamePlaceholder
                )}
                errors={errors}
                touched={touched}
                required
              >
                <FormattedMessage {...messages.templateNameLabel} />
              </FieldInput>
              <Tags
                entityId={entityId}
                label={intl.formatMessage(messages.exercisesLabelTags)}
                tags={tags}
                isMulti
                isClearable={false}
                creatable
                readOnly={false}
                onChange={tags => {
                  setTags(tags ?? []);
                  setFieldValue('tags', tags ?? []);
                }}
              />
            </CardBody>
            {fromProgram && (
              <CardBody
                modal
                extraClassNames="u-padding-bottom-none u-padding-horizontal-none"
              >
                <CardHeader secondary extraClassNames="u-margin-bottom-medium">
                  <CardHeaderText extraClassNames="u-margin-bottom-none u-padding-horizontal-small">
                    <CardHeaderTitle>
                      <FormattedMessage {...messages.exercisesInTemplate} />
                    </CardHeaderTitle>
                  </CardHeaderText>
                </CardHeader>

                <List
                  draggableDisabled
                  extraClassNames="u-margin-top-none u-padding-bottom-large u-padding-right-small"
                >
                  <ListBody extraClassNames="u-margin-top-none">
                    {template?.exercises &&
                      template.exercises.map(exercise => (
                        <ListItem key={exercise.id}>
                          <ListItemLabelWrapper>
                            <ListItemLabel label>
                              {exercise.title}
                            </ListItemLabel>
                            <div className="c-list__item-small-editing-area">
                              <p>
                                {getNote(exercise, program.notes[exercise.id])}
                              </p>
                            </div>
                          </ListItemLabelWrapper>
                        </ListItem>
                      ))}
                  </ListBody>
                </List>
              </CardBody>
            )}
            <CardFooter modal extraClassNames="c-card__footer--modal-larger">
              <ButtonsGroup>
                <Button secondary onClick={() => onCloseHandler()}>
                  <FormattedMessage {...messages.buttonCancel} />
                </Button>
                <Button type="submit" primary disabled={isSubmitting}>
                  <FormattedMessage {...messages.buttonSave} />
                </Button>
              </ButtonsGroup>
            </CardFooter>
          </>
        )}
      </TemplateForm>
    </Modal>
  );
}

export default AddTemplateModal;
