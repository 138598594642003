import { useContext, useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Card, CardBody, CardFooter } from 'components/card';
import Panel from 'components/panel/Panel';
import PanelHeader from 'components/panel/PanelHeader';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelTools from 'components/panel/PanelTools';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ROUTE_EXERCISES_COLLECTIONS_ENTITY,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES_EXERCISE
} from 'routes/RouteList';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import messages from 'messages';
import PanelBody from 'components/panel/PanelBody';
import { StoreContext } from 'index';
import { Tab, TabList, TabPanel, Tabs } from 'components/tabs';
import TemplateForm from 'containers/pages/exercises/templates/TemplateForm';
import Template from 'models/Template';
import FieldInput from 'components/input/FieldInput';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import useTabIndex from 'hooks/utils/useTabIndex';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { Button, ButtonsGroup } from 'components/button';
import Icon from 'components/icon/Icon';
import AddExercisesModal from 'containers/pages/exercises/collections/AddExercisesModal';
import { useNotificationQueue } from 'components/notification';
import useTemplate from 'hooks/queries/useTemplate';
import Loader from 'components/loader/Loader';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import { PanelSubtitle, PanelTitleButton } from 'components/panel/panel-title';
import { MenuWrapper } from 'components/menu';
import useSelectable from 'hooks/utils/useSelectable';
import RemoveExerciseTemplateButton from 'containers/partials/buttons/RemoveExerciseTemplateButton';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import ExercisesListSortable from 'containers/partials/lists/ExercisesListSortable';
import RemoveExercisesFromTemplateButton from 'containers/partials/buttons/RemoveExercisesFromTemplateButton';
import SortableList from 'components/dndkit/SortableList';
import { usePreviewModalContext } from 'contexts/PreviewModalContext';
import { useMutation } from '@apollo/client';
import {
  MUTATION_EDIT_TEMPLATE,
  QUERY_GET_TEMPLATES
} from 'services/aws/templates-query';
import { useRouteMatch } from 'react-router-dom';
import { TemplateType } from 'constants.js';
import { cleanFalsy } from 'utils/object';
import AddTrainingModal from 'containers/pages/exercises/trainings/AddTrainingModal';
import Training from 'models/Training';
import { useExercisesContext } from 'contexts/ExercisesContext';
import TabPanelBody from 'components/tabs/TabPanelBody';

const Collection = ({ entityId, active, collectionId, exerciseId }) => {
  const {
    authStore: { user },
    routing: { push }
  } = useContext(StoreContext);
  const ability = useAbility(AbilityContext);
  const intl = useIntl();
  const notification = useNotificationQueue();
  const isExercisesPage = useRouteMatch(
    ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES
  );
  const { setModalData } = usePreviewModalContext();
  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES,
    ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION
  ]);
  const [addExercisesModal, setAddExercisesModal] = useState(null);
  const [addModalData, setAddModalData] = useState(null);
  const [draggable, setDraggable] = useState(false);
  const {
    template: collection,
    exercises,
    setFilter,
    loading,
    error
  } = useTemplate(entityId, collectionId, user);
  const { exerciseActions } = useExercisesContext();
  const { selected, setSelected } = useSelectable([]);
  const [exercisesSortOrder, setExercisesSortOrder] = useState(null);

  const [editTemplate] = useMutation(MUTATION_EDIT_TEMPLATE);

  useEffect(() => {
    setSelected([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId]);

  useEffect(() => {
    if (collection) {
      exerciseActions.setSelectedExercises(exercises);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection]);

  const onCompleteHandler = collectionId => {
    notification.add(collectionId, {
      message: intl.formatMessage(messages.messageEditCollectionSuccess)
    });
    setTimeout(() => {
      push(
        generatePath(ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION, {
          entityId,
          collectionId
        })
      );
    }, 100);
  };

  const exercisesTab = () => {
    push(
      generatePath(ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES, {
        entityId,
        collectionId
      })
    );
  };

  const detailTab = () => {
    push(
      generatePath(ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION, {
        entityId,
        collectionId
      })
    );
  };

  const onSearch = e => {
    setFilter({ search: e.target.value });
  };

  const onAddedHandler = () => {
    setAddExercisesModal(false);
    setSelected([]);
  };

  const onCollectionDeleted = collectionId => {
    notification.add(`removed_${collectionId}`, {
      message: intl.formatMessage(messages.messageRemovedcollectionSuccess)
    });
    push(
      generatePath(ROUTE_EXERCISES_COLLECTIONS_ENTITY, {
        entityId
      })
    );
  };

  const submitExercisesSorting = async () => {
    const templateData = {
      id: collection.id,
      entityId: entityId,
      title: collection.title,
      exerciseIds: [...exercisesSortOrder],
      type: TemplateType.COLLECTION
    };

    await editTemplate({
      variables: { ...templateData },
      refetchQueries: [
        {
          query: QUERY_GET_TEMPLATES,
          variables: { entityId }
        }
      ]
    }).then(res => {
      setDraggable(false);
      notification.add(res.data.editExerciseProgramTemplate.id, {
        message: intl.formatMessage(messages.collectionSaved)
      });
    });
  };

  const createTrainingFromCollection = async collection => {
    const trainingObj = cleanFalsy({
      entityId,
      title: collection.title,
      exerciseIds: exercises.map(e => e.id),
      type: TemplateType.TRAINING
    });

    setAddModalData(trainingObj);
  };

  const onCancelHandler = () => {
    setDraggable(false);
  };

  if (loading)
    return (
      <Panel active={active}>
        <Loader />
      </Panel>
    );
  if (error) return <RetryPanel />;

  return (
    <Panel active={active}>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb
            to={generatePath(ROUTE_EXERCISES_COLLECTIONS_ENTITY, { entityId })}
          >
            <FormattedMessage {...messages.breadcrumbCollections} />
          </Breadcrumb>
          <Breadcrumb>{collection.title}</Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            {`${collection.title}`}
            <MenuWrapper trigger={<PanelTitleButton />}>
              {ability.can('update', collection) && (
                <RemoveExercisesFromTemplateButton
                  disabledMessage={
                    draggable
                      ? intl.formatMessage(
                          messages.collectionRemoveExerciseDisabledDragging
                        )
                      : intl.formatMessage(
                          messages.collectionRemoveExerciseDisabledNotSelected
                        )
                  }
                  entityId={entityId}
                  type="collection"
                  exerciseIds={selected}
                  templateId={collectionId}
                  updated={() => setSelected([])}
                  disabled={!selected || selected.length === 0}
                />
              )}
              {ability.can('delete', collection) && (
                <RemoveExerciseTemplateButton
                  entityId={entityId}
                  templateId={collectionId}
                  collectionType={TemplateType.COLLECTION}
                  menu
                  onDeleted={collectionId => onCollectionDeleted(collectionId)}
                >
                  <FormattedMessage {...messages.removeCollectionButton} />
                </RemoveExerciseTemplateButton>
              )}
              {ability.can('update', collection) && (
                <Button
                  menu
                  onClick={() => setDraggable(true)}
                  disabled={(selected && selected.length > 0) || draggable}
                >
                  {draggable ? (
                    intl.formatMessage(
                      messages.collectionSortExercisesDisabledDragging
                    )
                  ) : (
                    <FormattedMessage {...messages.sortCollectionButton} />
                  )}
                  <Icon id="arrange" strokeColor="color-neutral-dark" />
                </Button>
              )}
              <Button
                menu
                onClick={() => createTrainingFromCollection(collection)}
              >
                <FormattedMessage
                  {...messages.copyCollectionToTemplateButton}
                />
              </Button>
            </MenuWrapper>
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage
              {...messages.exercisesLengthInIt}
              values={{ this: collection.exercises.length }}
            />
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <TabList>
            <Tab onClick={exercisesTab}>
              <FormattedMessage {...messages.collectionsExercises} />
            </Tab>
            {ability.can('update', collection) && (
              <Tab onClick={detailTab}>
                <FormattedMessage {...messages.collectionsDetails} />
              </Tab>
            )}
          </TabList>
          <TabPanel>
            <TabPanelBody withScroller>
              {isExercisesPage && exercises.length > 0 && (
                <PanelTools>
                  <FieldInput
                    rounded
                    icon="search-grey"
                    iconLeft
                    onChange={onSearch}
                  />

                  {ability.can('update', collection) && (
                    <Button
                      rounded
                      primary
                      disabled={!collectionId}
                      onClick={() => setAddExercisesModal(collection)}
                    >
                      <Icon id="add-white" />
                      <FormattedMessage
                        {...messages.exercisesAddExercisesButton}
                      />
                    </Button>
                  )}
                </PanelTools>
              )}
              {exercises.length > 0 ? (
                <>
                  <SortableList
                    items={exercises}
                    onSort={exercises => setExercisesSortOrder(exercises)}
                  >
                    {({ items }) => (
                      <ExercisesListSortable
                        exercises={items}
                        selectedExercises={selected}
                        activeId={exerciseId}
                        draggable={draggable}
                        enablePreviewModal={false}
                        showTemplateCount={false}
                        checkable={ability.can('update', collection)}
                        onPreview={exercise =>
                          setModalData({ type: 'exercise', data: exercise })
                        }
                        onClick={id => {
                          if (id !== exerciseId) {
                            push(
                              generatePath(
                                ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES_EXERCISE,
                                {
                                  entityId,
                                  collectionId,
                                  exerciseId: id
                                }
                              )
                            );
                          }
                        }}
                        onSelect={exerciseIds => {
                          setSelected([...exerciseIds]);
                        }}
                      />
                    )}
                  </SortableList>
                  {draggable && (
                    <ButtonsGroup extraClassNames="u-margin-left-auto u-margin-top-medium">
                      <Button secondary onClick={() => onCancelHandler()}>
                        <FormattedMessage {...messages.cancelButton} />
                      </Button>
                      <Button
                        primary
                        disabled={loading} //|| processing
                        onClick={() => submitExercisesSorting()}
                      >
                        <FormattedMessage
                          {...messages.saveSortTemplateButton}
                        />
                      </Button>
                    </ButtonsGroup>
                  )}
                </>
              ) : (
                <Card empty extraClassNames="u-margin-auto">
                  <CardBody empty>
                    {ability.can('update', collection) ? (
                      <Message emptyState={true} icon="browse">
                        {collection.exercises.length ? (
                          <MessageText>
                            <FormattedMessage
                              {...messages.exercisesListEmptyResult}
                            />
                          </MessageText>
                        ) : (
                          <MessageText>
                            <FormattedMessage
                              {...messages.templateEmptyExercises}
                            />
                          </MessageText>
                        )}

                        <Button
                          primary
                          rounded
                          onClick={() => setAddExercisesModal(collection)}
                        >
                          <Icon id="add-white" />
                          <FormattedMessage
                            {...messages.exercisesAddExercisesButton}
                          />
                        </Button>
                      </Message>
                    ) : (
                      <Message emptyState icon="browse">
                        {collection.exercises.length ? (
                          <MessageText>
                            <FormattedMessage
                              {...messages.exercisesListEmptyResult}
                            />
                          </MessageText>
                        ) : (
                          <MessageText>
                            <FormattedMessage
                              {...messages.templateEmptyExercisesAndCantAdd}
                            />
                          </MessageText>
                        )}
                      </Message>
                    )}
                  </CardBody>
                </Card>
              )}
              {ability.can('update', collection) && addExercisesModal && (
                <AddExercisesModal
                  entityId={entityId}
                  template={collection}
                  onClose={() => {
                    setAddExercisesModal(false);
                  }}
                  onAdded={() => onAddedHandler()}
                />
              )}
            </TabPanelBody>
          </TabPanel>
          {ability.can('update', collection) && (
            <TabPanel>
              <TabPanelBody withScroller>
                <TemplateForm
                  entityId={entityId}
                  template={
                    new Template({
                      ...collection,
                      type: TemplateType.COLLECTION
                    })
                  }
                  onComplete={onCompleteHandler}
                >
                  {({ errors, touched, isSubmitting, handleSubmit }) => (
                    <Card secondary>
                      <CardBody secondary>
                        <FieldInput
                          id="title"
                          name="title"
                          placeholder={intl.formatMessage(
                            messages.collectionNamePlaceholder
                          )}
                          errors={errors}
                          touched={touched}
                          required
                        >
                          <FormattedMessage {...messages.collectionNameLabel} />
                        </FieldInput>
                      </CardBody>
                      <CardFooter secondary>
                        <FormEditFooterButtons
                          edit
                          disabledSubmit={isSubmitting}
                          onCancel={() =>
                            push(
                              generatePath(ROUTE_EXERCISES_COLLECTIONS_ENTITY, {
                                entityId
                              })
                            )
                          }
                          onSubmit={handleSubmit}
                        />
                      </CardFooter>
                    </Card>
                  )}
                </TemplateForm>
              </TabPanelBody>
            </TabPanel>
          )}
        </Tabs>
      </PanelBody>
      {addModalData && (
        <AddTrainingModal
          entityId={entityId}
          training={new Training({ ...addModalData })}
          onClose={() => setAddModalData(false)}
        />
      )}
    </Panel>
  );
};

export default observer(Collection);
