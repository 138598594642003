import React, { useContext } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Button, ButtonsGroup } from 'components/button';
import Icon from 'components/icon/Icon';
import { MenuWrapper } from 'components/menu';
import { ROUTE_EXERCISES_ENTITY_EXERCISE_LANGUAGE } from 'routes/RouteList';
import { StoreContext } from 'index';

function ExerciseLanguageButton({ entityId, exerciseId, lang }) {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const languages = user.getPossibleLanguages();

  const onSelectLanguageHandler = (lang = null) => {
    push(
      generatePath(ROUTE_EXERCISES_ENTITY_EXERCISE_LANGUAGE, {
        entityId,
        exerciseId,
        lang
      })
    );
  };

  return (
    <ButtonsGroup
      halfButtons
      extraClassNames="u-margin-left-auto u-margin-bottom-small"
    >
      <Button halfRounded primary>
        <Icon id="language" strokeColor="color-neutral-x-light" />
        {languages?.find(l => l.key === lang)?.language}
      </Button>
      <MenuWrapper
        trigger={
          <Button halfRounded primary>
            <Icon id="dropdown-open" fillColor="color-neutral-x-light" />
          </Button>
        }
      >
        {user.getPossibleLanguages().map(sp => {
          return (
            <Button
              menu
              key={sp.key}
              onClick={() => onSelectLanguageHandler(sp.key)}
            >
              {sp.language}
              {sp.key === lang && (
                <Icon id="language" strokeColor="color-neutral-dark" />
              )}
            </Button>
          );
        })}
      </MenuWrapper>
    </ButtonsGroup>
  );
}

export default ExerciseLanguageButton;
