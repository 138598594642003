import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_GET_TEMPLATES } from 'services/aws/templates-query';
import Select, { Option } from 'components/input/Select';
import messages from 'messages';
import { useIntl } from 'react-intl';
import { sort } from 'utils/sort';

function TemplateSelect({ entityId, value, onChange }) {
  const intl = useIntl();
  const [templates, setTemplates] = useState([]);

  const { loading, error, data } = useQuery(QUERY_GET_TEMPLATES, {
    variables: { entityId }
  });

  useEffect(() => {
    if (
      data?.getExerciseProgramTemplates &&
      data.getExerciseProgramTemplates.length > 0
    ) {
      setTemplates(
        sort(data.getExerciseProgramTemplates, { keys: [{ key: 'title' }] })
      );
    }
  }, [data]);

  if (error) return intl.formatMessage(messages.errorMessage, { message: '' });

  return (
    <Select
      id="template"
      extraClassNames="o-flex--justify-space-between"
      inline
      label={intl.formatMessage(messages.exercisesProgramSelectTemplatesLabel)}
      onChange={selectedValue => {
        onChange(templates.find(i => selectedValue.value === i.id) || null);
      }}
      value={value}
      disabled={loading && templates.length === 0}
    >
      <Option value="" label={intl.formatMessage(messages.noResultsOption)} />
      {templates.map(({ id, title }) => (
        <Option key={id} value={id} label={title} />
      ))}
    </Select>
  );
}

export default TemplateSelect;
