import React from 'react';
import Badge from '../../badge/Badge';

class ListItemBadge extends React.Component {
  render() {
    const { children, bullet } = this.props;
    return (
      <Badge rank bullet={bullet}>
        {children}
      </Badge>
    );
  }
}

export default ListItemBadge;
