import { useContext, useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { PanelHeader, PanelSubtitle } from 'components/panel';
import PanelTools from 'components/panel/PanelTools';
import BreadcrumbPath from 'containers/partials/misc/BreadcrumbPath';
import {
  PanelTitle,
  PanelTitleButton,
  PanelTitleWrapper
} from 'components/panel/panel-title';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { MenuWrapper } from 'components/menu';
import { Button } from 'components/button';
import {
  ROUTE_LESSONS_ENTITY,
  ROUTE_LESSONS_ENTITY_LESSON,
  ROUTE_LESSONS_ENTITY_LESSON_VIDEOS
} from 'routes/RouteList';
import Icon from 'components/icon/Icon';
import Loader from 'components/loader/Loader';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import ListHeader from 'components/list/ListHeader';
import ListColumnTitle from 'components/list/ListColumnTitle';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import Panel from 'components/panel/Panel';
import { StoreContext } from 'index';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import LessonsListItem from 'containers/partials/list-items/LessonsListItem';
import AddLessonModal from 'containers/pages/lessons/AddLessonModal';
import { useLessonsContext } from 'contexts/LessonsContext';
import FieldInput from 'components/input/FieldInput';
import { TabPanelBody } from 'components/tabs';

const Lessons = ({ entityId, active, lessonId }) => {
  const {
    routing: { push }
  } = useContext(StoreContext);
  const ability = useAbility(AbilityContext);
  const [modalData, setModalData] = useState(null);
  const { state, actions, loading: lessonsLoading } = useLessonsContext();

  useEffect(() => {
    actions.getLessons();
  }, []);

  const onSearch = e => {
    actions.setFilter({ search: e.target.value });
  };

  const onClickHandler = collection => {
    push(
      generatePath(ROUTE_LESSONS_ENTITY_LESSON_VIDEOS, {
        entityId,
        lessonId: collection.id
      })
    );
  };

  const onEditHandler = collection => {
    push(
      generatePath(ROUTE_LESSONS_ENTITY_LESSON, {
        entityId,
        lessonId: collection.id
      })
    );
  };

  const onDeleteHandler = collectionId => {
    push(generatePath(ROUTE_LESSONS_ENTITY, { entityId, collectionId }));
  };

  return (
    <Panel active={active}>
      <PanelHeader>
        <BreadcrumbPath crumbs={[]} />
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.lessonsTitle} />

            <MenuWrapper trigger={<PanelTitleButton />} />
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage {...messages.lessonsSubTitle} />
          </PanelSubtitle>
        </PanelTitleWrapper>
        <PanelTools separated>
          <FieldInput rounded icon="search-grey" iconLeft onChange={onSearch} />

          {ability.can('create', 'Lesson') && (
            <Button
              rounded
              primary
              onClick={() =>
                setModalData({
                  lessons: { entityId, title: '', exercises: [], tags: [] }
                })
              }
            >
              <Icon id="add-white" />
              <FormattedMessage {...messages.lessonsAddButton} />
            </Button>
          )}
        </PanelTools>
      </PanelHeader>
      {lessonsLoading && <Loader />}

      {state.lessons && state.lessons.length ? (
        <TabPanelBody withScroller>
          <List>
            <ListHeader noTitleStatus>
              <ListColumnTitle
              // ascendent // in comment totdat het werkt:
              // TODO: rangschikking list by column title
              >
                <FormattedMessage {...messages.lessonsHeaderListLessons} />
              </ListColumnTitle>
              <ListColumnTitle>
                <FormattedMessage {...messages.lessonsHeaderListLessonsQty} />
              </ListColumnTitle>
            </ListHeader>
            <ListBody>
              {state.lessons.map(lesson => (
                <LessonsListItem
                  // checkbox
                  statusIcon="program"
                  // visible={selected.length > 0}
                  videosCount={lesson.exercises.length}
                  id={lesson.id}
                  key={lesson.id}
                  lesson={lesson}
                  entityId={entityId}
                  active={lesson.id === lessonId}
                  // checked={selected.includes(lesson.id)}
                  // onChange={() => select(lesson.id)}
                  onClick={() => {
                    return onClickHandler(lesson);
                  }}
                  onEdit={() => onEditHandler(lesson)}
                  onDeleted={() => onDeleteHandler(lessonId)}
                />
              ))}
            </ListBody>
          </List>
        </TabPanelBody>
      ) : (
        <Card centered>
          <CardBody empty>
            <Message emptyState={true} icon="browse">
              <MessageText>
                <FormattedMessage {...messages.lessonListEmpty} />
              </MessageText>
            </Message>
          </CardBody>
        </Card>
      )}

      {modalData && (
        <AddLessonModal
          entityId={entityId}
          lesson={modalData.lesson}
          onClose={() => setModalData(null)}
        />
      )}
    </Panel>
  );
};

export default Lessons;
