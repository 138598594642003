import React from 'react';

import Panel from './Panel';
import classNames from 'classnames';

class PanelError extends React.Component {
  render() {
    const { ...props } = this.props;
    return (
      <Panel extraClassNames={classNames('c-panel--error')}>
        {props.children}
      </Panel>
    );
  }
}

export default PanelError;
