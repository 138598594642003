import React, { Fragment } from 'react';
import { inject } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';

import messages from 'messages';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { Button, ButtonsGroup } from 'components/button';
import { CardBody, CardFooter, CardBodyTools } from 'components/card';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import Entity from 'models/Entity';
import { sortEntities } from 'utils/sort';
import {
  ListItem,
  ListItemStatus,
  ListItemLabelWrapper,
  ListItemLabel
} from 'components/list/list-item';
import Icon from 'components/icon/Icon';
import { QUERY_GET_GROUPS_BY_ENTITY_ID } from 'services/aws/client-query';
import { EntityTypes } from 'constants.js';
import Loader from 'components/loader/Loader';

class SelectGroupView extends React.Component {
  state = {
    entityId: this.props.entityId,
    activeEntityId: null,
    activeName: null
  };

  onBackHandler = () => {
    this.setState({
      entityId: this.entity.parentId,
      activeEntityId: null,
      activeName: null
    });
  };

  onGroupClickedHandler = (id, name) => {
    this.setState({ activeEntityId: id, activeName: name });
  };

  onGroupDoubleClickedHandler = id => {
    this.setState({ entityId: id, activeEntityId: null, activeName: null });
  };

  render() {
    const { authStore, onCancelHandler, onSubmitHandler } = this.props;
    const { entityId, activeEntityId, activeName } = this.state;
    return (
      <Fragment>
        <CardHeader modal>
          <CardHeaderTitle>
            <FormattedMessage
              {...messages.growthPredictionSubChoiceModalTitle}
            />
          </CardHeaderTitle>
          <CardHeaderSubtitle>
            <FormattedMessage
              {...messages.growthPredictionSubGroupChoiceModalSubtitle}
            />
          </CardHeaderSubtitle>
        </CardHeader>
        <CardBody
          modal
          extraClassNames={'u-padding-vertical-none u-padding-horizontal-small'}
        >
          {entityId !== authStore.user.rootEntityId && (
            <CardBodyTools style={{ flexWrap: 'wrap' }}>
              {/* Add a search field. And why the if? */}
              <Button rounded light onClick={e => this.onBackHandler(e)}>
                <Icon id="back-nav" />
                <FormattedMessage {...messages.growthPredictionBackButton} />
              </Button>
            </CardBodyTools>
          )}
          <List>
            <Query
              query={QUERY_GET_GROUPS_BY_ENTITY_ID}
              variables={{
                id: entityId
              }}
              fetchPolicy="cache-and-network"
            >
              {result => {
                const { loading, error, data } = result;
                if (loading) return <Loader />;

                //if (error) return <p>intl.formatMessage(messages.errorMessage, {message: error.message})</p>;
                if (error)
                  return (
                    <p>
                      <FormattedMessage
                        {...messages.errorMessage}
                        values={{
                          message: error.message
                        }}
                      />
                    </p>
                  );
                this.entity = new Entity(data.getEntity);
                const filteredEntities = this.entity.subEntities.filter(
                  se => se.type !== EntityTypes.ORGANISATION
                );
                const sortedEntities = sortEntities(filteredEntities);
                return (
                  <ListBody>
                    {sortedEntities.map(group => {
                      return (
                        <GroupListItem
                          id={group.id}
                          key={group.id}
                          group={group}
                          active={activeEntityId === group.id}
                          onDoubleClick={
                            group.subEntities && group.subEntities.length > 0
                              ? () => this.onGroupDoubleClickedHandler(group.id)
                              : () => false
                          }
                          onClick={() => {
                            this.onGroupClickedHandler(group.id, group.name);
                          }}
                        />
                      );
                    })}
                  </ListBody>
                );
              }}
            </Query>
          </List>
        </CardBody>
        <CardFooter modal>
          <ButtonsGroup>
            <Button secondary onClick={() => onCancelHandler()}>
              <FormattedMessage {...messages.growthPredictionCancelButton} />
            </Button>
            <Button
              disabled={activeEntityId === null}
              primary
              onClick={() => onSubmitHandler(activeEntityId, null, activeName)}
            >
              <FormattedMessage {...messages.growthPredictionViewButton} />
            </Button>
          </ButtonsGroup>
        </CardFooter>
      </Fragment>
    );
  }
}

export default withRouter(
  injectIntl(inject('authStore', 'routing')(SelectGroupView))
);

const GroupListItem = ({
  group: { id, name, clients },
  active,
  onClick,
  onDoubleClick
}) => (
  <ListItem
    group
    active={active}
    onClick={onClick}
    onDoubleClick={onDoubleClick}
  >
    <ListItemStatus itemId={id} statusIcon="group" />
    <ListItemLabelWrapper>
      <ListItemLabel label>{name}</ListItemLabel>
      <ListItemLabel info>
        <FormattedMessage
          {...messages.growthPredictionListItemSportersCount}
          values={{ count: (clients && clients.length) || 0 }}
        />
      </ListItemLabel>
    </ListItemLabelWrapper>
  </ListItem>
);
