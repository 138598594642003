import { useState, useEffect, useRef, useContext } from 'react';
import * as Sentry from '@sentry/react';
import { withRouter, useHistory } from 'react-router-dom';
import { Card } from 'components/card';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import FormEditHeaderButtons from '../buttons/FormEditHeaderButtons';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { useSessionContext } from 'contexts/SessionContext';
import FormEditFooterButtons from '../buttons/FormEditFooterButtons';
import Loader from 'components/loader/Loader';
import Button from 'components/button/Button';
import TestItemResultsList from 'containers/partials/lists/TestItemResultsList';
import TestItemInputList from 'containers/partials/forms/TestItemInputList';
import { useTestDataContext } from 'contexts/TestDataContext';
import { useUnmount } from 'react-use';
import { useIdle } from 'react-use';
import useEdit from 'hooks/utils/useEdit';
import { useBeforeunload } from 'react-beforeunload';
import { useEditableContext } from 'contexts/EditableContext';
import { StoreContext } from 'index';

function EditTestResultCard({
  entityId,
  reportEnabled,
  reportPath,
  personInjury,
  ...props
}) {
  const { uiState } = useContext(StoreContext);
  let { push } = useHistory();
  const blocker = useRef();
  const [nextLocation, setNextLocation] = useState();
  const { refetch } = useSessionContext();
  const {
    testDataId,
    testDataLoading,
    groupedTests,
    submitData,
    refetchTestData,
    formIsUpdated,
    formIsDirty
  } = useTestDataContext();
  const isIdle = useIdle(5e3);
  const rootRef = useRef(null);
  const { setIsEditing } = useEditableContext();
  const [edit, setEdit] = useEdit(props.edit, true);

  // prevent unloading the page when user has unsaved data
  useBeforeunload(event => {
    if (formIsDirty) {
      event.preventDefault();
    }
  });

  useEffect(() => {
    setIsEditing(edit ? testDataId : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, testDataId]);

  blocker.current = props.history.block(location => {
    if (edit && formIsDirty) {
      setTimeout(() => {
        setNextLocation(location);
      }, 500);
      return false;
    } else {
      return true;
    }
  });

  useEffect(() => {
    if (!formIsDirty && blocker.current && nextLocation) {
      blocker.current();
      push(nextLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker, formIsDirty, nextLocation]);

  useEffect(() => {
    const submitTheForm = async () => {
      await submitData(testDataId);
      await getNewData();
    };
    if (isIdle && formIsDirty) {
      submitTheForm().catch(error => {
        Sentry.captureException(error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle]);

  useUnmount(() => {
    setIsEditing(undefined);
    if (formIsUpdated) {
      getNewData();
    }
  });

  const getNewData = async () => {
    await refetch({});
    if (testDataId && formIsUpdated) {
      await refetchTestData();
    }
    return 'done';
  };

  const endForm = async () => {
    uiState.increasePendingRequest();
    if (formIsDirty) {
      await submitData(testDataId);
    }
    await getNewData();
    uiState.decreasePendingRequest();
    setEdit(false);
  };

  const submitForm = async () => {
    uiState.increasePendingRequest();
    await submitData(testDataId);
    uiState.decreasePendingRequest();
  };

  if (!testDataId || !groupedTests || (testDataLoading && !edit))
    return <Loader />;

  return (
    <>
      <Card editTestResult ref={rootRef}>
        <CardHeader secondary>
          <CardHeaderTitle>
            <FormattedMessage {...messages.titleTests} />
          </CardHeaderTitle>
          {Object.values(groupedTests).length > 0 && (
            <FormEditHeaderButtons
              edit={edit}
              setEdit={e => {
                if (!e) {
                  endForm(e);
                } else {
                  setEdit(true);
                }
              }}
              cancelLabel={`finishButton`}
              saveLabel={`saveButton`}
              onSubmit={submitForm}
            />
          )}
        </CardHeader>

        {edit ? (
          <TestItemInputList
            entityId={entityId}
            edit={edit}
            personInjury={personInjury}
          />
        ) : (
          <TestItemResultsList
            entityId={entityId}
            personInjury={personInjury}
          />
        )}

        {edit && (
          <FormEditFooterButtons
            edit={edit}
            setEdit={e => {
              if (!e) {
                endForm(e);
              } else {
                setEdit(true);
              }
            }}
            cancelLabel={`finishButton`}
            saveLabel={`saveButton`}
            onSubmit={submitForm}
          />
        )}
        {reportEnabled && !edit && (
          <div className="o-flex o-flex--justify-end u-margin-top-small">
            <Button
              primary
              onClick={() => {
                setTimeout(() => {
                  push(reportPath);
                }, 500);
              }}
            >
              <FormattedMessage {...messages[`seeReportButton`]} />
            </Button>
          </div>
        )}
      </Card>
    </>
  );
}

export default withRouter(EditTestResultCard);
