import { Fragment, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { withRouter, generatePath } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  ROUTE_SESSIONS_ENTITY,
  ROUTE_SESSIONS_ENTITY_TEMPLATES,
  ROUTE_USER_SESSIONS_ENTITY
} from 'routes/RouteList';
import Panel from 'components/panel/Panel';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelHeader from 'components/panel/PanelHeader';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelSubtitle from 'components/panel/panel-title/PanelSubtitle';
import { PanelBody } from 'components/panel';
import messages from 'messages';
import { TabList, TabPanel, Tabs } from 'components/tabs';
import useTabIndex from 'hooks/utils/useTabIndex';
import FieldInput from 'components/input/FieldInput';
import PanelTools from 'components/panel/PanelTools';
import TabLink from 'components/tabs/TabLink';
import UserSessions from 'containers/pages/user/sessions/UserSessions';
import { useUserContext } from 'contexts/UserContext';
import Loader from 'components/loader/Loader';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';

const Overview = ({ entityId, active }) => {
  const { loading, error, getData, sessions } = useUserContext();

  const [search, setSearch] = useState('');
  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_SESSIONS_ENTITY,
    ROUTE_SESSIONS_ENTITY_TEMPLATES
  ]);

  useEffect(() => {
    const fetchData = async () => {
      await getData();
    };
    fetchData().catch(error => {
      Sentry.captureException(error);
    });
  }, []);

  const onSearch = e => {
    setSearch(e.target.value);
  };

  if (loading) return <Loader />;
  if (error) return <RetryPanel />;

  return (
    <Panel active={active}>
      <Fragment>
        <PanelHeader>
          <PanelTitleWrapper>
            <PanelTitle>
              <FormattedMessage {...messages.titleSessions} />
            </PanelTitle>
            <PanelSubtitle>
              <FormattedMessage {...messages.subtitleAthleteSessions} />
            </PanelSubtitle>
          </PanelTitleWrapper>
          <PanelTools extraClassNames="u-margin-bottom-none">
            <FieldInput
              rounded
              type="text"
              icon="search-grey"
              iconLeft
              onChange={onSearch}
              style={{ marginBottom: 0 }}
            />
          </PanelTools>
        </PanelHeader>
        <PanelBody>
          <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
            <TabList>
              <TabLink
                key="session"
                to={generatePath(ROUTE_USER_SESSIONS_ENTITY, {
                  entityId
                })}
              >
                <FormattedMessage {...messages.tabSessionsSessions} />
              </TabLink>

              {/*<TabLink
                to={generatePath(ROUTE_USER_SESSIONS_ENTITY_MEDICAL, {
                  entityId
                })}
                key="medical"
              >
                <FormattedMessage {...messages.tabSporterMedical} />
              </TabLink>*/}
            </TabList>
            <TabPanel key="session">
              {sessions === null ? (
                <Loader />
              ) : (
                <UserSessions entityId={entityId} search={search} />
              )}
            </TabPanel>
            {/*<TabPanel key="medical">
              <UserMedicals entityId={entityId} search={search} />
            </TabPanel>*/}
          </Tabs>
        </PanelBody>
      </Fragment>
    </Panel>
  );
};

export default withRouter(Overview);
