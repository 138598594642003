import { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import { MUTATION_EXPORT_SESSIONS } from 'services/aws/session-query';
import { CardBody, CardFooter } from 'components/card';
import { ButtonsGroup, Button } from 'components/button';
import Icon from 'components/icon/Icon';
import InputWrapper from 'components/input/InputWrapper';
import messages from 'messages';
import { navigateToUrl } from 'utils/browser';
import { EXPORT_FILES_URL, TestSetV2Type } from 'constants.js';
import InputErrorMessage from 'components/input/InputErrorMessage';
import { StoreContext } from 'index';
import { useMutation } from '@apollo/client';
import { FieldDatePicker } from 'components/input/FieldDatePicker';
import { parseDateString } from 'utils/date';
import TestsetSelect from 'containers/partials/inputs/TestsetSelect';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';

const EXPORT_SESSIONS_STATE = Object.freeze({
  DEFAULT: 'default',
  SUCCESS: 'success'
});

const ExportSessionsCard = ({ onClose }) => {
  const {
    authStore: {
      user: { email, rootEntityId }
    },
    uiState
  } = useContext(StoreContext);
  const intl = useIntl();
  const testSetTypes = [TestSetV2Type.TALENT, TestSetV2Type.PREVENTION];

  const [exportState, setExportState] = useState(EXPORT_SESSIONS_STATE.DEFAULT);
  const [errorMessage, setErrorMessage] = useState(null);

  const [exportSessions, { loading }] = useMutation(MUTATION_EXPORT_SESSIONS);

  const handleSubmit = async values => {
    uiState.increasePendingRequest();

    try {
      const { data } = await exportSessions({
        variables: {
          from: format(values.from, 'yyyy-LL-dd'),
          to: format(values.to, 'yyyy-LL-dd'),
          testSetId: values.testSetId,
          entityId: rootEntityId
        }
      });

      if (data && data.exportTestSession) {
        uiState.decreasePendingRequest();
        if (data.exportTestSession === 'true') {
          setExportState(EXPORT_SESSIONS_STATE.SUCCESS);
        } else {
          navigateToUrl(EXPORT_FILES_URL + data.exportTestSession);
        }
      } else if (data && data.exportTestSession === null) {
        uiState.decreasePendingRequest();
      }
    } catch (error) {
      if (error.message.search('no_sessions') !== -1) {
        setErrorMessage(
          intl.formatMessage(messages.modalExportSessionsNoSessionsFound)
        );
      } else {
        setErrorMessage(error.message);
      }
      uiState.decreasePendingRequest();
    }
  };

  const validationSchema = Yup.object().shape({
    testSetId: Yup.string().required(messages.testsetTypeRequired),
    from: Yup.date()
      .transform(parseDateString)
      .required(intl.formatMessage(messages.startdateRequired)),
    to: Yup.date()
      .transform(parseDateString)
      .required(intl.formatMessage(messages.modalExportSessionsInValidDates))
  });

  return (
    <>
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.modalExportSessionsTitle} />
        </CardHeaderTitle>
        <CardHeaderSubtitle>
          <FormattedMessage {...messages.modalExportSessionsSubTitle} />
        </CardHeaderSubtitle>
      </CardHeader>
      <Formik
        initialValues={{
          from: '',
          to: '',
          testSetId: '',
          entityId: rootEntityId
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={handleSubmit}
      >
        {({
          values,
          touched,
          handleChange,
          setFieldValue,
          errors,
          handleSubmit
        }) => {
          return (
            <Form noValidate>
              {exportState === EXPORT_SESSIONS_STATE.DEFAULT && (
                <CardBody modalWithOverflow>
                  <TestsetSelect
                    name={'testSetId'}
                    entityId={rootEntityId}
                    value={values.testSetId}
                    types={testSetTypes}
                    onChange={testSet => {
                      testSet && setFieldValue('testSetId', testSet.id);
                    }}
                  />
                  <InputWrapper dateRangeCol>
                    <FieldDatePicker
                      id={'from'}
                      name="from"
                      values={values.from}
                      required
                      icon={
                        errors['startdate'] ? 'validation-error' : 'calendar'
                      }
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                    >
                      <FormattedMessage {...messages.cardLabelFrom} />
                    </FieldDatePicker>
                    <FieldDatePicker
                      id="to"
                      name="to"
                      value={values.to}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                    >
                      <FormattedMessage {...messages.cardLabelTill} />
                    </FieldDatePicker>
                  </InputWrapper>
                  {errorMessage && (
                    <InputErrorMessage>{errorMessage}</InputErrorMessage>
                  )}
                </CardBody>
              )}
              {exportState === EXPORT_SESSIONS_STATE.SUCCESS && (
                <CardBody empty modal>
                  <Icon id="big-send" xHugeIcon />
                </CardBody>
              )}
              <CardFooter primary modal>
                {exportState === EXPORT_SESSIONS_STATE.DEFAULT && (
                  <ButtonsGroup>
                    <Button secondary onClick={onClose}>
                      <FormattedMessage {...messages.cardButtonCancel} />
                    </Button>

                    <Button primary disabled={loading} onClick={handleSubmit}>
                      <FormattedMessage {...messages.cardButtonExport} />
                    </Button>
                  </ButtonsGroup>
                )}
                {exportState === EXPORT_SESSIONS_STATE.SUCCESS && (
                  <p>
                    {intl.formatMessage(messages.modalExportSessionsEmailSent, {
                      email
                    })}
                  </p>
                )}
              </CardFooter>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ExportSessionsCard;
