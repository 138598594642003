import React from 'react';
import VideoForm from 'containers/pages/videos/VideoForm';
import VideoFormInput from 'containers/pages/videos/VideoFormInput';
import VideoViewCard from 'containers/pages/videos/VideoViewCard';

const VideoPanel = ({
  entityId,
  video,
  videoId,
  edit,
  editable,
  readOnly,
  setEdit,
  onCancel,
  parentPath
}) => {
  if (edit && editable) {
    return (
      <VideoForm
        extraClassNames={'u-margin-top-medium'}
        entityId={entityId}
        video={video}
        onComplete={() => setEdit(false)}
      >
        <VideoFormInput
          entityId={entityId}
          edit={edit}
          readOnly={readOnly}
          setEdit={setEdit}
          onCancel={onCancel}
          video={video}
        />
      </VideoForm>
    );
  }

  return (
    <VideoViewCard
      entityId={entityId}
      video={video}
      videoId={videoId}
      parentPath={parentPath}
    />
  );
};

export default VideoPanel;
