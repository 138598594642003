import { useState, useEffect, useContext, Fragment } from 'react';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { Button, ButtonsGroup } from 'components/button';
import { Card, CardBody } from 'components/card';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import { StoreContext } from 'index';
import { reorder } from 'utils/array';
import { useFormikContext } from 'formik';
import Icon from 'components/icon/Icon';
import { useTestsContext } from 'contexts/TestsContext';
import AddExtraTestsModal from './AddExtraTestsModal';
import { generatePath } from 'react-router-dom';
import { ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION } from 'routes/RouteList';
import { TestListItem } from 'containers/partials/list-items/TestListItem';
import Loader from 'components/loader/Loader';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { TestSetV2Type } from 'constants.js';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { groupTests } from 'utils/tests';
import ListHeader from 'components/list/ListHeader';
import ListColumnTitle from 'components/list/ListColumnTitle';
import { usePreviewModalContext } from 'contexts/PreviewModalContext';
import { useUIDSeed } from 'react-uid';
import FieldInput from 'components/input/FieldInput';

function PreventionSelectedTestsCard({
  edit,
  tests: initialTests,
  onEdit,
  style,
  extraClassNames,
  editPanel = false,
  children,
  childrenOnTop
}) {
  const {
    authStore: { entityId },
    routing: { push }
  } = useContext(StoreContext);
  const {
    submitForm,
    values: session,
    setFieldValue,
    resetForm
  } = useFormikContext();

  const { testsState, testActions, loading } = useTestsContext();
  const { setModalData } = usePreviewModalContext();
  const [tests, setTests] = useState(initialTests || []);
  const [totalEstimate, setTotalEstimate] = useState(0);
  const [addExtraTestsModal, setAddExtraTestsModal] = useState(null);
  const uidSeed = useUIDSeed();
  const intl = useIntl();

  useEffect(() => {
    testActions.getTests();
  }, []);

  useEffect(() => {
    if (testsState.selectedTests) {
      setTests(groupTests([...testsState.selectedTests]));

      setFieldValue('tests', [...testsState.selectedTests]);
      const estimate = testsState.selectedTests.reduce((acc, current) => {
        return acc + current.duration;
      }, 0);
      setTotalEstimate(estimate);
    }
  }, [testsState.selectedTests]);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const source = result.source;
    const destination = result.destination;
    const reorderdTests = reorder(tests, source.index, destination.index);

    setTests(reorderdTests);

    setFieldValue('tests', [...reorderdTests]);
  };

  const onTestRemoveCheckHandler = test => {
    testActions.unselectTests([test]);
  };

  const onAddExtraTestsHandler = tests => {
    testActions.addExtraTest(tests);
    setAddExtraTestsModal(false);
  };

  const unselectAll = () => {
    // TODO werkt niet correct
    testActions.unselectTests(testsState.selectedTests);
  };

  const onEditPreventionHandler = () => {
    push(
      generatePath(ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION, {
        entityId,
        sessionId: session.id,
        type: session.preventionType
      })
    );
  };

  if (loading && !session) return <Loader />;

  return (
    <>
      <Card secondary style={style} extraClassNames={extraClassNames}>
        <CardHeader secondary extraClassNames={'o-flex--wrap'}>
          <CardHeaderTitle>
            <FormattedMessage {...messages.preventionSelectedTestsTitle} />
          </CardHeaderTitle>
          <FormEditHeaderButtons
            edit={edit}
            setEdit={onEdit}
            onSubmit={submitForm}
            onCancel={resetForm}
            type={'button'}
            extraClassNames="u-margin-tiny"
          />
          {childrenOnTop && edit && children}
        </CardHeader>
        <CardBody secondary>
          <ButtonsGroup>
            <FieldInput
              id="est-time"
              value={intl.formatMessage(messages.globalUnitsMinutes, {
                minutes: `${totalEstimate}`
              })}
              icon="test"
              disabled
              useNative
              inline
              extraClassNames={'u-margin-bottom-none'}
            >
              <FormattedMessage {...messages.preventionEstimatedTimeLabel} />
            </FieldInput>
          </ButtonsGroup>

          {editPanel && edit && (
            <ButtonsGroup
              extraClassNames={'u-margin-top-medium o-flex--justify-end'}
            >
              {tests.length > 0 && (
                <Button ghost small onClick={() => unselectAll()}>
                  <Icon id="delete" strokeColor="color-neutral-dark" />
                  <FormattedMessage
                    {...messages.preventionUnselectAllTestsButton}
                  />
                </Button>
              )}

              <div className="o-flex o-flex--justify-end">
                <Button
                  small
                  ghost
                  onClick={() => setAddExtraTestsModal(session)}
                >
                  <Icon id="add" />
                  <FormattedMessage
                    {...messages.preventionAddExtraTestsButton}
                  />
                </Button>
              </div>
            </ButtonsGroup>
          )}
          <Fragment>
            {Object.keys(tests).map((category, index) => (
              <List
                // dragNDropWithPreview
                key={category}
                preview
                style={{
                  minWidth: '0',
                  marginBottom: 0
                }}
              >
                <ListHeader oneTitle>
                  <ListColumnTitle>{category}</ListColumnTitle>
                </ListHeader>
                <ListBody>
                  {tests[category].length > 0 &&
                    tests[category].map(test => (
                      <TestListItem
                        uid={uidSeed(test.id)}
                        draggableDisabled
                        index={index}
                        key={test.id}
                        test={{
                          ...test,
                          video: test.video_link
                            ? `${test.video_link}-320.mp4`
                            : null
                        }}
                        checkbox={edit}
                        edit={edit}
                        checked
                        dependentEditable
                        onClick={() =>
                          setModalData({ type: 'testItem', data: test })
                        }
                        clickable
                        onChange={() => onTestRemoveCheckHandler(test)}
                      />
                    ))}
                </ListBody>
              </List>
            ))}
          </Fragment>

          {/*{tests.length > 0 && (
            <DragDropContext onDragEnd={result => onDragEnd(result)}>
              <Droppable droppableId="tests">
                {provided => (
                  <List
                    dragNDropWithPreview={edit}
                    preview={!edit}
                    style={{
                      minWidth: '0'
                    }}
                  >
                    <ListBody
                      {...provided.droppableProps}
                      innerRef={provided.innerRef}
                    >
                      {tests.map((test, index) => (
                        <Draggable
                          isDragDisabled={!edit}
                          key={test.id}
                          draggableId={test.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <TestListItem
                              index={index}
                              key={test.id}
                              test={{
                                ...test,
                                video: test.video_link
                                  ? `${test.video_link}-320.mp4`
                                  : null
                              }}
                              edit={edit}
                              checked
                              dependentEditable
                              onChange={() => onTestRemoveCheckHandler(test)}
                              innerRef={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            />
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ListBody>
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          )}*/}
          {tests.length > 5 && !childrenOnTop && children}

          {!editPanel && tests.length > 5 && (
            <div className="o-flex o-flex--justify-end o-flex--align-self-start">
              <Button small ghost onClick={onEditPreventionHandler}>
                <Icon id="edit" />
                <FormattedMessage {...messages.fullEditButton} />
              </Button>
            </div>
          )}

          {tests.length === 0 && (
            <Card reports={!editPanel}>
              <CardBody
                secondary
                extraClassNames="u-margin-bottom-none u-padding-vertical-huge"
              >
                <Message emptyState icon="test">
                  <MessageText>
                    <FormattedMessage {...messages.labelScreeningNoTests} />
                  </MessageText>
                </Message>

                {!editPanel && (
                  <Button
                    primary
                    rounded
                    extraClassNames="u-margin-top-large"
                    onClick={onEditPreventionHandler}
                  >
                    <Icon id="add-white" />
                    <FormattedMessage {...messages.testSetsAddTestsetButton} />
                  </Button>
                )}
              </CardBody>
            </Card>
          )}
        </CardBody>
      </Card>

      {addExtraTestsModal && (
        <AddExtraTestsModal
          type={TestSetV2Type.PREVENTION}
          entityId={entityId}
          session={session}
          onClose={() => {
            setAddExtraTestsModal(false);
          }}
          onAdd={tests => onAddExtraTestsHandler(tests)}
        />
      )}
    </>
  );
}

export default PreventionSelectedTestsCard;
