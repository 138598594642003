import { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import Template from 'models/Template';
import { QUERY_GET_TEMPLATE } from 'services/aws/templates-query';
import { getFilteredEntities } from 'utils/search';

const useTemplate = (entityId, templateId) => {
  const exercisesData = useRef();
  const [template, setTemplate] = useState(new Template({}));
  const [exercises, setExercises] = useState([]);
  const [filter, setFilter] = useState({ search: '' });

  const { loading, error, data } = useQuery(QUERY_GET_TEMPLATE, {
    variables: { id: templateId },
    skip: !templateId
  });

  useEffect(() => {
    if (data?.getExerciseProgramTemplate) {
      const template = new Template({ ...data.getExerciseProgramTemplate });
      setTemplate(template);
      exercisesData.current = data.getExerciseProgramTemplate.exercises;
      setExercises(exercisesData.current);
    }
  }, [data, templateId]);

  useEffect(() => {
    if (template && exercisesData?.current?.length) {
      const filteredExercises = getFilteredEntities(
        exercisesData.current,
        filter.search ?? '',
        filter.tags ?? null
      );
      setExercises(filteredExercises);
    }
  }, [filter, template]);

  return {
    template,
    exercises,
    filter,
    setFilter,
    loading,
    error
  };
};

export default useTemplate;
