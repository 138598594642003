import { Card, CardBody } from 'components/card';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import FormEditHeaderButtons from '../buttons/FormEditHeaderButtons';
import FormEditFooterButtons from '../buttons/FormEditFooterButtons';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import IssueDetailFields from 'containers/pages/rehab/issue/IssueDetailFields';
import IssueForm from 'containers/pages/rehab/issue/IssueForm';
import useEdit from 'hooks/utils/useEdit';

function IssueDetailCard({
  entityId,
  personIssue,
  medical,
  sporterId,
  ...props
}) {
  const [edit, setEdit] = useEdit(props.edit, true);

  return (
    <Card secondary>
      <IssueForm
        entityId={entityId}
        sporterId={sporterId}
        personIssue={personIssue}
        medical={medical}
      >
        {({ submitForm, resetForm }) => (
          <>
            <CardHeader secondary>
              <CardHeaderTitle>
                <FormattedMessage {...messages.titleIssueDetails} />
              </CardHeaderTitle>
              <FormEditHeaderButtons
                edit={edit}
                setEdit={setEdit}
                onCancel={resetForm}
                onSubmit={submitForm}
                type={'button'}
              />
            </CardHeader>
            <CardBody secondary>
              <IssueDetailFields edit={edit} />
            </CardBody>
            <FormEditFooterButtons
              edit={edit}
              setEdit={setEdit}
              onCancel={resetForm}
              onSubmit={submitForm}
              type={'button'}
            />
          </>
        )}
      </IssueForm>
    </Card>
  );
}

export default IssueDetailCard;
