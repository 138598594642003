import React, { useEffect, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import TooltipPopper from 'components/tooltip/TooltipPopper';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import useFileStatusPoller from 'hooks/queries/useFileStatusPoller';
import File from 'models/File';

const FileThumb = ({ file, editable, onDelete, selectedMedia, onClick }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: file.id, disabled: !editable });
  const [thumb, setThumb] = useState(file);
  const [type, setType] = useState('');
  const [fileError, setFileError] = useState(false);
  const { startPolling } = useFileStatusPoller(
    thumb,
    () => {
      setFileError(true);
    },
    file => {
      setThumb(new File(file));
    }
  );

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  useEffect(() => {
    if (thumb.status === 'waiting') {
      startPolling();
    }
    if (thumb.status === 'available') {
      if (thumb.type) {
        setType(
          thumb.type.endsWith('image')
            ? 'image'
            : thumb.type.endsWith('video')
            ? 'video'
            : ''
        );
      }
    }
  }, [thumb.status]);

  // File not ready status
  if (thumb.status === 'waiting' && !fileError) {
    return (
      <div className="c-media__gallery-thumb c-file-upload--uploading">
        <TooltipPopper
          id={`popper_tooltip_${thumb.id}`}
          icon="retry"
          mediumIcon
          onMouseEnter={() => true}
          onFocus={() => true}
          onMouseLeave={() => false}
          onBlur={() => false}
        >
          <FormattedMessage
            {...messages.fileUploadVideoNotAvailableTooltipMessage}
          />
        </TooltipPopper>
      </div>
    );
  }

  if (editable) {
    return (
      <div
        className={
          isDragging
            ? 'c-media__gallery-thumb-wrapper is-dragging'
            : 'c-media__gallery-thumb-wrapper is-draggable'
        }
        id={thumb.id}
        key={thumb.id}
        ref={setNodeRef}
        style={style}
      >
        <>
          <Button type="button" circular grey onClick={() => onDelete(thumb)}>
            <Icon id="delete" strokeColor="color-neutral-dark" />
          </Button>
          {/* File failed status*/}
          {fileError ? (
            <div className="c-media__gallery-thumb c-media__gallery-thumb-empty c-file-upload--uploading">
              <TooltipPopper
                id={`popper_tooltip_${thumb.id}`}
                icon="database-error"
                mediumIcon
                onMouseEnter={() => true}
                onFocus={() => true}
                onMouseLeave={() => false}
                onBlur={() => false}
              >
                <FormattedMessage
                  {...messages.fileUploadVideoFailedTooltipMessage}
                />
              </TooltipPopper>
            </div>
          ) : (
            <button
              className={'c-button c-media__gallery-thumb'}
              type={'button'}
              {...attributes}
              {...listeners}
            >
              <ThumbType fileType={type} file={thumb} />
            </button>
          )}
        </>
      </div>
    );
  }

  // view thumb file
  return selectedMedia && thumb.id === selectedMedia.id ? (
    <div
      className={'c-media__gallery-thumb c-media__gallery-thumb-selected'}
      id={thumb.id}
      key={thumb.id}
      ref={setNodeRef}
      style={style}
    >
      <ThumbType fileType={type} file={thumb} failed={fileError} />
    </div>
  ) : (
    <button
      className={'c-button c-media__gallery-thumb'}
      type={'button'}
      id={thumb.id}
      key={thumb.id}
      ref={setNodeRef}
      style={style}
      onClick={() => onClick(thumb)}
    >
      <ThumbType fileType={type} file={thumb} failed={fileError} />
    </button>
  );
};

export default FileThumb;

const ThumbType = ({ file, fileType }) => {
  const [ready, setReady] = useState(false);
  const [imgKey, setImgKey] = useState(() => Date.now());
  const handleImageLoaded = () => {
    setReady(true);
  };

  const handleImageError = () => {
    setReady(false);
    setTimeout(() => setImgKey(Date.now()), 1000);
  };

  switch (fileType) {
    case 'image':
      const src = file.signedDownloadUrl;
      const fileName = file.filename;
      return (
        <img
          key={imgKey}
          src={src}
          alt={fileName}
          onLoad={handleImageLoaded}
          onError={handleImageError}
          onMouseDown={e => e.preventDefault()}
        />
      );
    case 'video':
      const meta = file.meta;
      // If file is uploaded and processed but not available at mux, show spinning retry icon
      if (!meta?.mux?.playback_ids?.[0]?.id) {
        return (
          <div className="c-media__gallery-thumb c-media__gallery-thumb-empty c-file-upload--uploading">
            <Icon id="database-error"></Icon>
          </div>
        );
      }

      const playbackId = meta.mux.playback_ids[0].id;
      const muxThumb = `https://image.mux.com/${playbackId}/thumbnail.jpg?width=150&height=150&fit_mode=crop&time=35`;

      return (
        <>
          <Icon id="play" fillColor="color-neutral-x-light" />
          <img
            key={imgKey}
            src={muxThumb}
            alt={file.filename}
            style={!ready ? { display: 'none' } : { display: 'block' }}
            onLoad={handleImageLoaded}
            onError={handleImageError}
            onMouseDown={e => e.preventDefault()}
          />
        </>
      );
    default:
      return <></>;
  }
};
