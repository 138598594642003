import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, TabPanelBody } from 'components/tabs';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import Tabs from 'components/tabs/Tabs';
import { useHistory, useParams } from 'react-router-dom';
import ListHeader from 'components/list/ListHeader';
import ListColumnTitle from 'components/list/ListColumnTitle';
import { sort } from 'utils/sort';
import { useTestSetContext } from 'contexts/TestSetContext';
import TestSetListItem from 'containers/pages/settings/testsets/TestSetListItem';

function RehabTemplateList({}) {
  const { push } = useHistory();
  const { testSetId } = useParams();
  const { state, loading } = useTestSetContext();
  const [testSetGroups, setTestSetGroups] = useState({ mine: [], default: [] });

  useEffect(() => {
    if (state.testSets.length > 0) {
      const myTestSets = sort(
        groupTestSetsByInjury(
          state.testSets.filter(testSet => testSet.editable)
        ),
        {
          keys: [{ key: 'title' }]
        }
      );
      const defaultTestSets = sort(
        groupTestSetsByInjury(
          state.testSets.filter(testSet => !testSet.editable)
        ),
        {
          keys: [{ key: 'title' }]
        }
      );

      setTestSetGroups({ mine: myTestSets, default: defaultTestSets });
    }
  }, [state.testSets, loading]);

  return (
    <Tabs>
      <TabList>
        <Tab>
          <FormattedMessage {...messages.myTestSets} />
        </Tab>
        <Tab>
          <FormattedMessage {...messages.defaultTestSets} />
        </Tab>
      </TabList>
      <TabPanel>
        <TabPanelBody withScroller>
          {testSetGroups.mine.length > 0 &&
            testSetGroups.mine.map((injury, index) => (
              <List key={injury.id}>
                {injury.title && (
                  <ListHeader oneTitle>
                    <ListColumnTitle>{injury.title}</ListColumnTitle>
                  </ListHeader>
                )}
                <ListBody>
                  {injury.children.map(testSet => (
                    <TestSetListItem
                      key={testSet.id}
                      testSet={testSet}
                      push={push}
                      active={testSetId === testSet.id}
                    />
                  ))}
                </ListBody>
              </List>
            ))}
        </TabPanelBody>
      </TabPanel>
      <TabPanel>
        <TabPanelBody withScroller>
          {testSetGroups.default.length > 0 &&
            testSetGroups.default.map((injury, index) => (
              <List key={injury.id}>
                {injury.title && (
                  <ListHeader oneTitle>
                    <ListColumnTitle>{injury.title}</ListColumnTitle>
                  </ListHeader>
                )}
                <ListBody>
                  {injury.children.map(testSet => (
                    <TestSetListItem
                      key={testSet.id}
                      testSet={testSet}
                      push={push}
                      active={testSetId === testSet.id}
                    />
                  ))}
                </ListBody>
              </List>
            ))}
        </TabPanelBody>
      </TabPanel>
    </Tabs>
  );
}

const groupTestSetsByInjury = testSets => {
  return testSets.reduce((accum, testSet) => {
    const injury = accum.find(a => a.id === testSet.injury?.id);
    if (injury) {
      injury.children.push(testSet);
      return accum;
    }
    return [
      ...accum,
      {
        ...(testSet.injury || { ...{ id: 'default', name: 'Default' } }),
        children: [testSet]
      }
    ];
  }, []);
};

export default RehabTemplateList;
