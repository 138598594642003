import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Panel from 'components/panel/Panel';
import PanelBody from 'components/panel/PanelBody';
import { Card, CardBody } from 'components/card';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import { PanelTools } from 'components/panel';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper,
  ListItemStatus
} from 'components/list/list-item';
import messages from 'messages';
import { FormattedMessage, useIntl } from 'react-intl';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import PersonFeedbackCard from 'containers/partials/cards/PersonFeedbackCard';
import { ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_ATHLETES_PERSON } from 'routes/RouteList';
import AssignSportersToProgramModal from './AssignSportersToProgramModal';
import { getNamePrefix } from 'utils/name-prefix';
import FieldInput from 'components/input/FieldInput';
import Person from 'models/Person';

const ProgramAthletesPanel = ({ entityId, program, personId }) => {
  const intl = useIntl();
  let history = useHistory();
  const [person, setPerson] = useState(null);
  const [persons, setPersons] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [modalData, setModalData] = useState(null);

  const onPersonSelectHandler = p => {
    setPerson(p);
    history.push(
      ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_ATHLETES_PERSON.replace(
        ':entityId',
        entityId
      )
        .replace(':programId', program.id)
        .replace(':personId', p.id)
    );
  };

  useEffect(() => {
    if (program.personEntities) {
      const personEntities = program.personEntities.filter(
        sporter =>
          sporter.person.firstname
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          sporter.person.lastname
            .toLowerCase()
            .includes(searchInput.toLowerCase())
      );
      const persons = personEntities.map(sporter => new Person(sporter.person));
      setPersons(persons);
    }
  }, [searchInput, program.personEntities]);

  useEffect(() => {
    if (personId && program.personEntities.length) {
      const personEntity = program.personEntities.find(
        p => p.person.id === personId
      );
      setPerson(personEntity.person);
    }
  }, [personId, program.personEntities]);

  const onSearchHandler = value => {
    setSearchInput(value);
  };

  return (
    <Fragment>
      <Panel>
        <PanelBody>
          <Card secondary>
            <CardHeader secondary>
              <CardHeaderText>
                <CardHeaderTitle>
                  <FormattedMessage {...messages.programDetailAthletesTitle} />
                </CardHeaderTitle>
              </CardHeaderText>
              <PanelTools>
                <FieldInput
                  rounded
                  placeholder={intl.formatMessage(
                    messages.exercisesAssignUserSearchPlaceholder
                  )}
                  onChange={e => onSearchHandler(e.target.value)}
                  icon="search-grey"
                  iconLeft
                />
                <Button rounded primary onClick={() => setModalData(true)}>
                  <Icon id="add-white" />
                  <FormattedMessage {...messages.buttonAddUser} />
                </Button>
              </PanelTools>
            </CardHeader>

            <CardBody secondary>
              <List>
                <ListBody>
                  {persons.length > 0 &&
                    persons.map(person => (
                      <ListItem
                        active={person.id === personId}
                        key={person.id}
                        onClick={() => onPersonSelectHandler(person)}
                      >
                        <ListItemStatus>
                          {getNamePrefix(person.firstname, person.lastname)}
                        </ListItemStatus>
                        <ListItemLabelWrapper>
                          <ListItemLabel label>
                            {person.firstname} {person.lastname}
                          </ListItemLabel>
                        </ListItemLabelWrapper>
                      </ListItem>
                    ))}
                </ListBody>
              </List>
            </CardBody>
          </Card>
        </PanelBody>
      </Panel>

      <Panel>
        <PanelBody>
          {person ? (
            <PersonFeedbackCard programId={program.id} person={person} />
          ) : (
            <Card centered>
              <CardBody empty>
                <Message emptyState={true} icon="cursor">
                  <MessageText>
                    <FormattedMessage {...messages.programAthletesEmpty} />
                  </MessageText>
                </Message>
              </CardBody>
            </Card>
          )}
        </PanelBody>
      </Panel>
      {modalData && (
        <AssignSportersToProgramModal
          entityId={entityId}
          program={program}
          excludedPersons={persons}
          onClose={() => setModalData(null)}
          onDone={() => setModalData(null)}
        />
      )}
    </Fragment>
  );
};

export default ProgramAthletesPanel;
