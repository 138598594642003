import React, { useEffect, useState } from 'react';
import Panel from 'components/panel/Panel';
import CardBody from 'components/card/CardBody';
import Card from 'components/card/Card';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderText
} from 'components/card/card-header';
import FileUppyWrapper from 'containers/partials/file-upload/FileUppyWrapper';
import { FILE_TYPES } from 'constants.js';
import PanelHeader from 'components/panel/PanelHeader';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelBody from 'components/panel/PanelBody';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import Input from 'components/input/Input';
import useFile from 'hooks/queries/useFile';
import InputWrapper from 'components/input/InputWrapper';
import Button from 'components/button/Button';
import InputErrorMessage from 'components/input/InputErrorMessage';
import useFiles from 'hooks/queries/useFiles';
import classNames from 'classnames';
import Icon from 'components/icon/Icon';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { QUERY_MOVE_FILE_TO_VIDEOANALYSIS } from 'services/aws/videos-query';
import { useMutation } from '@apollo/client';
import VirtualizedList from 'containers/partials/lists/VirtualizedList';
import RemovePersonFileButton from 'containers/partials/buttons/RemovePersonFileButton';
import { Tab, TabList, TabPanel } from 'components/tabs';
import Tabs from 'components/tabs/Tabs';
import { useParams } from 'react-router-dom';
import MetaWrapper from 'containers/partials/list-items/MetaWrapper';

const tempLinkId = '99999';

function UploadVideo() {
  const { entityId } = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [fileId, setFileId] = useState('');
  const { file, error, getFile } = useFile();
  const { files, getFiles } = useFiles({ entityId, linkId: tempLinkId });

  const [startAnalysis, { data }] = useMutation(
    QUERY_MOVE_FILE_TO_VIDEOANALYSIS
  );

  useEffect(() => {
    getFiles();
  }, []);

  useEffect(() => {
    if (file?.id) {
      setFileId(file.id);
    }
  }, [file]);

  const onFilesUploaded = async files => {
    if (files.length > 0) {
      setFileId(files[0].id);

      const { data } = await startAnalysis({
        variables: { fileId: files[0].id }
      });
    }
  };

  const onFileDeleted = async fileId => {
    await getFiles();
  };

  return (
    <Panel>
      <PanelHeader>
        <PanelTitleWrapper>
          <PanelTitle>Upload video</PanelTitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        <Card secondary multiBody>
          <CardHeader secondary extraClassNames="u-margin-bottom-none">
            <CardHeaderText>
              <CardHeaderTitle>Upload Video</CardHeaderTitle>
            </CardHeaderText>
          </CardHeader>
          <CardBody secondary separatorBottom>
            {/* MEDIA */}
            {/* IGNORED STYLING!!! >  */}
            <div
              className={classNames(
                'c-file-upload-wrapper c-file-upload-wrapper--one-image',
                'c-file-upload--state',
                {
                  'c-file-upload-wrapper--one-image': true
                }
              )}
            >
              {/* http://localhost:3000/test/66658819-d06c-4bfe-be9b-a3c68e0c79f9/upload */}
              <div className="c-media">
                <FileUppyWrapper
                  entityId={entityId}
                  linkId={tempLinkId}
                  type={'video_analysis'}
                  accept={[...FILE_TYPES.VIDEO]}
                  onFilesUploaded={onFilesUploaded}
                  maxFiles={1}
                  isPublic
                >
                  <div className="c-media__empty-message">
                    <Icon
                      id="no-img"
                      hugeIcon
                      fillColor="color-neutral"
                      extraClassNames="u-margin-auto"
                    />
                    <FormattedMessage {...messages.videoUpload} />
                  </div>
                </FileUppyWrapper>
              </div>
            </div>
          </CardBody>
          <CardHeader secondary extraClassNames="u-margin-bottom-none">
            <CardHeaderText>
              <CardHeaderTitle>Get file url by video ID</CardHeaderTitle>
            </CardHeaderText>
          </CardHeader>
          <CardBody secondary separatorBottom>
            <div>
              <InputWrapper>
                <Input
                  value={fileId}
                  placeholder={'Enter video ID'}
                  onChange={e => setFileId(e.target.value)}
                />
                <Button
                  rounded
                  primary
                  onClick={() => {
                    getFile(fileId);
                  }}
                >
                  Get URL
                </Button>
              </InputWrapper>
              {error && (
                <InputErrorMessage>{error.toString()}</InputErrorMessage>
              )}
              <Input disabled value={file?.signedDownloadUrl ?? ''} />
            </div>
          </CardBody>
          <CardHeader secondary extraClassNames="u-margin-bottom-none">
            <CardHeaderText>
              <CardHeaderTitle>Uploaded files</CardHeaderTitle>
            </CardHeaderText>
          </CardHeader>
          <CardBody secondary>
            <Tabs
              selectedIndex={tabIndex}
              onSelect={index => setTabIndex(index)}
            >
              <TabList>
                <Tab>analyse videos</Tab>
                <Tab>test videos</Tab>
              </TabList>
              <TabPanel>
                <VirtualizedList
                  items={files.filter(
                    file => file.type === 'video_analysis_video'
                  )}
                  titleLabel={'filename'}
                  subtitleLabel={'id'}
                  entityId={entityId}
                  onClick={entity => getFile(entity.id)}
                  // type={`files`} // TODO: Maarten, volgens mij is dit niet nodig.
                  meta={
                    <MetaWrapper>
                      <RemovePersonFileButton
                        key={1}
                        entityId={entityId}
                        onDeleted={onFileDeleted}
                      />
                    </MetaWrapper>
                  }
                />
              </TabPanel>
              <TabPanel>
                <VirtualizedList
                  items={files.filter(file => file.type === 'testdata_video')}
                  titleLabel={'filename'}
                  subtitleLabel={'id'}
                  entityId={entityId}
                  onClick={entity => getFile(entity.id)}
                  meta={
                    <MetaWrapper>
                      <RemovePersonFileButton
                        entityId={entityId}
                        onDeleted={onFileDeleted}
                      />
                    </MetaWrapper>
                  }
                />
              </TabPanel>
            </Tabs>
          </CardBody>
        </Card>
      </PanelBody>
    </Panel>
  );
}

export default UploadVideo;
