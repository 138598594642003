import React from 'react';
import classNames from 'classnames';

const TableHeader = props => {
  return (
    <thead className={classNames('c-table__header', props.extraClassNames)}>
      {props.children}
    </thead>
  );
};

export default TableHeader;
