import gql from 'graphql-tag';

export const MUTATION_ADD_NOTE = gql`
  mutation addNote(
    $linkId: ID
    $linkId2: ID
    $linkId3: ID
    $note: String
    $noteType: NoteType
    $meta: AWSJSON
  ) {
    addNote(
      input: {
        linkId: $linkId
        linkId2: $linkId2
        linkId3: $linkId3
        note: $note
        noteType: $noteType
        meta: $meta
      }
    ) {
      id
      linkId
      linkId2
      linkId3
      note
      noteType
      meta
      date_created
    }
  }
`;

export const MUTATION_EDIT_NOTE = gql`
  mutation editNote(
    $id: ID!
    $linkId: ID
    $linkId2: ID
    $linkId3: ID
    $note: String
    $noteType: NoteType
    $meta: AWSJSON
  ) {
    editNote(
      input: {
        id: $id
        linkId: $linkId
        linkId2: $linkId2
        linkId3: $linkId3
        note: $note
        noteType: $noteType
        meta: $meta
      }
    ) {
      id
      linkId
      linkId2
      linkId3
      note
      noteType
      meta
      date_created
    }
  }
`;

export const MUTATION_ARCHIVE_NOTE = gql`
  mutation archiveNote($noteId: ID!) {
    archiveNote(noteId: $noteId)
  }
`;
