import {
  ListItem,
  ListItemStatus,
  ListItemLabelWrapper,
  ListItemLabel
} from 'components/list/list-item';
import { Button, ButtonsGroup } from 'components/button';
import Icon from 'components/icon/Icon';
import RemoveSessionTemplateButton from '../buttons/RemoveSessionTemplateButton';

export const SessionTemplateListItem = ({
  entityId,
  active,
  template,
  onClick,
  id,
  checkbox,
  onChange,
  checked,
  visible,
  noMaxHeight,
  onEdit,
  onUse
}) => {
  return (
    <ListItem
      noMaxHeight={noMaxHeight}
      checkbox={checkbox}
      active={active}
      onClick={onClick}
    >
      <ListItemStatus
        statusIcon="group"
        visible={visible}
        onChange={onChange}
        checked={checked}
        itemId={id}
      />
      <ListItemLabelWrapper>
        <ListItemLabel label>{template.title}</ListItemLabel>
        <ListItemLabel info></ListItemLabel>
        <div className="c-list__item-small-editing-area">
          <ButtonsGroup>
            <Button
              tiny
              onClick={e => onUse(id)}
              extraClassNames="u-margin-right-tiny"
            >
              <Icon id="use-template" smallMediumIcon />
            </Button>
            <Button tiny onClick={e => onEdit(id)}>
              <Icon id="edit" />
            </Button>
            <RemoveSessionTemplateButton
              tiny
              testSetId={id}
              entityId={entityId}
            />
          </ButtonsGroup>
        </div>
      </ListItemLabelWrapper>
    </ListItem>
  );
};
