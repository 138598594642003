import gql from 'graphql-tag';

export const QUERY_GET_PROGRAMS = gql`
  query getExercisePrograms($entityId: ID!) {
    getExercisePrograms(entityId: $entityId) {
      id
      entityId
      title
      startdate
      duration
      description
      persons {
        id
      }
    }
  }
`;

export const QUERY_GET_PROGRAMS_BY_PERSON = gql`
  query getExerciseProgramsByPerson($entityId: ID!, $personId: ID!) {
    getExerciseProgramsByPerson(entityId: $entityId, personId: $personId) {
      id
      entityId
      title
      startdate
      duration
      description
      persons {
        id
      }
    }
  }
`;

export const QUERY_GET_PROGRAM = gql`
  query getExerciseProgram($id: ID!) {
    getExerciseProgram(programId: $id) {
      id
      entityId
      title
      startdate
      personEntities {
        id
        person {
          id
          firstname
          lastname
        }
      }
      duration
      description
      exercises {
        id
        parentId
        title
        level
        note
        cues
        protocol
        tags {
          id
          label
        }
        media {
          id
          filename
          signedDownloadUrl
          linkId
          status
          meta
          type
        }
      }
      notes {
        id
        linkId
        linkId2
        linkId3
        note
        noteType
        date_created
      }
    }
  }
`;

export const MUTATION_ADD_PROGRAM = gql`
  mutation addExerciseProgram(
    $entityId: ID!
    $title: String!
    $startdate: AWSDate
    $duration: Int
    $description: String
    $exerciseIds: [ID]
  ) {
    addExerciseProgram(
      input: {
        entityId: $entityId
        title: $title
        startdate: $startdate
        duration: $duration
        description: $description
        exerciseIds: $exerciseIds
      }
    ) {
      id
      title
    }
  }
`;

export const MUTATION_EDIT_PROGRAM = gql`
  mutation editExerciseProgram(
    $id: ID!
    $entityId: ID!
    $title: String!
    $startdate: AWSDate
    $duration: Int
    $description: String
    $exerciseIds: [ID]
  ) {
    editExerciseProgram(
      input: {
        id: $id
        entityId: $entityId
        title: $title
        startdate: $startdate
        duration: $duration
        description: $description
        exerciseIds: $exerciseIds
      }
    ) {
      id
      title
    }
  }
`;

export const MUTATION_ADD_PERSONS_TO_PROGRAM = gql`
  mutation addPersonsToExerciseProgram(
    $exerciseProgramId: ID!
    $personIds: [ID]
  ) {
    addPersonsToExerciseProgram(
      exerciseProgramId: $exerciseProgramId
      personIds: $personIds
    ) {
      id
    }
  }
`;

export const MUTATION_REMOVE_PERSONS_FROM_PROGRAM = gql`
  mutation removePersonsFromExerciseProgram(
    $exerciseProgramId: ID!
    $personIds: [ID]!
  ) {
    removePersonsFromExerciseProgram(
      exerciseProgramId: $exerciseProgramId
      personIds: $personIds
    ) {
      id
    }
  }
`;

export const MUTATION_ARCHIVE_PROGRAM = gql`
  mutation archiveExerciseProgram($programId: ID!) {
    archiveExerciseProgram(exerciseProgramId: $programId)
  }
`;

export const GET_PROGRAM_EXERCISE_FEEDBACK = gql`
  query getExerciseProgramExerciseFeedback($programId: ID!, $personId: ID!) {
    getExerciseProgramExerciseFeedback(
      programId: $programId
      personId: $personId
    ) {
      id
      linkId
      linkId2
      linkId3
      note
      noteType
      date_created
    }
  }
`;
