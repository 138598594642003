import React, { createContext, useReducer, useContext, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_EXERCISES } from 'services/aws/exercises-query';
import { getFilteredEntities } from 'utils/search';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import Exercise from 'models/Exercise';

export const ExercisesContext = createContext();

const initialState = {
  exercises: [],
  exercisesStore: [],
  selectedExercises: [],
  unselectedExercises: [],
  filter: {
    search: '',
    tags: [],
    personId: null
  }
};

// Actions
export const SET_EXERCISES = 'SET_EXERCISES';
export const SET_EXERCISES_STORE = 'SET_EXERCISES_STORE';
export const SET_SELECTED_EXERCISES = 'SET_SELECTED_EXERCISES';

export const ADD_SELECTED_EXERCISES = 'ADD_SELECTED_EXERCISES';

export const SET_FILTER = 'SET_FILTER';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_EXERCISES:
      return {
        ...state,
        exercises: sort(
          [...action.payload].map(e => new Exercise({ ...e })),
          {
            keys: [
              {
                key: 'level',
                dataType: SORT_DATA_TYPES.NUMBER
              },
              {
                key: 'title'
              }
            ]
          }
        )
      };
    case SET_EXERCISES_STORE:
      return {
        ...state,
        exercisesStore: [...action.payload].map(e => new Exercise({ ...e }))
      };
    case SET_SELECTED_EXERCISES:
      const unDoubled = [];
      action.payload.forEach(exercise => {
        if (!unDoubled.find(t => t.id === exercise.id)) {
          unDoubled.push(exercise);
        }
      });
      return { ...state, selectedExercises: [...unDoubled] };
    case ADD_SELECTED_EXERCISES:
      const exercisesNotInSelected = action.payload.filter(
        exercise => !state.selectedExercises.find(t => t.id === exercise.id)
      );
      return {
        ...state,
        selectedExercises: [
          ...state.selectedExercises,
          ...exercisesNotInSelected
        ]
      };
    case SET_FILTER:
      return { ...state, filter: { ...action.payload } };
    default:
      return state;
  }
};

const ExercisesProvider = ({ entityId, children }) => {
  const [exercisesState, dispatch] = useReducer(reducer, initialState);

  const [getExercises, { loading, error, data }] = useLazyQuery(
    QUERY_GET_EXERCISES,
    {
      variables: { entityId }
    }
  );

  useEffect(() => {
    if (data?.getExercises) {
      exerciseActions.setExercises(data.getExercises);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (exercisesState.filter) {
      const filteredExercises = getFilteredEntities(
        exercisesState.exercisesStore,
        exercisesState.filter.search ?? '',
        exercisesState.filter.tags ?? null,
        exercisesState.filter.personId ?? null
      );
      dispatch({ type: SET_EXERCISES, payload: filteredExercises });
    }
  }, [exercisesState.exercisesStore, exercisesState.filter]);

  const exerciseActions = {
    getExercises: () => getExercises(),
    setAllExercises: exercises => {
      dispatch({ type: SET_EXERCISES_STORE, payload: exercises });
    },
    setExercises: exercises => {
      if (!exercises && data?.getExercises) {
        dispatch({ type: SET_EXERCISES, payload: data.getExercises });
        dispatch({ type: SET_EXERCISES_STORE, payload: data.getExercises });
      }
      if (exercises) {
        dispatch({ type: SET_EXERCISES, payload: exercises });
        dispatch({ type: SET_EXERCISES_STORE, payload: exercises });
      }
    },
    updateExercises: exercises => {
      const exercisesInSelected = exercises.filter(exercise =>
        exercisesState.selectedExercises.find(t => t.id === exercise.id)
      );

      // get all new exercises
      const newExercises = exercises.filter(
        exercise =>
          ![...exercisesState.selectedExercises].find(t => t.id === exercise.id)
      );

      dispatch({
        type: SET_SELECTED_EXERCISES,
        payload: [...exercisesInSelected, ...newExercises]
      });
    },
    setSelectedExercises: exercises => {
      if (exercises) {
        dispatch({ type: SET_SELECTED_EXERCISES, payload: exercises });

        return exercises;
      }
    },
    selectExercises: exercises => {
      if (exercises) {
        dispatch({ type: ADD_SELECTED_EXERCISES, payload: exercises });
      }
    },
    unselectExercises: exercisesToRemove => {
      if (exercisesToRemove) {
        const exercises = exercisesState.selectedExercises.filter(
          exercise => !exercisesToRemove.find(t => t.id === exercise.id)
        );

        dispatch({ type: SET_SELECTED_EXERCISES, payload: [...exercises] });

        return exercises;
      }
    },
    setFilter: filter => {
      dispatch({
        type: SET_FILTER,
        payload: { ...exercisesState.filter, ...filter }
      });
    }
  };

  return (
    <ExercisesContext.Provider
      value={{
        exercisesState,
        exerciseActions,
        loading,
        error
      }}
    >
      {children}
    </ExercisesContext.Provider>
  );
};

function useExercisesContext() {
  const context = useContext(ExercisesContext);
  if (context === undefined) {
    throw new Error(
      'The ExercisesContext hook must be used within a ExercisesContext.Provider'
    );
  }
  return context;
}

export { ExercisesProvider, useExercisesContext };
