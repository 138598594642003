import React, { Component } from 'react';
import classNames from 'classnames';

import PropTypes from 'prop-types';

class NavigationHeader extends Component {
  render() {
    return (
      <h3 className={classNames('c-nav__header')} {...this.props}>
        {this.props.children}
      </h3>
    );
  }
}

NavigationHeader.propTypes = {
  icon: PropTypes.string
};

export default NavigationHeader;
