import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION_EDIT } from 'routes/RouteList';

import messages from 'messages';

import Icon from 'components/icon/Icon';
import Button from 'components/button/Button';

import { Card, CardFooter } from 'components/card';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderButtons
} from 'components/card/card-header';
import { isValueValidNumber } from 'utils/form';
import FieldInput from 'components/input/FieldInput';

class CalibrationForm extends React.Component {
  render() {
    const {
      scannerId,
      entityId,
      calibrationId,
      edit,
      onChange,
      onBlur,
      onSave,
      onReset,
      loading,
      name,
      date,
      shim,
      coil,
      routing: { push },
      ...props
    } = this.props;
    const isValidToSubmit = () =>
      name && date && isValueValidNumber(coil) && isValueValidNumber(shim);
    return (
      <Card secondary>
        <CardHeader secondary>
          <CardHeaderTitle>
            <FormattedMessage {...messages.scannerCalibrationTitleDetails} />
          </CardHeaderTitle>
          <CardHeaderButtons>
            {edit ? (
              <Button
                type="submit"
                tiny
                disabled={loading || !isValidToSubmit()}
                onClick={e => {
                  e.preventDefault();
                  onSave();
                }}
              >
                <FormattedMessage {...messages.calibrationButtonSubmit} />
              </Button>
            ) : (
              <Button
                tiny
                onClick={e => {
                  e.preventDefault();
                  push(
                    `${ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION_EDIT.replace(
                      ':entityId',
                      entityId
                    )
                      .replace(':calibrationId', calibrationId)
                      .replace(':scannerId', scannerId)}`
                  );
                }}
              >
                <Icon id="edit" />
              </Button>
            )}
          </CardHeaderButtons>
        </CardHeader>
        <FieldInput
          id="name"
          value={name}
          onBlur={onBlur}
          onChange={onChange}
          readOnly={!edit}
          icon={props['nameError'] ? 'validation-error' : ' '}
        >
          <FormattedMessage {...messages.calibrationNameLabel} />*
        </FieldInput>

        <FieldInput
          id="date"
          value={date}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={!edit}
          disabled={!edit}
          type="date"
          required
          icon={props['dateError'] ? 'validation-error' : ' '}
        >
          <FormattedMessage {...messages.calibrationDateLabel} />*
        </FieldInput>

        <FieldInput
          id="shim"
          value={shim}
          pattern="\d+(\.\d*)?"
          onChange={e => onChange(e, 'number')}
          onBlur={e => onBlur(e, 'number')}
          readOnly={!edit}
          icon={props['shimError'] ? 'validation-error' : ' '}
        >
          <FormattedMessage {...messages.calibrationShimLabel} />*
        </FieldInput>

        <FieldInput
          id="coil"
          value={coil}
          pattern="\d+(\.\d*)?"
          onChange={e => onChange(e, 'number')}
          onBlur={e => onBlur(e, 'number')}
          readOnly={!edit}
          icon={props['coilError'] ? 'validation-error' : ' '}
        >
          <FormattedMessage {...messages.calibrationCoilLabel} />*
        </FieldInput>

        <CardFooter secondary>
          {edit && (
            <Button
              secondary
              disabled={loading}
              onClick={e => {
                e.preventDefault();
                onReset();
              }}
            >
              <FormattedMessage {...messages.scannerButtonCancel} />
            </Button>
          )}
          {edit && (
            <Button
              type="submit"
              primary
              onClick={e => {
                e.preventDefault();
                onSave();
              }}
              disabled={loading || !isValidToSubmit()}
            >
              <FormattedMessage {...messages.scannerButtonSubmit} />
            </Button>
          )}
        </CardFooter>
      </Card>
    );
  }
}

export default withRouter(
  injectIntl(inject('routing', 'authStore', 'uiState')(CalibrationForm))
);
