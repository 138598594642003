import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import GrowthPredictionTableCell from './GrowthPredictionTableCell';
import { TableCell, TableRow } from 'components/table';
import { GrowthPredictionPanelConsumer } from '../GrowthPredictionPanel';
import { ProgressBlockValueTypes } from 'components/progress-bar/ProgressBlock';
import messages from 'messages';
import differenceInWeeks from 'date-fns/differenceInWeeks';

class GrowthPredictionTableRow extends Component {
  render() {
    const { row, intl } = this.props;

    let heightEvolution =
      row.data.heightEvolution[row.data.heightEvolution.length - 1];
    let weightEvolution =
      row.data.weightEvolution[row.data.weightEvolution.length - 1];
    const khamisRoche = row.data.khamisRoche;

    if (!heightEvolution) {
      heightEvolution = {};
    }
    if (!weightEvolution) {
      weightEvolution = {};
    }

    const data = [
      {
        range:
          ProgressBlockValueTypes[
            `VALUE_${
              heightEvolution.alertLevel !== 'undefined'
                ? heightEvolution.alertLevel
                : ''
            }`
          ],
        value:
          typeof heightEvolution.growthYear !== 'undefined'
            ? heightEvolution.growthYear.toFixed(2)
            : null,
        unit:
          typeof heightEvolution.growthYear !== 'undefined'
            ? `cm/${intl.formatMessage(messages.growthPredictionYear)}`
            : '',
        date: new Date(heightEvolution.testdate),
        weekDiff: differenceInWeeks(
          new Date(),
          new Date(heightEvolution.testdate)
        ),
        alert:
          differenceInWeeks(new Date(), new Date(heightEvolution.testdate)) >
          10,
        noClick: false
      },
      {
        range:
          ProgressBlockValueTypes[
            `VALUE_${
              weightEvolution.alertLevel !== 'undefined'
                ? weightEvolution.alertLevel
                : ''
            }`
          ],
        value:
          typeof weightEvolution.growthYear !== 'undefined'
            ? weightEvolution.growthYear.toFixed(2)
            : null,
        unit:
          typeof weightEvolution.growthYear !== 'undefined'
            ? `kg/${intl.formatMessage(messages.growthPredictionYear)}`
            : null,
        date: new Date(weightEvolution.testdate),
        weekDiff: differenceInWeeks(
          new Date(),
          new Date(weightEvolution.testdate)
        ),
        alert:
          differenceInWeeks(new Date(), new Date(weightEvolution.testdate)) >
          10,
        noClick: false
      },
      {
        range: ProgressBlockValueTypes[`VALUE_${khamisRoche.alertLevel}`],
        value: khamisRoche.khamisRochePercentageAHP
          ? khamisRoche.khamisRochePercentageAHP.toFixed(2)
          : null,
        unit: khamisRoche.khamisRochePercentageAHP ? '%' : '',
        date: new Date(weightEvolution.testdate),
        weekDiff: differenceInWeeks(new Date(), new Date(khamisRoche.testdate)),
        alert:
          differenceInWeeks(new Date(), new Date(khamisRoche.testdate)) > 10,
        noClick: true
      }
    ];

    return (
      <GrowthPredictionPanelConsumer>
        {({ onClickCell }) => (
          <TableRow>
            <TableCell mainCell id={row.label}>
              {row.label}
            </TableCell>
            {data.map((item, i) => (
              <GrowthPredictionTableCell
                onClick={() => {
                  if (item.value) {
                    onClickCell(i, row);
                  }
                }}
                data={item}
                key={i}
              />
            ))}
          </TableRow>
        )}
      </GrowthPredictionPanelConsumer>
    );
  }
}

export default withRouter(
  injectIntl(inject('routing', 'authStore')(observer(GrowthPredictionTableRow)))
);
