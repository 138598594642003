import React from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FinishedState } from 'enums';
import {
  QUERY_GET_SESSION_BY_ID,
  MUTATION_UPDATE_TEST_SESSION_FINISHED
} from 'services/aws/session-query';
import { ROUTE_SESSIONS_ENTITY_SESSION } from 'routes/RouteList';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';

const FinishSessionButton = ({
  sessionId,
  entityId,
  routing: { push },
  children,
  name,
  testSetId,
  ...props
}) => {
  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_UPDATE_TEST_SESSION_FINISHED}
      variables={{
        id: sessionId,
        entityId: entityId,
        name,
        finished: FinishedState.FINISHED,
        testSetId
      }}
      refetchQueries={[
        {
          query: QUERY_GET_SESSION_BY_ID,
          variables: { testSessionId: sessionId, entityId }
        }
      ]}
      update={(cache, { data: { editTestSession } }) => {
        if (editTestSession) {
          push(
            ROUTE_SESSIONS_ENTITY_SESSION.replace(
              ':entityId',
              entityId
            ).replace(':sessionId', sessionId)
          );
        }
      }}
      confirmTitle={messages.modalConfirmFinishSessionTitle}
      confirmMessage={messages.modalConfirmFinishSessionMessage}
      confirmAcceptLabel={messages.modalConfirmFinishSessionAcceptButton}
    >
      {children}
    </ConfirmMutationButton>
  );
};

export default inject('routing')(withRouter(FinishSessionButton));
