import React, { Component } from 'react';
import classNames from 'classnames';

import PropTypes from 'prop-types';

import Icon from '../icon/Icon';

class NavigationItem extends Component {
  render() {
    const {
      id,
      icon,
      iconSize,
      active,
      secondary,
      signout,
      bottom,
      onClick,
      style,
      ...props
    } = this.props;
    return (
      <button
        id={id}
        className={classNames('c-nav__item', {
          'c-nav__item--secondary': secondary,
          'c-nav__item--bottom': bottom,
          'c-nav__item--signout': signout,
          'is-active': active
        })}
        onClick={onClick}
        style={style}
      >
        {icon && (
          <div className="c-nav__icon">
            <Icon
              id={icon}
              fillColor={
                active ? 'color-neutral-xxx-light' : 'color-neutral-steel'
              }
              mediumIcon={!iconSize}
              hugeIcon={iconSize === 'huge'}
            />
          </div>
        )}
        <span className={classNames('c-nav__item-description')}>
          {props.children}
        </span>
      </button>
    );
  }
}

NavigationItem.propTypes = {
  icon: PropTypes.string,
  iconColor: PropTypes.string
};

export default NavigationItem;
