import { useState, useEffect } from 'react';
import { Card, CardBody } from 'components/card';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { Button, ButtonsGroup } from 'components/button';
import List from 'components/list/List';
import ListHeader from 'components/list/ListHeader';
import ListColumnTitle from 'components/list/ListColumnTitle';
import ListBody from 'components/list/ListBody';
import { useQuery } from '@apollo/client';
import { TestSetV2Type } from 'constants.js';
import { useFormikContext } from 'formik';
import { QUERY_GET_INJURIES } from 'services/aws/injury-query';
import Badge from 'components/badge/Badge';
import InjuryListItem from 'containers/partials/list-items/InjuryListItem';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import useSelectable from 'hooks/utils/useSelectable';
import { useUIDSeed } from 'react-uid';

function PreventionSelectInjuriesCard({ entityId, edit }) {
  const { values: session, setFieldValue } = useFormikContext();
  const [injuries, setInjuries] = useState([]);
  const [injurySelection, setInjurySelection] = useState({});
  const { selected, select } = useSelectable();
  const uidSeed = useUIDSeed();

  const { data } = useQuery(QUERY_GET_INJURIES, {
    variables: {
      entityId,
      types: [TestSetV2Type.PREVENTION],
      sportId: session?.sport?.id
    }
  });

  useEffect(() => {
    if (data?.getInjuries) {
      const injuriesData = data.getInjuries.map(injury => {
        const selection = (session?.injuryIds &&
          session.injuryIds[injury.id]) || {
          left: false,
          right: false,
          noside: false
        };
        return { ...injury, selection };
      });
      setInjuries(injuriesData);
    }
  }, [data, session]);

  useEffect(() => {
    if (injuries.length > 0) {
      setInjurySelection(session.injuryIds);
    }
  }, [injuries]);

  const onSelectInjurySideChangeHandler = injury => {
    const injuriesSelection = { ...injurySelection, ...injury };
    const filteredSelection = Object.keys(injuriesSelection)
      .filter(
        key =>
          injuriesSelection[key].left ||
          injuriesSelection[key].right ||
          injuriesSelection[key].noside
      )
      .reduce((obj, key) => {
        obj[key] = injuriesSelection[key];
        return obj;
      }, {});

    setInjurySelection(filteredSelection);
    setFieldValue('injuryIds', filteredSelection);
  };

  return (
    <>
      <Card secondaryCollapsible>
        <CardHeader secondary>
          <CardHeaderTitle extraClassNames="u-margin-bottom-none">
            <FormattedMessage {...messages.preventionInjuriesTitle} />
          </CardHeaderTitle>
        </CardHeader>
        <CardBody secondary>
          {!edit ? (
            <ButtonsGroup extraClassNames="o-flex--wrap">
              {injurySelection &&
                Object.keys(injurySelection).map(injuryId => {
                  const injury = injuries.find(i => i.id === injuryId);
                  if (!injury) return '';
                  const selection = injurySelection[injuryId];
                  return (
                    <Button
                      rounded
                      white
                      key={injury.id}
                      extraClassNames={
                        injury?.sides &&
                        injury.sides.includes('left') &&
                        injury.sides.includes('right')
                          ? 'u-padding-left-small u-padding-right-tiny'
                          : 'u-padding-horizontal-small'
                      }
                    >
                      {injury.title}

                      {injury?.sides && (
                        <Badge extraClassNames="u-margin-left-small">
                          {selection.left && 'L'}
                          {selection.left && selection.right && ' + '}
                          {selection.right && 'R'}
                        </Badge>
                      )}
                    </Button>
                  );
                })}
            </ButtonsGroup>
          ) : (
            <>
              <List
                preview
                style={{
                  minWidth: '0'
                }}
                extraClassNames="c-list--injuries"
              >
                <ListHeader oneTitle>
                  <ListColumnTitle>
                    <FormattedMessage
                      {...messages.preventionListInjuriesTitle}
                      values={{
                        sport: session?.sport?.title
                      }}
                    />
                  </ListColumnTitle>
                </ListHeader>
                <ListBody>
                  {injuries.map((injury, index) => {
                    if (!injury.id) return null;
                    return (
                      <InjuryListItem
                        id={injury.id}
                        uid={uidSeed(injury.id)}
                        key={injury.id}
                        injury={injury}
                        index={index}
                        checked={selected.includes(injury.id)}
                        onChange={() => {
                          select(injury.id);
                        }}
                        onInjurySelect={onSelectInjurySideChangeHandler}
                      />
                    );
                  })}
                </ListBody>
              </List>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}

export default PreventionSelectInjuriesCard;
