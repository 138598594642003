import React, { useEffect } from 'react';
import Badge from '../badge/Badge';
import { useNotificationQueue } from 'components/notification/index';

function Notification({ id, message, level, duration = 5000 }) {
  const notifications = useNotificationQueue();

  useEffect(() => {
    const timeout = setTimeout(() => {
      notifications.remove(id);
    }, duration);
    return () => {
      clearTimeout(timeout);
    };
  }, [id, duration, notifications.remove]);

  return (
    <>
      <Badge notification key={id} error={level === 'error'} show>
        {message}
      </Badge>
    </>
  );
}

export default Notification;
