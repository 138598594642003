import React, { Fragment } from 'react';
import { inject } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';

import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { Button, ButtonsGroup } from 'components/button';
import { CardBody, CardFooter } from 'components/card';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import { sortAndGroupSessions } from 'utils/sort';
import { SessionListItem } from 'containers/partials/list-items/SessionListItem';
import { QUERY_GET_SESSIONS_BY_ENTITY_ID } from 'services/aws/session-query';
import Session from 'models/Session';
import { PackageTypes } from 'constants.js';
import messages from 'messages';
import Loader from 'components/loader/Loader';

class SelectGroupView extends React.Component {
  state = {
    entityId: this.props.entityId,
    activeSessionId: null,
    activeName: null
  };

  render() {
    const { intl, onCancelHandler, onSubmitHandler } = this.props;
    const { entityId, activeSessionId, activeName } = this.state;
    return (
      <Fragment>
        <CardHeader modal>
          <CardHeaderTitle>
            <FormattedMessage
              {...messages.growthPredictionSubChoiceModalTitle}
            />
          </CardHeaderTitle>
          <CardHeaderSubtitle>
            <FormattedMessage
              {...messages.growthPredictionSubSessionChoiceModalSubtitle}
            />
          </CardHeaderSubtitle>
        </CardHeader>
        <CardBody
          modal
          extraClassNames={'u-padding-vertical-none u-padding-horizontal-small'}
        >
          <List>
            <Query
              query={QUERY_GET_SESSIONS_BY_ENTITY_ID}
              variables={{
                entityId
              }}
              fetchPolicy="cache-and-network"
            >
              {result => {
                const { loading, error, data } = result;

                if (loading) return <Loader />;
                if (error) return <p>Error! {error.message}</p>;
                const sessions = data.getTestSessions.map(s => new Session(s));
                const filteredSessions = sessions.filter(
                  ts => ts.testSet.type === PackageTypes.IDO
                );
                const sortedSessions =
                  sortAndGroupSessions(filteredSessions).reverse();
                return (
                  <ListBody>
                    {sortedSessions.map(session => (
                      <SessionListItem
                        key={session.id}
                        session={session}
                        active={activeSessionId === session.id}
                        onClick={() =>
                          this.setState({
                            activeSessionId: session.id,
                            activeName: session.name
                          })
                        }
                      />
                    ))}
                  </ListBody>
                );
              }}
            </Query>
          </List>
        </CardBody>
        <CardFooter modal>
          <ButtonsGroup>
            <Button secondary onClick={() => onCancelHandler()}>
              <FormattedMessage {...messages.growthPredictionCancelButton} />
            </Button>
            <Button
              disabled={activeSessionId === null}
              primary
              onClick={e => onSubmitHandler(activeSessionId, null, activeName)}
            >
              <FormattedMessage {...messages.growthPredictionViewButton} />
            </Button>
          </ButtonsGroup>
        </CardFooter>
      </Fragment>
    );
  }
}

export default withRouter(
  injectIntl(inject('authStore', 'routing')(SelectGroupView))
);
