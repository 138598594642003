import React from 'react';
import './Icon.scss';
import classNames from 'classnames';
import svgSprite from 'assets/images/sprites.svg';
class Icon extends React.Component {
  render() {
    const {
      id,
      fillColor,
      bgColor,
      strokeColor,
      extraClassNames,
      smallIcon,
      smallMediumIcon,
      mediumIcon,
      hugeIcon,
      xHugeIcon,
      rotateCW90,
      mirrored,
      style
    } = this.props;
    const svgPath = `${svgSprite}#${id}`;

    return (
      <svg
        className={classNames('c-icon', extraClassNames, {
          ['c-icon--fill-' + fillColor]: fillColor,
          ['c-icon--bg-' + bgColor]: bgColor,
          ['c-icon--stroke-' + strokeColor]: strokeColor,
          'c-icon--small': smallIcon,
          'c-icon--small-medium': smallMediumIcon,
          'c-icon--medium': mediumIcon,
          'c-icon--huge': hugeIcon,
          'c-icon--x-huge': xHugeIcon,
          'c-icon--rotate-cw-90': rotateCW90,
          'c-icon--mirrored': mirrored
        })}
        style={style}
      >
        <use xlinkHref={svgPath} />
      </svg>
    );
  }
}

export default Icon;
