import { countries } from 'country-list-json';
import FieldSelect from 'components/input/FieldSelect';

function CountrySelect({ id, name, value, label, readOnly, onSelect }) {
  const fullList = countries.map(c => ({ label: c.name, value: c.code }));
  /*const activeList = countries
    .filter(c => activeCountries.includes(c.code))
    .map(c => ({ label: c.name, value: c.code }));*/

  const selectedValue = fullList.find(l => l.value === value);

  return (
    <FieldSelect
      id={id}
      name={name}
      onChange={select => onSelect(select)}
      value={selectedValue ?? ''}
      readOnly={readOnly}
      options={fullList}
      label={label}
    ></FieldSelect>
  );
}

export default CountrySelect;
