import { useEffect, useState } from 'react';
import Modal from 'components/modal/Modal';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import { FormattedMessage, useIntl } from 'react-intl';
import { CardBody, CardFooter } from 'components/card';
import CardBodyTools from 'components/card/CardBodyTools';
import { Button, ButtonsGroup } from 'components/button';
import messages from 'messages';
import Loader from 'components/loader/Loader';
import ListBody from 'components/list/ListBody';
import List from 'components/list/List';
import useSelectable from 'hooks/utils/useSelectable';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import PropTypes from 'prop-types';
import { useLessonsContext } from 'contexts/LessonsContext';
import LessonsListItem from 'containers/partials/list-items/LessonsListItem';
import FieldInput from 'components/input/FieldInput';

const AddVideosToLessonModal = ({ entityId, onAdd, onClose }) => {
  const intl = useIntl();
  const {
    state: { lessons },
    actions,
    loading
  } = useLessonsContext();
  const ability = useAbility(AbilityContext);
  const [newTemplateId, setNewTemplateId] = useState(null);
  const [lessonsList, setLessonsList] = useState([]);
  const [filter, setFilter] = useState({ search: '', tags: [] });
  const { selected, select } = useSelectable([]);

  useEffect(() => {
    if (actions) {
      actions.getLessons();
      actions.resetFilter();
    }
  }, []);

  useEffect(() => {
    if (lessons) {
      if (newTemplateId) {
        const newTemplate = lessons.find(t => t.id === newTemplateId);
        const filteredTemplates = lessons.filter(t => t.id !== newTemplateId);
        filteredTemplates.unshift(newTemplate);
        setLessonsList(filteredTemplates);
        return;
      }
      setLessonsList(lessons);
    }
  }, [lessons, newTemplateId]);

  const onCloseHandler = () => {
    actions.resetFilter();
    if (onClose) onClose();
  };

  const onAddHandler = () => {
    if (onAdd) onAdd(selected);
  };

  const onSearch = e => {
    setFilter({ ...filter, search: e.target.value });
    actions.setFilter({ search: e.target.value });
  };

  return (
    <Modal card isOpen={true} onClose={onCloseHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.addVideosToLessonModalTitle} />
        </CardHeaderTitle>
        <CardHeaderSubtitle>
          <FormattedMessage {...messages.addVideosToLessonModalSubtitle} />
        </CardHeaderSubtitle>
      </CardHeader>

      <CardBody modal>
        {loading && <Loader />}
        <div className="c-card-modal__body-head">
          <CardBodyTools>
            <FieldInput
              id="title"
              rounded
              placeholder={intl.formatMessage(
                messages.exercisesProgramFilterNameLabel
              )}
              onChange={onSearch}
              icon="search-grey"
              iconLeft
            />
          </CardBodyTools>
        </div>
        <div className="c-card-modal__body-content c-card-modal__body-content-collection">
          <List>
            <ListBody>
              {lessonsList.map(
                lesson =>
                  ability.can('update', lesson) && (
                    <LessonsListItem
                      icon={'program'}
                      checkbox={ability.can('update', lesson)}
                      id={`modal_${lesson.id}`}
                      key={lesson.id}
                      lesson={lesson}
                      entityId={entityId}
                      visible
                      checked={selected.includes(lesson.id)}
                      // onClick={() => onClickHandler(template)}
                      // In this context, a modal, clicking on the element
                      // should also select the list item.
                      onClick={() => {
                        ability.can('update', lesson) && select(lesson.id);
                      }}
                      onChange={() =>
                        ability.can('update', lesson) && select(lesson.id)
                      }
                    />
                  )
              )}
              {lessonsList.length <= 0 && !loading && (
                <li className="u-padding-vertical-large">
                  <Message emptyState={true} icon="cursor">
                    <MessageText>
                      <FormattedMessage {...messages.exercisesListEmpty} />
                    </MessageText>
                  </Message>
                </li>
              )}
            </ListBody>
          </List>
        </div>
      </CardBody>

      <CardFooter modal>
        <ButtonsGroup>
          <Button type="button" secondary onClick={() => onCloseHandler()}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button
            type="button"
            disabled={selected.length === 0}
            primary
            onClick={() => onAddHandler()}
          >
            <FormattedMessage {...messages.addButton} />
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
};

AddVideosToLessonModal.propTypes = {
  entityId: PropTypes.string.isRequired
};

export default AddVideosToLessonModal;
