import React from 'react';
import classNames from 'classnames';

class PageHeaderUser extends React.Component {
  render() {
    const { ...props } = this.props;
    return (
      <div
        className={classNames('c-page__right-item', {
          'c-page__right-item-language': props.langMobile
        })}
      >
        {props.children}
      </div>
    );
  }
}
export default PageHeaderUser;
