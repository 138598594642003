import React, { useContext } from 'react';
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  withRouter
} from 'react-router-dom';
import {
  ROUTE_VIDEOS_ENTITY,
  ROUTE_VIDEOS_ENTITY_VIDEO,
  ROUTE_VIDEOS_ENTITY_VIDEO_ANNOTATE,
  ROUTE_VIDEOS_ENTITY_VIDEO_ANNOTATE_ADD,
  ROUTE_VIDEOS_ENTITY_VIDEO_ANNOTATE_EDIT,
  ROUTE_VIDEOS_ENTITY_VIDEO_EDIT
} from 'routes/RouteList';
import Panel from 'components/panel/Panel';
import messages from 'messages';
import { StoreContext } from 'index';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import { useIntl } from 'react-intl';
import Videos from 'containers/pages/videos/Videos';
import { PreviewModalProvider } from 'contexts/PreviewModalContext';
import Video from 'containers/pages/videos/Video';
import AnnotateVideo from 'containers/pages/videos/AnnotateVideo';
import { VideosProvider } from 'contexts/VideosContext';
import { LessonsProvider } from 'contexts/LessonsContext';
import { EditableContextProvider } from 'contexts/EditableContext';

const VideosIndex = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const ability = useAbility(AbilityContext);
  const intl = useIntl();
  if (ability.cannot('manage', 'Videos')) {
    return <Redirect to={'/'} />;
  }
  return (
    <LessonsProvider entityId={user.rootEntityId}>
      <PreviewModalProvider entityId={user.rootEntityId}>
        <VideosProvider
          user={user}
          entityId={user.rootEntityId}
          personId={user.id}
        >
          <Switch>
            <Route
              exact
              path={ROUTE_VIDEOS_ENTITY}
              render={props => (
                <>
                  <Videos
                    entityId={user.rootEntityId}
                    active={ROUTE_VIDEOS_ENTITY === props.match.path}
                  />
                  <Panel
                    emptyState
                    emptyLabel={intl.formatMessage(messages.videosEmptyPanel)}
                  />
                </>
              )}
            />
            <Route
              exact
              path={[ROUTE_VIDEOS_ENTITY_VIDEO, ROUTE_VIDEOS_ENTITY_VIDEO_EDIT]}
              render={props => (
                <EditableContextProvider>
                  <Videos
                    entityId={props.match.params.entityId}
                    videoId={props.match.params.videoId}
                    active={false}
                    crumbs={[]}
                  />
                  <Video
                    videoId={props.match.params.videoId}
                    entityId={props.match.params.entityId}
                    active={true}
                    edit={ROUTE_VIDEOS_ENTITY_VIDEO_EDIT === props.match.path}
                    editable
                    crumbs={[
                      {
                        path: generatePath(ROUTE_VIDEOS_ENTITY, {
                          entityId: props.match.params.entityId
                        }),
                        label: intl.formatMessage(messages.breadcrumbVideos)
                      },
                      {
                        path: generatePath(ROUTE_VIDEOS_ENTITY_VIDEO, {
                          entityId: props.match.params.entityId,
                          videoId: props.match.params.videoId
                        }),
                        label: intl.formatMessage(messages.breadcrumbVideo)
                      }
                    ]}
                    parentPath={generatePath(ROUTE_VIDEOS_ENTITY_VIDEO, {
                      entityId: props.match.params.entityId,
                      videoId: props.match.params.videoId
                    })}
                  />
                </EditableContextProvider>
              )}
            />

            <Route
              exact
              path={[
                ROUTE_VIDEOS_ENTITY_VIDEO_ANNOTATE,
                ROUTE_VIDEOS_ENTITY_VIDEO_ANNOTATE_ADD,
                ROUTE_VIDEOS_ENTITY_VIDEO_ANNOTATE_EDIT
              ]}
              render={props => (
                <EditableContextProvider>
                  <AnnotateVideo
                    entityId={props.match.params.entityId}
                    annotationId={props.match.params.annotationId}
                    crumbs={[
                      {
                        path: generatePath(ROUTE_VIDEOS_ENTITY, {
                          entityId: props.match.params.entityId
                        }),
                        label: intl.formatMessage(messages.breadcrumbVideos)
                      },
                      {
                        path: generatePath(ROUTE_VIDEOS_ENTITY_VIDEO, {
                          entityId: props.match.params.entityId,
                          videoId: props.match.params.videoId
                        }),
                        label: intl.formatMessage(messages.breadcrumbVideo)
                      }
                    ]}
                    parentPath={generatePath(
                      ROUTE_VIDEOS_ENTITY_VIDEO_ANNOTATE,
                      {
                        entityId: props.match.params.entityId,
                        videoId: props.match.params.videoId
                      }
                    )}
                  />
                </EditableContextProvider>
              )}
            />

            <Redirect
              to={generatePath(ROUTE_VIDEOS_ENTITY, {
                entityId: user.rootEntityId
              })}
            />
          </Switch>
        </VideosProvider>
      </PreviewModalProvider>
    </LessonsProvider>
  );
};

export default withRouter(VideosIndex);
