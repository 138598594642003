import { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { getFilteredEntities } from 'utils/search';
import Lesson from 'models/Lesson';
import { QUERY_GET_LESSON } from 'services/aws/lessons-query';
import Video from 'models/Video';

const useLesson = (entityId, lessonId) => {
  const exercisesData = useRef();
  const [lesson, setLesson] = useState(new Lesson({}));
  const [videos, setVideos] = useState([]);
  const [filter, setFilter] = useState({ search: '' });

  const { loading, error, data } = useQuery(QUERY_GET_LESSON, {
    variables: { id: lessonId },
    skip: !lessonId
  });

  useEffect(() => {
    if (data?.getExerciseProgramTemplate) {
      const lesson = new Lesson({ ...data.getExerciseProgramTemplate });
      setLesson(lesson);
      exercisesData.current = data.getExerciseProgramTemplate.exercises.map(
        e => new Video({ ...e })
      );
      setVideos(exercisesData.current);
    }
  }, [data, lessonId]);

  useEffect(() => {
    if (lesson && exercisesData?.current?.length) {
      const filteredExercises = getFilteredEntities(
        exercisesData.current,
        filter.search ?? '',
        filter.tags ?? null
      );
      setVideos(filteredExercises);
    }
  }, [filter, lesson]);

  return {
    lesson,
    videos,
    filter,
    setFilter,
    loading,
    error
  };
};

export default useLesson;
