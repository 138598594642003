import React, { useEffect, useState } from 'react';
import ThumbList from 'containers/partials/media/ThumbList';
import FileFull from 'containers/partials/media/FileFull';
import File from 'models/File';

const MediaViewer = ({ media, entityId }) => {
  // TODO: juiste thumbnails genereren? Is dat hier nodig...
  // om naar de pdf te sturen [sc-7224]
  //   const meta = JSON.parse(media[1].meta);
  //   const playbackId = meta.mux.playback_ids[0].id;

  //   const muxThumb = `https://image.mux.com/${playbackId}/thumbnail.jpg?width=504&fit_mode=preserve&time=35`;

  const [selectedMedia, setSelectedMedia] = useState(null);

  useEffect(() => {
    if (media && media.length > 0) {
      setSelectedMedia(new File(media[0]));
    }
  }, [media]);

  const onThumbClickHandler = file => {
    setSelectedMedia(new File(file));
  };

  return (
    <>
      {selectedMedia && <FileFull file={selectedMedia} />}
      {media.length > 1 && (
        <ThumbList
          selectedMedia={selectedMedia}
          entityId={entityId}
          editable={false}
          media={media}
          onClick={onThumbClickHandler}
        />
      )}
    </>
  );
};

export default MediaViewer;
