import React, { useContext } from 'react';
import { StoreContext } from 'index';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { CardBody, CardFooter } from 'components/card';
import FieldInput from 'components/input/FieldInput';
import messages from 'messages';
import { Button, ButtonsGroup } from 'components/button';
import { ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES } from 'routes/RouteList';
import { useNotificationQueue } from 'components/notification';
import { generatePath } from 'react-router-dom';
import Template from 'models/Template';
import TemplateForm from 'containers/pages/exercises/templates/TemplateForm';
import { TemplateType } from 'constants.js';

function AddCollectionModal({ entityId, onClose }) {
  const {
    routing: { push }
  } = useContext(StoreContext);
  const intl = useIntl();
  const notification = useNotificationQueue();

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal card autoHeight isOpen={true} onClose={onCloseHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.collectionsAddTitle} />
        </CardHeaderTitle>
      </CardHeader>
      <TemplateForm
        entityId={entityId}
        template={new Template({ type: TemplateType.COLLECTION })}
        onComplete={async templateId => {
          notification.add(templateId, {
            message: intl.formatMessage(messages.messageAddCollectionSuccess)
          });
          setTimeout(() => {
            push(
              generatePath(
                ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES,
                {
                  entityId,
                  collectionId: templateId
                }
              )
            );
          }, 100);
          onClose && onClose();
        }}
      >
        {({ errors, touched }) => (
          <>
            <CardBody modalWithOverflow>
              <FieldInput
                id="title"
                name="title"
                placeholder={intl.formatMessage(messages.collectionNameLabel)}
                errors={errors}
                touched={touched}
                required
              >
                <FormattedMessage {...messages.collectionNameLabel} />
              </FieldInput>
            </CardBody>
            <CardFooter modal extraClassNames="c-card__footer--modal-larger">
              <ButtonsGroup>
                <Button secondary onClick={() => onCloseHandler()}>
                  <FormattedMessage {...messages.buttonCancel} />
                </Button>
                <Button type="submit" primary>
                  <FormattedMessage {...messages.buttonSave} />
                </Button>
              </ButtonsGroup>
            </CardFooter>
          </>
        )}
      </TemplateForm>
    </Modal>
  );
}

export default AddCollectionModal;
