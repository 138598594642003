import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PanelHeader } from 'components/panel';
import {
  PanelTitle,
  PanelTitleButton,
  PanelTitleWrapper
} from 'components/panel/panel-title';
import Panel from 'components/panel/Panel';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import PanelBody from 'components/panel/PanelBody';
import { Tab, TabList, TabPanel, Tabs } from 'components/tabs';
import {
  ROUTE_EXERCISES_ENTITY,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES,
  ROUTE_EXERCISES_TEMPLATES_ENTITY
} from 'routes/RouteList';
import messages from 'messages';
import SelectExercisesCard from 'containers/partials/cards/SelectExercisesCard';
import { Card } from 'components/card';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import Tags from 'containers/partials/inputs/Tags';
import FieldInput from 'components/input/FieldInput';
import { NoteTypes } from 'constants.js';
import { useNotificationQueue } from 'components/notification';
import { useExercisesContext } from 'contexts/ExercisesContext';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import { MenuWrapper } from 'components/menu';
import AddProgramModal from 'containers/pages/exercises/programs/AddProgramModal';
import SelectedExercisesCard from 'containers/partials/cards/SelectedExercisesCard';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import { usePreviewModalContext } from 'contexts/PreviewModalContext';
import useTemplate from 'hooks/queries/useTemplate';
import { StoreContext } from 'index';
import TemplateForm from 'containers/pages/exercises/templates/TemplateForm';

const EditTemplate = ({ edit: initialEdit, templateId, entityId }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);

  const notification = useNotificationQueue();
  const intl = useIntl();

  const { exerciseActions } = useExercisesContext();

  const [edit, setEdit] = useState(true);
  const { setModalData } = usePreviewModalContext();
  const [programModalData, setProgramModalData] = useState(null);

  const { template } = useTemplate(entityId, templateId, user);

  useEffect(() => {
    setEdit(initialEdit);
  }, [initialEdit]);

  useEffect(() => {
    if (template) {
      exerciseActions.setSelectedExercises(template.exercises);
    }
  }, [template]);

  const onCompleteHandler = templateId => {
    notification.add(templateId, {
      message: intl.formatMessage(messages.messageEditTemplateSuccess)
    });
    setEdit(false);
    //onComplete && onComplete(templateId);
  };

  const onUseHandler = template => {
    // show use template modal
    setProgramModalData({
      entityId,
      title: '',
      startdate: '',
      duration: '',
      description: '',
      exerciseIds: template.exercises.map(e => e.id),
      templateId: template.id
    });
  };

  return (
    <Panel secondary extraClassNames="o-flex o-flex--column">
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb
            to={ROUTE_EXERCISES_ENTITY.replace(':entityId', entityId)}
          >
            <FormattedMessage {...messages.breadcrumbOverview} />
          </Breadcrumb>
          <Breadcrumb
            to={ROUTE_EXERCISES_TEMPLATES_ENTITY.replace(':entityId', entityId)}
          >
            <FormattedMessage {...messages.breadcrumbTemplates} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle extraClassNames="u-margin-bottom-none">
            {template.title}
            <MenuWrapper trigger={<PanelTitleButton />}>
              <Button menu onClick={() => onUseHandler(template)}>
                <FormattedMessage {...messages.exercisesUseTemplateButton} />

                <Icon id="use-template" />
              </Button>
            </MenuWrapper>
          </PanelTitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody fullWidth>
        <Tabs fullWidth>
          <TabList>
            <Tab key="exercises.templates.exercises">
              <FormattedMessage {...messages.templateTabExercises} />
            </Tab>
          </TabList>
          <TabPanel key="exercises.templates.exercises">
            <Panel>
              <PanelBody>
                {entityId && (
                  <SelectExercisesCard
                    entityId={entityId}
                    edit={edit}
                    onEdit={edit => setEdit(edit)}
                    onPreview={exercise =>
                      setModalData({ type: 'exercise', data: exercise })
                    }
                  />
                )}
              </PanelBody>
            </Panel>
            <Panel>
              <PanelBody>
                <TemplateForm
                  entityId={entityId}
                  template={template}
                  onComplete={onCompleteHandler}
                >
                  {({
                    values: template,
                    errors,
                    touched,
                    setFieldValue,
                    handleSubmit,
                    handleReset
                  }) => {
                    return (
                      <>
                        <Card secondary multiBody>
                          <CardHeader secondary>
                            <CardHeaderText>
                              <CardHeaderTitle>
                                <FormattedMessage
                                  {...messages.templatesDetail}
                                />
                              </CardHeaderTitle>
                              <FormEditHeaderButtons
                                edit={edit}
                                setEdit={setEdit}
                                onCancel={handleReset}
                                onSubmit={handleSubmit}
                              />
                            </CardHeaderText>
                            <FieldInput
                              id="title"
                              name="title"
                              placeholder={intl.formatMessage(
                                messages.templateNamePlaceholder
                              )}
                              errors={errors}
                              touched={touched}
                              readOnly={!edit}
                            >
                              <FormattedMessage
                                {...messages.templateNameLabel}
                              />
                            </FieldInput>
                            <Tags
                              entityId={entityId}
                              label={`${intl.formatMessage(
                                messages.exercisesLabelTags
                              )}`}
                              tags={template.tags}
                              isMulti
                              isClearable={false}
                              creatable
                              edit={edit}
                              onChange={tags => {
                                setFieldValue('tags', tags);
                              }}
                              placeholder={
                                !edit
                                  ? intl.formatMessage(
                                      messages.selectTagsEmptyReadOnlyPlaceholder
                                    )
                                  : intl.formatMessage(
                                      messages.selectTagsPlaceholder
                                    )
                              }
                            />
                          </CardHeader>
                        </Card>
                        <SelectedExercisesCard
                          edit={edit}
                          onEdit={edit => setEdit(edit)}
                          onPreview={exercise =>
                            setModalData({ type: 'exercise', data: exercise })
                          }
                          onUse={() => onUseHandler(template)}
                          noteType={NoteTypes.TEMPLATE}
                          editableNotes={true}
                        />
                      </>
                    );
                  }}
                </TemplateForm>
              </PanelBody>
            </Panel>
          </TabPanel>
        </Tabs>
      </PanelBody>
      {programModalData && (
        <AddProgramModal
          entityId={entityId}
          program={programModalData}
          destiny={{
            path: ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES,
            variables: {}
          }}
          onClose={() => setProgramModalData(null)}
          fromTemplate
          template={template}
        />
      )}
    </Panel>
  );
};

export default EditTemplate;
