import { observable, computed, makeObservable } from 'mobx';
import { PersonEntityRoles } from 'constants.js';
import Session from './Session';
export default class Group {
  id = null;
  parentId = null;
  roles = [];
  name = '';
  type = '';
  subEntities = [];
  clients = [];
  users = [];
  testSessions = [];

  constructor({
    parentId = null,
    id,
    name = '',
    type,
    subEntities = [],
    clients = [],
    users = [],
    roles = [],
    testSessions = [],
    info,
    regioCode = 'nl',
    baseLanguage = 'nl',
    meta
  }) {
    makeObservable(this, {
      id: observable,
      parentId: observable,
      roles: observable,
      name: observable,
      type: observable,
      subEntities: observable,
      clients: observable,
      users: observable,
      testSessions: observable,
      canAddUsers: computed
    });

    // root ids
    this.id = id;
    this.parentId = parentId;

    // basis info entity
    this.name = name;
    this.type = type;
    try {
      this.info = JSON.parse(info);
    } catch (er) {
      this.info = {};
    }

    try {
      this.meta = JSON.parse(meta);
    } catch (er) {
      this.meta = {};
    }

    // sub data
    this.subEntities = subEntities || [];
    this.clients = clients || [];
    this.users = users || [];

    this.testSessions = parseTestSessionDataToModel(testSessions);

    // roles
    this.roles = roles || [];

    this.baseLanguage = baseLanguage;
    this.regioCode = regioCode;
  }

  get canAddUsers() {
    if (this.roles && this.roles.length > 0) {
      return this.roles.find(PersonEntityRoles.ENTITY_ADD_USER);
    }
    return false;
  }

  static get modelName() {
    return 'Group';
  }
}

export const parseTestSessionDataToModel = data => {
  let sessions = [];
  if (data && data.length > 0) {
    sessions = data.map(session => new Session(session));
  }
  return sessions;
};
