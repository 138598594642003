import React, { createContext, useReducer, useContext, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_TEMPLATES } from 'services/aws/templates-query';
import { sort } from 'utils/sort';
import { getFilteredEntities } from 'utils/search';
import Training from 'models/Training';

export const TrainingsContext = createContext();

const initialState = {
  trainingsStore: [],
  trainings: [],
  filter: {
    search: '',
    tags: []
  }
};

export const SET_TRAININGS_STORE = 'SET_TRAININGS_STORE';
export const SET_TRAININGS = 'SET_TRAININGS';

export const SET_FILTER = 'SET_FILTER';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_TRAININGS_STORE:
      return {
        ...state,
        trainingsStore: [...action.payload]
      };
    case SET_TRAININGS:
      return { ...state, trainings: [...action.payload] };

    case SET_FILTER:
      return { ...state, filter: { ...action.payload } };
    default:
      return state;
  }
};

const TrainingsProvider = ({ entityId, type, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [getTemplatesQuery, { loading, data }] =
    useLazyQuery(QUERY_GET_TEMPLATES);

  useEffect(() => {
    if (!data?.getExerciseProgramTemplates) return;

    const trainings = data.getExerciseProgramTemplates.map(
      training => new Training({ ...training })
    );
    dispatch({
      type: SET_TRAININGS_STORE,
      payload: trainings
    });
    dispatch({
      type: SET_TRAININGS,
      payload: trainings
    });
  }, [data]);

  useEffect(() => {
    if (state.filter) {
      const trainings = sort(
        getFilteredEntities(
          state.trainingsStore,
          state.filter.search ?? '',
          state.filter.tags ?? null
        ),
        {
          keys: [{ key: 'title' }]
        }
      );
      dispatch({
        type: SET_TRAININGS,
        payload: trainings
      });
    }
  }, [state.trainingsStore, state.filter]);

  const actions = {
    getTrainings: () => {
      getTemplatesQuery({ variables: { entityId, type } });
    },
    setFilter: filter => {
      dispatch({
        type: SET_FILTER,
        payload: { ...state.filter, ...filter }
      });
    },
    resetFilter: () => {
      dispatch({
        type: SET_FILTER,
        payload: { ...initialState.filter }
      });
    }
  };

  return (
    <TrainingsContext.Provider
      value={{
        loading,
        state,
        actions
      }}
    >
      {children}
    </TrainingsContext.Provider>
  );
};

function useTrainingsContext() {
  const context = useContext(TrainingsContext);
  if (context === undefined) {
    throw new Error(
      'The TrainingsContext hook must be used within a TrainingsContext.Provider'
    );
  }
  return context;
}

export { TrainingsProvider, useTrainingsContext };
