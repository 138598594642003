import React from 'react';
import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { ButtonsGroup, Button } from 'components/button';
import { CardFooter, CardBody } from 'components/card';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { Mutation } from '@apollo/client/react/components';
import { MODAL_TYPES } from 'models/ModalData';
import { sanitize } from 'dompurify';
export default class BasicModal extends React.Component {
  render() {
    const {
      modalData: {
        title,
        message,
        okLabel,
        okHandler,
        dismissButton,
        dismissHandler,
        dismissLabel,
        mutationData,
        type
      }
    } = this.props;
    return (
      <Modal card alert autoHeight isOpen={true} onClose={dismissHandler}>
        <CardHeader modal>
          <CardHeaderTitle>{title}</CardHeaderTitle>
          {/* <CardHeaderSubtitle></CardHeaderSubtitle> */}
        </CardHeader>
        <CardBody modal>
          <p
            dangerouslySetInnerHTML={{
              __html: sanitize(message)
            }}
          ></p>
        </CardBody>
        <CardFooter modal>
          <ButtonsGroup>
            {mutationData ? (
              <Mutation {...mutationData}>
                {mutation => (
                  <Button
                    alert={type === MODAL_TYPES.ALERT}
                    primary={type === MODAL_TYPES.WARNING}
                    onClick={() => {
                      okHandler();
                      mutation(mutationData.variables);
                    }}
                  >
                    {okLabel}
                  </Button>
                )}
              </Mutation>
            ) : (
              <Button
                alert={type === MODAL_TYPES.ALERT}
                primary={type === MODAL_TYPES.WARNING}
                onClick={() => {
                  okHandler();
                }}
              >
                {okLabel}
              </Button>
            )}
            {dismissButton && (
              <Button secondary onClick={dismissHandler}>
                {dismissLabel ? (
                  dismissLabel
                ) : (
                  <FormattedMessage {...messages.buttonCancel} />
                )}
              </Button>
            )}
          </ButtonsGroup>
        </CardFooter>
      </Modal>
    );
  }
}
