import React from 'react';
import './Loader.scss';

const Loader = prop => (
  <div className="c-loader">
    <div className="c-loader--bouncing">
      <div className="c-loader--bouncing-ball" />
      <div className="c-loader--bouncing-ball" />
      <div className="c-loader--bouncing-ball" />
    </div>
    {prop.children && <div className="c-loader__message">{prop.children}</div>}
  </div>
);

export default Loader;
