import React from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import {
  QUERY_GET_BENCHMARKS,
  MUTATION_PUBLISH_BENCHMARK,
  MUTATION_UNPUBLISH_BENCHMARK
} from 'services/aws/benchmark-query';
import { MODAL_TYPES } from 'models/ModalData';
import messages from 'messages';

const PublishBenchmarkButton = ({
  publish,
  benchmarkId,
  authStore,
  children,
  entityId,
  ...props
}) => {
  const mutation = publish
    ? MUTATION_PUBLISH_BENCHMARK
    : MUTATION_UNPUBLISH_BENCHMARK;

  const cButton = publish
    ? messages.modalConfirmPublishButton
    : messages.modalConfirmUnPublishButton;

  const cTitle = publish
    ? messages.modalConfirmPublishBenchmarkTitle
    : messages.modalConfirmUnPublishBenchmarkTitle;

  const cMessage = publish
    ? messages.modalConfirmPublishBenchmarkMessage
    : messages.modalConfirmUnPublishBenchmarkMessage;

  return (
    <ConfirmMutationButton
      {...props}
      mutation={mutation}
      variables={{
        benchmarkId
      }}
      confirmType={MODAL_TYPES.WARNING}
      confirmAcceptLabel={cButton}
      confirmTitle={cTitle}
      confirmMessage={cMessage}
      refetchQueries={[
        {
          query: QUERY_GET_BENCHMARKS,
          variables: { entityId }
        }
      ]}
    >
      <Icon id="finish" />
      {children}
    </ConfirmMutationButton>
  );
};

export default inject('authStore')(withRouter(PublishBenchmarkButton));
