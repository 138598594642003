import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Button.scss';
import Icon from '../icon/Icon';

const Button = props => {
  const {
    onClick,
    onMouseEnter,
    onMouseLeave,
    extraClassNames,
    primary,
    primaryDark,
    primaryDark50,
    secondary,
    accented,
    grey,
    white,
    light,
    disabled,
    pseudoDisabled,
    readOnly,
    small,
    tiny,
    tinySecondary,
    inactive,
    mini,
    miniDefaultText,
    ghost,
    select,
    parentTitle,
    benchmark,
    iconRight,
    circular,
    circularLarge,
    rounded,
    breadcrumb,
    halfRounded,
    tabbar,
    menuHeader,
    menu,
    alert,
    active,
    type,
    ariaLabel,
    ariaLabelledby,
    tabIndex,
    style,
    staticContext,
    iconLeft,
    children,
    ...rest
  } = props;

  const handleClick = e => {
    e.stopPropagation();

    if (onClick) onClick(e);
  };

  const handleMouseEnter = e => {
    e.stopPropagation();

    if (onMouseEnter) onMouseEnter(e);
  };

  const handleMouseLeave = e => {
    e.stopPropagation();

    if (onMouseLeave) onMouseLeave(e);
  };

  return (
    <button
      className={classNames('c-button', extraClassNames, {
        'c-button--primary': primary, // ok
        'c-button--primary c-button--primary-dark': primaryDark, // ok
        'c-button--primary c-button--primary-dark-50': primaryDark50, // ok
        'c-button--secondary': secondary, // ok
        'c-button--accented': accented, // ok
        'c-button--grey': grey, // ok
        'c-button--white': white, // ok
        'c-button--light': light, // ok
        'c-button--disabled': disabled || pseudoDisabled, // ok
        'c-button--read-only': readOnly, // ok
        'c-button--small': small, //ok
        'c-button--tiny': tiny, // ok
        'c-button--tiny c-button--tiny-cancel': tinySecondary,
        'c-button--inactive': inactive, // ok
        'c-button--mini': mini, // ok
        'c-button--mini c-button--mini-text-default': miniDefaultText,
        'c-button--ghost': ghost, // ok
        'c-button--ghost c-button--select': select, // ok
        'c-button--select-with-superscript': parentTitle,
        'c-button--benchmark': benchmark, // ok
        'c-button--icon-right': iconRight,
        'c-button--circular': circular, // ok
        'c-button--circular c-button--circular-large': circularLarge, // ok
        'c-button--rounded': rounded, // ok
        'c-button--breadcrumb': breadcrumb,
        'c-button--rounded c-button--half-rounded': halfRounded,
        'c-button--tabbar': tabbar,
        'c-button--menu c-button--menu-header': menuHeader,
        'c-button--menu': menu,
        'c-button--alert': alert,
        'is-active': active
      })}
      type={type || 'button'}
      onClick={e => handleClick(e)}
      onMouseEnter={e => handleMouseEnter(e)}
      onMouseLeave={e => handleMouseLeave(e)}
      onFocus={e => handleMouseEnter(e)}
      onBlur={e => handleMouseLeave(e)}
      disabled={disabled || readOnly}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledby}
      tabIndex={tabIndex}
      style={style}
      {...rest}
    >
      {select && parentTitle && (
        <div className="c-button__sup-value">{parentTitle}</div>
      )}
      {children}

      {select && (
        <Icon
          id="dropdown-open"
          extraClassNames={classNames('c-icon--input', {})}
        />
      )}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func
};

export default Button;
