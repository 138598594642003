import { useContext, useEffect, useState } from 'react';
import Modal from 'components/modal/Modal';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import { FormattedMessage, useIntl } from 'react-intl';
import { CardBody, CardFooter } from 'components/card';
import CardBodyTools from 'components/card/CardBodyTools';
import { Button, ButtonsGroup } from 'components/button';
import messages from 'messages';
import Loader from 'components/loader/Loader';
import { useTemplatesContext } from 'contexts/TemplatesContext';
import ListBody from 'components/list/ListBody';
import TemplatesListItem from 'containers/partials/list-items/TemplatesListItem';
import List from 'components/list/List';
import TemplatesListItemForm from 'containers/partials/list-items/TemplatesListItemForm';
import useSelectable from 'hooks/utils/useSelectable';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import PropTypes from 'prop-types';
import FieldInput from 'components/input/FieldInput';
import { TemplateType } from 'constants.js';
import { StoreContext } from 'index';

const AddExercisesToCollectionModal = ({
  entityId,
  onAdd,
  onClose,
  collectionType
}) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();
  const {
    state: { templates },
    actions,
    loading
  } = useTemplatesContext();
  const ability = useAbility(AbilityContext);
  const [newTemplateId, setNewTemplateId] = useState(null);
  const [templatesList, setTemplatesList] = useState([]);
  const [filter, setFilter] = useState({ search: '', tags: [] });
  const { selected, select } = useSelectable([]);

  useEffect(() => {
    if (actions) {
      actions.getTemplates(TemplateType[collectionType.toUpperCase()]);
      actions.resetFilter();
    }
  }, []);

  useEffect(() => {
    if (templates) {
      const myTemplates = templates.filter(t => t.personId === user.id);
      if (newTemplateId) {
        const newTemplate = myTemplates.find(t => t.id === newTemplateId);
        const filteredTemplates = myTemplates.filter(
          t => t.id !== newTemplateId
        );
        filteredTemplates.unshift(newTemplate);
        setTemplatesList(filteredTemplates);
        return;
      }
      setTemplatesList(myTemplates);
    }
  }, [templates, newTemplateId]);

  const onCloseHandler = () => {
    actions.resetFilter();
    if (onClose) onClose();
  };

  const onAddHandler = () => {
    if (onAdd) onAdd(selected);
  };

  const onSearch = e => {
    setFilter({ ...filter, search: e.target.value });
    actions.setFilter({ search: e.target.value });
  };

  const onCompleteTemplateHandler = async templateId => {
    select(templateId);
    await actions.getTemplates();
    setNewTemplateId(templateId);
  };

  return (
    <Modal
      card
      isOpen={true}
      onClose={onCloseHandler}
      extraClassNames={'c-card-modal__content-body-scroll'}
    >
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage
            {...messages[`addExercisesTo${collectionType}ModalTitle`]}
          />
        </CardHeaderTitle>
        <CardHeaderSubtitle>
          <FormattedMessage
            {...messages[`addExercisesTo${collectionType}ModalSubtitle`]}
          />
        </CardHeaderSubtitle>
      </CardHeader>

      <CardBody modal bodyWithHeader>
        {loading && <Loader />}
        <CardBodyTools style={{ flexWrap: 'wrap' }}>
          <FieldInput
            id="title"
            rounded
            placeholder={intl.formatMessage(
              messages.exercisesProgramFilterNameLabel
            )}
            onChange={onSearch}
            icon="search-grey"
            iconLeft
          />
        </CardBodyTools>
        <List onModal>
          <ListBody
            style={{
              minHeight: 'calc(100% - 40px)',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {/* Add a new Collection */}
            {!newTemplateId && (
              <TemplatesListItemForm
                extraClassNames="c-list__item-with-input"
                entityId={entityId}
                onComplete={onCompleteTemplateHandler}
                collectionType={collectionType}
              />
            )}

            {templatesList.map(
              template =>
                ability.can('update', template) && (
                  <TemplatesListItem
                    icon={'template'}
                    checkbox={ability.can('update', template)}
                    id={`modal_${template.id}`}
                    key={template.id}
                    template={template}
                    entityId={entityId}
                    visible
                    checked={selected.includes(template.id)}
                    // onClick={() => onClickHandler(template)}
                    // In this context, a modal, clicking on the element
                    // should also select the list item.
                    onClick={() => {
                      ability.can('update', template) && select(template.id);
                    }}
                    onChange={() =>
                      ability.can('update', template) && select(template.id)
                    }
                  />
                )
            )}

            {templatesList.length <= 0 && !loading && (
              <li className="u-padding-bottom-large u-margin-auto">
                <Message emptyState={true} icon="cursor">
                  <MessageText>
                    <FormattedMessage {...messages.templateListEmpty} />
                  </MessageText>
                </Message>
              </li>
            )}
          </ListBody>
        </List>
      </CardBody>

      <CardFooter modal>
        <ButtonsGroup>
          <Button secondary onClick={() => onCloseHandler()}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button
            disabled={selected.length === 0}
            primary
            onClick={() => onAddHandler()}
          >
            <FormattedMessage {...messages.addButton} />
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
};

AddExercisesToCollectionModal.propTypes = {
  entityId: PropTypes.string.isRequired,
  collectionType: PropTypes.string.isRequired
};

export default AddExercisesToCollectionModal;
