import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { ROUTE_SIGNUP, ROUTE_TESTS } from './RouteList';
import { StoreContext } from '../index';

const PublicRoute = ({ component, ...rest }) => {
  const {
    authStore: { isLoggedIn, tmpCognitoUser }
  } = useContext(StoreContext);

  if (
    (!isLoggedIn || rest.path === ROUTE_TESTS) &&
    (rest.path !== ROUTE_SIGNUP || tmpCognitoUser)
  ) {
    return <Route {...rest}>{component}</Route>;
  }

  return <Redirect to="/" />;
};

export default inject('authStore')(observer(PublicRoute));
