import classNames from 'classnames';
import { useLayoutEffect, useRef } from 'react';
import { Tabs as ReactTabs } from 'react-tabs';

import './Tabs.scss';

const Tabs = ({
  fullWidth,
  fullWidthB,
  sessionTemplateTabs,
  hrefWithin,
  marginNegativeTop = 0,
  ...props
}) => {
  //   TODO: Refactor deze, want het is ook gebruik bij CardBody.js,
  // en het zou wel in verschillende plaatsen gebruik worden.
  // zeker handig als een vaste height nodig is, om binnen
  // een virtualized-list in te steken.
  const resizableContent = useRef();
  useLayoutEffect(() => {
    if (resizableContent.current) {
      const tabPanels =
        resizableContent.current.querySelectorAll('[role="tabpanel"]');

      const tabTopPosition =
        resizableContent.current.querySelector(
          '.react-tabs__tab-panel--selected'
        ) &&
        resizableContent.current
          .querySelector('.react-tabs__tab-panel--selected')
          .getBoundingClientRect().top;

      [...tabPanels].map(tabPanel => {
        tabPanel.style.height = `calc(100vh - ${marginNegativeTop}px - ${tabTopPosition}px)`; // + 24
        tabPanel.style.overflowY = 'hidden';
        tabPanel.style.overflowX = 'hidden';

        // tabPanel.style.paddingTop = 0;
        // tabPanel.style.paddingBottom = 0;
      });
    }
  });

  return (
    <div
      ref={resizableContent}
      className={classNames('p-tabs', props.extraClassNames, {
        'p-tabs--full-width': fullWidth,
        'p-tabs--full-width p-tabs--full-width-b': fullWidthB,
        'p-tabs--list-sessions-templates': sessionTemplateTabs,
        'p-tabs--click-on-link': hrefWithin
      })}
    >
      <ReactTabs {...props}>{props.children}</ReactTabs>
    </div>
  );
};

export default Tabs;
