import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { inject } from 'mobx-react';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import { CardBody, CardFooter } from 'components/card';
import { ButtonsGroup, Button } from 'components/button';
import { DROP_STATE, IMPORT_BENCHMARK_EXAMPLE_URL } from 'constants.js';
import Icon from 'components/icon/Icon';
import ProgressBar from 'components/progress-bar/ProgressBar';
import messages from 'messages';
import { importBenchmarkData } from 'services/aws/file-upload';
import { Table, TableBody, TableCell, TableRow } from 'components/table';

const IMPORT_SPORTERS_STATE = Object.freeze({
  DEFAULT: 'default',
  UPLOADING: 'uploading',
  PROCESSING: 'processing',
  RESULT: 'result',
  FAILED: 'failed',
  FAILED_ERRORS: 'failed-errors'
});

class ImportBenchmarkDataCard extends Component {
  state = {
    importState: IMPORT_SPORTERS_STATE.DEFAULT,
    sportersCount: 0,
    uploadingProgress: 0,
    extension: '',
    fileId: null,
    importProblems: []
  };

  onUploadingProgress = progress => {
    const uploadingProgress = (progress.loaded / progress.total) * 100;
    this.setState({ uploadingProgress });
  };

  onReset = () => {
    this.setState({
      importState: IMPORT_SPORTERS_STATE.DEFAULT,
      sportersCount: 0,
      uploadingProgress: 0,
      extension: '',
      fileId: null
    });
  };

  onDrop = async acceptedFile => {
    const { rootEntityId, benchmarkTestId, benchmarkId } = this.props;
    if (acceptedFile.length === 1) {
      const file = acceptedFile[0];
      const extension = `.${file.name.split('.').pop()}`;
      this.setState({
        importProblems: [],
        importState: IMPORT_SPORTERS_STATE.UPLOADING,
        extension
      });
      const response = await importBenchmarkData({
        entityId: rootEntityId,
        benchmarkId: benchmarkId,
        benchmarkTestId: benchmarkTestId,
        file,
        onUploadProgress: this.onUploadingProgress,
        onUploaded: () =>
          this.setState({
            importState: IMPORT_SPORTERS_STATE.PROCESSING
          })
      });
      const hasValidResponse = !response.error;
      this.setState({
        importProblems: response.problems || [],
        importState: !hasValidResponse
          ? IMPORT_SPORTERS_STATE.FAILED
          : response.problems && response.problems.length
          ? IMPORT_SPORTERS_STATE.FAILED_ERRORS
          : IMPORT_SPORTERS_STATE.RESULT,

        sportersCount: hasValidResponse ? response.count : 0,
        fileId: hasValidResponse ? response.fileId : null
      });
    }
  };

  render() {
    const { importState, uploadingProgress, extension, importProblems } =
      this.state;

    const {
      intl,
      onClose,
      uiState: { locale }
    } = this.props;
    const language = locale.substring(0, 2);
    return (
      <Fragment>
        <CardHeader modal>
          <CardHeaderTitle>
            <FormattedMessage {...messages.cardImportBenchmarkTitle} />
          </CardHeaderTitle>
          <CardHeaderSubtitle>
            <FormattedMessage {...messages.cardImportBenchmarkSubTitle} />
          </CardHeaderSubtitle>
        </CardHeader>
        <CardBody modal>
          {importState === IMPORT_SPORTERS_STATE.DEFAULT && (
            <Fragment>
              <Dropzone
                className="c-file-upload c-file-upload--no-padding"
                accept={{
                  'application/vnd.ms-excel': [],
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    []
                }}
                onDrop={this.onDrop}
                multiple={false}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragReject
                }) => {
                  let dropState = DROP_STATE.DEFAULT;

                  if (isDragActive) {
                    dropState = DROP_STATE.DRAGGING;
                  }
                  if (isDragReject) {
                    dropState = DROP_STATE.REJECT;
                  }
                  return (
                    <div
                      className={classNames(
                        'c-file-upload--state c-file-upload--import-sporters',
                        {
                          'c-file-upload--rejected':
                            dropState === DROP_STATE.REJECT,
                          'c-file-upload--dragging':
                            dropState === DROP_STATE.DRAGGING
                        }
                      )}
                      style={{ maxWidth: 'calc(100% - 32px)', margin: 'auto' }}
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <Icon hugeIcon id="upload" />
                      <p className="c-file-upload__message">
                        <FormattedMessage
                          {...messages.cardImportBenchmarkDragInfo}
                        />
                      </p>
                      <div className="c-file-upload__dropzone-button">
                        <Icon id="browse" />
                        <label className={classNames('c-input__label', {})}>
                          <FormattedMessage
                            {...messages.cardImportSportersBrowseButton}
                          />
                        </label>
                      </div>
                    </div>
                  );
                }}
              </Dropzone>
              <p className="c-file-upload__message u-margin-horizontal-auto u-margin-top-large u-margin-bottom-small">
                <FormattedMessage {...messages.cardImportSportersFileInfo} />
                <a
                  download
                  rel="noreferrer"
                  target="_blank"
                  href={IMPORT_BENCHMARK_EXAMPLE_URL.replace(
                    '{language}',
                    language
                  )}
                  className="c-file-upload__link"
                >
                  <FormattedMessage
                    {...messages.cardImportSportersDownloadExampleButton}
                  />
                </a>
              </p>
            </Fragment>
          )}
          {importState === IMPORT_SPORTERS_STATE.UPLOADING && (
            <div className="c-file-upload--wrapper-states">
              <Icon id="spreadsheet" xHugeIcon />
              <ProgressBar
                secondary
                value={Math.round(uploadingProgress / 2)}
              />
              <p className="c-file-upload__message">
                <FormattedMessage
                  {...messages.cardImportSportersUploadingFile}
                  values={{ extension }}
                />
              </p>
            </div>
          )}
          {importState === IMPORT_SPORTERS_STATE.PROCESSING && (
            <div className="c-file-upload--wrapper-states">
              <Icon id="spreadsheet" xHugeIcon />
              <ProgressBar secondary value={75} />
              <p className="c-file-upload__message">
                <FormattedMessage
                  {...messages.cardImportBenchmarkIdentifying}
                />
              </p>
            </div>
          )}
          {importState === IMPORT_SPORTERS_STATE.RESULT && (
            <div className="c-file-upload--wrapper-states">
              <p className="c-file-upload__message c-file-upload__message-success">
                <FormattedMessage {...messages.cardImportBenchmarkResult} />
              </p>
            </div>
          )}
          {importState === IMPORT_SPORTERS_STATE.FAILED && (
            <div className="c-file-upload--wrapper-states">
              <Icon id="alert" xHugeIcon />

              <p className="c-file-upload__message c-file-upload__message-failed">
                <FormattedMessage {...messages.cardImportBenchmarkFailed} />
              </p>
            </div>
          )}
          {importState === IMPORT_SPORTERS_STATE.FAILED_ERRORS && (
            <div className="c-file-upload--wrapper-states c-file-upload--wrapper-states-errors">
              <p>
                <span className="u-color-error">
                  <FormattedMessage
                    {...messages.cardImportBenchmarkErrorsInfoLabel}
                  />
                </span>
              </p>
              <Table errorList>
                <TableBody>
                  {importProblems.map((p, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        {intl.formatMessage(
                          messages.cardImportSportersErrorRowLabel,
                          { row: p.row }
                        )}
                      </TableCell>
                      <TableCell>
                        <span className="u-color-error">
                          {messages[`cardImportSportersError_${p.problem}`]
                            ? intl.formatMessage(
                                messages[`cardImportSportersError_${p.problem}`]
                              )
                            : p.problem}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </CardBody>
        <CardFooter modal>
          <ButtonsGroup fullwidth>
            {(importState === IMPORT_SPORTERS_STATE.RESULT ||
              importState === IMPORT_SPORTERS_STATE.UPLOADING) && (
              <Button secondary onClick={onClose}>
                <FormattedMessage {...messages.cardImportBenchmarkClose} />
              </Button>
            )}

            {(importState === IMPORT_SPORTERS_STATE.FAILED ||
              importState === IMPORT_SPORTERS_STATE.FAILED_ERRORS) && (
              <Button primary onClick={this.onReset}>
                <FormattedMessage {...messages.cardImportSportersRetryButton} />
              </Button>
            )}
          </ButtonsGroup>
        </CardFooter>
      </Fragment>
    );
  }
}

export default injectIntl(
  inject('authStore', 'uiState')(ImportBenchmarkDataCard)
);
