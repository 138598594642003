import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { generatePath } from 'react-router-dom';
import { FinishedState } from 'enums';

import { Panel } from '../../../components/panel';
import PanelHeader from '../../../components/panel/PanelHeader';
import { FormattedMessage, useIntl } from 'react-intl';
import PanelTitleWrapper from '../../../components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from '../../../components/panel/panel-title/PanelTitle';
import PanelSubtitle from '../../../components/panel/panel-title/PanelSubtitle';
import {
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER,
  ROUTE_SESSIONS_ENTITY_SESSION
} from 'routes/RouteList';
import { getAgeFromDobOnSession } from 'utils/date';
import messages from 'messages';
import PanelBody from '../../../components/panel/PanelBody';
import PanelTitleButton from '../../../components/panel/panel-title/PanelTitleButton';
import { openNewWindow } from 'utils/browser';
import { MenuWrapper } from '../../../components/menu';
import RemoveSessionButton from '../../partials/buttons/RemoveSessionButton';
import RemoveSporterFromSessionButton from '../../partials/buttons/RemoveSporterFromSessionButton';
import { PanelTitleButtonsGroup } from '../../../components/panel/panel-title';
import { getTestSessionURL } from 'utils/url';
import { PackageTypes } from 'constants.js';
import { StoreContext } from 'index';
import { usePersonContext } from 'contexts/PersonContext';
import { useSessionContext } from 'contexts/SessionContext';
import DownloadSessionReportButton from 'containers/partials/buttons/DownloadSessionReportButton';
import MailReportButton from 'containers/partials/buttons/MailReportButton';
import BreadcrumbPath from 'containers/partials/misc/BreadcrumbPath';
import SessionMTS from 'containers/pages/sporters/SessionMTS';
import SessionTalentV1 from 'containers/pages/sporters/SessionTalentV1';
import Session from 'containers/pages/sporters/Session';
import DownloadSkReportButtons from 'containers/partials/buttons/DownloadSKReportButtons';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';

const SporterSession = ({ active, edit }) => {
  const ability = useAbility(AbilityContext);
  const {
    authStore: {
      user,
      user: { rootEntityId }
    },
    routing: { locale }
  } = useContext(StoreContext);

  const { entityId, sporterId, sessionId } = useParams();
  const { push } = useHistory();
  const intl = useIntl();
  const { person } = usePersonContext();
  const { session, options } = useSessionContext();
  const [testData, setTestData] = useState(false);

  useEffect(() => {
    if (session.id) {
      const testData = session.getTestData(sporterId);

      if (testData?.id) {
        setTestData(testData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.id]);

  const openSession = (entityId, sessionId) => {
    const url = `${generatePath(ROUTE_SESSIONS_ENTITY_SESSION, {
      entityId,
      sessionId
    })}`;
    openNewWindow(url);
  };

  const openTest = (entityId, sessionId, packageId) => {
    const url = getTestSessionURL({
      entityId,
      sessionId,
      packageType: packageId,
      locale,
      sporterId
    });
    openNewWindow(url);
  };

  return (
    <Panel active={active}>
      <PanelHeader>
        <BreadcrumbPath
          crumbs={[
            {
              path: generatePath(ROUTE_SPORTERS_ENTITY, {
                entityId
              }),
              label: intl.formatMessage(messages.breadcrumbSporters)
            },
            {
              path: generatePath(ROUTE_SPORTERS_ENTITY_SPORTER, {
                entityId,
                sporterId
              }),
              label: intl.formatMessage(messages.breadcrumbSportersSessions, {
                firstname: person.firstname,
                lastname: person.lastname
              })
            },
            {
              path: generatePath(ROUTE_SPORTERS_ENTITY, {
                entityId
              }),
              label: intl.formatMessage(messages.breadcrumbSporters)
            }
          ]}
        />
        <PanelTitleWrapper>
          <PanelTitle>
            {session.name}

            <PanelTitleButtonsGroup>
              {!testData &&
                session.finished !== FinishedState.FINISHED &&
                session.config.type !== PackageTypes.MTS && (
                  <PanelTitleButton
                    primary
                    small
                    icon="test-white"
                    onClick={() =>
                      openTest(rootEntityId, session.id, session.config.type)
                    }
                  />
                )}
              {ability.can('delete', 'Sessions') && (
                <MenuWrapper trigger={<PanelTitleButton />}>
                  {testData && (
                    <>
                      <DownloadSessionReportButton
                        session={session}
                        testDataId={testData?.id}
                        options={options}
                      />
                      <DownloadSkReportButtons
                        session={session}
                        testData={testData}
                      />
                    </>
                  )}
                  <RemoveSessionButton
                    menu
                    entityId={rootEntityId}
                    sessionId={session.id}
                    sporterId={sporterId}
                  >
                    <FormattedMessage
                      {...messages.menuItemSporterRemoveSession}
                    />
                  </RemoveSessionButton>
                  {user.isHylyghtOrg && testData?.id && (
                    <MailReportButton
                      menu
                      entityId={entityId}
                      session={session}
                      sporter={person}
                      testDataId={testData?.id}
                      options={options}
                    >
                      <FormattedMessage
                        {...messages.menuItemSessionMailReport}
                      />
                    </MailReportButton>
                  )}

                  <RemoveSporterFromSessionButton
                    menu
                    rootEntityId={rootEntityId}
                    entityId={entityId}
                    sessionId={session.id}
                    sporterId={sporterId}
                    ids={[sporterId]}
                    onComplete={() =>
                      push(
                        generatePath(ROUTE_SPORTERS_ENTITY_SPORTER, {
                          entityId,
                          sporterId
                        })
                      )
                    }
                  >
                    <FormattedMessage
                      {...messages.menuItemSporterRemoveSporterFromSession}
                    />
                  </RemoveSporterFromSessionButton>
                </MenuWrapper>
              )}
            </PanelTitleButtonsGroup>
          </PanelTitle>
          <PanelSubtitle>
            {session.startdate && person.birthdate && (
              <FormattedMessage
                {...messages.labelSessionSporterAgeAtTestDate}
                values={{
                  age: getAgeFromDobOnSession(
                    person.birthdate,
                    session.startdate
                  )
                }}
              />
            )}
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        <SporterView
          session={session}
          entityId={entityId}
          sessionId={sessionId}
          sporterId={sporterId}
          testDataId={testData?.id}
          hasTestData={testData?.id}
          edit={edit}
        />
      </PanelBody>
    </Panel>
  );
};

const SporterView = ({ session, ...props }) => {
  if (session.isPrevention || session.isTalentV2 || session.isIDo) {
    return <Session {...props} />;
  } else if (session.isMTS) {
    return <SessionMTS session={session} {...props} />;
  } else {
    return <SessionTalentV1 session={session} {...props} />;
  }
};

export default withRouter(SporterSession);
