import { useContext, useEffect, useRef, useState } from 'react';
import {
  Panel,
  PanelBody,
  PanelHeader,
  PanelTitle,
  PanelTitleButton,
  PanelTitleButtonsGroup,
  PanelTitleWrapper
} from 'components/panel';
import { Card, CardBody } from 'components/card';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { MenuWrapper } from 'components/menu';
import BreadcrumbPath from 'containers/partials/misc/BreadcrumbPath';
import { StoreContext } from 'index';
import { Tab, TabList, TabPanel, Tabs } from 'components/tabs';
import AnnotationPlayer from 'components/video/AnnotationPlayer';
import AnnotationForm from 'containers/pages/videos/AnnotationForm';

import AnnotationInput from 'containers/pages/videos/AnnotationInput';
import Annotation from 'models/Annotation';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import {
  ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_ANNOTATE_ADD,
  ROUTE_VIDEOS_ENTITY,
  ROUTE_VIDEOS_ENTITY_VIDEO_ANNOTATE_ADD
} from 'routes/RouteList';
import useAnnotations from 'hooks/queries/useAnnotations';
import AnnotationList from './AnnotationList';
import useVideo from 'hooks/queries/useVideo';
import Loader from 'components/loader/Loader';
import LegendCard from './HelperLegend';
import NoAccessPanel from 'containers/pages/auth/NoAccessPanel';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import AnnotateVideoButtonBar from 'containers/pages/videos/AnnotateVideoButtonBar';
import PanelSubtitleVideo from './PanelSubtitleVideo';
import { useEditableContext } from 'contexts/EditableContext';

const AnnotateVideo = ({ entityId, crumbs, parentPath }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  let { push } = useHistory();
  const isAdd = useRouteMatch([
    ROUTE_VIDEOS_ENTITY_VIDEO_ANNOTATE_ADD,
    ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_ANNOTATE_ADD
  ]);
  const ability = useAbility(AbilityContext);
  const intl = useIntl();
  const { videoId } = useParams();
  const { video, loading } = useVideo(entityId, videoId);
  const {
    annotations,
    loading: loadingAnnotations,
    refetch
  } = useAnnotations(video, user);
  const { setIsEditing } = useEditableContext();
  const [annotation, setAnnotation] = useState();
  const [activeAnnotation, setActiveAnnotation] = useState(null);
  const [seekingTime, setSeekingTime] = useState(0);
  const overflowParentRef = useRef();

  useEffect(() => {
    if (!loadingAnnotations && annotations?.length === 0 && !isAdd) {
      push(`${parentPath}/add`);
    }
    if (isAdd) {
      setIsEditing(false);
    }
  }, [loadingAnnotations, annotations, isAdd]);

  useEffect(() => {
    if (video?.id) {
      setAnnotation(
        new Annotation({
          personId: user.id,
          linkId: video.id,
          fileId: video.media[0].id
        })
      );
    }
  }, [isAdd?.url, user, video?.id]);

  const onCompleteHandler = async id => {
    await refetch();
    push(parentPath);
    setActiveAnnotation(id);
  };

  const onCancelHandler = () => {
    push(parentPath);
  };

  if (loading || loadingAnnotations || !annotations) return <Loader />;

  if (!ability.can('read', video)) {
    return (
      <NoAccessPanel
        crumbs={crumbs}
        entityId={entityId}
        emptyLabel={intl.formatMessage(messages.videosEmptyPanel)}
        redirectRoute={generatePath(ROUTE_VIDEOS_ENTITY, {
          entityId
        })}
      />
    );
  }

  return (
    <Panel secondary extraClassNames="o-flex o-flex--column">
      <PanelHeader>
        <BreadcrumbPath crumbs={crumbs} isMine={video.isMine(user)} inLesson />
        <PanelTitleWrapper>
          <PanelTitle>
            {video.title}
            <PanelTitleButtonsGroup>
              {/* TODO: fabian, replace with tooltip as portal */}
              <MenuWrapper
                extraClassNames={
                  'c-menu__wrapper-helper c-menu__wrapper-helper-wide'
                }
                asModal
                trigger={<PanelTitleButton small icon="question" />}
              >
                <Card helper>
                  <CardBody benchmarks>
                    <FormattedMessage
                      {...messages.annotationsHowToAnotate}
                      values={{
                        h2: chunks => <h2>{chunks}</h2>,
                        p: chunks => <p>{chunks}</p>,
                        ol: chunks => <ol>{chunks}</ol>,
                        li: chunks => <li>{chunks}</li>
                      }}
                    />
                  </CardBody>
                </Card>
              </MenuWrapper>
            </PanelTitleButtonsGroup>
          </PanelTitle>
          <PanelSubtitleVideo video={video} user={user} />
        </PanelTitleWrapper>
      </PanelHeader>
      <Tabs fullWidthB>
        <TabList>
          <Tab>
            <FormattedMessage {...messages.lessonsVideoLabel} />
          </Tab>
        </TabList>
        <TabPanel>
          <Panel>
            <PanelBody>
              <AnnotateVideoButtonBar
                extraClassNames={'u-margin-bottom-small'}
                entityId={entityId}
                video={video}
                annotations={annotations}
                annotationBtnLabel={intl.formatMessage(
                  messages.videoAnnotationsAddBtn
                )}
                annotatePath={`${parentPath}/add`}
                onClickAnnotation={() => {
                  overflowParentRef.current.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                  });
                }}
              />
              <div className={'c-media-wrapper'}>
                {video.media && video.media.length > 0 && (
                  <AnnotationPlayer
                    id={video.id}
                    ownershipVideo={video.person.id}
                    source={video.media[0].signedDownloadUrl}
                    annotations={annotations}
                    activeAnnotation={activeAnnotation}
                    onClickMarker={annotation => {
                      setActiveAnnotation(annotation);
                    }}
                    onPlayerStateChange={state => {
                      if (state.paused) {
                        setSeekingTime(state.currentTime);
                      }
                      if (state.seekingTime) {
                        setSeekingTime(state.seekingTime);
                      }
                    }}
                  />
                )}
              </div>
              <LegendCard />
            </PanelBody>
          </Panel>
          <Panel ref={overflowParentRef}>
            {!loadingAnnotations && (
              <PanelBody>
                {isAdd && video.media && video.media.length > 0 && (
                  <Card secondary>
                    <CardBody>
                      <AnnotationForm
                        extraClassNames={'c-form--annotations'}
                        entityId={entityId}
                        personId={user.id}
                        videoId={video.id}
                        fileId={video.media[0].id}
                        annotation={annotation}
                        onComplete={annotation => onCompleteHandler(annotation)}
                        onCancel={onCancelHandler}
                        refetch={refetch}
                      >
                        <AnnotationInput
                          entityId={entityId}
                          edit={true}
                          seekingTime={seekingTime}
                        />
                      </AnnotationForm>
                    </CardBody>
                  </Card>
                )}
                <AnnotationList
                  ownershipVideo={video.person.id}
                  videoId={videoId}
                  video={video}
                  annotations={annotations}
                  parentPath={parentPath}
                  activeAnnotation={activeAnnotation}
                  onClickItem={annotation => setActiveAnnotation(annotation)}
                  overflowParent={overflowParentRef}
                  editable={!isAdd}
                  refetch={refetch}
                />
              </PanelBody>
            )}
          </Panel>
        </TabPanel>
      </Tabs>
    </Panel>
  );
};

export default AnnotateVideo;
