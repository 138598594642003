import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { useVirtual } from 'react-virtual';
import { useUIDSeed } from 'react-uid';
import VirtualListItem from 'containers/partials/list-items/VirtualListItem';
import ListHeader from 'components/list/ListHeader';
import ListColumnTitle from 'components/list/ListColumnTitle';
import Person from 'models/Person';
import PersonStatusPopper from 'containers/partials/misc/PersonStatusPopper';
import ListItemLabel from 'components/list/list-item/ListItemLabel';
import { PersonStatus } from 'constants.js';
import MetaWrapper from 'containers/partials/list-items/MetaWrapper';

import useSelectableObject from 'hooks/utils/useSelectableObject';
import Badge from 'components/badge/Badge';

const VirtualizedEntityList = ({
  activeId,
  items = [],
  statusLabel,
  checkableGroups,
  checkable,
  visible,
  onlyGroups,
  listHeader,
  sortableListHeader,
  onClick,
  onSelect,
  selectedItems,
  disabledItems = [],
  excludedItems = [],
  extraClassNames = {},
  onModal,
  personStatus,
  onClickStatus,
  withFixedFooter,
  emptyMessage,
  reCalculateHeight = false,
  variableHeight = false
}) => {
  const parentRef = useRef();
  const uidSeed = useUIDSeed();
  const { selected, select, setSelected } = useSelectableObject([]);
  const [minHeight, setMinHeight] = useState(null);
  const [minHeightParent, setMinHeightParent] = useState(null);
  const [heightList, setHeightList] = useState(null);

  useEffect(() => {
    setSelected(selectedItems ? selectedItems : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, selectedItems]);

  const rowVirtualizer = useVirtual({
    size: items?.length ?? 0,
    parentRef,
    estimateSize: useCallback(() => 56, []),
    overscan: 5
  });

  const onSelectHandler = (item, checked) => {
    if (excludedItems.find(exI => exI.id === item.id)) return;
    const selectedItems = select(item);
    onSelect && onSelect(selectedItems, item, checked);
  };

  const checkAndSetHeight = theRef => {
    if (theRef?.current) {
      const roundedTop = theRef.current.getBoundingClientRect().top.toFixed(0);

      setMinHeight(`calc(100vh - 32px - ${roundedTop}px)`);
      setMinHeightParent(`calc(100vh - ${roundedTop}px)`);

      //   reCalculateHeight && reCalculate(roundedTop);
    }
  };

  const reCalculate = (top = false) => {
    if (top !== false) {
      setHeightList(`calc(100vh - ${top}px - 70px)`);
    } else {
      const roundedTop = parentRef?.current
        ?.getBoundingClientRect()
        .top.toFixed(0);

      const footerSpacing = document.querySelector(
        '.c-card__footer-bottom-fixed'
      )
        ? 70
        : 0;
      return `calc(100vh - ${roundedTop}px - ${footerSpacing}px)`;
    }
  };

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      if (parentRef) {
        checkAndSetHeight(parentRef);
        variableHeight && reCalculate();
        clearInterval(interval);
      }
    }, 100);
  }, [parentRef]);

  if (!Array.isArray(items)) return null;

  const groupIsChecked = group => {
    return (
      selectedItems.find(s => s.id === group.id) ||
      (group.clients.length > 0 &&
        group.clients.every(client =>
          new Set([
            ...selected.map(s => s.id),
            ...excludedItems.map(exI => exI.id)
          ]).has(client.id)
        ))
    );
  };

  const isIndeterminateChecked = group => {
    return (
      group.clients.some(
        client =>
          selected.find(p => p.id === client.id) ||
          excludedItems.find(exI => exI.id === client.id)
      ) &&
      !group.clients.every(client =>
        [
          ...selected.map(s => s.id),
          ...excludedItems.map(exI => exI.id)
        ].includes(client.id)
      )
    );
  };

  const personIsChecked = person => {
    return (
      selected.find(p => p.id === person.id) ||
      excludedItems.find(exI => exI.id === person.id)
    );
  };

  if (items.length > 0) {
    return (
      <List
        ref={parentRef}
        virtualizedList
        onModal={onModal}
        style={{
          maxHeight: `${minHeightParent}`,
          height: withFixedFooter ? 'calc(100% - 64px)' : '100%'
        }}
        extraClassNames={extraClassNames}
      >
        {listHeader && (
          <ListHeader oneTitle>
            <ListColumnTitle>
              <FormattedMessage {...listHeader} />
            </ListColumnTitle>
          </ListHeader>
        )}
        {sortableListHeader && sortableListHeader}
        <ListBody
          style={{
            minHeight: !onModal && !reCalculateHeight && minHeight,
            height: reCalculateHeight
              ? reCalculate()
              : `${rowVirtualizer.totalSize}px`,
            position: 'relative',
            marginBottom: 0
          }}
        >
          {rowVirtualizer.virtualItems.map(virtualRow => {
            const item = items[virtualRow.index];

            const disabled = disabledItems.find(exI => exI.id === item.id);
            const excluded = excludedItems.find(exI => exI.id === item.id);

            let listData;

            if (item.constructor.modelName === 'Person') {
              listData = {
                titleLabel: 'fullName',
                statusLabel: item.namePrefix,
                checkbox: checkable && !excluded,
                visible:
                  (checkable && selected.length > 0 && !excluded) ||
                  (visible && !excluded),
                onClick: () => onClick && onClick(item, 'person'),
                checked: checkable && personIsChecked(item) && !excluded,
                meta:
                  (personStatus && (
                    <MetaWrapper>
                      <PersonStatusIcon item={item} onClick={onClickStatus} />
                    </MetaWrapper>
                  )) ||
                  (excluded && (
                    <MetaWrapper>
                      <Badge tag>
                        <FormattedMessage {...messages.excludedEntityLabel} />
                      </Badge>
                    </MetaWrapper>
                  ))
              };
            } else if (item.constructor.modelName === 'Group') {
              listData = {
                titleLabel: 'name',
                checkbox: checkableGroups,
                visible: (checkableGroups && selected.length > 0) || visible,
                checked: checkableGroups && groupIsChecked(item),
                indeterminate: isIndeterminateChecked(item),
                mediumIcon: false,
                icon: 'group',
                onClick: () =>
                  ((onClick && onlyGroups && item.subEntities.length > 0) ||
                    (onClick && !onlyGroups)) &&
                  onClick(item, 'group'),
                meta: (
                  <MetaWrapper>
                    <p className="c-list__meta">
                      <FormattedMessage
                        {...messages.modalListItemSportersGroupsCount}
                        values={{
                          athletes: (item.clients && item.clients.length) || 0,
                          groups: item.subEntities.length
                        }}
                      />
                    </p>
                  </MetaWrapper>
                )
              };
            }
            return (
              <VirtualListItem
                uid={uidSeed(item.id)}
                item={item}
                key={virtualRow.index}
                virtualRow={virtualRow}
                active={item.id === activeId}
                statusLabel={statusLabel}
                clickable={!excluded}
                outfaded={excluded}
                // clickable
                disabled={disabled}
                // excluded={excluded}
                onChange={e => onSelectHandler(item, e)}
                {...listData}
              />
            );
          })}
        </ListBody>
      </List>
    );
  }

  return (
    <Card centered>
      <CardBody empty>
        <Message emptyState={true} icon="cursor">
          <MessageText>
            <FormattedMessage
              {...(emptyMessage ? emptyMessage : messages.noAthletesOrGroups)}
            />
          </MessageText>
        </Message>
      </CardBody>
    </Card>
  );
};

export default VirtualizedEntityList;

const PersonStatusIcon = ({ item, onClick }) => {
  if (
    ![
      PersonStatus.EMAIL,
      PersonStatus.USER,
      PersonStatus.PENDING,
      PersonStatus.UNCONFIRMED
    ].includes(item.status)
  )
    return null;

  return (
    <ListItemLabel info middleColumn style={{ paddingRight: 24 }}>
      <PersonStatusPopper person={item} onClickStatus={onClick} />
    </ListItemLabel>
  );
};
