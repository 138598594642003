import React from 'react';
import classNames from 'classnames';

class MenuHeader extends React.Component {
  render() {
    const { title, subTitle } = this.props;
    return (
      <div className={classNames('c-menu__header')}>
        <h3 className="c-menu__title">{title}</h3>
        <p className="c-menu__subtitle">{subTitle}</p>
      </div>
    );
  }
}

export default MenuHeader;
