import FieldInput from 'components/input/FieldInput';
import messages from 'messages';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldDatePicker } from 'components/input/FieldDatePicker';
import { useFormikContext } from 'formik';

const LessonFormInput = () => {
  const { errors, touched } = useFormikContext();
  const intl = useIntl();

  return (
    <>
      <FieldInput
        id="title"
        name="title"
        type="text"
        placeholder={intl.formatMessage(messages.programPlaceholderName)}
        errors={errors}
        touched={touched}
        required
      >
        <FormattedMessage {...messages.exercisesLabelTitle} />
      </FieldInput>
      <FieldInput
        id="didactics"
        name="didactics"
        type="text"
        placeholder={intl.formatMessage(messages.programPlaceholderDidactics)}
        errors={errors}
        touched={touched}
        required
      >
        <FormattedMessage {...messages.lessonLabelDidactics} />
      </FieldInput>
      <FieldInput
        id="school"
        name="school"
        type="text"
        placeholder={intl.formatMessage(messages.lessonPlaceholderSchool)}
        errors={errors}
        touched={touched}
        required
      >
        <FormattedMessage {...messages.lessonLabelSchool} />
      </FieldInput>
      <FieldInput
        id="classroom"
        name="classroom"
        type="text"
        placeholder={intl.formatMessage(messages.lessonPlaceholderClassroom)}
        errors={errors}
        touched={touched}
        required
      >
        <FormattedMessage {...messages.lessonLabelClassroom} />
      </FieldInput>
      <FieldInput
        id="subject"
        name="subject"
        type="text"
        placeholder={intl.formatMessage(messages.lessonPlaceholderSubject)}
        errors={errors}
        touched={touched}
        required
      >
        <FormattedMessage {...messages.lessonLabelSubject} />
      </FieldInput>
      <FieldDatePicker
        id="lessonDate"
        name="lessonDate"
        icon="calendar"
        placeholder={'dd/mm/yyyy'}
        errors={errors}
        touched={touched}
        required
      >
        <FormattedMessage {...messages.lessonLabelLessonDate} />
      </FieldDatePicker>
    </>
  );
};

LessonFormInput.propTypes = {};

export default LessonFormInput;
