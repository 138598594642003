import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import ListItem from 'components/list/list-item/ListItem';
import ListItemLabelWrapper from 'components/list/list-item/ListItemLabelWrapper';
import ListItemLabel from 'components/list/list-item/ListItemLabel';

const ProgramsListItem = ({
  onClick,
  program: { id, title, persons, startdate, status, dateString },
  type,
  active
}) => {
  return (
    <ListItem
      active={active}
      programs
      done={status === 'done'}
      inProgress={status === 'inProgress'}
      onClick={onClick}
    >
      <ListItemLabelWrapper>
        <ListItemLabel label>{title}</ListItemLabel>
        {persons.length > 0 && type !== 'user' && (
          <ListItemLabel info middleColumn>
            {`${persons.length} `}
            <FormattedMessage {...messages.exercisesAssignSportersLabel} />
          </ListItemLabel>
        )}
        {startdate && <ListItemLabel info>{dateString}</ListItemLabel>}
      </ListItemLabelWrapper>
    </ListItem>
  );
};

export default ProgramsListItem;
