import ResultsTableHeader from './ResultsTableHeader';
import { Table, TableBody } from 'components/table';
import ResultsTableRow from 'containers/pages/results/table/ResultsTableRow';
import { useResultsPanelContext } from 'contexts/ResultsPanelContext';

const ResultsTable = () => {
  const { athletes, rows } = useResultsPanelContext();

  return (
    <Table benchmarkTable>
      <ResultsTableHeader />
      <TableBody>
        {athletes.map(user => (
          <ResultsTableRow key={user.id} user={user} row={rows[user.id]} />
        ))}
      </TableBody>
    </Table>
  );
};

export default ResultsTable;
