import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { useVirtual } from 'react-virtual';
import useSelectable from 'hooks/utils/useSelectable';
import { useUIDSeed } from 'react-uid';
import VirtualListItem from 'containers/partials/list-items/VirtualListItem';
import ListHeader from 'components/list/ListHeader';
import ListColumnTitle from 'components/list/ListColumnTitle';

const VirtualizedList = ({
  entityId,
  activeId,
  items = [],
  checkable,
  titleLabel = 'title',
  titleAttribute = 'title',
  subtitleLabel = 'subtitle',
  metaLabel = 'subtitle',
  supertitle = false,
  statusLabel,
  icon,
  mediumIcon,
  listHeader,
  sortableListHeader,
  onClick,
  onEdit = false,
  onDelete = false,
  onSelect,
  selectedItems,
  extraClassNames = {},
  onModal,
  withFixedFooter,
  meta
}) => {
  const parentRef = useRef();
  const uidSeed = useUIDSeed();
  const { selected, select, setSelected } = useSelectable([]);
  const [minHeight, setMinHeight] = useState(null);
  const [minHeightParent, setMinHeightParent] = useState(null);

  useEffect(() => {
    setSelected(selectedItems ? selectedItems.map(e => e.id) : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, selectedItems]);

  const rowVirtualizer = useVirtual({
    size: items?.length ?? 0,
    parentRef,
    estimateSize: useCallback(() => 56, []),
    overscan: 5
  });

  const onSelectHandler = (item, checked) => {
    const selectIds = select(item.id);
    onSelect && onSelect(selectIds, item, checked);
  };

  const checkAndSetHeight = theRef => {
    if (theRef?.current) {
      const roundedTop = theRef.current.getBoundingClientRect().top.toFixed(0);

      setMinHeight(`calc(100vh - 32px - ${roundedTop}px)`);
      setMinHeightParent(`calc(100vh - ${roundedTop}px)`);
    }
  };

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      if (parentRef) {
        checkAndSetHeight(parentRef);
        clearInterval(interval);
      }
    }, 1000);
  }, [parentRef]);

  if (!Array.isArray(items)) return null;

  if (items.length > 0) {
    return (
      <List
        ref={parentRef}
        virtualizedList
        style={{
          maxHeight: `${minHeightParent}`,
          height: withFixedFooter ? 'calc(100% - 64px)' : '100%',
          paddingLeft: 16,
          paddingRight: 16,
          marginLeft: -16,
          marginRight: -16
        }}
        extraClassNames={extraClassNames}
      >
        {listHeader && (
          <ListHeader oneTitle>
            <ListColumnTitle>
              <FormattedMessage {...listHeader} />
            </ListColumnTitle>
          </ListHeader>
        )}
        {sortableListHeader && sortableListHeader}
        <ListBody
          style={{
            minHeight: !onModal && minHeight,
            height: `${rowVirtualizer.totalSize}px`,
            position: 'relative',
            marginBottom: 0
          }}
        >
          {rowVirtualizer.virtualItems.map(virtualRow => {
            const item = items[virtualRow.index];

            return (
              <VirtualListItem
                mediumIcon={mediumIcon}
                icon={icon}
                entityId={entityId}
                uid={uidSeed(item.id)}
                key={virtualRow.index}
                virtualRow={virtualRow}
                item={item}
                titleLabel={titleLabel}
                titleAttribute={titleAttribute}
                subtitleLabel={subtitleLabel}
                supertitle={supertitle}
                statusLabel={statusLabel}
                metaLabel={metaLabel}
                active={item.id === activeId}
                visible={checkable && selected.length > 0}
                checkbox={checkable}
                clickable={!!onClick}
                checked={checkable && selected.includes(item.id)}
                onChange={e => onSelectHandler(item, e)}
                onClick={() => onClick && onClick(item)}
                onEdit={onEdit}
                onDelete={onDelete}
                meta={meta}
                outfaded={
                  item.type === 'person_doc_application' &&
                  !item?.sharedWithAthlete
                }
              />
            );
          })}
        </ListBody>
      </List>
    );
  }

  return (
    <Card centered>
      <CardBody empty>
        <Message emptyState={true} icon="cursor">
          <MessageText>
            <FormattedMessage {...messages.listEmpty} />
          </MessageText>
        </Message>
      </CardBody>
    </Card>
  );
};

export default VirtualizedList;
