import * as Sentry from '@sentry/react';
import * as cognito from './cognito';
import * as userQuery from './user-query';
import * as appSync from './app-sync';
import { RESET_PASSWORD_URL } from 'constants.js';

/**
 * Config & connect cognito
 */
export const connect = async () => cognito.connect();

export const setClient = async () => appSync.setClient();

export const resetPassword = async email => {
  let response = { error: { message: 'Something went wrong!' } };
  try {
    const postData = { email };
    response = await fetch(RESET_PASSWORD_URL, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify(postData)
    });
  } catch (er) {}
  return response;
};

/**
 * Login user to cognito
 * Then create AppSyncClient to get data
 */
export const login = async (email, password) => {
  let userData;
  try {
    const cognitoUser = await cognito.loginWithEmailAndPassword(
      email.toLowerCase(),
      password
    );
    if (
      cognitoUser.challengeName &&
      cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED'
    ) {
      userData = { newPasswordRequired: true, cognitoUser };
    } else {
      await cognito.getCurrentSession();
      userData = await getCurrentUser();
    }
  } catch (error) {
    Sentry.captureException(error);
    userData = { error };
  }
  return userData;
};

export const loginWithNewPassword = async (cognitoTempUser, password) => {
  let userData;
  try {
    await cognito.completeNewPassword(cognitoTempUser, password);
    await cognito.getCurrentSession();
    userData = await getCurrentUser();
  } catch (error) {
    Sentry.captureException(error);
    userData = { error };
  }
  return userData;
};
/**
 * Get current user (when authenticated)
 */
export const getCurrentUser = async () => {
  let userData;
  try {
    await cognito.getCurrentAuthenticatedUser();
    await appSync.setClient();
    userData = await getMe();
    if (!userData.data.getMe) {
      userData = { error: { message: 'api-error' } };
    }
  } catch (error) {
    Sentry.captureException(error);
    userData = { error };
  }
  return userData;
};

/**
 * Gets my user data
 */
export const getMe = async () => userQuery.getMe();

export const getRoles = async entityId => {
  let rolesData;
  try {
    const response = await userQuery.getRoles(entityId);
    rolesData = JSON.parse(response.data.getEntityTree);
  } catch (error) {
    rolesData = { error };
  }
  return rolesData;
};

export const getMeEntities = async () => {
  let entities;
  try {
    const response = await userQuery.getMeEntities();
    entities = response.data.getMe.entities;
  } catch (error) {
    entities = { error };
  }
  return entities;
};

export const getEntity = async entityId => {
  try {
    const response = await userQuery.getEntity(entityId);
    return response.data.getEntity;
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const getPersonEntity = async (personId, entityId) => {
  try {
    const response = await userQuery.getPersonEntity(personId, entityId);
    return response.data.getPersonEntity;
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const createLogin = personId => userQuery.createLogin(personId);
/**
 * Logout user
 */
export const logout = async () => {
  try {
    await cognito.signOut();
  } catch (error) {
    Sentry.captureException(error);
  }
};

/**
 * Signup
 */
// export const signup = async (email, password, lastName, firstName) => {};
