import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Route, Switch, Redirect } from 'react-router-dom';

import {
  ROUTE_SCANNERS_ENTITY,
  ROUTE_SCANNERS_ENTITY_ADD,
  ROUTE_SCANNERS_ENTITY_SCANNER,
  ROUTE_SCANNERS_ENTITY_SCANNER_EDIT,
  ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS,
  ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_ADD,
  ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION,
  ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION_EDIT,
  ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION_RESULT,
  ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION_RESULT_EDIT,
  ROUTE_SCANNERS_ENTITY_SCANNER_DIAGRAM
} from 'routes/RouteList';

import Overview from './Overview';

import AddScanner from './AddScanner';
import Panel from 'components/panel/Panel';
import messages from 'messages';
import Scanner from './Scanner';
import AddCalibration from './AddCalibration';
import Calibration from './Calibration';

class Scanners extends Component {
  render() {
    const {
      intl,
      authStore: { user }
    } = this.props;
    return (
      <Fragment>
        <Switch>
          <Route
            exact
            path={ROUTE_SCANNERS_ENTITY}
            render={props => (
              <Overview
                entityId={props.match.params.entityId}
                active={ROUTE_SCANNERS_ENTITY === props.match.path}
              />
            )}
          />
          <Route
            path={ROUTE_SCANNERS_ENTITY_SCANNER}
            render={props => (
              <Overview
                active={false}
                entityId={props.match.params.entityId}
                scannerId={props.match.params.scannerId}
              />
            )}
          />
          <Redirect
            to={ROUTE_SCANNERS_ENTITY.replace(':entityId', user.rootEntityId)}
          />
        </Switch>
        <Route
          exact
          path={ROUTE_SCANNERS_ENTITY}
          render={props => (
            <Panel
              emptyState
              emptyLabel={intl.formatMessage(messages.scannersEmptyPanel)}
            />
          )}
        />

        <Switch>
          <Route
            path={ROUTE_SCANNERS_ENTITY_ADD}
            render={props => (
              <AddScanner entityId={props.match.params.entityId} />
            )}
          />

          <Route
            exact
            path={ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS}
            render={props => (
              <Scanner
                active={true}
                entityId={props.match.params.entityId}
                scannerId={props.match.params.scannerId}
                tabIndex={1}
              />
            )}
          />

          <Route
            exact
            path={ROUTE_SCANNERS_ENTITY_SCANNER_DIAGRAM}
            render={props => (
              <Scanner
                active={true}
                entityId={props.match.params.entityId}
                scannerId={props.match.params.scannerId}
                tabIndex={2}
              />
            )}
          />
          <Route
            path={ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION}
            render={props => (
              <Scanner
                active={true}
                entityId={props.match.params.entityId}
                scannerId={props.match.params.scannerId}
                calibrationId={props.match.params.calibrationId}
                tabIndex={1}
              />
            )}
          />

          <Route
            path={ROUTE_SCANNERS_ENTITY_SCANNER_EDIT}
            render={props => (
              <Scanner
                active={true}
                entityId={props.match.params.entityId}
                scannerId={props.match.params.scannerId}
                edit
                tabIndex={0}
              />
            )}
          />

          <Route
            path={ROUTE_SCANNERS_ENTITY_SCANNER}
            render={props => (
              <Scanner
                active={true}
                entityId={props.match.params.entityId}
                scannerId={props.match.params.scannerId}
                tabIndex={0}
              />
            )}
          />
        </Switch>

        <Switch>
          <Route
            path={ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION_EDIT}
            render={props => (
              <Calibration
                active={true}
                entityId={props.match.params.entityId}
                scannerId={props.match.params.scannerId}
                calibrationId={props.match.params.calibrationId}
                edit
              />
            )}
          />
          <Route
            path={
              ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION_RESULT_EDIT
            }
            render={props => (
              <Calibration
                tabIndex={1}
                active={true}
                entityId={props.match.params.entityId}
                scannerId={props.match.params.scannerId}
                calibrationId={props.match.params.calibrationId}
                edit
              />
            )}
          />
          <Route
            path={ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION_RESULT}
            render={props => (
              <Calibration
                tabIndex={1}
                active={true}
                entityId={props.match.params.entityId}
                scannerId={props.match.params.scannerId}
                calibrationId={props.match.params.calibrationId}
              />
            )}
          />
          <Route
            path={ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_ADD}
            render={props => (
              <AddCalibration
                active={true}
                entityId={props.match.params.entityId}
                scannerId={props.match.params.scannerId}
              />
            )}
          />
          <Route
            path={ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION}
            render={props => (
              <Calibration
                active={true}
                entityId={props.match.params.entityId}
                scannerId={props.match.params.scannerId}
                calibrationId={props.match.params.calibrationId}
              />
            )}
          />
        </Switch>
        {/*
          <Switch>
            <Route
              path={ROUTE_SESSIONS_ENTITY_ADD}
              render={props => (
                <AddSession
                  entityId={props.match.params.entityId}
                  active={ROUTE_SESSIONS_ENTITY_ADD === props.match.path}
                />
              )}
            />
            <Switch>
              <Route
                exact
                path={ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS}
                render={props => (
                  <Session
                    id="session1"
                    entityId={props.match.params.entityId}
                    sessionId={props.match.params.sessionId}
                    active={
                      ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS === props.match.path
                    }
                    tabIndex={1}
                  />
                )}
              />
              <Route
                path={ROUTE_SESSIONS_ENTITY_SESSION_SPORTER}
                render={props => (
                  <Session
                    id="session2"
                    entityId={props.match.params.entityId}
                    sessionId={props.match.params.sessionId}
                    sporterId={props.match.params.sporterId}
                    active={false}
                    tabIndex={1}
                  />
                )}
              />
              <Route
                path={ROUTE_SESSIONS_ENTITY_SESSION_EDIT}
                render={props => (
                  <Session
                    id="session3"
                    entityId={props.match.params.entityId}
                    sessionId={props.match.params.sessionId}
                    active={
                      ROUTE_SESSIONS_ENTITY_SESSION_EDIT === props.match.path
                    }
                    tabIndex={0}
                    edit
                  />
                )}
              />
              <Route
                path={ROUTE_SESSIONS_ENTITY_SESSION}
                render={props => (
                  <Session
                    id="session4"
                    entityId={props.match.params.entityId}
                    sessionId={props.match.params.sessionId}
                    active={ROUTE_SESSIONS_ENTITY_SESSION === props.match.path}
                    tab={0}
                  />
                )}
              />
            </Switch>
          </Switch>
          <Switch>
            <Route
              path={ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL}
              render={props => (
                <Sporter
                  entityId={props.match.params.entityId}
                  sessionId={props.match.params.sessionId}
                  sporterId={props.match.params.sporterId}
                  active={
                    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL ===
                    props.match.path
                  }
                  tab={1}
                />
              )}
            />
            <Route
              path={ROUTE_SESSIONS_ENTITY_SESSION_ADD_SPORTER}
              render={props => (
                <AssignSporterCard
                  entityId={props.match.params.entityId}
                  sessionId={props.match.params.sessionId}
                />
              )}
            />
            <Route
              path={ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_EDIT}
              render={props => (
                <Sporter
                  entityId={props.match.params.entityId}
                  sessionId={props.match.params.sessionId}
                  sporterId={props.match.params.sporterId}
                  active={
                    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_EDIT ===
                    props.match.path
                  }
                  tab={1}
                  edit
                />
              )}
            />
            <Route
              path={ROUTE_SESSIONS_ENTITY_SESSION_SPORTER}
              render={props => (
                <Sporter
                  entityId={props.match.params.entityId}
                  sessionId={props.match.params.sessionId}
                  sporterId={props.match.params.sporterId}
                  active={
                    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER === props.match.path
                  }
                  tab={0}
                />
              )}
            />
          </Switch> */}
      </Fragment>
    );
  }
}

export default withRouter(
  injectIntl(inject('uiState', 'authStore')(observer(Scanners)))
);
