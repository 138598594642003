import Exercise from 'models/Exercise';
import Annotation from 'models/Annotation';

export default class Video extends Exercise {
  person;
  shares;
  finished = false;
  constructor({
    id,
    entityId,
    title = '',
    protocol = '',
    entity = {},
    media = null,
    level = 0,
    note = '',
    remarks = '',
    cues = [],
    tags = [],
    editable,
    templateCount = 0,
    access = '',
    meta = null,
    date_created,
    shares = [],
    annotations = [],
    person = {}
  }) {
    super({
      id,
      entityId,
      title,
      protocol,
      entity,
      media,
      level,
      note,
      remarks,
      cues,
      tags,
      editable,
      templateCount,
      access,
      meta,
      date_created,
      annotations
    });

    this.annotations = annotations.map(a => new Annotation(a));
    this.person = person;
    this.shares = shares ?? [];

    if (this.meta) {
      this.finished = this.meta.finished ?? false;
    }
  }

  isMine(user) {
    if (this.person) {
      return this.person.id === user.id;
    }
  }

  static get modelName() {
    return 'Video';
  }
}
