import React from 'react';
import classNames from 'classnames';

const MenuItem = props => {
  const handleClick = e => {
    e.stopPropagation();

    const { onClick } = props;
    if (onClick) onClick(e);
  };

  return (
    <div
      className={classNames('c-menu__item')}
      onMouseDown={e => handleClick(e)}
    >
      {props.children}
    </div>
  );
};

export default MenuItem;
