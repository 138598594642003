import React, { useContext } from 'react';
import { StoreContext } from 'index';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper,
  ListItemStatus
} from 'components/list/list-item';
import { Button, ButtonsGroup } from 'components/button';
import Icon from 'components/icon/Icon';
import RemoveExerciseTemplateButton from '../buttons/RemoveExerciseTemplateButton';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { TemplateType } from 'constants.js';

const TemplatesListItem = ({
  id,
  template,
  template: { title },
  checkbox,
  active,
  clickable,
  onClick,
  onChange,
  onEdit,
  onUse,
  onDeleted,
  entityId,
  exercisesLength,
  visible,
  icon,
  mediumIcon,
  style,
  checked
}) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);

  return (
    <ListItem
      active={active}
      checkbox={checkbox}
      onClick={onClick}
      clickable={clickable}
      style={style}
      draggable
    >
      {icon !== null && (
        <ListItemStatus
          itemId={id}
          visible={visible}
          checked={checked}
          onChange={onChange}
          mediumIcon={mediumIcon}
          statusIcon={icon ? icon : 'group'}
        />
      )}
      <ListItemLabelWrapper>
        <ListItemLabel>
          <span>{title}</span>
        </ListItemLabel>
        {user.isGSVOrg && !isNaN(exercisesLength) ? (
          <ListItemLabel info>
            <FormattedMessage
              {...messages.exercisesLengthInListItem}
              values={{
                this: exercisesLength
              }}
            />
          </ListItemLabel>
        ) : (
          <ButtonsGroup>
            {onUse && (
              <Button
                tiny
                onClick={onUse}
                extraClassNames="u-margin-right-tiny"
              >
                <Icon id="use-template" smallMediumIcon />
              </Button>
            )}
            {onEdit && (
              <Button tiny onClick={onEdit}>
                <Icon id="edit" />
              </Button>
            )}
            {onDeleted && (
              <RemoveExerciseTemplateButton
                tiny
                templateId={template.id}
                collectionType={TemplateType.TEMPLATE}
                entityId={entityId}
                onDeleted={onDeleted}
              />
            )}
          </ButtonsGroup>
        )}
      </ListItemLabelWrapper>
    </ListItem>
  );
};

export default TemplatesListItem;
