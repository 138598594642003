import { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from 'components/table';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { Button, ButtonsGroup } from 'components/button';
import Icon from 'components/icon/Icon';
import {
  hasRowOverlapping,
  hasRowValidAge
} from 'containers/pages/settings/benchmarks/table.utils';
import { getEmptyRow, useDataTable } from 'hooks/utils/useDataTable';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import InputErrorMessage from 'components/input/InputErrorMessage';
import FieldInput from 'components/input/FieldInput';

function RangeDataTable({ edit, dataTable, dataKeys, loading, ...props }) {
  const intl = useIntl();
  const [state, dispatch] = useDataTable({ dataKeys, dataTable });
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (dataTable.length > 0) {
      dispatch({
        type: 'setDataTable',
        payload: {
          dataTable: edit ? [...dataTable, getEmptyRow(dataKeys)] : dataTable
        }
      });
    }
  }, [dataTable, edit]);

  const onClickCell = (row, col) => {
    dispatch({
      type: 'onClick',
      payload: { row, col }
    });
  };

  const onChange = (e, rowData, key) => {
    dispatch({
      type: 'onChange',
      payload: { value: e.target.value, rowData, key }
    });
  };

  const onBlur = (e, rowData, key) => {
    dispatch({
      type: 'onBlur',
      payload: { value: e.target.value, rowData, key }
    });
  };

  const onFocus = (e, row, col) => {
    dispatch({
      type: 'onFocus',
      payload: { row, col }
    });
  };

  const onRowDelete = rowData => {
    dispatch({
      type: 'onDelete',
      payload: { rowData }
    });
  };

  const onSubmit = () => {
    const errors = [];
    state.dataTable.forEach((row, i) => {
      if (row.empty) return;

      if (!hasRowValidAge(row)) {
        errors.push({
          row: i,
          error: intl.formatMessage(
            messages.benchmarksBenchmarkTestErrorInValidAge
          )
        });
        return;
      }

      if (hasRowOverlapping(state.dataTable, row)) {
        errors.push({
          row: i,
          error: intl.formatMessage(
            messages.benchmarksBenchmarkTestErrorOverlappingAge
          )
        });
      }
    });

    if (errors.length) {
      setErrors({ errors });
      return;
    }

    setErrors(null);
    const jsonToSave = state.dataTable.filter(r => !r.empty);
    const sortedArray = sort(jsonToSave, {
      keys: [{ key: 'age_min', dataType: SORT_DATA_TYPES.NUMBER }]
    });
    props.onSubmit && props.onSubmit(sortedArray);
  };

  const onCancel = () => {
    props.onCancel();
  };

  return (
    <>
      <div
        style={{
          overflow: 'auto',
          maxHeight: edit ? 'calc(100vh - 260px)' : 'calc(100vh - 210px)',
          width: '100%',
          marginBottom: 16
        }}
      >
        <Table editable fixed extraClassNames="c-table--custom" isEdit={edit}>
          <TableHeader>
            <TableRow>
              <TableHeaderCell colSpan="2">
                <FormattedMessage
                  {...messages.benchmarksBenchmarkTestTableAgeGroup}
                />
              </TableHeaderCell>
              <TableHeaderCell colSpan="10">
                <FormattedMessage
                  {...messages.benchmarksBenchmarkTestTableMale}
                />
              </TableHeaderCell>
              <TableHeaderCell colSpan="10">
                <FormattedMessage
                  {...messages.benchmarksBenchmarkTestTableFemale}
                />
              </TableHeaderCell>
            </TableRow>
            <TableRow>
              <TableHeaderCell>
                <FormattedMessage
                  {...messages.benchmarksBenchmarkTestTableMin}
                />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage
                  {...messages.benchmarksBenchmarkTestTableMax}
                />
              </TableHeaderCell>
              <TableHeaderCell
                colSpan="2"
                extraClassNames="c-table-celll--color c-table-cell--color-red"
              />
              <TableHeaderCell
                colSpan="2"
                extraClassNames="c-table-celll--color c-table-cell--color-orange"
              />
              <TableHeaderCell
                colSpan="2"
                extraClassNames="c-table-celll--color c-table-cell--color-yellow"
              />
              <TableHeaderCell
                colSpan="2"
                extraClassNames="c-table-celll--color c-table-cell--color-lime"
              />
              <TableHeaderCell
                colSpan="2"
                extraClassNames="c-table-celll--color c-table-cell--color-green"
              />
              <TableHeaderCell
                colSpan="2"
                extraClassNames="c-table-celll--color c-table-cell--color-red"
              />
              <TableHeaderCell
                colSpan="2"
                extraClassNames="c-table-celll--color c-table-cell--color-orange"
              />
              <TableHeaderCell
                colSpan="2"
                extraClassNames="c-table-celll--color c-table-cell--color-yellow"
              />
              <TableHeaderCell
                colSpan="2"
                extraClassNames="c-table-celll--color c-table-cell--color-lime"
              />
              <TableHeaderCell
                colSpan="2"
                extraClassNames="c-table-celll--color c-table-cell--color-green"
              />
            </TableRow>
          </TableHeader>
          <TableBody>
            {state.dataTable.map((rowData, iRow) => {
              if (!edit && rowData.empty) return null;
              const hasError =
                !hasRowValidAge(rowData) ||
                hasRowOverlapping(state.dataTable, rowData);
              return (
                <TableRow hasError={hasError} key={iRow} edit={rowData.empty}>
                  {dataKeys.map((key, iCol) => {
                    const activeCell =
                      iCol === state.activeCol && iRow === state.activeRow;
                    return (
                      <>
                        {(key === 'm1' || key === 'f1') && (
                          <TableCell extraClassNames="c-table-cell--empty" />
                        )}
                        <TableCell
                          key={key + iRow + iCol}
                          colSpan={
                            key.startsWith('m') || key.startsWith('f')
                              ? '2'
                              : ''
                          }
                          extraClassNames={[
                            activeCell ? 'is-edit' : '',
                            key === 'm1' || key === 'f1'
                              ? 'c-table-cell--first'
                              : '',
                            key === 'm4' || key === 'f4'
                              ? 'c-table-cell--last'
                              : ''
                          ]}
                          onClick={() => edit && onClickCell(iRow, iCol)}
                        >
                          <div>
                            {edit && (
                              <FieldInput
                                id={`input-${iRow}-${iCol}`}
                                inTable
                                defaultValue={
                                  rowData[key] ? `${rowData[key]}` : ``
                                }
                                readOnly={!activeCell}
                                onChange={e => onChange(e, rowData, key)}
                                onBlur={e => onBlur(e, rowData, key)}
                                onFocus={e => onFocus(e, iRow, iCol)}
                                autoFocus={activeCell}
                                hasError={!rowData.empty && hasError && edit}
                              />
                            )}
                            {!edit && rowData[key]}
                          </div>
                        </TableCell>
                        {(key === 'm4' || key === 'f4') && (
                          <TableCell extraClassNames="c-table-cell--empty" />
                        )}
                      </>
                    );
                  })}
                  {!rowData.empty && edit && (
                    <TableCell>
                      <Button
                        tiny
                        tabIndex={-1}
                        type="button"
                        onClick={() => onRowDelete(rowData)}
                      >
                        <Icon id="delete" strokeColor="color-neutral-dark" />
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      {errors &&
        !!errors.length &&
        errors.map((e, i) => (
          <InputErrorMessage key={i}>
            <FormattedMessage
              {...messages.errorOnRow}
              values={{
                message: `${e.row + 1}: ${e.error}`
              }}
            />
          </InputErrorMessage>
        ))}

      {edit && (
        <ButtonsGroup>
          <Button
            extraClassNames="u-margin-left-auto"
            secondary
            onClick={onCancel}
            type="button"
            disabled={loading}
          >
            <FormattedMessage {...messages.benchmarksBenchmarkButtonCancel} />
          </Button>
          <Button
            type="submit"
            primary
            disabled={loading}
            onClick={e => onSubmit(e, state.dataTable)}
          >
            <FormattedMessage {...messages.benchmarksBenchmarkButtonSave} />
          </Button>
        </ButtonsGroup>
      )}
    </>
  );
}
export default RangeDataTable;
