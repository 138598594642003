import React, { useContext } from 'react';
import Icon from 'components/icon/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Button from 'components/button/Button';
import { downloadSkReports } from 'services/aws/session-query';
import { MODAL_TYPES } from 'models/ModalData';
import { StoreContext } from 'index';

const DownloadSessionsReportButton = ({ session }) => {
  const intl = useIntl();
  const {
    uiState,
    authStore: { user }
  } = useContext(StoreContext);

  const downloadSkSessionReports = async sessionId => {
    try {
      const query = await downloadSkReports(sessionId, true);
      if (query) {
        return query.data.downloadSkReports;
      }
    } catch (error) {
      if (error.message === 'no_reports_available') {
        uiState.showModal({
          title: intl.formatMessage(
            messages.titleSessionSKReportDownloadFailed
          ),
          message: intl.formatMessage(
            messages.messageSessionAllReportsDownloadFailedNotAvailable
          ),
          dismissButton: false,
          type: MODAL_TYPES.ALERT
        });
      }
      return false;
    }
  };

  const downloadAllReports = async () => {
    let response;
    if (!session.isTestSetV2 && (session.isILike || session.isIDo)) {
      response = await downloadSkSessionReports(session.id);
    }

    if (response) {
      uiState.showModal({
        title: intl.formatMessage(messages.titleSessionAllReportsMailSuccess),
        message: intl.formatMessage(
          messages.messageSessionAllReportsMailSuccess
        ),
        dismissButton: false,
        type: MODAL_TYPES.ALERT
      });
    }
  };

  if (!session.isIDo || session.isHan || user.isHanOrg) {
    return <></>;
  }

  return (
    <Button menu onClick={downloadAllReports}>
      <Icon id="download" />
      <FormattedMessage {...messages.menuItemSessionDownloadAllReports} />
    </Button>
  );
};

export default DownloadSessionsReportButton;
