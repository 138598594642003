import React, { useCallback, useContext, useEffect, useState } from 'react';
import useUserPool from 'hooks/queries/useUserPool';
import messages from 'messages';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import {
  ROUTE_SPORTERS_ENTITY_POOL,
  ROUTE_SPORTERS_ENTITY_SPORTER
} from 'routes/RouteList';
import { StoreContext } from 'index';
import Loader from 'components/loader/Loader';
import { getFilteredUsers, useGroupsContext } from 'contexts/GroupsContext';
import Person from 'models/Person';
import { useMenuContext } from 'contexts/MenuContext';
import Button from 'components/button/Button';
import { SPORTER_ACTION } from 'containers/partials/modals/CopySporterModal';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/icon/Icon';
import VirtualizedEntityList from 'containers/pages/sporters/VirtualizedEntityList';
import RemovePersonsFromOrgButton from 'containers/partials/buttons/RemovePersonsFromOrgButton';
import useSelectable from 'hooks/utils/useSelectable';
import InviteSportersButton from 'containers/partials/buttons/InviteSportersButton';
import * as Sentry from '@sentry/react';

function PersonsPoolTab({
  onSelected,
  onClickPersonStatus,
  setCopyModal,
  setImportModal,
  ...props
}) {
  const {
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const { push, replace } = useHistory();
  const { entityId, sporterId } = useParams();
  const { selected, setSelected } = useSelectable();
  const { addMenuItems, removeAllMenuItems } = useMenuContext();
  const [persons, setPersons] = useState([]);
  const { loading, personPool, getPool } = useUserPool({
    entityId: rootEntityId
  });
  const {
    state: { filter }
  } = useGroupsContext();

  useEffect(() => {
    const loadPool = async () => {
      await getPool();
    };
    loadPool().catch(error => {
      Sentry.captureException(error);
    });
    return () => {
      setSelected([]);
      removeAllMenuItems();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.selected.length === 0) {
      setSelected([]);
    }
  }, [props.selected]);

  useEffect(() => {
    setPersons(
      getFilteredUsers([...personPool], filter.search).map(
        person => new Person(person)
      )
    );
  }, [filter, personPool]);

  useEffect(() => {
    removeAllMenuItems();
    const menuItems = [];

    if (selected.length > 0) {
      menuItems.push(
        <Button
          key={'copySporters'}
          menu
          onClick={() => setCopyModal(SPORTER_ACTION.COPY_POOL, selected)}
        >
          <FormattedMessage {...messages.buttonSportersCopySportersFromPool} />
          <Icon id="copy" />
        </Button>,
        <RemovePersonsFromOrgButton
          key={'removeSporters'}
          entityId={rootEntityId}
          ids={selected.map(p => p.id)}
          menu
          onComplete={onPersonsRemoved}
        >
          <FormattedMessage
            {...messages.menuItemSporterRemoveSporters}
            values={{ count: selected.length }}
          />
        </RemovePersonsFromOrgButton>,
        <InviteSportersButton
          key={'invitePersons'}
          menu
          entityId={rootEntityId}
          persons={selected}
        >
          <FormattedMessage {...messages.buttonSportersInvite} />
          <Icon id="email" />
        </InviteSportersButton>
      );
    }

    menuItems.push(
      <Button key={'importPersons'} menu onClick={() => setImportModal(true)}>
        <FormattedMessage {...messages.buttonSportersImportSporters} />
        <Icon id="add-user" />
      </Button>
    );

    addMenuItems(menuItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const onPersonsRemoved = useCallback(async () => {
    await getPool({
      variables: { entityId: rootEntityId },
      fetchPolicy: 'network-only'
    });
    setSelected([]);
    replace(generatePath(ROUTE_SPORTERS_ENTITY_POOL, { entityId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelected]);

  const onSelectSportersHandler = selected => {
    setSelected(selected);
    onSelected && onSelected(selected);
  };

  if (loading) return <Loader />;

  return (
    <VirtualizedEntityList
      entityId={entityId}
      items={persons}
      checkable
      personStatus
      activeId={sporterId}
      onClick={person => {
        if (person.id !== sporterId) {
          push(
            generatePath(ROUTE_SPORTERS_ENTITY_SPORTER, {
              entityId,
              sporterId: person.id
            })
          );
        }
      }}
      onClickStatus={person => onClickPersonStatus(person)}
      selectedItems={selected}
      onSelect={selected => {
        onSelectSportersHandler(selected);
      }}
    />
  );
}

export default PersonsPoolTab;
