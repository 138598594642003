import React, { useContext } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL } from 'routes/RouteList';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import {
  MUTATION_REMOVE_PERSON_INJURY,
  QUERY_GET_PERSON_MEDICALS
} from 'services/aws/injury-query';
import { StoreContext } from 'index';

const RemovePersonIllnessButton = ({
  entityId,
  personIllnessId,
  personId,
  children,
  ...props
}) => {
  const { push } = useHistory();
  const {
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_REMOVE_PERSON_INJURY}
      confirmTitle={messages.modalConfirmRemovePersonIllnessTitle}
      confirmMessage={messages.modalConfirmRemovePersonIllnessMessage}
      variables={{
        personInjuryId: personIllnessId
      }}
      refetchQueries={[
        {
          query: QUERY_GET_PERSON_MEDICALS,
          variables: { entityId: rootEntityId, personId }
        }
      ]}
      update={(cache, { data: { archivePersonInjury } }) => {
        if (archivePersonInjury) {
          push(
            generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL, {
              entityId,
              sporterId: personId
            })
          );
        }
      }}
    >
      <Icon id="delete" strokeColor="color-neutral-dark" />
      {children}
    </ConfirmMutationButton>
  );
};

export default RemovePersonIllnessButton;
