import React from 'react';
import classNames from 'classnames';
import './Menu.scss';

class Menu extends React.Component {
  render() {
    const { children, isOpen, organisation, ...props } = this.props;
    return (
      <div
        className={classNames('c-menu', {
          'c-menu--open': isOpen,
          'c-menu--open-organisations': organisation
        })}
        {...props}
      >
        {children}
      </div>
    );
  }
}

export default Menu;
