import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_GET_ANNOTATIONS } from 'services/aws/annotations-query';
import Annotation from 'models/Annotation';
import { sort, SORT_DATA_TYPES } from 'utils/sort';

const useAnnotations = (video, user) => {
  const [annotations, setAnnotations] = useState(null);
  const { loading, error, data, refetch } = useQuery(QUERY_GET_ANNOTATIONS, {
    variables: { linkId: video.id },
    skip: !video.id
  });

  useEffect(() => {
    if (data?.getAnnotations) {
      let annotationsData = data.getAnnotations.map(
        a => new Annotation({ ...a })
      );

      annotationsData = sort(annotationsData, {
        keys: [
          {
            key: 'starttime',
            dataType: SORT_DATA_TYPES.NUMBER
          }
        ]
      });

      annotationsData.map(annotation => {
        if (!annotation.person) return null;
        const ownershipClasses = {
          own:
            annotation.isByAthlete(user) &&
            video.person.id === annotation.person.id,
          other:
            annotation.isByAthlete(user) &&
            video.person.id !== annotation.person.id,
          supervisor: annotation.isByAdmin(user),
          'own-supervised':
            (video.person.id === annotation.person.id &&
              annotation?.notes?.length > 0) ||
            (video.person.id === annotation.person.id && annotation.isChecked())
        };
        annotation.iconClasses = Object.keys(ownershipClasses).filter(
          k => ownershipClasses[k]
        );
        return annotation;
      });

      setAnnotations(annotationsData);
    }
  }, [data, video?.id]);

  return { annotations, loading, error, refetch };
};

export default useAnnotations;
