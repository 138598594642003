import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import Select, { Option } from 'components/input/Select';
import SportSelect from 'containers/partials/inputs/SportSelect';
import { PreventionType, TestSetV2Type } from 'constants.js';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_TESTS_BY_SPORT_AND_INJURIES } from 'services/aws/testsets-query';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import FieldInput from 'components/input/FieldInput';

function PreventionDetailExtras({ edit, entityId }) {
  const intl = useIntl();
  const {
    values: session,
    errors,
    touched,
    setFieldValue
  } = useFormikContext();
  const [preventionType, setPreventionType] = useState(session?.preventionType);
  const [sport, setSport] = useState(session?.sport);

  useEffect(() => {
    if (session?.sport) {
      setSport(session.sport);
    }
    if (session?.preventionType) {
      setPreventionType(session.preventionType);
    }
  }, [session]);

  const [getTests, { data: testsData }] = useLazyQuery(
    QUERY_GET_TESTS_BY_SPORT_AND_INJURIES,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  useEffect(() => {
    if (session?.sport && session.id === null) {
      getTests({
        variables: {
          type: TestSetV2Type.PREVENTION,
          sportId: session.sport.id,
          preventionType: session.preventionType
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.sport, session.preventionType]);

  useEffect(() => {
    // Only add all tests if we have a basic test
    if (
      testsData?.getTestsBySportAndInjuries &&
      session.preventionType === PreventionType.BASIC &&
      session.tests.length === 0
    ) {
      setFieldValue('tests', [...testsData.getTestsBySportAndInjuries]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testsData]);

  return (
    <>
      <div className="o-layout o-layout--small">
        <div className="o-layout__item u-1-of-2">
          <Select
            label={intl.formatMessage(messages.preventionTypeSelect) + ' *'}
            id="preventionType"
            name="preventionType"
            value={preventionType}
            onChange={select => {
              setFieldValue('preventionType', select.value);
            }}
            required
            readOnly={!edit}
            placeholder={intl.formatMessage(
              messages.preventionTypeSelectPlaceholder
            )}
            errors={errors}
            touched={touched}
          >
            <Option
              value={PreventionType.BASIC}
              label={intl.formatMessage(messages.preventionBasicOption)}
            />
            <Option
              value={PreventionType.FULL}
              label={intl.formatMessage(messages.preventionFullOption)}
            />
          </Select>
        </div>
        <div className="o-layout__item u-1-of-2">
          <FieldInput
            disabled
            readOnly={!edit}
            id="benchmark-id"
            name="benchmarkId"
            defaultValue={intl.formatMessage(messages.preventionBenchmarkValue)}
          >
            <FormattedMessage {...messages.preventionBenchmarkSelect} /> *
          </FieldInput>
        </div>
      </div>
      <div className="o-layout o-layout--small">
        <div className="o-layout__item">
          <SportSelect
            entityId={entityId}
            type={TestSetV2Type.PREVENTION}
            preventionType={session.preventionType}
            readOnly={!edit || !session.preventionType}
            value={sport?.id}
            onChange={selectedItem => {
              setFieldValue('sport', selectedItem);
            }}
            errors={errors}
            touched={touched}
          />
        </div>
      </div>
    </>
  );
}

export default PreventionDetailExtras;
