import classNames from 'classnames';

const TableRow = props => {
  const { extraClassNames, edit, unavailable, hasError, children, ...rest } =
    props;
  return (
    <tr
      className={classNames('c-table__row', extraClassNames, {
        'c-table__row--edit': edit,
        'c-table__row--unavailable': unavailable
      })}
      style={{ color: hasError ? 'red' : '' }}
      {...rest}
    >
      {children}
    </tr>
  );
};

export default TableRow;
