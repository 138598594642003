import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow
} from 'components/table';
import ResultsTableRowWaiting from 'containers/pages/results/table/ResultsTableRowWaiting';

const ResultsTableWaiting = () => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeaderCell />
          {Array(3)
            .fill()
            .map((v, i) => (
              <TableHeaderCell key={i} />
            ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {Array(8)
          .fill()
          .map((row, i) => (
            <ResultsTableRowWaiting key={i} />
          ))}
      </TableBody>
    </Table>
  );
};

export default ResultsTableWaiting;
