import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/icon/Icon';

GroupedMarker.propTypes = {};

function GroupedMarker({
  annotations,
  groupIsActive,
  activeAnnotation,
  position,
  onClickMarker,
  onClickGroup
}) {
  if (!groupIsActive) {
    return (
      <button
        type={'button'}
        style={{
          left: `${position}%`
        }}
        className="annotation-marker annotation-marker--grouped"
        onClick={e => {
          onClickGroup(annotations[0].id);
        }}
      />
    );
  }

  return (
    <div
      className="video-react-annotations-grouped"
      style={{
        left: `${position}%`,
        width: `calc(19px * ${annotations.length})`
      }}
    >
      {annotations.map((annotation, i) => {
        return (
          <button
            key={annotation.id}
            style={{ left: i * 16 + 12 }}
            type={'button'}
            onClick={() => onClickMarker(annotation)}
            className={classNames(
              'annotation-marker',
              {
                'annotation-marker-active':
                  activeAnnotation?.id === annotation.id
              },
              annotation.iconClasses.map(c => `annotation-marker--${c}`)
            )}
          >
            <Icon id="pointer" />
          </button>
        );
      })}
    </div>
  );
}

export default GroupedMarker;
