import React, { useContext } from 'react';
import { generatePath } from 'react-router-dom';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import {
  ROUTE_USER_ENTITY_PROGRAMS,
  ROUTE_USER_ENTITY_PROGRAMS_PROGRAM,
  ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_EXERCISE,
  ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_FEEDBACK
} from 'routes/RouteList';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import Panel from 'components/panel/Panel';
import { PanelBody, PanelHeader, PanelTitleButton } from 'components/panel';
import {
  PanelSubtitle,
  PanelTitle,
  PanelTitleWrapper
} from 'components/panel/panel-title';
import Loader from 'components/loader/Loader';
import { Tabs } from 'components/tabs';
import { Tab, TabList, TabPanel } from 'react-tabs';
import { StoreContext } from 'index';
import { getLocalDateFormat } from 'utils/date';
import ExerciseFeedbackPanel from 'containers/pages/user/programs/ExerciseFeedbackPanel';
import ExercisePanel from 'containers/pages/user/programs/ExercisePanel';
import useTabIndex from 'hooks/utils/useTabIndex';
import MenuWrapper from 'components/menu/MenuWrapper';
import DownloadExerciseProgramButton from 'containers/partials/buttons/DownloadExerciseProgramButton';
import { useProgramContext } from 'contexts/ProgramContext';
import TabPanelBody from 'components/tabs/TabPanelBody';

const ProgramExercises = ({ entityId, programId, exerciseId, active }) => {
  const {
    routing: { push },
    authStore: { user }
  } = useContext(StoreContext);

  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_USER_ENTITY_PROGRAMS_PROGRAM,
    ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_FEEDBACK
  ]);
  const { program, exercises, loading } = useProgramContext();

  const handleExerciseClick = exerciseId => {
    push(
      generatePath(ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_EXERCISE, {
        entityId,
        programId,
        exerciseId: exerciseId
      })
    );
  };

  const exercisesTab = () => {
    push(
      generatePath(ROUTE_USER_ENTITY_PROGRAMS_PROGRAM, {
        entityId,
        programId
      })
    );
  };

  const feedbackTab = () => {
    push(
      generatePath(ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_FEEDBACK, {
        entityId,
        programId
      })
    );
  };

  return (
    <Panel active={active}>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb
            to={generatePath(ROUTE_USER_ENTITY_PROGRAMS, {
              entityId,
              programId
            })}
          >
            <FormattedMessage {...messages.breadcrumbPrograms} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            {program.title}
            <MenuWrapper trigger={<PanelTitleButton />}>
              <DownloadExerciseProgramButton programId={program.id} />
            </MenuWrapper>
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage
              {...messages.exercisesProgramSubTitle}
              values={{
                startdate: getLocalDateFormat(program.startdate),
                duration: program.duration
              }}
            />
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        {loading && <Loader />}

        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <TabList>
            <Tab onClick={exercisesTab}>
              <FormattedMessage {...messages.tabExercises} />
            </Tab>
            <Tab onClick={feedbackTab}>
              <FormattedMessage {...messages.tabFeedback} />
            </Tab>
          </TabList>
          <TabPanel>
            <ExercisePanel
              exercises={exercises}
              exerciseId={exerciseId}
              onClick={handleExerciseClick}
              exerciseGroup={program}
            />
          </TabPanel>
          <TabPanel>
            {program?.id && (
              <TabPanelBody>
                <ExerciseFeedbackPanel
                  editable
                  program={program}
                  personId={user.id}
                />
              </TabPanelBody>
            )}
          </TabPanel>
        </Tabs>
      </PanelBody>
    </Panel>
  );
};

export default ProgramExercises;
