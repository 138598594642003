import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import ExerciseListItem from 'containers/partials/list-items/ExerciseListItem';
import { useVirtual } from 'react-virtual';
import useSelectable from 'hooks/utils/useSelectable';
import { useUIDSeed } from 'react-uid';
import { StoreContext } from 'index';

const ExercisesListVirtualized = ({
  activeId,
  checkable,
  visible,
  exercises = [],
  exerciseGroup,
  onClick,
  enablePreviewModal,
  onPreview,
  onSelect,
  selectedExercises,
  extraClassNames = {},
  showTemplateCount = false,
  showLevel = false,
  showNote = false,
  showSharesAndAnnotations = false,
  showOwner = false,
  statusIcon,
  onModal,
  wrappedList,
  withFixedFooter,
  loading
}) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const parentRef = useRef();
  const uidSeed = useUIDSeed();
  const { selected, select, setSelected } = useSelectable([]);
  const [minHeight, setMinHeight] = useState(null);

  useEffect(() => {
    if (selectedExercises) {
      setSelected(selectedExercises.map(e => e.id));
    }
  }, [exercises, selectedExercises]);

  const rowVirtualizer = useVirtual({
    size: exercises.length,
    parentRef,
    estimateSize: React.useCallback(() => 56, []),
    overscan: 5
  });

  const onSelectHandler = (exercise, checked) => {
    const selectIds = select(exercise.id);
    onSelect && onSelect(selectIds, exercise, checked);
  };

  useLayoutEffect(() => {
    if (parentRef.current && !wrappedList) {
      const roundedTop = parentRef.current
        .getBoundingClientRect()
        .top.toFixed(0);
      const paddingSpace = withFixedFooter ? 64 + 18 : 18;

      setMinHeight(`calc(100vh - ${paddingSpace}px - ${roundedTop}px)`);
    }
  }, [wrappedList]);

  return (
    <>
      {exercises.length > 0 ? (
        <List
          ref={parentRef}
          virtualizedList
          style={{
            height: withFixedFooter ? 'calc(100% - 64px)' : '100%'
          }}
          extraClassNames={extraClassNames}
        >
          <ListBody
            style={{
              minHeight: !onModal && minHeight,
              height: `${rowVirtualizer.totalSize}px`,
              position: 'relative'
            }}
          >
            {rowVirtualizer.virtualItems.map((virtualRow, index) => {
              const exercise = exercises[virtualRow.index];

              return (
                <ExerciseListItem
                  done={exercise.finished}
                  inProgress={
                    exercise?.annotations?.length > 0 && !exercise.finished
                  }
                  statusIcon={statusIcon}
                  uid={uidSeed(exercise.id)}
                  key={virtualRow.index}
                  virtualRow={virtualRow}
                  exercise={exercise}
                  exerciseGroup={exerciseGroup}
                  notes={exerciseGroup && exerciseGroup.notes?.[exercise.id]}
                  active={exercise.id === activeId}
                  showTemplateCount={showTemplateCount}
                  showWarning={exercise.templateCount === 0 && user.isVitaleOrg}
                  showMessage={exercise.media.length === 0 && user.isVitaleOrg}
                  showNote={showNote}
                  showLevel={showLevel}
                  showSharesAndAnnotations={showSharesAndAnnotations}
                  showOwner={showOwner}
                  visible={visible || (checkable && selected.length > 0)}
                  checkbox={checkable}
                  preview={false}
                  clickable
                  checked={checkable && selected.includes(exercise.id)}
                  onShowPreview={() =>
                    enablePreviewModal && onPreview(exercise)
                  }
                  onChange={e => onSelectHandler(exercise, e)}
                  onClick={() =>
                    enablePreviewModal
                      ? onPreview(exercise)
                      : onClick && onClick(exercise.id)
                  }
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '48px',
                    transform: `translateY(${virtualRow.start}px)`
                  }}
                />
              );
            })}
          </ListBody>
        </List>
      ) : (
        <Card centered>
          <CardBody empty>
            {!loading && (
              <Message iconFillColor="color-neutral-dark" empty icon="cursor">
                <MessageText>
                  <FormattedMessage {...messages.exercisesListEmpty} />
                </MessageText>
              </Message>
            )}
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default ExercisesListVirtualized;
