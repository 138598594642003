import Icon from '../icon/Icon';
import Button from './Button';
import { Card, CardBody } from '../card';

const ButtonCard = props => {
  return (
    <Button
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
      aria-label={props.ariaLabel}
      extraClassNames="c-button--card"
    >
      <Card packageGeneral extraClassNames="c-card--in-button">
        <CardBody>
          <Icon
            id={props.iconId}
            hugeIcon
            fillColor={
              props.iconFillColor ? props.iconFillColor : 'color-neutral'
            }
          />
          <p className="c-card__title">{props.title}</p>
          <span className="c-card__subtitle">{props.subtitle}</span>
        </CardBody>
      </Card>
    </Button>
  );
};

export default ButtonCard;
