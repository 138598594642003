import { Fragment } from 'react';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle,
  CardHeaderText
} from 'components/card/card-header';
import LineChart from '../charts/LineChart';
import { Card, CardBody } from 'components/card';
import InputErrorMessage from 'components/input/InputErrorMessage';
import { Lateralities, ProgressBarValueTypes } from 'constants.js';
import { capitalize } from 'utils/string';
import ProgressBarGroup from 'components/progress-bar/ProgressBarGroup';
import ProgressBarLabelBlock from 'components/progress-bar/ProgressBarLabelBlock';
import ProgressBar from 'components/progress-bar/ProgressBar';
import { getProgressBarColorByPercentile } from 'containers/pages/results/utils/benchmarkUtil';
import { getBenchmarkResultLabel } from 'utils/benchmark';
// import { getProgressBarColorByPercentile } from 'containers/pages/benchmarks/utils/benchmarkUtil';

const SidePanelChartCard = ({
  title,
  subTitle = '',
  laterality,
  chartData,
  errorMessage = ''
}) => {
  // Dummy DATA
  //   const parsedPercentile = 50;
  //   const result = '11.0';
  //   const unit = 'min';
  //   const benchmarkType = '';
  //   const avg = { avg: 15 };

  const lateralitySubtitle =
    laterality && Number(laterality) !== 2
      ? capitalize(Lateralities[laterality])
      : '';

  const subtitleCard = (
    <>
      {subTitle}
      {laterality && Number(laterality) !== 2 && (
        <>- [{<b>{lateralitySubtitle}</b>}]</>
      )}
    </>
  );

  return (
    <Fragment>
      <CardHeader modal>
        <CardHeaderTitle>{title}</CardHeaderTitle>
        <CardHeaderSubtitle>{subtitleCard}</CardHeaderSubtitle>
        <InputErrorMessage>{errorMessage}</InputErrorMessage>
      </CardHeader>
      {/* 
      // Dummy TOOLTIP
      <div>
        <Card
          tooltip
          style={{
            backgroundColor: 'white',
            maxWidth: 300,
            margin: 'auto',
            position: 'absolute',
            zIndex: 50
          }}
        >
          <CardHeader secondary>
            <CardHeaderText style={{ margin: 0 }}>
              <CardHeaderTitle>{title}</CardHeaderTitle>
              <CardHeaderSubtitle>21/12/2022</CardHeaderSubtitle>
            </CardHeaderText>
            <CardHeaderText style={{ margin: 0 }}>
              <CardHeaderSubtitle>{subtitleCard}</CardHeaderSubtitle>
            </CardHeaderText>
          </CardHeader>
          <CardBody>
            {parsedPercentile && parsedPercentile !== null ? (
              <ProgressBarGroup fullWidth>
                <ProgressBarLabelBlock
                  value={`small movements`
                ></ProgressBarLabelBlock>
                {benchmarkType !== 'average' ? (
                  <ProgressBar
                    benchmarksWithFrame
                    visibleValue="true"
                    range={getProgressBarColorByPercentile(parsedPercentile)}
                    value={`${parsedPercentile}`}
                  >
                    {`${parsedPercentile} %`}
                  </ProgressBar>
                ) : (
                  <>
                    <ProgressBar
                      benchmarksWithFrame
                      value={`${parsedPercentile}`}
                    />

                    <ProgressBar
                      benchmarksWithFrame
                      range={ProgressBarValueTypes.AVERAGE}
                      value={`${avg.avg}`}
                    />
                  </>
                )}
              </ProgressBarGroup>
            ) : (
              <b>{getBenchmarkResultLabel(result, unit)}</b>
            )}
          </CardBody>
        </Card>
      </div> */}
      <div style={{ height: 'calc(100% - 156px)', padding: '8px 24px' }}>
        <LineChart
          chartData={chartData}
          title={title}
          tooltipSubTitle={subtitleCard}
        />
      </div>
    </Fragment>
  );
};

export default SidePanelChartCard;
