import classNames from 'classnames';

const AnnotationVideoWrapper = props => {
  const { children, extraClassNames } = props;

  return (
    <div className={classNames('c-annotation__video-wrapper', extraClassNames)}>
      {children}
    </div>
  );
};

export default AnnotationVideoWrapper;
