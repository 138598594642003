import gql from 'graphql-tag';

export const MUTATION_ADD_SHARE = gql`
  mutation addShare(
    $linkId: ID
    $personId: ID
    $shareWith: String
    $type: ShareType
  ) {
    addShare(
      input: {
        linkId: $linkId
        personId: $personId
        shareWith: $shareWith
        type: $type
      }
    ) {
      id
    }
  }
`;

/*
export const MUTATION_EDIT_SHARE = gql`
  mutation editShare(
    $id: ID
    $linkId: ID
    $fileId: ID
    $testSetId: ID
    $starttime: Int
    $endtime: Int
    $type: AnnotationType
    $meta: AWSJSON
  ) {
    editShare(
      input: {
        id: $id
        linkId: $linkId
        fileId: $fileId
        testSetId: $testSetId
        starttime: $starttime
        endtime: $endtime
        type: $type
        meta: $meta
      }
    ) {
      id
    }
  }
`;
*/

export const QUERY_GET_SHARES = gql`
  query getShares($linkId: ID!) {
    getShares(linkId: $linkId) {
      id
      linkId
      person {
        id
        firstname
        lastname
      }
      sharedWith {
        id
        firstname
        lastname
      }
      type
    }
  }
`;

export const QUERY_GET_SHARED_WITH = gql`
  query getSharedWith($personId: ID) {
    getSharedWith(personId: $personId) {
      id
      linkId
      person {
        id
        firstname
        lastname
      }
      sharedWith {
        id
        firstname
        lastname
      }
      type
    }
  }
`;

export const MUTATION_ARCHIVE_SHARE = gql`
  mutation archiveShare($shareId: ID!) {
    archiveShare(shareId: $shareId)
  }
`;
