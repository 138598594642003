import React from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import {
  QUERY_GET_BENCHMARK,
  MUTATION_RESET_BENCHMARK_DATA,
  QUERY_GET_BENCHMARK_DATA
} from 'services/aws/benchmark-query';

const ResetBenchmarkDataButton = ({
  entityId,
  benchmarkId,
  testItemId,
  children,
  ...props
}) => {
  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_RESET_BENCHMARK_DATA}
      variables={{ benchmarkId, testItemId }}
      confirmTitle={messages.modalConfirmResetBenchmarkTitle}
      confirmMessage={messages.modalConfirmResetBenchmarkMessage}
      confirmAcceptLabel={messages.modalConfirmResetButton}
      refetchQueries={[
        {
          query: QUERY_GET_BENCHMARK,
          variables: { entityId, benchmarkId }
        },
        {
          query: QUERY_GET_BENCHMARK_DATA,
          variables: {
            benchmarkId,
            entityId: entityId
          }
        }
      ]}
    >
      <Icon id="delete" strokeColor="color-neutral-dark" />
      {children}
    </ConfirmMutationButton>
  );
};

export default inject('routing')(withRouter(ResetBenchmarkDataButton));
