import { useContext } from 'react';
import { generatePath, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Panel from 'components/panel/Panel';
import ListItem from 'components/list/list-item/ListItem';
import ListItemStatus from 'components/list/list-item/ListItemStatus';
import ListItemLabelWrapper from 'components/list/list-item/ListItemLabelWrapper';
import ListItemLabel from 'components/list/list-item/ListItemLabel';
import messages from 'messages';
import {
  ROUTE_SETTINGS_USERS,
  ROUTE_SETTINGS_ORGANISATIONS,
  ROUTE_SETTINGS_GLOBAL,
  ROUTE_SETTINGS_BENCHMARKS,
  ROUTE_SETTINGS_TESTSETS,
  ROUTE_SETTINGS_USAGE,
  ROUTE_SETTINGS_DEMODATA
} from 'routes/RouteList';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { PanelTitleWrapper, PanelTitle, PanelHeader } from 'components/panel';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import { StoreContext } from 'index';

const OverView = ({ setting }) => {
  const {
    authStore: {
      user: { config }
    },
    routing: { push }
  } = useContext(StoreContext);

  const ability = useAbility(AbilityContext);

  return (
    <Panel>
      <PanelHeader>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.settingsTitle} />
          </PanelTitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <List>
        <ListBody>
          <ListItem
            active={setting === 'global'}
            onClick={() => push(ROUTE_SETTINGS_GLOBAL)}
          >
            <ListItemStatus statusIcon="global" />
            <ListItemLabelWrapper>
              <ListItemLabel label>
                <FormattedMessage {...messages.settingsGlobalListItem} />
              </ListItemLabel>
            </ListItemLabelWrapper>
          </ListItem>
          <ListItem
            active={setting === 'users'}
            onClick={() => push(ROUTE_SETTINGS_USERS)}
          >
            <ListItemStatus statusIcon="users-white" />
            <ListItemLabelWrapper>
              <ListItemLabel label>
                <FormattedMessage {...messages.settingsUserListItem} />
              </ListItemLabel>
            </ListItemLabelWrapper>
          </ListItem>
          <ListItem
            active={setting === 'organisations'}
            onClick={() => push(ROUTE_SETTINGS_ORGANISATIONS)}
          >
            <ListItemStatus statusIcon="organisations-white" />
            <ListItemLabelWrapper>
              <ListItemLabel label>
                <FormattedMessage {...messages.settingsOrganisationsListItem} />
              </ListItemLabel>
            </ListItemLabelWrapper>
          </ListItem>
        </ListBody>
      </List>
      {(ability.can('manage', 'Benchmarks') ||
        ability.can('manage', 'Testsets')) &&
        (config.benchmarksEnabled || config.testSetsEnabled) && (
          <List>
            <ListBody>
              {ability.can('manage', 'Benchmarks') && config.benchmarksEnabled && (
                <ListItem
                  active={setting === 'benchmarks'}
                  onClick={() => push(ROUTE_SETTINGS_BENCHMARKS)}
                >
                  <ListItemStatus statusIcon="benchmarks" />
                  <ListItemLabelWrapper>
                    <ListItemLabel label>
                      <FormattedMessage
                        {...messages.settingsBenchmarksListItem}
                      />
                    </ListItemLabel>
                  </ListItemLabelWrapper>
                </ListItem>
              )}
              {ability.can('manage', 'Testsets') && config.testSetsEnabled && (
                <ListItem
                  active={setting === 'testsets'}
                  onClick={() => push(generatePath(ROUTE_SETTINGS_TESTSETS))}
                >
                  <ListItemStatus statusIcon="sets" />
                  <ListItemLabelWrapper>
                    <ListItemLabel label>
                      <FormattedMessage
                        {...messages.settingsTestSetsListItem}
                      />
                    </ListItemLabel>
                  </ListItemLabelWrapper>
                </ListItem>
              )}
            </ListBody>
          </List>
        )}
      {ability.can('manage', 'Organisation') && (
        <>
          <PanelHeader>
            <PanelTitleWrapper>
              <PanelTitle>
                <FormattedMessage
                  {...messages.settingsOrganisationsListTitle}
                />
              </PanelTitle>
            </PanelTitleWrapper>
          </PanelHeader>
          <List>
            <ListBody>
              <ListItem
                active={setting === 'usage'}
                onClick={() => push(ROUTE_SETTINGS_USAGE)}
              >
                <ListItemStatus statusIcon="users-white" />
                <ListItemLabelWrapper>
                  <ListItemLabel label>
                    <FormattedMessage {...messages.accountUsageLabel} />
                  </ListItemLabel>
                </ListItemLabelWrapper>
              </ListItem>
              <ListItem
                active={setting === 'demodata'}
                onClick={() => push(ROUTE_SETTINGS_DEMODATA)}
              >
                <ListItemStatus statusIcon="template" />
                <ListItemLabelWrapper>
                  <ListItemLabel label>
                    <FormattedMessage {...messages.accountDemoDataLabel} />
                  </ListItemLabel>
                </ListItemLabelWrapper>
              </ListItem>
            </ListBody>
          </List>
        </>
      )}
    </Panel>
  );
};

export default withRouter(OverView);
