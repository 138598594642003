import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Card, CardBody, CardFooter } from 'components/card';
import { Button, ButtonsGroup } from 'components/button';
import { ROUTE_LOGIN, ROUTE_FORGOT_SUCCESS } from 'routes/RouteList';
import { isEmailValid } from 'utils/email';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import InputErrorMessage from 'components/input/InputErrorMessage';
import FieldInput from 'components/input/FieldInput';

class ForgotLogin extends React.Component {
  state = {
    email: '',
    emailError: false,
    errorMessage: ''
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { email } = this.state;
    const {
      authStore,
      routing: { push }
    } = this.props;
    await authStore.resetPassword(email);
    push(ROUTE_FORGOT_SUCCESS);
  };

  handleCancel = e => {
    e.preventDefault();

    const {
      routing: { push }
    } = this.props;
    push(ROUTE_LOGIN);
  };

  render() {
    const { email, emailError, errorMessage } = this.state;
    const emailIcon = emailError
      ? 'validation-error'
      : email && isEmailValid(email)
      ? 'validation-check'
      : ' ';
    return (
      <Card extraClassNames="c-card--primary">
        <CardHeader primary>
          <Button
            tiny
            extraClassNames="c-button--absolute-top-left c-button--large-spacing"
            onClick={e => this.handleCancel(e)}
          >
            <Icon id="back-nav" mediumIcon />
          </Button>
          <CardHeaderTitle>
            <FormattedMessage {...messages.loginForgotPasswordTitle} />
          </CardHeaderTitle>
          <CardHeaderSubtitle extraClassNames="u-margin-horizontal-auto">
            <FormattedMessage {...messages.loginForgotPasswordSubTitle} />
          </CardHeaderSubtitle>
        </CardHeader>
        <form>
          <CardBody primary>
            <FieldInput
              id="email-id"
              type="email"
              icon={emailIcon}
              onBlur={() =>
                this.setState({
                  emailError: !email || !isEmailValid(email)
                })
              }
              onChange={e =>
                this.setState({
                  email: e.target.value,
                  emailError: false,
                  errorMessage: ''
                })
              }
            >
              <FormattedMessage {...messages.loginUsername} />
            </FieldInput>
            {errorMessage && (
              <InputErrorMessage>{errorMessage}</InputErrorMessage>
            )}
          </CardBody>
          <CardFooter primary>
            <ButtonsGroup>
              <Button secondary onClick={e => this.handleCancel(e)}>
                <FormattedMessage {...messages.loginCancelButton} />
              </Button>
              <Button
                primary
                type="submit"
                disabled={!email || !isEmailValid(email)}
                onClick={e => this.handleSubmit(e)}
              >
                <FormattedMessage {...messages.loginResetPasswordButton} />
              </Button>
            </ButtonsGroup>
          </CardFooter>
        </form>
      </Card>
    );
  }
}

export default withRouter(
  injectIntl(inject('routing', 'authStore')(observer(ForgotLogin)))
);
