import { useContext, useEffect } from 'react';
import Card from 'components/card/Card';
import { FormattedMessage } from 'react-intl';
import { Panel, PanelHeader, PanelBody } from 'components/panel';
import messages from 'messages';
import CardBody from 'components/card/CardBody';
import Loader from 'components/loader/Loader';
import VirtualizedList from 'containers/partials/lists/VirtualizedList';
import SortableListHeader from 'components/list/SortableListHeader';
import { SORT_DATA_TYPES, SORT_DIRECTION } from 'utils/sort';
import { StoreContext } from 'index';
import useFiles from 'hooks/queries/useFiles';
import useSelectable from 'hooks/utils/useSelectable';
import { useParams } from 'react-router-dom';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import {
  PanelSubtitle,
  PanelTitle,
  PanelTitleWrapper
} from 'components/panel/panel-title';
import { usePersonContext } from 'contexts/PersonContext';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import DownloadFileButton from 'containers/partials/buttons/DownloadFileButton';
import MetaWrapper from 'containers/partials/list-items/MetaWrapper';

const columns = [
  {
    key: 'id',
    label: '',
    sortable: false
  },
  {
    key: 'filename',
    label: 'Document name',
    sortable: true,
    dir: SORT_DIRECTION.ASC,
    dataType: SORT_DATA_TYPES.STRING
  },
  {
    key: 'date_created',
    label: 'Creation date',
    default: true,
    sortable: true,
    dir: SORT_DIRECTION.DESC,
    dataType: SORT_DATA_TYPES.STRING
  }
];

function Documents() {
  const {
    authStore: {
      user,
      user: { id }
    }
  } = useContext(StoreContext);
  const { entityId } = useParams();
  const { person, loading, error } = usePersonContext();
  const {
    files,
    loading: loadingFiles,
    getFiles,
    sortFiles
  } = useFiles({
    entityId,
    linkId: id
  });
  const { selected, setSelected } = useSelectable();

  useEffect(() => {
    getFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Panel active>
      {loading && <Loader />}
      {error && <RetryPanel />}
      {person && (
        <>
          <PanelHeader borderBottom>
            <PanelTitleWrapper>
              <PanelTitle>
                <FormattedMessage {...messages.documents} />
              </PanelTitle>
              <PanelSubtitle>
                <FormattedMessage
                  {...messages.documentsSubtitle}
                  values={{
                    name: user.getRelatedEntityNameByEntityId(entityId)
                  }}
                />
              </PanelSubtitle>
            </PanelTitleWrapper>
          </PanelHeader>

          <PanelBody>
            {loadingFiles && <Loader />}

            {files.length > 0 ? (
              <VirtualizedList
                extraClassNames="c-list--sortable-two-columns-with-button"
                items={files}
                titleLabel={'filename'}
                metaLabel={'date_created'}
                icon={'folder'}
                mediumIcon
                supertitle
                entityId={entityId}
                selectedItems={selected}
                sortableListHeader={
                  <SortableListHeader
                    onSort={activeSort => {
                      sortFiles(files, [activeSort]);
                    }}
                    columns={columns}
                  />
                }
                meta={
                  <MetaWrapper toggler>
                    <DownloadFileButton />
                  </MetaWrapper>
                }
              />
            ) : (
              <Card empty extraClassNames="u-margin-auto">
                <CardBody empty>
                  <Message emptyState={true} icon="browse">
                    <MessageText>
                      <FormattedMessage {...messages.documentsListEmpty} />
                    </MessageText>
                  </Message>
                </CardBody>
              </Card>
            )}
          </PanelBody>
        </>
      )}
    </Panel>
  );
}

export default Documents;
