import React from 'react';
import { ROUTE_VIDEOS_ENTITY } from 'routes/RouteList';
import {
  MUTATION_ARCHIVE_VIDEO,
  QUERY_GET_VIDEOS
} from 'services/aws/videos-query';
import { QUERY_GET_LESSONS } from 'services/aws/lessons-query';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages.js';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';

const RemoveVideoButton = ({ entityId, videoId, children, ...props }) => {
  const { push } = useHistory();
  return (
    <ConfirmMutationButton
      menu
      {...props}
      mutation={MUTATION_ARCHIVE_VIDEO}
      confirmTitle={messages.modalConfirmRemoveVideoTitle}
      confirmMessage={messages.modalConfirmRemoveVideoMessage}
      variables={{
        exerciseId: videoId
      }}
      refetchQueries={[
        {
          query: QUERY_GET_VIDEOS,
          variables: { entityId }
        },
        {
          query: QUERY_GET_LESSONS,
          variables: { entityId }
        }
      ]}
      update={(cache, { data: { archiveExercise } }) => {
        if (archiveExercise) {
          push(generatePath(ROUTE_VIDEOS_ENTITY, { entityId }));
        }
      }}
    >
      <Icon id="delete" strokeColor="color-neutral-dark" />
      <FormattedMessage {...messages.videosRemoveVideoButton} />
    </ConfirmMutationButton>
  );
};

export default RemoveVideoButton;
