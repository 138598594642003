import classNames from 'classnames';
import PropTypes from 'prop-types';
import './ProgressBar.scss';
import { Button } from 'components/button';

export const ProgressBlockValueTypes = Object.freeze({
  VALUE_0: '0',
  VALUE_1: '1',
  VALUE_2: '2',
  VALUE_3: '3',
  VALUE_4: '4'
});

const ProgressBlockValue = props => {
  const { children, range, hiddenValue, noClick, onClick } = props;

  return noClick ? (
    <div
      className={classNames('c-progress-bar--block-value', {
        'c-progress-bar--hidden-value': hiddenValue
      })}
    >
      <div
        className={classNames(
          'c-progress-bar__block c-progress-bar__block-' + range
        )}
      />
      <span className="c-progress-bar__value">{children}</span>
    </div>
  ) : (
    <Button
      onClick={onClick}
      mini
      extraClassNames={classNames('c-progress-bar--block-value', {
        'c-progress-bar--hidden-value': hiddenValue
      })}
    >
      <div
        className={classNames(
          'c-progress-bar__block c-progress-bar__block-' + range
        )}
      />
      <span className="c-progress-bar__value">{children}</span>
    </Button>
  );
};

ProgressBlockValue.propTypes = {
  range: PropTypes.oneOf([
    ProgressBlockValueTypes.VALUE_0,
    ProgressBlockValueTypes.VALUE_1,
    ProgressBlockValueTypes.VALUE_2,
    ProgressBlockValueTypes.VALUE_3,
    ProgressBlockValueTypes.VALUE_4
  ])
};

export default ProgressBlockValue;
