import { useContext, useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { PanelHeader } from 'components/panel';
import {
  PanelSubtitle,
  PanelTitle,
  PanelTitleButton,
  PanelTitleWrapper
} from 'components/panel/panel-title';
import Panel from 'components/panel/Panel';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import { Tab, TabList, TabPanel, Tabs } from 'components/tabs';
import Badge from 'components/badge/Badge';
import {
  ROUTE_EXERCISES_ENTITY,
  ROUTE_EXERCISES_PROGRAMS_ENTITY,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_ATHLETES,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_ATHLETES_PERSON,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES
} from 'routes/RouteList';
import messages from 'messages';
import ProgramDetailPanel from './ProgramDetailPanel';
import ProgramAthletesPanel from './ProgramAthletesPanel';
import ProgramExercisePanel from './ProgramExercisePanel';
import { MenuWrapper } from 'components/menu';
import { StoreContext } from 'index';
import RemoveProgramButton from 'containers/partials/buttons/RemoveProgramButton';
import Icon from 'components/icon/Icon';
import { Button } from 'components/button';
import AddTemplateModal from 'containers/pages/exercises/templates/AddTemplateModal';
import { getLocalDateFormat } from 'utils/date';
import useTabIndex from 'hooks/utils/useTabIndex';
import { useExercisesContext } from 'contexts/ExercisesContext';
import DownloadExerciseProgramButton from 'containers/partials/buttons/DownloadExerciseProgramButton';
import { useProgramContext } from 'contexts/ProgramContext';

const EditProgram = ({ entityId, programId, personId }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const [modalData, setModalData] = useState(null);
  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT,
    [
      ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_ATHLETES,
      ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_ATHLETES_PERSON
    ],
    ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES
  ]);

  const { exerciseActions } = useExercisesContext();
  const { program } = useProgramContext();

  useEffect(() => {
    if (program) {
      exerciseActions.setSelectedExercises([...program.exercises]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program]);

  const onUse = program => {
    setModalData({
      template: {
        entityId,
        title: '',
        exerciseIds: program.exercises.map(e => e.id),
        exercises: program.exercises,
        tags: []
      }
    });
  };

  return (
    <Panel secondary extraClassNames="o-flex o-flex--column">
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb
            to={ROUTE_EXERCISES_ENTITY.replace(':entityId', entityId)}
          >
            <FormattedMessage {...messages.breadcrumbOverview} />
          </Breadcrumb>
          <Breadcrumb
            to={ROUTE_EXERCISES_PROGRAMS_ENTITY.replace(':entityId', entityId)}
          >
            <FormattedMessage {...messages.breadcrumbPrograms} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            {program.title}
            <MenuWrapper trigger={<PanelTitleButton />}>
              <DownloadExerciseProgramButton programId={program.id} />
              <Button menu onClick={() => onUse(program)}>
                <Icon
                  id="use-template"
                  style={{
                    width: 20,
                    height: 20,
                    left: 12,
                    top: 6
                  }}
                />
                <FormattedMessage {...messages.exercisesCreateTemplateButton} />
              </Button>
              {user.canRemoveExercises(entityId) && (
                <RemoveProgramButton
                  menu
                  entityId={entityId}
                  programId={programId}
                >
                  <FormattedMessage
                    {...messages.exercisesRemoveProgramButton}
                  />
                </RemoveProgramButton>
              )}
            </MenuWrapper>
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage
              {...messages.exercisesProgramSubTitle}
              values={{
                startdate: getLocalDateFormat(program.startdate),
                duration: program.duration
              }}
            />
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <Tabs
        fullWidth
        hrefWithin
        selectedIndex={tabIndex}
        onSelect={index => setTabIndex(index)}
      >
        <TabList>
          <Tab key="exercises.programs.detail">
            <Link
              to={generatePath(ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT, {
                entityId,
                programId
              })}
            >
              <FormattedMessage {...messages.details} />
            </Link>
          </Tab>
          <Tab
            key="exercises.programs.athletes"
            disabled={
              !program.personEntities || program.personEntities.length === 0
            }
          >
            {!program.personEntities ||
              (program.personEntities.length === 0 && (
                <div className="p-tabs__link-disabled">
                  <Badge>{program.personEntities.length}</Badge>
                  <FormattedMessage {...messages.athletesTab} />
                </div>
              ))}
            {program?.personEntities && program.personEntities.length > 0 && (
              <Link
                to={generatePath(
                  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_ATHLETES,
                  {
                    entityId,
                    programId: programId
                  }
                )}
              >
                <Badge>{program.personEntities.length}</Badge>
                <FormattedMessage {...messages.athletesTab} />
              </Link>
            )}
          </Tab>
          <Tab key="exercises.programs.exercises">
            {program?.exercises && (
              <Link
                to={generatePath(
                  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES,
                  {
                    entityId,
                    programId: programId
                  }
                )}
              >
                <Badge>{program.exercises.length}</Badge>
                <FormattedMessage {...messages.exercisesTitle} />
              </Link>
            )}
          </Tab>
        </TabList>

        <TabPanel key="exercises.programs.detail">
          <ProgramDetailPanel
            program={program}
            edit={false}
            entityId={entityId}
          />
        </TabPanel>
        <TabPanel key="exercises.programs.athletes">
          <ProgramAthletesPanel
            entityId={entityId}
            program={program}
            personId={personId}
          />
        </TabPanel>
        <TabPanel key="exercises.programs.exercises">
          <ProgramExercisePanel
            program={program}
            edit={false}
            entityId={entityId}
            onUse={() => onUse(program)}
          />
        </TabPanel>
      </Tabs>

      {modalData && (
        <AddTemplateModal
          entityId={entityId}
          template={modalData.template}
          onClose={() => setModalData(null)}
          fromProgram={true}
          program={program}
        />
      )}
    </Panel>
  );
};

export default observer(EditProgram);
