import React, { useContext } from 'react';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import { removePersonFromOrganisation } from 'services/aws/client-query';
import { StoreContext } from 'index';
import ConfirmButton from 'containers/partials/buttons/ConfirmButton';

const RemovePersonsFromOrgButton = ({
  ids,
  entityId,
  children,
  onComplete,
  ...props
}) => {
  const { uiState } = useContext(StoreContext);

  const onConfirmRemovePerson = async ids => {
    if (Array.isArray(ids) && ids.length > 0) {
      uiState.increasePendingRequest();
      for (const id of ids) {
        await removePersonFromOrganisation({ personId: id, entityId });
      }
      uiState.decreasePendingRequest();

      onComplete && onComplete();
    }
  };

  return (
    <ConfirmButton
      {...props}
      menu
      confirmTitle={messages.modalConfirmRemovePersonFromOrgTitle}
      confirmMessage={messages.modalConfirmRemovePersonFromOrgMessage}
      messageValues={{ count: ids.length }}
      onOk={() => onConfirmRemovePerson(ids)}
    >
      <Icon id="delete" strokeColor="color-neutral-dark" />
      {children}
    </ConfirmButton>
  );
};

export default RemovePersonsFromOrgButton;
