import classNames from 'classnames';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import Select, { components } from 'react-select';
import Icon from '../icon/Icon';

const MultiValueRemove = props => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon id="close" />
    </components.MultiValueRemove>
  );
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon id="dropdown-open" extraClassNames={classNames('c-icon--input')} />
    </components.DropdownIndicator>
  );
};

const IndicatorSeparator = () => {
  return <></>;
};

const TagsSelect = ({ dropdownDisabled = false, ...props }) => {
  return (
    <div
      className={classNames(
        'c-input__group c-input__group-select',
        props.extraClassNames,
        {
          'c-input--read-only': props.readOnly,
          'c-input--disabled': props.disabled,
          'c-input--inline': props.inline,
          'c-input--dropdown-disabled': dropdownDisabled
        }
      )}
    >
      {props.label && (
        <label htmlFor={props.id} className={classNames('c-input__label', {})}>
          {props.label}
        </label>
      )}
      {props.creatable ? (
        <CreatableSelect
          value={props.value}
          options={props.options}
          isMulti={props.isMulti}
          isClearable={props.isClearable}
          onChange={props.onChange}
          onInputChange={props.onInputChange}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          isSearchable={!props.readOnly}
          isDisabled={props.disabled}
          isLoading={props.isLoading}
          noOptionsMessage={() => props.noOptionsMessage}
          className={classNames('p-select-container')}
          classNamePrefix={'p-select'}
          components={{
            Menu: dropdownDisabled
              ? () => <></>
              : ({ children, ...props }) => (
                  <components.Menu {...props}>{children}</components.Menu>
                ),
            IndicatorsContainer: dropdownDisabled
              ? () => <></>
              : ({ children, ...props }) => (
                  <components.IndicatorsContainer {...props}>
                    {children}
                  </components.IndicatorsContainer>
                ),
            MultiValueRemove,
            DropdownIndicator,
            IndicatorSeparator
          }}
        />
      ) : (
        <Select
          value={props.value}
          options={props.options}
          isMulti={props.isMulti}
          isClearable={props.isClearable}
          onChange={props.onChange}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          isSearchable={!props.readOnly}
          isDisabled={props.disabled}
          isLoading={props.isLoading}
          noOptionsMessage={() => props.noOptionsMessage}
          className={classNames('p-select-container')}
          classNamePrefix={'p-select'}
          components={{
            Menu: dropdownDisabled
              ? () => <></>
              : ({ children, ...props }) => (
                  <components.Menu {...props}>{children}</components.Menu>
                ),
            IndicatorsContainer: dropdownDisabled
              ? () => <></>
              : ({ children, ...props }) => (
                  <components.IndicatorsContainer {...props}>
                    {children}
                  </components.IndicatorsContainer>
                ),
            MultiValueRemove,
            DropdownIndicator,
            IndicatorSeparator
          }}
        />
      )}
    </div>
  );
};

TagsSelect.propTypes = {
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  disabled: PropTypes.bool,
  creatable: PropTypes.bool
};

export default TagsSelect;
