import { Fragment, useEffect, useState } from 'react';
import TestItemResult from './TestItemResult';
import TestItemForm from './TestItemForm';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper,
  ListItemStatus
} from 'components/list/list-item';
import { Accordion, AccordionContent } from 'components/accordion';
import AccordionButtonHooks from 'components/accordion/AccordionButtonHooks';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import classNames from 'classnames';
import { checkLateralityIsDisabled, testItemHasValues } from 'utils/tests';
import { useTestDataContext } from 'contexts/TestDataContext';

function TestItemListItem({
  entityId,
  testDataId,
  test,
  testItems,
  sides,
  version,
  edit,
  index,
  indexListItem,
  collapsible,
  grouped,
  onClick
}) {
  const { formData } = useTestDataContext();
  const [hasResult, setHasResults] = useState(null);
  useEffect(() => {
    // Status is saved on server
    testItems.forEach(testItemResult => {
      /* In case of online data use this check
      * (testItemResult.constructor.modelName === 'TestItemResult' &&
          testItemResult.result)*/
      // This checks for local data
      if (formData?.[testItemResult.id]) {
        setHasResults(
          Object.values(formData[testItemResult.id]).some(a =>
            a.some(v => v !== '' && v !== null)
          )
        );
      }
    });
  }, [test, testItems]);

  if (!grouped && test.testItems.length === 0) return '';

  return (
    <ListItem
      readOnly={!edit}
      disabled={test.isDependency}
      withSubList={grouped}
      oneLine={!grouped}
      done={hasResult}
      extraClassNames={'c-list__test-item'}
      onClick={onClick}
      clickable
      style={{ zIndex: indexListItem }}
    >
      {grouped ? (
        // (If) List with subList === grouped
        collapsible ? (
          // (If) With Accordion
          <Accordion visibleFlow>
            {() => (
              <>
                <AccordionButtonHooks
                  id={`trigger-${index}`}
                  name={`accordion-story`}
                  checkbox
                  defaultChecked={true}
                >
                  <ListItemStatus
                    statusIcon={
                      hasResult
                        ? 'yes-white'
                        : edit && !hasResult
                        ? 'exercise'
                        : 'no-white'
                    }
                    checked={test.checked}
                    visible={false}
                    disabled={test.isDependency}
                  />
                  <ListItemLabelWrapper>
                    <ListItemLabel label>{test.name}</ListItemLabel>
                  </ListItemLabelWrapper>
                </AccordionButtonHooks>
                <AccordionContent>
                  <List subList>
                    <ListBody>
                      {testItems.map(testItem => (
                        <ListItem
                          smallEditableOpen
                          key={`${test.id}_${testItem.id}`}
                          extraClassNames={classNames({
                            activeTODO: testItemHasValues(
                              formData[testItem.id],
                              testItem.sides
                            )
                          })}
                        >
                          <ListItemLabelWrapper>
                            <ListItemLabel label>
                              {testItem.order} - {testItem.title}
                            </ListItemLabel>
                          </ListItemLabelWrapper>

                          {edit ? (
                            <TestItemForm
                              entityId={entityId}
                              edit={edit}
                              testId={test.id}
                              testItem={testItem}
                              testDataId={testDataId}
                              version={version}
                              sidesDisabled={checkLateralityIsDisabled(
                                test,
                                sides
                              )}
                              small
                              keyboardInput={!collapsible} // Prop for the Tooltip (remove it from flow with tabs)
                            />
                          ) : (
                            <TestItemResult
                              testItem={testItem}
                              sidesDisabled={checkLateralityIsDisabled(
                                test,
                                sides
                              )}
                            />
                          )}
                        </ListItem>
                      ))}
                    </ListBody>
                  </List>
                </AccordionContent>
              </>
            )}
          </Accordion>
        ) : (
          //* Without Accordion
          <>
            <ListItemStatus
              statusIcon={
                hasResult
                  ? 'yes-white'
                  : edit && !hasResult
                  ? 'exercise'
                  : 'no-white'
              }
              checked={test.checked}
              visible={false}
              disabled={test.isDependency}
            />
            <ListItemLabelWrapper>
              <ListItemLabel label>{test.name}</ListItemLabel>
            </ListItemLabelWrapper>

            <List subList>
              <ListBody>
                {testItems.map(testItem => (
                  <ListItem
                    smallEditableOpen
                    key={testItem.id}
                    extraClassNames={{
                      activeTODO: testItemHasValues(
                        formData[testItem.id],
                        testItem.sides
                      )
                    }}
                  >
                    <ListItemLabelWrapper>
                      <ListItemLabel label>{testItem.title}</ListItemLabel>
                    </ListItemLabelWrapper>
                    {edit ? (
                      <TestItemForm
                        entityId={entityId}
                        testId={test.id}
                        testDataId={testDataId}
                        edit={edit}
                        testItem={testItem}
                        version={version}
                        sidesDisabled={checkLateralityIsDisabled(test, sides)}
                        small
                        keyboardInput={!collapsible} // Prop for the Tooltip (remove it from flow with tabs)
                      />
                    ) : (
                      <div
                        className={classNames('c-list__item-editing-area', {
                          'c-list__item-with-slider':
                            testItem.input_type === 'slider'
                        })}
                      >
                        <TestItemResult
                          testItem={testItem}
                          sidesDisabled={checkLateralityIsDisabled(test, sides)}
                        />
                      </div>
                    )}
                  </ListItem>
                ))}
              </ListBody>
            </List>
          </>
        )
      ) : (
        // Not grouped (without sublist) === all in one list item
        <>
          <ListItemStatus
            statusIcon={
              hasResult
                ? 'yes-white'
                : edit && !hasResult
                ? 'exercise'
                : 'no-white'
            }
            checked={test.checked}
            visible={false}
            disabled={test.isDependency}
          />
          <ListItemLabelWrapper>
            <ListItemLabel label>{test.name}</ListItemLabel>
            {testItems.map(testItem => (
              <Fragment key={testItem.id}>
                {edit ? (
                  <TestItemForm
                    entityId={entityId}
                    testId={test.id}
                    testDataId={testDataId}
                    edit={edit}
                    testItem={testItem}
                    version={version}
                    sidesDisabled={checkLateralityIsDisabled(test, sides)}
                    keyboardInput={!collapsible} // Prop for the Tooltip (remove it from flow with tabs)
                  />
                ) : (
                  <div className="c-list__item-editing-area">
                    <TestItemResult
                      testItem={testItem}
                      sidesDisabled={checkLateralityIsDisabled(test, sides)}
                    />
                  </div>
                )}
              </Fragment>
            ))}
          </ListItemLabelWrapper>
        </>
      )}
    </ListItem>
  );
}

export default TestItemListItem;
