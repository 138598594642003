import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { getLocalDateFormat } from 'utils/date';
import { sortTestDataResultsOnScore } from 'utils/sort';
import CardBody from 'components/card/CardBody';
import Card from 'components/card/Card';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import messages from 'messages';
import ResultList from 'components/list/ResultList';
import { useTestDataContext } from 'contexts/TestDataContext';

const ILikeReportCard = ({ sporter, session }) => {
  const { testData } = useTestDataContext();
  const intl = useIntl();
  const [sports, setSports] = useState([]);

  useEffect(() => {
    if (testData?.sports && Array.isArray(testData.sports)) {
      setSports(sortTestDataResultsOnScore(testData.sports));
    } else {
      setSports([]);
    }
  }, [testData]);

  return (
    <Card reports>
      <CardHeader secondary>
        <CardHeaderText>
          <CardHeaderTitle>{session.name}</CardHeaderTitle>
          <CardHeaderSubtitle>
            {sporter.firstname} {sporter.lastname}
          </CardHeaderSubtitle>
          <CardHeaderSubtitle>
            {getLocalDateFormat(testData?.date_created)}
          </CardHeaderSubtitle>
        </CardHeaderText>
      </CardHeader>
      <CardBody secondary>
        <ResultList
          defaultVisibleItems={10}
          items={sports}
          labelShowMore={intl.formatMessage(messages.cardButtonShowAll, {
            count: sports.length
          })}
          labelShowLess={intl.formatMessage(messages.cardButtonShowLess)}
        />
      </CardBody>
    </Card>
  );
};

export default ILikeReportCard;
