import classnames from 'classnames';
import { forwardRef } from 'react';

const TabContentWrapper = forwardRef((props, ref) => {
  const { children, extraClassNames } = props;

  return (
    <div
      className={classnames(
        'p-tabs__active-tab-content-wrapper',
        extraClassNames
      )}
      ref={ref}
    >
      {children}
    </div>
  );
});

export default TabContentWrapper;
