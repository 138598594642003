import React, { useEffect, useState } from 'react';
import messages from 'messages';
import { Player, LoadingSpinner, BigPlayButton } from 'video-react';
import { useIntl } from 'react-intl';
import TooltipPopper from './TooltipPopper';
import { useUID } from 'react-uid';
import HLSSource from 'components/video/HLSSource';

function VideoTooltip({ media, videoMessage, onClick }) {
  const uid = useUID();
  const [onPreview, setOnPreview] = useState();
  const intl = useIntl();
  const [gif, setGif] = useState(null);
  const [video, setVideo] = useState(null);
  const [thumb, setThumb] = useState(null);

  useEffect(() => {
    if (!media) return;
    if (media.length > 0) {
      const video = media.find(m => m && m.type.endsWith('video'));
      if (video?.meta) {
        const meta = video.meta;
        if (meta?.mux?.playback_ids[0].id)
          setGif(
            `https://image.mux.com/${meta.mux.playback_ids[0].id}/animated.gif?width=300&fps=15`
          );
      }
      setVideo(video);
      setThumb(media.find(m => m.type.endsWith('image')));
    }
  }, [media]);

  if (gif || video)
    return (
      <TooltipPopper
        id={uid}
        video
        videoMessage={videoMessage}
        label={intl.formatMessage(
          messages.buttonPreview
          // onPreview ? messages.buttonView : messages.buttonPreview
        )}
        onClick={onClick}
        onMouseEnter={() => {
          setOnPreview(true);
        }}
        onFocus={() => {
          setOnPreview(true);
        }}
        onMouseLeave={() => {
          setOnPreview(false);
        }}
        onBlur={() => {
          setOnPreview(false);
        }}
      >
        {gif && onPreview && (
          <div
            className={'p-video-player'}
            style={{
              width: 300,
              height: 160,
              backgroundImage: `url(${gif})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundColor: '#000'
            }}
          ></div>
        )}
        {!gif && video && onPreview && (
          <Player
            loop
            muted
            autoPlay
            controls={false}
            poster={thumb && thumb.signedDownloadUrl}
          >
            <LoadingSpinner />
            <BigPlayButton position="center" />
            <HLSSource isVideoChild src={video.signedDownloadUrl} />
          </Player>
        )}
      </TooltipPopper>
    );

  // TODO een popup tonen met enkel maar een image
  /*if (!video && thumb) {

  }*/

  return <></>;
}

export default VideoTooltip;
