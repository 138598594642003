import React from 'react';
import { FormattedMessage } from 'react-intl';
import Loader from 'components/loader/Loader';
import messages from 'messages';
import { useParams } from 'react-router-dom';

const LoadingPage = () => {
  let { type } = useParams();

  let message;

  switch (type) {
    case 'generating-pdf':
      message = messages.messageGeneratingPDF;
      break;
    default:
      message = messages.globalMessageLoading;
  }

  return (
    <Loader>
      <FormattedMessage {...message} />
    </Loader>
  );
};

export default LoadingPage;
