import { useContext, useEffect } from 'react';
import { StoreContext } from 'index';
import { useExercisesContext } from 'contexts/ExercisesContext';
import Loader from 'components/loader/Loader';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import ExercisesListVirtualized from 'containers/partials/lists/ExercisesListVirtualized';

const ExercisesPanel = ({ entityId, exerciseId, onClick, onPreview }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const {
    exercisesState,
    exerciseActions,
    loading: loadingAll,
    error: errorAll
  } = useExercisesContext();

  useEffect(() => {
    exerciseActions.getExercises();
    exerciseActions.setFilter({ personId: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId]);

  if (errorAll) return <RetryPanel />;

  return (
    <>
      {loadingAll && <Loader />}
      <ExercisesListVirtualized
        loading={loadingAll}
        checkable
        exercises={exercisesState.exercises}
        activeId={exerciseId}
        onPreview={exercise => onPreview && onPreview(exercise)}
        enablePreviewModal={false}
        showTemplateCount={user.isGSVOrg}
        showLevel={!user.isGSVOrg}
        selectedExercises={exercisesState.selectedExercises}
        onClick={id => {
          onClick && onClick(id);
        }}
        onSelect={exerciseIds => {
          exerciseActions.setSelectedExercises(
            exercisesState.exercises.filter(e => exerciseIds.includes(e.id))
          );
        }}
      />
    </>
  );
};

export default ExercisesPanel;
