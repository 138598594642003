import { NoteTypes, TemplateType } from 'constants.js';
import Template from 'models/Template';
import { groupBy } from 'utils/array';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import Note from 'models/Note';

export default class Training extends Template {
  type = TemplateType.TRAINING;
  trainingNote;
  note = '';

  constructor({
    id,
    entityId,
    title = '',
    personId = '',
    exerciseIds = [],
    exercises = [],
    tags = [],
    duration = 0,
    notes = [],
    editable,
    type
  }) {
    super({
      id,
      entityId,
      title,
      personId,
      exerciseIds,
      exercises,
      tags,
      duration,
      notes,
      editable,
      type
    });

    if (notes.length > 0) {
      const groupedNotes = groupBy(
        notes
          .filter(n => n.noteType === NoteTypes.TEMPLATE)
          .map(n => new Note({ ...n })),
        ['linkId2']
      );
      Object.keys(groupedNotes).forEach(key => {
        if (key === '') {
          this.trainingNote = groupedNotes[key]?.[0];
          this.note = this.trainingNote?.note;
          delete groupedNotes[key];
          return;
        }
        groupedNotes[key] = sort(groupedNotes[key], {
          keys: [
            {
              key: 'date_created',
              desc: true,
              dataType: SORT_DATA_TYPES.NUMBER
            }
          ]
        });
      });
      this.notes = groupedNotes;
    }
  }

  static get modelName() {
    return 'Training';
  }
}
