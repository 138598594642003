import classNames from 'classnames';

const ListItemLabelWrapper = props => {
  const { extraClassNames, children } = props;
  return (
    <div className={classNames('c-list__item-label', extraClassNames)}>
      {children}
    </div>
  );
};

export default ListItemLabelWrapper;
