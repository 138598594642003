import { round } from 'utils/math';

const TypeNumber = v => {
  if (!v) return;
  if (typeof v === 'number') return v;
  return round(Number(v.replace(',', '.')));
};

export const hasRowOverlapping = (dataTable, row) => {
  return !!dataTable.some(rx => {
    return (
      row !== rx &&
      !row.empty &&
      ((TypeNumber(row.age_max) > TypeNumber(rx.age_min) &&
        TypeNumber(row.age_max) <= TypeNumber(rx.age_max)) ||
        (TypeNumber(rx.age_max) > TypeNumber(row.age_min) &&
          TypeNumber(rx.age_max) <= TypeNumber(row.age_max)))
    );
  });
};

export const hasRowValidAge = row => {
  const min = TypeNumber(row.age_min);
  const max = TypeNumber(row.age_max);
  return min < max;
};
