import { useEffect, useState } from 'react';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT } from 'routes/RouteList';
import { getLocalDateFormat } from 'utils/date';
import Loader from 'components/loader/Loader';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import { SessionListItemUser } from 'containers/partials/list-items/SessionListItemUser';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { useUserContext } from 'contexts/UserContext';
import { TabPanelBody } from 'components/tabs';

function UserSessions({ entityId, search }) {
  const { sessions, loading, error } = useUserContext();
  const { push } = useHistory();
  const { sessionId } = useParams();

  const [filteredSessions, setFilteredSessions] = useState([]);

  useEffect(() => {
    const filteredSessions = !search
      ? sessions
      : sessions.filter(s => {
          if (findStringInSession(s, search)) return true;
          const noFoundDeeper = search.split(' ').some(searchWord => {
            return !findStringInSession(s, searchWord);
          });
          return !noFoundDeeper;
        });
    setFilteredSessions(filteredSessions);
  }, [sessions, search]);

  const findStringInSession = (session, str) => {
    const trimmedString = str.toLowerCase().trim();
    const sessionName = session.name.toLowerCase().trim();
    if (sessionName.indexOf(trimmedString) !== -1) return true;
    if (
      session.testSets.find(
        ts => ts.title.toLowerCase().trim().indexOf(trimmedString) !== -1
      )
    )
      return true;
    const dateName = getLocalDateFormat(session.startdate);
    if (dateName.indexOf(trimmedString) !== -1) return true;
  };

  if (loading) return <Loader />;
  if (error) return <RetryPanel />;

  return (
    <TabPanelBody>
      {filteredSessions?.length > 0 ? (
        <List>
          <ListBody>
            {filteredSessions.map(session => (
              <SessionListItemUser
                noMaxHeight
                id={session.id}
                key={session.id}
                session={session}
                active={sessionId === session.id}
                onClick={() => {
                  push(
                    generatePath(ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT, {
                      entityId,
                      sessionId: session.id
                    })
                  );
                }}
              />
            ))}
          </ListBody>
        </List>
      ) : (
        <Card secondary>
          <CardBody extraClassNames="u-margin-bottom-none u-padding-vertical-huge">
            <Message emptyState icon="test">
              <MessageText>
                <FormattedMessage {...messages.emptySessionsDataAthlete} />
              </MessageText>
            </Message>
          </CardBody>
        </Card>
      )}
    </TabPanelBody>
  );
}

export default UserSessions;
