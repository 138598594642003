import { Button, ButtonsGroup } from 'components/button';
import Icon from 'components/icon/Icon';
import Select, { Option } from 'components/input/Select';
import { PanelBody } from 'components/panel';
import ProgressBar from 'components/progress-bar/ProgressBar';
import ProgressBarGroup from 'components/progress-bar/ProgressBarGroup';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from 'components/table';
import {
  cols,
  fullEvaluationHockeyData
} from 'components/_storybookHelpers/HelperData';
import { getProgressBarValueType } from 'containers/pages/benchmarks/utils/benchmarkUtil';
import { getBenchmarkResultLabel } from 'utils/benchmark';
import { SORT_TYPES } from 'utils/sort';

function InjuriesReportsCard({ entityId, personInjury }) {
  return (
    <>
      {/* <Panel benchmark> */}
      {/* <PanelHeader>
          <BreadcrumbsWrapper>
            <Breadcrumbs benchmarks>{breadcrumbs}</Breadcrumbs>
            <PanelTitleButtonsGroup>
              <MenuWrapper
                asModal
                trigger={<PanelTitleButton small icon="question" />}
              >
                <Card helper>
                  <CardBody benchmarks>
                    <CardHeaderTitle>
                      <FormattedMessage {...messages.benchmarksHelpLabel} />
                    </CardHeaderTitle>
                    <ProgressBarScale {...helpValueLabels} />
                  </CardBody>
                </Card>
              </MenuWrapper>
              <MenuWrapper trigger={<PanelTitleButton />}>
                {testCols && testCols.find(tc => tc.id !== undefined) && (
                  <Button menu onClick={onExportBenchmark}>
                    <Icon id="print" />
                    <FormattedMessage
                      {...messages.benchmarksMenuItemExportBenchmark}
                    />
                  </Button>
                )}
              </MenuWrapper>
            </PanelTitleButtonsGroup>
          </BreadcrumbsWrapper>

          <PanelTitleWrapper>
            <PanelTitle>
              {panelChoices ? (
                <MenuWrapper trigger={<span>{panelTitle}</span>}>
                  {panelChoices}
                </MenuWrapper>
              ) : (
                `${panelTitle}`
              )}
            </PanelTitle>
            <PanelSubtitle>{panelSubtitle}</PanelSubtitle>
          </PanelTitleWrapper>
          <PanelHeaderSettings>{benchmarkSettings}</PanelHeaderSettings>
        </PanelHeader> */}
      <PanelBody
        scrollHorizontal
        style={{ padding: '24px 0', borderTop: 0, overflowX: 'hidden' }}
        //innerRef={this.panelBodyRef}
      >
        {/* {benchmarkTable ? benchmarkTable : <BenchmarkTableWaiting />} */}
        {/* <BenchmarkTableWaiting /> */}

        {/* <BenchmarkTable
        dataSet={dataSet}
        benchmarkType={benchmarkType}
        benchmarkFilter={benchmarkFilter}
        onSorted={this.onSorted}
        /> */}
        <Table benchmarkTable extraClassNames={'c-table--reports-rehab'}>
          {/* <BenchmarkTableHeader
            cols={fixedCols}
            colFilterItems={colFilterItems}
            onClickSort={this.onClickSort}
            sortIndex={sortIndex}
            sortDirection={sortDirection}
          /> */}
          <TableHeader>
            <TableRow>
              <TableHeaderCell extraClassNames={'c-table__content'} />
              {cols.map((col, i) => (
                <TableHeaderCell key={col}>
                  <Select
                    name={`test-set-${col.id}`}
                    value={
                      i === 0
                        ? 'Sessie: APHV (mirwald) 27/02/2021'
                        : i === 1
                        ? 'Screening met testSetId'
                        : i === 2
                        ? 'Test Essevee inpout test'
                        : ''
                    }
                    title={'title'}
                  >
                    <Option
                      value={'Sessie: APHV (mirwald) 27/02/2021'}
                      label={'Sessie: APHV (mirwald) 27/02/2021'}
                    />
                    <Option
                      value={'Screening met testSetId'}
                      label={'Screening met testSetId'}
                    />
                    <Option
                      value={'Test Essevee inpout test'}
                      label={'Test Essevee inpout test'}
                    />
                  </Select>
                  <Button
                    tiny
                    inactive={i !== 1}
                    onClick={() => {
                      const newDirection =
                        i !== 1
                          ? SORT_TYPES.DOWN
                          : 'down' === SORT_TYPES.DOWN
                          ? SORT_TYPES.UP
                          : SORT_TYPES.DOWN;
                    }}
                    disabled={i === 3}
                  >
                    <Icon id={i === 1 ? `sort-${'down'}` : 'sort'} />
                  </Button>
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {/* {sortedRows.map(row => (
              <BenchmarkTableRow key={row.id} row={row} />
            ))} */}
            {fullEvaluationHockeyData.getInjuries.map((row, i) => (
              <TableRow>
                <TableCell mainCell id={row.id}>
                  {/*<Button tiny>
              <Icon
                id="three-dots"
                mediumIcon
                fillColor="color-neutral"
                extraClassNames="c-icon--rotated-90"
              />
            </Button>
            <Button tiny>
              {!row.label.includes('Kevin') ? (
                <Icon id="eye-enabled" mediumIcon strokeColor="color-primary" />
              ) : (
                <Icon id="eye-disabled" mediumIcon />
              )}
            </Button>*/}
                  <div className="c-table__content">{row.title}</div>
                </TableCell>
                {cols.map((data, j, array) => (
                  <TableCell //onClick={onClick}
                    key={j}
                  >
                    <div className="c-table__content">
                      {j < array.length - 1 && (
                        <ProgressBarGroup clickable>
                          <ProgressBar
                            benchmarks
                            range={getProgressBarValueType(
                              row.values.noSide
                                ? row.values.noSide
                                : row.values.left || row.values.right
                            )}
                            value={
                              (row.values.noSide
                                ? 10 + row.values.noSide
                                : row.values.left
                                ? 10 + row.values.left
                                : row.values.right
                                ? 10 + row.values.right
                                : 0) * 0.6
                            }
                          >
                            {getBenchmarkResultLabel(
                              row.values.noSide
                                ? row.values.noSide
                                : row.values.left || row.values.right,
                              '%',
                              0
                            )}
                            <br />
                            {getBenchmarkResultLabel(
                              row.values.noSide
                                ? row.values.noSide
                                : row.values.left || row.values.right,
                              'unit'
                            )}
                          </ProgressBar>
                        </ProgressBarGroup>
                      )}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* {benchmarkTable && isScroll && ( */}
        <ButtonsGroup>
          <Button
            circularLarge
            primaryDark
            // onClick={this.moveLeftHandler}
            // disabled={start}
          >
            <Icon
              id="play"
              fillColor="color-neutral-x-light"
              hugeIcon
              mirrored
            />
          </Button>
          <Button
            circularLarge
            primaryDark
            // onClick={this.moveRightHandler}
            // disabled={end}
          >
            <Icon id="play" fillColor="color-neutral-x-light" hugeIcon />
          </Button>
        </ButtonsGroup>
        {/* )} */}
      </PanelBody>

      {/*
      {alertData && (
        <Modal
          card
          benchmarksTableAlert
          noCloseButton
          isOpen={true}
          onClose={onCloseAlert}
        >
          <BenchmarkAlertCardBody alertData={alertData} />
        </Modal>
      )}

      {sidePanelData && (
        <Modal cardPanel isOpen={fa} onClose={onClosePanel}>
          <SidePanelChartCard {...sidePanelData} />
        </Modal>
      )} */}

      {/* </Panel> */}
    </>
  );
}

export default InjuriesReportsCard;
