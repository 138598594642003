import React, { useContext } from 'react';
import { generatePath } from 'react-router-dom';
import { StoreContext } from 'index';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { CardBody, CardFooter } from 'components/card';
import FieldInput from 'components/input/FieldInput';
import messages from 'messages';
import { Button, ButtonsGroup } from 'components/button';
import {
  ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_EDIT,
  ROUTE_VIDEOS_ENTITY
} from 'routes/RouteList';
import Video from 'models/Video';
import VideoForm from 'containers/pages/videos/VideoForm';
import {
  MUTATION_ADD_EXERCISE_TO_TEMPLATES,
  QUERY_GET_TEMPLATES
} from 'services/aws/templates-query';
import { QUERY_GET_VIDEOS } from 'services/aws/videos-query';

function AddVideoModal({ entityId, lessonId, onClose }) {
  const {
    routing: { push }
  } = useContext(StoreContext);
  const intl = useIntl();

  const [addExerciseToProgram] = useMutation(
    MUTATION_ADD_EXERCISE_TO_TEMPLATES,
    {
      refetchQueries: [
        { query: QUERY_GET_TEMPLATES, variables: { entityId } },
        { query: QUERY_GET_VIDEOS, variables: { entityId } }
      ]
    }
  );

  const onCloseHandler = e => {
    if (onClose) onClose();
  };

  return (
    <Modal card autoHeight isOpen={true} onClose={onCloseHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.lessonsAddVideoTitle} />
        </CardHeaderTitle>
      </CardHeader>
      <VideoForm
        entityId={entityId}
        video={new Video({})}
        onComplete={async data => {
          if (lessonId) {
            await addExerciseToProgram({
              variables: {
                exerciseId: data.id,
                programTemplateIds: [lessonId]
              }
            });
            push(
              generatePath(ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_EDIT, {
                entityId,
                lessonId,
                videoId: data.id
              })
            );
            onClose && onClose();
          } else {
            push(generatePath(ROUTE_VIDEOS_ENTITY, { entityId }));
          }
        }}
      >
        {({ errors, touched }) => (
          <>
            <CardBody modalWithOverflow>
              <FieldInput
                id="title"
                name="title"
                type="text"
                placeholder={intl.formatMessage(
                  messages.programPlaceholderName
                )}
                errors={errors}
                touched={touched}
                required
              >
                <FormattedMessage {...messages.exercisesLabelTitle} />
              </FieldInput>
            </CardBody>
            <CardFooter modal extraClassNames="c-card__footer--modal-larger">
              <ButtonsGroup>
                <Button secondary onClick={() => onCloseHandler()}>
                  <FormattedMessage {...messages.buttonCancel} />
                </Button>
                <Button type="submit" primary>
                  <FormattedMessage {...messages.buttonSave} />
                </Button>
              </ButtonsGroup>
            </CardFooter>
          </>
        )}
      </VideoForm>
    </Modal>
  );
}

export default AddVideoModal;
