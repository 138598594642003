import { useState, useEffect } from 'react';
import { Card, CardBody } from 'components/card';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import Tags from '../inputs/Tags';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import MilestoneSelect from '../inputs/MilestoneSelect';
import List from 'components/list/List';
import ListHeader from 'components/list/ListHeader';
import ListColumnTitle from 'components/list/ListColumnTitle';
import ListBody from 'components/list/ListBody';
import { useQuery } from '@apollo/client';
import { QUERY_GET_TESTS } from 'services/aws/testsets-query';
import { groupBy } from 'utils/array';
import SelectableList from 'components/list/SelectableList';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { TestListItem } from 'containers/partials/list-items/TestListItem';
import FieldInput from 'components/input/FieldInput';

function ScreeningCustomSelectTestCard({ entityId, onClick }) {
  const intl = useIntl();

  const [tags, setTags] = useState(null);
  const [search, setSearch] = useState('');
  const [milestoneTestSet, setMilestoneTestSet] = useState('');
  const [tests, setTests] = useState([]);
  const [selectedTests, setSelectedTests] = useState([]);

  const { data } = useQuery(QUERY_GET_TESTS);

  useEffect(() => {
    if (data?.getTests) {
      const tests = groupBy(data.getTests, ['category']);
      setTests(tests);
    }
  }, [data]);

  const onSearch = e => {
    setSearch(e.target.value);
  };

  const handleTagChange = tags => {
    setTags(tags);
  };

  const onSelectTestChangeHandler = (id, ids) => {
    setSelectedTests(ids);
  };

  const onClickHandler = id => {
    if (onClick) onClick(id);
  };

  return (
    <Card secondary>
      <CardHeader secondary>
        <CardHeaderTitle>
          <FormattedMessage {...messages.titleScreeningSelectTest} />
        </CardHeaderTitle>

        <Tags
          extraClassNames={
            'o-flex--align-start o-flex--justify-space-between u-margin-bottom-medium'
          }
          entityId={entityId}
          label={intl.formatMessage(messages.tagsLabel)}
          isMulti={true}
          isClearable={false}
          creatable={true}
          readOnly={false}
          placeholder={intl.formatMessage(messages.tagsPlaceHolder)}
          noOptionsMessage={intl.formatMessage(messages.tagsNoOption)}
          inline
          onChange={handleTagChange}
        />

        <MilestoneSelect
          entityId={entityId}
          milestoneId={milestoneTestSet.id}
          onChange={selectedItem => {
            setMilestoneTestSet(selectedItem);
          }}
        />

        <FieldInput
          rounded
          placeholder="search"
          icon="search-grey"
          iconLeft
          onChange={onSearch}
        />
      </CardHeader>

      <CardBody secondary>
        {Object.keys(tests).length > 0 ? (
          Object.keys(tests).map(category => (
            <List
              key={`${category}`}
              preview
              style={{
                minWidth: '0'
              }}
            >
              <ListHeader oneTitle>
                <ListColumnTitle>{category}</ListColumnTitle>
              </ListHeader>
              <ListBody>
                <SelectableList
                  onChange={(id, ids) => {
                    onSelectTestChangeHandler(id, ids);
                  }}
                  onClick={id => onClickHandler(id)}
                  selectedIds={selectedTests}
                  alwaysVisible // This props + `visible` at #193
                  // will make the ListItem Checkbox always visible
                >
                  {tests[category].length > 0 &&
                    tests[category].map(test => (
                      <TestListItem
                        uid={test.id}
                        key={test.id}
                        test={test}
                        edit={true}
                        checkbox
                      />
                    ))}
                </SelectableList>
              </ListBody>
            </List>
          ))
        ) : (
          <Message emptyState={true} icon="cursor">
            <MessageText>
              <FormattedMessage {...messages.testListEmpty} />
            </MessageText>
          </Message>
        )}
      </CardBody>
    </Card>
  );
}

export default ScreeningCustomSelectTestCard;
