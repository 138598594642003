import classNames from 'classnames';

const ProgressBarLabelBlock = props => {
  const { children, extraClassNames, value, inline, bold } = props;
  // let valueBar = range;
  return (
    <p
      className={classNames('c-progress-bar__label-block', extraClassNames, {
        'c-progress-bar__label-inline': inline
      })}
    >
      {children}
      {value && <span>{value}</span>}
    </p>
  );
};

export default ProgressBarLabelBlock;
