export const gqlMutation = async (
  appSyncClient,
  {
    mutation,
    variables,
    optimisticResponse,
    update,
    errorPolicy,
    refetchQueries = [],
    onError
  }
) => {
  const result = await appSyncClient.mutate({
    mutation,
    variables,
    optimisticResponse,
    update,
    errorPolicy,
    refetchQueries: () => refetchQueries,
    onError
  });
  return result;
};
