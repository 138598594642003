import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import GrowthPredictionTableHeader from './GrowthPredictionTableHeader';
import GrowthPredictionTableRow from './GrowthPredictionTableRow';
import { Table, TableBody } from 'components/table';
import { sortRowsOnLabel, sortRowsOnCol } from 'utils/sort';

export const FIXED_COLS = 2;

class GrowthPredictionTable extends Component {
  state = {
    sortDirection: null,
    sortIndex: -1
  };
  onClickSort = (sortIndex, sortDirection) => {
    const { onSorted } = this.props;
    this.setState({ sortIndex, sortDirection });
    onSorted && onSorted({ sortIndex, sortDirection });
  };

  render() {
    const {
      dataSet: { cols, colFilterItems, rows },
      growthPredictionType
    } = this.props;
    const { sortDirection, sortIndex } = this.state;

    const sortedRows =
      sortIndex === -1
        ? sortRowsOnLabel(rows)
        : sortRowsOnCol(rows, sortIndex, sortDirection, growthPredictionType);
    let fixedCols = cols;

    if (
      fixedCols.length === 0 ||
      (fixedCols.length < FIXED_COLS &&
        fixedCols[fixedCols.length - 1].id !== undefined)
    ) {
      fixedCols = [...fixedCols, {}];
    }
    return (
      <Table benchmarkTable>
        <GrowthPredictionTableHeader />
        <TableBody>
          {sortedRows.map(row => (
            <GrowthPredictionTableRow key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    );
  }
}

export default withRouter(
  injectIntl(inject('routing', 'authStore')(observer(GrowthPredictionTable)))
);
