import { useState, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_GET_EXERCISE_COPY } from 'services/aws/exercises-query';
import ExerciseCopy from 'models/ExerciseCopy';
import { StoreContext } from 'index';

const useExerciseCopy = (entityId, exerciseId, exercise, ...props) => {
  const {
    uiState: { locale }
  } = useContext(StoreContext);
  const [language, setLanguage] = useState(props.language);
  const [exerciseCopy, setExerciseCopy] = useState(
    new ExerciseCopy({ exerciseId, language: props.language })
  );
  const { loading, error, data } = useQuery(QUERY_GET_EXERCISE_COPY, {
    variables: { id: exerciseId, entityId },
    skip: !exerciseId
  });

  useEffect(() => {
    if (data?.getExercise) {
      const lang = language ?? locale;
      const exerciseData = { ...data.getExercise };
      if (exerciseData.copy.length > 0) {
        const currentCopy = exerciseData.copy.find(c => c.language === lang);
        if (currentCopy) {
          setExerciseCopy(new ExerciseCopy({ exerciseId, ...currentCopy }));
          return;
        } else {
          setExerciseCopy(
            new ExerciseCopy({
              exerciseId,
              language: lang,
              title: '',
              protocol: '',
              note: '',
              remarks: '',
              cues: exerciseData.cues.map(() => '')
            })
          );
          return;
        }
      }

      const cues = exerciseData.cues.map(() => '');
      setExerciseCopy(
        new ExerciseCopy({
          exerciseId,
          language: lang,
          title: '',
          protocol: '',
          note: '',
          remarks: '',
          cues
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, exercise, exerciseId, entityId, locale, language]);

  return { exerciseCopy, loading, error, setLanguage };
};

export default useExerciseCopy;
