import { useContext } from 'react';
import { withRouter, generatePath } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import {
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_EDIT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS
} from 'routes/RouteList';
import { TabList, Tab, TabPanel, Tabs, TabPanelBody } from 'components/tabs';
import SporterCard from 'containers/partials/cards/SporterCard';
import messages from 'messages';
import MTSTestDataResultCard from 'containers/partials/cards/MTSTestDataResultCard';
import { StoreContext } from 'index';
import SporterTabsLegacy from 'containers/partials/cards/SporterReportCard';
import useTabIndex from 'hooks/utils/useTabIndex';
import { useSessionContext } from 'contexts/SessionContext';

const SporterTabsMTS = ({
  edit,
  entityId,
  sessionId,
  sporterId,
  testDataId
}) => {
  const {
    routing: { push }
  } = useContext(StoreContext);
  const { session } = useSessionContext();

  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL
  ]);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
      <TabList>
        <Tab
          key="session.sporter.mtsscan"
          onClick={() =>
            push(
              `${ROUTE_SESSIONS_ENTITY_SESSION_SPORTER.replace(
                ':sessionId',
                sessionId
              )
                .replace(':entityId', entityId)
                .replace(':sporterId', sporterId)}`
            )
          }
        >
          <FormattedMessage {...messages.tabSessionSporterScan} />
        </Tab>

        <Tab
          key="session.sporter.mtsdata"
          onClick={() =>
            push(
              generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS, {
                sessionId,
                entityId,
                sporterId
              })
            )
          }
        >
          <FormattedMessage {...messages.tabSessionSporterTests} />
        </Tab>
        <Tab
          key="session.sporter.global"
          onClick={() =>
            push(
              generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL, {
                sessionId,
                entityId,
                sporterId
              })
            )
          }
        >
          <FormattedMessage {...messages.tabSessionGlobal} />
        </Tab>
      </TabList>

      <TabPanel>
        <TabPanelBody withScroller>
          <SporterTabsLegacy
            testDataId={testDataId}
            entityId={entityId}
            rootEntityId={entityId}
            session={session}
          />
        </TabPanelBody>
      </TabPanel>
      {/* Tab > For MTS > Data Result */}
      <TabPanel key="session.sporter.mtsdata">
        <TabPanelBody withScroller>
          <MTSTestDataResultCard
            edit={edit}
            entityId={entityId}
            rootEntityId={entityId}
            sporterId={sporterId}
            sessionId={sessionId}
            testDataId={testDataId}
            editUrl={generatePath(
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_EDIT,
              {
                entityId,
                sporterId,
                sessionId
              }
            )}
            resetUrl={generatePath(
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
              {
                entityId,
                sporterId,
                sessionId
              }
            )}
          />
        </TabPanelBody>
      </TabPanel>
      {/* Tab > GLOBAL */}
      <TabPanel key="session.sporter.global">
        <TabPanelBody withScroller>
          <SporterCard
            entityId={entityId}
            parentPath={generatePath(
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
              {
                sessionId,
                entityId,
                sporterId
              }
            )}
          />
        </TabPanelBody>
      </TabPanel>
    </Tabs>
  );
};

export default withRouter(SporterTabsMTS);
