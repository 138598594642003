import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { useNotificationQueue } from 'components/notification';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';

import {
  MUTATION_EDIT_PROGRAM,
  QUERY_GET_PROGRAM,
  QUERY_GET_PROGRAMS
} from 'services/aws/programs-query';
import { useExercisesContext } from 'contexts/ExercisesContext';
import messages from 'messages';
import { format } from 'date-fns';
import { parseDateString } from 'utils/date';

function ProgramForm({
  entityId,
  program,
  children,
  extraClassNames,
  onComplete,
  style
}) {
  const intl = useIntl();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage(messages.titleRequired)),
    startdate: Yup.date()
      .transform(parseDateString)
      .typeError(intl.formatMessage(messages.dateRequired))
      .required(intl.formatMessage(messages.startdateRequired)),
    duration: Yup.number().required(
      intl.formatMessage(messages.durationRequired)
    )
  });

  const { exercisesState } = useExercisesContext();

  const notification = useNotificationQueue();

  const [editProgram] = useMutation(MUTATION_EDIT_PROGRAM);

  const onSubmitHandler = async program => {
    await editProgram({
      variables: {
        ...program,
        startdate: format(program.startdate, 'yyyy-LL-dd'),
        exerciseIds: exercisesState.selectedExercises.map(e => e.id)
      },
      refetchQueries: [
        {
          query: QUERY_GET_PROGRAMS,
          variables: { entityId }
        },
        {
          query: QUERY_GET_PROGRAM,
          variables: { id: program.id }
        }
      ]
    }).then(({ data }) => {
      notification.add(data.editExerciseProgram.id, {
        message: intl.formatMessage(messages.programSaved)
      });
      onComplete && onComplete(data.editExerciseProgram.id);
    });
  };

  return (
    <Formik
      initialValues={program}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmitHandler}
    >
      {props => {
        return (
          <Form
            className={classNames(extraClassNames)}
            noValidate
            style={style}
          >
            {typeof children === 'function' ? children(props) : children}
          </Form>
        );
      }}
    </Formik>
  );
}

export default ProgramForm;
