import React from 'react';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import {
  MUTATION_COMPLETE_VIDEO,
  QUERY_GET_VIDEO
} from 'services/aws/videos-query';
import { useIntl } from 'react-intl';
import { useNotificationQueue } from 'components/notification';

const CompleteVideoButton = ({ video, entityId, children, ...props }) => {
  const intl = useIntl();
  const notification = useNotificationQueue();
  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_COMPLETE_VIDEO}
      variables={{
        id: video.id,
        entityId: entityId,
        title: video.title,
        meta: JSON.stringify({ ...video.meta, finished: true })
      }}
      refetchQueries={[
        {
          query: QUERY_GET_VIDEO,
          variables: { id: video.id, entityId }
        }
      ]}
      update={(cache, { data: { editExercise } }) => {
        if (editExercise) {
          notification.add(editExercise.id, {
            message: intl.formatMessage(messages.messageVideoFinished)
          });
        }
      }}
      confirmTitle={messages.modalConfirmFinishVideoTitle}
      confirmMessage={messages.modalConfirmFinishVideoMessage}
      confirmAcceptLabel={messages.modalConfirmFinishVideoAcceptButton}
    >
      {children}
    </ConfirmMutationButton>
  );
};

export default CompleteVideoButton;
