import { useState } from 'react';
import { useTimeoutFn } from 'react-use';

const useFormLeave = () => {
  const [focused, setFocus] = useState(false);
  const [cancel, reset] = useTimeoutFn(() => {
    setFocus(false);
  }, 400);

  const handleFocus = () => {
    cancel();
    setFocus(true);
  };

  const handleBlur = () => {
    reset();
  };

  return {
    focused,
    bind: {
      onFocus: () => handleFocus(),
      onBlur: () => handleBlur()
    }
  };
};

export default useFormLeave;
