import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useEdit = (initialEdit = false, routing = false) => {
  let location = useLocation();
  let history = useHistory();
  const [edit, setEditState] = useState(initialEdit);

  useEffect(() => {
    setEditState(initialEdit);
  }, [initialEdit]);

  const setEdit = edit => {
    if (edit) {
      history.push(
        location.pathname.endsWith('/edit')
          ? `${location.pathname}`
          : `${location.pathname}/edit`
      );
    } else {
      history.push(location.pathname.replace('/edit', ''));
    }
  };

  return [edit, setEdit];
};

export default useEdit;
