import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import CompleteVideoButton from 'containers/partials/buttons/CompleteVideoButton';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { Button, ButtonsGroup } from 'components/button';
import Icon from 'components/icon/Icon';
import ShareVideoModal from 'containers/pages/videos/ShareVideoModal';
import { StoreContext } from 'index';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_ANNOTATE,
  ROUTE_VIDEOS_ENTITY_VIDEO_ANNOTATE
} from 'routes/RouteList';

AnnotateVideoButtonBar.propTypes = {};

function AnnotateVideoButtonBar({
  entityId,
  video,
  annotations,
  annotationBtnLabel,
  annotatePath,
  children,
  extraClassNames,
  onClickAnnotation
}) {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  let { push } = useHistory();
  const [shareModal, setShareModal] = useState(false);
  const isAnnotateView = useRouteMatch([
    ROUTE_VIDEOS_ENTITY_VIDEO_ANNOTATE,
    ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_ANNOTATE
  ]);

  return (
    <>
      <ButtonsGroup extraClassNames={extraClassNames}>
        {video.isMine(user) && (
          <>
            {!video.finished && annotations.length > 0 && (
              <CompleteVideoButton
                video={video}
                entityId={entityId}
                accented
                rounded
                extraClassNames="u-margin-left-auto"
              >
                <FormattedMessage {...messages.videoFinishButtonLabel} />
              </CompleteVideoButton>
            )}
            {video.finished && (
              <Button
                light
                rounded
                extraClassNames="u-margin-left-auto"
                onClick={() => {
                  setShareModal(true);
                }}
              >
                <Icon id={'add-user'} strokeColor="color-neutral-x-light" />
                <FormattedMessage {...messages.videoAnnotationsShareBtn} />
              </Button>
            )}
          </>
        )}

        {isAnnotateView &&
          ((video.isMine(user) && !video.finished) ||
            (!video.isMine(user) && video.finished)) && (
            <Button
              primary
              rounded
              onClick={() => {
                push(annotatePath);
                onClickAnnotation && onClickAnnotation();
              }}
              extraClassNames={
                (!video.isMine(user) || annotations.length === 0) &&
                'u-margin-left-auto'
              }
            >
              <>
                <Icon id={'lesson'} strokeColor="color-neutral-x-light" />
                {annotationBtnLabel}
              </>
            </Button>
          )}

        {!isAnnotateView && (
          <Button
            primary
            light
            rounded
            onClick={() => push(annotatePath)}
            extraClassNames={
              (!video.isMine(user) || annotations.length === 0) &&
              'u-margin-left-auto'
            }
          >
            <>
              <Icon id={'arrow-right-left'} fillColor="color-neutral-dark" />
              {annotationBtnLabel}
            </>
          </Button>
        )}

        {children}
      </ButtonsGroup>
      {shareModal && (
        <ShareVideoModal
          entityId={entityId}
          linkId={video.id}
          onClose={() => setShareModal(false)}
        />
      )}
    </>
  );
}

export default AnnotateVideoButtonBar;
