import React, { useEffect, useState } from 'react';
import { QUERY_GET_TAGS } from 'services/aws/tags-query';

import RetryPanel from '../error-boundary/RetryPanel';
import TagsSelect from 'components/input/TagsSelect';
import { useQuery } from '@apollo/client';
import { sort } from 'utils/sort';

const Tags = ({
  entityId,
  label,
  tags,
  edit = true,
  inline,
  rounded,
  placeholder,
  noOptionsMessage,
  isClearable = false,
  creatable,
  isMulti,
  onChange,
  onInputChange,
  extraClassNames,
  dropdownDisabled,
  isLoading,
  isSearchable
}) => {
  const [tagOptions, setTagOptions] = useState([]);

  const { loading, error, data } = useQuery(QUERY_GET_TAGS, {
    variables: { entityId }
  });

  useEffect(() => {
    if (data?.getTagsByEntity) {
      setTagOptions(
        sort(
          data.getTagsByEntity.map(tag => ({
            id: tag.id,
            label: tag.label,
            value: tag.label
          })),
          { keys: [{ key: 'order' }] }
        )
      );
    }
  }, [data]);

  if (error) return <RetryPanel />;

  return (
    <TagsSelect
      extraClassNames={extraClassNames}
      label={label}
      options={tagOptions}
      value={tags && tags.map(tag => tag && { ...tag, value: tag.label })}
      isMulti={isMulti}
      isClearable={isClearable}
      isLoading={isLoading || loading}
      isSearchable={isSearchable}
      creatable={creatable}
      readOnly={!edit}
      disabled={!edit}
      onChange={onChange}
      onInputChange={onInputChange}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage}
      inline={inline}
      rounded={rounded}
      dropdownDisabled={dropdownDisabled}
    />
  );
};

export default Tags;
