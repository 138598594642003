import { lateralStepDown } from 'containers/pages/test/Tests';

export const files = [
  {
    label: 'Jump_Landing-F_view(1)',
    value: '2859924d-adc5-4918-9d55-22d348f790fd',
    url: 'https://stream.mux.com/IVwBCfw01qIOHBCxWBIN01JgeAJwZu3YJhlqyouWBvJnM.m3u8'
  },
  {
    label: 'Jump_Landing-F_view(2)',
    value: 'b081d617-6775-4c96-bb59-03f3bdf9c23f',
    url: 'https://stream.mux.com/pXbYCguYxe005RY01CoKxKmAfz0100vHrfFKqkLfnVKD3so.m3u8'
  },
  {
    label: 'Jump_Landing-F_view(3)',
    value: '231d8da9-be25-4e4d-ae37-94740551ca89',
    url: 'https://stream.mux.com/uWyILinttZArIojhUXEW02i28NuJ4knMRGGLTkG19zMo.m3u8'
  },
  {
    label: 'Jump_Landing-F_view(4)',
    value: 'f2ef6c13-6c20-451c-98a6-16413bbbfb8a',
    url: 'https://stream.mux.com/f24WoEWmLnQ2PvJMd00r0088Sy00JOe02pVjF3bpfpKCWEI.m3u8'
  },
  {
    label: 'Jump_Landing-F_view(5)',
    value: '390a2b2f-732b-462e-9812-dcce3893bbcf',
    url: 'https://stream.mux.com/PE0102OQ1zPD3d27nkk1Mw6GSSv8NryApje7KUET6SHpY.m3u8'
  },
  {
    label: 'Jump_Landing-F_view (6)',
    value: '12186f5b-8f41-460d-af66-a8d0c6862327',
    url: 'https://stream.mux.com/IV5jlj00zb4sqJnsYsxrdpUKsSso01Kwx1JYc7sUkPng4.m3u8'
  },
  {
    label: 'Jump_Landing-F_view (7)',
    value: '2df50e50-cd67-46eb-a8bd-397cd1ea34cc',
    url: 'https://stream.mux.com/a9xijXkC4c7s7Dh9EGa6i9rmSVwO9wvU8VctP7Cqdpo.m3u8'
  },
  {
    label: 'Jump_Landing-F_view (8)',
    value: '93dbe8b1-56a7-46f8-9025-9b4ecb069e5a',
    url: 'https://stream.mux.com/wedHuK2BXy6WYQfJiqWHa3CtqvvBVSsqKtPEAPWvxD00.m3u8'
  },
  {
    label: 'Jump_Landing-F_view (9)',
    value: 'fc1805ea-cb2b-4882-9419-fe008756ac84',
    url: 'https://stream.mux.com/rZaYFXMtuG8sQIXb18PN0154kv1hTsZW3WsoWdd29Y24.m3u8'
  },
  {
    label: 'Jump_Landing-S_view(1)',
    value: '3fca5fe5-de76-46bf-928c-fac4d994e8a4',
    url: 'https://stream.mux.com/02gG01OSYXrfjPvOJoZ4g02AxNo00BSMVQdFduUxi42Vmm8.m3u8'
  },
  {
    label: 'Jump_Landing-S_view(2)',
    value: 'da017a21-fbb4-4f9a-a506-94399ba82601',
    url: 'https://stream.mux.com/JQd3xRNyBdGuC6CWdFBmq1ThdabOEeXROlj700UF4m2c.m3u8'
  },
  {
    label: 'Jump_Landing-S_view(3)',
    value: 'c2552b9b-79d1-4b69-98d9-2940ceebd441',
    url: 'https://stream.mux.com/fGn62CWA00CmNX7OtI02mQXeFxVcgdd1QIRTTDxz8RD4Y.m3u8'
  },
  {
    label: 'Jump_Landing-S_view(4)',
    value: '5861be10-397b-413c-9092-72cbb4f42e2f',
    url: 'https://stream.mux.com/01NwC6rcmlyjzF8uxkZ4iP2ANm8j802LCPEIYWc1GwXwM.m3u8'
  },
  {
    label: 'Jump_Landing-S_view(5)',
    value: 'e104539c-1c39-44e4-a194-289e7cdb82cf',
    url: 'https://stream.mux.com/BKGfvcTl46WDc5P01x85Nq9wtOjfpq01Go00PlUpxl8Akw.m3u8'
  },
  {
    label: 'Jump_Landing-S_view (6)',
    value: '0a415fec-d579-4d9b-a81c-d1c304b4cb5a',
    url: 'https://stream.mux.com/9P7Cg02MXkQ7RrUasRMbdZY7Jlew8iZ2xumIUR82tkTE.m3u8'
  },
  {
    label: 'Jump_Landing-S_view (7)',
    value: '728b89cf-26ff-4a6f-92bc-3f5b39ead561',
    url: 'https://stream.mux.com/HjfxfqiWKtkXlKqdFCw4XUj2Bu6j2W8fwI3k7wUJdyA.m3u8'
  },
  {
    label: 'Jump_Landing-S_view (8)',
    value: 'b8ae3f84-06c3-4777-aba0-980c6f99c36d',
    url: 'https://stream.mux.com/T00uuv5nDMCF00DORkWd00wcP6vI7yncyky102HbtNBYRx4.m3u8'
  },
  {
    label: 'Jump_Landing-S_view (9)',
    value: '189e95c1-5a06-4e5a-81f6-e5f5223032d2',
    url: 'https://stream.mux.com/EgXVSZVn1OKx7SMHTAOu01802xkXPgXzX02Qn01XqOJ02CcE.m3u8'
  },
  {
    label: 'Lateral_Step_Down-Left_leg-1',
    value: '652c2ee3-65d6-4ebf-b9e3-271f21f94dd8',
    url: 'https://stream.mux.com/2C9Sg98npwm6DUrJE6ldVR7LG2awCD00NHIUldl028nrw.m3u8'
  },
  {
    label: 'Lateral_Step_Down-Right_leg-1',
    value: '141709c2-d1b6-4e97-802b-72761ffa8110',
    url: 'https://stream.mux.com/337b428wS0146hlquIDIqBiHVwvFH01t01yLI00D7CnklQE.m3u8'
  },
  {
    label: 'Lateral_Step_Down-Left_leg-2',
    value: '9ea3fe15-a761-4e84-975e-0158ad13ed89',
    url: 'https://stream.mux.com/c3nkM01cWOVMcg3z5TtuJ8M8rrpCZGDgbVVLlOQd1BwE.m3u8'
  },
  {
    label: 'Lateral_Step_Down-Right_leg-2',
    value: '3f4af533-aa16-431f-b619-3ff0acf1889f',
    url: 'https://stream.mux.com/M7miu3l8pYxQ7f701hOZU00jvMny01omL5Yn75h5ljQU01M.m3u8'
  },
  {
    label: 'Lateral_Step_Down-Left_leg-3',
    value: '18938fd2-87b6-4981-b7f0-739904289329',
    url: 'https://stream.mux.com/lXqFb76hbX01w58MBRusoLesJUbpg01JUjdycfwV3Iby00.m3u8'
  },
  {
    label: 'Lateral_Step_Down-Right_leg-3',
    value: '6a7e35a1-4254-4ce5-946e-b4ad1e3dc5f8',
    url: 'https://stream.mux.com/Nbb2S02aZOTZG5Uv9UI71TcgKrNoeOkfrRwQtqlvcMnA.m3u8'
  },
  {
    label: 'Lateral_Step_Down-Left_leg-4',
    value: '4a84365b-9623-4990-95ab-ca76e847296f',
    url: 'https://stream.mux.com/ClqsVf4NZfQaDS11ZjWGZTNuAffy4bwEYUYz9uWv2lg.m3u8'
  },
  {
    label: 'Lateral_Step_Down-Right_leg-4',
    value: 'eeb478e0-edce-4989-bc19-766f248a87b2',
    url: 'https://stream.mux.com/t02sE14rhW01Rem00VMDu2jYfC1cas6cwf900WYo3cZBG200.m3u8'
  },
  {
    label: 'Lateral_Step_Down-Left_leg-5',
    value: '506308dd-a494-4843-825e-aa2bcd461ac6',
    url: 'https://stream.mux.com/OD79KD02tCPA3hfDBVEpiIfaDHRFD3UUTOEnWNLZmaQk.m3u8'
  },
  {
    label: 'Lateral_Step_Down-Right_leg-5',
    value: 'a8433451-55f0-4be0-81bf-49c201cd86d1',
    url: 'https://stream.mux.com/TCgR3wjdA00u8NArZF9XOBVdsmL6L4tAgndPj2JXphSU.m3u8'
  },
  {
    label: 'Lateral_Step_Down-Left_leg-6',
    value: 'd240d150-ea2d-40e9-8363-8a88945939c2',
    url: 'https://stream.mux.com/5RZn1u2XaXGoHiYV4bVaNebbKKGjmzyvb18mGm02ubtk.m3u8'
  },
  {
    label: 'Lateral_Step_Down-Right_leg-6',
    value: '4245211a-2c6e-41db-a7a6-fc101412158a',
    url: 'https://stream.mux.com/OL4aGZuM00aBYgR025SXGwI7Izyd84022l62QF6uLyGs00c.m3u8'
  },
  /*
  {
    label: 'Lateral_Step_Down-Left_leg-7',
    value: '8891014a-694d-4fa8-8b06-195524adb440',
    url: ''
  },*/
  {
    label: 'Lateral_Step_Down-Left_leg-8',
    value: 'bfed0c14-95df-42a0-b70e-9cecd6391f2f',
    url: 'https://stream.mux.com/mCaixytc8AM02n3CL2m2e4Vhy9nkQWUIZ8EO2Xkyg5Oo.m3u8'
  },
  {
    label: 'Lateral_Step_Down-Right_leg-8',
    value: 'b6e09ce9-3024-494a-9d95-57ebbe59c208',
    url: 'https://stream.mux.com/vb3lbrTs7EZZXNUsWDqaB01cWdC5hufqHSUDZbSJO003k.m3u8'
  },
  {
    label: 'Deep_Squat_Test-1',
    value: '79db694e-45c5-4d05-8536-5d2412b784e0',
    url: 'https://stream.mux.com/4RinpW4Ft9122JFYgZZUEV3iTB00lF3NZH01IE1LNYtkE.m3u8'
  },
  {
    label: 'Deep_Squat_Test-2',
    value: '4ae1bfeb-7cdc-4245-8598-cd6c94701c21',
    url: 'https://stream.mux.com/9iVnSiLfoBn00FkGen4S5LoTbokCRmi9qKhSo500202YNA.m3u8'
  },
  {
    label: 'Deep_Squat_Test-3',
    value: '9729c408-48eb-4afe-919e-ca15c2ac25b8',
    url: 'https://stream.mux.com/I601YI4zei15qR1G00cBu5rC7pBuZH1SypBXe11mcHedM.m3u8'
  },
  {
    label: 'Deep_Squat_Test-4',
    value: '77b90933-03cc-487c-a1f1-3ab8bff9de23',
    url: 'https://stream.mux.com/raWkHE00xnRf6qI8d7dJ2GLHVWxLX00a3pGgaFOk9h1r4.m3u8'
  },
  {
    label: 'Deep_Squat_Test-5',
    value: 'b620d1ec-968f-4ac5-94a9-a2a55a45fd32',
    url: 'https://stream.mux.com/3nxlKUBgDkbaG01ZRLo8MfNddP01jTz98NBR4yl7vJbVE.m3u8'
  },
  {
    label: 'Deep_Squat_Test-6',
    value: 'e9d3b0b0-538d-4897-b73a-dfc0462abb88',
    url: 'https://stream.mux.com/0002iSaOi1H026dg6PoItbFK6q5g6xDjplMFjK102u3f600c.m3u8'
  },
  {
    label: 'Deep_Squat_Test-7',
    value: '52d86297-91b9-4104-b81f-5a248908e2ea',
    url: 'https://stream.mux.com/xqZiF01cXP3kX1u8gGGMr34pvNdKa6Jf38ylX01jcxMT8.m3u8'
  },
  {
    label: 'Deep_Squat_Test-8',
    value: 'fd4cb548-79b2-43e6-abb3-5eb8a83c56d0',
    url: 'https://stream.mux.com/Yw3Ch01xjgCd5E01ipLVMqXBW4Uvo57Ff4g7FSRlA01ahc.m3u8'
  },
  {
    label: 'Deep_Squat_Test-9',
    value: 'e480daad-9ee7-44a1-bf19-9cfe6243b38f',
    url: 'https://stream.mux.com/cAUZBaOXLuIglk5feDDVkoBqeeKTfayPWrIleqQGyyY.m3u8'
  },
  {
    label: 'Tuck_Jump_Test-F_View-1 (score 12213003)',
    value: '4f83a2da-9d1e-47fb-a4f3-20063de7',
    url: 'https://stream.mux.com/vPKZFRl2uOzSoY97FlfjDs01KQcv7v9E00rVy57z6I4KY.m3u8'
  },
  {
    label: 'Tuck_Jump_Test-F_View-2 (score 20323002)',
    value: 'a0d69d8d-4d7c-4d56-9017-802b7374ea4e',
    url: 'https://stream.mux.com/leZVxjRcPXyqKEBtMFZTzu01N1XTqLza5sbcB00MZP00ck.m3u8'
  },
  {
    label: 'Tuck_Jump_Test-F_View-3 (score 32212003)',
    value: '5d29f3fa-d120-48ad-a6f2-b84c54d1bff9',
    url: 'https://stream.mux.com/pFqsHkjewnapqVQUHns1MMcmzf5ujDCBQItPLDu3W7M.m3u8'
  },
  {
    label: 'Tuck_Jump_Test-F_View-4 (score 32212003)',
    value: 'ab6a14fc-6cca-453d-832b-fefb016bd805',
    url: 'https://stream.mux.com/m8bfr12XCLmo2ioEF92YgyQ3kyKvquAHtpGdg4ZNcTE.m3u8'
  },
  {
    label: 'Tuck_Jump_Test-F_View-5 (score 31211003)',
    value: 'c4d571c9-6410-4fa6-ab3a-cff811a9eea6',
    url: 'https://stream.mux.com/YdEId015Brhsh8hHDJ00CKHj2cZSoggDSbsIS4DJD7Gfw.m3u8'
  },
  {
    label: 'Tuck_Jump_Test-F_View-6 (score 10310001)',
    value: 'f34ab24a-50b8-46d7-9bdd-d8fd01d29a02',
    url: 'https://stream.mux.com/02oPLNmDDD9caPMrOe02UmKjkGMm026u8h302pNtLd6Ok8c.m3u8'
  },
  {
    label: 'Tuck_Jump_Test-F_View-7 (score 00231001)',
    value: '7fa3f2e9-42fa-415c-8f60-e3340f1a8f3c',
    url: 'https://stream.mux.com/XVrN3006SmCHUXOhTaBTgiw1BNxY8KpjubR02LzrINzYY.m3u8'
  },
  {
    label: 'Tuck_Jump_Test-F_View-8 (score 02331001)',
    value: 'bd32bb18-9cbe-4fcf-b502-49e0cd0a5187',
    url: 'https://stream.mux.com/8cy95DK5O00ytNbPMbqTFaY023Z5hsiRhDa5EzRiOhzME.m3u8'
  },
  {
    label: 'Tuck_Jump_Test-F_View-9 (score 21001002)',
    value: '2a48827a-b172-4a81-992b-dfa1fca7f8a9',
    url: 'https://stream.mux.com/g6q3nKSF53CB6Bia3hChgw4scCebRGfUxP02vSFJJn02A.m3u8'
  },
  {
    label: 'Tuck_Jump_Test-S_View-1 (score 12213003)',
    value: 'b7863bae-e7ff-4d5a-a0c6-7318a95458f3',
    url: 'https://stream.mux.com/o3W1T02A02J5L1wnvlgbtYwjLWT7K1MX7ZkX5UMMmmckw.m3u8'
  },
  {
    label: 'Tuck_Jump_Test-S_View-6 (score 10310001)',
    value: '75f06011-20ce-4ed9-9455-0346885300cd',
    url: 'https://stream.mux.com/ceqb7Jp7DByPhDSKRdahmJK5xI004XsTcvAq8KGLAjsU.m3u8'
  },
  {
    label: 'Deep_Squat_Test-1_640',
    value: '2576ff11-f6a6-4601-b0c5-5048ea2c8af7',
    url: 'https://stream.mux.com/a02jw4nmSBKi01bA2guJ8X4JWL01pM6b3QEkQEA1pa9gyI.m3u8'
  },
  {
    label: 'Deep_Squat_Test-1_320',
    value: 'b186711a-27a2-4002-ab34-50580d20a0b6',
    url: 'https://stream.mux.com/lq901ZdzO9ByGyjNwVlPaE02D2FvhIDxGJCUpW5opL68w.m3u8'
  },
  {
    label: 'Unipodal_Jump_Landing-1 left-leg (score 101)',
    value: '5d00a311-063e-4588-95df-4e82c98b48ee',
    url: 'https://stream.mux.com/nGu01m55wlmOLphsUBEUsEDGdTlf4KPCwqghnIbOJ9OE.m3u8'
  },
  {
    label: 'Unipodal_Jump_Landing-1 right-leg',
    value: '187eb789-a918-4a50-860d-1d9d6ede6bba',
    url: 'https://stream.mux.com/9HLj76iIuRJwnyXvDPePiSnsnzzrE6IzHZ3xxePsuXI.m3u8'
  },
  {
    label: 'Unipodal_Jump_Landing-2 left-leg',
    value: 'aae7d619-a99e-4406-9814-274ecd258d9f',
    url: 'https://stream.mux.com/1HySdtUEXQkjJ1Uc1V1KiiGMHiqYgr01Qd9fBWi58rME.m3u8'
  },
  {
    label: 'Unipodal_Jump_Landing-2 right-leg',
    value: '1573318e-044a-463f-9c96-6fd88f397f1f',
    url: 'https://stream.mux.com/FObTBqXiNKUIgorFelLW02fHRP4p00GSpQ3RpPPud5bDM.m3u8'
  },
  {
    label: 'Unipodal_Jump_Landing-3 left-leg',
    value: '8a520987-b07f-4a89-b607-6f8e8fa19063',
    url: 'https://stream.mux.com/j4a6XbPSyvsXaSpzgyjqdv01hX8w95P02FvoagkOXLsVc.m3u8'
  },
  {
    label: 'Unipodal_Jump_Landing-3 right-leg',
    value: 'e06b9a68-c29a-412b-bfb7-6d23e7ddbbc4',
    url: 'https://stream.mux.com/5YCbqVPaf01XvpTP2eYeQpBgxFT9qiLk3LpPuiNhenU8.m3u8'
  },
  {
    label: 'Unipodal_Jump_Landing-4 left-leg',
    value: '9fbfcb90-a6c0-4743-abda-c33a5022be22',
    url: 'https://stream.mux.com/r200AzIIL1I701eAt02M2se823rsYDV00r9cfW89b4irdaY.m3u8'
  },
  {
    label: 'Unipodal_Jump_Landing-4 right-leg',
    value: '01ff3a28-b3ae-4ece-86c6-4a2a63219622',
    url: 'https://stream.mux.com/Ed2s3QO4eSZFEPfY5tV2BnSMHKpgL7gNojnPe202GQnE.m3u8'
  },
  {
    label: 'Unipodal_Jump_Landing-5 left-leg',
    value: 'bc0d180e-7cd4-4d5d-a75e-1ef764e27599',
    url: 'https://stream.mux.com/bv01fbZYVT32SL02rksvXO00jHRbt4lDlGVYaRgbHgtlMk.m3u8'
  },
  {
    label: 'Unipodal_Jump_Landing-5 right-leg (score 201)',
    value: 'db71175d-3e90-4ce8-8ef0-dca18166de0c',
    url: 'https://stream.mux.com/rLYh1cmcZTtaEHFQLnA01PGCUL5sbRvRBUuTiUVngI02w.m3u8'
  },
  {
    label: 'Unipodal_Jump_Landing-6 left-leg',
    value: '7093758a-100e-4daa-8f68-f19e073214f8',
    url: 'https://stream.mux.com/E8wtEicXfmeIngmWuJvG01rs2BrPzRq9Mv01RicihhvGQ.m3u8'
  },
  {
    label: 'Unipodal_Jump_Landing-6 right-leg',
    value: 'a0adf7e1-1fc5-4add-a336-b2585bd7c5fc',
    url: 'https://stream.mux.com/QETa01o6lCEWqyXCbDGWDMgpEfqZk3GLc46AsScXmTCQ.m3u8'
  },
  {
    label: 'Unipodal_Jump_Landing-7 left-leg',
    value: '73284e67-5f10-4a06-a70a-b58d4972aab5',
    url: 'https://stream.mux.com/24lxOSRU78asJOTxLzLRwlOOEsYhkQ3BzfqMv501R2zs.m3u8'
  },
  {
    label: 'Unipodal_Jump_Landing-7 right-leg',
    value: 'b39da0b1-d058-4b0c-8bdb-58608cbc355f',
    url: 'https://stream.mux.com/W86b400OlLy4GGoWlvGgHfK9pTAfISJf3o9ItVwhSSsM.m3u8'
  },
  {
    label: 'Unipodal_Jump_Landing-8 left-leg',
    value: '0b0a4b30-8c9e-4970-a961-5993ac59abe7',
    url: 'https://stream.mux.com/OgW1iaE4HDo01Cn3RCsvWptu02tSDKa5OLIQXH2ZSy1Wk.m3u8'
  },
  {
    label: 'Unipodal_Jump_Landing-8 right-leg',
    value: '46433e52-be51-470f-a5fb-8156668cb5ee',
    url: 'https://stream.mux.com/UxNsZay9mHfS01JPCyp600P5oUdC02EoR9V5leQCs9L01gY.m3u8'
  }
];

export const exercises = [
  {
    name: 'Jump Landing Front-View',
    method: 'jumpLandingFrontView',
    id: 0
  },
  {
    name: 'Jump Landing Side-View',
    method: 'jumpLandingSideView',
    id: 1
  },
  {
    name: 'Lateral Step Down',
    method: 'lateralStepDown',
    id: 2
  },
  {
    name: 'Deep Squat',
    method: 'deepSquat',
    props: { elevatedHeels: false },
    id: 3
  },
  {
    name: 'Deep Squat Elevated Heels',
    method: 'deepSquat',
    props: { elevatedHeels: true },
    id: 4
  },
  {
    name: 'Tuck Jump Front-View',
    method: 'tuckJumpFrontView',
    id: 5
  },
  {
    name: 'Tuck Jump Side-View',
    method: '',
    id: 6
  },
  {
    name: 'Unipodal Jump Landing',
    method: 'unipodalJumpLanding',
    id: 7
  }
];
