import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import CardBody from 'components/card/CardBody';
import messages from 'messages';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderText
} from 'components/card/card-header';
import Cues from 'containers/partials/inputs/Cues';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import Icon from 'components/icon/Icon';
import { sortByArray } from 'utils/sort';
import useExerciseNotes from 'hooks/queries/useExerciseNotes';
import { useParams } from 'react-router-dom';
import MediaViewer from 'containers/partials/media/MediaViewer';
import { sanitize } from 'dompurify';

const getNote = (exercise, notes) => {
  if (notes.length > 0) {
    return notes[0].note.replace(/(?:\r\n|\r|\n)/g, '<br />');
  }
  if (exercise.note) {
    return exercise.note.replace(/(?:\r\n|\r|\n)/g, '<br />');
  }
  return '';
};

const ExerciseViewCard = ({ entityId, exercise, setEdit, editable }) => {
  const { programId, templateId } = useParams();
  const { getNotes, notes } = useExerciseNotes();

  useEffect(() => {
    if (exercise?.id && (programId || templateId)) {
      getNotes({
        exerciseId: exercise.id,
        programId,
        templateId
      });
    }
  }, [exercise, programId, templateId]);

  return (
    <>
      {/* INTRO / Notes */}
      <CardHeader extraClassNames="u-margin-bottom-none" secondary>
        <CardHeaderText>
          <CardHeaderTitle>
            <FormattedMessage {...messages.exercisesLabelNotes} />
          </CardHeaderTitle>
        </CardHeaderText>
        {exercise.editable && editable && (
          <FormEditHeaderButtons setEdit={setEdit} />
        )}
      </CardHeader>
      <CardBody secondary separatorBottom>
        <div className={'c-input__group c-input--closed c-input--read-only'}>
          <div
            className="c-input--textarea-readonly"
            dangerouslySetInnerHTML={{
              __html: sanitize(getNote(exercise, notes), {
                ALLOWED_TAGS: ['b', 'i', 'br']
              })
            }}
          ></div>
        </div>
      </CardBody>

      {/* Remarks */}
      {exercise.remarks && exercise.remarks.length > 0 && (
        <div className="c-card--remarks c-card__body-separator">
          <Icon
            strokeColor="color-primary"
            fillColor="transparent"
            id="attention"
          />
          <div className="c-card__content">
            <CardHeader
              extraClassNames="u-margin-bottom-none"
              secondary
              remarks
            >
              <CardHeaderText>
                <CardHeaderTitle>
                  <FormattedMessage {...messages.exercisesLabelRemarks} />
                </CardHeaderTitle>
              </CardHeaderText>
            </CardHeader>
            <CardBody secondary remarks>
              <div
                className={'c-input__group c-input--closed c-input--read-only'}
              >
                <div
                  className="c-input--textarea-readonly"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(
                      exercise.remarks.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                      {
                        ALLOWED_TAGS: ['b', 'i', 'br']
                      }
                    )
                  }}
                ></div>
              </div>
            </CardBody>
          </div>
        </div>
      )}

      {exercise.media && exercise.media.length > 0 && (
        <CardBody
          secondary
          separatorBottom
          extraClassNames={'c-card__body--media'}
        >
          {/* http://localhost:3000/exercises/7f747a4b-55e9-4641-ae25-c45de20fb32d/ee8e00ee-42f4-43da-b10b-6a24c8b98cd6 */}
          <div className="c-media c-media--main">
            {exercise.media && (
              <MediaViewer
                entityId={entityId}
                id={exercise.id}
                media={
                  exercise.media.length > 0
                    ? exercise.meta?.order
                      ? sortByArray(exercise.media, exercise.meta.order)
                      : exercise.media
                    : []
                }
              />
            )}
          </div>
        </CardBody>
      )}

      {/* CUES */}
      {exercise.cues && exercise.cues.length > 0 && (
        <>
          <CardHeader extraClassNames="u-margin-bottom-none" secondary>
            <CardHeaderText>
              <CardHeaderTitle>
                <FormattedMessage {...messages.exercisesLabelCues} />
              </CardHeaderTitle>
            </CardHeaderText>
          </CardHeader>

          <CardBody secondary separatorBottom>
            <Cues
              entityId={entityId}
              label={``}
              cues={exercise.cues}
              readOnly
            />
          </CardBody>
        </>
      )}

      {exercise.protocol && exercise.protocol.length > 0 && (
        <>
          <CardHeader extraClassNames="u-margin-bottom-none" secondary>
            <CardHeaderText>
              <CardHeaderTitle>
                <FormattedMessage {...messages.exercisesLabelProtocol} />
              </CardHeaderTitle>
            </CardHeaderText>
          </CardHeader>
          <CardBody secondary>
            {/* PROTOCOL */}
            {exercise.protocol && (
              <div
                className={'c-textarea--editor'}
                dangerouslySetInnerHTML={{
                  __html: sanitize(exercise.protocol)
                }}
              />
            )}
          </CardBody>
        </>
      )}
    </>
  );
};

export default ExerciseViewCard;
