import React from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import copy from 'copy-to-clipboard';

import Modal from 'components/modal/Modal';

import messages from 'messages';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import { ButtonsGroup, Button } from 'components/button';
import { CardBody, CardFooter } from 'components/card';
import Textarea from 'components/input/Textarea';
import FieldInput from 'components/input/FieldInput';

class CopyUrlModal extends React.Component {
  isMultiline = false;
  lines = 1;

  constructor(props) {
    super(props);
    let value = props.content || '';
    this.isMultiline = value.search('\n') !== -1;
    if (this.isMultiline) {
      const splitted = value.split('\n');
      this.lines = splitted.length;
    }
    this.state = { value };
  }

  onCloseHandler = () => {
    const { onClose } = this.props;
    if (onClose) onClose();
  };

  onCopyToClipboard = () => {
    const { value } = this.state;
    copy(value);
    this.onCloseHandler();
  };

  render() {
    const { isOpen, intl } = this.props;
    const { value } = this.state;
    return (
      <Modal card autoHeight isOpen={isOpen} onClose={this.onCloseHandler}>
        <CardHeader modal>
          <CardHeaderTitle>
            {intl.formatMessage(
              this.isMultiline
                ? messages.modalCopyUrlsTitle
                : messages.modalCopyUrlTitle
            )}
          </CardHeaderTitle>
          <CardHeaderSubtitle>
            {intl.formatMessage(
              this.isMultiline
                ? messages.modalCopyUrlsSubtitle
                : messages.modalCopyUrlSubtitle
            )}
          </CardHeaderSubtitle>
        </CardHeader>

        <CardBody modal>
          {!this.isMultiline ? (
            <FieldInput url id="sessionurl-id" icon="copy" defaultValue={value}>
              <FormattedMessage {...messages.modalLabelUrl} />
            </FieldInput>
          ) : (
            <Textarea
              id="sessionurl-id"
              icon="copy"
              value={value}
              rows={this.lines}
              resizeVertical
            >
              <FormattedMessage {...messages.modalLabelUrls} />
            </Textarea>
          )}
        </CardBody>
        <CardFooter modal>
          <ButtonsGroup>
            <Button secondary onClick={this.onCloseHandler}>
              <FormattedMessage {...messages.buttonCancel} />
            </Button>
            <Button primary onClick={this.onCopyToClipboard}>
              <FormattedMessage {...messages.modalButtonCopyToClipboard} />
            </Button>
          </ButtonsGroup>
        </CardFooter>
      </Modal>
    );
  }
}

export default withRouter(
  injectIntl(inject('routing', 'authStore')(CopyUrlModal))
);
