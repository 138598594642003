import React, { Component } from 'react';
import classNames from 'classnames';

import PropTypes from 'prop-types';

class DrawerHeader extends Component {
  render() {
    const { title, small, homeUrl, onClick, logo } = this.props;
    return (
      <a
        href={homeUrl}
        className={classNames('c-sidebar__header')}
        onClick={onClick}
      >
        <div className={classNames('c-sidebar__logo')}>
          {logo && <img className="c-sidebar__logo-img" src={logo} />}
        </div>
        {title && (
          <div
            className={classNames('c-sidebar__user-info', {
              'is-small': small,
              'is-large': this.props.large
            })}
          >
            {title && title}
          </div>
        )}
      </a>
    );
  }
}

DrawerHeader.propTypes = {
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  email: PropTypes.string
};

export default DrawerHeader;
