import React from 'react';
import classNames from 'classnames';

class PageContent extends React.Component {
  render() {
    const { centered } = this.props;
    return (
      <div
        className={classNames('c-page__content', {
          'c-page__content-centered': centered
        })}
      >
        {this.props.children}
      </div>
    );
  }
}

export default PageContent;
