import { useIntl } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import ListItem from 'components/list/list-item/ListItem';
import { ROUTE_SETTINGS_TESTSETS_TESTSET } from 'routes/RouteList';
import { TestSetV2Type } from 'constants.js';
import ListItemStatus from 'components/list/list-item/ListItemStatus';
import ListItemLabelWrapper from 'components/list/list-item/ListItemLabelWrapper';
import ListItemLabel from 'components/list/list-item/ListItemLabel';
import messages from 'messages';

const TestSetListItem = ({ active, testSet: { id, title, type, version } }) => {
  const intl = useIntl();
  const { push } = useHistory();
  return (
    <ListItem
      onClick={() =>
        push(
          generatePath(ROUTE_SETTINGS_TESTSETS_TESTSET, {
            testSetId: id,
            type: type ?? TestSetV2Type.TALENT
          })
        )
      }
      active={active}
    >
      <ListItemStatus statusIcon="sets" />
      <ListItemLabelWrapper>
        <ListItemLabel label>{title}</ListItemLabel>
        <ListItemLabel info>
          {type === 'talent' || type === null
            ? intl.formatMessage(messages.talentLabel)
            : type ?? TestSetV2Type.TALENT}
        </ListItemLabel>
        <ListItemLabel info>{`v${version}`}</ListItemLabel>
      </ListItemLabelWrapper>
    </ListItem>
  );
};

export default TestSetListItem;
