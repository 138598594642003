import { PersonInjuryType } from 'constants.js';

export default class PersonIssue {
  id;
  title;
  injuryDate;
  injuryEndDate;
  personInjuryType;
  description;
  extraInfo;
  medicalId;
  constructor({
    id,
    title = '',
    injuryDate = '',
    injuryEndDate = '',
    personInjuryType = PersonInjuryType.ISSUE,
    meta = {},
    description,
    extraInfo,
    medicalId = ''
  }) {
    this.id = id;
    this.title = title ?? '';
    this.injuryDate = injuryDate ? new Date(injuryDate) : '';
    this.injuryEndDate = injuryEndDate ? new Date(injuryEndDate) : '';
    this.personInjuryType = personInjuryType;
    try {
      this.meta = meta ? JSON.parse(meta) : {};
    } catch (e) {
      this.meta = meta;
    }
    this.description = description ?? this.meta.description ?? '';
    this.extraInfo = extraInfo ?? this.meta.extraInfo ?? '';
    this.medicalId = medicalId;
  }
}
