import React, { useContext } from 'react';
import { ROUTE_SESSIONS_ENTITY_TEMPLATES } from 'routes/RouteList';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import {
  MUTATION_ARCHIVE_TESTSET,
  QUERY_GET_TESTSETS
} from 'services/aws/testsets-query';
import { TestSetV2Type } from 'constants.js';
import { StoreContext } from 'index';

const RemoveSessionTemplateButton = ({
  entityId,
  testSetId,
  children,
  onComplete,
  ...props
}) => {
  const {
    routing: { push }
  } = useContext(StoreContext);
  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_ARCHIVE_TESTSET}
      confirmTitle={messages.modalConfirmRemoveTemplateTitle}
      confirmMessage={messages.modalConfirmRemoveTemplateMessage}
      variables={{
        testSetId
      }}
      refetchQueries={[
        {
          query: QUERY_GET_TESTSETS,
          variables: { entityId, type: TestSetV2Type.PREVENTION_TEMPLATE }
        }
      ]}
      update={(cache, { data: { archiveTestSetV2 } }) => {
        if (archiveTestSetV2) {
          push(ROUTE_SESSIONS_ENTITY_TEMPLATES.replace(':entityId', entityId));
        }
      }}
    >
      <Icon id="delete" strokeColor="color-neutral-dark" />
      {children}
    </ConfirmMutationButton>
  );
};

export default RemoveSessionTemplateButton;
