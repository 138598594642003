import React from 'react';
import classNames from 'classnames';

const AccordionContent = props => {
  return (
    <div
      className={classNames('c-accordion__content', props.extraClassNames, {
        'c-accordion__content-visible-flow': props.visibleFlow
      })}
    >
      {props.children}
    </div>
  );
};

export default AccordionContent;
