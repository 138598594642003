import classNames from 'classnames';

const CardHeaderTitle = props => {
  const { children, small, ...rest } = props;

  return (
    <h3
      className={classNames('c-card__header-title', props.extraClassNames, {
        'c-card__header-title--small': small
      })}
    >
      {children}
    </h3>
  );
};

export default CardHeaderTitle;
