import React from 'react';
import classNames from 'classnames';

class MenuBody extends React.Component {
  render() {
    const { children } = this.props;
    return <div className={classNames('c-menu__body')}>{children}</div>;
  }
}

export default MenuBody;
