import React, { Fragment, useState } from 'react';
import { format } from 'date-fns';
import { Card, CardBody } from 'components/card';
import {
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import {
  ListItem,
  ListItemBadge,
  ListItemLabel,
  ListItemLabelWrapper
} from 'components/list/list-item';
import { Button, ButtonsGroup } from 'components/button';
import Icon from 'components/icon/Icon';
import Loader from 'components/loader/Loader';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import useProgramFeedback from 'hooks/queries/useProgramFeedback';

function PersonFeedbackCard({ programId, person }) {
  const [{ feedback, done, loading }] = useProgramFeedback({
    programId: programId,
    userId: person.id
  });

  const intl = useIntl();
  const defaultVisibleItems = 10;
  const [showAll, setShowAll] = useState(false);

  return (
    <Card reports>
      {person && (
        <CardHeader secondary>
          <CardHeaderText extraClassNames="o-flex--wrap">
            <CardHeaderTitle>
              {`${person.firstname} ${person.lastname} - `}
              <FormattedMessage {...messages.programLabelFeedback} />
            </CardHeaderTitle>
            <CardHeaderSubtitle extraClassNames="u-margin-top-none">
              <FormattedMessage
                {...messages.programLabelTimesDone}
                values={{ timesDone: done.length }}
              />
            </CardHeaderSubtitle>
          </CardHeaderText>
        </CardHeader>
      )}
      <CardBody
        secondary
        style={{ maxHeight: 'calc(100vh - 356px)', minHeight: 315 }}
      >
        {loading && <Loader />}
        {feedback.length > 0 && (
          <List>
            <ListBody>
              {feedback.map((note, index) => (
                <Fragment key={note.id}>
                  {(showAll || index < defaultVisibleItems) && (
                    <ListItem key={note.id} small>
                      <ListItemBadge>{index + 1}</ListItemBadge>
                      <ListItemLabelWrapper>
                        <ListItemLabel label>{note.note}</ListItemLabel>
                        <ListItemLabel label>
                          {format(note.date_created, 'dd/LL/yyyy')}
                        </ListItemLabel>
                      </ListItemLabelWrapper>
                    </ListItem>
                  )}
                </Fragment>
              ))}
            </ListBody>
            <ButtonsGroup extraClassNames="o-flex--justify-center u-margin-vertical-medium">
              {feedback.length > defaultVisibleItems && (
                <Button rounded grey onClick={() => setShowAll(!showAll)}>
                  <Icon id={!showAll ? 'dropdown-open' : 'dropdown-closed'} />
                  {showAll
                    ? intl.formatMessage(messages.buttonShowLessFeedback)
                    : intl.formatMessage(messages.buttonShowAllFeedback, {
                        count: feedback.length
                      })}
                </Button>
              )}
            </ButtonsGroup>
            {/* <Button rounded grey extraClassNames="u-margin-vertical-medium">
            <Icon id="dropdown-open" />
            <FormattedMessage {...messages.buttonShowAllFeedback} />
          </Button> */}
          </List>
        )}
        {/* Empty feedback */}
        {feedback.length === 0 && (
          <Card centered>
            <CardBody empty>
              <Message emptyState={true} icon="browse">
                <MessageText>
                  <FormattedMessage
                    {...messages.programAthletesEmptyFeedback}
                  />
                </MessageText>
              </Message>
            </CardBody>
          </Card>
        )}
      </CardBody>
    </Card>
  );
}

export default PersonFeedbackCard;
