export const MODAL_TYPES = Object.freeze({
  ALERT: 'alert',
  WARNING: 'warning'
});

export default class ModalData {
  constructor({
    title = '',
    message = '',
    okLabel = 'OK',
    okHandler = null,
    dismissHandler = null,
    dismissLabel = '',
    dismissButton = true,
    mutationData = null,
    type = MODAL_TYPES.ALERT
  }) {
    this.title = title;
    this.message = message;
    this.okLabel = okLabel;
    this.okHandler = okHandler;
    this.dismissHandler = dismissHandler;
    this.dismissButton = dismissButton;
    this.dismissLabel = dismissLabel;
    this.mutationData = mutationData;
    this.type = type;
  }
}
