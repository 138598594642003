import React, { useState, useEffect, useRef } from 'react';
import { Card, CardBody } from 'components/card';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import List from 'components/list/List';
import ListHeader from 'components/list/ListHeader';
import ListColumnTitle from 'components/list/ListColumnTitle';
import ListBody from 'components/list/ListBody';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_TESTS_BY_SPORT_AND_INJURIES } from 'services/aws/testsets-query';
import { TestSetV2Type } from 'constants.js';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { useFormikContext } from 'formik';
import { TestListItem } from 'containers/partials/list-items/TestListItem';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import { useTestsContext } from 'contexts/TestsContext';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import { groupTests } from 'utils/tests';
import { useUIDSeed } from 'react-uid';

function PreventionSelectTestsCard({ edit, onEdit }) {
  const { testsState, testActions } = useTestsContext();
  const { values: session, submitForm } = useFormikContext();
  const [tests, setTests] = useState([]);
  const uidSeed = useUIDSeed();
  const isReady = useRef(false);

  useEffect(() => {
    testActions.getTests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [getTests, { data }] = useLazyQuery(
    QUERY_GET_TESTS_BY_SPORT_AND_INJURIES,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  useEffect(() => {
    if (!session.preventionType) return;

    const variables = {
      type: TestSetV2Type.PREVENTION,
      preventionType: session.preventionType
    };
    if (session?.sport) {
      variables.sportId = session.sport.id;
    }

    if (session?.injuryIds) {
      variables.injuryIds = Object.keys(session.injuryIds);
    }

    getTests({
      variables: { ...variables }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.sport, session.injuryIds]);

  useEffect(() => {
    if (data?.getTestsBySportAndInjuries) {
      if (!isReady.current) {
        testActions.setTests(data.getTestsBySportAndInjuries);
        isReady.current = true;
      } else {
        testActions.updateTests(data.getTestsBySportAndInjuries);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setTests(groupTests(testsState.unselectedTests));
  }, [testsState.unselectedTests]);

  const onSelectTestChangeHandler = id => {
    const test = testsState.tests.find(t => t.id === id);
    testActions.selectTests([test]);
  };

  const selectAll = () => {
    testActions.selectTests(testsState.unselectedTests);
  };

  return (
    <>
      {edit && (
        <Card secondaryCollapsible empty={Object.keys(tests).length <= 0}>
          <CardHeader secondary>
            <CardHeaderTitle extraClassNames="u-margin-bottom-none o-flex--align-self-center">
              <FormattedMessage {...messages.preventionUnselectedTestsTitle} />
            </CardHeaderTitle>
            {testsState.unselectedTests.length > 0 && (
              <Button
                ghost
                small
                extraClassNames={'u-margin-vertical-none'}
                onClick={() => selectAll()}
              >
                <Icon id="add" />
                <FormattedMessage
                  {...messages.preventionSelectAllTestsButton}
                />
              </Button>
            )}
          </CardHeader>

          <CardBody empty={Object.keys(tests).length <= 0}>
            {Object.keys(tests).length > 0 ? (
              Object.keys(tests).map(category => (
                <List
                  key={category}
                  preview
                  style={{
                    minWidth: '0'
                  }}
                >
                  <ListHeader oneTitle>
                    <ListColumnTitle>{category}</ListColumnTitle>
                  </ListHeader>
                  <ListBody>
                    {tests[category].length > 0 &&
                      tests[category].map(test => (
                        <TestListItem
                          uid={uidSeed(test.id)}
                          key={test.id}
                          checkbox={edit}
                          test={{
                            ...test,
                            video: test.video_link
                              ? `${test.video_link}-320.mp4`
                              : null
                          }}
                          edit={edit}
                          onChange={() => onSelectTestChangeHandler(test.id)}
                        />
                      ))}
                  </ListBody>
                </List>
              ))
            ) : (
              <Message emptyState={true} icon="cursor">
                <MessageText>
                  <FormattedMessage {...messages.testListEmpty} />
                </MessageText>
              </Message>
            )}
          </CardBody>

          {Object.keys(tests).length > 0 && (
            <FormEditFooterButtons
              edit={edit}
              setEdit={onEdit}
              onSubmit={submitForm}
            />
          )}
        </Card>
      )}
    </>
  );
}

export default PreventionSelectTestsCard;
