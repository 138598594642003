export default class Note {
  id;
  linkId;
  linkId2;
  linkId3;
  note;
  noteType;
  date_created;
  meta;

  constructor({
    id,
    linkId,
    linkId2,
    linkId3,
    note,
    noteType,
    date_created,
    meta
  }) {
    this.id = id;
    this.linkId = linkId;
    this.linkId2 = linkId2;
    this.linkId3 = linkId3;
    this.note = note;
    this.noteType = noteType;
    this.date_created = date_created;
    this.meta = meta;

    try {
      this.meta = JSON.parse(meta);
    } catch (er) {
      this.meta = meta;
    }
  }

  static get modelName() {
    return 'Note';
  }
}
