import React, { Fragment, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Modal from 'components/modal/Modal';
import { Button, ButtonsGroup } from 'components/button';
import { CardBody, CardFooter } from 'components/card';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import {
  copyPersonToOtherEntity,
  movePersonToOtherEntity,
  addPersonToEntityFromPool
} from 'services/aws/client-query';
import messages from 'messages';
import { Table, TableBody, TableRow, TableCell } from 'components/table';
import { StoreContext } from 'index';
import Loader from 'components/loader/Loader';
import SelectPersonsCard from 'containers/partials/cards/SelectPersonsCard';
import useGroups from 'hooks/queries/useGroups';

export const SPORTER_ACTION = Object.freeze({
  COPY: 'copy',
  MOVE: 'move',
  COPY_POOL: 'copyPool'
});

const CopySporterModal = ({ action, sporters, oldEntityId, onClose }) => {
  const {
    authStore: {
      user: { rootEntityId }
    },
    uiState
  } = useContext(StoreContext);
  const intl = useIntl();

  const [selectedGroups, setSelectedGroups] = useState([]);
  const [errors, setErrors] = useState([]);
  const [errorState, setErrorState] = useState(false);
  const [responses, setResponses] = useState([]);

  const { fetchGroups } = useGroups({
    rootEntityId
  });

  const doModalAction = async sporters => {
    const newEntityId = selectedGroups[0];
    let mutation;
    let mutationVariables;

    // eslint-disable-next-line default-case
    switch (action) {
      case SPORTER_ACTION.COPY:
        mutation = copyPersonToOtherEntity;
        mutationVariables = {
          oldEntityId,
          newEntityId: newEntityId.id
        };
        break;
      case SPORTER_ACTION.MOVE:
        mutation = movePersonToOtherEntity;
        mutationVariables = {
          oldEntityId,
          newEntityId: newEntityId.id
        };
        break;
      case SPORTER_ACTION.COPY_POOL:
        mutation = addPersonToEntityFromPool;
        mutationVariables = {
          topLevelEntityId: oldEntityId,
          newEntityId: newEntityId.id
        };
        break;
    }

    uiState.increasePendingRequest();
    const responses = [];
    for (const person of sporters) {
      responses.push(
        await mutation({
          ...mutationVariables,
          personId: person.id ?? person
        })
      );
    }
    uiState.decreasePendingRequest();
    const errors = responses.filter(resp => resp.error);

    if (errors.length === 0) {
      await fetchGroups(oldEntityId);
      await fetchGroups(newEntityId.id);
      // push(ROUTE_SPORTERS_ENTITY.replace(':entityId', newEntityId));
      onClose && onClose(true);
    } else {
      setErrorState(true);
      setErrors(errors);
      setResponses(responses);
      if (errors.length !== responses.length) {
        await fetchGroups(oldEntityId);
        await fetchGroups(newEntityId.id);
      }
    }
  };

  const onSelectGroupsHandler = selectedGroups => {
    setSelectedGroups(selectedGroups);
    // doModalAction(selectedPersons);
  };

  const onRetry = () => {
    const errorSporters = errors.map(e =>
      sporters.find(s => s.id === e.personId)
    );
    doModalAction(errorSporters);
  };

  const intlActionButton =
    action === SPORTER_ACTION.COPY || action === SPORTER_ACTION.COPY_POOL
      ? messages.modalButtonCopy
      : messages.modalButtonMove;

  const intlFailedLabel =
    action === SPORTER_ACTION.COPY || action === SPORTER_ACTION.COPY_POOL
      ? messages.modalCopySporterFailed
      : messages.modalMoveSporterFailed;

  const intlHasErrorsLabel =
    action === SPORTER_ACTION.COPY || action === SPORTER_ACTION.COPY_POOL
      ? messages.modalCopySporterHasErrors
      : messages.modalMoveSporterHasErrors;

  return (
    <Modal
      card
      isOpen={true}
      onClose={onClose}
      extraClassNames={'c-card-modal__content-body-scroll'}
    >
      {uiState.pendingRequestCount > 0 && <Loader />}
      <CardHeader modal>
        <CardHeaderTitle>
          {action === SPORTER_ACTION.COPY || action === SPORTER_ACTION.COPY_POOL
            ? intl.formatMessage(messages.modalCopySporterTitle)
            : intl.formatMessage(messages.modalMoveSporterTitle)}
        </CardHeaderTitle>
        <CardHeaderSubtitle>
          {action === SPORTER_ACTION.COPY || action === SPORTER_ACTION.COPY_POOL
            ? intl.formatMessage(messages.modalCopySporterSubtitle)
            : intl.formatMessage(messages.modalMoveSporterSubtitle)}
        </CardHeaderSubtitle>
      </CardHeader>
      {!errorState && (
        <Fragment>
          <CardBody modal bodyWithHeader>
            <SelectPersonsCard
              rootEntityId={rootEntityId}
              onSelectGroups={ids => onSelectGroupsHandler(ids)}
              selectedGroups={selectedGroups}
              onlyGroups={true}
              onModal
            />
          </CardBody>
          <CardFooter modal>
            <ButtonsGroup>
              <Button secondary onClick={onClose}>
                <FormattedMessage {...messages.buttonCancel} />
              </Button>
              <Button
                primary
                disabled={selectedGroups.length === 0}
                onClick={() => doModalAction(sporters)}
              >
                <FormattedMessage
                  {...intlActionButton}
                  values={{ count: sporters.length }}
                />
              </Button>
            </ButtonsGroup>
          </CardFooter>
        </Fragment>
      )}
      {errorState && (
        <Fragment>
          <CardBody modal bodyWithHeader>
            <div className="c-file-upload--wrapper-states c-file-upload--wrapper-states-errors">
              <p>
                <span className="u-color-error">
                  {errors.length === responses.length
                    ? intl.formatMessage(intlFailedLabel)
                    : intl.formatMessage(intlHasErrorsLabel, {
                        count: errors.length
                      })}
                </span>
              </p>
              <Table errorList>
                <TableBody>
                  {errors.map((p, i) => {
                    const sporter = sporters.find(s => s.id === p.personId);
                    const errorMessage =
                      p.error.message &&
                      p.error.message.search('person_already_in_new_entity') !==
                        -1
                        ? messages.modalCopyMoveSporterErrorExistingSporter
                        : messages.modalCopyMoveSporterErrorUnknownReason;
                    return (
                      <TableRow key={i}>
                        <TableCell>{`${sporter.firstname} ${sporter.lastname}`}</TableCell>
                        <TableCell>
                          <span className="u-color-error">
                            {intl.formatMessage(errorMessage)}
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </CardBody>
          <CardFooter modal>
            <ButtonsGroup fullwidth>
              <Button secondary onClick={() => setErrorState(null)}>
                <FormattedMessage {...messages.buttonCancel} />
              </Button>
              <Button primary onClick={onRetry}>
                <FormattedMessage
                  {...messages.modalButtonRetry}
                  values={{ count: errors.length }}
                />
              </Button>
            </ButtonsGroup>
          </CardFooter>
        </Fragment>
      )}
    </Modal>
  );
};

export default withRouter(CopySporterModal);
