import { useRef } from 'react';

const useMediaList = (initialState = []) => {
  const media = useRef([...initialState]);

  const setMedia = object => {
    media.current = [object];
    return media.current;
  };

  const addMedia = object => {
    if (Array.isArray(object)) {
      media.current = [...media.current, ...object];
      return media.current;
    }
    media.current.push(object);
    return media.current;
  };

  const getMedia = () => media.current;

  const removeMedia = objectId => {
    media.current = media.current.filter(m => m.id !== objectId);
    return media.current;
  };

  return { media: media.current, getMedia, setMedia, addMedia, removeMedia };
};

export default useMediaList;
