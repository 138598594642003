import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { useNotificationQueue } from 'components/notification';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import messages from 'messages.js';
import {
  MUTATION_ADD_LESSON,
  MUTATION_EDIT_LESSON,
  QUERY_GET_LESSONS
} from 'services/aws/lessons-query';
import { cleanFalsy } from 'utils/object';
import { format } from 'date-fns';
import { parseDateString } from 'utils/date';

const LessonForm = ({
  entityId,
  lesson,
  children,
  extraClassNames,
  onComplete
}) => {
  const notification = useNotificationQueue();
  const intl = useIntl();

  const [editTemplate] = useMutation(MUTATION_EDIT_LESSON);
  const [addTemplate] = useMutation(MUTATION_ADD_LESSON, {
    awaitRefetchQueries: true
  });

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    lessonDate: Yup.date()
      .transform(parseDateString)
      .typeError(intl.formatMessage(messages.dateRequired))
  });

  const onSubmitHandler = async lesson => {
    const lessonObj = cleanFalsy({
      entityId,
      title: lesson.title,
      tagIds: lesson.tags.map(tag => tag.id),
      meta: JSON.stringify({
        classroom: lesson.classroom,
        didactics: lesson.didactics,
        school: lesson.school,
        subject: lesson.subject,
        lessonDate: format(lesson.lessonDate, 'yyyy-LL-dd')
      })
    });

    if (!lesson.id) {
      await addTemplate({
        variables: {
          ...lessonObj,
          exerciseIds: []
        },
        refetchQueries: [
          {
            query: QUERY_GET_LESSONS,
            variables: { entityId }
          }
        ]
      }).then(
        ({ data }) => {
          if (onComplete) onComplete(data.addExerciseProgramTemplate.id);
        },
        () => {
          notification.add('edit_error', {
            message: intl.formatMessage(messages.messageAddTemplateSaveError),
            level: 'error'
          });
        }
      );
    } else {
      await editTemplate({
        variables: {
          id: lesson.id,
          ...lessonObj
        },
        refetchQueries: [
          {
            query: QUERY_GET_LESSONS,
            variables: { entityId }
          }
        ]
      }).then(
        ({ data }) => {
          onComplete && onComplete(data.editExerciseProgramTemplate.id);
        },
        () => {
          notification.add('edit_error', {
            message: intl.formatMessage(messages.messageAddTemplateSaveError),
            level: 'error'
          });
        }
      );
    }
  };

  return (
    <Formik
      initialValues={lesson}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmitHandler}
    >
      {props => {
        return (
          <Form className={classNames(extraClassNames)} noValidate>
            {typeof children === 'function' ? children(props) : children}
          </Form>
        );
      }}
    </Formik>
  );
};

export default LessonForm;
