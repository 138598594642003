import React, { useEffect, useState } from 'react';
import { QUERY_GET_TAGS } from 'services/aws/tags-query';

import RetryPanel from '../error-boundary/RetryPanel';
import TagsSelect from 'components/input/TagsSelect';
import { useQuery } from '@apollo/client';
import { sort } from 'utils/sort';
import { arrayToTree } from 'performant-array-to-tree';
import Checkbox from 'components/input/Checkbox';
import { useUIDSeed } from 'react-uid';
import useSelectable from 'hooks/utils/useSelectable';

const TagTree = ({
  entityId,
  tags,
  edit = true,
  placeholder,
  noOptionsMessage,
  isClearable,
  isMulti,
  onChange,
  extraClassNames
}) => {
  const uidSeed = useUIDSeed();
  const [tagOptions, setTagOptions] = useState([]);
  const { selected, select, setSelected } = useSelectable();

  const { loading, error, data } = useQuery(QUERY_GET_TAGS, {
    variables: { entityId }
  });

  useEffect(() => {
    if (data?.getTagsByEntity) {
      setTagOptions(
        arrayToTree(
          sort(
            data.getTagsByEntity.map(({ id, label, parentId }) => ({
              id,
              label,
              value: label,
              parentId
            })),
            { keys: [{ key: 'order' }] }
          ),
          { dataField: null }
        )
      );
    }
  }, [data]);

  useEffect(() => {
    // setSelectedTags(tags);
    if (tags) {
      setSelected(tags.map(t => t.id));
    }
  }, [tags]);

  const onTagSelect = (tags, actions) => {
    let selectedIds = [];
    switch (actions.action) {
      case 'remove-value':
      case 'pop-value':
        selectedIds = select(actions.removedValue.id);
        break;
      case 'select-option':
        selectedIds = select(actions.option.id);
        break;
    }

    triggerOnChange(selectedIds);
  };

  const onTagCheck = (e, tag) => {
    const selectedIds = select(tag.id);
    triggerOnChange(selectedIds);
  };

  const triggerOnChange = tags => {
    onChange && onChange(tags);
  };

  if (loading && tagOptions.length === 0)
    return (
      <TagsSelect
        extraClassNames={extraClassNames}
        edit={edit}
        disabled
        readOnly
        placeholder={placeholder}
        noOptionsMessage={noOptionsMessage}
      />
    );

  if (error) return <RetryPanel />;

  return (
    <>
      {tagOptions.map(tag => {
        return (
          <div className="c-card__body-wrapper" key={tag.id}>
            {tag.children.length > 14 ? (
              <TagsSelect
                label={tag.label}
                extraClassNames={extraClassNames}
                options={tag.children}
                value={tag.children
                  .filter(t => selected.includes(t.id))
                  .map(tag => tag && { ...tag, value: tag.label })}
                isMulti={isMulti}
                isClearable={isClearable}
                onChange={(value, actions) => onTagSelect(value, actions)}
                placeholder={placeholder}
                noOptionsMessage={noOptionsMessage}
              />
            ) : (
              <fieldset className="c-input-group">
                <legend className="c-input__label">{tag.label}</legend>
                {tag.children.map(tag => (
                  <Checkbox
                    secondary
                    key={tag.id}
                    id={uidSeed(tag.id)}
                    checked={selected.includes(tag.id)}
                    onChange={e => onTagCheck(e, tag)}
                  >
                    {tag.label}
                  </Checkbox>
                ))}
              </fieldset>
            )}
          </div>
        );
      })}
    </>
  );
};

export default TagTree;
