import classNames from 'classnames';

import './Card.scss';
import { forwardRef } from 'react';

const Card = forwardRef((props, ref) => {
  const {
    extraClassNames,
    secondary,
    secondaryCollapsible,
    multiBody,
    reports,
    reportsFullHeight,
    packageGeneral,
    packageILike,
    packageIDo,
    centered,
    empty,
    tooltip,
    alert,
    helper,
    organisations,
    editTestResult,
    style,
    children
  } = props;

  return (
    <div
      ref={ref}
      className={classNames('c-card', extraClassNames, {
        'c-card--secondary': secondary,
        'c-card--secondary c-card--secondary-collapsible': secondaryCollapsible,
        'c-card--multiple-body': multiBody,
        'c-card--secondary c-card--progress-bar': reports,
        'c-card--secondary c-card--progress-bar c-card--full-height':
          reportsFullHeight,
        'c-card--package': packageGeneral,
        'c-card--package c-card--i-like': packageILike,
        'c-card--package c-card--i-do': packageIDo,
        'c-card--center-center': centered,
        'c-card--empty': empty,
        'c-card--tooltip c-card--shadow': tooltip,
        'c-card-modal__content-auto-height c-card-modal__content-alert c-card--shadow':
          alert,
        'c-card--helper c-card--shadow': helper,
        'c-card--organisations': organisations,
        'c-card--edit-test-result c-card--secondary': editTestResult
      })}
      style={style}
    >
      {children}
    </div>
  );
});

export default Card;
