import { useState, useContext, useEffect } from 'react';
import { Accordion, AccordionContent } from 'components/accordion';
import AccordionButtonHooks from 'components/accordion/AccordionButtonHooks';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, CardBody } from 'components/card';
import FormEditHeaderButtons from '../buttons/FormEditHeaderButtons';
import { StoreContext } from 'index';
import { useFormikContext } from 'formik';
import TestSetDetail from 'containers/pages/prevention/TestSetDetail';
import messages from 'messages';

function PreventionTemplateDetailCard({ edit: initialEdit, onEdit }) {
  const {
    authStore: { entityId }
  } = useContext(StoreContext);
  const intl = useIntl();
  const { values, submitForm, setFieldValue } = useFormikContext();

  const [edit, setEdit] = useState(initialEdit);
  const [openAccordion, setOpenAccordion] = useState(edit);

  useEffect(() => {
    setEdit(initialEdit);
  }, [initialEdit]);

  useEffect(() => {
    onEdit(edit);
  }, [edit]);

  const editAndOpenAccordion = () => {
    if (openAccordion) {
      setEdit(!edit);
    } else {
      setEdit(!edit);
      setOpenAccordion(true);
    }
  };

  return (
    <>
      <Card secondaryCollapsible>
        <CardHeader secondary>
          <CardHeaderTitle>
            <FormattedMessage {...messages.templatesDetail} />
          </CardHeaderTitle>
          <FormEditHeaderButtons
            edit={edit}
            setEdit={setEdit}
            onCancel={() => editAndOpenAccordion()}
            onEdit={() => editAndOpenAccordion()}
            onSubmit={submitForm}
            type={'button'}
          />
        </CardHeader>

        <CardBody secondary>
          <TestSetDetail edit={edit} onEdit={() => setEdit(true)} />
        </CardBody>
      </Card>
    </>
  );
}

export default PreventionTemplateDetailCard;
