import { BenchmarkTypes } from 'constants.js';

export const SORT_TYPES = Object.freeze({
  UP: 'up',
  DOWN: 'down'
});

export const SORT_DIRECTION = Object.freeze({
  DESC: 'desc',
  ASC: 'asc'
});

export const SORT_DATA_TYPES = Object.freeze({
  NUMBER: 'number',
  STRING: 'string',
  DATE: 'date',
  DATE_STRING: 'date-string',
  BOOL: 'boolean'
});

export const sortUsers = users =>
  users.sort((a, b) => {
    if (a.firstname.toLowerCase() > b.firstname.toLowerCase()) return 1;
    if (a.firstname.toLowerCase() < b.firstname.toLowerCase()) return -1;
    if (a.lastname.toLowerCase() > b.lastname.toLowerCase()) return 1;
    if (a.lastname.toLowerCase() < b.lastname.toLowerCase()) return -1;
    return 0;
  });

export const sortFiles = files =>
  files.sort((a, b) => {
    if (a.filename.toLowerCase() > b.filename.toLowerCase()) return 1;
    if (a.filename.toLowerCase() < b.filename.toLowerCase()) return -1;
    return 0;
  });

export const sortPersonEntities = personEntities =>
  personEntities.sort((a, b) => {
    if (a.person.firstname.toLowerCase() > b.person.firstname.toLowerCase())
      return 1;
    if (a.person.firstname.toLowerCase() < b.person.firstname.toLowerCase())
      return -1;
    if (a.person.lastname.toLowerCase() > b.person.lastname.toLowerCase())
      return 1;
    if (a.person.lastname.toLowerCase() < b.person.lastname.toLowerCase())
      return -1;
    return 0;
  });

export const sortByCustom = (items, custom = 'custom') =>
  items.sort((a, b) => {
    if (a[custom].toLowerCase() > b[custom].toLowerCase()) return 1;
    if (a[custom].toLowerCase() < b[custom].toLowerCase()) return -1;
    return 0;
  });

export const sortByName = items =>
  items.sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    return 0;
  });

export const sortEntities = entities =>
  entities.sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    return 0;
  });

export const sortScannersByName = scanners =>
  scanners.sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    return 0;
  });

export const sortCalibrationsOnDateAndName = calibrations =>
  calibrations.sort((a, b) => {
    if (new Date(a.date).getTime() < new Date(b.date).getTime()) return 1;
    if (new Date(a.date).getTime() > new Date(b.date).getTime()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    return 0;
  });

export const sortSessionsOnPercentageAndDate = testDatas =>
  testDatas.sort((a, b) => {
    if (a.finishedPercentage > b.finishedPercentage) return 1;
    if (a.finishedPercentage < b.finishedPercentage) return -1;
    if (new Date(a.startdate).getTime() < new Date(b.startdate).getTime())
      return 1;
    if (new Date(a.startdate).getTime() > new Date(b.startdate).getTime())
      return -1;
    return 0;
  });

export const sortTestdataOnDateUpdated = testDatas =>
  testDatas.sort((a, b) => b.date_updated - a.date_updated);

export const sortRowsOnLabel = rows =>
  rows.sort((a, b) => {
    if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
    if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
    return 0;
  });

export const sortRowsOnCol = (rows, index, sortDirection, type) =>
  rows.sort((a, b) => {
    let valueA = a.data[index].value ? a.data[index].value.zScore : -5;
    let valueB = b.data[index].value ? b.data[index].value.zScore : -5;
    if (type === BenchmarkTypes.AVERAGE) {
      valueA = a.data[index].value ? a.data[index].value.result : 0;
      valueB = b.data[index].value ? b.data[index].value.result : 0;
    }

    if (
      sortDirection === SORT_TYPES.UP ||
      sortDirection === SORT_DIRECTION.DESC
    ) {
      if (valueA > valueB) return 1;
      if (valueA < valueB) return -1;
    } else {
      if (valueA < valueB) return 1;
      if (valueA > valueB) return -1;
    }

    if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
    if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
    return 0;
  });

export const sortResultRowsOnCol = (rows, index, col, type) => {
  return Object.entries(rows)
    .sort((a, b) => {
      const [id, laterality] = col.id.split('_');

      let valueA;
      let valueB;

      if (type !== BenchmarkTypes.AVERAGE) {
        valueA = parseFloat(
          a[1][index]?.benchmarked?.[laterality]?.percentage ?? -5
        );
        valueB = parseFloat(
          b[1][index]?.benchmarked?.[laterality]?.percentage ?? -5
        );

        valueA =
          valueA === -5
            ? a[1][index]?.result?.[laterality]?.[0]
              ? valueA + parseFloat(a[1][index].result[laterality][0])
              : -10
            : valueA * 100;

        valueB =
          valueB === -5
            ? b[1][index]?.result?.[laterality]?.[0]
              ? valueB + parseFloat(b[1][index].result[laterality][0])
              : -10
            : valueB * 100;
      }

      if (type === BenchmarkTypes.AVERAGE) {
        valueA = parseFloat(a[1][index]?.result?.[laterality]?.[0] ?? 0);
        valueB = parseFloat(b[1][index]?.result?.[laterality]?.[0] ?? 0);
      }

      if (
        col.sortDirection === SORT_TYPES.UP ||
        col.sortDirection === SORT_DIRECTION.DESC
      ) {
        if (valueA > valueB) return 1;
        if (valueA < valueB) return -1;
      } else {
        if (valueA < valueB) return 1;
        if (valueA > valueB) return -1;
      }

      return 0;
    })
    .reduce(
      (_sortedObj, [k, v]) => ({
        ..._sortedObj,
        [k]: v
      }),
      {}
    );
};

export const sortTestDataResultsOnScore = results =>
  results.sort((a, b) => b.result - a.result);

export const sortAndGroupSessions = sessions => {
  const finishedSessions = sortSessionsOnPercentageAndDate(
    sessions.filter(session => session.isDone)
  );

  const startedSessions = sortSessionsOnPercentageAndDate(
    sessions.filter(session => session.inProgress)
  );

  const notStartedSessions = sortSessionsOnPercentageAndDate(
    sessions.filter(session => session.isNotStarted)
  );

  return [...notStartedSessions, ...startedSessions, ...finishedSessions];
};

export const sortSportersOnFinishedAndDate = persons =>
  persons.sort((a, b) => {
    if (a.finished > b.finished) return 1;
    if (a.finished < b.finished) return -1;
    if (a.firstname.toLowerCase() > b.firstname.toLowerCase()) return 1;
    if (a.firstname.toLowerCase() < b.firstname.toLowerCase()) return -1;
    if (a.lastname.toLowerCase() > b.lastname.toLowerCase()) return 1;
    if (a.lastname.toLowerCase() < b.lastname.toLowerCase()) return -1;
    return 0;
  });

export const sortSessionsOnFinishedAndDate = sessions =>
  sessions.sort((a, b) => {
    if (a.finished > b.finished) return 1;
    if (a.finished < b.finished) return -1;
    if (new Date(a.startdate).getTime() < new Date(b.startdate).getTime())
      return 1;
    if (new Date(a.startdate).getTime() > new Date(b.startdate).getTime())
      return -1;
    return 0;
  });

export const sortByArray = (array, sortArray) => {
  const arrayToSort = [...array];
  const sorter = (a, b) => {
    return sortArray.indexOf(a.id) - sortArray.indexOf(b.id);
  };
  arrayToSort.sort(sorter);
  return arrayToSort;
};

export const sortByArrayValue = (array, sortArray) => {
  const arrayToSort = [...array];
  const sorter = (a, b) => {
    return sortArray.indexOf(a) !== -1
      ? sortArray.indexOf(a) - sortArray.indexOf(b)
      : sortArray.length;
  };
  arrayToSort.sort(sorter);
  return arrayToSort;
};

const _getValueToSort = (value, dataType) => {
  let newValue = value;
  if (dataType === SORT_DATA_TYPES.STRING)
    newValue = value ? value?.toLowerCase() : '';
  if (dataType === SORT_DATA_TYPES.DATE) newValue = value.getTime();
  if (dataType === SORT_DATA_TYPES.DATE_STRING)
    newValue = new Date(value).getTime();
  return newValue;
};
const _defaultSortKeys = [
  {
    key: 'name',
    desc: false,
    dataType: SORT_DATA_TYPES.STRING
  }
];
const _defaultSortOptions = { reference: false, deepCopy: false };

/**
 * Sorts an array of objects and returns it, default order direction is ASC on key 'name'
 * @param {Object[]} array - Array to sort
 * @param {Object} [paramOptions] - Optional options for sorting, default sorting on key 'name' and asc
 * @param {Object[]} [paramOptions.keys] - Defines sorting on which keys, in the keys array order
 * @param {string} paramOptions.keys[].key - Defines the sorting key
 * @param {boolean} [paramOptions.keys[].desc] - Defines the sorting direction, desc or asc
 * @param {string} [paramOptions.keys[].dataType] - Defines the data type of the value (use SORT_DATA_TYPES), default string
 * @param {Object} [paramOptions.options] - Defines the reference or copy options
 * @param {boolean} [paramOptions.options.reference] - Returns same sorted array when true, otherwise its no reference array
 * @param {boolean} [paramOptions.options.deepCopy] - Returns a deepCopy of array and objects when true
 * @returns {Object[]} sortedArray
 */
export const sort = (
  array,
  { keys = _defaultSortKeys, options = _defaultSortOptions } = {
    keys: _defaultSortKeys,
    options: _defaultSortOptions
  }
) => {
  const arrayToSort = options.deepCopy
    ? JSON.parse(JSON.stringify(array))
    : options.reference
    ? array
    : [...array];
  return arrayToSort.sort((a, b) => {
    let orderedValue = null;
    keys.some(o => {
      const type = o.dataType || SORT_DATA_TYPES.STRING;
      const desc = !!o.desc;
      const theKey = o.key || 'name';

      const valueA = _getValueToSort(a[theKey], type);
      const valueB = _getValueToSort(b[theKey], type);

      if (!desc) {
        if (valueA > valueB) {
          orderedValue = 1;
          return true;
        }
        if (valueA < valueB) {
          orderedValue = -1;
          return true;
        }
      } else {
        if (valueA < valueB) {
          orderedValue = 1;
          return true;
        }
        if (valueA > valueB) {
          orderedValue = -1;
          return true;
        }
      }
      return false;
    });

    return orderedValue !== null ? orderedValue : 0;
  });
};
