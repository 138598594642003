import { Component } from 'react';
import Tooltip from 'components/tooltip/Tooltip';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';

export default class GrowthPredictionTableWarning extends Component {
  render() {
    return (
      <Tooltip
        id="warning-date-2"
        iconFillColor
        alert
        right
        smallIcon
        icon="alert-round"
      >
        <FormattedMessage {...messages.growthPredictionTableWarning} />
      </Tooltip>
    );
  }
}
