import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { StoreContext } from 'index';
import { Route, Switch, Redirect, generatePath } from 'react-router-dom';
import {
  ROUTE_SPORTERS_ENTITY,
  ROUTE_TEST_ENTITY,
  ROUTE_TEST_ENTITY_ANALYSE,
  ROUTE_TEST_ENTITY_ANALYSE_PYTHON,
  ROUTE_TEST_ENTITY_EXERCISES,
  ROUTE_TEST_ENTITY_EXERCISES_EXERCISE,
  ROUTE_TEST_ENTITY_EXERCISES_EXERCISE_PYTHON,
  ROUTE_TEST_ENTITY_UPLOAD
} from 'routes/RouteList';
import { SessionContextProvider } from 'contexts/SessionContext';
import TestApp from 'containers/pages/test/TestApp';
import Panel from 'components/panel/Panel';
import messages from 'messages';
import UploadVideo from 'containers/pages/test/UploadVideo';
import AnalyseVideo from 'containers/pages/test/AnalyseVideo';
import ExercisesList from 'containers/pages/test/ExercisesList';
import AnalyseVideoPython from 'containers/pages/test/AnalyseVideoPython';
import NoAccessPanel from 'containers/pages/auth/NoAccessPanel';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';

function Test() {
  const ability = useAbility(AbilityContext);
  const intl = useIntl();
  const {
    authStore: { user }
  } = useContext(StoreContext);

  if (ability.cannot('read', 'Test')) {
    return (
      <NoAccessPanel
        entityId={user.rootEntityId}
        emptyLabel={intl.formatMessage(messages.noAccessEmptyPanel)}
        redirectRoute={generatePath(ROUTE_SPORTERS_ENTITY, {
          entityId: user.rootEntityId
        })}
      />
    );
  }

  return (
    <>
      <Switch>
        <Route
          exact
          path={ROUTE_TEST_ENTITY}
          render={props => <TestApp entityId={props.match.params.entityId} />}
        />
        <Route
          exact
          path={[
            ROUTE_TEST_ENTITY_UPLOAD,
            ROUTE_TEST_ENTITY_ANALYSE,
            ROUTE_TEST_ENTITY_EXERCISES,
            ROUTE_TEST_ENTITY_ANALYSE_PYTHON
          ]}
          render={props => (
            <TestApp
              entityId={props.match.params.entityId}
              type={props.match.params.type ?? 'upload'}
            />
          )}
        />
        <Route
          exact
          path={ROUTE_TEST_ENTITY_EXERCISES_EXERCISE_PYTHON}
          render={props => {
            const { entityId } = props.match.params;

            return <AnalyseVideoPython entityId={entityId} />;
          }}
        />
        <Route
          exact
          path={ROUTE_TEST_ENTITY_EXERCISES_EXERCISE}
          render={props => {
            const { entityId } = props.match.params;

            return <AnalyseVideo entityId={entityId} />;
          }}
        />
        <Redirect
          to={ROUTE_TEST_ENTITY.replace(':entityId', user.rootEntityId)}
        />
      </Switch>

      <Route
        exact
        path={ROUTE_TEST_ENTITY}
        render={() => (
          <Panel
            emptyState
            emptyLabel={intl.formatMessage(messages.usersEmptyPanel)}
          />
        )}
      />

      <SessionContextProvider>
        <Switch>
          <Route
            exact
            path={ROUTE_TEST_ENTITY_UPLOAD}
            render={props => (
              <UploadVideo entityId={props.match.params.entityId} />
            )}
          />
          <Route
            exact
            path={ROUTE_TEST_ENTITY_EXERCISES}
            render={props => <ExercisesList />}
          />
          <Route
            exact
            path={ROUTE_TEST_ENTITY_ANALYSE_PYTHON}
            render={props => <ExercisesList type={props.match.params.type} />}
          />
        </Switch>
      </SessionContextProvider>
    </>
  );
}

export default Test;
