import React, { useContext } from 'react';
import { ROUTE_EXERCISES_ENTITY } from 'routes/RouteList';
import {
  MUTATION_ARCHIVE_EXERCISE,
  QUERY_GET_EXERCISES,
  QUERY_GET_EXERCISES_BY_PERSON
} from 'services/aws/exercises-query';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages.js';
import { StoreContext } from 'index';
import { FormattedMessage } from 'react-intl';
import { QUERY_GET_TEMPLATES } from 'services/aws/templates-query';

const RemoveExerciseButton = ({ entityId, exerciseId, children, ...props }) => {
  const {
    routing: { push }
  } = useContext(StoreContext);
  return (
    <ConfirmMutationButton
      menu
      {...props}
      mutation={MUTATION_ARCHIVE_EXERCISE}
      confirmTitle={messages.modalConfirmRemoveExerciseTitle}
      confirmMessage={messages.modalConfirmRemoveExerciseMessage}
      variables={{
        exerciseId
      }}
      refetchQueries={[
        {
          query: QUERY_GET_EXERCISES,
          variables: { entityId }
        },
        {
          query: QUERY_GET_TEMPLATES,
          variables: { entityId }
        },
        {
          query: QUERY_GET_EXERCISES_BY_PERSON,
          variables: { entityId }
        }
      ]}
      update={(cache, { data: { archiveExercise } }) => {
        if (archiveExercise) {
          push(ROUTE_EXERCISES_ENTITY.replace(':entityId', entityId));
        }
      }}
    >
      <Icon id="delete" strokeColor="color-neutral-dark" />
      <FormattedMessage {...messages.exercisesRemoveExerciseButton} />
    </ConfirmMutationButton>
  );
};

export default RemoveExerciseButton;
