import { createContext, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useLazyQuery } from '@apollo/client';
import { S_QUERY_GET_SESSIONS_BY_ENTITY_ID } from 'services/aws/session-query';
import { parseTestSessionDataToModel } from 'models/Entity';
import { sortAndGroupSessions } from 'utils/sort';
import useTestSets from 'hooks/queries/useTestSets';
import { TestSetV2Type } from 'constants.js';

export const SessionsContext = createContext({});

const SessionsContextProvider = ({ entityId, children }) => {
  const { fetchTestSets, loading: loadingTestSets } = useTestSets({ entityId });
  const [testSets, setTestSets] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [getSessions, { loading, error, data }] = useLazyQuery(
    S_QUERY_GET_SESSIONS_BY_ENTITY_ID,
    {
      variables: { entityId },
      fetchPolicy: 'cache-and-network'
    }
  );

  useEffect(() => {
    const fetchSessionData = async () => {
      const testSets = await fetchTestSets({
        types: [TestSetV2Type.TALENT, TestSetV2Type.PREVENTION]
      });
      setTestSets(testSets);
      await getSessions();
    };
    if (entityId) {
      fetchSessionData().catch(error => {
        Sentry.captureException(error);
      });
    }
  }, [entityId, getSessions]);

  useEffect(() => {
    if (data?.getTestSessions) {
      const filteredSessions = data.getTestSessions.filter(
        session =>
          session.type !== TestSetV2Type.REHAB &&
          session.type !== TestSetV2Type.PREVENTION_TEMPLATE
      );
      const sessions = parseTestSessionDataToModel(filteredSessions, testSets);
      const sortedSessions = sortAndGroupSessions(sessions);

      setSessions(sortedSessions);
    }
  }, [data]);

  return (
    <SessionsContext.Provider
      value={{ sessions, loading: loading || loadingTestSets, error }}
    >
      {children}
    </SessionsContext.Provider>
  );
};

function useSessionsContext() {
  const context = useContext(SessionsContext);
  if (context === undefined) {
    throw new Error(
      'The SessionsContext hook must be used within a SessionsContext.Provider'
    );
  }
  return context;
}

export { SessionsContextProvider, useSessionsContext };
