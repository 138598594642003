import React, { useEffect, useState, useContext } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import messages from 'messages';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldTextarea } from 'components/input/FieldTextarea';
import { Accordion, AccordionContent } from 'components/accordion';
import AccordionButtonHooks from 'components/accordion/AccordionButtonHooks';
import {
  CardHeader,
  CardHeaderButtons,
  CardHeaderTitle
} from 'components/card/card-header';
import Card from 'components/card/Card';
import { useMutation } from '@apollo/client';
import {
  MUTATION_ADD_NOTE,
  MUTATION_EDIT_NOTE
} from 'services/aws/notes-query';
import { NoteTypes } from 'constants.js';
import { useNotificationQueue } from 'components/notification';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import { StoreContext } from 'index';
import { useTestDataContext } from 'contexts/TestDataContext';
import { sanitize } from 'dompurify';

const ReportRemarkInput = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();
  const notification = useNotificationQueue();
  const validationSchema = Yup.object().shape({
    note: Yup.string()
  });
  const { testData, refetchTestData } = useTestDataContext();

  const [edit, setEdit] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(false);
  const [addNote] = useMutation(MUTATION_ADD_NOTE);
  const [editNote] = useMutation(MUTATION_EDIT_NOTE);
  const [testDataNote, setTestDataNote] = useState({
    note: '',
    displayNoteInExport: true
  });
  const [formattedNote, setFormattedNote] = useState('');

  useEffect(() => {
    const newLineText = testData?.note?.note
      ? testData.note.note.replace(/(?:\r\n|\r|\n)/g, '<br />')
      : '';

    setTestDataNote({
      note: testData?.note?.note ?? '',
      displayNoteInExport: true
    });
    setFormattedNote(newLineText);

    if (testData?.note?.note?.length > 0) {
      setOpenAccordion(true);
    }
  }, [testData]);

  const onSubmitHandler = async data => {
    const note = {
      linkId: testData.id,
      note: data.note,
      noteType: NoteTypes.TESTDATA
    };

    if (!testDataNote.note) {
      await addNote({ variables: { ...note } }).then(res => {
        notification.add(res.data.addNote.id, {
          message: intl.formatMessage(messages.noteSaved)
        });
        refetchTestData();
        setEdit(false);
      });
    } else {
      await editNote({
        variables: { ...note, id: testData.note.id }
      }).then(res => {
        notification.add(res.data.editNote.id, {
          message: intl.formatMessage(messages.noteSaved)
        });
        refetchTestData();
        setEdit(false);
      });
    }
  };

  const editAndOpenAccordion = () => {
    if (openAccordion) {
      setEdit(!edit);
    } else {
      setEdit(!edit);
      setOpenAccordion(true);
    }
  };

  if (user.hasAdminRole()) {
    return (
      <Card secondary>
        <Accordion>
          {() => (
            <>
              <AccordionButtonHooks
                id="trigger-1"
                name="accordion-story"
                checkbox
                isControlled
                checked={openAccordion}
                onChange={() => setOpenAccordion(!openAccordion)}
              >
                <CardHeader secondary>
                  <CardHeaderTitle>
                    <FormattedMessage
                      {...messages.sessionReportAthleteNoteFormTitle}
                    />
                  </CardHeaderTitle>
                  <CardHeaderButtons>
                    {!edit ? (
                      <Button tiny onClick={() => editAndOpenAccordion()}>
                        <Icon id="edit" />
                      </Button>
                    ) : (
                      <Button tiny onClick={() => editAndOpenAccordion()}>
                        <Icon id="close" strokeColor="color-primary-dark" />
                      </Button>
                    )}
                  </CardHeaderButtons>
                </CardHeader>
              </AccordionButtonHooks>

              {((testDataNote?.note && testDataNote?.note.length > 0) ||
                edit) && (
                <AccordionContent>
                  {edit ? (
                    <Formik
                      initialValues={testDataNote}
                      enableReinitialize={true}
                      validationSchema={validationSchema}
                      validateOnChange={false}
                      validateOnBlur={false}
                      onSubmit={onSubmitHandler}
                    >
                      {({
                        errors,
                        touched,
                        submitForm,
                        resetForm,
                        isSubmitting
                      }) => (
                        <Form>
                          <FieldTextarea
                            id="note"
                            name="note"
                            errors={errors}
                            touched={touched}
                            resizeVertical
                            characterLimit={1500}
                            maxLength={1500}
                          >
                            &nbsp;
                          </FieldTextarea>
                          {/*<InputWrapper
                        label={`${intl.formatMessage(
                          messages.cardLabelReportNoteDisplayInPDF
                        )}`}
                      >
                        <FieldCheckbox
                          id="displayNoteInExport"
                          name="displayNoteInExport"
                          type="text"
                          errors={errors}
                          touched={touched}
                        />
                      </InputWrapper>*/}
                          <FormEditFooterButtons
                            edit={edit}
                            setEdit={setEdit}
                            onCancel={resetForm}
                            onSubmit={submitForm}
                            disabledSubmit={isSubmitting}
                          />
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <div
                      className="c-input--textarea-readonly"
                      dangerouslySetInnerHTML={{
                        __html: sanitize(
                          formattedNote.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                          {
                            ALLOWED_TAGS: ['b', 'i', 'br']
                          }
                        )
                      }}
                    />
                  )}
                </AccordionContent>
              )}
            </>
          )}
        </Accordion>
      </Card>
    );
  } else if (testDataNote?.note) {
    return (
      <Card secondary>
        <Accordion>
          {() => (
            <>
              <AccordionButtonHooks
                id="trigger-1"
                name="accordion-story"
                checkbox
                isControlled
                checked={openAccordion}
                onChange={() => setOpenAccordion(!openAccordion)}
              >
                <CardHeader secondary>
                  <CardHeaderTitle>
                    <FormattedMessage
                      {...messages.sessionReportAthleteNoteFormTitle}
                    />
                  </CardHeaderTitle>
                </CardHeader>
              </AccordionButtonHooks>
              <AccordionContent>
                <div
                  className="c-input--textarea-readonly"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(
                      formattedNote.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                      {
                        ALLOWED_TAGS: ['b', 'i', 'br']
                      }
                    )
                  }}
                />
              </AccordionContent>
            </>
          )}
        </Accordion>
      </Card>
    );
  } else {
    return <></>;
  }
};

export default ReportRemarkInput;
