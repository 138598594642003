import React from 'react';
import { FinishedState } from 'enums';
import { MUTATION_UPDATE_TEST_DATA } from 'services/aws/session-query';
import messages from 'messages';
import { QUERY_GET_ANNOTATIONS } from 'services/aws/annotations-query';
import ConfirmMutationButton from 'containers/partials/buttons/ConfirmMutationButton';

const CheckAnnotationButton = ({
  testData,
  data,
  linkId,
  children,
  ...props
}) => {
  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_UPDATE_TEST_DATA}
      variables={{
        id: testData.id,
        data: testData.data,
        finished: FinishedState.FINISHED
      }}
      refetchQueries={[
        {
          query: QUERY_GET_ANNOTATIONS,
          variables: { linkId }
        }
      ]}
      update={(cache, { data: { editTestData } }) => {
        if (editTestData) {
        }
      }}
      confirmTitle={messages.modalConfirmFinishAnnotationTitle}
      confirmMessage={messages.modalConfirmFinishAnnotationMessage}
      confirmAcceptLabel={messages.modalConfirmFinishSessionAcceptButton}
    >
      {children}
    </ConfirmMutationButton>
  );
};

export default CheckAnnotationButton;
