import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import {
  generatePath,
  matchPath,
  useHistory,
  withRouter
} from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Route, Switch, Redirect } from 'react-router-dom';
import Overview from './Overview';

import {
  ROUTE_SESSIONS_ENTITY,
  ROUTE_SESSIONS_ENTITY_ADD,
  ROUTE_SESSIONS_ENTITY_SESSION,
  ROUTE_SESSIONS_ENTITY_SESSION_EDIT,
  ROUTE_SESSIONS_ENTITY_SESSION_ADD_SPORTER,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_EDIT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_EDIT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
  ROUTE_SESSIONS_ENTITY_TEMPLATES,
  ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION,
  ROUTE_SESSIONS_ENTITY_TEMPLATES_TESTSET_EDIT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_EDIT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT,
  ROUTE_SPORTERS_ENTITY
} from 'routes/RouteList';
import AddSession from './AddSession';
import SessionDetail from './SessionDetail';
import SessionSporter from 'containers/pages/sessions/SessionSporter';
import Panel from 'components/panel/Panel';
import messages from 'messages';
import { SessionContextProvider } from 'contexts/SessionContext';
import { StoreContext } from 'index';
import EditPrevention from 'containers/pages/prevention/EditPrevention';
import EditPreventionTemplate from 'containers/pages/prevention/EditPreventionTemplate';
import { TestSetContextProvider } from 'contexts/TestSetContext';
import { TestsProvider } from 'contexts/TestsContext';
import { PersonContextProvider } from 'contexts/PersonContext';
import { PreviewModalProvider } from 'contexts/PreviewModalContext';
import { EditableContextProvider } from 'contexts/EditableContext';
import AssignPersonsToSessionModal from 'containers/pages/sessions/AssignPersonsToSessionModal';
import { MenuContextProvider } from 'contexts/MenuContext';
import { GroupsContextProvider } from 'contexts/GroupsContext';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import NoAccessPanel from 'containers/pages/auth/NoAccessPanel';
import { SessionsContextProvider } from 'contexts/SessionsContext';

const Sessions = () => {
  const ability = useAbility(AbilityContext);
  const intl = useIntl();
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { push, location } = useHistory();

  if (ability.cannot('read', 'Sessions')) {
    return (
      <NoAccessPanel
        entityId={user.rootEntityId}
        emptyLabel={intl.formatMessage(messages.noAccessEmptyPanel)}
        redirectRoute={generatePath(ROUTE_SPORTERS_ENTITY, {
          entityId: user.rootEntityId
        })}
      />
    );
  }

  if (
    matchPath(location.pathname, {
      path: [ROUTE_SESSIONS_ENTITY_TEMPLATES]
    }) &&
    ability.cannot('read', 'Prevention')
  ) {
    return <Redirect to="/sessions" />;
  }

  return (
    <PersonContextProvider>
      <PreviewModalProvider entityId={user.rootEntityId}>
        <EditableContextProvider>
          <Switch>
            {ability.can('read', 'Prevention') && (
              <Route
                exact
                path={ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION}
                render={props => (
                  <TestsProvider entityId={props.match.params.entityId}>
                    <SessionContextProvider>
                      <EditPrevention
                        entityId={props.match.params.entityId}
                        active={
                          ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION ===
                          props.match.path
                        }
                      />
                    </SessionContextProvider>
                  </TestsProvider>
                )}
              />
            )}
            {ability.can('read', 'Prevention') && (
              <Route
                exact
                path={ROUTE_SESSIONS_ENTITY_TEMPLATES_TESTSET_EDIT}
                render={props => (
                  <TestsProvider entityId={props.match.params.entityId}>
                    <TestSetContextProvider>
                      <EditPreventionTemplate
                        edit={true}
                        entityId={props.match.params.entityId}
                        testSetId={props.match.params.testSetId}
                        active={
                          ROUTE_SESSIONS_ENTITY_TEMPLATES_TESTSET_EDIT ===
                          props.match.path
                        }
                      />
                    </TestSetContextProvider>
                  </TestsProvider>
                )}
              />
            )}
            <Route
              path={[ROUTE_SESSIONS_ENTITY_SESSION, ROUTE_SESSIONS_ENTITY]}
              render={props => (
                <SessionsContextProvider entityId={user.rootEntityId}>
                  <Overview
                    key={props.match.params.entityId}
                    entityId={props.match.params.entityId}
                    active={ROUTE_SESSIONS_ENTITY === props.match.path}
                  />
                </SessionsContextProvider>
              )}
            />
            <Redirect
              to={ROUTE_SESSIONS_ENTITY.replace(':entityId', user.rootEntityId)}
            />
          </Switch>
          <Route
            exact
            path={[ROUTE_SESSIONS_ENTITY]}
            render={() => (
              <Panel
                emptyState
                emptyLabel={intl.formatMessage(messages.sessionsEmptyPanel)}
              />
            )}
          />
          <Switch>
            <Route
              exact
              path={ROUTE_SESSIONS_ENTITY_TEMPLATES}
              render={() => (
                <Panel
                  emptyState
                  emptyLabel={intl.formatMessage(messages.sessionsEmptyPanel)}
                />
              )}
            />
            <Route
              exact
              path={ROUTE_SESSIONS_ENTITY_ADD}
              render={props => (
                <AddSession
                  entityId={props.match.params.entityId}
                  active={ROUTE_SESSIONS_ENTITY_ADD === props.match.path}
                />
              )}
            />
            <Route
              exact
              path={[
                ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS,
                ROUTE_SESSIONS_ENTITY_SESSION_ADD_SPORTER,
                ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
                ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
                ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT,
                ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_EDIT,
                ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
                ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_EDIT,
                ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_EDIT,
                ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE,
                ROUTE_SESSIONS_ENTITY_SESSION_SPORTER,
                ROUTE_SESSIONS_ENTITY_SESSION_EDIT,
                ROUTE_SESSIONS_ENTITY_SESSION
              ]}
              render={props => (
                <SessionContextProvider>
                  <SessionDetail
                    key={props.match.params.sessionId}
                    entityId={props.match.params.entityId}
                    sessionId={props.match.params.sessionId}
                    sporterId={props.match.params.sporterId}
                    edit={props.match.path.endsWith('edit')}
                    active={
                      ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS ===
                        props.match.path ||
                      ROUTE_SESSIONS_ENTITY_SESSION_EDIT === props.match.path ||
                      ROUTE_SESSIONS_ENTITY_SESSION === props.match.path
                    }
                  />
                </SessionContextProvider>
              )}
            />
          </Switch>
          <Switch>
            <Route
              exact
              path={ROUTE_SESSIONS_ENTITY_SESSION_ADD_SPORTER}
              render={props => (
                <SessionContextProvider>
                  <GroupsContextProvider
                    entityId={props.match.params.entityId}
                    rootEntityId={user.rootEntityId}
                  >
                    <AssignPersonsToSessionModal
                      entityId={props.match.params.entityId}
                      onClose={() => {
                        push(
                          generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS, {
                            entityId: props.match.params.entityId,
                            sessionId: props.match.params.sessionId
                          })
                        );
                      }}
                      onDone={() => {
                        push(
                          generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS, {
                            entityId: props.match.params.entityId,
                            sessionId: props.match.params.sessionId
                          })
                        );
                      }}
                    />
                  </GroupsContextProvider>
                </SessionContextProvider>
              )}
            />
            <Route
              exact
              path={[
                ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
                ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT,
                ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_EDIT,
                ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
                ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
                ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_EDIT,
                ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE,
                ROUTE_SESSIONS_ENTITY_SESSION_SPORTER
              ]}
              render={props => (
                <SessionContextProvider>
                  <MenuContextProvider>
                    <SessionSporter
                      key={props.match.params.sporterId}
                      entityId={props.match.params.entityId}
                      sessionId={props.match.params.sessionId}
                      sporterId={props.match.params.sporterId}
                      programId={props.match.params.programId}
                      active={
                        ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL ===
                          props.match.path ||
                        ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT ===
                          props.match.path ||
                        ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE ===
                          props.match.path ||
                        ROUTE_SESSIONS_ENTITY_SESSION_SPORTER ===
                          props.match.path
                      }
                      edit={
                        ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_EDIT ===
                          props.match.path ||
                        ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_EDIT ===
                          props.match.path
                      }
                    />
                  </MenuContextProvider>
                </SessionContextProvider>
              )}
            />
          </Switch>
        </EditableContextProvider>
      </PreviewModalProvider>
    </PersonContextProvider>
  );
};

export default withRouter(observer(Sessions));
