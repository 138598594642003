import React, { useEffect, useRef, useState } from 'react';
import Panel from 'components/panel/Panel';
import { PanelBody, PanelHeader, PanelTitle } from 'components/panel';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import { Tab, TabList, TabPanel, Tabs } from 'components/tabs';
import { Card, CardBody } from 'components/card';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import { Button } from 'components/button';
import useCanvas from 'hooks/utils/useCanvas';
import { Player, ControlBar } from 'video-react';

import { Context } from './camera';
import { files, exercises } from 'containers/pages/test/data';
import HLSSource from 'components/video/HLSSource';
import { createDetector } from 'containers/pages/test/Detector';
import useTabIndex from 'hooks/utils/useTabIndex';
import { ROUTE_TEST_ENTITY_EXERCISES_EXERCISE } from 'routes/RouteList';
import FieldSelect from 'components/input/FieldSelect';
import classNames from 'classnames';
import CardHeaderButtons from 'components/card/card-header/CardHeaderButtons';
import { useParams } from 'react-router-dom';

let detector, camera;
let poses;
let rafId;

function AnalyseVideo() {
  const { exerciseId } = useParams();
  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_TEST_ENTITY_EXERCISES_EXERCISE
  ]);
  const [videoUrl, setVideoUrl] = useState();
  const [resultVideo, setResultVideo] = useState();
  const [showResultVideo, setShowResultVideo] = useState();
  const [exercise, setExercise] = useState();
  const [videoReady, setVideoReady] = useState(false);

  const playerRef = useRef(null);
  const resultPlayerRef = useRef(null);
  const dimensionRef = useRef(null);

  //canvas creation
  const canvasRef = useCanvas(([canvas, ctx]) => {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  });

  useEffect(() => {
    setExercise(exercises.find(e => e.id === parseInt(exerciseId)));
  }, [exerciseId]);

  useEffect(() => {
    const initPoseDetection = async () => {
      detector = await createDetector();
      camera = new Context(playerRef.current, canvasRef, onDataAvailable);
    };
    if (canvasRef.current && playerRef.current) {
      playerRef.current.subscribeToStateChange(onStateChange);
      initPoseDetection();
    }
  }, [canvasRef, playerRef]);

  const onStateChange = async state => {
    if (!state.currentSrc) return;
    console.log('state video', state.videoWidth, state.videoHeight, state);
    if (state.readyState >= 3 && !dimensionRef.current) {
      dimensionRef.current = {
        width: state.videoWidth,
        height: state.videoHeight
      };

      console.log('set the dimensions');
      camera.setDimensions(state.videoWidth, state.videoHeight);

      await updateVideo();

      setVideoReady(true);
    }
  };

  function onDataAvailable(event) {
    console.log('mediaencoder', event);
    if (event.data.size > 0) {
      setShowResultVideo(true);
      const recordedChunks = [event.data];

      // Download.
      const blob = new Blob(recordedChunks, { type: 'video/webm' });
      const url = URL.createObjectURL(blob);

      resultPlayerRef.current.video.video.style.visibility = 'visible';
      setResultVideo(url);
      resultPlayerRef.current.load();
      resultPlayerRef.current.playbackRate = 2.5;
      console.log('RESULT POSES', poses);
    }
  }

  async function runFrame() {
    console.log('Run frame');
    console.log('Render start');
    await renderResult();

    // wait for 1 second
    //await new Promise(resolve => setTimeout(resolve, 1000));
    console.log('Render done');
    console.log('Current frame', camera.video.currentTime);

    if (camera.video.currentTime < camera.video.duration) {
      rafId = requestAnimationFrame(runFrame);
    }
  }

  async function renderResult() {
    return new Promise(async resolve => {
      console.log('1. Render result');
      let currentTime;
      let posesResult = [];

      // Detector can be null if initialization failed (for example when loading
      // from a URL that does not exist).
      if (detector != null) {
        // Detectors can throw errors, for example when using custom URLs that
        // contain a model that doesn't provide the expected output.
        try {
          currentTime = camera.video.currentTime;
          console.log('2. Start estimate poses');
          camera.drawCtx();

          posesResult = await detector.estimatePoses(camera.canvas, {
            maxPoses: 1,
            flipHorizontal: false
          });
          let imgData = camera.canvas.toDataURL('image/png');
          let newData = imgData.replace(
            /^data:image\/jpg/,
            'data:application/octet-stream'
          );
          console.log('3. Pose result');
          if (posesResult && posesResult.length > 0) {
            poses.push({
              currentTime,
              keypoints: posesResult[0].keypoints,
              id: poses.length,
              frameImg: newData
            });

            console.log('4. Draw result');
            camera.drawResults(posesResult);
          }

          console.log('5. Resolve');
          resolve();
        } catch (error) {
          detector.dispose();
          detector = null;
          alert(error);
        }
      }
    });
  }

  async function runEncoder() {
    //camera.video.style.visibility = 'hidden';
    playerRef.current.pause();
    playerRef.current.currentTime = 0;
    playerRef.current.playbackRate = 0.4;
    playerRef.current.play();
    camera.start();

    rafId = requestAnimationFrame(runFrame);
  }

  async function updateVideo() {
    const videoWidth = camera.video.videoWidth;
    const videoHeight = camera.video.videoHeight;
    console.log('video', videoWidth, videoHeight);

    poses = [];

    camera.canvas.width = camera.video.offsetWidth;
    camera.canvas.height = camera.video.offsetHeight;

    console.log('Video is loaded.');

    // prerender first frame
    await renderResult();
  }

  function videoIsEnded() {
    console.log('Player is paused');
    // video has finished.
    camera.stop();
    //camera.video.style.visibility = 'visible';

    showTestResults(exercise);

    window.cancelAnimationFrame(rafId);
  }

  function changeUrl(file) {
    dimensionRef.current = null;
    setShowResultVideo(false);
    setVideoReady(false);
    camera.clearCtx();

    setVideoUrl(file.url);
  }

  const [testResults, setTestResults] = useState('');

  const showTestResults = exercise => {
    /*switch (exercise.name) {
      case 'Lateral Step Down':
        setTestResults(lateralStepDown(poses));
        break;
      case 'Jump Landing Side-View':
        setTestResults(jumpLandingSideView(poses));
        break;
      case 'Jump Landing Front-View':
        setTestResults(jumpLandingFrontView(poses));
        break;
      case 'Deep Squat':
        setTestResults(deepSquat(poses, false));
        break;
      case 'Deep Squat Elevated Heels':
        setTestResults(deepSquat(poses, true));
        break;
      case 'Unipodal Jump Landing':
        setTestResults(unipodalJumpLanding(poses));
        break;
      case 'Tuck Jump Front-View':
        setTestResults(tuckJumpFrontView(poses));
        break;
      case 'Tuck Jump Side-View':
        setTestResults(tuckJumpSideView(poses));
        break;
    }
    */
  };

  return (
    <Panel secondary extraClassNames="o-flex o-flex--column">
      <PanelHeader>
        <PanelTitleWrapper>
          <PanelTitle>{`Analyse ${exercise?.name}`}</PanelTitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        <Tabs
          fullWidth
          hrefWithin
          selectedIndex={tabIndex}
          onSelect={index => setTabIndex(index)}
        >
          <TabList>
            <Tab>Video</Tab>
          </TabList>
          <TabPanel>
            <Panel>
              <PanelBody>
                <Card secondary>
                  <CardHeader secondary extraClassNames="u-margin-bottom-none">
                    <CardHeaderText>
                      <CardHeaderTitle>Video source</CardHeaderTitle>
                    </CardHeaderText>
                  </CardHeader>
                  <CardBody secondary separatorBottom>
                    <div className={classNames('o-layout o-layout--small')}>
                      <div className={classNames('o-layout__item', 'u-2-of-3')}>
                        <FieldSelect
                          onChange={file => changeUrl(file)}
                          options={files}
                          label={'Select uploaded video'}
                          placeholder="Select a video"
                        />
                      </div>
                      <div className={classNames('o-layout__item', 'u-1-of-3')}>
                        <label
                          htmlFor={`videoBtn`}
                          className={'c-input__label'}
                        >
                          {`or activate webcam`}
                        </label>
                        <Button primary id="videoBtn" disabled>
                          Use the webcam
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                  <CardHeader secondary>
                    <CardHeaderTitle>Pose Detection</CardHeaderTitle>
                    <CardHeaderButtons>
                      {videoReady ? (
                        <Button primary onClick={() => runEncoder()}>
                          Start
                        </Button>
                      ) : (
                        videoUrl && <p>Loading first pose</p>
                      )}
                    </CardHeaderButtons>
                  </CardHeader>

                  <CardBody secondary>
                    <div className={'o-layout o-layout--small'}>
                      <div className={'o-layout__item u-1-of-2'}>
                        <Player
                          playsInline
                          ref={player => (playerRef.current = player)}
                          onEnded={() => {
                            console.log('ended');
                            videoIsEnded();
                          }}
                          style={{
                            zIndex: 10
                          }}
                        >
                          <ControlBar disableCompletely />
                          <HLSSource isVideoChild src={videoUrl} />
                        </Player>
                      </div>
                      <div className={'o-layout__item u-1-of-2'}>
                        <canvas
                          ref={canvasRef}
                          style={{
                            position: 'absolute',
                            zIndex: showResultVideo ? -1 : 10
                          }}
                        ></canvas>

                        {camera?.canvas && (
                          <Player
                            playsInline
                            fluid={false}
                            width={camera.canvas.width}
                            height={camera.canvas.height}
                            ref={player => (resultPlayerRef.current = player)}
                            style={{
                              position: 'absolute',
                              zIndex: showResultVideo ? 10 : -1
                            }}
                          >
                            <source src={resultVideo} />
                          </Player>
                        )}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </PanelBody>
            </Panel>
            <Panel>
              <PanelBody>
                <CardHeader secondary extraClassNames="u-margin-bottom-none">
                  <CardHeaderText>
                    <CardHeaderTitle>Analyse Results</CardHeaderTitle>
                  </CardHeaderText>
                </CardHeader>
                <CardBody secondary separatorBottom>
                  {testResults && (
                    <div
                      // Code for dev purposes
                      dangerouslySetInnerHTML={{ __html: testResults }}
                    ></div>
                  )}
                </CardBody>
              </PanelBody>
            </Panel>
          </TabPanel>
        </Tabs>
      </PanelBody>
    </Panel>
  );
}

export default AnalyseVideo;
