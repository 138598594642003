import React from 'react';
import classNames from 'classnames';

class CardHeader extends React.Component {
  render() {
    const {
      primary,
      secondary,
      modal,
      remarks,
      extraClassNames,
      style,
      ...props
    } = this.props;

    return (
      <div
        className={classNames('c-card__header', extraClassNames, {
          'c-card__header--primary': primary,
          'c-card__header--secondary': secondary,
          'c-card__header--remarks': remarks,
          'c-card__header--primary c-card__header--left': modal
        })}
        style={style}
      >
        {props.children}
      </div>
    );
  }
}

export default CardHeader;
