import gql from 'graphql-tag';

export const QUERY_GET_SPORTS = gql`
  query getSports(
    $entityId: ID
    $type: TestSetV2Type
    $preventionType: PreventionType
  ) {
    getSports(
      entityId: $entityId
      type: $type
      preventionType: $preventionType
    ) {
      id
      title
    }
  }
`;
