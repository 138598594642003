import React, { Component } from 'react';
import classNames from 'classnames';

class TableHeaderCell extends Component {
  render() {
    const { children, extraClassNames, nowrap, ...props } = this.props;
    return (
      <th
        className={classNames('c-table-cell c-table-cell--header', {
          'c-table-cell--nowrap': nowrap
        })}
        {...props}
      >
        <div className={classNames('o-flex', extraClassNames)}>{children}</div>
      </th>
    );
  }
}

export default TableHeaderCell;
