import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Panel.scss';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';

const Panel = forwardRef((props, ref) => {
  const {
    children,
    active,
    emptyState,
    navigator,
    primary,
    secondary,
    home,
    emptyLabel,
    onClick,
    clickable,
    extraClassNames,
    benchmark,
    growthPrediction,
    fullscreen,
    style,
    dataTest,
    ...rest
  } = props;
  return (
    <div
      className={classNames('c-panel', extraClassNames, {
        'c-panel--primary': primary,
        'c-panel--secondary': secondary,
        'c-panel--home': home,
        'c-panel--home c-panel--benchmark': benchmark || growthPrediction,
        'c-panel--home c-panel--fullscreen': fullscreen,
        'is-active': active,
        'c-panel--empty-state': emptyState,
        'c-panel--nav': navigator,
        'c-panel--nav-button': clickable
      })}
      style={style}
      onClick={onClick}
      data-test={dataTest}
      ref={ref}
      {...rest}
    >
      {emptyState ? (
        <Message icon="cursor">
          {emptyLabel ? (
            <MessageText>{emptyLabel}</MessageText>
          ) : (
            <MessageText>
              Please select one of the options on the left to view the
              details...
            </MessageText>
          )}
        </Message>
      ) : (
        children
      )}
    </div>
  );
});

export default Panel;

Panel.propTypes = {
  active: PropTypes.bool,
  emptyState: PropTypes.bool,
  navigator: PropTypes.bool,
  primary: PropTypes.string,
  secondary: PropTypes.bool,
  home: PropTypes.string,
  emptyLabel: PropTypes.string,
  onClick: PropTypes.func,
  clickable: PropTypes.bool,
  extraClassNames: PropTypes.string,
  benchmark: PropTypes.bool,
  growthPrediction: PropTypes.bool,
  fullscreen: PropTypes.bool,
  style: PropTypes.object,
  dataTest: PropTypes.string
};
