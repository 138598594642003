import { BenchmarkFilters, CHART_COLORS } from 'constants.js';
import { sort, SORT_DATA_TYPES } from './sort';
import { IntlMessageFormat } from 'intl-messageformat';
import messages from 'messages';

export const parseGroupBenchmark = (benchmarkData, filter) => {
  if (!benchmarkData) return false;

  let { tests, result } = JSON.parse(benchmarkData);
  tests = tests.filter(test => !test.hidden);
  let colFilterItems = tests.map(test => ({
    id: test.id,
    label: test.name,
    hidden: test.hidden
  }));
  //colFilterItems = colFilterItems.filter(test => !test.hidden);

  const sportersResult =
    filter === BenchmarkFilters.LAST
      ? getLastOfSporters(result)
      : filter === BenchmarkFilters.BEST
      ? getBestOfSporters(result)
      : getAverageOfSporters(result); // changed to last instead of average
  // const resultAvg = JSON.parse(JSON.stringify(result));
  // const averageSportersResult = null; //getAverageOfSporters(resultAvg);

  let rows = createBenchmarkRows(result, tests, sportersResult);

  rows = addAverageToRows(rows);

  return {
    groupEntity: { id: result.entity, name: result.name },
    averageGroupEntity: { id: result.entity, name: result.name },
    dataSet: { colFilterItems, rows },
    result
  };
};

const addAverageToRows = rows => {
  const averages = {};

  rows.forEach(row => {
    row.data.forEach(data => {
      if (data.value && !averages[data.value.benchmarkId]) {
        averages[data.value.benchmarkId] = {
          data: [],
          avg: null
        };
      }
      if (data.value) {
        averages[data.value.benchmarkId].data.push(data.value.avgresult);
      }
    });
  });

  for (const prop in averages) {
    averages[prop].avg =
      averages[prop].data.reduce((acc, currentValue) => (acc += currentValue)) /
      averages[prop].data.length;
    averages[prop].minResult = averages[prop].data.reduce((acc, currentValue) =>
      acc > currentValue ? currentValue : acc
    );
    averages[prop].maxResult = averages[prop].data.reduce((acc, currentValue) =>
      acc < currentValue ? currentValue : acc
    );
  }

  rows.forEach(row => {
    row.data.forEach(data => {
      if (data.value) {
        data.avg.result = averages[data.avg.benchmarkId].avg;
        data.avg.minResult = averages[data.avg.benchmarkId].minResult;
        data.avg.maxResult = averages[data.avg.benchmarkId].maxResult;
      }
    });
  });

  return rows;
};

export const getBenchmarkDataSet = (testCols, dataSet) => {
  const rows = dataSet.rows.map(r => ({
    ...r,
    id: r.id,
    label: r.label,
    data: []
  }));
  testCols.forEach(tc => {
    const cfIndex = dataSet.colFilterItems.findIndex(cfi => cfi.id === tc.id);
    rows.forEach(row => {
      const orgRow = dataSet.rows.find(r => r.id === row.id);
      const orgRowData = orgRow.data;
      const hasData = orgRow.data && orgRowData[cfIndex];
      row.data.push(hasData ? orgRowData[cfIndex] : {});
    });
  });
  return {
    rows,
    cols: testCols,
    colFilterItems: dataSet.colFilterItems
  };
};

export const getGroupBenchmarkChartData = (result, sporterId, testId) => {
  const sporter = result.sporters.find(sporter => sporter.id === sporterId);
  const { firstname, lastname } = sporter;
  let data = getDataForChart(sporter, testId);
  data = sort(data, {
    keys: [{ key: 'date', dataType: SORT_DATA_TYPES.DATE_STRING }]
  });
  const { min, max, avg } = getMinMaxAndAvarageOfTest(result, testId);
  return {
    min,
    max,
    avg,
    data: [
      {
        id: `${firstname} ${lastname}`,
        color: CHART_COLORS.GREEN,
        data
      }
    ]
  };
};

export const getBenchmarkResultLabel = (result, unit, fractionDigits = 2) => {
  //   if (!fractionDigits) {
  //     // fractionDigits = result - Math.floor(result) !== 0 ? 2 : 0;
  //     fractionDigits = 2;
  //   }

  if (isNaN(parseFloat(result))) return '';

  try {
    const unitFirst = unit && unit === '#' ? unit : '';
    const resultValue = parseFloat(result).toFixed(fractionDigits);
    const unitLast =
      unit && unit.toLowerCase().includes('min')
        ? new IntlMessageFormat(messages.labelUnit.defaultMessage).format({
            count: '',
            unit: 'mins'
          })
        : unit && unit !== '#' && unit.toLowerCase().indexOf('none') === -1
        ? unit
        : '';

    return `${unitFirst} ${resultValue} ${unitLast}`;
  } catch (error) {
    return `${unit && unit === '#' ? unit : ''}${result}${
      unit && unit !== '#' && unit.toLowerCase() !== 'none' ? unit : ''
    }`;
  }
};

//TODO is dat wel nodig? Ja he
export const getPercentageOfResult = (result, min, max) => {
  if (
    parseFloat(result) === parseFloat(min) &&
    parseFloat(min) === parseFloat(max)
  ) {
    return 50;
  } else {
    return ((result - min) / (max - min)) * 100;
  }
};

export const chartData = {
  min: 50,
  max: 210,
  avg: 130,
  data: [
    {
      id: 'Fabian Spinazie',
      color: CHART_COLORS.GREEN,
      data: [
        { x: 'Sessie A', y: 100, date: '22/12/2016' },
        { x: 'Sessie B', y: 120, date: '22/12/2017' },
        { x: 'Sessie C', y: 200, date: '22/12/2018' }
      ]
    }
  ]
};

/**
 * give sporter his array of sessions and will return
 * the avarage of all sessions
 * @param {*} sessions
 */
const getAverageOfSporterSessions = sessions => {
  // merge all results over mutliple sessions to one array
  let concattedResults = sessions.reduce((a, b) => {
    if (b && b.results && b.results.length) {
      return [...a, ...b.results];
    }
    return a;
  }, []);

  return mergeSporterResult(concattedResults, 'average');
};

/**
 * give sporter his array of sessions and will return
 * the last of all sessions
 * @param {*} sessions
 */
const getLastOfSporterSessions = sessions => {
  // merge all results over mutliple sessions to one array
  const concattedResults = sessions.reduce((a, b) => {
    if (b && b.results && b.results.length) {
      b.results.forEach(result => {
        result.datum = b.date;
        result.date = new Date(
          b.date.split('-')[0],
          parseInt(b.date.split('-')[1]) - 1,
          b.date.split('-')[2]
        );
      });
      return [...a, ...b.results];
    }
    return a;
  }, []);

  return mergeSporterResult(concattedResults, 'last');
};

/**
 * give sporter his array of sessions and will return
 * the last of all sessions
 * @param {*} sessions
 */
const getBestOfSporterSessions = sessions => {
  // merge all results over mutliple sessions to one array
  const concattedResults = sessions.reduce((a, b) => {
    if (b && b.results && b.results.length) {
      b.results.forEach(result => {
        result.datum = b.date;
        result.date = new Date(
          b.date.split('-')[0],
          parseInt(b.date.split('-')[1]) - 1,
          b.date.split('-')[2]
        );
      });
      return [...a, ...b.results];
    }
    return a;
  }, []);

  return mergeSporterResult(concattedResults, 'best');
};

const getAverageOfSporters = results => {
  const averagesOfSporters = [];
  results.sporters.forEach(sporter => {
    const average = getAverageOfSporterSessions(sporter.sessions);
    sporter.results = average;
    averagesOfSporters.push(...average);
  });
  return mergeSporterResult(averagesOfSporters, 'average');
};

const getLastOfSporters = results => {
  const lastOfSporters = [];
  results.sporters.forEach(sporter => {
    const last = getLastOfSporterSessions(sporter.sessions);
    sporter.results = last;
    lastOfSporters.push(...last);
  });
  return mergeSporterResult(lastOfSporters, 'last');
};

const getBestOfSporters = results => {
  const bestOfSporters = [];
  results.sporters.forEach(sporter => {
    const best = getBestOfSporterSessions(sporter.sessions);
    sporter.results = best;
    bestOfSporters.push(...best);
  });
  return mergeSporterResult(bestOfSporters, 'best');
};

const mergeSporterResult = (sporterResult, type) => {
  const reducer =
    type === 'average'
      ? testDataAverageReducer
      : type === 'last'
      ? testDataLastReducer
      : testDataBeststReducer;
  const mergedSporterResultObject = sporterResult.reduce(reducer, {});
  const mergedSporterResultArray = [];
  // convert merged sport data to array of items
  Object.entries(mergedSporterResultObject).forEach(([key, item]) => {
    // if (item.zscore !== undefined && !isNaN(item.zscore)) {
    mergedSporterResultArray.push(item);
    // }
  });
  return mergedSporterResultArray;
};

/**
 * loops all tests from user and will create a unique item per sport
 * and return the avg of all items
 * @param {*} accumulator
 * @param {*} currentValue
 * @param {*} currentIndex
 * @param {*} array
 */
const testDataAverageReducer = (
  accumulator,
  currentValue,
  currentIndex,
  array
) => {
  const totalItemCount = array.length;
  const key = currentValue.benchmarkId;

  const item = accumulator[key] || {
    benchmarkId: currentValue.benchmarkId,
    name: currentValue.name,
    countZScore: 0,
    count: 0,
    score: 0,
    zscore: 0,
    percentile: 0,
    minScore: Number.POSITIVE_INFINITY,
    maxScore: 0,

    avgscore: 0,
    avgzscore: 0,
    avgpercentile: 0
  };

  item.count += 1;
  item.score += currentValue.score;
  item.avgscore += currentValue.score;
  if (currentValue.zscore !== undefined && !isNaN(currentValue.zscore)) {
    item.zscore += currentValue.zscore;
    item.percentile += currentValue.percentile;
    item.countZScore += 1;

    item.avgzscore += currentValue.zscore;
    item.avgpercentile += currentValue.percentile;
  } else {
    item.zscore = NaN;
  }

  if (currentValue.score < item.minScore) item.minScore = currentValue.score;
  if (currentValue.score > item.maxScore) item.maxScore = currentValue.score;

  accumulator[key] = item;

  if (totalItemCount - 1 === currentIndex) {
    Object.entries(accumulator).forEach(([key, item]) => {
      item.score = item.score / item.count;
      item.avgscore = item.avgscore / item.count;
      //if (currentValue.zscore !== undefined && !isNaN(currentValue.zscore)) {
      if (item.zscore !== undefined && !isNaN(item.zscore)) {
        item.zscore = item.zscore / item.countZScore;
        item.percentile = item.percentile / item.countZScore;

        item.avgzscore = item.avgzscore / item.countZScore;
        item.avgpercentile = item.avgpercentile / item.countZScore;
      }
    });
  }

  return accumulator;
};

/**
 * loops all tests from user and will create a unique item per sport
 * and return the avg of all items
 * @param {*} accumulator
 * @param {*} currentValue
 */
const testDataLastReducer = (accumulator, currentValue) => {
  const key = currentValue.benchmarkId;

  const item = accumulator[key] || {
    benchmarkId: currentValue.benchmarkId,
    name: currentValue.name,
    countZScore: 0,
    count: 0,
    score: 0,
    zscore: 0,
    percentile: 0,
    minScore: Number.POSITIVE_INFINITY,
    maxScore: 0,
    date: new Date(1970, 0, 1),

    avgscore: 0
    // avgzscore: 0,
    // avgpercentile: 0
  };

  item.count += 1;
  if (currentValue.date.getTime() > item.date.getTime()) {
    item.zscore = currentValue.zscore;
    item.percentile = currentValue.percentile;
    item.date = currentValue.date;
    item.score = currentValue.score;

    item.avgscore += currentValue.score;
    // item.avgzscore += currentValue.zscore;
    // item.avgpercentile += currentValue.percentile;
  }

  // if (currentValue.score < item.minScore) item.minScore = currentValue.score;
  // if (currentValue.score > item.maxScore) item.maxScore = currentValue.score;

  accumulator[key] = item;

  /*if (totalItemCount - 1 === currentIndex) {
    Object.entries(accumulator).forEach(([key, item]) => {
      item.avgscore = item.avgscore / item.count;
      //if (currentValue.zscore !== undefined && !isNaN(currentValue.zscore)) {
      if (item.zscore !== undefined && !isNaN(item.zscore)) {
        item.avgzscore = item.avgzscore / item.countZScore;
        item.avgpercentile = item.avgpercentile / item.countZScore;
      }
    });
  }*/

  return accumulator;
};

/**
 * loops all tests from user and will create a unique item per sport
 * and return the avg of all items
 * @param {*} accumulator
 * @param {*} currentValue
 */
const testDataBeststReducer = (accumulator, currentValue) => {
  const key = currentValue.benchmarkId;

  const item = accumulator[key] || {
    benchmarkId: currentValue.benchmarkId,
    name: currentValue.name,
    countZScore: 0,
    count: 0,
    score: 0,
    zscore: 0,
    percentile: 0,
    minScore: Number.POSITIVE_INFINITY,
    maxScore: 0,
    date: new Date(1970, 0, 1),

    avgscore: 0
    // avgzscore: 0,
    // avgpercentile: 0
  };

  item.count += 1;
  if (currentValue.percentile > item.percentile) {
    item.zscore = currentValue.zscore;
    item.percentile = currentValue.percentile;
    item.date = currentValue.date;
    item.score = currentValue.score;

    // item.avgscore += currentValue.score;
    // item.avgzscore += currentValue.zscore;
    // item.avgpercentile += currentValue.percentile;

    // if (currentValue.score < item.minScore) item.minScore = currentValue.score;
    // if (currentValue.score > item.maxScore) item.maxScore = currentValue.score;
  }

  if (currentValue.score > item.avgscore) {
    item.avgscore = currentValue.score;
  }

  accumulator[key] = item;

  // if (totalItemCount - 1 === currentIndex) {
  //   Object.entries(accumulator).forEach(([key, item]) => {
  //     item.avgscore = item.avgscore / item.count;
  //     //if (currentValue.zscore !== undefined && !isNaN(currentValue.zscore)) {
  //     if (item.zscore !== undefined && !isNaN(item.zscore)) {
  //       item.avgzscore = item.avgzscore / item.countZScore;
  //       item.avgpercentile = item.avgpercentile / item.countZScore;
  //     }
  //   });
  // }

  return accumulator;
};

const getRowDataForSporter = (
  tests,
  sportersResult,
  averageSportersResult,
  results,
  sessions
) => {
  const rowData = [];
  tests.forEach(test => {
    const testUnit = getTestUnit(test.id, sessions);
    const data = {};
    const rowValue = results.find(sporterResult => {
      return test.id === sporterResult.benchmarkId;
    });
    const rowAvg = sportersResult.find(
      sporterResult => test.id === sporterResult.benchmarkId
    );
    if (rowValue && rowAvg) {
      data.value = getCellData(rowValue, testUnit);
      data.avg = getCellData(rowAvg, testUnit);
    }
    rowData.push(data);
  });

  return rowData;
};

const getTestUnit = (id, sessions) => {
  let unit;
  sessions.some(s => {
    if (s.results && s.results.length) {
      const result = s.results.find(r => r.benchmarkId === id);
      if (result) {
        unit = result.unit;
        return true;
      }
    }
    return false;
  });
  return unit || '#';
};

const getCellData = (data, testUnit) => {
  return {
    benchmarkId: data.benchmarkId,
    name: data.name,
    zScore: data.zscore,
    result: data.score,
    avgresult: data.avgscore,
    percentile: data.percentile,
    unit: testUnit,
    minResult: data.minScore,
    maxResult: data.maxScore
  };
};

const createBenchmarkRows = (
  result,
  tests,
  sportersResult,
  averageSportersResult
) => {
  const rows = [];
  result.sporters.forEach(({ id, firstname, lastname, results, sessions }) => {
    const rowData = getRowDataForSporter(
      tests,
      sportersResult,
      averageSportersResult,
      results,
      sessions
    );
    const row = {
      id: id,
      label: `${firstname} ${lastname}`,
      firstname,
      lastname,
      data: rowData
    };
    rows.push(row);
  });
  return rows;
};

const getAvgOfTest = (results, benchmarkId) => {
  const result = getAverageOfSporters(results).find(
    result => result.benchmarkId === benchmarkId
  );
  if (result) {
    return result.score;
  }

  return -1;
};

const getMinMaxOfTest = (results, benchmarkId) => {
  let max = 0;
  let min = Number.POSITIVE_INFINITY;
  results.sporters.forEach(sporter => {
    sporter.sessions.forEach(session => {
      if (session.results && session.results.length) {
        const filterTestResults = session.results.filter(
          result => result.benchmarkId === benchmarkId
        );
        filterTestResults.forEach(result => {
          if (result.score > max) {
            max = result.score;
          }
          if (result.score < min) {
            min = result.score;
          }
        });
      }
    });
  });

  return { max, min };
};

const getMinMaxAndAvarageOfTest = (results, testId) => {
  const avg = getAvgOfTest(results, testId);
  const { min, max } = getMinMaxOfTest(results, testId);
  return { min, max, avg };
};

const getDataForChart = (sporter, benchmarkId) => {
  const testResults = [];
  sporter.sessions.forEach(session => {
    if (session.results && session.results.length) {
      const test = session.results.find(result => {
        return benchmarkId === result.benchmarkId;
      });
      if (test) {
        testResults.push({
          x: `${session.name}#-#(${session.date})`,
          y: test.score,
          date: session.date,
          unit: test.unit,
          percentile: test.percentile,
          result: test.score,
          zScore: test.zscore
        });
      }
    }
  });
  return testResults;
};
