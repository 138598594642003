import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { observer } from 'mobx-react';
import Panel from 'components/panel/Panel';
import messages from 'messages';
import { PanelBody, PanelHeader, PanelSubtitle } from 'components/panel';
import {
  PanelTitle,
  PanelTitleButton,
  PanelTitleWrapper
} from 'components/panel/panel-title';
import PanelTools from 'components/panel/PanelTools';
import { Button } from 'components/button';
import {
  ROUTE_EXERCISES_TRAININGS_ENTITY,
  ROUTE_EXERCISES_TRAININGS_ENTITY_ARCHIVED,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES
} from 'routes/RouteList';
import Icon from 'components/icon/Icon';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { MenuWrapper } from 'components/menu';
import { StoreContext } from 'index';
import Loader from 'components/loader/Loader';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import TemplatesListItem from 'containers/partials/list-items/TemplatesListItem';
import BreadcrumbPath from 'containers/partials/misc/BreadcrumbPath';
import useSelectable from 'hooks/utils/useSelectable';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import { TabList, Tab, TabPanel, Tabs, TabPanelBody } from 'components/tabs';
import AddTrainingModal from 'containers/pages/exercises/trainings/AddTrainingModal';
import useTabIndex from 'hooks/utils/useTabIndex';
import { Link } from 'react-router-dom';
import { useTrainingsContext } from 'contexts/TrainingsContext';
import FieldInput from 'components/input/FieldInput';
import Training from 'models/Training';

const Trainings = ({ active, entityId, trainingId }) => {
  const {
    authStore: { user },
    routing: { push }
  } = useContext(StoreContext);
  const ability = useAbility(AbilityContext);

  const [addModalData, setAddModalData] = useState(null);
  const { state, actions, loading: trainingsLoading } = useTrainingsContext();
  const { selected, select } = useSelectable([]);
  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_EXERCISES_TRAININGS_ENTITY,
    ROUTE_EXERCISES_TRAININGS_ENTITY_ARCHIVED
  ]);

  useEffect(() => {
    actions.getTrainings();
  }, []);

  const onSearch = e => {
    actions.setFilter({ search: e.target.value });
  };

  const onClickHandler = training => {
    push(
      generatePath(ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES, {
        entityId,
        trainingId: training.id
      })
    );
  };

  const onEditHandler = training => {
    push(
      generatePath(ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING, {
        entityId,
        trainingId: training.id
      })
    );
  };

  const onDeleteHandler = trainingId => {
    push(
      generatePath(ROUTE_EXERCISES_TRAININGS_ENTITY, {
        entityId,
        trainingId
      })
    );
  };

  return (
    <Panel active={active}>
      <PanelHeader>
        <BreadcrumbPath crumbs={[]} />
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.trainingsTitle} />
            <MenuWrapper trigger={<PanelTitleButton />} />
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage {...messages.trainingsSubTitle} />
          </PanelSubtitle>
        </PanelTitleWrapper>
        <PanelTools>
          <FieldInput rounded icon="search-grey" iconLeft onChange={onSearch} />

          {ability.can('create', 'Training') && (
            <Button rounded primary onClick={() => setAddModalData(true)}>
              <Icon id="add-white" />
              <FormattedMessage {...messages.exercisesAddTrainingButton} />
            </Button>
          )}
        </PanelTools>
      </PanelHeader>
      <PanelBody>
        {trainingsLoading && <Loader />}
        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <TabList>
            <Tab key={`trainings.my`}>
              <Link
                to={generatePath(ROUTE_EXERCISES_TRAININGS_ENTITY, {
                  entityId
                })}
              >
                <FormattedMessage {...messages.trainingsTabTrainings} />
              </Link>
            </Tab>
          </TabList>
          <TabPanel>
            <TabPanelBody withScroller>
              {state.trainings &&
              state.trainings.length &&
              state.trainings.filter(t => t.personId === user.id).length ? (
                <List>
                  {/* <ListHeader noTitleStatus>
                <ListColumnTitle
                // ascendent // in comment totdat het werkt:
                // TODO: rangschikking list by column title
                >
                  <FormattedMessage
                    {...messages.trainingsHeaderListTrainings}
                  />
                </ListColumnTitle>
                <ListColumnTitle>
                  <FormattedMessage
                    {...messages.trainingsHeaderListExercisesQty}
                  />
                </ListColumnTitle>
              </ListHeader> */}
                  <ListBody>
                    {state.trainings
                      .filter(t => t.personId === user.id)
                      .map(training => (
                        <TemplatesListItem
                          checkbox={false}
                          icon={'program'}
                          visible={selected.length > 0}
                          exercisesLength={training.exercises.length}
                          id={training.id}
                          key={training.id}
                          template={training}
                          entityId={entityId}
                          active={training.id === trainingId}
                          checked={selected.includes(training.id)}
                          onChange={() => select(training.id)}
                          onClick={() => {
                            return onClickHandler(training);
                          }}
                          onEdit={() => onEditHandler(training)}
                          onDeleted={() => onDeleteHandler(trainingId)}
                        />
                      ))}
                  </ListBody>
                </List>
              ) : (
                <EmptyTrainingList />
              )}
            </TabPanelBody>
          </TabPanel>
        </Tabs>
      </PanelBody>

      {addModalData && (
        <AddTrainingModal
          entityId={entityId}
          training={new Training({})}
          onClose={() => setAddModalData(false)}
        />
      )}
    </Panel>
  );
};

export default observer(Trainings);

const EmptyTrainingList = () => (
  <Card centered>
    <CardBody empty>
      <Message emptyState={true} icon="browse">
        <MessageText>
          <FormattedMessage {...messages.templateListEmpty} />
        </MessageText>
      </Message>
    </CardBody>
  </Card>
);
