import {
  getLeftLegFreeLatStepDown,
  dynamicBalance,
  kneeValgus2,
  lumbarPelvicControl,
  getExtremum,
  skelet,
  hipFlexionDisplacement,
  footSymmetrySide,
  kneeFlexionDisplacement,
  kneeFurtherThanFoot,
  footRotation,
  getFirstJumpLandingSide,
  lateralTrunkFlexion,
  stanceWidth,
  footSymmetryFront,
  deepSquatEvaluation,
  getLeftLegFreeUnipodal,
  downloadImage,
  getLandingFramesIdx,
  ValgusClassifier,
  getLeftSideVisible,
  TuckJumpFootPlacement,
  TuckJumpKneeInwards,
  getFirstJumpLandingFront,
  TuckJumpAverageThighsEqual,
  devideByIndeces
} from './TestFunctions';

export const testMethod = {
  /****************************************
   * LATERAL STEP DOWN TEST:              *
   * dynamic balance @todo evaluate       *
   * lumbar pelvic control                *
   * knee valgus hip rotation             *
   ****************************************/

  /**
   * Function to calculate scores for the lateral step down test.
   * @param {Array} poses list contains {pose} = {id: , keypoints: [], frame: }
   * @returns all the info about the lateral step down test
   */
  lateralStepDown: ({ poses }) => {
    var result = '';
    const leftLegFree = getLeftLegFreeLatStepDown(poses);
    const lowestPose = getExtremum(
      poses,
      leftLegFree ? skelet.left_heel : skelet.right_heel,
      true,
      false
    );
    result += `Checks: leftLegFree: ${leftLegFree}<br>`;
    const [dynamicBalanceScore, dynamicBalanceDetails] = dynamicBalance(poses);
    result += `Scoring: Dynamic Balance: ${dynamicBalanceScore} (${dynamicBalanceDetails}) <br>`;
    const [kneeValgusScore, kneeValgusDetails] = ValgusClassifier(
      poses,
      !leftLegFree
    );
    result += `Scoring: Knee valgus: ${kneeValgusScore} (${kneeValgusDetails}) <br>`;
    const [lumbarPelvicControlScore, lumbarPelvicControlDetails] =
      lumbarPelvicControl(poses);
    result += `Scoring: Pelvic control: ${lumbarPelvicControlScore} (${lumbarPelvicControlDetails}) <br>`;

    return { message: result };
  },

  /********************************************
   * JUMP LANDING TEST: SIDEVIEW              *
   *  initial landing of feet                 *
   *  knee reflex at initial contact          *
   *  @todo knee reflex through first landing *
   *  hip flexion at initial contact          *
   *  knee before foot?                       *
   ********************************************/

  /**
   * Function to calculate scores for the Jump Landing test.
   * @param {Array} poses list contains [ {pose} = {id: , keypoints: [], frame: }
   * @returns all the info about the jump landing test
   * @todo difference between kneeflexions (video?)
   */
  jumpLandingSideView: ({ poses }) => {
    const leftSide = getLeftSideVisible(poses[0]);
    const firstLanding = getFirstJumpLandingSide(poses, leftSide);
    var posesAfterLanding = [];
    // get poses from 0.8s after landing
    for (let i = firstLanding.id; i < poses.length; i++) {
      if (poses[i].currentTime - firstLanding.currentTime < 0.8) {
        posesAfterLanding.push(poses[i]);
      }
    }
    var posesBeforeLanding = [];
    // get poses from 0.8s before landing
    for (let i = 0; i <= firstLanding.id; i++) {
      if (firstLanding.currentTime - poses[i].currentTime < 0.8) {
        posesBeforeLanding.push(poses[i]);
      }
    }
    let result = '';
    result += `Checks: leftSide: ${leftSide}<br>`;
    result += `Checks: Landing time: ${firstLanding.currentTime}<br>`;
    const [footSymmetryScore, footSymmetryDetails] = footSymmetrySide(
      posesBeforeLanding,
      leftSide
    );
    result += `Scoring: Initial landing of feet: ${footSymmetryScore} (${footSymmetryDetails})<br>`;
    const [kneeFlexionScore, kneeFlexionDetails, deepestPose] =
      kneeFlexionDisplacement(posesAfterLanding, leftSide);
    result += `Scoring: Amount of knee-flexion displacement: ${kneeFlexionScore} (${kneeFlexionDetails}) <br>`;
    const [hipFlexionScore, hipFlexionDetails] = hipFlexionDisplacement(
      firstLanding,
      leftSide
    );
    result += `Scoring: Amount of hip-flexion displacement: ${hipFlexionScore} (${hipFlexionDetails})<br>`;
    const [kneeFurtherThanFootScore, kneeFurtherThanFootDetails] =
      kneeFurtherThanFoot(firstLanding, leftSide);
    result += `Scoring: Total joint displacement in the sagittal plane: ${kneeFurtherThanFootScore} (${kneeFurtherThanFootDetails}) <br>`;
    result += `Overall impression: / <br>`;

    return { message: result };
  },

  /********************************************
   * JUMP LANDING TEST: FRONT VIEW            *
   *  stand width                             *
   *  max postiton foot rotation              *
   *  initial foot contact                    *
   *  max knee valgus                         *
   *  lateroflexion trunk                     *
   ********************************************/

  /**
   * Function to calculate scores for the Jump Landing test, filmed from the front.
   * @param {Array} poses list contains {pose} = {id: , keypoints: [], frame: }
   * @returns all the info about the jump landing test: front view
   */
  jumpLandingFrontView: ({ poses }) => {
    let pose = poses[poses.length - 1];
    let firstLandingLeft = getFirstJumpLandingFront(poses, true);
    let firstLandingRight = getFirstJumpLandingFront(poses, false);
    let result = '';
    const [standWidthScore, standWidthDetails] = stanceWidth(firstLandingLeft);
    result += `Scoring: Stance width: ${standWidthScore} (${standWidthDetails})<br>`;
    const [footRotationLScore, footRotationLDetails] = footRotation(
      firstLandingLeft,
      true
    );
    result += `Scoring: Maximum foot-rotation position left: ${footRotationLScore} (${footRotationLDetails})<br>`;
    const [footRotationRScore, footRotationRDetails] = footRotation(
      firstLandingRight,
      false
    );
    result += `Scoring: Maximum foot-rotation position right: ${footRotationRScore} (${footRotationRDetails})<br>`;
    const [footSymmetryScore, footSymmetryDetails] = footSymmetryFront(
      firstLandingLeft,
      firstLandingRight
    );
    result += `Scoring: Initial foot contact: ${footSymmetryScore} (${footSymmetryDetails}) <br>`;
    const [
      [leftKneeValgusScore, leftKneeValgusDetails],
      [rightKneeValgusScore, rightKneeValgusDetails]
    ] = kneeValgus2(poses);
    result += `Scoring: Maximum knee-valgus angle: left: ${leftKneeValgusScore}, right: ${rightKneeValgusScore} <br>`;
    const [lateralFlexionScore, lateralFlexionDetails] =
      lateralTrunkFlexion(firstLandingLeft);
    result += `Scoring: Amount of lateral trunk flexion: ${lateralFlexionScore} (${lateralFlexionDetails})<br>`;

    return { message: result };
  },

  /*********************************************************************
   * TUCK JUMP TEST:                                                   *
   * A. Knee move inwards                                              *
   * Thighs don’t reach horizontal (at highest point of jump)          *
   * C. Thighs not equal side to side (observed throughout sequence)   *
   * D. Foot placement not shoulder width apart                        *
   * Foot placement staggered (= assymetric) or rotated)               *
   * No toe-to-midfoot landing                                         *
   * No rapid rebound between jumps (visible delay)                    *
   * Lands in different footprints                                     *
   ********************************************************************/

  tuckJumpFrontView: ({ poses }) => {
    const values = [];
    let message = '';
    let Indices = getLandingFramesIdx(poses);
    console.log('Indices', Indices);
    let blocks = devideByIndeces(poses, Indices);
    //A
    let score = TuckJumpKneeInwards(poses);
    message += `A. Scoring knee moving inwards: ${score} <br>`;
    values.push({ TuckJumpKneeInwards: score });

    //C
    score = TuckJumpAverageThighsEqual(poses);
    message += `C. Thighs not equal side to side: ${score} <br>`;
    values.push({ TuckJumpAverageThighsEqual: score });

    //D
    //@todo when rendering goes smoother, check shoulder-feet ratio's
    console.log(TuckJumpFootPlacement(poses, Indices));

    //E

    return { frames: Indices, message, values };
  },

  /*********************************************************************
   * TUCK JUMP TEST:                                                   *
   * B. Thighs don’t reach horizontal (at highest point of jump)       *
   * No toe-to-midfoot landing                                         *
   * Lands in different footprints                                     *
   ********************************************************************/

  tuckJumpSideView: ({ poses }) => {
    let result = '';
    return result;
  },

  /********************************************
   * DEEP SQUAT TEST                          *
   * score 0 (bad) - 3 (best)                 *
   ********************************************/

  /**

     * @param {Array} poses list contains {pose} = {id: , keypoints: [], frame: }
     * @returns all the info about the deep squat test
     */
  deepSquat: ({ poses, elevatedHeels = true }) => {
    var result = '';
    const leftSide = getLeftSideVisible(poses[0]);
    result += `Checks: leftSide: ${leftSide}<br>`;
    const [score, details] = deepSquatEvaluation(
      poses,
      leftSide,
      elevatedHeels
    );
    result += `Scoring: ${score} (${details})`;

    return { message: result };
  },

  /********************************************
   * UNIPODAL JUMP LANDING TEST               *
   * maximum foot rotation position           *
   * maximum knee valgus angle
   * lateral trunk flexion at initial contact
   ********************************************/

  /**
   * Function to calculate scores for the Jump Landing test, filmed from the front.
   * @param {Array} poses list contains {pose} = {id: , keypoints: [], frame: }
   * @returns all the info about the Unipodal Jump Landing Test
   */
  unipodalJumpLanding: ({ poses }) => {
    var result = '';
    const leftLeg = !getLeftLegFreeUnipodal(poses);
    const firstLanding = getFirstJumpLandingFront(poses, leftLeg);
    result += `Checks: leftLeg: ${leftLeg}<br>`;
    const [footRotationScore, footRotationDetails] = footRotation(
      firstLanding,
      leftLeg
    );
    result += `Scoring: Maximum foot-rotation position: ${footRotationScore} (${footRotationDetails})<br>`;
    const [kneeValgusScore, kneeValgusDetails] = leftLeg
      ? ValgusClassifier(poses, leftLeg)
      : ValgusClassifier(poses, !leftLeg);
    result += `Scoring: Maximum knee-valgus angle: ${kneeValgusScore} (${kneeValgusDetails})<br>`;
    const [lateralFlexionScore, lateralFlexionDetails] =
      lateralTrunkFlexion(firstLanding);
    result += `Scoring: Amount of lateral trunk flexion: ${lateralFlexionScore} (${lateralFlexionDetails})<br>`;

    return { message: result };
  }
};
