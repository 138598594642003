import React from 'react';
import messages from 'messages';
import { useIntl } from 'react-intl';
import { useNotificationQueue } from 'components/notification';
import ConfirmButton from 'containers/partials/buttons/ConfirmButton';
import Icon from 'components/icon/Icon';
import {
  createLogin,
  QUERY_GET_USER_BY_ID,
  updateUserMutation
} from 'services/aws/user-query';
import { PersonEntityRoles } from 'constants.js';

const InviteSportersButton = ({ entityId, persons, children, ...props }) => {
  const intl = useIntl();
  const notification = useNotificationQueue();
  const onConfirmInviteUsers = async () => {
    if (Array.isArray(persons) && persons.length > 0) {
      for (const person of persons) {
        if (person.hasEmailNoLogin() || person.hasInValidLogin()) {
          await updateUserMutation({
            variables: {
              personId: person.id,
              entityId,
              roles: [PersonEntityRoles.ROLE_ATHLETE]
            },
            refetchQueries: [
              {
                query: QUERY_GET_USER_BY_ID,
                variables: {
                  id: person.id
                }
              }
            ]
          });

          await createLogin(person.id);
        }
      }

      notification.add(`personsInvite`, {
        message: intl.formatMessage(messages.messageUsersInvited)
      });
    }
  };
  return (
    <ConfirmButton
      {...props}
      confirmTitle={messages.modalConfirmInviteSportersTitle}
      confirmMessage={messages.modalConfirmInviteSportersMessage}
      onOk={onConfirmInviteUsers}
      confirmAcceptLabel={messages.modalConfirmInviteSportersAcceptButton}
    >
      <Icon id="email" strokeColor="color-neutral-dark" />
      {children}
    </ConfirmButton>
  );
};

export default InviteSportersButton;
