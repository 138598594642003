import { useEffect, useState } from 'react';
import Select, { Option } from 'components/input/Select';
import { useQuery } from '@apollo/client';
import { QUERY_GET_SPORTS } from 'services/aws/prevention-query';
import { capitalize } from 'utils/string';
import { sort } from 'utils/sort';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Input from 'components/input/Input';

function SportSelect({
  entityId,
  type,
  preventionType,
  onChange,
  value,
  errors,
  touched,
  readOnly = false
}) {
  const intl = useIntl();
  const [sports, setSports] = useState([]);
  const { loading, data } = useQuery(QUERY_GET_SPORTS, {
    fetchPolicy: 'cache-first',
    variables: { entityId, type, preventionType },
    skip: !preventionType
  });
  useEffect(() => {
    if (data?.getSports) {
      setSports(sort(data.getSports, { keys: [{ key: 'title' }] }));
    }
  }, [data, value]);

  if (!preventionType) {
    return (
      <Input
        id="sportNoTypeSelected"
        disabled
        placeholder={intl.formatMessage(messages.sportSelectNoTypeMessage)}
        defaultValue=""
      >
        <FormattedMessage {...messages.sportSelect} /> *
      </Input>
    );
  }

  if (loading) {
    return (
      <Input
        id="sportNoTypeLoading"
        disabled
        placeholder={intl.formatMessage(messages.globalLoading)}
        defaultValue=""
      >
        <FormattedMessage {...messages.sportSelect} /> *
      </Input>
    );
  }

  return (
    <Select
      id="sport"
      label={intl.formatMessage(messages.sportSelect) + ' *'}
      name="sport"
      onChange={selectedValue => {
        onChange(sports.find(i => selectedValue.value === i.id));
      }}
      value={value}
      readOnly={readOnly}
      disabled={loading || readOnly}
      errors={errors}
      touched={touched}
      placeholder={intl.formatMessage(messages.sportSelectPlaceholder)}
    >
      {sports.map(sport => (
        <Option
          key={sport.id}
          value={sport.id}
          label={capitalize(sport.title)}
        />
      ))}
    </Select>
  );
}

export default SportSelect;
