import gql from 'graphql-tag';

export const MUTATION_ADD_ANNOTATION = gql`
  mutation addAnnotation(
    $linkId: ID
    $fileId: ID
    $testSetId: ID
    $starttime: Int
    $endtime: Int
    $type: AnnotationType
    $meta: AWSJSON
  ) {
    addAnnotation(
      input: {
        linkId: $linkId
        fileId: $fileId
        testSetId: $testSetId
        starttime: $starttime
        endtime: $endtime
        type: $type
        meta: $meta
      }
    ) {
      id
    }
  }
`;

export const MUTATION_EDIT_ANNOTATION = gql`
  mutation editAnnotation(
    $id: ID
    $linkId: ID
    $fileId: ID
    $testSetId: ID
    $starttime: Int
    $endtime: Int
    $type: AnnotationType
    $meta: AWSJSON
  ) {
    editAnnotation(
      input: {
        id: $id
        linkId: $linkId
        fileId: $fileId
        testSetId: $testSetId
        starttime: $starttime
        endtime: $endtime
        type: $type
        meta: $meta
      }
    ) {
      id
    }
  }
`;

export const QUERY_GET_ANNOTATION = gql`
  query getAnnotation($annotationId: ID) {
    getAnnotation(annotationId: $annotationId) {
      id
      file {
        id
        filename
        signedDownloadUrl
        linkId
        status
        meta
        type
      }
      person {
        id
        firstname
        lastname
        meta
      }

      testSet {
        id
        type
        testSetVersion
      }
      testData {
        id
        personId
        data
        finished
        finishedPercentage
        date_updated
        date_created
        testSetVersion
      }

      notes {
        id
        linkId
        note
        person {
          id
          firstname
          lastname
        }
      }

      starttime
      endtime

      type
      meta

      date_created

      exercise {
        id
      }
    }
  }
`;

export const QUERY_GET_ANNOTATIONS = gql`
  query getAnnotations($linkId: ID) {
    getAnnotations(linkId: $linkId) {
      id
      person {
        id
        firstname
        lastname
        meta
      }
      testSet {
        id
        type
        testSetVersion
      }
      testData {
        id
        personId
        data
        result
        date_updated
        date_created
        finished
        resultBenchmarkId
      }
      starttime
      endtime
      date_created
      notes {
        id
        linkId
        note
        noteType
        date_created
        person {
          id
          firstname
          lastname
        }
      }
      exercise {
        id
      }
    }
  }
`;

export const MUTATION_ARCHIVE_ANNOTATION = gql`
  mutation archiveAnnotation($annotationId: ID!) {
    archiveAnnotation(annotationId: $annotationId)
  }
`;
