import React from 'react';
import './Message.scss';
import classNames from 'classnames';

class MessageText extends React.Component {
  render() {
    const { children, warning, extraClassNames } = this.props;

    return (
      <p
        className={classNames('c-message__text', extraClassNames, {
          'c-message__text-warning': warning
        })}
      >
        {children}
      </p>
    );
  }
}

export default MessageText;
