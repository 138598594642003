import { useContext } from 'react';
import { StoreContext } from 'index';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { CardBody, CardFooter } from 'components/card';
import FieldInput from 'components/input/FieldInput';
import messages from 'messages';
import { Button, ButtonsGroup } from 'components/button';
import { ROUTE_EXERCISES_ENTITY_EXERCISE_EDIT } from 'routes/RouteList';
import Exercise from 'models/Exercise';
import ExerciseForm from 'containers/pages/exercises/exercises/ExerciseForm';
import { generatePath } from 'react-router-dom';
import Select, { Option } from 'components/input/Select';
import { ErrorMessage } from 'formik';
import InputErrorMessage from 'components/input/InputErrorMessage';

function AddExerciseModal({ entityId, templateId, onClose }) {
  const {
    authStore: { user },
    routing: { push }
  } = useContext(StoreContext);
  const intl = useIntl();

  /*const [addExerciseToProgram] = useMutation(
    MUTATION_ADD_EXERCISE_TO_TEMPLATES,
    {
      refetchQueries: [
        { query: QUERY_GET_TEMPLATES, variables: { entityId } },
        { query: QUERY_GET_EXERCISES, variables: { entityId } }
      ]
    }
  );*/

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal card autoHeight isOpen={true} onClose={onCloseHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.exercisesAddExerciseTitle} />
        </CardHeaderTitle>
      </CardHeader>
      <ExerciseForm
        entityId={entityId}
        exercise={new Exercise({})}
        onComplete={async exerciseId => {
          if (templateId) {
            /*await addExerciseToProgram({
              variables: {
                exerciseId: data.id,
                programTemplateIds: [lessonId]
              }
            });*/
            /*push(
              generatePath(ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO, {
                entityId,
                lessonId,
                exerciseId: data.id
              })
            );*/
          } else {
            push(
              generatePath(ROUTE_EXERCISES_ENTITY_EXERCISE_EDIT, {
                entityId,
                exerciseId: exerciseId
              })
            );
          }
          onClose && onClose();
        }}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <>
            <CardBody modalWithOverflow>
              <div className="o-layout o-layout--small">
                {user.isGSVOrg ? (
                  <div className="o-layout__item u-3-of-3">
                    {/* EXERCISE TITLE */}
                    <FieldInput
                      id="title"
                      name="title"
                      placeholder={intl.formatMessage(
                        messages.exercisesLabelTitle
                      )}
                      errors={errors}
                      touched={touched}
                    >
                      <FormattedMessage {...messages.exercisesLabelTitle} />*
                    </FieldInput>
                  </div>
                ) : (
                  <>
                    <div className="o-layout__item u-2-of-3">
                      {/* EXERCISE TITLE */}
                      <FieldInput
                        id="title"
                        name="title"
                        placeholder={intl.formatMessage(
                          messages.exercisesLabelTitle
                        )}
                        errors={errors}
                        touched={touched}
                      >
                        <FormattedMessage {...messages.exercisesLabelTitle} />*
                      </FieldInput>
                    </div>
                    <div className="o-layout__item u-1-of-3">
                      {/* LEVEL */}
                      {/* TODO level field is deprecated */}
                      <Select
                        extraClassNames="u-growth-left"
                        label={`${intl.formatMessage(
                          messages.exercisesLabelLevel
                        )}*`}
                        name="level"
                        left
                        value={values.level}
                        onChange={option => {
                          setFieldValue('level', option.value);
                        }}
                      >
                        {[1, 2, 3, 4, 5].map(lvl => (
                          <Option
                            key={`level_${lvl}`}
                            value={lvl}
                            label={`Level ${lvl}`}
                          />
                        ))}
                      </Select>
                      {errors && (
                        <ErrorMessage
                          name="level"
                          component={InputErrorMessage}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </CardBody>
            <CardFooter modal extraClassNames="c-card__footer--modal-larger">
              <ButtonsGroup>
                <Button secondary onClick={() => onCloseHandler()}>
                  <FormattedMessage {...messages.buttonCancel} />
                </Button>
                <Button type="submit" primary>
                  <FormattedMessage {...messages.buttonSave} />
                </Button>
              </ButtonsGroup>
            </CardFooter>
          </>
        )}
      </ExerciseForm>
    </Modal>
  );
}

export default AddExerciseModal;
