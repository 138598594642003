import Scanner from './Scanner';
export default class Calibration {
  id = '';
  name = '';
  date = '';
  shim = '';
  coil = '';
  meta;
  constructor({
    id = '',
    name = '',
    date = '',
    shim = '',
    coil = '',
    meta,
    scanner = {}
  }) {
    this.id = id;
    this.name = name;
    this.date = date;
    this.shim = shim;
    this.coil = coil;
    this.meta = meta;
    this.scanner = new Scanner(scanner);
  }
}
