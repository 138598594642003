import React, { useContext, useEffect, useState } from 'react';
import {
  withRouter,
  generatePath,
  useHistory,
  useParams,
  useLocation
} from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { MenuWrapper } from 'components/menu';
import PanelTools from 'components/panel/PanelTools';
import Panel from 'components/panel/Panel';
import {
  ROUTE_SPORTERS_ENTITY_ADD,
  ROUTE_SPORTERS_ENTITY_ADD_GROUP,
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SPORTERS_ENTITY_POOL
} from 'routes/RouteList';
import {
  PanelTitle,
  PanelSubtitle,
  PanelTitleWrapper,
  PanelTitleButton
} from 'components/panel/panel-title';
import PanelHeader from 'components/panel/PanelHeader';
import Icon from 'components/icon/Icon';
import Button from 'components/button/Button';
import messages from 'messages';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import Modal from 'components/modal/Modal';
import ImportSportersCard, {
  IMPORT_SPORTERS_STATE
} from 'containers/partials/cards/ImportSportersCard';
import CopySporterModal, {
  SPORTER_ACTION
} from 'containers/partials/modals/CopySporterModal';
import { StoreContext } from 'index';
import { useGroupsContext } from 'contexts/GroupsContext';
import YearSelect from 'containers/partials/inputs/YearSelect';
import FieldInput from 'components/input/FieldInput';
import { PanelBody } from 'components/panel';
import TabPanelBody from 'components/tabs/TabPanelBody';
import { Tabs } from 'components/tabs';
import { TabList, TabPanel } from 'react-tabs';
import useTabIndex from 'hooks/utils/useTabIndex';
import TabLink from 'components/tabs/TabLink';
import GroupsTab from 'containers/pages/sporters/GroupsTab';
import PersonsPoolTab from 'containers/pages/sporters/PersonsPoolTab';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import {
  createLogin,
  MUTATE_RESET_LOGIN,
  MUTATE_UPDATE_USER_ROLES,
  QUERY_GET_PERSONS_OF_ENTITY,
  QUERY_GET_USER_BY_ID
} from 'services/aws/user-query';
import { PersonEntityRoles } from 'constants.js';
import { S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID } from 'services/aws/client-query';
import { useNotificationQueue } from 'components/notification';
import { useMenuContext } from 'contexts/MenuContext';
import useSelectableObject from 'hooks/utils/useSelectableObject';

const OverView = ({ active }) => {
  const {
    uiState,
    authStore: {
      user,
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const intl = useIntl();
  let location = useLocation();
  const { entityId, sporterId } = useParams();
  const { push } = useHistory();
  const ability = useAbility(AbilityContext);
  const {
    state: { group, hasGroups, filter },
    actions,
    error,
    refetch
  } = useGroupsContext();
  const { selected, setSelected } = useSelectableObject();
  const notification = useNotificationQueue();

  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_SPORTERS_ENTITY,
    ROUTE_SPORTERS_ENTITY_POOL
  ]);

  const [copyModal, setCopyModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [modalSize, setModalSize] = useState(true);

  const { menuItems } = useMenuContext();

  useEffect(() => {
    if (entityId === rootEntityId && sporterId && tabIndex > 0) {
      setTabIndex(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setSelected([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex]);

  const onCloseImportModal = reload => {
    if (reload) {
      refetch();
    }
    setImportModal(false);
  };

  const onImportModalStateChange = state => {
    setModalSize(state !== IMPORT_SPORTERS_STATE.RESULT);
  };

  const onCloseCopyModal = () => {
    setCopyModal(false);
    setSelected([]);
  };

  const onSearch = e => {
    actions.setFilter({ search: e.target.value });
  };

  const onYearFilter = year => {
    actions.setFilter({ year });
  };

  const onSelected = persons => {
    setSelected(persons);
  };

  const onClickPersonStatusHandler = person => {
    let mutation;
    let variables;
    if (person.email) {
      mutation = MUTATE_RESET_LOGIN;
      variables = {
        personId: person.id
      };
    } else {
      mutation = MUTATE_UPDATE_USER_ROLES;
      variables = {
        personId: person.id,
        entityId,
        roles: [PersonEntityRoles.ROLE_ATHLETE]
      };
    }
    uiState.showModal({
      title: intl.formatMessage(messages.modalConfirmInviteSporterTitle),
      message: intl.formatMessage(messages.modalConfirmReInviteSporterMessage),
      okLabel: intl.formatMessage(messages.modalConfirmInviteAcceptButton),
      dismissLabel: intl.formatMessage(messages.modalConfirmRemoveCancelButton),
      mutationData: {
        mutation,
        variables,
        refetchQueries: [
          {
            query: S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID,
            variables: {
              id: entityId
            }
          },
          {
            query: QUERY_GET_PERSONS_OF_ENTITY,
            variables: {
              entityId
            }
          },
          {
            query: QUERY_GET_USER_BY_ID,
            variables: {
              id: person.id
            }
          }
        ],
        update: async (cache, { data: { updatePersonEntityRoles } }) => {
          if (updatePersonEntityRoles) {
            if (!person.email) {
              await createLogin(person.id);
            }
            notification.add(`personInvite_${person.id}`, {
              message: intl.formatMessage(messages.messageUserInvited)
            });
            await actions.refetchGroups(entityId);
          }
        }
      }
    });
  };

  if (error) return <RetryPanel />;

  return (
    <Panel active={active}>
      <PanelHeader>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.titleSporters} />
            {((user.rootEntityId !== entityId &&
              ability.can('manage', 'Groups')) ||
              ability.can('update', 'Athletes')) && (
              <MenuWrapper trigger={<PanelTitleButton />}>
                {menuItems}
              </MenuWrapper>
            )}
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage {...messages.subtitleSporters} />
          </PanelSubtitle>
        </PanelTitleWrapper>
        <PanelTools>
          <FieldInput
            rounded
            type="text"
            icon="search-grey"
            iconLeft
            value={filter.search}
            onChange={onSearch}
          />
          {(user.isSportamundiOrg || user.isHanOrg) && hasGroups && (
            <YearSelect
              onChange={onYearFilter}
              value={filter.year}
              label={intl.formatMessage(messages.yearPerGroupSelect)}
            />
          )}

          {ability.can('create', 'Athletes') && (
            <Button
              rounded
              primary
              onClick={() =>
                push(generatePath(ROUTE_SPORTERS_ENTITY_ADD, { entityId }))
              }
            >
              <Icon id="add-white" />
              <FormattedMessage {...messages.buttonSportersAddSporter} />
            </Button>
          )}

          {ability.can('create', 'Groups') && (
            <Button
              rounded
              primary
              onClick={() =>
                push(
                  generatePath(ROUTE_SPORTERS_ENTITY_ADD_GROUP, { entityId })
                )
              }
            >
              <Icon id="add-white" />
              <FormattedMessage {...messages.addGroupButton} />
            </Button>
          )}
        </PanelTools>
      </PanelHeader>
      <PanelBody>
        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <TabList>
            <TabLink
              key="athletes"
              to={generatePath(ROUTE_SPORTERS_ENTITY, {
                entityId
              })}
            >
              <FormattedMessage {...messages.athletesGroupsTab} />
            </TabLink>
            <TabLink
              key="athletes.pool"
              to={generatePath(ROUTE_SPORTERS_ENTITY_POOL, {
                entityId: rootEntityId
              })}
            >
              <FormattedMessage {...messages.athletesPoolTab} />
            </TabLink>
          </TabList>
          <TabPanel key="athletes">
            <TabPanelBody>
              <GroupsTab
                onSelected={persons => onSelected(persons)}
                selected={selected}
                setCopyModal={setCopyModal}
                setImportModal={setImportModal}
                onClickPersonStatus={onClickPersonStatusHandler}
              />
            </TabPanelBody>
          </TabPanel>
          <TabPanel key="athletes.pool">
            <TabPanelBody>
              <PersonsPoolTab
                onSelected={persons => onSelected(persons)}
                selected={selected}
                setCopyModal={setCopyModal}
                setImportModal={setImportModal}
                onClickPersonStatus={onClickPersonStatusHandler}
              />
            </TabPanelBody>
          </TabPanel>
        </Tabs>

        {copyModal && (
          <CopySporterModal
            oldEntityId={
              copyModal === SPORTER_ACTION.COPY_POOL ? rootEntityId : entityId
            }
            sporters={selected}
            onClose={onCloseCopyModal}
            action={copyModal}
          />
        )}
        {importModal && (
          <Modal
            importSporters={!modalSize}
            cardSmall={modalSize}
            autoHeight={modalSize}
            isOpen={true}
            onClose={onCloseImportModal}
          >
            <ImportSportersCard
              rootEntityId={user.rootEntityId}
              entityId={entityId}
              entityName={group.name}
              onClose={reload => onCloseImportModal(reload)}
              onStateChange={onImportModalStateChange}
            />
          </Modal>
        )}
      </PanelBody>
    </Panel>
  );
};

export default withRouter(OverView);
