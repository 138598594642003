import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from 'messages';
import { TabList, Tab, TabPanel, Tabs } from 'components/tabs';
import Button from 'components/button/Button';
import Panel from 'components/panel/Panel';
import PanelHeader from 'components/panel/PanelHeader';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import {
  ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS,
  ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION,
  ROUTE_SCANNERS
} from 'routes/RouteList';
import { Mutation, Query } from '@apollo/client/react/components';
import PanelBody from 'components/panel/PanelBody';

import { Card, CardBody, CardFooter } from 'components/card';
import {
  CardHeader,
  CardHeaderButtons,
  CardHeaderTitle
} from 'components/card/card-header';
import {
  MUTATION_ADD_CALIBRATION_TO_SCANNER,
  QUERY_GET_SCANNER_BY_ID
} from 'services/aws/scanner-query';
import ScannerModel from 'models/Scanner';
import {
  PanelSubtitle,
  PanelTitleWrapper,
  PanelTitle
} from 'components/panel/panel-title';
import { isValueValidNumber } from 'utils/form';
import Loader from 'components/loader/Loader';
import FieldInput from 'components/input/FieldInput';

class AddCalibration extends Component {
  state = {
    name: '',
    nameError: false,
    date: new Date().toISOString().substring(0, 10),
    dateError: false,
    shim: '',
    shimError: false,
    coil: '',
    coilError: false
  };

  onChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  onBlur = (e, type) => {
    if (type === 'number') {
      const value = Number(e.target.value.replace(',', '.'));
      this.setState({
        [e.target.id]: value,
        [`${e.target.id}Error`]: isValueValidNumber(value) ? false : true
      });
    } else {
      this.setState({
        [`${e.target.id}Error`]: e.target.value ? false : true
      });
    }
  };

  handleSubmit = async (e, mutation) => {
    e.preventDefault();

    const { entityId, scannerId } = this.props;
    const { name, date, coil, shim } = this.state;

    await mutation({
      variables: {
        scannerId,
        entityId,
        name,
        date,
        coil,
        shim
      }
    });
  };

  render() {
    const {
      routing: { push },
      entityId,
      scannerId,
      active,
      intl
    } = this.props;

    const {
      name,
      nameError,
      date,
      dateError,
      coil,
      coilError,
      shim,
      shimError
    } = this.state;

    const isValidToSubmit = () =>
      name && date && isValueValidNumber(coil) && isValueValidNumber(shim);

    return (
      <Panel active={active}>
        <PanelHeader>
          <Breadcrumbs>
            <Breadcrumb
              onClick={() =>
                push(ROUTE_SCANNERS.replace(':entityId', entityId))
              }
            >
              <FormattedMessage {...messages.breadcrumbScanners} />
            </Breadcrumb>
            <Breadcrumb
              onClick={() =>
                push(
                  ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS.replace(
                    ':entityId',
                    entityId
                  ).replace(':scannerId', scannerId)
                )
              }
            >
              <Query
                query={QUERY_GET_SCANNER_BY_ID}
                fetchPolicy="cache-and-network"
                variables={{ entityId, scannerId }}
              >
                {result => {
                  const { loading, error, data } = result;
                  if (loading) return <Loader />;
                  if (error)
                    return (
                      <p>
                        <FormattedMessage
                          {...messages.errorMessage}
                          values={{
                            message: error.message
                          }}
                        />
                      </p>
                    );
                  const scanner = new ScannerModel(data.getMtsScanner);
                  return (
                    <FormattedMessage
                      {...messages.breadcrumbScannersCalibrations}
                      values={{ scanner: scanner.name }}
                    />
                  );
                }}
              </Query>
            </Breadcrumb>
          </Breadcrumbs>
          <PanelTitleWrapper>
            <PanelTitle>
              {name ? (
                name
              ) : (
                <FormattedMessage {...messages.scannerTitleNewCalibration} />
              )}
            </PanelTitle>
            <PanelSubtitle />
          </PanelTitleWrapper>
        </PanelHeader>
        <PanelBody>
          <Tabs>
            <TabList>
              <Tab>
                <FormattedMessage {...messages.scannerTabGlobal} />
              </Tab>
            </TabList>
            <TabPanel>
              <Mutation
                mutation={MUTATION_ADD_CALIBRATION_TO_SCANNER}
                refetchQueries={[
                  {
                    query: QUERY_GET_SCANNER_BY_ID,
                    variables: { entityId, scannerId }
                  }
                ]}
                update={(cache, { data: { addMtsCalibrationToScanner } }) => {
                  if (
                    addMtsCalibrationToScanner &&
                    addMtsCalibrationToScanner.id
                  ) {
                    push(
                      `${ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION.replace(
                        ':entityId',
                        entityId
                      )
                        .replace(':scannerId', scannerId)
                        .replace(
                          ':calibrationId',
                          addMtsCalibrationToScanner.id
                        )}`
                    );
                  }
                }}
              >
                {(addCalibration, result) => {
                  const { loading, error } = result;
                  return (
                    <Card secondary>
                      <form>
                        <CardHeader secondary>
                          <CardHeaderTitle>
                            <FormattedMessage
                              {...messages.scannerCalibrationTitleDetails}
                            />
                          </CardHeaderTitle>

                          <CardHeaderButtons>
                            <Button
                              type="submit"
                              tiny
                              disabled={loading || !isValidToSubmit()}
                              onClick={e =>
                                this.handleSubmit(e, addCalibration)
                              }
                            >
                              {intl.formatMessage(messages.scannerButtonSubmit)}
                            </Button>
                          </CardHeaderButtons>
                        </CardHeader>
                        <CardBody secondary>
                          <FieldInput
                            id="name"
                            onChange={this.onChange}
                            onBlur={this.onBlur}
                            icon={nameError ? 'validation-error' : ' '}
                          >
                            <FormattedMessage
                              {...messages.calibrationNameLabel}
                            />
                            *
                          </FieldInput>
                          <FieldInput
                            id="date"
                            onChange={this.onChange}
                            onBlur={this.onBlur}
                            type="date"
                            icon={dateError ? 'validation-error' : ' '}
                            defaultValue={date}
                            required
                          >
                            <FormattedMessage
                              {...messages.calibrationDateLabel}
                            />
                            *
                          </FieldInput>
                          <FieldInput
                            id="shim"
                            pattern="\d+(\.\d*)?"
                            onChange={e => this.onChange(e, 'number')}
                            onBlur={e => this.onBlur(e, 'number')}
                            icon={shimError ? 'validation-error' : ' '}
                          >
                            <FormattedMessage
                              {...messages.calibrationShimLabel}
                            />
                            *
                          </FieldInput>
                          <FieldInput
                            id="coil"
                            pattern="\d+(\.\d*)?"
                            onChange={e => this.onChange(e, 'number')}
                            onBlur={e => this.onBlur(e, 'number')}
                            icon={coilError ? 'validation-error' : ' '}
                          >
                            <FormattedMessage
                              {...messages.calibrationCoilLabel}
                            />
                            *
                          </FieldInput>
                        </CardBody>
                        <CardFooter secondary>
                          <Button
                            secondary
                            onClick={() =>
                              push(
                                ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS.replace(
                                  ':entityId',
                                  entityId
                                ).replace(':scannerId', scannerId)
                              )
                            }
                          >
                            <FormattedMessage
                              {...messages.scannerButtonCancel}
                            />
                          </Button>
                          <Button
                            type="submit"
                            primary
                            disabled={loading || !isValidToSubmit()}
                            onClick={e => this.handleSubmit(e, addCalibration)}
                          >
                            <FormattedMessage
                              {...messages.scannerButtonSubmit}
                            />
                          </Button>
                        </CardFooter>
                      </form>
                    </Card>
                  );
                }}
              </Mutation>
            </TabPanel>
          </Tabs>
        </PanelBody>
      </Panel>
    );
  }
}

export default withRouter(
  injectIntl(inject('routing', 'authStore')(observer(AddCalibration)))
);
