import React, { Component } from 'react';
import classNames from 'classnames';

class ProgressBarGroup extends Component {
  render() {
    const {
      children,
      noData,
      clickable,
      inline,
      valueAtEnd,
      fullWidth,
      noSideNoBenchmark
    } = this.props;
    // let valueBar = range;
    return (
      <div
        className={classNames('c-progress-bar__group', {
          'c-progress-bar__group-nodata': noData,
          'c-progress-bar__group-clickable': clickable,
          'c-progress-bar__group-inline': inline,
          'c-progress-bar__group-value-at-end c-progress-bar__group-full-width':
            valueAtEnd,
          'c-progress-bar__group-full-width': fullWidth,
          'c-progress-bar__group-full-width c-progress-bar__group-no-side-no-benchmark':
            noSideNoBenchmark
        })}
      >
        {children}
      </div>
    );
  }
}

export default ProgressBarGroup;
