import React from 'react';
import classNames from 'classnames';

class PageContentWrapper extends React.Component {
  scrollRef = React.createRef();

  componentDidMount() {
    window.addEventListener('resize', this.resizeHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  componentDidUpdate() {
    if (this.scrollRef) {
      this.resizeHandler();
    }
  }

  resizeHandler = () => {
    const element = this.scrollRef.current;
    window.requestAnimationFrame(() => {
      element.scrollLeft = element.scrollWidth - element.clientWidth;
    });
  };

  render() {
    const {
      horizontalScroller,
      centeredContent,
      //isHomePage,
      isResults,
      isGrowthPrediction
    } = this.props;
    return (
      <div
        className={classNames('c-page__content-wrapper', {
          'c-page__content-center': centeredContent,
          'c-page__content-horizontal-flow': horizontalScroller,
          //'c-page__content-home': isHomePage,
          'c-page__content-benchmark': isResults || isGrowthPrediction
        })}
        ref={this.scrollRef}
      >
        {this.props.children}
      </div>
    );
  }
}

export default PageContentWrapper;
