import React from 'react';

class PanelSubtitle extends React.Component {
  render() {
    const { ...props } = this.props;
    return (
      <h3 className="c-panel__subtitle">
        {props.children ? props.children : '\u00a0'}
      </h3>
    );
  }
}

export default PanelSubtitle;
