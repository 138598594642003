import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';

import { ROUTE_LOGIN } from './RouteList';
import { StoreContext } from '../index';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const {
    authStore: { initLoginStateChanged, isLoggedIn }
  } = useContext(StoreContext);

  if (initLoginStateChanged) {
    if (isLoggedIn)
      return (
        <Route {...rest}>
          <Component {...rest} />
        </Route>
      );
    return <Redirect to={ROUTE_LOGIN} />;
  }

  return null;
};

export default observer(PrivateRoute);
