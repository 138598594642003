import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import { generatePath } from 'react-router-dom';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useNotificationQueue } from 'components/notification';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import {
  MUTATION_ADD_PERSON_INJURY,
  MUTATION_EDIT_PERSON_INJURY,
  QUERY_GET_PERSON_MEDICALS
} from 'services/aws/injury-query';
import { ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL } from 'routes/RouteList';
import { StoreContext } from 'index';
import { usePersonContext } from 'contexts/PersonContext';
import { useMedicalContext } from 'contexts/MedicalContext';
import messages from 'messages';
import { parseDateString } from 'utils/date';
import { format } from 'date-fns';

function InjuryForm({
  entityId,
  personInjury,
  extraClassNames,
  onComplete,
  children
}) {
  const notification = useNotificationQueue();
  const intl = useIntl();
  const {
    routing: { push },
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);

  const { person } = usePersonContext();
  const { medical } = useMedicalContext();

  const [addInjury] = useMutation(MUTATION_ADD_PERSON_INJURY);

  const [editInjury] = useMutation(MUTATION_EDIT_PERSON_INJURY);

  const onSubmitHandler = async personInjury => {
    const injuryObject = {
      sides: personInjury.side !== 'center' ? [personInjury.side] : [],
      medicalId: medical.id,
      injuryId: personInjury.injury.id,
      injuryDate: format(personInjury.injuryDate, 'yyyy-LL-dd'),
      injuryEndDate: personInjury.injuryEndDate
        ? format(personInjury.injuryEndDate, 'yyyy-LL-dd')
        : null,
      meta: JSON.stringify({
        typeOfInjury: personInjury.typeOfInjury,
        causeOfInjury: personInjury.causeOfInjury,
        relatedTo: {
          sport: personInjury.relatedToSport,
          growth: personInjury.relatedToGrowth
        },
        description: personInjury.description,
        extraInfo: personInjury.extraInfo
      })
    };

    if (personInjury.id) {
      await editInjury({
        variables: { ...personInjury, ...injuryObject },
        refetchQueries: [
          {
            query: QUERY_GET_PERSON_MEDICALS,
            variables: { entityId: rootEntityId, personId: person.id }
          }
        ]
      }).then(res => {
        notification.add(res.data.editPersonInjury.id, {
          message: intl.formatMessage(messages.rehabInjurySaved)
        });
        if (onComplete) onComplete();
      });
    } else {
      await addInjury({
        variables: injuryObject,
        refetchQueries: [
          {
            query: QUERY_GET_PERSON_MEDICALS,
            variables: { entityId: rootEntityId, personId: person.id }
          }
        ]
      }).then(res => {
        notification.add(res.data.addPersonInjury.id, {
          message: intl.formatMessage(messages.rehabInjurySaved)
        });
        if (onComplete) onComplete();
        push(
          generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL, {
            entityId,
            sporterId: person.id,
            medicalId: medical.id,
            injuryId: res.data.addPersonInjury.id
          })
        );
      });
    }
  };

  const validationSchema = Yup.object().shape({
    injury: Yup.object().shape({
      id: Yup.string().required(intl.formatMessage(messages.injuryRequired))
    }),
    injuryDate: Yup.date()
      .transform(parseDateString)
      .typeError(intl.formatMessage(messages.dateRequired))
      .required(intl.formatMessage(messages.dateRequired)),
    injuryEndDate: Yup.date()
      .transform(parseDateString)
      .nullable()
      .default(null),
    side: Yup.string()
      .matches(/(left|right|center)/)
      .required(intl.formatMessage(messages.sideRequired))
  });

  return (
    <Formik
      initialValues={personInjury}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
    >
      {props => {
        return (
          <Form className={classNames(extraClassNames)} noValidate>
            {typeof children === 'function' ? children(props) : children}
          </Form>
        );
      }}
    </Formik>
  );
}

export default InjuryForm;
