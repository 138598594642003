import classNames from 'classnames';

const BreadcrumbsWrapper = props => {
  return (
    <div
      className={classNames('c-breadcrumbs-wrapper', props.extraClassNames, {
        'c-breadcrumbs-wrapper--for-lists': props.forLists
      })}
    >
      {props.children}
    </div>
  );
};

export default BreadcrumbsWrapper;
