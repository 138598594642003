import React from 'react';
import classNames from 'classnames';

import './PageLayout.scss';

const PageLayout = props => (
  <div
    className={classNames(
      `c-page c-page--login-${props.branding.toLowerCase()}`,
      {
        'c-page--login': props.loginPage
      }
    )}
  >
    {props.children}
  </div>
);

PageLayout.propTypes = {};

export default PageLayout;
