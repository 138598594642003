import { useContext, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import Panel from 'components/panel/Panel';
import messages from 'messages';
import { PanelBody, PanelHeader, PanelTitleButton } from 'components/panel';
import { PanelTitle, PanelTitleWrapper } from 'components/panel/panel-title';
import { Button, ButtonsGroup } from 'components/button';
import {
  ROUTE_EXERCISES_ENTITY,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES,
  ROUTE_EXERCISES_TEMPLATES_ENTITY,
  ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EDIT,
  ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EXERCISES_EXERCISE
} from 'routes/RouteList';
import Icon from 'components/icon/Icon';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import { Tabs } from 'components/tabs';
import { Tab, TabList, TabPanel } from 'react-tabs';
import { StoreContext } from 'index';
import MessageText from 'components/message/MessageText';
import Message from 'components/message/Message';
import { Card, CardBody } from 'components/card';
import Loader from 'components/loader/Loader';
import { MenuWrapper } from 'components/menu';
import AddProgramModal from 'containers/pages/exercises/programs/AddProgramModal';
import { usePreviewModalContext } from 'contexts/PreviewModalContext';
import useTemplate from 'hooks/queries/useTemplate';
import useSelectable from 'hooks/utils/useSelectable';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import ExercisesListSortable from 'containers/partials/lists/ExercisesListSortable';
import RemoveExercisesFromTemplateButton from 'containers/partials/buttons/RemoveExercisesFromTemplateButton';
import SortableList from 'components/dndkit/SortableList';
import {
  MUTATION_EDIT_TEMPLATE,
  QUERY_GET_TEMPLATES
} from 'services/aws/templates-query';
import { useNotificationQueue } from 'components/notification';
import { useMutation } from '@apollo/client';

const Template = ({ active, entityId, templateId, tabIndex, exerciseId }) => {
  const {
    authStore: { user },
    routing: { push }
  } = useContext(StoreContext);
  const intl = useIntl();
  const notification = useNotificationQueue();
  const { setModalData } = usePreviewModalContext();
  const [programModalData, setProgramModalData] = useState(null);
  const [draggable, setDraggable] = useState(false);
  const { template, exercises, loading, error } = useTemplate(
    entityId,
    templateId,
    user
  );
  const { selected, setSelected } = useSelectable();
  const [exercisesSortOrder, setExercisesSortOrder] = useState(null);

  const [editTemplate] = useMutation(MUTATION_EDIT_TEMPLATE);

  const onEditHandler = () => {
    push(
      generatePath(ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EDIT, {
        entityId,
        templateId
      })
    );
  };

  const onUseHandler = template => {
    // show use template modal
    setProgramModalData({
      entityId,
      title: '',
      startdate: '',
      duration: '',
      description: '',
      exerciseIds: template.exercises.map(e => e.id),
      templateId: template.id
    });
  };

  const submitExercisesSorting = async () => {
    const templateData = {
      id: template.id,
      entityId: entityId,
      title: template.title,
      exerciseIds: [...exercisesSortOrder]
    };

    await editTemplate({
      variables: { ...templateData },
      refetchQueries: [
        {
          query: QUERY_GET_TEMPLATES,
          variables: { entityId }
        }
      ]
    }).then(res => {
      setDraggable(false);
      notification.add(res.data.editExerciseProgramTemplate.id, {
        message: intl.formatMessage(messages.templateSaved)
      });
    });
  };

  const onCancelHandler = () => {
    setDraggable(false);
  };

  if (loading)
    return (
      <Panel active={active}>
        <Loader />
      </Panel>
    );
  if (error) return <RetryPanel />;

  return (
    <Panel active={active}>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb
            to={ROUTE_EXERCISES_ENTITY.replace(':entityId', entityId)}
          >
            <FormattedMessage {...messages.breadcrumbOverview} />
          </Breadcrumb>
          <Breadcrumb
            to={ROUTE_EXERCISES_TEMPLATES_ENTITY.replace(':entityId', entityId)}
          >
            <FormattedMessage {...messages.breadcrumbTemplates} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            {template.title}

            <MenuWrapper trigger={<PanelTitleButton />}>
              {selected.length > 0 && (
                <RemoveExercisesFromTemplateButton
                  entityId={entityId}
                  type="template"
                  exerciseIds={selected}
                  templateId={templateId}
                  updated={() => setSelected([])}
                />
              )}
              <Button menu onClick={() => onUseHandler(template)}>
                <FormattedMessage {...messages.exercisesUseTemplateButton} />

                <Icon id="use-template" />
              </Button>
              <Button menu onClick={() => onEditHandler(template)}>
                <FormattedMessage {...messages.exercisesEditTemplateButton} />
                <Icon id="edit" />
              </Button>
              <Button
                menu
                onClick={() => setDraggable(true)}
                disabled={(selected && selected.length > 0) || draggable}
              >
                {draggable ? (
                  <FormattedMessage
                    {...messages.templatesSortExercisesDisabledDragging}
                  />
                ) : (
                  <FormattedMessage {...messages.sortTemplateButton} />
                )}
                <Icon id="arrange" strokeColor="color-neutral-dark" />
              </Button>
            </MenuWrapper>
          </PanelTitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        <Tabs defaultIndex={tabIndex}>
          <TabList>
            <Tab>
              <FormattedMessage {...messages.exercisesTitle} />
            </Tab>
          </TabList>
          <TabPanel>
            <div
              className="o-flex o-flex--column u-padding-vertical-small"
              style={{
                overflowY: 'scroll',
                flex: 1,
                marginBottom: draggable ? 72 : 0
              }}
            >
              {exercises.length > 0 ? (
                <>
                  <SortableList
                    items={exercises}
                    onSort={exercises => setExercisesSortOrder(exercises)}
                  >
                    {({ items }) => (
                      <ExercisesListSortable
                        checkable
                        exercises={items}
                        exerciseGroup={template}
                        selectedExercises={selected}
                        activeId={exerciseId}
                        draggable={draggable}
                        enablePreviewModal={false}
                        showTemplateCount={false}
                        showNote
                        onPreview={exercise =>
                          setModalData({ type: 'exercise', data: exercise })
                        }
                        onClick={id => {
                          if (id !== exerciseId) {
                            push(
                              generatePath(
                                ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EXERCISES_EXERCISE,
                                {
                                  entityId,
                                  templateId,
                                  exerciseId: id
                                }
                              )
                            );
                          }
                        }}
                        onSelect={exerciseIds => {
                          setSelected([...exerciseIds]);
                        }}
                      />
                    )}
                  </SortableList>
                  {draggable && (
                    <ButtonsGroup
                      extraClassNames="u-margin-left-auto u-margin-top-medium"
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        left: 0,
                        background: 'white',
                        padding: '16px 32px',
                        boxShadow: '0 -5px 5px -8px #ccc',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Button secondary onClick={() => onCancelHandler()}>
                        <FormattedMessage {...messages.cancelButton} />
                      </Button>
                      <Button
                        primary
                        disabled={loading} //|| processing
                        onClick={() => submitExercisesSorting()}
                      >
                        <FormattedMessage
                          {...messages.saveSortTemplateButton}
                        />
                      </Button>
                    </ButtonsGroup>
                  )}
                </>
              ) : (
                <Card centered>
                  <CardBody empty>
                    <Message emptyState={true} icon="browse">
                      <MessageText>
                        <FormattedMessage
                          {...messages.templateEmptyExercises}
                        />
                      </MessageText>
                      <Button
                        primary
                        rounded
                        onClick={() => onEditHandler(template)}
                      >
                        <Icon id="add-white" />
                        <FormattedMessage
                          {...messages.exercisesAddExercisesButton}
                        />
                      </Button>
                    </Message>
                  </CardBody>
                </Card>
              )}
            </div>
          </TabPanel>
        </Tabs>
      </PanelBody>
      {programModalData && (
        <AddProgramModal
          entityId={entityId}
          program={programModalData}
          destiny={{
            path: ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES,
            variables: {}
          }}
          onClose={() => setProgramModalData(null)}
          fromTemplate
          template={programModalData.template}
        />
      )}
    </Panel>
  );
};

export default observer(Template);
