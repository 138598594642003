import React, { useState, createRef, useContext, useEffect } from 'react';
import { Card, CardBody } from 'components/card';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import classNames from 'classnames';
import FormEditHeaderButtons from '../buttons/FormEditHeaderButtons';
import ScreeningForm from 'containers/pages/rehab/screening/ScreeningForm';
import { StoreContext } from 'index';
import { reorder } from 'utils/array';
import { TestListItem } from 'containers/partials/list-items/TestListItem';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import ScreeningFormDetails from 'containers/pages/rehab/screening/ScreeningFormDetails';
import { TestSetV2Type } from 'constants.js';
import { useSessionContext } from 'contexts/SessionContext';
import { useTestsContext } from 'contexts/TestsContext';
import { useTestSetContext } from 'contexts/TestSetContext';

function ScreeningCustomDetailCard({ personInjury, edit: initialEdit }) {
  const {
    authStore: { entityId }
  } = useContext(StoreContext);
  const { session: screening } = useSessionContext();
  const { testsState, testActions } = useTestsContext();
  const { actions, state } = useTestSetContext();

  const [edit, setEdit] = useState(initialEdit);
  const [testSet, setTestSet] = useState();
  const [testSetTests, setTestSetTests] = useState([]);
  const [testIds, setTestIds] = useState([]);
  const [testEstimate, setTestEstimate] = useState('');
  const screeningFormRef = createRef();

  useEffect(() => {
    testActions.getTests(TestSetV2Type.REHAB);
  }, []);

  useEffect(() => {
    testActions.setTests([]);
    testActions.updateTests([]);

    if (state.testSet) {
      setTestSet(state.testSet);

      testActions.setTests(state.testSet.tests);
      if (testsState.tests.length > 0) {
        testActions.updateTests(state.testSet.tests);
      }
    }
  }, [screening.id, state.testSet]);

  useEffect(() => {
    const estimate = testSetTests.reduce((acc, current) => {
      return acc + current.duration;
    }, 0);
    setTestEstimate(estimate);
  }, [testSetTests]);

  const onTestRemoveCheckHandler = test => {
    // get the dependencies
    const dependencies = testSetTests.filter(ts =>
      test.dependedTestIds?.includes(ts.id)
    );
    const tests = testSetTests.filter(
      ts => ts.id !== test.id && !test.dependedTestIds?.includes(ts.id)
    );
    setTestSetTests(tests);
    setTestIds(tests.map(t => t.id));
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    const reorderdTestSet = reorder(
      testSetTests,
      source.index,
      destination.index
    );
    const testSetIds = reorderdTestSet.map(test => test.id);

    setTestSetTests(reorderdTestSet);
    setTestIds(testSetIds);
  };

  return (
    <ScreeningForm
      submitRef={screeningFormRef}
      entityId={entityId}
      screening={screening}
      personInjury={personInjury}
      edit={edit}
      testEstimate={testEstimate}
      customizable={false}
    >
      {({ isSubmitting }) => (
        <>
          <Card secondaryCollapsible style={{ flex: 0 }}>
            <CardHeader secondary>
              <CardHeaderTitle>
                <FormattedMessage {...messages.titleScreening} />
              </CardHeaderTitle>
              <FormEditHeaderButtons
                edit={edit}
                setEdit={setEdit}
                type={'submit'}
                disabledSubmit={isSubmitting}
              />
            </CardHeader>
            <CardBody secondary extraClassNames={'u-margin-top-small'}>
              <ScreeningFormDetails
                entityId={entityId}
                personInjury={personInjury}
                edit
              />
            </CardBody>
          </Card>
          <Card secondary>
            <CardHeader secondary>
              <div className="o-flex o-flex--justify-space-between u-margin-bottom-none">
                <CardHeaderTitle>
                  <FormattedMessage {...messages.titleScreeningTests} />
                </CardHeaderTitle>
                <FormEditHeaderButtons
                  edit={edit}
                  setEdit={setEdit}
                  type={'submit'}
                  disabledSubmit={isSubmitting}
                />
              </div>
            </CardHeader>
            <CardBody secondary>
              <DragDropContext onDragEnd={result => onDragEnd(result)}>
                <Droppable droppableId="exercises">
                  {provided => (
                    <List
                      dragNDropWithPreview={edit}
                      // threeColsAndDropdown
                      preview={!edit}
                      style={{
                        minWidth: '0'
                      }}
                    >
                      <ListBody
                        {...provided.droppableProps}
                        innerRef={provided.innerRef}
                      >
                        {testSetTests.map((test, index) => (
                          <Draggable
                            isDragDisabled={!edit}
                            key={test.id}
                            draggableId={test.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <TestListItem
                                uid={test.id}
                                key={test.id}
                                test={{
                                  ...test,
                                  video: test.video_link
                                    ? `${test.video_link}-320.mp4`
                                    : null
                                }}
                                checkbox={edit}
                                edit={edit}
                                dependencyEditable={true}
                                checked={true}
                                onChange={() => onTestRemoveCheckHandler(test)}
                                extraClassNames={classNames('', {
                                  'c-list__item-is-dragging':
                                    snapshot.isDragging
                                })}
                                innerRef={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              />
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </ListBody>
                    </List>
                  )}
                </Droppable>
              </DragDropContext>
            </CardBody>
          </Card>
        </>
      )}
    </ScreeningForm>
  );
}

export default ScreeningCustomDetailCard;
