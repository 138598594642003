import React, { useEffect, useState } from 'react';
import Icon from 'components/icon/Icon';
import { Button, ButtonsGroup } from 'components/button';
import { Card, CardBody } from 'components/card';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { useExercisesContext } from 'contexts/ExercisesContext';
import { useFormikContext } from 'formik';
import FormEditFooterButtons from '../buttons/FormEditFooterButtons';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import SortableList from 'components/dndkit/SortableList';
import ExercisesListSortable from 'containers/partials/lists/ExercisesListSortable';
import { sortByArray } from 'utils/sort';
import Template from 'models/Template';

const SelectedExercisesCard = ({
  edit,
  onEdit,
  onPreview,
  onUse,
  noteType
}) => {
  const [draggable, setDraggable] = useState(false);
  const [exercisesSortOrder, setExercisesSortOrder] = useState([]);
  const { exercisesState, exerciseActions, loading } = useExercisesContext();
  const { submitForm, values: exerciseGroup, resetForm } = useFormikContext();

  const [exercises, setExercises] = useState([]);

  useEffect(() => {
    if (exercisesState.selectedExercises) {
      setExercises([...exercisesState.selectedExercises]);
    }
  }, [exercisesState.selectedExercises]);

  useEffect(() => {
    if (edit) {
      setDraggable(false);
    }
  }, [edit]);

  const submitExercisesSorting = async () => {
    if (exercisesSortOrder.length === 0) {
      setDraggable(false);
      return;
    }
    exerciseActions.setSelectedExercises(
      sortByArray(exercisesState.selectedExercises, exercisesSortOrder)
    );

    await submitForm();
    setDraggable(false);
  };

  const onCancelHandler = () => {
    resetForm();
    setDraggable(false);
    setExercises([...exerciseGroup.exercises]);
    exerciseActions.setSelectedExercises(exerciseGroup.exercises);
  };

  return (
    <Card
      secondary
      empty={exercises.length <= 0}
      style={exercises.length <= 0 ? { flex: 1 } : {}}
    >
      <CardHeader secondary>
        <div className="o-flex o-flex--wrap o-flex--justify-space-between u-margin-bottom-none">
          <CardHeaderTitle>
            <FormattedMessage {...messages.titleScreeningExercises} />
          </CardHeaderTitle>
          <ButtonsGroup extraClassNames="o-flex--wrap o-flex--justify-end u-margin-top-none u-margin-left-small">
            {draggable ? (
              <Button tiny disabled>
                <FormattedMessage
                  {...messages.enableAddRemoveExercisesButton}
                />
              </Button>
            ) : (
              <Button tiny onClick={() => onEdit(!edit)}>
                <FormattedMessage
                  {...messages.enableAddRemoveExercisesButton}
                />
              </Button>
            )}
            {edit ? (
              <Button tiny extraClassNames="u-margin-left-medium" disabled>
                <FormattedMessage {...messages.enableSortExercisesButton} />
              </Button>
            ) : (
              <Button
                tiny
                extraClassNames="u-margin-left-medium"
                onClick={() => setDraggable(!draggable)}
              >
                <FormattedMessage {...messages.enableSortExercisesButton} />
              </Button>
            )}
          </ButtonsGroup>
        </div>
      </CardHeader>

      {exercises.length > 0 ? (
        <>
          <CardBody secondary>
            <SortableList
              items={exercises}
              onSort={exercises => setExercisesSortOrder(exercises)}
            >
              {({ items }) => (
                <ExercisesListSortable
                  exercises={items}
                  exerciseGroup={exerciseGroup}
                  draggable={draggable}
                  checkable={edit}
                  enablePreviewModal={true}
                  showTemplateCount={false}
                  onPreview={onPreview}
                  showNote
                  noteType={noteType}
                  editableNotes
                  selectedExercises={exercisesState.selectedExercises.map(
                    e => e.id
                  )}
                  onSelect={(exerciseIds, exercise, checked) => {
                    if (!edit) return;
                    if (checked) {
                      exerciseActions.selectExercises([exercise]);
                    } else {
                      exerciseActions.unselectExercises([exercise]);
                    }
                  }}
                />
              )}
            </SortableList>
            {draggable && !edit && (
              <ButtonsGroup extraClassNames="u-margin-left-auto u-margin-top-medium">
                <Button secondary onClick={() => onCancelHandler()}>
                  <FormattedMessage {...messages.cancelButton} />
                </Button>
                <Button
                  type="button"
                  primary
                  disabled={loading} //|| processing
                  onClick={() => submitExercisesSorting()}
                >
                  <FormattedMessage {...messages.saveButton} />
                </Button>
              </ButtonsGroup>
            )}
          </CardBody>
          {edit && !draggable && (
            <FormEditFooterButtons
              edit={edit}
              setEdit={onEdit}
              onCancel={() => {
                onCancelHandler();
              }}
              onSubmit={submitForm}
            />
          )}
          {onUse && !edit && !draggable && (
            <div className="o-flex o-flex--justify-end">
              <Button
                small
                ghost
                onClick={onUse}
                // disabled={draggable || edit}
              >
                <Icon id="use-template" />
                {exerciseGroup instanceof Template ? (
                  <>
                    <FormattedMessage {...messages.buttonSaveAsProgram} />
                  </>
                ) : (
                  <FormattedMessage
                    {...messages.buttonScreeningSaveAsTemplate}
                  />
                )}
              </Button>
            </div>
          )}
        </>
      ) : (
        <CardBody empty>
          <Message emptyState={true} icon="cursor">
            <MessageText>
              <FormattedMessage
                {...messages.exercisesEmptyPanelInThis}
                values={{ this: exerciseGroup.title }}
              />
            </MessageText>
          </Message>
        </CardBody>
      )}
    </Card>
  );
};

export default SelectedExercisesCard;
