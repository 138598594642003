import React, { useContext } from 'react';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, CardBody } from 'components/card';
import FormEditHeaderButtons from '../buttons/FormEditHeaderButtons';
import { StoreContext } from 'index';
import { useFormikContext } from 'formik';
import PreventionDetail from 'containers/pages/prevention/PreventionDetail';
import messages from 'messages';

function PreventionDetailCard({ edit, onEdit }) {
  const {
    authStore: { entityId }
  } = useContext(StoreContext);
  const intl = useIntl();
  const {
    values: session,
    submitForm,
    setFieldValue,
    resetForm
  } = useFormikContext();

  return (
    <>
      <Card secondaryCollapsible style={{ flex: 0 }}>
        <CardHeader secondary>
          <CardHeaderTitle>
            <FormattedMessage {...messages.titleSessionDetails} />
          </CardHeaderTitle>
          <FormEditHeaderButtons
            edit={edit}
            setEdit={onEdit}
            onCancel={resetForm}
            onEdit={onEdit}
            onSubmit={submitForm}
            type={'button'}
          />
        </CardHeader>

        <CardBody secondary>
          <PreventionDetail
            entityId={entityId}
            edit={edit}
            onEdit={() => onEdit(true)}
          />
        </CardBody>
      </Card>
    </>
  );
}

export default PreventionDetailCard;
