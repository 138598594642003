export const cleanFalsy = obj =>
  Object.entries(obj).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {});

export const cleanNullUndefined = obj =>
  Object.entries(obj).reduce(
    (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
    {}
  );

export const cleanNull = obj =>
  Object.entries(obj).reduce(
    (a, [k, v]) => (v === null ? a : ((a[k] = v), a)),
    {}
  );

export const cleanUndefined = obj =>
  Object.entries(obj).reduce(
    (a, [k, v]) => (v === undefined ? a : ((a[k] = v), a)),
    {}
  );

export const sortByKey = unordered =>
  Object.keys(unordered)
    .sort()
    .reduce((obj, key) => {
      obj[key] = unordered[key];
      return obj;
    }, {});
