import { BenchmarkFilters } from 'constants.js';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import { round } from 'utils/math';

export const getBenchmarkColData = (sessions, benchmarkFilter, laterality) => {
  if (!sessions) return {};

  switch (benchmarkFilter) {
    case BenchmarkFilters.LAST:
      return getAthleteLastSession(sessions);
    case BenchmarkFilters.BEST:
      return getAthleteBestSession(sessions, laterality);
    case BenchmarkFilters.AVERAGE:
      return getAthleteAverageSession(sessions, laterality);
    default:
  }
};

const getAthleteLastSession = sessions => {
  const sortedSessions = sort(sessions, {
    keys: [{ key: 'sessionDate', desc: true, dataType: SORT_DATA_TYPES.NUMBER }]
  });
  if (sortedSessions.length > 0) {
    return { ...sortedSessions[0] };
  }
};

const getAthleteBestSession = (sessions, laterality) => {
  if (sessions.length > 0) {
    const bestSession = sessions.reduce(function (prevSession, currentSession) {
      if (currentSession?.best_value === 1) {
        return currentSession?.result?.[laterality]?.[0] &&
          prevSession?.result?.[laterality]?.[0] &&
          Number(prevSession.result?.[laterality]?.[0]) >
            Number(currentSession.result?.[laterality]?.[0])
          ? prevSession
          : currentSession;
      } else {
        return currentSession?.result?.[laterality]?.[0] &&
          prevSession?.result?.[laterality]?.[0] &&
          Number(prevSession.result?.[laterality]?.[0]) <
            Number(currentSession.result?.[laterality]?.[0])
          ? prevSession
          : currentSession;
      }
    });
    return { ...bestSession };
  }
};

const getAthleteAverageSession = (sessions, laterality) => {
  if (sessions.length > 0) {
    //const averageTestItemResult = new TestItemResult({});

    const result = sessions.reduce(
      (item, currentValue) => {
        const percentage = currentValue?.benchmarked?.[laterality]?.percentage;
        const result = currentValue?.result?.[laterality]?.[0];

        if (result) {
          item.count += 1;
          item.percentage += percentage ? percentage : null;
          item.score += Number(result);

          if (result < item.minScore) item.minScore = Number(result);
          if (result > item.maxScore) item.maxScore = Number(result);
        }

        return item;
      },
      {
        name: '',
        count: 0,
        percentage: null,
        score: 0,
        minScore: Number.POSITIVE_INFINITY,
        maxScore: 0,

        avgscore: 0,
        avgzscore: 0,
        avgpercentile: 0
      }
    );

    return {
      ...sessions.find(s => s.hasValues),
      result: result.score
        ? { [laterality]: [round(result.score / result.count)] }
        : null,
      benchmarked: {
        [laterality]: {
          percentage: result.percentage
            ? result.percentage / result.count
            : null
        }
      }
    };
  }
};

export const getAverageOfGroup = (rows, testId, index, laterality) => {
  const average = Object.values(rows).reduce(
    (item, currentValue) => {
      const session = currentValue[index];
      if (session) {
        const result = session?.result?.[laterality]?.[0];

        if (result) {
          item.count += 1;
          item.score += Number(result);

          if (result < item.minScore) item.minScore = Number(result);
          if (result > item.maxScore) item.maxScore = Number(result);
        }
      }
      return item;
    },
    {
      count: 0,
      percentage: 0,
      score: 0,
      minScore: Number.POSITIVE_INFINITY,
      maxScore: 0
    }
  );

  return {
    ...average,
    avg: average.score / average.count
  };
};

export const getDataRows = ({ athletes, testCols, benchmarkFilter }) => {
  const rows = [];
  athletes.forEach(athlete => {
    let row = [];
    testCols.forEach(col => {
      let athleteCol = {};
      if (col.id) {
        const [id, laterality] = col.id.split('_');
        const sessions = athlete.results[id]?.filter(s => s && s.result); // Filter out sessions without results;
        athleteCol = getBenchmarkColData(sessions, benchmarkFilter, laterality);
      }
      row.push(athleteCol);
    });
    rows[athlete.id] = row;
  });
  return rows;
};
