import React from 'react';
import { PanelHeader, PanelTitle, PanelTitleWrapper } from 'components/panel';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import Panel from 'components/panel/Panel';
import ListItem from 'components/list/list-item/ListItem';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import ListItemStatus from 'components/list/list-item/ListItemStatus';
import ListItemLabelWrapper from 'components/list/list-item/ListItemLabelWrapper';
import ListItemLabel from 'components/list/list-item/ListItemLabel';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import {
  ROUTE_TEST_ENTITY_ANALYSE_PYTHON,
  ROUTE_TEST_ENTITY_EXERCISES,
  ROUTE_TEST_ENTITY_EXERCISES_EXERCISE,
  ROUTE_TEST_ENTITY_UPLOAD
} from 'routes/RouteList';

function TestApp({ entityId, type }) {
  const { push } = useHistory();
  const isUploadView = useRouteMatch([ROUTE_TEST_ENTITY_UPLOAD]);
  const isExercisesView = useRouteMatch([
    ROUTE_TEST_ENTITY_EXERCISES,
    ROUTE_TEST_ENTITY_EXERCISES_EXERCISE
  ]);

  return (
    <Panel>
      <PanelHeader>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.settingsTitle} />
          </PanelTitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <List>
        <ListBody>
          <ListItem
            onClick={() =>
              push(generatePath(ROUTE_TEST_ENTITY_UPLOAD, { entityId }))
            }
            active={!!isUploadView}
          >
            <ListItemStatus statusIcon="video" />
            <ListItemLabelWrapper>
              <ListItemLabel label>Upload video</ListItemLabel>
            </ListItemLabelWrapper>
          </ListItem>
          {/*  <ListItem
            onClick={() =>
              push(generatePath(ROUTE_TEST_ENTITY_EXERCISES, { entityId }))
            }
            active={!!isExercisesView}
          >
            <ListItemStatus statusIcon="exercise" />
            <ListItemLabelWrapper>
              <ListItemLabel label>Exercises</ListItemLabel>
            </ListItemLabelWrapper>
          </ListItem>
          <ListItem
            onClick={() =>
              push(
                generatePath(ROUTE_TEST_ENTITY_ANALYSE, {
                  entityId,
                  type: 'video'
                })
              )
            }
            active={type === 'video'}
          >
            <ListItemStatus statusIcon="video" />
            <ListItemLabelWrapper>
              <ListItemLabel label>Start video analyse</ListItemLabel>
            </ListItemLabelWrapper>
          </ListItem>*/}
          <ListItem
            onClick={() =>
              push(
                generatePath(ROUTE_TEST_ENTITY_ANALYSE_PYTHON, {
                  entityId,
                  type: 'python'
                })
              )
            }
            active={type === 'video'}
          >
            <ListItemStatus statusIcon="video" />
            <ListItemLabelWrapper>
              <ListItemLabel label>Analyse</ListItemLabel>
            </ListItemLabelWrapper>
          </ListItem>
          {/*<ListItem
            onClick={() =>
              push(
                generatePath(ROUTE_TEST_ENTITY_ANALYSE, {
                  entityId,
                  type: 'webcam'
                })
              )
            }
            active={type === 'webcam'}
          >
            <ListItemStatus statusIcon="video" />
            <ListItemLabelWrapper>
              <ListItemLabel label>Start webcam analyse</ListItemLabel>
            </ListItemLabelWrapper>
          </ListItem>*/}
        </ListBody>
      </List>
    </Panel>
  );
}

export default TestApp;
