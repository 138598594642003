import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@apollo/client';
import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { CardBody, CardFooter } from 'components/card';
import messages from 'messages';
import { Button, ButtonsGroup } from 'components/button';
import {
  MUTATION_ADD_PERSONS_TO_PROGRAM,
  QUERY_GET_PROGRAM
} from 'services/aws/programs-query';
import SelectPersonsCard from 'containers/partials/cards/SelectPersonsCard';

function AssignSportersToProgramModal({
  entityId,
  program,
  onClose,
  onDone,
  excludedPersons
}) {
  const [selectedPersons, setSelectedPersons] = useState([]);

  const [assignPersons, { loading }] = useMutation(
    MUTATION_ADD_PERSONS_TO_PROGRAM
  );

  const onAssignPersonsHandler = async persons => {
    setSelectedPersons(persons);
  };

  const onAddPersonsHandler = async persons => {
    if (program.id) {
      try {
        await assignPersons({
          variables: {
            exerciseProgramId: program.id,
            personIds: persons.map(person => person.id)
          },
          refetchQueries: [
            { query: QUERY_GET_PROGRAM, variables: { id: program.id } }
          ]
        });
        if (onDone) onDone();
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  };

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal
      card
      isOpen={true}
      onClose={onCloseHandler}
      extraClassNames={'c-card-modal__content-body-scroll'}
    >
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.programDetailSelectAthletes} />
        </CardHeaderTitle>
      </CardHeader>

      <CardBody modal bodyWithHeader>
        <SelectPersonsCard
          rootEntityId={entityId}
          onSelectPersons={persons => onAssignPersonsHandler(persons)}
          excludedPersons={excludedPersons}
          selectedPersons={selectedPersons}
          onModal
        />
      </CardBody>
      <CardFooter modal extraClassNames="c-card__footer--modal-larger">
        <ButtonsGroup>
          <Button secondary onClick={() => onCloseHandler()}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button
            primary
            disabled={selectedPersons.length === 0 || loading}
            onClick={() => onAddPersonsHandler(selectedPersons)}
          >
            <FormattedMessage {...messages.addButton} />
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
}

export default AssignSportersToProgramModal;
