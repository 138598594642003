import React, { useState } from 'react';
import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import messages from 'messages';
import { FormattedMessage } from 'react-intl';
import { CardBody, CardFooter } from 'components/card';
import { Button, ButtonsGroup } from 'components/button';
import TestSetDetail from './TestSetDetail';
import TestSetForm from './TestSetForm';
import PreventionDetailExtras from 'containers/pages/prevention/PreventionDetailExtras';

function AddTemplateModal({ entityId, onClose }) {
  const [edit, setEdit] = useState(true);

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal card autoHeight isOpen={true} onClose={onCloseHandler}>
      <TestSetForm entityId={entityId} testSet={null}>
        <>
          <CardHeader modal>
            <CardHeaderTitle>
              <FormattedMessage
                {...messages.addTemplateTitle}
                values={{ fromProgram: false }}
              />
            </CardHeaderTitle>
          </CardHeader>
          <CardBody modalWithOverflow>
            <TestSetDetail edit={edit} onEdit={() => setEdit(true)} />
          </CardBody>

          <CardBody modalWithOverflow>
            <PreventionDetailExtras entityId={entityId} edit={true} />
          </CardBody>
          <CardFooter modal extraClassNames="c-card__footer--modal-larger">
            <ButtonsGroup>
              <Button type="button" secondary onClick={() => onCloseHandler()}>
                <FormattedMessage {...messages.cancelButton} />
              </Button>
              <Button type="submit" primary>
                <FormattedMessage {...messages.saveButton} />
              </Button>
            </ButtonsGroup>
          </CardFooter>
        </>
      </TestSetForm>
    </Modal>
  );
}

export default AddTemplateModal;
