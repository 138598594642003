import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import {
  withRouter,
  Route,
  Switch,
  Redirect,
  generatePath
} from 'react-router-dom';
import Overview from './Overview';
import AddSporter from './AddSporter';
import {
  ROUTE_SPORTERS,
  ROUTE_SPORTERS_ENTITY_ADD,
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SPORTERS_ENTITY_POOL,
  ROUTE_SPORTERS_ENTITY_ADD_GROUP,
  ROUTE_SPORTERS_ENTITY_EDIT_GROUP,
  ROUTE_SPORTERS_ENTITY_SPORTER,
  ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL,
  ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ADD,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RETEST,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORTS,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RESULTS,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RESULTS_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL,
  ROUTE_SPORTERS_ENTITY_SPORTER_VIDEOS,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_EXERCISE,
  ROUTE_SPORTERS_ENTITY_SPORTER_GROUPS,
  ROUTE_SPORTERS_ENTITY_SPORTER_DOCS,
  ROUTE_SPORTERS_ENTITY_SPORTER_EDIT,
  ROUTE_SESSIONS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL_EDIT
} from 'routes/RouteList';
import Panel from 'components/panel/Panel';
import Sporter from './Sporter';
import SporterSession from 'containers/pages/sporters/SporterSession';
import messages from 'messages';
import Injury from 'containers/pages/rehab/injury/Injury';
import Screening from 'containers/pages/rehab/screening/Screening';
import { PersonContextProvider } from 'contexts/PersonContext';
import { StoreContext } from 'index';
import ScheduleReTestScreening from 'containers/pages/rehab/screening/ScheduleReTestScreening';
import { MedicalContextProvider } from 'contexts/MedicalContext';
import { SessionContextProvider } from 'contexts/SessionContext';
import { PreviewModalProvider } from 'contexts/PreviewModalContext';
import Group from 'containers/pages/sporters/Group';
import { GroupsContextProvider } from 'contexts/GroupsContext';
import { EditableContextProvider } from 'contexts/EditableContext';
import SporterProgramExercises from 'containers/pages/sporters/SporterProgramExercises';
import { ProgramProvider } from 'contexts/ProgramContext';
import SporterProgramExercise from 'containers/pages/sporters/SporterProgramExercise';
import { MenuContextProvider } from 'contexts/MenuContext';
import NoAccessPanel from 'containers/pages/auth/NoAccessPanel';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import Illness from 'containers/pages/rehab/illness/Illness';
import Issue from 'containers/pages/rehab/issue/Issue';

const Sporters = () => {
  const ability = useAbility(AbilityContext);
  const {
    authStore: {
      user,
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const intl = useIntl();

  if (ability.cannot('read', 'Athletes')) {
    return (
      <NoAccessPanel
        entityId={user.rootEntityId}
        emptyLabel={intl.formatMessage(messages.noAccessEmptyPanel)}
        redirectRoute={generatePath(ROUTE_SESSIONS_ENTITY, {
          entityId: user.rootEntityId
        })}
      />
    );
  }

  return (
    <PersonContextProvider>
      <MedicalContextProvider rootEntityId={rootEntityId}>
        <EditableContextProvider>
          <Switch>
            <Route
              exact
              path={[
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORTS,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RESULTS,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RESULTS_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY
              ]}
              render={() => <Injury edit={true} tabIndex={1} active={false} />}
            />

            <Route
              exact
              path={
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RETEST
              }
              render={() => <ScheduleReTestScreening />}
            />

            <Route
              exact
              path={[
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS,
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ADD,
                ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM,
                ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK
              ]}
              render={() => (
                <MenuContextProvider>
                  <Sporter active={false} />
                </MenuContextProvider>
              )}
            />
            <Route
              path={[
                ROUTE_SPORTERS_ENTITY_SPORTER,
                ROUTE_SPORTERS_ENTITY_POOL,
                ROUTE_SPORTERS_ENTITY
              ]}
              render={props => (
                <MenuContextProvider>
                  <GroupsContextProvider
                    entityId={props.match.params.entityId}
                    rootEntityId={user.rootEntityId}
                  >
                    <Overview active={false} />
                  </GroupsContextProvider>
                </MenuContextProvider>
              )}
            />
            <Redirect to={`${ROUTE_SPORTERS}/${user.rootEntityId}`} />
          </Switch>
          <Route
            exact
            path={ROUTE_SPORTERS_ENTITY}
            render={() => (
              <Panel
                emptyState
                emptyLabel={intl.formatMessage(messages.panelSportersEmpty)}
              />
            )}
          />
          <Switch>
            <Route
              path={ROUTE_SPORTERS_ENTITY_ADD}
              render={({ match }) => (
                <MenuContextProvider>
                  <AddSporter
                    active={ROUTE_SPORTERS_ENTITY_ADD === match.path}
                  />
                </MenuContextProvider>
              )}
            />
            <Route
              path={ROUTE_SPORTERS_ENTITY_EDIT_GROUP}
              render={({ match }) => (
                <Group
                  mode={'edit'}
                  active={ROUTE_SPORTERS_ENTITY_EDIT_GROUP === match.path}
                />
              )}
            />
            <Route
              path={ROUTE_SPORTERS_ENTITY_ADD_GROUP}
              render={({ match }) => (
                <Group
                  mode={'add'}
                  active={ROUTE_SPORTERS_ENTITY_ADD_GROUP === match.path}
                />
              )}
            />
            <Route
              path={ROUTE_SPORTERS_ENTITY_POOL}
              render={() => (
                <Panel
                  emptyState
                  emptyLabel={intl.formatMessage(messages.panelSportersEmpty)}
                />
              )}
            />
            <Switch>
              <Route
                exact
                path={[
                  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL_EDIT,
                  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL,
                  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS,
                  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ADD
                ]}
                render={({ match }) => (
                  <Injury
                    edit={
                      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL_EDIT ===
                      match.path
                    }
                    active
                  />
                )}
              />
              <Route
                exact
                path={[
                  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL,
                  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL_EDIT
                ]}
                render={({ match }) => (
                  <Illness
                    edit={
                      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL_EDIT ===
                      match.path
                    }
                    active
                  />
                )}
              />
              <Route
                exact
                path={[
                  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL,
                  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL_EDIT
                ]}
                render={({ match }) => (
                  <Issue
                    edit={
                      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL_EDIT ===
                      match.path
                    }
                    active
                  />
                )}
              />
              <Route
                exact
                path={[
                  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY,
                  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORTS,
                  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RESULTS,
                  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RESULTS_EDIT
                ]}
                render={({ match }) => (
                  <PreviewModalProvider entityId={user.rootEntityId}>
                    <SessionContextProvider>
                      <Screening
                        active
                        edit={
                          ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RESULTS_EDIT ===
                          match.path
                        }
                      />
                    </SessionContextProvider>
                  </PreviewModalProvider>
                )}
              />
              <Route
                exact
                path={
                  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_EXERCISE
                }
                render={() => (
                  <ProgramProvider user={user}>
                    <SporterProgramExercises />
                  </ProgramProvider>
                )}
              />
              <Route
                exact
                path={[
                  ROUTE_SPORTERS_ENTITY_SPORTER,
                  ROUTE_SPORTERS_ENTITY_SPORTER_EDIT,
                  ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL,
                  ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL_EDIT,
                  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION,
                  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL,
                  ROUTE_SPORTERS_ENTITY_SPORTER_VIDEOS,
                  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS,
                  ROUTE_SPORTERS_ENTITY_SPORTER_GROUPS,
                  ROUTE_SPORTERS_ENTITY_SPORTER_DOCS
                ]}
                render={({ match }) => (
                  <MenuContextProvider>
                    <SessionContextProvider>
                      <Sporter
                        active={
                          ROUTE_SPORTERS_ENTITY_SPORTER === match.path ||
                          ROUTE_SPORTERS_ENTITY_SPORTER_EDIT === match.path ||
                          ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL === match.path ||
                          ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL_EDIT ===
                            match.path ||
                          ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL ===
                            match.path ||
                          ROUTE_SPORTERS_ENTITY_SPORTER_VIDEOS === match.path ||
                          ROUTE_SPORTERS_ENTITY_SPORTER === match.path ||
                          ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL === match.path ||
                          ROUTE_SPORTERS_ENTITY_SPORTER === match.path ||
                          ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL === match.path ||
                          ROUTE_SPORTERS_ENTITY_SPORTER === match.path ||
                          ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL === match.path
                        }
                        edit={true}
                      />
                    </SessionContextProvider>
                  </MenuContextProvider>
                )}
              />
            </Switch>
          </Switch>
          <Switch>
            <Route
              exact
              path={[
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS,
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_EDIT
              ]}
              render={({ match }) => (
                <SessionContextProvider>
                  <PreviewModalProvider entityId={user.rootEntityId}>
                    <SporterSession
                      active={
                        ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION ===
                        match.path
                      }
                      edit={
                        ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_EDIT ===
                        match.path
                      }
                      tab={'tests'}
                    />
                  </PreviewModalProvider>
                </SessionContextProvider>
              )}
            />
            <Route
              path={ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT}
              exact
              render={({ match }) => (
                <SessionContextProvider>
                  <PreviewModalProvider entityId={user.rootEntityId}>
                    <SporterSession
                      active={
                        ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION ===
                        match.path
                      }
                      edit={false}
                      tab={'report'}
                    />
                  </PreviewModalProvider>
                </SessionContextProvider>
              )}
            />
            <Route
              path={[
                ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM,
                ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK
              ]}
              exact
              render={() => (
                <ProgramProvider user={user}>
                  <SporterProgramExercises />
                </ProgramProvider>
              )}
            />
            <Route
              path={[
                ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_EXERCISE
              ]}
              exact
              render={() => (
                <ProgramProvider user={user}>
                  <SporterProgramExercise edit={false} />
                </ProgramProvider>
              )}
            />
            {/*<Redirect
              to={ROUTE_SPORTERS_ENTITY.replace(':entityId', user.rootEntityId)}
            />*/}
          </Switch>
        </EditableContextProvider>
      </MedicalContextProvider>
    </PersonContextProvider>
  );
};

export default withRouter(observer(Sporters));
