import React from 'react';
import classNames from 'classnames';

const ProgressBarScale = props => {
  return (
    <div className="c-legend">
      <div className={classNames('c-legend__wrapper')}>
        {props.values ? (
          props.values.map(val => (
            <div
              className={classNames(`c-legend__bar u-color--${val.color}`)}
              key={val.color}
            >
              <span className="c-legend__label">
                {typeof val !== 'object' ? val : val.label}
              </span>
            </div>
          ))
        ) : (
          <React.Fragment>
            <div className="c-legend__bar">
              <span className="c-legend__label">{props.value1}</span>
            </div>
            <div className="c-legend__bar">
              <span className="c-legend__label">{props.value2}</span>
            </div>
            <div className="c-legend__bar">
              <span className="c-legend__label">{props.value3}</span>
            </div>
            <div className="c-legend__bar">
              <span className="c-legend__label">{props.value4}</span>
            </div>
            <div className="c-legend__bar">
              <span className="c-legend__label">{props.value5}</span>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ProgressBarScale;
