import React, { Component } from 'react';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow
} from 'components/table';
import GrowthPredictionTableRowWaiting from './GrowthPredictionTableRowWaiting';

export default class GrowthPredictionTableWaiting extends Component {
  render() {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell />
            {Array(3)
              .fill()
              .map((v, i) => (
                <TableHeaderCell key={i} />
              ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {Array(8)
            .fill()
            .map((row, i) => (
              <GrowthPredictionTableRowWaiting key={i} />
            ))}
        </TableBody>
      </Table>
    );
  }
}
