import { useRef, useState } from 'react';
import classNames from 'classnames';
import Icon from 'components/icon/Icon';
import { usePopper } from 'react-popper';
import { Portal } from 'components/Portal/Portal';

import './Tooltip.scss';
import { useTogglerContext } from 'contexts/TogglerContext';

const ToggletipPopper = props => {
  const { isOpen, setOpen } = useTogglerContext();

  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();

  const {
    id,
    children,
    extraClassNames,
    icon,
    info,
    mediumIcon,
    alert,
    video,
    videoMessage,
    label,
    stylesLabel,
    iconStrokeColor,
    tabIndex
  } = props;

  const open = isOpen === id;

  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-end',
    strategy: 'absolute',

    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8]
        }
      },
      {
        name: 'flip',
        options: {
          flipVariations: true,
          padding: { bottom: 24, top: 224 },
          fallbackPlacements: ['bottom-end']
        }
      }
    ]
  });

  return (
    <div
      className={classNames('c-tooltip c-tooltip--popper', extraClassNames, {
        'c-tooltip--alert': alert,
        'c-tooltip--info': info,
        'c-tooltip--video': video,
        'c-tooltip--label': label?.length > 0
      })}
    >
      <button
        className={classNames('c-button c-button--tiny c-tooltip__trigger', {
          'c-button--disabled': props.disabled || props.pseudoDisabled, // ok
          'c-tooltip__trigger--active': open
        })}
        type={'button'}
        aria-labelledby={id?.length && !video ? id : `toggletip-label-${id}`}
        onClick={() => {
          open ? setOpen(false) : setOpen(id);
        }}
        ref={setReferenceElement}
        tabIndex={tabIndex}
      >
        {icon &&
          (!open ? (
            <Icon
              id={icon}
              mediumIcon={mediumIcon}
              strokeColor={
                iconStrokeColor
                  ? iconStrokeColor
                  : alert
                  ? 'color-error'
                  : false
              }
            />
          ) : (
            <Icon
              id={'close'}
              strokeColor={'color-neutral-dark'}
              style={{
                borderRadius: 16,
                padding: 2,
                strokeWidth: 2,
                border: '1px solid gray'
              }}
            />
          ))}
        {/* TODO: accessible info to toggletip when toggletip is not open? */}
        {label && (
          <span id={`toggletip-label-${id}`} style={stylesLabel}>
            {label}
          </span>
        )}
      </button>

      {open && (
        <Portal>
          <>
            {video && (
              <div
                className={classNames(
                  'c-tooltip__message c-tooltip-popper__message-video',
                  {
                    'c-tooltip-popper__message--hidden': !open
                  }
                )}
                role="tooltip"
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                {children}
                {videoMessage && (
                  <section className="c-tooltip__info">{videoMessage}</section>
                )}
              </div>
            )}
            {!video && (
              <p
                id={id}
                role="tooltip"
                className={classNames('c-toggletip-message', {
                  'c-toggletip-message--hidden': !open
                })}
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                {children}
              </p>
            )}
          </>
        </Portal>
      )}
    </div>
  );
};

export default ToggletipPopper;
