import React, { createContext, useReducer, useContext, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useLazyQuery } from '@apollo/client';
import { sort } from 'utils/sort';
import Video from 'models/Video';
import {
  QUERY_GET_VIDEOS,
  QUERY_GET_VIDEOS_SHARED_WITH
} from 'services/aws/videos-query';

export const VideosContext = createContext();

const initialState = {
  videos: [],
  sharedVideos: [],
  videosStore: [],
  sharedVideosStore: [],
  selectedVideos: [],
  unselectedVideos: [],
  filter: {
    title: '',
    user: '',
    status: [],
    dimensions: [],
    behaviours: []
  }
};

const getFilteredVideos = (videos, filter) => {
  if (videos.length === 0) return videos;

  let filtered = videos.reduce((acc, video) => {
    if (
      filter.user === '' ||
      video.person.firstname
        .toLowerCase()
        .includes(filter.user.toLowerCase()) ||
      video.person.lastname.toLowerCase().includes(filter.user.toLowerCase())
    ) {
      acc.push(video);
    }
    return acc;
  }, []);

  filtered = filtered.reduce((acc, video) => {
    if (
      filter.title === '' ||
      video.title.toLowerCase().includes(filter.title.toLowerCase())
    ) {
      acc.push(video);
    }
    return acc;
  }, []);

  filtered = filtered.reduce((acc, video) => {
    if (filter.dimensions.length > 0) {
      // if the video.annotations.tags array contains all the ids in the filter.tags array, then add the video to the acc array
      if (
        filter.dimensions.some(tag =>
          video.annotations.some(annotation => annotation.tags[0] === tag)
        )
      ) {
        if (!acc.find(v => v.id === video.id)) {
          acc.push(video);
        }
      }
    } else {
      acc.push(video);
    }
    return acc;
  }, []);

  filtered = filtered.reduce((acc, video) => {
    if (filter.behaviours.length > 0) {
      // if the video.annotations.tags array contains all the ids in the filter.tags array, then add the video to the acc array
      if (
        filter.behaviours.some(tag =>
          video.annotations.some(annotation => annotation.tags[1] === tag)
        )
      ) {
        if (!acc.find(v => v.id === video.id)) {
          acc.push(video);
        }
      }
    } else {
      acc.push(video);
    }
    return acc;
  }, []);

  filtered = filtered.reduce((acc, video) => {
    if (filter.status.length > 0) {
      if (filter.status.includes('SHARED')) {
        if (video.shares.length > 0) {
          if (!acc.find(v => v.id === video.id)) {
            acc.push(video);
          }
        }
      }
      if (filter.status.includes('COMPLETED')) {
        if (video.meta?.finished === true) {
          if (!acc.find(v => v.id === video.id)) {
            acc.push(video);
          }
        }
      }
      if (filter.status.includes('IN_PROGRESS')) {
        if (!video.meta?.finished) {
          if (!acc.find(v => v.id === video.id)) {
            acc.push(video);
          }
        }
      }
    } else {
      acc.push(video);
    }
    return acc;
  }, []);

  return filtered;
};

// Actions
export const SET_VIDEOS = 'SET_VIDEOS';
export const SET_SHARED_VIDEOS = 'SET_SHARED_VIDEOS';
export const SET_VIDEOS_STORE = 'SET_VIDEOS_STORE';
export const SET_SHARED_VIDEOS_STORE = 'SET_SHARED_VIDEOS_STORE';
export const SET_SELECTED_VIDEOS = 'SET_SELECTED_VIDEOS';

export const ADD_SELECTED_VIDEOS = 'ADD_SELECTED_VIDEOS';

export const SET_FILTER = 'SET_FILTER';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_VIDEOS:
      return {
        ...state,
        videos: sort(
          [...action.payload].map(e => new Video({ ...e })),
          {
            keys: [
              {
                key: 'title'
              }
            ]
          }
        )
      };
    case SET_SHARED_VIDEOS:
      return {
        ...state,
        sharedVideos: sort(
          [...action.payload].map(e => new Video({ ...e })),
          {
            keys: [
              {
                key: 'title'
              }
            ]
          }
        )
      };
    case SET_VIDEOS_STORE:
      return {
        ...state,
        videosStore: [...action.payload].map(e => new Video({ ...e }))
      };
    case SET_SHARED_VIDEOS_STORE:
      return {
        ...state,
        sharedVideosStore: [...action.payload].map(e => new Video({ ...e }))
      };
    case SET_SELECTED_VIDEOS:
      const unDoubled = [];
      action.payload.forEach(video => {
        if (!unDoubled.find(t => t.id === video.id)) {
          unDoubled.push(video);
        }
      });
      return { ...state, selectedVideos: [...unDoubled] };
    case ADD_SELECTED_VIDEOS:
      const videosNotInSelected = action.payload.filter(
        video => !state.selectedVideos.find(t => t.id === video.id)
      );
      return {
        ...state,
        selectedVideos: [...state.selectedVideos, ...videosNotInSelected]
      };
    case SET_FILTER:
      return { ...state, filter: { ...action.payload } };
    default:
      return state;
  }
};

const VideosProvider = ({ entityId, personId, children }) => {
  const [videosState, dispatch] = useReducer(reducer, initialState);

  const [
    getVideos,
    { loading: loadingVideos, error: errorVideos, data: dataVideos }
  ] = useLazyQuery(QUERY_GET_VIDEOS, {
    variables: { entityId },
    fetchPolicy: 'cache-and-network'
  });

  const [
    getSharedVideos,
    {
      loading: loadingSharedVideos,
      error: errorSharedVideos,
      data: dataSharedVideos
    }
  ] = useLazyQuery(QUERY_GET_VIDEOS_SHARED_WITH, {
    variables: { entityId, personId: personId },
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    const fetchData = async () => {
      await getVideos();
      await getSharedVideos();
    };
    if (entityId) {
      fetchData().catch(error => {
        Sentry.captureException(error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId]);

  useEffect(() => {
    videoActions.setVideos([]);
    if (dataVideos?.getExercises) {
      const videos = dataVideos.getExercises.map(e => new Video({ ...e }));
      videoActions.setVideos(videos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataVideos]);

  useEffect(() => {
    videoActions.setSharedVideos([]);
    if (dataSharedVideos?.getExercisesSharedWith) {
      const sharedVideos = dataSharedVideos.getExercisesSharedWith.map(
        e => new Video({ ...e })
      );
      videoActions.setSharedVideos(sharedVideos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSharedVideos]);

  useEffect(() => {
    if (videosState.filter) {
      const filteredVideos = getFilteredVideos(
        videosState.videosStore,
        videosState.filter
      );
      dispatch({ type: SET_VIDEOS, payload: filteredVideos });

      const filteredSharedVideos = getFilteredVideos(
        videosState.sharedVideosStore,
        videosState.filter
      );
      dispatch({ type: SET_SHARED_VIDEOS, payload: filteredSharedVideos });
    }
  }, [
    videosState.videosStore,
    videosState.sharedVideosStore,
    videosState.filter
  ]);

  const videoActions = {
    setAllVideos: videos => {
      dispatch({ type: SET_VIDEOS_STORE, payload: videos });
    },
    setVideos: videos => {
      if (!videos && dataVideos?.getExercises) {
        dispatch({ type: SET_VIDEOS, payload: dataVideos.getExercises });
        dispatch({ type: SET_VIDEOS_STORE, payload: dataVideos.getExercises });
      }
      if (videos) {
        dispatch({ type: SET_VIDEOS, payload: videos });
        dispatch({ type: SET_VIDEOS_STORE, payload: videos });

        dispatch({
          type: SET_SELECTED_VIDEOS,
          payload: [...videosState.selectedVideos]
        });
      }
    },
    setSharedVideos: videos => {
      if (!videos && dataSharedVideos?.getExercisesSharedWith) {
        dispatch({
          type: SET_SHARED_VIDEOS,
          payload: dataSharedVideos.getExercisesSharedWith
        });
        dispatch({
          type: SET_SHARED_VIDEOS_STORE,
          payload: dataSharedVideos.getExercisesSharedWith
        });
      }
      if (videos) {
        dispatch({ type: SET_SHARED_VIDEOS, payload: videos });
        dispatch({ type: SET_SHARED_VIDEOS_STORE, payload: videos });

        dispatch({
          type: SET_SELECTED_VIDEOS,
          payload: [...videosState.selectedVideos]
        });
      }
    },
    updateVideos: videos => {
      const videosInSelected = videos.filter(video =>
        videosState.selectedVideos.find(t => t.id === video.id)
      );

      // get all new videos
      const newVideos = videos.filter(
        video => ![...videosState.selectedVideos].find(t => t.id === video.id)
      );

      dispatch({
        type: SET_SELECTED_VIDEOS,
        payload: [...videosInSelected, ...newVideos]
      });
    },
    setSelectedVideos: videos => {
      if (videos) {
        dispatch({ type: SET_SELECTED_VIDEOS, payload: videos });

        return videos;
      }
    },
    selectVideos: videos => {
      if (videos) {
        dispatch({ type: ADD_SELECTED_VIDEOS, payload: videos });
      }
    },
    unselectVideos: videosToRemove => {
      if (videosToRemove) {
        const videos = videosState.selectedVideos.filter(
          video => !videosToRemove.find(t => t.id === video.id)
        );

        dispatch({ type: SET_SELECTED_VIDEOS, payload: [...videos] });

        return videos;
      }
    },
    setFilter: filter => {
      dispatch({
        type: SET_FILTER,
        payload: { ...videosState.filter, ...filter }
      });
    },
    resetFilter: () => {
      dispatch({
        type: SET_FILTER,
        payload: { ...initialState.filter }
      });
    }
  };

  return (
    <VideosContext.Provider
      value={{
        videosState,
        videoActions,
        loading: loadingVideos && loadingSharedVideos,
        error: errorVideos && errorSharedVideos
      }}
    >
      {children}
    </VideosContext.Provider>
  );
};

function useVideosContext() {
  const context = useContext(VideosContext);
  if (context === undefined) {
    throw new Error(
      'The VideosContext hook must be used within a VideosContext.Provider'
    );
  }
  return context;
}

export { VideosProvider, useVideosContext };
