import React, { Component, Fragment } from 'react';
import { Mutation, Query } from '@apollo/client/react/components';
import { inject } from 'mobx-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import MTSTestDataResultForm from './MTSTestDataResultForm';
import { FILE_UPLOAD_ACCEPT } from 'containers/partials/file-upload/FileUpload';
import CardBody from 'components/card/CardBody';
import Card from 'components/card/Card';
import FileUploadWrapper from 'containers/partials/file-upload/FileUploadWrapper';
import {
  MUTATION_UPDATE_TEST_DATA,
  QUERY_GET_TESTDATA_BY_ID
} from 'services/aws/session-query';
import { isValueValidNumber } from 'utils/form';
import messages from 'messages';
import { CardHeader, CardHeaderButtons } from 'components/card/card-header';
import { Button } from 'components/button';
import { MUTATE_SEND_MTS_REPORT_TO_SPORTER } from 'services/aws/client-query';
import { MODAL_TYPES } from 'models/ModalData';
import { QUERY_GET_UPLOADED_FILES_BY_LINK_ID } from 'services/aws/file-upload';
import File from 'models/File';
import { isEmailValid } from 'utils/email';
import Loader from 'components/loader/Loader';
import RetryPanel from '../error-boundary/RetryPanel';

const getValue = value => (value !== undefined ? value : '');

class MTSTestDataResultCard extends Component {
  state = {};
  onSubmit = mutation => {
    const { testDataId } = this.props;
    const {
      soleusVoxelSizeX,
      soleusVoxelSizeY,
      soleusVoxelSizeZ,
      soleusCarnosine,
      soleusCreatine,
      soleusTma,
      soleusSignalWater,
      gastroCarnosine,
      gastroVoxelSizeX,
      gastroVoxelSizeY,
      gastroVoxelSizeZ,
      gastroSignalWater
    } = this.state;

    mutation({
      variables: {
        id: testDataId,
        result: JSON.stringify({
          soleusVoxelSizeX,
          soleusVoxelSizeY,
          soleusVoxelSizeZ,
          soleusCarnosine,
          soleusCreatine,
          soleusTma,
          soleusSignalWater,
          gastroCarnosine,
          gastroVoxelSizeX,
          gastroVoxelSizeY,
          gastroVoxelSizeZ,
          gastroSignalWater
        })
      }
    });
  };

  onChange = (e, type) => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  onBlur = (e, type) => {
    if (type === 'number') {
      const value = Number(e.target.value.replace(',', '.'));
      this.setState({
        [e.target.id]: value,
        [`${e.target.id}Error`]: isValueValidNumber(value) ? false : true
      });
    } else {
      this.setState({
        [`${e.target.id}Error`]: e.target.value ? false : true
      });
    }
  };

  onResetResult = () => {
    const { resetUrl } = this.props;
    const errors = {};
    Object.keys(this.state).forEach(key => {
      if (key.search('Error') !== -1) {
        errors[key] = false;
      }
    });
    let result = {};
    try {
      result = JSON.parse(this.testData.result);
      if (!result) result = {};
    } catch (er) {}

    this.setState({
      soleusVoxelSizeX: getValue(result.soleusVoxelSizeX),
      soleusVoxelSizeY: getValue(result.soleusVoxelSizeY),
      soleusVoxelSizeZ: getValue(result.soleusVoxelSizeZ),
      soleusCarnosine: getValue(result.soleusCarnosine),
      soleusCreatine: getValue(result.soleusCreatine),
      soleusTma: getValue(result.soleusTma),
      soleusSignalWater: getValue(result.soleusSignalWater),
      gastroCarnosine: getValue(result.gastroCarnosine),
      gastroVoxelSizeX: getValue(result.gastroVoxelSizeX),
      gastroVoxelSizeY: getValue(result.gastroVoxelSizeY),
      gastroVoxelSizeZ: getValue(result.gastroVoxelSizeZ),
      gastroSignalWater: getValue(result.gastroSignalWater),
      ...errors
    });

    const {
      routing: { push }
    } = this.props;
    push(resetUrl);
  };

  sendToSporter = (e, mutation) => {
    e.preventDefault();
    const { sporter } = this.props;

    let metaData = {};
    try {
      metaData = JSON.parse(sporter.meta);
      if (!metaData) metaData = {};
    } catch (er) {}

    if (metaData && metaData.email && isEmailValid(metaData.email)) {
      mutation();
    } else {
      this.showMailFailedNoEmail();
    }
  };

  onCompleted = data => {
    const testData = data.getTestData;
    let result = {};
    try {
      result = JSON.parse(testData.result);
      if (!result) result = {};
    } catch (er) {}

    this.setState({
      soleusVoxelSizeX: getValue(result.soleusVoxelSizeX),
      soleusVoxelSizeY: getValue(result.soleusVoxelSizeY),
      soleusVoxelSizeZ: getValue(result.soleusVoxelSizeZ),
      soleusCarnosine: getValue(result.soleusCarnosine),
      soleusCreatine: getValue(result.soleusCreatine),
      soleusTma: getValue(result.soleusTma),
      soleusSignalWater: getValue(result.soleusSignalWater),
      gastroCarnosine: getValue(result.gastroCarnosine),
      gastroVoxelSizeX: getValue(result.gastroVoxelSizeX),
      gastroVoxelSizeY: getValue(result.gastroVoxelSizeY),
      gastroVoxelSizeZ: getValue(result.gastroVoxelSizeZ),
      gastroSignalWater: getValue(result.gastroSignalWater)
    });
  };

  showMailSuccess = () => {
    const { uiState, intl } = this.props;
    uiState.showModal({
      title: intl.formatMessage(messages.modalReportSendToSporterSuccessTitle),
      message: intl.formatMessage(
        messages.modalReportSendToSporterSuccessMessage
      ),
      dismissButton: false,
      type: MODAL_TYPES.WARNING
    });
  };

  showMailFailed = () => {
    const { uiState, intl } = this.props;
    uiState.showModal({
      title: intl.formatMessage(messages.modalReportSendToSporterFailedTitle),
      message: intl.formatMessage(
        messages.modalReportSendToSporterFailedMessage
      ),
      dismissButton: false,
      type: MODAL_TYPES.ALERT
    });
  };

  showMailFailedNoEmail = () => {
    const { uiState, intl } = this.props;
    uiState.showModal({
      title: intl.formatMessage(
        messages.modalReportSendToSporterFailedNoEmailTitle
      ),
      message: intl.formatMessage(
        messages.modalReportSendToSporterFailedNoEmailMessage
      ),
      dismissButton: false,
      type: MODAL_TYPES.ALERT
    });
  };

  render() {
    const {
      edit,
      editUrl,
      resetUrl,
      entityId,
      rootEntityId,
      testDataId,
      intl,
      routing: { push }
    } = this.props;
    const { ...state } = this.state;

    if (!testDataId) {
      return (
        <Card secondary>
          <CardBody secondary />
        </Card>
      );
    }

    return (
      <Fragment>
        <Query
          query={QUERY_GET_TESTDATA_BY_ID}
          variables={{ entityId, testDataId }}
          fetchPolicy="cache-and-network"
          onCompleted={this.onCompleted}
        >
          {result => {
            const { loading, data, error } = result;
            if (loading) return <Loader />;
            if (error) return <RetryPanel />;
            this.testData = data.getTestData;
            return (
              <Mutation
                mutation={MUTATION_UPDATE_TEST_DATA}
                update={(cache, { data: { editTestData } }) => {
                  if (editTestData && editTestData.id) {
                    push(resetUrl);
                  }
                }}
                refetchQueries={() => [
                  {
                    query: QUERY_GET_TESTDATA_BY_ID,
                    variables: { entityId, testDataId }
                  }
                ]}
              >
                {(editTestSession, data) => {
                  return (
                    <MTSTestDataResultForm
                      editUrl={editUrl}
                      edit={edit}
                      onSave={() => this.onSubmit(editTestSession)}
                      onReset={this.onResetResult}
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                      loading={data.loading || loading}
                      {...state}
                    />
                  );
                }}
              </Mutation>
            );
          }}
        </Query>

        <Card secondary>
          <CardHeader secondary>
            <CardHeaderButtons>
              <Query
                query={QUERY_GET_UPLOADED_FILES_BY_LINK_ID}
                variables={{ entityId: rootEntityId, linkId: testDataId }}
                fetchPolicy="cache-and-network"
              >
                {result => {
                  const { loading, data, error } = result;

                  if (loading) return null;
                  if (error) return null;
                  let uploadedFiles = [];
                  if (data && data.getFiles && data.getFiles.length) {
                    uploadedFiles = data.getFiles.map(uf => new File(uf));
                  }
                  const hasReportFile = uploadedFiles.find(
                    f => f.type === 'report'
                  );

                  if (!hasReportFile) {
                    return null;
                  }
                  return (
                    <Mutation
                      mutation={MUTATE_SEND_MTS_REPORT_TO_SPORTER}
                      variables={{ testDataId }}
                      update={(cache, { data: { sendMtsReport } }) => {
                        if (sendMtsReport) {
                          this.showMailSuccess();
                        } else {
                          this.showMailFailed();
                        }
                      }}
                      onError={this.showMailFailed}
                    >
                      {(mutation, data) => {
                        return (
                          <Button
                            small
                            light
                            onClick={e => this.sendToSporter(e, mutation)}
                            disabled={data.loading}
                          >
                            <FormattedMessage
                              {...messages.cardSendReportToSporter}
                            />
                          </Button>
                        );
                      }}
                    </Mutation>
                  );
                }}
              </Query>
            </CardHeaderButtons>
          </CardHeader>
          <CardBody secondary>
            <FileUploadWrapper
              entityId={rootEntityId}
              linkId={testDataId}
              fileUploads={[
                {
                  type: 'report',
                  accept: FILE_UPLOAD_ACCEPT.PDF
                }
              ]}
            />
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

export default injectIntl(inject('routing', 'uiState')(MTSTestDataResultCard));
