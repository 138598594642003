import React, { useEffect, useRef, useState } from 'react';
import { useInterval } from 'react-use';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_FILE } from 'services/aws/file-upload';

const useFileStatusPoller = (file, onError, onReady) => {
  const maxPollCount = 5;
  const [pollCount, setPollCount] = useState(0);
  const [interval, setInterval] = useState(null);
  const [status, setStatus] = useState();

  const savedCallback = useRef(onError);
  useEffect(() => {
    savedCallback.current = onError;
  }, [onError]);

  const [refetch, { data }] = useLazyQuery(QUERY_GET_FILE, {
    fetchPolicy: 'network-only',
    variables: { fileId: file.id }
  });

  useEffect(() => {
    return () => {
      stopPolling();
    };
  }, []);

  useEffect(() => {
    if (data?.getFile) {
      setStatus(data.getFile.status);
      if (data.getFile.status === 'available') {
        setTimeout(() => {
          onReady(data.getFile);
        }, 1000);
      }
    }
  }, [data]);

  useEffect(() => {
    if (pollCount >= maxPollCount) {
      setInterval(null);
      onError && savedCallback.current(file);
    }
  }, [pollCount, file]);

  useInterval(() => {
    if (status === 'waiting') {
      refetch();
      setPollCount(pollCount + 1);
      return;
    }
    onReady && onReady(file);
    setInterval(null);
  }, interval);

  const startPolling = () => {
    if (pollCount === 0) {
      refetch();
      setInterval(2000);
    }
  };
  const stopPolling = () => {
    setInterval(null);
  };

  return { status, startPolling };
};

export default useFileStatusPoller;
