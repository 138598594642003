import React from 'react';
import classNames from 'classnames';
import './Badge.scss';

class Badge extends React.Component {
  render() {
    const {
      children,
      extraClassNames,
      notification,
      show,
      rank,
      bullet,
      error,
      tag,
      inButton,
      large,
      style
    } = this.props;
    return (
      <span
        className={classNames('c-badge', extraClassNames, {
          'c-badge--notification': notification,
          'c-badge--show': show,
          'c-badge--rank': rank,
          'c-badge--bullet': bullet,
          'c-badge--error': error,
          'c-badge--tag': tag,
          'c-badge--in-button': inButton,
          'c-badge--large': large
        })}
        style={style}
      >
        {children}
      </span>
    );
  }
}

export default Badge;
