import { PersonInjuryType } from 'constants.js';

export default class PersonInjury {
  id;
  injury;
  injuryDate;
  injuryEndDate;
  personInjuryType;
  meta;
  typeOfInjury;
  causeOfInjury;
  relatedTo;
  relatedToSport;
  relatedToGrowth;
  description;
  extraInfo;
  medicalId;
  sides;
  side;
  screenings;
  hasMoreInfo;

  constructor({
    id,
    injury = {},
    injuryDate = '',
    injuryEndDate = '',
    personInjuryType = PersonInjuryType.INJURY,
    meta,
    typeOfInjury,
    causeOfInjury,
    relatedTo,
    description,
    extraInfo,
    medicalId = '',
    sides = [],
    screenings = []
  }) {
    this.id = id;
    this.injury = injury;
    this.injuryDate = injuryDate ? new Date(injuryDate) : '';
    this.injuryEndDate = injuryEndDate ? new Date(injuryEndDate) : '';
    this.personInjuryType = personInjuryType;
    try {
      this.meta = meta ? JSON.parse(meta) : {};
    } catch (e) {
      this.meta = meta;
    }
    this.typeOfInjury = typeOfInjury ?? this.meta.typeOfInjury ?? '';
    this.causeOfInjury = causeOfInjury ?? this.meta.causeOfInjury ?? '';
    this.relatedTo = relatedTo ?? this.meta.relatedTo ?? '';
    this.relatedToSport = this.relatedTo?.sport ?? false;
    this.relatedToGrowth = this.relatedTo?.growth ?? false;
    this.description = description ?? this.meta.description ?? '';
    this.extraInfo = extraInfo ?? this.meta.extraInfo ?? '';
    this.medicalId = medicalId;
    this.sides = sides;
    this.side = this.sides?.length > 0 ? this.sides[0] : 'center';
    this.screenings = screenings;

    this.hasMoreInfo =
      this.typeOfInjury !== '' ||
      this.causeOfInjury !== '' ||
      this.relatedToSport !== false ||
      this.relatedToGrowth !== false ||
      this.description !== '' ||
      this.extraInfo !== '';
  }
}
