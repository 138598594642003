import React from 'react';
import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import messages from 'messages';
import { FormattedMessage, useIntl } from 'react-intl';
import { CardBody, CardFooter } from 'components/card';
import { Button, ButtonsGroup } from 'components/button';
import { useNotificationQueue } from 'components/notification';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import FieldInput from 'components/input/FieldInput';
import { ShareTypes } from 'constants.js';
import CardHeaderSubtitle from 'components/card/card-header/CardHeaderSubtitle';
import { useMutation } from '@apollo/client';
import { MUTATION_ADD_SHARE } from 'services/aws/shares-query';
import { QUERY_GET_VIDEO } from 'services/aws/videos-query';

const initialShareObj = {
  linkId: null,
  shareWith: '',
  type: ShareTypes.EXERCISE
};

function ShareVideoModal({ entityId, linkId, onClose }) {
  const intl = useIntl();
  const notification = useNotificationQueue();

  const validationSchema = Yup.object().shape({
    shareWith: Yup.string()
      .email(intl.formatMessage(messages.emailNotValid))
      .required(intl.formatMessage(messages.emailRequired))
  });

  const [addShare] = useMutation(MUTATION_ADD_SHARE);

  const onSubmitHandler = async (data, { setFieldError }) => {
    const shareWith = {
      ...data,
      shareWith: data.shareWith.toLowerCase(),
      linkId
    };
    await addShare({
      variables: shareWith,
      refetchQueries: [
        {
          query: QUERY_GET_VIDEO,
          variables: { entityId, id: linkId }
        }
      ]
    }).then(
      res => {
        notification.add(res.data.addShare.id, {
          message: intl.formatMessage(messages.videoShareMessage)
        });
        if (onClose) onClose();
      },
      error => {
        if (error) {
          if (error.message === 'same_person') {
            setFieldError(
              'shareWith',
              intl.formatMessage(messages.videoShareErrorMessageSamePerson)
            );
          }
          if (error.message === 'already_shared_with_person') {
            setFieldError(
              'shareWith',
              intl.formatMessage(
                messages.videoShareErrorMessageAlreadySharedWithPerson
              )
            );
          }
          if (error.message === 'shareWith_not_found') {
            setFieldError(
              'shareWith',
              intl.formatMessage(messages.videoShareErrorMessageNotFound)
            );
          }
        }
      }
    );
  };

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal card autoHeight isOpen={true} onClose={onCloseHandler}>
      <Formik
        initialValues={initialShareObj}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onSubmitHandler}
      >
        {({ errors, isSubmitting, touched }) => (
          <Form>
            <CardHeader modal>
              <CardHeaderTitle>
                <FormattedMessage {...messages.videoShareModalShareTitle} />
              </CardHeaderTitle>
              <CardHeaderSubtitle>
                <FormattedMessage {...messages.videoShareModalShareSubTitle} />
              </CardHeaderSubtitle>{' '}
            </CardHeader>
            <CardBody modalWithOverflow>
              <div className="o-layout o-layout--small">
                <div className="o-layout__item u-3-of-3">
                  <FieldInput
                    id="shareWith"
                    name="shareWith"
                    type="text"
                    placeholder={intl.formatMessage(
                      messages.videoShareModalShareWithLabel
                    )}
                    errors={errors}
                    touched={touched}
                  >
                    <FormattedMessage
                      {...messages.videoShareModalShareWithLabel}
                    />
                    *
                  </FieldInput>
                </div>
              </div>
            </CardBody>
            <CardFooter modal extraClassNames="c-card__footer--modal-larger">
              <ButtonsGroup>
                <Button
                  type="button"
                  secondary
                  onClick={() => onCloseHandler()}
                >
                  <FormattedMessage {...messages.cancelButton} />
                </Button>
                <Button type="submit" primary disabled={isSubmitting}>
                  <FormattedMessage {...messages.sendButton} />
                </Button>
              </ButtonsGroup>
            </CardFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ShareVideoModal;
