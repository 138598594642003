import { useContext, useLayoutEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper
} from 'components/list/list-item';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Icon from 'components/icon/Icon';
import Button from 'components/button/Button';
import { format } from 'date-fns';
import { Card, CardBody } from 'components/card';
import {
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderText
} from 'components/card/card-header';
import { NoteTypes } from 'constants.js';
import { useNotificationQueue } from 'components/notification';
import Loader from 'components/loader/Loader';
import { StoreContext } from 'index';
import FieldInput from 'components/input/FieldInput';
import MessageText from 'components/message/MessageText';
import Message from 'components/message/Message';
import useProgramFeedback from 'hooks/queries/useProgramFeedback';

const emptyFeedBack = { feedback: '', date: '' };

const ExerciseFeedbackPanel = ({ program, editable = false, personId }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const [showEmptyFeedback, setShowEmptyFeedback] = useState(false);
  const [{ feedback, loading }, { addFeedback, archiveFeedback }] =
    useProgramFeedback({
      programId: program.id,
      userId: personId
    });
  const [feedbackItem, setFeedbackItem] = useState({ ...emptyFeedBack });
  const inputRef = useRef();

  const notification = useNotificationQueue();
  const intl = useIntl();

  let schema = Yup.object().shape({
    feedback: Yup.string().required(
      intl.formatMessage(messages.feedbackRequired)
    )
  });

  useLayoutEffect(() => {
    if (showEmptyFeedback && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showEmptyFeedback, inputRef]);

  const onAddNoteHandler = async () => {
    try {
      await schema.validate(feedbackItem);

      const note = await addFeedback({
        note: feedbackItem.feedback,
        noteType: NoteTypes.FEEDBACK,
        linkId: program.id,
        linkId3: user.id
      });

      hideFeedbackInputHandler();
      notification.add(note.id, {
        message: intl.formatMessage(messages.noteSaved)
      });
    } catch (err) {
      inputRef.current.focus();
    }
  };

  const deleteFeedbackHandler = async note => {
    await archiveFeedback({ noteId: note.id });
    notification.add(note.id, {
      message: intl.formatMessage(messages.noteRemove)
    });
  };

  const hideFeedbackInputHandler = () => {
    setFeedbackItem({ ...emptyFeedBack });
    setShowEmptyFeedback(false);
  };

  const addFeedbackRowHandler = () => {
    if (showEmptyFeedback) {
      inputRef.current.focus();
    }
    setShowEmptyFeedback(true);
  };

  return (
    <Card reports extraClassNames={'c-card--feedback'}>
      {feedback && feedback.length > 0 && (
        <CardHeader secondary extraClassNames="u-margin-top-medium">
          <CardHeaderText>
            <CardHeaderSubtitle>
              <FormattedMessage
                {...messages.subtitleFeedback}
                values={{
                  count: feedback.length
                }}
              />
            </CardHeaderSubtitle>
          </CardHeaderText>
        </CardHeader>
      )}
      <CardBody secondary>
        {loading && <Loader />}
        {((feedback && feedback.length > 0) || showEmptyFeedback) && (
          <List cue>
            <ListBody>
              {feedback.map((data, i) => (
                <ListItem
                  small
                  extraClassNames={'c-list__item-feedback'}
                  key={i}
                >
                  <ListItemLabelWrapper>
                    <ListItemLabel label>{data.note}</ListItemLabel>
                    <ListItemLabel label>
                      {format(data.date_created, 'dd/LL/yyyy')}
                    </ListItemLabel>
                  </ListItemLabelWrapper>
                  {editable && (
                    <Button
                      tiny
                      onClick={() => deleteFeedbackHandler(data)}
                      role="button"
                    >
                      <Icon id="delete" strokeColor="color-neutral-dark" />
                    </Button>
                  )}
                </ListItem>
              ))}

              {editable && (
                <>
                  {showEmptyFeedback && (
                    <ListItem
                      smallEditable
                      extraClassNames={'c-list__item-feedback'}
                    >
                      <ListItemLabelWrapper>
                        <FieldInput
                          ref={inputRef}
                          name="feedback"
                          onChange={e =>
                            setFeedbackItem({
                              ...feedbackItem,
                              feedback: e.target.value
                            })
                          }
                          onKeyEnter={() => {
                            onAddNoteHandler();
                          }}
                        />
                      </ListItemLabelWrapper>
                      <Button
                        tiny
                        onClick={() => {
                          onAddNoteHandler();
                        }}
                        role="button"
                      >
                        <FormattedMessage {...messages.buttonSubmit} />
                      </Button>
                      <Button
                        tiny
                        onClick={() => hideFeedbackInputHandler()}
                        role="button"
                      >
                        <Icon id="delete" strokeColor="color-neutral-dark" />
                      </Button>
                    </ListItem>
                  )}
                </>
              )}
            </ListBody>
          </List>
        )}

        {((editable && feedback?.length > 0) ||
          (editable && feedback?.length === 0 && showEmptyFeedback)) && (
          <Button
            rounded
            primary
            onClick={() => {
              addFeedbackRowHandler();
            }}
          >
            <Icon id="add-white" />
            <FormattedMessage {...messages.buttonAddFeedback} />
          </Button>
        )}
        {(!feedback || feedback.length === 0) && !showEmptyFeedback && (
          <Message emptyState icon="test">
            <MessageText>
              <FormattedMessage
                {...messages.emptyProgramExercisesFeedbackAthlete}
              />
            </MessageText>
            {editable && (
              <Button
                rounded
                primary
                onClick={() => {
                  addFeedbackRowHandler();
                }}
              >
                <Icon id="add-white" />
                <FormattedMessage {...messages.buttonAddFeedback} />
              </Button>
            )}
          </Message>
        )}
      </CardBody>
    </Card>
  );
};

export default ExerciseFeedbackPanel;
