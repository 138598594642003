import { Fragment, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import {
  ROUTE_SESSIONS_ENTITY_SESSION,
  ROUTE_SESSIONS_ENTITY,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER,
  ROUTE_SESSIONS_ENTITY_SESSION_ADD_SPORTER,
  ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_EDIT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE,
  ROUTE_SESSIONS_ENTITY_SESSION_EDIT
} from 'routes/RouteList';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import Panel from 'components/panel/Panel';
import { TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import PanelHeader from 'components/panel/PanelHeader';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';

import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelSubtitle from 'components/panel/panel-title/PanelSubtitle';
import { getLocalDateFormat } from 'utils/date';
import Badge from 'components/badge/Badge';
import PanelBody from 'components/panel/PanelBody';

import Icon from 'components/icon/Icon';
import Button from 'components/button/Button';
import { SporterListItem } from 'containers/partials/list-items/SporterListItem';
import MenuWrapper from 'components/menu/MenuWrapper';
import { sortSportersOnFinishedAndDate } from 'utils/sort';
import SelectableList from 'components/list/SelectableList';
import PanelTitleButton from 'components/panel/panel-title/PanelTitleButton';
import RemoveSporterFromSessionButton from 'containers/partials/buttons/RemoveSporterFromSessionButton';
import RemoveSessionButton from 'containers/partials/buttons/RemoveSessionButton';
import { PackageTypes, PackageSubTypes, TestSetV2Type } from 'constants.js';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import MailAllReportsButton from 'containers/partials/buttons/MailAllReportsButton';
import { StoreContext } from 'index';
import EditSessionCard from 'containers/partials/cards/EditSessionCard';
import { useSessionContext } from 'contexts/SessionContext';
import PreventionSession from 'containers/pages/prevention/PreventionSession';
import SessionForm from 'containers/pages/prevention/SessionForm';
import Loader from 'components/loader/Loader';
import { TestsProvider } from 'contexts/TestsContext';
import messages from 'messages';
import Person from 'models/Person';
import ExportReportButton from 'containers/partials/buttons/ExportReportButton';
import DownloadHanReportButton from 'containers/partials/buttons/DownloadHanReportButton';
import DownloadEmptyFormButton from 'containers/partials/buttons/DownloadEmptyFormButton';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import DownloadSessionReportButton from 'containers/partials/buttons/DownloadSessionReportButton';
import DownloadReportOptionsModal, {
  downloadAllReports
} from 'containers/partials/modals/DownloadReportOptionsModal';
import { uid } from 'react-uid';
import DownloadSessionsReportButton from 'containers/partials/buttons/DownloadSessionsReportButton';
import useTabIndex from 'hooks/utils/useTabIndex';
import TabLink from 'components/tabs/TabLink';
import { openWindowWithLoader } from 'utils/browser';
import MailReportOptionsModal from 'containers/partials/modals/MailReportOptionsModal';
import MailAllReportsModalButton from 'containers/partials/buttons/MailAllReportsModalButton';
import CopySessionButton from 'containers/partials/buttons/CopySessionButton';
import AddPreventionModal from 'containers/pages/prevention/AddPreventionModal';
import Session from 'models/Session';
import useEdit from 'hooks/utils/useEdit';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';

export const isMTSType = (id, testSets) => {
  if (testSets) {
    const testSet = testSets.find(ts => ts.id === id);
    if (testSet && testSet.type === PackageTypes.MTS) {
      return true;
    }
  }
  return false;
};

export const isHANType = (id, testSets) => {
  if (testSets) {
    const testSet = testSets.find(ts => ts.id === id);
    if (testSet && testSet.subtype === PackageSubTypes.HAN) {
      return true;
    }
  }
  return false;
};

const SessionDetail = ({
  entityId,
  sessionId,
  sporterId,
  active,
  ...props
}) => {
  const ability = useAbility(AbilityContext);
  const {
    authStore: { user },
    routing: { push }
  } = useContext(StoreContext);
  const { session, loading, error, options } = useSessionContext();

  const { uiState } = useContext(StoreContext);
  const intl = useIntl();
  const [edit, setEdit] = useEdit(props.edit, true);
  const [copySessionModal, setCopySessionModal] = useState(false);
  const [selectedSporterIds, setSelectedSporterIds] = useState('');
  const [sortedPersons, setSortedPersons] = useState([]);
  const [showReportOptionsModal, setShowReportOptionsModal] = useState(false);
  const [showMailReportOptionsModal, setShowMailReportOptionsModal] =
    useState(false);
  const [tabIndex, setTabIndex] = useTabIndex([
    [ROUTE_SESSIONS_ENTITY_SESSION, ROUTE_SESSIONS_ENTITY_SESSION_EDIT],
    [
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER,
      ROUTE_SESSIONS_ENTITY_SESSION_ADD_SPORTER,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_EDIT,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE
    ]
  ]);

  useEffect(() => {
    return () => {
      setSelectedSporterIds([]);
    };
  }, []);

  useEffect(() => {
    if (session?.sporters) {
      setSortedPersons(sortSportersOnFinishedAndDate(session.sporters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, sortSportersOnFinishedAndDate]);

  const onSelectSporterChangeHandler = selectedSporterIds => {
    setSelectedSporterIds(selectedSporterIds);
  };

  const onEditPreventionHandler = () => {
    push(
      generatePath(ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION, {
        entityId,
        type: session.preventionType,
        sessionId
      })
    );
  };

  const sessionIsBenchmarked = !(
    session.isIAm ||
    session.isDjas ||
    session.isHan ||
    session.isHanSisp ||
    session.isIDo
  );

  const copySession = () => {
    const newSession = new Session({ ...session });
    newSession.id = null;
    newSession.startdate = new Date();

    setCopySessionModal(newSession);
  };

  if (loading) {
    return (
      <Panel active={active}>
        <Loader />
      </Panel>
    );
  }

  return (
    <Fragment>
      <Panel active={active}>
        {error && <RetryPanel />}
        {session && (
          <div style={{ background: 'inherit' }}>
            <PanelHeader>
              <Breadcrumbs>
                <Breadcrumb
                  onClick={() =>
                    push(generatePath(ROUTE_SESSIONS_ENTITY, { entityId }))
                  }
                >
                  <FormattedMessage {...messages.breadcrumbSessions} />
                </Breadcrumb>
                <Breadcrumb>
                  <FormattedMessage
                    {...messages.breadcrumbSession}
                    values={{ session: session.name }}
                  />
                </Breadcrumb>
              </Breadcrumbs>
              <PanelTitleWrapper>
                <PanelTitle>
                  {session.name}
                  {!user.isAthlete() && (
                    <MenuWrapper trigger={<PanelTitleButton />}>
                      {session.isPrevention && (
                        <Button menu onClick={onEditPreventionHandler}>
                          <Icon id="add" />
                          <FormattedMessage
                            {...messages.preventionAddExtraTestsButton}
                          />
                        </Button>
                      )}

                      {/* Add Athlete: only visible on Athlete tab */}
                      {tabIndex === 1 && (
                        <Button
                          menu
                          onClick={() =>
                            push(
                              generatePath(
                                ROUTE_SESSIONS_ENTITY_SESSION_ADD_SPORTER,
                                { entityId, sessionId }
                              )
                            )
                          }
                        >
                          <Icon id="add" />
                          <FormattedMessage
                            {...messages.buttonSessionAddSporters}
                          />
                        </Button>
                      )}

                      <DownloadEmptyFormButton session={session} />

                      {session.finishedPercentage !== 0 && (
                        <>
                          <ExportReportButton session={session} />
                          <DownloadHanReportButton session={session} />

                          {sessionIsBenchmarked && (
                            <Button
                              menu
                              onClick={async () => {
                                if (user.isHylyghtOrg) {
                                  setShowReportOptionsModal(true);
                                } else {
                                  const newWindow =
                                    !session.isTestSetV2 && session.isIAm
                                      ? openWindowWithLoader()
                                      : null;
                                  await downloadAllReports(
                                    session,
                                    newWindow,
                                    uiState,
                                    options,
                                    intl
                                  );
                                }
                              }}
                            >
                              <Icon id="download" />
                              <FormattedMessage
                                {...messages.menuItemSessionDownloadAllReports}
                              />
                            </Button>
                          )}
                          <DownloadSessionsReportButton session={session} />
                          <DownloadSessionReportButton
                            session={session}
                            isSession={true}
                            options={options}
                          />
                          {user.isHylyghtOrg ? (
                            <MailAllReportsModalButton
                              menu
                              onClick={() => {
                                setShowMailReportOptionsModal(true);
                              }}
                            />
                          ) : (
                            <MailAllReportsButton
                              menu
                              entityId={entityId}
                              session={session}
                              options={options}
                            />
                          )}
                        </>
                      )}

                      <CopySessionButton
                        menu
                        entityId={entityId}
                        session={session}
                        onClick={() => copySession()}
                      />

                      {ability.can('delete', 'Sessions') && (
                        <RemoveSessionButton
                          menu
                          entityId={entityId}
                          sessionId={session.id}
                          onComplete={() =>
                            push(
                              ROUTE_SESSIONS_ENTITY.replace(
                                ':entityId',
                                entityId
                              )
                            )
                          }
                        >
                          <FormattedMessage
                            {...messages.menuItemSessionRemoveSession}
                          />
                        </RemoveSessionButton>
                      )}

                      {/* Remove Athlete: only visible on Athlete tab */}
                      {tabIndex === 1 && !user.isAthlete() && (
                        <RemoveSporterFromSessionButton
                          menu
                          ids={selectedSporterIds}
                          sessionId={sessionId}
                          rootEntityId={entityId}
                          onComplete={() => {
                            setSelectedSporterIds([]); // TODO nodig aangezien er een push is?
                            push(
                              generatePath(
                                ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS,
                                { entityId, sessionId }
                              )
                            );
                          }}
                          disabled={selectedSporterIds.length === 0}
                        >
                          <FormattedMessage
                            {...messages.menuItemSessionRemoveSporters}
                          />
                        </RemoveSporterFromSessionButton>
                      )}
                    </MenuWrapper>
                  )}
                </PanelTitle>
                <PanelSubtitle>
                  {getLocalDateFormat(session.startdate)}
                </PanelSubtitle>
              </PanelTitleWrapper>
              {/*
              Eventually we could add a button to add athletes,
              and a search field for athletes
               */}
              {/*<PanelTools>
                <FieldInput
                  rounded
                  type="text"
                  icon="search-grey"
                  iconLeft
                  //   onChange={onSearch}
                />
               {!user.isAthlete() && tabIndex === 1 && (
                  <Button
                    rounded
                    primary
                    onClick={() =>
                      push(
                        generatePath(
                          ROUTE_SESSIONS_ENTITY_SESSION_ADD_SPORTER,
                          { entityId, sessionId }
                        )
                      )
                    }
                  >
                    <Icon id="add-white" />
                    <FormattedMessage {...messages.buttonSessionAddSporters} />
                  </Button>
                )}
                </PanelTools> */}
            </PanelHeader>
            <PanelBody>
              <Tabs
                selectedIndex={tabIndex}
                onSelect={index => setTabIndex(index)}
              >
                <TabList>
                  <TabLink
                    to={generatePath(ROUTE_SESSIONS_ENTITY_SESSION, {
                      sessionId,
                      entityId
                    })}
                  >
                    <FormattedMessage {...messages.tabSessionDetail} />
                  </TabLink>
                  <TabLink
                    to={generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS, {
                      sessionId,
                      entityId
                    })}
                  >
                    <Badge>{session.personEntities.length}</Badge>
                    <FormattedMessage {...messages.tabSessionSporters} />
                  </TabLink>
                </TabList>
                <TabPanel>
                  <TabPanelBody>
                    {session.type === TestSetV2Type.PREVENTION ? (
                      <TestsProvider entityId={entityId}>
                        <SessionForm
                          entityId={entityId}
                          session={session}
                          onComplete={() => setEdit(false)}
                        >
                          <PreventionSession
                            entityId={entityId}
                            sessionId={sessionId}
                            edit={edit}
                          />
                        </SessionForm>
                      </TestsProvider>
                    ) : (
                      <SessionForm
                        entityId={entityId}
                        session={session}
                        onComplete={() => setEdit(false)}
                      >
                        <EditSessionCard
                          entityId={entityId}
                          sessionId={sessionId}
                          session={session}
                          edit={edit}
                        />
                      </SessionForm>
                    )}
                  </TabPanelBody>
                </TabPanel>
                <TabPanel>
                  <TabPanelBody>
                    {sortedPersons.length > 0 ? (
                      <>
                        <List>
                          <ListBody>
                            <SelectableList
                              onChange={(id, ids) =>
                                onSelectSporterChangeHandler(ids)
                              }
                              onClick={id => {
                                if (sporterId !== id)
                                  push(
                                    generatePath(
                                      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER,
                                      { entityId, sessionId, sporterId: id }
                                    )
                                  );
                              }}
                              selectedIds={selectedSporterIds}
                            >
                              {sortedPersons.map(person => {
                                const sporter = new Person(person);
                                return (
                                  <SporterListItem
                                    key={uid(sporter.id)}
                                    id={sporter.id}
                                    entityId={entityId}
                                    sessionId={sessionId}
                                    active={sporterId === sporter.id}
                                    percentage={session.getPersonTestPercentage(
                                      sporter.id
                                    )}
                                    sporter={sporter}
                                    checkbox
                                    session
                                  />
                                );
                              })}
                            </SelectableList>
                          </ListBody>
                        </List>
                      </>
                    ) : (
                      <Card reports>
                        <CardBody
                          secondary
                          extraClassNames="u-margin-bottom-none u-padding-vertical-huge"
                        >
                          <Message
                            emptyState
                            icon="sporters"
                            iconFillColor="color-neutral-dark"
                          >
                            <MessageText>
                              <FormattedMessage
                                {...messages.labelSessionNoSporters}
                              />
                            </MessageText>
                          </Message>

                          {!user.isAthlete() && (
                            <Button
                              rounded
                              primary
                              extraClassNames="u-margin-top-large"
                              onClick={() =>
                                push(
                                  generatePath(
                                    ROUTE_SESSIONS_ENTITY_SESSION_ADD_SPORTER,
                                    { entityId, sessionId }
                                  )
                                )
                              }
                            >
                              <Icon id="add-white" />
                              <FormattedMessage
                                {...messages.buttonSessionAddSporters}
                              />
                            </Button>
                          )}
                        </CardBody>
                      </Card>
                    )}
                  </TabPanelBody>
                </TabPanel>
              </Tabs>
            </PanelBody>
          </div>
        )}
      </Panel>
      {copySessionModal && (
        <AddPreventionModal
          entityId={entityId}
          session={copySessionModal}
          isTemplate={false}
          onClose={() => {
            setCopySessionModal(false);
          }}
        />
      )}
      {showMailReportOptionsModal && (
        <MailReportOptionsModal
          onClose={() => setShowMailReportOptionsModal(false)}
        />
      )}
      {showReportOptionsModal && (
        <DownloadReportOptionsModal
          onClose={() => setShowReportOptionsModal(false)}
        />
      )}
    </Fragment>
  );
};

export default SessionDetail;
