import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import messages from 'messages';
import { Button } from 'components/button';
import { CardBodyTools } from 'components/card';
import Icon from 'components/icon/Icon';
import Loader from 'components/loader/Loader';
import FieldInput from 'components/input/FieldInput';
import {
  Breadcrumb,
  Breadcrumbs,
  BreadcrumbsWrapper
} from 'components/breadcrumbs';
import VirtualizedEntityList from 'containers/pages/sporters/VirtualizedEntityList';
import Group from 'models/Group';
import useGroups from 'hooks/queries/useGroups';
import useSelectableObject from 'hooks/utils/useSelectableObject';
import Person from 'models/Person';

const SelectPersonsCard = ({
  rootEntityId,
  onSelectPersons,
  onSelectGroups,
  onModal = false,
  extraClassNamesForList = {},
  selectedPersons = [],
  excludedPersons = [],
  onlyGroups = false
}) => {
  const intl = useIntl();
  const [rootGroup, setRootGroup] = useState();
  const [activeGroup, setActiveGroup] = useState();
  const [entitiesList, setEntitiesList] = useState([]);
  const { selected, select, setSelected, addSelected, removeSelected } =
    useSelectableObject();
  const { selected: groupsSelected, select: selectGroup } =
    useSelectableObject();
  const { group, groups, persons, filter, setFilter, loading, fetchGroups } =
    useGroups({
      rootEntityId
    });

  useEffect(() => {
    fetchGroups(rootEntityId);
  }, []);

  useEffect(() => {
    if (selectedPersons && !onlyGroups) {
      setSelected(selectedPersons);
    }
  }, [selectedPersons]);

  /*useEffect(() => {
    if (selectedGroups) {
      setSelectedGroups(selectedGroups);
    }
  }, [selectedGroups]);*/

  useEffect(() => {
    if (group?.id) {
      setActiveGroup(group);
    }
    if (group?.id === rootEntityId) {
      setRootGroup(group);
    }
  }, [group]);

  const onBackHandler = async () => {
    const newList = [...entitiesList];
    const lastGroup = newList.pop();
    setEntitiesList([...newList]);
    fetchGroups(lastGroup.parentId);
  };

  const onGroupClickedHandler = async item => {
    setEntitiesList([...entitiesList, item]);
    fetchGroups(item.id);
  };

  const onBreadcrumbClickedHandler = async item => {
    const newList = [...entitiesList];
    const index = newList.findIndex(e => e.id === item.id);
    newList.splice(index + 1);
    setEntitiesList([...newList]);
    fetchGroups(item.id);
  };

  const onSearch = e => {
    setFilter({ search: e.target.value });
  };

  const onSelectGroupChangeHandler = async (group, checked) => {
    let selectedPersons;
    if (!onlyGroups) {
      if (!checked) {
        selectedPersons = removeSelected(
          group.clients
            .filter(p => !excludedPersons.find(exp => exp.id === p.id))
            .map(user => new Person(user))
        );
      } else {
        selectedPersons = addSelected(
          group.clients
            .filter(p => !excludedPersons.find(exp => exp.id === p.id))
            .map(user => new Person(user))
        );
      }
    }

    onSelectPersons && onSelectPersons(selectedPersons);

    const selectedGroups = selectGroup(group);
    onSelectGroups && onSelectGroups(selectedGroups);
  };

  const onSelectPersonsHandler = person => {
    const selectedPersons = select(person);
    onSelectPersons && onSelectPersons(selectedPersons);
  };

  if (loading || !activeGroup) return <Loader />;

  return (
    <>
      <CardBodyTools style={{ flexWrap: 'wrap' }}>
        {activeGroup.id !== rootEntityId && (
          //   TODO: Fabian fix this ul as flex with divs, spans and icons on it
          <BreadcrumbsWrapper forLists>
            <Button rounded light onClick={() => onBackHandler()}>
              <Icon id="back-nav" />
              <FormattedMessage {...messages.exercisesAssignUserButtonBack} />
            </Button>
            <Breadcrumbs>
              {entitiesList.map((group, i, arr) => {
                if (i === arr.length - 1) {
                  return (
                    <div
                      className="c-card-modal__body-head-breadcrumb"
                      key={group.id}
                    >
                      {arr.length === 1 && (
                        <Icon id="breadcrumb" fillColor="color-neutral-steel" />
                      )}
                      <span className="c-organisation">{group.name}</span>
                    </div>
                  );
                } else {
                  return (
                    <Fragment key={group.id}>
                      {arr.length !== 1 && (
                        <Icon id="breadcrumb" fillColor="color-neutral-steel" />
                      )}
                      <Breadcrumb
                        onClick={() => onBreadcrumbClickedHandler(group)}
                      >
                        {group.name}
                      </Breadcrumb>
                    </Fragment>
                  );
                }
              })}
            </Breadcrumbs>
          </BreadcrumbsWrapper>
        )}
        <FieldInput
          rounded
          placeholder={intl.formatMessage(
            messages.searchUsersAndGroupsPlaceholder
          )}
          value={filter.search}
          onChange={onSearch}
          icon="search-grey"
          iconLeft
          extraClassNames={'u-margin-none'}
          useNative
        />
      </CardBodyTools>
      <VirtualizedEntityList
        onModal={onModal}
        entityId={activeGroup.id}
        items={[...groups, ...(!onlyGroups ? persons : [])]}
        checkableGroups
        checkable
        onlyGroups={onlyGroups}
        selectedItems={[...selected, ...groupsSelected]}
        excludedItems={excludedPersons}
        onClick={(item, type) => {
          if (type === 'group') {
            setFilter({ search: '' });
            onGroupClickedHandler(item);
          }
        }}
        onSelect={(ids, item, checked) => {
          if (item instanceof Group) {
            onSelectGroupChangeHandler(item, checked);
          } else {
            onSelectPersonsHandler(item, checked);
          }
        }}
        visible
        extraClassNames={extraClassNamesForList}
      />
    </>
  );
};

export default SelectPersonsCard;
