import {
  ListItem,
  ListItemStatus,
  ListItemLabelWrapper,
  ListItemLabel
} from 'components/list/list-item';
import { getLocalDateFormat } from 'utils/date';

export const SessionListItemUser = ({
  active,
  session,
  onClick,
  cta,
  id,
  checkbox,
  onChange,
  checked,
  visible,
  noMaxHeight
}) => (
  <ListItem
    noMaxHeight={noMaxHeight}
    checkbox={checkbox}
    active={active}
    done
    onClick={onClick}
    cta={!!cta}
    withSupertitle
  >
    <ListItemStatus
      visible={visible}
      onChange={onChange}
      checked={checked}
      itemId={id}
      statusIcon={'sessions'}
      mediumIcon
    ></ListItemStatus>
    <ListItemLabelWrapper>
      <ListItemLabel
        //supertitle={session.isRehab ? 'Rehab' : false}
        label
      >
        {session.name}
      </ListItemLabel>
      <ListItemLabel info>
        {getLocalDateFormat(session.startdate)}
      </ListItemLabel>
    </ListItemLabelWrapper>
  </ListItem>
);
