import React from 'react';
import classNames from 'classnames';

class PanelTitleWrapper extends React.Component {
  render() {
    const { ...props } = this.props;
    return (
      <div className={classNames('c-panel__title-wrapper')}>
        {props.children}
      </div>
    );
  }
}

export default PanelTitleWrapper;
