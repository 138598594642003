import React, { Component } from 'react';
import classNames from 'classnames';

import './Drawer.scss';

const enhanceWithClickOutside = require('react-click-outside');

class Drawer extends Component {
  handleClickOutside = () => {
    const { open } = this.props;

    if ('ontouchstart' in document.documentElement || open) {
      document.body.style.cursor = 'pointer';
    }

    if (this.props.handleClickOutside) {
      this.props.handleClickOutside();
    }
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.open) {
      document.body.style.cursor = '';
    }
  }

  render() {
    const { open } = this.props;
    return (
      <div
        className={classNames('c-sidebar', {
          'c-sidebar--uncollapsed': open
        })}
      >
        <div className={classNames('c-sidebar__wrapper', {})}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default enhanceWithClickOutside(Drawer);
