import { AnnotationType } from 'constants.js';
import { FinishedState } from 'enums';

export default class Annotation {
  id;
  fileId;
  person;
  linkId;
  exercise;
  testSetId;
  testData;
  notes = [];
  note = '';
  starttime = 0;
  endtime = 0;
  tags = [];
  score = 0;
  type;
  meta;
  date_created;
  iconClasses = [];

  constructor({
    id,
    fileId,
    linkId,
    person,
    exercise,
    testData,
    notes,
    starttime,
    endtime,
    tags = [],
    score,
    meta,
    date_created,
    iconClasses
  }) {
    this.id = id;
    this.fileId = fileId;
    this.person = person;
    if (this.person?.meta) {
      try {
        this.person = { ...person, meta: JSON.parse(person.meta) };
      } catch (e) {
        this.person = { ...person };
      }
    }
    this.testSetId = 'be1782e4-12a7-4e28-ba08-995dcb995b73';
    this.linkId = linkId || exercise?.id;
    this.exercise = exercise;
    this.testData = testData;
    this.notes = notes ?? [];
    this.starttime = starttime ?? 0;
    this.endtime = endtime ?? 0;
    this.type = AnnotationType.EXERCISE;
    this.tags = tags;
    this.score = score;
    this.meta = meta;
    this.date_created = date_created;
    this.iconClasses = iconClasses;

    if (testData?.data) {
      const data = JSON.parse(testData.data);
      // Text
      this.note =
        data?.['73cae474-72ad-4f60-b49e-b210aee96592']?.[
          '73cae474-72ad-4f60-b49e-b210aee96592'
        ][2][0] ?? '';

      // Tags
      this.tags =
        data?.['5316e89d-b75b-4333-8e11-3fe618172346']?.[
          '5316e89d-b75b-4333-8e11-3fe618172346'
        ][2][0] ?? [];

      // Score
      this.score =
        data?.['c107c331-673e-42a4-9332-17390f831290']?.[
          'c107c331-673e-42a4-9332-17390f831290'
        ][2][0] ?? null;
    }
  }

  isChecked() {
    return this.testData?.finished === FinishedState.FINISHED;
  }

  isMine(user) {
    return this.person?.id === user.id;
  }

  isByAthlete() {
    if (this.person?.meta.roletype) {
      return (
        this.person.meta.roletype === 'athlete' ||
        this.person.meta.roletype === 'client'
      );
    }
    return false;
  }

  isByAdmin() {
    if (this.person?.meta.roletype) {
      return this.person?.meta.roletype === 'user';
    }
    return false;
  }
}
