import React, { useState } from 'react';
import { mergeArrays } from 'utils/array';

const useSelectableObject = (initialState = []) => {
  const [selected, setAllSelected] = useState(initialState);

  const select = object => {
    let selectedObjects;
    if (!selected.find(s => s.id === object.id)) {
      selectedObjects = [...selected, object];
    } else {
      selectedObjects = [...selected.filter(s => s.id !== object.id)];
    }
    setAllSelected(selectedObjects);
    return selectedObjects;
  };

  const setSelected = objects => {
    setAllSelected(objects);
  };

  const addSelected = objects => {
    const selectedObjects = mergeArrays(selected, objects);
    setSelected(mergeArrays(selected, objects));
    return selectedObjects;
  };

  const removeSelected = objects => {
    const selectedObjects = selected.filter(
      s => !objects.find(o => o.id === s.id)
    );
    setSelected(selectedObjects);
    return selectedObjects;
  };

  return { selected, select, setSelected, addSelected, removeSelected };
};

export default useSelectableObject;
