import React, { useEffect, useState } from 'react';
import { QUERY_GET_USER_BY_ID } from 'services/aws/user-query';
import { useLazyQuery } from '@apollo/client';
import Person from 'models/Person';

function useUser({ personId }) {
  const [person, setPerson] = useState();
  const [getPerson, { loading, error, data }] = useLazyQuery(
    QUERY_GET_USER_BY_ID,
    {
      variables: { id: personId },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (data?.getPerson) {
      setPerson(new Person(data.getPerson));
    }
  }, [data]);

  return { loading, error, person, getPerson };
}

export default useUser;
