import { makeObservable, observable } from 'mobx';
import { FinishedState } from 'enums';
import { sortTestdataOnDateUpdated } from 'utils/sort';
import { PackageSubTypes, PackageTypes, TestSetV2Type } from 'constants.js';

export default class Session {
  id = null;
  name = '';
  language = null;
  injuryIds;

  constructor({
    id = null,
    name = '',
    location = '',
    notes = '',
    startdate = '',
    // startdate,
    benchmark,
    testSetIds = [],
    testSetTemplateIds = [],
    personEntities = [],
    testData = [],
    finished = FinishedState.NOT_STARTED,
    finishedPercentage = 0,
    meta = null,
    type = '',
    preventionType = '',
    testSets = [],
    tests = [],
    sport = null,
    isTypeV2 = false,
    language
  }) {
    makeObservable(this, {
      id: observable,
      name: observable
    });
    this.id = id;
    this.name = name;
    this.location = location || '';
    this.notes = notes || '';
    this.startdate = startdate ? new Date(startdate) : null;
    this.testSetIds = testSetIds || [];
    this.testSetId = this.testSetIds.length > 0 ? this.testSetIds[0] : null;
    this.testSetTemplateIds = testSetTemplateIds || [];
    this.milestoneTestSetId = testSetTemplateIds?.[0] || 0;
    //this.testSet.url = ''; // this.testSet.url || ''; //https://s.surveyanyplace.com/s/mejisnga/?cf1={UID}&cf2={FIRSTNAME}&cf3={sporterId}&cf4={sessionId}';
    this.personEntities = personEntities || [];
    this.testData = testData || [];
    this.finished = finished || FinishedState.NOT_STARTED;
    this.finishedPercentage =
      finishedPercentage === null ? 0 : finishedPercentage;
    this.meta = meta;
    this.benchmark = benchmark;
    this.type = type;
    this.preventionType = preventionType;
    this.testSets = testSets || [];
    this.tests = tests;
    this.sport = sport;
    this.isTypeV2 = isTypeV2;
    this.isTestSetV2 =
      testSets?.[0]?.testSetVersion && testSets?.[0]?.testSetVersion === 2;
    this.version =
      testSets?.[0]?.testSetVersion && testSets?.[0]?.testSetVersion === 2
        ? 2
        : 1;

    this.meta = typeof meta === 'string' ? JSON.parse(meta) : {};

    const { language: metaLanguage, ...injuryIds } = this.meta;
    this.injuryIds = injuryIds;

    this.language = metaLanguage || language || null;

    // json parse config if it is a string
    if (typeof testSets?.[0]?.config === 'string') {
      this.config = JSON.parse(testSets?.[0]?.config) ?? {};
    } else if (testSets?.[0]?.config) {
      this.config = testSets?.[0]?.config;
    } else {
      this.config = {};
    }

    // workaround for Klusce tests
    if (!this.config?.type && this.id) {
      if (this.type === 'talent' && this.isTestSetV2) {
        this.config = { ...this.config, type: 'ido' };
      }
    }

    // fallback for old tests
    /* if (this.testSet?.testItems && this.testSets.length === 0) {
      const testItems = JSON.parse(this.testSet.testItems);
      if (testItems) {
        this.testSets = [...this.testSets, testItems];
      }
    }*/

    /*if (this.version !== 2) {
      const testItems = JSON.parse(testSet.testItems);
      if (testItems?.tests) {
        this.tests = Object.values(testItems.tests);
      }
    }*/
  }

  get inProgress() {
    const finishedPercentage = this.finishedPercentage;
    return (
      finishedPercentage !== 0 &&
      finishedPercentage !== 100 &&
      this.finished !== FinishedState.FINISHED
    );
  }

  get isDone() {
    return (
      this.finishedPercentage === 100 ||
      this.finished === FinishedState.FINISHED
    );
  }

  get isNotStarted() {
    return (
      this.finishedPercentage === 0 && this.finished !== FinishedState.FINISHED
    );
  }

  get isTalentV2() {
    return (
      (this.type === TestSetV2Type.TALENT ||
        this.type === null ||
        this.type === 'undefined') &&
      this.isTestSetV2
    );
  }
  get isRehab() {
    return this.type === TestSetV2Type.REHAB;
  }
  get isPrevention() {
    return this.type === TestSetV2Type.PREVENTION;
  }
  get isILike() {
    return this.config?.type === PackageTypes.ILIKE;
  }
  get isIDo() {
    return this.config?.type === PackageTypes.IDO && !this.isTestSetV2;
  }
  get isIAm() {
    return this.config?.subtype === PackageSubTypes.IAM;
  }
  get isHan() {
    return this.config?.subtype === PackageSubTypes.HAN;
  }
  get isHanSisp() {
    return this.config?.subtype === PackageSubTypes.SISP;
  }
  get isDjas() {
    return (
      this.type === TestSetV2Type.TALENT &&
      this.config?.subtype === PackageSubTypes.DJAS
    );
  }
  get isSAP() {
    return this.config?.type === PackageTypes.SAP;
  }
  get isMTS() {
    return this.config?.type === PackageTypes.MTS;
  }
  get isKlusce() {
    return (
      (this.config?.type === PackageTypes.IDO ||
        (this.type === TestSetV2Type.TALENT &&
          this.config?.type === 'undefined')) &&
      this.isTestSetV2
    );
  }
  get hasBenchmarkSelect() {
    return (
      this.type === null || this.type === TestSetV2Type.TALENT
      /*this.type === TestSetV2Type.REHAB ||
      this.type === TestSetV2Type.PREVENTION*/
    );
  }

  getPersonEntityIdByPersonId(personId) {
    if (this.personEntities.length === 1) {
      return this.personEntities[0].id;
    }
    const personEntity = this.personEntities.find(
      pe => pe.person.id === personId
    );
    if (personEntity) {
      return personEntity.id;
    }
    return null;
  }

  getAccurateTestData(testDatas) {
    if (!this.testSets?.length) return testDatas[0];

    const testDatasFinished =
      this.testSets[0].type === PackageTypes.ILIKE
        ? testDatas.filter(td => td.finished === FinishedState.FINISHED)
        : [testDatas[0]];
    if (testDatasFinished.length === 1) return testDatasFinished[0];
    if (testDatasFinished.length > 1) {
      const sortedTestResults = sortTestdataOnDateUpdated(testDatasFinished);
      return sortedTestResults[0];
    }
  }

  getTestDataByPersonId(personId) {
    return this.testData.filter(pe => pe.personId === personId);
  }

  getTestData(personId) {
    const testDatas = this.getTestDataByPersonId(personId);
    if (testDatas?.length) {
      const testData = this.getAccurateTestData(testDatas);
      return testData.finishedPercentage > 0 ? testData : false;
    }
    return false;
  }

  /**
   * Gets the current report for a session.
   */
  getReportForPerson(personId) {
    const testDatas = this.getTestDataByPersonId(personId);
    if (testDatas.length) {
      return this.getAccurateTestData(testDatas);
    }
    return null;
  }

  getPersonTestPercentage(personId) {
    const testData = this.getPersonTestPercentageTestData(personId);
    if (testData) {
      return testData.finishedPercentage || 0;
    }
    return 0;
  }

  getPersonTestState(personId) {
    const testDatas = this.getTestDataByPersonId(personId);
    if (testDatas.length) {
      return testDatas[0].finished || FinishedState.NOT_STARTED;
    }
    return FinishedState.NOT_STARTED;
  }

  getPersonTestPercentageTestData(personId) {
    const testDatas = this.testData.filter(
      pe => pe.personId === personId && pe.data !== null
    );
    if (testDatas.length) {
      return this.getAccurateTestData(testDatas);
    }
    return null;
  }

  get sporters() {
    return this.personEntities.map(personEntity => ({
      id: personEntity.id,
      ...personEntity.person,
      finished: this.getPersonTestPercentage(personEntity.person.id)
    }));
  }

  getSporterById = id => {
    const personEntity = this.personEntities.find(pe => pe.person.id === id);
    if (personEntity) {
      return personEntity.person;
    }
    return null;
  };
}
