import React, { useContext, useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { StoreContext } from 'index';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import Session from 'models/Session';
import { SessionSporterListItem } from 'containers/partials/list-items/SessionSporterListItem';
import { ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS } from 'routes/RouteList';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { useQuery } from '@apollo/client';
import { QUERY_GET_SESSIONS_OF_SPORTER } from 'services/aws/session-query';
import { parseTestSessionDataToModel } from 'models/Entity';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import Loader from 'components/loader/Loader';

const SporterSessions = ({ entityId, sessionId, sporterId }) => {
  const {
    routing: { push }
  } = useContext(StoreContext);
  const ability = useAbility(AbilityContext);

  const [sessions, setSessions] = useState([]);

  const { loading, data } = useQuery(QUERY_GET_SESSIONS_OF_SPORTER, {
    variables: { personId: sporterId, entityId },
    skip: !ability.can('read', 'Sessions')
  });

  useEffect(() => {
    if (data?.getTestSessionsOfPerson) {
      const sessions = parseTestSessionDataToModel(
        data.getTestSessionsOfPerson
      );
      setSessions(
        sort(
          sessions.map(s => {
            return {
              ...s,
              finished: s.getPersonTestPercentage(sporterId)
            };
          }),
          {
            keys: [
              { key: 'startdate', dataType: SORT_DATA_TYPES.NUMBER, desc: true }
            ]
          }
        )
      );
    }
  }, [data, sporterId]);

  if (loading) return <Loader />;

  if (sessions.length > 0) {
    return (
      <List>
        <ListBody>
          {sessions.map(session => {
            const sessionModel = new Session(session);
            return (
              <SessionSporterListItem
                noMaxHeight
                key={sessionModel.id}
                session={sessionModel}
                active={sessionId === sessionModel.id}
                onClick={() =>
                  push(
                    generatePath(
                      ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS,
                      {
                        entityId,
                        sessionId: sessionModel.id,
                        sporterId
                      }
                    )
                  )
                }
                percentage={sessionModel.getPersonTestPercentage(sporterId)}
              />
            );
          })}
        </ListBody>
      </List>
    );
  }

  return (
    <Card centered>
      <CardBody empty>
        <Message iconFillColor="color-neutral-dark" empty icon="browse">
          <MessageText>
            <FormattedMessage {...messages.athleteSessionsEmpty} />
          </MessageText>
        </Message>
      </CardBody>
    </Card>
  );
};

export default SporterSessions;
