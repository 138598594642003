import { useEffect, useState } from 'react';
import { Card, CardBody } from 'components/card';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import Select, { Option } from 'components/input/Select';
import { FormattedMessage, useIntl } from 'react-intl';
import SelectExercisesCard from 'containers/partials/cards/SelectExercisesCard';
import messages from 'messages';
import TrainingAdviceCategoryCard from 'containers/pages/prevention/TrainingAdviceCategoryCard';
import { usePreviewModalContext } from 'contexts/PreviewModalContext';
import { useSessionContext } from 'contexts/SessionContext';
import { uid } from 'react-uid';
import { useTestDataContext } from 'contexts/TestDataContext';

const TrainingSelectTests = ({ entityId, sporterId, edit }) => {
  const { session, options } = useSessionContext();
  const { groupedTests } = useTestDataContext();
  const [exerciseSelect, setExerciseSelect] = useState('result');
  const intl = useIntl();
  const { setModalData } = usePreviewModalContext();

  useEffect(() => {
    if (session && sporterId) {
      session.getReportForPerson(sporterId);
    }
  }, [session, sporterId]);

  return (
    <>
      <Card secondaryCollapsible>
        <CardHeader secondary>
          <CardHeaderTitle>
            <FormattedMessage {...messages.preventionTrainingFilter} />
          </CardHeaderTitle>
        </CardHeader>
        <CardBody secondary>
          <Select
            label={intl.formatMessage(
              messages.preventionTrainingSelectExercises
            )}
            name={'screening-name-field'}
            value={exerciseSelect}
            onChange={select => {
              setExerciseSelect(select.value);
            }}
          >
            <Option
              key={1}
              value={'result'}
              label={intl.formatMessage(
                messages.preventionTrainingResultsOption
              )}
            />
            <Option
              key={2}
              value={'exercises'}
              label={intl.formatMessage(
                messages.preventionTrainingExercisesOption
              )}
            />
          </Select>
        </CardBody>
      </Card>

      {exerciseSelect === 'exercises' && (
        <SelectExercisesCard
          entityId={entityId}
          edit={edit}
          onPreview={exercise =>
            setModalData({ type: 'exercise', data: exercise })
          }
        />
      )}

      {exerciseSelect === 'result' &&
        groupedTests &&
        Object.keys(groupedTests).map((category, i) => (
          <TrainingAdviceCategoryCard
            key={uid(category, i)}
            category={category}
            tests={groupedTests[category]}
            options={options}
            compressedTable={true}
            entity={entityId}
            onPreview={exercise =>
              setModalData({ type: 'exercise', data: exercise })
            }
            edit={edit}
          />
        ))}
    </>
  );
};

export default TrainingSelectTests;
