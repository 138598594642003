export const roundSecondsOrMinutes = (value, unit) => {
  const values = value.map(v => {
    // v = parseFloat(v.toFixed(2));
    if (unit === 'min' || unit === 's') {
      return ` ${Math.round(v * 100) / 100}`;
    }
    if (Number.isInteger(v)) {
      return ` ${v}`;
    } else {
      try {
        return ` ${v.toFixed(2)}`;
      } catch (error) {
        return ` ${v}`;
      }
    }
    // return v;//.toFixed(2);
  });
  values[0] = values[0].trim();

  return values.toString();
};

export const secondsToHHMMSS = s => {
  const hh = Math.floor(s / 3600)
    .toString()
    .padStart(2, '0');
  const mm = Math.floor((s % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const ss = Math.floor(s % 60)
    .toString()
    .padStart(2, '0');

  return `${hh}:${mm}:${ss}`;
};

export const secondsToMMSS = s => {
  /*const hh = Math.floor(s / 3600)
    .toString()
    .padStart(2, '0');*/
  const mm = Math.floor((s % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const ss = Math.floor(s % 60)
    .toString()
    .padStart(2, '0');

  return `${mm}:${ss}`;
};

export const mmssToSeconds = mmss => {
  if (!mmss.includes(':')) return mmss;

  const [mm, ss] = mmss.split(':');
  return (mm ? parseInt(mm) * 60 : mm) + (ss ? parseInt(ss || 0) : ss);
};
