import React, { Fragment, useContext } from 'react';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch, generatePath } from 'react-router-dom';
import {
  ROUTE_GROWTH_PREDICTION_ENTITY,
  ROUTE_GROWTH_PREDICTION_ENTITY_GROUP,
  ROUTE_GROWTH_PREDICTION_ENTITY_POPUP,
  ROUTE_SPORTERS_ENTITY
} from 'routes/RouteList';
import GrowthPredictionMainModal from './modals/GrowthPredictionMainModal';
import Group from './Group';
import { useIntl } from 'react-intl';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import NoAccessPanel from 'containers/pages/auth/NoAccessPanel';
import messages from 'messages';
import { StoreContext } from 'index';

const GrowthPrediction = ({}) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);

  const intl = useIntl();
  const ability = useAbility(AbilityContext);

  if (ability.cannot('read', 'Growthtracker')) {
    return (
      <NoAccessPanel
        entityId={user.rootEntityId}
        emptyLabel={intl.formatMessage(messages.noAccessEmptyPanel)}
        redirectRoute={generatePath(ROUTE_SPORTERS_ENTITY, {
          entityId: user.rootEntityId
        })}
      />
    );
  }

  return (
    <Fragment>
      <Route
        path={ROUTE_GROWTH_PREDICTION_ENTITY_POPUP}
        render={props => (
          <GrowthPredictionMainModal
            master
            entityId={props.match.params.entityId}
          />
        )}
      />
      <Switch>
        <Route
          path={ROUTE_GROWTH_PREDICTION_ENTITY_GROUP}
          render={props => (
            <Group
              entityId={props.match.params.entityId}
              groupId={props.match.params.groupId}
              testSetId={props.match.params.testSetId}
              benchmarkId={props.match.params.benchmarkId}
            />
          )}
        />
        <Redirect
          to={ROUTE_GROWTH_PREDICTION_ENTITY.replace(
            ':entityId',
            user.rootEntityId
          ).replace(':popup', 'popup')}
        />
      </Switch>
    </Fragment>
  );
};

export default observer(GrowthPrediction);
