import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Route, Switch, Redirect } from 'react-router-dom';

import {
  ROUTE_TESTS_PACKAGE_SESSION,
  ROUTE_SURVEY_PACKAGE_SESSION_SPORTER,
  ROUTE_SURVEY_PACKAGE_SESSION_LIST
} from 'routes/RouteList';
import TestSession from './TestSession';
import TestSessionSporterList from './TestSessionSporterList';
import { PackageTypes } from 'constants.js';
import { SessionContextProvider } from 'contexts/SessionContext';

class Tests extends Component {
  state = { hasAuthentication: false };
  async componentDidMount() {
    const { authStore } = this.props;
    if (!authStore.isLoggedIn) {
      await authStore.loginAsTester();
    }
    this.setState({ hasAuthentication: true });
  }
  render() {
    const { hasAuthentication } = this.state;
    if (!hasAuthentication) {
      return null;
    }
    return (
      <Fragment>
        <Switch>
          <Route
            exact
            path={ROUTE_SURVEY_PACKAGE_SESSION_LIST}
            render={props => (
              <TestSessionSporterList
                packageId={PackageTypes.SAP}
                entityId={props.match.params.entityId}
                sessionId={props.match.params.sessionId}
              />
            )}
          />
          <Route
            exact
            path={ROUTE_SURVEY_PACKAGE_SESSION_SPORTER}
            render={props => (
              <SessionContextProvider>
                <TestSession
                  packageId={props.match.params.packageId}
                  entityId={props.match.params.entityId}
                  sessionId={props.match.params.sessionId}
                  sporterId={props.match.params.sporterId}
                />
              </SessionContextProvider>
            )}
          />
          <Route
            exact
            path={ROUTE_TESTS_PACKAGE_SESSION}
            render={props => (
              <SessionContextProvider>
                <TestSession
                  packageId={props.match.params.packageId}
                  entityId={props.match.params.entityId}
                  sessionId={props.match.params.sessionId}
                />
              </SessionContextProvider>
            )}
          />
          <Redirect to="/" />
        </Switch>
      </Fragment>
    );
  }
}

export default withRouter(inject('uiState', 'authStore')(observer(Tests)));
