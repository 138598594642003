import React from 'react';
import classNames from 'classnames';

class PanelHeader extends React.Component {
  render() {
    const { ...props } = this.props;
    return (
      <div
        className={classNames('c-panel__header', props.extraClassNames, {
          'c-panel__header--home': props.home,
          'c-panel__header--border-bottom': props.borderBottom
        })}
      >
        {props.children}
      </div>
    );
  }
}

export default PanelHeader;
