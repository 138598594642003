import { useCallback, useContext, useEffect, useState } from 'react';
import { StoreContext } from 'index';
import VirtualizedList from 'containers/partials/lists/VirtualizedList';
import useFiles from 'hooks/queries/useFiles';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import CardBody from 'components/card/CardBody';
import classNames from 'classnames';
import FileUppyWrapper from 'containers/partials/file-upload/FileUppyWrapper';
import { FILE_TYPES } from 'constants.js';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Card from 'components/card/Card';
import useSelectable from 'hooks/utils/useSelectable';
import { useMenuContext } from 'contexts/MenuContext';
import RemoveFilesButton from 'containers/partials/buttons/RemoveFilesButton';
import { SORT_DATA_TYPES, SORT_DIRECTION } from 'utils/sort';
import SortableListHeader from 'components/list/SortableListHeader';
import { Accordion, AccordionContent } from 'components/accordion';
import AccordionButtonHooks from 'components/accordion/AccordionButtonHooks';
import DownloadFileButton from 'containers/partials/buttons/DownloadFileButton';
import Loader from 'components/loader/Loader';
import MetaWrapper from 'containers/partials/list-items/MetaWrapper';
import { useNotificationQueue } from 'components/notification';
import { MUTATION_EDIT_FILE } from 'services/aws/file-upload';
import TooltipPopper from 'components/tooltip/TooltipPopper';

const columns = [
  {
    key: 'id',
    label: '',
    sortable: false
  },
  {
    key: 'filename',
    label: 'Document name',
    sortable: true,
    dir: SORT_DIRECTION.ASC,
    dataType: SORT_DATA_TYPES.STRING
  },
  {
    key: 'date_created',
    label: 'Creation date',
    default: true,
    sortable: true,
    dir: SORT_DIRECTION.DESC,
    dataType: SORT_DATA_TYPES.STRING
  }
];

const SporterDocs = ({ entityId, personId }) => {
  const {
    uiState,
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const intl = useIntl();
  const notification = useNotificationQueue();
  const { files, loading, getFiles, sortFiles } = useFiles({
    entityId: rootEntityId,
    linkId: personId,
    activeSort: columns.find(c => c.default) || columns[0]
  });
  const { selected, setSelected } = useSelectable();
  const { addMenuItems, removeAllMenuItems } = useMenuContext();
  const [openAccordion, setOpenAccordion] = useState(false);

  const toggleDeleteMenuItem = selected => {
    return (
      <RemoveFilesButton
        key="deleteDocs"
        entityId={entityId}
        menu
        ids={selected}
        onComplete={refetchFiles}
        disabled={selected.length === 0}
      >
        {selected.length === 0
          ? intl.formatMessage(messages.menuItemUnselectedDocumentsToRemove)
          : intl.formatMessage(messages.menuItemRemoveSelectedDocuments, {
              count: selected.length
            })}
      </RemoveFilesButton>
    );
  };

  useEffect(() => {
    getFiles();
    addMenuItems(toggleDeleteMenuItem(selected));

    return () => {
      removeAllMenuItems();
      setSelected([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilesUploaded = async () => {
    setTimeout(async () => {
      await getFiles();
    }, 1000);
  };

  const onSelected = selected => {
    addMenuItems(toggleDeleteMenuItem(selected));
  };

  const refetchFiles = useCallback(async () => {
    await getFiles();
    setSelected([]);
  }, [getFiles, setSelected]);

  const onClickPersonFilePrivacyHandler = (file, onTogglePrivacy) => {
    // @ts-ignore
    uiState.showModal({
      title: intl.formatMessage(messages.modalConfirmToggleVisibilityFileTitle),
      message: intl.formatMessage(messages.modalConfirmVisibilityFileMessage, {
        visibility: file.sharedWithAthlete ? 'hidden' : 'visible'
      }),
      okLabel: intl.formatMessage(
        messages.modalConfirmVisibilityFileAcceptButton
      ),

      dismissLabel: intl.formatMessage(messages.modalConfirmRemoveCancelButton),
      mutationData: {
        mutation: MUTATION_EDIT_FILE,
        variables: {
          entityId,
          ...file,
          sharedWithAthlete: !file.sharedWithAthlete
        },
        refetchQueries: [],
        update: (cache, { data: { editFile } }) => {
          if (editFile) {
            notification.add(`fileupdate_success_${file.id}`, {
              message: intl.formatMessage(messages.fileEditSuccessfully)
            });
            onTogglePrivacy && onTogglePrivacy(file.id);
          }
        },
        onError: error => {
          notification.add(`fileupdate_error_${file.id}`, {
            message:
              error.message === 'edit_not_possible'
                ? intl.formatMessage(messages.fileEditFailed)
                : error.message,
            level: 'error'
          });
        }
      }
    });
  };

  return (
    <>
      <Card secondary extraClassNames="c-card--small-padding">
        <Accordion>
          {() => (
            <>
              <AccordionButtonHooks
                id="trigger-1"
                name="accordion-story"
                checkbox
                isControlled
                checked={openAccordion}
                onChange={() => setOpenAccordion(!openAccordion)}
              >
                <CardHeader secondary>
                  <CardHeaderTitle>
                    <FormattedMessage {...messages.uploadLabel} />
                  </CardHeaderTitle>
                </CardHeader>
              </AccordionButtonHooks>
              <AccordionContent>
                <CardBody secondary extraClassNames="u-margin-top-small">
                  <div
                    className={classNames(
                      'c-file-upload--state c-file-upload--state-small'
                    )}
                  >
                    <div className="c-media">
                      <FileUppyWrapper
                        entityId={entityId}
                        linkId={personId}
                        type={'person_doc'}
                        accept={[
                          ...FILE_TYPES.DOCUMENTS,
                          ...FILE_TYPES.PDF,
                          ...FILE_TYPES.IMAGE
                        ]}
                        onFilesUploaded={onFilesUploaded}
                        maxFiles={1}
                        isPublic
                      ></FileUppyWrapper>
                    </div>
                  </div>
                </CardBody>
              </AccordionContent>
            </>
          )}
        </Accordion>
      </Card>
      <Card
        secondary
        extraClassNames={classNames(
          'c-card--small-padding u-padding-bottom-none',
          {
            'c-card--empty-home': files?.length <= 0
          }
        )}
      >
        {loading && <Loader />}
        <CardHeader secondary extraClassNames="u-margin-bottom-none">
          <CardHeaderText>
            <CardHeaderTitle>
              <FormattedMessage {...messages.uploadedDocuments} />
            </CardHeaderTitle>
          </CardHeaderText>
        </CardHeader>
        <CardBody secondary>
          <VirtualizedList
            extraClassNames="c-list--sortable-two-columns-with-button"
            items={files}
            titleLabel={'filename'}
            titleAttribute={'filename'}
            metaLabel={'date_created'}
            icon={'folder'}
            mediumIcon
            supertitle
            entityId={entityId}
            checkable
            selectedItems={selected}
            onSelect={selected => onSelected(selected)}
            meta={
              <MetaWrapper toggler>
                <DownloadFileButton />

                <TogglePersonFilePrivacyPopper
                  item
                  extraClassNames="u-margin-right-medium"
                  onClickStatus={parameters =>
                    onClickPersonFilePrivacyHandler(parameters)
                  }
                  onTogglePrivacy={() => refetchFiles()}
                />
              </MetaWrapper>
            }
            sortableListHeader={
              <SortableListHeader
                sticky
                onSort={activeSort => {
                  sortFiles(files, [activeSort]);
                }}
                columns={columns}
              />
            }
          />
        </CardBody>
      </Card>
    </>
  );
};

export default SporterDocs;

const TogglePersonFilePrivacyPopper = ({
  item,
  onClickStatus,
  onTogglePrivacy,
  extraClassNames
}) => {
  return (
    <TooltipPopper
      info
      extraClassNames={extraClassNames}
      id={`popper_tooltip_${item?.id}`}
      icon={item?.sharedWithAthlete ? 'eye-enabled' : 'eye-disabled'}
      iconStrokeColor="color-neutral-dark"
      onMouseEnter={() => true}
      onFocus={() => true}
      onMouseLeave={() => false}
      onBlur={() => false}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();

        onClickStatus && onClickStatus(item, onTogglePrivacy);
      }}
    >
      <FormattedMessage
        {...messages.messageTooltipToggleVisibilityDocument}
        values={{
          visibility: item?.sharedWithAthlete,
          b: chunks => <b>{chunks}</b>
        }}
      />
    </TooltipPopper>
  );
};
