import { BroadcastChannel } from 'broadcast-channel';

export default class BroadcastStore {
  _broadcaster;
  constructor() {
    this._broadcaster = new BroadcastChannel('sportkompas');
    this._broadcaster.addEventListener('message', this.onmessage);
  }

  postMessage = () => {
    this._broadcaster.postMessage('reload');
  };

  listen = () => {};

  onmessage = () => {
    window.location.reload();
  };
}
