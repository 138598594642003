import { appLocales } from '../i18n';

export const getLocalFromUrl = () => {
  const { location } = document;
  const languageExists = appLocales.find(locale => {
    return location.pathname.startsWith(`/${locale}`);
  });
  if (languageExists) return languageExists;
  return null;
};

export const getLocalFromBrowser = () => {
  if (navigator.language) {
    const userLang = navigator.language;
    if (userLang.search('nl') !== -1) {
      return 'nl';
    }
  }
  return 'en';
};

export const getAppLocale = locale => {
  const languageExists = appLocales.find(appLocale => {
    return appLocale.startsWith(`${locale}`);
  });
  if (languageExists) return languageExists;
  return 'en-en';
};
