import classNames from 'classnames';
import Icon from 'components/icon/Icon';
import Select, { components } from 'react-select';

const selectStyles = {
  container: (provided, state) => ({
    ...provided
    // ...styles?.container, // overrule the default styles
  }),
  option: (provided, state) => ({
    ...provided,
    isDisabled: state.isSelected
    // ...styles?.option, // overrule the default styles
  }),
  control: (provided, state) => ({
    ...provided
  }),
  menu: (provided, state) => ({
    ...provided
  }),
  indicatorSeparator: () => null,
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon
        id="dropdown-open"
        extraClassNames={classNames('c-icon--input c-icon--input-left')}
      />
    </components.DropdownIndicator>
  );
};

// customMultiValue: gebruikt om sporters uit te filteren in `ResultsTableHeader.js`
// TODO: Fabian, finetunen a11y.
const ValueContainer = ({ children, ...props }) => {
  return props.selectProps?.value?.length > 1 ? (
    <components.ValueContainer {...props}>
      {props.selectProps.value.length} filtered {children[1]}
    </components.ValueContainer>
  ) : (
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  );
};

const MultiValueContainer = props => {
  return <>{props.selectProps.value.length} filtered</>;
};

const FieldSelect = props => {
  const {
    id,
    extraClassNames,
    readOnly,
    disabled,
    inline,
    standalone,
    label,
    labelGroup,
    name,
    options,
    value,
    isSearchable,
    isClearable,
    isMulti,
    onChange,
    onBlur,
    customMultiValue,
    placeholder,
    defaultMenuIsOpen
  } = props;
  return (
    <div
      className={classNames(
        'c-input__group c-input__group-select',
        extraClassNames,
        {
          'c-input--read-only c-input--disabled': readOnly || disabled,
          'c-input--inline': inline,
          'c-input__group-select-standalone': standalone,
          'c-input-select--menu-250-width': customMultiValue
        }
      )}
      onClick={e => e.stopPropagation()}
    >
      {label && (
        <label htmlFor={id} className={classNames('c-input__label', {})}>
          {label}
        </label>
      )}
      <div
        className={classNames('c-input__wrapper c-menu__wrapper', {
          'c-input__wrapper--select': !readOnly && !disabled
        })}
      >
        {labelGroup && ( //selectedItem &&
          <div className={classNames('c-input__sup-value')}>{labelGroup}</div>
        )}
        {readOnly ? (
          <input
            value={value?.label ?? ''}
            className={classNames('c-input')}
            readOnly
          />
        ) : (
          <Select
            id={id}
            name={name}
            options={options}
            value={value}
            isDisabled={disabled}
            isSearchable={isSearchable}
            isClearable={isClearable}
            isMulti={isMulti}
            onChange={onChange}
            onBlur={onBlur}
            className="c-input-select"
            classNamePrefix="c-input-select"
            styles={selectStyles}
            components={
              customMultiValue
                ? { DropdownIndicator, MultiValueContainer, ValueContainer }
                : { DropdownIndicator }
            }
            hideSelectedOptions={false}
            placeholder={placeholder ?? ''}
            defaultMenuIsOpen={defaultMenuIsOpen}
          />
        )}
      </div>
    </div>
  );
};

export default FieldSelect;
