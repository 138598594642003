import React, { useContext } from 'react';
import {
  Redirect,
  Route,
  Switch,
  generatePath,
  withRouter
} from 'react-router-dom';
import {
  ROUTE_EXERCISES_COLLECTIONS_ENTITY,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES_EXERCISE,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_MY_EXERCISES,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_MY_EXERCISES_EXERCISE,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_MY_EXERCISES_EXERCISE_EDIT
} from 'routes/RouteList';
import { ExercisesProvider } from 'contexts/ExercisesContext';
import { StoreContext } from 'index';
import { PreviewModalProvider } from 'contexts/PreviewModalContext';
import messages from 'messages';
import Panel from 'components/panel/Panel';
import { useIntl } from 'react-intl';
import { TemplatesProvider } from 'contexts/TemplatesContext';
import Collections from 'containers/pages/exercises/collections/Collections';
import Collection from 'containers/pages/exercises/collections/Collection';
import Exercise from 'containers/pages/exercises/exercises/Exercise';
import MyExercises from 'containers/pages/exercises/collections/MyExercises';
import { TemplateType } from 'constants.js';

const GSVCollections = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();
  return (
    <>
      <TemplatesProvider
        entityId={user.rootEntityId}
        type={TemplateType.COLLECTION}
      >
        <PreviewModalProvider entityId={user.rootEntityId}>
          <ExercisesProvider user={user} entityId={user.rootEntityId}>
            <Switch>
              <Route
                exact
                path={ROUTE_EXERCISES_COLLECTIONS_ENTITY}
                render={props => (
                  <>
                    <Collections
                      entityId={props.match.params.entityId}
                      active={
                        ROUTE_EXERCISES_COLLECTIONS_ENTITY === props.match.path
                      }
                    />
                    <Panel
                      emptyState
                      emptyLabel={intl.formatMessage(
                        messages.templateEmptyPanel
                      )}
                    />
                  </>
                )}
              />

              <Route
                exact
                path={[
                  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_MY_EXERCISES,
                  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_MY_EXERCISES_EXERCISE,
                  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_MY_EXERCISES_EXERCISE_EDIT
                ]}
                render={props => (
                  <>
                    <Collections
                      entityId={props.match.params.entityId}
                      active={
                        ROUTE_EXERCISES_COLLECTIONS_ENTITY === props.match.path
                      }
                    />
                    <MyExercises
                      entityId={props.match.params.entityId}
                      exerciseId={props.match.params.exerciseId}
                      active={
                        ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_MY_EXERCISES ===
                        props.match.path
                      }
                      collectionId={'my-exercises'}
                    />
                    <Route
                      exact
                      path={[
                        ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_MY_EXERCISES_EXERCISE,
                        ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_MY_EXERCISES_EXERCISE_EDIT
                      ]}
                      render={props => (
                        <Exercise
                          entityId={props.match.params.entityId}
                          active={
                            ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_MY_EXERCISES_EXERCISE ===
                            props.match.path
                          }
                          edit={
                            ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_MY_EXERCISES_EXERCISE_EDIT ===
                            props.match.path
                          }
                          editable={true}
                          exerciseId={props.match.params.exerciseId}
                          parentPath={generatePath(
                            ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_MY_EXERCISES_EXERCISE,
                            {
                              entityId: props.match.params.entityId,
                              exerciseId: props.match.params.exerciseId
                            }
                          )}
                          crumbs={[
                            {
                              path: generatePath(
                                ROUTE_EXERCISES_COLLECTIONS_ENTITY,
                                {
                                  entityId: props.match.params.entityId
                                }
                              ),
                              label: intl.formatMessage(
                                messages.breadcrumbCollections
                              )
                            },
                            {
                              path: generatePath(
                                ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_MY_EXERCISES,
                                {
                                  entityId: props.match.params.entityId
                                }
                              ),
                              label: intl.formatMessage(
                                messages.breadcrumbMyExercises
                              )
                            }
                          ]}
                        />
                      )}
                    />
                  </>
                )}
              />

              <Route
                exact
                path={[
                  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION,
                  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES,
                  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES_EXERCISE
                ]}
                render={props => (
                  <>
                    <Collections
                      entityId={props.match.params.entityId}
                      collectionId={props.match.params.collectionId}
                      active={
                        ROUTE_EXERCISES_COLLECTIONS_ENTITY === props.match.path
                      }
                    />
                    <Collection
                      entityId={props.match.params.entityId}
                      active={
                        ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION ===
                        props.match.path
                      }
                      collectionId={props.match.params.collectionId}
                      exerciseId={props.match.params.exerciseId}
                    />
                    <Route
                      exact
                      path={[
                        ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES_EXERCISE
                      ]}
                      render={props => (
                        <Exercise
                          entityId={props.match.params.entityId}
                          active={
                            ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES_EXERCISE ===
                            props.match.path
                          }
                          editable={false}
                          inCollection
                          exerciseId={props.match.params.exerciseId}
                          collectionId={props.match.params.collectionId}
                          parentPath={generatePath(
                            ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES_EXERCISE,
                            {
                              entityId: props.match.params.entityId,
                              collectionId: props.match.params.collectionId,
                              exerciseId: props.match.params.exerciseId
                            }
                          )}
                          crumbs={[
                            {
                              path: generatePath(
                                ROUTE_EXERCISES_COLLECTIONS_ENTITY,
                                {
                                  entityId: props.match.params.entityId
                                }
                              ),
                              label: intl.formatMessage(
                                messages.breadcrumbCollections
                              )
                            },
                            {
                              path: generatePath(
                                ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION,
                                {
                                  entityId: props.match.params.entityId,
                                  collectionId: props.match.params.collectionId
                                }
                              ),
                              label: intl.formatMessage(
                                messages.breadcrumbCollection
                              )
                            }
                          ]}
                        />
                      )}
                    />
                  </>
                )}
              />

              <Redirect
                to={generatePath(ROUTE_EXERCISES_COLLECTIONS_ENTITY, {
                  entityId: user.rootEntityId
                })}
              />
            </Switch>
          </ExercisesProvider>
        </PreviewModalProvider>
      </TemplatesProvider>
    </>
  );
};

export default withRouter(GSVCollections);
