import React, { Fragment } from 'react';
import { inject } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { CardBody, CardFooter } from 'components/card';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import messages from 'messages';
import { GrowthPredictionModalView } from './growth-prediction-modal-view';
import ButtonCard from 'components/button/ButtonCard';

// TODO: Currently unused, we could rid of it, couldn't we?
class GrowthPredictionChoiceView extends React.Component {
  state = {};

  render() {
    const { onChangeView, intl } = this.props;
    return (
      <Fragment>
        <CardHeader modal>
          <CardHeaderTitle>
            <FormattedMessage {...messages.growthPredictionChoiceModalTitle} />
          </CardHeaderTitle>
          <CardHeaderSubtitle>
            <FormattedMessage
              {...messages.growthPredictionChoiceModalSubTitle}
            />
          </CardHeaderSubtitle>
        </CardHeader>
        <CardBody modal>
          <ul className="o-list-bare c-card__list-packages c-card__list-packages-modal">
            <li className="o-list-bare__item c-card__list-packages-item">
              <ButtonCard
                disabled
                onClick={() =>
                  onChangeView(
                    GrowthPredictionModalView.GROWTH_PREDICTION_SPORTER
                  )
                }
                iconId="sporters"
                iconFillColor="color-neutral-x-light"
                title={intl.formatMessage(
                  messages.growthPredictionChoiceModalSportersTitleButton
                )}
                subtitle={intl.formatMessage(
                  messages.growthPredictionChoiceModalSportersDescriptionButton
                )}
                ariaLabel={intl.formatMessage(
                  messages.growthPredictionChoiceModalSportersDescriptionButton
                )}
              />
            </li>
            <li className="o-list-bare__item c-card__list-packages-item">
              <ButtonCard
                onClick={() =>
                  onChangeView(
                    GrowthPredictionModalView.GROWTH_PREDICTION_GROUP
                  )
                }
                iconId="group-new-icon"
                title={intl.formatMessage(
                  messages.growthPredictionChoiceModalGroupsTitleButton
                )}
                subtitle={intl.formatMessage(
                  messages.growthPredictionChoiceModalGroupsDescriptionButton
                )}
                ariaLabel={intl.formatMessage(
                  messages.growthPredictionChoiceModalGroupsDescriptionButton
                )}
              />
            </li>
            <li className="o-list-bare__item c-card__list-packages-item">
              <ButtonCard
                disabled
                onClick={() =>
                  onChangeView(
                    GrowthPredictionModalView.GROWTH_PREDICTION_SESSION
                  )
                }
                iconId="sessions-white"
                title={intl.formatMessage(
                  messages.growthPredictionChoiceModalSessionsTitleButton
                )}
                subtitle={intl.formatMessage(
                  messages.growthPredictionChoiceModalSessionsDescriptionButton
                )}
                ariaLabel={intl.formatMessage(
                  messages.growthPredictionChoiceModalSessionsDescriptionButton
                )}
              />
            </li>
          </ul>
        </CardBody>
        <CardFooter modal />
      </Fragment>
    );
  }
}

export default withRouter(
  injectIntl(inject('routing', 'authStore')(GrowthPredictionChoiceView))
);
