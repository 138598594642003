import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../icon/Icon';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import InputErrorMessage from './InputErrorMessage';

export const FieldTextarea = forwardRef((props, ref) => {
  const {
    disabled,
    readOnly,
    id,
    children,
    name,
    resizeVertical,
    characterLimit,
    extraClassNames,
    minLength,
    maxLength,
    required,
    autoFocus,
    placeholder,
    icon,
    iconColor,
    iconLeft,
    errors,
    touched,
    onChange,
    onBlur,
    rows = '5',
    value,
    errorMessage,
    edit = true,
    ...rest
  } = props;

  const { setFieldValue, validateField, validationSchema } = useFormikContext();
  const [charactersRemaining, setCharactersRemaining] = useState(
    characterLimit ?? 0
  );
  const onChangeHandler = event => {
    if (characterLimit) {
      setCharactersRemaining(characterLimit - event.target.value.length);
    }
    setFieldValue(name, event.target.value);

    try {
      validateField(name);
    } catch (e) {
      // TODO Formik v3 zal validationSchema terug geven
      // console.log('validationSchema', validationSchema);
    }
  };

  return (
    <div
      className={classNames('c-input__group', extraClassNames, {
        'c-input--closed': disabled,
        'c-input--read-only': readOnly
      })}
    >
      {id && children && (
        <label
          htmlFor={id}
          className={classNames('c-input__label', {
            'c-input__label-flex': characterLimit
          })}
        >
          {children}

          {characterLimit && (
            <div className="c-input__label-extra-info">
              <span>
                <FormattedMessage
                  {...messages.textareaCharacterLimit}
                  values={{
                    limit: characterLimit
                  }}
                />{' '}
                {charactersRemaining < characterLimit &&
                  charactersRemaining > 0 && (
                    <FormattedMessage
                      {...messages.textareaCharacterCount}
                      values={{
                        count: charactersRemaining
                      }}
                    />
                  )}
              </span>
              {charactersRemaining < 0 && (
                <span
                  className={classNames('', {
                    'u-color-error': characterLimit && charactersRemaining < 0
                  })}
                >
                  <FormattedMessage
                    {...messages.textareaCharacterCountWarning}
                    values={{
                      count: charactersRemaining * -1
                    }}
                  />
                </span>
              )}
            </div>
          )}
        </label>
      )}

      <div className="c-input__wrapper">
        <Field
          as="textarea"
          id={id}
          innerRef={ref}
          name={name}
          minLength={minLength}
          maxLength={maxLength}
          rows={rows}
          className={classNames('c-input', {
            'c-input--textarea-resize-vertical': resizeVertical,
            'c-input--textarea': !resizeVertical,
            'has-error': charactersRemaining < 0
          })}
          disabled={disabled}
          readOnly={readOnly}
          required={required}
          autoFocus={autoFocus}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={event => onChangeHandler(event)}
        />

        {icon && !readOnly && (
          <Icon
            id={icon}
            fillColor={iconColor}
            extraClassNames={classNames('c-icon--input', {
              'c-icon--input-left': iconLeft
            })}
          />
        )}
      </div>

      {errors?.[name] && typeof errors[name] === 'string' && (
        <ErrorMessage name={name} component={InputErrorMessage} />
      )}
    </div>
  );
});

FieldTextarea.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  edit: PropTypes.bool,
  name: PropTypes.string,
  resizeVertical: PropTypes.bool,
  characterLimit: PropTypes.number,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  autoFocus: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  iconLeft: PropTypes.bool,
  errors: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  rows: PropTypes.string,
  value: PropTypes.string,
  errorMessage: PropTypes.string
};
