import { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { generatePath } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import Panel from 'components/panel/Panel';
import { ROUTE_EXERCISES_ENTITY_EXERCISE } from 'routes/RouteList';
import messages from 'messages';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import { PanelBody, PanelHeader } from 'components/panel';
import {
  PanelTitle,
  PanelTitleButton,
  PanelTitleWrapper
} from 'components/panel/panel-title';
import PanelTools from 'components/panel/PanelTools';
import { StoreContext } from 'index';
import { useExercisesContext } from 'contexts/ExercisesContext';
import { usePreviewModalContext } from 'contexts/PreviewModalContext';
import Modal from 'components/modal/Modal';
import FilterPanel from 'containers/pages/exercises/collections/FilterPanel';
import BreadcrumbPath from 'containers/partials/misc/BreadcrumbPath';
import Loader from 'components/loader/Loader';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import { MenuWrapper } from 'components/menu';
import AddExercisesToCollectionModal from 'containers/pages/exercises/collections/AddExercisesToCollectionModal';
import { useMutation } from '@apollo/client';
import {
  MUTATION_ADD_EXERCISES_TO_TEMPLATES,
  QUERY_GET_TEMPLATES
} from 'services/aws/templates-query';
import { useNotificationQueue } from 'components/notification';
import Tags from 'containers/partials/inputs/Tags';
import { useTemplatesContext } from 'contexts/TemplatesContext';
import { QUERY_GET_EXERCISES } from 'services/aws/exercises-query';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import ButtonsGroup from 'components/button/ButtonsGroup';
import AddExerciseModal from 'containers/pages/exercises/exercises/AddExerciseModal';
import { TabList, Tab, TabPanel, Tabs } from 'components/tabs';
import useMyExercises from 'hooks/queries/useMyExercises';
import FieldInput from 'components/input/FieldInput';
import ExercisesPanel from 'containers/pages/exercises/exercises/ExercisesPanel';
import ExercisesForAthletePanel from 'containers/pages/exercises/exercises/ExercisesForAthletePanel';
import ExercisesByMePanel from 'containers/pages/exercises/exercises/ExercisesByMePanel';

const Exercises = ({ active, exerciseId, entityId, crumbs }) => {
  const {
    authStore: { user },
    routing: { push }
  } = useContext(StoreContext);
  const ability = useAbility(AbilityContext);
  const {
    exercisesState,
    exerciseActions,
    loading: loadingAll,
    error: errorAll
  } = useExercisesContext();
  const { actions } = useTemplatesContext();
  const notification = useNotificationQueue();
  const intl = useIntl();
  const { setModalData } = usePreviewModalContext();
  const [addModalData, setAddModalData] = useState(null);
  const [addToCollectionModal, setAddToCollectionModal] = useState(null);
  const [filterPanel, setFilterPanel] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const [addExercises] = useMutation(MUTATION_ADD_EXERCISES_TO_TEMPLATES, {
    refetchQueries: [
      { query: QUERY_GET_TEMPLATES, variables: { entityId } },
      { query: QUERY_GET_EXERCISES, variables: { entityId } }
    ]
  });

  const { setFilter } = useMyExercises(entityId, user);

  const addToCollectionHandler = () => {
    setAddToCollectionModal(true);
  };

  const onAddExercisesToCollection = async collections => {
    await addExercises({
      variables: {
        exerciseIds: exercisesState.selectedExercises.map(e => e.id),
        programTemplateIds: collections
      }
    }).then(
      () => {
        notification.add('add_exercises', {
          message: intl.formatMessage(messages.messageExercisesAddedToTemplate)
        });
        actions.getTemplates();
        setAddToCollectionModal(false);
        exerciseActions.setSelectedExercises([]);
      },
      () => {
        notification.add('edit_error', {
          message: intl.formatMessage(
            messages.messageExercisesAddedToTemplateError
          ),
          level: 'error'
        });
      }
    );
  };

  const onSearch = e => {
    exerciseActions.setFilter({ search: e.target.value });
    setFilter({ search: e.target.value });
  };

  const handleTagChange = tags => {
    exerciseActions.setFilter({ tags });
    setFilter({ tags });
  };

  const onClosePanel = () => setFilterPanel(false);

  if (errorAll) return <RetryPanel />;

  return (
    <Panel active={active}>
      <PanelHeader>
        <BreadcrumbPath crumbs={crumbs} />
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.exercisesTitle} />

            <MenuWrapper trigger={<PanelTitleButton />}>
              <Button
                menu
                onClick={addToCollectionHandler}
                disabled={
                  !exercisesState.selectedExercises ||
                  exercisesState.selectedExercises.length <= 0
                }
              >
                {exercisesState.selectedExercises &&
                exercisesState.selectedExercises.length > 0 ? (
                  <>
                    <Icon
                      id="add"
                      strokeColor="color-neutral-dark"
                      style={{ top: 'auto' }}
                    />

                    <FormattedMessage
                      {...messages.collectionAddExercisesToTemplateButton}
                      values={{
                        thisMany: exercisesState.selectedExercises.length,
                        type: user.isGSVOrg ? 'collection' : 'template'
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Icon id="add" style={{ top: 'auto', opacity: 0.35 }} />

                    <FormattedMessage
                      {...messages.collectionAddExercisesToTemplateDisabled}
                      values={{
                        type: user.isGSVOrg ? 'collection' : 'template'
                      }}
                    />
                  </>
                )}
              </Button>
            </MenuWrapper>
          </PanelTitle>
        </PanelTitleWrapper>
        <PanelTools extraClassNames="u-margin-bottom-none">
          <FieldInput rounded icon="search-grey" iconLeft onChange={onSearch} />

          <ButtonsGroup extraClassNames="u-margin-bottom-small">
            {user.isGSVOrg && (
              <Button rounded light onClick={() => setFilterPanel(true)}>
                <Icon id="filter" strokeColor="color-neutral-dark" />
                <FormattedMessage {...messages.filterButton} />
              </Button>
            )}
            {ability.can('create', 'Exercise') && (
              <Button rounded primary onClick={() => setAddModalData(true)}>
                <Icon id="add-white" />
                <FormattedMessage {...messages.exercisesAddExerciseButton} />
              </Button>
            )}
          </ButtonsGroup>

          <Tags
            extraClassNames="u-margin-left-none"
            entityId={entityId}
            label={intl.formatMessage(messages.exercisesLabelTags)}
            tags={exercisesState.filter.tags}
            isMulti
            isClearable={false}
            dropdownDisabled={user.isGSVOrg}
            onChange={handleTagChange}
            isLoading={loadingAll}
            placeholder={
              loadingAll
                ? intl.formatMessage(messages.globalLoading)
                : intl.formatMessage(messages.exercisesProgramTagsLabel)
            }
            noOptionsMessage={intl.formatMessage(
              messages.exercisesProgramTagsNoOption
            )}
            rounded
          />
        </PanelTools>
      </PanelHeader>

      <PanelBody>
        <Tabs
          marginNegativeTop={4}
          selectedIndex={tabIndex}
          onSelect={index => setTabIndex(index)}
        >
          <TabList>
            <Tab>
              {/*<Badge>{exercisesState.exercisesStore.length}</Badge>*/}
              <FormattedMessage {...messages.exercisesTabAllExercises} />
            </Tab>
            {!user.isGSVOrg && (
              <Tab>
                {/*<Badge>{exercisesState.exercisesStore.length}</Badge>*/}
                <FormattedMessage {...messages.exercisesTabMyExercises} />
              </Tab>
            )}
            {user.isAthlete() && user.isGSVOrg && (
              <Tab>
                {/*<Badge>{totalMyExercises}</Badge>*/}
                <FormattedMessage {...messages.exercisesTabMyExercises} />
              </Tab>
            )}
          </TabList>
          <TabPanel className="react-tabs__tab-panel u-padding-vertical-none">
            {loadingAll && <Loader />}
            <ExercisesPanel
              entityId={entityId}
              exerciseId={exerciseId}
              onPreview={exercise =>
                setModalData({ type: 'exercise', data: exercise })
              }
              onClick={id => {
                if (id !== exerciseId) {
                  push(
                    generatePath(ROUTE_EXERCISES_ENTITY_EXERCISE, {
                      entityId,
                      exerciseId: id
                    })
                  );
                }
              }}
            />
          </TabPanel>
          {!user.isGSVOrg && (
            <TabPanel className="react-tabs__tab-panel u-padding-vertical-none">
              {loadingAll && <Loader />}
              <ExercisesByMePanel
                entityId={entityId}
                exerciseId={exerciseId}
                onPreview={exercise =>
                  setModalData({ type: 'exercise', data: exercise })
                }
                onClick={id => {
                  if (id !== exerciseId) {
                    push(
                      generatePath(ROUTE_EXERCISES_ENTITY_EXERCISE, {
                        entityId,
                        exerciseId: id
                      })
                    );
                  }
                }}
              />
            </TabPanel>
          )}
          {user.isAthlete() && (
            <TabPanel className="react-tabs__tab-panel u-padding-vertical-none">
              <ExercisesForAthletePanel
                entityId={entityId}
                activeId={exerciseId}
                onPreview={exercise =>
                  setModalData({ type: 'exercise', data: exercise })
                }
                onClick={id => {
                  if (id !== exerciseId) {
                    push(
                      generatePath(ROUTE_EXERCISES_ENTITY_EXERCISE, {
                        entityId,
                        exerciseId: id
                      })
                    );
                  }
                }}
              />
            </TabPanel>
          )}
        </Tabs>
      </PanelBody>
      {filterPanel && (
        <Modal filter isOpen={true} onClose={onClosePanel}>
          <FilterPanel
            entityId={entityId}
            onCancel={onClosePanel}
            selectedTags={exercisesState.filter.tags}
            onSubmit={tags => {
              handleTagChange(tags);
              onClosePanel();
            }}
            {...filterPanel}
          />
        </Modal>
      )}
      {addToCollectionModal && (
        <AddExercisesToCollectionModal
          entityId={entityId}
          exercises={exercisesState.selectedExercises}
          onAdd={selected => onAddExercisesToCollection(selected)}
          onClose={() => {
            setAddToCollectionModal(false);
          }}
          collectionType={user.isGSVOrg ? 'Collection' : 'Template'}
        />
      )}
      {/*{addToProgramModal && (
        <AddExercisesToCollectionModal
          entityId={entityId}
          exercises={exercisesState.selectedExercises}
          onClose={() => {
            setAddToProgramModal(false);
          }}
        />
      )}*/}
      {addModalData && (
        <AddExerciseModal
          entityId={entityId}
          onClose={() => setAddModalData(false)}
        />
      )}
    </Panel>
  );
};

export default observer(Exercises);
