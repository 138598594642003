import React, { createContext, useReducer, useContext, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { sort } from 'utils/sort';
import { getFilteredEntities } from 'utils/search';
import Lesson from 'models/Lesson';
import { QUERY_GET_LESSONS } from 'services/aws/lessons-query';

export const LessonsContext = createContext();

const initialState = {
  lessonsStore: [],
  lessons: [],
  filter: {
    search: '',
    tags: []
  }
};

export const SET_LESSONS_STORE = 'SET_LESSONS_STORE';
export const SET_LESSONS = 'SET_LESSONS';

export const SET_FILTER = 'SET_FILTER';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_LESSONS_STORE:
      return {
        ...state,
        lessonsStore: [...action.payload]
      };
    case SET_LESSONS:
      return { ...state, lessons: [...action.payload] };

    case SET_FILTER:
      return { ...state, filter: { ...action.payload } };
    default:
      return state;
  }
};

const LessonsProvider = ({ entityId, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [getLessonsQuery, { loading, data }] = useLazyQuery(QUERY_GET_LESSONS, {
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    if (!data?.getExerciseProgramTemplates) return;

    const lessons = data.getExerciseProgramTemplates.map(
      lesson => new Lesson({ ...lesson })
    );
    dispatch({
      type: SET_LESSONS_STORE,
      payload: lessons
    });
    dispatch({
      type: SET_LESSONS,
      payload: lessons
    });
  }, [data]);

  useEffect(() => {
    if (state.filter) {
      const lessons = sort(
        getFilteredEntities(
          state.lessonsStore,
          state.filter.search ?? '',
          state.filter.tags ?? null
        ),
        {
          keys: [{ key: 'title' }]
        }
      );
      dispatch({
        type: SET_LESSONS,
        payload: lessons
      });
    }
  }, [state.lessonsStore, state.filter]);

  const actions = {
    getLessons: () => {
      getLessonsQuery({ variables: { entityId } });
    },
    setFilter: filter => {
      dispatch({
        type: SET_FILTER,
        payload: { ...state.filter, ...filter }
      });
    },
    resetFilter: () => {
      dispatch({
        type: SET_FILTER,
        payload: { ...initialState.filter }
      });
    }
  };

  return (
    <LessonsContext.Provider
      value={{
        loading,
        state,
        actions
      }}
    >
      {children}
    </LessonsContext.Provider>
  );
};

function useLessonsContext() {
  const context = useContext(LessonsContext);
  if (context === undefined) {
    throw new Error(
      'The LessonsContext hook must be used within a LessonsContext.Provider'
    );
  }
  return context;
}

export { LessonsProvider, useLessonsContext };
