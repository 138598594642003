// root auth
export const ROUTE_LOGIN = '/login';
export const ROUTE_SIGNUP = '/signup';
export const ROUTE_FORGOT = '/forgot';
export const ROUTE_FORGOT_SUCCESS = '/forgot-success';
export const ROUTE_NO_ACCESS = '/no-access';

export const ROUTE_NO_ROLES = '/noroles';

export const ROUTE_LOADING = '/loading/:type?';

export const ROUTE_HOME = '/';

// Packages
export const ROUTE_PACKAGES = '/packages';
export const ROUTE_PACKAGES_PACKAGE = `/packages/:packageType`;

// Settings
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_SETTINGS_SUB = '/settings/:setting';
export const ROUTE_SETTINGS_USERS = '/settings/users';
export const ROUTE_SETTINGS_USERS_ADD = '/settings/users/add';
export const ROUTE_SETTINGS_USERS_USER = '/settings/users/:userId';
export const ROUTE_SETTINGS_USERS_USER_EDIT = '/settings/users/:userId/edit';
export const ROUTE_SETTINGS_USERS_USER_ROLES = '/settings/users/:userId/roles';

export const ROUTE_SETTINGS_ORGANISATIONS = '/settings/organisations';
export const ROUTE_SETTINGS_ORGANISATIONS_ADD = '/settings/organisations/add';
export const ROUTE_SETTINGS_ORGANISATIONS_ORG =
  '/settings/organisations/:organisationId';
export const ROUTE_SETTINGS_ORGANISATIONS_ORG_EDIT =
  '/settings/organisations/:organisationId/edit';

export const ROUTE_SETTINGS_BENCHMARKS = '/settings/benchmarks';
// export const ROUTE_SETTINGS_BENCHMARKS_ADD = '/settings/benchmarks/add';
export const ROUTE_SETTINGS_BENCHMARKS_BENCHMARK =
  '/settings/benchmarks/:benchmarkId';
export const ROUTE_SETTINGS_BENCHMARKS_BENCHMARK_TEST =
  '/settings/benchmarks/:benchmarkId/test/:benchmarkTestId';
export const ROUTE_SETTINGS_BENCHMARKS_BENCHMARK_TEST_EDIT =
  '/settings/benchmarks/:benchmarkId/test/:benchmarkTestId/edit';

export const ROUTE_SETTINGS_TESTSETS = '/settings/testsets/:type?';
export const ROUTE_SETTINGS_TESTSETS_TESTSET =
  '/settings/testsets/:type/:testSetId';

export const ROUTE_SETTINGS_GLOBAL = '/settings/global';
export const ROUTE_SETTINGS_GLOBAL_EDIT = '/settings/global/edit';

export const ROUTE_SETTINGS_USAGE = '/settings/usage';
export const ROUTE_SETTINGS_DEMODATA = '/settings/demodata';

// Sporters
export const ROUTE_SPORTERS = '/athletes';
export const ROUTE_SPORTERS_ENTITY = '/athletes/:entityId';
export const ROUTE_SPORTERS_ENTITY_POOL = '/athletes/:entityId/all';
export const ROUTE_SPORTERS_ENTITY_ADD = '/athletes/:entityId/add';
export const ROUTE_SPORTERS_ENTITY_ADD_GROUP = '/athletes/:entityId/addgroup';
export const ROUTE_SPORTERS_ENTITY_EDIT_GROUP = '/athletes/:entityId/editgroup';
export const ROUTE_SPORTERS_ENTITY_SPORTER =
  '/athletes/:entityId/:sporterId([a-z0-9-]{36})';
export const ROUTE_SPORTERS_ENTITY_SPORTER_EDIT =
  '/athletes/:entityId/:sporterId([a-z0-9-]{36})/edit';
export const ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION =
  '/athletes/:entityId/:sporterId/sessions/:sessionId';
export const ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT =
  '/athletes/:entityId/:sporterId/sessions/:sessionId([a-z0-9-]{36})/report';
export const ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_EDIT =
  '/athletes/:entityId/:sporterId/sessions/:sessionId([a-z0-9-]{36})/tests/edit';
export const ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS =
  '/athletes/:entityId/:sporterId/sessions/:sessionId([a-z0-9-]{36})/tests';
export const ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL =
  '/athletes/:entityId/:sporterId/global';
export const ROUTE_SPORTERS_ENTITY_SPORTER_TESTS =
  '/athletes/:entityId/:sporterId/tests';
export const ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL_EDIT =
  '/athletes/:entityId/:sporterId/global/edit';

// Sessions
export const ROUTE_SESSIONS = '/sessions';
export const ROUTE_SESSIONS_ENTITY = '/sessions/:entityId';
export const ROUTE_SESSIONS_ENTITY_ADD = '/sessions/:entityId/add';
export const ROUTE_SESSIONS_ENTITY_SESSION = '/sessions/:entityId/:sessionId';
export const ROUTE_SESSIONS_ENTITY_SESSION_EDIT =
  '/sessions/:entityId/:sessionId([a-z0-9-]{36})/edit';
export const ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS =
  '/sessions/:entityId/:sessionId([a-z0-9-]{36})/athletes';
export const ROUTE_SESSIONS_ENTITY_SESSION_ADD_SPORTER =
  '/sessions/:entityId/:sessionId([a-z0-9-]{36})/athletes/add';
export const ROUTE_SESSIONS_ENTITY_SESSION_SPORTER =
  '/sessions/:entityId/:sessionId([a-z0-9-]{36})/sporter/:sporterId([a-z0-9-]{36})';
export const ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_EDIT =
  '/sessions/:entityId/:sessionId([a-z0-9-]{36})/sporter/:sporterId([a-z0-9-]{36})/edit';
export const ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL =
  '/sessions/:entityId/:sessionId([a-z0-9-]{36})/sporter/:sporterId([a-z0-9-]{36})/global';
export const ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT =
  '/sessions/:entityId/:sessionId([a-z0-9-]{36})/sporter/:sporterId([a-z0-9-]{36})/global/edit';
export const ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT =
  '/sessions/:entityId/:sessionId([a-z0-9-]{36})/sporter/:sporterId([a-z0-9-]{36})/report';
export const ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_EDIT =
  '/sessions/:entityId/:sessionId([a-z0-9-]{36})/sporter/:sporterId([a-z0-9-]{36})/report/edit';
export const ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS =
  '/sessions/:entityId/:sessionId([a-z0-9-]{36})/sporter/:sporterId([a-z0-9-]{36})/tests';
export const ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_EDIT =
  '/sessions/:entityId/:sessionId([a-z0-9-]{36})/sporter/:sporterId([a-z0-9-]{36})/tests/edit';
export const ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE =
  '/sessions/:entityId/:sessionId([a-z0-9-]{36})/sporter/:sporterId([a-z0-9-]{36})/advice/:programId';
export const ROUTE_SESSIONS_ENTITY_TEMPLATES = '/sessions/:entityId/templates';
export const ROUTE_SESSIONS_ENTITY_TEMPLATES_ADD =
  '/sessions/:entityId/templates/add';
export const ROUTE_SESSIONS_ENTITY_TEMPLATES_TESTSET_EDIT =
  '/sessions/:entityId/templates/:testSetId/edit';

// Scanners
export const ROUTE_SCANNERS = '/scanners';
export const ROUTE_SCANNERS_ENTITY = '/scanners/:entityId';
export const ROUTE_SCANNERS_ENTITY_ADD = '/scanners/:entityId/add';
export const ROUTE_SCANNERS_ENTITY_SCANNER = '/scanners/:entityId/:scannerId';
export const ROUTE_SCANNERS_ENTITY_SCANNER_EDIT =
  '/scanners/:entityId/:scannerId/edit';
export const ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS =
  '/scanners/:entityId/:scannerId/calibrations';

export const ROUTE_SCANNERS_ENTITY_SCANNER_DIAGRAM =
  '/scanners/:entityId/:scannerId/diagram';

export const ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_ADD =
  '/scanners/:entityId/:scannerId/calibrations/add';

export const ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION =
  '/scanners/:entityId/:scannerId/calibrations/:calibrationId';
export const ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION_EDIT =
  '/scanners/:entityId/:scannerId/calibrations/:calibrationId/edit';
export const ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION_RESULT =
  '/scanners/:entityId/:scannerId/calibrations/:calibrationId/result';
export const ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION_RESULT_EDIT =
  '/scanners/:entityId/:scannerId/calibrations/:calibrationId/result/edit';

export const ROUTE_TESTS = '/tests';
export const ROUTE_TESTS_PACKAGE_SESSION =
  '/tests/:packageId/:entityId/:sessionId';
export const ROUTE_SURVEY_PACKAGE_SESSION_SPORTER =
  '/tests/:packageId/:entityId/:sessionId([a-z0-9-]{36})/:sporterId';
export const ROUTE_SURVEY_PACKAGE_SESSION_LIST =
  '/tests/sap/:entityId/:sessionId';

// Benchmarks
export const ROUTE_BENCHMARKS = '/benchmarks';
export const ROUTE_BENCHMARKS_ENTITY = '/benchmarks/:entityId/:popup';
export const ROUTE_BENCHMARKS_ENTITY_POPUP = '/benchmarks/:entityId/popup';
export const ROUTE_BENCHMARKS_ENTITY_SPORTER =
  '/benchmarks/:entityId/:popup/group/:groupId/sporter/:sporterId';
export const ROUTE_BENCHMARKS_ENTITY_GROUP =
  '/benchmarks/:entityId/:popup/group/:groupId/testset/:testSetId/benchmark/:benchmarkId';

export const ROUTE_BENCHMARKS_ENTITY_SESSION =
  '/benchmarks/:entityId/:popup/session/:sessionId';

// Results
export const ROUTE_RESULTS = '/results';
export const ROUTE_RESULTS_ENTITY = '/results/:entityId/:popup';
export const ROUTE_RESULTS_ENTITY_POPUP = '/results/:entityId/popup';
export const ROUTE_RESULTS_ENTITY_SPORTER =
  '/results/:entityId/:popup/group/:groupId/sporter/:sporterId';
export const ROUTE_RESULTS_ENTITY_GROUP =
  '/results/:entityId/:popup/group/:groupId/benchmark/:benchmarkId';

export const ROUTE_RESULTS_ENTITY_SESSION =
  '/results/:entityId/:popup/session/:sessionId';

// Growth
export const ROUTE_GROWTH_PREDICTION = '/growthprediction';
export const ROUTE_GROWTH_PREDICTION_ENTITY =
  '/growthprediction/:entityId/:popup';
export const ROUTE_GROWTH_PREDICTION_ENTITY_POPUP =
  '/growthprediction/:entityId/popup';
export const ROUTE_GROWTH_PREDICTION_ENTITY_SPORTER =
  '/growthprediction/:entityId/:popup/group/:groupId/sporter/:sporterId';
export const ROUTE_GROWTH_PREDICTION_ENTITY_GROUP =
  '/growthprediction/:entityId/:popup/group/:groupId/testset/:testSetId/benchmark/:benchmarkId';
export const ROUTE_GROWTH_PREDICTION_ENTITY_SESSION =
  '/growthprediction/:entityId/:popup/session/:sessionId';

// Exercises
export const ROUTE_EXERCISES = '/exercises';

// Exercises
export const ROUTE_EXERCISES_INDEX = '/exercises/index';
export const ROUTE_EXERCISES_ENTITY = '/exercises/:entityId';
export const ROUTE_EXERCISES_ENTITY_EXERCISE = `/exercises/:entityId/:exerciseId`;
export const ROUTE_EXERCISES_ENTITY_EXERCISE_ADD = `/exercises/:entityId/add`;
export const ROUTE_EXERCISES_ENTITY_EXERCISE_EDIT = `/exercises/:entityId/:exerciseId/edit`;
export const ROUTE_EXERCISES_ENTITY_EXERCISE_LANGUAGE = `/exercises/:entityId/:exerciseId/language/:lang?`;

// Programs
export const ROUTE_EXERCISES_PROGRAMS = '/programs';
export const ROUTE_EXERCISES_PROGRAMS_ENTITY = '/programs/:entityId';
export const ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM =
  '/programs/:entityId/:programId';
export const ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT =
  '/programs/:entityId/:programId/edit';
export const ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_ATHLETES_PERSON =
  '/programs/:entityId/:programId/athletes/:personId';
export const ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_ATHLETES =
  '/programs/:entityId/:programId/athletes';
export const ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES =
  '/programs/:entityId/:programId/exercises';

// Templates
export const ROUTE_EXERCISES_TEMPLATES = '/templates';
export const ROUTE_EXERCISES_TEMPLATES_ENTITY = '/templates/:entityId';
export const ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE =
  '/templates/:entityId/:templateId';
export const ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_ADD =
  '/templates/:entityId/add';
export const ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EDIT =
  '/templates/:entityId/:templateId/edit';
export const ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EXERCISES_EXERCISE =
  '/templates/:entityId/:templateId/exercises/:exerciseId';

// Collections
export const ROUTE_EXERCISES_COLLECTIONS = '/collections';
export const ROUTE_EXERCISES_COLLECTIONS_ENTITY = '/collections/:entityId';
export const ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_ADD =
  '/collections/:entityId/add';
export const ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION =
  '/collections/:entityId/:collectionId';
export const ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_MY_EXERCISES =
  '/collections/:entityId/my-exercises';
export const ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_MY_EXERCISES_EXERCISE =
  '/collections/:entityId/my-exercises/:exerciseId';
export const ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_MY_EXERCISES_EXERCISE_EDIT =
  '/collections/:entityId/my-exercises/:exerciseId/edit';
export const ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES =
  '/collections/:entityId/:collectionId/exercises';
export const ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES_EXERCISE =
  '/collections/:entityId/:collectionId/exercises/:exerciseId';

// Trainings
export const ROUTE_EXERCISES_TRAININGS = '/trainings';
export const ROUTE_EXERCISES_TRAININGS_ENTITY = '/trainings/:entityId';
export const ROUTE_EXERCISES_TRAININGS_ENTITY_ARCHIVED =
  '/trainings/:entityId/archived';
export const ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_ADD =
  '/trainings/:entityId/add';
export const ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING =
  '/trainings/:entityId/:trainingId';
export const ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE_EDIT =
  '/trainings/:entityId/exercises/:exerciseId/edit';
export const ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE_DETAILS =
  '/trainings/:entityId/:trainingId/exercises/:exerciseId/details';
export const ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES =
  '/trainings/:entityId/:trainingId/exercises';
export const ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE =
  '/trainings/:entityId/:trainingId/exercises/:exerciseId';

// Rehab
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL =
  '/athletes/:entityId/:sporterId/medical/:medicalId?';

// Injury
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL =
  '/athletes/:entityId/:sporterId/medical/:medicalId/injury/:injuryId';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL_EDIT =
  '/athletes/:entityId/:sporterId/medical/:medicalId/injury/:injuryId/edit';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_REPORTS =
  '/athletes/:entityId/:sporterId/medical/:medicalId/injury/:injuryId/reports';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS =
  '/athletes/:entityId/:sporterId/medical/:medicalId/injury/:injuryId/screenings';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ADD =
  '/athletes/:entityId/:sporterId/medical/:medicalId/injury/:injuryId/screenings/add';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY =
  '/athletes/:entityId/:sporterId/medical/:medicalId/injury/:injuryId/screenings/:sessionId';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RETEST =
  '/athletes/:entityId/:sporterId/medical/:medicalId/injury/:injuryId/screenings/:sessionId([a-z0-9-]{36})/retest';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RESULTS =
  '/athletes/:entityId/:sporterId/medical/:medicalId/injury/:injuryId/screenings/:sessionId([a-z0-9-]{36})/results';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RESULTS_EDIT =
  '/athletes/:entityId/:sporterId/medical/:medicalId/injury/:injuryId/screenings/:sessionId([a-z0-9-]{36})/results/edit';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORTS =
  '/athletes/:entityId/:sporterId/medical/:medicalId/injury/:injuryId/screenings/:sessionId([a-z0-9-]{36})/reports';

// Illness

export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL =
  '/athletes/:entityId/:sporterId/medical/:medicalId/illness/:illnessId';

export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL_EDIT =
  '/athletes/:entityId/:sporterId/medical/:medicalId/illness/:illnessId/edit';

// Issues

export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL =
  '/athletes/:entityId/:sporterId/medical/:medicalId/issue/:issueId';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL_EDIT =
  '/athletes/:entityId/:sporterId/medical/:medicalId/issue/:issueId/edit';

// Prevention
export const ROUTE_PREVENTION = '/prevention';
export const ROUTE_PREVENTION_ENTITY_SESSION = '/sessions/:entityId/:sessionId';
export const ROUTE_PREVENTION_ENTITY_ADD_PREVENTION_SPORTERS =
  '/prevention/:entityId/add/athletes';
export const ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION =
  '/prevention/:entityId/:sessionId([a-z0-9-]{36})/edit';

// User Programs
export const ROUTE_USER_PROGRAMS = '/programs';
export const ROUTE_USER_ENTITY_PROGRAMS = '/programs/:entityId';
export const ROUTE_USER_ENTITY_PROGRAMS_PROGRAM =
  '/programs/:entityId/:programId';
export const ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_FEEDBACK =
  '/programs/:entityId/:programId/feedback';
export const ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_EXERCISE =
  '/programs/:entityId/:programId/:exerciseId';
export const ROUTE_USER_ENTITY = '/user/:entityId';

// User Sessions
export const ROUTE_USER_SESSIONS_ENTITY = '/sessions/:entityId';
export const ROUTE_USER_SESSIONS_ENTITY_MEDICAL = '/sessions/:entityId/medical';
export const ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT =
  '/sessions/:entityId/:sessionId([a-z0-9-]{36})/report';

// User Exercises
export const ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS =
  '/athletes/:entityId/:sporterId/programs';
export const ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM =
  '/athletes/:entityId/:sporterId/programs/:programId';
export const ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_EXERCISE =
  '/athletes/:entityId/:sporterId/programs/:programId/:exerciseId([a-z0-9-]{36})';
export const ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK =
  '/athletes/:entityId/:sporterId/programs/:programId/feedback';

// User Groups
export const ROUTE_SPORTERS_ENTITY_SPORTER_GROUPS =
  '/athletes/:entityId/:sporterId/groups';

// User docs
export const ROUTE_SPORTERS_ENTITY_SPORTER_DOCS =
  '/athletes/:entityId/:sporterId/documents';
export const ROUTE_USER_ENTITY_DOCUMENTS = '/documents/:entityId';
export const ROUTE_USER_DOCUMENTS = '/documents';

// Vitale routes
export const ROUTE_LESSONS = '/lessons';
export const ROUTE_LESSONS_ENTITY = '/lessons/:entityId';
export const ROUTE_LESSONS_ENTITY_LESSON = '/lessons/:entityId/:lessonId/';
export const ROUTE_LESSONS_ENTITY_LESSON_EDIT =
  '/lessons/:entityId/:lessonId/edit';

export const ROUTE_LESSONS_ENTITY_LESSON_ANNOTATE =
  '/lessons/:entityId/:lessonId/annotate';
export const ROUTE_LESSONS_ENTITY_LESSON_VIDEOS =
  '/lessons/:entityId/:lessonId/videos';
export const ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO =
  '/lessons/:entityId/:lessonId/videos/:videoId';
export const ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_EDIT =
  '/lessons/:entityId/:lessonId/videos/:videoId/edit';
export const ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_ANNOTATE = `/lessons/:entityId/:lessonId/videos/:videoId/annotate`;
export const ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_ANNOTATE_ADD = `/lessons/:entityId/:lessonId/videos/:videoId/annotate/add`;
export const ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_ANNOTATE_EDIT = `/lessons/:entityId/:lessonId/videos/:videoId/annotate/edit/:annotationId`;

export const ROUTE_VIDEOS = '/videos';
export const ROUTE_VIDEOS_INDEX = '/videos/index';
export const ROUTE_VIDEOS_ENTITY = '/videos/:entityId';
export const ROUTE_VIDEOS_ENTITY_VIDEO = `/videos/:entityId/:videoId`;
export const ROUTE_VIDEOS_ENTITY_VIDEO_ADD = `/videos/:entityId/add`;
export const ROUTE_VIDEOS_ENTITY_VIDEO_EDIT = `/videos/:entityId/:videoId/edit`;
export const ROUTE_VIDEOS_ENTITY_VIDEO_ANNOTATE = `/videos/:entityId/:videoId/annotate`;
export const ROUTE_VIDEOS_ENTITY_VIDEO_ANNOTATE_ADD = `/videos/:entityId/:videoId/annotate/add`;
export const ROUTE_VIDEOS_ENTITY_VIDEO_ANNOTATE_EDIT = `/videos/:entityId/:videoId/annotate/edit/:annotationId`;

export const ROUTE_SPORTERS_ENTITY_SPORTER_VIDEOS =
  '/athletes/:entityId/:sporterId/videos';
export const ROUTE_SPORTERS_ENTITY_SPORTER_VIDEOS_VIDEO =
  '/athletes/:entityId/:sporterId/videos/:videoId?';

// Test app
export const ROUTE_TEST = '/test';
export const ROUTE_TEST_ENTITY = '/test/:entityId';
export const ROUTE_TEST_ENTITY_UPLOAD = '/test/:entityId/upload';
export const ROUTE_TEST_ENTITY_EXERCISES = '/test/:entityId/exercises';
export const ROUTE_TEST_ENTITY_EXERCISES_EXERCISE_PYTHON =
  '/test/:entityId/exercises/:exerciseId/:type';
export const ROUTE_TEST_ENTITY_EXERCISES_EXERCISE =
  '/test/:entityId/exercises/:exerciseId/:type?';
export const ROUTE_TEST_ENTITY_ANALYSE = '/test/:entityId/analyse/:type';
export const ROUTE_TEST_ENTITY_ANALYSE_PYTHON = '/test/:entityId/analyse/:type';
