import { Fragment, useContext, useState } from 'react';
import Card from 'components/card/Card';
import CardBody from 'components/card/CardBody';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import { Accordion, AccordionContent } from 'components/accordion';
import AccordionButtonHooks from 'components/accordion/AccordionButtonHooks';
import { Table, TableBody, TableCell, TableRow } from 'components/table';
import ExercisesListFiltered from 'containers/partials/lists/ExercisesListFiltered';
import TestItemResultRow from 'containers/partials/cards/ReportCards/TestItemResultRow';
import { checkLateralityIsHidden } from 'utils/tests';
import { StoreContext } from 'index';
import { usePersonContext } from 'contexts/PersonContext';

const TrainingAdviceCategoryCard = ({
  category,
  tests,
  options,
  compressedTable,
  onPreview,
  edit
}) => {
  const {
    uiState: { locale }
  } = useContext(StoreContext);

  const { person } = usePersonContext();
  const [openList, setOpenList] = useState(null);

  return (
    <Card secondary>
      <CardHeader secondary extraClassNames="u-margin-bottom-small">
        <CardHeaderText>
          <CardHeaderTitle>{category}</CardHeaderTitle>
        </CardHeaderText>
      </CardHeader>
      <CardBody secondary>
        {tests.map(test => (
          <Fragment key={test.id}>
            {test.testItems.map((testItem, j) => {
              if (
                testItem?.benchmarked && // TODO als we dit gaan gebruiken voor custom moet de check anders
                Object.keys(testItem.benchmarked).length > 0
              ) {
                let show = false;

                Object.keys(testItem.benchmarked).forEach(side => {
                  show =
                    show ||
                    (testItem.values[side][0] !== null &&
                      testItem.benchmarked[side] !== null);
                });

                const TableWithResults = () => (
                  <Table
                    key={testItem.id}
                    tableReport
                    verticalTop
                    extraClassNames={
                      'u-margin-bottom-none c-table--results-left-right'
                    }
                    compressedTable={compressedTable}
                  >
                    <TableBody>
                      <TableRow
                        extraClassNames={
                          testItem?.benchmarked &&
                          Object.keys(testItem.benchmarked).length > 0
                            ? ''
                            : 'u-1-of-1'
                        }
                      >
                        <TableCell evenHalf colSpan={2}>
                          {testItem.copy?.[locale]?.title ?? testItem.title}
                        </TableCell>
                      </TableRow>
                      <TestItemResultRow
                        testItem={testItem}
                        options={options}
                        hasBenchmarkedValues={true}
                        sidesDisabled={checkLateralityIsHidden(
                          testItem,
                          testItem.sides,
                          person
                        )}
                        colSpan={
                          Object.keys(testItem.sides)[0] === '2' ? 2 : null
                        }
                      />
                    </TableBody>
                  </Table>
                );

                if (show) {
                  return (
                    <Accordion visibleFlow key={testItem.id}>
                      {() => (
                        <Fragment key={`${testItem.id}-${j}`}>
                          {testItem.tags && testItem.tags.length > 0 ? (
                            <AccordionButtonHooks
                              id={`trigger-${test.id}-${testItem.id}-${j}`}
                              name="accordion-story"
                              checkbox
                              checked={openList === `${test.id}-${testItem.id}`}
                              tableTrigger
                              isControlled
                              onChange={checked => {
                                setOpenList(
                                  checked ? `${test.id}-${testItem.id}` : false
                                );
                              }}
                            >
                              <TableWithResults />
                            </AccordionButtonHooks>
                          ) : (
                            <TableWithResults />
                          )}

                          {testItem.tags &&
                            testItem.tags.length > 0 &&
                            openList === `${test.id}-${testItem.id}` && (
                              <AccordionContent>
                                <ExercisesListFiltered
                                  edit={edit}
                                  filter={{ tags: testItem.tags }}
                                  onPreview={onPreview}
                                />
                              </AccordionContent>
                            )}
                        </Fragment>
                      )}
                    </Accordion>
                  );
                }
              }
              return null;
            })}
          </Fragment>
        ))}
      </CardBody>
    </Card>
  );
};

export default TrainingAdviceCategoryCard;
