import { Card, CardBody } from 'components/card';
import messages from 'messages';
import { FormattedMessage } from 'react-intl';

const LegendCard = ({ helper = false }) => {
  return (
    <Card secondary={!helper} helper={helper}>
      <CardBody benchmarks={helper}>
        <p className="c-input__label">
          <FormattedMessage {...messages.annotationLegend} />
        </p>
        <ul className="o-list o-list-bare c-list-legend">
          <li className="o-list__item c-list-legend__item c-list-legend__item-own">
            <FormattedMessage {...messages.annotationOwnAnnotation} />
          </li>
          <li className="o-list__item c-list-legend__item c-list-legend__item-own-supervised">
            <FormattedMessage
              {...messages.annotationOwnAndSupervisorAnnotation}
            />
          </li>
          <li className="o-list__item c-list-legend__item c-list-legend__item-supervisor">
            <FormattedMessage {...messages.annotationSupervisorAnnotation} />
          </li>
          <li className="o-list__item c-list-legend__item c-list-legend__item-other">
            <FormattedMessage {...messages.annotationStudentAnnotation} />
          </li>
          <li className="o-list__item c-list-legend__item c-list-legend__item-grouped">
            <FormattedMessage {...messages.annotationLegendMultiple} />
          </li>
        </ul>
      </CardBody>
    </Card>
  );
};

export default LegendCard;
