import { useContext, useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import Panel from 'components/panel/Panel';
import PanelHeader from 'components/panel/PanelHeader';
import { ROUTE_SPORTERS, ROUTE_SPORTERS_ENTITY } from 'routes/RouteList';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import {
  PanelSubtitle,
  PanelTitle,
  PanelTitleWrapper
} from 'components/panel/panel-title';
import PanelBody from 'components/panel/PanelBody';
import { Card, CardBody, CardFooter } from 'components/card';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import BreadcrumbPath from 'containers/partials/misc/BreadcrumbPath';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import { StoreContext } from 'index';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import GroupForm from 'containers/pages/sporters/GroupForm';
import FieldInput from 'components/input/FieldInput';
import { useQuery } from '@apollo/client';
import { S_QUERY_GET_ENTITY_BY_ID } from 'services/aws/entity-query';
import Loader from 'components/loader/Loader';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import YearSelect from 'containers/partials/inputs/YearSelect';

const GroupModel = {
  name: '',
  grade: '',
  year: ''
};

const Group = ({ active, mode }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const { entityId } = useParams();
  const intl = useIntl();

  const [group, setGroup] = useState({ ...GroupModel });
  const [edit, setEdit] = useState(true);

  const { loading, error, data } = useQuery(S_QUERY_GET_ENTITY_BY_ID, {
    variables: { id: entityId },
    skip: mode === 'add'
  });

  useEffect(() => {
    if (mode === 'add') {
      setGroup({ ...GroupModel });
    }
    if (data?.getEntity) {
      const groupData = {
        name: data.getEntity.name
      };
      const meta = data.getEntity?.meta ? JSON.parse(data.getEntity.meta) : {};
      groupData.grade = meta?.grade ?? '';
      groupData.year = meta?.year ?? GroupModel.year;
      setGroup({ ...groupData });
    }
  }, [data, mode]);

  if (loading) return <Loader />;
  if (error) return <RetryPanel />;

  return (
    <Panel active={active}>
      <PanelHeader>
        <BreadcrumbPath
          crumbs={[
            {
              path: ROUTE_SPORTERS,
              label: intl.formatMessage(messages.breadcrumbSporters)
            }
          ]}
        />
        <PanelTitleWrapper>
          <PanelTitle>
            {group.name ? (
              group.name
            ) : (
              <FormattedMessage {...messages.titleSportersAddGroup} />
            )}
          </PanelTitle>
          <PanelSubtitle />
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        <Card secondary>
          <GroupForm entityId={entityId} group={group} mode={mode}>
            {({
              values,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              errors,
              touched
            }) => (
              <>
                <CardHeader secondary>
                  <CardHeaderTitle>
                    <FormattedMessage {...messages.titleSportersAddGroup} />
                  </CardHeaderTitle>

                  <FormEditHeaderButtons
                    edit={edit}
                    setEdit={setEdit}
                    onCancel={() =>
                      push(generatePath(ROUTE_SPORTERS_ENTITY, { entityId }))
                    }
                    onSubmit={handleSubmit}
                    saveLabel={
                      mode === 'add' ? `addGroupButton` : `editGroupButton`
                    }
                    type={'button'}
                  />
                </CardHeader>
                <CardBody secondary>
                  <FieldInput id="name" name="name">
                    <FormattedMessage {...messages.labelSportersGroupName} />*
                  </FieldInput>
                  {(user.isSportamundiOrg || user.isHanOrg) && (
                    <>
                      <FieldInput
                        id="grade"
                        name="grade"
                        errors={errors}
                        touched={touched}
                      >
                        <FormattedMessage
                          {...messages.labelSportersGroupGrade}
                        />
                      </FieldInput>
                      <YearSelect
                        id="year"
                        name="year"
                        onChange={year => setFieldValue('year', year)}
                        value={values.year}
                      >
                        <FormattedMessage
                          {...messages.labelSportersGroupYear}
                        />
                      </YearSelect>
                    </>
                  )}
                </CardBody>
                <CardFooter secondary>
                  <FormEditFooterButtons
                    edit={edit}
                    setEdit={setEdit}
                    disabledSubmit={isSubmitting}
                    onCancel={() =>
                      push(generatePath(ROUTE_SPORTERS_ENTITY, { entityId }))
                    }
                    onSubmit={handleSubmit}
                    saveLabel={
                      mode === 'add' ? `addGroupButton` : `editGroupButton`
                    }
                    type={'button'}
                  />
                </CardFooter>
              </>
            )}
          </GroupForm>
        </Card>
      </PanelBody>
    </Panel>
  );
};

export default Group;
