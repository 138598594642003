import React from 'react';
import classNames from 'classnames';

const TableBody = props => {
  return (
    <tbody className={classNames('c-table__body', props.extraClassNames)}>
      {props.children}
    </tbody>
  );
};

export default TableBody;
