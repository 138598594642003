import React from 'react';
import { useIntl } from 'react-intl';
import { getLocalDateFormat } from 'utils/date';
import CardBody from 'components/card/CardBody';
import Card from 'components/card/Card';
import CardFooter from 'components/card/CardFooter';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import ResultList from 'components/list/ResultList';
import messages from 'messages';
import { useSessionContext } from 'contexts/SessionContext';
import { useTestDataContext } from 'contexts/TestDataContext';

const IAmReportCard = () => {
  const intl = useIntl();
  const { session } = useSessionContext();
  const { testData, tests } = useTestDataContext(); // TESTS NOT EXPOSED

  let iamTestResuls = [];

  if (!tests) {
    if (testData?.data?.data) {
      const result = testData.data.data;

      if (result.length && result[0].questionText && result[0].answersOptions) {
        result.forEach(result => {
          const question = result.questionText;
          // if (question.length > 60) question = `${question.substring(0, 57)}...`;
          // const options = result.answersOptions;
          const options = [];
          result.answersOptions.forEach(option => {
            // remove text between parentheses
            options.push(option.replace(/\([^()]*\)/g, ''));
          });
          const optionsJoin = options.join(' | ');
          // if (options.length > 1 && optionsJoin.length > 20) {
          if (options.length > 1) {
            iamTestResuls.push({
              name: question,
              value: ''
            });
            options.forEach(option => {
              iamTestResuls.push({
                name: '-',
                value: option
              });
            });
          } else {
            iamTestResuls.push({
              name: question,
              value: optionsJoin
            });
          }
        });
      }
    }
  } else {
    iamTestResuls = [...tests];
  }

  const sortedtTests = iamTestResuls; // sort(tests);

  return (
    <Card reports>
      <CardHeader secondary>
        <CardHeaderText>
          <CardHeaderTitle>{session.name}</CardHeaderTitle>
          <CardHeaderSubtitle>
            {testData && getLocalDateFormat(testData.date_created)}
          </CardHeaderSubtitle>
        </CardHeaderText>
      </CardHeader>
      <CardBody secondary>
        {sortedtTests && (
          <ResultList
            items={sortedtTests}
            labelShowMore={intl.formatMessage(
              messages.cardButtonShowQuestions,
              {
                count: sortedtTests.length
              }
            )}
            labelShowLess={intl.formatMessage(
              messages.cardButtonShowLessQuestions
            )}
          />
        )}
      </CardBody>
      <CardFooter />
    </Card>
  );
};

export default IAmReportCard;
