import React, { useEffect, useRef, useState } from 'react';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper,
  ListItemStatus
} from 'components/list/list-item';
import InputWrapper from 'components/input/InputWrapper';
import Checkbox from 'components/input/Checkbox';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';

const InjuryListItem = ({
  uid,
  injury: { id, title, sides, selection: injurySelection },
  index,
  onChange,
  onInjurySelect
}) => {
  const loaded = useRef(false);

  const [selection, setSelection] = useState({
    left: injurySelection.left,
    right: injurySelection.right,
    noside: injurySelection.noside
  });

  useEffect(() => {
    if (loaded.current) {
      onInjurySelect({ [id]: selection });
    } else {
      loaded.current = true;
    }
  }, [selection]);

  const onSideChange = (e, side) => {
    switch (side) {
      case 'left':
        setSelection({ ...selection, left: e.target.checked });
        break;
      case 'right':
        setSelection({ ...selection, right: e.target.checked });
        break;
      case 'noside':
        setSelection({ ...selection, noside: e.target.checked });
        break;
    }
  };

  const onMainChange = () => {
    if (selection.left || selection.right || selection.noside) {
      setSelection({ left: false, right: false, noside: false });
    } else {
      if (sides && sides.includes('left')) {
        setSelection({ left: true, right: true, noside: false });
      } else {
        setSelection({ left: false, right: false, noside: true });
      }
    }
    onChange();
  };

  return (
    <ListItem key={id} active={false}>
      <ListItemStatus
        itemId={uid} // Required to enable selection!
        visible
        checked={(selection.left && selection.right) || selection.noside}
        onChange={onMainChange}
        indeterminate={
          (selection.left && !selection.right) ||
          (!selection.left && selection.right)
        }
      />
      <ListItemLabelWrapper>
        <ListItemLabel label style={{ whiteSpace: 'normal' }}>
          {title}
        </ListItemLabel>

        <div className="c-list__item-editing-area" style={{ minWidth: 0 }}>
          <InputWrapper>
            {!sides || (!sides.includes('left') && !sides.includes('right')) ? (
              <Checkbox
                id={`check-no-side-${index}`}
                name="noside"
                checked={selection.noside}
                onChange={e => onSideChange(e, 'noside')}
              >
                <FormattedMessage
                  {...messages.checkboxSide}
                  values={{
                    side: 'NoSide'
                  }}
                />
              </Checkbox>
            ) : (
              <>
                <Checkbox
                  id={`check-left-${index}`}
                  name="left"
                  checked={selection.left}
                  onChange={e => onSideChange(e, 'left')}
                  readOnly={!sides.includes('left')}
                >
                  <FormattedMessage
                    {...messages.checkboxSide}
                    values={{
                      side: sides.includes('left') && 'Left'
                    }}
                  />
                </Checkbox>
                <Checkbox
                  id={`check-right-${index}`}
                  name="right"
                  checked={selection.right}
                  onChange={e => onSideChange(e, 'right')}
                  readOnly={!sides.includes('right')}
                >
                  <FormattedMessage
                    {...messages.checkboxSide}
                    values={{
                      side: sides.includes('right') && 'Right'
                    }}
                  />
                </Checkbox>
              </>
            )}
          </InputWrapper>
        </div>
      </ListItemLabelWrapper>
    </ListItem>
  );
};

export default InjuryListItem;
