import React, { useContext, useEffect, useState } from 'react';
import { Accordion, AccordionContent } from 'components/accordion';
import AccordionButtonHooks from 'components/accordion/AccordionButtonHooks';
import {
  CardHeader,
  CardHeaderButtons,
  CardHeaderTitle
} from 'components/card/card-header';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import { Form, Formik } from 'formik';
import { FieldTextarea } from 'components/input/FieldTextarea';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import { sanitize } from 'dompurify';
import { Card } from 'components/card';
import { usePersonContext } from 'contexts/PersonContext';
import { useMedicalContext } from 'contexts/MedicalContext';
import useFormLeave from 'hooks/utils/useFormLeave';
import { useNotificationQueue } from 'components/notification';
import { useMutation } from '@apollo/client';
import {
  MUTATION_EDIT_MEDICAL,
  QUERY_GET_PERSON_MEDICALS
} from 'services/aws/injury-query';
import Loader from 'components/loader/Loader';
import { StoreContext } from 'index';

function SporterMedicalHistory() {
  const intl = useIntl();
  const {
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);

  const { person } = usePersonContext();
  const { medical, loading } = useMedicalContext();
  const [edit, setEdit] = useState(false);
  const [description, setDescription] = useState('');
  const [openAccordion, setOpenAccordion] = useState(false);
  const { focused, bind } = useFormLeave();
  const notification = useNotificationQueue();

  const [editMedical] = useMutation(MUTATION_EDIT_MEDICAL);

  useEffect(() => {
    if (medical) {
      if (medical.description) {
        const newLineText = medical.description.replace(
          /\r\n|\r|\n/g,
          '<br />'
        );
        setDescription(newLineText);
      }
      setOpenAccordion(true);
    }
  }, [person, medical]);

  useEffect(() => {
    if (!focused) {
      //onEditMedicalDescriptionHandler();
    }
  }, [focused]);

  const onEditMedicalDescriptionHandler = async form => {
    try {
      await editMedical({
        variables: {
          id: medical.id,
          entityId: rootEntityId,
          personId: person.id,
          description: form.description
        },
        refetchQueries: [
          {
            query: QUERY_GET_PERSON_MEDICALS,
            variables: { entityId: rootEntityId, personId: person.id }
          }
        ]
      }).then(result => {
        notification.add(result.data.editMedical.id, {
          message: intl.formatMessage(messages.rehabMedicalSaved)
        });
        setEdit(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const editAndOpenAccordion = () => {
    if (openAccordion) {
      setEdit(!edit);
    } else {
      setEdit(!edit);
      setOpenAccordion(true);
    }
  };

  if (loading) return <Loader />;

  return (
    <Card secondary>
      <Accordion>
        {() => (
          <>
            <AccordionButtonHooks
              id="trigger-1"
              name="accordion-story"
              checkbox
              isControlled
              checked={openAccordion}
              onChange={() =>
                edit ? setOpenAccordion(true) : setOpenAccordion(!openAccordion)
              }
            >
              <CardHeader secondary>
                <CardHeaderTitle>
                  <FormattedMessage {...messages.rehabMedicalHistory} />
                </CardHeaderTitle>
                <CardHeaderButtons>
                  {!edit ? (
                    <Button tiny onClick={() => editAndOpenAccordion()}>
                      <Icon id="edit" />
                    </Button>
                  ) : (
                    <Button tiny onClick={() => editAndOpenAccordion()}>
                      <Icon id="close" strokeColor="color-primary-dark" />
                    </Button>
                  )}
                </CardHeaderButtons>
              </CardHeader>
            </AccordionButtonHooks>
            {((medical?.description && medical.description.length > 0) ||
              edit) && (
              <AccordionContent>
                {edit ? (
                  <Formik
                    initialValues={medical}
                    enableReinitialize={true}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={onEditMedicalDescriptionHandler}
                  >
                    {({ submitForm, resetForm }) => (
                      <Form {...bind} noValidate>
                        <FieldTextarea
                          id="medical"
                          name="description"
                          rows="4"
                          resizeVertical
                        />
                        <FormEditFooterButtons
                          edit={edit}
                          setEdit={setEdit}
                          onCancel={resetForm}
                          onSubmit={submitForm}
                        />
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <div
                    className="c-input--textarea-readonly"
                    dangerouslySetInnerHTML={{
                      __html: sanitize(
                        description.replace(/(?:\r\n|\r|\n)/g, '<br />'),
                        {
                          ALLOWED_TAGS: ['b', 'i', 'br']
                        }
                      )
                    }}
                  />
                )}
              </AccordionContent>
            )}
          </>
        )}
      </Accordion>
    </Card>
  );
}

export default SporterMedicalHistory;
