import { CardBody } from 'components/card';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import { FormattedMessage } from 'react-intl';

const AlertRemarks = ({ message, iconId = 'alert', title = '' }) => {
  return (
    <div className="c-card--remarks c-card--remarks-alert c-card__body-separator">
      <Icon strokeColor="color-error" fillColor="transparent" id={iconId} />
      <div className="c-card__content">
        <CardHeader extraClassNames="u-margin-bottom-none" secondary remarks>
          <CardHeaderText>
            <CardHeaderTitle>
              {title.length > 0 ? (
                title
              ) : (
                <FormattedMessage {...messages.exercisesLabelRemarks} />
              )}
            </CardHeaderTitle>
          </CardHeaderText>
        </CardHeader>
        <CardBody secondary remarks>
          <div className={'c-input__group c-input--closed c-input--read-only'}>
            <div className="c-input--textarea-readonly">{message}</div>
          </div>
        </CardBody>
      </div>
    </div>
  );
};
export default AlertRemarks;
