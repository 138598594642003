import React from 'react';
import classNames from 'classnames';

const InputButtonWrapper = props => {
  return (
    <div
      className={classNames('c-input__group', props.extraClassNames, {
        'c-input--closed': props.disabled
      })}
    >
      <label htmlFor={props.id} className={classNames('c-input__label', {})}>
        {props.label}
      </label>
      <div
        className={classNames('c-input__wrapper', props.extraClassNamesWrapper)}
      >
        {props.children}
      </div>
    </div>
  );
};

export default InputButtonWrapper;
