export const filteredOrganisations = [
  {
    id: 0,
    name: 'Stad Gent',
    info: 'Gent',
    suborgs: [
      { id: 11, name: 'De Bezige Bij', info: 'Gent' },
      { id: 12, name: 'De Gele Vlinder', info: 'Ledeberg' },
      { id: 13, name: 'De Kleine Wereld', info: 'Gentbrugge' }
    ]
  },
  { id: 1, name: 'Sportamundi - DC', info: 'Gent' },
  { id: 2, name: 'DC', info: 'Antwerpen' },
  { id: 3, name: 'Dreams & Creations', info: 'Muide' },
  { id: 4, name: 'European Union', info: 'Brussels' },
  { id: 5, name: 'Another Org', info: '' }
];

export const filteredOrganisationsFlat = [
  {
    id: 0,
    name: 'Stad Gent',
    info: 'Gent'
  },
  { id: 1, name: 'Sportamundi - DC', info: 'Gent' },
  { id: 2, name: 'DC', info: 'Antwerpen' },
  { id: 3, name: 'Dreams & Creations', info: 'Muide' },
  { id: 4, name: 'European Union', info: 'Brussels' },
  { id: 5, name: 'Another Org', info: '' }
];

export const tagSelectOptions = [
  { value: '1', label: 'Strength' },
  { value: '2', label: 'Legs' },
  { value: '3', label: 'Arms' },
  { value: '4', label: 'Muscle' }
];

export const listExercises = [
  { id: 1, name: '180° Jump' },
  { id: 2, name: '4 Pose Rotation' },
  { id: 3, name: '3 Times Forward' },
  { id: 4, name: 'Exercise 4' },
  { id: 5, name: '360° Jump' },
  { id: 6, name: '1 Pose Rotation' },
  { id: 7, name: '10 Times Forward' },
  { id: 8, name: 'Another Exercise 4' }
];

export const filteredOrganisationsExtended = [
  {
    id: 0,
    name: 'Andere Bollebozen',
    info: 'Antwerpen',
    type: 'group',
    status: {
      indeterminate: false,
      checked: false
    }
  },
  {
    id: 1,
    name: 'Stad Gent',
    info: 'Gent',
    type: 'group',
    status: {
      indeterminate: true,
      checked: false
    },
    subEntities: [
      {
        id: 11,
        name: 'Group Top Athletes',
        info: 'Gent',
        type: 'group',
        status: {
          indeterminate: false,
          checked: true
        }
      },
      {
        id: 12,
        name: 'Amateurs',
        info: 'Ledeberg',
        type: 'group',
        status: {
          indeterminate: true,
          checked: false
        },
        subEntities: [
          {
            id: 131,
            name: 'Kelly Verbier',
            info: '',
            type: 'other',
            status: {
              indeterminate: false,
              checked: true
            }
          },
          {
            id: 132,
            name: 'John Doe',
            info: '',
            type: 'other',
            status: {
              indeterminate: false,
              checked: true
            }
          },
          {
            id: 133,
            name: 'Jane Fondi',
            info: '',
            type: 'other',
            status: {
              indeterminate: true,
              checked: false
            },
            subEntities: [
              {
                id: 1331,
                name: '3e Leerjaar A',
                info: '',
                type: 'other',
                status: {
                  indeterminate: false,
                  checked: false
                }
              },
              {
                id: 1332,
                name: '3e Leerjaar B',
                info: '',
                type: 'other',
                status: {
                  indeterminate: false,
                  checked: false
                }
              },
              {
                id: 1333,
                name: '3e Leerjaar C',
                info: '',
                type: 'other',
                status: {
                  indeterminate: false,
                  checked: false
                }
              }
            ]
          },
          {
            id: 134,
            name: 'Ludovic Roman',
            info: '',
            type: 'other',
            status: {
              indeterminate: false,
              checked: true
            }
          }
        ]
      },
      {
        id: 13,
        name: 'De Kleine Wereld',
        info: 'Gentbrugge',
        type: 'group',
        status: {
          indeterminate: false,
          checked: false
        }
      }
    ]
  },
  {
    id: 2,
    name: 'De 4 Bollebozen',
    info: 'Antwerpen',
    type: 'other',
    status: {
      indeterminate: false,
      checked: false
    }
  },
  {
    id: 3,
    name: 'De 7 Dwergen',
    info: 'Antwerpen',
    type: 'other',
    status: {
      indeterminate: false,
      checked: false
    }
  },
  {
    id: 4,
    name: 'Kaboem!',
    info: 'Brussels',
    type: 'other',
    status: {
      indeterminate: false,
      checked: false
    },
    subEntities: [
      {
        id: 41,
        name: '1e Leerjaar',
        info: '',
        type: 'other',
        status: {
          indeterminate: false,
          checked: false
        }
      }
    ]
  }
];

export const activeTreeId = 1;

export const scheduledAthletes = [
  {
    id: '3fbb1738-e0ef-47ff-8541-bd568303dcc7',
    lastName: 'Verbier',
    firstName: 'Kelly'
  },
  {
    id: '713c4a10-a1ff-43a0-88ac-803d56d195c5',
    lastName: 'Thomas',
    firstName: 'Jules'
  },
  {
    id: '3fbb1738-e0ef-47ff-8541-ddd',
    lastName: 'Johnson',
    firstName: 'John'
  },
  {
    id: '713c4a10-a1ff-43a0-88ac-eee',
    lastName: 'Maryson',
    firstName: 'Mary'
  },
  {
    id: '3fbb1738-e0ef-47ff-8541-fff',
    lastName: 'Ruthson',
    firstName: 'Ruth'
  },
  {
    id: '713c4a10-a1ff-43a0-88ac-ggg',
    lastName: 'Thomason',
    firstName: 'Jeffers'
  }
];

export const listFeedback = [
  { id: 1, feedback: 'Pijn bij uitvoering', date: '01/01/2020' },
  { id: 2, feedback: 'De helft niet gedaan', date: '02/02/2020' },
  { id: 3, feedback: 'Random opmerking 3', date: '03/03/2020' }
];

export const exerciseList = [
  {
    id: '1',
    name: '180° Jump',
    status: 'done',
    note: 'Notitie (free text box)'
  },
  {
    id: '2',
    name: '4 Pose Rotation',
    status: 'inProgress',
    note:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque velit libero, lobortis in tempus sit amet, gravida ut nisl. Donec rhoncus ipsum et ex egestas, non vestibulum nulla rhoncus'
  },
  {
    id: '3',
    name: 'Last Exercise',
    status: '',
    note: 'Another note'
  }
];

export const personInjuriesList = [
  {
    id: '0ec7976f-4040-4bf1-b74a-3a80091b53c6',
    name: 'ACL Injury',
    date: '10/01/2018',
    issueYear: '2018',
    injury: '0ec7976f-4040-4bf1-b74a-3a80091b53c6',
    side: {
      left: true,
      right: false
    }
  },
  {
    id: '856c74ed-7203-4781-a801-25f106b0276d',
    name: 'Hamstring',
    date: '21/11/2015',
    issueYear: '2015',
    injury: '856c74ed-7203-4781-a801-25f106b0276d',
    side: {
      left: false,
      right: false
    }
  }
];

export const injuriesList = [
  {
    id: '0ec7976f-4040-4bf1-b74a-3a80091b53c6',
    name: 'ACL Injury'
  },
  {
    id: '856c74ed-7203-4781-a801-25f106b0276d',
    name: 'Hamstring'
  }
];

export const ScreeningTypeList = [
  {
    id: '0ec7976f-4040-4bf1-b74a-3a80091b53c6',
    name: 'ACL "Return to run'
  },
  {
    id: '856c74ed-7203-4781-a801-25f106b0276d',
    name: 'ACLR "move"'
  }
];

export const screeningList = [
  {
    id: '25f2ab66-d803-408e-9b85-28f279c56e79',
    name: 'ACL "Return to Run"',
    screeningDate: '2016-05-02',
    tests: [
      {
        id: 11,
        name: 'Knee Effusion',
        type: 'Anthropometrics',
        duration: 5,
        dependent: false,
        upgrade: false
      },
      {
        id: 12,
        name: 'Knee Extension ROM',
        type: 'Flexibility',
        duration: 1,
        dependent: false,
        upgrade: false
      },
      {
        id: 13,
        name: 'Knee Effusion Preparation',
        type: 'Knee Effusion Dependency',
        duration: 1,
        dependent: true,
        upgrade: false
      },
      {
        id: 14,
        name: 'Isokinetic Knee Concentric',
        type: 'Flexibility',
        duration: 30,
        dependent: false,
        upgrade: true
      }
    ]
  },
  {
    id: '33d455cf-444d-42db-845d-403d36772b45',
    name: 'ACLR "Move"',
    screeningDate: '2016-03-15',
    tests: []
  }
];

export const exerciseListBasicWithSuperTitle = [
  {
    id: '1',
    name: 'Ankle Ball Squeeze',
    status: '',
    checked: false,
    readOnly: false,
    isDependency: false,
    supertitle: 'Level 1 - Hip Adductor Strength',
    duration: '1 min',
    thumb: null,
    video: null,
    side: { left: true, right: true }
  },
  {
    id: '2',
    name: 'Sideways Hop on Step',
    status: '',
    checked: true,
    readOnly: false,
    isDependency: false,
    supertitle: 'Level 1 - Leg Alignment',
    duration: '1 min',
    thumb: null,
    video: null,
    side: { left: true, right: false }
  },
  {
    id: '3',
    name: 'Basic Hamstring Stretch',
    status: '',
    checked: true,
    readOnly: false,
    isDependency: false,
    supertitle: 'Level 1 - Flexibility Hamstrings',
    duration: '25 min',
    thumb: null,
    video: null,
    side: { left: false, right: true }
  }
  // {
  //   id: '4',
  //   name: 'Preparation',
  //   status: '',
  //   checked: false,
  //   readOnly: true,
  //   isDependency: false,
  //   supertitle: 'Flexibility',
  //   duration: '5 min',
  //   side: { left: false, right: false }
  // }
];

export const exerciseListUpgradeWithSuperTitle = [
  {
    id: '145',
    name: 'Preparation',
    status: '',
    checked: true,
    readOnly: true,
    isDependency: false,
    supertitle: 'Flexibility',
    duration: '5 min'
  }
];

export const sessionsData = {
  getTestSessions: [
    {
      id: '0cd8a65d-a85a-4fce-9846-468ca51ecc25',
      name: 'New session',
      startdate: '2020-02-17',
      finished: null,
      finishedPercentage: 100.0,
      testSet: {
        id: '2698301d-e5c9-4bc8-807c-bda4e86e75cf',
        name: 'i-do',
        type: 'ido',
        locale: 'nl-nl',
        subtype: 'ido',
        url: null,
        version: '1',
        __typename: 'TestSet'
      },
      __typename: 'TestSession'
    },
    {
      id: '3b45ef41-1ed7-47a2-a146-f816f4d8c650',
      name: 'new spartanova testsession',
      startdate: '2020-04-09',
      finished: null,
      finishedPercentage: 18.0,
      testSet: {
        id: 'df8826e2-69cb-4f14-8bf7-112f0de65b09',
        name: 'new spartanova testsets',
        type: 'ido',
        locale: null,
        subtype: 'new spartanova testsets',
        url: null,
        version: '0',
        __typename: 'TestSet'
      },
      __typename: 'TestSession'
    },
    {
      id: '6a5bb899-c295-4e81-8a22-06787a5f898e',
      name: 'KH + mirwald',
      startdate: '2020-06-10',
      finished: null,
      finishedPercentage: 88.0,
      testSet: {
        id: '32f578b5-3bf1-4633-8036-270d0251325f',
        name: 'KH + Mirwald',
        type: 'ido',
        locale: null,
        subtype: 'KH + Mirwald',
        url: null,
        version: '0',
        __typename: 'TestSet'
      },
      __typename: 'TestSession'
    },
    {
      id: '27d659e7-99c4-414d-860b-5d20a747959e',
      name: 'My Session',
      startdate: '2020-02-03',
      finished: null,
      finishedPercentage: 33.0,
      testSet: {
        id: '2698301d-e5c9-4bc8-807c-bda4e86e75cf',
        name: 'i-do',
        type: 'ido',
        locale: 'nl-nl',
        subtype: 'ido',
        url: null,
        version: '1',
        __typename: 'TestSet'
      },
      __typename: 'TestSession'
    },
    {
      id: 'c374032b-e18e-44b3-b2fc-b7120f8822e5',
      name: 'Armspan',
      startdate: '2020-06-05',
      finished: null,
      finishedPercentage: 0.0,
      testSet: {
        id: '08044fc2-89e9-4922-8cdb-67ea38c10958',
        name: 'Arm span',
        type: 'ido',
        locale: null,
        subtype: 'Arm span',
        url: null,
        version: '0',
        __typename: 'TestSet'
      },
      __typename: 'TestSession'
    },
    {
      id: 'bcc33b37-0997-4647-9523-628da7c338d0',
      name: 'balance it',
      startdate: '2020-10-13',
      finished: null,
      finishedPercentage: 0.0,
      testSet: {
        id: 'a28ed964-ef75-4ff0-bdd2-0175f8704742',
        name: 'Khamis Roche + Balance beam',
        type: 'ido',
        locale: null,
        subtype: 'Khamis Roche + Balance beam',
        url: null,
        version: '0',
        __typename: 'TestSet'
      },
      __typename: 'TestSession'
    },
    {
      id: '5571e0ca-3340-404e-bddc-4ab11bae1524',
      name: 'Badminton Maylasia 2',
      startdate: '2018-10-10',
      finished: null,
      finishedPercentage: 100.0,
      testSet: {
        id: 'd990f63a-51ab-42b1-90da-d675112f0c50',
        name: 'Badminton',
        type: 'ido',
        locale: null,
        subtype: 'Badminton',
        url: null,
        version: '1',
        __typename: 'TestSet'
      },
      __typename: 'TestSession'
    },
    {
      id: 'ee03549f-47b9-491b-8ec5-339ad55b2651',
      name: 'Badminton Maylasia 1',
      startdate: '2018-04-26',
      finished: null,
      finishedPercentage: 100.0,
      testSet: {
        id: 'd990f63a-51ab-42b1-90da-d675112f0c50',
        name: 'Badminton',
        type: 'ido',
        locale: null,
        subtype: 'Badminton',
        url: null,
        version: '1',
        __typename: 'TestSet'
      },
      __typename: 'TestSession'
    },
    {
      id: '6bd6397b-1290-490b-ba33-7627d1c4dc78',
      name: 'Mirwald',
      startdate: '2020-05-10',
      finished: null,
      finishedPercentage: 50.0,
      testSet: {
        id: 'b303f262-4936-472d-8644-6fa131a683ea',
        name: 'APHV (mirwald)',
        type: 'ido',
        locale: null,
        subtype: 'APHV (mirwald)',
        url: null,
        version: '0',
        __typename: 'TestSet'
      },
      __typename: 'TestSession'
    },
    {
      id: '3e6f0a03-aa69-4884-8275-c5d1d5e6f4b7',
      name: 'sparta',
      startdate: '2020-04-18',
      finished: null,
      finishedPercentage: 0.0,
      testSet: {
        id: 'df8826e2-69cb-4f14-8bf7-112f0de65b09',
        name: 'new spartanova testsets',
        type: 'ido',
        locale: null,
        subtype: 'new spartanova testsets',
        url: null,
        version: '0',
        __typename: 'TestSet'
      },
      __typename: 'TestSession'
    },
    {
      id: '46280130-d653-4a58-9dd9-be22192f877d',
      name: 'balance it 2',
      startdate: '2020-10-13',
      finished: null,
      finishedPercentage: 0.0,
      testSet: {
        id: 'ae0755ab-7d6c-4675-a98b-27bc1b048e3a',
        name: 'aaa',
        type: 'ido',
        locale: null,
        subtype: 'aaa',
        url: null,
        version: '0',
        __typename: 'TestSet'
      },
      __typename: 'TestSession'
    },
    {
      id: 'f161d15d-b935-49fb-9e10-3b377606d9ec',
      name: 'BMI',
      startdate: '2020-05-11',
      finished: null,
      finishedPercentage: 50.0,
      testSet: {
        id: '97f34280-fb18-49f8-8b96-35dfd48236c9',
        name: 'BMI',
        type: 'ido',
        locale: null,
        subtype: 'BMI',
        url: null,
        version: '0',
        __typename: 'TestSet'
      },
      __typename: 'TestSession'
    },
    {
      id: 'a3c2c442-6983-4f00-9b3b-8839921c66be',
      name: 'ido test',
      startdate: '2020-04-04',
      finished: null,
      finishedPercentage: 0.0,
      testSet: {
        id: '2698301d-e5c9-4bc8-807c-bda4e86e75cf',
        name: 'i-do',
        type: 'ido',
        locale: 'nl-nl',
        subtype: 'ido',
        url: null,
        version: '1',
        __typename: 'TestSet'
      },
      __typename: 'TestSession'
    },
    {
      id: 'b3d0cb3e-5181-466d-9131-43ba7ea0006f',
      name: 'Khamis Roche',
      startdate: '2020-06-01',
      finished: null,
      finishedPercentage: 50.0,
      testSet: {
        id: 'bc5e3cd4-a788-4646-bf1d-2c1411d4d0ea',
        name: 'Khamis Roche',
        type: 'ido',
        locale: null,
        subtype: 'Khamis Roche',
        url: null,
        version: '0',
        __typename: 'TestSet'
      },
      __typename: 'TestSession'
    },
    {
      id: 'b7f23d91-edc2-45cd-9902-ec3600e75534',
      name: 'KR + Balance',
      startdate: '2020-06-11',
      finished: null,
      finishedPercentage: 100.0,
      testSet: {
        id: 'd186adf9-b07d-42f3-916e-4d28852e7775',
        name: 'New Spartanova',
        type: 'ido',
        locale: null,
        subtype: 'New Spartanova',
        url: null,
        version: '1',
        __typename: 'TestSet'
      },
      __typename: 'TestSession'
    },
    {
      id: '1c5ae702-a394-406a-a898-0a81181fe926',
      name: 'Test',
      startdate: '2020-05-15',
      finished: null,
      finishedPercentage: 0.0,
      testSet: {
        id: '2698301d-e5c9-4bc8-807c-bda4e86e75cf',
        name: 'i-do',
        type: 'ido',
        locale: 'nl-nl',
        subtype: 'ido',
        url: null,
        version: '1',
        __typename: 'TestSet'
      },
      __typename: 'TestSession'
    },
    {
      id: '6f2accf7-6b15-4aec-9f8b-4177fa5e4755',
      name: 'Essevee',
      startdate: '2020-07-28',
      finished: null,
      finishedPercentage: 45.0,
      testSet: {
        id: '575990fc-6188-47a9-b29c-8e9632c425b4',
        name: 'Test Essevee',
        type: 'ido',
        locale: null,
        subtype: 'Test Essevee',
        url: null,
        version: '0',
        __typename: 'TestSet'
      },
      __typename: 'TestSession'
    },
    {
      id: 'af207bfa-3da2-41d3-af54-0a8e83567801',
      name: 'KR + Balance 2',
      startdate: '2020-08-05',
      finished: null,
      finishedPercentage: 10.0,
      testSet: {
        id: 'd186adf9-b07d-42f3-916e-4d28852e7775',
        name: 'Khamis Roche + Balance beam 2',
        type: 'ido',
        locale: null,
        subtype: 'Khamis Roche + Balance beam 2',
        url: null,
        version: '1',
        __typename: 'TestSet'
      },
      __typename: 'TestSession'
    }
  ]
};

export const testSetFullWithSuperTitle = {
  getTestSets: [
    {
      id: 11,
      title: 'Functional Leg Length',
      category: 'Anthropometrics',
      checked: true,
      duration: 60,
      side: {
        left: true,
        right: true
      }
    },
    {
      id: 12,
      title: 'Navicular Drop',
      category: 'Anthropometrics',
      checked: true,
      duration: 60,
      side: {
        left: false,
        right: true
      }
    },
    {
      id: 13,
      title: 'Straight Leg Raise',
      category: 'Flexibility',
      checked: true,
      duration: 60,
      side: {
        left: true,
        right: true
      }
    },
    {
      id: 14,
      title: "Ely's Test",
      category: 'Flexibility',
      checked: true,
      duration: 60,
      side: {
        left: true,
        right: true
      }
    },
    {
      id: 15,
      title: 'Isokinetic Knee Concentric',
      category: 'Strength',
      checked: true,
      duration: 1920,
      side: {
        left: true,
        right: true
      }
    },
    {
      id: 16,
      title: 'Injury History',
      category: 'Other',
      checked: true,
      duration: 60,
      side: {
        left: false,
        right: true
      }
    }
  ]
};

export const testSet = {
  getTestSets: [
    {
      id: '1a',
      testCategory: 'Anthropometrics',
      tests: [
        {
          id: '1a1',
          title: 'Functional Leg Length',
          checked: true,
          side: {
            left: true,
            right: true
          }
        },
        {
          id: '1a2',
          title: 'Navicular Drop',
          checked: true,
          side: {
            left: true,
            right: true
          }
        }
      ]
    },
    {
      id: 1,
      testCategory: 'Flexibility',
      tests: [
        {
          id: 11,
          title: 'Straight Leg Raise',
          checked: true,
          side: {
            left: true,
            right: true
          }
        },
        {
          id: 12,
          title: "Ely's Test",
          checked: true,
          side: {
            left: true,
            right: true
          }
        }
      ]
    },

    {
      id: 2,
      testCategory: 'Strength',
      tests: [
        {
          id: 21,
          title: 'Deep Neck Flexor Endurance',
          checked: false,
          side: {
            left: false,
            right: false
          }
        },
        {
          id: 22,
          title: 'Isokinetic Knee Concentric',
          checked: true,
          side: {
            left: false,
            right: false
          }
        }
      ]
    },
    {
      id: 3,
      testCategory: 'Functional Analyses',
      tests: [
        {
          id: 31,
          title: 'Rotation Stability Core',
          checked: false,
          side: {
            left: false,
            right: false
          }
        },
        {
          id: 32,
          title: 'Lateral Step Down',
          checked: false,
          side: {
            left: true,
            right: true
          }
        },
        {
          id: 33,
          title: "Waiter's Bow",
          checked: false,
          side: {
            left: false,
            right: false
          }
        }
      ]
    },
    {
      id: 4,
      testCategory: 'Other',
      tests: [
        {
          id: 31,
          title: 'Injury History',
          checked: true,
          side: {
            left: false,
            right: false
          }
        }
      ]
    }
  ]
};

export const flattenTestSet = {
  getTestSets: [
    {
      id: 11,
      title: 'Straight Leg Raise',
      category: 'Flexibility',
      duration: 60,
      checked: true,
      side: {
        left: true,
        right: true
      }
    },
    {
      id: 12,
      title: "Ely's Test",
      category: 'Flexibility',
      duration: 60,
      checked: true,
      side: {
        left: true,
        right: true
      }
    },
    {
      id: 21,
      title: 'Deep Neck Flexor Endurance',
      category: 'Strength',
      duration: 1500,
      checked: true,
      side: {
        left: false,
        right: false
      }
    },
    {
      id: 31,
      title: 'Rotation Stability Core',
      category: 'Functional Analyses',
      duration: 60,
      checked: false,
      side: {
        left: false,
        right: false
      }
    },
    {
      id: 32,
      title: 'Lateral Step Down',
      category: 'Functional Analyses',
      duration: 360,
      checked: false,
      side: {
        left: true,
        right: true
      }
    },
    {
      id: 33,
      title: "Waiter's Bow",
      category: 'Functional Analyses',
      duration: 420,
      checked: false,
      side: {
        left: false,
        right: false
      }
    }
  ]
};

export const injuriesSelector = {
  getInjuries: [
    {
      id: 1,
      title: 'Anterior Knee Pain',
      side: { left: true, right: true, noSide: false },
      selectedSide: { left: true, right: true, noSide: false }
    },
    {
      id: 2,
      title: 'Lateral Knee Pain',
      side: { left: true, right: true, noSide: false },
      selectedSide: { left: false, right: true, noSide: false }
    },
    {
      id: 3,
      title: 'Low Back Pain',
      side: { left: false, right: false, noSide: true },
      selectedSide: { left: false, right: false, noSide: true }
    },
    {
      id: 4,
      title: 'Neck Pain',
      side: { left: false, right: false, noSide: true },
      selectedSide: { left: false, right: false, noSide: false }
    },
    {
      id: 5,
      title: 'Achilles Tendon Injury',
      side: { left: true, right: true, noSide: false },
      selectedSide: { left: false, right: false, noSide: false }
    },
    {
      id: 6,
      title: 'Ankle Sprain',
      side: { left: true, right: true, noSide: false },
      selectedSide: { left: false, right: false, noSide: false }
    },
    {
      id: 7,
      title: 'Groin Injury',
      side: { left: true, right: true, noSide: false },
      selectedSide: { left: false, right: false, noSide: false }
    },
    {
      id: 8,
      title: 'Hamstring Strain',
      side: { left: true, right: true, noSide: false },
      selectedSide: { left: false, right: false, noSide: false }
    },
    {
      id: 9,
      title: 'Knee Ligament Injury',
      side: { left: true, right: true, noSide: false },
      selectedSide: { left: false, right: false, noSide: false }
    },
    {
      id: 10,
      title: 'Plantar Fasciitis',
      side: { left: true, right: true, noSide: false },
      selectedSide: { left: false, right: false, noSide: false }
    },
    {
      id: 11,
      title: 'Quadriceps Strain',
      side: { left: true, right: true, noSide: false },
      selectedSide: { left: false, right: false, noSide: false }
    },
    {
      id: 12,
      title: 'Redcord Stability',
      side: { left: true, right: true, noSide: false },
      selectedSide: { left: false, right: false, noSide: false }
    },
    {
      id: 13,
      title: 'Shoulder Overuse',
      side: { left: true, right: true, noSide: false },
      selectedSide: { left: false, right: false, noSide: false }
    },
    {
      id: 14,
      title: 'Shoulder Overuse Throwing',
      side: { left: true, right: true, noSide: false },
      selectedSide: { left: false, right: false, noSide: false }
    }
  ]
};

export const fullEvaluationHockeyData = {
  getInjuries: [
    {
      id: 1,
      title: 'Ankle Sprain',
      values: {
        left: 25,
        right: 63,
        noSide: null
      }
    },
    {
      id: 2,
      title: 'Hamstring Strain',
      values: {
        left: 52,
        right: 69,
        noSide: null
      }
    },
    {
      id: 3,
      title: 'Groin Injury',
      values: {
        left: 49,
        right: 52,
        noSide: null
      },
      testSet: [
        {
          id: 31,
          name: 'Strength',
          testItems: [
            {
              id: 311,
              name: 'Hip Extensor Strength',
              values: { left: 100, right: 65, noSide: null }
            },
            {
              id: 312,
              name: 'Hip Abductor Strength',
              values: { left: 100, right: 83, noSide: null }
            }
          ]
        },
        {
          id: 32,
          name: 'Flexibility',
          testItems: [
            {
              id: 321,
              name: 'Iliopsoas Flexibility',
              values: { left: 66, right: 90, noSide: null }
            },
            {
              id: 322,
              name: 'Hip External Rotation ROM',
              values: { left: 60, right: 60, noSide: null }
            },
            {
              id: 323,
              name: 'Hip Internal Rotation ROM',
              values: { left: 70, right: 70, noSide: null }
            },
            {
              id: 324,
              name: 'Hip Adductor Flexibility',
              values: { left: 0, right: 63, noSide: null }
            }
          ]
        },
        {
          id: 33,
          name: 'Stability',
          testItems: [
            {
              id: 331,
              name: 'Core Stability',
              values: { left: null, right: null, noSide: 0 },
              subTestItems: [
                {
                  id: 3311,
                  name: 'Core Endurance Ventral',
                  values: { left: null, right: null, noSide: 0 }
                },
                {
                  id: 3312,
                  name: 'Core Rotatory Control',
                  values: { left: null, right: null, noSide: 70 }
                },
                {
                  id: 3313,
                  name: 'Core Sagittal Control',
                  values: { left: null, right: null, noSide: 25 }
                }
              ]
            },
            {
              id: 332,
              name: 'Leg Stability',
              values: { left: 44, right: 44, noSide: null },
              subTestItems: [
                {
                  id: 3321,
                  name: 'Leg Alignment',
                  values: { left: 10, right: 10, noSide: null }
                },
                {
                  id: 3322,
                  name: 'Foot Alignment',
                  values: { left: 22, right: 22, noSide: null }
                },
                {
                  id: 3323,
                  name: 'Dynamic Balance',
                  values: { left: 70, right: 70, noSide: null }
                },
                {
                  id: 3324,
                  name: 'Pelvic Control',
                  values: { left: 70, right: 70, noSide: null }
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: 4,
      title: 'Knee Ligament Injury',
      values: {
        left: 51,
        right: 64,
        noSide: null
      }
    },
    {
      id: 5,
      title: 'Low Back Pain',
      values: {
        left: null,
        right: null,
        noSide: 72
      }
    }
  ]
};

export const cols = [
  {
    id: 1
  },
  {
    id: 2
  },
  {
    id: 3
  },
  {
    id: 4
  }
];

export const rows = [
  {
    id: 1
  },
  {
    id: 2
  },
  {
    id: 3
  },
  { id: 4 },
  { id: 5 },
  { id: 6 },
  { id: 7 },
  { id: 8 },
  { id: 9 },
  { id: 10 },
  { id: 11 },
  { id: 12 }
];

export const leftRightData = [
  {
    id: 1,
    side: 'right',
    value: 100,
    unit: 'mins'
  },
  {
    id: 2,
    side: 'left',
    value: 80,
    unit: 'mins'
  }
];

export const testDummySessionForProgram = [
  {
    id: 'Flexibility',
    label: 'Flexibility',
    children: [
      {
        id: 'b4e5b4df-fed4-4944-a46b-afc6f2c13473',
        title: "Ely's Test",
        category: 'Flexibility',
        testItems: [
          {
            id: '9a5256ac-e47c-4eea-a1f0-14edac8b3971',
            title: 'Quadriceps Flexibility',
            unit: '°',
            calculation: 'none',
            best_performance: '0',
            input_type: 'Free number',
            input_type_parameters: '{"minimum":0,"maximum":165}',
            order: 0,
            benchmarks: ['recNFTkfSgPiAjLER'],
            date_created: '2021-02-19T15:10:43.000Z',
            date_updated: '2021-03-16T10:01:47.000Z',
            duration: 10,
            best_value: 0,
            inputtype: 'free_number',
            minimum: 0,
            maximum: 165,
            airId: 'rec3VOT3Xndkq7nIa',
            laterality: 'bilateral_independent',
            trials: 1,
            values: {
              0: [0],
              1: [10]
            },
            result: {
              0: [0],
              1: [10]
            },
            benchmarked: {
              0: 0,
              1: 0
            }
          }
        ],
        trials: 1,
        video_link:
          'https://sportkompas-platform-hlapp-videos.s3.eu-west-1.amazonaws.com/Ely%27s%20Test-converted',
        modules: ['prevention', 'rehab'],
        laterality: 'bilateral_independent',
        equipment: 'Long goniometer;Treatment table',
        injury_sport_tests: [
          'recW0wvXr7bOr5scW',
          'recOUuUXq98BKGNHZ',
          'reccWCDA4A4Z770Lf',
          'rec4U15TstMk6NYjg',
          'recH0Y9LP7z7oVxaC',
          'recgFxPgGQhc379GU',
          'rec9pf1Bfr0F9oDux',
          'receUOiHcZzSCf6BA',
          'recMoxCf492UiksKt',
          'recew1osCthDbm7Jo',
          'rec3oCVItwiEaKkpJ',
          'recamWeUFDWPsKovy',
          'recbEE3QQBegMAW7S'
        ],
        duration: 2,
        date_updated: '2021-04-07T08:34:04.000Z',
        date_created: '2021-02-08T15:21:00.000Z',
        'title (from testitems)': ['Quadriceps Flexibility'],
        airId: 'rec2RXG8z18qrA8KT'
      },
      {
        id: '2563b32a-f168-4ee8-b115-1fa0d0c13e03',
        title: 'Straight Leg Raise',
        category: 'Flexibility',
        testItems: [
          {
            id: 'e2441671-eb38-4a2b-b14c-c7a275b2df26',
            title: 'Hamstring Flexibility SLR',
            unit: '°',
            calculation: 'none',
            best_performance: '1',
            input_type: 'Free number',
            input_type_parameters: '{"minimum":0,"maximum":180}',
            order: 1,
            benchmarks: [
              'rec2lMXAISUY1YzIn',
              'reca4FCh2md7390vu',
              'reccueuMoop0uqaLQ'
            ],
            date_created: '2021-02-19T16:25:59.000Z',
            date_updated: '2021-04-06T17:56:04.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'free_number',
            minimum: 0,
            maximum: 180,
            airId: 'recL5tWiBCPSteDtm',
            laterality: 'bilateral_independent',
            trials: 1,
            values: {
              0: [150],
              1: [75]
            },
            result: {
              0: [150],
              1: [75]
            },
            benchmarked: {
              0: 1,
              1: 0.675
            }
          }
        ],
        trials: 1,
        video_link:
          'https://sportkompas-platform-hlapp-videos.s3.eu-west-1.amazonaws.com/Straight%20Leg%20Raise-converted',
        modules: ['rehab', 'prevention'],
        laterality: 'bilateral_independent',
        equipment: 'Inclinometer;Treatment table',
        injury_sport_tests: [
          'recW0wvXr7bOr5scW',
          'recjwBe3BD9qQEfiH',
          'recJD2JoASiV0MSNv',
          'recCL88Njp0lKrlcr',
          'recuL3tNCqiLQUS8U',
          'recslo0TTotPU5F9O',
          'recOUuUXq98BKGNHZ',
          'recyMSaZ1g6Eu8gly',
          'recBWw7cm8DmfxGXn',
          'rechEuQClLKqLuEgq',
          'recuTNqXDAkuZ7D4o',
          'rec3oCVItwiEaKkpJ',
          'recamWeUFDWPsKovy',
          'recbEE3QQBegMAW7S',
          'recfYstkyOTbBRSvZ'
        ],
        duration: 2,
        date_updated: '2021-04-07T08:38:11.000Z',
        date_created: '2021-02-16T14:49:23.000Z',
        'title (from testitems)': ['Hamstring Flexibility SLR'],
        airId: 'recrR055pNed2KD7B'
      }
    ]
  },
  {
    id: 'Functional Analysis',
    label: 'Functional Analysis',
    children: [
      {
        id: '31608f6e-0fc2-4c0a-93cf-1b7b9aac7470',
        title: 'Craniocervical Flexion Test',
        category: 'Functional Analysis',
        testItems: [
          {
            id: '00648c4d-a75c-47a5-bc60-86cf6b14885c',
            title: 'Cervical Motor Control',
            unit: '- None -',
            calculation: 'none',
            best_performance: '1',
            input_type: 'Select with Options',
            input_type_parameters:
              '{"minimum":1,"maximum":5,"options":[{"value":1,"label":"to 22 mmHg or lower"},{"value":2,"label":"to 24 mmHg"},{"value":3,"label":"to 26 mmHg"},{"value":4,"label":"to 28 mmHg"},{"value":5,"label":"to 30 mmHg"}]}',
            order: 0,
            benchmarks: ['rec1tgG4c7qsmpj0o'],
            date_created: '2021-02-22T10:15:46.000Z',
            date_updated: '2021-03-16T11:56:45.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'select_with_options',
            minimum: 1,
            maximum: 5,
            airId: 'rec7yNUoIfGepEJSH',
            laterality: 'unilateral',
            trials: 1,
            values: {
              2: [4]
            },
            result: {
              2: [4]
            },
            benchmarked: {
              2: 0
            }
          }
        ],
        trials: 1,
        video_link:
          'https://sportkompas-platform-hlapp-videos.s3.eu-west-1.amazonaws.com/Craniocervical%20Flexion%20Test-converted',
        modules: ['prevention', 'rehab'],
        laterality: 'unilateral',
        equipment: 'Stabilizer',
        injury_sport_tests: ['recLRfMbNHtVhQfij'],
        duration: 2,
        date_updated: '2021-03-23T10:39:18.000Z',
        date_created: '2021-02-08T15:01:38.000Z',
        'title (from testitems)': ['Cervical Motor Control'],
        airId: 'recv3HJRM6d1JEdkU'
      },
      {
        id: '2782f1cb-4a45-48df-9b4d-8a0fa18e626a',
        title: 'Lateral Step Down',
        category: 'Functional Analysis',
        testItems: [
          {
            id: '719a43b4-3a95-4cc7-88e9-b28d3e94eca2',
            title: 'Dynamic Balance',
            unit: '- None -',
            calculation: 'none',
            best_performance: '0',
            input_type: 'Select with Options',
            input_type_parameters:
              '{"minimum":0,"maximum":3,"options":[{"value":0,"label":"Perfect balance"},{"value":1,"label":"Small movements to control balance"},{"value":2,"label":"Moderate movements to control balance"},{"value":3,"label":"Arms move away from the shoulders OR large movements"}]}',
            order: 0,
            hidden: true,
            benchmarks: ['recT9SZZkMzcCCHHt', 'recHCz47pU58keq2N'],
            date_created: '2021-02-22T10:35:08.000Z',
            date_updated: '2021-03-17T12:55:21.000Z',
            duration: 10,
            best_value: 0,
            inputtype: 'select_with_options',
            minimum: 0,
            maximum: 3,
            airId: 'rechStH1zCkS5CLWY',
            laterality: 'bilateral_independent',
            trials: 1,
            values: {
              0: [3],
              1: [1]
            },
            result: {
              0: [3],
              1: [1]
            },
            benchmarked: {
              0: 0.10000000000000009,
              1: 0.7
            }
          },
          {
            id: '69fd71ae-a622-42c6-8965-f0502dedf82c',
            title: 'Lumbopelvic Control',
            unit: '- None -',
            calculation: 'none',
            best_performance: '0',
            input_type: 'Select with Options',
            input_type_parameters:
              '{"minimum":0,"maximum":3,"options":[{"value":0,"label":"Both ASIS level"},{"value":1,"label":"Small pelvic drop (<10°)"},{"value":2,"label":"Moderate pelvic drop (10°-20°) "},{"value":3,"label":"Severe drop (>20°) or weight transfer"}]}',
            order: 1,
            hidden: true,
            benchmarks: ['rec26sONBloq9Quds', 'recOMkK8YH3g44hoG'],
            date_created: '2021-02-22T10:35:47.000Z',
            date_updated: '2021-03-17T12:56:07.000Z',
            duration: 10,
            best_value: 0,
            inputtype: 'select_with_options',
            minimum: 0,
            maximum: 3,
            airId: 'recW3nTNpadV5gkh2',
            laterality: 'bilateral_independent',
            trials: 1,
            values: {
              0: [2],
              1: [1]
            },
            result: {
              0: [2],
              1: [1]
            },
            benchmarked: {
              0: 0,
              1: 0.22999999999999998
            }
          },
          {
            id: 'd795cfd8-7015-4c46-8d3c-f2d55d4ccde2',
            title: 'Leg Alignment / Knee Valgus',
            unit: '- None -',
            calculation: 'none',
            best_performance: '0',
            input_type: 'Select with Options',
            input_type_parameters:
              '{"minimum":0,"maximum":3,"options":[{"value":0,"label":"Central patella always in line with hip joint and second toe"},{"value":1,"label":"Small oscillatory movements around neutral position"},{"value":2,"label":"Central patella over great toe"},{"value":3,"label":"Central patella medial from great toe"}]}',
            order: 3,
            hidden: true,
            benchmarks: [
              'rec9HfuQ9wlF9nCn8',
              'rec7eNBzyXfHu5VOV',
              'recUFgpCnnyXwXWyE'
            ],
            date_created: '2021-02-22T10:37:09.000Z',
            date_updated: '2021-03-16T16:33:27.000Z',
            duration: 10,
            best_value: 0,
            inputtype: 'select_with_options',
            minimum: 0,
            maximum: 3,
            airId: 'recRDdg92Pyfdp2WQ',
            laterality: 'bilateral_independent',
            trials: 1,
            values: {
              0: [2],
              1: [2]
            },
            result: {
              0: [2],
              1: [2]
            },
            benchmarked: {
              0: 0.4,
              1: 0.4
            }
          }
        ],
        trials: 1,
        video_link:
          'https://sportkompas-platform-hlapp-videos.s3.eu-west-1.amazonaws.com/Lateral%20Step%20Down',
        modules: ['prevention', 'rehab'],
        laterality: 'bilateral_independent',
        equipment: 'Test box;Smartphone',
        injury_sport_tests: [
          'recW0wvXr7bOr5scW',
          'recOUuUXq98BKGNHZ',
          'recbGW2bOAabVCah9',
          'rec2VLFGQCiKbJBhQ',
          'reccWCDA4A4Z770Lf',
          'rec4U15TstMk6NYjg',
          'recH0Y9LP7z7oVxaC',
          'recgFxPgGQhc379GU',
          'rec9pf1Bfr0F9oDux',
          'receUOiHcZzSCf6BA',
          'rec9lsbsyEsFKjntf',
          'recXfudTKhYbS1Czz',
          'recyMSaZ1g6Eu8gly',
          'recCW584lFsLHFGrV',
          'recv1Q2w4EVxB5feU',
          'recVpuz4ID2N5x62R',
          'recPT93h0biionwSn',
          'rec4yIwdoGC1tzfac',
          'recBWw7cm8DmfxGXn',
          'rec33e2fgHMXg24YW',
          'recHXFBePt6fsexgy',
          'recuTNqXDAkuZ7D4o',
          'rec3oCVItwiEaKkpJ',
          'recbEE3QQBegMAW7S'
        ],
        duration: 5,
        date_updated: '2021-04-07T14:34:59.000Z',
        date_created: '2021-02-10T14:22:46.000Z',
        'title (from testitems)': [
          'Dynamic Balance',
          'Lumbopelvic Control',
          'Leg Alignment / Knee Valgus'
        ],
        airId: 'rec3lV6P71Cw723Am'
      },
      {
        id: '34ec78be-324e-4b06-a352-bbd1defb738c',
        title: 'Rotation Stability Test',
        category: 'Functional Analysis',
        testItems: [
          {
            id: '6f9a480d-23c8-47aa-adca-63a73e1f1974',
            title: 'Level 0 Rotatory Stability',
            unit: '- None -',
            calculation: 'none',
            best_performance: '1',
            input_type: 'Free number',
            input_type_parameters: '{"minimum":0,"maximum":4}',
            order: 0,
            date_created: '2021-02-22T10:56:22.000Z',
            date_updated: '2021-04-09T09:50:27.000Z',
            hidden: true,
            duration: 10,
            best_value: 1,
            inputtype: 'free_number',
            minimum: 0,
            maximum: 4,
            airId: 'rec8TPQ17ht0Lj6ct',
            laterality: 'unilateral',
            trials: 1,
            values: {
              2: [1]
            },
            result: {
              2: [1]
            },
            benchmarked: {}
          },
          {
            id: '7dfea426-bd53-44c8-820a-e50e95a1813b',
            title: 'Level 0 Sagittal Stability',
            unit: '- None -',
            calculation: 'none',
            best_performance: '1',
            input_type: 'Free number',
            input_type_parameters: '{"minimum":0,"maximum":2}',
            order: 1,
            date_created: '2021-02-22T10:56:52.000Z',
            date_updated: '2021-04-09T09:50:33.000Z',
            hidden: true,
            duration: 10,
            best_value: 1,
            inputtype: 'free_number',
            minimum: 0,
            maximum: 2,
            airId: 'reclO8lIW22dnTupu',
            laterality: 'unilateral',
            trials: 1,
            values: {
              2: [0.5]
            },
            result: {
              2: [0.5]
            },
            benchmarked: {}
          },
          {
            id: '53dbc78c-a877-4fec-86c1-d95a0ddc6fe1',
            title: 'Level 1 Rotatory Stability',
            unit: '- None -',
            calculation: 'none',
            best_performance: '1',
            input_type: 'Select with Options',
            input_type_parameters:
              '{"minimum":0,"maximum":4,"options":[{"value":0,"label":"No deviation from neutral alignment"},{"value":1,"label":"Small movement out of neutral position "},{"value":2,"label":"Moderate movement out of neutral position "},{"value":3,"label":"Severe movement out of neutral position"},{"value":4,"label":"Not able to perform 5 times"}]}',
            order: 2,
            hidden: true,
            date_created: '2021-02-22T10:58:40.000Z',
            date_updated: '2021-03-16T16:42:38.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'select_with_options',
            minimum: 0,
            maximum: 4,
            airId: 'recqLMNs9MKxoAZ5H',
            laterality: 'unilateral',
            trials: 1,
            values: {
              2: [0.7]
            },
            result: {
              2: [0.7]
            },
            benchmarked: {}
          },
          {
            id: '75c4e807-8264-4c79-9880-00c9815e6942',
            title: 'Level 1 Sagittal Stability',
            unit: '- None -',
            calculation: 'none',
            best_performance: '1',
            input_type: 'Select with Options',
            input_type_parameters:
              '{"minimum":0,"maximum":2,"options":[{"value":0,"label":"Neutral lumbar lordosis"},{"value":1,"label":"Loss of lumbar lordosis until flat lumbar spine"},{"value":2,"label":"Loss of lumbar lordosis to kyfotic lumbar spine"}]}',
            order: 3,
            hidden: true,
            date_created: '2021-02-22T10:59:31.000Z',
            date_updated: '2021-03-16T16:44:42.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'select_with_options',
            minimum: 0,
            maximum: 2,
            airId: 'recwoXHcurUec4D67',
            laterality: 'unilateral',
            trials: 1,
            values: {
              2: [2]
            },
            result: {
              2: [2]
            },
            benchmarked: {}
          },
          {
            id: 'b5a47eac-dd76-4bc6-b162-262bdedab819',
            title: 'Level 2 Rotatory Stability',
            unit: '- None -',
            calculation: 'none',
            best_performance: '1',
            input_type: 'Select with Options',
            input_type_parameters:
              '{"minimum":0,"maximum":4,"options":[{"value":0,"label":"No deviation from neutral alignment "},{"value":1,"label":"Small movement out of neutral position "},{"value":2,"label":"Moderate movement out of neutral position "},{"value":3,"label":" Severe movement out of neutral position "},{"value":4,"label":" Not able to perform 5 times "}]}',
            order: 4,
            hidden: true,
            date_created: '2021-02-22T11:00:00.000Z',
            date_updated: '2021-03-16T16:43:50.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'select_with_options',
            minimum: 0,
            maximum: 4,
            airId: 'rech6UFB1ZkzKgBtc',
            laterality: 'unilateral',
            trials: 1,
            values: {
              2: [3.5]
            },
            result: {
              2: [3.5]
            },
            benchmarked: {}
          },
          {
            id: '33b811e2-48be-4478-8841-e3771f4c8701',
            title: 'Level 2 Sagittal Stability\t',
            unit: '- None -',
            calculation: 'none',
            best_performance: '1',
            input_type: 'Select with Options',
            input_type_parameters:
              '{"minimum":0,"maximum":2,"options":[{"value":0,"label":"Neutral lumbar lordosis"},{"value":1,"label":"Loss of lumbar lordosis until flat lumbar spine"},{"value":2,"label":"Loss of lumbar lordosis to kyfotic lumbar spine"}]}',
            order: 5,
            hidden: true,
            date_created: '2021-02-22T11:00:19.000Z',
            date_updated: '2021-03-16T16:45:27.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'select_with_options',
            minimum: 0,
            maximum: 2,
            airId: 'recIaU8XfAEKB6Nz6',
            laterality: 'unilateral',
            trials: 1,
            values: {
              2: [0.5]
            },
            result: {
              2: [0.5]
            },
            benchmarked: {}
          },
          {
            id: 'fca5b609-e544-436d-9d57-9e233e4f2790',
            title: 'Core Stability - Rotation Stability',
            unit: '- None -',
            calculation: 'none',
            best_performance: '1',
            formula:
              'sum(53dbc78c-a877-4fec-86c1-d95a0ddc6fe1, b5a47eac-dd76-4bc6-b162-262bdedab819)',
            input_type: 'derived',
            input_type_parameters: '{"minimum":0,"maximum":8}',
            order: 6,
            benchmarks: ['reca5NGc62VBnXxqY'],
            date_created: '2021-02-22T11:01:38.000Z',
            date_updated: '2021-03-16T11:29:48.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'derived',
            minimum: 0,
            maximum: 8,
            airId: 'recGnKu4ozjdkL211',
            laterality: 'unilateral',
            trials: 1,
            values: {
              0: '0.73.5'
            },
            result: {
              0: '0.73.5'
            },
            benchmarked: {
              0: null
            }
          },
          {
            id: 'a855d24b-e8fa-4e4e-ae31-c2b7aa29d9b8',
            title: 'Core Stability - Sagittal Stability',
            unit: '- None -',
            calculation: 'none',
            best_performance: '0',
            formula:
              'sum(75c4e807-8264-4c79-9880-00c9815e6942, 33b811e2-48be-4478-8841-e3771f4c8701)',
            input_type: 'derived',
            input_type_parameters: '{"minimum":0,"maximum":4}',
            order: 7,
            benchmarks: ['recIYXTnTrrtBVZZP'],
            date_created: '2021-02-22T11:07:23.000Z',
            date_updated: '2021-03-16T11:30:26.000Z',
            duration: 10,
            best_value: 0,
            inputtype: 'derived',
            minimum: 0,
            maximum: 4,
            airId: 'recjq5j81KsjUrtZs',
            laterality: 'unilateral',
            trials: 1,
            values: {
              0: '20.5'
            },
            result: {
              0: '20.5'
            },
            benchmarked: {
              0: 0
            }
          }
        ],
        trials: 1,
        video_link:
          'https://sportkompas-platform-hlapp-videos.s3.eu-west-1.amazonaws.com/Rotation%20Stability%20Test',
        modules: ['prevention', 'rehab'],
        laterality: 'unilateral',
        injury_sport_tests: [
          'rec73E9hwLoMy9TCp',
          'recW0wvXr7bOr5scW',
          'recjwBe3BD9qQEfiH',
          'recCL88Njp0lKrlcr',
          'recuL3tNCqiLQUS8U',
          'recOUuUXq98BKGNHZ',
          'recbGW2bOAabVCah9',
          'reccWCDA4A4Z770Lf',
          'recH0Y9LP7z7oVxaC',
          'recgFxPgGQhc379GU',
          'rec9pf1Bfr0F9oDux',
          'receUOiHcZzSCf6BA',
          'recQHHIwLaOA1QI5s',
          'reccbQwSH4BCOf8Gx',
          'rec9lsbsyEsFKjntf',
          'recXfudTKhYbS1Czz',
          'recyMSaZ1g6Eu8gly',
          'recCW584lFsLHFGrV',
          'recv1Q2w4EVxB5feU',
          'recVpuz4ID2N5x62R',
          'recPT93h0biionwSn',
          'rec4yIwdoGC1tzfac',
          'rechEuQClLKqLuEgq',
          'recuTNqXDAkuZ7D4o',
          'rec3oCVItwiEaKkpJ',
          'recamWeUFDWPsKovy',
          'recWb3veBCRAokbzk',
          'recpgDUnVzkLPwPee',
          'recbEE3QQBegMAW7S',
          'recfYstkyOTbBRSvZ'
        ],
        duration: 4,
        date_updated: '2021-04-07T08:38:11.000Z',
        date_created: '2021-02-15T10:51:56.000Z',
        'title (from testitems)': [
          'Level 0 Rotatory Stability',
          'Level 0 Sagittal Stability',
          'Level 1 Rotatory Stability',
          'Level 1 Sagittal Stability',
          'Level 2 Rotatory Stability',
          'Level 2 Sagittal Stability\t',
          'Core Stability - Rotation Stability',
          'Core Stability - Sagittal Stability'
        ],
        airId: 'recbnz5S1zVbyJqaB'
      }
    ]
  },
  {
    id: 'Strength',
    label: 'Strength',
    children: [
      {
        id: '7cf39064-b68b-42f2-b3e0-8d327bc32c08',
        title: 'Active SLR',
        category: 'Strength',
        testItems: [
          {
            id: '91c2e3aa-86c4-45f3-83cb-6e298628e224',
            title: 'Active SLR',
            unit: '- None -',
            calculation: 'none',
            best_performance: '0',
            input_type: 'Select with Options',
            input_type_parameters:
              '{"minimum":0,"maximum":1,"options":[{"value":0,"label":"No Lag"},{"value":1,"label":"Lag"}]}',
            order: 0,
            benchmarks: ['recNr3WCCpyp6VUCD'],
            date_created: '2021-02-23T09:13:00.000Z',
            date_updated: '2021-03-18T13:38:07.000Z',
            duration: 10,
            best_value: 0,
            inputtype: 'select_with_options',
            minimum: 0,
            maximum: 1,
            airId: 'rec5imNQ8zTUaY9To',
            laterality: 'bilateral_independent',
            trials: 1,
            values: {
              0: [0.5]
            },
            result: {
              0: [0.5]
            },
            benchmarked: {}
          }
        ],
        trials: 1,
        video_link:
          'https://sportkompas-platform-hlapp-videos.s3.eu-west-1.amazonaws.com/Active%20SLR-converted',
        modules: ['prevention', 'rehab'],
        laterality: 'bilateral_independent',
        equipment: 'Treatment table',
        injury_sport_tests: [
          'recnHIYo31CGGhhh1',
          'reciQpEg1WA2oMy4A',
          'recx5blcZvg50rwkS',
          'recZZFSupZ1mLgDgf',
          'recyKnwBnvQBNP8MD',
          'recfsdcAZty0PIeX3',
          'recRGCsfL5nYbjeOZ',
          'recb1pQxXccXPqEYm'
        ],
        duration: 2,
        date_updated: '2021-04-07T14:34:59.000Z',
        date_created: '2021-02-08T13:28:22.000Z',
        'title (from testitems)': ['Active SLR'],
        airId: 'recKZ9mRaWUYKBt4F'
      },
      {
        id: '54f5bf48-2f64-4434-bb10-de9c27162bb6',
        title: 'Arm Curl Test',
        category: 'Strength',
        testItems: [
          {
            id: '2c48baa7-b910-434d-8656-ce3906a56730',
            title: 'Arm Curl Test',
            unit: '#',
            calculation: 'best_performance',
            best_performance: '1',
            input_type: 'Free number',
            input_type_parameters: '{"minimum":0,"maximum":100}',
            order: 0,
            date_created: '2021-02-08T09:49:13.000Z',
            date_updated: '2021-02-11T13:39:17.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'free_number',
            minimum: 0,
            maximum: 100,
            airId: 'recbwPzBDvaoPnsOn',
            laterality: 'unilateral',
            trials: 1,
            values: {
              2: [80]
            },
            result: {
              2: [80]
            },
            benchmarked: {}
          }
        ],
        countdown: true,
        timer: 30,
        trials: 1,
        'Test beperken tot organisaties': 'Sekora',
        modules: ['talent'],
        laterality: 'unilateral',
        date_updated: '2021-02-08T09:49:13.000Z',
        date_created: '2021-02-08T08:58:44.000Z',
        'title (from testitems)': ['Arm Curl Test'],
        duration: 0,
        airId: 'rec8A0jMAlkcU1KDH'
      },
      {
        id: 'a752d65f-dc13-44be-91c3-d6eda57c6a6a',
        title: 'Deep Neck Flexor Endurance',
        category: 'Strength',
        testItems: [
          {
            id: '8b31b82b-13ce-49fc-88f3-1d2801e2725b',
            title: 'Deep Neck Flexor Endurance',
            unit: 's',
            calculation: 'none',
            best_performance: '1',
            input_type: 'Free number',
            input_type_parameters: '{"minimum":0,"maximum":200}',
            order: 0,
            benchmarks: ['recJJnCxgFMKdAhQa'],
            date_created: '2021-02-23T09:14:17.000Z',
            date_updated: '2021-03-16T10:22:06.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'free_number',
            minimum: 0,
            maximum: 200,
            airId: 'recDfI9i4pLODegoD',
            laterality: 'unilateral',
            trials: 1,
            values: {
              2: [0]
            },
            result: {
              2: [0]
            },
            benchmarked: {
              2: 0.1
            }
          }
        ],
        trials: 1,
        video_link:
          'https://sportkompas-platform-hlapp-videos.s3.eu-west-1.amazonaws.com/Deep%20Neck%20Flexor%20Endurance-converted',
        modules: ['prevention', 'rehab'],
        laterality: 'unilateral',
        equipment: 'Treatment table;Stopwatch;Pen',
        injury_sport_tests: ['recLRfMbNHtVhQfij', 'recyMSaZ1g6Eu8gly'],
        duration: 2,
        date_updated: '2021-03-23T10:39:31.000Z',
        date_created: '2021-02-08T15:02:24.000Z',
        'title (from testitems)': ['Deep Neck Flexor Endurance'],
        airId: 'rec8GYa5EJ7kZ0kM5'
      },
      {
        id: '4236483d-7c29-42e5-9fcb-7e8d0e16ff7f',
        title: 'Isokinetic Knee Concentric',
        category: 'Strength',
        testItems: [
          {
            id: '57576377-3288-4bd6-8a0f-994e51c06a5b',
            title: 'Hamstrings Concentric Strength 60°/s Score',
            unit: 'Nm',
            calculation: 'none',
            best_performance: '1',
            input_type: 'Free number',
            input_type_parameters: '{"minimum":0,"maximum":500}',
            order: 0,
            hidden: true,
            date_created: '2021-02-23T09:46:26.000Z',
            date_updated: '2021-03-10T13:20:51.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'free_number',
            minimum: 0,
            maximum: 500,
            airId: 'recgy0d35xQtCdxgB',
            laterality: 'bilateral_dependent',
            trials: 1,
            values: {
              0: [500]
            },
            result: {
              0: [500]
            },
            benchmarked: {}
          },
          {
            id: 'ba62cc4c-586d-4a2f-8412-c4fd5ecbde23',
            title: 'Hamstrings Concentric Strength 240°/s Score\t',
            unit: 'Nm',
            calculation: 'none',
            best_performance: '1',
            input_type: 'Free number',
            input_type_parameters: '{"minimum":0,"maximum":500}',
            order: 1,
            hidden: true,
            date_created: '2021-02-23T09:47:05.000Z',
            date_updated: '2021-03-10T13:21:24.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'free_number',
            minimum: 0,
            maximum: 500,
            airId: 'recWDbaRSFVtvgo2M',
            laterality: 'bilateral_dependent',
            trials: 1,
            values: {
              0: [500]
            },
            result: {
              0: [500]
            },
            benchmarked: {}
          },
          {
            id: 'a3eb5d64-7a9d-402f-aab4-b6cecfe8f776',
            title: 'Quadriceps Concentric Strength 60°/s Score',
            unit: 'Nm',
            calculation: 'none',
            best_performance: '1',
            input_type: 'Free number',
            input_type_parameters: '{"minimum":0,"maximum":500}',
            order: 2,
            date_created: '2021-02-23T09:50:56.000Z',
            date_updated: '2021-04-09T09:52:58.000Z',
            hidden: true,
            duration: 10,
            best_value: 1,
            inputtype: 'free_number',
            minimum: 0,
            maximum: 500,
            airId: 'reclxHNYoByig1JOe',
            laterality: 'bilateral_dependent',
            trials: 1,
            values: {
              0: [0]
            },
            result: {
              0: [0]
            },
            benchmarked: {}
          },
          {
            id: 'b08839e0-323b-4f00-8774-219a141c0189',
            title: 'Quadriceps Concentric Strength 240°/s Score',
            unit: 'Nm',
            calculation: 'none',
            best_performance: '1',
            input_type: 'Free number',
            input_type_parameters: '{"minimum":0,"maximum":500}',
            order: 3,
            date_created: '2021-02-23T09:51:18.000Z',
            date_updated: '2021-04-09T09:53:03.000Z',
            hidden: true,
            duration: 10,
            best_value: 1,
            inputtype: 'free_number',
            minimum: 0,
            maximum: 500,
            airId: 'recNC1nVhETAovG88',
            laterality: 'bilateral_dependent',
            trials: 1,
            values: {
              0: [0]
            },
            result: {
              0: [0]
            },
            benchmarked: {}
          },
          {
            id: 'e22ac265-4c8d-4550-8baa-f8ed98a751b1',
            title: 'Hamstrings Concentric Strength 60°/s',
            unit: '- None -',
            calculation: 'none',
            best_performance: '1',
            formula:
              'ratio({"id": "57576377-3288-4bd6-8a0f-994e51c06a5b", "side": "same"},{"id": "57576377-3288-4bd6-8a0f-994e51c06a5b", "side": "other"})',
            input_type: 'derived',
            input_type_parameters: '{"minimum":0,"maximum":1}',
            order: 4,
            benchmarks: [
              'rec8MByxHxkjxQX0g',
              'rec7021p2ZW3Cw43A',
              'recZKwdh0hOBMWAiN'
            ],
            date_created: '2021-02-23T10:05:29.000Z',
            date_updated: '2021-04-06T17:13:42.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'derived',
            minimum: 0,
            maximum: 1,
            airId: 'recWhVaTlXn5DyGs3',
            laterality: 'bilateral_dependent',
            trials: 1,
            values: {
              0: 3.3333333333333335,
              1: 0.3
            },
            result: {
              0: 3.3333333333333335,
              1: 0.3
            },
            benchmarked: {
              0: 1,
              1: 0
            }
          },
          {
            id: 'd5a50a6c-6dc7-41c7-bb47-98b6daf84c29',
            title: 'Hamstrings Concentric Strength 240°/s',
            unit: '- None -',
            calculation: 'none',
            best_performance: '1',
            formula:
              'ratio({"id": "ba62cc4c-586d-4a2f-8412-c4fd5ecbde23", "side": "same"},\n{"id": "ba62cc4c-586d-4a2f-8412-c4fd5ecbde23", "side": "other"})',
            input_type: 'derived',
            input_type_parameters: '{"minimum":0,"maximum":1}',
            order: 5,
            benchmarks: ['recMuaafnM23hZIRT', 'recUTfegFeEtxqzZ8'],
            date_created: '2021-02-23T10:06:26.000Z',
            date_updated: '2021-04-06T17:16:44.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'derived',
            minimum: 0,
            maximum: 1,
            airId: 'recPdBFok2TCRLBSh',
            laterality: 'bilateral_dependent',
            trials: 1,
            values: {
              0: 8.333333333333334,
              1: 0.12
            },
            result: {
              0: 8.333333333333334,
              1: 0.12
            },
            benchmarked: {
              0: 1,
              1: 0
            }
          },
          {
            id: '92120df2-53f1-41f3-b1a6-970223642ec1',
            title: 'Quadriceps Concentric Strength 60°/s',
            unit: '- None -',
            calculation: 'none',
            best_performance: '1',
            formula:
              'ratio({"id": "a3eb5d64-7a9d-402f-aab4-b6cecfe8f776", "side": "same"},\n{"id": "a3eb5d64-7a9d-402f-aab4-b6cecfe8f776", "side": "other"})',
            input_type: 'derived',
            input_type_parameters: '{"minimum":0,"maximum":1}',
            order: 6,
            benchmarks: [
              'recLV7YATxoyfsRds',
              'rec9wpaVO5477zjgw',
              'recsbZ1HMH2kgKtOe',
              'recgoU39OVggQ2LzE',
              'rec6QW61lGnMpiHNK'
            ],
            date_created: '2021-02-23T10:07:29.000Z',
            date_updated: '2021-04-06T17:16:02.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'derived',
            minimum: 0,
            maximum: 1,
            airId: 'reci0tF4hqonodOzK',
            laterality: 'bilateral_dependent',
            trials: 1,
            values: {
              0: 0,
              1: null
            },
            result: {
              0: 0,
              1: null
            },
            benchmarked: {
              0: 0,
              1: 0
            }
          },
          {
            id: 'f1c4fdc8-23aa-4e72-801d-2ccd58264e6d',
            title: 'Quadriceps Concentric Strength 240°/s',
            unit: '- None -',
            calculation: 'none',
            best_performance: '1',
            formula:
              'ratio({"id": "b08839e0-323b-4f00-8774-219a141c0189", "side": "same"},\n{"id": "b08839e0-323b-4f00-8774-219a141c0189", "side": "other"})',
            input_type: 'derived',
            input_type_parameters: '{"minimum":0,"maximum":1}',
            order: 7,
            benchmarks: ['recwt5cIs4ClsXbRG', 'recvUkUG7h71VG2VY'],
            date_created: '2021-02-23T10:09:04.000Z',
            date_updated: '2021-04-06T17:17:09.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'derived',
            minimum: 0,
            maximum: 1,
            airId: 'recuZbR9Tox9PRxvh',
            laterality: 'bilateral_dependent',
            trials: 1,
            values: {
              0: 0,
              1: null
            },
            result: {
              0: 0,
              1: null
            },
            benchmarked: {
              0: 0,
              1: 0
            }
          }
        ],
        trials: 1,
        dependencies: ['recZ6qh85FK06DBnE'],
        modules: ['prevention', 'rehab'],
        laterality: 'bilateral_dependent',
        equipment: 'Biodex Isokinetic Device',
        injury_sport_tests: [
          'recW0wvXr7bOr5scW',
          'recOUuUXq98BKGNHZ',
          'reccWCDA4A4Z770Lf',
          'rec4U15TstMk6NYjg',
          'recH0Y9LP7z7oVxaC',
          'recgFxPgGQhc379GU',
          'rec9pf1Bfr0F9oDux',
          'receUOiHcZzSCf6BA',
          'recMoxCf492UiksKt',
          'recew1osCthDbm7Jo',
          'recBWw7cm8DmfxGXn',
          'recx5blcZvg50rwkS',
          'rec33e2fgHMXg24YW',
          'recHXFBePt6fsexgy',
          'recamWeUFDWPsKovy',
          'recbEE3QQBegMAW7S'
        ],
        duration: 30,
        date_updated: '2021-04-07T08:34:04.000Z',
        date_created: '2021-02-10T12:38:10.000Z',
        'title (from dependencies)': ['Body Weight'],
        'title (from testitems)': [
          'Hamstrings Concentric Strength 60°/s Score',
          'Hamstrings Concentric Strength 240°/s Score\t',
          'Quadriceps Concentric Strength 60°/s Score',
          'Quadriceps Concentric Strength 240°/s Score',
          'Hamstrings Concentric Strength 60°/s',
          'Hamstrings Concentric Strength 240°/s',
          'Quadriceps Concentric Strength 60°/s',
          'Quadriceps Concentric Strength 240°/s'
        ],
        dependedTestIds: ['a3288161-0ee8-4298-8a40-a6c8afc3fb44'],
        airId: 'recWmc3n2weASlesF'
      },
      {
        id: '74fe0289-3241-423e-a4c6-19fefe0b48b9',
        title: 'Isometric Hamstrings',
        category: 'Strength',
        testItems: [
          {
            id: '5a6be6ec-8437-44c7-94a8-6bd612807573',
            title: 'Hamstrings Strength Score',
            unit: 'N',
            calculation: 'none',
            best_performance: '1',
            input_type: 'Free number',
            input_type_parameters: '{"minimum":0,"maximum":900}',
            order: 0,
            hidden: true,
            date_created: '2021-02-25T17:45:05.000Z',
            date_updated: '2021-03-10T13:15:09.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'free_number',
            minimum: 0,
            maximum: 900,
            airId: 'recDIpZBtQfq73FXJ',
            laterality: 'bilateral_dependent',
            trials: 1,
            values: {
              0: [0]
            },
            result: {
              0: [0]
            },
            benchmarked: {}
          },
          {
            id: 'a80022b2-0b9f-41d8-abfd-c4ae30a78614',
            title: 'Hamstrings Strength',
            unit: '- None -',
            calculation: 'none',
            best_performance: '1',
            formula:
              'ratio({"id": "5a6be6ec-8437-44c7-94a8-6bd612807573", "side": "same"},{"id": "5a6be6ec-8437-44c7-94a8-6bd612807573", "side": "other"})\n',
            input_type: 'derived',
            input_type_parameters: '{"minimum":0,"maximum":1}',
            order: 1,
            benchmarks: [
              'recZmKdBaL7wuoq4n',
              'recknAumuwJegTuBD',
              'rec4I9EPBltpZpdbR'
            ],
            date_created: '2021-02-25T17:47:49.000Z',
            date_updated: '2021-04-06T13:17:28.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'derived',
            minimum: 0,
            maximum: 1,
            airId: 'recpsl3MOXtThIXTR',
            laterality: 'bilateral_dependent',
            trials: 1,
            values: {
              0: 0,
              1: null
            },
            result: {
              0: 0,
              1: null
            },
            benchmarked: {
              0: 0,
              1: 0
            }
          }
        ],
        trials: 1,
        video_link:
          'https://sportkompas-platform-hlapp-videos.s3.eu-west-1.amazonaws.com/Isometric%20Knee%20Hamstring-converted',
        modules: ['prevention', 'rehab'],
        laterality: 'bilateral_dependent',
        equipment: 'Treatment table;Handheld dynamometer',
        injury_sport_tests: [
          'recW0wvXr7bOr5scW',
          'recOUuUXq98BKGNHZ',
          'reccWCDA4A4Z770Lf',
          'rec4U15TstMk6NYjg',
          'recH0Y9LP7z7oVxaC',
          'recgFxPgGQhc379GU',
          'rec9pf1Bfr0F9oDux',
          'receUOiHcZzSCf6BA',
          'recBWw7cm8DmfxGXn',
          'recx5blcZvg50rwkS',
          'rec33e2fgHMXg24YW',
          'recHXFBePt6fsexgy'
        ],
        duration: 2,
        date_updated: '2021-04-06T17:33:08.000Z',
        date_created: '2021-02-10T13:31:33.000Z',
        'title (from testitems)': [
          'Hamstrings Strength Score',
          'Hamstrings Strength'
        ],
        airId: 'recbUl8Um0reerfzw'
      },
      {
        id: 'b1a6a170-62ec-4c6b-9a6c-5f53cf2cd16d',
        title: 'Isometric Hip Abduction',
        category: 'Strength',
        testItems: [
          {
            id: '542e6f90-d4e4-403b-808a-cda8d50d8b50',
            title: 'Hip Abduction Strength Score',
            unit: 'N',
            calculation: 'none',
            best_performance: '1',
            input_type: 'Free number',
            input_type_parameters: '{"minimum":0,"maximum":900}',
            order: 0,
            date_created: '2021-02-24T15:54:23.000Z',
            date_updated: '2021-03-10T08:47:37.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'free_number',
            minimum: 0,
            maximum: 900,
            airId: 'recNZh5WczAh1O4vB',
            laterality: 'bilateral_dependent',
            trials: 1,
            values: {
              0: [0]
            },
            result: {
              0: [0]
            },
            benchmarked: {}
          },
          {
            id: '957238af-277a-417e-858a-ab1389d4f14a',
            title: 'Hip Abduction Strength',
            unit: '- None -',
            calculation: 'none',
            best_performance: '1',
            formula:
              'ratio({"id": "542e6f90-d4e4-403b-808a-cda8d50d8b50", "side": "same"},{"id": "542e6f90-d4e4-403b-808a-cda8d50d8b50", "side": "other"})',
            input_type: 'derived',
            input_type_parameters: '{"minimum":0,"maximum":1}',
            order: 1,
            benchmarks: ['recblNWRHZhMuY67R'],
            date_created: '2021-02-24T15:55:18.000Z',
            date_updated: '2021-03-16T10:26:24.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'derived',
            minimum: 0,
            maximum: 1,
            airId: 'recdP3bfD0RrEIpxc',
            laterality: 'bilateral_dependent',
            trials: 1,
            values: {
              0: 0,
              1: null
            },
            result: {
              0: 0,
              1: null
            },
            benchmarked: {
              0: 0,
              1: 0
            }
          }
        ],
        trials: 1,
        video_link:
          'https://sportkompas-platform-hlapp-videos.s3.eu-west-1.amazonaws.com/Isometric%20Hip%20Abduction-converted',
        modules: ['prevention', 'rehab'],
        laterality: 'bilateral_dependent',
        equipment: 'Handheld dynamometer',
        injury_sport_tests: [
          'recW0wvXr7bOr5scW',
          'recjwBe3BD9qQEfiH',
          'recJD2JoASiV0MSNv',
          'recCL88Njp0lKrlcr',
          'recuL3tNCqiLQUS8U',
          'recslo0TTotPU5F9O',
          'recbGW2bOAabVCah9',
          'reccWCDA4A4Z770Lf',
          'rec4U15TstMk6NYjg',
          'recH0Y9LP7z7oVxaC',
          'recgFxPgGQhc379GU',
          'rec9pf1Bfr0F9oDux',
          'receUOiHcZzSCf6BA',
          'recXfudTKhYbS1Czz',
          'reciQpEg1WA2oMy4A',
          'rec3oCVItwiEaKkpJ',
          'recamWeUFDWPsKovy',
          'recfYstkyOTbBRSvZ'
        ],
        duration: 2,
        date_updated: '2021-04-07T08:38:11.000Z',
        date_created: '2021-02-10T13:14:59.000Z',
        'title (from testitems)': [
          'Hip Abduction Strength Score',
          'Hip Abduction Strength'
        ],
        airId: 'recmUZhWmyt4t31Ju'
      },
      {
        id: 'f030b7c9-7e94-4584-b350-bda7e484f0ac',
        title: 'Isometric Hip Extension',
        category: 'Strength',
        testItems: [
          {
            id: 'cdb8d330-2aac-4863-8038-8919750c7a43',
            title: 'Hip Extension Strength Score',
            unit: 'N',
            calculation: 'none',
            best_performance: '1',
            input_type: 'Free number',
            input_type_parameters: '{"minimum":0,"maximum":900}',
            order: 0,
            hidden: true,
            date_created: '2021-02-25T17:39:26.000Z',
            date_updated: '2021-03-10T08:50:33.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'free_number',
            minimum: 0,
            maximum: 900,
            airId: 'receKvb3P5gWv2E7Q',
            laterality: 'bilateral_dependent',
            trials: 1,
            values: {
              0: [0]
            },
            result: {
              0: [0]
            },
            benchmarked: {}
          },
          {
            id: 'af5397a0-78af-4322-83f4-cb701d7577c7',
            title: 'Hip Extension Strength',
            unit: '- None -',
            calculation: 'none',
            best_performance: '1',
            formula:
              'ratio({"id": "cdb8d330-2aac-4863-8038-8919750c7a43", "side": "same"},{"id": "cdb8d330-2aac-4863-8038-8919750c7a43", "side": "other"})',
            input_type: 'derived',
            input_type_parameters: '{"minimum":0,"maximum":1}',
            order: 1,
            benchmarks: ['recMLoJBH4RQncHVj', 'rec1dwO6KrOMzhUWm'],
            date_created: '2021-02-25T17:40:30.000Z',
            date_updated: '2021-03-17T12:33:22.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'derived',
            minimum: 0,
            maximum: 1,
            airId: 'recr27ficnKfoBbpG',
            laterality: 'bilateral_dependent',
            trials: 1,
            values: {
              0: 0,
              1: null
            },
            result: {
              0: 0,
              1: null
            },
            benchmarked: {
              0: 0,
              1: 0
            }
          },
          {
            id: '893a83c1-04e1-44b7-9ea5-34986cb89a56',
            title: 'Isometric Hip Extension to BW',
            unit: '- None -',
            calculation: 'none',
            best_performance: '1',
            formula:
              'ratio({"id": "cdb8d330-2aac-4863-8038-8919750c7a43", "side": "same"},\n{"id": "a2a243bc-8d3e-4eb8-9837-9a3bb814488d", "side": "center"})',
            input_type: 'derived',
            input_type_parameters: '{"minimum":0,"maximum":10}',
            order: 2,
            date_created: '2021-02-25T17:41:08.000Z',
            date_updated: '2021-02-25T17:41:08.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'derived',
            minimum: 0,
            maximum: 10,
            airId: 'recwEaZxnamT2txQS',
            laterality: 'bilateral_dependent',
            trials: 1
          }
        ],
        trials: 1,
        video_link:
          'https://sportkompas-platform-hlapp-videos.s3.eu-west-1.amazonaws.com/Isometric%20Hip%20Extension-converted',
        dependencies: ['recZ6qh85FK06DBnE'],
        modules: ['prevention', 'rehab'],
        laterality: 'bilateral_dependent',
        equipment: 'Treatment table;Handheld dynamometer',
        injury_sport_tests: [
          'recW0wvXr7bOr5scW',
          'recjwBe3BD9qQEfiH',
          'recJD2JoASiV0MSNv',
          'recCL88Njp0lKrlcr',
          'recuL3tNCqiLQUS8U',
          'recslo0TTotPU5F9O',
          'recbGW2bOAabVCah9',
          'rec2VLFGQCiKbJBhQ',
          'recbLcYIZR5Uc4AZr',
          'recgFxPgGQhc379GU',
          'rec9lsbsyEsFKjntf',
          'recBWw7cm8DmfxGXn',
          'rechEuQClLKqLuEgq',
          'rec3oCVItwiEaKkpJ',
          'recamWeUFDWPsKovy',
          'recWb3veBCRAokbzk',
          'recpgDUnVzkLPwPee',
          'recfYstkyOTbBRSvZ'
        ],
        duration: 2,
        date_updated: '2021-04-07T08:38:11.000Z',
        date_created: '2021-02-10T13:29:58.000Z',
        'title (from dependencies)': ['Body Weight'],
        'title (from testitems)': [
          'Hip Extension Strength Score',
          'Hip Extension Strength',
          'Isometric Hip Extension to BW'
        ],
        dependedTestIds: ['a3288161-0ee8-4298-8a40-a6c8afc3fb44'],
        airId: 'rec4agPWVjbmaRMyV'
      },
      {
        id: 'f28f619e-1416-45eb-9a60-ea7fd1f6d46c',
        title: 'Isometric Hip Flexion',
        category: 'Strength',
        testItems: [
          {
            id: '976b404c-b751-4f87-8ad8-1b5a4109a905',
            title: 'Hip Flexion Strength Score',
            unit: 'N',
            calculation: 'none',
            best_performance: '1',
            input_type: 'Free number',
            input_type_parameters: '{"minimum":0,"maximum":1500}',
            order: 0,
            date_created: '2021-02-25T17:41:57.000Z',
            date_updated: '2021-03-10T08:45:17.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'free_number',
            minimum: 0,
            maximum: 1500,
            airId: 'recgRHMthhhiWvOE7',
            laterality: 'bilateral_dependent',
            trials: 1,
            values: {
              0: [0]
            },
            result: {
              0: [0]
            },
            benchmarked: {}
          },
          {
            id: 'fb1ec6af-da5e-4f76-9e16-211883e2677b',
            title: 'Hip Flexion Strength',
            unit: '- None -',
            calculation: 'none',
            best_performance: '1',
            formula:
              'ratio({"id": "976b404c-b751-4f87-8ad8-1b5a4109a905", "side": "same"},\n{"id": "976b404c-b751-4f87-8ad8-1b5a4109a905", "side": "other"})',
            input_type: 'derived',
            input_type_parameters: '{"minimum":0,"maximum":15}',
            order: 1,
            benchmarks: ['rec4kd8GwNDk8RQIr'],
            date_created: '2021-02-25T17:42:08.000Z',
            date_updated: '2021-03-16T10:24:58.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'derived',
            minimum: 0,
            maximum: 15,
            airId: 'recBqa4UpOjRBOOoV',
            laterality: 'bilateral_dependent',
            trials: 1,
            values: {
              0: 0,
              1: null
            },
            result: {
              0: 0,
              1: null
            },
            benchmarked: {
              0: 0,
              1: 0
            }
          }
        ],
        trials: 1,
        video_link:
          'https://sportkompas-platform-hlapp-videos.s3.eu-west-1.amazonaws.com/Isometric%20Hip%20Flexion-converted',
        modules: ['prevention', 'rehab'],
        laterality: 'bilateral_dependent',
        equipment: 'Treatment table;Handheld dynamometer',
        injury_sport_tests: [
          'recW0wvXr7bOr5scW',
          'recjwBe3BD9qQEfiH',
          'recJD2JoASiV0MSNv',
          'recCL88Njp0lKrlcr',
          'recuL3tNCqiLQUS8U',
          'recslo0TTotPU5F9O',
          'recbGW2bOAabVCah9',
          'rec2VLFGQCiKbJBhQ',
          'recbLcYIZR5Uc4AZr',
          'rec9lsbsyEsFKjntf',
          'recuTNqXDAkuZ7D4o',
          'recfYstkyOTbBRSvZ'
        ],
        duration: 2,
        date_updated: '2021-04-07T08:38:11.000Z',
        date_created: '2021-02-10T13:30:42.000Z',
        'title (from testitems)': [
          'Hip Flexion Strength Score',
          'Hip Flexion Strength'
        ],
        airId: 'recCPFVVLZI65k8JZ'
      },
      {
        id: '42e03f23-e143-4b61-bd52-287584e58440',
        title: 'Isometric Quadriceps',
        category: 'Strength',
        testItems: [
          {
            id: '85c84ae3-9aaf-4992-84c6-e079459fe460',
            title: 'Quadriceps Strength Score',
            unit: 'N',
            calculation: 'none',
            best_performance: '1',
            input_type: 'Free number',
            input_type_parameters: '{"minimum":0,"maximum":1500}',
            order: 0,
            date_created: '2021-02-25T17:49:16.000Z',
            date_updated: '2021-04-09T09:56:21.000Z',
            hidden: true,
            duration: 10,
            best_value: 1,
            inputtype: 'free_number',
            minimum: 0,
            maximum: 1500,
            airId: 'recYCGvPE5GTs0W2b',
            laterality: 'bilateral_dependent',
            trials: 1,
            values: {
              0: [1500]
            },
            result: {
              0: [1500]
            },
            benchmarked: {}
          },
          {
            id: 'cf1e08d2-5b55-41c4-a43d-ab4378035095',
            title: 'Quadriceps Strength',
            unit: '- None -',
            calculation: 'none',
            best_performance: '1',
            formula:
              'ratio({"id": "85c84ae3-9aaf-4992-84c6-e079459fe460", "side": "same"},{"id": "85c84ae3-9aaf-4992-84c6-e079459fe460", "side": "other"})',
            input_type: 'derived',
            input_type_parameters: '{"minimum":0,"maximum":1}',
            order: 1,
            benchmarks: [
              'recsR7qph560Y7GEF',
              'rec3vvQhoMACmDtv7',
              'recvhwVuxcIH6uF5M',
              'rec0EvEEMDnmUjgjw'
            ],
            date_created: '2021-02-25T17:50:30.000Z',
            date_updated: '2021-04-06T13:20:45.000Z',
            duration: 10,
            best_value: 1,
            inputtype: 'derived',
            minimum: 0,
            maximum: 1,
            airId: 'recBdTfMp7S4Ct4Pe',
            laterality: 'bilateral_dependent',
            trials: 1,
            values: {
              0: 1.5,
              1: 0.6666666666666666
            },
            result: {
              0: 1.5,
              1: 0.6666666666666666
            },
            benchmarked: {
              0: 1,
              1: 0
            }
          }
        ],
        trials: 1,
        video_link:
          'https://sportkompas-platform-hlapp-videos.s3.eu-west-1.amazonaws.com/Isometric%20Knee%20quadriceps-converted',
        modules: ['prevention', 'rehab'],
        laterality: 'bilateral_dependent',
        equipment: 'Treatment table;Sling;Handheld dynamometer',
        injury_sport_tests: [
          'recW0wvXr7bOr5scW',
          'recOUuUXq98BKGNHZ',
          'reccWCDA4A4Z770Lf',
          'rec4U15TstMk6NYjg',
          'recH0Y9LP7z7oVxaC',
          'recgFxPgGQhc379GU',
          'receUOiHcZzSCf6BA',
          'recMoxCf492UiksKt',
          'recew1osCthDbm7Jo',
          'reciQpEg1WA2oMy4A',
          'recx5blcZvg50rwkS',
          'rec33e2fgHMXg24YW',
          'recHXFBePt6fsexgy',
          'recRGCsfL5nYbjeOZ',
          'rec3oCVItwiEaKkpJ',
          'recamWeUFDWPsKovy',
          'recbEE3QQBegMAW7S'
        ],
        duration: 2,
        date_updated: '2021-04-07T08:34:04.000Z',
        date_created: '2021-02-10T13:32:24.000Z',
        'title (from testitems)': [
          'Quadriceps Strength Score',
          'Quadriceps Strength'
        ],
        airId: 'recc9LCZ3jLa1hvIp'
      }
    ]
  }
];

export const exerciseLevelsOne = [
  {
    __typename: 'Exercise',
    id: '8a6c3ac9-f537-49dc-b360-32310579c78e',
    title: 'Ankle Ball Squeeze',
    note: 'An a note on self.',
    cues: ['This is my cue', 'This is Cue 2', 'Cue 3', 'Cue 4', 'Vijf'],
    protocol:
      '<h2>Another Protocol...</h2>\n<p>Nostra ac volutpat ex porttitor finibus sollicitudin et semper penatibus, at morbi eros <strong>ultricies</strong> dui turpis suscipit metus sapien, taciti adipiscing dapibus lorem sociosqu ligula mollis sem.</p>\n<p>Sollicitudin purus finibus aliquam non feugiat commodo in, aliquet cras <em>molestie</em> ipsum ornare sit, lorem ligula quisque nulla lacinia id.</p>\n<ul>\n<li>Test 1</li>\n<li>Test 2</li>\n</ul>\n<p><a href="https://github.com/tinymce/tinymce-react">https://github.com/tinymce/tinymce-react</a></p>\n<p>&nbsp;</p>',
    level: 1,
    thumb: {
      __typename: 'File',
      id: '50753690-082b-4750-b066-31ff8e43de36',
      signedDownloadUrl:
        'https://sportkompas-platform-dc2-uploads.s3.eu-west-1.amazonaws.com/50753690-082b-4750-b066-31ff8e43de36.jpg?AWSAccessKeyId=ASIA4ARYMHD762FPOKMV&Expires=1617986468&Signature=9xYylGflxs33x%2BdTIxQZ7CuOjJM%3D&x-amz-security-token=IQoJb3JpZ2luX2VjECEaCWV1LXdlc3QtMSJHMEUCIDw39%2Bu3P9k2QmjLq5ZoaCvhs17yyBaseueUpSO2%2BSpaAiEAmIKQsziZet6gxDRYNaPmYA6duXAJSyHh64oLnukrKEsq4AEIehACGgw4MjU4MjU3MDQxOTEiDLOLn2UbR0i9s86EsCq9AV%2FyP0iHdaQFx7zg421d4tQWgJ4XtXHTp3jaqlXcpUejgzG%2BaKG0AU4RoY6Fr73tXAUvo4rSg5Z8H2So3T8B7Cjpsghnfeaje47w%2Bl4f5d2wlZ2wkvYnhjJhiE24EkmU8ztwF%2Fh1HjuUf3jAAHVlfVTH6AFc6jRHTHZ%2F6Y2s7kc2foXvDZlYBdKruwnBa1aUEgLG7S1sfy9yyhBXypfF%2FI0G42Te50byCoRRpaLKQ6DonilZxCBFwgdAYdxyGzCugsKDBjrgAQ6956JyKdu7q2q4B1MB06dBnaiFXGbS2xM3uZ1vwA80LCarxLok%2Bc%2BpXPfQqXB471eS14RC7qHsPrry4HdiSYUlXUKHcR4kUZw82dyOGvScskBOSAsKDFrW1iF09aZZ5Lv%2B%2B7Ol%2BvOSo5LqAq5wHftsOTQ5IytPrjKnrIDKZfGjIb5Kb83a3BiIvNGeZyFcpBwC4QHi3mVFeJ8%2F0lnPLQWqkrETgQtejzWJDGdDTrXyUoizugsmh5iWN4%2Fedr9PdrFRbHCxmyjNXfDNefa2jgi7vsrds20zQjBF%2Ba2QqQoC',
      linkId: 'c456379e-3b42-441a-897f-0c52a88c37bf'
    },
    video: {
      __typename: 'File',
      id: '4fe730cb-611e-408e-aa52-44d75b304dc2',
      signedDownloadUrl:
        'https://sportkompas-platform-dc2-uploads.s3.eu-west-1.amazonaws.com/4fe730cb-611e-408e-aa52-44d75b304dc2.mp4?AWSAccessKeyId=ASIA4ARYMHD762FPOKMV&Expires=1617986468&Signature=ezdx8ExbkAHkm4478JrTOfdNshs%3D&x-amz-security-token=IQoJb3JpZ2luX2VjECEaCWV1LXdlc3QtMSJHMEUCIDw39%2Bu3P9k2QmjLq5ZoaCvhs17yyBaseueUpSO2%2BSpaAiEAmIKQsziZet6gxDRYNaPmYA6duXAJSyHh64oLnukrKEsq4AEIehACGgw4MjU4MjU3MDQxOTEiDLOLn2UbR0i9s86EsCq9AV%2FyP0iHdaQFx7zg421d4tQWgJ4XtXHTp3jaqlXcpUejgzG%2BaKG0AU4RoY6Fr73tXAUvo4rSg5Z8H2So3T8B7Cjpsghnfeaje47w%2Bl4f5d2wlZ2wkvYnhjJhiE24EkmU8ztwF%2Fh1HjuUf3jAAHVlfVTH6AFc6jRHTHZ%2F6Y2s7kc2foXvDZlYBdKruwnBa1aUEgLG7S1sfy9yyhBXypfF%2FI0G42Te50byCoRRpaLKQ6DonilZxCBFwgdAYdxyGzCugsKDBjrgAQ6956JyKdu7q2q4B1MB06dBnaiFXGbS2xM3uZ1vwA80LCarxLok%2Bc%2BpXPfQqXB471eS14RC7qHsPrry4HdiSYUlXUKHcR4kUZw82dyOGvScskBOSAsKDFrW1iF09aZZ5Lv%2B%2B7Ol%2BvOSo5LqAq5wHftsOTQ5IytPrjKnrIDKZfGjIb5Kb83a3BiIvNGeZyFcpBwC4QHi3mVFeJ8%2F0lnPLQWqkrETgQtejzWJDGdDTrXyUoizugsmh5iWN4%2Fedr9PdrFRbHCxmyjNXfDNefa2jgi7vsrds20zQjBF%2Ba2QqQoC',
      linkId: '31a5f50a-52ed-4cfd-8d53-578bae8ea3f6'
    },
    tags: [
      {
        __typename: 'Tag',
        id: 'a274f76d-4cb0-4ede-9b37-50545a25d7e0',
        label: 'Sailing'
      },
      {
        __typename: 'Tag',
        id: 'a778740a-5dc1-42dc-9b88-5c77c6d64468',
        label: 'Strength'
      },
      {
        __typename: 'Tag',
        id: '34b4f931-c1aa-4a14-8994-806254549d03',
        label: 'Tag 3'
      }
    ]
  },
  {
    __typename: 'Exercise',
    id: 'c6d157e5-5459-4868-b362-ea6707978da3',
    title: 'Sideways Hop on Step',
    note: 'This is the exercise note',
    cues: null,
    protocol: null,
    level: 1,
    thumb: null,
    video: null,
    tags: [
      {
        __typename: 'Tag',
        id: '292a75db-5c27-41e6-ac7a-211d9fd2dcb2',
        label: 'Arms'
      },
      {
        __typename: 'Tag',
        id: '208b7fd3-ff0e-4371-a714-ec2fd707b9f3',
        label: 'Legs'
      },
      {
        __typename: 'Tag',
        id: '34b4f931-c1aa-4a14-8994-806254549d03',
        label: 'Tag 3'
      }
    ]
  },
  {
    __typename: 'Exercise',
    id: '24981a61-4331-49b1-99bf-da4ce689c0d1',
    title: 'Basic Harmstring Stretch',
    note: 'this is a note',
    cues: ['cue 1', 'cue 2', 'cue 3'],
    protocol: 'this is a protocol',
    level: 1,
    thumb: null,
    video: {
      __typename: 'File',
      id: '04e4d4dc-acf9-492b-a94b-2d7e7c41c5a6',
      signedDownloadUrl:
        'https://sportkompas-platform-dc2-uploads.s3.eu-west-1.amazonaws.com/04e4d4dc-acf9-492b-a94b-2d7e7c41c5a6.mp4?AWSAccessKeyId=ASIA4ARYMHD762FPOKMV&Expires=1617986468&Signature=1kNS%2BcJjwtzo%2FnlJg9WrvRM9RRg%3D&x-amz-security-token=IQoJb3JpZ2luX2VjECEaCWV1LXdlc3QtMSJHMEUCIDw39%2Bu3P9k2QmjLq5ZoaCvhs17yyBaseueUpSO2%2BSpaAiEAmIKQsziZet6gxDRYNaPmYA6duXAJSyHh64oLnukrKEsq4AEIehACGgw4MjU4MjU3MDQxOTEiDLOLn2UbR0i9s86EsCq9AV%2FyP0iHdaQFx7zg421d4tQWgJ4XtXHTp3jaqlXcpUejgzG%2BaKG0AU4RoY6Fr73tXAUvo4rSg5Z8H2So3T8B7Cjpsghnfeaje47w%2Bl4f5d2wlZ2wkvYnhjJhiE24EkmU8ztwF%2Fh1HjuUf3jAAHVlfVTH6AFc6jRHTHZ%2F6Y2s7kc2foXvDZlYBdKruwnBa1aUEgLG7S1sfy9yyhBXypfF%2FI0G42Te50byCoRRpaLKQ6DonilZxCBFwgdAYdxyGzCugsKDBjrgAQ6956JyKdu7q2q4B1MB06dBnaiFXGbS2xM3uZ1vwA80LCarxLok%2Bc%2BpXPfQqXB471eS14RC7qHsPrry4HdiSYUlXUKHcR4kUZw82dyOGvScskBOSAsKDFrW1iF09aZZ5Lv%2B%2B7Ol%2BvOSo5LqAq5wHftsOTQ5IytPrjKnrIDKZfGjIb5Kb83a3BiIvNGeZyFcpBwC4QHi3mVFeJ8%2F0lnPLQWqkrETgQtejzWJDGdDTrXyUoizugsmh5iWN4%2Fedr9PdrFRbHCxmyjNXfDNefa2jgi7vsrds20zQjBF%2Ba2QqQoC',
      linkId: '24981a61-4331-49b1-99bf-da4ce689c0d1'
    },
    tags: [
      {
        __typename: 'Tag',
        id: '9d8c44f8-0350-4a07-93ce-e7713592e125',
        label: 'Tag 1'
      },
      {
        __typename: 'Tag',
        id: 'b23937b6-16dc-4d4b-8cd5-048d3f88deab',
        label: 'Tag 2'
      },
      {
        __typename: 'Tag',
        id: '34b4f931-c1aa-4a14-8994-806254549d03',
        label: 'Tag 3'
      }
    ]
  }
];

export const exerciseLevelsTwo = [
  {
    __typename: 'Exercise',
    id: 'e628e4f9-1ad2-4da4-9b5f-38e19d9e2092',
    title: 'Wide Squat',
    note: 'my note in exercise',
    cues: null,
    protocol: null,
    level: 2,
    thumb: null,
    video: {
      __typename: 'File',
      id: '834022dd-11bb-4aee-96d4-757d9f2ebd68',
      signedDownloadUrl:
        'https://sportkompas-platform-dc2-uploads.s3.eu-west-1.amazonaws.com/834022dd-11bb-4aee-96d4-757d9f2ebd68.mp4?AWSAccessKeyId=ASIA4ARYMHD762FPOKMV&Expires=1617986468&Signature=SnWLK5tybSLQ462mvV5YjMqQut0%3D&x-amz-security-token=IQoJb3JpZ2luX2VjECEaCWV1LXdlc3QtMSJHMEUCIDw39%2Bu3P9k2QmjLq5ZoaCvhs17yyBaseueUpSO2%2BSpaAiEAmIKQsziZet6gxDRYNaPmYA6duXAJSyHh64oLnukrKEsq4AEIehACGgw4MjU4MjU3MDQxOTEiDLOLn2UbR0i9s86EsCq9AV%2FyP0iHdaQFx7zg421d4tQWgJ4XtXHTp3jaqlXcpUejgzG%2BaKG0AU4RoY6Fr73tXAUvo4rSg5Z8H2So3T8B7Cjpsghnfeaje47w%2Bl4f5d2wlZ2wkvYnhjJhiE24EkmU8ztwF%2Fh1HjuUf3jAAHVlfVTH6AFc6jRHTHZ%2F6Y2s7kc2foXvDZlYBdKruwnBa1aUEgLG7S1sfy9yyhBXypfF%2FI0G42Te50byCoRRpaLKQ6DonilZxCBFwgdAYdxyGzCugsKDBjrgAQ6956JyKdu7q2q4B1MB06dBnaiFXGbS2xM3uZ1vwA80LCarxLok%2Bc%2BpXPfQqXB471eS14RC7qHsPrry4HdiSYUlXUKHcR4kUZw82dyOGvScskBOSAsKDFrW1iF09aZZ5Lv%2B%2B7Ol%2BvOSo5LqAq5wHftsOTQ5IytPrjKnrIDKZfGjIb5Kb83a3BiIvNGeZyFcpBwC4QHi3mVFeJ8%2F0lnPLQWqkrETgQtejzWJDGdDTrXyUoizugsmh5iWN4%2Fedr9PdrFRbHCxmyjNXfDNefa2jgi7vsrds20zQjBF%2Ba2QqQoC',
      linkId: '39811021-7e1a-46b9-8a74-d85fcc04969f'
    },
    tags: [
      {
        __typename: 'Tag',
        id: 'a274f76d-4cb0-4ede-9b37-50545a25d7e0',
        label: 'Sailing'
      }
    ]
  },
  {
    __typename: 'Exercise',
    id: 'b48b7b33-bae5-4e0e-95f9-4447ecc6ce67',
    title: 'Unilateral Towel Adductor Slide ',
    note: 'this is not a note',
    cues: ['cue 1', 'cue 2', 'cue 3'],
    protocol: 'this is a protocol',
    level: 2,
    thumb: null,
    video: null,
    tags: [
      {
        __typename: 'Tag',
        id: '9d8c44f8-0350-4a07-93ce-e7713592e125',
        label: 'Tag 1'
      },
      {
        __typename: 'Tag',
        id: 'b23937b6-16dc-4d4b-8cd5-048d3f88deab',
        label: 'Tag 2'
      },
      {
        __typename: 'Tag',
        id: '33444a2e-ab9d-4b07-a4c5-4d77af809925',
        label: 'Tag 5'
      }
    ]
  },
  {
    __typename: 'Exercise',
    id: '5af5a33a-ef32-47e7-92d7-d7272866980b',
    title: 'Adductor Knee-kick with TB on Step',
    note: 'Some note',
    cues: ['My fancy cue'],
    protocol: 'Some protocol',
    level: 2,
    thumb: {
      __typename: 'File',
      id: '48a2b54c-6c89-4077-a1ba-43bf42dff9b8',
      signedDownloadUrl:
        'https://sportkompas-platform-dc2-uploads.s3.eu-west-1.amazonaws.com/48a2b54c-6c89-4077-a1ba-43bf42dff9b8.jpg?AWSAccessKeyId=ASIA4ARYMHD762FPOKMV&Expires=1617986468&Signature=I75pO4lCEqTGBlpv5zgqi7bdSvg%3D&x-amz-security-token=IQoJb3JpZ2luX2VjECEaCWV1LXdlc3QtMSJHMEUCIDw39%2Bu3P9k2QmjLq5ZoaCvhs17yyBaseueUpSO2%2BSpaAiEAmIKQsziZet6gxDRYNaPmYA6duXAJSyHh64oLnukrKEsq4AEIehACGgw4MjU4MjU3MDQxOTEiDLOLn2UbR0i9s86EsCq9AV%2FyP0iHdaQFx7zg421d4tQWgJ4XtXHTp3jaqlXcpUejgzG%2BaKG0AU4RoY6Fr73tXAUvo4rSg5Z8H2So3T8B7Cjpsghnfeaje47w%2Bl4f5d2wlZ2wkvYnhjJhiE24EkmU8ztwF%2Fh1HjuUf3jAAHVlfVTH6AFc6jRHTHZ%2F6Y2s7kc2foXvDZlYBdKruwnBa1aUEgLG7S1sfy9yyhBXypfF%2FI0G42Te50byCoRRpaLKQ6DonilZxCBFwgdAYdxyGzCugsKDBjrgAQ6956JyKdu7q2q4B1MB06dBnaiFXGbS2xM3uZ1vwA80LCarxLok%2Bc%2BpXPfQqXB471eS14RC7qHsPrry4HdiSYUlXUKHcR4kUZw82dyOGvScskBOSAsKDFrW1iF09aZZ5Lv%2B%2B7Ol%2BvOSo5LqAq5wHftsOTQ5IytPrjKnrIDKZfGjIb5Kb83a3BiIvNGeZyFcpBwC4QHi3mVFeJ8%2F0lnPLQWqkrETgQtejzWJDGdDTrXyUoizugsmh5iWN4%2Fedr9PdrFRbHCxmyjNXfDNefa2jgi7vsrds20zQjBF%2Ba2QqQoC',
      linkId: '21efd3ca-c470-4c91-a1aa-46ba3cc816dc'
    },
    video: null,
    tags: [
      {
        __typename: 'Tag',
        id: 'a778740a-5dc1-42dc-9b88-5c77c6d64468',
        label: 'Strength'
      },
      {
        __typename: 'Tag',
        id: '582ad590-f9f1-4606-bd12-8697bb8561d1',
        label: 'Weight'
      }
    ]
  },
  {
    __typename: 'Exercise',
    id: '3b3ed3c7-b72c-4574-9052-66d7c515491f',
    title: 'Side Lying Adductor Lifts ',
    note: 'Test 123',
    cues: ['Some fancy cue'],
    protocol: 'Testtest',
    level: 2,
    thumb: null,
    video: null,
    tags: [
      {
        __typename: 'Tag',
        id: 'a778740a-5dc1-42dc-9b88-5c77c6d64468',
        label: 'Strength'
      },
      {
        __typename: 'Tag',
        id: '582ad590-f9f1-4606-bd12-8697bb8561d1',
        label: 'Weight'
      }
    ]
  }
];

export const exerciseLevelsThree = [
  {
    __typename: 'Exercise',
    id: '815b466f-8464-4a3d-8d1a-fdde0f0e797a',
    title: 'Adduction Bilateral Scissor-Jump with TB',
    note: null,
    cues: null,
    protocol: null,
    level: 3,
    thumb: null,
    video: {
      __typename: 'File',
      id: '40bb1fc1-8c03-4519-8b0f-662634683434',
      signedDownloadUrl:
        'https://sportkompas-platform-dc2-uploads.s3.eu-west-1.amazonaws.com/40bb1fc1-8c03-4519-8b0f-662634683434.mp4?AWSAccessKeyId=ASIA4ARYMHD762FPOKMV&Expires=1617986468&Signature=OMC7ZcR94wt%2B5vEW07rhfrix8Ok%3D&x-amz-security-token=IQoJb3JpZ2luX2VjECEaCWV1LXdlc3QtMSJHMEUCIDw39%2Bu3P9k2QmjLq5ZoaCvhs17yyBaseueUpSO2%2BSpaAiEAmIKQsziZet6gxDRYNaPmYA6duXAJSyHh64oLnukrKEsq4AEIehACGgw4MjU4MjU3MDQxOTEiDLOLn2UbR0i9s86EsCq9AV%2FyP0iHdaQFx7zg421d4tQWgJ4XtXHTp3jaqlXcpUejgzG%2BaKG0AU4RoY6Fr73tXAUvo4rSg5Z8H2So3T8B7Cjpsghnfeaje47w%2Bl4f5d2wlZ2wkvYnhjJhiE24EkmU8ztwF%2Fh1HjuUf3jAAHVlfVTH6AFc6jRHTHZ%2F6Y2s7kc2foXvDZlYBdKruwnBa1aUEgLG7S1sfy9yyhBXypfF%2FI0G42Te50byCoRRpaLKQ6DonilZxCBFwgdAYdxyGzCugsKDBjrgAQ6956JyKdu7q2q4B1MB06dBnaiFXGbS2xM3uZ1vwA80LCarxLok%2Bc%2BpXPfQqXB471eS14RC7qHsPrry4HdiSYUlXUKHcR4kUZw82dyOGvScskBOSAsKDFrW1iF09aZZ5Lv%2B%2B7Ol%2BvOSo5LqAq5wHftsOTQ5IytPrjKnrIDKZfGjIb5Kb83a3BiIvNGeZyFcpBwC4QHi3mVFeJ8%2F0lnPLQWqkrETgQtejzWJDGdDTrXyUoizugsmh5iWN4%2Fedr9PdrFRbHCxmyjNXfDNefa2jgi7vsrds20zQjBF%2Ba2QqQoC',
      linkId: 'b61dd461-cbc9-41a9-8bc7-48e7c8e91721'
    },
    tags: []
  },
  {
    __typename: 'Exercise',
    id: '7a4cf15f-ff07-4dab-bd05-4075d07d1330',
    title: 'Bilateral Adductor Cymbal Slides with Towel ',
    note: null,
    cues: null,
    protocol: null,
    level: 3,
    thumb: null,
    video: {
      __typename: 'File',
      id: 'ac05033e-24cd-49fb-bb50-328dade68b81',
      signedDownloadUrl:
        'https://sportkompas-platform-dc2-uploads.s3.eu-west-1.amazonaws.com/ac05033e-24cd-49fb-bb50-328dade68b81.mp4?AWSAccessKeyId=ASIA4ARYMHD762FPOKMV&Expires=1617986468&Signature=4f7xcEDdp%2FbWtkC7rmCU4wgghWI%3D&x-amz-security-token=IQoJb3JpZ2luX2VjECEaCWV1LXdlc3QtMSJHMEUCIDw39%2Bu3P9k2QmjLq5ZoaCvhs17yyBaseueUpSO2%2BSpaAiEAmIKQsziZet6gxDRYNaPmYA6duXAJSyHh64oLnukrKEsq4AEIehACGgw4MjU4MjU3MDQxOTEiDLOLn2UbR0i9s86EsCq9AV%2FyP0iHdaQFx7zg421d4tQWgJ4XtXHTp3jaqlXcpUejgzG%2BaKG0AU4RoY6Fr73tXAUvo4rSg5Z8H2So3T8B7Cjpsghnfeaje47w%2Bl4f5d2wlZ2wkvYnhjJhiE24EkmU8ztwF%2Fh1HjuUf3jAAHVlfVTH6AFc6jRHTHZ%2F6Y2s7kc2foXvDZlYBdKruwnBa1aUEgLG7S1sfy9yyhBXypfF%2FI0G42Te50byCoRRpaLKQ6DonilZxCBFwgdAYdxyGzCugsKDBjrgAQ6956JyKdu7q2q4B1MB06dBnaiFXGbS2xM3uZ1vwA80LCarxLok%2Bc%2BpXPfQqXB471eS14RC7qHsPrry4HdiSYUlXUKHcR4kUZw82dyOGvScskBOSAsKDFrW1iF09aZZ5Lv%2B%2B7Ol%2BvOSo5LqAq5wHftsOTQ5IytPrjKnrIDKZfGjIb5Kb83a3BiIvNGeZyFcpBwC4QHi3mVFeJ8%2F0lnPLQWqkrETgQtejzWJDGdDTrXyUoizugsmh5iWN4%2Fedr9PdrFRbHCxmyjNXfDNefa2jgi7vsrds20zQjBF%2Ba2QqQoC',
      linkId: '89342a6c-6a3f-4103-9630-ea089b6eae06'
    },
    tags: []
  }
];

export const exerciseLevels = {
  1: exerciseLevelsOne,
  2: exerciseLevelsTwo,
  3: exerciseLevelsThree
};
