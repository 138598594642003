import React, { useContext, useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import FieldInput from 'components/input/FieldInput';
import { useFormikContext } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { FieldDatePicker } from 'components/input/FieldDatePicker';
import MilestoneSelect from 'containers/partials/inputs/MilestoneSelect';
import Button from 'components/button';
import Icon from 'components/icon/Icon';
import { ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RETEST } from 'routes/RouteList';
import { StoreContext } from 'index';
import { usePersonContext } from 'contexts/PersonContext';
import { useMedicalContext } from 'contexts/MedicalContext';
import { useTestsContext } from 'contexts/TestsContext';

function ScreeningFormDetails({ entityId, personInjury, edit, customizable }) {
  const {
    routing: { push }
  } = useContext(StoreContext);
  const intl = useIntl();
  const {
    values: screening,
    errors,
    touched,
    handleChange,
    setFieldValue
  } = useFormikContext();

  const { testsState } = useTestsContext();
  const { person } = usePersonContext();
  const { medical } = useMedicalContext();

  const [totalEstimate, setTotalEstimate] = useState(0);

  useEffect(() => {
    if (testsState.selectedTests.length > 0) {
      const estimate = testsState.selectedTests.reduce((acc, current) => {
        return acc + current.duration;
      }, 0);
      setTotalEstimate(estimate);
    } else if (screening.tests.length > 0) {
      const estimate = screening.tests.reduce((acc, current) => {
        return acc + current.duration;
      }, 0);
      setTotalEstimate(estimate);
    }
  }, [testsState.selectedTests]);

  const onScheduleReTestClickHandler = () => {
    push(
      generatePath(
        ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RETEST,
        {
          entityId,
          sporterId: person.id,
          medicalId: medical.id,
          injuryId: personInjury.id,
          sessionId: screening.id
        }
      )
    );
  };

  return (
    <>
      <div className="o-layout o-layout--small">
        <div className="o-layout__item u-3-of-5">
          <MilestoneSelect
            name="milestoneTestSetId"
            extraClassNames={'c-input--select-open-from-left'}
            entityId={entityId}
            milestoneId={screening?.testSetTemplateIds?.[0] ?? '0'}
            injuryId={personInjury?.injury?.id}
            readOnly={!edit}
            disabled={!edit}
            onChange={selectedItem => {
              if (selectedItem === 0) {
                setFieldValue('milestoneTestSetId', '0');
                setFieldValue('milestoneTestSet', null);
                setFieldValue('testSetTemplateIds', ['0']);
              } else {
                if (screening.name === '') {
                  setFieldValue(
                    'name',
                    selectedItem?.title ? selectedItem.title : selectedItem
                  );
                }
                if (selectedItem) {
                  setFieldValue('milestoneTestSet', selectedItem);
                  setFieldValue('milestoneTestSetId', selectedItem.id);
                  setFieldValue('testSetTemplateIds', [selectedItem.id]);
                }
              }
            }}
            errors={errors}
            touched={touched}
          />
        </div>
        <div className={'o-layout__item u-2-of-5'}>
          <FieldDatePicker
            id="startdate"
            name="startdate"
            icon="calendar"
            errors={errors}
            touched={touched}
            readOnly={!edit}
            disabled={!edit}
            onChange={handleChange}
            required
          >
            <FormattedMessage {...messages.rehabScreeningDateLabel} />
          </FieldDatePicker>
        </div>
      </div>
      <div className="o-layout o-layout--small">
        <div
          className={
            screening?.testSetIds ? 'o-layout__item u-3-of-5' : 'o-layout__item'
          }
        >
          <FieldInput
            id="name"
            name="name"
            errors={errors}
            touched={touched}
            readOnly={!edit}
            required
          >
            <FormattedMessage {...messages.rehabScreeningNameLabel} />
          </FieldInput>
        </div>

        {(testsState.selectedTests.length > 0 ||
          screening.tests.length > 0) && (
          <div className="o-layout__item u-2-of-5 o-flex--align-end o-flex">
            <FieldInput
              id="est-time"
              value={intl.formatMessage(messages.globalUnitsMinutes, {
                minutes: `${totalEstimate}`
              })}
              icon="test"
              disabled
              useNative
            >
              <FormattedMessage {...messages.rehabScreeningTimeLabel} />
            </FieldInput>
          </div>
        )}
      </div>

      {false && customizable && screening?.id && edit && (
        <div className="o-layout">
          <div className="o-layout__item o-flex--align-end o-flex--justify-end o-flex">
            {screening?.id && (
              <Button
                rounded
                accented
                extraClassNames="u-margin-bottom-medium"
                onClick={onScheduleReTestClickHandler}
              >
                <Icon id="settings-white" />
                <FormattedMessage
                  {...messages.rehabScreeningScheduleTestButton}
                />
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ScreeningFormDetails;
