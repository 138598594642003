import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_UPLOADED_FILES_BY_LINK_ID } from 'services/aws/file-upload';
import File from 'models/File';
import { sort, SORT_DATA_TYPES } from 'utils/sort';

const useFiles = ({
  entityId,
  linkId,
  defaultSort = [{ key: 'date_created', dataType: SORT_DATA_TYPES.STRING }]
}) => {
  const [activeSort, setActiveSort] = useState(defaultSort);
  const [files, setFiles] = useState([]);
  const [fetchFiles, { data, loading, error, refetch }] = useLazyQuery(
    QUERY_GET_UPLOADED_FILES_BY_LINK_ID,
    {
      variables: { entityId, linkId },
      skip: !entityId || !linkId
    }
  );

  useEffect(() => {
    if (data?.getFiles) {
      const files = data.getFiles.map(f => new File({ ...f }));
      sortFiles(files, activeSort);
    }
  }, [data]);

  const getFiles = async sort => {
    if (sort) {
      setActiveSort(sort);
    }
    await fetchFiles({
      variables: { entityId, linkId },
      fetchPolicy: 'network-only'
    });
  };

  const sortFiles = async (files, keys) => {
    setActiveSort(keys);
    setFiles(sort(files, { keys }));
  };

  return { files, loading, error, refetch, getFiles, sortFiles };
};

export default useFiles;
