import React, { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  MUTATE_RESET_LOGIN,
  QUERY_GET_USER_BY_ID
} from 'services/aws/user-query';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import { useNotificationQueue } from 'components/notification';
import { useIntl } from 'react-intl';
import useUserPool from 'hooks/queries/useUserPool';
import useUser from 'hooks/queries/useUser';
import { StoreContext } from 'index';

const ResetLoginButton = ({
  entityId,
  person,
  isPerson = false,
  target,
  children,
  extraClassNames,
  redirect
}) => {
  const { uiState } = useContext(StoreContext);
  const intl = useIntl();
  const notification = useNotificationQueue();
  const { push } = useHistory();
  const timeout = useRef();

  const { getPool } = useUserPool({ entityId });
  const { getPerson } = useUser({ personId: person.id });

  useEffect(() => {
    return () => {
      timeout.current && clearTimeout(timeout.current);
      uiState.decreasePendingRequest();
    };
  }, []);

  return (
    <ConfirmMutationButton
      small
      light
      extraClassNames={extraClassNames}
      confirmTitle={messages.modalConfirmResetPasswordTitle}
      confirmMessage={messages.modalConfirmResetPasswordMessage}
      confirmAcceptLabel={messages.modalConfirmResetPasswordAcceptButton}
      mutation={MUTATE_RESET_LOGIN}
      variables={{ personId: person.id }}
      refetchQueries={[
        {
          query: QUERY_GET_USER_BY_ID,
          variables: {
            id: person.id
          }
        }
      ]}
      update={(cache, { data: { resetLogin } }) => {
        if (resetLogin) {
          uiState.increasePendingRequest();
          timeout.current = setTimeout(
            async () => {
              if (isPerson) {
                await getPool();
              }
              await getPerson();
              uiState.decreasePendingRequest();
              notification.add(`emailReset_${person.id}`, {
                message: intl.formatMessage(messages.messageUserEmailReset)
              });
              if (redirect) {
                push(redirect);
                return;
              }
              push(target);
            },
            isPerson ? 2000 : 0
          ); // Delay from the backend
        }
      }}
      onError={error => {
        notification.add(`emailReset_error_${person.id}`, {
          message: error.message
        });
      }}
    >
      {children}
    </ConfirmMutationButton>
  );
};

export default ResetLoginButton;
