import classNames from 'classnames';
import ListColumnTitle from './ListColumnTitle';

const ListHeader = props => {
  const {
    extraClassNames,
    oneTitle,
    noTitleStatus,
    sticky,
    sortableByProgress,
    onClick,
    children,
    style,
    ...rest
  } = props;

  return (
    <div
      className={classNames('c-list__header', extraClassNames, {
        'c-list__header-one-title': oneTitle,
        'c-list__header-item-status-hidden': noTitleStatus,
        'c-list__header-sticky': sticky
      })}
      style={style}
      {...rest}
    >
      {sortableByProgress && (
        <ListColumnTitle descendent sortableByProgress onClick={onClick}>
          %
        </ListColumnTitle>
      )}
      <div className={'c-list__header-labels'}>{children}</div>
    </div>
  );
};

export default ListHeader;
