import React from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { MUTATION_MAIL_SESSION_REPORT } from 'services/aws/session-query';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import { MODAL_TYPES } from 'models/ModalData';
import { useNotificationQueue } from 'components/notification';
import { useIntl } from 'react-intl';

const MailReportButton = ({
  testDataId,
  entityId,
  session,
  sporter,
  sessionId,
  options,
  children,
  ...props
}) => {
  const notification = useNotificationQueue();
  const intl = useIntl();
  if (session.isIAm || session.isHanSisp) return <></>;
  if (!sporter?.meta?.email && !sporter.email) return <></>;

  const { benchmark, ...restOptions } = options;
  const data = {
    testDataId,
    options: restOptions
  };
  if (!session.isRehab && !session.isPrevention) {
    data.benchmarkId = benchmark;
  }
  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_MAIL_SESSION_REPORT}
      confirmTitle={messages.modalConfirmMailReportTitle}
      confirmMessage={messages.modalConfirmMailReportMessage}
      confirmAcceptLabel={messages.modalConfirmMailReportAcceptButton}
      confirmType={MODAL_TYPES.WARNING}
      variables={data}
      update={(cache, { data: { mailSessionReport } }) => {
        if (mailSessionReport) {
          notification.add('mailSessionReport', {
            message: intl.formatMessage(
              messages.modalReportSendToSporterSuccessMessage
            )
          });
        }
      }}
    >
      <Icon id="send" />
      {children}
    </ConfirmMutationButton>
  );
};

export default inject('routing')(withRouter(MailReportButton));
