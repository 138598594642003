import { features } from 'constants.js';

export default class Organisation {
  id;
  name = '';
  info = {
    contact: {
      firstname: '',
      lastname: '',
      email: '',
      notes: '',
      type: '',
      otherType: ''
    },
    logoLinkId: '',
    logo: '',
    location: '',
    address_1: '',
    address_2: '',
    zipcode: '',
    country: '',
    organisationType: '',
    otherOrganisationType: ''
  };
  featureFlags = Object.keys(features).reduce((obj, feature) => {
    obj[feature] = false;
    return obj;
  }, {});
  apiKeys = [];
  baseLanguage = 'nl';
  parentId;

  // cast array to object
  static castArrayToObject(array) {
    return array.reduce((obj, item) => {
      obj[item[0]] = item[1];
      return obj;
    }, {});
  }

  constructor({
    id,
    name,
    info,
    parentId,
    baseLanguage,
    featureFlags,
    apiKeys
  }) {
    this.id = id;
    this.name = name ?? '';
    this.parentId = parentId;
    this.baseLanguage = baseLanguage;

    const parsedInfo = info ? JSON.parse(info) : {};

    this.info = {
      logoLinkId: parsedInfo?.logoLinkId ?? '',
      logo: parsedInfo?.logo ?? '',
      location: parsedInfo?.location ?? '',
      address_1: parsedInfo?.address_1 ?? '',
      address_2: parsedInfo?.address_2 ?? '',
      zipcode: parsedInfo?.zipcode ?? '',
      country: parsedInfo?.country ?? '',
      organisationType: parsedInfo?.organisationType ?? '',
      otherOrganisationType: parsedInfo?.otherOrganisationType ?? '',
      contact: {
        firstname: parsedInfo.contact?.firstname ?? '',
        lastname: parsedInfo.contact?.lastname ?? '',
        email: parsedInfo.contact?.email ?? '',
        notes: parsedInfo.contact?.notes ?? '',
        type: parsedInfo.contact?.type ?? '',
        otherType: parsedInfo.contact?.otherType ?? ''
      }
    };

    this.featureFlags = featureFlags
      ? JSON.parse(featureFlags)
      : this.featureFlags;

    this.apiKeys = apiKeys;
  }

  static get modelName() {
    return 'Organisation';
  }
}
