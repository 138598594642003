import { PersonInjuryType } from 'constants.js';
import PersonInjury from 'models/PersonInjury';
import PersonIllness from 'models/PersonIllness';
import PersonIssue from 'models/PersonIssue';
import { sort, SORT_DATA_TYPES } from 'utils/sort';

export default class Medical {
  id;
  description;
  injuries;
  person;
  // personentity;
  constructor({
    id,
    description = '',
    injuries = [],
    person = null
    // personEntities = NULL
  }) {
    this.id = id;
    this.description = description ?? '';
    this.injuries =
      injuries?.length > 0
        ? sort(
            injuries
              .filter(
                i =>
                  i.personInjuryType !== PersonInjuryType.ILLNESS &&
                  i.personInjuryType !== PersonInjuryType.ISSUE
              )
              .map(i => new PersonInjury({ ...i })),
            {
              keys: [
                {
                  key: 'injuryDate',
                  dataType: SORT_DATA_TYPES.DATE_STRING,
                  desc: true
                }
              ]
            }
          )
        : [];
    this.illnesses =
      injuries?.length > 0
        ? sort(
            injuries
              .filter(i => i.personInjuryType === PersonInjuryType.ILLNESS)
              .map(i => new PersonIllness({ ...i })),
            {
              keys: [
                {
                  key: 'injuryDate',
                  dataType: SORT_DATA_TYPES.DATE_STRING,
                  desc: true
                }
              ]
            }
          )
        : [];
    this.issues =
      injuries?.length > 0
        ? sort(
            injuries
              .filter(i => i.personInjuryType === PersonInjuryType.ISSUE)
              .map(i => new PersonIssue({ ...i })),
            {
              keys: [
                {
                  key: 'injuryDate',
                  dataType: SORT_DATA_TYPES.DATE_STRING,
                  desc: true
                }
              ]
            }
          )
        : [];
    this.person = person;
    // this.personEntities = personEntities;
  }
}
