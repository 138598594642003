import React from 'react';
import ConfirmButton from './ConfirmButton';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import { deleteFileMutation } from 'services/aws/file-upload';

const RemoveFilesButton = ({
  entityId,
  linkId,
  type,
  ids,
  children,
  onComplete,
  ...props
}) => {
  const onConfirmDeleteFiles = async () => {
    if (Array.isArray(ids) && ids.length > 0) {
      for (const id of ids) {
        await deleteFileMutation({ fileId: id, entityId });
        onComplete && onComplete();
      }
    }
  };
  return (
    <ConfirmButton
      {...props}
      confirmTitle={messages.modalConfirmRemoveFilesTitle}
      confirmMessage={messages.modalConfirmRemoveFilesMessage}
      onOk={onConfirmDeleteFiles}
    >
      <Icon
        id="delete"
        strokeColor={props.disabled ? 'color-neutral' : 'color-neutral-dark'}
      />
      {children}
    </ConfirmButton>
  );
};

export default RemoveFilesButton;
