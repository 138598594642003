import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import Button from 'components/button/Button';
import messages from 'messages';
import { StoreContext } from 'index';

const ConfirmMutationButton = ({
  children,
  confirmType,
  confirmTitle,
  confirmMessage,
  messageValues,
  confirmAcceptLabel = messages.modalConfirmRemoveAcceptButton,
  confirmCancelLabel = messages.modalConfirmRemoveCancelButton,
  mutation,
  variables,
  refetchQueries,
  update,
  onError,
  onClick,
  ...props
}) => {
  const { uiState } = useContext(StoreContext);
  const intl = useIntl();
  return (
    <Button
      {...props}
      onClick={e => {
        e.preventDefault();
        if (onClick) onClick();
        uiState.showModal({
          title: confirmTitle
            ? intl.formatMessage(confirmTitle, messageValues)
            : '',
          message: confirmMessage
            ? intl.formatMessage(confirmMessage, messageValues)
            : '',
          okLabel: intl.formatMessage(confirmAcceptLabel),
          dismissLabel: intl.formatMessage(confirmCancelLabel),
          okHandler: () => {
            uiState.increasePendingRequest();
          },
          type: confirmType,
          mutationData: {
            mutation,
            variables,
            refetchQueries,
            update: (cache, data) => {
              uiState.decreasePendingRequest();
              if (update) update(cache, data);
            },
            onError
          }
        });
      }}
    >
      {children}
    </Button>
  );
};

export default ConfirmMutationButton;
