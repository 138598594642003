import * as api from '../services/aws/api';

export default class UserStore {
  constructor(routerStore, uiState) {
    this.routerStore = routerStore;
    this.uiState = uiState;
  }

  createLogin(personId) {
    api.createLogin(personId);
  }

  injectEntityStore(entityStore) {
    this.entityStore = entityStore;
  }
}
