import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  QUERY_GET_PERSONS_OF_ENTITY,
  QUERY_GET_USER_BY_ID
} from 'services/aws/user-query';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import { useNotificationQueue } from 'components/notification';
import { useIntl } from 'react-intl';
import {
  MUTATION_REMOVE_PERSON_LOGIN,
  S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID
} from 'services/aws/client-query';
import { StoreContext } from 'index';

const RemoveLoginButton = ({
  personId,
  entityId,
  target,
  children,
  extraClassNames,
  redirect
}) => {
  const {
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const intl = useIntl();
  const notification = useNotificationQueue();
  const { push } = useHistory();

  return (
    <ConfirmMutationButton
      small
      light
      extraClassNames={extraClassNames}
      confirmTitle={messages.modalConfirmRemoveLoginTitle}
      confirmMessage={messages.modalConfirmRemoveLoginMessage}
      confirmAcceptLabel={messages.modalConfirmRemoveLoginAcceptButton}
      mutation={MUTATION_REMOVE_PERSON_LOGIN}
      variables={{ personId, entityId }}
      refetchQueries={[
        {
          query: S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID,
          variables: { id: entityId },
          fetchPolicy: 'network-only'
        },
        {
          query: QUERY_GET_PERSONS_OF_ENTITY,
          variables: { entityId: rootEntityId },
          fetchPolicy: 'network-only'
        },
        {
          query: QUERY_GET_USER_BY_ID,
          variables: {
            id: personId
          },
          fetchPolicy: 'network-only'
        }
      ]}
      update={(cache, { data: { removeLogin } }) => {
        if (removeLogin) {
          notification.add(`loginRemove_${personId}`, {
            message: intl.formatMessage(messages.messageUserLoginRemoved)
          });
          if (redirect) {
            push(redirect);
            return;
          }
          push(target);
        }
      }}
      onError={error => {
        notification.add(`loginRemove_error_${personId}`, {
          message: error.message
        });
      }}
    >
      {children}
    </ConfirmMutationButton>
  );
};

export default RemoveLoginButton;
