import React, { useContext, useEffect, useState } from 'react';
import {
  withRouter,
  generatePath,
  useParams,
  useHistory
} from 'react-router-dom';
import { TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import { FormattedMessage } from 'react-intl';
import Badge from 'components/badge/Badge';
import { Panel, PanelHeader } from 'components/panel';
import messages from 'messages';
import {
  PanelTitleWrapper,
  PanelTitle,
  PanelSubtitle,
  PanelTitleButton
} from 'components/panel/panel-title';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import PanelBody from 'components/panel/PanelBody';
import { MenuWrapper } from 'components/menu';

import {
  ROUTE_SPORTERS_ENTITY_SPORTER,
  ROUTE_SPORTERS_ENTITY_SPORTER_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL,
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS,
  ROUTE_SPORTERS_ENTITY_SPORTER_VIDEOS,
  ROUTE_SPORTERS_ENTITY_SPORTER_VIDEOS_VIDEO,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK,
  ROUTE_SPORTERS_ENTITY_SPORTER_GROUPS,
  ROUTE_SPORTERS_ENTITY_SPORTER_DOCS,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL_EDIT
} from 'routes/RouteList';
import SporterCard from 'containers/partials/cards/SporterCard';
import { QUERY_GET_TOTAL_SESSIONS_OF_SPORTER } from 'services/aws/session-query';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import SporterMedicalCard from 'containers/pages/rehab/SporterMedicalCard';
import { StoreContext } from 'index';
import { usePersonContext } from 'contexts/PersonContext';
import { getDateInYears } from 'utils/date';
import Loader from 'components/loader/Loader';
import useTabIndex from 'hooks/utils/useTabIndex';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import SporterSessions from 'containers/pages/sporters/SporterSessions';
import { useQuery } from '@apollo/client';
import SporterVideos from 'containers/pages/sporters/SporterVideos';
import TabLink from 'components/tabs/TabLink';
import SporterPrograms from 'containers/pages/sporters/SporterPrograms';
import SporterGroups from 'containers/pages/sporters/SporterGroups';
import SporterDocs from 'containers/pages/sporters/SporterDocs';
import { useMenuContext } from 'contexts/MenuContext';

const Sporter = ({ active }) => {
  const {
    authStore: {
      user,
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const { entityId, sporterId, sessionId } = useParams();
  const ability = useAbility(AbilityContext);
  const {
    person,
    loading: personLoading,
    error: personError
  } = usePersonContext();
  const { menuItems } = useMenuContext();

  // Define the tabs for the logged in user
  let routes = [];
  if (ability.can('read', 'Sessions')) {
    routes.push(ROUTE_SPORTERS_ENTITY_SPORTER);
  }

  if (ability.can('read', 'Rehab')) {
    routes.push([
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL_EDIT,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL_EDIT,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL_EDIT,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS
    ]);
  }

  if (ability.can('manage', 'Videos')) {
    routes = [
      [
        ROUTE_SPORTERS_ENTITY_SPORTER,
        ROUTE_SPORTERS_ENTITY_SPORTER_VIDEOS,
        ROUTE_SPORTERS_ENTITY_SPORTER_VIDEOS_VIDEO
      ]
    ];
  }

  if (ability.can('read', 'Programs')) {
    routes.push([
      ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS,
      ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM,
      ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK
    ]);
  }

  if (ability.can('manage', 'Athletes')) {
    routes.push([ROUTE_SPORTERS_ENTITY_SPORTER_GROUPS]);
  }
  if (ability.can('read', 'Documents')) {
    routes.push([ROUTE_SPORTERS_ENTITY_SPORTER_DOCS]);
  }

  routes.push([
    ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL_EDIT,
    ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL
  ]);

  const [tabIndex, setTabIndex] = useTabIndex(routes);

  const [totalSessions, setTotalSessions] = useState(0);

  const { error, data } = useQuery(QUERY_GET_TOTAL_SESSIONS_OF_SPORTER, {
    variables: { personId: sporterId, entityId },
    skip: !ability.can('read', 'Sessions')
  });

  useEffect(() => {
    if (data?.getTestSessionsOfPerson) {
      setTotalSessions(data.getTestSessionsOfPerson.length);
    }
  }, [data, sporterId]);

  const onCancel = () => {
    push(
      generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL, {
        entityId,
        sporterId
      })
    );
  };

  if (personLoading)
    return (
      <Panel>
        <Loader />
      </Panel>
    );

  return (
    <Panel active={active}>
      {(error || personError) && <RetryPanel />}
      {person && (
        <>
          <PanelHeader>
            <Breadcrumbs>
              <Breadcrumb
                onClick={() =>
                  push(ROUTE_SPORTERS_ENTITY.replace(':entityId', entityId))
                }
              >
                <FormattedMessage {...messages.breadcrumbSporters} />
              </Breadcrumb>
            </Breadcrumbs>
            <PanelTitleWrapper>
              <PanelTitle>
                {person.firstname} {person.lastname}
                <MenuWrapper trigger={<PanelTitleButton />}>
                  {menuItems}
                </MenuWrapper>
              </PanelTitle>
              {!user.isVitaleOrg && (
                <PanelSubtitle>
                  {person.birthdate && (
                    <FormattedMessage
                      {...messages.labelSporterAge}
                      values={{ age: getDateInYears(person.birthdate) }}
                    />
                  )}
                </PanelSubtitle>
              )}
            </PanelTitleWrapper>
          </PanelHeader>

          <PanelBody>
            <Tabs
              selectedIndex={tabIndex}
              onSelect={index => setTabIndex(index)}
            >
              <TabList>
                {ability.can('read', 'Sessions') && (
                  <TabLink
                    to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER, {
                      entityId,
                      sporterId
                    })}
                  >
                    {totalSessions > 0 && <Badge>{totalSessions}</Badge>}
                    <FormattedMessage {...messages.tabSporterSession} />
                  </TabLink>
                )}

                {ability.can('read', 'Rehab') && (
                  <TabLink
                    to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL, {
                      entityId,
                      sporterId
                    })}
                  >
                    <FormattedMessage {...messages.tabSporterMedical} />
                  </TabLink>
                )}

                {ability.can('manage', 'Videos') && (
                  <TabLink
                    to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_VIDEOS, {
                      entityId,
                      sporterId
                    })}
                  >
                    <FormattedMessage {...messages.tabSporterVideos} />
                  </TabLink>
                )}

                {ability.can('read', 'Programs') && (
                  <TabLink
                    to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS, {
                      entityId,
                      sporterId
                    })}
                  >
                    <FormattedMessage {...messages.programs} />
                  </TabLink>
                )}

                {ability.can('manage', 'Athletes') && (
                  <TabLink
                    to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_GROUPS, {
                      entityId,
                      sporterId
                    })}
                  >
                    <FormattedMessage {...messages.athleteGroups} />
                  </TabLink>
                )}

                {ability.can('read', 'Documents') && (
                  <TabLink
                    to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_DOCS, {
                      entityId,
                      sporterId
                    })}
                  >
                    <FormattedMessage {...messages.athleteDocs} />
                  </TabLink>
                )}

                {/* Sporter Global */}
                <TabLink
                  to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL, {
                    entityId,
                    sporterId
                  })}
                >
                  <FormattedMessage {...messages.tabSporterGlobal} />
                </TabLink>
              </TabList>

              {ability.can('read', 'Sessions') && (
                <TabPanel>
                  <TabPanelBody>
                    <SporterSessions
                      entityId={entityId}
                      sporterId={sporterId}
                      sessionId={sessionId}
                    />
                  </TabPanelBody>
                </TabPanel>
              )}

              {ability.can('read', 'Rehab') && (
                <TabPanel>
                  <TabPanelBody withScroller>
                    <SporterMedicalCard />
                  </TabPanelBody>
                </TabPanel>
              )}

              {ability.can('manage', 'Videos') && (
                <TabPanel>
                  <TabPanelBody withScroller>
                    <SporterVideos entityId={entityId} sporterId={sporterId} />
                  </TabPanelBody>
                </TabPanel>
              )}

              {ability.can('read', 'Programs') && (
                <TabPanel>
                  <TabPanelBody>
                    <SporterPrograms
                      entityId={rootEntityId}
                      personId={sporterId}
                    />
                  </TabPanelBody>
                </TabPanel>
              )}

              {ability.can('manage', 'Athletes') && (
                <TabPanel>
                  <TabPanelBody>
                    <SporterGroups
                      entityId={rootEntityId}
                      personId={sporterId}
                    />
                  </TabPanelBody>
                </TabPanel>
              )}

              {ability.can('read', 'Documents') && (
                <TabPanel>
                  <TabPanelBody>
                    <SporterDocs entityId={rootEntityId} personId={sporterId} />
                  </TabPanelBody>
                </TabPanel>
              )}

              {/* Sporter Global */}
              <TabPanel>
                <TabPanelBody withScroller>
                  <SporterCard entityId={entityId} onCancel={onCancel} />
                </TabPanelBody>
              </TabPanel>
            </Tabs>
          </PanelBody>
        </>
      )}
    </Panel>
  );
};

export default withRouter(Sporter);
