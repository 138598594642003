import { createRef, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import CardBody from 'components/card/CardBody';
import Card from 'components/card/Card';
import messages from 'messages';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderText
} from 'components/card/card-header';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import { useFormikContext } from 'formik';
import FieldInput from 'components/input/FieldInput';
import FileUppyWrapper from 'containers/partials/file-upload/FileUppyWrapper';
import { FILE_TYPES } from 'constants.js';
import ThumbList from 'containers/partials/media/ThumbList';
import AlertRemarks from './AlertRemarks';
import { useLazyQuery } from '@apollo/client';
import { deleteFileMutation, QUERY_GET_FILE } from 'services/aws/file-upload';
import useMediaList from 'hooks/utils/useMediaList';

const VideoFormInput = ({
  edit,
  readOnly,
  isAdd,
  setEdit,
  onCancel,
  entityId
}) => {
  const {
    values: video,
    submitForm,
    setFieldValue,
    resetForm
  } = useFormikContext();
  const { getMedia, setMedia, removeMedia } = useMediaList(video.media);

  const intl = useIntl();
  const playerRef = createRef();

  const [getFile] = useLazyQuery(QUERY_GET_FILE, {
    fetchPolicy: 'network-only'
  });

  const onFilesUploaded = async files => {
    const result = await Promise.all(
      files.map(async file => {
        const exerciseFile = await getFile({
          variables: { fileId: file.id }
        });
        if (exerciseFile?.data?.getFile) {
          return exerciseFile.data.getFile;
        }
      })
    );
    const prevMedia = getMedia();
    if (prevMedia?.[0]) {
      await deleteFileMutation({
        entityId,
        fileId: prevMedia[0].id
      });
    }
    const updated = setMedia(result[0]);
    setFieldValue('videoFileId', updated[0].linkId);
  };

  const onFileDeleted = async fileId => {
    removeMedia(fileId);
    setFieldValue('videoFileId', null);
  };

  const onSortHandler = ids => {
    setFieldValue('meta', {
      order: [...ids]
    });
  };

  useEffect(() => {
    if (video?.video?.signedDownloadUrl && playerRef?.current) {
      playerRef.current.load();
    }
  }, [video, playerRef]);

  return (
    <>
      {video.templateCount === 0 && (
        // TODO: add NL translation
        // same as in `VideoViewCard.js`
        <AlertRemarks
          message={intl.formatMessage(messages.videosMessageNotInLesson)}
        />
      )}
      <Card secondary multiBody>
        <CardHeader secondary>
          <CardHeaderText>
            <CardHeaderTitle>
              <FormattedMessage {...messages.cardTitleExercise} />
            </CardHeaderTitle>
          </CardHeaderText>
          {!readOnly && (video.editable || isAdd) && (
            <FormEditHeaderButtons
              edit={edit}
              setEdit={setEdit}
              type={'button'}
              onSubmit={submitForm}
              onCancel={() => {
                resetForm();
                if (onCancel) onCancel();
              }}
            />
          )}
        </CardHeader>
        <CardBody secondary separatorBottom>
          <div className="o-layout o-layout--small">
            <div className="o-layout__item u-3-of-3">
              {/* VIDEO TITLE */}
              <FieldInput id="title" name="title" readOnly={readOnly}>
                <FormattedMessage {...messages.exercisesLabelTitle} />*
              </FieldInput>
            </div>
          </div>
        </CardBody>

        <CardHeader secondary extraClassNames="u-margin-bottom-none">
          <CardHeaderText>
            <CardHeaderTitle>
              <FormattedMessage {...messages.lessonsVideoLabel} />
            </CardHeaderTitle>
          </CardHeaderText>
        </CardHeader>
        <CardBody secondary>
          {/* MEDIA */}
          <div className={'c-file-upload--state c-file-upload--state-small'}>
            <div className="c-media">
              {!isAdd ? (
                <div className={'c-media__upload'}>
                  <FileUppyWrapper
                    entityId={entityId}
                    linkId={video.id}
                    type={'exercise'}
                    accept={[...FILE_TYPES.VIDEO]}
                    onFilesUploaded={files => onFilesUploaded(files)}
                    maxFiles={1}
                    numFiles={getMedia().length}
                    isPublic
                  >
                    {getMedia().length > 0 && (
                      <ThumbList
                        entityId={entityId}
                        media={getMedia()}
                        onSort={onSortHandler}
                        onFileDeleted={file => onFileDeleted(file)}
                        editable={true}
                      />
                    )}
                  </FileUppyWrapper>
                </div>
              ) : (
                <p>
                  <FormattedMessage
                    {...messages.exercisesWarningFotoAndVideo}
                  />
                </p>
              )}
            </div>
          </div>
        </CardBody>

        {!readOnly && (video.editable || isAdd) && (
          <FormEditFooterButtons
            extraClassNames="u-padding-bottom-medium"
            edit={edit}
            setEdit={setEdit}
            onCancel={() => {
              resetForm();
              if (onCancel) onCancel();
            }}
            onSubmit={submitForm}
            topMargin
          />
        )}
      </Card>
    </>
  );
};

export default VideoFormInput;
