import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter, generatePath } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Panel from 'components/panel/Panel';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import messages from 'messages';
import { PanelHeader } from 'components/panel';
import { PanelTitle, PanelTitleWrapper } from 'components/panel/panel-title';
import ListItem from 'components/list/list-item/ListItem';
import ListItemStatus from 'components/list/list-item/ListItemStatus';
import ListItemLabelWrapper from 'components/list/list-item/ListItemLabelWrapper';
import ListItemLabel from 'components/list/list-item/ListItemLabel';
import {
  ROUTE_EXERCISES_ENTITY,
  ROUTE_EXERCISES_PROGRAMS_ENTITY,
  ROUTE_EXERCISES_TEMPLATES_ENTITY
} from 'routes/RouteList';
import { useParams, useRouteMatch } from 'react-router-dom';

const Overview = ({ active, routing: { push }, entityId }) => {
  const { entityId: entityParam } = useParams();
  return (
    <Panel active={active}>
      <PanelHeader>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.exercisesTitle} />
          </PanelTitle>
        </PanelTitleWrapper>
      </PanelHeader>

      <List>
        <ListBody>
          <ListItem
            active={!!useRouteMatch(ROUTE_EXERCISES_PROGRAMS_ENTITY)}
            onClick={() =>
              push(generatePath(ROUTE_EXERCISES_PROGRAMS_ENTITY, { entityId }))
            }
          >
            <ListItemStatus statusIcon="program" />
            <ListItemLabelWrapper>
              <ListItemLabel>
                <FormattedMessage {...messages.programs} />
              </ListItemLabel>
            </ListItemLabelWrapper>
          </ListItem>
          <ListItem
            active={!!useRouteMatch(ROUTE_EXERCISES_TEMPLATES_ENTITY)}
            onClick={() =>
              push(generatePath(ROUTE_EXERCISES_TEMPLATES_ENTITY, { entityId }))
            }
          >
            <ListItemStatus statusIcon="template" />
            <ListItemLabelWrapper>
              <ListItemLabel>
                <FormattedMessage {...messages.templates} />
              </ListItemLabel>
            </ListItemLabelWrapper>
          </ListItem>
          <ListItem
            active={
              !!useRouteMatch({
                path: ROUTE_EXERCISES_ENTITY,
                exact: true
              }) && entityParam === entityId
            }
            onClick={() =>
              push(generatePath(ROUTE_EXERCISES_ENTITY, { entityId }))
            }
          >
            <ListItemStatus statusIcon="exercise" />
            <ListItemLabelWrapper>
              <ListItemLabel>
                <FormattedMessage {...messages.exercisesTitle} />
              </ListItemLabel>
            </ListItemLabelWrapper>
          </ListItem>
        </ListBody>
      </List>
    </Panel>
  );
};

export default withRouter(
  inject('routing', 'authStore', 'uiState')(observer(Overview))
);
