import React from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { ROUTE_SETTINGS_ORGANISATIONS } from 'routes/RouteList';
import {
  MUTATION_ARCHIVE_ENTITY,
  QUERY_GET_ORGANISATIONS_BY_ID
} from 'services/aws/entity-query';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';

const RemoveOrganisationButton = ({
  organisationId,
  authStore,
  children,
  parentId,
  routing: { push },
  ...props
}) => {
  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_ARCHIVE_ENTITY}
      variables={{ id: organisationId }}
      confirmTitle={messages.modalConfirmRemoveOrganisationTitle}
      confirmMessage={messages.modalConfirmRemoveOrganisationMessage}
      refetchQueries={[
        {
          query: QUERY_GET_ORGANISATIONS_BY_ID,
          variables: { id: parentId }
        }
      ]}
      update={async (cache, { data: { archiveEntity } }) => {
        if (archiveEntity) {
          push(ROUTE_SETTINGS_ORGANISATIONS);
          await authStore.renewEntities();
        }
      }}
    >
      <Icon id="delete" strokeColor="color-neutral-dark" />
      {children}
    </ConfirmMutationButton>
  );
};

export default inject(
  'routing',
  'authStore'
)(withRouter(RemoveOrganisationButton));
