import { useEffect } from 'react';
import ExercisesListVirtualized from 'containers/partials/lists/ExercisesListVirtualized';
import { useExercisesContext } from 'contexts/ExercisesContext';
import Loader from 'components/loader/Loader';

const ExercisesListFiltered = ({ edit, filter, onPreview }) => {
  const { exercisesState, exerciseActions, loading } = useExercisesContext();

  useEffect(() => {
    exerciseActions.getExercises();
    exerciseActions.setFilter({ ...filter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="c-accordion__virtualized-list-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <ExercisesListVirtualized
          wrappedList
          onPreview={onPreview}
          visible={edit}
          checkable={edit}
          showLevel
          enablePreviewModal
          exercises={exercisesState.exercises}
          onSelect={(exerciseIds, exercise, checked) => {
            if (!edit) return;
            if (checked) {
              exerciseActions.selectExercises([exercise]);
            } else {
              exerciseActions.unselectExercises([exercise]);
            }
          }}
          selectedExercises={exercisesState.selectedExercises}
        />
      )}
    </div>
  );
};

ExercisesListFiltered.propTypes = {};

export default ExercisesListFiltered;
