import React, { useContext } from 'react';
import Icon from 'components/icon/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Button from 'components/button/Button';
import {
  downloadSessionReport,
  downloadSkReportSession
} from 'services/aws/session-query';
import { MODAL_TYPES } from 'models/ModalData';
import { StoreContext } from 'index';
import { pollIsValidUrl } from 'utils/url';
import { openWindowWithLoader } from 'utils/browser';

const DownloadSessionReportButton = ({
  session,
  testDataId = null,
  options,
  isSession = false
}) => {
  const intl = useIntl();
  const {
    uiState,
    authStore: { user }
  } = useContext(StoreContext);

  const downloadSession = async () => {
    const { benchmark, ...restOptions } = options;
    const data = {
      testDataId,
      options: restOptions
    };
    if (!session.isRehab && !session.isPrevention) {
      data.benchmarkId = benchmark;
    }
    const filenameQuery = await downloadSessionReport(data);
    if (filenameQuery) {
      return filenameQuery.data.downloadSessionReport;
    }
  };

  const downloadSkSession = async () => {
    const filenameQuery = await downloadSkReportSession(
      session.id,
      testDataId,
      false
    );
    if (filenameQuery) {
      return filenameQuery.data.generateSkReport;
    }
  };

  const downloadReport = async newWindow => {
    const openWindow = newWindow;

    try {
      uiState.increasePendingRequest();
      const url = session.isTestSetV2
        ? await downloadSession(testDataId)
        : await downloadSkSession(session, testDataId);

      if (url) {
        const response = await pollIsValidUrl(
          url.replace('&', '%2526'), // TODO Temp hack to allow & in url
          25,
          1000
        );

        if (response) {
          openWindow.location = url.replace('&', '%2526');
          openWindow.focus();
        }
      }
    } catch (error) {
      // @ts-ignore
      uiState.showModal({
        title: intl.formatMessage(messages.titleSessionHanReportDownloadFailed),
        message: intl.formatMessage(
          messages.messageSessionHanReportDownloadFailed
        ),
        dismissButton: false,
        type: MODAL_TYPES.WARNING
      });
    }

    uiState.decreasePendingRequest();
  };

  if (
    (isSession && user.isHylyghtOrg) ||
    user.isHanOrg ||
    (!user.isHylyghtOrg &&
      (session.isILike ||
        session.isIDo ||
        session.isHanSisp ||
        (session.isKlusce && isSession) ||
        (session.isIAm && !isSession) ||
        (session.isDjas && !isSession)))
  )
    return <></>;

  return (
    <Button
      menu
      onClick={() => {
        const newWindow = openWindowWithLoader();
        downloadReport(newWindow);
      }}
    >
      <Icon id="download" />
      <FormattedMessage {...messages.menuItemSessionDownloadReport} />
    </Button>
  );
};

export default DownloadSessionReportButton;
