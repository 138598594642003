import { observable, computed, makeObservable } from 'mobx';
import {
  PersonEntityRoles,
  GLOBAL_TESTER_LOGIN,
  OrganisationTypes,
  TestSetV2Type
} from 'constants.js';
import Person from './Person';
import { capitalize } from 'utils/string';
import {
  athleteFeatures,
  defaultOrgFeatures,
  gsvAthleteRules,
  gsvRules,
  VitaleAdminRules,
  VitaleAthleteRules,
  VitaleRules,
  VitaleSubAdminRules
} from 'rules';

export default class User extends Person {
  entityRoles = '';
  language = 'en';
  dateCreated;
  testSetTypes = [];

  superadmin = false;

  constructor(
    {
      id,
      hmac,
      parentId,
      lastname,
      firstname,
      birthdate,
      entities,
      email,
      superadmin = false,
      date_created,
      baseLanguage,
      meta,
      account_meta,
      status
    },
    entityId
  ) {
    super(
      {
        id,
        hmac,
        parentId,
        lastname,
        firstname,
        email,
        birthdate,
        entities,
        baseLanguage,
        meta,
        account_meta,
        status
      },
      entityId
    );

    makeObservable(this, {
      entityRoles: observable,
      hasGroupsToShow: computed
    });
    this.superadmin = superadmin;
    this.dateCreated = date_created;
  }

  getPossibleLanguages(entityId = this.rootEntityId) {
    const personEntity = this.getPersonEntityByEntityId(entityId);
    if (
      personEntity &&
      personEntity.entity &&
      personEntity.entity.possibleLanguages
    ) {
      return personEntity.entity.possibleLanguages;
    }
    return [];
  }

  getUserLocale(entityId = this.rootEntityId) {
    const language = this.getLanguage(entityId);
    const entityRegionCode = this.getEntityRegioCode(entityId);

    if (this.isGSVOrg) {
      return `nl-gsv`;
    }
    if (this.isVitaleOrg) {
      return `nl-vitale`;
    }

    if (language && entityRegionCode) {
      return language; //${entityRegionCode} // TODO dit werkt niet meer met de huidige translation flow
    }
    return null;
  }

  getEntityLocale(entityId = this.rootEntityId) {
    const entityLanguage = this.getEntityLanguage(entityId);
    const entityRegionCode = this.getEntityRegioCode(entityId);
    if (entityLanguage && entityRegionCode) {
      return `${entityLanguage}-${entityRegionCode}`;
    }
    return null;
  }

  getEntityRegioCode(entityId = this.rootEntityId) {
    const personEntity = this.getPersonEntityByEntityId(entityId);
    if (personEntity && personEntity.entity && personEntity.entity.regioCode) {
      return personEntity.entity.regioCode;
    }
    return null;
  }

  getEntityLanguage(entityId = this.rootEntityId) {
    const personEntity = this.getPersonEntityByEntityId(entityId);
    if (
      personEntity &&
      personEntity.entity &&
      personEntity.entity.baseLanguage
    ) {
      return personEntity.entity.baseLanguage;
    }
    return null;
  }

  getUserLanguage(entityId = this.rootEntityId) {
    if (this.baseLanguage) return this.baseLanguage;
    const personEntity = this.getPersonEntityByEntityId(entityId);
    if (personEntity && personEntity.baseLanguage) {
      return personEntity.baseLanguage;
    }
    return null;
  }

  getLanguage(entityId = this.rootEntityId) {
    return this.getUserLanguage(entityId) || this.getEntityLanguage(entityId);
  }

  get isGlobalTester() {
    return this.email === GLOBAL_TESTER_LOGIN;
  }

  hasNoRoles(entityId = this.rootEntityId) {
    return (
      !this.hasRoleByEntityId(entityId, PersonEntityRoles.ROLE_ADMIN) &&
      !this.hasRoleByEntityId(entityId, PersonEntityRoles.ROLE_SUB_ADMIN) &&
      // !this.hasRoleByEntityId(entityId, PersonEntityRoles.ROLE_TESTER) &&
      !this.hasRoleByEntityId(entityId, PersonEntityRoles.ROLE_ATHLETE)
    );
  }

  get hasGroupsToShow() {
    const canShowGroup =
      this.entityRoles && this.entityRoles.find(er => this.canSeeGroup(er.id));
    return !!canShowGroup;
  }

  isAdmin(entityId = this.rootEntityId) {
    return this.hasRoleByEntityId(entityId, PersonEntityRoles.ROLE_ADMIN);
  }

  isSubAdmin(entityId = this.rootEntityId) {
    return this.hasRoleByEntityId(entityId, PersonEntityRoles.ROLE_SUB_ADMIN);
  }

  hasAdminRole(entityId = this.rootEntityId) {
    return this.isAdmin(entityId) || this.isSubAdmin(entityId);
  }

  /*isTester(entityId = this.rootEntityId) {
    return this.hasRoleByEntityId(entityId, PersonEntityRoles.ROLE_TESTER);
  }*/

  isAthlete(entityId = this.rootEntityId) {
    return this.hasRoleByEntityId(entityId, PersonEntityRoles.ROLE_ATHLETE);
  }

  get isSportamundiOrg() {
    return !this.config.type;
  }

  get isMTSOrg() {
    return this.config.type === OrganisationTypes.MTS;
  }

  get isHylyghtOrg() {
    return this.config.type === OrganisationTypes.HYLYGHT;
  }

  get isGSVOrg() {
    return this.config.type === OrganisationTypes.GSV;
  }

  get isVitaleOrg() {
    return this.config.type === OrganisationTypes.VITALE;
  }

  get isHanOrg() {
    return this.config.type === OrganisationTypes.HAN;
  }

  canEditUserRoles(entityId = this.rootEntityId) {
    return this.isAdmin(entityId);
  }

  canSeeGroup(groupId) {
    return (
      this.isAdmin(groupId) || this.isSubAdmin(groupId) // || this.isTester(groupId)
    );
  }

  canEditGroups(entityId = this.rootEntityId) {
    return (
      this.isAdmin(entityId) || this.isSubAdmin(entityId) // || this.isTester(entityId)
    );
  }

  canRemoveGroups(entityId = this.rootEntityId) {
    return this.isAdmin(entityId) || this.isSubAdmin(entityId);
  }

  canAddTestSets(entityId = this.rootEntityId) {
    return (
      (this.isHylyghtOrg && this.isAdmin(entityId)) ||
      (!this.isHylyghtOrg && this.config.testSetsEnabled === true)
    );
  }

  canEditTestSets(entityId = this.rootEntityId) {
    return this.isAdmin(entityId) || this.isSubAdmin(entityId);
  }

  canRemoveExercises(entityId = this.rootEntityId) {
    return this.isAdmin(entityId) || this.isSubAdmin(entityId);
  }

  canAddPrograms(entityId = this.rootEntityId) {
    return this.isAdmin(entityId) || this.isSubAdmin(entityId);
  }

  canRemovePersonInjury(entityId = this.rootEntityId) {
    return this.isAdmin(entityId) || this.isSubAdmin(entityId);
  }
  canRemoveScreenings(entityId = this.rootEntityId) {
    return this.isAdmin(entityId) || this.isSubAdmin(entityId);
  }

  setAbilities = () => {
    const rules = [];

    /*if (this.superadmin) {
        rules.push({
          subject: 'all',
          action: 'manage'
        });
      }*/
    // Disable scanners for everyone
    /*rules.push({
      inverted: true,
      subject: 'Scanners',
      action: 'manage'
    });*/

    if (this.isGSVOrg) {
      const gsvRulesSet = gsvRules;

      if (this.isAthlete()) {
        gsvRulesSet.push(...gsvAthleteRules);
      }

      if (this.isSubAdmin()) {
        gsvRulesSet.push({
          subject: 'Athletes',
          action: 'manage',
          inverted: !!this.entityTree.parentId
        });

        gsvRulesSet.push({
          subject: 'Settings',
          action: 'manage',
          inverted: true
        });
      }
      if (this.isAdmin()) {
        gsvRulesSet.push({
          subject: 'Athletes',
          action: 'manage',
          inverted: !!this.entityTree.parentId
        });

        gsvRulesSet.push({
          subject: 'Settings',
          action: 'manage'
        });

        gsvRulesSet.push({
          subject: 'Groups',
          action: 'manage'
        });
      }

      if (
        this?.activeEntity?.entity?.apiKeys?.length > 0 &&
        (this.isAdmin() || this.superadmin)
      ) {
        gsvRulesSet.push({
          subject: 'Trainings',
          action: 'embed'
        });
      } else {
        gsvRulesSet.push({
          subject: 'Trainings',
          action: 'embed',
          inverted: true
        });
      }

      rules.push(...gsvRulesSet);
    } else if (this.isVitaleOrg) {
      const vitaleRulesSet = VitaleRules;
      if (this.isAthlete()) {
        const vitaleRules = VitaleAthleteRules;
        vitaleRules.push({
          subject: 'Video',
          action: 'read',
          conditions: {
            'person.id': this.id
          }
        });
        vitaleRules.push({
          subject: 'Video',
          action: 'read',
          conditions: {
            'shares.sharedWith': { $elemMatch: { id: this.id } }
          }
        });
        vitaleRulesSet.push(...vitaleRules);
      }
      if (this.isSubAdmin()) {
        const vitaleRules = VitaleSubAdminRules;
        vitaleRules.push({
          subject: 'Video',
          action: 'read',
          conditions: {
            'shares.sharedWith': { $elemMatch: { id: this.id } }
          }
        });
        vitaleRulesSet.push(...vitaleRules);
      }
      if (this.isAdmin()) {
        vitaleRulesSet.push(...VitaleAdminRules);
      }
      rules.push(...vitaleRulesSet);
    } else {
      console.log(this?.activeEntity);
      const featureFlags = this.activeEntity.entity?.featureFlags
        ? JSON.parse(this.activeEntity.entity.featureFlags)
        : defaultOrgFeatures;

      let menu;
      /*
        if (config?.menu) {
          menu = config?.menu
            ?.filter(item => item !== 'packages')
            .map(item => {
              switch (item) {
                // replace legacy sporters with athletes
                case 'sporters':
                  return 'athletes';
                // replace legacy benchmarks with results
                case 'benchmarks':
                  return 'results';
                // replace legacy benchmarks with results
                case 'growthprediction':
                  return 'growthtracker';
                default:
                  return item;
              }
            });
        }*/
      if (featureFlags) {
        menu = Object.keys(featureFlags)
          .map(ff => (featureFlags[ff] ? ff : null))
          .filter(Boolean);

        if (menu.includes('sessions')) {
          this.testSetTypes.push(TestSetV2Type.TALENT);
        }
        if (menu.includes('rehab')) {
          this.testSetTypes.push(TestSetV2Type.REHAB);
        }
        if (menu.includes('prevention')) {
          this.testSetTypes.push(TestSetV2Type.PREVENTION);
        }
      }

      if (this.isAdmin() || this.isSubAdmin()) {
        // Give ability from the menu items
        menu.forEach(item => {
          if (
            item === 'athletes' ||
            item === 'organisations' ||
            item === 'users'
          ) {
            rules.push({
              subject: capitalize(item),
              action: this.isSubAdmin() ? 'read' : 'manage'
            });
          } else {
            rules.push({
              subject: capitalize(item),
              action: 'manage'
            });
          }
        });
      } else if (this.isAthlete()) {
        menu.forEach(item => {
          if (athleteFeatures.includes(item)) {
            rules.push({
              subject: capitalize(item),
              action: 'read'
            });
          }
        });
      }

      if (this.isSubAdmin()) {
        if (rules.find(rule => rule.subject === 'Benchmarks')) {
          rules.push({
            subject: 'Benchmarks',
            action: 'read'
          });
        }
        if (rules.find(rule => rule.subject === 'Organisations')) {
          rules.push({
            subject: 'Organisations',
            action: 'read'
          });
        }
      }

      if (this.isAdmin()) {
        if (rules.find(rule => rule.subject === 'Benchmarks')) {
          rules.push({
            subject: 'Benchmarks',
            action: 'manage'
          });
        }
        if (rules.find(rule => rule.subject === 'Users')) {
          rules.push({
            subject: 'Users',
            action: 'manage'
          });
        }

        if (rules.find(rule => rule.subject === 'Organisations')) {
          rules.push({
            subject: 'Organisations',
            action: 'manage'
          });
        }
      }

      if (this.isAdmin() || this.isSubAdmin()) {
        if (rules.find(rule => rule.subject === 'Prevention')) {
          rules.push({
            subject: 'Prevention',
            action: 'manage'
          });
        }
        if (rules.find(rule => rule.subject === 'Rehab')) {
          rules.push({
            subject: 'Rehab',
            action: 'manage'
          });
        }
        if (rules.find(rule => rule.subject === 'Athletes')) {
          rules.push({
            subject: 'Athletes',
            action: 'manage'
          });
          rules.push({
            subject: 'Groups',
            action: 'manage'
          });
        }

        if (rules.find(rule => rule.subject === 'PersonInvite')) {
          rules.push({
            subject: 'Athletes',
            action: 'invite'
          });
        } else {
          rules.push({
            subject: 'Athletes',
            action: 'invite',
            inverted: true
          });
        }

        if (rules.find(rule => rule.subject === 'Exercises')) {
          rules.push(
            ...[
              {
                subject: 'Exercise',
                action: 'create'
              },
              {
                subject: 'Exercise',
                action: 'update',
                conditions: { editable: true }
              },
              {
                subject: 'Exercise',
                action: 'delete',
                conditions: { editable: true }
              },
              {
                subject: 'Template',
                action: 'create'
              },
              {
                subject: 'Template',
                action: 'update',
                conditions: { editable: true }
              },
              {
                subject: 'Template',
                action: 'delete',
                conditions: { editable: true }
              },
              {
                subject: 'Programs',
                action: 'manage'
              }
            ]
          );
        }
        if (rules.find(rule => rule.subject === 'Documents')) {
          rules.push({
            subject: 'Documents',
            action: 'manage'
          });
        }
        rules.push({
          subject: 'Organisation',
          action: 'read'
        });

        rules.push({
          subject: 'Settings',
          action: 'manage'
        });
      }
    }

    if (this.superadmin) {
      rules.push({
        subject: 'Organisation',
        action: 'manage'
      });
      rules.push({
        subject: 'Organisations',
        action: 'manage'
      });
    }

    return rules;
  };

  initIntercom = () => {
    if (
      typeof window.Intercom === 'function' &&
      process.env.REACT_APP_INTERCOM_ENABLED === 'true'
    ) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        custom_launcher_selector: '#intercom-launcher',
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        name: this.fullName,
        user_id: this.id,
        email: this.email,
        user_hash: this.hmac,
        created_at: Math.round(this.dateCreated / 1000),
        birthdate: this.birthdate,
        language_override: this.baseLanguage,
        root_entity: this.rootEntityId,
        company: {
          id: this.entityTree?.id,
          name: this.entityTree?.name,
          created_at: this.entityTree?.date_created
            ? Math.round(this.entityTree.date_created / 1000)
            : null
        },
        role: this.hasAdminRole() ? 'admin' : 'athlete'
      });
    }
  };
}
