import React from 'react';
import classNames from 'classnames';
import Icon from '../icon/Icon';
import PropTypes from 'prop-types';
import { MenuItem, Menu, MenuBody } from '../menu';
import Downshift from 'downshift';
import { ErrorMessage, getIn } from 'formik';
import InputErrorMessage from 'components/input/InputErrorMessage';

const Select = props => {
  const {
    extraClassNames,
    readOnly,
    disabled,
    inline,
    standalone,
    label,
    id,
    name,
    onChange,
    value,
    children,
    placeholder,
    iconLeft,
    style,
    isLoading = false,
    type = 'button',
    errors,
    touched
  } = props;
  return (
    <div
      className={classNames(
        'c-input__group c-input__group-select',
        extraClassNames,
        {
          'c-input--read-only c-input--disabled': readOnly || disabled,
          'c-input--inline': inline,
          'c-input__group-select-standalone': standalone
        }
      )}
    >
      {label && (
        <label htmlFor={id} className={classNames('c-input__label', {})}>
          {label}
        </label>
      )}
      <Downshift
        onSelect={onChange}
        itemToString={item => (item ? item.label : '')}
        selectedItem={
          value !== undefined ? getSelecteditem(children, value) : null
        }
      >
        {({
          isOpen,
          getInputProps,
          getMenuProps,
          toggleMenu,
          selectedItem,
          getItemProps
        }) => {
          /* const hasSubItems =
            selectedItem && selectedItem.value.search(SEPARATOR) !== -1;
          let parentTitle = props.labelGroup;
          let title = selectedItem ? selectedItem.label : '';
          if (hasSubItems) {
            const ids = selectedItem.value.split(SEPARATOR);
            const parentId = ids[0];
            const childId = ids[1];
            const parentItem = props.items.find(i => i.id === parentId);
            parentTitle = parentItem.label;
            const childItem = parentItem.subItems.find(i => i.id === childId);
            title = childItem.label;
          } */

          let title = selectedItem ? selectedItem.label : placeholder ?? '';

          return (
            <div
              className={classNames('c-input__wrapper c-menu__wrapper', {
                'c-input__wrapper--select': !readOnly && !disabled
              })}
            >
              {props.labelGroup && selectedItem && (
                <div className={classNames('c-input__sup-value')}>
                  {props.labelGroup}
                </div>
              )}
              <input
                {...getInputProps()}
                onClick={e => {
                  e.stopPropagation();
                  toggleMenu();
                }}
                placeholder={placeholder}
                value={title}
                type={type}
                title={title}
                readOnly={readOnly || disabled}
                disabled={readOnly || disabled}
                className={classNames('c-input c-input--select', {
                  'c-input--select-placeholder': !selectedItem || isLoading,
                  'has-error':
                    errors?.[name] &&
                    touched?.[name] &&
                    typeof errors[name] === 'string'
                })}
              />

              {!readOnly && !disabled && (
                <Icon
                  id="dropdown-open"
                  extraClassNames={classNames('c-icon--input', {
                    'c-icon--input-left': iconLeft
                  })}
                />
              )}

              {(!readOnly || !disabled) && isOpen && (
                <Menu style={style} {...getMenuProps({ isOpen })}>
                  <MenuBody onClick={e => e.stopPropagation()}>
                    {renderChildren(children, getItemProps)}
                  </MenuBody>
                </Menu>
              )}
            </div>
          );
        }}
      </Downshift>

      {errors?.[name] &&
        touched?.[name] &&
        typeof errors[name] === 'string' && (
          <ErrorMessage name={name} component={InputErrorMessage} />
        )}

      {errors?.[name] &&
        touched?.[name] &&
        typeof errors[name] !== 'string' &&
        getIn(errors, name) && (
          <InputErrorMessage>{getIn(errors, name)}</InputErrorMessage>
        )}
    </div>
  );
};

Select.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  errors: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired,
    PropTypes.string.isRequired
  ]),
  touched: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired,
    PropTypes.string.isRequired
  ])
};

export default Select;

export const Option = ({ label, value, onClick }) => (
  <MenuItem onClick={onClick} value={value}>
    {label}
  </MenuItem>
);

Option.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ])
};

const renderChildren = (children, getItemProps) => {
  return React.Children.map(children, child => {
    if (child) {
      const childProps = {
        ...getItemProps({ item: child.props })
      };
      return React.cloneElement(child, childProps);
    }
    return null;
  });
};

const getSelecteditem = (children, selectedValue) => {
  const childs = React.Children.map(children, child => child);
  const found = childs.find(child => {
    if (child && child.props) {
      if (
        child.props.value !== undefined &&
        child.props.value === selectedValue
      ) {
        return true;
      }
    }
    return false;
  });
  if (found) return { value: found.props.value, label: found.props.label };
  return null;
};
