import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Card, CardBody, CardFooter } from 'components/card';
import { ROUTE_LOGIN } from 'routes/RouteList';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';

class PasswordResetSuccess extends React.Component {
  handeGoToLogin = e => {
    e.preventDefault();
    const {
      authStore,
      routing: { push }
    } = this.props;

    authStore.setPasswordResetEmail('');
    push(ROUTE_LOGIN);
  };

  render() {
    const {
      authStore: { passwordResetEmail }
    } = this.props;
    return (
      <Card extraClassNames="c-card--primary">
        <CardHeader primary>
          <CardHeaderTitle>
            <FormattedMessage {...messages.loginForgotPasswordTitle} />
          </CardHeaderTitle>
          <CardHeaderSubtitle extraClassNames="u-margin-horizontal-auto">
            <FormattedMessage {...messages.loginForgotPasswordSubTitle} />
          </CardHeaderSubtitle>
        </CardHeader>
        <CardBody primary empty>
          <Icon id="big-send" xHugeIcon />
        </CardBody>
        <CardFooter primary>
          <p>
            <FormattedMessage
              {...messages.loginForgotPasswordSendEmail}
              values={{ email: passwordResetEmail }}
            />
          </p>
        </CardFooter>
      </Card>
    );
  }
}

export default withRouter(
  injectIntl(inject('routing', 'authStore')(observer(PasswordResetSuccess)))
);
