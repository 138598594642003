import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper,
  ListItemStatus
} from 'components/list/list-item';
import { Button, ButtonsGroup } from 'components/button';
import VideoTooltip from 'components/tooltip/VideoTooltip';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import PropTypes from 'prop-types';
import PreviewButton from '../buttons/PreviewButton';

const TestListItem = ({
  uid,
  test,
  edit = false,
  category = false,
  checkbox,
  active,
  onChange,
  checkable,
  onClick,
  clickable,
  checked,
  disabled,
  innerRef,
  extraClassNames,
  dependentEditable,
  ...props
}) => (
  <ListItem
    active={active}
    checkbox={checkbox}
    onClick={onClick}
    disabled={test.isDependency}
    readOnly={!edit}
    extraClassNames={classNames(extraClassNames, {
      'c-list__item-done': test.status === 'done',
      'c-list__item-in-progress': test.status === 'inProgress'
    })}
    group={disabled}
    clickable={clickable}
    ref={innerRef}
    {...props}
  >
    <ListItemStatus
      itemId={uid}
      checkbox={checkbox}
      visible={(!test.isDependency || dependentEditable) && !!edit}
      checked={checked}
      onChange={edit && onChange}
      statusIcon={
        (test.isDependency || !edit) && checked
          ? 'yes-white'
          : (test.isDependency || !edit) && !checked
          ? 'no-white'
          : 'sets'
      }
      readOnly={disabled}
      disabled={test.isDependency && !dependentEditable}
    />
    <ListItemLabelWrapper>
      <ListItemLabel
        labelNotePreview
        supertitle={category && test.category}
        style={
          !category && {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            alignSelf: 'center'
          }
        }
      >
        {test.title}
      </ListItemLabel>

      <div className="c-list__item-small-editing-area c-list__item-wrapping-note-preview">
        <p>
          <FormattedMessage
            {...messages.labelUnit}
            values={{
              count: test?.duration,
              unit: 'mins'
            }}
          />
        </p>

        <ButtonsGroup verticalCenter>
          <Button tiny onClick={onClick}>
            <FormattedMessage {...messages.buttonView} />
          </Button>
        </ButtonsGroup>
      </div>
    </ListItemLabelWrapper>
  </ListItem>
);

export { TestListItem };

TestListItem.propTypes = {
  uid: PropTypes.string.isRequired
};
