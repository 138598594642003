import { createContext, useEffect, useContext, useReducer } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import TestSet from '../models/TestSet';
import {
  MUTATION_ADD_TESTSET,
  MUTATION_COPY_TESTSET,
  MUTATION_EDIT_TESTSET,
  QUERY_GET_TESTSET,
  QUERY_GET_TESTSETS
} from 'services/aws/testsets-query';
import messages from 'messages';
import { useNotificationQueue } from 'components/notification';
import { useIntl } from 'react-intl';
import { QUERY_GET_ENTITY_BY_ID } from 'services/aws/entity-query';
import { useTestsContext } from 'contexts/TestsContext';

export const TestSetContext = createContext({});

const initialState = {
  filter: [],
  testSet: null,
  testSets: undefined
};

// Actions
export const SET_TESTSETS = 'SET_TESTSETS';
export const SET_TESTSET = 'SET_TESTSET';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_TESTSETS:
      return { ...state, testSets: [...action.payload] };
    case SET_TESTSET:
      return { ...state, testSet: { ...action.payload } };
    default:
      return state;
  }
};

const TestSetContextProvider = ({ entityId, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { actions: testsContextActions } = useTestsContext();
  const { type } = useParams();
  const intl = useIntl();
  const notification = useNotificationQueue();

  useEffect(() => {
    if (testsContextActions) {
      testsContextActions.getTests(type);
    }
  }, [testsContextActions, type]);

  const [
    getTestSetsQuery,
    {
      loading: loadingTestSets,
      error: errorTestSets,
      data: dataTestSets,
      refetch
    }
  ] = useLazyQuery(QUERY_GET_TESTSETS, { fetchPolicy: 'network-only' });

  useEffect(() => {
    if (dataTestSets?.getTestSetsV2) {
      dispatch({
        type: SET_TESTSETS,
        payload: dataTestSets.getTestSetsV2
          .filter(ts => ts.testSetVersion === 2)
          .map(ts => new TestSet(ts))
      });
    }
  }, [dataTestSets]);

  const [
    getTestSetQuery,
    { loading: loadingTestSet, error: errorTestSet, data: dataTestSet }
  ] = useLazyQuery(QUERY_GET_TESTSET, { fetchPolicy: 'network-only' });

  useEffect(() => {
    if (dataTestSet?.getTestSetV2) {
      const testSet = new TestSet(dataTestSet.getTestSetV2);
      testSet.tests = !testSet.tests ? [] : testSet.tests;

      dispatch({ type: SET_TESTSET, payload: testSet });
    } else {
      dispatch({ type: SET_TESTSET, payload: null });
    }
  }, [dataTestSet]);

  const [editTestSet] = useMutation(MUTATION_EDIT_TESTSET, {
    refetchQueries: [
      {
        query: QUERY_GET_ENTITY_BY_ID,
        variables: {
          id: entityId
        }
      }
    ],
    onCompleted: async data => {
      notification.add(data.editTestSetV2.id, {
        message: intl.formatMessage(messages.testSetsTestsSaveSuccess)
      });
      await refetch();
    },
    onError: () => {
      notification.add(`testSetDataError`, {
        message: intl.formatMessage(messages.testSetsTestsSaveError),
        level: 'error'
      });
    }
  });

  const [addTestSet] = useMutation(MUTATION_ADD_TESTSET, {
    refetchQueries: [
      {
        query: QUERY_GET_ENTITY_BY_ID,
        variables: {
          id: entityId
        }
      }
    ],
    onCompleted: async data => {
      notification.add(data.addTestSetV2.id, {
        message: intl.formatMessage(messages.testSetsTestsSaveSuccess)
      });
      await refetch();
    }
  });

  const [copyTestSet] = useMutation(MUTATION_COPY_TESTSET, {
    refetchQueries: [
      {
        query: QUERY_GET_ENTITY_BY_ID,
        variables: {
          id: entityId
        }
      }
    ],
    onCompleted: async data => {
      await refetch();
      notification.add(data.addTestSetV2.id, {
        message: intl.formatMessage(messages.testSetsTestsSaveSuccess)
      });
    }
  });

  const actions = {
    getTestSets: types => {
      dispatch({
        type: SET_TESTSETS,
        payload: []
      });
      getTestSetsQuery({
        variables: { entityId, types }
      });
    },
    getTestSet: testSetId => {
      getTestSetQuery({
        variables: { entityId, testSetId }
      });
    },
    addTestSet: async testSet => {
      return await addTestSet({
        variables: { ...testSet }
      }).then(({ data }) => {
        return data.addTestSetV2.id;
      });
    },
    editTestSet: async testSet => {
      return await editTestSet({
        variables: { ...testSet },
        refetchQueries: [
          {
            query: QUERY_GET_TESTSETS,
            variables: {
              entityId: entityId,
              types: [testSet.type]
            }
          },
          {
            query: QUERY_GET_TESTSET,
            variables: {
              testSetId: testSet.id,
              entityId: entityId
            }
          },
          {
            query: QUERY_GET_ENTITY_BY_ID,
            variables: {
              id: entityId
            }
          }
        ]
      }).then(({ data }) => {
        return data.editTestSetV2.id;
      });
    },
    copyTestSet: async testSet => {
      return await copyTestSet({
        variables: { ...testSet },
        refetchQueries: [
          {
            query: QUERY_GET_TESTSETS,
            variables: {
              entityId: entityId,
              types: [testSet.type]
            }
          },
          {
            query: QUERY_GET_ENTITY_BY_ID,
            variables: {
              id: entityId
            }
          }
        ]
      }).then(({ data }) => {
        actions.getTestSet(data.addTestSetV2.id);
        return data.addTestSetV2.id;
      });
    },
    findExistingTitle: title => {
      return state.testSets
        .map(b => b.title.toLowerCase())
        .some(b => b === title.toLowerCase());
    }
  };

  return (
    <TestSetContext.Provider
      value={{
        actions,
        state,
        loading: loadingTestSets || loadingTestSet,
        error: errorTestSets || errorTestSet
      }}
    >
      {children}
    </TestSetContext.Provider>
  );
};

function useTestSetContext() {
  const context = useContext(TestSetContext);
  if (context === undefined) {
    throw new Error(
      'The TestSetContext hook must be used within a TestSetContext.Provider'
    );
  }
  return context;
}

export { TestSetContextProvider, useTestSetContext };
