import React, { Fragment } from 'react';
import { useSessionContext } from 'contexts/SessionContext';
import { CardBody } from 'components/card';
import List from 'components/list/List';
import ListHeader from 'components/list/ListHeader';
import ListColumnTitle from 'components/list/ListColumnTitle';
import ListBody from 'components/list/ListBody';
import TestItemListItem from 'containers/partials/list-items/TestItemListItem';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { usePreviewModalContext } from 'contexts/PreviewModalContext';
import { useTestDataContext } from 'contexts/TestDataContext';

function TestItemResultsList({ entityId, personInjury }) {
  const { session } = useSessionContext();
  const { groupedTests, testDataId } = useTestDataContext();
  const { setModalData } = usePreviewModalContext();

  if (Object.keys(groupedTests).length > 0) {
    return (
      <CardBody secondary>
        {Object.keys(groupedTests).map(category => (
          <Fragment key={category}>
            {groupedTests[category].length > 0 &&
              groupedTests[category].some(
                test => test.testItems.length > 0 // Hide tests without testitems
              ) &&
              groupedTests[category].some(
                test => test.testItems.some(ti => ti.input_type !== 'derived') // Hide test categories with only derived items
              ) && (
                <List
                  preview
                  style={{
                    minWidth: '0',
                    zIndex: 'auto'
                  }}
                  threeColsAndDropdown
                >
                  <ListHeader oneTitle>
                    <ListColumnTitle>{category}</ListColumnTitle>
                  </ListHeader>
                  <ListBody>
                    {groupedTests[category].map((test, index, array) => {
                      const testItems = [
                        ...test.testItems.filter(
                          testItem => testItem.input_type !== 'derived'
                        )
                      ];
                      if (!testItems.length) return null;
                      return (
                        <TestItemListItem
                          entityId={entityId}
                          testDataId={testDataId}
                          key={`${test.id}`}
                          edit={false}
                          test={test}
                          testItems={testItems}
                          version={session.version}
                          sides={personInjury ? personInjury.sides : []}
                          // Grouped is a list Item with SubList and Items within
                          grouped={testItems.length > 1}
                          // Collapsible work only when grouped={true}
                          collapsible={false}
                          onClick={() => {
                            setModalData({
                              type: 'testItem',
                              data: test
                            });
                          }}
                          indexListItem={array.length - index}
                        />
                      );
                    })}
                  </ListBody>
                </List>
              )}
          </Fragment>
        ))}
      </CardBody>
    );
  }

  return (
    <CardBody empty>
      <p
        className={
          'o-flex o-flex--column o-flex--align-center u-margin-bottom-none'
        }
      >
        <FormattedMessage {...messages.cardLabelNoTests} />
      </p>
    </CardBody>
  );
}

export default TestItemResultsList;
