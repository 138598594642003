import { FormattedMessage, useIntl } from 'react-intl';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import Modal from 'components/modal/Modal';
import messages from 'messages';
import OrganisationForm from 'containers/pages/settings/organisations/OrganisationForm';
import OrganisationEntity from 'models/Organisation';
import { CardBody, CardFooter } from 'components/card';
import FieldInput from 'components/input/FieldInput';
import { Button, ButtonsGroup } from 'components/button';
import { useContext } from 'react';
import { StoreContext } from 'index';
import { defaultOrgFeatures } from 'rules';

function AddOrganisationModal({ onClose }) {
  const intl = useIntl();
  const {
    authStore: { user }
  } = useContext(StoreContext);

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal card autoHeight isOpen={true} onClose={onCloseHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.organisationsAddTitle} />
        </CardHeaderTitle>
      </CardHeader>
      <OrganisationForm
        organisation={
          new OrganisationEntity({
            featureFlags:
              user.activeEntity.entity?.featureFlags || defaultOrgFeatures
          })
        }
        onComplete={async () => {
          onClose && onClose();
        }}
        allowFeatureEdit={true}
      >
        {({ isValid, errors, touched, isSubmitting }) => (
          <>
            <CardBody modalWithOverflow>
              <FieldInput
                id="name"
                name="name"
                placeholder={intl.formatMessage(
                  messages.organisationsNameLabel
                )}
                errors={errors}
                touched={touched}
                required
              >
                <FormattedMessage {...messages.organisationsNameLabel} />
              </FieldInput>
            </CardBody>
            <CardFooter modal extraClassNames="c-card__footer--modal-larger">
              <ButtonsGroup>
                <Button secondary onClick={() => onCloseHandler()}>
                  <FormattedMessage {...messages.buttonCancel} />
                </Button>
                <Button
                  type="submit"
                  primary
                  disabled={!isValid || isSubmitting}
                >
                  <FormattedMessage {...messages.addButton} />
                </Button>
              </ButtonsGroup>
            </CardFooter>
          </>
        )}
      </OrganisationForm>
    </Modal>
  );
}

export default AddOrganisationModal;
