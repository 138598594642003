import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import {
  ROUTE_SCANNERS,
  ROUTE_SCANNERS_ENTITY_SCANNER_EDIT,
  ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS,
  ROUTE_SCANNERS_ENTITY_SCANNER,
  ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_ADD,
  ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION,
  ROUTE_SCANNERS_ENTITY,
  ROUTE_SCANNERS_ENTITY_SCANNER_DIAGRAM
} from 'routes/RouteList';

import Panel from 'components/panel/Panel';
import PanelHeader from 'components/panel/PanelHeader';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelSubtitle from 'components/panel/panel-title/PanelSubtitle';
import PanelBody from 'components/panel/PanelBody';

import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';

import messages from 'messages';

import Icon from 'components/icon/Icon';
import Button from 'components/button/Button';
import Input from 'components/input/Input';

import { Card, CardFooter, CardBody } from 'components/card';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderButtons
} from 'components/card/card-header';

import Badge from 'components/badge/Badge';

import { Tabs, Tab, TabList, TabPanel } from 'components/tabs';
import { PanelTools } from 'components/panel';
import { PanelTitleButton } from 'components/panel/panel-title';
import { MenuWrapper } from 'components/menu';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import RemoveScannerButton from 'containers/partials/buttons/RemoveScannerButton';
import { CalibrationListItem } from 'containers/partials/list-items/CalibrationListItem';
import {
  QUERY_GET_SCANNER_BY_ID,
  QUERY_GET_SCANNERS_BY_ENTITY_ID,
  MUTATION_UPDATE_SCANNER
} from 'services/aws/scanner-query';
import { Query, Mutation } from '@apollo/client/react/components';
import ScannerModel from 'models/Scanner';
import {
  sortCalibrationsOnDateAndName,
  sort,
  SORT_DATA_TYPES
} from 'utils/sort';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import LineChart from 'containers/partials/charts/LineChart';
import { getLocalDateFormat } from 'utils/date';
import Loader from 'components/loader/Loader';
import FieldInput from 'components/input/FieldInput';

const getMinMaxOfData = chartData => {
  let max = 0;
  let min = Number.MAX_VALUE;
  chartData.forEach(item =>
    item.data.forEach(dataItem => {
      if (dataItem.y > max) {
        max = dataItem.y;
      }
      if (dataItem.y < min) {
        min = dataItem.y;
      }
    })
  );

  return { min, max };
};

class Scanner extends React.Component {
  scanner;
  state = {
    name: '',
    type: '',
    vendor: '',
    coil: '',
    location: ''
  };

  onSave = async mutation => {
    const { scannerId } = this.props;
    const { name, type, vendor, location, coil } = this.state;
    await mutation({
      variables: {
        id: scannerId,
        name,
        type,
        vendor,
        coil,
        location
      }
    });
  };

  getChartData = calis => {
    const { intl } = this.props;
    const chartData = [
      {
        id: intl.formatMessage(messages.calibrationCarnosineLabel),
        color: 'hsl(282, 70%, 50%)',
        data: []
      },
      {
        id: intl.formatMessage(messages.calibrationCreatineLabel),
        color: 'hsl(158, 70%, 50%)',
        data: []
      },
      {
        id: intl.formatMessage(messages.calibrationTmaLabel),
        color: 'hsl(238, 70%, 50%)',
        data: []
      }
    ];
    const calibrations = sort(calis, {
      key: 'date',
      dataType: SORT_DATA_TYPES.DATE_STRING
    });
    calibrations.forEach(calibration => {
      if (calibration.meta) {
        try {
          const meta = JSON.parse(calibration.meta);
          if (
            meta.tma !== undefined &&
            meta.carnosine !== undefined &&
            meta.creatine !== undefined
          ) {
            const label = `${calibration.name} - ${getLocalDateFormat(
              calibration.date
            )}`;
            chartData[0].data.push({
              x: label,
              y: meta.carnosine
            });
            chartData[1].data.push({
              x: label,
              y: meta.creatine
            });
            chartData[2].data.push({
              x: label,
              y: meta.tma
            });
          }
        } catch (er) {}
      }
    });
    return chartData;
  };

  onCompleted = data => {
    if (data && data.getMtsScanner) {
      const scanner = data.getMtsScanner;
      const chartData =
        scanner.calibrations.length > 0 &&
        scanner.calibrations.find(c => c.meta)
          ? this.getChartData(scanner.calibrations)
          : null;

      let min = 0;
      let max = 50;
      if (chartData) {
        const data = getMinMaxOfData(chartData);
        min = data.min;
        max = data.max;
      }
      this.setState({
        name: scanner.name,
        type: scanner.type,
        vendor: scanner.vendor,
        coil: scanner.coil,
        location: scanner.location,
        chartData: { data: chartData, min, max, curve: 'linear' }
      });
    }
  };

  onEdit = () => {
    const {
      entityId,
      scannerId,
      routing: { push }
    } = this.props;
    push(
      `${ROUTE_SCANNERS_ENTITY_SCANNER_EDIT.replace(
        ':entityId',
        entityId
      ).replace(':scannerId', scannerId)}`
    );
  };

  onReset = () => {
    this.setState({
      name: this.scanner.name,
      type: this.scanner.type,
      vendor: this.scanner.vendor,
      coil: this.scanner.coil,
      location: this.scanner.location
    });

    const {
      routing: { push },
      entityId,
      scannerId
    } = this.props;
    push(
      `${ROUTE_SCANNERS_ENTITY_SCANNER.replace(':entityId', entityId).replace(
        ':scannerId',
        scannerId
      )}`
    );
  };

  onChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  render() {
    const {
      intl,
      entityId,
      scannerId,
      calibrationId,
      tabIndex,
      edit,
      routing: { push }
    } = this.props;

    const { chartData } = this.state;
    return (
      <Panel>
        <Query
          query={QUERY_GET_SCANNER_BY_ID}
          fetchPolicy="cache-and-network"
          variables={{ scannerId, entityId }}
          onCompleted={this.onCompleted}
        >
          {result => {
            const { loading, error, data } = result;
            if (loading) return <Loader />;
            if (error) return <RetryPanel />;
            this.scanner = new ScannerModel(data.getMtsScanner);
            const calibrations = sortCalibrationsOnDateAndName(
              this.scanner.calibrations
            );
            return (
              <Fragment>
                <PanelHeader>
                  <Breadcrumbs>
                    <Breadcrumb
                      onClick={() =>
                        push(ROUTE_SCANNERS.replace(':entityId', entityId))
                      }
                    >
                      <FormattedMessage {...messages.breadcrumbScanners} />
                    </Breadcrumb>
                  </Breadcrumbs>
                  <PanelTitleWrapper>
                    <PanelTitle>
                      {this.scanner.name}
                      <MenuWrapper trigger={<PanelTitleButton />}>
                        <RemoveScannerButton
                          menu
                          entityId={entityId}
                          scannerId={scannerId}
                          onComplete={() =>
                            push(
                              ROUTE_SCANNERS_ENTITY.replace(
                                ':entityId',
                                entityId
                              )
                            )
                          }
                        >
                          <FormattedMessage
                            {...messages.scannerMenuItemRemoveScanner}
                          />
                        </RemoveScannerButton>
                      </MenuWrapper>
                    </PanelTitle>
                    <PanelSubtitle />
                  </PanelTitleWrapper>
                </PanelHeader>
                <PanelBody>
                  <Tabs defaultIndex={tabIndex}>
                    <TabList>
                      <Tab
                        onClick={() =>
                          push(
                            `${ROUTE_SCANNERS_ENTITY_SCANNER.replace(
                              ':scannerId',
                              scannerId
                            ).replace(':entityId', entityId)}`
                          )
                        }
                      >
                        <FormattedMessage {...messages.scannerTabGlobal} />
                      </Tab>
                      <Tab
                        onClick={() =>
                          push(
                            `${ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS.replace(
                              ':scannerId',
                              scannerId
                            ).replace(':entityId', entityId)}`
                          )
                        }
                      >
                        <Badge>{calibrations.length}</Badge>
                        <FormattedMessage
                          {...messages.scannerTabCalibrations}
                        />
                      </Tab>
                      {calibrations.length > 0 &&
                        calibrations.find(c => c.meta) && (
                          <Tab
                            onClick={() =>
                              push(
                                `${ROUTE_SCANNERS_ENTITY_SCANNER_DIAGRAM.replace(
                                  ':scannerId',
                                  scannerId
                                ).replace(':entityId', entityId)}`
                              )
                            }
                          >
                            <FormattedMessage {...messages.scannerTabDiagram} />
                          </Tab>
                        )}
                    </TabList>
                    <TabPanel>
                      <Mutation
                        mutation={MUTATION_UPDATE_SCANNER}
                        refetchQueries={() => {
                          return [
                            {
                              query: QUERY_GET_SCANNER_BY_ID,
                              variables: { entityId, scannerId }
                            },
                            {
                              query: QUERY_GET_SCANNERS_BY_ENTITY_ID,
                              variables: { entityId }
                            }
                          ];
                        }}
                        update={(cache, { data: { editMtsScanner } }) => {
                          if (editMtsScanner && editMtsScanner.id) {
                            push(
                              `${ROUTE_SCANNERS_ENTITY_SCANNER.replace(
                                ':entityId',
                                entityId
                              ).replace(':scannerId', scannerId)}`
                            );
                          }
                        }}
                      >
                        {(editMtsScanner, data) => {
                          return (
                            <ScannerForm
                              edit={edit}
                              onSave={() => this.onSave(editMtsScanner)}
                              onReset={this.onReset}
                              onEdit={this.onEdit}
                              onChange={this.onChange}
                              loading={data.loading || loading}
                              {...this.state}
                            />
                          );
                        }}
                      </Mutation>
                    </TabPanel>
                    <TabPanel>
                      <PanelTools tabTools>
                        <Button
                          rounded
                          primary
                          onClick={() => {
                            push(
                              ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_ADD.replace(
                                ':entityId',
                                entityId
                              ).replace(':scannerId', scannerId)
                            );
                          }}
                        >
                          <Icon id="add-white" />
                          <FormattedMessage
                            {...messages.scannerButtonAddCalibration}
                          />
                        </Button>
                      </PanelTools>
                      <List>
                        <ListBody>
                          {calibrations.map(calibration => {
                            return (
                              <CalibrationListItem
                                key={calibration.id}
                                active={calibration.id === calibrationId}
                                onClick={() =>
                                  push(
                                    ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION.replace(
                                      ':entityId',
                                      entityId
                                    )
                                      .replace(':scannerId', scannerId)
                                      .replace(':calibrationId', calibration.id)
                                  )
                                }
                                calibration={calibration}
                              />
                            );
                          })}
                        </ListBody>
                      </List>
                    </TabPanel>

                    {calibrations.length > 0 && calibrations.find(c => c.meta) && (
                      <TabPanel>
                        <CardBody modalSecondary>
                          {chartData && <LineChart chartData={chartData} />}
                        </CardBody>
                      </TabPanel>
                    )}
                  </Tabs>
                </PanelBody>
              </Fragment>
            );
          }}
        </Query>
      </Panel>
    );
  }
}

export default withRouter(
  injectIntl(inject('routing', 'authStore', 'uiState')(Scanner))
);

const ScannerForm = inject('routing')(
  injectIntl(
    ({
      edit,
      loading,
      onChange,
      onSave,
      onReset,
      name,
      type,
      vendor,
      coil,
      location,
      onEdit
    }) => {
      const isValidToSubmit = () => name && type && vendor;
      return (
        <Card secondary>
          <CardHeader secondary>
            <CardHeaderTitle>
              <FormattedMessage {...messages.scannerTitleDetails} />
            </CardHeaderTitle>
            <CardHeaderButtons>
              {edit ? (
                <Button
                  type="submit"
                  tiny
                  disabled={loading || !isValidToSubmit()}
                  onClick={e => {
                    e.preventDefault();
                    onSave();
                  }}
                >
                  <FormattedMessage {...messages.scannerButtonSubmit} />
                </Button>
              ) : (
                <Button
                  tiny
                  onClick={e => {
                    e.preventDefault();
                    onEdit && onEdit();
                  }}
                >
                  <Icon id="edit" />
                </Button>
              )}
            </CardHeaderButtons>
          </CardHeader>
          <FieldInput
            id="name"
            value={name || ''}
            onChange={onChange}
            readOnly={!edit}
            icon={!name ? 'validation-error' : ' '}
          >
            <FormattedMessage {...messages.scannerNameLabel} />*
          </FieldInput>

          <FieldInput
            id="type"
            value={type || ''}
            onChange={onChange}
            readOnly={!edit}
            icon={!type ? 'validation-error' : ' '}
          >
            <FormattedMessage {...messages.scannerTypeLabel} />*
          </FieldInput>
          <FieldInput
            id="vendor"
            value={vendor || ''}
            onChange={onChange}
            readOnly={!edit}
            icon={!vendor ? 'validation-error' : ' '}
          >
            <FormattedMessage {...messages.scannerVendorLabel} />*
          </FieldInput>
          {(edit || coil) && (
            <FieldInput
              id="coil"
              value={coil || ''}
              onChange={onChange}
              readOnly={!edit}
            >
              <FormattedMessage {...messages.scannerCoilTypeLabel} />
            </FieldInput>
          )}
          {(edit || location) && (
            <FieldInput
              id="location"
              value={location || ''}
              onChange={onChange}
              readOnly={!edit}
            >
              <FormattedMessage {...messages.scannerLocationLabel} />
            </FieldInput>
          )}
          <CardFooter secondary>
            {edit && (
              <Button
                secondary
                disabled={loading}
                onClick={e => {
                  e.preventDefault();
                  onReset();
                }}
              >
                <FormattedMessage {...messages.scannerButtonCancel} />
              </Button>
            )}
            {edit && (
              <Button
                type="submit"
                primary
                onClick={e => {
                  e.preventDefault();
                  onSave();
                }}
                disabled={loading || !isValidToSubmit()}
              >
                <FormattedMessage {...messages.scannerButtonSubmit} />
              </Button>
            )}
          </CardFooter>
        </Card>
      );
    }
  )
);
