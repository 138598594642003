import { useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';

const CardBody = props => {
  const {
    primary,
    secondary,
    progressBar,
    benchmarks,
    modal,
    modalSecondary,
    modalWithOverflow,
    bodyWithHeader,
    empty,
    extraClassNames,
    wrapper,
    separatorBottom,
    children,
    remarks,
    resizableHeight,
    style,
    ...rest
  } = props;

  //   TODO: Refactor deze, want het is ook gebruik bij Tabs.js,
  // en het zou wel in verschillende plaatsen gebruik worden.
  // zeker handig als een vaste height nodig is, om binnen
  // een virtualized-list in te steken.
  const resizableContent = useRef();
  useLayoutEffect(() => {
    if (resizableContent.current) {
      const resizableElement = resizableContent.current;
      const tabTopPosition = resizableElement.getBoundingClientRect().top;
      resizableElement.style.maxHeight = `calc(100vh - ${tabTopPosition}px )`;
      resizableElement.style.overflowY = 'hidden';
      resizableElement.style.overflowX = 'hidden';
      resizableElement.style.minHeight = '326px';
    }
  });

  return (
    <div
      className={classNames('c-card__body', extraClassNames, {
        'c-card__body--primary': primary,
        'c-card__body--secondary': secondary,
        'c-card__body--remarks': remarks,
        'c-card__body__progress-bar c-card__body-separator': progressBar,
        'c-card__body--primary c-card__body--modal': modal,
        'c-card__body--secondary c-card__body--modal': modalSecondary,
        'c-card__body--primary c-card__body--modal c-card__body--modal-with-overflow ':
          modalWithOverflow,
        'c-card__body--empty': empty,
        'c-card__body--benchmarks': benchmarks,
        'c-card__body--with-header': bodyWithHeader,
        'c-card__body-separator': separatorBottom
      })}
      style={style}
      ref={resizableHeight && resizableContent}
      {...rest}
    >
      {children}
    </div>
  );
};

export default CardBody;
