import React, { useContext } from 'react';
import { withRouter, generatePath } from 'react-router-dom';
import { ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS } from 'routes/RouteList';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import { MUTATION_ARCHIVE_SCREENING } from 'services/aws/screenings-query';
import { QUERY_GET_PERSON_MEDICALS } from 'services/aws/injury-query';
import { StoreContext } from 'index';
import { usePersonContext } from 'contexts/PersonContext';
import { useMedicalContext } from 'contexts/MedicalContext';

const RemoveScreeningButton = ({
  entityId,
  personInjuryId,
  testSessionId,
  personId,
  children,
  ...props
}) => {
  const {
    authStore: {
      user: { rootEntityId }
    },
    routing: { push }
  } = useContext(StoreContext);
  const { person } = usePersonContext();
  const { medical } = useMedicalContext();

  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_ARCHIVE_SCREENING}
      confirmTitle={messages.modalConfirmRemoveScreeningTitle}
      confirmMessage={messages.modalConfirmRemoveScreeningMessage}
      variables={{
        personInjuryId,
        testSessionId
      }}
      refetchQueries={[
        {
          query: QUERY_GET_PERSON_MEDICALS,
          variables: { entityId: rootEntityId, personId }
        }
      ]}
      update={(cache, { data: { archiveScreening } }) => {
        if (archiveScreening) {
          push(
            generatePath(
              ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS,
              {
                entityId,
                sporterId: person.id,
                medicalId: medical.id,
                injuryId: personInjuryId
              }
            )
          );
        }
      }}
    >
      <Icon id="delete" strokeColor="color-neutral-dark" />
      {children}
    </ConfirmMutationButton>
  );
};

export default withRouter(RemoveScreeningButton);
