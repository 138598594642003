import React, { useContext } from 'react';
import classNames from 'classnames';
import { TableHeader, TableHeaderCell, TableRow } from 'components/table';
import TableCell from 'components/table/TableCell';
import Table from 'components/table/Table';
import TableBody from 'components/table/TableBody';
import { uid } from 'react-uid';
import { StoreContext } from 'index';
import { round } from 'utils/math';
import ProgressBar from 'components/progress-bar/ProgressBar';
import { FormattedMessage } from 'react-intl';
import messages from 'messages.js';

function SportsTableRow({ testItem }) {
  const {
    uiState: { locale }
  } = useContext(StoreContext);
  return (
    <Table tableReport extraClassNames={classNames()}>
      <TableHeader>
        <TableRow>
          <TableHeaderCell colSpan={2}>
            <FormattedMessage {...messages.cardLabelTitleSports} />
          </TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {testItem.result?.[2].map(sport => {
          return (
            <TableRow key={uid(`${testItem.id}_${sport.meta.id}`)}>
              <TableCell>{sport?.copy?.[locale] ?? sport.title}</TableCell>
              <TableCell extraClassNames="c-table-cell--value">
                <ProgressBar value={Number(round(sport.meta.result, 0))}>
                  {round(sport.meta.result, 0)}
                </ProgressBar>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default SportsTableRow;
