import React, { useContext } from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { MUTATION_MAIL_ALL_SESSION_REPORT } from 'services/aws/session-query';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import { MODAL_TYPES } from 'models/ModalData';
import { FormattedMessage, useIntl } from 'react-intl';
import { StoreContext } from 'index';
import { useNotificationQueue } from 'components/notification';

const MailAllReportsButton = ({
  entityId,
  session,
  options,
  children,
  ...props
}) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const notification = useNotificationQueue();
  const intl = useIntl();

  if (
    !user.isHylyghtOrg &&
    (session.isIDo ||
      session.isILike ||
      session.isIAm ||
      session.isDjas ||
      session.isHan ||
      user.isHanOrg ||
      session.isHanSisp ||
      session.isKlusce)
  )
    return <></>;
  const { benchmark, ...restOptions } = options;
  const data = {
    sessionId: session.id,
    options: restOptions
  };
  if (!session.isRehab && !session.isPrevention) {
    data.benchmarkId = benchmark;
  }

  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_MAIL_ALL_SESSION_REPORT}
      confirmTitle={messages.modalConfirmMailAllReportsTitle}
      confirmMessage={messages.modalConfirmMailAllReportsMessage}
      confirmAcceptLabel={messages.modalConfirmMailReportAcceptButton}
      confirmType={MODAL_TYPES.WARNING}
      variables={data}
      update={(cache, { data: { mailSessionAllReportOfTestSession } }) => {
        if (mailSessionAllReportOfTestSession) {
          notification.add('mailSessionAllReportOfTestSession', {
            message: intl.formatMessage(
              messages.modalReportSendToSporterSuccessMessage
            )
          });
        }
      }}
    >
      <Icon id="send" />
      <FormattedMessage {...messages.menuItemSessionMailAllReports} />
    </ConfirmMutationButton>
  );
};

export default inject('routing')(withRouter(MailAllReportsButton));
