import { useContext } from 'react';
import { StoreContext } from 'index';
import { useExercisesContext } from 'contexts/ExercisesContext';
import Loader from 'components/loader/Loader';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import ExercisesListVirtualized from 'containers/partials/lists/ExercisesListVirtualized';
import useMyExercises from 'hooks/queries/useMyExercises';

const ExercisesForAthletePanel = ({
  entityId,
  exerciseId,
  onClick,
  onPreview
}) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const {
    exercisesState,
    exerciseActions,
    loading: loadingAll,
    error: errorAll
  } = useExercisesContext();

  const { exercises: myExercises, loading: loadingMy } = useMyExercises(
    entityId,
    user
  );

  if (errorAll) return <RetryPanel />;

  return (
    <>
      {loadingMy && <Loader />}
      <ExercisesListVirtualized
        loading={loadingAll}
        checkable
        exercises={myExercises}
        activeId={exerciseId}
        onPreview={exercise => onPreview && onPreview(exercise)}
        enablePreviewModal={false}
        showTemplateCount={user.isGSVOrg}
        showLevel={!user.isGSVOrg}
        selectedExercises={exercisesState.selectedExercises}
        onClick={id => {
          onClick && onClick(id);
        }}
        onSelect={exerciseIds => {
          exerciseActions.setSelectedExercises(
            exercisesState.exercises.filter(e => exerciseIds.includes(e.id))
          );
        }}
      />
    </>
  );
};

export default ExercisesForAthletePanel;
