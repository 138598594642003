import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from 'messages';

import PanelError from 'components/panel/PanelError';
import Icon from 'components/icon/Icon';
import { Button } from 'components/button';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';

const RetryPanel = ({ onRetry = null }) => (
  <PanelError>
    <Message emptyState icon="database-error">
      <MessageText warning>
        <FormattedMessage {...messages.title} />
      </MessageText>
      <MessageText>
        <FormattedMessage {...messages.message} />
      </MessageText>
      <Button tiny onClick={onRetry ? onRetry : () => window.location.reload()}>
        <Icon id="retry" smallIcon />
        <span>
          <FormattedMessage {...messages.retry} />
        </span>
      </Button>
    </Message>
  </PanelError>
);

export default RetryPanel;
