export const defaultMTSSportsEN = [
  {
    label: 'American football',
    value: 'american-football',
    subItems: [
      { label: 'Center', value: 'center' },
      { label: 'Offensive guard', value: 'offensive-guard' },
      { label: 'Offensive tackle', value: 'offensive-tackle' },
      { label: 'Quarterback', value: 'quarterback' },
      { label: 'Running back', value: 'running-back' },
      { label: 'Wide receiver', value: 'wide-receiver' },
      { label: 'Thight end', value: 'thight-end' },
      { label: 'Defensive tackle', value: 'defensive-tackle' },
      { label: 'Defensive end', value: 'defensive-end' },
      { label: 'Middle linebacker', value: 'middle-linebacker' },
      { label: 'Outside linebacker', value: 'outside-linebacker' },
      { label: 'Cornerback', value: 'cornerback' },
      { label: 'Safety', value: 'safety' },
      { label: 'Nickelback', value: 'nickelback' }
    ]
  },
  { label: 'Archery', value: 'archery' },
  {
    label: 'Athletics',
    value: 'athletics',
    subItems: [
      { label: '100m', value: '100-m' },
      { label: '200m', value: '200-m' },
      { label: '400m', value: '400-m' },
      { label: '800m', value: '800-m' },
      { label: '1500m', value: '1500-m' },
      { label: '3000m', value: '3000-m' },
      { label: '5000m', value: '5000-m' },
      { label: '10000m', value: '10000-m' },
      { label: 'Half marathon', value: 'half-marathon' },
      { label: 'Marathon', value: 'marathon' },
      { label: 'Javalin', value: 'javalin' },
      { label: 'Discus throw', value: 'discus-throw' },
      { label: 'Shot put', value: 'shot-put' },
      { label: 'Hammer throw', value: 'hammer-throw' },
      { label: 'Long jump', value: 'long-jump' },
      { label: 'High jump', value: 'high-jump' },
      { label: 'Pole vault', value: 'pole-vault' },
      { label: 'Triple jump', value: 'triple-jump' },
      { label: '100m hurdles', value: '100-m-hurdles' },
      { label: '110m hurdles', value: '110-m-hurdles' },
      { label: '400m hurdles', value: '400-m-hurdles' },
      { label: '3000m steeple chase', value: '3000-m-steeple-chase' },
      { label: 'Race walking', value: 'race-walking' },
      { label: 'Pentathlon', value: 'pentathlon' },
      { label: 'Decathlon', value: 'decathlon' },
      { label: 'Recreational road jogger', value: 'recreational-road-jogger' },
      { label: 'Cross country', value: 'cross-country' },
      { label: 'Trail running', value: 'trail-running' }
    ]
  },
  {
    label: 'Australian football',
    value: 'australian-football',
    subItems: [
      { label: 'Full-forward', value: 'full-forward' },
      { label: 'Forward pocket', value: 'forward-pocket' },
      { label: 'Centre half-forward', value: 'centre-half-forward' },
      { label: 'Half-forward flanks', value: 'half-forward-flanks' },
      { label: 'Rover', value: 'rover' },
      { label: 'Ruck rover', value: 'ruck-over' },
      { label: 'Ruckman', value: 'ruckman' },
      { label: 'Centre', value: 'centre' },
      { label: 'Wing', value: 'wing' },
      { label: 'Centre half-back', value: 'centre-half-back' },
      { label: 'Half-back flanks', value: 'half-back-flanks' },
      { label: 'Back pocket', value: 'back-pocket' },
      { label: 'Full-back', value: 'full-back' }
    ]
  },
  { label: 'Badminton', value: 'badminton' },
  {
    label: 'Baseball',
    value: 'baseball',
    subItems: [
      { label: 'Pitcher', value: 'pitcher' },
      { label: 'Catcher', value: 'catcher' },
      { label: 'Left field', value: 'left-field' },
      { label: 'Center field', value: 'center-field' },
      { label: 'Right field', value: 'right-field' },
      { label: 'Shortstop', value: 'shortstop' },
      { label: 'First base', value: 'first-base' },
      { label: 'Second base', value: 'second-base' },
      { label: 'Third base', value: 'third-base' }
    ]
  },
  {
    label: 'Basketball',
    value: 'basketball',
    subItems: [
      { label: 'Point guard', value: 'point-guard' },
      { label: 'Shooting guard', value: 'shooting-guard' },
      { label: 'Small forward', value: 'small-forward' },
      { label: 'Power forward', value: 'power-forward' },
      { label: 'Center', value: 'center' },
      { label: 'Combo guard', value: 'combo-guard' },
      { label: 'Point forward', value: 'point-forward' },
      { label: 'Swingman', value: 'swingman' },
      { label: 'Cornerman', value: 'cornerman' }
    ]
  },
  { label: 'Boxing', value: 'boxing' },
  { label: 'Climbing', value: 'climbing' },
  {
    label: 'Canoe',
    value: 'canoe',
    subItems: [
      { label: 'Canoe sprint', value: 'canoe-sprint' },
      { label: 'Canoe marathon', value: 'canoe-marathon' },
      { label: 'Wild water canoe slalom', value: 'wild-water-canoe-slalom' },
      { label: 'Wild water canoe sailing', value: 'wild-water-canoe-sailing' },
      {
        label: 'Wild water canoe freestyle',
        value: 'wild-water-canoe-freestyle'
      },
      { label: 'Canoe polo', value: 'canoe-polo' }
    ]
  },
  {
    label: 'Cricket',
    value: 'cricket',
    subItems: [
      { label: 'Bowler', value: 'bowler' },
      { label: 'Wicket-keeper', value: 'wicket-keeper' },
      { label: 'Long stop', value: 'long-stop' },
      { label: 'Sweeper', value: 'sweeper' },
      { label: 'Cow corner', value: 'cow-corner' },
      { label: '45', value: '45' }
    ]
  },
  {
    subItems: [
      { label: 'Track sprint', value: 'track-sprint' },
      { label: 'Track fond', value: 'track-fond' },
      { label: 'BMX', value: 'bmx' },
      { label: 'Mountainbike', value: 'mountainbike' },
      { label: 'Road sprinter', value: 'road-sprinter' },
      { label: 'Classic road rider', value: 'classic-road-rider' },
      { label: 'Road climber', value: 'road-climber' },
      { label: 'Cyclocross', value: 'cyclocross' }
    ],
    label: 'Cycling',
    value: 'cycling'
  },
  { label: 'Dancing', value: 'dancing' },
  { label: 'Equestrian', value: 'equestrian' },
  { label: 'Fencing', value: 'fencing' },
  { label: 'Golf', value: 'golf' },
  {
    label: 'Gymnastics',
    value: 'gymnastics',
    subItems: [
      { label: 'Acrobatic gymnastics', value: 'acrobatic-gymnastics' },
      { label: 'Artistic gymnastics', value: 'artistic-gymnastics' },
      { label: 'Rythmic gymnastics', value: 'rythmic-gymnastics' },
      { label: 'Freerunning / parkour', value: 'freerunning-parkour' },
      { label: 'Trampolining', value: 'trampolining' },
      { label: 'Tumbling', value: 'tumbling' }
    ]
  },
  {
    label: 'Handball',
    value: 'handball',
    subItems: [
      { label: 'Centre player', value: 'centre-player' },
      { label: 'Circle runner', value: 'circle-runner' },
      { label: 'Goalkeeper', value: 'goalkeeper' },
      { label: 'Left / Right back', value: 'left-right-back' },
      { label: 'Left / Right wing', value: 'left-right-wing' }
    ]
  },
  {
    label: 'Hockey',
    value: 'hockey',
    subItems: [
      { label: 'Goaltender', value: 'goaltender' },
      { label: 'Full-back', value: 'full-back' },
      { label: 'Sweeper', value: 'sweeper' },
      { label: 'Midfielder', value: 'midfielder' },
      { label: 'Inner', value: 'inner' },
      { label: 'Wing player', value: 'wing-player' }
    ]
  },
  {
    label: 'Ice hockey',
    value: 'ice-hockey',
    subItems: [
      { label: 'Center', value: 'center' },
      { label: 'Defenseman', value: 'defenseman' },
      { label: 'Goaltender', value: 'goaltender' },
      { label: 'Left wing', value: 'left-wing' },
      { label: 'Right wing', value: 'right-wing' }
    ]
  },
  { label: 'Judo', value: 'judo' },
  {
    label: 'Lacrosse',
    value: 'lacrosse',
    subItems: [
      { label: 'Attacker', value: 'attacker' },
      { label: 'Goalkeeper', value: 'goalkeeper' },
      { label: 'Midfielder', value: 'midfielder' },
      { label: 'Defender', value: 'defender' }
    ]
  },
  { label: 'Rope skipping', value: 'rope-skipping' },
  {
    label: 'Rowing',
    value: 'rowing',
    subItems: [
      { label: 'Single sculls / Skiff', value: 'single-sculls-skiff' },
      { label: 'Double sculls', value: 'double-sculls' },
      { label: 'Quadruple sculls', value: 'quadruple-sculls' },
      { label: 'Coxless pair', value: 'coxless-pair' },
      { label: 'Coxless four', value: 'coxless-four' },
      { label: 'Eight', value: 'eight' }
    ]
  },
  {
    label: 'Rugby',
    value: 'rugby',
    subItems: [
      { label: 'Prop (1/3)', value: 'prop-1-3' },
      { label: 'Hooker (2)', value: 'hooker-2' },
      { label: 'Lock/Second row (4/5)', value: 'lock-second-row-4-5' },
      {
        label: 'Flanker/Wing forward (6/7)',
        value: 'flanker-wing-forward-6-7'
      },
      { label: 'Number eight (8)', value: 'number-eight-8' },
      { label: 'Scrum-half (9)', value: 'scrum-half-9' },
      { label: 'Fly-half (10)', value: 'fly-half-10' },
      { label: 'Wing (11/14)', value: 'wing-11-14' },
      { label: 'Centre (12/13)', value: 'centre-12-13' },
      { label: 'Full-back (15)', value: 'full-back-15' }
    ]
  },
  {
    label: 'Sailing',
    value: 'sailing',
    subItems: [
      { label: '470', value: '470' },
      { label: 'Laser', value: 'laser' },
      { label: 'Laser Radiaal', value: 'laser-radiaal' },
      { label: '49er', value: '49er' },
      { label: 'RS:X', value: 'rs-x' },
      { label: '49er FX', value: '49er-fx' },
      { label: 'Nacra 17', value: 'nacra-17' },
      { label: 'Finn', value: 'finn' }
    ]
  },
  {
    label: 'Soccer',
    value: 'soccer',
    subItems: [
      { label: 'Keeper', value: 'keeper' },
      { label: 'Centre back', value: 'centre-back' },
      { label: 'Full back', value: 'full-back' },
      { label: 'Centre midfield', value: 'centre-midfield' },
      { label: 'Side midfielder', value: 'side-midfielder' },
      { label: 'Winger', value: 'winger' },
      { label: 'Centre forward', value: 'centre-forward' }
    ]
  },
  { label: 'Surfing', value: 'surfing' },
  {
    label: 'Swimming',
    value: 'swimming',
    subItems: [
      { label: '50m freestyle', value: '50m-freestyle' },
      { label: '100m freestyle', value: '100m-freestyle' },
      { label: '200m freestyle', value: '200m-freestyle' },
      { label: '400m freestyle', value: '400m-freestyle' },
      { label: '800m freestyle', value: '800m-freestyle' },
      { label: '1500m freestyle', value: '1500m-freestyle' },
      { label: '50m breaststroke', value: '50m-breaststroke' },
      { label: '100m breaststroke', value: '100m-breaststroke' },
      { label: '200m breaststroke ', value: '200m-breaststroke' },
      { label: '50m backstroke', value: '50m-backstroke' },
      { label: '100m backstroke', value: '100m-backstroke' },
      { label: '200m backstroke', value: '200m-backstroke' },
      { label: '50m butterfly', value: '50m-butterfly' },
      { label: '100m butterfly', value: '100m-butterfly' },
      { label: '200m butterfly', value: '200m-butterfly' },
      { label: '100m individual medley', value: '100m-individual-medley' },
      { label: '200m individual medley', value: '200m-individual-medley' },
      { label: '400m individual medley', value: '400m-individual-medley' },
      { label: '10km marathon', value: '10km-marathon' },
      { label: 'Open water 5km', value: 'open-water-5km' },
      { label: 'Open water 10km', value: 'open-water-10km' },
      { label: 'Open water 20km', value: 'open-water-20km' }
    ]
  },
  { label: 'Table tennis', value: 'table-tennis' },
  { label: 'Taekwondo', value: 'taekwondo' },
  { label: 'Tennis', value: 'tennis' },
  {
    label: 'Triathlon',
    value: 'triathlon',
    subItems: [
      { label: 'Super sprint', value: 'super-sprint' },
      { label: 'Sprint distance (1/8)', value: 'sprint-distance-1-8' },
      { label: 'Standard distance (1/4)', value: 'standard-distance-1-4' },
      { label: 'Middle distance (1/2)', value: 'middle-distance-1-2' },
      { label: 'Long distance - Full', value: 'long-distance-full' }
    ]
  },
  {
    label: 'Volleyball',
    value: 'volleyball',
    subItems: [
      { label: 'Libero', value: 'libero' },
      { label: 'Middle hitter/blocker', value: 'middle-hitter-blocker' },
      { label: 'Opposite hitter/blocker', value: 'opposite-hitter-blocker' },
      { label: 'Outside hitter', value: 'outside-hitter' },
      { label: 'Setter', value: 'setter' }
    ]
  },
  { label: 'Weight lifting', value: 'weight-lifting' },
  {
    label: 'Wrestling',
    value: 'wrestling',
    subItems: [
      { label: 'Freestyle', value: 'freestyle' },
      { label: 'Greco Roman', value: 'greco-roman' }
    ]
  }
];

export const defaultMTSSportsNL = [
  {
    label: 'American football',
    value: 'american-football',
    subItems: [
      { label: 'Center', value: 'center' },
      { label: 'Offensive guard', value: 'offensive-guard' },
      { label: 'Offensive tackle', value: 'offensive-tackle' },
      { label: 'Quarterback', value: 'quarterback' },
      { label: 'Running back', value: 'running-back' },
      { label: 'Wide receiver', value: 'wide-receiver' },
      { label: 'Thight end', value: 'thight-end' },
      { label: 'Defensive tackle', value: 'defensive-tackle' },
      { label: 'Defensive end', value: 'defensive-end' },
      { label: 'Middle linebacker', value: 'middle-linebacker' },
      { label: 'Outside linebacker', value: 'outside-linebacker' },
      { label: 'Cornerback', value: 'cornerback' },
      { label: 'Safety', value: 'safety' },
      { label: 'Nickelback', value: 'nickelback' }
    ]
  },
  { label: 'Boogschieten', value: 'archery' },
  {
    label: 'Atletiek',
    value: 'athletics',
    subItems: [
      { label: '100m', value: '100-m' },
      { label: '200m', value: '200-m' },
      { label: '400m', value: '400-m' },
      { label: '800m', value: '800-m' },
      { label: '1500m', value: '1500-m' },
      { label: '3000m', value: '3000-m' },
      { label: '5000m', value: '5000-m' },
      { label: '10000m', value: '10000-m' },
      { label: 'Halve marathon', value: 'half-marathon' },
      { label: 'Marathon', value: 'marathon' },
      { label: 'Speer', value: 'javalin' },
      { label: 'Discus', value: 'discus-throw' },
      { label: 'Kogel', value: 'shot-put' },
      { label: 'Hamer', value: 'hammer-throw' },
      { label: 'Ver', value: 'long-jump' },
      { label: 'Hoog', value: 'high-jump' },
      { label: 'Polsstok', value: 'pole-vault' },
      { label: 'Hinkstapspringen', value: 'triple-jump' },
      { label: '100m horden', value: '100-m-hurdles' },
      { label: '110m horden', value: '110-m-hurdles' },
      { label: '400m horden', value: '400-m-hurdles' },
      { label: '3000m steeple', value: '3000-m-steeple-chase' },
      { label: 'Snelwandelen', value: 'race-walking' },
      { label: 'Zevenkamp', value: 'pentathlon' },
      { label: 'Tienkamp', value: 'decathlon' },
      { label: 'Recreatief op de weg', value: 'recreational-road-jogger' },
      { label: 'Veldlopen', value: 'cross-country' },
      { label: 'Trailrunning', value: 'trail-running' }
    ]
  },
  {
    label: 'Australian football',
    value: 'australian-football',
    subItems: [
      { label: 'Full-forward', value: 'full-forward' },
      { label: 'Forward pocket', value: 'forward-pocket' },
      { label: 'Centre half-forward', value: 'centre-half-forward' },
      { label: 'Half-forward flanks', value: 'half-forward-flanks' },
      { label: 'Rover', value: 'rover' },
      { label: 'Ruck rover', value: 'ruck-over' },
      { label: 'Ruckman', value: 'ruckman' },
      { label: 'Centre', value: 'centre' },
      { label: 'Wing', value: 'wing' },
      { label: 'Centre half-back', value: 'centre-half-back' },
      { label: 'Half-back flanks', value: 'half-back-flanks' },
      { label: 'Back pocket', value: 'back-pocket' },
      { label: 'Full-back', value: 'full-back' }
    ]
  },
  { label: 'Badminton', value: 'badminton' },
  {
    label: 'Basebal',
    value: 'baseball',
    subItems: [
      { label: 'Pitcher', value: 'pitcher' },
      { label: 'Catcher', value: 'catcher' },
      { label: 'Left field', value: 'left-field' },
      { label: 'Center field', value: 'center-field' },
      { label: 'Right field', value: 'right-field' },
      { label: 'Shortstop', value: 'shortstop' },
      { label: 'First base', value: 'first-base' },
      { label: 'Second base', value: 'second-base' },
      { label: 'Third base', value: 'third-base' }
    ]
  },
  {
    label: 'Basketbal',
    value: 'basketball',
    subItems: [
      { label: 'Point guard', value: 'point-guard' },
      { label: 'Shooting guard', value: 'shooting-guard' },
      { label: 'Small forward', value: 'small-forward' },
      { label: 'Power forward', value: 'power-forward' },
      { label: 'Center', value: 'center' },
      { label: 'Combo guard', value: 'combo-guard' },
      { label: 'Point forward', value: 'point-forward' },
      { label: 'Swingman', value: 'swingman' },
      { label: 'Cornerman', value: 'cornerman' }
    ]
  },
  { label: 'Boksen', value: 'boxing' },
  { label: 'Klimmen', value: 'climbing' },
  {
    label: 'Kano',
    value: 'canoe',
    subItems: [
      { label: 'Vlakwatersprint', value: 'canoe-sprint' },
      { label: 'Vlakwater marathon', value: 'canoe-marathon' },
      { label: 'Wildwater kanoslalom', value: 'wild-water-canoe-slalom' },
      { label: 'Wildwaterafvaart', value: 'wild-water-canoe-sailing' },
      {
        label: 'Wildwater freestyle',
        value: 'wild-water-canoe-freestyle'
      },
      { label: 'Kanopolo', value: 'canoe-polo' }
    ]
  },
  {
    label: 'Cricket',
    value: 'cricket',
    subItems: [
      { label: 'Bowler', value: 'bowler' },
      { label: 'Wicket-keeper', value: 'wicket-keeper' },
      { label: 'Long stop', value: 'long-stop' },
      { label: 'Sweeper', value: 'sweeper' },
      { label: 'Cow corner', value: 'cow-corner' },
      { label: '45', value: '45' }
    ]
  },
  {
    subItems: [
      { label: 'Baanwielrennen - sprint', value: 'track-sprint' },
      { label: 'Baanwielrennen - fond', value: 'track-fond' },
      { label: 'BMX', value: 'bmx' },
      { label: 'Mountainbike', value: 'mountainbike' },
      { label: 'Wegwielrennen - sprint', value: 'road-sprinter' },
      { label: 'Wegwielrennen - klassiek', value: 'classic-road-rider' },
      { label: 'Wegwielrennen - klimmer', value: 'road-climber' },
      { label: 'Veldrijden', value: 'cyclocross' }
    ],
    label: 'Wielrennen',
    value: 'cycling'
  },
  { label: 'Dans', value: 'dancing' },
  { label: 'Paardrijden', value: 'equestrian' },
  { label: 'Schermen', value: 'fencing' },
  { label: 'Golf', value: 'golf' },
  {
    label: 'Gymnastiek',
    value: 'gymnastics',
    subItems: [
      { label: 'Acrobatische gymnastiek', value: 'acrobatic-gymnastics' },
      { label: 'Artistieke gymnastiek', value: 'artistic-gymnastics' },
      { label: 'Ritmische gymnastiek', value: 'rythmic-gymnastics' },
      { label: 'Freerunning', value: 'freerunning-parkour' },
      { label: 'Trampoline', value: 'trampolining' },
      { label: 'Tumbling', value: 'tumbling' }
    ]
  },
  {
    label: 'Handbal',
    value: 'handball',
    subItems: [
      { label: 'Centrale speler', value: 'centre-player' },
      { label: 'Cirkelspeler', value: 'circle-runner' },
      { label: 'Keeper', value: 'goalkeeper' },
      { label: 'Linker / Rechter opbouw', value: 'left-right-back' },
      { label: 'Linker / Rechterhoek', value: 'left-right-wing' }
    ]
  },
  {
    label: 'Hockey',
    value: 'hockey',
    subItems: [
      { label: 'Keeper', value: 'goaltender' },
      { label: 'Achterspeler', value: 'full-back' },
      { label: 'Voorstopper', value: 'sweeper' },
      { label: 'Middenvelder', value: 'midfielder' },
      { label: 'Spits', value: 'inner' },
      { label: 'Linker/Rechter aanvaller', value: 'wing-player' }
    ]
  },
  {
    label: 'Ijshockey',
    value: 'ice-hockey',
    subItems: [
      { label: 'Center', value: 'center' },
      { label: 'Defenseman', value: 'defenseman' },
      { label: 'Goaltender', value: 'goaltender' },
      { label: 'Left wing', value: 'left-wing' },
      { label: 'Right wing', value: 'right-wing' }
    ]
  },
  { label: 'Judo', value: 'judo' },
  {
    label: 'Lacrosse',
    value: 'lacrosse',
    subItems: [
      { label: 'Aanvaller', value: 'attacker' },
      { label: 'Keeper', value: 'goalkeeper' },
      { label: 'Middenvelder', value: 'midfielder' },
      { label: 'Verdediger', value: 'defender' }
    ]
  },
  { label: 'Rope skipping', value: 'rope-skipping' },
  {
    label: 'Roeien',
    value: 'rowing',
    subItems: [
      { label: 'Single sculls / Skiff', value: 'single-sculls-skiff' },
      { label: 'Double sculls', value: 'double-sculls' },
      { label: 'Quadruple sculls', value: 'quadruple-sculls' },
      { label: 'Coxless pair', value: 'coxless-pair' },
      { label: 'Coxless four', value: 'coxless-four' },
      { label: 'Eight', value: 'eight' }
    ]
  },
  {
    label: 'Rugby',
    value: 'rugby',
    subItems: [
      { label: 'Prop (1/3)', value: 'prop-1-3' },
      { label: 'Hooker (2)', value: 'hooker-2' },
      { label: 'Lock/Second row (4/5)', value: 'lock-second-row-4-5' },
      {
        label: 'Flanker/Wing forward (6/7)',
        value: 'flanker-wing-forward-6-7'
      },
      { label: 'Number eight (8)', value: 'number-eight-8' },
      { label: 'Scrum-half (9)', value: 'scrum-half-9' },
      { label: 'Fly-half (10)', value: 'fly-half-10' },
      { label: 'Wing (11/14)', value: 'wing-11-14' },
      { label: 'Centre (12/13)', value: 'centre-12-13' },
      { label: 'Full-back (15)', value: 'full-back-15' }
    ]
  },
  {
    label: 'Zeilen',
    value: 'sailing',
    subItems: [
      { label: '470', value: '470' },
      { label: 'Laser', value: 'laser' },
      { label: 'Laser Radiaal', value: 'laser-radiaal' },
      { label: '49er', value: '49er' },
      { label: 'RS:X', value: 'rs-x' },
      { label: '49er FX', value: '49er-fx' },
      { label: 'Nacra 17', value: 'nacra-17' },
      { label: 'Finn', value: 'finn' }
    ]
  },
  {
    label: 'Voetbal',
    value: 'soccer',
    subItems: [
      { label: 'Keeper', value: 'keeper' },
      { label: 'Centrale verdediger', value: 'centre-back' },
      { label: 'Buitenste verdediger', value: 'full-back' },
      { label: 'Centrale middenvelder', value: 'centre-midfield' },
      { label: 'Buitenste middenvelder', value: 'side-midfielder' },
      { label: 'Buitenste aanvaller', value: 'winger' },
      { label: 'Centrale aanvaller', value: 'centre-forward' }
    ]
  },
  { label: 'Surfen', value: 'surfing' },
  {
    label: 'Zwemmen',
    value: 'swimming',
    subItems: [
      { label: '50m vrije slag', value: '50m-freestyle' },
      { label: '100m vrije slag', value: '100m-freestyle' },
      { label: '200m vrije slag', value: '200m-freestyle' },
      { label: '400m vrije slag', value: '400m-freestyle' },
      { label: '800m vrije slag', value: '800m-freestyle' },
      { label: '1500m vrije slag', value: '1500m-freestyle' },
      { label: '50m schoolslag', value: '50m-breaststroke' },
      { label: '100m schoolslag', value: '100m-breaststroke' },
      { label: '200m schoolslag ', value: '200m-breaststroke' },
      { label: '50m rugslag', value: '50m-backstroke' },
      { label: '100m rugslag', value: '100m-backstroke' },
      { label: '200m rugslag', value: '200m-backstroke' },
      { label: '50m vlinderslag', value: '50m-butterfly' },
      { label: '100m vlinderslag', value: '100m-butterfly' },
      { label: '200m vlinderslag', value: '200m-butterfly' },
      { label: '100m wisselslag', value: '100m-individual-medley' },
      { label: '200m wisselslag', value: '200m-individual-medley' },
      { label: '400m wisselslag', value: '400m-individual-medley' },
      { label: '10km marathon', value: '10km-marathon' },
      { label: 'Open water 5km', value: 'open-water-5km' },
      { label: 'Open water 10km', value: 'open-water-10km' },
      { label: 'Open water 20km', value: 'open-water-20km' }
    ]
  },
  { label: 'Tafeltennis', value: 'table-tennis' },
  { label: 'Taekwondo', value: 'taekwondo' },
  { label: 'Tennis', value: 'tennis' },
  {
    label: 'Triatlon',
    value: 'triathlon',
    subItems: [
      { label: 'Supersprint (mini)', value: 'super-sprint' },
      { label: 'Sprintafstand (1/8)', value: 'sprint-distance-1-8' },
      { label: 'Kwarttriatlon', value: 'standard-distance-1-4' },
      { label: 'Halve triatlon', value: 'middle-distance-1-2' },
      { label: 'Volledige afstand', value: 'long-distance-full' }
    ]
  },
  {
    label: 'Volleybal',
    value: 'volleyball',
    subItems: [
      { label: 'Libero', value: 'libero' },
      { label: 'Middenaanvaller/blokker', value: 'middle-hitter-blocker' },
      { label: 'Opposite', value: 'opposite-hitter-blocker' },
      { label: 'Receptie-hoek', value: 'outside-hitter' },
      { label: 'Passeur', value: 'setter' }
    ]
  },
  { label: 'Gewichtheffen', value: 'weight-lifting' },
  {
    label: 'Worstelen',
    value: 'wrestling',
    subItems: [
      { label: 'Freestyle', value: 'freestyle' },
      { label: 'Grieks-Romeins', value: 'greco-roman' }
    ]
  }
];

export const defaultMTSLevels = [
  { label: 'N/A', value: 'not-applicable' },
  { label: 'Olympian', value: 'olympian' },
  { label: 'International', value: 'international' },
  { label: 'Professional', value: 'professional' },
  { label: 'Semi professional', value: 'semi-professional' },
  { label: 'Amateur', value: 'amateur' },
  { label: 'Youth', value: 'youth' }
];

export const defaultMTSMetaConfig = {
  sports: { nl: defaultMTSSportsNL, en: defaultMTSSportsEN },
  levels: defaultMTSLevels
};
