import React, { useContext } from 'react';
import { generatePath } from 'react-router-dom';
import { Tab, TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import {
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_EDIT
} from 'routes/RouteList';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import SporterReportCard from 'containers/partials/cards/SporterReportCard';
import SporterCard from 'containers/partials/cards/SporterCard';
import { StoreContext } from 'index';
import useTabIndex from 'hooks/utils/useTabIndex';

const SporterTabsLegacy = ({ entityId, sessionId, sporterId }) => {
  const {
    routing: { push }
  } = useContext(StoreContext);

  const [tabIndex, setTabIndex] = useTabIndex([
    [
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_EDIT
    ],
    [
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT
    ]
  ]);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
      <TabList>
        <Tab
          key="session.sporter.data"
          onClick={() =>
            push(
              generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS, {
                sessionId,
                entityId,
                sporterId
              })
            )
          }
        >
          <FormattedMessage {...messages.tabSessionSporterTests} />
        </Tab>

        <Tab
          key="session.sporter.global"
          onClick={() =>
            push(
              generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL, {
                sessionId,
                entityId,
                sporterId
              })
            )
          }
        >
          <FormattedMessage {...messages.tabSessionGlobal} />
        </Tab>
      </TabList>

      <TabPanel key="session.sporter.data">
        <TabPanelBody withScroller>
          <SporterReportCard entityId={entityId} />
        </TabPanelBody>
      </TabPanel>

      <TabPanel key="session.sporter.global">
        <TabPanelBody withScroller>
          <SporterCard
            entityId={entityId}
            parentPath={generatePath(
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
              {
                sessionId,
                entityId,
                sporterId
              }
            )}
          />
        </TabPanelBody>
      </TabPanel>
    </Tabs>
  );
};

export default SporterTabsLegacy;
