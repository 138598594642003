import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import Panel from 'components/panel/Panel';
import messages from 'messages';
import { PanelHeader } from 'components/panel';
import { PanelTitle, PanelTitleWrapper } from 'components/panel/panel-title';
import PanelTools from 'components/panel/PanelTools';
import { Button } from 'components/button';
import {
  ROUTE_EXERCISES_ENTITY,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES
} from 'routes/RouteList';
import Icon from 'components/icon/Icon';
import { useQuery } from '@apollo/client';
import { QUERY_GET_PROGRAMS } from 'services/aws/programs-query';

import { StoreContext } from 'index';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import AddProgramModal from './AddProgramModal';
import Loader from 'components/loader/Loader';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import ProgramsList from 'containers/partials/lists/ProgramsList';
import { parsePrograms } from 'utils/programs';
import FieldInput from 'components/input/FieldInput';

const Programs = ({ active, entityId }) => {
  const {
    authStore: { user },
    routing: { push },
    uiState: { locale }
  } = useContext(StoreContext);
  const intl = useIntl();
  const [search, setSearch] = useState('');
  const [programsData, setProgramsData] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [modalData, setModalData] = useState(null);

  const { loading, data } = useQuery(QUERY_GET_PROGRAMS, {
    variables: { entityId }
  });

  useEffect(() => {
    if (data?.getExercisePrograms && data.getExercisePrograms.length > 0) {
      setProgramsData(parsePrograms(data.getExercisePrograms, intl, locale));
    }
  }, [data]);

  useEffect(() => {
    if (programsData.length > 0) {
      setPrograms(
        sort(
          programsData.filter(p =>
            p.title.toLowerCase().includes(search.toLowerCase())
          ),
          {
            keys: [
              { key: 'order', dataType: SORT_DATA_TYPES.NUMBER },
              { key: 'sortDate', dataType: SORT_DATA_TYPES.NUMBER }
            ]
          }
        )
      );
    }
  }, [search, programsData]);

  const onSearch = e => {
    setSearch(e.target.value);
  };

  const onClickHandler = program => {
    push(
      ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT.replace(
        ':entityId',
        entityId
      ).replace(':programId', program.id)
    );
  };

  return (
    <Panel active={active}>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb
            to={ROUTE_EXERCISES_ENTITY.replace(':entityId', entityId)}
          >
            <FormattedMessage {...messages.breadcrumbOverview} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.programs} />
          </PanelTitle>
        </PanelTitleWrapper>
        <PanelTools separated>
          <FieldInput rounded icon="search-grey" iconLeft onChange={onSearch} />
          {user.canAddPrograms(entityId) && ( // TODO canAddPrograms check
            <Button rounded primary onClick={() => setModalData(true)}>
              <Icon id="add-white" />
              <FormattedMessage {...messages.exercisesAddProgramButton} />
            </Button>
          )}
        </PanelTools>
      </PanelHeader>
      {loading && <Loader />}

      <ProgramsList
        entityId={entityId}
        programs={programs}
        onClick={program => onClickHandler(program)}
      />

      {modalData && (
        <AddProgramModal
          entityId={entityId}
          destiny={{
            path: ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES,
            variables: {}
          }}
          program={{
            entityId,
            title: '',
            startdate: '',
            duration: '',
            description: '',
            exerciseIds: [],
            exercises: []
          }}
          onClose={() => setModalData(null)}
        />
      )}
    </Panel>
  );
};

export default observer(Programs);
