import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Route, Switch, Redirect } from 'react-router-dom';
import Overview from './Overview';

import {
  ROUTE_USER_SESSIONS_ENTITY,
  ROUTE_USER_SESSIONS_ENTITY_MEDICAL,
  ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT
} from 'routes/RouteList';

import Panel from 'components/panel/Panel';
import messages from 'messages';
import { SessionContextProvider } from 'contexts/SessionContext';
import { StoreContext } from 'index';
import { PersonContextProvider } from 'contexts/PersonContext';
import SessionReportPanel from 'containers/pages/user/sessions/SessionReportPanel';
import { UserContextProvider } from 'contexts/UserContext';

const UserSessions = () => {
  const intl = useIntl();
  const {
    authStore: { user }
  } = useContext(StoreContext);

  return (
    <PersonContextProvider personId={user.id}>
      <Switch>
        <Route
          exact
          path={[
            ROUTE_USER_SESSIONS_ENTITY,
            ROUTE_USER_SESSIONS_ENTITY_MEDICAL,
            ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT
          ]}
          render={props => (
            <UserContextProvider
              entityId={props.match.params.entityId}
              personId={user.id}
            >
              <Overview
                key={props.match.params.entityId}
                entityId={props.match.params.entityId}
                sessionId={props.match.params.sessionId}
                active={ROUTE_USER_SESSIONS_ENTITY === props.match.path}
              />
            </UserContextProvider>
          )}
        />
        <Redirect
          to={ROUTE_USER_SESSIONS_ENTITY.replace(
            ':entityId',
            user.rootEntityId
          )}
        />
      </Switch>
      <Route
        exact
        path={[ROUTE_USER_SESSIONS_ENTITY, ROUTE_USER_SESSIONS_ENTITY_MEDICAL]}
        render={() => (
          <Panel
            emptyState
            emptyLabel={intl.formatMessage(messages.sessionsEmptyPanel)}
          />
        )}
      />
      <Switch>
        <Route
          exact
          path={[ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT]}
          render={props => (
            <SessionContextProvider>
              <SessionReportPanel
                entityId={props.match.params.entityId}
                sessionId={props.match.params.sessionId}
              />
            </SessionContextProvider>
          )}
        />
      </Switch>
    </PersonContextProvider>
  );
};

export default withRouter(observer(UserSessions));
