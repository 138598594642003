import React from 'react';
import './Message.scss';
import classNames from 'classnames';
import Icon from 'components/icon/Icon';

const Message = props => {
  const { children, emptyState, extraClassNames, icon, iconFillColor } = props;

  return (
    <div
      className={classNames('c-message', extraClassNames, {
        'c-message--empty-state': emptyState
      })}
    >
      {icon && (
        <div className="c-message__icon">
          <Icon
            id={icon}
            mediumIcon
            fillColor={iconFillColor ? iconFillColor : 'color-neutral'}
          />
        </div>
      )}
      <div className="c-message__text-wrapper">{children}</div>
    </div>
  );
};

export default Message;
