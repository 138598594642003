import React, { useEffect, useState } from 'react';
import PreviewButton from '../buttons/PreviewButton';
import { ButtonsGroup } from 'components/button';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper,
  ListItemStatus
} from 'components/list/list-item';
import Icon from 'components/icon/Icon';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Button from 'components/button/Button';
import { useEditableContext } from 'contexts/EditableContext';
import EditableExerciseNote from 'containers/partials/inputs/EditableExerciseNote';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import classNames from 'classnames';

const getNote = (exercise, notes) => {
  if (!notes) return;
  if (notes.length > 0) {
    return notes[0];
  }
  if (exercise.note) {
    return { note: exercise.note };
  }
  return '';
};

const ExerciseListItemSortable = ({
  id,
  uid,
  exercise,
  exerciseGroup,
  notes,
  active,
  clickable,
  draggable,
  checkable,
  onClick,
  onChange,
  visible,
  preview,
  onShowPreview,
  showNote,
  showFrequency,
  showHasComment,
  noteType,
  editableNote,
  showTemplateCount,
  checked
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id, disabled: !draggable });

  const { isEditing, setIsEditing } = useEditableContext();
  const [note, setNote] = useState({ note: exercise.note });

  useEffect(() => {
    if (notes) {
      setNote(getNote(exercise, notes));
    }
  }, [notes]);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const editingNote =
    isEditing === exercise.id && !isDragging && !draggable && !checkable;

  return (
    <ListItem
      id={id}
      ref={setNodeRef}
      active={active || isDragging}
      checkbox={!draggable && checkable}
      onClick={e => !editingNote && onClick && onClick(e)}
      clickable={clickable}
      style={style}
      draggable={draggable}
      isDragging={isDragging}
      noMaxHeight={editingNote}
      {...attributes}
      {...listeners}
    >
      <ListItemStatus
        itemId={uid}
        checkbox={checkable}
        visible={visible}
        checked={checked}
        onChange={onChange}
        statusIcon="exercise"
      />
      <ListItemLabelWrapper extraClassNames="u-padding-horizontal-medium u-padding-vertical-small">
        <ListItemLabel labelNotePreview>
          <span className="c-list__item-title c-list__item-title-ellipsed">
            {exercise.title}
          </span>
        </ListItemLabel>

        {exercise.note && showNote && (
          <div className="c-list__item-small-editing-area c-list__item-wrapping-note-preview">
            {editingNote ? (
              <EditableExerciseNote
                exercise={exercise}
                note={note}
                exerciseGroup={exerciseGroup}
                noteType={noteType}
                onEdit={() => setIsEditing(false)}
              />
            ) : (
              <>
                <p>{note.note}</p>

                {((exercise.media.length > 0 && onShowPreview) ||
                  editableNote) && (
                  <ButtonsGroup verticalCenter>
                    {exercise.media.length > 0 && onShowPreview && (
                      <PreviewButton
                        media={exercise.media}
                        showPreview={onShowPreview}
                      />
                    )}

                    {editableNote && (
                      <Button
                        tiny
                        disabled={draggable || checkable}
                        onClick={() => setIsEditing(exercise.id)}
                      >
                        <FormattedMessage {...messages.editNotesButton} />
                      </Button>
                    )}
                  </ButtonsGroup>
                )}
              </>
            )}
          </div>
        )}

        {(showFrequency || showHasComment) && (
          <div
            className={classNames('c-list__item-double-icon-info', {
              'c-list__item-double-icon-info--with-preview':
                exercise.media.length > 0 &&
                onShowPreview &&
                exercise.media[0].type === 'exercise_video'
            })}
          >
            {showFrequency && note.meta?.frequency && (
              <ListItemLabel
                info
                extraClassNames={classNames({
                  'u-padding-right-none': !(
                    showHasComment &&
                    note.id &&
                    note.note.length > 0
                  )
                })}
              >
                {note.meta.frequency}
              </ListItemLabel>
            )}

            {showHasComment && note.id && note.note.length > 0 && (
              <ListItemLabel
                info
                extraClassNames={classNames({
                  'u-padding-right-none': !(
                    showFrequency && note.meta?.frequency
                  )
                })}
                style={{ minWidth: 'auto' }}
              >
                <Icon
                  extraClassNames="u-margin-left-tiny"
                  id="comment"
                  fillColor="transparent"
                  strokeColor="color-neutral-dark"
                />
              </ListItemLabel>
            )}

            {exercise.media.length > 0 &&
              onShowPreview &&
              exercise.media[0].type === 'exercise_video' && (
                <ButtonsGroup verticalCenter>
                  <PreviewButton
                    media={exercise.media}
                    showPreview={onShowPreview}
                  />
                </ButtonsGroup>
              )}
          </div>
        )}
        {showTemplateCount && (
          <ListItemLabel info>
            {exercise.templateCount ? (
              <>
                {exercise.templateCount}
                <Icon
                  extraClassNames="u-margin-left-tiny"
                  id="bookmark"
                  fillColor="color-primary"
                  strokeColor="color-primary"
                />
              </>
            ) : (
              <>
                0
                <Icon
                  extraClassNames="u-margin-left-tiny"
                  id="bookmark"
                  fillColor="transparent"
                  strokeColor="color-neutral-dark"
                />
              </>
            )}
          </ListItemLabel>
        )}
      </ListItemLabelWrapper>
    </ListItem>
  );
};

export default ExerciseListItemSortable;
