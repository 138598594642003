import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { useNotificationQueue } from 'components/notification';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';

import {
  MUTATION_ADD_VIDEO,
  MUTATION_EDIT_VIDEO
} from 'services/aws/videos-query';
import messages from 'messages.js';
import { QUERY_GET_VIDEO, QUERY_GET_VIDEOS } from 'services/aws/videos-query';

function VideoForm({ entityId, video, children, extraClassNames, onComplete }) {
  const notification = useNotificationQueue();
  const intl = useIntl();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required')
  });

  const [editExercise] = useMutation(MUTATION_EDIT_VIDEO);
  const [addExercise] = useMutation(MUTATION_ADD_VIDEO);

  const onSubmitHandler = async video => {
    const videoObj = {
      ...video,
      entityId,
      meta: JSON.stringify(video.meta)
    };

    if (!video.id) {
      await addExercise({
        variables: { ...videoObj },
        refetchQueries: [
          {
            query: QUERY_GET_VIDEOS,
            variables: { entityId }
          }
        ]
      }).then(({ data }) => {
        notification.add(data.addExercise.id, {
          message: intl.formatMessage(messages.messageAddVideoSuccess)
        });
        setTimeout(() => {
          onComplete && onComplete(data.addExercise);
        }, 100);
      });
    } else {
      await editExercise({
        variables: { ...videoObj },
        refetchQueries: [
          {
            query: QUERY_GET_VIDEO,
            variables: { id: video.id, entityId }
          }
        ]
      }).then(({ data }) => {
        notification.add(data.editExercise.id, {
          message: intl.formatMessage(messages.messageEditVideoSuccess)
        });
        onComplete && onComplete();
      });
    }
  };

  return (
    <Formik
      initialValues={video}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmitHandler}
    >
      {props => {
        return (
          <Form className={classNames(extraClassNames)} noValidate>
            {typeof children === 'function' ? children(props) : children}
          </Form>
        );
      }}
    </Formik>
  );
}

export default VideoForm;
