import appSyncConfig from './config/AppSync';
import { Auth } from 'aws-amplify';
import { createAuthLink } from 'aws-appsync-auth-link';
import * as Sentry from '@sentry/react';
import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  from
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { PersonStatus } from 'constants.js';

const url = appSyncConfig.graphqlEndpoint;
const region = appSyncConfig.region;
const auth = {
  type: appSyncConfig.authenticationType,
  credentials: () => Auth.currentCredentials(),
  jwtToken: async () =>
    (await Auth.currentSession()).getAccessToken().getJwtToken()
};

const authLink = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  new HttpLink({ uri: url })
]);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, path }) => {
      console.log(`[GraphQL error]: Message:`, message, path);
      Sentry.captureException(graphQLErrors);
      // If the user is not authorised redirect to root
      if (message === 'not_authorised') {
        //TODO
        document.location = '/';
      }
    });
  if (networkError) {
    Sentry.captureException(networkError);
  }
});

export let appSyncClient;

const personFieldsAccountMeta = {
  read(account_meta = {}) {
    return typeof account_meta === 'string'
      ? JSON.parse(account_meta)
      : account_meta;
  }
};

const personFieldsStatus = {
  read(status, { readField }) {
    let accountMeta = readField('account_meta');
    const email = readField('email');
    let meta = readField('meta');
    let athleteEmail = null;
    if (accountMeta && typeof accountMeta === 'string') {
      accountMeta = JSON.parse(accountMeta);
    }
    if (meta && typeof meta === 'string') {
      meta = JSON.parse(meta);
      athleteEmail = meta?.email ? (meta?.email).toString().trim() : '';
    }

    let accountStatus = PersonStatus.NONE;
    if (email && accountMeta?.status === 'CONFIRMED') {
      accountStatus = PersonStatus.USER;
    } else if (
      email &&
      (accountMeta?.status === 'FORCE_CHANGE_PASSWORD' ||
        accountMeta?.status === 'DELETED') &&
      accountMeta?.expires &&
      new Date(accountMeta.expires) < new Date()
    ) {
      accountStatus = PersonStatus.UNCONFIRMED;
    } else if (
      email &&
      accountMeta?.status === 'FORCE_CHANGE_PASSWORD' &&
      accountMeta?.expires &&
      new Date(accountMeta.expires) > new Date()
    ) {
      accountStatus = PersonStatus.PENDING;
    } else if (athleteEmail && !email) {
      accountStatus = PersonStatus.EMAIL;
    }
    return accountStatus;
  }
};

export const setClient = async () => {
  appSyncClient = new ApolloClient({
    link: from([errorLink, authLink]),
    shouldBatch: true,
    cache: new InMemoryCache({
      typePolicies: {
        Person: {
          fields: {
            account_meta: personFieldsAccountMeta,
            status: personFieldsStatus
          }
        },
        PersonV2: {
          fields: {
            account_meta: personFieldsAccountMeta,
            status: personFieldsStatus
          }
        },
        TestDataV2: {
          keyFields: ['id', 'resultBenchmarkId']
        },
        TestSetV2: {
          fields: {
            config: {
              // Merge the config fields together
              merge(existing = {}, incoming, { mergeObjects }) {
                if (incoming) {
                  return mergeObjects(
                    existing,
                    typeof incoming === 'string'
                      ? JSON.parse(incoming)
                      : incoming
                  );
                }
                return existing;
              }
            },
            meta: {
              read(meta = {}) {
                return typeof incoming === 'string' ? JSON.parse(meta) : meta;
              }
            }
          }
        }
      }
    })
  });

  // await sleep(1000);
  return appSyncClient;
};

// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
