import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_GET_TAGS } from 'services/aws/tags-query';
import { arrayToTree } from 'performant-array-to-tree';
import { sort } from 'utils/sort';

const useTags = ({ entityId }) => {
  const [tags, setTags] = useState([]);
  const { loading, error, data } = useQuery(QUERY_GET_TAGS, {
    variables: { entityId }
  });

  useEffect(() => {
    if (data?.getTagsByEntity) {
      setTags(
        arrayToTree(
          sort(
            data.getTagsByEntity.map(
              ({ id, label, parentId, description }) => ({
                id,
                label,
                value: label,
                parentId,
                description
              })
            ),
            { keys: [{ key: 'order' }] }
          ),
          { dataField: null }
        )
      );
    }
  }, [data]);

  return { tags, loading, error };
};

export default useTags;
