import { useContext } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { StoreContext } from 'index';
import { ROUTE_SPORTERS_ENTITY } from 'routes/RouteList';
import Loader from 'components/loader/Loader';
import VirtualizedList from 'containers/partials/lists/VirtualizedList';
import useUserGroups from 'hooks/queries/useUserGroups';

const SporterGroups = ({ entityId, personId }) => {
  const {
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const { loading, entities } = useUserGroups({
    entityId: rootEntityId,
    personId
  });

  const onClickHandler = entity => {
    push(
      generatePath(ROUTE_SPORTERS_ENTITY, {
        entityId: entity.entityId
      })
    );
  };

  if (loading) return <Loader />;

  return (
    <VirtualizedList
      items={entities}
      entityId={entityId}
      onClick={entity => onClickHandler(entity)}
      icon={'group'}
      type={`user`}
    />
  );
};

export default SporterGroups;
