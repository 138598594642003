import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { generatePath } from 'react-router-dom';
import Panel from 'components/panel/Panel';
import messages from 'messages';
import { PanelHeader } from 'components/panel';
import { PanelTitle, PanelTitleWrapper } from 'components/panel/panel-title';
import PanelTools from 'components/panel/PanelTools';
import { Button } from 'components/button';
import {
  ROUTE_EXERCISES,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES,
  ROUTE_EXERCISES_TEMPLATES_ENTITY,
  ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE,
  ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EDIT
} from 'routes/RouteList';
import Icon from 'components/icon/Icon';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import { StoreContext } from 'index';
import AddTemplateModal from './AddTemplateModal';
import Tags from 'containers/partials/inputs/Tags';
import AddProgramModal from 'containers/pages/exercises/programs/AddProgramModal';
import Loader from 'components/loader/Loader';
import { useTemplatesContext } from 'contexts/TemplatesContext';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import TemplateListVirtualized from 'containers/partials/lists/TemplateListVirtualized';
import FieldInput from 'components/input/FieldInput';

const Templates = ({ active, entityId, templateId }) => {
  const {
    routing: { push }
  } = useContext(StoreContext);
  const ability = useAbility(AbilityContext);
  const { state, actions, loading: templatesLoading } = useTemplatesContext();
  const intl = useIntl();
  const [modalData, setModalData] = useState(null);
  const [programModalData, setProgramModalData] = useState(null);
  const [filter, setFilter] = useState({ search: '', tags: [] });

  useEffect(() => {
    actions.getTemplates();
  }, []);

  const onSearch = e => {
    setFilter({ ...filter, search: e.target.value });
    actions.setFilter({ search: e.target.value });
  };

  const handleTagChange = tags => {
    setFilter({ ...filter, tags });
    actions.setFilter({ tags });
  };

  const onClickHandler = templateId => {
    push(
      ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE.replace(
        ':entityId',
        entityId
      ).replace(':templateId', templateId)
    );
  };

  const onEditHandler = templateId => {
    push(
      ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EDIT.replace(
        ':entityId',
        entityId
      ).replace(':templateId', templateId)
    );
  };

  const onUseHandler = template => {
    // show use template modal
    setProgramModalData({
      entityId,
      title: '',
      startdate: '',
      duration: '',
      description: '',
      exerciseIds: template.exercises.map(e => e.id),
      templateId: template.id
    });
  };

  const onDeleteHandler = () => {
    push(generatePath(ROUTE_EXERCISES_TEMPLATES_ENTITY, { entityId }));
  };

  return (
    <Panel active={active}>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb to={ROUTE_EXERCISES}>
            <FormattedMessage {...messages.breadcrumbOverview} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.templates} />

            {/*<MenuWrapper trigger={<PanelTitleButton />}></MenuWrapper>*/}
          </PanelTitle>
        </PanelTitleWrapper>
        <PanelTools separated>
          <FieldInput rounded icon="search-grey" iconLeft onChange={onSearch} />

          {ability.can('create', 'Exercise') && ( // TODO canAddExercise check
            <Button
              rounded
              primary
              onClick={() =>
                setModalData({
                  template: { entityId, title: '', exercises: [], tags: [] }
                })
              }
            >
              <Icon id="add-white" />
              <FormattedMessage {...messages.exercisesAddTemplateButton} />
            </Button>
          )}

          <Tags
            extraClassNames="u-margin-left-none"
            entityId={entityId}
            label={intl.formatMessage(messages.exercisesProgramTagsLabel)}
            // value={filter.tags}
            isMulti
            placeholder={intl.formatMessage(
              messages.exercisesProgramTagsPlaceHolder
            )}
            noOptionsMessage={intl.formatMessage(
              messages.exercisesProgramTagsNoOption
            )}
            inline
            onChange={handleTagChange}
          />
        </PanelTools>
      </PanelHeader>
      {templatesLoading && <Loader />}
      {state.templates && (
        <TemplateListVirtualized
          entityId={entityId}
          templates={state.templates}
          activeId={templateId}
          enablePreviewModal={false}
          onClick={id => onClickHandler(id)}
          onEdit={id => onEditHandler(id)}
          onUse={template => onUseHandler(template)}
          onDeleted={id => onDeleteHandler(id)}
        />
      )}
      {modalData && (
        <AddTemplateModal
          entityId={entityId}
          template={modalData.template}
          onClose={() => setModalData(null)}
        />
      )}
      {programModalData && (
        <AddProgramModal
          entityId={entityId}
          program={programModalData}
          destiny={{
            path: ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES,
            variables: {}
          }}
          onClose={() => setProgramModalData(null)}
          fromTemplate
        />
      )}
    </Panel>
  );
};

export default observer(Templates);
