import React, { Component, Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Query } from '@apollo/client/react/components';
import FileUpload, { FILE_UPLOAD_ACCEPT } from './FileUpload';
import { QUERY_GET_UPLOADED_FILES_BY_LINK_ID } from 'services/aws/file-upload';
import File from 'models/File';
import './FileUpload.scss';
import RetryPanel from '../error-boundary/RetryPanel';
import { sortFiles } from 'utils/sort';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import messages from 'messages';

const defaultVisibleItems = 5;

class MultiFileUploadWrapper extends Component {
  state = {
    showAll: false
  };
  render() {
    const { linkId, entityId, type } = this.props;
    const { showAll } = this.state;
    return (
      <Query
        query={QUERY_GET_UPLOADED_FILES_BY_LINK_ID}
        variables={{ entityId, linkId }}
        fetchPolicy="cache-and-network"
      >
        {result => {
          const { loading, data, error, refetch } = result;
          if (error) return <RetryPanel onRetry={() => refetch()} />;
          let uploadedFiles = [];
          if (data && data.getFiles && data.getFiles.length) {
            uploadedFiles = data.getFiles.map(uf => new File(uf));
          }
          uploadedFiles = uploadedFiles.filter(f => f.type === type);
          uploadedFiles = sortFiles(uploadedFiles);
          uploadedFiles.unshift(null);
          return (
            <div className="c-file-upload-wrapper">
              {uploadedFiles.map((fu, i) => (
                <Fragment key={i}>
                  {(showAll || i < defaultVisibleItems) && (
                    <FileUpload
                      loading={loading}
                      multi
                      key={fu ? fu.id : 'add'}
                      type={'*'}
                      linkId={linkId}
                      entityId={entityId}
                      attachedFile={fu}
                      accept={FILE_UPLOAD_ACCEPT.ALL}
                      onFileUploaded={() => refetch()}
                      onFileDeleted={() => refetch()}
                    />
                  )}
                </Fragment>
              ))}
              {uploadedFiles.length > defaultVisibleItems && (
                <Button
                  rounded
                  grey
                  onClick={() => this.setState({ showAll: !showAll })}
                >
                  {showAll && <Icon id={'dropdown-closed'} rotateCW90 />}
                  {!showAll && <Icon id={'dropdown-open'} />}
                  {showAll ? (
                    <FormattedMessage
                      {...messages.fileUploadLabelShowLessFiles}
                    />
                  ) : (
                    <FormattedMessage
                      {...messages.fileUploadLabelShowAllFiles}
                      values={{ count: uploadedFiles.length }}
                    />
                  )}
                </Button>
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}

export default injectIntl(MultiFileUploadWrapper);
