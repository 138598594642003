import File from 'models/File';

export default class Exercise {
  id;
  entityId;
  title;
  protocol;
  entity;
  media;
  level;
  note;
  remarks;
  cues;
  tags;
  editable;
  templateCount;
  access;
  meta;
  date_created;
  comment;
  frequency;
  person;

  constructor({
    id,
    entityId,
    title = '',
    protocol = '',
    entity = {},
    media = [],
    level = 0,
    note = '',
    remarks = '',
    cues = [],
    tags = [],
    editable = false,
    templateCount = 0,
    access = '',
    meta = null,
    date_created,
    comment = '',
    frequency = '',
    person = null
  }) {
    this.id = id;
    this.entityId = entityId;
    this.title = title;
    this.protocol = protocol ?? '';
    this.entity = entity;
    this.media = Array.isArray(media) ? media.map(m => new File({ ...m })) : [];
    this.level = level;
    this.note = note ?? '';
    this.remarks = remarks ?? '';
    this.cues = cues; // ? JSON.parse(cues) : [];
    this.tags = tags ?? []; // ? JSON.parse(tags) : [];
    this.editable = editable;
    this.templateCount = templateCount;
    this.access = access;
    this.date_created = date_created;
    this.person = person;

    // Extra fields, stored in Note not stored in exercise Object
    this.comment = comment;
    this.frequency = frequency;

    try {
      this.meta = JSON.parse(meta);
    } catch (er) {
      this.meta = meta;
    }
  }

  static get modelName() {
    return 'Exercise';
  }
}
