import { useEffect, useState } from 'react';
import ListHeader from 'components/list/ListHeader';
import { SORT_DIRECTION } from 'utils/sort';
import ListColumnTitle from 'components/list/ListColumnTitle';

const SortableListHeader = ({ sticky, topPosition, columns, onSort }) => {
  const [activeSort, setActiveSort] = useState(null);

  useEffect(() => {
    if (columns?.length > 0 && !activeSort) {
      const defaultSort = columns.find(c => c.default);
      if (defaultSort) {
        setActiveSort({
          key: defaultSort.key,
          desc: defaultSort.dir === SORT_DIRECTION.DESC,
          dataType: defaultSort.dataType
        });
      }
    }
  }, [activeSort, columns]);

  useEffect(() => {
    if (onSort && activeSort) onSort(activeSort);
  }, [activeSort]);

  const getStyleListItemHeader = extraProps => {
    let result;

    switch (extraProps?.id) {
      case 'listItemStatus':
        result = 'c-list__col--status';
        break;
      case 'listItemMainLabel':
        result = 'c-list__col--main-label';
        break;
      case 'listItemLastLabel':
        result = 'c-list__col--last-label';
        break;

      default:
        result = 'u-hidden';
        break;
    }
    return result;
  };

  return (
    <ListHeader
      sticky={sticky}
      style={
        sticky &&
        topPosition &&
        !isNaN(topPosition) && { top: `${topPosition}px` }
      }
    >
      {columns.map(col => {
        const active = activeSort?.key === col.key;
        let desc = active ? activeSort.desc : col.dir === SORT_DIRECTION.DESC;

        return (
          <ListColumnTitle
            extraClassNames={
              col.extraProps && getStyleListItemHeader(col.extraProps)
            }
            key={col.key}
            ascendent={!desc && active}
            descendent={desc && active}
            unClickable={!col.sortable}
            onClick={e => {
              col.sortable &&
                setActiveSort({
                  key: col.key,
                  desc: active ? !desc : desc,
                  dataType: col.dataType
                });
            }}
          >
            {col.label}
          </ListColumnTitle>
        );
      })}
    </ListHeader>
  );
};

export default SortableListHeader;
