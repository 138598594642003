import messages from 'messages';
import PanelError from 'components/panel/PanelError';

import { FormattedMessage } from 'react-intl';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';

const NoRolesView = () => (
  <PanelError>
    <Message emptyState icon="database-error">
      <MessageText warning>
        <FormattedMessage {...messages.noRolesTitle} />
      </MessageText>
      <MessageText>
        <FormattedMessage {...messages.noRolesMessage} />
      </MessageText>
    </Message>
  </PanelError>
);

export default NoRolesView;
