import { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'components/modal/Modal';
import messages from 'messages';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import { ButtonsGroup, Button } from 'components/button';
import { CardBody, CardFooter } from 'components/card';
import ReportOptions from 'containers/partials/forms/ReportOptions';
import { MODAL_TYPES } from 'models/ModalData';
import { mailAllSessionReports } from 'services/aws/session-query';
import { StoreContext } from 'index';
import { useSessionContext } from 'contexts/SessionContext';
import Loader from 'components/loader/Loader';
import { uid } from 'react-uid';

export const mailAllReports = async (
  session,
  uiState,
  options,
  intl,
  setIsLoading,
  onCompleted
) => {
  setIsLoading && setIsLoading(true);

  const { benchmark, ...restOptions } = options;
  const data = {
    sessionId: session.id,
    options: restOptions
  };
  if (!session.isRehab && !session.isPrevention) {
    data.benchmarkId = benchmark;
  }
  const response = await mailAllSessionReports(data);

  if (response) {
    // @ts-ignore
    setIsLoading && setIsLoading(false);
    onCompleted && onCompleted();

    return uiState.showModal({
      title: intl.formatMessage(messages.modalReportSendToSporterSuccessTitle),
      message: intl.formatMessage(
        messages.modalReportSendToSporterSuccessMessage
      ),
      dismissButton: false,
      type: MODAL_TYPES.ALERT
    });
  }

  setIsLoading && setIsLoading(false);
};

const MailReportOptionsModal = ({ onClose }) => {
  const intl = useIntl();
  const { uiState } = useContext(StoreContext);
  const { session, options } = useSessionContext();
  const [isLoading, setIsLoading] = useState(false);

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal card largeBody isOpen={true} onClose={onCloseHandler}>
      {isLoading && <Loader />}
      <CardHeader modal>
        <CardHeaderTitle>
          {intl.formatMessage(messages.modalDownloadReportOptionsTitle)}
        </CardHeaderTitle>
        <CardHeaderSubtitle>
          {intl.formatMessage(messages.modalDownloadReportOptionsSubtitle)}
        </CardHeaderSubtitle>
      </CardHeader>

      <CardBody modal style={{ overflowY: 'scroll' }}>
        <ReportOptions
          key={uid('modalReportOptions')}
          inlineSelect
          openAccordion
          inModal
        />
      </CardBody>
      <CardFooter modal style={{ zIndex: 9 }}>
        <ButtonsGroup style={{ width: '100%', justifyContent: 'center' }}>
          <Button secondary onClick={onCloseHandler}>
            <FormattedMessage {...messages.buttonCancel} />
          </Button>
          <Button
            primary
            onClick={async () => {
              await mailAllReports(
                session,
                uiState,
                options,
                intl,
                setIsLoading,
                onCloseHandler
              );
            }}
          >
            <FormattedMessage {...messages.menuItemSessionMailAllReports} />
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
};

export default MailReportOptionsModal;
