import { makeAutoObservable } from 'mobx';
import ModalData, { MODAL_TYPES } from '../models/ModalData';

class UiState {
  locale = 'en';
  translations = 'en';
  pendingRequestCount = 0;
  isMenuOpen = false;
  modalData = null;
  isLocaleReady = false;
  homeRoute = '/';

  constructor() {
    let storage = localStorage.uiState;
    if (storage) {
      storage = JSON.parse(storage);
      this.locale = storage.locale ? storage.locale : 'en';
      this.translations = storage.translations
        ? storage.translations
        : this.locale;
    }

    makeAutoObservable(this);
  }

  setLocale(locale) {
    this.locale = locale ?? this.locale;
    this.persistEntity({ locale: this.locale });
    this.isLocaleReady = true;
  }

  setTranslations(locale) {
    this.translations = locale;
    this.persistEntity({ translations: this.translations });
  }

  setHomeRoute(route) {
    this.homeRoute = route;
  }

  get appIsInSync() {
    return this.pendingRequestCount === 0;
  }

  showModal({
    title,
    message,
    okLabel,
    okHandler,
    dismissHandler,
    dismissLabel,
    dismissButton,
    mutationData,
    type = MODAL_TYPES.ALERT
  }) {
    const modalData = new ModalData({
      title,
      message,
      okLabel,
      okHandler: () => {
        if (okHandler && typeof okHandler === 'function') okHandler();
        this.hideModal();
      },
      dismissLabel,
      dismissButton,
      dismissHandler: () => {
        if (dismissHandler && typeof dismissHandler === 'function')
          dismissHandler();
        this.hideModal();
      },
      mutationData,
      type
    });
    this.setModalData(modalData);
  }

  setModalData(modalData) {
    this.modalData = modalData;
  }

  hideModal() {
    this.modalData = null;
  }

  increasePendingRequest() {
    this.pendingRequestCount += 1;
  }

  decreasePendingRequest() {
    if (this.pendingRequestCount > 0) {
      this.pendingRequestCount -= 1;
    }
  }

  persistEntity(data) {
    let storage = localStorage.getItem('uiState');
    storage = storage ? JSON.parse(storage) : {};
    storage = { ...storage, ...data };
    try {
      localStorage.setItem('uiState', JSON.stringify(storage));
    } catch (e) {}
  }
}

export default UiState;
