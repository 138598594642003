import Modal from 'components/modal/Modal';
import {
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderTitle
} from 'components/card/card-header';
import { CardBody, CardFooter } from 'components/card';
import classNames from 'classnames';
import messages from 'messages';
import Cues from '../inputs/Cues';
import { Button, ButtonsGroup } from 'components/button';
import { FormattedMessage, useIntl } from 'react-intl';
import useExercise from 'hooks/queries/useExercise';
import { sortByArray } from 'utils/sort';
import MediaViewer from 'containers/partials/media/MediaViewer';
import { useEffect } from 'react';

const getNote = exercise => {
  if (exercise.notes && exercise.notes.length > 0) {
    return exercise.notes[0].note;
  } else {
    return exercise.note;
  }
};

function ExercisePreviewModal({
  entityId,
  data,
  data: { id, title, level, note, notes, cues, tags },
  onClose
}) {
  const intl = useIntl();
  const onCloseHandler = () => {
    if (onClose) onClose();
  };
  const { exercise, getExercise } = useExercise(entityId, id);

  useEffect(() => {
    getExercise();
  }, []);

  return (
    <Modal
      card
      isOpen={true}
      extraClassNames={'c-card-modal__content-body-scroll'}
      onClose={onCloseHandler}
    >
      <CardHeader modal>
        <CardHeaderTitle>{title}</CardHeaderTitle>
        {level && (
          <CardHeaderSubtitle>
            <FormattedMessage
              {...messages.labelLevel}
              values={{
                level: level
              }}
            />
          </CardHeaderSubtitle>
        )}
      </CardHeader>
      <CardBody modal>
        {(note || notes) && (
          <>
            <label className={classNames('c-input__label', {})}>
              <FormattedMessage {...messages.exercisesLabelNotes} />
            </label>
            <p>{getNote(data)}</p>
          </>
        )}

        {exercise.media && exercise.media.length > 0 && (
          <div
            className={
              'c-card__body--media u-padding-horizontal-large u-margin-bottom-medium'
            }
          >
            {/* http://localhost:3000/programs/7f747a4b-55e9-4641-ae25-c45de20fb32d/7bf6b6a4-d9ae-47fc-a14c-512fba1c5cab/exercises */}
            <div className="c-media c-media--main">
              {exercise.media && (
                <MediaViewer
                  entityId={entityId}
                  id={exercise.id}
                  media={
                    exercise.media.length > 0
                      ? exercise.meta?.order
                        ? sortByArray(exercise.media, exercise.meta.order)
                        : exercise.media
                      : []
                  }
                />
              )}
            </div>
          </div>
        )}

        {cues && cues.length > 0 && (
          <Cues
            entityId={entityId}
            label={`${intl.formatMessage(messages.exercisesLabelCues)}`}
            cues={cues}
            readOnly
          />
        )}

        {tags && tags.length > 0 && (
          <div className={'p-select-container__wrapper'}>
            <label className={classNames('c-input__label', {})}>
              <FormattedMessage {...messages.exercisesLabelTags} />
            </label>
            <div className={'p-select-container o-flex'}>
              {tags && tags.length > 0 ? (
                tags.map(tag => (
                  <span className={'p-select__multi-value'} key={tag.label}>
                    {tag.label}
                  </span>
                ))
              ) : (
                <span className={'p-select__value-empty'}></span>
              )}
            </div>
          </div>
        )}
      </CardBody>
      <CardFooter modal>
        <ButtonsGroup>
          <Button secondary onClick={onCloseHandler}>
            <FormattedMessage {...messages.cardButtonClose} />
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
}

export default ExercisePreviewModal;
