export default class ExerciseCopy {
  exerciseId;
  language;
  title;
  protocol;
  note;
  remarks;
  cues;

  constructor({
    exerciseId,
    language,
    title = '',
    protocol = '',
    note = '',
    remarks = '',
    cues = []
  }) {
    this.exerciseId = exerciseId;
    this.language = language;
    this.title = title ?? '';
    this.protocol = protocol ?? '';
    this.note = note ?? '';
    this.remarks = remarks ?? '';
    this.cues = cues; // ? JSON.parse(cues) : [];
  }

  static get modelName() {
    return 'ExerciseCopy';
  }
}
