import React, { createContext, useContext, useState } from 'react';

export const TogglerContext = createContext({});

const TogglerContextProvider = ({ children }) => {
  const [isOpen, setOpen] = useState([]);

  return (
    <TogglerContext.Provider value={{ isOpen, setOpen }}>
      {children}
    </TogglerContext.Provider>
  );
};

function useTogglerContext() {
  const context = useContext(TogglerContext);
  if (context === undefined) {
    throw new Error(
      'The TogglerContext hook must be used within a TogglerContext.Provider'
    );
  }
  return context;
}

export { TogglerContextProvider, useTogglerContext };
