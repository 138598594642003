import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'components/modal/Modal';

import messages from 'messages';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import { ButtonsGroup, Button } from 'components/button';
import { CardBody, CardFooter } from 'components/card';
import Textarea from 'components/input/Textarea';
import { QUERY_GET_EXERCISE_PROGRAM_TEMPLATE_JSON_LINK } from 'services/aws/exercises-query';
import { useQuery } from '@apollo/client';
import Loader from 'components/loader/Loader';
import Input from 'components/input/Input';
import { StoreContext } from 'index';

const ShowEmbedUrlModal = ({ isOpen, onClose, templateId }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();
  const [value, setValue] = useState('');

  const { loading, data } = useQuery(
    QUERY_GET_EXERCISE_PROGRAM_TEMPLATE_JSON_LINK,
    {
      variables: { templateId }
    }
  ); //

  useEffect(() => {
    if (data?.getExerciseProgramTemplateJsonLink) {
      const result = data.getExerciseProgramTemplateJsonLink;
      if (result) {
        setValue(result);
      }
    }
  }, [data]);

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  if (loading) return <Loader />;

  return (
    <Modal card autoHeight isOpen={isOpen} onClose={onCloseHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          {intl.formatMessage(messages.modalTrainingEmbedUrlTitle)}
        </CardHeaderTitle>
        <CardHeaderSubtitle>
          {intl.formatMessage(messages.modalTrainingEmbedSubtitle)}
        </CardHeaderSubtitle>
      </CardHeader>

      <CardBody modal>
        <Input
          id={'apiKey'}
          icon="copy"
          value={`x-api-key: ${user.activeEntity.entity.apiKeys[0]}`}
          disabled
        >
          <FormattedMessage {...messages.modalTrainingEmbedApiKeyLabel} />
        </Input>
        <Textarea
          id="requestUrl"
          icon="copy"
          value={value}
          rows="3"
          resizeVertical
          disabled
        >
          <FormattedMessage {...messages.modalTrainingEmbedURLLabel} />
        </Textarea>

        <Textarea
          id={'example'}
          icon="copy"
          rows="3"
          resizeVertical
          disabled
          value={`curl --location '${value}' --header 'x-api-key: ${user.activeEntity.entity.apiKeys[0]}'`}
        >
          <FormattedMessage {...messages.modalTrainingEmbedExampleLabel} />
        </Textarea>
      </CardBody>
      <CardFooter modal>
        <ButtonsGroup>
          <Button primary onClick={onCloseHandler}>
            <FormattedMessage {...messages.cardButtonClose} />
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
};

export default ShowEmbedUrlModal;
