import { useEffect, useState } from 'react';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { CardBody } from 'components/card';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages.js';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import { useUIDSeed } from 'react-uid';
import FieldInput from 'components/input/FieldInput';
import { VideoAnnotationStatus } from 'constants.js';
import useTags from 'hooks/queries/useTags';
import Checkbox from 'components/input/Checkbox';
import MultiOutsideTagsSelect from 'components/input/MultiOutsideTagsSelect';
import { useVideosContext } from 'contexts/VideosContext';
import { sort } from 'utils/sort';

const VideoFilterPanel = ({ entityId, onSubmit, onCancel }) => {
  const intl = useIntl();
  const uidSeed = useUIDSeed();
  const [behaviourOptions, setBehaviourOptions] = useState([]);
  const [selectedDimensions, setSelectedDimensions] = useState([]);
  const [selectedBehaviours, setSelectedBehaviours] = useState([]);
  const { videosState, videoActions } = useVideosContext();

  const { tags } = useTags({ entityId });

  const setFilter = filter => {
    videoActions.setFilter(filter);
  };

  const videoAnnotationStatus = [
    ...Object.keys(VideoAnnotationStatus).map(key => ({
      key: key,
      value: intl.formatMessage(VideoAnnotationStatus[key]),
      label: intl.formatMessage(VideoAnnotationStatus[key])
    }))
  ];

  useEffect(() => {
    if (videosState.filter.dimensions || videosState.filter.behaviours) {
      const dimensions = tags.filter(t =>
        videosState.filter.dimensions.includes(t.id)
      );
      setSelectedDimensions(dimensions);

      const behaviours = [
        ...dimensions.reduce((arr, item) => [...arr, ...item.children], [])
      ];

      setSelectedBehaviours(
        behaviours.filter(t => videosState.filter.behaviours.includes(t.id))
      );
      setBehaviourOptions(sort(behaviours, { keys: [{ key: 'title' }] }));
    }
  }, [tags, videosState.filter.dimensions, videosState.filter.behaviours]);

  const onStatusSelect = (e, value) => {
    let statuses = [...videosState.filter.status];
    if (e.target.checked) {
      statuses.push(value);
    } else {
      statuses = statuses.filter(s => s !== value);
    }
    setFilter({ ...videoActions.filter, status: statuses });
  };

  const onDimensionCheck = (e, value) => {
    let dimensions;

    if (e.target.checked) {
      dimensions = [...selectedDimensions, value];
    } else {
      dimensions = [...selectedDimensions.filter(d => d.id !== value.id)];
    }
    setSelectedDimensions([...dimensions]);

    const behaviours = [
      ...dimensions.reduce((arr, item) => [...arr, ...item.children], [])
    ];
    const remainingBehaviours = selectedBehaviours.filter(sb =>
      behaviours.find(b => b.id === sb.id)
    );
    setSelectedBehaviours(remainingBehaviours);
    setBehaviourOptions(sort(behaviours, { keys: [{ key: 'title' }] }));
    setFilter({
      ...videoActions.filter,
      dimensions: Array.from(new Set([...dimensions.map(d => d.id)])),
      behaviours: Array.from(new Set([...remainingBehaviours.map(v => v.id)]))
    });
  };

  const onSubmitFilter = () => {
    onSubmit && onSubmit();
  };

  const onCancelFilter = () => {
    videoActions.resetFilter();
    onCancel && onCancel();
  };

  const handleSelectChange = values => {
    setSelectedBehaviours(values);
    setFilter({
      ...videoActions.filter,
      dimensions: Array.from(new Set([...selectedDimensions.map(v => v.id)])),
      behaviours: Array.from(new Set([...values.map(v => v.id)]))
    });
  };

  return (
    <>
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.filterButton} />
        </CardHeaderTitle>
      </CardHeader>

      <CardBody modalSecondary style={{ paddingBottom: 128 }}>
        <div className="c-card__body-wrapper">
          <FieldInput
            id="name"
            name="name"
            value={videosState.filter.title ?? ''}
            onChange={e =>
              setFilter({ ...videosState.filter, title: e.target.value })
            }
            useNative
          >
            <FormattedMessage {...messages.videoFilterLabelName} />
          </FieldInput>
        </div>

        <div className="c-card__body-wrapper">
          <FieldInput
            id="owner"
            name="owner"
            value={videosState.filter.user ?? ''}
            onChange={e =>
              setFilter({ ...videosState.filter, user: e.target.value })
            }
            useNative
          >
            <FormattedMessage {...messages.videoFilterLabelOwner} />
          </FieldInput>
        </div>

        <div className="c-card__body-wrapper">
          <fieldset className="c-input-group">
            <legend className="c-input__label">
              <FormattedMessage {...messages.videoFilterLabelStatus} />
            </legend>
            {videoAnnotationStatus.map(status => (
              <Checkbox
                secondary
                key={status.key}
                id={uidSeed(status.key)}
                checked={videosState.filter.status.includes(status.key)}
                onChange={e => onStatusSelect(e, status.key)}
              >
                {status.label}
              </Checkbox>
            ))}
          </fieldset>
        </div>

        <div className="c-card__body-wrapper">
          <fieldset className="c-input-group c-input-group--two-cols">
            <legend className="c-input__label">
              <FormattedMessage {...messages.videoFilterLabelDimension} />
            </legend>
            {tags.map(tag => (
              <Checkbox
                secondary
                key={tag.id}
                id={uidSeed(tag.id)}
                checked={videosState.filter.dimensions.includes(tag.id)}
                onChange={e => onDimensionCheck(e, tag)}
              >
                {tag.label}
              </Checkbox>
            ))}
          </fieldset>
        </div>
        <div className="c-card__body-wrapper">
          <fieldset className="c-input-group">
            <legend className="c-input__label">
              <FormattedMessage {...messages.labelAnnotationTagsBehavior} />
            </legend>

            <MultiOutsideTagsSelect
              options={behaviourOptions}
              value={selectedBehaviours}
              isMulti
              outsideMultiValues
              isClearable={false}
              onChange={values => handleSelectChange(values)}
            />
          </fieldset>
        </div>
      </CardBody>

      <FormEditFooterButtons
        saveLabel={'filterButton'}
        edit
        onCancel={onCancelFilter}
        onSubmit={onSubmitFilter}
        topMargin
        extraClassNames={'c-card__footer-bottom-fixed'}
      />
    </>
  );
};

export default VideoFilterPanel;
