import { useEffect } from 'react';
import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { FormattedMessage } from 'react-intl';
import { CardBody, CardFooter } from 'components/card';
import { Button, ButtonsGroup } from 'components/button';
import messages from 'messages';
import Loader from 'components/loader/Loader';
import { useTestsContext } from 'contexts/TestsContext';
import TestsList from 'containers/partials/lists/TestsList';
import useSelectable from 'hooks/utils/useSelectable';

function AddExtraTestsModal({ type, onAdd, onClose }) {
  const { testsState, loading } = useTestsContext();
  const { selected, setSelected } = useSelectable([]);

  useEffect(() => {
    let ids = [...testsState.selectedTests.map(t => t.id)];
    if (testsState.extraTests) {
      ids = [...ids, ...testsState.extraTests.map(t => t.id)];
    }
    setSelected(ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testsState.selectedTests, testsState.extraTests]);

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  const onAddHandler = () => {
    if (testsState.allTests) {
      if (onAdd)
        onAdd(testsState.allTests.filter(t => selected.includes(t.id)));
    }
  };

  return (
    <Modal card isOpen={true} onClose={onCloseHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.preventionAddExtraTestsModalTitle} />
        </CardHeaderTitle>
      </CardHeader>

      <CardBody modal>
        {loading && <Loader />}
        <TestsList
          type={type}
          checkable
          edit
          selectedTests={selected}
          onSelect={exerciseIds => setSelected(exerciseIds)}
        />
      </CardBody>
      <CardFooter modal>
        <ButtonsGroup>
          <Button type="button" secondary onClick={() => onCloseHandler()}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button type="button" primary onClick={() => onAddHandler()}>
            <FormattedMessage {...messages.addButton} />
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
}

export default AddExtraTestsModal;
