import { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { CardBody, CardFooter } from 'components/card';
import messages from 'messages';
import { Button, ButtonsGroup } from 'components/button';
import {
  MUTATION_ADD_PERSONS_TO_TESTSESSION,
  QUERY_GET_SESSION_BY_ID
} from 'services/aws/session-query';
import { useSessionContext } from 'contexts/SessionContext';
import { useNotificationQueue } from 'components/notification';
import SelectPersonsCard from 'containers/partials/cards/SelectPersonsCard';
import { StoreContext } from 'index';
import Person from 'models/Person';

function AssignPersonsToSessionModal({ entityId, onClose, onDone }) {
  const {
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const intl = useIntl();
  const { session } = useSessionContext();
  const notification = useNotificationQueue();

  const [excludedPersons, setExcludedPersons] = useState([]);
  const [selectedPersons, setSelectedPersons] = useState([]);

  const [assignPersons] = useMutation(MUTATION_ADD_PERSONS_TO_TESTSESSION);

  useEffect(() => {
    if (session) {
      setExcludedPersons(session.personEntities.map(p => new Person(p.person)));
    }
  }, [session]);

  const onAssignPersonHandler = async persons => {
    setSelectedPersons(
      persons.filter(p => !excludedPersons.find(exp => exp.id === p.id))
    );
  };

  const onAddPersonsHandler = async persons => {
    if (session.id) {
      try {
        const { data } = await assignPersons({
          variables: {
            testSessionId: session.id,
            personIds: persons.map(p => p.id)
          },
          refetchQueries: [
            {
              query: QUERY_GET_SESSION_BY_ID,
              variables: { entityId, testSessionId: session.id }
            }
          ]
        });
        notification.add(data.addPersonsToTestSession.id, {
          message: intl.formatMessage(
            messages.messageAthletesAddedToTestSession
          )
        });
        if (onDone) onDone();
      } catch (e) {
        Sentry.captureException(e);
        if (onDone) onDone();
      }
    }
  };

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal
      card
      isOpen={true}
      onClose={onCloseHandler}
      extraClassNames={'c-card-modal__content-body-scroll'}
    >
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.titleSessionAssignUsers} />
        </CardHeaderTitle>
      </CardHeader>

      <CardBody modal bodyWithHeader>
        <SelectPersonsCard
          rootEntityId={rootEntityId}
          onSelectPersons={persons => onAssignPersonHandler(persons)}
          onModal
          onlyPersons
          selectedPersons={selectedPersons}
          excludedPersons={excludedPersons}
        />
      </CardBody>
      <CardFooter modal extraClassNames="c-card__footer--modal-larger">
        <ButtonsGroup>
          <Button secondary onClick={() => onCloseHandler()}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button
            type="button"
            primary
            disabled={selectedPersons.length === 0}
            onClick={() => onAddPersonsHandler(selectedPersons)}
          >
            <FormattedMessage
              {...messages.addPersonsButton}
              values={{ count: selectedPersons.length }}
            />
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
}

export default AssignPersonsToSessionModal;
