export const VALID_PASSWORD = 'isValid';
export const ERROR_PASSWORD_NOT_EQUAL = 'notEqual';
export const ERROR_PASSWORD_EMPTY = 'empty';
export const ERROR_PASSWORD_TOO_SHORT = 'tooShort';
export const ERROR_PASSWORD_NO_ALPHABETICAL_CHAR = 'noAlphabeticalChar';
export const ERROR_PASSWORD_NO_NUMERICAL_CHAR = 'noNumericalChar';

export const isPasswordValid = password => {
  if (password) {
    if (password.length >= 8) {
      if (password.match(/[a-z]/i)) {
        if (password.match(/[0-9]/i)) {
          return VALID_PASSWORD;
        }
        return ERROR_PASSWORD_NO_NUMERICAL_CHAR;
      }
      return ERROR_PASSWORD_NO_ALPHABETICAL_CHAR;
    }
    return ERROR_PASSWORD_TOO_SHORT;
  }
  return ERROR_PASSWORD_EMPTY;
};
