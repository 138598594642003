import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { useVirtual } from 'react-virtual';
import useSelectable from 'hooks/utils/useSelectable';
import { useUIDSeed } from 'react-uid';
import TemplatesListItem from 'containers/partials/list-items/TemplatesListItem';

const TemplateListVirtualized = ({
  entityId,
  activeId,
  checkable,
  templates = [],
  onClick,
  onUse,
  onEdit,
  onDeleted,
  onSelect,
  selectedTemplates,
  extraClassNames = {},
  onModal,
  withFixedFooter
}) => {
  const parentRef = useRef();
  const uidSeed = useUIDSeed();
  const { selected, select, setSelected } = useSelectable([]);
  const [minHeight, setMinHeight] = useState(null);

  useEffect(() => {
    if (selectedTemplates) {
      setSelected(selectedTemplates.map(e => e.id));
    }
  }, [templates, selectedTemplates]);

  const rowVirtualizer = useVirtual({
    size: templates.length,
    parentRef,
    estimateSize: React.useCallback(() => 56, []),
    overscan: 5
  });

  const onSelectHandler = (template, checked) => {
    const selectIds = select(template.id);
    onSelect && onSelect(selectIds, template, checked);
  };

  useLayoutEffect(() => {
    if (parentRef.current) {
      const roundedTop = parentRef.current
        .getBoundingClientRect()
        .top.toFixed(0);

      setMinHeight(`calc(100vh - 16px - ${roundedTop}px)`);
    }
  }, []);

  return (
    <>
      {templates.length > 0 ? (
        <List
          ref={parentRef}
          virtualizedList
          style={{
            height: withFixedFooter ? 'calc(100% - 64px)' : '100%',
            paddingTop: 16
          }}
          extraClassNames={extraClassNames}
        >
          <ListBody
            style={{
              minHeight: !onModal && minHeight,
              height: `${rowVirtualizer.totalSize}px`,
              position: 'relative',
              marginBottom: 0
            }}
          >
            {rowVirtualizer.virtualItems.map(virtualRow => {
              const template = templates[virtualRow.index];
              return (
                <TemplatesListItem
                  entityId={entityId}
                  id={uidSeed(template.id)}
                  key={virtualRow.index}
                  virtualRow={virtualRow}
                  template={template}
                  active={template.id === activeId}
                  visible={checkable && selected.length > 0}
                  checkbox={checkable}
                  clickable
                  checked={checkable && selected.includes(template.id)}
                  onChange={e => onSelectHandler(template, e)}
                  onClick={() => onClick && onClick(template.id)}
                  onUse={() => onUse && onUse(template)}
                  onEdit={() => onEdit && onEdit(template.id)}
                  onDeleted={() => onDeleted && onDeleted(template.id)}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '48px',
                    transform: `translateY(${virtualRow.start}px)`
                  }}
                />
              );
            })}
          </ListBody>
        </List>
      ) : (
        <Card centered>
          <CardBody empty>
            <Message emptyState={true} icon="cursor">
              <MessageText>
                <FormattedMessage {...messages.templateListEmpty} />
              </MessageText>
            </Message>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default TemplateListVirtualized;
