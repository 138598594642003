import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '../button';
import Icon from '../icon/Icon';

class PageHeader extends React.Component {
  render() {
    const { fixed, showSidebar } = this.props;

    return (
      <div
        className={classNames('c-page__header', {
          'c-page__header--fixed': fixed
        })}
      >
        <Button
          onClick={showSidebar}
          tiny
          extraClassNames="c-button--sidebar-trigger"
        >
          <Icon id="hamburger" />
        </Button>
        {this.props.children}
      </div>
    );
  }
}
PageHeader.defaultProps = {
  visible: false
};

PageHeader.propTypes = {
  visible: PropTypes.bool
};

export default PageHeader;
