import React from 'react';
import { Form, Formik } from 'formik';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useNotificationQueue } from 'components/notification';
import messages from 'messages';
import {
  MUTATION_ADD_GROUP_ENTITY,
  MUTATION_UPDATE_ENTITY
} from 'services/aws/entity-query';
import { S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID } from 'services/aws/client-query';

function GroupForm({ entityId, children, extraClassNames, group, mode }) {
  const notification = useNotificationQueue();
  const intl = useIntl();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage(messages.sessionNameRequired)
    ),
    grade: Yup.number()
      .min(1, intl.formatMessage(messages.minimumValidation, { value: 1 }))
      .max(12, intl.formatMessage(messages.maximumValidation, { value: 12 }))
      .typeError(intl.formatMessage(messages.gradeNotANumber))
  });

  const [addGroup, { data: groupAddData }] = useMutation(
    MUTATION_ADD_GROUP_ENTITY
  );
  const [editGroup, { data: groupEditData }] = useMutation(
    MUTATION_UPDATE_ENTITY
  );

  const onSubmitHandler = async (groupData, { resetForm }) => {
    const meta = {};
    if (groupData.grade) {
      meta.grade = groupData.grade;
    }
    if (groupData.year) {
      meta.year = groupData.year;
    }

    const group = {
      name: groupData.name,
      meta: JSON.stringify(meta)
    };

    if (mode === 'edit') {
      //edit
      group.id = entityId;
      await editGroup({
        variables: { ...group },
        refetchQueries: [
          {
            query: S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID,
            variables: { id: entityId }
          }
        ]
      }).then(res => {
        notification.add(res.data.editEntity.id, {
          message: intl.formatMessage(messages.messageGroupUpdated)
        });
        //if (onComplete) return onComplete(res);
      });
    } else {
      // add
      group.parentId = entityId;
      await addGroup({
        variables: {
          ...group
        },
        refetchQueries: [
          {
            query: S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID,
            variables: { id: entityId }
          }
        ]
      }).then(res => {
        //onSubmitted();
        resetForm();
        notification.add(res.data.createEntity.id, {
          message: intl.formatMessage(messages.messageGroupSaved)
        });

        //if (onComplete) return onComplete(res);

        /*push(
          generatePath(ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION, {
            entityId,
            type: PreventionType.BASIC,
            sessionId: res.data.addTestSession.id
          })
        );*/
      });
    }
  };

  return (
    <Formik
      initialValues={group}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={false}
      onSubmit={onSubmitHandler}
    >
      {props => {
        return (
          <Form className={classNames(extraClassNames)} noValidate>
            {typeof children === 'function' ? children(props) : children}
          </Form>
        );
      }}
    </Formik>
  );
}

export default GroupForm;
