import React from 'react';
import {
  ListItem,
  ListItemStatus,
  ListItemLabelWrapper,
  ListItemLabel
} from 'components/list/list-item';

export const ScannerListItem = ({
  scanner: { id, name = 'default scanner name' },
  active,
  onClick
}) => (
  <ListItem active={active} onClick={onClick}>
    <ListItemStatus statusIcon="scanner" itemId={`id${id}`} />
    <ListItemLabelWrapper>
      <ListItemLabel label>{name}</ListItemLabel>
    </ListItemLabelWrapper>
  </ListItem>
);
