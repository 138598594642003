import React from 'react';
import './Organisation.scss';
import Icon from 'components/icon/Icon';

const Organisation = ({ to, name, logo }) => {
  return to ? (
    <a className="c-organisation" href={to} target="_blank" rel="noreferrer">
      {logo && <img src={logo} alt={name} />}
      {name}
      <Icon id="breadcrumb" fillColor="color-neutral-steel" />
    </a>
  ) : (
    <div className="c-organisation">
      {logo && <img src={logo} alt={name} />}
      {name}
      <Icon id="breadcrumb" fillColor="color-neutral-steel" />
    </div>
  );
};

export default Organisation;
