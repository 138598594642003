import React, { Fragment, useContext } from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import {
  withRouter,
  Redirect,
  generatePath,
  Route,
  Switch
} from 'react-router-dom';
import {
  ROUTE_EXERCISES_PROGRAMS_ENTITY,
  ROUTE_EXERCISES_TEMPLATES_ENTITY,
  ROUTE_EXERCISES_ENTITY_EXERCISE,
  ROUTE_EXERCISES_ENTITY_EXERCISE_ADD,
  ROUTE_EXERCISES_ENTITY_EXERCISE_EDIT,
  ROUTE_EXERCISES_INDEX,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM,
  ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE,
  ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EDIT,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_ATHLETES,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_ATHLETES_PERSON,
  ROUTE_EXERCISES_ENTITY_EXERCISE_LANGUAGE,
  ROUTE_EXERCISES_ENTITY,
  ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EXERCISES_EXERCISE,
  ROUTE_EXERCISES,
  ROUTE_SPORTERS_ENTITY
} from 'routes/RouteList';
import Panel from 'components/panel/Panel';
import Overview from './Overview';
import Exercises from './exercises/Exercises';
import messages from 'messages';
import AddExercise from './exercises/AddExercise';
import Exercise from './exercises/Exercise';
import Programs from './programs/Programs';
import Templates from './templates/Templates';
import EditProgram from './programs/EditProgram';
import Template from './templates/Template';
import EditTemplate from './templates/EditTemplate';
import { ExercisesProvider } from 'contexts/ExercisesContext';
import { PreviewModalProvider } from 'contexts/PreviewModalContext';
import ExercisesLanguages from 'containers/pages/exercises/exercises/ExercisesLanguages';
import { StoreContext } from 'index';
import { TemplatesProvider } from 'contexts/TemplatesContext';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import { ProgramProvider } from 'contexts/ProgramContext';
import { GroupsContextProvider } from 'contexts/GroupsContext';
import NoAccessPanel from 'containers/pages/auth/NoAccessPanel';

const ExercisesIndex = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const ability = useAbility(AbilityContext);
  const intl = useIntl();

  if (ability.cannot('read', 'Exercises')) {
    return (
      <NoAccessPanel
        entityId={user.rootEntityId}
        emptyLabel={intl.formatMessage(messages.noAccessEmptyPanel)}
        redirectRoute={generatePath(ROUTE_SPORTERS_ENTITY, {
          entityId: user.rootEntityId
        })}
      />
    );
  }

  return (
    <>
      <TemplatesProvider entityId={user.rootEntityId}>
        <ExercisesProvider user={user} entityId={user.rootEntityId}>
          <PreviewModalProvider entityId={user.rootEntityId}>
            <Switch>
              <Route
                exact
                path={ROUTE_EXERCISES_INDEX}
                render={props => (
                  <Fragment>
                    <Overview
                      entityId={user.rootEntityId}
                      active={ROUTE_EXERCISES_INDEX === props.match.path}
                    />
                    <Panel
                      emptyState
                      emptyLabel={intl.formatMessage(
                        messages.exercisesEmptyPanel
                      )}
                    />
                  </Fragment>
                )}
              />

              <Route
                exact
                path={ROUTE_EXERCISES_PROGRAMS_ENTITY}
                render={props => (
                  <Fragment>
                    <Overview
                      entityId={user.rootEntityId}
                      active={ROUTE_EXERCISES_ENTITY === props.match.path}
                    />
                    <Programs
                      entityId={user.rootEntityId}
                      active={
                        ROUTE_EXERCISES_PROGRAMS_ENTITY === props.match.path
                      }
                    />
                  </Fragment>
                )}
              />

              <Route
                exact
                path={[
                  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM,
                  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT,
                  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_ATHLETES,
                  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_ATHLETES_PERSON,
                  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES
                ]}
                render={props => (
                  <Fragment>
                    <ProgramProvider>
                      <ExercisesProvider
                        user={user}
                        entityId={props.match.params.entityId}
                      >
                        <GroupsContextProvider
                          entityId={props.match.params.entityId}
                          rootEntityId={user.rootEntityId}
                        >
                          <EditProgram
                            entityId={props.match.params.entityId}
                            programId={props.match.params.programId}
                            personId={props.match.params.personId}
                          />
                        </GroupsContextProvider>
                      </ExercisesProvider>
                    </ProgramProvider>
                  </Fragment>
                )}
              />

              <Route
                exact
                path={ROUTE_EXERCISES_TEMPLATES_ENTITY}
                render={props => (
                  <Fragment>
                    <Overview entityId={user.rootEntityId} />
                    <Templates
                      entityId={user.rootEntityId}
                      active={
                        ROUTE_EXERCISES_TEMPLATES_ENTITY === props.match.path
                      }
                    />
                  </Fragment>
                )}
              />

              <Route
                exact
                path={ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EDIT}
                render={props => (
                  <ProgramProvider>
                    <ExercisesProvider
                      user={user}
                      entityId={props.match.params.entityId}
                    >
                      <EditTemplate
                        edit={true}
                        entityId={props.match.params.entityId}
                        templateId={props.match.params.templateId}
                      />
                    </ExercisesProvider>
                  </ProgramProvider>
                )}
              />

              <Route
                exact
                path={[
                  ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE,
                  ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EXERCISES_EXERCISE
                ]}
                render={props => (
                  <Fragment>
                    <Templates
                      entityId={props.match.params.entityId}
                      templateId={props.match.params.templateId}
                      active={
                        ROUTE_EXERCISES_TEMPLATES_ENTITY === props.match.path
                      }
                    />
                    <Template
                      entityId={props.match.params.entityId}
                      templateId={props.match.params.templateId}
                      exerciseId={props.match.params.exerciseId}
                      active={
                        ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE ===
                        props.match.path
                      }
                    />
                    <Route
                      exact
                      path={[
                        ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EXERCISES_EXERCISE
                      ]}
                      render={props => (
                        <Exercise
                          entityId={props.match.params.entityId}
                          active={
                            ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EXERCISES_EXERCISE ===
                            props.match.path
                          }
                          editable={false}
                          inTemplate
                          exerciseId={props.match.params.exerciseId}
                          parentPath={generatePath(
                            ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EXERCISES_EXERCISE,
                            {
                              entityId: props.match.params.entityId,
                              templateId: props.match.params.templateId,
                              exerciseId: props.match.params.exerciseId
                            }
                          )}
                          crumbs={[
                            {
                              path: generatePath(ROUTE_EXERCISES, {
                                entityId: props.match.params.entityId
                              }),
                              label: intl.formatMessage(
                                messages.breadcrumbOverview
                              )
                            },
                            {
                              path: generatePath(
                                ROUTE_EXERCISES_TEMPLATES_ENTITY,
                                {
                                  entityId: props.match.params.entityId
                                }
                              ),
                              label: intl.formatMessage(
                                messages.breadcrumbTemplates
                              )
                            },
                            {
                              path: generatePath(
                                ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE,
                                {
                                  entityId: props.match.params.entityId,
                                  templateId: props.match.params.templateId
                                }
                              ),
                              label: intl.formatMessage(
                                messages.breadcrumbTemplate
                              )
                            }
                          ]}
                        />
                      )}
                    />
                  </Fragment>
                )}
              />

              <Route
                exact
                path={ROUTE_EXERCISES_ENTITY}
                render={props => (
                  <Fragment>
                    <Overview entityId={user.rootEntityId} />
                    <ExercisesProvider user={user} entityId={user.rootEntityId}>
                      <Exercises
                        entityId={user.rootEntityId}
                        active={ROUTE_EXERCISES_ENTITY === props.match.path}
                        crumbs={[
                          {
                            path: generatePath(ROUTE_EXERCISES, {
                              entityId: user.rootEntityId
                            }),
                            label: intl.formatMessage(
                              messages.breadcrumbOverview
                            )
                          }
                        ]}
                      />
                    </ExercisesProvider>
                  </Fragment>
                )}
              />

              <Route
                exact
                path={ROUTE_EXERCISES_ENTITY_EXERCISE_ADD}
                render={props => (
                  <ExercisesProvider
                    user={user}
                    entityId={props.match.params.entityId}
                  >
                    <Exercises
                      entityId={props.match.params.entityId}
                      active={ROUTE_EXERCISES_ENTITY === props.match.path}
                      crumbs={[
                        {
                          path: generatePath(ROUTE_EXERCISES, {
                            entityId: props.match.params.entityId
                          }),
                          label: intl.formatMessage(messages.breadcrumbOverview)
                        }
                      ]}
                    />
                    <AddExercise
                      entityId={props.match.params.entityId}
                      active={
                        ROUTE_EXERCISES_ENTITY_EXERCISE_ADD === props.match.path
                      }
                    />
                  </ExercisesProvider>
                )}
              />

              <Route
                exact
                path={ROUTE_EXERCISES_ENTITY_EXERCISE_LANGUAGE}
                render={props => (
                  <ExercisesLanguages
                    entityId={props.match.params.entityId}
                    exerciseId={props.match.params.exerciseId}
                    lang={props.match.params.lang}
                  />
                )}
              />

              <Route
                exact
                path={[
                  ROUTE_EXERCISES_ENTITY_EXERCISE,
                  ROUTE_EXERCISES_ENTITY_EXERCISE_EDIT
                ]}
                render={props => (
                  <ExercisesProvider
                    user={user}
                    entityId={props.match.params.entityId}
                  >
                    <Exercises
                      entityId={props.match.params.entityId}
                      exerciseId={props.match.params.exerciseId}
                      active={ROUTE_EXERCISES_ENTITY === props.match.path}
                      crumbs={[
                        {
                          path: generatePath(ROUTE_EXERCISES, {
                            entityId: user.rootEntityId
                          }),
                          label: intl.formatMessage(messages.breadcrumbOverview)
                        }
                      ]}
                    />
                    <Exercise
                      exerciseId={props.match.params.exerciseId}
                      entityId={props.match.params.entityId}
                      active={
                        ROUTE_EXERCISES_ENTITY_EXERCISE === props.match.path
                      }
                      edit={
                        ROUTE_EXERCISES_ENTITY_EXERCISE_EDIT ===
                        props.match.path
                      }
                      editable={ability.can('create', 'Exercise')}
                      parentPath={generatePath(
                        ROUTE_EXERCISES_ENTITY_EXERCISE,
                        {
                          entityId: props.match.params.entityId,
                          exerciseId: props.match.params.exerciseId
                        }
                      )}
                      crumbs={[
                        {
                          path: ROUTE_EXERCISES_INDEX,
                          label: intl.formatMessage(messages.breadcrumbOverview)
                        },
                        {
                          path: generatePath(ROUTE_EXERCISES_ENTITY, {
                            entityId: props.match.params.entityId
                          }),
                          label: intl.formatMessage(
                            messages.breadcrumbExercises
                          )
                        }
                      ]}
                    />
                  </ExercisesProvider>
                )}
              />
              <Redirect
                to={generatePath(ROUTE_EXERCISES_INDEX, {
                  entityId: user.rootEntityId
                })}
              />
            </Switch>
          </PreviewModalProvider>
        </ExercisesProvider>
      </TemplatesProvider>
    </>
  );
};

export default withRouter(observer(ExercisesIndex));
