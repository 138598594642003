import { useState, useEffect } from 'react';
import { Card, CardBody } from 'components/card';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import messages from 'messages';
import Tags from '../inputs/Tags';
import TemplateSelect from '../inputs/TemplateSelect';
import { useExercisesContext } from 'contexts/ExercisesContext';
import ExercisesListVirtualized from 'containers/partials/lists/ExercisesListVirtualized';
import Loader from 'components/loader/Loader';
import FieldInput from 'components/input/FieldInput';

function SelectExercisesCard({ entityId, edit, onPreview }) {
  const { exercisesState, exerciseActions, loading } = useExercisesContext();

  const intl = useIntl();

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [filter, setFilter] = useState({ search: '', tags: [] });

  useEffect(() => {
    exerciseActions.getExercises();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = e => {
    setFilter({ ...filter, search: e.target.value });
    exerciseActions.setFilter({ search: e.target.value });
  };

  const handleTagChange = tags => {
    setFilter({ ...filter, tags });
    exerciseActions.setFilter({ tags });
  };

  const handleTemplateChange = template => {
    setSelectedTemplate(template);
    exerciseActions.setExercises(template?.exercises);
  };

  return (
    <Card secondary>
      <CardHeader secondary extraClassNames="u-margin-bottom-medium">
        <CardHeaderTitle>
          <FormattedMessage {...messages.programDetailSelectExercises} />
        </CardHeaderTitle>
        <FieldInput id="title" inline onChange={onSearch}>
          <FormattedMessage {...messages.exercisesProgramFilterNameLabel} />
        </FieldInput>
        <TemplateSelect
          entityId={entityId}
          onChange={select => handleTemplateChange(select)}
          value={selectedTemplate ? selectedTemplate.id : ''}
        />
        <Tags
          extraClassNames={
            'o-flex--align-start o-flex--justify-space-between u-margin-bottom-medium'
          }
          entityId={entityId}
          label={intl.formatMessage(messages.exercisesProgramTagsLabel)}
          isMulti
          isClearable={false}
          creatable={false}
          placeholder={intl.formatMessage(
            messages.exercisesProgramTagsPlaceHolder
          )}
          noOptionsMessage={intl.formatMessage(
            messages.exercisesProgramTagsNoOption
          )}
          inline
          onChange={handleTagChange}
        />
        {/* <CardHeaderButtons>
          <Button tiny>Select All</Button>
        </CardHeaderButtons> */}
      </CardHeader>

      <CardBody secondary resizableHeight style={{ padding: 0 }}>
        {loading && <Loader />}
        <ExercisesListVirtualized
          extraClassNames="u-padding-horizontal-medium"
          onPreview={onPreview}
          visible={edit}
          checkable={edit}
          enablePreviewModal
          showLevel
          exercises={exercisesState.exercises}
          onSelect={(exerciseIds, exercise, checked) => {
            if (!edit) return;
            if (checked) {
              exerciseActions.selectExercises([exercise]);
            } else {
              exerciseActions.unselectExercises([exercise]);
            }
          }}
          selectedExercises={exercisesState.selectedExercises}
        />
      </CardBody>
    </Card>
  );
}

export default observer(SelectExercisesCard);
