import React, { Component } from 'react';
import { CardBody } from 'components/card';
import Icon from 'components/icon/Icon';
import { ButtonsGroup, Button } from 'components/button';
import { injectIntl } from 'react-intl';
import messages from 'messages';

class GrowthPredictionAlertCardBody extends Component {
  render() {
    const {
      intl,
      alertData: {
        message = intl.formatMessage(messages.errorSomethingWentWrong),
        dismissLabel = intl.formatMessage(messages.cardButtonCancel),
        dismissButton = true,
        okLabel = intl.formatMessage(messages.buttonOK),
        okHandler,
        dismissHandler
      }
    } = this.props;
    return (
      <CardBody benchmarks>
        <Icon id="database-error" mediumIcon />
        <p>{message}</p>
        <ButtonsGroup>
          {dismissButton && (
            <Button small secondary onClick={dismissHandler}>
              {dismissLabel}
            </Button>
          )}
          {okHandler && (
            <Button small primary onClick={okHandler}>
              {okLabel}
            </Button>
          )}
        </ButtonsGroup>
      </CardBody>
    );
  }
}

export default injectIntl(GrowthPredictionAlertCardBody);
