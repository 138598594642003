import { useFormikContext } from 'formik';
import FieldInput from 'components/input/FieldInput';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { FieldTextarea } from 'components/input/FieldTextarea';

function TestSetDetail({ edit }) {
  const { errors, touched } = useFormikContext();
  return (
    <>
      <FieldInput
        id="title"
        name="title"
        errors={errors}
        touched={touched}
        readOnly={!edit}
        required
      >
        <FormattedMessage {...messages.modalTestSetLabelName} />
      </FieldInput>

      <FieldTextarea
        id="note"
        name="note"
        rows="4"
        errors={errors}
        touched={touched}
        readOnly={!edit}
        resizeVertical={edit}
      >
        <FormattedMessage {...messages.modalTestSetLabelNotes} />
      </FieldTextarea>
    </>
  );
}

export default TestSetDetail;
