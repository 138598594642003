import { forwardRef, useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ErrorMessage, getIn, FormikContext, Field } from 'formik';
import InputErrorMessage from './InputErrorMessage';
import Icon from 'components/icon/Icon';

const FieldInput = forwardRef((props, ref) => {
  const {
    id,
    children,
    extraClassNames,
    iconLeft,
    inline,
    inTable,
    large,
    rounded,
    url,
    hasError,
    errorMessage,
    errors,
    touched,
    icon,
    iconColor,
    type,
    autoFocus,
    autoComplete = 'off',
    disabled,
    inputMode,
    max,
    maxLength,
    min,
    minLength,
    multiple,
    name,
    pattern,
    placeholder,
    readOnly,
    required,
    step,
    title,
    value,
    defaultValue,
    style,
    onBlur,
    onChange,
    onFocus,
    onMouseUp, // FieldInput
    innerRef, // FieldInput
    useNative,
    tabIndex
  } = props;

  const context = useContext(FormikContext);

  const handleKeyDown = e => {
    const { onKeyEnter, onKeyBackspace } = props;
    if (e.key === 'Enter') {
      if (onKeyEnter) onKeyEnter();
    }

    if (e.key === 'Backspace') {
      if (onKeyBackspace) onKeyBackspace();
    }
  };

  return (
    <div
      className={classNames('c-input__group', {
        'c-input--read-only': readOnly,
        'c-input--disabled': disabled,
        'c-input--icon-left': iconLeft,
        'c-input--inline': inline
      })}
      style={style}
    >
      {id && children && (
        <label htmlFor={id} className={classNames('c-input__label')}>
          {children}
        </label>
      )}
      <div className={classNames('c-input__wrapper')}>
        {/* Using it within Formik */}
        {!useNative && context && !defaultValue ? (
          <Field
            id={id}
            className={classNames('c-input', extraClassNames, {
              'c-input--in-table': inTable,
              'c-input--large': large,
              'c-input--rounded': rounded,
              'c-input--url': url,
              'has-error':
                errors?.[name] &&
                touched?.[name] &&
                typeof errors[name] === 'string',
              'c-input--range': type === 'range'
            })}
            type={type}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            disabled={disabled}
            inputMode={inputMode}
            max={max}
            maxLength={maxLength}
            min={min}
            minLength={minLength}
            multiple={multiple}
            name={name}
            pattern={pattern}
            placeholder={placeholder}
            readOnly={readOnly}
            required={required}
            step={step}
            title={title}
            style={{ color: hasError ? 'red' : '' }}
            onBlur={onBlur}
            onKeyUp={onChange}
            onMouseUp={onMouseUp}
            onClick={e => e.stopPropagation()}
            innerRef={innerRef}
            // value={value ? value : undefined}
            tabIndex={tabIndex}
          />
        ) : (
          // Using it without Formik */}
          <input
            id={id}
            className={classNames('c-input', extraClassNames, {
              'c-input--in-table': inTable,
              'c-input--large': large,
              'c-input--rounded': rounded,
              'c-input--url': url,
              'has-error':
                props?.errors?.[name] &&
                props?.touched?.[name] &&
                typeof errors[name] === 'string'
            })}
            type={type}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            disabled={disabled}
            inputMode={inputMode}
            max={max}
            maxLength={maxLength}
            min={min}
            minLength={minLength}
            multiple={multiple}
            name={name}
            pattern={pattern}
            placeholder={placeholder}
            readOnly={readOnly || !onChange}
            required={required}
            step={step}
            title={title}
            value={value}
            defaultValue={defaultValue}
            style={{ color: hasError ? 'red' : '' }}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onKeyDown={e => handleKeyDown(e)}
            ref={ref}
            tabIndex={tabIndex}
          />
        )}

        {(icon ||
          (props?.errors?.[name] &&
            props?.touched?.[name] &&
            typeof errors[name] === 'string')) &&
          !readOnly && (
            <Icon
              id={
                props?.errors?.[name] &&
                props?.touched?.[name] &&
                typeof errors[name] === 'string'
                  ? 'validation-error'
                  : icon
              }
              fillColor={iconColor}
              extraClassNames={classNames('c-icon--input', {
                'c-icon--input-left': iconLeft
              })}
            />
          )}
      </div>
      {/*{errorMessage && <InputErrorMessage>{errorMessage}</InputErrorMessage>}*/}
      {errors &&
        errors?.[name] &&
        touched?.[name] &&
        typeof errors[name] === 'string' && (
          <ErrorMessage name={name} component={InputErrorMessage} />
        )}
      {errors && typeof errors[name] !== 'string' && getIn(errors, name) && (
        <InputErrorMessage>{getIn(errors, name)}</InputErrorMessage>
      )}
    </div>
  );
});

const inputPropTypes = {
  id: PropTypes.string,
  children: PropTypes.node,

  // CSS Classes added on wrapper
  extraClassNames: PropTypes.string, // also added in input ???
  iconLeft: PropTypes.bool,
  inline: PropTypes.bool,
  // readOnly
  // disabled

  // CSS Classes added on input
  inTable: PropTypes.bool,
  large: PropTypes.bool,
  rounded: PropTypes.bool,
  url: PropTypes.bool,

  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  errors: PropTypes.any,
  touched: PropTypes.any,

  icon: PropTypes.string,
  iconColor: PropTypes.string,

  type: PropTypes.string, // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#input_types

  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  inputMode: PropTypes.string, // possible values: none, text, tel, url, email, numeric, decimal, and search
  max: PropTypes.number, // for numeric typesL: Max Value
  maxLength: PropTypes.number, // for password, search, tel, text, url: number of chars of value
  min: PropTypes.number, // for numeric types: Min Value
  minLength: PropTypes.number, // for password, search, tel, text, url: number of chars of value
  multiple: PropTypes.bool, // for email, file
  name: PropTypes.string,
  pattern: PropTypes.string, // for password, text, tel
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool, // for text, search, url, tel, email, date, month, week, time, datetime-local, number, and password input types
  required: PropTypes.bool,
  step: PropTypes.number, // for numeric types
  title: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,

  style: PropTypes.object,

  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  useNative: PropTypes.bool
};

FieldInput.propTypes = inputPropTypes;

export default FieldInput;
