import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Icon from 'components/icon/Icon';
import Button from 'components/button/Button';
import Input from 'components/input/Input';

import { Card, CardFooter, CardBody } from 'components/card';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderButtons
} from 'components/card/card-header';
import { isValueValidNumber } from 'utils/form';
import messages from 'messages';
import FieldInput from 'components/input/FieldInput';

class MTSTestDataResultForm extends React.Component {
  render() {
    const {
      editUrl,
      edit,
      onChange,
      onBlur,
      onSave,
      onReset,
      loading,
      soleusVoxelSizeX,
      soleusVoxelSizeY,
      soleusVoxelSizeZ,
      soleusCarnosine,
      soleusCreatine,
      soleusTma,
      soleusSignalWater,
      gastroCarnosine,
      gastroVoxelSizeX,
      gastroVoxelSizeY,
      gastroVoxelSizeZ,
      gastroSignalWater,
      routing: { push },
      ...props
    } = this.props;

    const isValidToSubmit = () =>
      isValueValidNumber(soleusVoxelSizeX) &&
      isValueValidNumber(soleusVoxelSizeY) &&
      isValueValidNumber(soleusVoxelSizeZ) &&
      isValueValidNumber(soleusCarnosine) &&
      isValueValidNumber(soleusCreatine) &&
      isValueValidNumber(soleusTma) &&
      isValueValidNumber(soleusSignalWater) &&
      isValueValidNumber(gastroCarnosine) &&
      isValueValidNumber(gastroVoxelSizeX) &&
      isValueValidNumber(gastroVoxelSizeY) &&
      isValueValidNumber(gastroVoxelSizeZ) &&
      isValueValidNumber(gastroSignalWater);
    return (
      <Card secondary>
        <CardHeader secondary>
          <CardHeaderTitle>
            <FormattedMessage {...messages.cardLabelTitleSoleus} />
          </CardHeaderTitle>
          <CardHeaderButtons>
            {edit ? (
              <Button
                type="submit"
                tiny
                disabled={loading || !isValidToSubmit()}
                onClick={e => {
                  e.preventDefault();
                  onSave();
                }}
              >
                <FormattedMessage {...messages.cardButtonSave} />
              </Button>
            ) : (
              <Button
                tiny
                onClick={e => {
                  e.preventDefault();
                  push(editUrl);
                }}
              >
                <Icon id="edit" />
              </Button>
            )}
          </CardHeaderButtons>
        </CardHeader>
        <CardBody secondary>
          <div className="c-input__three-cols-group c-input__three-cols-group-label">
            <span className="c-input__label">
              <FormattedMessage {...messages.cardLabelVoxelSize} />
            </span>
            <FieldInput
              extraClassNames="c-input--border"
              id="soleusVoxelSizeX"
              value={soleusVoxelSizeX}
              onBlur={e => onBlur(e, 'number')}
              onChange={e => onChange(e, 'number')}
              readOnly={!edit}
              icon={props['soleusVoxelSizeXError'] ? 'validation-error' : ' '}
            >
              <FormattedMessage {...messages.cardLabelVoxelSizeX} />*
            </FieldInput>
            <FieldInput
              extraClassNames="c-input--border"
              id="soleusVoxelSizeY"
              value={soleusVoxelSizeY}
              onBlur={e => onBlur(e, 'number')}
              onChange={e => onChange(e, 'number')}
              readOnly={!edit}
              icon={props['soleusVoxelSizeYError'] ? 'validation-error' : ' '}
            >
              <FormattedMessage {...messages.cardLabelVoxelSizeY} />*
            </FieldInput>
            <FieldInput
              extraClassNames="c-input--border"
              id="soleusVoxelSizeZ"
              value={soleusVoxelSizeZ}
              onBlur={e => onBlur(e, 'number')}
              onChange={e => onChange(e, 'number')}
              readOnly={!edit}
              icon={props['soleusVoxelSizeZError'] ? 'validation-error' : ' '}
            >
              <FormattedMessage {...messages.cardLabelVoxelSizeZ} />*
            </FieldInput>
          </div>
          <div className="c-input__three-cols-group">
            <FieldInput
              extraClassNames="c-input--border"
              id="soleusCarnosine"
              value={soleusCarnosine}
              onBlur={e => onBlur(e, 'number')}
              onChange={e => onChange(e, 'number')}
              readOnly={!edit}
              icon={props['soleusCarnosineError'] ? 'validation-error' : ' '}
            >
              <FormattedMessage {...messages.cardLabelCarnosine} />*
            </FieldInput>
            <FieldInput
              extraClassNames="c-input--border"
              id="soleusCreatine"
              value={soleusCreatine}
              onBlur={e => onBlur(e, 'number')}
              onChange={e => onChange(e, 'number')}
              readOnly={!edit}
              icon={props['soleusCreatineError'] ? 'validation-error' : ' '}
            >
              <FormattedMessage {...messages.cardLabelCreatine} />*
            </FieldInput>
          </div>
          <div className="c-input__three-cols-group">
            <FieldInput
              extraClassNames="c-input--border"
              id="soleusTma"
              value={soleusTma}
              onBlur={e => onBlur(e, 'number')}
              onChange={e => onChange(e, 'number')}
              readOnly={!edit}
              icon={props['soleusTmaError'] ? 'validation-error' : ' '}
            >
              <FormattedMessage {...messages.cardLabelTma} />*
            </FieldInput>
            <FieldInput
              extraClassNames="c-input--border"
              id="soleusSignalWater"
              value={soleusSignalWater}
              onBlur={e => onBlur(e, 'number')}
              onChange={e => onChange(e, 'number')}
              readOnly={!edit}
              icon={props['soleusSignalWaterError'] ? 'validation-error' : ' '}
            >
              <FormattedMessage {...messages.cardLabelWaterSignal} />*
            </FieldInput>
          </div>
        </CardBody>

        <CardHeader secondary>
          <CardHeaderTitle>
            <FormattedMessage {...messages.cardLabelTitleGastro} />
          </CardHeaderTitle>
        </CardHeader>
        <div className="c-input__three-cols-group c-input__three-cols-group-label">
          <span className="c-input__label">
            <FormattedMessage {...messages.cardLabelVoxelSize} />
          </span>
          <FieldInput
            extraClassNames="c-input--border"
            id="gastroVoxelSizeX"
            value={gastroVoxelSizeX}
            onBlur={e => onBlur(e, 'number')}
            onChange={e => onChange(e, 'number')}
            readOnly={!edit}
            icon={props['gastroVoxelSizeXError'] ? 'validation-error' : ' '}
          >
            <FormattedMessage {...messages.cardLabelVoxelSizeX} />*
          </FieldInput>
          <FieldInput
            extraClassNames="c-input--border"
            id="gastroVoxelSizeY"
            value={gastroVoxelSizeY}
            onBlur={e => onBlur(e, 'number')}
            onChange={e => onChange(e, 'number')}
            readOnly={!edit}
            icon={props['gastroVoxelSizeYError'] ? 'validation-error' : ' '}
          >
            <FormattedMessage {...messages.cardLabelVoxelSizeY} />*
          </FieldInput>
          <FieldInput
            extraClassNames="c-input--border"
            id="gastroVoxelSizeZ"
            value={gastroVoxelSizeZ}
            onBlur={e => onBlur(e, 'number')}
            onChange={e => onChange(e, 'number')}
            readOnly={!edit}
            icon={props['gastroVoxelSizeZError'] ? 'validation-error' : ' '}
          >
            <FormattedMessage {...messages.cardLabelVoxelSizeZ} />*
          </FieldInput>
        </div>
        <div className="c-input__three-cols-group">
          <FieldInput
            extraClassNames="c-input--border"
            id="gastroCarnosine"
            value={gastroCarnosine}
            onBlur={e => onBlur(e, 'number')}
            onChange={e => onChange(e, 'number')}
            readOnly={!edit}
            icon={props['gastroCarnosineError'] ? 'validation-error' : ' '}
          >
            <FormattedMessage {...messages.cardLabelCarnosine} />*
          </FieldInput>
          <FieldInput
            extraClassNames="c-input--border"
            id="gastroSignalWater"
            value={gastroSignalWater}
            onBlur={e => onBlur(e, 'number')}
            onChange={e => onChange(e, 'number')}
            readOnly={!edit}
            icon={props['gastroSignalWaterError'] ? 'validation-error' : ' '}
          >
            <FormattedMessage {...messages.cardLabelWaterSignal} />*
          </FieldInput>
        </div>
        <CardFooter secondary>
          {edit && (
            <Button
              secondary
              disabled={loading}
              onClick={e => {
                e.preventDefault();
                onReset();
              }}
            >
              <FormattedMessage {...messages.cardButtonCancel} />
            </Button>
          )}
          {edit && (
            <Button
              type="submit"
              primary
              onClick={e => {
                e.preventDefault();
                onSave();
              }}
              disabled={loading || !isValidToSubmit()}
            >
              <FormattedMessage {...messages.cardButtonSave} />
            </Button>
          )}
        </CardFooter>
      </Card>
    );
  }
}

export default withRouter(injectIntl(inject('routing')(MTSTestDataResultForm)));
