import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { Provider, observer } from 'mobx-react';
import { IntlProvider } from 'react-intl';
import { Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'mobx-react-router';
import { translationMessages } from 'i18n';
import App from './App';
import { backController } from 'utils/back-controller';
import { NotificationProvider } from 'components/notification';
import { NotificationList } from 'components/notification/NotificationList';
import { DEFAULT_LOCALE } from 'constants.js';
import { AbilityContext } from "Can";

export const browserHistory = createBrowserHistory();

const AppContainer = ({ client, stores }) => {
  const history = syncHistoryWithStore(browserHistory, stores.routing);
  backController.setPath(browserHistory.location.pathname);
  history.listen(location => {
    backController.setPath(location.pathname);
  });

  const { locale, translations } = stores.uiState;

  return (
    <AbilityContext.Provider value={stores.authStore.abilities}>
      <NotificationProvider>
        <ApolloProvider client={client}>
          <Provider {...stores}>
            <IntlProvider
              locale={locale}
              defaultLocale={DEFAULT_LOCALE}
              messages={translationMessages[translations]}
              onError={err => {
                if (err.code === 'MISSING_TRANSLATION') {
                  console.warn('Missing translation', err.message);
                  return;
                }
                throw err;
              }}
            >
              <Router history={history}>
                <LastLocationProvider>
                  <App />
                </LastLocationProvider>
              </Router>
            </IntlProvider>
          </Provider>
        </ApolloProvider>
        <NotificationList />
      </NotificationProvider>
    </AbilityContext.Provider>
  );
};

export default observer(AppContainer);
