import { FormattedMessage } from 'react-intl';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import CardBody from 'components/card/CardBody';
import { features } from 'constants.js';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import FieldCheckbox from 'components/input/FieldCheckbox';
import { capitalize } from 'utils/string';

function OrganisationSettings({ edit }) {
  const ability = useAbility(AbilityContext);

  return (
    <>
      <CardHeader secondary extraClassNames="o-flex--wrap">
        <CardHeaderText extraClassNames="u-margin-bottom-none">
          <CardHeaderTitle>Features</CardHeaderTitle>
        </CardHeaderText>
      </CardHeader>

      <CardBody
        secondary
        separatorBottom
        extraClassNames={'u-padding-bottom-medium'}
      >
        {Object.keys(features).map(feature => (
          <div key={feature} className="c-input-checkbox--with-paragraph">
            <FieldCheckbox
              id={feature}
              name={`featureFlags.${feature}`}
              secondary
              readOnly={
                !edit ||
                (!ability.can('update', 'Organisation') &&
                  !ability.can('manage', capitalize(feature)))
              }
              inputExtraClassNames={'u-hidden-visually'}
            >
              <FormattedMessage {...features[feature].label} />
            </FieldCheckbox>
            <div className="c-input__description">
              <FormattedMessage {...features[feature].description} />
            </div>
          </div>
        ))}
      </CardBody>
    </>
  );
}

export default OrganisationSettings;
