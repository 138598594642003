import gql from 'graphql-tag';
import { appSyncClient } from './app-sync';
// import { gqlMutation } from './utils/mutate';
import { getGQLResult } from './utils/get';

export const QUERY_GET_GROUP_GROWTHPREDICTION = gql`
  query getGrowthDataOfGroup($entityId: ID!) {
    getGrowthDataOfGroup(entityId: $entityId)
  }
`;

/*
query getGrowth{
  getGrowthDataOfGroup(entityId: "8dea729c-d99b-4347-a973-9305cad91ac7")
}
*/
