import Icon from '../../icon/Icon';
import Button from '../../button/Button';

const PanelTitleButton = props => {
  const { onClick, icon, primary, small, iconFillColor, iconStrokeColor } =
    props;
  return (
    <Button
      circular
      light={!primary}
      onClick={onClick}
      primary={primary}
      extraClassNames="c-panel__title-button"
    >
      <Icon
        id={icon ? icon : 'three-dots'}
        smallIcon={small}
        mediumIcon={!small}
        fillColor={iconFillColor}
        strokeColor={iconStrokeColor}
      />
    </Button>
  );
};

export default PanelTitleButton;
