import { createContext } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { RouterStore } from 'mobx-react-router';
//import registerServiceWorker from './registerServiceWorker';

// Stores
import AuthStore from './stores/AuthStore';
// import EntityStore from './stores/EntityStore';
import UiState from './stores/UiState';
import ClientStore from './stores/ClientStore';
import UserStore from './stores/UserStore';
import BroadcastStore from './stores/BroadcastStore';
import smoothscroll from 'smoothscroll-polyfill';
import './_scss/_index.scss';

import AppContainer from './AppContainer';

// setup all stores
const routing = new RouterStore();
const uiState = new UiState();
const clientStore = new ClientStore(routing, uiState);
const userStore = new UserStore(routing, uiState);
// const entityStore = new EntityStore(routing, uiState, userStore);
const authStore = new AuthStore(routing, uiState);
const broadcastStore = new BroadcastStore();

const stores = {
  routing,
  authStore,
  // entityStore,
  uiState,
  clientStore,
  userStore,
  broadcastStore
};

export const StoreContext = createContext(stores);

const render = client => {
  if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      tunnel: process.env.REACT_APP_SENTRY_TUNNEL,
      integrations: [new BrowserTracing()], //, new Sentry.Replay()
      environment: process.env.REACT_APP_ENV,
      // replaysSessionSampleRate: 0.1,
      // replaysOnErrorSampleRate: 1.0,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0
    });
  }

  ReactDOM.render(
    <AppContainer client={client} stores={stores} />,
    document.getElementById('root')
  );
};

/**
 * responsible for persistance of stores every store we want te keep alive between refreshes
 * we have to wait for the persiststore finish before we can render our views
 */
const persistStores = async () => {
  /*const hydrate = create({
    storage: localStorage,
    jsonify: false
  });
  await hydrate('authStore', authStore).then(r => {
    r.isLoggedIn = false;
  });
  await hydrate('uiState', stores.uiState);*/
  const client = await authStore.connect();
  uiState.setLocale();
  render(client);
  authStore.setLoginState();
};

//registerServiceWorker();
persistStores();

smoothscroll.polyfill();
