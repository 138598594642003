import React, { useContext, useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import ListBody from 'components/list/ListBody';
import SelectableList from 'components/list/SelectableList';
import { ROUTE_SESSIONS_ENTITY_TEMPLATES_TESTSET_EDIT } from 'routes/RouteList';
import List from 'components/list/List';
import { StoreContext } from 'index';
import { useQuery } from '@apollo/client';
import { TestSetV2Type } from 'constants.js';
import { QUERY_GET_TESTSETS_WITH_SPORT } from 'services/aws/testsets-query';
import TestSet from 'models/TestSet';
import { SessionTemplateListItem } from 'containers/partials/list-items/SessionTemplateListItem';
import Session from 'models/Session';

function PreventionTemplateList({ entityId, onChange, onUse }) {
  const {
    routing: { push }
  } = useContext(StoreContext);
  const [sessionTemplates, setPreventionTemplates] = useState([]);

  const { data } = useQuery(QUERY_GET_TESTSETS_WITH_SPORT, {
    variables: { entityId, type: TestSetV2Type.PREVENTION_TEMPLATE }
  });

  useEffect(() => {
    if (data?.getTestSetsV2) {
      setPreventionTemplates(
        data.getTestSetsV2.map(testSet => new TestSet(testSet))
      );
    }
  }, [data]);

  // Loader is set in sessions
  // if (loading && sessionTemplates.length === 0) return <Loader />;

  const onUseTemplateHandler = async template => {
    const { id, ...sessionTemplate } = {
      ...template,
      name: template.title,
      notes: template.note,
      meta: template.meta
    };
    const session = new Session({
      ...sessionTemplate,
      type: TestSetV2Type.PREVENTION
    });
    onUse(session);
  };

  const onEditTemplateHandler = id => {
    push(
      generatePath(ROUTE_SESSIONS_ENTITY_TEMPLATES_TESTSET_EDIT, {
        entityId,
        testSetId: id
      })
    );
  };

  return (
    <List>
      <ListBody>
        <SelectableList
          onChange={(id, ids) => {
            onChange(id, ids);
          }}
          onClick={id => {
            onEditTemplateHandler(id);
          }}
        >
          {sessionTemplates.map(template => (
            <SessionTemplateListItem
              noMaxHeight
              entityId={entityId}
              id={template.id}
              key={template.id}
              template={template}
              onUse={() => {
                onUseTemplateHandler(template);
              }}
              onEdit={id => {
                onEditTemplateHandler(id);
              }}
            />
          ))}
        </SelectableList>
      </ListBody>
    </List>
  );
}

export default PreventionTemplateList;
