import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'components/panel/Panel';

const IFramePage = ({ src }) => {
  return (
    <Panel fullscreen>
      {src && (
        <iframe title={'home'} src={src} frameBorder={0} height={'100%'} />
      )}
    </Panel>
  );
};

IFramePage.propTypes = {
  src: PropTypes.string
};

export default IFramePage;
