import { useEffect, useContext, useState } from 'react';
import { QUERY_GET_SESSIONS_FOR_TEST } from 'services/aws/session-query';
import Session from 'models/Session';
import { FinishedState } from 'enums';
import {
  ListItem,
  ListItemStatus,
  ListItemLabelWrapper,
  ListItemLabel
} from 'components/list/list-item';
import { getNamePrefix } from 'utils/name-prefix';
import List from 'components/list/List';
import ListColumnTitle from 'components/list/ListColumnTitle';
import ListHeader from 'components/list/ListHeader';
import ListBody from 'components/list/ListBody';
import { sortUsers } from 'utils/sort';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { Panel, PanelBody } from 'components/panel';
import { Card, CardBody } from 'components/card';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { getTestSessionURL } from 'utils/url';
import { openNewWindow } from 'utils/browser';
import { useQuery } from '@apollo/client';
import Loader from 'components/loader/Loader';
import { StoreContext } from 'index';
import { useHistory } from 'react-router-dom';

const TestSessionSporterList = ({ entityId, packageId, sessionId }) => {
  const {
    uiState,
    uiState: { locale }
  } = useContext(StoreContext);
  const { push } = useHistory();

  const [session, setSession] = useState({});
  const [athletes, setAthletes] = useState([]);
  const [athleteId, setAthleteId] = useState(0);

  const { loading, error, data } = useQuery(QUERY_GET_SESSIONS_FOR_TEST, {
    variables: { testSessionId: sessionId, entityId }
  });
  useEffect(() => {
    if (data?.getTestSession) {
      const session = new Session(data.getTestSession);
      if (session.finished === FinishedState.FINISHED) {
        push('/');
        return;
      }
      setSession(session);
      setAthletes(sortUsers(session.sporters));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onGotoSporter = ({ id }) => {
    setAthleteId(id);

    const url = getTestSessionURL({
      entityId,
      sessionId,
      packageType: packageId,
      locale,
      sporterId: id
    });
    openNewWindow(url);
  };

  if (loading || !uiState.isLocaleReady) <Loader />;

  if (error)
    return (
      <p>
        <FormattedMessage
          {...messages.errorMessage}
          values={{
            message: error.message
          }}
        />
      </p>
    );

  return (
    <Panel fullscreen>
      <PanelBody home>
        <Card>
          <CardHeader primary>
            <CardHeaderTitle>{session.name}</CardHeaderTitle>
          </CardHeader>
          <CardBody>
            <List>
              <ListHeader oneTitle>
                <ListColumnTitle>
                  <FormattedMessage {...messages.sporters} />
                </ListColumnTitle>
              </ListHeader>
              <ListBody>
                {athletes.map(athlete => (
                  <ClientListItem
                    id={athlete.id}
                    key={athlete.id}
                    user={athlete}
                    active={athleteId === athlete.id}
                    done={session.getPersonTestPercentage(athlete.id) === 100}
                    onClick={() => onGotoSporter(athlete)}
                  />
                ))}
              </ListBody>
            </List>
          </CardBody>
        </Card>
      </PanelBody>
    </Panel>
  );
};

export default TestSessionSporterList;

const ClientListItem = ({
  active,
  user: { firstname, lastname },
  onClick,
  done
}) => (
  <ListItem active={active} onClick={onClick} done={done}>
    <ListItemStatus>{getNamePrefix(firstname, lastname)}</ListItemStatus>
    <ListItemLabelWrapper>
      <ListItemLabel label>
        {firstname} {lastname}
      </ListItemLabel>
    </ListItemLabelWrapper>
  </ListItem>
);
