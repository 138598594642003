import classnames from 'classnames';

function TabPanelBody({ withScroller, extraClassNames, children, ...props }) {
  return (
    <div
      className={classnames(
        'p-tabs__active-tab-content-wrapper',
        extraClassNames,
        {
          'p-tabs__active-tab-content-wrapper--scroll': withScroller
        }
      )}
    >
      {children}
    </div>
  );
}

export default TabPanelBody;
