import { FormattedMessage, useIntl } from 'react-intl';
import CardBody from 'components/card/CardBody';
import Card from 'components/card/Card';
import messages from 'messages';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderText
} from 'components/card/card-header';
import Cues from 'containers/partials/inputs/Cues';
import { Editor } from '@tinymce/tinymce-react';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import { useFormikContext } from 'formik';
import FieldInput from 'components/input/FieldInput';
import { FieldTextarea } from 'components/input/FieldTextarea';
import { Field } from 'formik';

const ExerciseCopyFormInput = ({ readOnly, onCancel, entityId }) => {
  const { values, errors, touched, submitForm, setFieldValue, resetForm } =
    useFormikContext();

  const intl = useIntl();

  return (
    <Card secondary multiBody>
      <CardHeader secondary>
        <CardHeaderText>
          <CardHeaderTitle>
            <FormattedMessage {...messages.cardTitleExercise} />
          </CardHeaderTitle>
        </CardHeaderText>

        <FormEditHeaderButtons
          edit
          onSubmit={submitForm}
          onCancel={() => {
            resetForm();
            if (onCancel) onCancel();
          }}
        />
      </CardHeader>
      <CardBody secondary separatorBottom>
        <div className="o-layout o-layout--small">
          <div className="o-layout__item u-3-of-3">
            {/* EXERCISE TITLE */}
            <FieldInput id="title" name="title" readOnly={readOnly}>
              <FormattedMessage {...messages.exercisesLabelTitle} />*
            </FieldInput>
          </div>
        </div>
      </CardBody>

      <CardHeader secondary>
        <CardHeaderText>
          <CardHeaderTitle>
            <FormattedMessage {...messages.cardExercisePracticalTitle} />
          </CardHeaderTitle>
        </CardHeaderText>
      </CardHeader>
      <CardBody secondary separatorBottom>
        {/* Notes / INTRO */}
        <FieldTextarea
          id="note"
          name="note"
          errors={errors}
          touched={touched}
          resizeVertical
        >
          <FormattedMessage {...messages.exercisesLabelNotes} />
        </FieldTextarea>

        {/* Remarks */}
        <FieldTextarea
          id="remarks"
          name="remarks"
          errors={errors}
          touched={touched}
          resizeVertical
        >
          <FormattedMessage {...messages.exercisesLabelRemarks} />
        </FieldTextarea>

        <Cues
          entityId={entityId}
          label={`${intl.formatMessage(messages.exercisesLabelCues)}`}
          cues={values?.cues}
          onChange={newCues => setFieldValue('cues', newCues)}
        />
      </CardBody>

      <CardHeader secondary>
        <CardHeaderText>
          <CardHeaderTitle>
            <FormattedMessage {...messages.exercisesLabelProtocol} />
          </CardHeaderTitle>
        </CardHeaderText>
      </CardHeader>
      <CardBody
        secondary
        separatorBottom
        extraClassNames="u-padding-bottom-large"
      >
        {/* PROTOCOL */}
        <Field
          as={Editor}
          name="protocol"
          init={{
            branding: false,
            height: 300,
            menubar: false,
            plugins: [
              'advlist autolink lists link image',
              'charmap print preview anchor help',
              'searchreplace visualblocks code',
              'insertdatetime media table paste'
            ],
            toolbar:
              'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help'
          }}
          onEditorChange={(content, editor) => {
            setFieldValue('protocol', editor.getContent());
          }}
        />
      </CardBody>

      <FormEditFooterButtons
        edit={true}
        onCancel={() => {
          resetForm();
          if (onCancel) onCancel();
        }}
        onSubmit={submitForm}
        topMargin
      />
    </Card>
  );
};

export default ExerciseCopyFormInput;
