import * as poseDetection from '@tensorflow-models/pose-detection';
import * as mpPose from '@mediapipe/pose';

const runtime = 'mediapipe';
const modelTypes = ['heavy', 'full', 'lite'];

/**
 * Create the detector for pose detection.
 */
export async function createDetector() {
  if (runtime === 'mediapipe') {
    return poseDetection.createDetector(
      poseDetection.SupportedModels.BlazePose,
      {
        runtime,
        modelType: modelTypes[2],
        solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${mpPose.VERSION}`
      }
    );
  } else if (runtime === 'tfjs') {
    return poseDetection.createDetector(
      poseDetection.SupportedModels.BlazePose,
      { runtime, modelType: modelTypes[1] }
    );
  }
}
