import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import FileUpload from './FileUpload';
import {
  QUERY_GET_UPLOADED_FILES_BY_LINK_ID,
  FILE_STATUS
} from 'services/aws/file-upload';
import File from 'models/File';

import './FileUpload.scss';
import RetryPanel from '../error-boundary/RetryPanel';
import { useLazyQuery } from '@apollo/client';
import { usePrevious } from 'react-use';

const FileUploadWrapper = ({
  parentLoading,
  linkId,
  entityId,
  fileUploads,
  onFileDeleted,
  onFileUploaded,
  onFileUploaded2,
  isPublic,
  formImage,
  formVideo,
  label = ''
}) => {
  const prevLink = usePrevious(linkId);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [getFiles, { loading, error, data }] = useLazyQuery(
    QUERY_GET_UPLOADED_FILES_BY_LINK_ID,
    {
      variables: { entityId, linkId }
    }
  );
  useEffect(() => {
    if (linkId && prevLink !== linkId) {
      getFiles(linkId);
    }
  }, [linkId]);

  useEffect(() => {
    if (data?.getFiles && data.getFiles.length) {
      setUploadedFiles(data.getFiles.map(uf => new File(uf)));
    }
  }, [data]);
  if (error) return <RetryPanel onRetry={() => getFiles()} />;

  return (
    <div
      className={classNames('c-file-upload-wrapper', {
        'c-file-upload-wrapper--one-image': formImage
      })}
    >
      {fileUploads.map(fu => (
        <FileUpload
          isPublic={isPublic}
          label={label}
          loading={loading || parentLoading}
          key={fu.type}
          type={fu.type}
          linkId={linkId}
          entityId={entityId}
          formImage={formImage}
          formVideo={formVideo}
          attachedFile={uploadedFiles.find(
            f => f.type === fu.type && fu.status !== FILE_STATUS.FAILED
          )}
          url={fu.url}
          accept={fu.accept}
          onFileUploaded={(linkedId, theType, file) => {
            onFileUploaded2 && onFileUploaded2(file.id, file.url, file);
            if (onFileUploaded) {
              onFileUploaded();
            } else {
              getFiles({ variables: { entityId, linkId: linkedId } });
            }
          }}
          onFileDeleted={() => {
            if (onFileDeleted) {
              onFileDeleted();
            } else {
              getFiles();
            }
          }}
        />
      ))}
    </div>
  );
};

export default FileUploadWrapper;
