import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_SPORTERS_ENTITY } from 'routes/RouteList';
import {
  QUERY_GET_PERSONENTITIES_OF_PERSON,
  QUERY_GET_PERSONS_OF_ENTITY,
  REMOVE_PERSON_FROM_ENTITY
} from 'services/aws/user-query';
import { S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID } from 'services/aws/client-query';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import { StoreContext } from 'index';

const RemoveSporterFromGroupButton = ({
  entityId,
  personId,
  children,
  ...props
}) => {
  const {
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const { push } = useHistory();
  return (
    <ConfirmMutationButton
      {...props}
      mutation={REMOVE_PERSON_FROM_ENTITY}
      confirmTitle={messages.modalConfirmRemoveSporterTitle}
      confirmMessage={messages.modalConfirmRemoveSporterMessage}
      variables={{
        personId,
        entityId
      }}
      refetchQueries={[
        {
          query: S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID,
          variables: { id: entityId }
        },
        {
          query: QUERY_GET_PERSONS_OF_ENTITY,
          variables: { rootEntityId }
        },
        {
          query: QUERY_GET_PERSONENTITIES_OF_PERSON,
          variables: { topLevelEntityId: entityId, personId }
        }
      ]}
      update={(cache, { data: { removePersonFromEntity } }) => {
        if (removePersonFromEntity) {
          push(ROUTE_SPORTERS_ENTITY.replace(':entityId', entityId));
        }
      }}
    >
      <Icon id="delete" strokeColor="color-neutral-dark" />
      {children}
    </ConfirmMutationButton>
  );
};

export default RemoveSporterFromGroupButton;
