import { FormattedMessage } from 'react-intl';
import {
  ListItem,
  ListItemStatus,
  ListItemLabelWrapper,
  ListItemLabel
} from 'components/list/list-item';
import { getLocalDateFormat } from 'utils/date';
import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';
import messages from 'messages';

const getTestSetName = testSets => {
  return testSets?.[0] ? `${testSets[0].title} (v${testSets[0].version})` : '';
};

export const SessionListItem = ({
  active,
  session,
  onClick,
  cta,
  id,
  checkbox,
  onChange,
  checked,
  visible,
  noMaxHeight
}) => (
  <ListItem
    noMaxHeight={noMaxHeight}
    checkbox={checkbox}
    active={active}
    inProgress={session.inProgress}
    done={session.isDone}
    onClick={onClick}
    cta={!!cta}
  >
    <ListItemStatus
      visible={visible}
      onChange={onChange}
      checked={checked}
      itemId={id}
    >
      {`${session.finishedPercentage}%`}
    </ListItemStatus>
    <ListItemLabelWrapper>
      <ListItemLabel label>{session.name}</ListItemLabel>
      <ListItemLabel info middleColumn>
        {cta
          ? getLocalDateFormat(session.startdate)
          : getTestSetName(session.testSets)}
      </ListItemLabel>
      <ListItemLabel info>
        {cta ? (
          session.isDone ? (
            <Button rounded grey>
              <FormattedMessage {...messages.sessionListItemResults} />
            </Button>
          ) : session.personEntities.length ? (
            <Button rounded accented>
              <Icon id="test-white" />
              <FormattedMessage {...messages.sessionListItemTakeTest} />
            </Button>
          ) : (
            <Button rounded primary>
              <Icon id="add-white" />
              <FormattedMessage {...messages.sessionListItemAddSporters} />
            </Button>
          )
        ) : (
          getLocalDateFormat(session.startdate)
        )}
      </ListItemLabel>
    </ListItemLabelWrapper>
  </ListItem>
);
