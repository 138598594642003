import React, { createContext, useReducer, useContext, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_TEMPLATES } from 'services/aws/templates-query';
import { sort } from 'utils/sort';
import { getFilteredEntities } from 'utils/search';
import Template from 'models/Template';

export const TemplatesContext = createContext();

const initialState = {
  templatesStore: [],
  templates: [],
  filter: {
    search: '',
    tags: []
  }
};

export const SET_TEMPLATES_STORE = 'SET_TEMPLATES_STORE';
export const SET_TEMPLATES = 'SET_TEMPLATES';

export const SET_FILTER = 'SET_FILTER';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_TEMPLATES_STORE:
      return {
        ...state,
        templatesStore: [...action.payload]
      };
    case SET_TEMPLATES:
      return { ...state, templates: [...action.payload] };

    case SET_FILTER:
      return { ...state, filter: { ...action.payload } };
    default:
      return state;
  }
};

const TemplatesProvider = ({ entityId, type, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [getTemplatesQuery, { loading, data }] =
    useLazyQuery(QUERY_GET_TEMPLATES);

  useEffect(() => {
    if (!data?.getExerciseProgramTemplates) return;

    const templates = data.getExerciseProgramTemplates.map(
      template => new Template({ ...template })
    );
    dispatch({
      type: SET_TEMPLATES_STORE,
      payload: templates
    });
    dispatch({
      type: SET_TEMPLATES,
      payload: templates
    });
  }, [data]);

  useEffect(() => {
    if (state.filter) {
      const templates = sort(
        getFilteredEntities(
          state.templatesStore,
          state.filter.search ?? '',
          state.filter.tags ?? null
        ),
        {
          keys: [{ key: 'title' }]
        }
      );
      dispatch({
        type: SET_TEMPLATES,
        payload: templates
      });
    }
  }, [state.templatesStore, state.filter]);

  const actions = {
    getTemplates: templateType => {
      getTemplatesQuery({
        variables: { entityId, type: templateType ?? type }
      });
    },
    setFilter: filter => {
      dispatch({
        type: SET_FILTER,
        payload: { ...state.filter, ...filter }
      });
    },
    resetFilter: () => {
      dispatch({
        type: SET_FILTER,
        payload: { ...initialState.filter }
      });
    }
  };

  return (
    <TemplatesContext.Provider
      value={{
        loading,
        state,
        actions
      }}
    >
      {children}
    </TemplatesContext.Provider>
  );
};

function useTemplatesContext() {
  const context = useContext(TemplatesContext);
  if (context === undefined) {
    throw new Error(
      'The TemplatesContext hook must be used within a TemplatesContext.Provider'
    );
  }
  return context;
}

export { TemplatesProvider, useTemplatesContext };
