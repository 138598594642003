import React, { useContext, useEffect, useState } from 'react';
import {
  Breadcrumb,
  Breadcrumbs,
  BreadcrumbsWrapper
} from 'components/breadcrumbs';
import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import Loader from 'components/loader/Loader';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import {
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SPORTERS_ENTITY_EDIT_GROUP,
  ROUTE_SPORTERS_ENTITY_SPORTER
} from 'routes/RouteList';
import { useGroupsContext } from 'contexts/GroupsContext';
import { StoreContext } from 'index';
import VirtualizedEntityList from 'containers/pages/sporters/VirtualizedEntityList';
import { useMenuContext } from 'contexts/MenuContext';
import RemoveGroupButton from 'containers/partials/buttons/RemoveGroupButton';
import { SPORTER_ACTION } from 'containers/partials/modals/CopySporterModal';
import RemovePersonsFromGroupButton from 'containers/partials/buttons/RemovePersonsFromGroupButton';
import InviteSportersButton from 'containers/partials/buttons/InviteSportersButton';
import useSelectable from 'hooks/utils/useSelectable';

function GroupsTab({
  onSelected,
  onClickPersonStatus,
  setCopyModal,
  setImportModal,
  ...props
}) {
  const {
    authStore: {
      user,
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const { entityId, sporterId } = useParams();
  const {
    state: { group, entities },
    actions,
    loading
  } = useGroupsContext();
  const { selected, setSelected } = useSelectable();
  const { addMenuItems, removeAllMenuItems } = useMenuContext();
  const [entitiesList, setEntitiesList] = useState([]);

  useEffect(() => {
    actions.resetFilter();
    return () => {
      setSelected([]);
      removeAllMenuItems();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.selected.length === 0) {
      setSelected([]);
    }
  }, [props.selected]);

  useEffect(() => {
    actions.getGroups(entityId);
  }, [entityId]);

  useEffect(() => {
    removeAllMenuItems();

    const menuItems = [];
    if (rootEntityId !== entityId && user.canEditGroups(entityId)) {
      menuItems.push(
        <Button
          key={'editGroup'}
          menu
          onClick={() =>
            push(
              generatePath(ROUTE_SPORTERS_ENTITY_EDIT_GROUP, {
                entityId
              })
            )
          }
        >
          <Icon id="edit" />
          <FormattedMessage {...messages.menuItemSporterEditGroup} />
        </Button>,

        <RemoveGroupButton
          key={'removeGroup'}
          menu
          entityId={entityId}
          group={group}
          parentId={group.parentId}
        >
          <FormattedMessage {...messages.menuItemSporterRemoveGroup} />
        </RemoveGroupButton>,

        <Button key={'importPersons'} menu onClick={() => setImportModal(true)}>
          <FormattedMessage {...messages.buttonSportersImportSporters} />
          <Icon id="add-user" />
        </Button>
      );
    }

    if (selected.length > 0) {
      menuItems.push(
        <Button
          key={'copyPersons'}
          menu
          onClick={() => setCopyModal(SPORTER_ACTION.COPY)}
        >
          <FormattedMessage {...messages.buttonSportersCopySporters} />
          <Icon id="copy" />
        </Button>,
        <Button
          key={'movePersons'}
          menu
          onClick={() => setCopyModal(SPORTER_ACTION.MOVE)}
        >
          <FormattedMessage {...messages.buttonSportersMoveSporters} />
          <Icon id="move" />
        </Button>,
        <RemovePersonsFromGroupButton
          key={'removepersons'}
          entityId={entityId}
          rootEntityId={rootEntityId}
          ids={selected.map(p => p.id)}
          menu
          onComplete={() => onPersonsRemoved(entityId)}
        >
          <FormattedMessage {...messages.menuItemSporterArchiveSporters} />
        </RemovePersonsFromGroupButton>,
        <InviteSportersButton
          key={'invitePersons'}
          menu
          entityId={rootEntityId}
          persons={selected}
        >
          <FormattedMessage {...messages.buttonSportersInvite} />
          <Icon id="email" />
        </InviteSportersButton>
      );
    }
    addMenuItems(menuItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, group, selected, loading]);

  const onPersonsRemoved = async entityId => {
    await actions.refetchGroups(entityId);
    setSelected([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onBackHandler = () => {
    const newList = [...entitiesList];
    const lastGroup = newList.pop();
    setEntitiesList([...newList]);
    actions.resetFilter();
    push(generatePath(ROUTE_SPORTERS_ENTITY, { entityId: group.parentId }));
  };

  const onBreadcrumbClickedHandler = async item => {
    const newList = [...entitiesList];
    const index = newList.findIndex(e => e.id === item.id);
    newList.splice(index + 1);
    setEntitiesList([...newList]);
    push(generatePath(ROUTE_SPORTERS_ENTITY, { entityId: item.id }));
  };

  const onSelectSportersHandler = selected => {
    setSelected(selected);
    onSelected && onSelected(selected);
  };

  if (loading && (!entities || entities.length === 0)) return <Loader />;
  return (
    <>
      {entityId !== rootEntityId && (
        <BreadcrumbsWrapper forLists>
          <Button rounded light onClick={() => onBackHandler()}>
            <Icon id="back-nav" />
            <FormattedMessage {...messages.exercisesAssignUserButtonBack} />
          </Button>
          <Breadcrumbs>
            {/*<Breadcrumb onClick={() => onBreadcrumbClickedHandler(rootGroup)}>
                {rootGroup.name}
              </Breadcrumb>*/}

            {/*
            ATM this is unnecessary
            {entitiesList.map((group, i) => (
              <Breadcrumb
                onClick={() => onBreadcrumbClickedHandler(group)}
                key={group.id}
                noLink={i === entitiesList.length - 1}
              >
                {group.name}
              </Breadcrumb>
            ))} */}
            <div className="c-card-modal__body-head-breadcrumb" key={group.id}>
              <Icon id="breadcrumb" fillColor="color-neutral-steel" />
              <span className="c-organisation">{group.name}</span>
            </div>
          </Breadcrumbs>
        </BreadcrumbsWrapper>
      )}

      <VirtualizedEntityList
        entityId={entityId}
        items={entities}
        checkable
        personStatus
        activeId={sporterId}
        onClick={(item, type) => {
          if (type === 'group') {
            actions.setEntities([]);
            push(ROUTE_SPORTERS_ENTITY.replace(':entityId', item.id));
          } else {
            if (item.id !== sporterId) {
              push(
                generatePath(ROUTE_SPORTERS_ENTITY_SPORTER, {
                  entityId,
                  sporterId: item.id
                })
              );
            }
          }
        }}
        onClickStatus={person => onClickPersonStatus(person)}
        selectedItems={selected}
        onSelect={(persons, item) => {
          onSelectSportersHandler(persons, item);
        }}
      />
    </>
  );
}

export default GroupsTab;
