import React, { useContext, useEffect, useState } from 'react';
import Select, { Option } from 'components/input/Select';
import { useQuery } from '@apollo/client';
import { QUERY_GET_TESTSETS_LIST } from 'services/aws/testsets-query';
import { TestSetV2Type } from 'constants.js';
import { useIntl } from 'react-intl';
import { sort } from 'utils/sort';
import messages from 'messages';
import { groupBy } from 'utils/array';
import TestSet from 'models/TestSet';
import { StoreContext } from 'index';

function TestsetSelect({
  name,
  entityId,
  onChange,
  value,
  types = [TestSetV2Type.TALENT],
  testSet,
  readOnly = false,
  errors,
  hasLabel = true
}) {
  const intl = useIntl();
  const {
    authStore: {
      user: { testSetTypes }
    }
  } = useContext(StoreContext);
  const [hasTestSet, setHasTestSet] = useState(true);
  const [testSets, setTestSets] = useState([]);

  // filter the array types to only include the types that the user has access to in testSetTypes
  const allowedTypes = types.filter(type => {
    if (testSetTypes.includes(type)) {
      return type;
    }
  });

  const { loading, error, data } = useQuery(QUERY_GET_TESTSETS_LIST, {
    variables: {
      entityId,
      types: allowedTypes
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data?.getTestSetsV2) {
      const testSets = data.getTestSetsV2.map(ts => new TestSet(ts));
      const groupedTests = groupBy(testSets, ['originalId']);
      Object.keys(groupedTests).forEach(key => {
        if (key && groupedTests[key].length > 1) {
          const lastVersion = groupedTests[key].reduce((acc, test) =>
            acc.version > test.version ? acc : test
          );
          groupedTests[key] = [lastVersion];
        }
      });

      const filteredTestSets = Object.values(groupedTests).reduce(
        (acc, tests) => {
          return [...acc, ...tests];
        },
        []
      );
      if (!filteredTestSets.find(ts => ts.id === testSet?.id)) {
        setHasTestSet(false);
        onChange(testSet);
      }
      setTestSets(sort(filteredTestSets, { keys: [{ key: 'title' }] }));
    }
  }, [data]);

  const onSelectHandler = select => {
    onChange(testSets.find(testSet => testSet.id === select.value));
  };

  if (error) {
    return intl.formatMessage(messages.errorMessage, {
      message: 'Error loading Testset types'
    });
  }
  if (loading) {
    return (
      <Select
        label={hasLabel ? intl.formatMessage(messages.labelTestSetType) : ''}
        readOnly={readOnly}
        disabled={loading || readOnly}
        value=""
      >
        <Option value="" label={intl.formatMessage(messages.globalLoading)} />
      </Select>
    );
  }

  if (!hasTestSet && testSet) {
    return (
      <Select
        label={hasLabel ? intl.formatMessage(messages.labelTestSetType) : ''}
        readOnly
        disabled
        value={testSet.id}
      >
        <Option value={testSet.id} label={testSet.title} />
      </Select>
    );
  }

  return (
    <>
      <Select
        id="testsetType"
        label={hasLabel ? intl.formatMessage(messages.labelTestSetType) : ''}
        name={name}
        onChange={select => onSelectHandler(select)}
        value={value}
        readOnly={readOnly}
        disabled={loading || readOnly}
        error={errors}
      >
        {testSets.map(
          testSet =>
            testSet && (
              <Option
                key={testSet.id}
                value={testSet.id}
                label={`${testSet.title} (v${testSet.version})`}
              />
            )
        )}
      </Select>
    </>
  );
}

export default TestsetSelect;
