import React, { useEffect, useState } from 'react';
import Select, { Option } from 'components/input/Select';
import { useQuery } from '@apollo/client';
import { QUERY_GET_TESTSETS_LIST_WITH_TESTS } from 'services/aws/testsets-query';
import { TestSetV2Type } from 'constants.js';
import { useIntl } from 'react-intl';
import { sort } from 'utils/sort';
import messages from 'messages';

function MilestoneSelect({
  entityId,
  milestoneId,
  injuryId,
  onChange,
  readOnly = false,
  extraClassNames,
  errors
}) {
  const intl = useIntl();
  const [milestoneTestSet, setMilestoneTestSet] = useState([]);
  const { loading, error, data } = useQuery(
    QUERY_GET_TESTSETS_LIST_WITH_TESTS,
    {
      variables: { entityId, type: TestSetV2Type.REHAB_TEMPLATE, injuryId },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (data?.getTestSetsV2) {
      if (data.getTestSetsV2.length === 0) {
        onChange('');
      }
      setMilestoneTestSet(
        sort(data.getTestSetsV2, { keys: [{ key: 'title' }] })
      );
      if (milestoneId) {
        onChange(data.getTestSetsV2.find(i => milestoneId === i.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) {
    return intl.formatMessage(messages.screentypesError);
  }

  if (loading) {
    return (
      <Select
        isLoading
        label={intl.formatMessage(messages.rehabScreeningMilestoneLabel)}
        value=""
        readOnly={readOnly}
        disabled={loading || readOnly}
      >
        <Option value="" label={intl.formatMessage(messages.globalLoading)} />
      </Select>
    );
  }

  if (milestoneTestSet.length === 0) {
    return (
      <Select
        label={intl.formatMessage(messages.rehabScreeningMilestoneLabel)}
        value=""
        readOnly={readOnly}
        disabled={readOnly}
        extraClassNames={extraClassNames}
      >
        <Option
          value=""
          label={intl.formatMessage(
            messages.rehabScreeningMilestoneNonOptionLabel
          )}
          selected={'selected'}
        />
      </Select>
    );
  }

  return (
    <>
      <Select
        id="milestone"
        label={intl.formatMessage(messages.rehabScreeningMilestoneLabel)}
        name="milestone"
        onChange={selectedValue => {
          if (selectedValue.value === '0') {
            onChange(0);
          } else {
            onChange(milestoneTestSet.find(i => selectedValue.value === i.id));
          }
        }}
        value={milestoneId}
        readOnly={readOnly}
        disabled={loading || readOnly}
        placeholder={intl.formatMessage(
          messages.rehabScreeningMilestonePlaceholder
        )}
        extraClassNames={extraClassNames}
        errors={errors}
      >
        {milestoneTestSet.map(
          testSet =>
            testSet &&
            testSet.title?.length > 0 && (
              <Option
                key={testSet.id}
                value={testSet.id}
                label={testSet.title}
              />
            )
        )}
        <Option
          value="0"
          label={intl.formatMessage(
            messages.rehabScreeningMilestoneNonOptionLabel
          )}
        />
      </Select>
    </>
  );
}

export default MilestoneSelect;
