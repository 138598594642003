import React, { useContext, useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import {
  Panel,
  PanelBody,
  PanelHeader,
  PanelSubtitle,
  PanelTitle,
  PanelTitleButton,
  PanelTitleWrapper
} from 'components/panel';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import { TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import { StoreContext } from 'index';
import { usePersonContext } from 'contexts/PersonContext';
import ScreeningDetailCard from 'containers/partials/cards/ScreeningDetailCard';
import { useMedicalContext } from 'contexts/MedicalContext';
import {
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RESULTS,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RESULTS_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORTS,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS
} from 'routes/RouteList';
import { MenuWrapper } from 'components/menu';
import { FormattedMessage } from 'react-intl';
import RemoveScreeningButton from 'containers/partials/buttons/RemoveScreeningsButton';
import messages from 'messages';
import { getLocalDateFormat, getToday } from 'utils/date';
import useTabIndex from 'hooks/utils/useTabIndex';
import EditTestResultCard from 'containers/partials/cards/EditTestResultCard';
import ReportCard from 'containers/partials/cards/ReportCard';
import Loader from 'components/loader/Loader';
import { TestsProvider } from 'contexts/TestsContext';
import { useSessionContext } from 'contexts/SessionContext';
import DownloadSessionReportButton from 'containers/partials/buttons/DownloadSessionReportButton';
import MailReportButton from 'containers/partials/buttons/MailReportButton';
import DownloadEmptyFormButton from 'containers/partials/buttons/DownloadEmptyFormButton';
import { TestSetContextProvider } from 'contexts/TestSetContext';
import { TestDataProvider } from 'contexts/TestDataContext';
import TabLink from 'components/tabs/TabLink';
import CopySessionButton from 'containers/partials/buttons/CopySessionButton';
import Session from 'models/Session';
import AddScreeningModal from 'containers/pages/rehab/screening/AddScreeningModal';

export const parseTestSetTests = tests => {
  if (!tests) return [];

  return tests.map(test => {
    // find if it's a dependency
    const isDependency = tests.some(ts => {
      try {
        return ts.dependedTestIds?.includes(test.id);
      } catch (e) {
        return [];
      }
    });

    // find it's status
    const status = true;

    // add sides
    let sides;
    switch (test.laterality.includes('bilateral_')) {
      case 1:
        sides = [];
        break;
      case 2:
        sides = ['left', 'right'];
        break;
      default:
        break;
    }

    return { ...test, isDependency, status, sides };
  });
};

function Screening({ active, edit }) {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { entityId, sporterId, injuryId, sessionId } = useParams();
  const { person } = usePersonContext();
  const { medical, loading: medicalLoading } = useMedicalContext();
  const { session: screening, loading, options } = useSessionContext();
  const [personInjury, setPersonInjury] = useState({});
  const [reportTabEnabled, setReportTabEnabled] = useState(false);
  const [testDataId, setTestDataId] = useState();
  const [copySessionModal, setCopySessionModal] = useState(false);

  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORTS,
    [
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RESULTS,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RESULTS_EDIT
    ],
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY
  ]);

  useEffect(() => {
    if (medical?.injuries) {
      setPersonInjury(medical.injuries.find(i => i.id === injuryId));
    }
  }, [medical, injuryId]);

  useEffect(() => {
    if (screening && sporterId) {
      const testData = screening.getTestData(sporterId);
      setTestDataId(testData?.id ?? null);
    }
  }, [screening, sporterId]);

  useEffect(() => {
    if (testDataId) {
      setReportTabEnabled(true);
    }
  }, [screening, testDataId]);

  const copySession = () => {
    const newSession = new Session({ ...screening });
    newSession.id = null;
    newSession.startdate = getToday();

    setCopySessionModal(newSession);
  };

  if (loading || medicalLoading || !medical?.id) {
    return (
      <Panel active={active}>
        <Loader />
      </Panel>
    );
  }

  return (
    <>
      <Panel active={active}>
        <PanelHeader>
          <Breadcrumbs>
            <Breadcrumb to={generatePath(ROUTE_SPORTERS_ENTITY, { entityId })}>
              <FormattedMessage {...messages.breadcrumbSporters} />
            </Breadcrumb>
            <Breadcrumb
              to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER, {
                entityId,
                sporterId
              })}
            >
              {`${person.firstname} ${person.lastname}`}
            </Breadcrumb>
            <Breadcrumb
              to={generatePath(
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS,
                {
                  entityId,
                  sporterId,
                  medicalId: medical?.id,
                  injuryId: injuryId
                }
              )}
            >
              <FormattedMessage {...messages.breadcrumbScreening} />
            </Breadcrumb>
          </Breadcrumbs>
          <PanelTitleWrapper>
            <PanelTitle>
              {screening.name}
              {user.canRemoveScreenings(entityId) && (
                <MenuWrapper trigger={<PanelTitleButton />}>
                  <DownloadEmptyFormButton session={screening} />
                  <DownloadSessionReportButton
                    session={screening}
                    testDataId={testDataId}
                    options={options}
                  />
                  <MailReportButton
                    menu
                    entityId={entityId}
                    session={screening}
                    sporter={person}
                    testDataId={testDataId}
                    options={options}
                  >
                    <FormattedMessage {...messages.menuItemSessionMailReport} />
                  </MailReportButton>
                  <RemoveScreeningButton
                    menu
                    entityId={entityId}
                    personInjuryId={injuryId}
                    testSessionId={sessionId}
                    personId={sporterId}
                  >
                    <FormattedMessage
                      {...messages.rehabRemoveScreeningButton}
                    />
                  </RemoveScreeningButton>
                  <CopySessionButton
                    menu
                    entityId={entityId}
                    session={screening}
                    onClick={() => copySession()}
                  />
                </MenuWrapper>
              )}
            </PanelTitle>
            <PanelSubtitle>
              {getLocalDateFormat(screening.startdate)}
            </PanelSubtitle>
          </PanelTitleWrapper>
        </PanelHeader>
        <PanelBody>
          <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
            <TabList>
              <TabLink
                key="screening.report"
                to={generatePath(
                  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORTS,
                  {
                    entityId,
                    sporterId,
                    medicalId: medical.id,
                    injuryId,
                    sessionId
                  }
                )}
              >
                <FormattedMessage {...messages.rehabScreeningReportTab} />
              </TabLink>
              <TabLink
                key="screening.results"
                to={generatePath(
                  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RESULTS,
                  {
                    entityId,
                    sporterId,
                    medicalId: medical.id,
                    injuryId,
                    sessionId
                  }
                )}
              >
                <FormattedMessage {...messages.rehabScreeningResultsTab} />
              </TabLink>
              <TabLink
                key="screening.detail"
                to={generatePath(
                  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY,
                  {
                    entityId,
                    sporterId,
                    medicalId: medical.id,
                    injuryId,
                    sessionId
                  }
                )}
              >
                <FormattedMessage {...messages.rehabScreeningDetailsTab} />
              </TabLink>
            </TabList>
            <TabPanel key="screening.report">
              <TabPanelBody withScroller>
                <TestDataProvider
                  entityId={entityId}
                  session={screening}
                  sporterId={sporterId}
                >
                  <ReportCard
                    testDataId={testDataId}
                    entityId={entityId}
                    rootEntityId={entityId}
                    session={screening}
                    sporter={person}
                    personInjury={personInjury}
                    compressed
                  />
                </TestDataProvider>
              </TabPanelBody>
            </TabPanel>
            <TabPanel key="screening.results">
              <TabPanelBody withScroller>
                <TestDataProvider
                  entityId={entityId}
                  session={screening}
                  sporterId={sporterId}
                >
                  <EditTestResultCard
                    entityId={entityId}
                    sporterId={sporterId}
                    personInjury={personInjury}
                    session={screening}
                    edit={edit}
                    reportEnabled={reportTabEnabled}
                    reportPath={generatePath(
                      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORTS,
                      {
                        entityId,
                        sporterId,
                        medicalId: medical.id,
                        injuryId,
                        sessionId
                      }
                    )}
                  />
                </TestDataProvider>
              </TabPanelBody>
            </TabPanel>
            <TabPanel key="screening.detail">
              <TabPanelBody withScroller>
                <TestsProvider entityId={entityId}>
                  <TestSetContextProvider>
                    <ScreeningDetailCard
                      key={screening.id}
                      personInjury={personInjury}
                      edit={false}
                    />
                  </TestSetContextProvider>
                </TestsProvider>
              </TabPanelBody>
            </TabPanel>
          </Tabs>
        </PanelBody>
      </Panel>

      {copySessionModal && (
        <TestsProvider entityId={entityId}>
          <AddScreeningModal
            entityId={entityId}
            screening={copySessionModal}
            personInjury={personInjury}
            onClose={() => setCopySessionModal(false)}
          />
        </TestsProvider>
      )}
    </>
  );
}

export default Screening;
