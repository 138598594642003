import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_EXERCISE_NOTES } from 'services/aws/exercises-query';
import Note from 'models/Note';
import { sort, SORT_DATA_TYPES } from 'utils/sort';

const useExerciseNotes = () => {
  const [notes, setNotes] = useState([]);

  const [getExerciseNotes, { loading, error, data }] = useLazyQuery(
    QUERY_GET_EXERCISE_NOTES
  );

  useEffect(() => {
    if (data?.getExerciseNotes) {
      const notes = data.getExerciseNotes.map(note => new Note({ ...note }));
      setNotes(
        sort(notes, {
          keys: [
            {
              key: 'date_created',
              desc: true,
              dataType: SORT_DATA_TYPES.NUMBER
            }
          ]
        })
      );
    }
  }, [data]);

  const getNotes = async variables => {
    return getExerciseNotes({ variables: { ...variables } });
  };

  return { getNotes, notes, loading, error };
};

export default useExerciseNotes;
