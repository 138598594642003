import React from 'react';
import classNames from 'classnames';

class PanelHeaderSettings extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div className={classNames('c-panel__header-settings')}>{children}</div>
    );
  }
}

export default PanelHeaderSettings;
