import { useContext } from 'react';
import { TabList, Tab, TabPanel, Tabs } from 'components/tabs';
import { FormattedMessage, useIntl } from 'react-intl';
import { Panel, PanelHeader } from 'components/panel';
import messages from 'messages';
import {
  PanelTitleWrapper,
  PanelTitle,
  PanelTitleButton,
  PanelTitleButtonsGroup
} from 'components/panel/panel-title';
import PanelBody from 'components/panel/PanelBody';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import './Videos.scss';
import { MenuWrapper } from 'components/menu';
import Loader from 'components/loader/Loader';
import { AbilityContext } from 'Can';
import BreadcrumbPath from 'containers/partials/misc/BreadcrumbPath';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useAbility } from '@casl/react';
import { ROUTE_VIDEOS_ENTITY } from 'routes/RouteList';
import VideoPanel from 'containers/pages/videos/VideoPanel';
import RemoveVideoButton from 'containers/partials/buttons/RemoveVideoButton';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import useVideo from 'hooks/queries/useVideo';
import { StoreContext } from 'index';
import useLesson from 'hooks/queries/useLesson';
import NoAccessPanel from 'containers/pages/auth/NoAccessPanel';
import useEdit from 'hooks/utils/useEdit';
import PanelSubtitleVideo from './PanelSubtitleVideo';

const Video = ({
  entityId,
  videoId,
  tabIndex,
  active,
  editable,
  inLesson = false,
  crumbs,
  parentPath,
  ...props
}) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const { lessonId } = useParams();
  const { lesson } = useLesson(entityId, lessonId);
  const ability = useAbility(AbilityContext);
  const intl = useIntl();
  const [edit, setEdit] = useEdit(props.edit, true);
  const { video, loading, error } = useVideo(entityId, videoId);

  if (loading)
    return (
      <Panel active={active}>
        <Loader />
      </Panel>
    );
  if (error) return <RetryPanel />;

  if (!ability.can('read', video)) {
    return (
      <NoAccessPanel
        crumbs={crumbs}
        entityId={entityId}
        emptyLabel={intl.formatMessage(messages.videosEmptyPanel)}
        redirectRoute={generatePath(ROUTE_VIDEOS_ENTITY, {
          entityId
        })}
      />
    );
  }

  return (
    <Panel active={active}>
      <PanelHeader>
        {crumbs && (
          <BreadcrumbPath
            crumbs={crumbs}
            inLesson={inLesson}
            customLabel={inLesson && lesson.title}
            isMine={video.isMine(user)}
          />
        )}
        <PanelTitleWrapper>
          <PanelTitle>
            <span title={video.title}>{video.title}</span>
            <PanelTitleButtonsGroup>
              <MenuWrapper trigger={<PanelTitleButton />}>
                {ability.can('delete', video) && editable && (
                  <RemoveVideoButton entityId={entityId} videoId={videoId} />
                )}
                {ability.can('update', video) && editable && (
                  <Button
                    menu
                    disabled={edit}
                    onClick={() => {
                      if (setEdit) setEdit(true);
                      push(`${parentPath}/edit`);
                    }}
                  >
                    <Icon
                      id="edit"
                      strokeColor={
                        edit ? 'color-neutral' : 'color-neutral-dark'
                      }
                      // TODO: Fabian Refactor edit icon
                    />
                    <FormattedMessage {...messages.videoEdit} />
                  </Button>
                )}
              </MenuWrapper>
            </PanelTitleButtonsGroup>
          </PanelTitle>
          <PanelSubtitleVideo video={video} user={user} />
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        <Tabs defaultIndex={tabIndex}>
          <TabList>
            <Tab>
              <FormattedMessage {...messages.breadcrumbVideo} />
            </Tab>
          </TabList>
          <TabPanel className="react-tabs__tab-panel u-padding-vertical-none">
            <VideoPanel
              entityId={entityId}
              video={video}
              videoId={videoId}
              edit={edit}
              editable={editable && video.editable}
              setEdit={edit => setEdit(edit)}
              onCancel={() => push(parentPath)}
              parentPath={parentPath}
            />
          </TabPanel>
        </Tabs>
      </PanelBody>
    </Panel>
  );
};

export default Video;
