import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from 'messages';
import { TabList, Tab, TabPanel, Tabs } from 'components/tabs';
import Badge from 'components/badge/Badge';
import Button from 'components/button/Button';
import Panel from 'components/panel/Panel';
import PanelHeader from 'components/panel/PanelHeader';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import {
  ROUTE_SCANNERS_ENTITY_SCANNER,
  ROUTE_SCANNERS_ENTITY
} from 'routes/RouteList';
import {
  MUTATION_ADD_SCANNER,
  QUERY_GET_SCANNERS_BY_ENTITY_ID
} from 'services/aws/scanner-query';
import { Mutation } from '@apollo/client/react/components';

import PanelBody from 'components/panel/PanelBody';

import { Card, CardBody, CardFooter } from 'components/card';
import {
  CardHeader,
  CardHeaderButtons,
  CardHeaderTitle
} from 'components/card/card-header';
import {
  PanelSubtitle,
  PanelTitleWrapper,
  PanelTitle
} from 'components/panel/panel-title';
import FieldInput from 'components/input/FieldInput';

class AddScanner extends Component {
  state = {
    name: '',
    nameError: false,
    vendor: '',
    vendorError: false,
    type: '',
    typeError: false,
    location: '',
    coil: ''
  };

  onChange = e =>
    this.setState({
      [e.target.id]: e.target.value,
      [`${e.target.id}Error`]: e.target.value ? false : true
    });

  handleSubmit = async (e, mutation) => {
    e.preventDefault();

    const { entityId } = this.props;
    const { name, vendor, type, coil, location } = this.state;

    await mutation({
      variables: {
        entityId,
        name,
        vendor,
        type,
        coil: coil || '',
        location: location || ''
      }
    });
  };
  render() {
    const {
      routing: { push },
      entityId,
      active,
      intl
    } = this.props;

    const { name, nameError, type, typeError, vendor, vendorError } =
      this.state;

    const isValidToSubmit = () => name && type && vendor;
    return (
      <Panel active={active}>
        <PanelHeader>
          <Breadcrumbs>
            <Breadcrumb
              onClick={() =>
                push(ROUTE_SCANNERS_ENTITY.replace(':entityId', entityId))
              }
            >
              <FormattedMessage {...messages.breadcrumbScanners} />
            </Breadcrumb>
          </Breadcrumbs>
          <PanelTitleWrapper>
            <PanelTitle>
              {name ? (
                name
              ) : (
                <FormattedMessage {...messages.scannerTitleNewScanner} />
              )}
            </PanelTitle>
            <PanelSubtitle />
          </PanelTitleWrapper>
        </PanelHeader>
        <PanelBody>
          <Tabs>
            <TabList>
              <Tab>
                <FormattedMessage {...messages.scannerTabGlobal} />
              </Tab>
              <Tab disabled>
                {/* TODO: Check if this is correct? */}
                <Badge>0</Badge>
                <FormattedMessage {...messages.scannerTabCalibrations} />
              </Tab>
            </TabList>
            <TabPanel>
              <Mutation
                mutation={MUTATION_ADD_SCANNER}
                refetchQueries={[
                  {
                    query: QUERY_GET_SCANNERS_BY_ENTITY_ID,
                    variables: { entityId }
                  }
                ]}
                update={(cache, { data: { addMtsScanner } }) => {
                  if (addMtsScanner && addMtsScanner.id) {
                    push(
                      `${ROUTE_SCANNERS_ENTITY_SCANNER.replace(
                        ':entityId',
                        entityId
                      ).replace(':scannerId', addMtsScanner.id)}`
                    );
                  }
                }}
              >
                {(addScanner, result) => {
                  const { loading, error } = result;
                  return (
                    <Card secondary>
                      <form>
                        <CardHeader secondary>
                          <CardHeaderTitle>
                            <FormattedMessage
                              {...messages.scannerTitleDetails}
                            />
                          </CardHeaderTitle>

                          <CardHeaderButtons>
                            <Button
                              type="submit"
                              tiny
                              disabled={loading || !isValidToSubmit()}
                              onClick={e => this.handleSubmit(e, addScanner)}
                            >
                              {intl.formatMessage(messages.scannerButtonSubmit)}
                            </Button>
                          </CardHeaderButtons>
                        </CardHeader>
                        <CardBody secondary>
                          <FieldInput
                            id="name"
                            onChange={this.onChange}
                            icon={nameError ? 'validation-error' : ' '}
                          >
                            <FormattedMessage {...messages.scannerNameLabel} />*
                          </FieldInput>

                          <FieldInput
                            id="vendor"
                            onChange={this.onChange}
                            icon={vendorError ? 'validation-error' : ' '}
                          >
                            <FormattedMessage
                              {...messages.scannerVendorLabel}
                            />
                            *
                          </FieldInput>

                          <FieldInput
                            id="type"
                            onChange={this.onChange}
                            icon={typeError ? 'validation-error' : ' '}
                          >
                            <FormattedMessage {...messages.scannerTypeLabel} />*
                          </FieldInput>
                          <FieldInput id="coil" onChange={this.onChange}>
                            <FormattedMessage
                              {...messages.scannerCoilTypeLabel}
                            />
                          </FieldInput>
                          <FieldInput id="location" onChange={this.onChange}>
                            <FormattedMessage
                              {...messages.scannerLocationLabel}
                            />
                          </FieldInput>
                        </CardBody>
                        <CardFooter secondary>
                          <Button
                            secondary
                            onClick={() =>
                              push(
                                ROUTE_SCANNERS_ENTITY.replace(
                                  ':entityId',
                                  entityId
                                )
                              )
                            }
                          >
                            <FormattedMessage
                              {...messages.scannerButtonCancel}
                            />
                          </Button>
                          <Button
                            type="submit"
                            primary
                            disabled={loading || !isValidToSubmit()}
                            onClick={e => this.handleSubmit(e, addScanner)}
                          >
                            <FormattedMessage
                              {...messages.scannerButtonSubmit}
                            />
                          </Button>
                        </CardFooter>
                      </form>
                    </Card>
                  );
                }}
              </Mutation>
            </TabPanel>
            <TabPanel />
          </Tabs>
        </PanelBody>
      </Panel>
    );
  }
}

export default withRouter(injectIntl(inject('routing')(observer(AddScanner))));
