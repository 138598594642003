import React, { useContext } from 'react';
import * as Sentry from '@sentry/react';
import { generatePath } from 'react-router-dom';
import { StoreContext } from 'index';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import Modal from 'components/modal/Modal';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import { Form, Formik } from 'formik';
import { CardBody, CardFooter } from 'components/card';
import FieldInput from 'components/input/FieldInput';
import messages from 'messages';
import { Button, ButtonsGroup } from 'components/button';
import {
  MUTATION_ADD_PERSONS_TO_PROGRAM,
  MUTATION_ADD_PROGRAM,
  QUERY_GET_PROGRAMS
} from 'services/aws/programs-query';
import { ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT } from 'routes/RouteList';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper
} from 'components/list/list-item';
import { NoteTypes } from 'constants.js';
import { MUTATION_ADD_NOTE } from 'services/aws/notes-query';
import { FieldDatePicker } from 'components/input/FieldDatePicker';
import { useNotificationQueue } from 'components/notification';
import useTemplate from 'hooks/queries/useTemplate';
import Loader from 'components/loader/Loader';
import { parseDateString } from 'utils/date';
import { format } from 'date-fns';

const getNote = (exercise, notes) => {
  if (notes && notes.length > 0) {
    return notes[0].note;
  }
  if (exercise.note) {
    return exercise.note;
  }
  return '';
};

function AddProgramModal({
  entityId,
  destiny = {
    path: ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT,
    variables: {}
  },
  program,
  persons,
  onClose,
  fromTemplate
}) {
  const {
    routing: { push }
  } = useContext(StoreContext);
  const intl = useIntl();
  const notification = useNotificationQueue();
  const [addProgram] = useMutation(MUTATION_ADD_PROGRAM);
  const [addNote] = useMutation(MUTATION_ADD_NOTE);
  const [addPersonsToProgram] = useMutation(MUTATION_ADD_PERSONS_TO_PROGRAM);
  const { template, exercises, loading } = useTemplate(
    entityId,
    program?.templateId
  );

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage(messages.titleRequired)),
    startdate: Yup.date()
      .transform(parseDateString)
      .typeError(intl.formatMessage(messages.dateRequired))
      .required(intl.formatMessage(messages.dateRequired)),
    duration: Yup.number().required(
      intl.formatMessage(messages.durationRequired)
    )
  });

  const onSubmitForm = async program => {
    let newProgramId = '';
    addProgram({
      variables: {
        ...program,
        startdate: format(program.startdate, 'yyyy-LL-dd')
      },
      refetchQueries: [{ query: QUERY_GET_PROGRAMS, variables: { entityId } }]
    })
      .then(res => {
        newProgramId = res.data.addExerciseProgram.id;
        Promise.all([
          ...Object.values(template.notes).map(note => {
            return addNote({
              variables: {
                linkId: newProgramId,
                linkId2: note[0].linkId2,
                note: note[0].note,
                noteType: NoteTypes.PROGRAM
              }
            });
          }),
          persons &&
            addPersonsToProgram({
              variables: {
                exerciseProgramId: newProgramId,
                personIds: persons.map(p => p.id)
              }
            })
        ]).then(() => {
          push(
            generatePath(destiny.path, {
              entityId,
              programId: newProgramId,
              ...destiny.variables
            })
          );
        });
      })
      .catch(error => {
        notification.add(`addProgramError${program.id}`, {
          message: intl.formatMessage(messages.messageAddProgramSaveError),
          level: 'error'
        });
        Sentry.captureException(error);
      });
  };

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal card autoHeight isOpen={true} onClose={onCloseHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage
            {...messages.exercisesAddProgramTitle}
            values={{
              fromTemplate
            }}
          />
        </CardHeaderTitle>
      </CardHeader>
      <Formik
        initialValues={program}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={values => onSubmitForm(values)}
      >
        {({ errors, touched, handleChange }) => {
          return (
            <Form noValidate>
              <CardBody modalWithOverflow>
                <FieldInput
                  id="title"
                  name="title"
                  placeholder={intl.formatMessage(
                    messages.programPlaceholderName
                  )}
                  errors={errors}
                  touched={touched}
                  required
                >
                  <FormattedMessage {...messages.programLabelName} />
                </FieldInput>
                <div className="o-layout o-layout--small">
                  <div className="o-layout__item u-1-of-2">
                    <FieldDatePicker
                      id="startdate"
                      name="startdate"
                      icon="calendar"
                      placeholder={intl.formatMessage(
                        messages.programPlaceholderStartDate
                      )}
                      errors={errors}
                      touched={touched}
                      onChange={handleChange}
                      required
                    >
                      <FormattedMessage {...messages.programLabelStartDate} />
                    </FieldDatePicker>
                  </div>
                  <div className="o-layout__item u-1-of-2">
                    <FieldInput
                      id="duration"
                      name="duration"
                      placeholder={intl.formatMessage(
                        messages.programPlaceholderWeeks
                      )}
                      errors={errors}
                      touched={touched}
                      required
                    >
                      <FormattedMessage {...messages.programLabelWeeks} />
                    </FieldInput>
                  </div>
                </div>
              </CardBody>
              {fromTemplate && (
                <CardBody
                  modal
                  extraClassNames="u-padding-bottom-none u-padding-horizontal-none"
                >
                  <CardHeader
                    secondary
                    extraClassNames="u-margin-bottom-medium"
                  >
                    <CardHeaderText extraClassNames="u-margin-bottom-none u-padding-horizontal-small">
                      <CardHeaderTitle>
                        <FormattedMessage {...messages.exercisesInTemplate} />
                      </CardHeaderTitle>
                    </CardHeaderText>
                  </CardHeader>
                  {loading && <Loader />}
                  <List
                    draggableDisabled
                    extraClassNames="u-margin-top-none u-padding-bottom-large u-padding-right-small"
                  >
                    <ListBody extraClassNames="u-margin-top-none">
                      {exercises.map(exercise => (
                        <ListItem key={exercise.id}>
                          <ListItemLabelWrapper>
                            <ListItemLabel label>
                              {exercise.title}
                            </ListItemLabel>
                            <div className="c-list__item-small-editing-area">
                              <p>
                                {getNote(exercise, template.notes[exercise.id])}
                              </p>
                            </div>
                          </ListItemLabelWrapper>
                        </ListItem>
                      ))}
                    </ListBody>
                  </List>
                </CardBody>
              )}
              <CardFooter modal extraClassNames="c-card__footer--modal-larger">
                <ButtonsGroup>
                  <Button secondary onClick={() => onCloseHandler()}>
                    <FormattedMessage {...messages.buttonCancel} />
                  </Button>
                  <Button type="submit" primary>
                    <FormattedMessage {...messages.buttonSave} />
                  </Button>
                </ButtonsGroup>
              </CardFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default AddProgramModal;
