import { searchPerson } from '../services/aws/user-query';

export const isEmailValid = email => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const emailExists = async (email, entityId) => {
  const result = await searchPerson(email, entityId);
  return result.data.searchPerson.length > 0;
};
