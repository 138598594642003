import { useContext, useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import { Card, CardBody } from 'components/card';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { Button } from 'components/button';
import { useFormikContext } from 'formik';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import PreventionDetail from './PreventionDetail';
import PreventionSelectedTestsCard from './PreventionSelectedTestsCard';
import Icon from 'components/icon/Icon';
import { StoreContext } from 'index';
import { ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION } from 'routes/RouteList';
import { useSessionContext } from 'contexts/SessionContext';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import { useTestsContext } from 'contexts/TestsContext';
import useEdit from 'hooks/utils/useEdit';

function PreventionSession({
  entityId,
  sessionId,
  editPanel = false,
  ...props
}) {
  const {
    routing: { push }
  } = useContext(StoreContext);
  const { session } = useSessionContext();
  const { submitForm, handleReset, isValid, isSubmitting } = useFormikContext();
  const { testActions, loading } = useTestsContext();
  const [edit, setEdit] = useEdit(props.edit, true);

  useEffect(() => {
    testActions.getTests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading) {
      testActions.setSelectedTests(session.tests);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.tests, loading]);

  const onEditPreventionHandler = () => {
    push(
      generatePath(ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION, {
        entityId,
        sessionId: session.id,
        type: session.preventionType
      })
    );
  };

  return (
    <>
      <Card secondary multiBody>
        <CardHeader secondary>
          <CardHeaderTitle>
            <FormattedMessage {...messages.titleSessionDetails} />
          </CardHeaderTitle>
          <FormEditHeaderButtons
            edit={edit}
            setEdit={setEdit}
            onCancel={handleReset}
            onSubmit={submitForm}
            type={'button'}
          />
        </CardHeader>
        <CardBody secondary>
          <PreventionDetail entityId={entityId} edit={edit} />
        </CardBody>
        {editPanel && (
          <FormEditFooterButtons
            edit={edit}
            setEdit={setEdit}
            disabledSubmit={!isValid || isSubmitting}
            onCancel={handleReset}
            onSubmit={submitForm}
            type={'button'}
          />
        )}
      </Card>

      <PreventionSelectedTestsCard
        childrenOnTop
        edit={edit}
        onEdit={edit => setEdit(edit)}
        extraClassNames={'u-padding-vertical-medium'}
      >
        <Button
          extraClassNames="u-margin-left-auto"
          small
          ghost
          onClick={onEditPreventionHandler}
        >
          <Icon id="edit" />
          <FormattedMessage {...messages.fullEditButton} />
        </Button>
      </PreventionSelectedTestsCard>
    </>
  );
}

export default PreventionSession;
