import React from 'react';
import { inject } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Modal from 'components/modal/Modal';
import {
  ROUTE_GROWTH_PREDICTION_ENTITY_SPORTER,
  ROUTE_GROWTH_PREDICTION_ENTITY_GROUP,
  ROUTE_GROWTH_PREDICTION_ENTITY_SESSION,
  ROUTE_GROWTH_PREDICTION_ENTITY
} from 'routes/RouteList';
import GrowthPredictionChoiceView from './GrowthPredictionChoiceView';
import SelectGroupView from './SelectGroupView';
import { GrowthPredictionModalView } from './growth-prediction-modal-view';
import SelectSessionView from './SelectSessionView';
import SelectSporterView from './SelectSporterView';
import { PackageSubTypes } from 'constants.js';

class GrowthPredictionMainModal extends React.Component {
  state = {
    view: this.props.view || GrowthPredictionModalView.GROWTH_PREDICTION_GROUP,
    isOpen: true
  };

  onSubmitClientHandler = (id, extraId, name) => {
    const { onSubmitHandler } = this.props;
    if (onSubmitHandler && typeof onSubmitHandler === 'function') {
      onSubmitHandler(id, extraId, name);
    }
  };

  onSubmitMasterHandler = (id, extraId) => {
    const {
      onCloseHandler,
      routing,
      routing: { push },
      authStore: {
        user: { rootEntityId, testSets }
      }
    } = this.props;
    const { view } = this.state;
    let route;
    switch (view) {
      case GrowthPredictionModalView.GROWTH_PREDICTION_GROUP:
        route = ROUTE_GROWTH_PREDICTION_ENTITY_GROUP.replace(':groupId', id);
        break;
      case GrowthPredictionModalView.GROWTH_PREDICTION_SPORTER:
        route = ROUTE_GROWTH_PREDICTION_ENTITY_SPORTER.replace(
          ':sporterId',
          id
        ).replace(':groupId', extraId);
        break;
      case GrowthPredictionModalView.GROWTH_PREDICTION_SESSION:
        route = ROUTE_GROWTH_PREDICTION_ENTITY_SESSION.replace(
          ':sessionId',
          id
        );
        break;
      default:
        route = ROUTE_GROWTH_PREDICTION_ENTITY;
    }
    let testSetId = testSets[0].id;
    if (testSets.length > 0) {
      const hasIdoSet = testSets.find(ts => ts.subtype === PackageSubTypes.IDO);

      if (hasIdoSet) {
        testSetId = hasIdoSet.id;
      } else {
        testSetId = testSets.find(ts => ts.subtype === PackageSubTypes.HAN);
      }
    }

    route = route
      .replace(':popup', 'default')
      .replace(':testSetId', testSetId)
      .replace(':entityId', rootEntityId)
      .replace(':benchmarkId', 'default');

    if (onCloseHandler && typeof onCloseHandler === 'function') {
      onCloseHandler();
    }
    if (route !== routing.location.pathname) {
      push(route);
    }
  };

  onCancelHandler = () => {
    this.onCloseHandler();
  };

  onCloseHandler = () => {
    const { routing, onCloseHandler } = this.props;
    if (onCloseHandler && typeof onCloseHandler === 'function') {
      onCloseHandler();
    } else {
      routing.goBack();
    }
  };

  getView = view => {
    switch (view) {
      case GrowthPredictionModalView.GROWTH_PREDICTION_CHOICE:
        return GrowthPredictionChoiceView;
      case GrowthPredictionModalView.GROWTH_PREDICTION_GROUP:
        return SelectGroupView;
      case GrowthPredictionModalView.GROWTH_PREDICTION_SESSION:
        return SelectSessionView;
      case GrowthPredictionModalView.GROWTH_PREDICTION_SPORTER:
        return SelectSporterView;
      default:
        return SelectGroupView;
    }
  };

  render() {
    const Component = this.getView(this.state.view);
    const { entityId, master } = this.props;
    const { isOpen } = this.state;
    return (
      <Modal card isOpen={isOpen} onClose={() => this.onCloseHandler()}>
        <Component
          entityId={entityId}
          onChangeView={view => this.setState({ view })}
          onCancelHandler={this.onCancelHandler}
          onSubmitHandler={
            master ? this.onSubmitMasterHandler : this.onSubmitClientHandler
          }
        />
      </Modal>
    );
  }
}

export default withRouter(
  injectIntl(inject('routing', 'authStore')(GrowthPredictionMainModal))
);
