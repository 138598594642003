import { useContext, useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { TabList, Tab, TabPanel, Tabs, TabPanelBody } from 'components/tabs';
import { FormattedMessage, useIntl } from 'react-intl';
import { Panel, PanelHeader, PanelSubtitle } from 'components/panel';
import messages from 'messages';
import {
  PanelTitleWrapper,
  PanelTitle,
  PanelTitleButton
} from 'components/panel/panel-title';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import PanelBody from 'components/panel/PanelBody';

import {
  ROUTE_USER_ENTITY_PROGRAMS,
  ROUTE_USER_ENTITY_PROGRAMS_PROGRAM,
  ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_EXERCISE
} from 'routes/RouteList';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import { MenuWrapper } from 'components/menu';
import RemoveExerciseButton from 'containers/partials/buttons/RemoveExerciseButton';
import { StoreContext } from 'index';
import Loader from 'components/loader/Loader';
import Card from 'components/card/Card';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import CardBody from 'components/card/CardBody';
import Cues from 'containers/partials/inputs/Cues';
import { Button, ButtonsGroup } from 'components/button';
import Icon from 'components/icon/Icon';
import { useProgramContext } from 'contexts/ProgramContext';
import { sortByArray } from 'utils/sort';
import MediaViewer from 'containers/partials/media/MediaViewer';
import useExercise from 'hooks/queries/useExercise';
import { sanitize } from 'dompurify';

const getNote = (exercise, notes) => {
  if (notes && notes.length > 0) {
    return notes[0].note;
  }
  if (exercise?.note) {
    return exercise.note;
  }
  return '';
};

const ProgramExercise = ({
  entityId,
  exerciseId,
  programId,
  tabIndex,
  active
}) => {
  const {
    authStore: { user },
    routing: { push }
  } = useContext(StoreContext);
  const intl = useIntl();
  const { program, exercises } = useProgramContext();
  const { exercise, loading, error, getExercise } = useExercise(
    entityId,
    exerciseId
  );
  const [exerciseIndex, setExerciseIndex] = useState(null);

  useEffect(() => {
    getExercise();
  }, []);

  useEffect(() => {
    if (exercises) {
      const index = exercises.findIndex(e => e.id === exerciseId);
      setExerciseIndex(index);
    }
  }, [exerciseId, exercises]);

  if (loading)
    return (
      <Panel active={active}>
        <Loader />
      </Panel>
    );
  if (error) return <RetryPanel />;

  return (
    <Panel active={active}>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb
            to={generatePath(ROUTE_USER_ENTITY_PROGRAMS, {
              entityId,
              programId
            })}
          >
            <FormattedMessage {...messages.breadcrumbPrograms} />
          </Breadcrumb>
          <Breadcrumb
            to={generatePath(ROUTE_USER_ENTITY_PROGRAMS_PROGRAM, {
              entityId,
              programId
            })}
          >
            <FormattedMessage {...messages.breadcrumbProgram} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <span className="" title={exercise.title}>
              {exercise.title}
            </span>

            {user.canRemoveExercises(entityId) && exercise.editable && (
              <MenuWrapper trigger={<PanelTitleButton />}>
                <RemoveExerciseButton
                  entityId={entityId}
                  exerciseId={exerciseId}
                />
              </MenuWrapper>
            )}
          </PanelTitle>

          <PanelSubtitle>
            <FormattedMessage {...messages.exercisesLabelLevelSubtitle} />{' '}
            {exercise.level}
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody withFixedButtonsBottom>
        <Tabs defaultIndex={tabIndex}>
          <TabList>
            <Tab>
              <FormattedMessage {...messages.breadcrumbExercise} />
            </Tab>
          </TabList>
          <TabPanel>
            <TabPanelBody withScroller>
              <Card secondary multiBody>
                <CardHeader secondary />
                <CardBody secondary separatorBottom>
                  {/* NOTES */}
                  <div
                    className={
                      'c-input__group c-input--closed c-input--read-only'
                    }
                  >
                    <span className={'c-input__label'}>
                      <FormattedMessage {...messages.exercisesLabelNotes} />
                    </span>
                    <div className="c-input--textarea-readonly">
                      {getNote(exercise, program?.notes[exercise.id])}
                    </div>
                  </div>

                  {/* MEDIA */}
                  <MediaViewer
                    entityId={entityId}
                    id={exercise.id}
                    media={
                      exercise.media.length > 0
                        ? exercise.meta?.order
                          ? sortByArray(exercise.media, exercise.meta.order)
                          : exercise.media
                        : []
                    }
                  />

                  {/* CUES */}
                  <Cues
                    entityId={entityId}
                    label={`${intl.formatMessage(messages.exercisesLabelCues)}`}
                    cues={exercise.cues}
                    readOnly={true}
                  />
                </CardBody>

                {/* PROTOCOL */}
                <CardHeader secondary>
                  <CardHeaderText>
                    <CardHeaderTitle>
                      <FormattedMessage {...messages.exercisesLabelProtocol} />
                    </CardHeaderTitle>
                  </CardHeaderText>
                </CardHeader>
                <CardBody secondary>
                  {exercise.protocol && (
                    <div className={'c-textarea--editor'}>
                      <div
                        className={'field-item'}
                        dangerouslySetInnerHTML={{
                          __html: sanitize(exercise.protocol)
                        }}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </TabPanelBody>
          </TabPanel>
        </Tabs>

        <ButtonsGroup extraClassNames="c-button__group-fixed-bottom">
          <Button
            circularLarge
            primaryDark
            disabled={exerciseIndex === 0}
            onClick={() => {
              const prevExercise = exercises[exerciseIndex - 1];
              push(
                generatePath(ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_EXERCISE, {
                  entityId,
                  programId,
                  exerciseId: prevExercise.id
                })
              );
            }}
          >
            <Icon
              id="play"
              fillColor="color-neutral-x-light"
              hugeIcon
              mirrored
            />
          </Button>
          <Button
            circularLarge
            primaryDark
            disabled={exerciseIndex === exercises.length - 1}
            onClick={() => {
              const nextExercise = exercises[exerciseIndex + 1];
              push(
                generatePath(ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_EXERCISE, {
                  entityId,
                  programId,
                  exerciseId: nextExercise.id
                })
              );
            }}
          >
            <Icon id="play" fillColor="color-neutral-x-light" hugeIcon />
          </Button>
          {/* TODO:
            // 1. hoeveel keer is deze oefening gedaan: Teller. Eerste keer tonen we geen badge.
            // 2. `Done` in translations.
            */}
          {/*
          <Button primary>
            Done
            {true && <Badge inButton>1 &times;</Badge>}
          </Button>*/}
        </ButtonsGroup>
      </PanelBody>
    </Panel>
  );
};

export default ProgramExercise;
