import { useContext } from 'react';
import { generatePath } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { usePersonContext } from 'contexts/PersonContext';
import { StoreContext } from 'index';
import { ROUTE_USER_ENTITY_PROGRAMS } from 'routes/RouteList';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import { Panel, PanelHeader } from 'components/panel';
import PanelBody from 'components/panel/PanelBody';

import messages from 'messages';
import {
  PanelSubtitle,
  PanelTitle,
  PanelTitleWrapper
} from 'components/panel/panel-title';
import { Tab, TabList, TabPanel, Tabs } from 'components/tabs';
import { useSessionsContext } from 'contexts/SessionsContext';
import ProgramsPanel from 'containers/pages/user/programs/ProgramsPanel';
import Loader from 'components/loader/Loader';

const OverView = ({ active, entityId }) => {
  const {
    authStore: { user },
    routing: { push }
  } = useContext(StoreContext);
  const { person, loading, error } = usePersonContext();
  const { loading: sessionLoading } = useSessionsContext();

  const programsTab = () => {
    push(generatePath(ROUTE_USER_ENTITY_PROGRAMS, { entityId }));
  };

  return (
    <Panel active={active}>
      {(loading || sessionLoading) && <Loader />}
      {error && <RetryPanel />}
      {person && (
        <>
          <PanelHeader>
            <PanelTitleWrapper>
              <PanelTitle>
                <FormattedMessage {...messages.programs} />
              </PanelTitle>
              <PanelSubtitle>
                <FormattedMessage
                  {...messages.programsSubtitle}
                  values={{
                    name: user.getRelatedEntityNameByEntityId(entityId)
                  }}
                />
              </PanelSubtitle>
            </PanelTitleWrapper>
          </PanelHeader>

          <PanelBody>
            <Tabs selectedIndex={0} onSelect={() => {}}>
              <TabList>
                <Tab onClick={programsTab}>
                  <FormattedMessage {...messages.tabSporterPrograms} />
                </Tab>
              </TabList>
              <TabPanel>
                {/* Spacing and scrolling moved into List component  */}
                <ProgramsPanel entityId={entityId} />
              </TabPanel>
            </Tabs>
          </PanelBody>
        </>
      )}
    </Panel>
  );
};

export default OverView;
