import React, { useContext } from 'react';
import { generatePath } from 'react-router-dom';
import { observer } from 'mobx-react';

import Panel from 'components/panel/Panel';
import PanelHeader from 'components/panel/PanelHeader';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import { FormattedMessage, useIntl } from 'react-intl';
import { ROUTE_EXERCISES, ROUTE_EXERCISES_ENTITY } from 'routes/RouteList';
import messages from 'messages';
import PanelBody from 'components/panel/PanelBody';
import { StoreContext } from 'index';
import ExerciseForm from './ExerciseForm';
import Exercise from 'models/Exercise';
import { Tab, TabList, TabPanel, Tabs } from 'components/tabs';
import ExerciseFormInput from 'containers/pages/exercises/exercises/ExerciseFormInput';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import NoAccessPanel from 'containers/pages/auth/NoAccessPanel';
import BreadcrumbPath from 'containers/partials/misc/BreadcrumbPath';

const AddExercise = ({ entityId, active }) => {
  const {
    routing: { push }
  } = useContext(StoreContext);

  const intl = useIntl();
  const ability = useAbility(AbilityContext);

  const crumbs = [
    {
      path: generatePath(ROUTE_EXERCISES, {
        entityId
      }),
      label: intl.formatMessage(messages.breadcrumbOverview)
    },
    {
      path: generatePath(ROUTE_EXERCISES_ENTITY, {
        entityId
      }),
      label: intl.formatMessage(messages.breadcrumbExercises)
    }
  ];

  if (ability.cannot('create', 'Exercise')) {
    return (
      <NoAccessPanel
        crumbs={crumbs}
        entityId={entityId}
        emptyLabel={intl.formatMessage(messages.exercisesEmptyPanel)}
        redirectRoute={generatePath(ROUTE_EXERCISES_ENTITY, {
          entityId
        })}
      />
    );
  }

  return (
    <Panel active={active}>
      <PanelHeader>
        <BreadcrumbPath crumbs={crumbs} />
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.exercisesAddExerciseTitle} />
          </PanelTitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        <Tabs defaultIndex={0}>
          <TabList>
            <Tab>
              <FormattedMessage {...messages.exerciseInfoTab} />
            </Tab>
          </TabList>
          <TabPanel>
            <ExerciseForm entityId={entityId} exercise={new Exercise({})}>
              {() => (
                <ExerciseFormInput
                  isAdd={true}
                  edit={true}
                  onCancel={() =>
                    push(ROUTE_EXERCISES_ENTITY.replace(':entityId', entityId))
                  }
                  entityId={entityId}
                />
              )}
            </ExerciseForm>
          </TabPanel>
        </Tabs>
      </PanelBody>
    </Panel>
  );
};

export default observer(AddExercise);
