import { makeAutoObservable } from 'mobx';
import { ROUTE_NO_ACCESS, ROUTE_SIGNUP } from 'routes/RouteList';
import * as api from '../services/aws/api';
import * as appSync from '../services/aws/app-sync';
import User from '../models/User';
import { GLOBAL_TESTER_LOGIN, GLOBAL_TESTER_PASSWORD } from 'constants.js';
import { appSyncClient } from 'services/aws/app-sync';
import { createMongoAbility } from '@casl/ability';
import * as Sentry from '@sentry/react';

export default class AuthStore {
  isLoggedIn = false;
  entityId = '';
  tmpCognitoUser = null;
  initLoginStateChanged = null;
  user = null;
  passwordResetEmail = '';
  roles = null;

  abilities = null;

  constructor(routerStore, uiState) {
    this.routerStore = routerStore;
    this.uiState = uiState;

    let storage = localStorage.authStore;
    if (storage) {
      storage = JSON.parse(storage);
      this.isLoggedIn = storage.isLoggedIn ?? false;
      this.entityId = storage.entityId ?? null;
    }

    this.setAbilities();

    makeAutoObservable(this);
  }

  async connect() {
    await api.connect();
    return await api.setClient();
  }

  async setLoginState() {
    if (this.isLoggedIn) {
      this.uiState.increasePendingRequest();
      const user = await api.getCurrentUser();
      if (user && !user.error) {
        if (user.data.getMe.entities.length === 0) {
          return this.noEntities();
        }
        await this.loginSucces(user, true);
      } else {
        if (user.error && user.error.message === 'api-error') {
          return this.loginAPIFailed();
        }
        this.initLoginFailed();
      }
      this.uiState.decreasePendingRequest();
    }
    this.setInitLoginStateChanged(true);
  }

  async doLogin(email, password) {
    try {
      localStorage.clear();
    } catch (er) {}
    this.uiState.increasePendingRequest();
    const user = await api.login(email, password);

    if (user && !user.error) {
      this.uiState.decreasePendingRequest();
      if (user.newPasswordRequired) {
        this.setTmpCognitoUser(user.cognitoUser);
        this.routerStore.push(ROUTE_SIGNUP);
      } else {
        if (user?.data?.getMe && user.data.getMe.entities.length === 0) {
          return this.noEntities();
        } else {
          this.loginSucces(user);
        }
      }
    } else {
      if (user.error && user.error.message === 'api-error') {
        this.loginAPIFailed();
      } else {
        this.loginFail();
      }
      this.uiState.decreasePendingRequest();
    }
    return user;
  }

  async renewRoles() {
    const result = await api.getRoles(this.user.rootEntityId);

    if (!result.error) {
      this.roles = result;
      this.user.setRoles(this.roles);
    } else {
      this.roles = null;
    }
  }

  async setAbilities() {
    this.abilities = createMongoAbility([]);
  }

  async updateAbilities(user) {
    const abilities = user.setAbilities();

    this.abilities.update(abilities);
  }

  async renewEntities() {
    await api.getMe();
  }

  noEntities = async () => {
    this.uiState.decreasePendingRequest();
    await api.logout();

    this.isLoggedIn = false;
    this.user = null;
    this.routerStore.push(ROUTE_NO_ACCESS);

    try {
      localStorage.setItem('authStore', JSON.stringify({ isLoggedIn: false }));
    } catch (er) {}
  };

  async loginSucces(user, refresh) {
    this.user = new User(user.data.getMe, this.entityId);
    // TODO testing different way of logging in
    // const urlEntity = this.routerStore.location.pathname.split('/')[2];

    /*
    // If we don't have an entityId use the one from the url
    if (!this.entityId && !urlEntity) {
      this.entityId = this.user.entities[0].id;
    } else if (!this.entityId && urlEntity) {
      this.entityId = urlEntity;
    }

    const entity = await api.getPersonEntity(this.user.id, this.entityId);
    // this.user.activeEntity = entity;
    // this.user.rootEntityId = entity.id;
    // this.user.rootEntityRoles = entity.roles;

     */

    // set default language first
    const locale = this.user.baseLanguage;
    this.uiState.setLocale(locale);

    this.uiState.increasePendingRequest();
    const entityIndex = this.user.entities.findIndex(
      entity => entity.entity.id === this.entityId
    );
    const entities = this.user.entities.slice();
    if (entityIndex && entityIndex !== -1) {
      let entity = entities[entityIndex];
      if (!entity.baseLanguage) {
        entities[entityIndex].entity = await api.getEntity(this.entityId);
      }
      this.user.updateEntities(entities);
    }
    this.user.setPersonReady(true);
    await this.renewRoles();
    this.user.setConfig();

    await this.updateAbilities(this.user);

    this.user.initIntercom();

    Sentry.setUser({
      id: this.user.id,
      email: this.user.email,
      username: this.user.fullName
    });

    // update t with possible org language
    this.uiState.setTranslations(this.user.getUserLocale());
    this.uiState.decreasePendingRequest();

    this.uiState.setHomeRoute(this.user.routes[0]);

    if (this.entityId === '' && this.user.rootEntityId) {
      this.entityId = this.user.rootEntityId;
    }

    if (this.roles) {
      this.isLoggedIn = true;
      this.persistEntity({ isLoggedIn: true, entityId: this.entityId });
      if (!refresh) {
        this.routerStore.push(this.uiState.homeRoute);
      }

      if (this.routerStore?.location?.pathname) {
        const urlEntity = this.routerStore.location.pathname.split('/')[2];
        const hasEntity = this.user.entities.some(
          entity => entity.entity.id === urlEntity
        );

        // If the entityId doesn't match the urlEntity, redirect the user
        if (hasEntity && this.entityId !== urlEntity) {
          this.setEntityId(urlEntity, this.routerStore.location.pathname);
        }
      }
    } else {
      this.loginFail();
    }
  }

  loginFail() {
    this.isLoggedIn = false;
    try {
      localStorage.setItem('authStore', JSON.stringify({}));
    } catch (er) {}
  }

  initLoginFailed() {
    this.isLoggedIn = false;
    this.user = null;
    this.uiState.decreasePendingRequest();
    try {
      localStorage.setItem('authStore', JSON.stringify({}));
    } catch (er) {}
  }

  loginAPIFailed() {
    this.isLoggedIn = false;
    this.user = null;
    this.uiState.decreasePendingRequest();
    try {
      localStorage.setItem('authStore', JSON.stringify({}));
    } catch (er) {}
  }

  setTmpCognitoUser(cognitoUser) {
    this.tmpCognitoUser = cognitoUser;
  }

  async doLogout() {
    this.uiState.decreasePendingRequest();
    await api.logout();
    await appSyncClient.resetStore();
    this.loggedOut();
  }

  async clearCache() {
    await appSyncClient.resetStore();
    window.location.reload();
  }

  async resetPassword(email) {
    this.setPasswordResetEmail(email);
    this.uiState.increasePendingRequest();
    const response = await api.resetPassword(email);
    this.uiState.decreasePendingRequest();
    return response;
  }

  loggedOut() {
    this.abilities.update([]);
    this.isLoggedIn = false;
    this.user = null;

    try {
      localStorage.setItem(
        'authStore',
        JSON.stringify({ isLoggedIn: false, entityId: this.entityId })
      );
    } catch (er) {}
  }

  async doSignup(password) {
    let user;
    if (this.tmpCognitoUser) {
      this.uiState.increasePendingRequest();
      user = await api.loginWithNewPassword(this.tmpCognitoUser, password);
      this.uiState.decreasePendingRequest();
      if (user && !user.error) {
        this.loginSucces(user);
      } else {
        this.loginFail();
      }
    }
    return user;
  }

  async loginAsTester() {
    this.uiState.increasePendingRequest();
    const user = await api.login(GLOBAL_TESTER_LOGIN, GLOBAL_TESTER_PASSWORD);
    this.uiState.decreasePendingRequest();
    if (user && !user.error) {
      const splittedPath = this.routerStore.location.pathname.split('/');
      const entityId =
        splittedPath.length > 3 &&
        this.routerStore.location.pathname.split('/')[3];
      const userModel = new User(user.data.getMe, entityId);
      const locale = userModel.getEntityLocale();
      this.uiState.setLocale(locale);
      this.setUser(userModel);
    }
  }

  setEntityId(id, route) {
    this.entityId = id;

    this.persistEntity({ entityId: id });
    this.routerStore.push(route ?? '/');
    window.location.reload(true);
  }

  setPasswordResetEmail(email) {
    this.passwordResetEmail = email;
  }

  setUser(user) {
    this.user = user;
  }

  setInitLoginStateChanged(value) {
    this.initLoginStateChanged = value;
  }

  get client() {
    return appSync.appSyncClient;
  }

  persistEntity(data) {
    let storage = localStorage.getItem('authStore');
    storage = storage ? JSON.parse(storage) : {};
    storage = { ...storage, ...data };
    try {
      localStorage.setItem('authStore', JSON.stringify(storage));
    } catch (er) {}
  }
}
