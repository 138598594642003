import React, { useEffect, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID } from 'services/aws/client-query';
import { EntityTypes } from 'constants.js';
import Group from 'models/Group';
import Person from 'models/Person';
import { getFilteredGroups, getFilteredUsers } from 'contexts/GroupsContext';
import { sort } from 'utils/sort';

function useGroups({ entityId }) {
  const groupData = useRef();
  const [group, setGroup] = useState(new Group({}));
  const [groups, setGroups] = useState([]);
  const [persons, setPersons] = useState([]);
  const [filter, setFilter] = useState({ search: '' });

  const [getGroups, { loading, error, data, refetch }] = useLazyQuery(
    S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID,
    {
      variables: { id: entityId }
    }
  );

  useEffect(() => {
    if (data?.getEntity) {
      const group = new Group(data.getEntity);
      groupData.current = group;
      if (group?.subEntities) {
        const groups = group.subEntities
          .filter(se => se.type !== EntityTypes.ORGANISATION)
          .map(g => new Group(g));
        setGroups(sort(groups, { keys: [{ key: 'name' }] }));
      }
      setGroup(group);

      if (group?.clients) {
        const clients = sort(
          group.clients.map(user => new Person(user)),
          { keys: [{ key: 'firstname' }, { key: 'lastName' }] }
        );
        setPersons(clients);
      }
    }
  }, [data]);

  useEffect(() => {
    if (group && groupData?.current?.id) {
      const filteredGroups = getFilteredGroups(
        groupData.current.subEntities.map(g => new Group(g)),
        filter.search ?? ''
      );
      setGroups(sort(filteredGroups, { keys: [{ key: 'name' }] }));

      const filteredPersons = getFilteredUsers(
        groupData.current.clients.map(g => new Person(g)),
        filter.search ?? ''
      );
      setPersons(
        sort(filteredPersons, {
          keys: [{ key: 'firstname' }, { key: 'lastName' }]
        })
      );
    }
  }, [filter, group]);

  const fetchGroups = async entityId => {
    await getGroups({
      variables: { id: entityId },
      fetchPolicy: 'network-only'
    });
  };

  return {
    group,
    groups,
    persons,
    filter,
    setFilter,
    loading,
    error,
    refetch,
    fetchGroups
  };
}

export default useGroups;
