import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { inject } from 'mobx-react';
import { Mutation, Query } from '@apollo/client/react/components';
import { getLocalDateFormat } from 'utils/date';
import CardBody from 'components/card/CardBody';
import Card from 'components/card/Card';
import CardFooter from 'components/card/CardFooter';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle,
  CardHeaderSubtitle,
  CardHeaderButtons
} from 'components/card/card-header';
import Input from 'components/input/Input';
import { Button } from 'components/button';
import Select, { Option } from 'components/input/Select';
import {
  MUTATION_UPDATE_TEST_DATA,
  QUERY_GET_SESSION_BY_ID
} from 'services/aws/session-query';

import messages from 'messages';
import Icon from 'components/icon/Icon';
import { QUERY_GET_CALIBRATIONS_BY_SCANNER_ID } from 'services/aws/scanner-query';
import { sortCalibrationsOnDateAndName } from 'utils/sort';
import Calibration from 'models/Calibration';
import MultiFileUploadCard from './MultiFileUploadCard';
import { isValueValidNumber } from 'utils/form';
import InputErrorMessage from 'components/input/InputErrorMessage';
import FieldInput from 'components/input/FieldInput';

class MTCCard extends Component {
  state = {
    edit: true,

    calibrationId: '',

    date: new Date().toISOString().substring(0, 10),
    dateError: false,

    shimSoleus: '',
    shimSoleusError: false,
    shimSoleusWaterPhase: '',
    shimSoleusWaterPhaseError: false,
    shimSoleusWaterContent: '',
    shimSoleusWaterContentError: false,

    shimGastro: '',
    shimGastroError: false,
    shimGastroWaterPhase: '',
    shimGastroWaterPhaseError: false,
    shimGastroWaterContent: '',
    shimGastroWaterContentError: false
  };

  constructor(props) {
    super(props);
    const newState = this.getNewStateObject(props);
    this.state = { ...this.state, ...newState };
  }

  getNewStateObject = props => {
    const { testData } = props;
    let newState = {};
    try {
      if (testData.data) {
        const dataObject = testData.data;
        newState = { ...dataObject };
        newState.edit = false;
      }
    } catch (er) {}
    return newState;
  };

  onCompletedGetCalibrations = data => {
    if (data && data.getMtsCalibrations) {
      const { calibrationId, edit } = this.state;
      if (!calibrationId && edit) {
        const calibrations = data.getMtsCalibrations.map(
          sc => new Calibration(sc)
        );
        const sortedCalibrations = sortCalibrationsOnDateAndName(calibrations);
        if (sortedCalibrations.length) {
          this.setState({
            calibrationId: sortedCalibrations[0].id
          });
        }
      }
    }
  };

  onReset = () => {
    const newState = this.getNewStateObject(this.props);
    newState.edit = false;
    this.setState(newState);
  };

  onSubmit = (e, mutation) => {
    const { testData } = this.props;
    const {
      calibrationId,
      date,
      shimSoleus,
      shimSoleusWaterPhase,
      shimSoleusWaterContent,
      shimGastro,
      shimGastroWaterPhase,
      shimGastroWaterContent
    } = this.state;
    e.preventDefault();
    mutation({
      variables: {
        id: testData.id,
        data: JSON.stringify({
          calibrationId,
          date,
          shimSoleus,
          shimSoleusWaterPhase,
          shimSoleusWaterContent,
          shimGastro,
          shimGastroWaterPhase,
          shimGastroWaterContent
        })
      }
    });
  };

  onChange = (e, type) => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  onBlur = (e, type) => {
    if (type === 'number') {
      if (e.target.value !== '') {
        const value = Number(e.target.value.replace(',', '.'));
        this.setState({
          [e.target.id]: value,
          [`${e.target.id}Error`]: isValueValidNumber(value) ? false : true
        });
      } else {
        this.setState({
          [e.target.id]: '',
          [`${e.target.id}Error`]: false
        });
      }
    } else {
      this.setState({
        [`${e.target.id}Error`]: e.target.value ? false : true
      });
    }
  };

  render() {
    const { testData, sporter, session, entityId, intl } = this.props;
    const {
      calibrationId,
      date,
      dateError,
      shimSoleus,
      shimSoleusError,
      shimSoleusWaterPhase,
      shimSoleusWaterPhaseError,
      shimSoleusWaterContent,
      shimSoleusWaterContentError,

      shimGastro,
      shimGastroError,
      shimGastroWaterPhase,
      shimGastroWaterPhaseError,
      shimGastroWaterContent,
      shimGastroWaterContentError,
      edit
    } = this.state;
    const isValid =
      (shimSoleus === '' || isValueValidNumber(shimSoleus)) &&
      (shimSoleusWaterPhase === '' ||
        isValueValidNumber(shimSoleusWaterPhase)) &&
      (shimSoleusWaterContent === '' ||
        isValueValidNumber(shimSoleusWaterContent)) &&
      (shimGastro === '' || isValueValidNumber(shimGastro)) &&
      (shimGastroWaterPhase === '' ||
        isValueValidNumber(shimGastroWaterPhase)) &&
      (shimGastroWaterContent === '' ||
        isValueValidNumber(shimGastroWaterContent)) &&
      date &&
      calibrationId;
    const hasScanner = session.meta && session.meta.scannerId;
    const scannerId = hasScanner ? session.meta.scannerId : '';
    return (
      <Fragment>
        <Card secondary>
          <Mutation
            mutation={MUTATION_UPDATE_TEST_DATA}
            refetchQueries={() => {
              return [
                {
                  query: QUERY_GET_SESSION_BY_ID,
                  variables: { testSessionId: session.id, entityId }
                }
              ];
            }}
            update={(cache, { data: { editTestData } }) => {
              if (editTestData && editTestData.id) {
                this.setState({ edit: false });
              }
            }}
          >
            {(editTestData, data) => {
              return (
                <form>
                  <CardHeader secondary>
                    <CardHeaderText>
                      <CardHeaderTitle>{session.name}</CardHeaderTitle>
                      <CardHeaderSubtitle>
                        {sporter.firstname} {sporter.lastname}
                      </CardHeaderSubtitle>
                    </CardHeaderText>
                    <CardHeaderButtons>
                      {edit ? (
                        <Button
                          tiny
                          type="submit"
                          disabled={!isValid || data.loading}
                          onClick={e => this.onSubmit(e, editTestData)}
                        >
                          <FormattedMessage {...messages.cardButtonSave} />
                        </Button>
                      ) : (
                        <Button
                          tiny
                          onClick={e => {
                            e.preventDefault();
                            this.setState({ edit: true });
                          }}
                        >
                          <Icon id="edit" />
                        </Button>
                      )}
                    </CardHeaderButtons>
                  </CardHeader>
                  <CardBody secondary>
                    {scannerId ? (
                      <Query
                        query={QUERY_GET_CALIBRATIONS_BY_SCANNER_ID}
                        variables={{ entityId, scannerId }}
                        fetchPolicy="cache-and-network"
                        onCompleted={this.onCompletedGetCalibrations}
                      >
                        {result => {
                          const { loading, error, data } = result;
                          if (loading) return <Fragment />;
                          if (error)
                            return (
                              <p>
                                <FormattedMessage
                                  {...messages.errorMessage}
                                  values={{
                                    message: error.message
                                  }}
                                />
                              </p>
                            );
                          const calibrations = data.getMtsCalibrations.map(
                            sc => new Calibration(sc)
                          );
                          const sortedCalibrations =
                            sortCalibrationsOnDateAndName(calibrations);
                          if (sortedCalibrations.length === 0) {
                            return (
                              <InputErrorMessage>
                                <FormattedMessage
                                  {...messages.cardLabelNoCalibrationsForScanner}
                                />
                              </InputErrorMessage>
                            );
                          }
                          return (
                            <Select
                              label={`${intl.formatMessage(
                                messages.cardLabelCalibration
                              )}*`}
                              name="calibration-id"
                              value={calibrationId}
                              readOnly={!edit}
                              onChange={option => {
                                this.setState({
                                  calibrationId: option.value
                                });
                              }}
                            >
                              {sortedCalibrations.map(calibration => (
                                <Option
                                  key={calibration.id}
                                  value={calibration.id}
                                  label={`${getLocalDateFormat(
                                    calibration.date
                                  )} - ${calibration.name}`}
                                />
                              ))}
                            </Select>
                          );
                        }}
                      </Query>
                    ) : (
                      <InputErrorMessage>
                        <FormattedMessage {...messages.cardLabelNoScanner} />
                      </InputErrorMessage>
                    )}
                    <FieldInput
                      id="date"
                      type="date"
                      value={date}
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                      readOnly={!edit}
                      disabled={!edit}
                      required
                      icon={dateError ? 'validation-error' : ' '}
                    >
                      <FormattedMessage {...messages.cardLabelDate} />*
                    </FieldInput>
                    <div className="c-input__three-cols-group">
                      <FieldInput
                        id="shimSoleus"
                        value={shimSoleus}
                        onChange={this.onChange}
                        onBlur={e => this.onBlur(e, 'number')}
                        readOnly={!edit}
                        pattern="\d+(\.\d*)?"
                        icon={shimSoleusError ? 'validation-error' : ' '}
                      >
                        <FormattedMessage {...messages.cardLabelShimSoleus} />
                      </FieldInput>
                      <FieldInput
                        id="shimGastro"
                        value={shimGastro}
                        onChange={this.onChange}
                        onBlur={e => this.onBlur(e, 'number')}
                        pattern="\d+(\.\d*)?"
                        readOnly={!edit}
                        icon={shimGastroError ? 'validation-error' : ' '}
                      >
                        <FormattedMessage {...messages.cardLabelShimGastro} />
                      </FieldInput>
                    </div>
                    <div className="c-input__three-cols-group">
                      <FieldInput
                        id="shimSoleusWaterPhase"
                        value={shimSoleusWaterPhase}
                        onChange={this.onChange}
                        onBlur={e => this.onBlur(e, 'number')}
                        readOnly={!edit}
                        pattern="\d+(\.\d*)?"
                        icon={
                          shimSoleusWaterPhaseError ? 'validation-error' : ' '
                        }
                      >
                        <FormattedMessage
                          {...messages.cardLabelShimSoleusWaterPhase}
                        />
                      </FieldInput>
                      <FieldInput
                        id="shimGastroWaterPhase"
                        value={shimGastroWaterPhase}
                        onChange={this.onChange}
                        onBlur={e => this.onBlur(e, 'number')}
                        pattern="\d+(\.\d*)?"
                        readOnly={!edit}
                        icon={
                          shimGastroWaterPhaseError ? 'validation-error' : ' '
                        }
                      >
                        <FormattedMessage
                          {...messages.cardLabelShimGastroWaterPhase}
                        />
                      </FieldInput>
                    </div>
                    <div className="c-input__three-cols-group">
                      <FieldInput
                        id="shimSoleusWaterContent"
                        value={shimSoleusWaterContent}
                        onChange={this.onChange}
                        onBlur={e => this.onBlur(e, 'number')}
                        readOnly={!edit}
                        pattern="\d+(\.\d*)?"
                        icon={
                          shimSoleusWaterContentError ? 'validation-error' : ' '
                        }
                      >
                        <FormattedMessage
                          {...messages.cardLabelShimSoleusWaterContent}
                        />
                      </FieldInput>
                      <FieldInput
                        id="shimGastroWaterContent"
                        value={shimGastroWaterContent}
                        onChange={this.onChange}
                        onBlur={e => this.onBlur(e, 'number')}
                        pattern="\d+(\.\d*)?"
                        readOnly={!edit}
                        icon={
                          shimGastroWaterContentError ? 'validation-error' : ' '
                        }
                      >
                        <FormattedMessage
                          {...messages.cardLabelShimGastroWaterContent}
                        />
                      </FieldInput>
                    </div>
                  </CardBody>
                  {edit && (
                    <CardFooter secondary>
                      {edit && testData.data && (
                        <Button
                          secondary
                          onClick={e => {
                            e.preventDefault();
                            this.onReset();
                          }}
                        >
                          <FormattedMessage {...messages.cardButtonCancel} />
                        </Button>
                      )}
                      {edit && (
                        <Button
                          type="submit"
                          primary
                          disabled={data.loading || !isValid}
                          onClick={e => this.onSubmit(e, editTestData)}
                        >
                          <FormattedMessage {...messages.cardButtonSave} />
                        </Button>
                      )}
                    </CardFooter>
                  )}
                </form>
              );
            }}
          </Mutation>
        </Card>
        <MultiFileUploadCard entityId={entityId} linkId={testData.id} />
      </Fragment>
    );
  }
}

export default injectIntl(inject('authStore')(MTCCard));
