import React, { useEffect, useState } from 'react';
import { QUERY_GET_PERSONENTITIES_OF_PERSON } from 'services/aws/user-query';
import { useQuery } from '@apollo/client';
import { sort } from 'utils/sort';
import PersonEntity from 'models/PersonEntity';

function useUserGroups({ entityId, personId }) {
  const [entities, setEntities] = useState([]);
  const { loading, error, data } = useQuery(
    QUERY_GET_PERSONENTITIES_OF_PERSON,
    {
      variables: { topLevelEntityId: entityId, personId },
      fetchPolicy: 'cache-and-network',
      skip: !entityId || !personId
    }
  );

  useEffect(() => {
    if (data?.getPersonEntitiesOfPerson) {
      setEntities(
        sort(
          data.getPersonEntitiesOfPerson
            .filter(e => e.roletype === 'client' && e.entityId !== entityId)
            .map(e => new PersonEntity({ ...e })),
          { keys: [{ key: 'title' }] }
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { loading, error, entities };
}

export default useUserGroups;
