import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { CardBody, CardFooter } from 'components/card';
import { Button, ButtonsGroup } from 'components/button';
import InjuryForm from 'containers/pages/rehab/injury/InjuryForm';
import InjuryDetailFields from 'containers/pages/rehab/injury/InjuryDetailFields';
import PersonInjury from 'models/PersonInjury';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import InjuryDetailMoreFields from 'containers/pages/rehab/injury/InjuryDetailMoreFields';

function AddInjuryModal({ entityId, onClose, sporterId }) {
  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal
      card
      largeBody
      bodyOverflowScroll
      isOpen={true}
      onClose={onCloseHandler}
    >
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage
            {...messages.rehabAddInjuryTitle}
            values={{
              injuryExists: false
            }}
          />
        </CardHeaderTitle>
      </CardHeader>
      <InjuryForm
        entityId={entityId}
        sporterId={sporterId}
        personInjury={new PersonInjury({})}
        onComplete={() => onCloseHandler()}
      >
        {({ isSubmitting }) => (
          <>
            <CardBody modalWithOverflow>
              <div className="o-layout o-layout--small">
                <InjuryDetailFields edit={true} />
                <InjuryDetailMoreFields edit={true} />
              </div>
            </CardBody>
            <CardFooter modal extraClassNames="c-card__footer--modal-larger">
              <ButtonsGroup>
                <Button secondary onClick={() => onCloseHandler()}>
                  <FormattedMessage {...messages.cancelButton} />
                </Button>
                <Button type="submit" primary disabled={isSubmitting}>
                  <FormattedMessage {...messages.saveButton} />
                </Button>
              </ButtonsGroup>
            </CardFooter>
          </>
        )}
      </InjuryForm>
    </Modal>
  );
}

export default AddInjuryModal;
