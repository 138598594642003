import React, { useContext, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { Card, CardBody } from 'components/card';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import AssignPersonsToSessionModal from 'containers/pages/sessions/AssignPersonsToSessionModal';
import { ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS } from 'routes/RouteList';
import { StoreContext } from 'index';
import MessageText from 'components/message/MessageText';
import Message from 'components/message/Message';

function NoSportersAddedCard({ entityId, session }) {
  const {
    routing: { push }
  } = useContext(StoreContext);
  const [modalData, setModalData] = useState(null);

  return (
    <Card empty secondary>
      <CardBody empty>
        <Message emptyState icon="sporters" iconFillColor="color-neutral-dark">
          <MessageText>
            <FormattedMessage {...messages.labelSessionNoSporters} />
          </MessageText>
          <br />
          <MessageText>
            <FormattedMessage {...messages.sublabelSessionNoSporters} />
          </MessageText>
          <Button
            extraClassNames={'u-margin-top-medium'}
            rounded
            primary
            onClick={() => setModalData(true)}
          >
            <Icon id="add-white" />
            <FormattedMessage {...messages.addSportersButton} />
          </Button>
        </Message>
        {/* On Click, trigger Modal as in athletes panel
                After the user added the athletes:
                  1. the user will be redirected to that panel/tab
                  2. the user will see the added athletes
              */}
      </CardBody>
      {modalData && (
        <AssignPersonsToSessionModal
          entityId={entityId}
          onClose={() => {
            setModalData(null);
          }}
          onDone={() => {
            setModalData(null);
            push(
              generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS, {
                entityId,
                sessionId: session.id
              })
            );
          }}
        />
      )}
    </Card>
  );
}

export default NoSportersAddedCard;
