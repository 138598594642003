import { useContext, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Card, CardBody } from 'components/card';
import Panel from 'components/panel/Panel';
import PanelHeader from 'components/panel/PanelHeader';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelTools from 'components/panel/PanelTools';
import { FormattedMessage } from 'react-intl';
import {
  ROUTE_EXERCISES_COLLECTIONS_ENTITY,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_MY_EXERCISES_EXERCISE
} from 'routes/RouteList';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import messages from 'messages';
import PanelBody from 'components/panel/PanelBody';
import { StoreContext } from 'index';
import { Tab, TabList, TabPanel, Tabs } from 'components/tabs';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import { usePreviewModalContext } from 'contexts/PreviewModalContext';
import Loader from 'components/loader/Loader';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import { PanelSubtitle } from 'components/panel';
import ExercisesListVirtualized from 'containers/partials/lists/ExercisesListVirtualized';
import useMyExercises from 'hooks/queries/useMyExercises';
import AddExerciseModal from 'containers/pages/exercises/exercises/AddExerciseModal';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import FieldInput from 'components/input/FieldInput';

const MyExercises = ({ entityId, exerciseId, active, collectionId }) => {
  const {
    authStore: { user },
    routing: { push }
  } = useContext(StoreContext);
  const ability = useAbility(AbilityContext);
  const { setModalData } = usePreviewModalContext();
  const [addModalData, setAddModalData] = useState(null);

  const { exercises, totalExercises, filter, setFilter, loading, error } =
    useMyExercises(entityId, user);

  const onSearch = e => {
    setFilter({ search: e.target.value });
  };

  if (loading)
    return (
      <Panel active={active}>
        <Loader />
      </Panel>
    );
  if (error) return <RetryPanel />;

  return (
    <Panel active={active}>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb
            to={generatePath(ROUTE_EXERCISES_COLLECTIONS_ENTITY, { entityId })}
          >
            <FormattedMessage {...messages.breadcrumbCollections} />
          </Breadcrumb>
          <Breadcrumb>
            <FormattedMessage {...messages.breadcrumbMyExercises} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.breadcrumbMyExercises} />
          </PanelTitle>
          <PanelSubtitle>
            <div className="u-margin-bottom-small">
              <FormattedMessage
                {...messages.exercisesLengthInIt}
                values={{ this: exercises.length }}
              />
            </div>
          </PanelSubtitle>
        </PanelTitleWrapper>
        <PanelTools extraClassNames="u-margin-top-small">
          <FieldInput rounded icon="search-grey" iconLeft onChange={onSearch} />
          {ability.can('create', 'Exercise') && (
            <Button rounded primary onClick={() => setAddModalData(true)}>
              <Icon id="add-white" />
              <FormattedMessage {...messages.exercisesAddExerciseButton} />
            </Button>
          )}
        </PanelTools>
      </PanelHeader>
      <PanelBody>
        <Tabs>
          <TabList>
            <Tab>
              <FormattedMessage {...messages.collectionsExercises} />
            </Tab>
          </TabList>
          <TabPanel>
            {exercises.length > 0 ? (
              <ExercisesListVirtualized
                exercises={exercises}
                activeId={exerciseId}
                edit={false}
                onPreview={exercise =>
                  setModalData({ type: 'exercise', data: exercise })
                }
                editable={true}
                onClick={id => {
                  if (id !== exerciseId) {
                    push(
                      generatePath(
                        ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_MY_EXERCISES_EXERCISE,
                        { entityId, collectionId, exerciseId: id }
                      )
                    );
                  }
                }}
              />
            ) : (
              <Card centered>
                <CardBody empty>
                  <Message emptyState={true} icon="browse">
                    {totalExercises ? (
                      <MessageText>
                        <FormattedMessage
                          {...messages.exercisesListEmptyResult}
                        />
                      </MessageText>
                    ) : (
                      <MessageText>
                        <FormattedMessage
                          {...messages.templateEmptyExercises}
                        />
                      </MessageText>
                    )}
                    <Button
                      primary
                      rounded
                      onClick={() => setAddModalData(true)}
                    >
                      <Icon id="add-white" />
                      <FormattedMessage
                        {...messages.exercisesAddExerciseButton}
                      />
                    </Button>
                  </Message>
                </CardBody>
              </Card>
            )}
          </TabPanel>
        </Tabs>
      </PanelBody>
      {addModalData && (
        <AddExerciseModal
          entityId={entityId}
          onClose={() => setAddModalData(false)}
        />
      )}
    </Panel>
  );
};

export default observer(MyExercises);
