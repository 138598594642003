import React from 'react';
import classNames from 'classnames';
import Icon from '../icon/Icon';
import './Breadcrumbs.scss';

class Breadcrumbs extends React.Component {
  renderBreadCrumbs = () => {
    const breadCrumbs = React.Children.map(
      this.props.children,
      (breadCrumb, index) =>
        React.cloneElement(breadCrumb, {
          ...breadCrumb.props
        })
    );

    return breadCrumbs;
  };

  render() {
    const { benchmarks, growthPrediction, extraClassNames } = this.props;
    return (
      <ul
        className={classNames('c-breadcrumbs', extraClassNames, {
          'c-breadcrumbs--benchmarks': benchmarks || growthPrediction
        })}
      >
        {!benchmarks && !growthPrediction && (
          <li className="c-breadcrumbs__item c-breadcrumbs__icon">
            <Icon id="funnel" fillColor="color-primary" />
          </li>
        )}
        {this.renderBreadCrumbs()}
      </ul>
    );
  }
}

export default Breadcrumbs;
