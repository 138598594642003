import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { CardBody, CardFooter } from 'components/card';
import { Button, ButtonsGroup } from 'components/button';
import IllnessForm from 'containers/pages/rehab/illness/IllnessForm';
import IllnessDetailFields from 'containers/pages/rehab/illness/IllnessDetailFields';
import PersonIllness from 'models/PersonIllness';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';

function AddIllnessModal({ entityId, onClose, sporterId }) {
  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal card autoHeight isOpen={true} onClose={onCloseHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage
            {...messages.rehabAddIllnessTitle}
            values={{
              illnessExists: false
            }}
          />
        </CardHeaderTitle>
      </CardHeader>
      <IllnessForm
        entityId={entityId}
        sporterId={sporterId}
        personIllness={new PersonIllness({})}
      >
        {({ isSubmitting }) => (
          <>
            <CardBody modalWithOverflow>
              <IllnessDetailFields edit={true} />
            </CardBody>
            <CardFooter modal extraClassNames="c-card__footer--modal-larger">
              <ButtonsGroup>
                <Button secondary onClick={() => onCloseHandler()}>
                  <FormattedMessage {...messages.cancelButton} />
                </Button>
                <Button type="submit" primary disabled={isSubmitting}>
                  <FormattedMessage {...messages.saveButton} />
                </Button>
              </ButtonsGroup>
            </CardFooter>
          </>
        )}
      </IllnessForm>
    </Modal>
  );
}

export default AddIllnessModal;
