import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { PanelBody } from 'components/panel';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import ButtonsGroup from 'components/button/ButtonsGroup';
import ResultsTableWaiting from 'containers/pages/results/table/ResultsTableWaiting';
import { useGroupsContext } from 'contexts/GroupsContext';
import { useResultsPanelContext } from 'contexts/ResultsPanelContext';
import ResultsTable from 'containers/pages/results/table/ResultsTable';

const ResultsPanel = () => {
  const { loading } = useGroupsContext();

  const { loading: resultsLoading } = useResultsPanelContext();

  // pagination state
  const [start, setStart] = useState(true);
  const [end, setEnd] = useState(true);
  const [isScroll, setIsScroll] = useState(false);

  const panelBodyRef = useRef();

  useEffect(() => {
    const element = panelBodyRef.current;
    element.scrollLeft = 0;
    element.addEventListener('scroll', onScrollHandler);
    window.addEventListener('resize', onResizeHandler);
    onScroll(element);
    return () => {
      const element = panelBodyRef.current;
      if (element) {
        element.removeEventListener('scroll', onScrollHandler);
        window.removeEventListener('resize', onResizeHandler);
      }
    };
  }, []);

  useLayoutEffect(() => {
    if (panelBodyRef.current) {
      const panel = panelBodyRef.current;

      const tabTopPositionInPx = `${panel
        .getBoundingClientRect()
        .top.toFixed()}px`;

      panel.style.height = `calc(100vh - ${tabTopPositionInPx})`;
    }
  });

  const onResizeHandler = () => {
    const element = panelBodyRef.current;
    onScroll(element);
  };
  const onScrollHandler = e => {
    const element = e.currentTarget;
    onScroll(element);
  };

  const onScroll = element => {
    if (!element) return;
    if (element.scrollLeft === 0 && !start) {
      setStart(true);
    } else if (element.scrollLeft !== 0 && start) {
      setStart(false);
    }

    if (element.scrollWidth !== element.offsetWidth && !isScroll) {
      setIsScroll(true);
    } else if (element.scrollWidth === element.offsetWidth && isScroll) {
      setIsScroll(false);
    }

    if (
      element.offsetWidth + element.scrollLeft === element.scrollWidth &&
      !end
    ) {
      setEnd(true);
    } else if (
      element.offsetWidth + element.scrollLeft !== element.scrollWidth &&
      end
    ) {
      setEnd(false);
    }
  };

  const moveLeftHandler = () => {
    const element = panelBodyRef.current;
    const colWidth = element
      .querySelector('th:nth-child(2)')
      .getBoundingClientRect().width;

    window.requestAnimationFrame(() => {
      element.scrollLeft = element.scrollLeft - colWidth;
    });
  };

  const moveRightHandler = () => {
    const element = panelBodyRef.current;
    const colWidth = element
      .querySelector('th:nth-child(2)')
      .getBoundingClientRect().width;

    window.requestAnimationFrame(() => {
      element.scrollLeft = element.scrollLeft + colWidth;
    });
  };

  return (
    <PanelBody
      ref={panelBodyRef}
      scrollHorizontal
      tabIndex="-1" // De scroller veroorzakt een focusable element 🤔
    >
      {loading || resultsLoading ? <ResultsTableWaiting /> : <ResultsTable />}
      {isScroll && (
        <ButtonsGroup>
          <Button
            circularLarge
            primaryDark
            onClick={moveLeftHandler}
            disabled={start}
          >
            <Icon
              id="play"
              fillColor="color-neutral-x-light"
              hugeIcon
              mirrored
            />
          </Button>
          <Button
            circularLarge
            primaryDark
            onClick={moveRightHandler}
            disabled={end}
          >
            <Icon id="play" fillColor="color-neutral-x-light" hugeIcon />
          </Button>
        </ButtonsGroup>
      )}
    </PanelBody>
  );
};

export default ResultsPanel;
