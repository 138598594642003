//BlazePose Keypoints numbers
export const skelet = {
  nose: 0,
  left_eye_inner: 1,
  left_eye: 2,
  left_eye_outer: 3,
  right_eye_inner: 4,
  right_eye: 5,
  right_eye_outer: 6,
  left_ear: 7,
  right_ear: 8,
  mouth_left: 9,
  mouth_right: 10,
  left_shoulder: 11,
  right_shoulder: 12,
  left_elbow: 13,
  right_elbow: 14,
  left_wrist: 15,
  right_wrist: 16,
  left_pinky: 17,
  right_pinky: 18,
  left_index: 19,
  right_index: 20,
  left_thumb: 21,
  right_thumb: 22,
  left_hip: 23,
  right_hip: 24,
  left_knee: 25,
  right_knee: 26,
  left_ankle: 27,
  right_ankle: 28,
  left_heel: 29,
  right_heel: 30,
  left_foot_index: 31,
  right_foot_index: 32
};

// const dataKey = 'keypoints';
const dataKey = 'landmark';

/** TODO
 * (dynamicBalance: on elbows as well)
 * Lateral Step Down: check without width-bug
 * ValgusClassifier: changing
 * Foot Symmetry Front and Deep Squat: check with Python version
 */

var horizontal = { point1: { x: 0, y: 0 }, point2: { x: 1, y: 0 } };

export function setHorizontal(point1, point2) {
  const crookedAngle = calcAngle(
    point1.x,
    point1.y,
    point2.x,
    point2.y,
    horizontal.point1.x,
    horizontal.point1.y,
    horizontal.point2.x,
    horizontal.point2.y
  );
  if (crookedAngle > 10) {
    console.log('Video is filmed too crooked, namely angle of:', crookedAngle);
  } else {
    console.log('Video is filmed ok, namely angle of:', crookedAngle);
  }
}

// returns if the left leg is free by checking which foot moves the most vertically
export function getLeftLegFreeLatStepDown(poses) {
  const rightFootMovement =
    getExtremum(poses, skelet.right_foot_index, true, false)[dataKey][
      skelet.right_foot_index
    ]['y'] -
    getExtremum(poses, skelet.right_foot_index, true, true)[dataKey][
      skelet.right_foot_index
    ]['y'];
  const leftFootMovement =
    getExtremum(poses, skelet.left_foot_index, true, false)[dataKey][
      skelet.left_foot_index
    ]['y'] -
    getExtremum(poses, skelet.left_foot_index, true, true)[dataKey][
      skelet.left_foot_index
    ]['y'];
  const leftLegFree = leftFootMovement > rightFootMovement ? true : false;
  return leftLegFree;
}

// returns if the left leg is free by checking the distance between the ankle of one leg and the knee of the other leg (to see which leg is 'tucked in')
export function getLeftLegFreeUnipodal(poses) {
  const highestJump = getExtremum(poses, skelet.left_hip, true, true);
  var leftLegFree;
  const [xRAnkle, yRAnkle] = getKeypointCoordinates(
    highestJump,
    skelet.right_ankle
  );
  const [xLAnkle, yLAnkle] = getKeypointCoordinates(
    highestJump,
    skelet.left_ankle
  );
  const [xRKnee, yRKnee] = getKeypointCoordinates(
    highestJump,
    skelet.right_knee
  );
  const [xLKnee, yLKnee] = getKeypointCoordinates(
    highestJump,
    skelet.left_knee
  );
  leftLegFree =
    Math.abs(yRAnkle - yLKnee) > Math.abs(yLAnkle - yRKnee) ? true : false;
  return leftLegFree;
}

// returns a score by checking the horizontal shoulder movement and the free foot movement, relative to the shoulder-width
export function dynamicBalance(poses, leftFree) {
  var score;
  var details = '';
  const shoulderWidth =
    poses[poses.length - 1][dataKey][skelet.left_shoulder]['x'] -
    poses[poses.length - 1][dataKey][skelet.right_shoulder]['x'];
  const shoulderMostRight = getExtremum(
    poses,
    skelet.right_shoulder,
    false,
    true
  )[dataKey][skelet.right_shoulder]['x'];
  const shoulderMostLeft = getExtremum(
    poses,
    skelet.right_shoulder,
    false,
    false
  )[dataKey][skelet.right_shoulder]['x'];
  const shoulderMovemement = shoulderMostLeft - shoulderMostRight;
  const relativeShoulderMovement = shoulderMovemement / shoulderWidth;
  const foot = leftFree ? skelet.left_foot_index : skelet.right_foot_index;
  const footMostRight = getExtremum(poses, foot, false, true)[dataKey][foot][
    'x'
  ];
  const footMostLeft = getExtremum(poses, foot, false, false)[dataKey][foot][
    'x'
  ];
  const footMovement = footMostLeft - footMostRight;
  const relativeFootMovement = footMovement / shoulderWidth;
  if (relativeShoulderMovement < 0.1 && relativeFootMovement < 0.1) {
    score = 0;
    details = 'perfect balance';
  } else if (relativeShoulderMovement < 0.3 && relativeFootMovement < 0.3) {
    score = 1;
    details = 'small movements with trunk to control balance';
  } else if (relativeShoulderMovement < 0.5 && relativeFootMovement < 0.5) {
    score = 2;
    details = 'moderate movements with trunk';
  } else {
    score = 3;
    details = 'severe movement with trunk';
  }
  return [score, details];
}

/**
 * Get the angle between the hips (roundend number).
 * @param {Object} poses contains {id: , keypoints: [], frame: }
 */
export function lumbarPelvicControl(poses) {
  const lowestPose = getExtremum(poses, skelet.nose, true, false);
  const localExtrema = getLocalExtrema(poses, skelet.nose, true, false);
  var angles = [];
  const faceLength =
    getKeypointCoordinates(poses[0], skelet.mouth_right)[1] -
    getKeypointCoordinates(poses[0], skelet.right_eye)[1];
  for (let i = 0; i < localExtrema.length; i++) {
    const pose = localExtrema[i];
    const [xLH, yLH] = getKeypointCoordinates(pose, skelet.left_hip);
    const [xRH, yRH] = getKeypointCoordinates(pose, skelet.right_hip);
    const inRange =
      Math.abs(
        getKeypointCoordinates(pose, skelet.nose)[1] -
          getKeypointCoordinates(lowestPose, skelet.nose)[1]
      ) <= faceLength;
    if (inRange) {
      //downloadImage(pose);
      angles.push(
        Math.round(
          calcAngle(
            xLH,
            yLH,
            xRH,
            yRH,
            horizontal.point2.x,
            horizontal.point2.y,
            horizontal.point1.x,
            horizontal.point1.y
          )
        )
      );
    }
  }
  const angle = Math.max(...angles);
  var score;
  var details = '';
  if (angle === 0) {
    details = 'both asis level';
    score = 0;
  } else if (angle < 10) {
    details = `asis not level (<10° drop) ${angle}`;
    score = 1;
  } else if (angle < 20) {
    details = `moderate (>10° drop) ${angle}`;
    score = 2;
  } else {
    details = `>20° drop or obvious weight transfer ${angle}`;
    score = 3;
  }
  return [score, details];
}

/**
 * Function to determine which part of the foot landed first: the heel or the toes
 * @param {*} poses
 * @returns
 */
export function footSymmetrySide(poses, leftSide) {
  var score;
  var details = '';
  /* altijd 0
  const foot = leftSide ? skelet.left_foot_index : skelet.right_foot_index;
  const heel = leftSide ? skelet.left_heel : skelet.right_heel;
  var avgAngle = 0;
  for (let i = 0; i < poses.length; i++) {
    const [xFoot, yFoot] = getKeypointCoordinates(poses[i], foot);
    const [xHeel, yHeel] = getKeypointCoordinates(poses[i], heel);
    var angle = calcAngle(
      xHeel,
      yHeel,
      xFoot,
      yFoot,
      horizontal.point1.x,
      horizontal.point1.y,
      horizontal.point2.x,
      horizontal.point2.y,
      false
    );
    console.log('footSymmetrySide: angle', angle);
    avgAngle += angle;
  }
  avgAngle = avgAngle / poses.length;
  avgAngle = leftSide ? 180 - avgAngle : avgAngle;
  console.log('footSymmetrySide: avgAngle', avgAngle);
  const marginToFlat = 20;
  if (avgAngle < 90 && avgAngle > marginToFlat) {
    details = 'toe to heel';
    score = 0;
  }
  */
  details = 'toe to heel';
  score = 0;
  return [score, details];
}

/**
 * Function to get the flexion between the upper and lower leg (knee flexion)
 * @param {*} landPoses starting at the landing, till the end
 * @returns
 */
export function kneeFlexionDisplacement(landPoses, leftSide) {
  var details = '';
  var score;
  const firstLanding = landPoses[0];
  let [xHip, yHip] = leftSide
    ? getKeypointCoordinates(firstLanding, skelet.left_hip)
    : getKeypointCoordinates(firstLanding, skelet.right_hip);
  let [xKnee, yKnee] = leftSide
    ? getKeypointCoordinates(firstLanding, skelet.left_knee)
    : getKeypointCoordinates(firstLanding, skelet.right_knee);
  let [xAnkle, yAnkle] = leftSide
    ? getKeypointCoordinates(firstLanding, skelet.left_ankle)
    : getKeypointCoordinates(firstLanding, skelet.right_ankle);
  var landingAngle = Math.round(
    calcAngle(xHip, yHip, xKnee, yKnee, xAnkle, yAnkle, xKnee, yKnee)
  );
  console.log('kneeFlexionDispl: landingAngle', landingAngle);
  let deepestPose;
  let deepestAngle;
  for (let i = 1; i < landPoses.length; i++) {
    const pose = landPoses[i];
    const [xHip, yHip] = leftSide
      ? getKeypointCoordinates(pose, skelet.left_hip)
      : getKeypointCoordinates(pose, skelet.right_hip);
    const [xKnee, yKnee] = leftSide
      ? getKeypointCoordinates(pose, skelet.left_knee)
      : getKeypointCoordinates(pose, skelet.right_knee);
    const [xAnkle, yAnkle] = leftSide
      ? getKeypointCoordinates(pose, skelet.left_ankle)
      : getKeypointCoordinates(pose, skelet.right_ankle);
    const angle = Math.round(
      calcAngle(xHip, yHip, xKnee, yKnee, xKnee, yKnee, xAnkle, yAnkle, false)
    );
    if (angle > deepestAngle || deepestAngle === undefined) {
      deepestAngle = angle;
      deepestPose = pose;
    }
  }
  console.log(
    `kneeFlexionDispl: deepestAngle ${deepestPose.currentTime}`,
    deepestAngle
  );
  //downloadImage(deepestPose);

  const angle = Math.abs(deepestAngle - landingAngle);
  if (angle < 20) {
    details = `small flexion (<20°) ${angle}`;
    score = 2;
  } else if (angle < 30) {
    details = `average flexion (20-30°)  ${angle}`;
    score = 1;
  } else {
    details = `large flexion (>30°) ${angle}`;
    score = 0;
  }
  return [score, details, deepestPose];
}

/**
 * Function to get the angle between the back and a vertical line (hip flexion)
 * @param {*} pose
 * @returns
 */
export function hipFlexionDisplacement(pose, leftSide) {
  var details = '';
  var score;
  const [xHip, yHip] = leftSide
    ? getKeypointCoordinates(pose, skelet.left_hip)
    : getKeypointCoordinates(pose, skelet.right_hip);
  const [xShoulder, yShoulder] = leftSide
    ? getKeypointCoordinates(pose, skelet.left_shoulder)
    : getKeypointCoordinates(pose, skelet.right_shoulder);
  const [xAnkle, yAnkle] = leftSide
    ? getKeypointCoordinates(pose, skelet.left_ankle)
    : getKeypointCoordinates(pose, skelet.right_ankle);
  const angle = Math.round(
    calcAngle(xHip, yHip, xShoulder, yShoulder, xHip, yHip, xHip, 0)
  );
  if (angle < 20) {
    details = `small flexion (<20°) ${angle}`;
    score = 2;
  } else if (angle < 30) {
    details = `average flexion (20-30°)  ${angle}`;
    score = 1;
  } else {
    details = `large flexion (>30°) ${angle}`;
    score = 0;
  }
  return [score, details];
}

/**
 * Function to see if the knee is further then the foot.
 * @param {Object} pose contains {id: , keypoints: [], frame: }
 * @returns weather the knee is in front of the foot or not.
 * @todo the keypoint of the knee is inside the leg, the actual outermost kneepoint
 * is futher away?
 */
export function kneeFurtherThanFoot(pose, leftSide) {
  var score;
  var details = '';
  const [xKnee, yKnee] = leftSide
    ? getKeypointCoordinates(pose, skelet.left_knee)
    : getKeypointCoordinates(pose, skelet.right_knee);
  const [xFoot, yFoot] = leftSide
    ? getKeypointCoordinates(pose, skelet.left_foot_index)
    : getKeypointCoordinates(pose, skelet.right_foot_index);
  var angle = calcAngle(
    xKnee,
    yKnee,
    xFoot,
    yFoot,
    horizontal.point1.x,
    horizontal.point1.y,
    horizontal.point2.x,
    horizontal.point2.y,
    false
  );
  if (leftSide) {
    angle = Math.abs(180 - angle);
  }
  const margin = 75;
  console.log('KneeFurtherThanFoot, angle:', angle);
  if (angle <= margin) {
    details = `knee before foot ${angle}`;
    score = 0;
  } else {
    score = 1;
    details = `knee in front of foot ${angle}`;
  }
  return [score, details, angle];
}

/**
 * Function to calculate the difference in sholder with and the distance between the feet
 * @param {*} pose
 * @returns
 */
export function stanceWidth(pose) {
  var score;
  var details = '';
  const xLS = getKeypointCoordinates(pose, skelet.left_shoulder)[0];
  const xRS = getKeypointCoordinates(pose, skelet.right_shoulder)[0];
  const xLF = getKeypointCoordinates(pose, skelet.left_foot_index)[0];
  const xRF = getKeypointCoordinates(pose, skelet.right_foot_index)[0];
  const shoulderWidth = Math.abs(xLS - xRS);
  const feetWidth = Math.abs(xLF - xRF);
  console.log(`shoulderwidth/feetwidth: ${shoulderWidth / feetWidth}`);
  const marginNarrow = 1.15; // < 1.26
  const marginWide = 0.95; // < 0.97
  if (shoulderWidth > feetWidth && shoulderWidth / feetWidth > marginNarrow) {
    score = 1;
    details = 'narrow';
  } else if (
    shoulderWidth < feetWidth &&
    shoulderWidth / feetWidth < marginWide
  ) {
    score = 1;
    details = 'wide';
  } else {
    score = 0;
    details = 'normal';
  }
  return [score, details];
}

function footRotationPose(pose, leftFoot) {
  var [xHeel, yHeel] = [undefined, undefined];
  var [xFoot, yFoot] = [undefined, undefined];
  var [xKnee, yKnee] = [undefined, undefined];
  var internally;
  var angle;
  if (leftFoot) {
    [xHeel, yHeel] = getKeypointCoordinates(pose, skelet.left_heel);
    [xFoot, yFoot] = getKeypointCoordinates(pose, skelet.left_foot_index);
    [xKnee, yKnee] = getKeypointCoordinates(pose, skelet.left_knee);
    internally = xHeel > xFoot ? true : false;
  } else {
    [xHeel, yHeel] = getKeypointCoordinates(pose, skelet.right_heel);
    [xFoot, yFoot] = getKeypointCoordinates(pose, skelet.right_foot_index);
    [xKnee, yKnee] = getKeypointCoordinates(pose, skelet.right_knee);
    internally = xHeel < xFoot ? true : false;
  }
  angle = Math.round(
    calcAngle(
      xHeel,
      yHeel,
      xFoot,
      yFoot,
      horizontal.point1.x,
      horizontal.point1.y,
      horizontal.point2.x,
      horizontal.point2.y
    )
  );
  return [internally, angle];
}

/**
 * Function to calculate if the foot is straight, turned to the inside or outside
 * @param {*} pose
 * @returns
 */
export function footRotation(pose, leftFoot) {
  var score;
  var details = '';
  const [internally, angle] = footRotationPose(pose, leftFoot);
  if (internally && angle < 80) {
    score = 1;
    details = `internally rotated ${angle}`;
  } else if (!internally && angle < 70) {
    score = 1;
    details = `externally rotated ${angle}`;
  } else {
    score = 0;
    details = `straight ${angle}`;
  }
  return [score, details];
}

/**
 * Function to calculate if the feet are straight, turned to the inside or outside
 * @param {*} pose
 * @returns
 */
export function feetRotation(pose) {
  var score;
  var details = '';
  const [internallyLeft, angleLeft] = footRotationPose(pose, true);
  const [internallyRight, angleRight] = footRotationPose(pose, false);
  if (internallyLeft && internallyRight && angleLeft < 80 && angleRight < 70) {
    score = 1;
    details = `internally rotated (R,L) (${angleRight}, ${angleLeft})`;
  } else if (
    !internallyLeft &&
    !internallyRight &&
    angleLeft < 70 &&
    angleRight < 70
  ) {
    score = 1;
    details = `externally rotated (R,L) (${angleRight}, ${angleLeft})`;
  } else if (
    (internallyLeft && !internallyRight) ||
    (!internallyLeft && internallyRight)
  ) {
    console.log('jumped to a certain side');
  } else {
    score = 0;
    details = `straight (${angleRight}, ${angleLeft})`;
  }
  return [score, details];
}

/**
 * Function to calculate the valgus of the knees
 * @param {*} poses
 * @returns
 */
export function kneeValgus2(poses) {
  const scoreLeft = ValgusClassifier(poses, true);
  const scoreRight = ValgusClassifier(poses, false);
  return [scoreLeft, scoreRight];
}

/**
 * Function to get the valgus score of the knee
 * 0 if the knee is on the line of the hip and toe, no? Go to 1
 * 1 if the knee is oscilating around that line, no? Go to 2
 * 2 if the knee is right above the big toe, no? Go to 3
 * 3 if the knee is next to the big toe, more to the side of the other foot, no? go to 404
 * 404 if unable to find the scores
 * @todo parameters to be experimentally defined
 * @todo give the function a frame ID to specify where the oscilation needs to be calculated
 * @param {*} poses
 * @param {*} leftLeg
 * @param {Number} test, to diversify between tests, default = 0 = most tests
 * @returns {[number, *]}
 */
export function ValgusClassifier(poses, leftLeg, test = 0) {
  const hip = leftLeg ? skelet.left_hip : skelet.right_hip;
  const knee = leftLeg ? skelet.left_knee : skelet.right_knee;
  let foot = leftLeg ? skelet.left_foot_index : skelet.right_foot_index;

  var avgAngle = 0; //average angle between the segment foot - knee makes with foot - hip
  for (let i = 0; i < poses.length; i++) {
    const frame = poses[i];
    const [xH, yH] = getKeypointCoordinates(frame, hip);
    const [xK, yK] = getKeypointCoordinates(frame, knee);
    const [xF, yF] = getKeypointCoordinates(frame, foot);
    avgAngle += pointOnLineSegment([xH, yH], [xK, yK], [xF, yF]);
  }
  avgAngle = avgAngle / poses.length;
  var score;
  var details = '';
  var ratio = 1;
  details = `valgus average angle ${avgAngle}`;
  if (avgAngle < 0.5) {
    score = 0;
  } else {
    let frame;
    if (test === 1) {
      frame = getExtremum(poses, foot, true, false);
    } else {
      frame = getFirstJumpLandingFront(poses, leftLeg);
    }
    const [xK] = getKeypointCoordinates(frame, knee);
    const [xF] = getKeypointCoordinates(frame, foot);
    ratio = xF / xK;
    if (!leftLeg) {
      ratio = 1 / ratio;
    }
    //score for angle and ratio calculated seperatly
    let AngleScore = 1; //until 2.9
    if (avgAngle >= 2.9 && avgAngle < 4) {
      AngleScore = 2;
    } else if (avgAngle >= 4) {
      AngleScore = 3;
    }
    let RatioScore = 1; //until 2.9
    if (ratio >= 0.95 && avgAngle < 0.98) {
      RatioScore = 2;
    } else if (ratio >= 0.98) {
      RatioScore = 3;
    }
    score = (1 / 2) * (2 * 0.55 * AngleScore + 2 * 0.45 * RatioScore);
  }
  return [avgAngle, ratio];
}

//function to determine if pnt is on the line segment of sgmPnt1 and sgmPnt2
export function pointOnLineSegment(sgmPnt1, sgmPnt2, pnt) {
  return calcAngle(
    sgmPnt1[0],
    sgmPnt1[1],
    sgmPnt2[0],
    sgmPnt2[1],
    sgmPnt1[0],
    sgmPnt1[1],
    pnt[0],
    pnt[1]
  );
}

export function footSymmetryFront(leftFootLanding, rightFootLanding) {
  var score;
  var details = '';
  const timeMargin = 0.3;
  if (
    rightFootLanding['currentTime'] >
    leftFootLanding['currentTime'] + timeMargin
  ) {
    score = 1;
    details = 'not symmetric (left foot lands before right foot)';
  } else if (
    rightFootLanding['currentTime'] + timeMargin <
    leftFootLanding['currentTime']
  ) {
    score = 1;
    details = 'not symmetric (right foot lands before left foot)';
  } else {
    score = 0;
    details = 'symmetric';
  }
  console.log(
    `right: ${rightFootLanding['currentTime']}, left: ${leftFootLanding['currentTime']}`
  );
  return [score, details];
}

//function to determine if the middle of the shoulder width is above the middle of both feet
export function lateralTrunkFlexion(pose) {
  var score;
  var details = '';
  const [xRHip, yRHip] = getKeypointCoordinates(pose, skelet.right_hip);
  const [xLHip, yLHip] = getKeypointCoordinates(pose, skelet.left_hip);
  const [xRS, yRS] = getKeypointCoordinates(pose, skelet.right_shoulder);
  const [xLS, yLS] = getKeypointCoordinates(pose, skelet.left_shoulder);
  const xMidFoot = (xRHip + xLHip) / 2;
  const xMidShoulder = (xRS + xLS) / 2;
  const angle = Math.round(
    calcAngle(xMidFoot, yLHip, xMidFoot, 0, xMidFoot, yLHip, xMidShoulder, yLS)
  );
  const margin = 5;
  if (angle < margin) {
    details += `no flexion ${angle}`;
    score = 0;
  } else {
    details += `flexion (leaning to the side) ${angle}`;
    score = 1;
  }
  return [score, details];
}

export function getLeftSideVisible(pose) {
  const [xLFoot, yLFoot] = getKeypointCoordinates(pose, skelet.left_foot_index);
  const [xLHeel, yLHeel] = getKeypointCoordinates(pose, skelet.left_heel);
  const [xRFoot, yRFoot] = getKeypointCoordinates(
    pose,
    skelet.right_foot_index
  );
  const [xRHeel, yRHeel] = getKeypointCoordinates(pose, skelet.right_heel);
  // check which side is filmed to use the keypoints that are best visible in frame
  const leftSide = xLFoot < xLHeel || xRFoot < xRHeel ? true : false;
  return leftSide;
}

export function deepSquatEvaluation(poses, leftSide, elevatedHeels) {
  let score;
  let details = '';
  const lowestHip = getExtremum(
    poses,
    leftSide ? skelet.left_hip : skelet.right_hip,
    true,
    false
  );
  //downloadImage(lowestHip);
  const [xAnkle, yAnkle] = getKeypointCoordinates(
    lowestHip,
    leftSide ? skelet.left_ankle : skelet.right_ankle
  );
  const [xHip, yHip] = getKeypointCoordinates(
    lowestHip,
    leftSide ? skelet.left_hip : skelet.right_hip
  );
  const [xKnee, yKnee] = getKeypointCoordinates(
    lowestHip,
    leftSide ? skelet.left_knee : skelet.right_knee
  );
  const [xShoulder, yShoulder] = getKeypointCoordinates(
    lowestHip,
    leftSide ? skelet.left_shoulder : skelet.right_shoulder
  );
  const torsoToTibia = calcAngle(
    xShoulder,
    yShoulder,
    xHip,
    yHip,
    xKnee,
    yKnee,
    xAnkle,
    yAnkle
  );
  const [, , kneesOverFeetAngle] = kneeFurtherThanFoot(lowestHip, leftSide);
  console.log('torsoToTibia < 20?', torsoToTibia);
  console.log('yLKnee < yLHip', yKnee, yHip);
  console.log('kneesOverFeet > 75?', kneesOverFeetAngle);
  const passed =
    torsoToTibia < 20 &&
    yKnee < yHip &&
    kneesOverFeetAngle > 75 &&
    kneesOverFeetAngle < 105;
  // !! y-as pointing down
  if (passed && !elevatedHeels) {
    score = 3;
    details =
      'upper torso is parallel with the tibia or more vertical, femur is below horizontal and knees remain aligned over feet';
  } else if (passed && elevatedHeels) {
    score = 2;
    details = 'score 3 criteria obtained with elevated heels (5cm elevation)';
  } else if (elevatedHeels && !passed) {
    score = 1;
    details = 'score 3 criteria not obtained with elevated heels';
  } else {
    score = 0;
    details = 'Didnt pass, try elevated heels';
  }
  return [score, details];
}

export function TuckJumpAverageThighsEqual(poses) {
  let extremePoses = getLocalExtrema(poses, skelet.left_knee, true, true);
  let avgRatio = 0;
  for (let i = 0; i < extremePoses.length; i++) {
    let [xLK, yLK] = getKeypointCoordinates(extremePoses[i], skelet.left_knee);
    let [xLH, yLH] = getKeypointCoordinates(extremePoses[i], skelet.left_hip);
    let [xRK, yRK] = getKeypointCoordinates(extremePoses[i], skelet.right_knee);
    let [xRH, yRH] = getKeypointCoordinates(extremePoses[i], skelet.right_hip);
    let ratio = euclDist(xLK, xLH, yLK, yLH) / euclDist(xRK, xRH, yRK, yRH);
    avgRatio += ratio;
  }
  avgRatio = avgRatio / extremePoses.length;
  let index = Math.abs(avgRatio - 1) * 1000;
  console.log('TuckJumpAverageThighsEqual', avgRatio);
  if (index < 16.5) {
    return 0;
  } else if (index >= 16.5 && index < 28.5) {
    return 1;
  } else if (index >= 28.5 && index < 237) return 2;
  else {
    return 3;
  }
}

/**
 * Function that returns the index of all landingframes collected in the array of poses
 * @param {*} poses
 * @returns {Array} an array of indeces
 */
export function getLandingFramesIdx(poses) {
  let lHeelYSeries = [];
  let rHeelYSeries = [];
  for (let i = 0; i < poses.length; i++) {
    lHeelYSeries[i] = getKeypointCoordinates(poses[i], skelet.left_heel)[1];
    rHeelYSeries[i] = getKeypointCoordinates(poses[i], skelet.right_heel)[1];
  }
  lHeelYSeries = removeMinPlateauFromSeries(lHeelYSeries);
  rHeelYSeries = removeMinPlateauFromSeries(rHeelYSeries);
  const leftIndices = findMaximumIndices(lHeelYSeries);
  const rightIndices = findMaximumIndices(rHeelYSeries);
  let Indices = keepBiggestIndices(leftIndices, rightIndices);
  return Indices;
}

export function TuckJumpKneeInwards(poses) {
  let frames = getLocalExtrema(poses, skelet.left_knee, true, false);
  let avgAngle = 0;
  let amount = frames.length;
  for (let i = 0; i < frames.length; i++) {
    let [xLK, yLK] = getKeypointCoordinates(frames[i], skelet.left_knee);
    let [xLH, yLH] = getKeypointCoordinates(frames[i], skelet.left_hip);
    let [xLA, yLA] = getKeypointCoordinates(frames[i], skelet.left_ankle);
    let [xRK, yRK] = getKeypointCoordinates(frames[i], skelet.right_knee);
    let [xRH, yRH] = getKeypointCoordinates(frames[i], skelet.right_hip);
    let [xRA, yRA] = getKeypointCoordinates(frames[i], skelet.right_ankle);
    let hipDist = euclDist(xLH, xRH, yLH, yRH);
    let kneeDist = euclDist(xLK, xRK, yLK, yRK);
    let ankleDist = euclDist(xLA, xRA, yLA, yRA);
    let angle = calcAngle(xLK, yLK, xLA, yLA, xRK, yRK, xRA, yRA);
    if (kneeDist / hipDist < 0.98) {
      if (
        footRotation(frames[i], true)[0] === 1 ||
        footRotation(frames[i], false)[0] === 1
      ) {
        //console.log(angle);
        avgAngle += 0.5;
        console.log('+0.5');
      }
      if (kneeDist / hipDist < 0.85) {
        //console.log(angle);
        avgAngle += 4;
        console.log(4);
        //console.log('gebeurt dit?');
      } else if (kneeDist / hipDist < 0.92 && kneeDist / hipDist > 0.85) {
        avgAngle += 3;
        console.log(3);
      } else {
        avgAngle += 2;
        console.log(2);
        //console.log('no rotation and significant inwardiness');
      }
    } else {
      if (kneeDist / hipDist > 2) {
        amount -= 1;
        console.log('niets');
      } else if (kneeDist / hipDist < 1.3) {
        avgAngle += 0.5;
        console.log('0.5');
      }
      //console.log("less");
      else {
        console.log(0);
      }
    }
  }
  return avgAngle / amount;
}

export function TuckJumpFootPlacement(poses, Indices) {
  let avgRatio = 0;
  let times = [];
  for (let i = 0; i < Indices.length; i++) {
    let [xLS, yLS] = getKeypointCoordinates(
      poses[Indices[i]],
      skelet.left_shoulder
    );
    let [xRS, yRS] = getKeypointCoordinates(
      poses[Indices[i]],
      skelet.right_shoulder
    );
    let [xLF, yLF] = getKeypointCoordinates(
      poses[Indices[i]],
      skelet.left_foot_index
    );
    let [xRF, yRF] = getKeypointCoordinates(
      poses[Indices[i]],
      skelet.right_foot_index
    );
    let [xLH, yLH] = getKeypointCoordinates(
      poses[Indices[i]],
      skelet.left_heel
    );
    let [xRH, yRH] = getKeypointCoordinates(
      poses[Indices[i]],
      skelet.right_heel
    );
    //take the middle part of the foot (average of foot_index and heel) to account for internal or external rotation
    [xLF, yLF] = [(xLF + xLH) / 2, (yLF + yLH) / 2];
    [xRF, yRF] = [(xRF + xRH) / 2, (yRF + yRH) / 2];

    //take ratio of shoulder witdth/x-difference-feet
    let ratio = euclDist(xLS, xRS, yLS, yRS) / Math.abs(xLF - xRF);
    avgRatio += ratio;
    times[i] = poses[Indices[i]].currentTime;
  }
  avgRatio = avgRatio / Indices.length;
  console.log(avgRatio);
  return avgRatio;
}

/************************************
 * utility functions                *
 ************************************/

/**
 * Get angle between lines, given two points of each line
 * @param {Number} x00
 * @param {Number} y00
 * @param {Number} x01
 * @param {Number} y01
 * @param {Number} x10
 * @param {Number} y10
 * @param {Number} x11
 * @param {Number} y11
 * @returns {Number} angle: The angle between the lines.
 */
function calcAngle(
  x00,
  y00,
  x01,
  y01,
  x10,
  y10,
  x11,
  y11,
  // asuming there will almost never be angles bigger then 90°: default is true
  noObtuseAngles = true
) {
  const dx0 = x01 - x00;
  const dy0 = y01 - y00;
  const dx1 = x11 - x10;
  const dy1 = y11 - y10;
  let angle =
    (Math.abs(Math.atan2(dx0 * dy1 - dx1 * dy0, dx0 * dx1 + dy0 * dy1)) * 180) /
    3.1415926;
  if (angle > 90 && noObtuseAngles) {
    angle = Math.abs(angle - 180);
  }
  return angle;
}

/**
 * Get a list of the x coordinate and y coordinate of a keypoint in the skelet
 * @param {Object} pose contains {id: , keypoints: [], frame: }
 * @param {Number} number the specified keypoint
 * @returns
 */
function getKeypointCoordinates(pose, number) {
  const coordinates = [pose[dataKey][number]['x'], pose[dataKey][number]['y']];
  return coordinates;
}

//smallest: y-axis is oriented vertically down. Smallest y-coordinate is highest position
export function getExtremum(poses, bodyPart, vertically, smallest) {
  var extr_pose = null;
  var extr_coordinates = [];
  for (let i = 0; i < poses.length; i++) {
    var pose = poses[i];
    var coordinates = getKeypointCoordinates(pose, bodyPart);
    if (extr_pose === null) {
      extr_pose = pose;
      extr_coordinates = coordinates;
    } else {
      if (smallest) {
        if (
          coordinates[Number(vertically)] < extr_coordinates[Number(vertically)]
        ) {
          extr_pose = pose;
          extr_coordinates = coordinates;
        }
      } else {
        if (
          coordinates[Number(vertically)] > extr_coordinates[Number(vertically)]
        ) {
          extr_pose = pose;
          extr_coordinates = coordinates;
        }
      }
    }
  }
  return extr_pose;
}

/**
 * Function that returns the poses (from the array poses) where a given podypart is vertically or
 * horizontally at it's local maximum or minimum
 * @param {Array} poses
 * @param {*} bodyPart
 * @param {Boolean} vertically
 * @param {Boolean} smallest
 * @returns an array of poses
 */
export function getLocalExtrema(poses, bodyPart, vertically, smallest) {
  var localExtrema = [];
  var coordinates = [];
  for (let i = 0; i < poses.length; i++) {
    coordinates[i] = getKeypointCoordinates(poses[i], bodyPart)[
      Number(vertically)
    ];
  }
  //if the athlete is for ex. long in the air, it can happen that the y-coordinate is
  //the same for mutliple consecutive frames --> local extremum won't be found
  //a simple work around is adding or substracting a small perturbation to these plateaus
  coordinates = smallest
    ? removeMinPlateauFromSeries(coordinates)
    : removeMaxPlateauFromSeries(coordinates);
  const extremaIndices = smallest
    ? findMinimumIndices(coordinates)
    : findMaximumIndices(coordinates);
  for (let i = 0; i < extremaIndices.length; i++) {
    localExtrema[i] = poses[extremaIndices[i]];
  }
  return localExtrema;
}

/**
 * Finds the frame when some part of the feet first touch ground (aka part of feet are the lowest in that frame of all frames)
 * @param {Array} poses contains list of {pose} = {id: , keypoints: [], frame: }
 * @returns land_info: frame where certain part of foot first touches floor
 */
export function getFirstJumpLandingSide(poses, leftFoot) {
  var landPose;
  const foot = leftFoot ? skelet.left_foot_index : skelet.right_foot_index;
  const heel = leftFoot ? skelet.left_heel : skelet.right_heel;
  const ankle = leftFoot ? skelet.left_ankle : skelet.right_ankle;
  //finds minima of feet
  const footLocalExtrema = getLocalExtrema(poses, foot, true, false);
  const start = poses[0];
  const footWidth = Math.abs(
    getKeypointCoordinates(start, foot)[0] -
      getKeypointCoordinates(start, heel)[0]
  );
  const footHeight = Math.abs(
    getKeypointCoordinates(start, foot)[1] -
      getKeypointCoordinates(start, ankle)[1]
  );
  var extrema;
  if (footLocalExtrema.length > 1) {
    for (let i = 0; i < footLocalExtrema.length; i++) {
      let footMovement = Math.abs(
        getKeypointCoordinates(footLocalExtrema[i], foot)[0] -
          getKeypointCoordinates(start, foot)[0]
      );
      // checks if the current pose of the extrema is a pose where the person is still at the start position
      if (footMovement > footWidth) {
        extrema = footLocalExtrema[i];
        landPose = extrema;
        break;
      }
    }
  }
  const [xHExtrema, yHExtrema] = getKeypointCoordinates(extrema, heel);
  const [xFExtrema, yFExtrema] = getKeypointCoordinates(extrema, foot);
  var angleLand = calcAngle(
    xFExtrema,
    yFExtrema,
    xHExtrema,
    yHExtrema,
    1,
    0,
    0,
    0
  );
  // check if few poses before and after extrema are real moment of landing
  for (let i = 0; i < 10; i++) {
    const [xHeel, yHeel] = getKeypointCoordinates(poses[extrema.id - i], heel);
    const [xFoot, yFoot] = getKeypointCoordinates(poses[extrema.id - i], foot);
    const angle = calcAngle(xFoot, yFoot, xHeel, yHeel, 1, 0, 0, 0);
    //checks if foot is not too high from the ground (namely the extrema) relative to the footHeight
    const inRange = Math.abs(yFoot - yFExtrema) < footHeight;
    // checks if the foot is not too high from ground (inRange), flat enough (angle<10),and smaller than the current angle
    if (inRange && angle < 10 && angle < angleLand) {
      landPose = poses[extrema.id - i];
      angleLand = angle;
    }
  }
  console.log('landPose.currentTime, ', landPose.currentTime);
  //downloadImage(landPose);
  return landPose;
}

export function getFirstJumpLandingFront(poses, leftFoot) {
  const foot = leftFoot ? skelet.left_foot_index : skelet.right_foot_index;
  const ankle = leftFoot ? skelet.left_ankle : skelet.right_ankle;
  const footLocalExtrema = getLocalExtrema(poses, foot, true, false);
  var landPose = footLocalExtrema[0];
  const start = poses[0];
  const footHeight = Math.abs(
    getKeypointCoordinates(start, foot)[1] -
      getKeypointCoordinates(start, ankle)[1]
  );
  console.log(
    `foot ${getKeypointCoordinates(start, foot)[1]} - ankle ${
      getKeypointCoordinates(start, ankle)[1]
    }`,
    footHeight
  );
  if (footLocalExtrema.length > 1) {
    for (let i = 0; i < footLocalExtrema.length; i++) {
      // vertical
      let footMovement = Math.abs(
        getKeypointCoordinates(footLocalExtrema[i], foot)[1] -
          getKeypointCoordinates(start, foot)[1]
      );
      if (footMovement > footHeight) {
        console.log('footmovement', footMovement);
        landPose = footLocalExtrema[i];
        break;
      }
    }
  }
  console.log(`landPose.currentTime of left ${leftFoot}`, landPose.currentTime);
  //downloadImage(landPose);
  return landPose;
}

/**
 * Function that removes plateaus from a time series that hide local minima
 * @param {Array} a
 * @returns {Array} an array without plateaus
 */
function removeMinPlateauFromSeries(a) {
  if (a.length === 0) {
    return a;
  }
  let ei = 0;
  for (let i = 1; i < a.length; i++) {
    if (a[i] === a[ei]) {
      a[i] = a[i] + 0.0001;
    } else {
      ei += 1;
    }
  }
  return a;
}

/**
 * Function that removes plateaus from a time series that hide local minima
 * @param {Array} a
 * @returns {Array} an array without plateaus
 */
function removeMaxPlateauFromSeries(a) {
  if (a.length === 0) {
    return a;
  }
  let ei = 0;
  for (let i = 1; i < a.length; i++) {
    if (a[i] === a[ei]) {
      a[i] = a[i] - 0.0001;
    } else {
      ei += 1;
    }
  }
  return a;
}

/**
 * Function to find local minema in a time series
 * @param {Array} arr
 * @returns indices of the local minema
 */
const findMinimumIndices = arr =>
  arr
    .map((el, index) => {
      return el < arr[index - 3] &&
        el < arr[index - 2] &&
        el < arr[index - 1] &&
        el < arr[index + 1] &&
        el < arr[index + 2] &&
        el < arr[index + 3]
        ? index
        : null;
    })
    .filter(i => i != null);

function downloadFrames(poses, indices) {
  for (let i = 0; i < indices.length; i++) {
    downloadImage(poses[indices[i]]);
  }
}

/**
 * Function to find local maxima in a time series
 * @param {Array} arr
 * @returns indices of the local maxima
 */
const findMaximumIndices = arr =>
  arr
    .map((el, index) => {
      return el > arr[index - 3] &&
        el > arr[index - 2] &&
        el > arr[index - 1] &&
        el > arr[index + 1] &&
        el > arr[index + 2] &&
        el > arr[index + 3]
        ? index
        : null;
    })
    .filter(i => i != null);

function euclDist(x1, x2, y1, y2) {
  return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
}

/**
 * Function that compares two arrays element wise and keeps the biggest entry.
 * This can be used for comparing two arrays of indeces and keeping the indeces who are
 * tha last in time.
 * @param {Array} a1
 * @param {Array} a2
 * @returns The array with the biggest entries from eather the first or second array
 */
function keepBiggestIndices(a1, a2) {
  let n = Math.min(a1.length, a2.length);
  let result = [];
  for (let i = 0; i < n; i++) {
    result[i] = Math.max(a1[i], a2[i]);
  }
  return result;
}

export function downloadImage(pose) {
  var link = document.createElement('a');
  link.href = pose.frameImg;
  link.download = 'Download' + pose.currentTime + '.jpg';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function devideByIndeces(poses, indeces) {
  let J = [0].concat(indeces);
  let blocks = [];
  for (let i = 0; i < J.length - 1; i++) {
    let block = poses.slice(J[i], J[i + 1] + 1);
    blocks.push(block);
  }
  return blocks;
}
//Does not seem to be a good approach
// export function seperateAttempts(poses, bodyPart, vertically, cutMinema) {
//   const localMinimum = getLocalExtrema(poses, bodyPart, vertically, true);
//   const localMaximum = getLocalExtrema(poses, bodyPart, vertically, false);
//   console.log('min', localMinimum);
//   console.log('max', localMaximum);
//   var minSeries = [];
//   for (let i = 0; i < localMinimum.length; i++) {
//     minSeries[i] = getKeypointCoordinates(localMinimum[i], bodyPart)[
//       Number(vertically)
//     ];
//   }
//   const coMin = Math.min.apply(null, minSeries);
//   var maxSeries = [];
//   for (let i = 0; i < localMaximum.length; i++) {
//     maxSeries[i] = getKeypointCoordinates(localMaximum[i], bodyPart)[
//       Number(vertically)
//     ];
//   }
//   const coMax = Math.max.apply(null, maxSeries);
//   const margin = 0.9 * (coMax - coMin);
//   let result = [];
//   let posesBlock = [];
//   for (let i = 1; i < poses.length; i++) {
//     if (
//       seperationCheck(
//         poses[i],
//         bodyPart,
//         vertically,
//         cutMinema,
//         coMax,
//         coMin,
//         margin
//       )
//     ) {
//       posesBlock.push(poses[i]);
//     } else {
//       if (posesBlock.length !== 0) {
//         result.push(posesBlock);
//         posesBlock = [];
//       }
//     }
//   }

//   return result;
// }

// function seperationCheck(
//   pose,
//   bodyPart,
//   vertically,
//   cutMinema,
//   coMax,
//   coMin,
//   margin
// ) {
//   if (cutMinema) {
//     return (
//       getKeypointCoordinates(pose, bodyPart)[Number(vertically)] >
//       coMax - margin
//     );
//   } else {
//     return (
//       getKeypointCoordinates(pose, bodyPart)[Number(vertically)] <
//       coMin + margin
//     );
//   }
// }
