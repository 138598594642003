import Icon from 'components/icon/Icon';
import { Link } from 'react-router-dom';

function DownloadFileButton({ item }) {
  return (
    <Link
      className="c-button c-button--tiny u-margin-right-medium"
      to={{ pathname: item.signedDownloadUrl }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon id={'download'} strokeColor="color-neutral-dark" />
    </Link>
  );
}

export default DownloadFileButton;
