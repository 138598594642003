import { groupBy } from 'utils/array';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import { NoteTypes } from 'constants.js';

export default class Program {
  id;
  entityId;
  title;
  startdate;
  duration;
  persons = [];
  personEntities = [];
  description = '';
  exercises = [];
  exerciseIds = [];
  notes = [];
  feedback = [];

  constructor({
    id = null,
    entityId = null,
    title = '',
    startdate = '',
    duration = '',
    persons = [],
    personEntities = [],
    description = '',
    exercises = [],
    exerciseIds = [],
    notes = []
  }) {
    this.id = id;
    this.entityId = entityId;
    this.title = title;
    this.startdate = startdate ? new Date(startdate) : '';
    this.duration = duration;
    this.persons = persons || [];
    this.personEntities = personEntities || [];
    this.description = description;
    this.exercises = exercises || [];
    this.exerciseIds = exerciseIds || [];

    this.feedback = notes.filter(n => n.noteType === NoteTypes.FEEDBACK);

    const groupedNotes = groupBy(
      notes.filter(n => n.noteType === NoteTypes.PROGRAM),
      ['linkId2']
    );
    Object.keys(groupedNotes).forEach(key => {
      groupedNotes[key] = sort(groupedNotes[key], {
        keys: [
          {
            key: 'date_created',
            desc: true,
            dataType: SORT_DATA_TYPES.NUMBER
          }
        ]
      });
    });
    this.notes = groupedNotes;
  }

  static get modelName() {
    return 'Program';
  }
}
