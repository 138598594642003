import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import useSelectable from 'hooks/utils/useSelectable';
import { useUIDSeed } from 'react-uid';
import ExerciseListItemSortable from 'containers/partials/list-items/ExerciseListItemSortable';
import { EditableContextProvider } from 'contexts/EditableContext';

const ExercisesListSortable = ({
  activeId,
  exercises = [],
  exerciseGroup,
  onClick,
  draggable,
  checkable,
  enablePreviewModal,
  onPreview,
  onSelect,
  selectedExercises,
  extraClassNames = {},
  showTemplateCount,
  showNote,
  showFrequency,
  showHasComment,
  noteType,
  editableNotes
}) => {
  const parentRef = useRef();
  const uidSeed = useUIDSeed();
  const { selected, select, setSelected } = useSelectable([]);

  useEffect(() => {
    if (selectedExercises) {
      setSelected([...selectedExercises]);
    }
  }, [selectedExercises]);

  const onSelectHandler = (exercise, checked) => {
    const selectIds = select(exercise.id);
    onSelect && onSelect(selectIds, exercise, checked);
  };

  return (
    <>
      {exercises.length > 0 ? (
        <EditableContextProvider>
          <List
            ref={parentRef}
            extraClassNames={extraClassNames}
            dragNDrop={draggable}
            style={{
              padding: 4,
              margin: '0 -4px'
            }}
          >
            <ListBody>
              {exercises.map(exercise => {
                return (
                  <ExerciseListItemSortable
                    id={exercise.id}
                    key={exercise.id}
                    uid={uidSeed(exercise.id)}
                    exercise={exercise}
                    exerciseGroup={exerciseGroup}
                    notes={exerciseGroup?.notes?.[exercise.id]}
                    active={exercise.id === activeId}
                    showTemplateCount={showTemplateCount}
                    showNote={showNote}
                    showFrequency={showFrequency}
                    showHasComment={showHasComment}
                    noteType={noteType}
                    editableNote={editableNotes}
                    visible={selected.length > 0 && checkable}
                    clickable
                    checkable={checkable}
                    draggable={draggable}
                    checked={selected.includes(exercise.id)}
                    onShowPreview={() =>
                      enablePreviewModal && onPreview(exercise)
                    }
                    onChange={e => onSelectHandler(exercise, e)}
                    onClick={() =>
                      enablePreviewModal
                        ? onPreview(exercise)
                        : onClick && onClick(exercise.id)
                    }
                  />
                );
              })}
            </ListBody>
          </List>
        </EditableContextProvider>
      ) : (
        <Card centered>
          <CardBody empty>
            <Message emptyState={true} icon="cursor">
              <MessageText>
                <FormattedMessage {...messages.exercisesListEmpty} />
              </MessageText>
            </Message>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default ExercisesListSortable;
