import { Fragment, useContext, useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  ROUTE_SESSIONS_ENTITY,
  ROUTE_SESSIONS_ENTITY_ADD,
  ROUTE_SESSIONS_ENTITY_SESSION,
  ROUTE_SESSIONS_ENTITY_TEMPLATES
} from 'routes/RouteList';
import Button from 'components/button/Button';
import Panel from 'components/panel/Panel';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelHeader from 'components/panel/PanelHeader';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelSubtitle from 'components/panel/panel-title/PanelSubtitle';
import Icon from 'components/icon/Icon';
import {
  sort,
  sortAndGroupSessions,
  SORT_DATA_TYPES,
  SORT_DIRECTION
} from 'utils/sort';
import { SessionListItem } from 'containers/partials/list-items/SessionListItem';
import Modal from 'components/modal/Modal';
import ExportSessionsCard from 'containers/partials/cards/ExportSessionsCard';
import MenuWrapper from 'components/menu/MenuWrapper';
import { PanelBody, PanelTitleButton, PanelTools } from 'components/panel';
import messages from 'messages';
import SelectableList from 'components/list/SelectableList';
import CopyUrlModal from 'containers/partials/modals/CopyUrlModal';
import { getTestSessionURL } from 'utils/url';
import { PackageTypes, TestSetV2Type } from 'constants.js';
import { getLocalDateFormat } from 'utils/date';
import { StoreContext } from 'index';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import { ButtonsGroup } from 'components/button';
import { Tab, TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import useTabIndex from 'hooks/utils/useTabIndex';
import Loader from 'components/loader/Loader';
import AddPreventionModal from 'containers/pages/prevention/AddPreventionModal';
import PreventionTemplateList from 'containers/pages/prevention/PreventionTemplateList';
import AddTemplateModal from 'containers/pages/prevention/AddTemplateModal';
import Session from 'models/Session';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import FieldInput from 'components/input/FieldInput';
import SortableListHeader from 'components/list/SortableListHeader';
import { useSessionsContext } from 'contexts/SessionsContext';

const columns = [
  {
    key: 'finishedPercentage',
    label: '%',
    sortable: true,
    dataType: SORT_DATA_TYPES.NUMBER,
    dir: SORT_DIRECTION.ASC,
    extraProps: { id: 'listItemStatus' } // this prop is only necessary for styles. Most of the times we don't need it
  },
  {
    key: 'name',
    label: 'Session name',
    sortable: true,
    dir: SORT_DIRECTION.ASC,
    dataType: SORT_DATA_TYPES.STRING,
    extraProps: { id: 'listItemMainLabel' } // this prop is only necessary for styles. Most of the times we don't need it
  },
  {
    key: 'type',
    label: 'Type',
    sortable: false,
    extraProps: { id: '' } // Empty id === hidden column
  },
  {
    key: 'startdate',
    label: 'Date',
    default: true,
    sortable: true,
    dir: SORT_DIRECTION.DESC,
    dataType: SORT_DATA_TYPES.DATE_STRING,
    extraProps: { id: 'listItemLastLabel' } // this prop is only necessary for styles. Most of the times we don't need it
  }
];

const Overview = ({ entityId, active }) => {
  const {
    authStore: { user },
    uiState: { locale }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const { sessionId } = useParams();
  const ability = useAbility(AbilityContext);
  const [activeSort, setActiveSort] = useState(() => {
    return columns.find(c => c.default) || columns[0];
  });
  const [exportModal, setExportModal] = useState(false);
  const [selectedSessionIds, setSelectedSessionIds] = useState([]);
  const [copyUrlModal, setCopyUrlModal] = useState(false);
  const [urlModalContent, setUrlModalContent] = useState(null);
  const [search, setSearch] = useState('');
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_SESSIONS_ENTITY,
    ROUTE_SESSIONS_ENTITY_TEMPLATES
  ]);
  const [preventionModal, setPreventionModal] = useState(null);
  const [templateModal, setTemplateModal] = useState(null);
  const { sessions, loading, error } = useSessionsContext();
  // const [sessions, setSessions] = useState([]);
  /*const { loading, error, data } = useQuery(S_QUERY_GET_SESSIONS_BY_ENTITY_ID, {
    variables: { entityId }
  });*/

  /*useEffect(() => {
    if (data?.getTestSessions) {
      const filteredSessions = data.getTestSessions.filter(
        session =>
          session.type !== TestSetV2Type.REHAB &&
          session.type !== TestSetV2Type.PREVENTION_TEMPLATE
      );
      const sessions = parseTestSessionDataToModel(filteredSessions);
      const sortedSessions = sortAndGroupSessions(sessions);
      setSessions(sortedSessions);
    }
  }, [data]);
*/

  const onSearch = e => {
    setSearch(e.target.value);
  };

  const onCloseModal = () => {
    setExportModal(false);
  };

  const onSelectSessionsChangeHandler = (id, selectedIds) => {
    setSelectedSessionIds(selectedIds);
    setUrlModalContent(getModalContent(selectedIds));
  };

  const getModalContent = selectedIds => {
    if (!sessions) return null;

    const selectedSessions = selectedIds.map(id =>
      sessions.find(s => s.id === id)
    );
    const sessionsFiltered = selectedSessions.filter(
      s => !s.isDone && s.config.type !== PackageTypes.MTS
    );
    if (sessionsFiltered.length > 1) {
      const sortedSessions = sortAndGroupSessions(sessionsFiltered);
      const urls = sortedSessions.map(
        session =>
          session.name +
          ':\n' +
          getTestSessionURL({
            entityId,
            sessionId: session.id,
            packageType: session.config.type,
            locale,
            sporterId: false,
            session,
            copyAble: true
          })
      );
      return urls.join('\n\n');
    } else if (sessionsFiltered.length === 1) {
      const session = sessionsFiltered[0];
      return getTestSessionURL({
        entityId,
        sessionId: session.id,
        packageType: session.config.type,
        locale
      });
    }
    return null;
  };

  const findStringInSession = (session, str) => {
    const trimmedString = str.toLowerCase().trim();
    const sessionName = session.name.toLowerCase().trim();
    if (sessionName.indexOf(trimmedString) !== -1) return true;
    const tesSetName = session.testSets?.[0]?.name
      ? session.testSets[0].name.toLowerCase().trim()
      : '';
    if (tesSetName.indexOf(trimmedString) !== -1) return true;
    const dateName = getLocalDateFormat(session.startdate);
    if (dateName.indexOf(trimmedString) !== -1) return true;
  };

  useEffect(() => {
    const filteredSessions = !search
      ? sessions
      : sessions.filter(s => {
          if (findStringInSession(s, search)) return true;
          const noFoundDeeper = search.split(' ').some(searchWord => {
            return !findStringInSession(s, searchWord);
          });
          return !noFoundDeeper;
        });

    setFilteredSessions(
      sort(filteredSessions, {
        keys: [activeSort]
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessions, search]);

  const onSessionsTabClick = () => {
    push(
      generatePath(ROUTE_SESSIONS_ENTITY, {
        entityId
      })
    );
  };

  const onTemplatesTabClick = () => {
    push(
      generatePath(ROUTE_SESSIONS_ENTITY_TEMPLATES, {
        entityId
      })
    );
  };

  return (
    <>
      <Panel active={active}>
        {loading && <Loader />}
        {error && <RetryPanel />}
        {sessions && (
          <Fragment>
            {exportModal && (
              <Modal card autoHeight isOpen={true} onClose={onCloseModal}>
                <ExportSessionsCard onClose={onCloseModal} />
              </Modal>
            )}
            {copyUrlModal && (
              <CopyUrlModal
                isOpen={copyUrlModal}
                content={urlModalContent}
                onClose={() => {
                  setCopyUrlModal(false);
                  setSelectedSessionIds([]);
                  setUrlModalContent(null);
                }}
              />
            )}
            <PanelHeader>
              <PanelTitleWrapper>
                <PanelTitle>
                  <FormattedMessage {...messages.titleSessions} />
                  {!user.isAthlete() && (
                    <MenuWrapper trigger={<PanelTitleButton />}>
                      {!urlModalContent && (
                        <Button menu onClick={() => setExportModal(true)}>
                          <FormattedMessage
                            {...messages.menuItemSessionExportSessions}
                          />
                          <Icon id="download" />
                        </Button>
                      )}
                      {urlModalContent && (
                        <Button menu onClick={() => setCopyUrlModal(true)}>
                          {urlModalContent.search('\n') !== -1 ? (
                            <FormattedMessage
                              {...messages.menuItemSessionCopyUrls}
                            />
                          ) : (
                            <FormattedMessage
                              {...messages.menuItemSessionOverviewCopyUrl}
                            />
                          )}
                          <Icon id="copy" />
                        </Button>
                      )}
                    </MenuWrapper>
                  )}
                </PanelTitle>
                <PanelSubtitle>
                  <FormattedMessage
                    {...messages.subtitleSessions}
                    values={{
                      name: user.getRelatedEntityNameByEntityId(entityId)
                    }}
                  />
                </PanelSubtitle>
              </PanelTitleWrapper>

              <PanelTools>
                <FieldInput
                  rounded
                  type="text"
                  icon="search-grey"
                  iconLeft
                  onChange={onSearch}
                />

                {ability.can('create', 'Sessions') && (
                  <>
                    {tabIndex === 0 && (
                      <ButtonsGroup>
                        {ability.can('create', 'Prevention') ? (
                          <>
                            <MenuWrapper
                              trigger={
                                <Button primary rounded>
                                  <Icon id="add-white" />
                                  <FormattedMessage
                                    {...messages.buttonSessionsAddSession}
                                  />
                                  <Icon
                                    id="dropdown-open"
                                    fillColor="color-neutral"
                                  />
                                </Button>
                              }
                            >
                              <Button
                                menu
                                onClick={() =>
                                  push(
                                    ROUTE_SESSIONS_ENTITY_ADD.replace(
                                      ':entityId',
                                      entityId
                                    )
                                  )
                                }
                              >
                                <FormattedMessage
                                  {...messages.preventionTalentButton}
                                />{' '}
                                <Icon id="test" />
                              </Button>

                              <Button
                                menu
                                onClick={() => {
                                  setPreventionModal(
                                    new Session({
                                      type: TestSetV2Type.PREVENTION
                                    })
                                  );
                                }}
                              >
                                <FormattedMessage
                                  {...messages.preventionButton}
                                />{' '}
                                <Icon id="test" />
                              </Button>
                            </MenuWrapper>
                          </>
                        ) : (
                          <Button
                            primary
                            rounded
                            onClick={() =>
                              push(
                                ROUTE_SESSIONS_ENTITY_ADD.replace(
                                  ':entityId',
                                  entityId
                                )
                              )
                            }
                          >
                            <Icon id="add-white" />
                            <FormattedMessage
                              {...messages.buttonSessionsAddSession}
                            />
                          </Button>
                        )}
                      </ButtonsGroup>
                    )}

                    {tabIndex === 1 && (
                      <ButtonsGroup>
                        <Button
                          primary
                          rounded
                          onClick={() => setTemplateModal(true)}
                        >
                          <Icon id="add-white" />
                          <FormattedMessage
                            {...messages.buttonSessionsAddTemplate}
                          />
                        </Button>
                      </ButtonsGroup>
                    )}
                  </>
                )}
              </PanelTools>
            </PanelHeader>
            <PanelBody>
              <Tabs
                sessionTemplateTabs={ability.can('create', 'Sessions')}
                selectedIndex={tabIndex}
                onSelect={index => setTabIndex(index)}
              >
                <TabList>
                  <Tab key="session" onClick={onSessionsTabClick}>
                    <FormattedMessage {...messages.tabSessionsSessions} />
                  </Tab>
                  {ability.can('create', 'Prevention') && (
                    <Tab
                      key="prevention.templates"
                      onClick={onTemplatesTabClick}
                    >
                      <FormattedMessage {...messages.tabSessionsTemplates} />
                    </Tab>
                  )}
                </TabList>
                <TabPanel key="session">
                  <TabPanelBody>
                    {filteredSessions.length > 0 && (
                      // TODO: Maarten dit zou moeten Virtualized zijn
                      <List>
                        <SortableListHeader
                          sticky
                          topPosition={-16}
                          onSort={activeSort => {
                            setActiveSort(activeSort);
                            setFilteredSessions(
                              sort(filteredSessions, {
                                keys: [activeSort]
                              })
                            );
                          }}
                          columns={columns}
                        />
                        <ListBody>
                          <SelectableList
                            onChange={(id, ids) => {
                              if (!user.isMTSOrg) {
                                onSelectSessionsChangeHandler(id, ids);
                              }
                            }}
                            onClick={id => {
                              setSelectedSessionIds([]);
                              setUrlModalContent(null);
                              push(
                                `${ROUTE_SESSIONS_ENTITY_SESSION.replace(
                                  ':entityId',
                                  entityId
                                ).replace(':sessionId', id)}`
                              );
                            }}
                            selectedIds={selectedSessionIds}
                          >
                            {filteredSessions.map(session => (
                              <SessionListItem
                                noMaxHeight
                                id={session.id}
                                visible={!user.isMTSOrg}
                                checkbox={!user.isMTSOrg}
                                key={session.id}
                                session={session}
                                active={sessionId === session.id}
                              />
                            ))}
                          </SelectableList>
                        </ListBody>
                      </List>
                    )}
                  </TabPanelBody>
                </TabPanel>
                {ability.can('create', 'Prevention') && (
                  <TabPanel key="prevention.templates">
                    <TabPanelBody>
                      <PreventionTemplateList
                        entityId={entityId}
                        onUse={template => setPreventionModal(template)}
                      />
                    </TabPanelBody>
                  </TabPanel>
                )}
              </Tabs>
            </PanelBody>
          </Fragment>
        )}
      </Panel>
      {preventionModal && (
        <AddPreventionModal
          entityId={entityId}
          session={preventionModal}
          isTemplate
          onClose={() => {
            setPreventionModal(false);
          }}
        />
      )}
      {templateModal && (
        <AddTemplateModal
          entityId={entityId}
          onClose={() => {
            setTemplateModal(false);
          }}
        />
      )}
    </>
  );
};

export default Overview;
