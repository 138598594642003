import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import Select, { Option } from 'components/input/Select';
import messages from 'messages';
import { useIntl } from 'react-intl';
import { QUERY_GET_SCANNERS_BY_ENTITY_ID } from 'services/aws/scanner-query';
import Scanner from 'models/Scanner';
import { sortScannersByName } from 'utils/sort';

function ScannersSelect({ entityId, onChange, readOnly, inline = false }) {
  const intl = useIntl();
  const [scanners, setScanners] = useState([]);
  const [selectedScanner, setSelectedScanner] = useState(null);

  const { loading, error, data } = useQuery(QUERY_GET_SCANNERS_BY_ENTITY_ID, {
    variables: { entityId }
  });

  useEffect(() => {
    if (data?.getMtsScanners && data.getMtsScanners.length > 0) {
      const scanners = data.getMtsScanners.map(sc => new Scanner(sc));
      setScanners(sortScannersByName(scanners));
    }
  }, [data]);

  const onSelectHandler = select => {
    setSelectedScanner(select);
    onChange(scanners.find(scanner => scanner.id === select.value));
  };

  if (error) return 'Error';

  return (
    <Select
      extraClassNames="o-flex--justify-space-between"
      inline={inline}
      label={intl.formatMessage(messages.labelSessionScanner)}
      onChange={select => onSelectHandler(select)}
      value={selectedScanner ? selectedScanner.value : ''}
      readOnly={readOnly}
      disabled={loading}
    >
      <Option value="" label={intl.formatMessage(messages.noResultsOption)} />
      {scanners &&
        scanners.map(({ id, name }) => (
          <Option key={id} value={id} label={name} />
        ))}
    </Select>
  );
}

export default ScannersSelect;
