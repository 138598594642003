import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { useNotificationQueue } from 'components/notification';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';

import {
  MUTATION_ADDEDIT_TRANSLATION_EXERCISE,
  QUERY_GET_EXERCISE_COPY
} from 'services/aws/exercises-query';
import messages from 'messages.js';

function ExerciseCopyForm({
  entityId,
  exerciseCopy,
  extraClassNames,
  children,
  onComplete
}) {
  const notification = useNotificationQueue();
  const intl = useIntl();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage(messages.titleRequired))
  });

  const [editExercise] = useMutation(MUTATION_ADDEDIT_TRANSLATION_EXERCISE);

  const onSubmitHandler = async exerciseCopy => {
    const exerciseObj = {
      ...exerciseCopy,
      entityId
    };

    await editExercise({
      variables: { ...exerciseObj },
      refetchQueries: [
        {
          query: QUERY_GET_EXERCISE_COPY,
          variables: { id: exerciseCopy.exerciseId, entityId }
        }
      ]
    }).then(({ data }) => {
      notification.add(data.addEditExerciseCopy.id, {
        message: intl.formatMessage(messages.messageEditExerciseSuccess)
      });
      onComplete && onComplete();
    });
  };

  return (
    <Formik
      initialValues={exerciseCopy}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmitHandler}
    >
      {props => {
        return (
          <Form className={classNames(extraClassNames)} noValidate>
            {typeof children === 'function' ? children(props) : children}
          </Form>
        );
      }}
    </Formik>
  );
}

export default ExerciseCopyForm;
