import classNames from 'classnames';

const PanelTitle = props => {
  const { children, extraClassNames, ...rest } = props;
  return (
    <h2 className={classNames('c-panel__title', extraClassNames, {})}>
      {props.children}
    </h2>
  );
};

export default PanelTitle;
