import { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  generatePath,
  useHistory,
  useLocation,
  useRouteMatch
} from 'react-router-dom';
import { GenderTypes } from 'enums';
import CardBody from 'components/card/CardBody';
import CardFooter from 'components/card/CardFooter';
import messages from 'messages.js';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import Select, { Option } from 'components/input/Select';
import { LeftRight, PersonStatus } from 'constants.js';
import InputButtonWrapper from 'components/input/InputButtonWrapper';
import ResetLoginButton from '../buttons/ResetLoginButton';
import { StoreContext } from 'index';
import FieldInput from 'components/input/FieldInput';
import { FieldTextarea } from 'components/input/FieldTextarea';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import MTSPersonFields from 'containers/partials/forms/MTSPersonFields';
import InviteSporterButton from 'containers/partials/buttons/InviteSporterButton';
import SporterForm from 'containers/pages/sporters/SporterForm';
import { usePersonContext } from 'contexts/PersonContext';
import RemoveLoginButton from 'containers/partials/buttons/RemoveLoginButton';
import { FieldDatePicker } from 'components/input/FieldDatePicker';
import PersonStatusPopper from 'containers/partials/misc/PersonStatusPopper';
import useEdit from 'hooks/utils/useEdit';
import {
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT,
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SPORTERS_ENTITY_POOL,
  ROUTE_SPORTERS_ENTITY_SPORTER_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL,
  ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL_EDIT
} from 'routes/RouteList';
import { useMenuContext } from 'contexts/MenuContext';
import RemoveSporterFromGroupButton from 'containers/partials/buttons/RemoveSporterFromGroupButton';
import useUserGroups from 'hooks/queries/useUserGroups';
import Loader from 'components/loader/Loader';
import RemovePersonsFromOrgButton from 'containers/partials/buttons/RemovePersonsFromOrgButton';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_PERSONS_OF_ENTITY } from 'services/aws/user-query';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';

const SporterCard = ({ entityId, onCancel, isAdd = false }) => {
  const ability = useAbility(AbilityContext);
  const {
    authStore: {
      user,
      user: { rootEntityId }
    },
    uiState: { locale }
  } = useContext(StoreContext);
  const location = useLocation();
  const { push } = useHistory();
  const { person, loading } = usePersonContext();
  const { addMenuItems, removeAllMenuItems } = useMenuContext();

  const { loading: groupsLoading, entities } = useUserGroups({
    entityId: rootEntityId,
    personId: person?.id
  });

  const [getPool] = useLazyQuery(QUERY_GET_PERSONS_OF_ENTITY);

  const [edit, setEdit] = useEdit(
    !!useRouteMatch([
      ROUTE_SPORTERS_ENTITY_SPORTER_EDIT,
      ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL_EDIT,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT
    ]) || isAdd,
    !isAdd
  );
  const intl = useIntl();

  useEffect(() => {
    return () => {
      removeAllMenuItems();
    };
  }, []);

  useEffect(() => {
    if (
      ability.can('delete', 'Athletes') &&
      person?.id &&
      entities.length > 0 &&
      entityId !== rootEntityId
    ) {
      addMenuItems(
        <RemoveSporterFromGroupButton
          key="deleteAthlete"
          menu
          entityId={entityId}
          personId={person.id}
        >
          <FormattedMessage {...messages.menuItemSporterRemoveSporter} />
        </RemoveSporterFromGroupButton>
      );
    }
    if (
      ability.can('delete', 'Athletes') &&
      person?.id &&
      entityId === rootEntityId
    ) {
      addMenuItems(
        <RemovePersonsFromOrgButton
          key={'removeSporters'}
          entityId={rootEntityId}
          ids={[person.id]}
          menu
          onComplete={onPersonsRemoved}
        >
          <FormattedMessage
            {...messages.menuItemSporterRemoveSporters}
            values={{ count: 1 }}
          />
        </RemovePersonsFromOrgButton>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, person, user, entities]);

  const onPersonsRemoved = async () => {
    const { data } = await getPool({
      variables: { entityId: rootEntityId },
      fetchPolicy: 'network-only'
    });
    if (data?.getPersonsOfEntity) {
      if (entityId === rootEntityId) {
        push(ROUTE_SPORTERS_ENTITY_POOL.replace(':entityId', rootEntityId));
      } else {
        push(ROUTE_SPORTERS_ENTITY.replace(':entityId', entityId));
      }
    }
  };

  const onPersonAdded = personId => {
    push(
      generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL, {
        entityId,
        sporterId: personId
      })
    );
  };

  const onPersonUpdated = personId => {
    setEdit(false);
  };

  if (loading || groupsLoading) return <Loader />;

  return (
    <SporterForm
      entityId={entityId}
      person={{ ...person }}
      onAdded={personId => onPersonAdded(personId)}
      onComplete={personId => onPersonUpdated(personId)}
    >
      {({
        submitForm,
        resetForm,
        values,
        errors,
        touched,
        setFieldValue,
        validateField,
        handleChange,
        handleBlur
      }) => (
        <>
          <CardHeader secondary>
            <CardHeaderTitle>
              <FormattedMessage {...messages.cardTitleSporter} />
            </CardHeaderTitle>
            {!user.isAthlete() && (
              <FormEditHeaderButtons
                edit={edit}
                setEdit={edit => setEdit(edit)}
                type={'button'}
                onSubmit={submitForm}
                onCancel={() => {
                  resetForm();
                  onCancel && onCancel();
                }}
              />
            )}
          </CardHeader>
          <CardBody secondary>
            <div className="o-layout o-layout--tiny">
              <div className="o-layout__item u-1-of-2-at-small">
                <FieldInput
                  id="firstname"
                  name="firstname"
                  readOnly={!edit}
                  touched={touched}
                  errors={errors}
                  onBlur={handleBlur}
                >
                  <FormattedMessage {...messages.cardLabelFirstname} />*
                </FieldInput>
              </div>
              <div className="o-layout__item u-1-of-2-at-small">
                <FieldInput
                  id="lastname"
                  name="lastname"
                  readOnly={!edit}
                  touched={touched}
                  errors={errors}
                  onBlur={handleBlur}
                >
                  <FormattedMessage {...messages.cardLabelLastname} />*
                </FieldInput>
              </div>
            </div>
            <div className="o-layout o-layout--tiny">
              <div className="o-layout__item u-1-of-2-at-small">
                <FieldDatePicker
                  id="birthdate"
                  name="birthdate"
                  icon="calendar"
                  placeholder={'dd/mm/yyyy'}
                  onChange={handleChange}
                  readOnly={!edit}
                  locale={locale}
                  min={new Date('1930/01/01')}
                  max={new Date()}
                  errors={errors}
                  required
                >
                  <FormattedMessage {...messages.cardLabelBirthdate} />*
                </FieldDatePicker>
              </div>
              <div className="o-layout__item u-1-of-2-at-small">
                <Select
                  label={`${intl.formatMessage(messages.cardLabelGender)}*`}
                  name="gender"
                  value={values.gender}
                  readOnly={!edit}
                  onChange={selected => {
                    setFieldValue('gender', selected.value);
                    validateField('gender');
                  }}
                  touched={touched}
                  errors={errors}
                >
                  {Object.entries(GenderTypes).map(([key, value]) => {
                    return (
                      <Option
                        key={key}
                        value={value}
                        label={intl.formatMessage(messages[value])}
                      />
                    );
                  })}
                </Select>
              </div>

              {!user.isVitaleOrg &&
                (edit ||
                  (person.uid !== ' ' &&
                    person.uid !== '' &&
                    person.uid !== undefined)) && (
                  <div className="o-layout__item u-1-of-2-at-small">
                    <FieldInput id="uid" name="uid" readOnly={!edit}>
                      <FormattedMessage {...messages.cardLabelUid} />
                    </FieldInput>
                  </div>
                )}

              {!user.isMTSOrg &&
                !user.isVitaleOrg &&
                (edit || person.dominantHand) && (
                  <div className="o-layout__item u-1-of-2-at-small">
                    <Select
                      label={`${intl.formatMessage(
                        messages.cardLabelDominantHand
                      )}`}
                      //   extraClassNames={'u-growth-left'}
                      name="dominantHand"
                      value={values.dominantHand}
                      readOnly={!edit}
                      onChange={selected => {
                        setFieldValue('dominantHand', selected.value);
                      }}
                    >
                      {Object.entries(LeftRight).map(([key, value]) => {
                        return (
                          <Option
                            key={key}
                            value={value}
                            label={intl.formatMessage(
                              messages[`dominantHand${value}`]
                            )}
                          />
                        );
                      })}
                    </Select>
                  </div>
                )}
            </div>

            <div className="o-layout o-layout--tiny">
              <div className="o-layout__item u-1-of-2-at-large">
                <FieldInput
                  id="athleteEmail"
                  name="athleteEmail"
                  readOnly={!edit}
                  errors={errors}
                  touched={touched}
                >
                  <FormattedMessage {...messages.cardLabelEmail} />
                </FieldInput>
              </div>

              {ability.can('invite', 'Athletes') &&
                person.id &&
                !person.email &&
                person.athleteEmail &&
                !errors['athleteEmail'] && (
                  <div className="o-layout__item u-1-of-2-at-small">
                    <InputButtonWrapper
                      id="resetpassword-id"
                      label={<FormattedMessage {...messages.labelUserInvite} />}
                    >
                      <InviteSporterButton
                        person={person}
                        entityId={rootEntityId}
                        extraClassNames={'c-button--ellipsed'}
                        target={location.pathname}
                        redirect={location.pathname}
                      >
                        <FormattedMessage {...messages.buttonUserInvite} />
                      </InviteSporterButton>
                    </InputButtonWrapper>
                  </div>
                )}

              {ability.can('invite', 'Athletes') &&
                person.id &&
                person.email &&
                !errors['athleteEmail'] && (
                  <>
                    <div className="o-layout__item u-1-of-2-at-large">
                      <InputButtonWrapper
                        extraClassNamesWrapper="o-flex o-flex--align-center"
                        id="resetpassword-id"
                        label={
                          <FormattedMessage
                            {...messages.labelUserResetPassword}
                          />
                        }
                      >
                        <ResetLoginButton
                          person={person}
                          entityId={rootEntityId}
                          target={location.pathname}
                          redirect={location.pathname}
                          extraClassNames={'c-button--ellipsed'}
                        >
                          <FormattedMessage
                            {...messages.buttonUserResetPassword}
                          />
                        </ResetLoginButton>
                        {[
                          PersonStatus.PENDING,
                          PersonStatus.UNCONFIRMED
                        ].includes(person.status) && (
                          <PersonStatusPopper
                            extraClassNames="u-margin-left-small"
                            person={person}
                          />
                        )}
                      </InputButtonWrapper>
                    </div>
                    <div className="o-layout__item u-1-of-2-at-large">
                      <InputButtonWrapper
                        id="remove-login-id"
                        label={
                          <FormattedMessage
                            {...messages.labelUserRemoveLogin}
                          />
                        }
                      >
                        <RemoveLoginButton
                          personId={person.id}
                          entityId={entityId}
                          target={location.pathname}
                          redirect={location.pathname}
                          extraClassNames={'c-button--ellipsed'}
                        >
                          <FormattedMessage
                            {...messages.buttonUserRemoveLogin}
                          />
                        </RemoveLoginButton>
                      </InputButtonWrapper>
                    </div>
                  </>
                )}
            </div>
            {/* {person.account_meta?.status === 'FORCE_CHANGE_PASSWORD' && (
              <AlertRemarks
                message={intl.formatMessage(messages.emailIsNotConfirmed)}
              />
            )} */}
            {false && user.isMTSOrg && person && (
              <MTSPersonFields
                edit={
                  edit // TODO MTS fields voorlopig disabled
                }
                person={person}
              />
            )}
            {edit ? (
              <FieldTextarea
                id="notes-id"
                name="notes"
                rows="4"
                readOnly={!edit}
                extraClassNames={!edit && 'u-margin-bottom-none'}
                resizeVertical
              >
                <FormattedMessage {...messages.cardLabelNotes} />
              </FieldTextarea>
            ) : (
              person.notes && (
                <>
                  <p className={'c-input__label'}>
                    <FormattedMessage {...messages.cardLabelNotes} />
                  </p>
                  <p className={'u-margin-bottom-none'}>{person.notes}</p>
                </>
              )
            )}
          </CardBody>
          {edit && (
            <CardFooter secondary>
              <FormEditFooterButtons
                edit={edit}
                setEdit={edit => setEdit(edit)}
                onCancel={() => {
                  resetForm();
                  onCancel && onCancel();
                }}
                onSubmit={submitForm}
                type={'button'}
              />
            </CardFooter>
          )}
        </>
      )}
    </SporterForm>
  );
};
export default SporterCard;
