import React, { useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';
import { DefaultBenchmark, PreventionType, TestSetV2Type } from 'constants.js';
import { ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION } from 'routes/RouteList';
import { useMutation } from '@apollo/client';
import {
  MUTATION_ADD_TEST_SESSION,
  MUTATION_UPDATE_TEST_SESSION,
  QUERY_GET_SESSION_BY_ID,
  QUERY_GET_SESSIONS_BY_ENTITY_ID
} from 'services/aws/session-query';
import {
  MUTATION_ADD_TESTSET,
  MUTATION_EDIT_TESTSET
} from 'services/aws/testsets-query';
import * as Yup from 'yup';
import { StoreContext } from 'index';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { cleanFalsy } from 'utils/object';
import { useNotificationQueue } from 'components/notification';

import Session from 'models/Session';
import messages from 'messages';
import { format } from 'date-fns';
import { parseDateString } from 'utils/date';

function SessionForm({
  entityId,
  session: templateSession,
  children,
  extraClassNames,
  onComplete
}) {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const notification = useNotificationQueue();

  const intl = useIntl();
  const [session, setSession] = useState(new Session({}));

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage(messages.sessionNameRequired)
    ),
    startdate: Yup.date()
      .transform(parseDateString)
      .typeError(intl.formatMessage(messages.dateRequired))
      .required(intl.formatMessage(messages.dateRequired)),
    type: Yup.string().required(intl.formatMessage(messages.typeRequired)),
    preventionType: Yup.string().when('type', {
      is: val => val === TestSetV2Type.PREVENTION,
      then: Yup.string()
        .required(intl.formatMessage(messages.testsetTypeRequired))
        .typeError(intl.formatMessage(messages.labelTestSetType)),
      otherwise: Yup.string().nullable()
    }),
    sport: Yup.object()
      .shape({
        id: Yup.string()
      })
      .when('type', {
        is: val => val === TestSetV2Type.PREVENTION,
        then: Yup.object()
          .required(intl.formatMessage(messages.sportRequired))
          .typeError(intl.formatMessage(messages.sportSelectPlaceholder)),
        otherwise: Yup.object().nullable()
      }),
    testSetId: Yup.string().when('type', {
      is: val => val === TestSetV2Type.TALENT,
      then: Yup.string()
        .required(intl.formatMessage(messages.testsetTypeRequired))
        .typeError(intl.formatMessage(messages.optionSessionSelectTestSetType)),
      otherwise: Yup.string().nullable()
    })
  });

  useEffect(() => {
    if (templateSession) {
      setSession(templateSession);
    }
  }, [templateSession]);

  const [addTestSession] = useMutation(MUTATION_ADD_TEST_SESSION);
  const [editTestSession] = useMutation(MUTATION_UPDATE_TEST_SESSION);
  const [addTestSet] = useMutation(MUTATION_ADD_TESTSET);
  const [editTestSet] = useMutation(MUTATION_EDIT_TESTSET);

  const onSubmitHandler = async (session, { resetForm }) => {
    let testSet;
    let testSetId;

    // TODO fix for sc-11306, check on testSetId is not enough
    // needs verification
    if (session.isPrevention || session.isRehab) {
      testSet = cleanFalsy({
        entityId,
        title: session.name,
        note: session.notes,
        defaultBenchmarkId: session.benchmarkId
          ? session.benchmarkId
          : session.benchmark
          ? session.benchmark.id
          : null,
        sportId: session?.sport ? session.sport.id : null,
        testIds: session.tests.length ? session.tests.map(t => t.id) : [],
        type: TestSetV2Type.PREVENTION,
        preventionType: session.preventionType,
        meta: session.injuryIds ? JSON.stringify(session.injuryIds) : null
      });
    }

    const sessionMeta = session.injuryIds ? { ...session.injuryIds } : null;
    if (session.language) {
      sessionMeta.language = session.language;
    }

    const testSession = cleanFalsy({
      entityId,
      name: session.name,
      benchmarkId: session.benchmarkId
        ? session.benchmarkId
        : session.benchmark
        ? session.benchmark.id
        : null,
      startdate: format(session.startdate, 'yyyy-LL-dd'),
      location: session.location,
      notes: session.notes,
      type: session.type,
      preventionType: session.preventionType,
      meta:
        Object.keys(sessionMeta).length > 0 ? JSON.stringify(sessionMeta) : null
    });

    if (testSession.type === TestSetV2Type.TALENT && !user.isHylyghtOrg) {
      testSession.benchmarkId = DefaultBenchmark;
    }

    if (session.id) {
      //edit
      // TODO fix for sc-11306, check on testSetId is not enough
      if (session.isPrevention || session.isRehab) {
        const testSetData = await editTestSet({
          variables: {
            ...testSet,
            id: session.testSetIds[0],
            forceUpdate: true
          }
        });

        testSetId = testSetData.data.editTestSetV2.id;
      } else {
        testSetId = session.testSetId;
      }

      await editTestSession({
        variables: { ...testSession, testSetIds: [testSetId], id: session.id },
        refetchQueries: [
          {
            query: QUERY_GET_SESSIONS_BY_ENTITY_ID,
            variables: { entityId }
          },
          {
            query: QUERY_GET_SESSION_BY_ID,
            variables: { entityId, testSessionId: session.id }
          }
        ]
      }).then(res => {
        notification.add(res.data.editTestSession.id, {
          message: intl.formatMessage(messages.messageSessionSaved)
        });
        if (onComplete) return onComplete(res);
      });
    } else {
      // add
      if (!session.testSetId) {
        const testSetData = await addTestSet({
          variables: { ...testSet }
        });

        testSetId = testSetData.data.addTestSetV2.id;
      } else {
        testSetId = session.testSetId;
      }
      await addTestSession({
        variables: {
          ...testSession,
          testSetId: testSetId,
          testSetIds: [testSetId]
        },
        refetchQueries: [
          {
            query: QUERY_GET_SESSIONS_BY_ENTITY_ID,
            variables: { entityId }
          }
        ]
      }).then(res => {
        //onSubmitted();
        resetForm();
        notification.add(res.data.addTestSession.id, {
          message: intl.formatMessage(messages.messageSessionSaved)
        });

        if (onComplete) return onComplete(res);

        push(
          generatePath(ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION, {
            entityId,
            type: PreventionType.BASIC,
            sessionId: res.data.addTestSession.id
          })
        );
      });
    }
  };

  return (
    <Formik
      initialValues={session}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={onSubmitHandler}
    >
      {props => {
        return (
          <Form className={classNames(extraClassNames)} noValidate>
            {typeof children === 'function' ? children(props) : children}
          </Form>
        );
      }}
    </Formik>
  );
}

export default SessionForm;
