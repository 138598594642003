import React from 'react';
import TooltipPopper from 'components/tooltip/TooltipPopper';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { BigPlayButton, Player } from 'video-react';
import HLSSource from 'components/video/HLSSource';

const FileFull = ({ file }) => {
  switch (file.fileType) {
    case 'image':
      return (
        <div className={'c-media-wrapper'} key={file.id}>
          <img src={file.signedDownloadUrl} alt={file.filename} />
        </div>
      );
    case 'video':
      if (!file.meta?.mux?.playback_ids?.[0]?.id) {
        return (
          <div className="c-media-wrapper o-flex">
            <div className="c-media__gallery-thumb c-media__gallery-thumb-empty c-file-upload--uploading">
              <TooltipPopper
                id="popper-tooltip-2"
                icon="retry"
                mediumIcon
                //   onClick={onClick}
                onMouseEnter={() => true}
                onFocus={() => true}
                onMouseLeave={() => false}
                onBlur={() => false}
              >
                <FormattedMessage
                  {...messages.fileUploadVideoNotAvailableTooltipMessage}
                />
              </TooltipPopper>
            </div>
          </div>
        );
      }

      return (
        <div className={'c-media-wrapper'} key={file.id}>
          {file?.signedDownloadUrl && (
            <Player playsInline fluid>
              <HLSSource isVideoChild src={file.signedDownloadUrl} />
              <BigPlayButton position="center" />
            </Player>
          )}
        </div>
      );

    case 'video_legacy':
      return (
        <div className={'c-media-wrapper'} key={file.id}>
          <Player playsInline>
            <BigPlayButton position="center" />
            <source src={file.signedDownloadUrl} />
          </Player>
        </div>
      );
    default:
      return <></>;
  }
};

export default FileFull;
