import { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { generatePath } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import Panel from 'components/panel/Panel';
import { ROUTE_VIDEOS_ENTITY_VIDEO } from 'routes/RouteList';
import messages from 'messages';
import { Button, ButtonsGroup } from 'components/button';
import Icon from 'components/icon/Icon';
import { PanelBody, PanelHeader } from 'components/panel';
import {
  PanelTitle,
  PanelTitleButton,
  PanelTitleWrapper
} from 'components/panel/panel-title';
import PanelTools from 'components/panel/PanelTools';
import Modal from 'components/modal/Modal';
import BreadcrumbPath from 'containers/partials/misc/BreadcrumbPath';
import Loader from 'components/loader/Loader';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import ExercisesListVirtualized from 'containers/partials/lists/ExercisesListVirtualized';
import { Tab, TabList, TabPanel, Tabs } from 'components/tabs';
import Badge from 'components/badge/Badge';
import VideoFilterPanel from 'containers/pages/videos/VideoFilterPanel';
import { useVideosContext } from 'contexts/VideosContext';
import VideosSharedWithMe from 'containers/pages/videos/VideosSharedWithMe';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { StoreContext } from 'index';
import { MenuWrapper } from 'components/menu';
import {
  MUTATION_ADD_EXERCISES_TO_TEMPLATES,
  QUERY_GET_TEMPLATES
} from 'services/aws/templates-query';
import { QUERY_GET_EXERCISES } from 'services/aws/exercises-query';
import { useNotificationQueue } from 'components/notification';
import AddVideosToLessonModal from 'containers/pages/videos/AddVideosToLessonModal';
import FieldInput from 'components/input/FieldInput';

const Videos = ({ active, videoId, entityId, crumbs }) => {
  const {
    authStore: {
      user,
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const [tabIndex, setTabIndex] = useState(0);
  const notification = useNotificationQueue();
  const { push } = useHistory();
  const ability = useAbility(AbilityContext);
  const { videosState, videoActions, loading, error } = useVideosContext();
  const [addToCollectionModal, setAddToCollectionModal] = useState(null);
  const intl = useIntl();
  const [filterPanel, setFilterPanel] = useState(false);

  const [addExercises] = useMutation(MUTATION_ADD_EXERCISES_TO_TEMPLATES, {
    refetchQueries: [
      { query: QUERY_GET_TEMPLATES, variables: { entityId } },
      { query: QUERY_GET_EXERCISES, variables: { entityId } }
    ]
  });

  const addToCollectionHandler = () => {
    setAddToCollectionModal(true);
  };

  const onAddExercisesToCollection = async collections => {
    await addExercises({
      variables: {
        exerciseIds: videosState.selectedVideos.map(e => e.id),
        programTemplateIds: collections
      }
    }).then(
      () => {
        notification.add('add_exercises', {
          message: intl.formatMessage(messages.messageExercisesAddedToTemplate)
        });
        setAddToCollectionModal(false);
        videoActions.setSelectedVideos([]);
      },
      () => {
        notification.add('edit_error', {
          message: intl.formatMessage(
            messages.messageExercisesAddedToTemplateError
          ),
          level: 'error'
        });
      }
    );
  };

  const onSearch = e => {
    videoActions.setFilter({ title: e.target.value });
  };

  const onClosePanel = () => setFilterPanel(false);

  if (loading)
    return (
      <Panel active={active}>
        <Loader />
      </Panel>
    );
  if (error) return <RetryPanel />;

  return (
    <Panel active={active}>
      <PanelHeader>
        <BreadcrumbPath crumbs={crumbs} />
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.videosTitle} />
            {user.hasAdminRole() && (
              <MenuWrapper trigger={<PanelTitleButton />}>
                <Button
                  menu
                  onClick={addToCollectionHandler}
                  disabled={
                    !videosState.selectedVideos ||
                    videosState.selectedVideos.length <= 0
                  }
                >
                  {videosState.selectedVideos &&
                  videosState.selectedVideos.length > 0 ? (
                    <>
                      <Icon
                        id="add"
                        strokeColor="color-neutral-dark"
                        style={{ top: 'auto' }}
                      />

                      <FormattedMessage
                        {...messages.collectionAddExercisesToTemplateButton}
                        values={{
                          thisMany: videosState.selectedVideos.length,
                          type: 'video'
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Icon id="add" style={{ top: 'auto', opacity: 0.35 }} />

                      <FormattedMessage
                        {...messages.collectionAddExercisesToTemplateDisabled}
                        values={{
                          type: user.isGSVOrg ? 'collection' : 'template'
                        }}
                      />
                    </>
                  )}
                </Button>
              </MenuWrapper>
            )}
          </PanelTitle>
        </PanelTitleWrapper>
        <PanelTools extraClassNames="u-margin-bottom-none">
          <FieldInput
            rounded
            icon="search-grey"
            iconLeft
            useNative
            value={videosState.filter.title ?? ''}
            onChange={onSearch}
          />
          <ButtonsGroup extraClassNames="u-margin-bottom-small">
            <Button rounded light onClick={() => setFilterPanel(true)}>
              <Icon id="filter" strokeColor="color-neutral-dark" />
              <FormattedMessage {...messages.filterButton} />
            </Button>
          </ButtonsGroup>
          {/*{!loading && (
            <Tags
              extraClassNames="u-margin-left-none"
              entityId={user.rootEntityId}
              label={intl.formatMessage(messages.exercisesLabelTags)}
              tags={videosState.filter.tags}
              isMulti
              isClearable={false}
              dropdownDisabled
              onChange={handleTagChange}
              placeholder={intl.formatMessage(
                messages.exercisesProgramTagsLabel
              )}
              noOptionsMessage={intl.formatMessage(
                messages.exercisesProgramTagsNoOption
              )}
              rounded
            />
          )}*/}
        </PanelTools>
      </PanelHeader>
      <PanelBody>
        <Tabs defaultIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <TabList>
            {ability.can('create', 'Video') && (
              <Tab key="1">
                <Badge>{videosState.videos.length}</Badge>
                <FormattedMessage {...messages.videosTabMyVideos} />
              </Tab>
            )}
            <Tab key="2">
              <Badge>{videosState.sharedVideos.length}</Badge>
              <FormattedMessage {...messages.videosTabSharedWithMe} />
            </Tab>
          </TabList>
          {ability.can('create', 'Video') && (
            <TabPanel
              key="1"
              className="react-tabs__tab-panel u-padding-vertical-none"
            >
              <ExercisesListVirtualized
                checkable={user.hasAdminRole()}
                statusIcon="play"
                exercises={videosState.videos}
                activeId={videoId}
                enablePreviewModal={false}
                onClick={id => {
                  if (id !== videoId) {
                    push(
                      generatePath(ROUTE_VIDEOS_ENTITY_VIDEO, {
                        entityId,
                        videoId: id
                      })
                    );
                  }
                }}
                onSelect={exerciseIds => {
                  videoActions.setSelectedVideos(
                    videosState.videos.filter(e => exerciseIds.includes(e.id))
                  );
                }}
                showSharesAndAnnotations={true}
              />
            </TabPanel>
          )}
          <TabPanel
            key="2"
            className="react-tabs__tab-panel u-padding-vertical-none"
          >
            <VideosSharedWithMe entityId={rootEntityId} videoId={videoId} />
          </TabPanel>
        </Tabs>
      </PanelBody>

      {filterPanel && (
        <Modal filter isOpen={true} onClose={onClosePanel}>
          <VideoFilterPanel
            entityId={entityId}
            onCancel={onClosePanel}
            onSubmit={() => {
              onClosePanel();
            }}
            {...filterPanel}
          />
        </Modal>
      )}
      {addToCollectionModal && (
        <AddVideosToLessonModal
          entityId={entityId}
          exercises={videosState.selectedVideos}
          onAdd={selected => onAddExercisesToCollection(selected)}
          onClose={() => {
            setAddToCollectionModal(false);
          }}
        />
      )}
    </Panel>
  );
};

export default observer(Videos);
