import { useEffect, useReducer } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PROGRAM_EXERCISE_FEEDBACK } from 'services/aws/programs-query';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import {
  MUTATION_ADD_NOTE,
  MUTATION_ARCHIVE_NOTE,
  MUTATION_EDIT_NOTE
} from 'services/aws/notes-query';
import { NoteTypes } from 'constants.js';

const actions = {
  LOADING: 'LOADING',
  ERROR: 'ERROR'
};

function reducer(state, action) {
  switch (action.type) {
    case [actions.LOADING]:
      return { ...state, loading: true };
    case [actions.ERROR]:
      return { ...state, loading: false, error: action.payload };
    case 'set':
      return { ...state, loading: false, feedback: action.payload };
    case 'setDone':
      return { ...state, loading: false, done: action.payload };
    case 'add':
      return {
        ...state,
        loading: false,
        feedback: [...state.feedback, action.payload]
      };
    case 'edit':
      return {
        ...state,
        loading: false,
        feedback: state.feedback.map(item => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        })
      };
    case 'remove':
      return {
        ...state,
        loading: false,
        feedback: state.feedback.filter(item => item.id !== action.payload)
      };
    default:
      return state;
  }
}

function useProgramFeedback({ programId, userId }) {
  const initialState = {
    loading: false,
    error: null,
    feedback: [],
    done: []
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const { data, loading, error } = useQuery(GET_PROGRAM_EXERCISE_FEEDBACK, {
    variables: { programId: programId, personId: userId },
    skip: !programId && !userId
  });

  const [addNote] = useMutation(MUTATION_ADD_NOTE);
  const [editNote] = useMutation(MUTATION_EDIT_NOTE);
  const [archiveNote] = useMutation(MUTATION_ARCHIVE_NOTE);

  useEffect(() => {
    dispatch({ type: actions.LOADING, loading });
    if (data?.getExerciseProgramExerciseFeedback) {
      dispatch({
        type: 'set',
        payload: sort(
          data.getExerciseProgramExerciseFeedback.filter(
            f => f.noteType === NoteTypes.FEEDBACK
          ),
          {
            keys: [
              {
                key: 'date_created',
                desc: true,
                dataType: SORT_DATA_TYPES.NUMBER
              }
            ]
          }
        )
      });
      dispatch({
        type: 'setDone',
        payload: sort(
          data.getExerciseProgramExerciseFeedback.filter(
            f => f.noteType === NoteTypes.DONE
          ),
          {
            keys: [
              {
                key: 'date_created',
                desc: true,
                dataType: SORT_DATA_TYPES.NUMBER
              }
            ]
          }
        )
      });
    }
  }, [data]);

  useEffect(() => {
    dispatch({ type: actions.ERROR, error });
  }, [error]);

  const addFeedback = async ({ note, noteType, linkId, linkId2 }) => {
    dispatch({ type: actions.LOADING });
    const { data } = await addNote({
      variables: {
        note,
        noteType,
        linkId,
        linkId2,
        linkId3: userId
      },
      refetchQueries: [
        {
          query: GET_PROGRAM_EXERCISE_FEEDBACK,
          variables: { programId: programId, personId: userId }
        }
      ]
    });
    dispatch({
      type: 'add',
      payload: { ...data.addNote }
    });

    return data.addNote;
  };

  const editFeedback = async ({ id, note }) => {
    dispatch({ type: actions.LOADING });
    const { data } = await editNote({
      variables: {
        id,
        note
      }
    });
    dispatch({ type: 'edit', payload: { ...data.editNote } });
  };

  const archiveFeedback = async ({ noteId }) => {
    dispatch({ type: actions.LOADING });
    await archiveNote({
      variables: {
        noteId
      },
      refetchQueries: [
        {
          query: GET_PROGRAM_EXERCISE_FEEDBACK,
          variables: { programId: programId, personId: userId }
        }
      ]
    });
    dispatch({ type: 'remove', payload: noteId });
  };

  const markAsDone = async () => {
    dispatch({ type: actions.LOADING });
    const { data } = await addNote({
      variables: {
        noteType: NoteTypes.DONE,
        linkId: programId,
        linkId3: userId
      },
      refetchQueries: [
        {
          query: GET_PROGRAM_EXERCISE_FEEDBACK,
          variables: { programId: programId, personId: userId }
        }
      ]
    });
    dispatch({
      type: 'add',
      payload: { ...data.addNote }
    });

    return data.addNote;
  };

  return [state, { addFeedback, editFeedback, archiveFeedback, markAsDone }];
}

export default useProgramFeedback;
