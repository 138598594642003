import { useState, useEffect } from 'react';
import ExerciseModel from 'models/Exercise';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_EXERCISE } from 'services/aws/exercises-query';

const useExercise = (entityId, exerciseId) => {
  const [exercise, setExercise] = useState(new ExerciseModel({}));
  const [getExercise, { loading, error, data }] = useLazyQuery(
    QUERY_GET_EXERCISE,
    {
      variables: { id: exerciseId, entityId },
      skip: !exerciseId
    }
  );

  useEffect(() => {
    if (data?.getExercise) {
      const exerciseData = data.getExercise;
      setExercise(
        new ExerciseModel({
          ...exerciseData,
          thumbFileId: exerciseData?.thumb?.id,
          videoFileId: exerciseData?.video?.id,
          entityId
        })
      );
    }
  }, [data, exerciseId, entityId]);

  return { exercise, loading, error, getExercise };
};

export default useExercise;
