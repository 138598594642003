import { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { getFilteredEntities } from 'utils/search';
import { QUERY_GET_EXERCISES_BY_PERSON } from 'services/aws/exercises-query';
import Exercise from 'models/Exercise';

const useMyExercises = (entityId, user) => {
  let myExercises = useRef();
  const [totalMyExercises, setTotalExercises] = useState(0);
  const [exercises, setExercises] = useState([]);
  const [filter, setFilter] = useState({ search: '' });

  const { loading, error, data } = useQuery(QUERY_GET_EXERCISES_BY_PERSON, {
    variables: { entityId },
    skip: !user.isAthlete()
  });

  useEffect(() => {
    if (data?.getExercisesByPersonId) {
      const exercises = data.getExercisesByPersonId.map(
        exercise => new Exercise({ ...exercise })
      );
      myExercises.current = exercises;
      setTotalExercises(exercises.length);
      setExercises(exercises);
    }
  }, [data]);

  useEffect(() => {
    if (myExercises?.current?.length) {
      const filteredExercises = getFilteredEntities(
        myExercises.current,
        filter.search ?? '',
        filter.tags ?? null
      );
      setExercises([...filteredExercises]);
    }
  }, [filter]);

  return { exercises, totalMyExercises, filter, setFilter, loading, error };
};

export default useMyExercises;
