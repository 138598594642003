import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { QUERY_GET_TESTSETS_LIST } from 'services/aws/testsets-query';
import { useLazyQuery } from '@apollo/client';

function useTestSets({ entityId }) {
  const [testSets, setTestSets] = useState();
  const [getTestSets, { loading, error, data }] = useLazyQuery(
    QUERY_GET_TESTSETS_LIST
  );

  useEffect(() => {
    if (data?.getTestSetsV2) {
      setTestSets(data.getTestSetsV2);
    }
  }, [data]);

  const fetchTestSets = async ({ types }) => {
    try {
      const result = await getTestSets({
        variables: { types, entityId },
        fetchPolicy: 'cache-and-network'
      });
      if (result.data?.getTestSetsV2) {
        setTestSets(result.data.getTestSetsV2);
        return result.data.getTestSetsV2;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return { testSets, loading, error, fetchTestSets };
}

export default useTestSets;
