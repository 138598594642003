import { useContext, useState, useEffect } from 'react';
import { withRouter, generatePath } from 'react-router-dom';
import {
  Panel,
  PanelBody,
  PanelHeader,
  PanelSubtitle,
  PanelTitle,
  PanelTitleButton,
  PanelTitleButtonsGroup,
  PanelTitleWrapper
} from 'components/panel';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import {
  ROUTE_SESSIONS_ENTITY,
  ROUTE_PREVENTION_ENTITY_ADD_PREVENTION_SPORTERS,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS,
  ROUTE_SESSIONS_ENTITY_SESSION
} from 'routes/RouteList';
import { Tab, TabList, TabPanel, Tabs } from 'components/tabs';
import { FormattedMessage } from 'react-intl';
import { StoreContext } from 'index';
import { getLocalDateFormat } from 'utils/date';
import useTabIndex from 'hooks/utils/useTabIndex';
import PreventionDetailCard from 'containers/partials/cards/PreventionDetailCard';
import NoSportersAddedCard from 'containers/partials/cards/NoSportersAddedCard';
import PreventionSelectedTestsCard from './PreventionSelectedTestsCard';
import PreventionSelectTestsCard from './PreventionSelectTestsCard';
import { useSessionContext } from 'contexts/SessionContext';
import Loader from 'components/loader/Loader';
import { capitalize } from 'utils/string';
import Badge from 'components/badge/Badge';
import PreventionSelectInjuriesCard from './PreventionSelectInjuriesCard';
import SessionForm from './SessionForm';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import { MenuWrapper } from 'components/menu';
import RemoveSessionButton from 'containers/partials/buttons/RemoveSessionButton';
import Session from 'models/Session';
import AddPreventionModal from './AddPreventionModal';
import AddExtraTestsModal from './AddExtraTestsModal';
import { useTestsContext } from 'contexts/TestsContext';
import { TestSetV2Type } from 'constants.js';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';

const EditPrevention = ({ entityId, sessionId }) => {
  const ability = useAbility(AbilityContext);
  const {
    routing: { push }
  } = useContext(StoreContext);
  const { session, loading } = useSessionContext();
  const { testActions } = useTestsContext();
  const [preventionModal, setPreventionModal] = useState(null);
  const [addExtraTestsModal, setAddExtraTestsModal] = useState(null);
  const [edit, setEdit] = useState(true);
  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_PREVENTION_ENTITY_ADD_PREVENTION_SPORTERS
  ]);

  useEffect(() => {
    testActions.getTests();
  }, []);

  useEffect(() => {
    testActions.setSelectedTests(session.tests);
  }, [session.tests]);

  const onGlobalClick = () => {
    push(
      generatePath(ROUTE_SESSIONS_ENTITY_SESSION, {
        entityId,
        sessionId: session.id
      })
    );
  };

  const onSportersClick = () => {
    push(
      generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS, {
        entityId,
        sessionId: session.id
      })
    );
  };

  const onUseTemplateHandler = async template => {
    const { id, ...sessionTemplate } = {
      ...template,
      name: template.title,
      notes: template.note
    };
    setPreventionModal(new Session({ ...sessionTemplate }));
  };

  const onAddExtraTestsHandler = tests => {
    testActions.addExtraTest(tests);
    setAddExtraTestsModal(false);
  };

  if (loading && !session) return <Loader />;

  return (
    <Panel secondary extraClassNames="o-flex o-flex--column">
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb to={generatePath(ROUTE_SESSIONS_ENTITY, { entityId })}>
            <FormattedMessage {...messages.breadcrumbSessions} />
          </Breadcrumb>
          <Breadcrumb
            to={generatePath(ROUTE_SESSIONS_ENTITY_SESSION, {
              entityId,
              sessionId
            })}
          >
            <FormattedMessage
              {...messages.breadcrumbSession}
              values={{ session: session.name }}
            />
          </Breadcrumb>
          <Breadcrumb>
            <FormattedMessage {...messages.breadcrumbEditPrevention} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            {session.name}
            <PanelTitleButtonsGroup>
              <MenuWrapper trigger={<PanelTitleButton />}>
                {ability.can('delete', 'Sessions') && (
                  <RemoveSessionButton
                    menu
                    entityId={entityId}
                    sessionId={session.id}
                    onComplete={() =>
                      push(ROUTE_SESSIONS_ENTITY.replace(':entityId', entityId))
                    }
                  >
                    <FormattedMessage
                      {...messages.menuItemSessionRemoveSession}
                    />
                  </RemoveSessionButton>
                )}
                <Button menu onClick={() => onUseTemplateHandler(session)}>
                  <Icon id="download" />
                  <FormattedMessage
                    {...messages.preventionMenuItemUseTemplate}
                  />
                </Button>
              </MenuWrapper>
            </PanelTitleButtonsGroup>
          </PanelTitle>
          <PanelSubtitle>
            {session?.sport && capitalize(session.sport.title)} -{' '}
            {session?.preventionType} - {getLocalDateFormat(session?.startdate)}
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>

      <SessionForm
        entityId={entityId}
        session={session}
        extraClassNames={'c-panel__body c-panel__body--full-width'}
      >
        {({ values }) => (
          <Tabs
            fullWidth
            selectedIndex={tabIndex}
            onSelect={index => setTabIndex(index)}
          >
            <TabList>
              <Tab key="prevention.global" onClick={onGlobalClick}>
                <FormattedMessage {...messages.preventionTabGlobal} />
              </Tab>
              <Tab key="prevention.sporters" onClick={onSportersClick}>
                <Badge>
                  {(session?.personEntities &&
                    session?.personEntities.length) ||
                    0}
                </Badge>
                <FormattedMessage {...messages.preventionTabSporters} />
              </Tab>
            </TabList>
            <TabPanel key="prevention.global">
              <Panel>
                <PanelBody style={{ height: '100%' }}>
                  <PreventionDetailCard
                    edit={edit}
                    onEdit={edit => setEdit(edit)}
                  />
                  {values.preventionType === 'full' && (
                    <PreventionSelectInjuriesCard
                      entityId={entityId}
                      edit={edit}
                      onEdit={edit => setEdit(edit)}
                    />
                  )}
                  <PreventionSelectTestsCard
                    edit={edit}
                    onEdit={edit => setEdit(edit)}
                  />
                </PanelBody>
              </Panel>
              <Panel>
                <PanelBody>
                  {
                    session.personEntities.length <= 0 && (
                      <NoSportersAddedCard
                        entityId={entityId}
                        session={session}
                      />
                    ) // !isTemplate &&
                  }
                  <PreventionSelectedTestsCard
                    tests={session.tests}
                    edit={edit}
                    onEdit={edit => setEdit(edit)}
                    editPanel={true}
                    onUse={onUseTemplateHandler}
                  >
                    {edit && (
                      <div className="o-flex o-flex--justify-end">
                        <Button
                          small
                          ghost
                          onClick={() => setAddExtraTestsModal(session)}
                        >
                          <Icon id="add" />
                          <FormattedMessage
                            {...messages.preventionAddExtraTestsButton}
                          />
                        </Button>
                      </div>
                    )}
                  </PreventionSelectedTestsCard>
                </PanelBody>
              </Panel>

              {addExtraTestsModal && (
                <AddExtraTestsModal
                  entityId={entityId}
                  session={session}
                  onClose={() => {
                    setAddExtraTestsModal(false);
                  }}
                  onAdd={tests => onAddExtraTestsHandler(tests)}
                />
              )}
            </TabPanel>
            <TabPanel key="prevention.sporters">
              {/* Links to Session sporter view */}
            </TabPanel>
          </Tabs>
        )}
      </SessionForm>

      {preventionModal && (
        <AddPreventionModal
          type={TestSetV2Type.PREVENTION}
          entityId={entityId}
          session={preventionModal}
          isTemplate={false}
          onClose={() => {
            setPreventionModal(false);
          }}
        />
      )}
    </Panel>
  );
};

export default withRouter(EditPrevention);
