import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';

import Overview from 'containers/pages/user/programs/Overview';

import {
  ROUTE_EXERCISES_ENTITY_EXERCISE,
  ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_EXERCISE,
  ROUTE_USER_ENTITY_PROGRAMS_PROGRAM,
  ROUTE_USER_ENTITY_PROGRAMS,
  ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_FEEDBACK
} from 'routes/RouteList';
import Panel from 'components/panel/Panel';
import messages from 'messages';
import { PersonContextProvider } from 'contexts/PersonContext';
import { StoreContext } from 'index';
import ProgramExercises from 'containers/pages/user/programs/ProgramExercises';
import ProgramExercise from 'containers/pages/user/programs/ProgramExercise';
import { ProgramProvider } from 'contexts/ProgramContext';

const UserPrograms = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();

  return (
    <PersonContextProvider>
      <ProgramProvider user={user}>
        <Switch>
          <Route
            exact
            path={[
              ROUTE_USER_ENTITY_PROGRAMS,
              ROUTE_USER_ENTITY_PROGRAMS_PROGRAM,
              ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_FEEDBACK
            ]}
            render={({ match }) => (
              <Overview
                entityId={match.params.entityId}
                active={ROUTE_USER_ENTITY_PROGRAMS === match.path}
              />
            )}
          />
          <Route
            exact
            path={[ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_EXERCISE]}
            render={({ match }) => (
              <ProgramExercises
                entityId={match.params.entityId}
                programId={match.params.programId}
                exerciseId={match.params.exerciseId}
                active={false}
              />
            )}
          />
          <Redirect
            to={generatePath(ROUTE_USER_ENTITY_PROGRAMS, {
              entityId: user.rootEntityId
            })}
          />
        </Switch>
        <Route
          exact
          path={[ROUTE_USER_ENTITY_PROGRAMS]}
          render={() => (
            <Panel
              emptyState
              emptyLabel={intl.formatMessage(messages.panelSportersEmpty)}
            />
          )}
        />
        <Switch>
          <Route
            exact
            path={[
              ROUTE_USER_ENTITY_PROGRAMS_PROGRAM,
              ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_FEEDBACK
            ]}
            render={({ match }) => (
              <ProgramExercises
                entityId={match.params.entityId}
                programId={match.params.programId}
                active={ROUTE_USER_ENTITY_PROGRAMS === match.path}
              />
            )}
          />
          <Route
            exact
            path={ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_EXERCISE}
            render={({ match }) => (
              <ProgramExercise
                programId={match.params.programId}
                exerciseId={match.params.exerciseId}
                entityId={match.params.entityId}
                active={ROUTE_EXERCISES_ENTITY_EXERCISE === match.path}
                edit={false}
              />
            )}
          />
        </Switch>
      </ProgramProvider>
    </PersonContextProvider>
  );
};

export default UserPrograms;
