import { useContext } from 'react';
import { useIntl } from 'react-intl';
import messages from 'messages';
import InputWrapper from 'components/input/InputWrapper';
import { BenchmarkTypes, BenchmarkFilters } from 'constants.js';
import { StoreContext } from 'index';
import { useResultsPanelContext } from 'contexts/ResultsPanelContext';
import TestsetFieldSelect from 'containers/partials/inputs/TestsetFieldSelect';
import FieldSelect from 'components/input/FieldSelect';
import BenchmarksFieldSelect from 'containers/partials/inputs/BenchmarksFieldSelect';

const ResultsSettingsView = ({ testSetId }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();
  const {
    onChangeTestSet,
    onChangeType,
    onChangeBenchmark,
    onChangeBenchmarkFilter,
    type,
    benchmarkId,
    benchmarkFilter,
    testSets
  } = useResultsPanelContext();

  const BenchmarkTypeOptions = [
    {
      value: BenchmarkTypes.DEFAULT,
      label: intl.formatMessage(messages.benchmarksSettingsTypeBenchmark)
    },
    {
      value: BenchmarkTypes.AVERAGE,
      label: intl.formatMessage(messages.benchmarksSettingsTypeAverage)
    }
  ];
  const benchmarkFilterOptions = [
    {
      value: BenchmarkFilters.BEST,
      label: intl.formatMessage(messages.benchmarksSettingsFilterBest)
    },
    {
      value: BenchmarkFilters.LAST,
      label: intl.formatMessage(messages.benchmarksSettingsFilterLast)
    },
    {
      value: BenchmarkFilters.AVERAGE,
      label: intl.formatMessage(messages.benchmarksSettingsFilterAverage)
    }
  ];

  return (
    <>
      <div className="o-layout__item u-1-of-4-at-large u-1-of-3-at-medium u-1-of-2-at-small u-1-of-1">
        <InputWrapper>
          <FieldSelect
            label={intl.formatMessage(messages.benchmarksSettingsType)}
            value={BenchmarkTypeOptions.find(o => o.value === type)}
            options={BenchmarkTypeOptions}
            onChange={bt => onChangeType(bt.value)}
          />
        </InputWrapper>
      </div>

      {user.isHylyghtOrg &&
        // testSetId !== 'undefined' &&
        // testSets.find(t => t.id === testSetId).subtype !== 'han' &&
        // testSets.find(t => t.id === testSetId).subtype !== 'ido' &&
        // testSets.find(t => t.id === testSetId).subtype !== 'badminton' &&
        type === BenchmarkTypes.DEFAULT && (
          <div className="o-layout__item u-1-of-4-at-large u-1-of-3-at-medium u-1-of-2-at-small u-1-of-1">
            <InputWrapper>
              <BenchmarksFieldSelect
                label={intl.formatMessage(messages.labelSessionBenchmark)}
                entityId={user.entityId}
                value={benchmarkId}
                benchmarkId={benchmarkId}
                onChange={benchmark =>
                  onChangeBenchmark(benchmark?.id ?? 'default')
                }
                emptyOption={intl.formatMessage(
                  messages.benchmarksSettingsSelectDefault
                )}
              />
            </InputWrapper>
          </div>
        )}
      <div className="o-layout__item u-1-of-4-at-large u-1-of-3-at-medium u-1-of-2-at-small u-1-of-1">
        <InputWrapper>
          <FieldSelect
            value={benchmarkFilterOptions.find(
              o => o.value === benchmarkFilter
            )}
            onChange={bt => onChangeBenchmarkFilter(bt.value)}
            options={benchmarkFilterOptions}
            label={intl.formatMessage(messages.benchmarksSettingsFilter)}
          />
        </InputWrapper>
      </div>

      <div className="o-layout__item u-1-of-4-at-large u-1-of-3-at-medium u-1-of-2-at-small u-1-of-1">
        <InputWrapper>
          <TestsetFieldSelect
            name={'testSetId'}
            hasLabel={false}
            entityId={user.entityId}
            testSets={testSets}
            value={testSetId}
            onChange={selectedItem => onChangeTestSet(selectedItem?.id)}
            isClearable
            label={intl.formatMessage(messages.benchmarksSettingsPackage)}
          />
        </InputWrapper>
      </div>
    </>
  );
};

export default ResultsSettingsView;
