import React, { useContext, useEffect, useState } from 'react';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import { CardBody, CardFooter } from 'components/card';
import FieldInput from 'components/input/FieldInput';
import { FieldDatePicker } from 'components/input/FieldDatePicker';
import TestsetSelect from 'containers/partials/inputs/TestsetSelect';
import { DefaultBenchmark, PackageTypes } from 'constants.js';
import BenchmarksSelect from 'containers/partials/inputs/BenchmarksSelect';
import LanguageSelect from 'components/input/LanguageSelect';
import ScannersSelect from 'containers/partials/inputs/ScannersSelect';
import { FieldTextarea } from 'components/input/FieldTextarea';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import { ROUTE_SESSIONS_ENTITY } from 'routes/RouteList';
import { useFormikContext } from 'formik';
import { StoreContext } from 'index';
import { useSessionContext } from 'contexts/SessionContext';
import { useHistory } from 'react-router-dom';

function SessionFormInput({ entityId, edit, setEdit }) {
  const {
    authStore: { user },
    uiState: { locale }
  } = useContext(StoreContext);
  const intl = useIntl();
  const { push } = useHistory();
  const {
    values,
    errors,
    touched,
    setFieldValue,
    submitForm,
    handleReset,
    handleChange,
    isSubmitting
  } = useFormikContext();
  const { session } = useSessionContext();
  const [isMTSType, setIsMTSType] = useState(false);
  const [isILike, setIsILike] = useState(false);

  useEffect(() => {
    if (session?.id) {
      setIsILike(session.isILike);
    }
  }, [session]);

  return (
    <>
      <CardHeader secondary>
        <CardHeaderTitle>
          <FormattedMessage {...messages.titleSessionDetails} />
        </CardHeaderTitle>
        <FormEditHeaderButtons
          type={'submit'}
          edit={edit}
          setEdit={setEdit}
          onCancel={() =>
            push(ROUTE_SESSIONS_ENTITY.replace(':entityId', entityId))
          }
          onSubmit={submitForm}
          disabledSubmit={isSubmitting}
        />
      </CardHeader>
      <CardBody secondary>
        <div className="o-layout o-layout--tiny">
          <div className="o-layout__item">
            <FieldInput
              id="name"
              name="name"
              errors={errors}
              touched={touched}
              readOnly={!edit}
            >
              <FormattedMessage {...messages.labelSessionName} />*
            </FieldInput>
          </div>
          <div className="o-layout__item u-1-of-2-at-small">
            <FieldDatePicker
              id="startdate"
              name="startdate"
              icon="calendar"
              locale={locale}
              errors={errors}
              touched={touched}
              readOnly={!edit}
              disabled={!edit}
              onChange={handleChange}
              required
            >
              <FormattedMessage {...messages.labelSessionDate} />*
            </FieldDatePicker>
          </div>

          {!user.isMTSOrg && (
            <div className="o-layout__item u-1-of-2-at-small">
              <FieldInput
                id="location"
                name="location"
                errors={errors}
                touched={touched}
                readOnly={!edit}
              >
                <FormattedMessage {...messages.labelSessionLocation} />
              </FieldInput>
            </div>
          )}
          <div className="o-layout__item u-1-of-2-at-small">
            <TestsetSelect
              name={'testSetId'}
              entityId={entityId}
              value={values.testSetId}
              testSet={session?.testSets?.[0]}
              readOnly={!edit || values.finishedPercentage !== 0}
              disabled={!edit || values.finishedPercentage !== 0}
              onChange={selectedItem => {
                if (selectedItem) {
                  setIsMTSType(
                    selectedItem && selectedItem.type === PackageTypes.MTS
                  );
                  setFieldValue(
                    'testSetId',
                    selectedItem ? selectedItem.id : ''
                  );
                  setIsILike(selectedItem.isILike);
                  if (selectedItem?.defaultBenchmark) {
                    setFieldValue(
                      'benchmarkId',
                      selectedItem.defaultBenchmark.id
                    );
                    setFieldValue('benchmark', selectedItem.defaultBenchmark);
                  } else {
                    setFieldValue('benchmarkId', DefaultBenchmark);
                    setFieldValue('benchmark', null);
                  }
                }
              }}
              errors={errors}
              touched={touched}
            />
          </div>
          {user.isHylyghtOrg && !isILike && (
            <div className="o-layout__item u-1-of-2-at-small">
              <BenchmarksSelect
                entityId={entityId}
                value={values?.benchmark?.id || DefaultBenchmark}
                readOnly={!edit}
                disabled={!edit}
                onChange={selectedItem => {
                  setFieldValue(
                    'benchmarkId',
                    selectedItem ? selectedItem.id : ''
                  );
                }}
              />
            </div>
          )}
          {(isILike || session?.isILike) && (
            <div className="o-layout__item u-1-of-2-at-small">
              <LanguageSelect
                id="language"
                name="language"
                readOnly={!edit}
                label={intl.formatMessage(messages.labelUserLanguage)}
                possibleLanguages={user.getPossibleLanguages()}
                value={values.language}
                onSelect={language => {
                  setFieldValue('language', language.value);
                }}
              />
            </div>
          )}
          {isMTSType && (
            <div className="o-layout__item u-1-of-2-at-small">
              <ScannersSelect
                entityId={entityId}
                value={session.scannerId}
                onChange={selectedItem => {
                  setFieldValue(
                    'meta',
                    selectedItem ? { selectedItem } : undefined
                  );
                }}
              />
            </div>
          )}

          <div className="o-layout__item">
            <FieldTextarea
              id="notes"
              name="notes"
              rows="4"
              errors={errors}
              touched={touched}
              readOnly={!edit}
              resizeVertical={edit}
            >
              <FormattedMessage {...messages.labelSessionNotes} />
            </FieldTextarea>
          </div>
        </div>
      </CardBody>
      <CardFooter secondary>
        <FormEditFooterButtons
          edit={edit}
          setEdit={setEdit}
          onCancel={() =>
            push(ROUTE_SESSIONS_ENTITY.replace(':entityId', entityId))
          }
          onSubmit={submitForm}
          disabledSubmit={isSubmitting}
        />
      </CardFooter>
    </>
  );
}

export default SessionFormInput;
