import { createContext, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useLazyQuery } from '@apollo/client';
import { useParams, useRouteMatch } from 'react-router-dom';
import Session from '../models/Session';
import {
  QUERY_GET_SESSION_BY_ID,
  QUERY_GET_TESTDATA_BY_SESSION
} from 'services/aws/session-query';
import { DefaultBenchmark, reportOptions } from 'constants.js';
import { ROUTE_TESTS } from 'routes/RouteList';
import TestData from 'models/TestData';

export const SessionContext = createContext({});

const SessionContextProvider = ({ children }) => {
  const { entityId, sessionId } = useParams();

  /*const sessionRoute = useRouteMatch([
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
    ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT,
    ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT
  ]);*/
  const testRoute = useRouteMatch(ROUTE_TESTS) ?? null;

  const [session, setSession] = useState(new Session({}));
  const [options, setOptions] = useState({
    ...Object.assign(
      {},
      ...Object.values(reportOptions).map(option => {
        return (
          option.id !== 'advancedOptions' && {
            [option.id]: option.default
          }
        );
      })
    )
  });
  const [sessionTestData, setSessionTestData] = useState(undefined);

  const [getSession, { loading, error, data: sessionData, refetch }] =
    useLazyQuery(QUERY_GET_SESSION_BY_ID);

  const [getTestDataBySession] = useLazyQuery(QUERY_GET_TESTDATA_BY_SESSION);

  useEffect(() => {
    if (entityId && sessionId) {
      getSession({
        variables: { entityId, testSessionId: sessionId }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, sessionId]);

  useEffect(() => {
    if (sessionData?.getTestSession) {
      const testSession = new Session(sessionData.getTestSession);

      testSession.sport = null;

      let parsedTests = [
        ...parseTestSetTests(
          testSession.testSets
            .map(testSet => testSet.tests.map(test => test))
            .flat()
        )
      ];

      testSession.sport =
        testSession?.testSets && testSession.testSets.length > 0
          ? testSession?.testSets[0].sport
          : null;

      testSession.tests = parsedTests;

      setOptions({
        ...Object.assign(
          {},
          ...Object.values(reportOptions).map(option => {
            return (
              option.id !== 'advancedOptions' && {
                [option.id]: option.default
              }
            );
          })
        ),
        showScore: !(testSession.isPrevention || testSession.isRehab),
        benchmark: testSession.benchmark?.id ?? DefaultBenchmark
      });
      setSession(testSession);
    }
  }, [sessionData]);

  const fetchTestDataBySession = async testSession => {
    if (!testRoute) {
      return {};
    }
    if (testSession.id) {
      const { data } = await getTestDataBySession({
        variables: {
          testSessionId: testSession.id
        }
      });

      if (data?.getTestDataV2OfSession && testSession.tests) {
        const testDatas = [];
        data.getTestDataV2OfSession.forEach(td => {
          const testDataResult = new TestData(td);

          testDatas.push(testDataResult);
        });
        setSessionTestData(testDatas);

        return {};
      }
    }
  };

  return (
    <SessionContext.Provider
      value={{
        session,
        refetch,
        loading,
        error,
        options,
        setOptions,
        sessionTestData,
        fetchTestDataBySession
      }}
    >
      {typeof children === 'function' ? children({}) : children}
    </SessionContext.Provider>
  );
};

function useSessionContext() {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error(
      'The SessionContext hook must be used within a SessionContext.Provider'
    );
  }
  return context;
}

export { SessionContextProvider, useSessionContext };

const parseTestItem = testItem => {
  let sides;
  if (testItem?.laterality && testItem.laterality.includes('bilateral_')) {
    sides = {
      0: Array(testItem.trials).fill(''),
      1: Array(testItem.trials).fill('')
    };
  } else {
    sides = { 2: Array(testItem.trials).fill('') };
  }
  let inputParameters = {};
  try {
    if (testItem.input_type_parameters) {
      inputParameters = JSON.parse(testItem.input_type_parameters);
    }
  } catch (err) {
    Sentry.captureException(err);
    console.log('Error parsing input_type_parameters', err.message);
  }

  return {
    ...testItem,
    sides,
    input_type_parameters: inputParameters
  };
};

const parseTestSetTests = tests => {
  if (!tests) return [];

  return tests.map(test => {
    // find if it's a dependency
    const isDependency = tests.some(ts => {
      try {
        return ts.dependedTestIds?.includes(test.id);
      } catch (e) {
        return [];
      }
    });

    // TODO testen of dit werkt als een test geen testitems heeft
    const testItems = test.testItems
      ? test.testItems
          .filter(testItem => testItem.input_type !== 'derived')
          .map(testItem => parseTestItem(testItem))
      : [];

    return {
      ...test,
      name: test.title,
      isDependency,
      status: true,
      testItems
    };
  });
};
