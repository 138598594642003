import { Fragment, useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { PageHeader } from 'components/page-layout';
import PageTitle from 'components/page-layout/PageTitle';
import PageHeaderUser from 'components/page-layout/PageHeaderUser';
import { QUERY_GET_ORGANISATION_BY_ID } from 'services/aws/entity-query';
import Entity from 'models/Entity';
import Button from 'components/button/Button';
import { MenuWrapper, MenuHeader } from 'components/menu';
import Icon from 'components/icon/Icon';
import UpdateUserLanguageButton from '../buttons/UpdateUserLanguageButton';
import { Card } from 'components/card';
import messages from 'messages';
import { useQuery } from '@apollo/client';
import OrganisationTree from 'containers/partials/header/OrganisationTree';
import { StoreContext } from 'index';
import { EntityTypes } from 'constants.js';
import { useHistory } from 'react-router-dom';
import { ROUTE_SETTINGS } from 'routes/RouteList';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import classNames from 'classnames';

const Header = props => {
  const {
    authStore,
    authStore: {
      user,
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const ability = useAbility(AbilityContext);
  // const [activeTreeId, setActiveTreeId] = useState(null);
  const [hasTree, setHasTree] = useState(false);
  const [entity, setEntity] = useState([]);

  const { data } = useQuery(QUERY_GET_ORGANISATION_BY_ID, {
    variables: { id: rootEntityId },
    skip: !rootEntityId
  });

  useEffect(() => {
    if (!user) return;

    if (data && data.getEntity) {
      const entity = new Entity(data.getEntity);
      setEntity(entity);

      const filteredOrganisations = user.entities.filter(
        userEntity => userEntity.entity.type === EntityTypes.ORGANISATION
      );
      setHasTree(filteredOrganisations.length > 1);
    }
  }, [user, data]);

  const logout = () => {
    authStore.doLogout();
  };

  const clearCache = () => {
    authStore.clearCache();
  };

  const onClickOpenDrawer = props.onClickOpenDrawer;
  const toggleMenu = () => {
    return onClickOpenDrawer();
  };

  return (
    <PageHeader fixed showSidebar={() => toggleMenu()}>
      {authStore.user && (
        <Fragment>
          {entity && (
            <PageTitle
              location={entity.info && entity.info.location}
              logo={entity.info && entity.info.logo}
            >
              {hasTree ? (
                <MenuWrapper
                  organisation
                  asModal
                  /*onStateChange={e => {
                    if (e.isOpen) {
                      setActiveTreeId(null);
                    }
                  }}*/
                  trigger={
                    <Button menuHeader>
                      {entity.name}
                      <Icon id="dropdown-open" />
                    </Button>
                  }
                >
                  <Card organisations={hasTree}>
                    <OrganisationTree entityId={rootEntityId} entity={entity} />
                  </Card>
                </MenuWrapper>
              ) : (
                <span className="c-page__title-title">{entity.name}</span>
              )}
            </PageTitle>
          )}

          <PageHeaderUser>
            <MenuWrapper
              trigger={
                <Button menuHeader>
                  {authStore.user.firstname}
                  <Icon id="dropdown-open" />
                </Button>
              }
              header={
                <MenuHeader
                  title={authStore.user.firstname}
                  subTitle={authStore.user.email}
                />
              }
            >
              <ul className="c-menu--lang o-list o-list-bare">
                {user.getPossibleLanguages().map(sp => {
                  return (
                    <li
                      key={sp.key}
                      className={classNames('c-menu--lang__item', {
                        'is-active': sp.key === user.getLanguage()
                      })}
                    >
                      <UpdateUserLanguageButton
                        personEntityId={user.getPersonEntityIdByEntityId()}
                        baseLanguage={sp.key}
                        currentLanguage={user.getLanguage()}
                        label={sp.language}
                      />
                    </li>
                  );
                })}
              </ul>
              {ability.can('manage', 'Account') && (
                <Button onClick={() => push(ROUTE_SETTINGS)} menu>
                  <Icon id="preferences" />
                  <FormattedMessage {...messages.headerAccount} />
                </Button>
              )}
              {process.env.REACT_APP_ERASE_CACHE_ENABLED === 'true' && (
                <Button onClick={() => clearCache()} menu>
                  Clear cache
                </Button>
              )}
              <Button onClick={() => logout()} menu>
                <Icon id="signout" />
                <FormattedMessage {...messages.headerSignOut} />
              </Button>
            </MenuWrapper>
          </PageHeaderUser>

          {/* LangSwitcher for Mobile */}
          <PageHeaderUser langMobile>
            <MenuWrapper
              trigger={
                <Button menuHeader>
                  {user.getLanguage()}
                  <Icon id="dropdown-open" />
                </Button>
              }
            >
              <ul className="c-menu--lang o-list o-list-bare">
                {user.getPossibleLanguages().map(sp => (
                  <li
                    key={sp.key}
                    className={`c-menu--lang__item${
                      sp.key === user.getLanguage() ? ' is-active' : ''
                    }`}
                  >
                    <UpdateUserLanguageButton
                      personEntityId={user.getPersonEntityIdByEntityId()}
                      baseLanguage={sp.key}
                      currentLanguage={user.getLanguage()}
                      label={sp.language}
                    />
                  </li>
                ))}
              </ul>
            </MenuWrapper>
          </PageHeaderUser>
        </Fragment>
      )}
    </PageHeader>
  );
};

export default observer(Header);
