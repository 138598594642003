import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Field, ErrorMessage, getIn, FormikContext } from 'formik';
import InputErrorMessage from './InputErrorMessage';
import { forwardRef, useContext } from 'react';

const FieldRange = forwardRef((props, ref) => {
  const {
    id,
    children,
    extraClassNames,
    inline,
    hasError,
    errorMessage,
    errors,
    autoFocus,
    autoComplete = 'off',
    disabled,
    max,
    maxLabel,
    min,
    minLabel,
    name,
    readOnly,
    step,
    title,
    style,
    value,
    onBlur,
    onChange,
    onMouseUp,
    innerRef
  } = props;
  const context = useContext(FormikContext);

  return (
    <div
      className={classNames('c-input__group', {
        'c-input--read-only': readOnly,
        'c-input--disabled': disabled,
        'c-input--inline': inline
      })}
      style={style}
    >
      {id && children && (
        <label htmlFor={id} className={classNames('c-input__label')}>
          {children}
        </label>
      )}

      <div className={classNames('c-input__wrapper o-flex')}>
        {minLabel ? (
          <span className="c-input__label c-input--range-extreme-values">
            {minLabel}
          </span>
        ) : (
          <span className="c-input__label c-input--range-extreme-values">
            {min}
          </span>
        )}

        {context ? (
          <Field
            id={id}
            className={classNames('c-input c-input--range', extraClassNames, {
              'has-error':
                props?.errors?.[name] &&
                props?.touched?.[name] &&
                typeof errors[name] === 'string'
            })}
            type={'range'}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            disabled={disabled}
            max={max}
            min={min}
            name={name}
            step={step}
            title={title}
            style={{ color: hasError ? 'red' : '' }}
            onBlur={onBlur}
            onMouseUp={onChange}
            onClick={e => e.stopPropagation()}
            innerRef={innerRef}
          />
        ) : (
          <input
            id={id}
            className={classNames('c-input c-input--range', extraClassNames, {
              'has-error':
                props?.errors?.[name] &&
                props?.touched?.[name] &&
                typeof errors[name] === 'string'
            })}
            type={'range'}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            disabled={disabled}
            max={max}
            min={min}
            name={name}
            step={step}
            title={title}
            style={{ color: hasError ? 'red' : '' }}
            onBlur={onBlur}
            onChange={onChange}
            onMouseUp={onMouseUp}
            onClick={e => e.stopPropagation()}
            ref={ref}
            value={value}
          />
        )}

        {maxLabel ? (
          <span className="c-input__label c-input--range-extreme-values">
            {maxLabel}
          </span>
        ) : (
          <span className="c-input__label c-input--range-extreme-values">
            {max}
          </span>
        )}
      </div>

      {errorMessage && <InputErrorMessage>{errorMessage}</InputErrorMessage>}

      {errors && errors[name] && typeof errors[name] === 'string' && (
        <ErrorMessage name={name} component={InputErrorMessage} />
      )}

      {errors && typeof errors[name] !== 'string' && getIn(errors, name) && (
        <InputErrorMessage>{getIn(errors, name)}</InputErrorMessage>
      )}
    </div>
  );
});

const inputPropTypes = {
  id: PropTypes.string,
  children: PropTypes.node,

  // CSS Classes added on wrapper
  extraClassNames: PropTypes.string, // also added in input ???
  inline: PropTypes.bool,
  // readOnly
  // disabled

  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  errors: PropTypes.any,

  type: PropTypes.string, // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#input_types

  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  max: PropTypes.number, // for numeric typesL: Max Value
  min: PropTypes.number, // for numeric types: Min Value
  name: PropTypes.string,
  pattern: PropTypes.string, // for password, text, tel
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool, // for text, search, url, tel, email, date, month, week, time, datetime-local, number, and password input types
  step: PropTypes.number, // for numeric types
  title: PropTypes.string,

  style: PropTypes.object,

  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func
};

FieldRange.propTypes = inputPropTypes;

export default FieldRange;
