import React, { useContext } from 'react';
import {
  Redirect,
  Route,
  Switch,
  generatePath,
  withRouter
} from 'react-router-dom';
import {
  ROUTE_EXERCISES_TRAININGS_ENTITY,
  ROUTE_EXERCISES_TRAININGS_ENTITY_ARCHIVED,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE_DETAILS
} from 'routes/RouteList';
import { ExercisesProvider } from 'contexts/ExercisesContext';
import { StoreContext } from 'index';
import { PreviewModalProvider } from 'contexts/PreviewModalContext';
import messages from 'messages';
import Panel from 'components/panel/Panel';
import { useIntl } from 'react-intl';
import Trainings from 'containers/pages/exercises/trainings/Trainings';
import Exercise from 'containers/pages/exercises/exercises/Exercise';
import Training from 'containers/pages/exercises/trainings/Training';
import { TemplateType } from 'constants.js';
import { TrainingsProvider } from 'contexts/TrainingsContext';

const GSVTrainings = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();
  return (
    <>
      <TrainingsProvider
        entityId={user.rootEntityId}
        type={TemplateType.TRAINING}
      >
        <PreviewModalProvider entityId={user.rootEntityId}>
          <ExercisesProvider user={user} entityId={user.rootEntityId}>
            <Switch>
              <Route
                exact
                path={[
                  ROUTE_EXERCISES_TRAININGS_ENTITY,
                  ROUTE_EXERCISES_TRAININGS_ENTITY_ARCHIVED
                ]}
                render={props => (
                  <ExercisesProvider
                    user={user}
                    entityId={props.match.params.entityId}
                  >
                    <Trainings
                      entityId={props.match.params.entityId}
                      active={
                        ROUTE_EXERCISES_TRAININGS_ENTITY === props.match.path ||
                        ROUTE_EXERCISES_TRAININGS_ENTITY_ARCHIVED ===
                          props.match.path
                      }
                    />
                    <Panel
                      emptyState
                      emptyLabel={intl.formatMessage(
                        messages.exercisesEmptyPanel
                      )}
                    />
                  </ExercisesProvider>
                )}
              />

              <Route
                exact
                path={[
                  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING,
                  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES,
                  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE,
                  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE_DETAILS
                ]}
                render={props => (
                  <>
                    <Trainings
                      entityId={props.match.params.entityId}
                      trainingId={props.match.params.trainingId}
                      active={
                        ROUTE_EXERCISES_TRAININGS_ENTITY === props.match.path
                      }
                    />
                    <Training
                      entityId={props.match.params.entityId}
                      active={
                        ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING ===
                        props.match.path
                      }
                      trainingId={props.match.params.trainingId}
                      exerciseId={props.match.params.exerciseId}
                    />
                    <Route
                      exact
                      path={[
                        ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE,
                        ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE_DETAILS
                      ]}
                      render={props => (
                        <Exercise
                          entityId={props.match.params.entityId}
                          trainingId={props.match.params.trainingId}
                          exerciseId={props.match.params.exerciseId}
                          active={
                            ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE ===
                            props.match.path
                          }
                          editable={false}
                          inTraining
                          parentPath={generatePath(
                            ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE,
                            {
                              entityId: props.match.params.entityId,
                              trainingId: props.match.params.trainingId,
                              exerciseId: props.match.params.exerciseId
                            }
                          )}
                          crumbs={[
                            {
                              path: generatePath(
                                ROUTE_EXERCISES_TRAININGS_ENTITY,
                                {
                                  entityId: props.match.params.entityId
                                }
                              ),
                              label: intl.formatMessage(
                                messages.breadcrumbTrainings
                              )
                            },
                            {
                              path: generatePath(
                                ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING,
                                {
                                  entityId: props.match.params.entityId,
                                  trainingId: props.match.params.trainingId
                                }
                              ),
                              label: intl.formatMessage(
                                messages.breadcrumbTraining
                              )
                            }
                          ]}
                        />
                      )}
                    />
                  </>
                )}
              />

              <Redirect
                to={generatePath(ROUTE_EXERCISES_TRAININGS_ENTITY, {
                  entityId: user.rootEntityId
                })}
              />
            </Switch>
          </ExercisesProvider>
        </PreviewModalProvider>
      </TrainingsProvider>
    </>
  );
};

export default withRouter(GSVTrainings);
