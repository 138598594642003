import { useEffect, useState } from 'react';
import { uid } from 'react-uid';
import { useFormikContext } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { FieldTextarea } from 'components/input/FieldTextarea';
import { InjuryCauseOptions, InjuryTypeOptions } from 'constants.js';
import FieldSelect from 'components/input/FieldSelect';
import FieldCheckbox from 'components/input/FieldCheckbox';
import classNames from 'classnames';
import { Accordion, AccordionContent } from 'components/accordion';
import AccordionButtonHooks from 'components/accordion/AccordionButtonHooks';

function InjuryDetailMoreFields({ edit, showMore = false }) {
  const intl = useIntl();

  const {
    values: personInjury,
    setFieldValue,
    touched,
    errors
  } = useFormikContext();

  const injuryCauseOptions = InjuryCauseOptions.map(o => ({
    value: o.value,
    label: o.label?.id ? intl.formatMessage(o.label) : o.label
  }));
  const injuryTypeOptions = InjuryTypeOptions.map(o => ({
    value: o.value,
    label: o.label?.id ? intl.formatMessage(o.label) : o.label
  }));

  const [openSecondaryAccordion, setOpenSecondaryAccordion] =
    useState(showMore);

  useEffect(() => {
    setOpenSecondaryAccordion(showMore);
  }, [showMore]);

  return (
    <Accordion switch>
      {() => (
        <>
          <AccordionButtonHooks
            id={`trigger-options-advanced-${uid(personInjury)}`}
            name="accordion-options-advanced"
            checkbox
            isControlled
            checked={openSecondaryAccordion}
            onChange={() => setOpenSecondaryAccordion(!openSecondaryAccordion)}
          >
            <FormattedMessage {...messages.rehabInjuryMoreInfo} />
          </AccordionButtonHooks>

          <AccordionContent extraClassNames="o-layout">
            {openSecondaryAccordion && (
              <>
                <div className="o-layout__item">
                  <FieldTextarea
                    id={`description_${uid(personInjury)}`}
                    name="description"
                    rows="3"
                    errors={errors}
                    touched={touched}
                    readOnly={!edit}
                    disabled={!edit}
                    resizeVertical={edit}
                  >
                    <FormattedMessage
                      {...messages.rehabInjuryDescriptionLabel}
                    />
                  </FieldTextarea>
                </div>

                <div className="o-layout__item u-1-of-2">
                  <FieldSelect
                    id={`typeOfInjury_${uid(personInjury)}`}
                    label={
                      intl.formatMessage(messages.rehabInjuryTypeLabel) + ' *'
                    }
                    placeholder={intl.formatMessage(
                      messages.rehabInjuryTypePlaceholder
                    )}
                    options={injuryTypeOptions}
                    value={injuryTypeOptions.find(
                      o => o.value === personInjury?.typeOfInjury
                    )}
                    onChange={selectedItem => {
                      if (!selectedItem?.value) {
                        setFieldValue('typeOfInjury', '');
                        return;
                      }
                      setFieldValue('typeOfInjury', selectedItem.value);
                    }}
                    errors={errors}
                    touched={touched}
                    readOnly={!edit}
                    //   disabled={!edit}
                    required
                  />
                </div>
                <div className="o-layout__item u-1-of-2">
                  <FieldSelect
                    id={`causeOfInjury_${uid(personInjury)}`}
                    label={
                      intl.formatMessage(messages.rehabInjuryCauseLabel) + ' *'
                    }
                    placeholder={intl.formatMessage(
                      messages.rehabInjuryCausePlaceholder
                    )}
                    options={injuryCauseOptions}
                    value={injuryCauseOptions.find(
                      o => o.value === personInjury?.causeOfInjury
                    )}
                    onChange={selectedItem => {
                      if (!selectedItem?.value) {
                        setFieldValue('causeOfInjury', '');
                        return;
                      }
                      setFieldValue('causeOfInjury', selectedItem.value);
                    }}
                    errors={errors}
                    touched={touched}
                    readOnly={!edit}
                    //   disabled={!edit}
                    required
                  />
                </div>

                <div className="o-layout__item">
                  <label
                    htmlFor={'relatedTo'}
                    className={classNames(
                      'c-input__label u-margin-bottom-tiny',
                      {}
                    )}
                  >
                    <FormattedMessage {...messages.rehabInjuryRelatedToLabel} />
                  </label>
                </div>
                <div className="o-layout__item u-margin-bottom-small o-flex o-flex--wrap">
                  <div className="c-input-checkbox--with-paragraph u-margin-bottom-small">
                    <FieldCheckbox
                      inputExtraClassNames="u-hidden-visually"
                      id={`relatedToSport_${uid(personInjury)}`}
                      name={`relatedToSport`}
                      readOnly={!edit}
                      secondary
                    >
                      <FormattedMessage
                        {...messages.rehabInjuryRelatedToSport}
                      />
                    </FieldCheckbox>
                  </div>

                  <div className="c-input-checkbox--with-paragraph u-margin-bottom-small">
                    <FieldCheckbox
                      inputExtraClassNames="u-hidden-visually"
                      id={`relatedToGrowth_${uid(personInjury)}`}
                      name={`relatedToGrowth`}
                      readOnly={!edit}
                      secondary
                    >
                      <FormattedMessage
                        {...messages.rehabInjuryRelatedToGrowth}
                      />
                    </FieldCheckbox>
                  </div>
                </div>
                <div className="o-layout__item">
                  <FieldTextarea
                    id={`extraInfo_${uid(personInjury)}`}
                    name="extraInfo"
                    rows="3"
                    errors={errors}
                    touched={touched}
                    readOnly={!edit}
                    disabled={!edit}
                    resizeVertical={edit}
                  >
                    <FormattedMessage {...messages.rehabInjuryExtraInfoLabel} />
                  </FieldTextarea>
                </div>
              </>
            )}
          </AccordionContent>
        </>
      )}
    </Accordion>
  );
}

export default InjuryDetailMoreFields;
