import { createContext, useState, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_PERSON_MEDICALS } from 'services/aws/injury-query';
import Medical from 'models/Medical';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import { S_QUERY_GET_SESSIONS_BY_ENTITY_ID } from 'services/aws/session-query';
import { TestSetV2Type } from 'constants.js';
import { parseTestSessionDataToModel } from 'models/Entity';

export const UserContext = createContext({});

const UserContextProvider = ({ entityId, personId, children }) => {
  const [sessions, setSessions] = useState(null);

  const [getSessions, { loading: loadingSessions, error: errorSessions }] =
    useLazyQuery(S_QUERY_GET_SESSIONS_BY_ENTITY_ID, {
      variables: { entityId }
    });

  const [getMedicals, { loading: loadingMedical, error: errorMedical }] =
    useLazyQuery(QUERY_GET_PERSON_MEDICALS, {
      variables: { entityId, personId },
      skip: !entityId && !personId
    });

  const parseSessionData = sessionsData => {
    if (sessionsData?.getTestSessions) {
      const filteredSessions = sessionsData.getTestSessions.filter(
        session => session.type !== TestSetV2Type.PREVENTION_TEMPLATE
      );
      return parseTestSessionDataToModel(filteredSessions).filter(
        s => s.finishedPercentage > 0
      );
    }
    return [];
  };

  const parseMedicalData = medicalData => {
    if (medicalData?.getMedicalsByPerson) {
      const medicals = medicalData.getMedicalsByPerson;
      if (medicals[0]) {
        const medical = new Medical({ ...medicals[0] });
        const screenings = medical.injuries.reduce((screenings, injury) => {
          return [
            ...screenings,
            ...injury.screenings.reduce((sessions, screening) => {
              return [...sessions, screening];
            }, [])
          ];
        }, []);
        return parseTestSessionDataToModel(screenings);
      }
    }
    return [];
  };

  const getData = async () => {
    const { data: sessionsData } = await getSessions();
    const sessions = parseSessionData(sessionsData);
    const { data: medicalData } = await getMedicals();
    const screenings = parseMedicalData(medicalData);

    const mergedSessions = [...sessions, ...screenings];
    setSessions(
      sort(mergedSessions, {
        keys: [
          {
            key: 'startdate',
            desc: true,
            dataType: SORT_DATA_TYPES.DATE_STRING
          }
        ]
      })
    );
    return !!mergedSessions.length;
  };

  return (
    <UserContext.Provider
      value={{
        sessions,
        loading: loadingMedical && loadingSessions,
        error: errorMedical && errorSessions,
        getData
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

function useUserContext() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(
      'The UserContext hook must be used within a UserContext.Provider'
    );
  }
  return context;
}

export { UserContextProvider, useUserContext };
