import React, { useContext } from 'react';
import Icon from 'components/icon/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Button from 'components/button/Button';
import { downloadHanReportSession } from 'services/aws/session-query';
import { pollIsValidUrl } from 'utils/url';
import { openWindowWithLoader } from 'utils/browser';
import { MODAL_TYPES } from 'models/ModalData';
import { StoreContext } from 'index';

const DownloadHanReportButton = ({ session }) => {
  const intl = useIntl();
  const {
    uiState,
    authStore: { user }
  } = useContext(StoreContext);

  const downloadHanReport = async newWindow => {
    const openWindow = newWindow;
    try {
      uiState.increasePendingRequest();
      const filenameQuery = await downloadHanReportSession(session.id);
      if (filenameQuery) {
        const url = filenameQuery.data.generateHanReport;

        const response = await pollIsValidUrl(url, 120, 1000);

        if (response) {
          openWindow.location = url;
          openWindow.focus();
        }
      }
    } catch (error) {
      if (error.message.indexOf('grade') !== -1) {
        // @ts-ignore
        uiState.showModal({
          title: intl.formatMessage(
            messages.titleSessionHanReportDownloadGradeFailed
          ),
          message: intl.formatMessage(
            messages.messageSessionHanReportDownloadGradeFailed
          ),
          dismissButton: false,
          type: MODAL_TYPES.WARNING
        });
      } else {
        // @ts-ignore
        uiState.showModal({
          title: intl.formatMessage(
            messages.titleSessionHanReportDownloadFailed
          ),
          message: intl.formatMessage(
            messages.messageSessionHanReportDownloadFailed
          ),
          dismissButton: false,
          type: MODAL_TYPES.WARNING
        });
      }
    }

    uiState.decreasePendingRequest();
  };

  if (!user.isHanOrg || session.isILike) return <></>;

  return (
    <Button
      menu
      onClick={() => {
        const newWindow = openWindowWithLoader();
        downloadHanReport(newWindow);
      }}
    >
      <Icon id="download" />
      <FormattedMessage {...messages.menuItemSessionDownloadReport} />
    </Button>
  );
};

export default DownloadHanReportButton;
