import React, { useContext } from 'react';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import { FormattedMessage } from 'react-intl';
import { StoreContext } from 'index';
import Button from 'components/button/Button';

const CopySessionButton = ({
  entityId,
  session,
  onClick,
  children,
  ...props
}) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);

  if (!user.isHylyghtOrg || session.isTalentV2) return <></>;

  return (
    <Button menu onClick={onClick} {...props}>
      <Icon id="copy" />
      <FormattedMessage {...messages.menuItemCopySession} />
    </Button>
  );
};

export default CopySessionButton;
