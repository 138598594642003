import React, { useEffect, useState, useContext } from 'react';
import { generatePath } from 'react-router-dom';
import ProgramsList from 'containers/partials/lists/ProgramsList';
import { useQuery } from '@apollo/client';
import { QUERY_GET_PROGRAMS } from 'services/aws/programs-query';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import { parsePrograms } from 'utils/programs';
import { StoreContext } from 'index';
import { useIntl } from 'react-intl';
import { ROUTE_USER_ENTITY_PROGRAMS_PROGRAM } from 'routes/RouteList';

const ProgramsPanel = ({ entityId }) => {
  const {
    routing: { push },
    uiState: { locale }
  } = useContext(StoreContext);
  const intl = useIntl();
  const [search, setSearch] = useState('');
  const [programsData, setProgramsData] = useState([]);
  const [programs, setPrograms] = useState([]);

  const { data } = useQuery(QUERY_GET_PROGRAMS, {
    variables: { entityId }
  });

  useEffect(() => {
    if (data?.getExercisePrograms && data.getExercisePrograms.length > 0) {
      setProgramsData(parsePrograms(data.getExercisePrograms, intl, locale));
    }
  }, [data]);

  useEffect(() => {
    if (programsData.length > 0) {
      setPrograms(
        sort(
          programsData.filter(p =>
            p.title.toLowerCase().includes(search.toLowerCase())
          ),
          {
            keys: [
              { key: 'order', dataType: SORT_DATA_TYPES.NUMBER },
              { key: 'sortDate', dataType: SORT_DATA_TYPES.NUMBER }
            ]
          }
        )
      );
    }
  }, [search, programsData]);

  const onSearch = e => {
    setSearch(e.target.value);
  };

  const onClickHandler = program => {
    push(
      generatePath(ROUTE_USER_ENTITY_PROGRAMS_PROGRAM, {
        entityId,
        programId: program.id
      })
    );
  };

  return (
    <ProgramsList
      programs={programs}
      entityId={entityId}
      onClick={program => onClickHandler(program)}
      type={`user`}
    />
  );
};

export default ProgramsPanel;
