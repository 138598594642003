import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { useNotificationQueue } from 'components/notification';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import messages from 'messages.js';
import {
  MUTATION_ADD_TEMPLATE,
  MUTATION_EDIT_TEMPLATE,
  QUERY_GET_TEMPLATES
} from 'services/aws/templates-query';
import { cleanFalsy } from 'utils/object';
import { useExercisesContext } from 'contexts/ExercisesContext';
import { TemplateType } from 'constants.js';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required')
});

const TemplateForm = ({
  entityId,
  template,
  children,
  extraClassNames,
  onComplete
}) => {
  const notification = useNotificationQueue();
  const intl = useIntl();

  const { exercisesState } = useExercisesContext();
  const [editTemplate] = useMutation(MUTATION_EDIT_TEMPLATE);
  const [addTemplate] = useMutation(MUTATION_ADD_TEMPLATE, {
    awaitRefetchQueries: true
  });

  const onSubmitHandler = async template => {
    const templateObj = cleanFalsy({
      entityId,
      title: template.title,
      exerciseIds: exercisesState.selectedExercises.map(e => e.id),
      tagIds: template.tags.map(tag => tag.id),
      duration: template.duration,
      type: template.type ?? TemplateType.TEMPLATE
    });

    if (!template.id) {
      await addTemplate({
        variables: {
          ...templateObj
        },
        refetchQueries: [
          {
            query: QUERY_GET_TEMPLATES,
            variables: { entityId, type: template.type }
          }
        ]
      }).then(
        ({ data }) => {
          if (onComplete) onComplete(data.addExerciseProgramTemplate.id);
        },
        () => {
          notification.add('edit_error', {
            message: intl.formatMessage(messages.messageAddTemplateSaveError),
            level: 'error'
          });
        }
      );
    } else {
      await editTemplate({
        variables: {
          id: template.id,
          ...templateObj
        },
        refetchQueries: [
          {
            query: QUERY_GET_TEMPLATES,
            variables: { entityId }
          }
        ]
      }).then(
        ({ data }) => {
          onComplete && onComplete(data.editExerciseProgramTemplate.id);
        },
        () => {
          notification.add('edit_error', {
            message: intl.formatMessage(messages.messageAddTemplateSaveError),
            level: 'error'
          });
        }
      );
    }
  };

  return (
    <Formik
      initialValues={template}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmitHandler}
    >
      {props => {
        return (
          <Form className={classNames(extraClassNames)} noValidate>
            {typeof children === 'function' ? children(props) : children}
          </Form>
        );
      }}
    </Formik>
  );
};

export default TemplateForm;
