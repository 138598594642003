import { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_GET_TEMPLATE } from 'services/aws/templates-query';
import { getFilteredEntities } from 'utils/search';
import Training from 'models/Training';

const useTraining = (entityId, trainingId) => {
  const exercisesData = useRef();
  const [training, setTraining] = useState(new Training({}));
  const [exercises, setExercises] = useState([]);
  const [filter, setFilter] = useState({ search: '' });

  const { loading, error, data } = useQuery(QUERY_GET_TEMPLATE, {
    variables: { id: trainingId },
    skip: !trainingId
  });

  useEffect(() => {
    if (data?.getExerciseProgramTemplate) {
      const training = new Training({ ...data.getExerciseProgramTemplate });
      setTraining(training);
      exercisesData.current = data.getExerciseProgramTemplate.exercises;
      setExercises(exercisesData.current);
    }
  }, [data, trainingId]);

  useEffect(() => {
    if (training && exercisesData?.current?.length) {
      const filteredExercises = getFilteredEntities(
        exercisesData.current,
        filter.search ?? '',
        filter.tags ?? null
      );
      setExercises(filteredExercises);
    }
  }, [filter, training]);

  return {
    training,
    exercises,
    filter,
    setFilter,
    loading,
    error
  };
};

export default useTraining;
