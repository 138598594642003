import { useEffect, useState } from 'react';
import {
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderTitle
} from 'components/card/card-header';
import { CardBody } from 'components/card';
import { FormattedMessage } from 'react-intl';
import messages from 'messages.js';
import { useQuery } from '@apollo/client';
import { QUERY_GET_TAGS } from 'services/aws/tags-query';
import { arrayToTree } from 'performant-array-to-tree';
import { sort } from 'utils/sort';
import TagsSelect from 'components/input/TagsSelect';
import Checkbox from 'components/input/Checkbox';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import { useUIDSeed } from 'react-uid';
import useSelectable from 'hooks/utils/useSelectable';
import slugify from 'slugify';

const FilterPanel = ({ entityId, onSubmit, onCancel, selectedTags }) => {
  const uidSeed = useUIDSeed();
  const [tags, setTags] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const { selected, select, setSelected } = useSelectable();

  const { data } = useQuery(QUERY_GET_TAGS, {
    variables: { entityId }
  });

  useEffect(() => {
    if (selectedTags) {
      setSelected(selectedTags.map(t => t.id));
    }
  }, [selectedTags]);

  useEffect(() => {
    if (data?.getTagsByEntity) {
      setTags(data.getTagsByEntity);
      setTagOptions(
        arrayToTree(
          sort(
            data.getTagsByEntity.map(({ id, label, parentId }) => ({
              id,
              label,
              value: label,
              parentId
            })),
            { keys: [{ key: 'order' }] }
          ),
          { dataField: null }
        )
      );
    }
  }, [data]);

  const onTagSelect = (tags, actions) => {
    switch (actions.action) {
      case 'remove-value':
      case 'pop-value':
        select(actions.removedValue.id);
        break;
      case 'select-option':
        select(actions.option.id);
        break;
    }
  };

  const onTagCheck = (e, tag) => {
    select(tag);
  };

  const onSubmitFilter = () => {
    onSubmit && onSubmit(tags.filter(t => selected.includes(t.id)));
  };

  return (
    <>
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.filterButton} />
        </CardHeaderTitle>
        <CardHeaderSubtitle>
          <FormattedMessage {...messages.filterButton} />
        </CardHeaderSubtitle>
      </CardHeader>
      <CardBody modalSecondary>
        <>
          {tagOptions.map(tag => {
            return (
              <div className="c-card__body-wrapper" key={tag.id}>
                {tag.children.length > 12 ? (
                  <TagsSelect
                    label={tag.label}
                    options={tag.children}
                    value={tag.children
                      .filter(t => selected.includes(t.id))
                      .map(tag => tag && { ...tag, value: tag.label })}
                    isMulti
                    isClearable={false}
                    onChange={(value, actions) => onTagSelect(value, actions)}
                  />
                ) : (
                  <fieldset className="c-input-group">
                    <legend className="c-input__label">{tag.label}</legend>
                    {tag.children.map(tag => (
                      <Checkbox
                        id={uidSeed(tag.id)}
                        name={`checkbox-${slugify(tag.label.toLowerCase())}`}
                        secondary
                        key={tag.id}
                        checked={selected.includes(tag.id)}
                        onChange={e => onTagCheck(e, tag.id)}
                      >
                        {tag.label}
                      </Checkbox>
                    ))}
                  </fieldset>
                )}
              </div>
            );
          })}
          <FormEditFooterButtons
            saveLabel={'filterButton'}
            edit
            onCancel={onCancel}
            onSubmit={onSubmitFilter}
            topMargin
          />
        </>
      </CardBody>
    </>
  );
};

export default FilterPanel;
