import { useEffect, useRef, useState } from 'react';
import {
  BigPlayButton,
  Player,
  ControlBar,
  PlayToggle,
  VolumeMenuButton,
  CurrentTimeDisplay,
  ProgressControl,
  TimeDivider,
  DurationDisplay
} from 'video-react';
import HLSSource from 'components/video/HLSSource';
import AnnotationMarkers from 'components/video/AnnotationMarkers';

const AnnotationPlayer = ({
  id,
  source,
  annotations,
  activeAnnotation,
  onClickMarker,
  onPlayerStateChange,
  ownershipVideo,
  resizedOnScroll
}) => {
  const [playerState, setPlayerState] = useState(null);
  const playerRef = useRef(null);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.subscribeToStateChange(setPlayerState);
    }
  }, [playerRef]);

  useEffect(() => {
    if (playerState?.currentTime) {
      onPlayerStateChange && onPlayerStateChange(playerState);
    }
  }, [playerState, playerState?.currentTime]);

  useEffect(() => {
    if (activeAnnotation?.id) {
      playerRef.current.seek(activeAnnotation.starttime);
    }
  }, [activeAnnotation?.id]);

  const onClickMarkerHandler = annotation => {
    playerRef.current.seek(annotation.starttime);
    onClickMarker && onClickMarker(annotation);
  };

  return (
    <Player
      ref={player => (playerRef.current = player)}
      playsInline
      key={id}
      aspectRatio={resizedOnScroll ? '5:2' : 'auto'}
      height={resizedOnScroll && 200}
    >
      <BigPlayButton position="center" />
      <HLSSource isVideoChild src={source} />
      <ControlBar disableDefaultControls={true} autoHide={false}>
        <PlayToggle key="play-toggle" order={1} />
        <VolumeMenuButton vertical key="volume-menu-button" order={4} />
        <CurrentTimeDisplay key="current-time-display" order={5.1} />
        <TimeDivider order={5.2} />
        <DurationDisplay order={5.3} />
        <AnnotationMarkers
          ownershipVideo={ownershipVideo}
          key="annotation-control"
          annotations={annotations}
          duration={playerRef?.current?.duration}
          activeAnnotation={activeAnnotation}
          order={6}
          onClickMarker={annotation => onClickMarkerHandler(annotation)}
        >
          <ProgressControl key="progress-control" />
        </AnnotationMarkers>
      </ControlBar>
    </Player>
  );
};

export default AnnotationPlayer;
